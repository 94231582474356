import React, { useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';
import type { Dictionary } from 'lodash';
import {
  size,
  toNumber,
  find,
  toUpper,
  filter,
  forEach,
  head,
  isEmpty,
  map,
  toString,
} from 'lodash';
import {
  IonContent,
  IonFooter,
  IonLoading,
  IonRow,
  IonToolbar,
} from '@ionic/react';
import { issueViewURL } from 'navigation';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import useGetStoreroom from 'StoreroomsApp/api/getStoreroom';
import useAddIssue from 'StoreroomsApp/api/useAddIssue';
import useFindChargeBackProfiles from 'StoreroomsApp/api/useFindChargeBackProfiles';
import useUploadIssues from 'StoreroomsApp/api/useUploadIssues';
import ChargeBackFastFind from 'StoreroomsApp/components/ChargeBackFastFind/ChargeBackFastFind';
import ItemCard from 'StoreroomsApp/components/ItemCard/ItemCard';
import useIssueDB from 'StoreroomsApp/database/useIssueDB';
import useItemPOUDB from 'StoreroomsApp/database/useItemPOUDB';
import type { ItemIssue, ItemIssueDTO } from 'StoreroomsApp/models/Issue';
import { getIssueChargeBackValues } from 'StoreroomsApp/pages/IssueItems';
import type { RootState } from 'store/reducers';
import selectIsBranchLocation from 'store/user/selectors';
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import Loader from 'components/Loader/Loader';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './IssueList.module.scss';

interface IssueListProps {
  formValues: Dictionary<unknown>;
  setFieldValue: (field: string, value: unknown) => void;
  resetForm: () => void;
  headerRef?: React.RefObject<HTMLDivElement>;
}

const IssueList = ({
  formValues,
  setFieldValue,
  resetForm,
  headerRef,
}: IssueListProps): JSX.Element => {
  const history = useHistory();
  const { isOnline } = useNetworkStatus();
  const { findItemsFromItemId } = useIssueDB();
  const { findItemsPOUByStoreroom } = useItemPOUDB();
  const { storeroom: storeroomId = '', allowAddAllFromStoreroom } = useSelector(
    (state: RootState) => state.user
  );
  const isBranch = useSelector(selectIsBranchLocation);

  const { storeroom } = useGetStoreroom({ storeroomNumber: storeroomId });
  const [issueItems, setIssueItems] = useState<ItemIssueDTO[]>([]);

  const selectedItems = useMemo(
    () =>
      map(formValues.issueItems as ItemIssueDTO[], (i) => ({
        itemId: i.itemId,
        issueQuantity: i.issueQuantity,
      })),
    [formValues.issueItems]
  );

  useEffect(() => {
    const doFindIssueItems = async () => {
      setIssueItems(
        map(
          await findItemsFromItemId(
            map(selectedItems, (i) => toString(i.itemId))
          ),
          (item) => ({
            ...item,
            ...find(selectedItems, (i) => i.itemId === item.combinedId),
          })
        )
      );
    };
    void doFindIssueItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  const { profiles, isLoading: profilesLoading } = useFindChargeBackProfiles({
    customerNumber: storeroom?.customerNumber,
    enabled: !isEmpty(storeroom?.customerNumber),
  });
  const profile = (head(profiles) || {}) as Dictionary<unknown>;
  const isLoading = profilesLoading;

  useEffect(() => {
    forEach(Object.keys(profile), (key) => {
      const index = key.replace('chargeBackDescription', '');
      if (profile[`chargeBackRequired${index}`] === 'Y') {
        setFieldValue(`chargeBackRequired${index}`, true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [readyForSave, setReadyForSave] = useState(false);
  const [readyForUpload, setReadyForUpload] = useState(false);
  const [offlineUpload, setOfflineUpload] = useState(false);
  const [saveType, setSaveType] = useState('');

  const {
    newIssueId,
    status: addStatus,
    onAddIssue,
    isLoading: newIssueLoading,
  } = useAddIssue();

  const {
    onUploadIssues,
    isLoading: uploadIsLoading,
    status: uploadStatus,
  } = useUploadIssues();

  const onSubmit = (type: string) => {
    setIsSubmitting(true);
    setSaveType(type);
    onAddIssue({
      storeroomNumber: storeroomId,
      chargeBackValues: getIssueChargeBackValues(formValues),
      items: formValues.issueItems as ItemIssue[],
    });
    setIsSubmitting(false);
    setReadyForUpload(true);
  };

  useEffect(() => {
    if (newIssueId && addStatus === 'success' && readyForUpload) {
      if (isOnline) {
        // TODO temporal solution while we fix having multiple DB connections
        setTimeout(() => {
          onUploadIssues({ issueId: newIssueId, storeroomNumber: storeroomId });
        }, 500);
      } else {
        setOfflineUpload(true);
      }
      setReadyForUpload(false);
      setReadyForSave(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline, newIssueId, addStatus, storeroomId, readyForUpload]);

  useEffect(() => {
    if (
      newIssueId &&
      (uploadStatus === 'success' || offlineUpload) &&
      readyForSave
    ) {
      setReadyForSave(false);
      setOfflineUpload(false);
      resetForm();
      if (saveType === 'complete') {
        history.replace(issueViewURL());
      } else {
        forEach(Object.keys(profile), (key) => {
          const index = key.replace('chargeBackDescription', '');
          if (profile[`chargeBackRequired${index}`] === 'Y') {
            setFieldValue(`chargeBackRequired${index}`, true);
          }
        });
        const employeeFieldIndex = find(
          Object.keys(profile),
          (key) => profile[key] === toUpper('employee')
        );
        if (employeeFieldIndex) {
          const index = employeeFieldIndex?.replace(
            'chargeBackDescription',
            ''
          );
          setFieldValue(`chargeBack${index}`, formValues[`chargeBack${index}`]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    uploadStatus,
    newIssueId,
    history,
    saveType,
    readyForSave,
    offlineUpload,
    profile,
  ]);

  const isSubmitLoading =
    isSubmitting ||
    newIssueLoading ||
    uploadIsLoading ||
    readyForUpload ||
    readyForSave;

  const canSubmit =
    !isSubmitLoading &&
    !isEmpty(formValues.issueItems) &&
    filter(issueItems, (i) => {
      const formItem = find(formValues.issueItems as ItemIssueDTO[], {
        itemId: i.combinedId,
      });
      if (formItem && formItem.issueQuantity <= 0) {
        return true;
      }
      const maxQuantity: number | undefined = toNumber(i.balanceOnHandQuantity);
      if (maxQuantity > 0 && formItem && formItem.issueQuantity > maxQuantity) {
        return true;
      }
      return false;
    }).length === 0 &&
    filter(Object.keys(profile), (key) => {
      const index = key.replace('chargeBackDescription', '');
      return (
        formValues[`chargeBackRequired${index}`] &&
        isEmpty(formValues[`chargeBack${index}`])
      );
    }).length === 0;

  return (
    <>
      <IonContent className={classes.content}>
        {headerRef?.current &&
          allowAddAllFromStoreroom &&
          createPortal(
            <Button
              className={classes.addAllButton}
              text="Add all items"
              variant="action"
              disabled={isSubmitLoading}
              onClick={async () => {
                const storeroomItems = await findItemsPOUByStoreroom({
                  miLoc: toString(storeroom?.miLocation),
                  storeroomNumber: storeroomId,
                });
                void setFieldValue('issueItems', [
                  ...(formValues.issueItems as ItemIssue[]),
                  ...map(storeroomItems, (itemPOU) => ({
                    issueQuantity: '1',
                    itemId: itemPOU.combinedId,
                    itemName: `${itemPOU.barcodeValue} ${itemPOU.itemDescription}`,
                  })),
                ]);
              }}
              testid="add-all-items-button"
            />,
            headerRef?.current
          )}
        <IonLoading
          isOpen={uploadIsLoading}
          message="Uploading issue in progress. This could take a couple minutes."
        />
        <div>
          {map(Object.keys(profile), (key) => {
            if (key.startsWith('chargeBackDescription')) {
              const index = key.replace('chargeBackDescription', '');
              const label = toString(profile[`chargeBackDescription${index}`]);
              const isRequired = profile[`chargeBackRequired${index}`] === 'Y';
              const isFastFind =
                toString(profile[`validateChargeBack${index}`]) === 'Y';
              const error =
                size(formValues.issueItems as unknown[]) > 0 &&
                isRequired &&
                !formValues[`chargeBack${index}`]
                  ? `${label} is required.`
                  : '';
              if (label) {
                return isFastFind ? (
                  <ChargeBackFastFind
                    className={classes.issueInput}
                    key={key}
                    label={label}
                    value={toString(formValues[`chargeBack${index}`])}
                    error={error}
                    setValue={(v) => setFieldValue(`chargeBack${index}`, v)}
                    required={isRequired}
                    customerNumber={storeroom?.customerNumber}
                    descriptionId={index}
                    disabled={isSubmitLoading}
                    testid={`${label}-input`}
                  />
                ) : (
                  <Input
                    className={classes.issueInput}
                    key={key}
                    label={label}
                    value={toString(formValues[`chargeBack${index}`])}
                    setValue={(v) => setFieldValue(`chargeBack${index}`, v)}
                    error={error}
                    required={isRequired}
                    disabled={isSubmitLoading}
                    testid={`${label}-input`}
                  />
                );
              }
            }
            return <React.Fragment key={key} />;
          })}
        </div>
        <IonRow className={classes.listHeader}>
          <Text text="CSN" variant="content-heavy" />
          <Text text="QTY" variant="content-heavy" />
        </IonRow>
        {!isBranch && (
          <WarningMessage
            className={classes.warningMessage}
            icon={['far', 'info-circle']}
            title="You must be at a branch level to use this feature."
          />
        )}
        {!isEmpty(issueItems) && (
          <Virtuoso
            className="ion-content-scroll-host"
            data={issueItems}
            increaseViewportBy={{ top: 500, bottom: 500 }}
            itemContent={(index, item) => (
              <ItemCard
                key={item.itemId}
                item={item}
                disabled={isSubmitLoading}
                formValues={formValues}
                setFieldValue={setFieldValue}
              />
            )}
          />
        )}
        {isBranch && isEmpty(issueItems) && !isLoading && (
          <WarningMessage
            className={classes.warningMessage}
            icon={['far', 'info-circle']}
            title="No items added to issue"
          />
        )}
        <Loader
          className={classes.loader}
          text="Loading issue items"
          isOpen={isLoading}
          testid="loading-issue-items"
        />
      </IonContent>
      <IonFooter className={classes.footer}>
        <IonToolbar>
          <IonRow>
            <Button
              className={classes.saveButton}
              variant="action"
              type="submit"
              text="Save"
              onClick={() => onSubmit('save')}
              disabled={!canSubmit}
              testid="save-button"
            />
            <Button
              variant="action"
              type="submit"
              text="Complete"
              onClick={() => onSubmit('complete')}
              disabled={!canSubmit}
              testid="complete-button"
            />
          </IonRow>
        </IonToolbar>
      </IonFooter>
    </>
  );
};

export default IssueList;
