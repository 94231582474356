import { map, size, toNumber } from 'lodash';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { doPromiseAPI, onSuccessMutation } from 'api/helpers';
import type { MiProFile } from 'models/Attachment';
import type { ActivityNoteSimple } from 'models/Notebook';
import { findActivityNotesQueryKey } from './useFindActivityNotes';

interface UploadActivityAttachmentBody {
  updatedImages?: MiProFile[];
  activityNote: ActivityNoteSimple;
}

interface UseUpdateImagesResponse {
  doUpdateActivityAttachments: (
    vars: UploadActivityAttachmentBody
  ) => Promise<void>;
}

const useAddActivityNoteAttachments = (): UseUpdateImagesResponse => {
  const { axios } = useAxios();
  const { addActivityNoteAttachmentsAPI } = useAPIUrl();
  const queryClient = useQueryClient();

  const doUpdateActivityAttachments = async (
    vars: UploadActivityAttachmentBody
  ) => {
    return doPromiseAPI(async (cancelToken) => {
      if (size(vars.updatedImages) > 0 && vars.activityNote) {
        await axios.post<MiProFile[]>(
          addActivityNoteAttachmentsAPI(),
          map(vars.updatedImages, (item, index) => {
            const extension = item.name.substring(
              item.name.lastIndexOf('.') + 1
            );
            return {
              eventNoteId: toNumber(vars?.activityNote.eventNoteId),
              historyId: vars.activityNote.historyId,
              image: item.fileURL,
              imagePath: `file-${Date.now()}-${index}.${extension}`,
              miLoc: vars.activityNote.miLoc,
              seqNo: 0,
            };
          }),
          { cancelToken }
        );
      }
    });
  };

  useMutation(doUpdateActivityAttachments, {
    // TODO: optimisticUI updates in add/update note hooks
    onSuccess: async () => {
      await onSuccessMutation(queryClient, findActivityNotesQueryKey);
    },
  });

  return {
    doUpdateActivityAttachments,
  };
};

export default useAddActivityNoteAttachments;
