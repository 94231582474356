import React from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { isEmpty, toString } from 'lodash';
import { IonCard, IonCol, IonRow } from '@ionic/react';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import useAccessControls, { AccessControlType } from 'hooks/useAccessControls';
import type { InspectionReport } from 'models/InspectionReport';
import { DateFormatEnum, formatDate } from 'utils/date';
import { findIcon } from 'utils/icons';
import Button from 'components/Button/Button';
import DateFormat from 'components/DateFormat/DateFormat';
import Text from 'components/Text/Text';
import classes from './DocumentCard.module.scss';

interface DocumentCardProps {
  document: InspectionReport;
  searchQuery?: string;
  href?: string;
  disabled?: boolean;
  onSendEmail?: () => void;
  id: string;
}

const DocumentCard = ({
  document,
  searchQuery,
  href,
  disabled,
  onSendEmail,
  id,
}: DocumentCardProps): JSX.Element => {
  const { isOnline } = useNetworkStatus();
  const dateString: string =
    (document.coverPage.startDate as string) || '01/15/2020';
  const history = useHistory();

  const doNavigation: () => void = () => {
    if (href && !disabled) {
      history.push(href);
    }
  };

  const documentEdit = useAccessControls(AccessControlType.editDocuments);
  const disabledEmail = !isOnline || document.needSync;

  return (
    <IonCard
      onClick={doNavigation}
      role="button"
      tabIndex={0}
      className={classNames(classes.documentCard, {
        [classes.disabledCard]: disabled,
      })}
      data-testid="DocumentCard"
      id={id}
    >
      <IonRow>
        <div className={classes.info}>
          <div className={classes.titleRow}>
            <Text
              className={classes.titleWrap}
              text={toString(document.updatedName || document.coverPage.name)}
              textQuery={searchQuery}
              variant="title-action-card"
            />
            {!!document.needSync && (
              <Text
                className={
                  documentEdit ? classes.needSyncBadge : classes.noAccessBadge
                }
                variant="label-micro"
                text={documentEdit ? 'Ready for Upload' : 'No Upload Access'}
              />
            )}
          </div>
          <IonRow className={classes.subtitleRow}>
            <IonCol size="auto">
              <DateFormat
                testid="cardDate"
                date={new Date(dateString)}
                variant="content-small"
              />
            </IonCol>
            <IonCol size="auto">
              <Text
                text={toString(document.coverPage.status)}
                variant="content-small"
                textQuery={searchQuery}
                className={classes.status}
              />
            </IonCol>
          </IonRow>
          {!isEmpty(document.coverPage.woCtlNo) && (
            <div className={classes.DetailsRow}>
              <Text
                className={classes.documentDetail}
                text={`WO: ${toString(document.coverPage.woCtlNo)}`}
                textQuery={searchQuery}
                variant="content-small"
              />
              <Text
                className={classes.documentDetail}
                text={`Branch: ${toString(document.coverPage.miLoc)}`}
                textQuery={searchQuery}
                variant="content-small"
              />
              {document.coverPage.orderCtlNo && (
                <Text
                  className={classes.documentDetail}
                  text={`OCN: ${toString(document.coverPage.orderCtlNo)}`}
                  textQuery={searchQuery}
                  variant="content-small"
                />
              )}
            </div>
          )}
          {!isEmpty(document.coverPage.siteText) && (
            <div className={classes.DetailsRow}>
              <Text
                className={classes.documentDetail}
                text={`Site: ${toString(document.coverPage.siteText)}`}
                textQuery={searchQuery}
                variant="content-small"
              />
            </div>
          )}
          {!isEmpty(document.coverPage.machineText) && (
            <div className={classes.DetailsRow}>
              <Text
                className={classes.documentDetail}
                text={`Machine: ${toString(document.coverPage.machineText)}`}
                textQuery={searchQuery}
                variant="content-small"
              />
            </div>
          )}
          {(document.coverPage.customerName ||
            document.coverPage.customerNo) && (
            <div className={classes.DetailsRow}>
              <Text
                className={classes.documentDetail}
                text={`Customer: ${toString(
                  document.coverPage.customerName ||
                    document.coverPage.customerNo
                )}`}
                textQuery={searchQuery}
                variant="content-small"
              />
            </div>
          )}
          {document.coverPage.creationUserName && (
            <div className={classes.DetailsRow}>
              <Text
                className={classes.documentDetail}
                text={`Created: ${toString(
                  document.coverPage.creationUserName
                )} Date: ${formatDate(
                  toString(document.coverPage.creationTmstmp),
                  DateFormatEnum.DocumentCardDateAPI
                )}`}
                textQuery={searchQuery}
                variant="content-small"
              />
            </div>
          )}
          {document.coverPage.lastUpdUserName && (
            <div className={classes.DetailsRow}>
              <Text
                className={classes.documentDetail}
                text={`Updated: ${toString(
                  document.coverPage.lastUpdUserName
                )} Date: ${formatDate(
                  toString(document.coverPage.lastUpdTmstmp),
                  DateFormatEnum.DocumentCardDateAPI
                )}`}
                textQuery={searchQuery}
                variant="content-small"
              />
            </div>
          )}
        </div>
        <div className={classes.actions}>
          {(document.coverPage.status as string) === 'CL' && (
            <Button
              variant="link"
              className={classNames(classes.envelope, {
                [classes.disabledButton]: disabledEmail,
              })}
              icon={findIcon('envelope')}
              onClick={(e) => {
                e.stopPropagation();
                if (!disabled && !disabledEmail) {
                  onSendEmail?.();
                }
              }}
              testid="email-button"
            />
          )}
          <Button
            variant="link"
            className={classes.rightIcon}
            icon={findIcon('chevron-right')}
            testid="nav-button"
          />
        </div>
      </IonRow>
    </IonCard>
  );
};

export default DocumentCard;
