import type { MutationStatus } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import type { UpdateMutationContext } from 'api/helpers';
import {
  onErrorUpdate,
  doPromiseAPI,
  onMutateUpdate,
  onSuccessMutation,
} from 'api/helpers';
import { ToastType } from 'models/Toast';
import type { UserConfigs } from 'models/UserConfig';
import { userConfigQueryKey } from './useGetUserConfig';

interface UseUpdateUserConfigResponse {
  status: MutationStatus;
  onUpdateUserConfig: (body: UserConfigs) => void;
}

interface UseUpdateUserConfigProps {
  configType: 'reports' | 'currency';
}

const useUpdateUserConfig = ({
  configType,
}: UseUpdateUserConfigProps): UseUpdateUserConfigResponse => {
  const { axios } = useAxios();
  const { configTypeAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  const doUpdateUserReportConfigs = (body: UserConfigs) => {
    return doPromiseAPI(async (cancelToken) => {
      await axios.post(configTypeAPI(configType), body, {
        cancelToken,
      });
    });
  };

  const { status, mutate } = useMutation(doUpdateUserReportConfigs, {
    onMutate: async (body) => {
      return onMutateUpdate<UserConfigs>({
        queryClient,
        queryKey: userConfigQueryKey,
        queryKeyParams: { configType },
        updatedItems: [
          {
            currency: body?.currency,
            report: body?.report,
          } as UserConfigs,
        ],
        updateAll: true,
        isInfiniteQuery: false,
        isSingleQuery: true,
      });
    },
    onSuccess: () => {
      void onSuccessMutation(queryClient, userConfigQueryKey, { configType });
    },
    onError: (_error, _vars, context) => {
      addToast({
        type: ToastType.error,
        text: 'Update User Config operation failed. Please try again later.',
        testid: 'update-user-config-error-toast',
      });
      onErrorUpdate<UserConfigs>({
        queryClient,
        context: context as UpdateMutationContext<UserConfigs>[],
        isInfiniteQuery: false,
        isSingleQuery: true,
      });
    },
  });

  return {
    status,
    onUpdateUserConfig: (body: UserConfigs) => mutate(body),
  };
};

export default useUpdateUserConfig;
