import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { isEmpty, kebabCase, map } from 'lodash';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { namespaces } from 'i18n/i18n.constants';
import { searchURL } from 'navigation';
import useFindSearchResults from 'api/search/useFindSearchResults';
import type { SearchGlobalOrder } from 'models/Search';
import {
  SearchResultTabTypeEnum,
  SearchSuggestionTypeEnum,
} from 'models/Search';
import { getAddress } from 'utils/address';
import { getErrorMessage } from 'utils/helpers';
import { concatRoutes } from 'utils/navigations';
import {
  getSearchResultData,
  getSearchResultTabLabel,
  handleSearchNavigation,
} from 'utils/search';
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll';
import Loader from 'components/Loader/Loader';
import Refresher from 'components/Refresher/Refresher';
import SearchSuggestionCard from 'components/Search/SearchSuggestionCard/SearchSuggestionCard';
import SearchSuggestionOcnCard from 'components/Search/SearchSuggestionCard/SearchSuggestionOcnCard';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './SearchResults.module.scss';

interface SearchResultsProps {
  searchType: SearchResultTabTypeEnum;
  searchQuery: string;
  miLoc: string;
  sortField?: string;
  title?: string;
}
const SearchResults = ({
  searchType,
  searchQuery,
  miLoc,
  sortField,
  title,
  className,
}: SearchResultsProps & IonicReactProps): JSX.Element => {
  const { url } = useRouteMatch();
  const { t } = useTranslation(namespaces.search);

  const {
    items,
    error,
    fetchNextPage,
    hasError,
    showLoader,
    isEmptyResponse,
    enableInfiniteScroll,
    refetch,
  } = useFindSearchResults({ searchType, query: searchQuery, sortField });

  return (
    <div className={className} data-testid={`search-result-tab-${searchType}`}>
      <Refresher
        slot="fixed"
        className={classes.refresher}
        hidden
        onRefresh={refetch}
        testid="Search-Results"
        disabled={showLoader}
      />
      {!isEmpty(title) && (
        <Text
          className={classes.title}
          variant="title-info-card"
          text={t(getSearchResultTabLabel(searchType))}
          testid="search-section-title"
        />
      )}
      {map(items, (item, index) => {
        const { type: cardType } = item;

        if (cardType === SearchResultTabTypeEnum.ocns) {
          return (
            <SearchSuggestionOcnCard
              item={item as SearchGlobalOrder}
              testid={`ocn-${item.text}`}
              textQuery={searchQuery}
            />
          );
        }
        const {
          type,
          miLoc: iMiLoc,
          id,
          key,
          text,
          description,
          natlAcctNo,
          address,
          customerNo,
          supplierLocationNo,
        } = getSearchResultData(item, index, t);

        return (
          <SearchSuggestionCard
            key={key}
            id={searchType}
            searchSuggestionType={SearchSuggestionTypeEnum.result}
            text={text}
            customerNo={customerNo}
            textQuery={searchQuery}
            description={description}
            account={natlAcctNo}
            address={getAddress(address, ',')}
            href={concatRoutes(
              !isEmpty(title) ? url : searchURL(),
              handleSearchNavigation({
                type,
                miLoc: iMiLoc || miLoc,
                sequenceNo: id,
                supplierId: supplierLocationNo || id || '',
                customerId: customerNo || id || '',
                employeeId: id,
              })
            )}
            testid={`${type}-${kebabCase(text)}`}
            searchType={type}
            isMainCard={isEmpty(title) && index === 0}
          />
        );
      })}
      {isEmptyResponse && (
        <WarningMessage
          className={classes.warningMessage}
          icon={['far', 'info-circle']}
          title="No results for your search"
        />
      )}
      {hasError && (
        <WarningMessage
          className={classes.warningMessage}
          title="Error loading results"
          body={getErrorMessage(error)}
        />
      )}
      <Loader
        className={classes.loader}
        text="Loading results"
        isOpen={showLoader}
      />
      <InfiniteScroll
        disabled={!enableInfiniteScroll}
        onLoadMore={fetchNextPage}
        testid="infinite-scroll"
      />
    </div>
  );
};

export default SearchResults;
