import type React from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { map, type Dictionary, includes, split } from 'lodash';
import type { SalesReportRow } from 'ReportsApp/api/useGetSalesDashboard';
import { useGetTableColumns } from 'ReportsApp/hooks/useGetTableColumns';
import type { ReportField } from 'ReportsApp/models';
import { goToSalesPerformanceReport } from 'ReportsApp/navigation/navigationHelpers';
import { goToCustomerSnapshot } from 'SearchApp/navigation/navigationHelpers';
import type { RootState } from 'store/reducers';
import type { FilterOption } from 'components/Filter/Filter';
import type { SalesPerformanceURLParams } from './SalesPerformance';

interface UseGetSalesTableDataProps {
  drilldownData: SalesReportRow[];
  summaryData?: SalesReportRow;
  reportFields: ReportField[];
  selectedGroupBy: FilterOption;
  setCanChangeTab: React.Dispatch<React.SetStateAction<boolean>>;
  setGroupByData: React.Dispatch<
    React.SetStateAction<Dictionary<FilterOption | undefined> | undefined>
  >;
}

// eslint-disable-next-line import/prefer-default-export
export const useGetSalesTableData = ({
  drilldownData,
  summaryData,
  reportFields,
  selectedGroupBy,
  setCanChangeTab,
  setGroupByData,
}: UseGetSalesTableDataProps) => {
  const { isCamUser } = useSelector((state: RootState) => state.user);

  const {
    tab = '',
    orgType = '',
    rowId: routeRowId = '',
  } = useParams<SalesPerformanceURLParams>();

  const [rowId] = split(routeRowId, '-');
  const groupByDataKey = selectedGroupBy.key;
  const namespace = 'reports:salesPerformanceReport';

  const getTableData = (row: SalesReportRow, index: number) => {
    let rowName = row.miLocName ? `${row.miLoc}: ${row.miLocName}` : row.miLoc;
    let href: string | undefined = goToSalesPerformanceReport({
      orgType: groupByDataKey,
      miLoc: row.miLoc,
      rowId: routeRowId,
    });
    const customerHref = goToCustomerSnapshot({
      miLoc: row.miLoc,
      customerId: row.customerNo,
    });

    switch (groupByDataKey) {
      case 'TEAM':
        rowName = row.teamName;
        href = goToSalesPerformanceReport({
          orgType: groupByDataKey,
          miLoc: row.teamId,
        });
        break;
      case 'REP':
        rowName = row.repName;
        href = goToSalesPerformanceReport({
          orgType: groupByDataKey,
          miLoc: row.miLoc,
          rowId: row.repNo,
        });
        break;
      case 'NATLACCT':
        rowName = `${row.miLoc}: ${row.customerName}`;
        href = goToSalesPerformanceReport({
          orgType: groupByDataKey,
          miLoc: row.miLoc,
          rowId: `${row.nationalAcctNo}-${row.miLoc}`,
        });
        break;
      case 'CUST':
        rowName =
          !includes(
            ['TEAM', 'BRCH', 'REP', 'CUST', 'PRD_GRP_01', 'PRD_GRP_02'],
            orgType
          ) && rowId
            ? `${row.miLoc}: ${row.customerName}`
            : row.customerName;
        href = isCamUser
          ? customerHref
          : goToSalesPerformanceReport({
              orgType: groupByDataKey,
              miLoc: row.miLoc,
              rowId: row.customerNo,
            });
        break;
      case 'PRD_GRP_01':
        rowName = row.pgcName;
        href = goToSalesPerformanceReport({
          baseUrl: tab === 'search' ? `${customerHref}/reports` : '',
          orgType: groupByDataKey,
          miLoc: row.miLoc,
          rowId,
          pgc1: row.pgc,
        });
        break;
      case 'PRD_GRP_02':
        rowName = row.pgcName;
        href = undefined;
        break;
      default:
    }

    if (row.disabled) {
      href = undefined;
    }

    const customerLink = isCamUser && groupByDataKey === 'CUST';
    let hideArrow = !href;
    hideArrow ||= !!customerLink;
    hideArrow ||= row.disabled;

    const onClick = () => {
      if (!href) {
        return;
      }
      // Changing tabs doesn't work after clicking on a customer name if canChangeTab is false
      // DOC: ionic fires tab change on route change, we need a flag to stop it
      // TUDU: remove or replace canChangeTab logic
      setCanChangeTab(includes(['CUST'], groupByDataKey));
      setGroupByData((prev) => {
        const newGroupBy = { ...prev };
        delete newGroupBy?.[groupByDataKey];
        return newGroupBy;
      });
    };

    return {
      data: {
        ...row,
        title: rowName,
        href,
        customerLink,
        onClick,
        hideArrow,
        testid: `sales-${rowName}-${index}`,
      },
    };
  };

  const tableData = useMemo(
    () => map(drilldownData, getTableData),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [drilldownData, groupByDataKey, routeRowId, rowId, isCamUser, orgType, tab]
  );

  const { tableColumns, totalsRow } = useGetTableColumns({
    groupByDataKey,
    reportFields,
    selectedGroupBy,
    namespace,
    summaryData,
  });

  return { tableData, tableColumns, totalsRow };
};
