import type { FC } from 'react';
import React from 'react';
import { isNumber, max, toNumber } from 'lodash';
import type { BarItemProps, BarDatum } from '@nivo/bar';
import { ResponsiveBar } from '@nivo/bar';
import { animated, useSpring } from '@react-spring/web';
import { formatLeftYAxisValues } from 'components/Charts/helpers/utils';
import classes from './DailyChart.module.scss';

export interface BarColumnData {
  key: string;
  value: number;
}

export interface DailyChartData extends BarDatum {
  id: string;
  color: string;
}
export interface DailyChartProps {
  isLoading?: boolean;
  data: DailyChartData[];
  testid: string;
  currencyType?: string;
}

const getMaxScale = (data: DailyChartData[]): number => {
  const yScale: number[] = data.map(({ value }) =>
    isNumber(value) ? value : 0
  );

  const maxValue = toNumber(max(yScale)) || 0;
  if (!maxValue) return 3;

  const getOrderOfMagnitude = (n: number) =>
    10 ** Math.floor(Math.log(Math.abs(n)) / Math.LN10 + 0.000000001) || 1;

  const order = getOrderOfMagnitude(maxValue);

  return Math.ceil(maxValue / order) * order;
};

export interface DailyChartDatum {
  color: string;
}

export const CustomBarComponent: FC<BarItemProps<BarDatum>> = ({
  bar: { x, y, width, height, color },
}: BarItemProps<BarDatum>) => {
  const customBarAnimation = useSpring({
    from: { height: 0, y: 0 },
    to: { height, y },
  });
  return (
    <animated.rect
      width={64}
      fill={color}
      strokeWidth="1"
      x={x + (width - 64) / 2}
      style={customBarAnimation}
    />
  );
};

const theme = {
  axis: {
    ticks: {
      text: {
        fill: '#999999',
      },
      line: {
        strokeWidth: 0,
      },
    },
  },
};

const Chart = ({
  isLoading,
  data,
  testid,
  currencyType,
}: DailyChartProps): JSX.Element => (
  <div className={classes.DailyChart} data-testid={testid}>
    <ResponsiveBar
      data={data}
      animate
      margin={{
        top: 10,
        bottom: 25,
        right: 10,
        // TODO: calculate this margin, same as LineChart
        left: currencyType === 'USD' ? 35 : 50,
      }}
      keys={['value']}
      indexBy="key"
      minValue="auto"
      maxValue={getMaxScale(data)}
      enableGridY
      valueScale={{
        type: 'linear',
      }}
      gridYValues={4}
      axisLeft={{
        tickSize: 0,
        tickPadding: 5,
        tickValues: 4,
        format: formatLeftYAxisValues(!!isLoading, currencyType),
      }}
      layout="vertical"
      colors={{ datum: 'data.color' }}
      isInteractive={false}
      enableLabel={false}
      barComponent={CustomBarComponent}
      theme={theme}
    />
  </div>
);

export default Chart;
