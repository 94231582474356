import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { toString } from 'lodash';
import type { PluginListenerHandle } from '@capacitor/core';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import type { TextareaChangeEventDetail } from '@ionic/core/components';
import { IonCol, IonItem, IonLabel, IonRow, IonTextarea } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { withStringProp } from 'utils/helpers';
import {
  handleKeypadOnScroll,
  removePaddedChildren,
} from 'utils/keypadScrollHelpers';
import type { TextVariantType } from 'components/Text/Text';
import Text from 'components/Text/Text';
import classes from './TextArea.module.scss';

interface TextAreaProps {
  label?: string;
  error?: string;
  setValue?: (v: string) => void;
  testid: string;
  opacityDisabled?: boolean;
  textVariant?: TextVariantType;
  inputRowClassName?: string;
  parentRef?: React.RefObject<HTMLDivElement>;
  disableAutoScroll?: boolean;
}

const TextArea = ({
  className,
  inputRowClassName,
  autofocus,
  placeholder,
  inputMode,
  value,
  name,
  rows,
  label = '',
  error,
  setValue,
  onBlur,
  disabled = false,
  required,
  testid,
  maxlength,
  opacityDisabled = false,
  textVariant,
  parentRef,
  disableAutoScroll = true,
  autocapitalize = 'sentences',
  spellcheck = true,
}: TextAreaProps &
  React.ComponentProps<typeof IonTextarea> &
  IonicReactProps): JSX.Element => {
  const withLabel = withStringProp(label);
  const inputDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let keyboardListener: PluginListenerHandle;
    const doKeyboadListener = async () => {
      if (Capacitor.isPluginAvailable('Keyboard')) {
        keyboardListener = await Keyboard.addListener('keyboardWillHide', () =>
          removePaddedChildren({ parentRef })
        );
      }
    };
    void doKeyboadListener();

    return () => {
      if (Capacitor.isPluginAvailable('Keyboard')) {
        void keyboardListener?.remove();
      }
    };
  }, [parentRef]);

  return (
    <div className={classes.inputDiv} ref={inputDivRef}>
      <IonItem
        className={classNames(className, classes.textareaContainer, {
          [classes.error]: error,
          [classes.withoutLabel]: !withLabel,
          [classes.disabled]: disabled,
          [classes.opacityDisabled]: opacityDisabled,
        })}
        lines="none"
      >
        <IonCol>
          {withLabel && (
            <IonLabel>
              <Text
                className={classes.label}
                variant={textVariant || 'content-heavy'}
                text={label}
                testid={`${testid}-label`}
              />
              {required && (
                <Text
                  className={classes.requiredLabel}
                  variant={textVariant || 'content-heavy'}
                  text="*"
                  testid={`${testid}-required-label`}
                />
              )}
            </IonLabel>
          )}
          <IonRow
            className={classNames(classes.textareaWrapper, inputRowClassName)}
          >
            <IonTextarea
              aria-label={name}
              className={classes.textarea}
              name={name}
              autofocus={autofocus}
              placeholder={placeholder}
              inputmode={inputMode}
              value={value}
              onIonInput={(e: CustomEvent<TextareaChangeEventDetail>) =>
                setValue?.call(null, toString(e.detail.value))
              }
              onIonBlur={(e: CustomEvent<FocusEvent>) => {
                onBlur?.call(
                  null,
                  e as unknown as React.FocusEvent<HTMLIonTextareaElement>
                );
                removePaddedChildren({ parentRef });
              }}
              onIonFocus={() => {
                if (!disableAutoScroll) {
                  handleKeypadOnScroll({ inputDivRef, parentRef });
                }
              }}
              data-testid={testid}
              required={required}
              disabled={disabled}
              maxlength={maxlength}
              rows={rows}
              autocapitalize={autocapitalize}
              spellcheck={spellcheck}
            />
          </IonRow>
          {error && (
            <Text
              className={classes.errorMessage}
              variant="content-small"
              text={error}
              testid={`${testid}-error`}
            />
          )}
        </IonCol>
      </IonItem>
    </div>
  );
};

export default TextArea;
