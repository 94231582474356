import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import type { Dictionary } from 'lodash';
import { get, includes, isEmpty, map, toString, values } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonCol, IonRow } from '@ionic/react';
import { documentListConfig } from 'DocumentsApp/api/useUpdateValues';
import {
  GENERIC_TYPE,
  type TemplateGroup,
  type TemplateImage,
  type TemplateSection,
} from 'models/DocumentTemplate';
import type {
  ReportImage,
  ReportValue,
  SignatureDBItem,
} from 'models/InspectionReport';
import { findIcon } from 'utils/icons';
import DocumentContainer from 'components/Documents/DocumentContainer/DocumentContainer';
import DocumentHeader from 'components/Documents/DocumentHeader/DocumentHeader';
import DocumentImage from 'components/Documents/DocumentImage/DocumentImage';
import DocumentParagraph from 'components/Documents/DocumentParagraph/DocumentParagraph';
import DocumentPartsList from 'components/Documents/DocumentPartsList/DocumentPartsList';
import type { PartsListItem } from 'components/Documents/DocumentPartsList/DocumentPartsList';
import DocumentRecursiveContainer from 'components/Documents/DocumentRecursiveContainer/DocumentRecursiveContainer';
import DocumentSubHeader from 'components/Documents/DocumentSubHeader/DocumentSubHeader';
import Loader from 'components/Loader/Loader';
import styles from './DocumentSection.module.scss';

export interface DocumentSectionProps {
  section: TemplateSection;
  isLoading: boolean;
  isExpanded: boolean;
  subSectionToFlip?: string;
  subSectionExpanded?: Dictionary<boolean>;
  setSubSectionExpanded?: React.Dispatch<
    React.SetStateAction<Dictionary<boolean>>
  >;
  subSectionIsEdit?: Dictionary<boolean>;
  setSubSectionIsEdit?: React.Dispatch<
    React.SetStateAction<Dictionary<boolean>>
  >;
  disabled?: boolean;
  toggleExpansion: (subSectionIndex?: string) => void;
  containsData: boolean;
  setContainsData: (hasData: boolean) => void;
  containsImages: boolean;
  setContainsImages: (hasImages: boolean) => void;
  updateSectionData: (newData: Record<number, ReportValue>) => void;
  reportId: number;
  imagesForUpload: Record<number, ReportImage[]>;
  setImagesForUpload: (images: Record<number, ReportImage[]>) => void;
  imagesForRemoval: Record<number, ReportImage[]>;
  setImagesForRemoval: (images: Record<number, ReportImage[]>) => void;
  reportValues?: ReportValue[];
  needSyncValues?: number[];
  reportImages?: ReportImage[];
  needSyncImages?: number[];
  custNo: string;
  miLoc: string;
  reportListData: Dictionary<object>;
  needSyncListData?: Dictionary<number[]>;
  updateListValue: (
    key: string
  ) => (
    partIndex: number,
    attributeName: string,
    value: string,
    defaultValue?: string
  ) => void;
  addNewToList: (key: string) => () => void;
  deleteFromList: (key: string, listId: string) => (partIndex: number) => void;
  startingSaveSection?: boolean;
  templateImages?: TemplateImage[];
  createSignature?: (name: string, image: string, inputId: number) => void;
  reportSignature: SignatureDBItem;
}

const DocumentSection = ({
  section,
  isLoading,
  isExpanded,
  subSectionToFlip,
  subSectionExpanded,
  setSubSectionExpanded,
  subSectionIsEdit,
  setSubSectionIsEdit,
  disabled,
  toggleExpansion,
  containsData,
  setContainsData,
  containsImages,
  setContainsImages,
  updateSectionData,
  reportId,
  reportValues,
  needSyncValues,
  reportImages,
  needSyncImages,
  imagesForUpload,
  setImagesForUpload,
  imagesForRemoval,
  setImagesForRemoval,
  custNo,
  miLoc,
  reportListData,
  needSyncListData,
  updateListValue,
  addNewToList,
  deleteFromList,
  startingSaveSection = false,
  templateImages = [],
  reportSignature,
  createSignature,
}: DocumentSectionProps): JSX.Element => {
  const getSectionHeader = () => {
    let sectionHeader = '';
    map(section.templateGroups, (group: TemplateGroup) => {
      if (group.type === 'H1') {
        sectionHeader = group.title || '';
      }
    });
    return sectionHeader;
  };

  const getCnValues = (group: TemplateGroup) => {
    const groupValues = new Array<ReportValue>();
    map(reportValues, (reportValue: ReportValue | undefined) => {
      if (reportValue && reportValue.groupId) {
        if (reportValue.groupId === group.groupId) {
          groupValues.push(reportValue);
        }
      }
    });
    return groupValues;
  };

  const getRCValues = (group: TemplateGroup) => {
    const groupValues = new Array<ReportValue>();
    map(reportValues, (reportValue: ReportValue | undefined) => {
      if (reportValue && reportValue.groupId) {
        if (reportValue.groupId === group.groupId) {
          groupValues.push(reportValue);
        }
      }
    });
    return groupValues;
  };

  const getImValues = (group: TemplateGroup) => {
    const groupImages: ReportImage[] = [];
    map(reportImages, (reportImage: ReportImage | undefined) => {
      if (reportImage && reportImage.groupId) {
        if (reportImage.groupId === group.groupId) {
          groupImages.push(reportImage);
        }
      }
    });
    return groupImages;
  };

  const getImagesForUpload = (groupId: number) => {
    return imagesForUpload[groupId] || [];
  };

  const getImagesForRemoval = (groupId: number) => {
    return imagesForRemoval[groupId] || [];
  };

  const populateContainerDataStates: () => Record<string, boolean> = () => {
    const dataStates: Record<string, boolean> = {};
    map(section.templateGroups, (templateGroup: TemplateGroup) => {
      if (
        templateGroup.type === 'CN' ||
        includes(['RC', GENERIC_TYPE], templateGroup.type)
      ) {
        let isPopulatedContainer = false;
        map(reportValues, (reportValue: ReportValue) => {
          if (
            reportValue &&
            templateGroup.groupId === reportValue.groupId &&
            reportValue.inputValue.length > 0
          ) {
            isPopulatedContainer = true;
          }
        });
        dataStates[templateGroup.groupId] = isPopulatedContainer;
      }
    });
    return dataStates;
  };

  const [containerDataStates, setContainerDataStates] = useState<
    Record<string, boolean>
  >(populateContainerDataStates());

  const [disabledView, setDisabledView] = useState(startingSaveSection);

  const onClick = (subSectionIndex?: string) => {
    if (!disabledView) {
      toggleExpansion(subSectionIndex);
    }
    const sectionListType = toString(
      get(
        section,
        `templateGroups.1.templateGroupInputs.0.additionalInformation`
      )
    );
    if (
      !isExpanded &&
      sectionListType &&
      isEmpty(reportListData[sectionListType])
    ) {
      addNewToList(sectionListType)();
    }
  };

  useEffect(() => {
    setDisabledView(startingSaveSection);
  }, [startingSaveSection]);

  useEffect(() => {
    setContainerDataStates(populateContainerDataStates());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportValues]);

  const populateContainerImageStates: () => Record<string, boolean> = () => {
    const imageStates: Record<string, boolean> = {};
    map(section.templateGroups, (imageGroup: TemplateGroup) => {
      if (imageGroup.type === 'IM') {
        let isPopulatedImGroup = false;
        map(reportImages, (reportImage: ReportImage) => {
          if (reportImage) {
            if (
              imageGroup.groupId === reportImage.groupId &&
              (reportImage.image || reportImage.imagePath)
            ) {
              isPopulatedImGroup = true;
            }
          }
        });
        imageStates[imageGroup.groupId] = isPopulatedImGroup;
      }
    });
    return imageStates;
  };

  const [containerImageStates, setContainerImageStates] = useState<
    Record<string, boolean>
  >(populateContainerImageStates());

  useEffect(() => {
    setContainerImageStates(populateContainerImageStates());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportImages]);

  const isGenericSection =
    get(section, 'templateGroups.0.type') === GENERIC_TYPE;

  return (
    <div className={styles.sectionWrap}>
      <div
        role="button"
        tabIndex={0}
        onClick={() => onClick()}
        onKeyPress={() => {}}
        className={classNames(styles.accordionHeader, {
          [styles.isLoading]: isLoading,
          [styles.hideSectionHeader]: isGenericSection,
        })}
      >
        <IonRow className={styles.headerRow}>
          <IonCol className={styles.p0}>
            <DocumentHeader titleText={getSectionHeader()} />
          </IonCol>
          <IonCol size="auto" className={styles.iconCol}>
            {isLoading && (
              <span className={styles.sectionLoaderWrap}>
                <Loader isOpen={isLoading} />
              </span>
            )}
            {containsImages && (
              <FontAwesomeIcon
                className={styles.rightIcon}
                icon={findIcon('file-image')}
              />
            )}
            {containsData && (
              <FontAwesomeIcon
                className={styles.rightIcon}
                icon={findIcon('file-edit')}
              />
            )}
          </IonCol>
        </IonRow>
      </div>
      {(isExpanded || isGenericSection) && (
        <div
          className={classNames(styles.accordionPanel, {
            [styles.genericContainer]: isGenericSection,
          })}
        >
          {map(section.templateGroups, (group, groupIndex) => {
            const rcGroup = group.templateGroupInputs?.[0];
            const listType = toString(rcGroup?.additionalInformation);
            const imagesProps = {
              imagesForUpload: getImagesForUpload(group.groupId),
              setImagesForUpload: (images: ReportImage[]) => {
                const myRecord: Record<number, ReportImage[]> = {
                  ...imagesForUpload,
                };
                myRecord[group.groupId] = images;
                setImagesForUpload(myRecord);
              },
              imagesForRemoval: getImagesForRemoval(group.groupId),
              setImagesForRemoval: (images: ReportImage[]) => {
                const myRecord: Record<number, ReportImage[]> = {
                  ...imagesForRemoval,
                };
                myRecord[group.groupId] = images;
                setImagesForRemoval(myRecord);
              },
              imageValues: getImValues(group),
              needSyncImages,
              setHasImageData: (hasImData: boolean) => {
                // 1st update state for specific image container
                const newImDataStates = { ...containerImageStates };
                newImDataStates[group.groupId] = hasImData;
                setContainerImageStates(newImDataStates);
                // update state for section
                let hasSectionData = false;
                map(values(newImDataStates), (imDataState: boolean) => {
                  if (imDataState) {
                    hasSectionData = true;
                  }
                });
                setContainsImages(hasSectionData);
              },
              captionLength: group.templateId === 1 ? 40 : undefined,
              captionShowCharCounter: group.templateId === 1,
              isPrimary: false,
            };

            return (
              <div
                className={
                  group.type === 'P' ? styles.paragraphWrap : styles.groupWrap
                }
                key={groupIndex}
              >
                {group.type === 'H2' && <DocumentSubHeader group={group} />}
                {group.type === 'P' && <DocumentParagraph group={group} />}
                {group.type === 'CN' && (
                  <DocumentContainer
                    group={group}
                    containerValues={getCnValues(group)}
                    needSyncValues={needSyncValues}
                    disabled={disabled}
                    reportId={reportId}
                    groupId={group.groupId}
                    custNo={custNo}
                    miLoc={miLoc}
                    createSignature={createSignature}
                    reportSignature={reportSignature}
                    setHasData={(hasCnData: boolean) => {
                      // 1st update state for specific container
                      const newCnDataStates = { ...containerDataStates };
                      newCnDataStates[group.groupId] = hasCnData;
                      setContainerDataStates(newCnDataStates);
                      // update state for section
                      let hasSectionData = false;
                      map(values(newCnDataStates), (cnDataState: boolean) => {
                        if (cnDataState) {
                          hasSectionData = true;
                        }
                      });
                      setContainsData(hasSectionData);
                    }}
                    onFormUpdate={updateSectionData}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...imagesProps}
                  />
                )}
                {includes(['RC', GENERIC_TYPE], group.type) && (
                  <DocumentRecursiveContainer
                    custNo={custNo}
                    miLoc={miLoc}
                    reportId={reportId}
                    groupId={group.groupId}
                    disabled={disabled}
                    type={group.type}
                    inputId={rcGroup?.inputId || 0}
                    fields={rcGroup?.rcObject?.fields || []}
                    title={toString(rcGroup?.rcObject?.title)}
                    multiple={rcGroup?.rcObject?.multiple || false}
                    listType={listType}
                    containerValues={getRCValues(group)}
                    needSyncValues={needSyncValues}
                    setHasData={(hasCnData: boolean) => {
                      if (listType) {
                        setContainsData(hasCnData);
                        return;
                      }
                      // 1st update state for specific container
                      const newCnDataStates = { ...containerDataStates };
                      newCnDataStates[group.groupId] = hasCnData;
                      setContainerDataStates(newCnDataStates);
                      // update state for section
                      let hasSectionData = false;
                      map(values(newCnDataStates), (cnDataState: boolean) => {
                        if (cnDataState) {
                          hasSectionData = true;
                        }
                      });
                      setContainsData(hasSectionData);
                    }}
                    onFormUpdate={updateSectionData}
                    templateImages={templateImages}
                    reportListData={reportListData}
                    needSyncListData={needSyncListData}
                    updateListValue={updateListValue(listType)}
                    addNewToList={addNewToList(listType)}
                    deleteFromList={deleteFromList(
                      listType,
                      toString(get(documentListConfig, `${listType}.id`))
                    )}
                    isLoading={isLoading}
                    disabledView={disabledView}
                    isExpanded={isExpanded}
                    onExpand={onClick}
                    subSectionToFlip={subSectionToFlip}
                    subSectionExpanded={subSectionExpanded}
                    setSubSectionExpanded={setSubSectionExpanded}
                    subSectionIsEdit={subSectionIsEdit}
                    setSubSectionIsEdit={setSubSectionIsEdit}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...imagesProps}
                  />
                )}
                {group.type === 'IM' && (
                  <DocumentImage
                    reportId={reportId}
                    groupId={group.groupId}
                    disabled={disabled}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...imagesProps}
                  />
                )}
                {group.type === 'PL' && (
                  <DocumentPartsList
                    partsListData={
                      reportListData.parts as Dictionary<PartsListItem>
                    }
                    needSyncPartsList={needSyncListData?.parts}
                    disabled={disabled}
                    updatePartValue={updateListValue('parts')}
                    addNewPart={addNewToList('parts')}
                    deletePart={deleteFromList('parts', 'partId')}
                  />
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default DocumentSection;
