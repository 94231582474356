import { useCallback } from 'react';
import SentryCategories from 'constants/sentryCategories';
import { includes, toString } from 'lodash';
import type { Photo } from '@capacitor/camera';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { CapacitorException } from '@capacitor/core';
import * as Sentry from '@sentry/capacitor';
import imageCompression from 'browser-image-compression';
import { useToasts } from 'providers/ToastProvider';
import { ToastType } from 'models/Toast';

export interface UseCameraResponse {
  getPhoto: (
    source?: CameraSource,
    compressed?: boolean
  ) => Promise<Photo | void>;
}

const useCamera = (): UseCameraResponse => {
  const { addToast } = useToasts();

  const getPhoto = useCallback(
    async (source?: CameraSource, compressed = false) => {
      try {
        const photo = await Camera.getPhoto({
          width: 1920,
          height: 1080,
          // TODO Check this change after Capacitor 4 Migration
          // preserveAspectRatio: true,
          quality: 100,
          source: source || CameraSource.Prompt,
          resultType: compressed
            ? CameraResultType.DataUrl
            : CameraResultType.Uri,
        });

        if (compressed) {
          const compressOptions = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920, // @todo: make this a constant?
            useWebWorker: true,
          };

          const imageFile = await imageCompression.getFilefromDataUrl(
            toString(photo.dataUrl),
            `camera-image-${new Date().getTime()}.${photo.format}`
          );

          const compressedFile = await imageCompression(
            imageFile,
            compressOptions
          );

          return {
            webPath: await imageCompression.getDataUrlFromFile(compressedFile),
            format: photo.format,
          } as Photo;
        }

        return photo;
      } catch (e) {
        if (
          e instanceof CapacitorException &&
          includes(
            ['User cancelled photos app', 'User denied access to photos'],
            e.message
          )
        ) {
          addToast({
            type: ToastType.error,
            text: 'Failed to add photo. Please ensure Mi Pro has been granted camera and gallery access.',
            testid: 'take-photo-error-toast',
          });
          // treat this specific exception as a breadcrumb to avoid noise in Sentry
          Sentry.addBreadcrumb({
            category: SentryCategories.CAMERA,
            message: e.message,
            level: 'debug',
          });
        } else {
          Sentry.captureException(e);
        }

        return Promise.resolve();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return { getPhoto };
};

export default useCamera;
