import React, { useState } from 'react';
import { toNumber, toString, isEmpty } from 'lodash';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import useFindMachines from 'DocumentsApp/api/useFindMachines';
import { useDebounce } from 'use-debounce';
import type { Machine } from 'models/InspectionReport';
import type Input from 'components/Input/Input';
import FastFind from './FastFind';

interface MachinesFastFindProps {
  siteId?: string;
  machine?: Machine;
  setMachine?: (i: Machine) => void;
}

const MachinesFastFind = ({
  label,
  value,
  required,
  disabled,
  setValue,
  siteId,
  machine,
  setMachine,
  testid,
}: MachinesFastFindProps &
  React.ComponentProps<typeof Input> &
  IonicReactProps): JSX.Element => {
  const [searchQuery, setSearchQuery] = useState(toString(value));
  const [searchQueryValue] = useDebounce(searchQuery, 300);

  const {
    machines,
    showLoader,
    isEmptyResponse,
    enableInfiniteScroll,
    fetchNextPage,
  } = useFindMachines({
    query: searchQueryValue,
    siteId: siteId ? toNumber(siteId) : undefined,
    enabled: !isEmpty(siteId),
  });

  return (
    <FastFind<Machine>
      items={machines}
      getId={(i) => toString(i.machineId)}
      getTitle={(i) =>
        toString(i.machineId)
          ? `${i.custMachineId}${i.machineDesc ? ` - ${i.machineDesc}` : ''}`
          : toString(i.machineText)
      }
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      showLoader={showLoader}
      isEmptyResponse={isEmptyResponse}
      enableInfiniteScroll={enableInfiniteScroll}
      fetchNextPage={fetchNextPage}
      label={label}
      value={value}
      disabled={disabled || isEmpty(siteId)}
      required={required}
      setValue={setValue}
      selectedItem={machine}
      setSelectedItem={setMachine}
      testid={testid}
    />
  );
};

export default MachinesFastFind;
