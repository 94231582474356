import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toNumber, upperCase } from 'lodash';
import { IonCard, IonCardContent, IonCardHeader } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { namespaces } from 'i18n/i18n.constants';
import { searchCustomerURL, searchURL } from 'navigation';
import useGetAccountsReceivable from 'api/customer/useGetAccountsReceivable';
import { getErrorMessage } from 'utils/helpers';
import { findIcon } from 'utils/icons';
import { concatRoutes } from 'utils/navigations';
import Button from 'components/Button/Button';
import AccountsReceivableWidget from 'components/Charts/AccountsReceivableWidget/AccountsReceivableWidget';
import type { BarColumnData } from 'components/Charts/DailyChart/Chart';
import Loader from 'components/Loader/Loader';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './Snapshots.module.scss';

interface AccountsReceivableCardProps {
  miLoc: string;
  id: string;
}

export interface AccountsReceivableReportItemData extends BarColumnData {
  color: string;
}

const AccountsReceivableCard = ({
  miLoc,
  id,
  className,
}: AccountsReceivableCardProps & IonicReactProps): JSX.Element => {
  const history = useHistory();
  const { t } = useTranslation(namespaces.ar);

  const { accountsReceivable, error, isLoading } = useGetAccountsReceivable({
    miLoc,
    id,
  });

  const arCurrentReportData: AccountsReceivableReportItemData = {
    key: 'arCurrent',
    value: toNumber(accountsReceivable?.ACCOUNT_STATUS_CURRENT) || 0,
    color: 'green',
  };

  const arBeyondTermsReportData: AccountsReceivableReportItemData = {
    key: 'arBeyondTerms',
    value: toNumber(accountsReceivable?.ACCOUNT_STATUS_BEYOND_TERMS) || 0,
    color: 'red',
  };

  if (error) {
    return (
      <WarningMessage
        className={classes.warningMessage}
        title={t('common:errorLoading')}
        body={getErrorMessage(error)}
      />
    );
  }

  return (
    <IonCard className={className}>
      <IonCardHeader>
        <Button
          variant="link"
          testid="ar_card_header_btn"
          onClick={() =>
            history.push(
              concatRoutes(
                searchURL(),
                searchCustomerURL(miLoc, id),
                '/accounts-receivable'
              )
            )
          }
          rightIcon={findIcon('chevron-right')}
          className={classes.accountsReceivableCardHeader}
          text={upperCase(t('snapshot:accountsReceivable'))}
        />
      </IonCardHeader>
      <IonCardContent>
        {!isLoading && (
          <AccountsReceivableWidget
            arCurrentData={arCurrentReportData}
            arBeyondTermsData={arBeyondTermsReportData}
            currencyType={accountsReceivable?.currencyType}
          />
        )}
        {isLoading && <Loader isOpen />}
      </IonCardContent>
    </IonCard>
  );
};

export default AccountsReceivableCard;
