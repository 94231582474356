import React, { useState } from 'react';
import type { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { get, isNil, toString } from 'lodash';
import { searchURL } from 'navigation';
import useAccessControls, { AccessControlType } from 'hooks/useAccessControls';
import type { Customer } from 'models/Customer';
import { concatRoutes } from 'utils/navigations';
import { handleSearchNavigation } from 'utils/search';
import {
  PhoneCallRow,
  PhoneSMSRow,
} from 'components/Contacts/PhoneNumber/PhoneNumber';
import SnapshotLink from 'components/Contacts/SnapshotLink/SnapshotLink';
import { EmailRow } from 'components/Email/Email';
import Modal from 'components/Modal/Modal';
import SendEmailModal from 'components/Modals/SendEmailModal/SendEmailModal';
import classes from './ContactModal.module.scss';

interface ContactModalProps {
  customerData: Customer;
  enableTexting?: boolean;
  testid: string;
  excludeSnapshotLink?: boolean;
}

const ContactModal = ({
  isOpen,
  setIsOpen,
  title,
  customerData,
  enableTexting,
  testid,
  excludeSnapshotLink,
}: ContactModalProps & ComponentProps<typeof Modal>): JSX.Element => {
  const ac = useAccessControls(AccessControlType.viewCustomers);
  const { t } = useTranslation();
  const { name: customerName = '', miLoc = '', customerNo = '' } = customerData;
  const getKeyValue = (customerKey: string) =>
    toString(get(customerData, customerKey));

  const phoneNumber = getKeyValue('mainContact.phone');
  const email = getKeyValue('mainContact.email');
  const { mainContact } = customerData;

  const [sendEmailModal, setSendEmailModal] = useState(false);

  return (
    <>
      <Modal
        className={classes.modal}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        eyebrow={
          !excludeSnapshotLink && (
            <SnapshotLink
              className={classes.snapshotLink}
              eyebrow={`${miLoc}${customerNo}`}
              onClick={() => setIsOpen?.(false)}
              href={concatRoutes(
                searchURL(),
                handleSearchNavigation({
                  type: 'customer',
                  miLoc,
                  customerId: customerNo,
                })
              )}
              text={customerName}
              disabled={!ac}
              testid="customer-snapshot-button"
            />
          )
        }
        title={title}
        testid={testid}
      >
        {phoneNumber && (
          <PhoneCallRow
            className={classes.actionRow}
            phoneNumber={phoneNumber}
            onClick={() => {
              setIsOpen?.call(null, false);
            }}
            testid={`${testid}-action-contact-phone`}
          />
        )}
        {phoneNumber && enableTexting && (
          <PhoneSMSRow
            className={classes.actionRow}
            phoneNumber={phoneNumber}
            onClick={() => {
              setIsOpen?.call(null, false);
            }}
            testid={`${testid}-action-contact-sms`}
          />
        )}
        {email && (
          <EmailRow
            emailAddress={email}
            className={classes.actionRow}
            testid={`${testid}-action-contact-email`}
            onClick={() => {
              setIsOpen?.call(null, false);
              setSendEmailModal(true);
            }}
          />
        )}
      </Modal>
      {!isNil(mainContact) && email && (
        <SendEmailModal
          searchType="customer"
          id={customerData.customerNo}
          miLoc={customerData.miLoc}
          isOpen={sendEmailModal}
          defaultRecipients={[mainContact]}
          setIsOpen={setSendEmailModal}
          title={t('common:sendEmail')}
          testid={`${testid}-send-email-modal`}
        />
      )}
    </>
  );
};
export default ContactModal;
