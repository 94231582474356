import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import type { Dictionary } from 'lodash';
import {
  head,
  includes,
  isNaN,
  join,
  replace,
  toNumber,
  toString,
  get,
} from 'lodash';
import { IonCol, IonRow } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import EmployeeSelect from 'CostSavingsApp/components/FastFind//EmployeeSelect';
import ManufacturerSelect from 'CostSavingsApp/components/FastFind//ManufacturerSelect';
import ContactSelect from 'CostSavingsApp/components/FastFind/ContactSelect';
import ProductSelect from 'CostSavingsApp/components/FastFind/ProductSelect';
import type {
  CostSavingsEntryForm,
  CostSavingsField,
} from 'CostSavingsApp/models/CostSavings';
import {
  CostSavingsDataTypeEnum,
  FastFindTypeEnum,
} from 'CostSavingsApp/models/CostSavings';
import classes from 'CostSavingsApp/pages/CostSavingsEntry.module.scss';
import { getUnixTime } from 'date-fns';
import * as yup from 'yup';
import type { SelectModalItem } from 'models/Search';
import { parseDate } from 'utils/date';
import CheckBox from 'components/CheckBox/CheckBox';
import CurrencyFormat from 'components/CurrencyFormat/CurrencyFormat';
import DateInput from 'components/DateInput/DateInput';
import Input from 'components/Input/Input';
import RadioButton from 'components/RadioButton/RadioButton';
import type { TextVariantType } from 'components/Text/Text';
import Text from 'components/Text/Text';
import TextArea from 'components/TextArea/TextArea';

export const getCalcExpression = (
  formData: CostSavingsEntryForm,
  text = '',
  withNumber = false
) => {
  const rTokens = [];
  const textRegExp = new RegExp(`\\{[a-zA-Z._]+\\}`, 'gi');
  let match = textRegExp.exec(text);
  let lastIndex = 0;
  rTokens.push(text);
  while (match !== null) {
    rTokens.pop();
    rTokens.push(text.substring(lastIndex, match.index));
    let matchText = `i.${replace(
      toString(head(match)),
      new RegExp(`[\\{\\}]`, 'gi'),
      ''
    )}`;
    if (withNumber) {
      matchText = `+(${matchText})`;
    }
    rTokens.push(matchText);
    lastIndex = textRegExp.lastIndex;
    rTokens.push(text.substring(textRegExp.lastIndex));
    match = textRegExp.exec(text);
  }
  return `(function doCalcExpression() {
    let i = ${JSON.stringify(formData)};
    return ${join(rTokens, '')};
  })()`;
};

export const getInputType = (
  name: string,
  type: string,
  fastFindType?: FastFindTypeEnum
): string => {
  switch (type) {
    case CostSavingsDataTypeEnum.date:
      return 'date';
    case CostSavingsDataTypeEnum.textarea:
      return 'textarea';
    case CostSavingsDataTypeEnum.decimal:
      return 'decimal';
    case CostSavingsDataTypeEnum.int:
      return 'int';
    case CostSavingsDataTypeEnum.email:
      return 'email';
    case CostSavingsDataTypeEnum.radio:
      return 'radio';
    case CostSavingsDataTypeEnum.checkbox:
      return 'checkbox';
    case CostSavingsDataTypeEnum.expression:
      return 'expression';
    case CostSavingsDataTypeEnum.fastfind:
      if (fastFindType === FastFindTypeEnum.employee) {
        return 'employeeFastFind';
      }
      if (
        fastFindType === FastFindTypeEnum.manufacturer ||
        name.indexOf('MFR_CTL_NO') !== -1
      ) {
        return 'manufacturerFastFind';
      }
      if (name.indexOf('ITEM_NO') !== -1) {
        return 'productFastFind';
      }
      if (fastFindType === FastFindTypeEnum.contact) {
        return 'contactFastFind';
      }
      return 'text';
    default:
      return 'text';
  }
};

export const contactSelectTypes = ['employeeFastFind', 'contactFastFind'];
export const selectTypes = [
  ...contactSelectTypes,
  'manufacturerFastFind',
  'productFastFind',
];

interface CostSavingsInputProps {
  miLoc?: string;
  customerNo?: string;
  formData: CostSavingsEntryForm;
  formErrors?: Dictionary<unknown>;
  setFieldValue?: (n: string, v: unknown) => void;
  setFieldError?: (n: string, v: string) => void;
  field: CostSavingsField;
  viewMode?: boolean;
  disabled?: boolean;
  textVariant?: TextVariantType;
  testid: string;
  parentRef?: React.RefObject<HTMLDivElement>;
  disableAutoScroll?: boolean;
}

const CostSavingsInput = ({
  miLoc = '',
  customerNo = '',
  formData,
  formErrors,
  setFieldValue,
  setFieldError,
  field,
  viewMode,
  disabled,
  textVariant,
  testid,
  parentRef,
  disableAutoScroll = true,
}: CostSavingsInputProps & IonicReactProps): JSX.Element => {
  const { t } = useTranslation();
  const fieldName = field.columnName;
  const value = toString(formData[fieldName]);
  const required = field.required === 'Y';
  const error = toString(formErrors?.[fieldName]);

  const RadioButtonOptions = [
    {
      value: 'Y',
      displayValue: t('common:yes'),
    },
    {
      value: 'N',
      displayValue: t('common:no'),
    },
  ];

  const getDisplayType = () => {
    if (field.displayType === CostSavingsDataTypeEnum.currency) {
      return 'currency';
    }
    return 'text';
  };

  const inputType = getInputType(fieldName, field.dataType, field.fastFindType);
  const displayType = getDisplayType();
  const selectType = includes(selectTypes, inputType);
  const selectFieldName = `${fieldName}_DROPDOWN`;
  const selectValue = get(formData, selectFieldName) as SelectModalItem;
  const numericInput = includes(['decimal', 'int'], inputType);

  const validateInput = async (v: string) => {
    let fieldError = '';
    if (inputType === 'email' && !!v) {
      try {
        const schema = yup.string().email();
        await schema.validate(v);
      } catch (e) {
        fieldError = t('contact:invalidEmail');
      }
    }
    setFieldError?.(
      fieldName,
      required && !v ? t('common:requiredError') : fieldError
    );
  };

  const setValue = (v: string) => {
    setFieldValue?.(fieldName, v);
    void validateInput(v);
  };

  const setSelectValue = (item: SelectModalItem) => {
    setFieldValue?.(fieldName, item.key);
    setFieldValue?.(selectFieldName, item);
    setTimeout(() => validateInput(item.key), 0);
  };

  const [inputFocus, setInputFocus] = useState(false);
  useEffect(() => {
    if (!value && field.defaultValue && !inputFocus) {
      setFieldValue?.(fieldName, field.defaultValue);
      setFieldError?.(fieldName, '');
    }
  }, [
    setFieldValue,
    setFieldError,
    inputFocus,
    fieldName,
    field.defaultValue,
    value,
  ]);

  let inputMask = toString(field.inputMask);
  if (inputType === 'expression') {
    inputMask = replace(inputMask, '^', '^-{0,1}');
  }
  // DOC: some masks have a definition like {\d}, but we need {\d,\d} for library to work
  const matchFixes = inputMask.matchAll(/\{(?<digits>\d+)\}/gi);
  let nextFix = matchFixes.next();
  while (!nextFix.done) {
    inputMask = replace(
      inputMask,
      toString(get(nextFix, 'value.0')),
      `{0,${toString(get(nextFix, 'value.groups.digits'))}}`
    );
    nextFix = matchFixes.next();
  }

  let scale = '';
  if (field.inputMask) {
    scale = toString(
      inputMask.match(/(?<digits>\d+)\}\)?\?\$/)?.groups?.digits
    );
  }

  const evalCalculation = () => {
    if (inputType === 'expression') {
      try {
        const calcValue = toNumber(
          // eslint-disable-next-line no-eval
          eval(getCalcExpression(formData, field.expression, true))
        );
        if (!isNaN(calcValue)) {
          const maskedValue = toString(calcValue);
          setFieldValue?.(fieldName, maskedValue);
        }
      } catch (e) {
        // do nothing with error
      }
    }
  };

  useEffect(() => {
    evalCalculation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, field.expression, fieldName, inputType]);

  if (viewMode) {
    return (
      <>
        {displayType === 'currency' && (
          <CurrencyFormat
            value={toNumber(value)}
            variant={textVariant}
            ignoreScale={!scale}
            scale={scale ? toNumber(scale) : undefined}
            currencyType="USD"
            testid={testid}
          />
        )}
        {displayType === 'text' && <Text text={value} variant={textVariant} />}
      </>
    );
  }

  return (
    <IonRow className={classes.rowHeading}>
      <IonCol className={classNames({ [classes.inputWrapper]: !selectType })}>
        {inputType === 'date' && (
          <DateInput
            label={field.displayName}
            placeholder={field.description}
            name={fieldName}
            value={value}
            error={error}
            setValue={(v) => {
              const date = getUnixTime(parseDate(v));
              setFieldValue?.(fieldName, date);
              setTimeout(() => validateInput(toString(date)), 0);
            }}
            onBlur={() => validateInput(value)}
            testid={testid}
            required={required}
            disabled={disabled}
            className={classes.vasHeading}
            inputRowClassName={classes.selectCustInput}
            parentRef={parentRef}
            disableAutoScroll={disableAutoScroll}
          />
        )}
        {includes(['text', 'email', 'decimal', 'int'], inputType) && (
          <Input
            className={classes.vasHeading}
            label={field.displayName}
            placeholder={field.description}
            mask={inputMask ? new RegExp(inputMask) : undefined}
            name={fieldName}
            value={value}
            error={error}
            type={numericInput ? 'number' : undefined}
            inputmode={numericInput ? 'decimal' : undefined}
            setValue={setValue}
            onFocus={() => setInputFocus(true)}
            onBlur={() => {
              setInputFocus(false);
              void validateInput(value);
            }}
            testid={testid}
            required={required}
            disabled={disabled}
            inputRowClassName={classes.selectCustInput}
            maxlength={field.maxlength}
            spellcheck={fieldName === 'custContactTitle'}
            autocapitalize={
              fieldName === 'custContactTitle' ? 'sentences' : 'off'
            }
            parentRef={parentRef}
            disableAutoScroll={disableAutoScroll}
          />
        )}
        {inputType === 'textarea' && (
          <TextArea
            label={field.displayName}
            placeholder={field.description}
            name={fieldName}
            value={value}
            error={error}
            setValue={setValue}
            onBlur={() => validateInput(value)}
            testid={testid}
            required={required}
            disabled={disabled}
            className={classes.vasHeading}
            inputRowClassName={classes.selectCustInput}
            parentRef={parentRef}
            disableAutoScroll={disableAutoScroll}
          />
        )}
        {/* TODO: make all fastfinds a single component? */}
        {inputType === 'employeeFastFind' && (
          <EmployeeSelect
            className={classes.vasHeading}
            label={field.displayName}
            placeholder={field.description}
            setEmployee={setSelectValue}
            onBlur={() => validateInput(value)}
            inputError={error}
            required={required}
            disabled={disabled}
            selItem={selectValue}
          />
        )}
        {inputType === 'manufacturerFastFind' && (
          <ManufacturerSelect
            className={classes.vasHeading}
            label={field.displayName}
            placeholder={field.description}
            setManufacturer={setSelectValue}
            onBlur={() => validateInput(value)}
            inputError={error}
            required={required}
            disabled={disabled}
            selItem={selectValue}
            showInput={field.show !== 'N'}
          />
        )}
        {inputType === 'productFastFind' && (
          <ProductSelect
            miLoc={miLoc}
            className={classes.vasHeading}
            label={field.displayName}
            placeholder={field.description}
            setProduct={setSelectValue}
            onBlur={() => validateInput(value)}
            inputError={error}
            required={required}
            disabled={disabled}
            selItem={selectValue}
          />
        )}
        {inputType === 'contactFastFind' && (
          <ContactSelect
            miLoc={miLoc}
            customerNo={customerNo}
            className={classes.vasHeading}
            label={field.displayName}
            placeholder={field.description}
            setContact={setSelectValue}
            onBlur={() => validateInput(value)}
            inputError={error}
            required={required}
            disabled={disabled}
            maxlength={field.maxlength}
            selItem={selectValue}
          />
        )}
        {inputType === 'radio' && (
          <>
            <IonRow
              className={classNames(classes.vasHeading, classes.vasRadio)}
            >
              <IonCol>
                <Text
                  text={`${field.displayName}${required ? '*' : ''}`}
                  variant="content-heavy"
                />
              </IonCol>
              <IonCol>
                <RadioButton
                  options={RadioButtonOptions}
                  allowEmptySelection
                  testid="cost-savings-radio-button"
                  disabled={disabled}
                  onChange={(val) => {
                    setFieldValue?.(fieldName, val);
                    setTimeout(() => validateInput(toString(val)), 0);
                  }}
                  value={value}
                />
              </IonCol>
            </IonRow>
            {error && (
              <Text
                className={classes.errorMessage}
                variant="content-small"
                text={error}
                testid={`${testid}-error`}
              />
            )}
          </>
        )}
        {inputType === 'checkbox' && (
          <IonRow className={classNames(classes.vasHeading, classes.vasRadio)}>
            <IonCol className={classes.checkboxLabel}>
              <Text
                text={`${field.displayName}${required ? '*' : ''}`}
                variant="content-heavy"
              />
            </IonCol>
            <IonRow className={classes.checkboxRow}>
              <IonCol className={classes.checkboxWrapper}>
                <CheckBox
                  aria-label={fieldName}
                  name={fieldName}
                  checked={!!value}
                  testid={testid}
                  onChange={(e) => {
                    const val = e ? 'Y' : '';
                    setFieldValue?.(fieldName, val);
                    setTimeout(() => validateInput(val), 0);
                  }}
                  label={field.description}
                  labelTextVariant="mipro-h6-headline"
                />
              </IonCol>
            </IonRow>
          </IonRow>
        )}
        {inputType === 'expression' && (
          <IonRow
            className={classNames(classes.vasHeading, classes.vasDisplay)}
          >
            <Text
              text={field.displayName}
              variant="content-heavy"
              testid={testid}
            />
            {displayType === 'currency' && (
              <CurrencyFormat
                value={toNumber(value)}
                ignoreScale={!scale}
                scale={scale ? toNumber(scale) : undefined}
                currencyType="USD"
                testid={testid}
              />
            )}
            {displayType === 'text' && (
              <Text text={value} variant="content-heavy" />
            )}
          </IonRow>
        )}
      </IonCol>
    </IonRow>
  );
};

export default CostSavingsInput;
