/* istanbul ignore file */
import type { Dictionary } from 'lodash';
import type { MiProFile } from 'models/Attachment';
import type { SelectModalItem, WithOptimisticUI } from 'models/Search';

export interface CostSavingsURLParams {
  miLoc?: string;
  id?: string;
  vasCtlNo?: string;
}

export interface CostSavingsCode {
  codeDesc: string;
  codeValue: string;
}

export interface CostSavingsTemplate {
  custCostSavingsDesc: string;
  custVasCode: string;
  custVasDesc: string;
  employeeId: string;
  employeeName: string;
  fieldsList: CostSavingsField[];
  miLoc?: string;
  instructions: string;
}

export enum CostSavingsDataTypeEnum {
  decimal = 'DECIMAL',
  fastfind = 'FASTFIND',
  text = 'TEXT',
  email = 'EMAIL',
  int = 'INT',
  date = 'DATE',
  radio = 'RADIO',
  checkbox = 'CHECKBOX',
  expression = 'EXPRESSION',
  textarea = 'TEXTAREA',
  currency = 'CURRENCY',
}

export enum CustomerSearchTabTypeEnum {
  CustomerSearch = 'Cust',
  CorporateSearch = 'Corporate',
}

export enum FastFindTypeEnum {
  employee = 'employee',
  contact = 'contact',
  manufacturer = 'manufacturer',
}

export interface CostSavingsBaseModel {
  employeeId?: string;
  motionContact?: string;
  custContactEmail?: string;
  custContactName?: string;
  custContactTitle?: string;
  costSavingsBlitz?: string;
  supplierAssistance?: string;
  supplierMfrCtlNo?: string;
  problemSolution?: string;
  signature?: string;
  signatureName?: string;
  signatureAgreement?: boolean;
}

export interface CostSavings extends CostSavingsBaseModel, WithOptimisticUI {
  costSavingsStatus: 'A' | 'D' | 'I' | 'P';
  description: string;
  creationTimestamp: Dictionary<string>;
  reminderDate: Dictionary<string>;
  dateOfService: Dictionary<string>;
  creationUserName: string;
  miLoc: string;
  customerNo: string;
  vasCtlNo: string;
  vasCode: string;
  vasDesc: string;
  custVasDesc: string;
  custName: string;
  custEmailAddress: string;
  custTitle: string;
  supplierAssistanceMfrCtlNo: string;
  supplierAssistanceMfrName: string;
  vasExtPrice: number;
}

export interface CostSavingsEntryForm extends CostSavingsBaseModel {
  VAS_CODE?: SelectModalItem;
  VAS_TEMPLATE?: CostSavingsTemplate;
  VAS_ATTACHMENTS?: MiProFile[];
  VAS_ATTACHMENTS_ADDED?: MiProFile[];
  VAS_ATTACHMENTS_REMOVED?: string[];
  custContactName_DROPDOWN?: SelectModalItem;
  supplierMfrCtlNo_DROPDOWN?: SelectModalItem;
  dateOfService?: number;
  vasCtlNo?: string;
}

export interface CostSavingsField {
  columnName: keyof CostSavingsEntryForm;
  miProColumnName?: keyof CostSavingsEntryForm;
  dataType: CostSavingsDataTypeEnum;
  defaultValue?: string;
  description: string;
  displayName: string;
  displayType?: CostSavingsDataTypeEnum;
  expression?: string;
  isExpression?: string;
  inputMask?: string;
  required?: string;
  vasCode?: string;
  fastFindType?: FastFindTypeEnum;
  show?: string;
  maxlength?: number;
}
