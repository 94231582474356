import { useSelector } from 'react-redux';
import type { RootState } from 'store/reducers';

interface UseGetLocationCurrency {
  secondaryCurrencyType?: string;
}

const useGetLocationCurrency = (currency?: string): UseGetLocationCurrency => {
  const {
    miLoc = '',
    currencyType,
    locationTree = {},
  } = useSelector((state: RootState) => state.user);
  if (currency) {
    return { secondaryCurrencyType: currency };
  }
  let secondaryCurrencyType = currencyType;

  const parentMiloc = locationTree[miLoc]?.parent;

  if (
    parentMiloc === 'EXEC' &&
    locationTree[parentMiloc]?.userRole === 'EXEC'
  ) {
    if (miLoc === 'CN00') {
      secondaryCurrencyType = 'CAD';
    }
    if (miLoc === 'MX00') {
      secondaryCurrencyType = 'MXN';
    }
  }
  return {
    secondaryCurrencyType,
  };
};

export default useGetLocationCurrency;
