import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { map, size, isEmpty } from 'lodash';
import { IonContent, IonPage, IonRow } from '@ionic/react';
import Header from 'common/components/Header/Header';
import useFindStartCustomers from 'InventoryApp/api/useFindStartCustomer';
import InventoryCustomTitle from 'InventoryApp/components/InventoryCustomTitle/InventoryCustomTitle';
import ListItem from 'InventoryApp/components/ListItem/ListItem';
import useInventoryHeaderActions from 'InventoryApp/hooks/useInventoryHeaderActions';
import { shippingCustomersURL, inventoryURL } from 'navigation';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import { useDebounce } from 'use-debounce';
import { useGetSelectedMiLoc } from 'api/helpers';
import { concatRoutes } from 'utils/navigations';
import ChangeLocation from 'components/ChangeLocation/ChangeLocation';
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll';
import Loader from 'components/Loader/Loader';
import Refresher from 'components/Refresher/Refresher';
import Searchbar from 'components/Searchbar/Searchbar';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './FindStartCustomer.module.scss';

const FindStartCustomer = (): JSX.Element => {
  const { t } = useTranslation();

  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryValue] = useDebounce(searchQuery, 300);
  const { selectedMiLoc, fromVirtualTeam, team, isBranchUser } =
    useGetSelectedMiLoc();
  const { isOnline } = useNetworkStatus();

  const isShowChangeLocation = !isBranchUser && !fromVirtualTeam && !team;

  const {
    findStartCustomersList,
    showLoader,
    lastUpdatedAt,
    refetch,
    fetchNextPage,
    enableInfiniteScroll,
    isEmptyResponse,
    loc,
  } = useFindStartCustomers({
    miLoc: fromVirtualTeam || team ? undefined : selectedMiLoc.miLoc,
    sendVirtualTeamId: true,
    searchQuery: searchQueryValue,
    enabled: isOnline && !isShowChangeLocation,
  });

  const contentTitle = searchQueryValue
    ? t('inventory:searchResult', { count: size(findStartCustomersList) })
    : t('inventory:allAccounts', { count: size(findStartCustomersList) });

  const { headerActions } = useInventoryHeaderActions({});

  return (
    <IonPage
      data-testid="find-start-customer"
      className={classes.findStartCustomerPage}
    >
      <Header
        hideMenuButton
        hideCartButton
        testid="find-start-customer-header"
        headerActions={headerActions}
        customTitle={<InventoryCustomTitle title={t('inventory:customers')} />}
      >
        <IonRow className={classes.searchWrapper}>
          <Searchbar
            placeholder={t('inventory:searchAccount')}
            testid="inventory-cusomter-search-bar"
            className={classes.searchBar}
            inputClassName={classes.input}
            value={searchQuery}
            setValue={setSearchQuery}
          />
        </IonRow>
      </Header>
      <IonContent className={classes.contentWrapper}>
        <Refresher
          slot="fixed"
          onRefresh={refetch}
          hidden
          testid="start-find-customer-refresher"
          disabled={showLoader || isShowChangeLocation}
          lastUpdatedAt={lastUpdatedAt}
        />
        {isOnline && !isShowChangeLocation && (
          <>
            <div className={classes.contentTitle}>
              <Text text={contentTitle} variant="mipro-h3-headline" />
            </div>
            <div>
              {map(findStartCustomersList, (customerItem, index) => (
                <ListItem
                  testid="start-find-customer-item"
                  href={concatRoutes(
                    inventoryURL(),
                    shippingCustomersURL(
                      customerItem.customerNo,
                      customerItem.miLoc
                    )
                  )}
                  title={customerItem.customerName}
                  secondaryTextArray={[
                    { text: `${customerItem.miLoc}${customerItem.customerNo}` },
                  ]}
                  subTitle={customerItem.topShipToName}
                  key={`${customerItem.locationName}-${index}`}
                />
              ))}
            </div>
          </>
        )}
        {isEmptyResponse && isOnline && (
          <WarningMessage
            icon={['far', 'info-circle']}
            title={t('inventory:noStartCustomerTitle')}
            body={t('inventory:noStartCustomerMsg', {
              loc,
            })}
            className={classes.warngMessage}
          />
        )}
        {!isOnline && (
          <WarningMessage
            icon={['far', 'info-circle']}
            title={t('inventory:offlineMsg')}
            className={classes.warngMessageWithMaringToTop}
          />
        )}
        {isShowChangeLocation && isOnline && (
          <ChangeLocation
            fromInventory
            onlineMsg={t('inventory:changeLocationMsg')}
            testid="inventory"
          />
        )}
        <Loader
          isOpen={
            showLoader &&
            !isShowChangeLocation &&
            isEmpty(findStartCustomersList)
          }
          text={t('inventory:loadingCustomers')}
          className={classes.loader}
        />
        <InfiniteScroll
          onLoadMore={fetchNextPage}
          testid="start-customer-infinite-scroll"
          disabled={!enableInfiniteScroll}
        />
      </IonContent>
    </IonPage>
  );
};

export default FindStartCustomer;
