import React from 'react';
import type { Dictionary } from 'lodash';
import { includes, map, values } from 'lodash';
import { IonCol, IonRow } from '@ionic/react';
import Button from 'components/Button/Button';
import classes from './DocumentPartsList.module.scss';
import PartsListPart from './PartsListPart/PartsListPart';

export interface PartsListItemInput {
  attributeName: string;
  displayName: string;
  value: string | number;
  hidden: boolean;
  type?: 'input' | 'textArea';
}

export interface PartsListItem {
  partId: PartsListItemInput;
  itemIndex: PartsListItemInput;
  reportId: PartsListItemInput;
  sectionId: PartsListItemInput;
  quantity: PartsListItemInput;
  description: PartsListItemInput;
  mfrCtlNo: PartsListItemInput;
  groupSerial: PartsListItemInput;
  itemNo: PartsListItemInput;
  custStockNo: PartsListItemInput;
  mfgPartNo: PartsListItemInput;
}

export interface DocumentPartsListProps {
  partsListData: Dictionary<PartsListItem>;
  needSyncPartsList?: number[];
  disabled?: boolean;
  updatePartValue: (
    partIndex: number,
    attributeName: string,
    value: string
  ) => void;
  addNewPart: () => void;
  deletePart: (partIndex: number) => void;
}

const DocumentPartsList = ({
  partsListData,
  needSyncPartsList,
  disabled,
  updatePartValue,
  addNewPart,
  deletePart,
}: DocumentPartsListProps): JSX.Element => {
  const partsListItems: PartsListItem[] = values(partsListData);

  const isPartListValid: () => boolean = () => {
    if (partsListItems && partsListItems.length > 0) {
      const lastIndex = partsListItems.length - 1;
      if (
        partsListItems[lastIndex].description.value &&
        partsListItems[lastIndex].description.value.toString().length > 0
      ) {
        return true;
      }
    }
    return false;
  };

  return (
    <div className={classes.DocumentPartsList}>
      {map(partsListItems, (part: PartsListItem, itemIndex) => (
        <div key={`item-${itemIndex}`}>
          <PartsListPart
            part={part}
            needSync={includes(needSyncPartsList, part.partId.value)}
            disabled={disabled}
            onUpdatePart={updatePartValue}
            onDeletePart={deletePart}
            isFirstPart={itemIndex === 0}
          />
        </div>
      ))}
      <IonRow className={classes.buttonRow}>
        <IonCol size="auto">
          <Button
            className={classes.partsButton}
            variant="action"
            testid="Parts-List-Button"
            text="Add Part"
            disabled={disabled || !isPartListValid()}
            onClick={addNewPart}
          />
        </IonCol>
      </IonRow>
    </div>
  );
};

export default DocumentPartsList;
