import React, { useState } from 'react';
import { toString } from 'lodash';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import useFindWorkTasks from 'DocumentsApp/api/useFindWorkTasks';
import FastFind from 'DocumentsApp/components/FastFind/FastFind';
import type { WorkTask } from 'DocumentsApp/models/WorkTask';
import { useDebounce } from 'use-debounce';
import type Input from 'components/Input/Input';

interface WorkTasksFastFindProps {
  workTask?: WorkTask;
  modalTitle?: string;
  setWorkTask?: (i: WorkTask) => void;
}

const WorkTasksFastFind = ({
  label,
  modalTitle,
  value,
  required,
  disabled,
  setValue,
  workTask,
  setWorkTask,
  testid,
}: WorkTasksFastFindProps &
  React.ComponentProps<typeof Input> &
  IonicReactProps): JSX.Element => {
  const [searchQuery, setSearchQuery] = useState(toString(value));
  const [searchQueryValue] = useDebounce(searchQuery, 300);

  const {
    workTasks,
    showLoader,
    isEmptyResponse,
    enableInfiniteScroll,
    fetchNextPage,
  } = useFindWorkTasks({ query: searchQueryValue });

  return (
    <FastFind<WorkTask>
      items={workTasks}
      getId={(i) => i.codeValue}
      getTitle={(i) => `(${i.codeValue}) ${i.codeDesc}`}
      getSelectedText={(i) => `(${i.codeValue}) ${i.codeDesc}`}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      showLoader={showLoader}
      isEmptyResponse={isEmptyResponse}
      enableInfiniteScroll={enableInfiniteScroll}
      fetchNextPage={fetchNextPage}
      label={label}
      modalTitle={modalTitle}
      value={value}
      required={required}
      disabled={disabled}
      setValue={setValue}
      selectedItem={workTask}
      setSelectedItem={setWorkTask}
      testid={testid}
    />
  );
};

export default WorkTasksFastFind;
