import { useSelector } from 'react-redux';
import type { AxiosError } from 'axios';
import { find, isEmpty, isNil } from 'lodash';
import { useIonViewDidEnter } from '@ionic/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import {
  doPromiseAPI,
  getPlaceholderData,
  useKeyUserId,
  useMiLocOrTeamId,
} from 'api/helpers';
import type { QueryFlags, SearchItem, SearchItemType } from 'models/Search';
import type { Supplier } from 'models/Supplier';
import type { RootState } from 'store/reducers';
import { findFavoriteSuppliersQueryKey } from './useFindFavoriteSuppliers';
import { findSuppliersQueryKey } from './useFindSuppliers';

export const getSupplierQueryKey = 'supplier';

interface UseGetSupplierProps {
  searchType: SearchItemType;
  id: string;
  invalidateQuery?: boolean;
  enabled?: boolean;
}

interface UseGetSupplierResponse {
  data?: Supplier;
}

const useGetSupplier = ({
  searchType,
  id,
  invalidateQuery = false,
  enabled = true,
}: UseGetSupplierProps): UseGetSupplierResponse & QueryFlags => {
  const { axios } = useAxios();
  const { getSupplierAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { createQueryKey } = useKeyUserId();
  const { recentlyViewed } = useSelector((state: RootState) => state.user);
  const { createParams } = useMiLocOrTeamId({ sendTeamId: false });
  const params = { ...createParams() };
  const { miLoc } = params;

  const doGetSupplier = () => {
    return doPromiseAPI<Supplier>(async (cancelToken) => {
      const { data } = await axios.get<Supplier>(getSupplierAPI(miLoc, id), {
        cancelToken,
      });
      return data;
    });
  };

  const enableCall = searchType === 'supplier' && !isEmpty(id) && enabled;

  const { data, status, error, isFetching, refetch } = useQuery<
    Supplier,
    AxiosError
  >(createQueryKey(getSupplierQueryKey, { id }), doGetSupplier, {
    enabled: enableCall,
    placeholderData: () => {
      // look for supplier in favorites
      const favoriteSupplier = getPlaceholderData<SearchItem>({
        queryClient,
        queryKey: findFavoriteSuppliersQueryKey,
        objectKey: 'items',
        findPredicate: { id },
      });
      if (!isNil(favoriteSupplier)) {
        return {
          supLocNo: id,
          supLocName: favoriteSupplier.text,
          bookmarked: 'Y',
        };
      }
      // look for supplier in search results
      const searchSupplier = getPlaceholderData<SearchItem>({
        queryClient,
        queryKey: findSuppliersQueryKey,
        objectKey: 'items',
        findPredicate: { id },
      });
      if (!isNil(searchSupplier)) {
        return {
          supLocNo: id,
          supLocName: searchSupplier.text,
          address: searchSupplier.address,
        };
      }
      // look for supplier in recently viewed
      const recentSupplier = find(recentlyViewed, { type: 'supplier', id });
      if (!isNil(recentSupplier)) {
        return {
          supLocNo: id,
          supLocName: recentSupplier.text,
        };
      }
      // no supplier found in query cache
      return undefined;
    },
  });

  useIonViewDidEnter(() => {
    if (invalidateQuery && enableCall) {
      void refetch();
    }
  });

  return {
    data,
    error,
    isLoading: status === 'loading' || (isFetching && isNil(data?.address)),
  };
};

export default useGetSupplier;
