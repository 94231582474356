import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { toNumber, toString, toUpper } from 'lodash';
import { IonCol, IonRow } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { useFormik } from 'formik';
import useVerifyAndSaveManualItem from 'InventoryApp/api/useVerifyAndSaveManualItem';
import type {
  CountGroup,
  CountGroupItem,
} from 'InventoryApp/models/InventoryPlanGroup';
import { isPiBelting } from 'InventoryApp/util/inventoryUtil';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import { useToasts } from 'providers/ToastProvider';
import * as yup from 'yup';
import { ToastType } from 'models/Toast';
import type { RootState } from 'store/reducers';
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import Modal from 'components/Modal/Modal';
import DiscardModal from 'components/Modals/DiscardModal/DiscardModal';
import Text from 'components/Text/Text';
import classes from './AddItemModal.module.scss';

interface AddItemModalProps {
  testid: string;
  countGroup?: CountGroup;
}

export interface AddItemFormData {
  mfrCtlNo?: string;
  groupSerialNo?: string;
  itemNo?: string;
  lotNo?: string;
  binLoc?: string;
  description?: string;
  actualItemLength?: string;
  actualItemWidth?: string;
  count?: string;
}

const AddItemModal = ({
  isOpen,
  testid,
  setIsOpen,
  countGroup,
}: AddItemModalProps &
  React.ComponentProps<typeof Modal> &
  IonicReactProps): JSX.Element => {
  const { t } = useTranslation();
  const { isOnline } = useNetworkStatus();
  const { miLoc = '' } = useSelector((state: RootState) => state.user);
  const [discardModalIsOpen, setDiscardModalIsOpen] = useState(false);
  const [addAnotherItem, setAddAnotherItem] = useState<boolean>(false);
  const { addToast } = useToasts();
  const { onSubmitVerifyManualItem, status, data } =
    useVerifyAndSaveManualItem();
  const MAX_QUANTITY = 999999;
  const MAX_WIDTH = 144;
  const MAX_LENGTH = 2000;
  const mfrCtlNoPattern = new RegExp(/\b\d{1,5}\b/g);
  const groupSerialNoPattern = new RegExp(/^[A-Y0-9]{5}[0-9]\b/g);
  const lotNoPattern = new RegExp(/\b\d{1,8}\b/g);
  const patternTwoDigitsAfterDecimal = /^\d+(\.\d{0,2})?$/;
  const isPIBelting = isPiBelting(countGroup);

  let validationSchema = yup.object().shape({
    mfrCtlNo: yup
      .string()
      .required(t('inventory:mfrCtlNoRequired'))
      .length(5, t('inventory:mfrCtlNoInValid'))
      .test('mfrCtlValidation', t('inventory:mfrCtlNoInValid'), (val) => {
        if (val !== undefined) {
          return mfrCtlNoPattern.test(val.toString());
        }
        return true;
      }),
    groupSerialNo: yup
      .string()
      .required(t('inventory:groupSerialRequired'))
      .length(6, t('inventory:groupSerialNoInvalid'))
      .test(
        'groupSerialNoValidation',
        t('inventory:groupSerialNoInvalid'),
        (val) => {
          if (val !== undefined) {
            return groupSerialNoPattern.test(val.toString());
          }
          return true;
        }
      ),
    binLoc: yup.string(),
    lotNo: yup
      .string()
      .test('lotNoValidation', t('inventory:lotNoInvalid'), (val) => {
        if (val !== undefined) {
          return lotNoPattern.test(val.toString());
        }
        return true;
      }),
    description: yup.string(),
    count: yup
      .number()
      .typeError(
        t('inventory:fieldRealNumber', { field: t('common:quantity') })
      )
      .integer(
        t('inventory:fieldRealNumber', { field: t('inventory:quantity') })
      )
      .min(0, t('inventory:fieldMinNumber', { field: t('inventory:quantity') }))
      .test(
        'is-quantity-min',
        t('inventory:fieldMinNumber', { field: t('inventory:quantity') }),
        (val) => {
          if (val !== undefined) {
            return val !== 0;
          }
          return true;
        }
      )
      .max(MAX_QUANTITY, t('inventory:fieldMaxNumber', { max: MAX_QUANTITY }))
      .required(
        t('inventory:fieldRequired', { field: t('inventory:quantity') })
      )
      .test(
        'is-decimal',
        t('inventory:fieldDecimalMaxNumber', {
          field: t('inventory:quantity'),
          max: 2,
        }),
        (val) => {
          if (val !== undefined) {
            return patternTwoDigitsAfterDecimal.test(val.toString());
          }
          return true;
        }
      ),
  });

  if (isPIBelting) {
    validationSchema = validationSchema.shape({
      lotNo: yup
        .string()
        .required(t('inventory:slabNoRequired'))
        .test('slabNoValidation', t('inventory:slabNoInvalid'), (val) => {
          if (val !== undefined) {
            return lotNoPattern.test(val.toString());
          }
          return true;
        }),
      count: yup
        .number()
        .typeError(
          t('inventory:fieldRealNumber', { field: t('inventory:quantity') })
        )
        .min(
          0,
          t('inventory:fieldMinNumber', { field: t('inventory:quantity') })
        )
        .max(
          MAX_QUANTITY,
          t('inventory:fieldMaxNumber', {
            max: MAX_QUANTITY,
            field: t('inventory:quantity'),
          })
        )
        .required(
          t('inventory:fieldRequired', { field: t('inventory:quantity') })
        ),
      actualItemLength: yup
        .number()
        .typeError(
          t('inventory:fieldRealNumber', { field: t('inventory:length') })
        )
        .min(0, t('inventory:fieldMinNumber', { field: t('inventory:length') }))
        .test(
          'is-length-min',
          t('inventory:fieldMinNumber', { field: t('inventory:length') }),
          (val) => {
            if (val !== undefined) {
              return val !== 0;
            }
            return true;
          }
        )
        .max(
          MAX_LENGTH,
          t('inventory:fieldMaxNumber', {
            field: t('inventory:length'),
            max: MAX_LENGTH,
          })
        )
        .test(
          'is-decimal',
          t('inventory:fieldDecimalMaxNumber', {
            field: t('inventory:length'),
            max: 2,
          }),
          (val) => {
            if (val !== undefined) {
              return patternTwoDigitsAfterDecimal.test(val.toString());
            }
            return true;
          }
        )
        .required(
          t('inventory:fieldRequired', { field: t('inventory:length') })
        ),
      actualItemWidth: yup
        .number()
        .typeError(
          t('inventory:fieldRealNumber', { field: t('inventory:width') })
        )
        .min(0, t('inventory:fieldMinNumber', { field: t('inventory:width') }))
        .test(
          'is-width-min',
          t('inventory:fieldMinNumber', { field: t('inventory:width') }),
          (val) => {
            if (val !== undefined) {
              return val !== 0;
            }
            return true;
          }
        )
        .max(
          MAX_WIDTH,
          t('inventory:fieldMaxNumber', {
            field: t('inventory:width'),
            max: MAX_WIDTH,
          })
        )
        .test(
          'is-decimal',
          t('inventory:fieldDecimalMaxNumber', {
            field: t('inventory:width'),
            max: 2,
          }),
          (val) => {
            if (val !== undefined) {
              return patternTwoDigitsAfterDecimal.test(val.toString());
            }
            return true;
          }
        )
        .required(
          t('inventory:fieldRequired', { field: t('inventory:width') })
        ),
    });
  }

  const transformAndAddItem = (formValues: AddItemFormData): CountGroupItem => {
    const { actualItemLength, actualItemWidth, lotNo, count, ...rest } =
      formValues;
    let remainingFormValues: AddItemFormData = isPIBelting ? formValues : rest;
    if (!isPIBelting && lotNo) {
      remainingFormValues = { ...rest, lotNo };
    }
    const {
      countPlanId = '',
      groupId = '',
      storeroomNo = '',
    } = countGroup || {};
    return {
      uniqueId: `${countPlanId}-${groupId}-${miLoc}-${storeroomNo}-${Date.now()}`,
      countPlanId: countGroup?.countPlanId || '',
      groupId: countGroup?.uniqueId || '',
      hasCount: true,
      miLoc,
      type: countGroup?.countType || '',
      ...remainingFormValues,
      actualCount: count,
      bin: remainingFormValues.binLoc,
    } as unknown as CountGroupItem;
  };

  const {
    values,
    errors,
    isValid,
    isSubmitting,
    dirty,
    handleSubmit,
    setFieldValue,
    setSubmitting,
    validateField,
    setErrors,
    setFieldError,
    resetForm,
  } = useFormik<AddItemFormData>({
    initialValues: {
      mfrCtlNo: '',
      groupSerialNo: '',
      count: '',
      description: '',
      actualItemLength: '',
      actualItemWidth: '',
      binLoc: '',
      lotNo: '',
    },
    onSubmit: (formValues) => {
      setSubmitting(false);
      onSubmitVerifyManualItem(transformAndAddItem(formValues));
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: true,
  });

  useEffect(() => {
    if (isOpen && status === 'success') {
      if (data?.Success) {
        if (!addAnotherItem) {
          setIsOpen?.(false);
        } else {
          resetForm();
        }
      }
    }
  }, [status, data, setIsOpen, addAnotherItem, isOpen, resetForm]);

  useEffect(() => {
    if (isOpen) {
      resetForm();
    }
  }, [isOpen, resetForm]);

  useEffect(() => {
    if (isPIBelting && values.actualItemLength && values.actualItemWidth) {
      const quantity =
        toNumber(values.actualItemLength) * toNumber(values.actualItemWidth);

      let count = toString(quantity);
      const decimalIndex = count.indexOf('.');
      if (decimalIndex !== -1) {
        count = count.substring(0, decimalIndex + 3);
      }
      void setFieldValue('count', count);
    }
  }, [
    isPIBelting,
    setErrors,
    setFieldError,
    setFieldValue,
    t,
    values.actualItemLength,
    values.actualItemWidth,
  ]);

  const validForm =
    isValid &&
    values.mfrCtlNo !== '' &&
    values.groupSerialNo !== '' &&
    values.count !== '' &&
    ((isPIBelting &&
      values.lotNo !== '' &&
      values.actualItemLength !== '' &&
      values.actualItemWidth !== '') ||
      !isPIBelting);

  const handleAddItem = (open = false) => {
    if (!isOnline) {
      addToast({
        type: ToastType.alert,
        text: t('inventory:offlineManualItem'),
        testid: 'offline-add-item-toast',
      });
    } else {
      handleSubmit();
      setAddAnotherItem(open);
    }
  };

  return (
    <Modal
      className={classes.modal}
      title={t('inventory:addItem')}
      headerClassName={classes.modalHeader}
      withTitleLine={false}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      forceFullHeight
      testid={testid}
      withCloseButton
      closeButtonClick={() => {
        if (dirty) {
          setDiscardModalIsOpen(true);
        } else {
          setIsOpen?.(false);
        }
      }}
      canDismiss={!dirty}
    >
      <IonRow className={classes.minoRow}>
        <Text
          className={classes.text}
          variant="mipro-report-info"
          text={t('inventory:mino', {
            mino:
              `${values.mfrCtlNo || ''}${values.groupSerialNo || ''}` || '--',
          })}
        />
      </IonRow>

      <form noValidate data-testid={`${testid}-form`}>
        <Input
          className={classNames(classes.formInput, classes.firstInput)}
          inputRowClassName={classes.inputItem}
          inputmode="numeric"
          type="text"
          placeholder={t('inventory:mfrCtlNoPlaceholder')}
          autocomplete="off"
          // mask={new RegExp(/\b\d{1,5}\b/g)}
          max={MAX_QUANTITY}
          label={t('inventory:mfrCtlNo')}
          name="mfrCtlNo"
          minlength={5}
          maxlength={5}
          value={values.mfrCtlNo}
          error={errors.mfrCtlNo}
          setValue={(e) => {
            void setFieldValue('mfrCtlNo', toUpper(e));
            setTimeout(() => {
              void validateField('mfrCtlNo');
            }, 100);
          }}
          disabled={isSubmitting}
          testid={`${testid}-mfrCtlNo-input`}
          required
        />
        <Input
          className={classes.formInput}
          inputRowClassName={classes.inputItem}
          inputmode="text"
          type="text"
          placeholder="Group Serial #"
          autocomplete="off"
          // mask={new RegExp(/^[A-Y|a-y]{1}\d{0,5}\b/g)}
          label={t('inventory:groupSerial')}
          name="groupSerial"
          maxlength={6}
          value={values.groupSerialNo}
          error={errors.groupSerialNo}
          setValue={(e) => {
            void setFieldValue('groupSerialNo', toUpper(e));
            setTimeout(() => {
              void validateField('groupSerialNo');
            }, 100);
          }}
          disabled={isSubmitting}
          testid={`${testid}-group-serial-input`}
          required
        />
        <Input
          className={classes.formInput}
          inputRowClassName={classes.inputItem}
          inputmode="numeric"
          type="text"
          placeholder={
            isPIBelting ? t('inventory:slabNoTitle') : t('inventory:lotNoTitle')
          }
          // mask={new RegExp(/\b\d{1,8}\b/g)}
          autocomplete="off"
          min={0}
          label={
            isPIBelting ? t('inventory:slabNoTitle') : t('inventory:lotNoTitle')
          }
          name="lotNo"
          maxlength={8}
          value={values.lotNo}
          error={errors.lotNo}
          setValue={(e) => {
            void setFieldValue('lotNo', toUpper(e));
            setTimeout(() => {
              void validateField('lotNo');
            }, 100);
          }}
          disabled={isSubmitting}
          testid={`${testid}-lotNo-input`}
          required={isPIBelting}
        />
        <Input
          className={classes.formInput}
          inputRowClassName={classes.inputItem}
          inputmode="text"
          type="text"
          placeholder={t('inventory:binLoc')}
          autocomplete="off"
          label={t('inventory:binLoc')}
          name="binLoc"
          maxlength={10}
          value={values.binLoc}
          error={errors.binLoc}
          setValue={(e) => {
            void setFieldValue('binLoc', toUpper(e));
            setTimeout(() => {
              void validateField('binLoc');
            }, 100);
          }}
          disabled={isSubmitting}
          testid={`${testid}-binLoc-input`}
        />
        <Input
          className={classes.formInput}
          inputRowClassName={classes.inputItem}
          inputmode="text"
          type="text"
          placeholder={t('common:description')}
          autocomplete="off"
          min={0}
          label={t('inventory:itemDesc')}
          name="description"
          maxlength={40}
          value={values.description}
          error={errors.description}
          setValue={(e) => {
            void setFieldValue('description', toUpper(e));
            setTimeout(() => {
              void validateField('description');
            }, 100);
          }}
          disabled={isSubmitting}
          testid={`${testid}-description-input`}
        />
        {isPIBelting ? (
          <>
            <IonRow className={classes.quantityRow}>
              <IonCol>
                <Input
                  className={classNames(classes.formInput, classes.lengthInput)}
                  inputRowClassName={classes.inputItem}
                  inputmode="numeric"
                  type="text"
                  placeholder={t('inventory:width')}
                  autocomplete="off"
                  min={0}
                  // mask={new RegExp(/^(\d{1,3}|\d{0,3}\.\d{1,2})\b/g)}
                  label={t('inventory:width')}
                  name="width"
                  maxlength={6}
                  value={values.actualItemWidth}
                  error={errors.actualItemWidth}
                  setValue={(e) => {
                    void setFieldValue('actualItemWidth', toUpper(e));
                    setTimeout(() => {
                      void validateField('actualItemWidth');
                    }, 100);
                  }}
                  disabled={isSubmitting}
                  testid={`${testid}-width-input`}
                  required
                />
              </IonCol>
              <IonCol>
                <Input
                  className={classNames(classes.formInput, classes.lengthInput)}
                  inputRowClassName={classes.inputItem}
                  inputmode="numeric"
                  type="text"
                  placeholder={t('inventory:length')}
                  autocomplete="off"
                  min={0}
                  // mask={new RegExp(/^(\d{1,4}|\d{1,4}\.\d{1,2})\b/g)}
                  label={t('inventory:length')}
                  name="length"
                  maxlength={7}
                  value={values.actualItemLength}
                  error={errors.actualItemLength}
                  setValue={(e) => {
                    void setFieldValue('actualItemLength', toUpper(e));
                    setTimeout(() => {
                      void validateField('actualItemLength');
                    }, 100);
                  }}
                  disabled={isSubmitting}
                  testid={`${testid}-length-input`}
                  required
                />
              </IonCol>
              <IonCol>
                <Input
                  className={classNames(classes.formInput, classes.lengthInput)}
                  inputRowClassName={classes.inputItem}
                  inputmode="numeric"
                  type="text"
                  placeholder={t('common:quantity')}
                  autocomplete="off"
                  min={0}
                  // mask={new RegExp(/^(\d{1,5}|\d{0,5}\.\d{1,2})\b/g)}
                  label={t('common:quantity')}
                  name="count"
                  value={values.count}
                  error={errors.count}
                  disabled={isSubmitting || isPIBelting}
                  testid={`${testid}-quantity-readonly-input`}
                />
              </IonCol>
            </IonRow>

            {errors.actualItemWidth && (
              <IonRow className={classes.errorWrapper}>
                <Text
                  variant="content-small"
                  className={classes.errMsg}
                  text={toString(errors.actualItemWidth)}
                />
              </IonRow>
            )}
            {errors.actualItemLength &&
              errors.actualItemLength !== errors.actualItemWidth && (
                <IonRow className={classes.errorWrapper}>
                  <Text
                    variant="content-small"
                    className={classes.errMsg}
                    text={toString(errors.actualItemLength)}
                  />
                </IonRow>
              )}
            {errors.count && (
              <IonRow className={classes.errorWrapper}>
                <Text
                  variant="content-small"
                  className={classes.errMsg}
                  text={errors.count}
                />
              </IonRow>
            )}
          </>
        ) : (
          <Input
            className={classes.formInput}
            inputRowClassName={classes.inputItem}
            inputmode="numeric"
            type="text"
            placeholder={t('common:quantity')}
            autocomplete="off"
            min={0}
            // mask={new RegExp(/^(\d{1,5}|\d{0,5}\.\d{1,2})\b/g)}
            label={t('common:quantity')}
            name="count"
            value={values.count}
            error={errors.count}
            setValue={(e) => {
              void setFieldValue('count', toUpper(e));
              setTimeout(() => {
                void validateField('count');
              }, 100);
            }}
            disabled={isSubmitting}
            testid={`${testid}-quantity-input`}
            required
          />
        )}

        <IonRow className={classes.buttonRow}>
          <div className={classes.anotherButtonWrapper}>
            <Button
              className={classNames(classes.addItemButton)}
              variant="secondary"
              onClick={() => handleAddItem(true)}
              text={t('inventory:addAnotherItem')}
              disabled={!validForm || isSubmitting}
              testid={`${testid}-addAnotherItem-button`}
            />
          </div>
          <div className={classes.addButtonWrapper}>
            <Button
              className={classes.addItemButton}
              variant="action"
              onClick={() => {
                handleAddItem(false);
              }}
              text={t('inventory:addItem')}
              disabled={!validForm || isSubmitting}
              testid={`${testid}-addItem-button`}
            />
          </div>
        </IonRow>
      </form>

      <DiscardModal
        title={t('inventory:closeOrderModal')}
        className={classes.discardModal}
        isOpen={discardModalIsOpen}
        setIsOpen={setDiscardModalIsOpen}
        initialBreakpoint={0.4}
        backdropDismiss={false}
        withRightCloseButton
        testid="discard-changes-modal"
        discardMsg={t('common:discardMsg')}
        goBackButtonTitle={t('inventory:goBack')}
        discardButtonTitle={t('inventory:secondaryConfirm')}
        onDiscardClick={() => setIsOpen?.(false)}
      />
    </Modal>
  );
};

export default AddItemModal;
