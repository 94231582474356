import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { map } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonCol, IonContent, IonRow } from '@ionic/react';
import { useDebounce } from 'use-debounce';
import useFindManufacturers from 'api/inspectionReports/useFindManufacturers';
import type { Manufacturer } from 'models/InspectionReport';
import { findIcon } from 'utils/icons';
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll';
import Input from 'components/Input/Input';
import Loader from 'components/Loader/Loader';
import Text from 'components/Text/Text';
import classes from './ManufacturerFastFind.module.scss';

export interface ManufacturerFastFindProps {
  input: string;
  setInput: (v: string) => void;
  selectedManufacturer?: Manufacturer;
  setSelectedManufacturer: (manufacturer?: Manufacturer) => void;
  disabled?: boolean;
}

// TODO: single component for fastFinds instead of one per input
const ManufacturerFastFind = ({
  input,
  setInput,
  selectedManufacturer,
  setSelectedManufacturer,
  disabled,
}: ManufacturerFastFindProps): JSX.Element => {
  const [queryString, setQueryString] = useState('');
  const [query] = useDebounce(queryString, 300);
  const findManufacturers = useFindManufacturers({ query });

  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (input.length >= 3) {
      setQueryString(input);
    }
  }, [input]);

  const showValues: () => boolean = () => {
    if (
      query.length > 0 &&
      input.length > 0 &&
      isFocused &&
      findManufacturers.items
    ) {
      return true;
    }
    return false;
  };

  return (
    <div
      className={classNames(classes.manufacturerFastFind, {
        [classes.boxFocus]: showValues(),
      })}
      data-testid="manufacturerFastFind"
    >
      <IonRow>
        <IonCol className={classes.inputCol} size="12">
          <Input
            className={classes.fastFindInput}
            value={input}
            testid="containerInput"
            disabled={disabled}
            setValue={(v: string) => {
              setInput(v);
            }}
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={() => {
              // onBlur fires before onClick below
              // making it impossible to click the child
              setTimeout(() => {
                setIsFocused(false);
              }, 150);
            }}
          />
          {(selectedManufacturer || input.length > 0) && !disabled && (
            <FontAwesomeIcon
              onClick={() => {
                setInput('');
                setSelectedManufacturer(undefined);
              }}
              className={classes.clearIcon}
              icon={findIcon('times')}
            />
          )}
        </IonCol>
        <IonCol className={classes.p0} size="12">
          {showValues() && (
            <IonContent
              className={classNames(classes.ionContent, {
                [classes.setHeight]: findManufacturers.items.length > 1,
              })}
            >
              <div className={classes.resultWrap}>
                {map(
                  findManufacturers.items,
                  (manufacturer: Manufacturer, i) => (
                    <div
                      onClick={() => setSelectedManufacturer(manufacturer)}
                      onKeyUp={() => {}}
                      role="button"
                      tabIndex={i}
                      key={`${i}`}
                      className={classes.manufacturer}
                    >
                      <IonRow className={classes.alignCenter}>
                        <IonCol>
                          <Text
                            text={manufacturer.mfrName}
                            variant="content-default"
                          />
                        </IonCol>
                        <IonCol size="auto">
                          <Text
                            text={manufacturer.mfrCtlNo}
                            variant="content-default"
                          />
                        </IonCol>
                      </IonRow>
                    </div>
                  )
                )}
              </div>
              <div className={classes.loaderWrap}>
                <Loader
                  className={classes.loader}
                  text="Loading results"
                  isOpen={findManufacturers.showLoader}
                />
                {findManufacturers.noMoreData && (
                  <div className={classes.emptyReponse}>
                    <Text
                      className={classes.titleWrap}
                      text="No more manufacturers!"
                      variant="content-heavy"
                    />
                  </div>
                )}
                {findManufacturers.isEmptyResponse && (
                  <div className={classes.emptyReponse}>
                    <Text
                      className={classes.titleWrap}
                      text="No manufacturers found!"
                      variant="content-heavy"
                    />
                  </div>
                )}
              </div>
              <InfiniteScroll
                threshold="600px"
                disabled={!findManufacturers.enableInfiniteScroll}
                onLoadMore={async () => {
                  const promises = [];
                  if (findManufacturers.enableInfiniteScroll) {
                    promises.push(findManufacturers.fetchNextPage?.call(null));
                  }
                  await Promise.all(promises);
                }}
                testid="infinite-scroll"
              />
            </IonContent>
          )}
        </IonCol>
      </IonRow>
    </div>
  );
};

export default ManufacturerFastFind;
