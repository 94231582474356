import { isNil } from 'lodash';

export enum FeatureFlagType {
  customizeActivityImportance,
  gaLogging,
  workOrders,
  removeDateFilter,
  includeDailyTotals,
  showPick12OnReportsTab,
  newReports,
  changePin,
  quoting,
  demoMode,
}

const featureFlags = (featureFlag: FeatureFlagType) => {
  switch (featureFlag) {
    case FeatureFlagType.customizeActivityImportance:
      return process.env.REACT_APP_CUSTOMIZE_ACTIVITY_IMPORTANCE === 'true';
    case FeatureFlagType.gaLogging:
      return process.env.REACT_APP_GA_LOGGING === 'true';
    case FeatureFlagType.workOrders:
      return process.env.REACT_APP_WORK_ORDERS === 'true';
    case FeatureFlagType.removeDateFilter:
      return process.env.REACT_APP_SHOW_ALL_ACTIVITIES === 'true';
    case FeatureFlagType.includeDailyTotals:
      return process.env.REACT_APP_FEATURE_INCLUDE_DAILY_TOTALS === 'true';
    case FeatureFlagType.changePin:
      return process.env.REACT_APP_FEATURE_CHANGE_PIN === 'true';
    case FeatureFlagType.demoMode:
      return process.env.REACT_APP_FEATURE_DEMO_MODE === 'true';
    default:
      return false;
  }
};

const useFeatureFlags = (featureFlag?: FeatureFlagType): boolean => {
  // if there is an featureFlag flag, it verifies an active value for it
  // if not it defaults to false (the user does not has access by default)
  if (!isNil(featureFlag)) {
    return featureFlags(featureFlag);
  }
  return false;
};

export default useFeatureFlags;
