import React from 'react';
import { useTranslation } from 'react-i18next';
import type { AxiosError } from 'axios';
import { isNil } from 'lodash';
import { subYears } from 'date-fns';
import type { DateSegmentType, SummaryItemOutput } from 'models/Reports';
import { formatDate } from 'utils/date';
import { getErrorMessage } from 'utils/helpers';
import {
  barDailyChartColors,
  useGetChartHeaderTabs,
} from 'components/Charts/helpers/utils';
import ChartHeader from 'components/Charts/ReportHeader/ChartHeader';
import ReportHeader from 'components/Charts/ReportHeader/ReportHeader';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import type { BarColumnData, DailyChartData } from './Chart';
import Chart from './Chart';
import classes from './DailyChart.module.scss';
import 'components/Charts/helpers/NivoChartOverrides.scss';

interface BarChartProps {
  isLoading?: boolean;
  error?: AxiosError | null;
  salesData?: BarColumnData;
  lastYearSalesData?: BarColumnData;
  profitData?: BarColumnData;
  lastYearProfitData?: BarColumnData;
  requestType: DateSegmentType;
  headerData: SummaryItemOutput[];
  displayChange?: boolean;
  selectedDate: Date;
  tabValue: string;
  setTabValue: (s: string) => void;
  testid: string;
  currencyType?: string;
}

const DailyChart = ({
  isLoading,
  error,
  salesData,
  lastYearSalesData,
  profitData,
  lastYearProfitData,
  requestType,
  headerData,
  displayChange = true,
  selectedDate,
  tabValue,
  setTabValue,
  testid,
  currencyType,
}: BarChartProps): JSX.Element => {
  const { t, i18n } = useTranslation();
  const prevData =
    tabValue === 'sales' ? lastYearSalesData : lastYearProfitData;

  const barData: DailyChartData[] = [];

  if (tabValue === 'sales') {
    if (lastYearSalesData) {
      barData[0] = {
        id: `prev-${tabValue}`,
        color: barDailyChartColors[`prev-${tabValue}`],
        ...lastYearSalesData,
      };
    }
    if (salesData) {
      barData[1] = {
        id: tabValue,
        color: barDailyChartColors[tabValue],
        ...salesData,
      };
    }
  } else {
    if (lastYearProfitData) {
      barData[0] = {
        id: `prev-${tabValue}`,
        color: barDailyChartColors[`prev-${tabValue}`],
        ...lastYearProfitData,
      };
    }
    if (profitData) {
      barData[1] = {
        id: tabValue,
        color: barDailyChartColors[tabValue],
        ...profitData,
      };
    }
  }

  return (
    <div className={classes.barChart} data-testid={testid}>
      <ReportHeader
        isLoading={isLoading}
        requestType={requestType}
        headerData={headerData}
        displayChange={displayChange}
        testid={testid}
        currencyType={currencyType}
      />
      <div className={classes.chartWrapper}>
        <ChartHeader
          tabValue={tabValue}
          setTabValue={setTabValue}
          options={useGetChartHeaderTabs()}
          items={[
            {
              text: formatDate(subYears(selectedDate, 1), 'y', i18n.language),
              style: {
                border: `2px solid ${barDailyChartColors[`prev-${tabValue}`]}`,
              },
              show: !isNil(prevData),
            },
            {
              text: formatDate(selectedDate, 'y', i18n.language),
              style: {
                background: barDailyChartColors[tabValue],
              },
              show: true,
            },
          ]}
        />
        {error && (
          <WarningMessage
            className={classes.warningMessage}
            title={t('reports:errorSummary')}
            body={getErrorMessage(error)}
          />
        )}
        {!error && (
          <Chart
            isLoading={isLoading}
            data={barData}
            testid="column-bar-chart"
            currencyType={currencyType}
          />
        )}
      </div>
    </div>
  );
};

export default DailyChart;
