import type React from 'react';
import { toString } from 'lodash';
import { Browser } from '@capacitor/browser';
import type * as H from 'history';
import { concatRoutes } from './navigations';

export const LinkClickHandler = async (
  event: React.SyntheticEvent,
  hrefValue: string,
  history: H.History<unknown>
) => {
  event.preventDefault();

  try {
    if (hrefValue) {
      if (
        hrefValue.startsWith('http://') ||
        hrefValue.startsWith('https://') ||
        hrefValue.startsWith('www.')
      ) {
        await Browser.open({ url: hrefValue });
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        history.push(concatRoutes('/', hrefValue));
      }
    }
    // eslint-disable-next-line no-empty
  } catch (targetError) {}
};

export const BulletinDescriptionClickHandler = (
  event: React.SyntheticEvent,
  // eslint-disable-next-line
  history: H.History<unknown>
) => {
  event.preventDefault();

  // defines the types of elements that support the href attribute
  const allowedElements = ['a'];
  let target = event.target as Element;

  if (!target.hasAttribute('href')) {
    target = target.closest('a[href]') as Element;
  }

  const href = target.hasAttribute('href') && target.getAttribute('href');
  const isAllowedElement = allowedElements.includes(
    target.tagName.toLowerCase()
  );

  if (isAllowedElement && href) {
    const hrefValue = toString(href);
    if (
      hrefValue.startsWith('http://') ||
      hrefValue.startsWith('https://') ||
      hrefValue.startsWith('www.')
    ) {
      const newUrl = new URL(toString(href));
      void Browser.open({ url: newUrl.href });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      history.push(concatRoutes('/', hrefValue));
    }
  }
};
