/* eslint-disable no-console */
import { map, size } from 'lodash';
import type { ChargeBackValue } from 'StoreroomsApp/models/ChargeBackProfile';
import useScannerDB from './initScannerDB';

interface UseChargeBackValueDBResponse {
  createChargeBackvalues: (profiles: ChargeBackValue[]) => Promise<void>;
  findChargeBackValuesByMiLoc: (
    miLoc: string,
    query?: string,
    customerNumber?: string,
    descriptionId?: string
  ) => Promise<ChargeBackValue[]>;
  removeChargeBackValues: (miLoc: string) => Promise<void>;
}

const useChargeBackValueDB = (): UseChargeBackValueDBResponse => {
  const { db, openDB, closeDB } = useScannerDB();

  const createChargeBackvalues = async (
    profiles: ChargeBackValue[]
  ): Promise<void> => {
    try {
      const statements = map(profiles, (item) => ({
        statement: `INSERT OR REPLACE INTO validate_charge_back (
          combinedId,
          miLocation,
          customerNumber,
          descriptionNumber,
          valueId,
          valueDescription,
          chargeBackGLNumber
          ) VALUES (?,?,?,?,?,?,?)`,
        values: [
          item.combinedId,
          item.miLocation,
          item.customerNumber,
          item.descriptionNumber,
          item.valueId,
          item.valueDescription,
          item.chargeBackGLNumber,
        ],
      }));
      await openDB();
      if (size(statements) > 0) {
        await db.executeSet(statements);
      }
    } catch (error) {
      console.log(
        'Error saving validate charge back profiles to database',
        error
      );
      throw new Error('Error saving validate charge back profiles to database');
    } finally {
      await closeDB();
    }
  };

  const findChargeBackValuesByMiLoc = async (
    miLoc: string,
    query?: string,
    customerNumber?: string,
    descriptionId?: string
  ): Promise<ChargeBackValue[]> => {
    try {
      await openDB();
      const vars = [miLoc];
      if (query) {
        vars.push(`%${query}%`);
        vars.push(`%${query}%`);
      }
      if (customerNumber) {
        vars.push(`${customerNumber}%`);
      }
      if (descriptionId) {
        vars.push(descriptionId);
      }
      return (
        await db.query(
          `SELECT * FROM validate_charge_back
          WHERE miLocation = ?
          ${query ? `AND valueDescription LIKE ? OR valueId LIKE ?` : ''}
          ${customerNumber ? `AND customerNumber LIKE ?` : ''}
          ${descriptionId ? `AND descriptionNumber = ?` : ''}
          ORDER BY valueId ASC`,
          vars
        )
      ).values as ChargeBackValue[];
    } catch (error) {
      console.log('Error loading validate charge back profiles', error);
      throw new Error('Error loading validate charge back profiles');
    } finally {
      await closeDB();
    }
  };

  const removeChargeBackValues = async (miLoc: string): Promise<void> => {
    try {
      await openDB();
      await db.query(
        `DELETE FROM validate_charge_back
        WHERE miLocation = ?`,
        [miLoc]
      );
    } catch (error) {
      console.log('Error removing validate charge back profiles', error);
      throw new Error('Error removing validate charge back profiles');
    } finally {
      await closeDB();
    }
  };

  return {
    createChargeBackvalues,
    findChargeBackValuesByMiLoc,
    removeChargeBackValues,
  };
};

export default useChargeBackValueDB;
