import { useEffect, useMemo, useState } from 'react';
import type { AxiosError } from 'axios';
import { size, toString } from 'lodash';
import * as Sentry from '@sentry/capacitor';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import type { QueryParamsType } from 'common/api/utils/useGetQueryFlags';
import useSiteDB from 'DocumentsApp/database/useSiteDB';
import type { FastFindAPIResponse } from 'DocumentsApp/models/FastFind';
import { useAxios } from 'providers/AxiosProvider';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import { useToasts } from 'providers/ToastProvider';
import {
  doConcatDataPages,
  doGetIsLoading,
  doPromiseAPI,
  getAPIHeadersV2,
  getOfflineNextPage,
  onDownloadData,
  onSuccessMutation,
  useKeyUserId,
  useMiLocOrTeamId,
} from 'api/helpers';
import type { Site } from 'models/InspectionReport';
import type { InfiniteQueryFlags } from 'models/Search';
import { ToastType } from 'models/Toast';
import { pageSize } from 'utils/constants';

export const findSitesQueryKey = 'sites';

interface UseFindSitesProps {
  query?: string;
  limit?: number;
  enabled?: boolean;
}

interface UseFindSitesResponse {
  sites: Site[];
  loadingAPI?: boolean;
  sync: () => Promise<void>;
}

const useFindSites = ({
  query,
  enabled = true,
  limit = pageSize(),
}: UseFindSitesProps): UseFindSitesResponse & InfiniteQueryFlags => {
  const { axios } = useAxios();
  const { addToast } = useToasts();
  const { getSitesDatabaseAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { createQueryKey } = useKeyUserId();
  const { createParams, getURLParams } = useMiLocOrTeamId({
    sendTeamId: false,
  });
  const { createSites, findSites, removeSites } = useSiteDB();
  const [loadingAPI, setLoadingAPI] = useState(false);
  const { isOnline } = useNetworkStatus();
  const params: QueryParamsType = {
    ...createParams(),
    fastFind: query,
    limit,
    showMachineSites: true,
    isOnline,
  };
  const miLoc = toString(params.miLoc);

  const doFindSites = ({ pageParam = 1 }) => {
    return doPromiseAPI<Site[]>(async () => {
      if (isOnline) {
        const {
          data: { rows: sitesData },
        } = await axios.post<FastFindAPIResponse<Site>>(
          getSitesDatabaseAPI(
            getURLParams({
              ...params,
              search: params.fastFind,
              page: pageParam,
            })
          ),
          null,
          { headers: { ...getAPIHeadersV2() } }
        );
        await createSites(sitesData);
        return sitesData;
      }
      const sites: Site[] = (await findSites(params)) || [];

      return sites;
    });
  };

  const response = useInfiniteQuery<Site[], AxiosError>(
    createQueryKey(findSitesQueryKey, params),
    doFindSites,
    {
      enabled,
      networkMode: 'always',
      getNextPageParam: getOfflineNextPage(!!isOnline),
    }
  );

  const {
    data,
    error,
    status,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = response;

  const sites = useMemo(() => doConcatDataPages<Site>(data), [data]);

  const hasItems = size(sites) > 0;
  const isEmptyResponse = status === 'success' && !hasNextPage && !hasItems;
  const noMoreData = status === 'success' && !hasNextPage && hasItems;
  const showLoader = doGetIsLoading(response) || isFetchingNextPage;
  const enableInfiniteScroll = !(!hasNextPage || isFetchingNextPage);

  useEffect(() => {
    if (enabled && !isOnline) {
      void refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, isOnline]);

  return {
    fetchNextPage: async () => {
      await fetchNextPage();
    },
    refetch: async () => {
      await refetch();
    },
    sync: async () => {
      try {
        setLoadingAPI(true);
        const { syncData } = await onDownloadData<Site>({
          customAxios: axios,
          getAPIUrl: getSitesDatabaseAPI,
          params: { miLoc, showMachineSites: toString(true) },
          headers: { ...getAPIHeadersV2() },
        });
        await removeSites(miLoc);
        await createSites(syncData);
        void onSuccessMutation(queryClient, findSitesQueryKey);
      } catch (e) {
        addToast({
          type: ToastType.error,
          text: 'There was an error while downloading sites',
          testid: 'sync-error-toast',
          duration: 0,
        });
        Sentry.captureException(e);
        throw e;
      } finally {
        setLoadingAPI(false);
      }
    },
    sites,
    error,
    showLoader,
    loadingAPI,
    isEmptyResponse,
    noMoreData,
    enableInfiniteScroll,
  };
};

export default useFindSites;
