import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { get, map, toLower, toString } from 'lodash';
import { useIonViewWillEnter } from '@ionic/react';
import useGetActivityNote from 'ActivitiesApp/api/notes/useGetActivityNote';
import type { AddActivityNoteURLParams } from 'ActivitiesApp/models/ActivityDetail';
import { goToShareActivityNoteByEmail } from 'ActivitiesApp/navigation/navigationHelpers';
import {
  SendEmailFormik,
  SendEmailPage,
} from 'common/components/SendEmail/SendEmail';
import type { SendEmailPageRef } from 'common/components/SendEmail/SendEmail';
import useSendEmail from 'api/activities/useSendEmail';
import useFindAttachments from 'api/attachments/useFindAttachments';
import useGetCustomer from 'api/customer/useGetCustomer';
import useGoBack from 'hooks/useGoBack';
import type { RootState } from 'store/reducers';

const ShareActivityNote = (): JSX.Element => {
  const { goBack } = useGoBack();
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const resetView = params.get('reset');
  const pageRef = useRef<SendEmailPageRef>(null);
  const { miLoc, id, userId, historyId, eventNoteId } =
    useParams<AddActivityNoteURLParams>();
  const { userInfo } = useSelector((state: RootState) => state.user);
  const loggedInUserId = get(userInfo, 'userid', '');
  const userName = get(userInfo, 'name', '');

  useIonViewWillEnter(() => {
    if (resetView) {
      pageRef.current?.resetForm?.();
      history.replace(
        goToShareActivityNoteByEmail({
          miLoc,
          customerId: id,
          userId,
          historyId,
          eventNoteId,
        })
      );
    }
  }, [resetView]);

  const { onSendEmail, status } = useSendEmail();

  useEffect(() => {
    if (status === 'success') {
      goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const {
    data: customerData,
    isLoading: customerLoading,
    error: customerError,
  } = useGetCustomer({ searchType: 'customer', miLoc, id });

  const {
    data: activityNote,
    isLoading: activityLoading,
    error: activityError,
  } = useGetActivityNote({
    id: eventNoteId,
    enabled: eventNoteId !== 'add',
  });

  const isSubmitting = status === 'loading';

  const subject = t('activities:sendEmail:defaultSubject', {
    userName,
    ownerName: activityNote?.userFullName,
    count: loggedInUserId === activityNote?.userId ? 0 : 1,
    customerName: customerData?.name,
    type: toLower(t('common:note')),
  });

  const message = t('activities:sendEmail:defaultNoteMessage', {
    customerName: customerData?.name,
    noteTitle: activityNote?.title,
    note: activityNote?.text,
  });

  const {
    data: attachmentsData,
    isLoading: attachmentsLoading,
    error: attachmentsError,
  } = useFindAttachments({
    domain: 'mproevn',
    miLoc,
    ctlNo: eventNoteId,
    enabled: !activityLoading,
    refetchOnEnter: true,
  });

  return (
    <SendEmailFormik
      onSubmit={(values) => {
        onSendEmail({
          subject: values.subject,
          recipients: map(values.recepientsList, ({ email }) =>
            toString(email)
          ),
          toastText: t('activities:sendEmail:emailNotesSuccess', {
            recipients: map(values.recepientsList, (i) => i.name).join('; '),
          }),
          body: toString(values.message),
          filesToUpload: values.files as File[],
        });
      }}
      values={{
        subject,
        message,
        recepients: '',
        files: attachmentsData,
      }}
    >
      <SendEmailPage
        ref={pageRef}
        customerData={customerData}
        isLoading={customerLoading || activityLoading || attachmentsLoading}
        error={customerError || activityError || attachmentsError}
        isSubmitting={isSubmitting}
        title={t('activities:emailNote')}
      />
    </SendEmailFormik>
  );
};

export default ShareActivityNote;
