import { useState } from 'react';
import type { AxiosError } from 'axios';
import type { Dictionary } from 'lodash';
import { map } from 'lodash';
import * as Sentry from '@sentry/capacitor';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import { useToasts } from 'providers/ToastProvider';
import useScannerProfileDB from 'StoreroomsApp/database/useScannerProfileDB';
import type {
  ScannerUserProfile,
  ScannerUserProfileAPI,
} from 'StoreroomsApp/models/ScannerUserProfile';
import {
  doGetIsLoading,
  doPromiseAPI,
  onSuccessMutation,
  useKeyUserId,
  useMiLocOrTeamId,
} from 'api/helpers';
import type { QueryFlags } from 'models/Search';
import { ToastType } from 'models/Toast';

export const findScannerProfilesQueryKey = 'scanner-profiles';

interface UseFindScannerProfilesProps {
  enabled?: boolean;
}

interface UseFindScannerProfilesResponse {
  profiles?: ScannerUserProfile[];
  loadingAPI?: boolean;
  sync: () => Promise<void>;
}

const useFindScannerProfiles = ({
  enabled,
}: UseFindScannerProfilesProps): QueryFlags &
  UseFindScannerProfilesResponse => {
  const { axios } = useAxios();
  const { addToast } = useToasts();
  const queryClient = useQueryClient();
  const { scannerUserProfileAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const { createParams } = useMiLocOrTeamId({ sendTeamId: false });
  const {
    createScanneProfiles,
    findScannerProfilesByMiLoc,
    removeScannerProfiles,
  } = useScannerProfileDB();
  const [loadingAPI, setLoadingAPI] = useState(false);
  const { isOnline } = useNetworkStatus();
  const params: Dictionary<string> = {
    ...createParams(),
  };
  const { miLoc } = params;

  const doFindScannerProfiles = async () => {
    return doPromiseAPI<ScannerUserProfile[]>(async () => {
      let profiles: ScannerUserProfile[] = [];
      if (isOnline) {
        const { data: profilesData } = await axios.post<
          ScannerUserProfileAPI[]
        >(scannerUserProfileAPI(miLoc));
        profiles = map(profilesData, (p) => ({
          id: p.scannerUserId,
          userID: p.scannerUserId,
          miLocation: p.miLoc,
          customerNumber: p.customerNo,
          storeroomNumber: p.storeroomNo,
          ...p,
        }));
        await createScanneProfiles(profiles);
        return profiles;
      }
      profiles = (await findScannerProfilesByMiLoc(miLoc)) || [];
      return profiles;
    });
  };

  const response = useQuery<ScannerUserProfile[], AxiosError>(
    createQueryKey(findScannerProfilesQueryKey, { ...params, isOnline }),
    doFindScannerProfiles,
    {
      enabled,
      networkMode: 'always',
      onError: () => {
        setLoadingAPI(false);
      },
    }
  );

  const { data, error, refetch } = response;

  return {
    profiles: data,
    error,
    isLoading: doGetIsLoading(response),
    loadingAPI,
    refetch: async () => {
      await refetch();
    },
    sync: async () => {
      try {
        setLoadingAPI(true);
        const { data: syncData } = await axios.post<ScannerUserProfileAPI[]>(
          scannerUserProfileAPI(miLoc)
        );
        const profiles: ScannerUserProfile[] = map(syncData, (p) => ({
          id: p.scannerUserId,
          userID: p.scannerUserId,
          miLocation: p.miLoc,
          customerNumber: p.customerNo,
          storeroomNumber: p.storeroomNo,
          ...p,
        }));
        await removeScannerProfiles(miLoc);
        await createScanneProfiles(profiles);
        void onSuccessMutation(queryClient, findScannerProfilesQueryKey);
      } catch (e) {
        addToast({
          type: ToastType.error,
          text: 'There was an error while downloading scanner profiles',
          testid: 'sync-error-toast',
          duration: 0,
        });
        Sentry.captureException(e);
        throw e;
      } finally {
        setLoadingAPI(false);
      }
    },
  };
};

export default useFindScannerProfiles;
