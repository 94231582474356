import React, { useState, useMemo, useEffect } from 'react';
import type { DatePickerProps } from 'react-datepicker';
import DatePick from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { find, isNil, map, size, some, toString } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type {
  DatetimePresentation,
  IonItemSlidingCustomEvent,
} from '@ionic/core/dist/types/components';
import {
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
} from '@ionic/react';
import type { BaseComponentProps } from 'common/components/utils/renderHelpers';
import { and, ifFunction, ifRender, or } from 'common/utils/logicHelpers';
import {
  addYears,
  subYears,
  startOfToday,
  isThisMonth,
  endOfQuarter,
  isThisQuarter,
  isSameDay,
  differenceInDays,
  isWeekend,
  subMonths,
  addMonths,
  endOfDay,
  endOfMonth,
  startOfDay,
  endOfToday,
  isBefore,
  isAfter,
  set,
  min,
  max,
} from 'date-fns';
import { namespaces } from 'i18n/i18n.constants';
import {
  ProductGroupKey,
  TabHomeKey,
  TabSalesPerformanceKey,
  useReportsConfig,
} from 'providers/ReportsProvider';
import type { DateSegmentType, ReportsURLParams } from 'models/Reports';
import {
  clampDate,
  DateFormatEnum,
  formatDate,
  getDateLocale,
  getQuarterMonths,
} from 'utils/date';
import ActionRow from 'components/ActionRow/ActionRow';
import Button from 'components/Button/Button';
import SheetModal from 'components/Modals/SheetModal/SheetModal';
import classes from './DatePickerModal.module.scss';
import DateTimeWheelModal from './DateTimeWheelModal';
import DateToolbarButtons from './DateToolbarButtons';
import './DatePickerModalOverrides.scss';

export interface DatePickerModalProps extends BaseComponentProps {
  date?: Date;
  maxDate?: Date;
  minDate?: Date;
  isOpen: boolean;
  setIsOpen?: (b: boolean) => void;
  onDone?: (d: Date) => void;
  onCancel?: () => void;
  onChange?: (d: Date) => void;
  holidays?: Date[];
  businessDays?: Date[];
  reportKey?: string;
  calendarKey?: string;
  showTimeSelect?: boolean;
  showSegmentedButtons?: boolean;
  filterDate?: (d: Date) => boolean;
  filterTime?: (d: Date) => boolean;
  title?: string;
}

const DatePickerModal = ({
  date = new Date(),
  title = '',
  isOpen,
  setIsOpen,
  onDone,
  onCancel,
  onChange,
  className,
  filterDate,
  filterTime,
  maxDate: propsMaxDate = addYears(endOfToday(), 1),
  minDate: propsMinDate = subYears(startOfToday(), 2),
  showTimeSelect = false,
  showSegmentedButtons = false,
  holidays,
  businessDays,
  reportKey,
  calendarKey = 'date-picker-key',
  testid,
}: DatePickerModalProps & Omit<DatePickerProps, 'onChange'>): JSX.Element => {
  const [wheelModalOpen, setWheelModalOpen] = useState(false);
  const [wheelModalPresentation, setWheelModalPresentation] =
    useState<DatetimePresentation>();
  const { tab = '' } = useParams<ReportsURLParams>();
  const { t, i18n } = useTranslation(namespaces.calendar);

  const key = useMemo(() => {
    switch (tab) {
      case 'home':
        return TabHomeKey;
      case 'search':
        return ProductGroupKey;
      case 'reports':
        return TabSalesPerformanceKey;
      default:
        return TabHomeKey;
    }
  }, [tab]);

  const modalTitle = or(title, t('calendar:selectCustomDate'));
  const hasBusinessDay = size(businessDays) > 0;
  const hasHolidays = size(holidays) > 0;
  const pickerReportKey = or(reportKey, key);
  const {
    updateRequestType: updateRequestTypeApply,
    updateBusPeriod: updateBusPeriodApply,
  } = useReportsConfig({ key: pickerReportKey });

  const { requestType, updateRequestType } = useReportsConfig({
    key: calendarKey,
  });

  const isDaily = requestType === 'DAILY';
  const isQuarterly = requestType === 'QTD';
  const useEndOfDay = or(!showSegmentedButtons, isDaily);
  let minDate = endOfMonth(propsMinDate);
  let maxDate = endOfMonth(propsMaxDate);

  if (useEndOfDay) {
    minDate = max([propsMinDate, startOfDay(propsMinDate)]);
    maxDate = min([propsMaxDate, endOfDay(propsMaxDate)]);
  }

  const dateProp = clampDate(date, minDate, maxDate);
  const [selectedDate, setSelectedDate] = useState(dateProp);

  useEffect(() => {
    if (isOpen) {
      setSelectedDate(dateProp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, date]);

  const currentDate = useEndOfDay
    ? endOfDay(selectedDate)
    : endOfMonth(selectedDate);

  const isLeftArrowDisabled =
    isBefore(currentDate, minDate) || isSameDay(currentDate, minDate);
  const isRightArrowDisabled =
    isAfter(currentDate, maxDate) || isSameDay(currentDate, maxDate);

  // #region event handlers
  const onDateChange = (dateTime: Date) => {
    const newDate = clampDate(dateTime, minDate, maxDate);
    setSelectedDate(newDate);
    onChange?.(newDate);
  };

  const onCancelClick = () => {
    onCancel?.();
    setIsOpen?.(false);
  };

  const onDoneClick = () => {
    let doneSelectedDate = selectedDate;
    if (!isDaily) {
      doneSelectedDate = endOfMonth(doneSelectedDate);
      if (isQuarterly) {
        doneSelectedDate = endOfQuarter(doneSelectedDate);
        if (isThisQuarter(doneSelectedDate)) {
          doneSelectedDate = startOfToday();
        }
      }
      if (isThisMonth(doneSelectedDate)) {
        doneSelectedDate = startOfToday();
      }
    }
    updateBusPeriodApply(doneSelectedDate);
    onDone?.(doneSelectedDate);
    updateRequestTypeApply(requestType);
    setIsOpen?.(false);
  };

  const wheelModalOnDoneClick = (dateTime: Date) => {
    let wheelTime = dateTime;
    if (wheelModalPresentation === 'month-year') {
      wheelTime = set(dateTime, {
        hours: selectedDate.getHours(),
        minutes: selectedDate.getMinutes(),
      });
    }
    setSelectedDate(clampDate(wheelTime, minDate, maxDate));
    setWheelModalOpen(false);
  };

  const handleArrowsOnClick = (isPrevArrow = false) => {
    if (isPrevArrow) {
      onDateChange(
        isDaily ? subMonths(selectedDate, 1) : subYears(selectedDate, 1)
      );
      return;
    }
    onDateChange(
      isDaily ? addMonths(selectedDate, 1) : addYears(selectedDate, 1)
    );
  };

  const onDrag = async (e: IonItemSlidingCustomEvent<unknown>) => {
    const windowWidth = window.innerWidth;
    const threshold = windowWidth * 0.3;
    const pixelRatio = await e.target.getOpenAmount();
    if (pixelRatio < threshold * -1) {
      handleArrowsOnClick(true);
      await e.target.close();
      return;
    }
    if (pixelRatio > threshold) {
      handleArrowsOnClick();
      await e.target.close();
    }
  };
  // #endregion

  // #region date-pick utils
  const sharedBusinessDays = useMemo(() => {
    if (isNil(businessDays)) return [];
    return map(businessDays, (bd, index) => {
      const left = differenceInDays(bd, businessDays[index + 1]) === -1;
      const right = differenceInDays(bd, businessDays[index - 1]) === 1;
      return {
        left: left && !right,
        right: right && !left,
        middle: left && right,
        date: bd,
      };
    });
  }, [businessDays]);

  const businessDay = useMemo(
    () =>
      (calendarDate: Date): boolean => {
        const sharedBusinessDay = find(sharedBusinessDays, [
          'date',
          calendarDate,
        ]);
        return (
          !isNil(sharedBusinessDay) &&
          !sharedBusinessDay.left &&
          !sharedBusinessDay.right
        );
      },
    [sharedBusinessDays]
  );

  const businessDayType = useMemo(
    () =>
      (
        calendarDate: Date
      ): { left: boolean; middle: boolean; right: boolean } => {
        const sharedBusinessDay = find(sharedBusinessDays, [
          'date',
          calendarDate,
        ]);
        if (!isNil(sharedBusinessDay)) {
          return {
            left: sharedBusinessDay.left,
            right: sharedBusinessDay.right,
            middle: sharedBusinessDay.middle,
          };
        }
        return { left: false, right: false, middle: false };
      },
    [sharedBusinessDays]
  );

  const renderQuarterContent = (quarter: string, shortQuarter: string) => {
    return (
      <div className={classNames(classes.qtdCellWrapper)}>
        <span className={classes.qtdText}>{shortQuarter}</span>
        <span className={classes.qtdMonths}>
          {map(getQuarterMonths(+quarter), (qMonth, index) => {
            const isFutureMonth = isAfter(
              Date.parse(`${qMonth}, ${new Date(selectedDate).getFullYear()}`),
              Date.now()
            );
            return (
              <span
                key={index}
                className={classNames({
                  [classes.notAvailable]: isFutureMonth,
                })}
              >
                {qMonth}
              </span>
            );
          })}
        </span>
      </div>
    );
  };

  // #endregion

  return (
    <SheetModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      testid={`datepicker-action-sheet-${toString(testid)}`}
      className={classNames(classes.datePicker, {
        [classes.dateTimePicker]: showTimeSelect,
      })}
      contentClass={classes.datePickerContent}
      withRightCloseButton
      title={modalTitle}
      initialBreakpoint={0.85}
    >
      <DateToolbarButtons
        showSegmentedButtons={showSegmentedButtons}
        className={classes.DateToolbarButtons}
        testid={toString(testid)}
        requestType={requestType}
        date={selectedDate}
        prevButtonDisabled={isLeftArrowDisabled}
        nextButtonDisabled={isRightArrowDisabled}
        onDateClick={() => {
          setWheelModalPresentation('month-year');
          setWheelModalOpen(true);
        }}
        onPrev={() => handleArrowsOnClick(true)}
        onNext={() => handleArrowsOnClick()}
        onSegmentChange={(e) => {
          updateRequestType(e.detail.value as DateSegmentType);
        }}
      />
      <IonItemSliding onIonDrag={onDrag}>
        <IonItemOptions side="start">
          <IonItemOption className={classes.itemOption} expandable>
            <FontAwesomeIcon
              className={classes.prevOption}
              icon={['far', 'chevron-left']}
              data-testid="prev-slide-option"
            />
          </IonItemOption>
        </IonItemOptions>
        <IonItem lines="none" className={classes.item}>
          <DatePick
            locale={getDateLocale(i18n.language)}
            className={className}
            renderCustomHeader={() => <></>}
            renderDayContents={(dayOfMonth: number, calendarDate: Date) => {
              const { left, right, middle } = businessDayType(calendarDate);
              return (
                <div
                  className={classNames({
                    [classes.dayWrapper]: size(sharedBusinessDays) > 0,
                    [classes.holiday]:
                      hasHolidays &&
                      some(holidays, (day) => isSameDay(day, calendarDate)),
                    [classes.businessDay]: businessDay(calendarDate),
                    [classes.businessDayLeft]: left,
                    [classes.businessDayRight]: right,
                    [classes.businessDayMiddle]: middle,
                    [classes.selected]: isSameDay(calendarDate, selectedDate),
                  })}
                >
                  <div>{dayOfMonth}</div>
                </div>
              );
            }}
            renderQuarterContent={renderQuarterContent}
            formatWeekDay={(nameOfDay: Date) => toString(nameOfDay).slice(0, 1)}
            dayClassName={() => classes.day}
            weekDayClassName={(day: Date) =>
              classNames(classes.weekDay, {
                [classes.weekendDay]: isWeekend(day),
              })
            }
            timeClassName={() => classes.time}
            inline
            disabledKeyboardNavigation
            selected={selectedDate}
            onChange={(d) => ifFunction(!!d, () => onDateChange(d as Date))}
            showMonthYearPicker={!isDaily && !isQuarterly}
            showQuarterYearPicker={isQuarterly}
            maxDate={maxDate}
            minDate={minDate}
            filterDate={filterDate}
            filterTime={filterTime}
            timeIntervals={60}
          />
        </IonItem>
        <IonItemOptions side="end">
          <IonItemOption className={classes.itemOption} expandable>
            <FontAwesomeIcon
              className={classes.nextOption}
              icon={['far', 'chevron-right']}
              data-testid="next-slide-option"
            />
          </IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
      {showTimeSelect && (
        <div className={classes.timeSelect}>
          <div>
            <FontAwesomeIcon className={classes.clockIcon} icon="alarm-clock" />
            <IonLabel>{toString(t('common:time'))}</IonLabel>
          </div>
          <Button
            className={classes.timeButton}
            testid={`${toString(testid)}-datetime-button`}
            onClick={() => {
              setWheelModalOpen(true);
              setWheelModalPresentation('time');
            }}
            text={formatDate(selectedDate, DateFormatEnum.ampmTime)}
          />
        </div>
      )}
      <div className={classes.buttons}>
        <Button
          className={classes.datePickButton}
          variant="secondary"
          text={t('common:cancel')}
          testid={`datepicker-button-cancel-${toString(testid)}`}
          onClick={onCancelClick}
        />
        <Button
          className={classes.datePickButton}
          variant="action"
          text={t('common:apply')}
          testid={`datepicker-button-apply-${toString(testid)}`}
          onClick={onDoneClick}
        />
      </div>
      {ifRender(
        and(isDaily, or(hasBusinessDay, hasHolidays)),
        <div className={classes.legend}>
          {hasBusinessDay && (
            <ActionRow
              className={classes.legendRow}
              leftButton={{
                className: classes.legendBusinessDay,
                testid: 'legend-icon',
              }}
              text={t('sharedDay')}
              testid="legend-row"
            />
          )}
          {hasHolidays && (
            <ActionRow
              className={classes.legendRow}
              leftButton={{
                className: classes.legendHoliday,
                testid: 'legend-icon',
              }}
              text={t('holiday')}
              testid="legend-row"
            />
          )}
        </div>
      )}
      <DateTimeWheelModal
        testid="date-picker-modal-wheel"
        isOpen={wheelModalOpen}
        setIsOpen={setWheelModalOpen}
        title={modalTitle}
        presentation={wheelModalPresentation}
        onCancel={() => setWheelModalOpen(false)}
        onDone={wheelModalOnDoneClick}
        dateTime={selectedDate}
        minDate={minDate}
        maxDate={maxDate}
      />
    </SheetModal>
  );
};

export default DatePickerModal;
