import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonRow } from '@ionic/react';
import { findIcon } from 'utils/icons';
import Text from 'components/Text/Text';
import classes from './Overlay.module.scss';

interface OverlayInfobarProps {
  message: string;
  setIsOpenOverlayCard?: (isOpenOverlay: boolean) => void;
  setIsShowInfobar?: (isShowInfobar: boolean) => void;
  reportBanner?: boolean;
  className?: string;
  hideInfoIcon?: boolean;
}

const OverlayInfobar = ({
  message,
  setIsOpenOverlayCard,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setIsShowInfobar,
  reportBanner = false,
  className,
  hideInfoIcon = false,
}: OverlayInfobarProps): JSX.Element => (
  <IonRow
    key="info-bar"
    className={classNames(classes.infoBar, className, {
      [classes.reportBanner]: reportBanner,
    })}
    onClick={() => setIsOpenOverlayCard?.(true)}
  >
    <div className={classes.infoBarContent} aria-hidden="true">
      {!hideInfoIcon && (
        <FontAwesomeIcon
          className={classes.icon}
          icon={findIcon('info-circle', 'fas')}
        />
      )}
      <Text
        className={classes.label}
        variant="title-info-card"
        text={message}
      />
    </div>
    {/*
      TODO: closing should only be enabled for daily totals
      <FontAwesomeIcon
      className={classes.closeButton}
      icon={findIcon('times')}
      onClick={() => setIsShowInfobar(false)}
    /> */}
  </IonRow>
);

export default OverlayInfobar;
