import type { AxiosError } from 'axios';
import { isEmpty } from 'lodash';
import { useIonViewWillEnter } from '@ionic/react';
import { useQuery } from '@tanstack/react-query';
import { useAxios } from 'providers/AxiosProvider';
import { doGetIsLoading, doPromiseAPI, useKeyUserId } from 'api/helpers';
import useAPIUrl from 'api/index';
import type { AttachmentsAPIResponse, MiProFile } from 'models/Attachment';
import type { QueryFlags } from 'models/Search';

export const findAttachmentsQueryKey = 'attachments';

interface UseFindAttachmentsProps {
  domain: string;
  miLoc?: string;
  ctlNo?: string;
  lineNo?: string;
  enabled?: boolean;
  refetchOnEnter?: boolean;
}

interface UseFindAttachmentsResponse {
  data?: MiProFile[];
}

const useFindAttachments = ({
  domain,
  miLoc,
  ctlNo,
  lineNo,
  enabled = true,
  refetchOnEnter = false,
}: UseFindAttachmentsProps): UseFindAttachmentsResponse & QueryFlags => {
  const { axios } = useAxios();
  const { findAttachmentsAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();

  const doFindAttachments = () => {
    return doPromiseAPI<MiProFile[]>(async (cancelToken) => {
      const { data } = await axios.get<AttachmentsAPIResponse>(
        findAttachmentsAPI(domain, miLoc, ctlNo, lineNo),
        { cancelToken }
      );
      return data.rows;
    });
  };

  const response = useQuery<MiProFile[], AxiosError>(
    createQueryKey(findAttachmentsQueryKey, { domain, miLoc, ctlNo, lineNo }),
    doFindAttachments,
    { enabled: enabled && !isEmpty(domain) }
  );

  const { data, error, refetch } = response;

  useIonViewWillEnter(() => {
    if (enabled && refetchOnEnter) {
      void refetch?.();
    }
  }, [enabled, refetchOnEnter]);

  return {
    data,
    error,
    isLoading: doGetIsLoading(response),
    refetch: async () => {
      await refetch();
    },
  };
};

export default useFindAttachments;
