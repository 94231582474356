import { useTranslation } from 'react-i18next';
import { capitalize, isEmpty, lowerCase } from 'lodash';
import { useUserInfo } from 'common/utils/userInfo';
import { type LastDocumentTransmission } from 'ProductSearchApp/models/Order';
import { formatCardDate } from 'utils/date';

interface UseTransmitLogDetailsResponse {
  hasTransmitted: boolean;
  transmitMessage: string;
  ocnListTransmitMessage: string;
}

const useTransmitDetails = ({
  transmissionMethod,
  transmissionTmstmp,
  transmissionStatus,
  creationUserId,
  creationUsername,
}: LastDocumentTransmission): UseTransmitLogDetailsResponse => {
  const { t } = useTranslation();
  const ocnNameSpace = 'productSearch:ocn';

  const { isOwner } = useUserInfo({
    userId: creationUserId,
  });

  const user = isOwner ? t('common:you') : creationUsername;

  return {
    hasTransmitted: !isEmpty(transmissionMethod) && transmissionStatus === 'T',
    transmitMessage: t(`${ocnNameSpace}:transmittedMessage`, {
      method: lowerCase(transmissionMethod),
      date: formatCardDate(transmissionTmstmp, false, false),
      user,
    }),
    ocnListTransmitMessage: t(`${ocnNameSpace}:transmitMessageOcnList`, {
      method: capitalize(transmissionMethod),
      date: formatCardDate(transmissionTmstmp, false, false),
    }),
  };
};

export default useTransmitDetails;
