import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IonLoading } from '@ionic/react';
import useFindMachines from 'DocumentsApp/api/useFindMachines';
import useFindReports from 'DocumentsApp/api/useFindReports';
import useFindSites from 'DocumentsApp/api/useFindSites';
import useFindTemplates from 'DocumentsApp/api/useFindTemplates';
import useFindVasCodes from 'DocumentsApp/api/useFindVasCodes';
import useFindWorkOrders from 'DocumentsApp/api/useFindWorkOrders';
import useFindWorkTasks from 'DocumentsApp/api/useFindWorkTasks';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import { useToasts } from 'providers/ToastProvider';
import { ToastType } from 'models/Toast';
import selectIsBranchLocation from 'store/user/selectors';
import { findIcon } from 'utils/icons';
import Button from 'components/Button/Button';
import OfflineInfo from 'components/OfflineInfo/OfflineInfo';
import classes from './UseSyncHook.module.scss';

interface UseDocumentSyncResponse {
  loader: JSX.Element;
  downloadButton?: JSX.Element;
  offlineBanner?: JSX.Element;
}

const useDocumentSync = (): UseDocumentSyncResponse => {
  const { isOnline } = useNetworkStatus();
  const { addToast } = useToasts();
  const isBranch = useSelector(selectIsBranchLocation);
  const [isDownloading, setIsDownloading] = useState(false);

  const {
    loadingAPI: isLoadingSyncTemplatesDatabase,
    sync: syncTemplatesDatabase,
  } = useFindTemplates({ enabled: false });

  const {
    loadingAPI: isLoadingSyncReportsDatabase,
    sync: syncReportsDatabase,
  } = useFindReports({ enabled: false, reportStatus: 'IP' });

  const {
    loadingAPI: isLoadingSyncWorkOrdersDatabase,
    sync: syncWorkOrdersDatabase,
  } = useFindWorkOrders({ enabled: false });

  const { loadingAPI: isLoadingSyncSitesDatabase, sync: syncSitesDatabase } =
    useFindSites({ enabled: false });

  const {
    loadingAPI: isLoadingSyncMachinesDatabase,
    sync: syncMachinesDatabase,
  } = useFindMachines({ enabled: false });

  const {
    loadingAPI: isLoadingSyncVasCodesDatabase,
    sync: syncVasCodesDatabase,
  } = useFindVasCodes({ enabled: false });

  const {
    loadingAPI: isLoadingSyncWorkTasksDatabase,
    sync: syncWorkTasksDatabase,
  } = useFindWorkTasks({ enabled: false });

  const syncDatabase = async () => {
    try {
      setIsDownloading(true);
      const totalRows = await syncReportsDatabase();
      await syncTemplatesDatabase();
      await syncWorkOrdersDatabase();
      await syncSitesDatabase();
      await syncMachinesDatabase();
      await syncVasCodesDatabase();
      await syncWorkTasksDatabase();
      addToast({
        type: ToastType.success,
        text: `Downloaded ${totalRows} documents.`,
        testid: 'sync-success-toast',
      });
    } catch (e) {
      // DOC: general error
    } finally {
      setIsDownloading(false);
    }
  };

  const isSyncDatabase =
    isLoadingSyncReportsDatabase ||
    isLoadingSyncTemplatesDatabase ||
    isLoadingSyncWorkOrdersDatabase ||
    isLoadingSyncSitesDatabase ||
    isLoadingSyncMachinesDatabase ||
    isLoadingSyncVasCodesDatabase ||
    isLoadingSyncWorkTasksDatabase;

  return {
    loader: (
      <IonLoading
        isOpen={isDownloading || !!isSyncDatabase}
        message="Sync data in progress. This could take a couple minutes."
      />
    ),
    downloadButton: (
      <Button
        slot="end"
        className={classes.downloadButton}
        onClick={syncDatabase}
        icon={findIcon('download')}
        testid="sync-documents"
        disabled={!isBranch || !isOnline}
      />
    ),
    offlineBanner: isOnline ? undefined : <OfflineInfo />,
  };
};

export default useDocumentSync;
