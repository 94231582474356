import React, { useState } from 'react';
import { toString } from 'lodash';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import useFindSites from 'DocumentsApp/api/useFindSites';
import { useDebounce } from 'use-debounce';
import type { Site } from 'models/InspectionReport';
import type Input from 'components/Input/Input';
import FastFind from './FastFind';

interface SitesFastFindProps {
  site?: Site;
  setSite?: (i: Site) => void;
}

const SitesFastFind = ({
  label,
  value,
  required,
  disabled,
  setValue,
  site,
  setSite,
  testid,
}: SitesFastFindProps &
  React.ComponentProps<typeof Input> &
  IonicReactProps): JSX.Element => {
  const [searchQuery, setSearchQuery] = useState(toString(value));
  const [searchQueryValue] = useDebounce(searchQuery, 300);

  const {
    sites,
    showLoader,
    isEmptyResponse,
    enableInfiniteScroll,
    fetchNextPage,
  } = useFindSites({ query: searchQueryValue });

  return (
    <FastFind<Site>
      items={sites}
      getId={(i) => toString(i.siteId)}
      getSelectedText={(i) =>
        toString(i.siteId)
          ? `${i.siteId} - ${i.siteDesc}`
          : toString(i.siteText)
      }
      getTitle={(i) => `${i.siteMiLoc}${i.siteCustNo} ${i.customerName}`}
      getDescription={(i) => `${i.siteId} - ${i.siteDesc}`}
      getAdditionalInfo={(i) => i.custSiteId}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      showLoader={showLoader}
      isEmptyResponse={isEmptyResponse}
      enableInfiniteScroll={enableInfiniteScroll}
      fetchNextPage={fetchNextPage}
      label={label}
      value={value}
      required={required}
      disabled={disabled}
      setValue={setValue}
      selectedItem={site}
      setSelectedItem={setSite}
      testid={testid}
    />
  );
};

export default SitesFastFind;
