import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { isEmpty, toNumber } from 'lodash';
import { Style as StatusBarStyle } from '@capacitor/status-bar';
import type { OverlayEventDetail } from '@ionic/core/components';
import {
  IonContent,
  IonFooter,
  IonHeader,
  IonModal,
  IonToolbar,
  useIonViewWillLeave,
} from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { useVault } from 'providers/VaultProvider';
import { setStatusBarTheme } from 'utils/helpers';
import classes from './ActionSheet.module.scss';

interface ActionSheetProps {
  modalClassName?: string;
  wrapperClassName?: string;
  isOpen: boolean;
  triggerModalResize?: number;
  setIsOpen?: (b: boolean) => void;
  onClose?: () => void;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  testid: string;
  forceFullHeight?: boolean;
}

const ActionSheet = ({
  className,
  modalClassName,
  wrapperClassName,
  triggerModalResize,
  onDidPresent,
  isOpen: pIsOpen,
  setIsOpen: pSetIsOpen,
  onClose,
  children,
  backdropDismiss = true,
  keyboardClose = true,
  forceFullHeight = false,
  canDismiss,
  onWillPresent,
  header,
  footer,
  testid,
}: React.PropsWithChildren<ActionSheetProps> &
  Omit<React.ComponentProps<typeof IonModal>, 'children'> &
  IonicReactProps): JSX.Element => {
  const { isLockedAfterBackgrounded } = useVault();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLIonContentElement>(null);
  const headerRef = useRef<HTMLIonToolbarElement>(null);
  const footerRef = useRef<HTMLIonFooterElement>(null);

  useEffect(() => {
    let isFullScreen = false;
    let interval: NodeJS.Timeout;
    let prevStyle: StatusBarStyle;
    let counter = 0;
    /**
     * setTimeout needed to wait for contentRef paint
     * requestAnimationFrame doesn't guarantee clientHeight value
     */
    if (pIsOpen) {
      interval = setInterval(() => {
        const modalEl = contentRef.current?.closest('ion-modal');
        const contentEl = contentRef.current?.closest('ion-content');
        const scrollEl = contentEl?.shadowRoot?.querySelector('.inner-scroll');
        const scrollElHeight = scrollEl
          ? parseInt(window.getComputedStyle(scrollEl).paddingTop, 10) +
            parseInt(
              window.getComputedStyle(scrollEl as HTMLIonContentElement)
                .paddingBottom,
              10
            )
          : 0;
        const wrapperScrollHeight =
          toNumber(wrapperRef.current?.scrollHeight || 0) + scrollElHeight;
        const contentScrollHeight = wrapperScrollHeight;
        const scrollHeight =
          contentScrollHeight +
          toNumber(headerRef.current?.scrollHeight || 0) +
          toNumber(footerRef.current?.scrollHeight || 0);

        if (scrollHeight === 0) {
          return;
        }

        isFullScreen = forceFullHeight || scrollHeight >= window.innerHeight;

        if (isFullScreen) {
          modalEl?.classList.add(classes.fullHeightModal);
          modalEl?.style.removeProperty('--height');
        } else {
          if (!forceFullHeight) {
            modalEl?.classList.remove(classes.fullHeightModal);
          }
          modalEl?.style.setProperty('--height', `${scrollHeight}px`);
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const setStatusBarStyle = async () => {
          if (isFullScreen) {
            prevStyle = await setStatusBarTheme(StatusBarStyle.Dark);
          }
        };

        void setStatusBarStyle();
        if (counter > 8) {
          clearInterval(interval);
        } else {
          counter += 1;
        }
      }, 50);
    }

    return () => {
      clearInterval(interval);
      if (isFullScreen && !isEmpty(prevStyle)) {
        void setStatusBarTheme(prevStyle);
      }
    };
  }, [pIsOpen, triggerModalResize, forceFullHeight]);

  // DOC: modal should close when leaving the view (otherwise it will be present over next screen),
  // and also when app locks (otherwise it will be stay over app without a connected state to close it)
  useIonViewWillLeave(() => {
    pSetIsOpen?.(false);
  });

  useEffect(() => {
    if (isLockedAfterBackgrounded) {
      pSetIsOpen?.(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLockedAfterBackgrounded]);

  // DOC: keep a local state for isOpen so it can be closed after rerenders
  const [isOpen, localSetIsOpen] = useState(pIsOpen);

  useEffect(() => {
    localSetIsOpen(pIsOpen);
  }, [pIsOpen]);

  const setIsOpen = (b: boolean) => {
    localSetIsOpen(b);
    pSetIsOpen?.(b);
  };

  return (
    <IonModal
      className={classNames(classes.modal, modalClassName, {
        [classes.fullHeightModal]: forceFullHeight,
      })}
      initialBreakpoint={1}
      breakpoints={[0, 0.5, 0.75, 1]}
      isOpen={isOpen}
      onDidPresent={onDidPresent}
      onDidDismiss={(e: CustomEvent<OverlayEventDetail>) => {
        // validation for failing tests as we can't mock the close event detail
        if (e?.detail.role === 'backdrop') {
          onClose?.();
        }
        setIsOpen?.(false);
      }}
      canDismiss={canDismiss || true}
      onWillPresent={onWillPresent}
      keyboardClose={keyboardClose}
      backdropDismiss={backdropDismiss}
      data-testid={testid}
    >
      <IonHeader className={classes.header}>
        {!isEmpty(header) && (
          <IonToolbar className={classes.toolbar} ref={headerRef}>
            {header}
          </IonToolbar>
        )}
      </IonHeader>
      <IonContent
        ref={contentRef}
        className={classNames(className, classes.wrapper)}
      >
        <div ref={wrapperRef} className={wrapperClassName}>
          {children}
        </div>
      </IonContent>
      <IonFooter ref={footerRef} className={classes.footer}>
        {footer}
      </IonFooter>
    </IonModal>
  );
};

export default ActionSheet;
