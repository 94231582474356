import React from 'react';
import classNames from 'classnames';
import { isNil } from 'lodash';
import { IonItem, IonLabel } from '@ionic/react';
import type { RadioOptionsProps } from 'components/RadioButton/RadioButton';
import RadioButton from 'components/RadioButton/RadioButton';
import Text from 'components/Text/Text';
import classes from './VisitFormLine.module.scss';

interface VisitFormRadioProps {
  value?: string;
  fieldName?: string;
  radioOptions?: RadioOptionsProps[];
  onChange?: (e: string) => void;
}

interface VisitFormLineProps {
  label: string;
  radioOptions?: RadioOptionsProps[];
  isSameUser?: boolean;
  required?: boolean;
}

const VisitFormRadio = ({
  value,
  radioOptions,
  fieldName,
  onChange,
}: VisitFormRadioProps) => {
  if (isNil(radioOptions)) {
    return null;
  }
  return (
    <RadioButton
      name={fieldName}
      value={value}
      options={radioOptions}
      testid="visit-form-line-radio"
      onChange={(e) => onChange?.(e as string)}
      inline
    />
  );
};

const VisitFormLine = ({
  value,
  fieldName,
  label,
  radioOptions,
  onChange,
  isSameUser,
  required,
}: VisitFormLineProps & VisitFormRadioProps) => {
  return (
    <>
      <IonItem className={classes.formLine} data-testid="visit-form-line">
        <IonLabel className={classes.labelWrapper}>
          <Text
            className={classNames(classes.label, {
              [classes.sameUser]: isSameUser,
            })}
            text={label}
            testid="visit-form-line-label"
          />
          {required && (
            <Text
              className={classes.requiredLabel}
              text="*"
              testid="required-label"
            />
          )}
        </IonLabel>
        {!isNil(radioOptions) && !isNil(value) && (
          <VisitFormRadio
            radioOptions={radioOptions}
            value={value}
            fieldName={fieldName}
            onChange={onChange}
          />
        )}
      </IonItem>
    </>
  );
};

export default VisitFormLine;
