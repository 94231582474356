/* eslint-disable no-console */
import type { Dictionary } from 'lodash';
import { filter, find, includes, map, toString } from 'lodash';
import type { SQLiteDBConnection } from '@capacitor-community/sqlite';
import { useDevice } from 'providers/DeviceProvider';
import type { Issue, ItemIssue } from 'StoreroomsApp/models/Issue';
import type { ItemPOU } from 'StoreroomsApp/models/ItemPOU';
import type {
  ItemReplenishment,
  Replenishment,
} from 'StoreroomsApp/models/Replenishment';
import {
  createIssueItems,
  createIssues,
  createItems,
  createReplenishmentItems,
  createReplenishments,
} from './utils';

const versionDBTable = `CREATE TABLE IF NOT EXISTS versionDB (
  version TEXT
)`;

const storeroomTable = `CREATE TABLE IF NOT EXISTS storeroom (
  combinedId TEXT PRIMARY KEY,
  miLocation TEXT,
  storeroomNumber TEXT,
  customerNumber TEXT,
  storeroomName TEXT,
  storeroomType TEXT,
  status TEXT,
  openOrCloseDate TEXT,
  phoneNumber TEXT,
  faxNumber TEXT,
  contactPerson TEXT,
  shipToAddress1 TEXT,
  shipToAddress2 TEXT,
  shipToAddress3 TEXT,
  shipToCity TEXT,
  shipToState TEXT,
  shipToZip TEXT,
  shipToZipPlus4 TEXT,
  shipToZipNonUsa TEXT,
  replenishFromLocation TEXT,
  replenishFromStoreroom TEXT,
  languageFlag TEXT,
  validToPurchase TEXT,
  globalOwnership TEXT,
  defaultChargebackValue1 TEXT,
  defaultChargebackValue2 TEXT,
  defaultChargebackValue3 TEXT,
  defaultChargebackValue4 TEXT,
  defaultChargebackValue5 TEXT,
  creationUserID TEXT,
  creationProgram TEXT,
  creationTimestamp TEXT,
  lastUpdateUserID TEXT,
  lastUpdateProgram TEXT,
  lastUpdateTimestamp TEXT,
  receiptRequiredFlag TEXT,
  alternateCustomers TEXT,
  receiptPrtinerOverride TEXT,
  csnForPurchasing TEXT,
  replenishMethod TEXT,
  defaultOwnership TEXT,
  imdOwnership TEXT,
  batchIssues TEXT,
  barcodeDescriptionPrint TEXT,
  inventoryType TEXT,
  monthReceipt TEXT,
  locationCode TEXT,
  supplierLocationNumber TEXT,
  previousMIBranchLocation TEXT,
  processIssues TEXT,
  processReceipts TEXT,
  processReplenishments TEXT,
  processCycleCounts TEXT,
  storeroomLabelFormat TEXT,
  storeroomLabelSortSequence TEXT,
  storeroomClassType TEXT,
  syncTimestamp INTEGER
)`;

const createIndexStoreroom = `CREATE UNIQUE INDEX IF NOT EXISTS idx_storeroom
  ON storeroom (combinedId)`;

const itemsPOUTable = `CREATE TABLE IF NOT EXISTS itemspou (
  combinedId TEXT PRIMARY KEY,
  itemNumber TEXT,
  barcodeValue TEXT,
  barcodeType TEXT,
  binLocation TEXT,
  customerNumber TEXT,
  customerStockNumber TEXT,
  mino TEXT,
  itemDescription TEXT,
  miLocation TEXT,
  storeroomNumber TEXT,
  unitOfMeasure TEXT,
  fixedOrderQuantity TEXT,
  minStockingQuantity TEXT,
  maxStockingQuantity TEXT,
  balanceOnHandQuantity TEXT,
  replenishmentType TEXT,
  orderIncrement TEXT
)`;

const createIndexItemPOU = `CREATE UNIQUE INDEX IF NOT EXISTS idx_itempou
  ON itemspou (combinedId)`;

const replenishmentTable = `CREATE TABLE IF NOT EXISTS replenishment (
  id INTEGER PRIMARY KEY AUTOINCREMENT,
  recordType TEXT,
  miLocation TEXT,
  userId TEXT,
  customerNumber TEXT,
  creationTimestamp INTEGER,
  syncTimestamp INTEGER
)`;

const replenishmentItemTable = `CREATE TABLE IF NOT EXISTS item_replenishment (
  id INTEGER PRIMARY KEY AUTOINCREMENT,
  itemId TEXT,
  replenishmentId INTEGER,
  balanceOnHandQuantity TEXT,
  orderQuantity TEXT,
  errorCode TEXT,
  error TEXT,
  FOREIGN KEY(itemId) REFERENCES itemspou(combinedId),
  FOREIGN KEY(replenishmentId) REFERENCES replenishment(id)
)`;

const scannerUserProfileTable = `CREATE TABLE IF NOT EXISTS scanner_user_profile (
  id TEXT PRIMARY KEY,
  userID TEXT,
  miLocation TEXT,
  customerNumber TEXT,
  storeroomNumber TEXT,
  securityResource1 TEXT,
  securityResource2 TEXT,
  securityResource3 TEXT,
  securityResource4 TEXT,
  securityResource5 TEXT
)`;

const chargeBackProfileTable = `CREATE TABLE IF NOT EXISTS charge_back_profile (
  combinedId TEXT PRIMARY KEY,
  miLocation TEXT,
  customerNumber TEXT,
  chargeBackDescription1 TEXT,
  chargeBackDescription2 TEXT,
  chargeBackDescription3 TEXT,
  chargeBackDescription4 TEXT,
  chargeBackDescription5 TEXT,
  chargeBackRequired1 TEXT,
  chargeBackRequired2 TEXT,
  chargeBackRequired3 TEXT,
  chargeBackRequired4 TEXT,
  chargeBackRequired5 TEXT,
  validateChargeBack1 TEXT,
  validateChargeBack2 TEXT,
  validateChargeBack3 TEXT,
  validateChargeBack4 TEXT,
  validateChargeBack5 TEXT,
  validateGLNumber TEXT,
  invoiceGroupingCode TEXT,
  useGLNumber TEXT,
  glNumberRequited TEXT
)`;

const chargeBackValueTable = `CREATE TABLE IF NOT EXISTS validate_charge_back (
  combinedId TEXT PRIMARY KEY,
  miLocation TEXT,
  customerNumber TEXT,
  descriptionNumber TEXT,
  valueId TEXT,
  valueDescription TEXT,
  chargeBackGLNumber TEXT
)`;

const issueTable = `CREATE TABLE IF NOT EXISTS issue (
  id INTEGER PRIMARY KEY AUTOINCREMENT,
  recordType TEXT,
  userId TEXT,
  userName TEXT,
  miLocation TEXT,
  storeroomNumber TEXT,
  creationTimestamp INTEGER,
  syncTimestamp INTEGER,
  takeOnStatus TEXT,
  chargeBack1 TEXT,
  chargeBack2 TEXT,
  chargeBack3 TEXT,
  chargeBack4 TEXT,
  chargeBack5 TEXT
)`;

const issueItemTable = `CREATE TABLE IF NOT EXISTS item_issue (
  id INTEGER PRIMARY KEY AUTOINCREMENT,
  itemId TEXT,
  issueId INTEGER,
  issueQuantity REAL,
  error TEXT,
  FOREIGN KEY(itemId) REFERENCES itemspou(combinedId),
  FOREIGN KEY(issueId) REFERENCES issue(id)
)`;

interface UseScannerDBResponse {
  db: SQLiteDBConnection;
  openDB: () => Promise<void>;
  closeDB: () => Promise<void>;
}

interface VersionDB {
  version: string;
}

const useScannerDB = (): UseScannerDBResponse => {
  const { scannerDB: db } = useDevice();

  const migrateTo1dot0 = async (versions: string[]): Promise<void> => {
    if (includes(versions, '1.0')) {
      return;
    }
    await db.execute(`DROP TABLE IF EXISTS storeroom`);
    await db.execute(`DROP TABLE IF EXISTS scanner_user_profile`);
    await db.execute(`DROP TABLE IF EXISTS charge_back_profile`);
    await db.execute(`DROP TABLE IF EXISTS validate_charge_back`);
    await db.query(
      `INSERT INTO versionDB (
      version
      ) VALUES (?)`,
      ['1.0']
    );
  };

  const migrateTo1dot1 = async (versions: string[]): Promise<void> => {
    if (includes(versions, '1.1')) {
      return;
    }
    await db.execute(`DROP INDEX IF EXISTS idx_storeroom`);
    await db.execute(`DROP INDEX IF EXISTS idx_itempou`);
    await db.execute(itemsPOUTable);
    const offlineItems = map(
      (await db.query(`SELECT * from itemspou`)).values as ItemPOU[],
      (i) => ({
        ...i,
        combinedId: `${i.miLocation}${i.customerNumber}${i.storeroomNumber}${i.barcodeValue}`,
      })
    );
    // DOC: migrate existing replenishments
    await db.execute(replenishmentTable);
    const offlineReplenishments = map(
      (await db.query(`SELECT * from replenishment`)).values as Replenishment[]
    );
    await db.execute(replenishmentItemTable);
    const offlineReplenishmentItems = map(
      (await db.query(`SELECT * from item_replenishment`))
        .values as ItemReplenishment[],
      (i) => ({
        ...i,
        itemId: toString(
          find(
            offlineItems,
            (oi) => i.itemId === (oi as unknown as Dictionary<string>).id
          )?.combinedId
        ),
      })
    );
    await db.execute(`DROP TABLE IF EXISTS item_replenishment`);
    await db.execute(replenishmentItemTable);
    await db.execute(`DROP TABLE IF EXISTS replenishment`);
    await db.execute(replenishmentTable);
    await createReplenishments(db, offlineReplenishments);
    await createReplenishmentItems(db, offlineReplenishmentItems);
    // DOC: migrate existing issues
    await db.execute(issueTable);
    const offlineIssues = map(
      (await db.query(`SELECT * from issue`)).values as Issue[]
    );
    await db.execute(issueItemTable);
    const offlineIssueItems = map(
      (await db.query(`SELECT * from item_issue`)).values as ItemIssue[],
      (i) => ({
        ...i,
        itemId: toString(
          find(
            offlineItems,
            (oi) => i.itemId === (oi as unknown as Dictionary<string>).id
          )?.combinedId
        ),
      })
    );
    await db.execute(`DROP TABLE IF EXISTS item_issue`);
    await db.execute(issueItemTable);
    await db.execute(`DROP TABLE IF EXISTS issue`);
    await db.execute(issueTable);
    await createIssues(db, offlineIssues);
    await createIssueItems(db, offlineIssueItems);
    // DOC: migrate downloaded items
    await db.execute(`DROP TABLE IF EXISTS itemspou`);
    await db.execute(itemsPOUTable);
    await createItems(db, offlineItems);
    await db.query(
      `INSERT INTO versionDB (
      version
      ) VALUES (?)`,
      ['1.1']
    );
  };

  const migrateTo1dot2 = async (versions: string[]): Promise<void> => {
    if (includes(versions, '1.2')) {
      return;
    }
    await db.execute(itemsPOUTable);
    const offlineItems = (await db.query(`SELECT * from itemspou`))
      .values as ItemPOU[];
    await db.execute(replenishmentItemTable);
    const offlineReplenishmentItems = (
      await db.query(`SELECT * from item_replenishment`)
    ).values as ItemReplenishment[];
    await db.execute(issueItemTable);
    const offlineIssueItems = (await db.query(`SELECT * from item_issue`))
      .values as ItemIssue[];
    // DOC: migrate downloaded items
    await db.execute(`DROP TABLE IF EXISTS itemspou`);
    await db.execute(itemsPOUTable);
    await createItems(
      db,
      filter(
        offlineItems,
        (item) =>
          !!find([...offlineReplenishmentItems, ...offlineIssueItems], {
            itemId: item.combinedId,
          })
      )
    );

    await db.query(
      `INSERT INTO versionDB (
      version
      ) VALUES (?)`,
      ['1.2']
    );
  };

  const migrateTo1dot3 = async (versions: string[]): Promise<void> => {
    if (includes(versions, '1.3')) {
      return;
    }
    // DOC: migrate existing replenishments
    await db.execute(replenishmentItemTable);
    const offlineReplenishmentItems = (
      await db.query(`SELECT * from item_replenishment`)
    ).values as ItemReplenishment[];
    await db.execute(`DROP TABLE IF EXISTS item_replenishment`);
    await db.execute(replenishmentItemTable);
    await createReplenishmentItems(db, offlineReplenishmentItems);

    await db.query(
      `INSERT INTO versionDB (
      version
      ) VALUES (?)`,
      ['1.3']
    );
  };

  const migrateTo1dot4 = async (versions: string[]): Promise<void> => {
    if (includes(versions, '1.4')) {
      return;
    }
    // DOC: migrate existing issues
    await db.execute(issueItemTable);
    const offlineIssueItems = (await db.query(`SELECT * from item_issue`))
      .values as ItemIssue[];
    await db.execute(`DROP TABLE IF EXISTS item_issue`);
    await db.execute(issueItemTable);
    await createIssueItems(db, offlineIssueItems);

    await db.query(
      `INSERT INTO versionDB (
      version
      ) VALUES (?)`,
      ['1.4']
    );
  };

  const createTables = async (): Promise<void> => {
    try {
      await db.execute(versionDBTable);
      const versions = map(
        (await db.query(`SELECT * FROM versionDB`)).values as VersionDB[],
        (v) => v.version
      );
      await migrateTo1dot0(versions);
      await migrateTo1dot1(versions);
      await migrateTo1dot2(versions);
      await migrateTo1dot3(versions);
      await migrateTo1dot4(versions);
      await db.execute(storeroomTable);
      await db.execute(createIndexStoreroom);
      await db.execute(itemsPOUTable);
      await db.execute(createIndexItemPOU);
      await db.execute(replenishmentTable);
      await db.execute(replenishmentItemTable);
      await db.execute(scannerUserProfileTable);
      await db.execute(chargeBackProfileTable);
      await db.execute(chargeBackValueTable);
      await db.execute(issueTable);
      await db.execute(issueItemTable);
    } catch (error) {
      console.log('Error creating tables', error);
    }
  };

  const openDB = async (): Promise<void> => {
    try {
      await db.open();
      await createTables();
    } catch (error) {
      console.log('Error opening DB connection', error);
    }
  };

  const closeDB = async (): Promise<void> => {
    try {
      await db.close();
    } catch (error) {
      console.log('Error closing DB connection', error);
    }
  };

  return {
    db,
    openDB,
    closeDB,
  };
};

export default useScannerDB;
