export enum SalesPlayTypeEnum {
  overdueSalesPlay = 'OverdueSalesPlayAdded',
  upSellSalesPlay = 'UpSellSalesPlayAdded',
  decliningSalesPlay = 'DecliningSalesPlayAdded',
  crossSellSalesPlay = 'CrossSellSalesPlayAdded',
}

export enum SalesPlayIconEnum {
  salesPlays = 'badge-dollar',
  overdueSalesPlay = 'alarm-exclamation',
  upSellSalesPlay = 'lightbulb-dollar',
  decliningSalesPlay = 'chart-line-down',
  crossSellSalesPlay = 'random',
}

export enum SalesPlayColorEnum {
  upSellSalesPlay = 'color-upsell',
  crossSellSalesPlay = 'color-crossSellSalesPlay',
  decliningSalesPlay = 'color-decliningSalesPlay',
  overdueSalesPlay = 'color-overdueSalesPlay',
}

export enum SalesPlayFilterEnum {
  salesPlays = 'salesPlays',
}
