import React from 'react';
import classNames from 'classnames';
import type { Dictionary } from 'lodash';
import { isEmpty, map } from 'lodash';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { InsetListData } from 'common/components/InsetList/InsetList';
import InsetList from 'common/components/InsetList/InsetList';
import type { BaseComponentProps } from 'common/components/utils/renderHelpers';
import Text from 'components/Text/Text';
import classes from './Timeline.module.scss';

enum TimeLineVariantEnum {
  'success',
  'pending',
  'alert',
}

type TimeLineVariant = keyof typeof TimeLineVariantEnum;

export type TimelineItem = {
  variant: TimeLineVariant;
  title: string;
  lines?: InsetListData[];
};

interface TimelineProps extends BaseComponentProps {
  data: TimelineItem[];
}

const Timeline = ({ testid, className, data }: TimelineProps) => {
  const iconName: Dictionary<IconProp> = {
    success: ['fas', 'circle-check'],
    pending: ['far', 'circle'],
    alert: ['fas', 'circle-xmark'],
  };

  return (
    <div
      className={classNames(classes.timeline, className)}
      data-testid={testid}
    >
      {map(data, (item, i) => {
        const isFirstElement = i === 0;
        const hasContent = !isEmpty(item.lines);

        return (
          <div
            className={classes.row}
            key={i}
            data-testid={`timeline-row-${i}`}
          >
            {!isFirstElement && (
              <div
                className={classNames(classes.colStart, classes.colTraceBefore)}
              >
                <div
                  data-testid={`timeline-row-${i}-trace-before`}
                  className={classNames(classes.trace, classes[item.variant])}
                />
              </div>
            )}
            <div className={classes.titleRow}>
              <div className={classes.colStart}>
                <FontAwesomeIcon
                  data-testid={`timeline-row-${i}-icon`}
                  icon={iconName[item.variant]}
                  className={classNames(classes.icon, classes[item.variant])}
                />
              </div>
              <div className={classes.colEnd}>
                <Text
                  testid={`timeline-row-${i}-title`}
                  text={item.title}
                  className={classNames(classes.title, classes[item.variant])}
                />
              </div>
            </div>
            {hasContent && (
              <div className={classes.contentRow}>
                <div
                  className={classNames(
                    classes.colStart,
                    classes.colTraceAfter
                  )}
                >
                  <div
                    data-testid={`timeline-row-${i}-trace-after`}
                    className={classNames(classes.trace, classes[item.variant])}
                  />
                </div>
                <div className={classes.colEnd}>
                  <InsetList
                    testid={`inset-${i}`}
                    data={item.lines}
                    className={classes.insetList}
                  />
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Timeline;
