/* eslint-disable no-console */
import { map, size } from 'lodash';
import type { FastFindQueryParams } from 'DocumentsApp/models/FastFind';
import type { WorkOrder } from 'DocumentsApp/models/WorkOrder';
import useDocumentsDB from './initDocumentsDB';

interface UseWorkOrderDBResponse {
  createWorkOrders: (workOrders: WorkOrder[]) => Promise<void>;
  findWorkOrders: (props: FastFindQueryParams) => Promise<WorkOrder[]>;
  removeWorkOrders: (miLoc: string) => Promise<void>;
}

const useWorkOrderDB = (): UseWorkOrderDBResponse => {
  const { db, openDB, closeDB, getLikeStatement, getWhereStatement } =
    useDocumentsDB();

  const createWorkOrders = async (workOrders: WorkOrder[]): Promise<void> => {
    try {
      await openDB();
      const statements = map(workOrders, (workOrder) => ({
        statement: `INSERT OR REPLACE INTO workOrder (
          woCtlNo,
          woDesc,
          shopLoc,
          itemNo,
          miLoc,
          orderCtlNo,
          custNo,
          customerName,
          custPo,
          orderLineNo,
          requestOrigin
          ) VALUES (?,?,?,?,?,?,?,?,?,?,?)`,
        values: [
          workOrder.woCtlNo,
          workOrder.woDesc,
          workOrder.shopLoc,
          workOrder.itemNo,
          workOrder.miLoc,
          workOrder.orderCtlNo,
          workOrder.custNo,
          workOrder.customerName,
          workOrder.custPo,
          workOrder.orderLineNo,
          workOrder.requestOrigin,
        ],
      }));
      if (size(statements) > 0) {
        await db.executeSet(statements, true);
      }
    } catch (error) {
      console.log('Error saving work orders to database', error);
      throw new Error('Error saving work orders to database');
    } finally {
      await closeDB();
    }
  };

  const findWorkOrders = async ({
    fastFind,
    miLoc,
  }: FastFindQueryParams): Promise<WorkOrder[]> => {
    try {
      const whereStatement = [];
      const vars = [];
      if (fastFind) {
        whereStatement.push(
          getLikeStatement(['woCtlNo', 'woDesc', 'custNo', 'custPo'])
        );
        const likeFastFind = `%${fastFind}%`;
        vars.push(likeFastFind, likeFastFind, likeFastFind, likeFastFind);
      }
      if (miLoc) {
        whereStatement.push(getLikeStatement('shopLoc', vars));
        vars.push(`%${miLoc}%`);
      }
      await openDB();
      return (
        await db.query(
          `SELECT * FROM workOrder
          ${getWhereStatement(whereStatement)}`,
          vars
        )
      ).values as WorkOrder[];
    } catch (error) {
      console.log('Error loading work orders', error);
      throw new Error('Error loading work orders');
    } finally {
      await closeDB();
    }
  };

  const removeWorkOrders = async (miLoc: string): Promise<void> => {
    try {
      await openDB();
      await db.query(
        `DELETE FROM workOrder
        WHERE miLoc = ?`,
        [miLoc]
      );
    } catch (error) {
      console.log('Error removing work orders', error);
      throw new Error('Error removing work orders');
    } finally {
      await closeDB();
    }
  };

  return {
    createWorkOrders,
    findWorkOrders,
    removeWorkOrders,
  };
};

export default useWorkOrderDB;
