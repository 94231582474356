import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { SortingRule } from 'react-table';
import classNames from 'classnames';
import { toString, type Dictionary, debounce, head, isEmpty } from 'lodash';
import { IonCol, IonContent, IonPage } from '@ionic/react';
import type { ContactModalProps } from 'common/components/ContactModal/ContactModal';
import ContactModal from 'common/components/ContactModal/ContactModal';
import Header from 'common/components/Header/Header';
import { searchURL } from 'navigation';
import useGetOpenQuotesReport from 'ReportsApp/api/useGetOpenQuotesReports';
import { useGetSelectedMiLoc } from 'api/helpers';
import { SortDirEnum } from 'models/Sort';
import { findIcon } from 'utils/icons';
import { concatRoutes } from 'utils/navigations';
import { getReportName } from 'utils/reports';
import { handleSearchNavigation } from 'utils/search';
import Button from 'components/Button/Button';
import ChangeLocation from 'components/ChangeLocation/ChangeLocation';
import type { FilterOption } from 'components/Filter/Filter';
import Filter from 'components/Filter/Filter';
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll';
import Loader from 'components/Loader/Loader';
import Refresher from 'components/Refresher/Refresher';
import Table from 'components/Table/Table';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './OpenQuotes.module.scss';
import { useGetOpenQuotesTableData } from './openQuotesHelpers';

const OpenQuotes = (): JSX.Element => {
  const { t } = useTranslation();
  const { selectedMiLoc, team, isBranchUser, fromVirtualTeam, isMyView } =
    useGetSelectedMiLoc();
  const [sortField, setSortField] = useState<string>('creationTmstmp');
  const [sortDir, setSortDir] = useState<SortDirEnum>(SortDirEnum.DESCENDING);
  const [selectedOcnContact, setSelectedOcnContact] =
    useState<ContactModalProps>();
  const [isOpenOcnContactModal, setIsOpenOcnContactModal] =
    useState<boolean>(false);

  const namespace = 'reports:openQuote';

  const isShowChangeLocation =
    !isBranchUser && !fromVirtualTeam && !team && !isMyView;

  const createdByOptions = {
    title: t(`${namespace}:createdBy`),
    options: [
      {
        key: 'All',
        name: t(`${namespace}:allFilter`),
      },
      {
        key: 'me',
        name: t(`${namespace}:meFilter`),
      },
    ],
  };

  const orderSourceOptions = {
    title: t(`${namespace}:orderSource`),
    options: [
      {
        key: 'all',
        name: t(`${namespace}:allFilter`),
      },
      {
        key: 'web',
        name: t(`${namespace}:webFilter`),
      },
    ],
  };

  const dateCreatedOptions = {
    title: t(`${namespace}:dateCreated`),
    options: [
      {
        key: 'all',
        name: t(`${namespace}:allFilter`),
      },
      {
        key: 'today',
        name: t(`${namespace}:todayFilter`),
      },
    ],
  };

  const filterOptions = [
    createdByOptions,
    orderSourceOptions,
    dateCreatedOptions,
  ];

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [quotesCreatedByOption, setQuotesCreatedByOption] = useState<
    FilterOption | undefined
  >(createdByOptions.options[0]);
  const [quotesOrderSourceOption, setQuoteOrderSourceOption] = useState<
    FilterOption | undefined
  >(orderSourceOptions.options[0]);
  const [quotesDateCreatedOption, setQuoteDateCreatedOption] = useState<
    FilterOption | undefined
  >(dateCreatedOptions.options[0]);

  const defaultCreatedByOption =
    quotesCreatedByOption?.key === createdByOptions.options[0].key;
  const defaultOrderSourceOption =
    quotesOrderSourceOption?.key === orderSourceOptions.options[0].key;
  const defaultDateCreationOption =
    quotesDateCreatedOption?.key === dateCreatedOptions.options[0].key;

  const defaultFilter =
    defaultCreatedByOption &&
    defaultOrderSourceOption &&
    defaultDateCreationOption;

  const {
    openQuoteData,
    lastUpdatedAt,
    showLoader,
    refetch,
    isEmptyResponse,
    hasError,
    fetchNextPage,
    enableInfiniteScroll,
  } = useGetOpenQuotesReport({
    miLoc: selectedMiLoc.miLoc,
    enabled: !isShowChangeLocation,
    sortDir,
    sortField,
    fromVirtualTeam,
    onlyMe: !defaultCreatedByOption,
    onlyWeb: !defaultOrderSourceOption,
    onlyToday: !defaultDateCreationOption,
  });

  const { tableColumns } = useGetOpenQuotesTableData({
    openQuoteData,
    openContactModal: (value) => {
      setSelectedOcnContact(value);
      setIsOpenOcnContactModal(true);
    },
  });

  const showReport = !isShowChangeLocation && !hasError;

  const onSortBy = debounce(
    (sortOption: SortingRule<Dictionary<unknown>>[]) => {
      if (!isEmpty(sortOption)) {
        setSortField(toString(head(sortOption)?.id));
        const sortDirection = head(sortOption)?.desc
          ? SortDirEnum.DESCENDING
          : SortDirEnum.ASCENDING;
        setSortDir(sortDirection);
      }
    },
    300
  );

  const headerTitle =
    isBranchUser || isMyView
      ? getReportName({
          miLoc: selectedMiLoc.miLoc,
          locName: selectedMiLoc.locName,
          team,
        })
      : selectedMiLoc.locName;

  const createdByCount = defaultCreatedByOption ? 0 : 1;
  const dateCreatedCount = defaultDateCreationOption ? 0 : 1;
  const orderSourceCount = defaultOrderSourceOption ? 0 : 1;

  const filterButton = (
    <Filter
      className={classes.filter}
      initialBreakpoint={0.6}
      selectedItems={[
        quotesCreatedByOption,
        quotesOrderSourceOption,
        quotesDateCreatedOption,
      ]}
      setFilterData={[
        (option) => setQuotesCreatedByOption(option),
        (option) => setQuoteOrderSourceOption(option),
        (option) => setQuoteDateCreatedOption(option),
      ]}
      filterOptions={filterOptions}
      isOpen={isFilterOpen}
      setIsOpen={setIsFilterOpen}
      testid="openquotes-filter"
      variant="sort"
      modalTitle={t('common:filter')}
      withFooter
      customButton={{
        className: classNames(classes.filterButton, {
          [classes.filterNone]: defaultFilter,
          [classes.hasFilters]: !defaultFilter,
        }),
        text: defaultFilter
          ? t('common:filter')
          : toString(createdByCount + dateCreatedCount + orderSourceCount),
        testid: 'open-quotes-filter-button',
        children: undefined,
        rightIcon: undefined,
        icon: findIcon('sliders', 'far'),
      }}
    />
  );

  return (
    <IonPage className={classes.openQuotesPage}>
      <Header
        testid="open-quote-report-header"
        title={t('reports:openQuotes')}
        className={classes.headerClass}
        backButton={{ className: classes.backButton }}
        endSlotComponent={filterButton}
        subTitle={headerTitle}
      />
      <IonContent className={classes.openQuotesWrapper}>
        <Refresher
          slot="fixed"
          className={classes.refresher}
          lastUpdatedAt={lastUpdatedAt}
          onRefresh={refetch}
          testid="open-quote-report"
          disabled={showLoader}
          hidden
        />
        {showReport && (
          <>
            <Header
              collapse="condense"
              testid="open-quote-report"
              pageTitle={t('reports:openQuotes')}
              className={classes.header}
              customTitle={
                <div className={classes.flex}>
                  <div className={classes.customHeader}>
                    <Button
                      text={headerTitle}
                      textVariant="mipro-h2-headline"
                      variant={fromVirtualTeam || isMyView ? 'clear' : 'link'}
                      testid="location"
                      href={
                        !(fromVirtualTeam || isMyView)
                          ? concatRoutes(
                              searchURL(),
                              handleSearchNavigation({
                                type: 'motionLocation',
                                miLoc: selectedMiLoc.miLoc,
                                sequenceNo: selectedMiLoc.miLoc,
                              })
                            )
                          : undefined
                      }
                    />
                    <Text
                      text={t('reports:openQuotesReportsSubTitle')}
                      variant="mipro-body-copy"
                    />
                  </div>
                  <IonCol className={classes.filterCol}>{filterButton}</IonCol>
                </div>
              }
            />
            <Table
              columns={tableColumns}
              data={openQuoteData as unknown as Dictionary<unknown>[]}
              className={classes.table}
              tdClassName={classes.td}
              thClassName={classes.th}
              sortField={sortField}
              sortDir={sortDir}
              onSortBy={onSortBy}
            />
            <InfiniteScroll
              disabled={!enableInfiniteScroll}
              onLoadMore={fetchNextPage}
              testid="infinite-scroll"
            />
          </>
        )}
        <Loader
          className={classes.warningMessage}
          text="Loading results"
          isOpen={showLoader}
          testid="loader"
        />
        {isShowChangeLocation && (
          <ChangeLocation
            onlineMsg={t(`${namespace}:changeLocationWarningMessage`)}
            testid="open-quotes-report"
          />
        )}
        {isEmptyResponse && (
          <WarningMessage
            className={classes.warningMessage}
            icon={['far', 'info-circle']}
            title={t('reports:noReports')}
            testid="empty-response"
          />
        )}
        {hasError && (
          <WarningMessage
            className={classes.warningMessage}
            title={t('reports:errorReports')}
            testid="error"
          />
        )}
        <ContactModal
          isOpen={isOpenOcnContactModal}
          setIsOpen={setIsOpenOcnContactModal}
          testid="ocn-contact-modal"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...selectedOcnContact}
        />
      </IonContent>
    </IonPage>
  );
};

export default OpenQuotes;
