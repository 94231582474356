import { useTranslation } from 'react-i18next';
import type { MutationStatus } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import {
  doPromiseAPI,
  onSuccessMutation,
  onMutateUpdate,
  onErrorUpdate,
} from 'api/helpers';
import type { Customer, IndustryCode } from 'models/Customer';
import { ToastType } from 'models/Toast';
import { getCustomerQueryKey } from './useGetCustomer';

interface UseUpdateIndustryCodeMutationProps {
  type: string;
  miLoc: string;
  id: string;
}

interface UseUpdateIndustryCodeResponse {
  status: MutationStatus;
  isLoading: boolean;
  updateIndustryCode: (body: IndustryCode) => void;
}

const useUpdateIndustryCode = ({
  type,
  miLoc,
  id,
}: UseUpdateIndustryCodeMutationProps): UseUpdateIndustryCodeResponse => {
  const { axios } = useAxios();
  const { updateSICCode } = useAPIUrl();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const doUpdateIndustryCode = ({ codeValue }: IndustryCode) => {
    return doPromiseAPI(async (cancelToken) => {
      if (type === 'altSic') {
        return axios.post(
          updateSICCode(miLoc, id, codeValue),
          {},
          { cancelToken }
        );
      }
      return null;
    });
  };

  const { mutate, status, isLoading } = useMutation(doUpdateIndustryCode, {
    onMutate: async (vars) => {
      const context = onMutateUpdate<Customer>({
        queryClient,
        queryKey: getCustomerQueryKey,
        updatedItems: [
          {
            miLoc,
            customerNo: id,
            [type]: vars.codeValue,
            [`${type}Desc`]: vars.codeDesc,
          },
        ],
        findPredicates: [{ miLoc, customerNo: id }],
        isSingleQuery: true,
      });
      addToast({
        text: t('snapshot:successEditIndustryCode', {
          type: t(`snapshot:${type}`),
        }),
        testid: 'update-industry-code-toast',
      });
      return context;
    },
    onSuccess: () => {
      void onSuccessMutation(queryClient, getCustomerQueryKey, { miLoc, id });
    },
    onError: (error, vars, context) => {
      addToast({
        type: ToastType.error,
        text: t('snapshot:errorEditIndustryCode', {
          type: t(`snapshot:${type}`),
        }),
        testid: 'update-industry-code-error-toast',
      });
      onErrorUpdate<Customer>({
        queryClient,
        context,
        pickProps: [type, `${type}Desc`],
        isSingleQuery: true,
      });
    },
  });

  return {
    status,
    isLoading,
    updateIndustryCode: (body: IndustryCode) => mutate(body),
  };
};

export default useUpdateIndustryCode;
