import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonCol, IonRow } from '@ionic/react';
import type { BaseComponentProps } from 'common/components/utils/renderHelpers';
import { findIcon } from 'utils/icons';
import Text from 'components/Text/Text';
import classes from './CostSavingsIndicator.module.scss';

interface CostSavingIndicatorProps extends BaseComponentProps {
  earMode: boolean;
}

const CostSavingsIndicator = ({
  earMode,
  testid,
  className,
}: CostSavingIndicatorProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <IonRow data-testid={testid}>
      <IonCol
        className={classNames(classes.approvalWrapper, className, {
          [classes.earMode]: earMode,
          [classes.nonEarMode]: !earMode,
        })}
      >
        <FontAwesomeIcon
          icon={findIcon(earMode ? 'check-circle' : 'times-circle', 'fas')}
          data-testid="indicator-icon"
        />
        <Text
          text={
            earMode
              ? t('costSavings:electronicApproval')
              : t('costSavings:noElectronicApproval')
          }
          variant="mipro-h6-headline"
          testid="indicator-text"
        />
      </IonCol>
    </IonRow>
  );
};

export default CostSavingsIndicator;
