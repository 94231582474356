import React, { useEffect, useState } from 'react';
import {
  getFileNameFromURL,
  hrefFromAnchorTag,
  imageSourceFromImgTag,
} from 'constants/regex';
import { isNil, toString, replace } from 'lodash';
import { File } from '@awesome-cordova-plugins/file';
import { PreviewAnyFile } from '@awesome-cordova-plugins/preview-any-file';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { isPlatform } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import { API_URL } from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import { ToastType } from 'models/Toast';
import {
  mimetypeFromExtension,
  sanitizeContent,
  UNSUPPORTED_FILE_TYPE,
} from 'utils/announcements';
import {
  CLOUDFLARE_CLIENT_ID,
  CLOUDFLARE_CLIENT_SECRET,
  isCloudflareConfigured,
} from 'utils/cloudflare';
import { getFileExtension } from 'utils/filesUpload';
import noFeatureImage from 'assets/noFeatureImage.jpg';
import Loader from 'components/Loader/Loader';

interface FilePreviewProps {
  className?: string;
  fileContent: string;
  allowedTags?: string[];
  featureCard?: boolean;
  loaderClassname?: string;
}

const FilePreview = ({
  className,
  fileContent,
  allowedTags,
  featureCard,
  loaderClassname,
}: FilePreviewProps): JSX.Element => {
  const [isDownloading, setIsDownloading] = useState(false);
  const { axios } = useAxios();
  const [imgSrc, setImgSrc] = useState<string>();
  const [aHref, setAHref] = useState<string>('');
  const { addToast } = useToasts();

  const sanitizedContent = sanitizeContent(toString(fileContent), allowedTags);

  useEffect(() => {
    const imgScrFromImgTag = sanitizedContent.match(imageSourceFromImgTag)?.[1];
    const getImage = async () => {
      // imgScrFromImgTag example: /api/announcements/link?path=/motion3/images/Fea-Mi-Breaux-on-the-Geaux-Texas-011723.jpg&token=e67dc39d-d02e-44c7-9441-e48e3a33a8d4
      if (!isNil(imgScrFromImgTag)) {
        const imageUrl =
          Capacitor.getPlatform() === 'web'
            ? imgScrFromImgTag
            : `${toString(
                process.env.REACT_APP_IMAGES_URL
              )}${imgScrFromImgTag}`;
        const res = await axios.get(imageUrl, {
          responseType: 'blob',
        });
        const resDataBlob = res.data as Blob;
        setImgSrc(window.URL.createObjectURL(resDataBlob));
      } else {
        // TODO What should be image height, it should match other feature images
        setImgSrc(noFeatureImage);
      }
    };
    void getImage();
    const hrefFromATag = sanitizedContent.match(hrefFromAnchorTag)?.[1];
    if (!isNil(hrefFromATag)) {
      setAHref(hrefFromATag);
    }
  }, [axios, sanitizedContent]);

  const downloadAndPreviewFile = async (downloadUrl: string) => {
    try {
      setIsDownloading(true);
      const fileName = toString(downloadUrl.match(getFileNameFromURL)?.[0]);
      const fileExtension = toString(getFileExtension({ fileName }));
      const mimeType = mimetypeFromExtension(fileExtension);
      if (mimeType === UNSUPPORTED_FILE_TYPE) {
        addToast({
          type: ToastType.error,
          text: UNSUPPORTED_FILE_TYPE,
          testid: 'unsupported-file-type-error-toast',
        });
        return;
      }
      const baseDirectory = isPlatform('android')
        ? File.externalDataDirectory
        : File.documentsDirectory;
      const newURL = new URL(downloadUrl);
      const fetchURL = `${API_URL}/announcements/link${newURL.search}`;

      const headers = new Headers();

      if (isCloudflareConfigured) {
        headers.append('CF-Access-Client-Id', CLOUDFLARE_CLIENT_ID);
        headers.append('CF-Access-Client-Secret', CLOUDFLARE_CLIENT_SECRET);
      }

      const fetchBlob = await fetch(fetchURL, {
        headers,
      });

      if (!fetchBlob.ok) {
        throw new Error(fetchBlob.statusText);
      }
      const fileBlob = await fetchBlob.blob();

      // raname file to avoid system permission issue of duplicate names file
      const renamedFile = `${fileName.substring(
        0,
        fileName.lastIndexOf(fileExtension)
      )}-${replace(
        new Date().toISOString(),
        new RegExp(':', 'g'),
        '-'
      )}${fileExtension}`;
      await File.writeExistingFile(baseDirectory, renamedFile, fileBlob);
      PreviewAnyFile.previewPath(`${baseDirectory}${renamedFile}`, {
        mimeType,
      }).subscribe();
    } catch (e) {
      Sentry.captureException(e);
      if (e instanceof Error) {
        addToast({
          type: ToastType.error,
          text: 'Download file operation failed. Please try again later.',
          testid: 'save-file-error-toast',
        });
      } else {
        addToast({
          type: ToastType.error,
          text: 'Failed to save file. Please ensure Mi Pro has been granted file system access.',
          testid: 'download-file-error-toast',
        });
      }
    } finally {
      setIsDownloading(false);
    }
  };

  const clickHandler = (event: React.SyntheticEvent) => {
    event.preventDefault();

    // defines the types of elements that support the href attribute
    const allowedElements = ['a'];
    let target = event.target as Element;

    if (!target.hasAttribute('href')) {
      target = target.closest('a[href]') as Element;
    }

    try {
      const href = target.hasAttribute('href') && target.getAttribute('href');
      const isAllowedElement = allowedElements.includes(
        target.tagName.toLowerCase()
      );

      if (isAllowedElement && href) {
        const newUrl = new URL(toString(href));
        if (
          Capacitor.getPlatform() === 'web' ||
          newUrl.pathname !== '/api/announcements/link'
        ) {
          void Browser.open({ url: newUrl.href });
        } else {
          void downloadAndPreviewFile(href);
        }
      }
      // eslint-disable-next-line no-empty
    } catch (targetError) {}
  };

  return (
    <>
      {featureCard ? (
        <div className={className}>
          {aHref ? (
            <a href={aHref} onClick={clickHandler}>
              <img src={imgSrc} alt="FeatureImage" />
            </a>
          ) : (
            <img src={imgSrc} alt="FeatureImage" />
          )}
        </div>
      ) : (
        <div
          onClick={clickHandler}
          className={className}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: sanitizedContent,
          }}
          role="presentation"
        />
      )}
      <Loader
        className={loaderClassname}
        text="Downloading file"
        isOpen={isDownloading}
      />
    </>
  );
};

export default FilePreview;
