import { useTranslation } from 'react-i18next';
import { get, map, toString } from 'lodash';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { MutationStatus } from '@tanstack/react-query';
import useAPIUrl from 'api';
import type {
  CostSavings,
  CostSavingsBaseModel,
} from 'CostSavingsApp/models/CostSavings';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import { findAttachmentsQueryKey } from 'api/attachments/useFindAttachments';
import type { UpdateMutationContext } from 'api/helpers';
import {
  doPromiseAPI,
  onErrorUpdate,
  onMutateUpdate,
  onSuccessMutation,
} from 'api/helpers';
import { ToastType } from 'models/Toast';
import { formatCardDate, parseDate } from 'utils/date';
import { findIcon } from 'utils/icons';
import { findCostSavingsQueryKey } from './useFindCostSavings';

interface EmailBody {
  cc: string[];
}

export interface SubmitCostSavingsBody extends CostSavingsBaseModel {
  miLoc: string;
  customerNo?: string;
  corpAcctNo?: string;
  vasCode: string;
  newVasCode?: string;
  vasCtlNo?: string;
  vasExtPrice?: number;
  vas_creationUserName?: string;
  vas_description?: string;
  reminderDate?: string;
  costSavingsStatus?: string;
  filesToUpload?: File[];
  filesToDelete?: string[];
  sendEmail?: string;
  email?: EmailBody;
}

interface SubmitCostSavingsAPIResponse {
  miLoc: string;
  nextApproverAcct: string;
  vasCtlNo: string;
  errors?: Array<{ field: string; message: string; reference_code: string }>;
}

interface UseSubmitCostSavingsResponse {
  status: MutationStatus;
  onSubmitCostSavings: (body: SubmitCostSavingsBody) => void;
}

const useSubmitCostSavings = (): UseSubmitCostSavingsResponse => {
  const { axios } = useAxios();
  const { submitCostSavingsAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { t, i18n } = useTranslation();
  const { addToast } = useToasts();

  const doSubmitCostSavings = ({
    filesToUpload,
    filesToDelete,
    ...body
  }: SubmitCostSavingsBody) => {
    return doPromiseAPI<SubmitCostSavingsAPIResponse>(async () => {
      const formData = new FormData();
      formData.append(
        'data',
        JSON.stringify({ ...body, deleteFiles: filesToDelete })
      );

      await Promise.all(
        map(filesToUpload, async (file) => {
          const fileName = toString(get(file, 'fileName'));
          if (file.type.startsWith('image/')) {
            const fileData: ArrayBuffer = await new Promise((resolve) => {
              const reader = new FileReader();
              reader.onload = (fileResult) => {
                resolve(fileResult.target?.result as ArrayBuffer);
              };
              reader.readAsArrayBuffer(file);
            });
            const fileBlob = new Blob([new Uint8Array(fileData)], {
              type: file.type,
            });
            formData.append(fileName, fileBlob, fileName);
          } else {
            formData.append(fileName, file);
          }
        })
      );
      let data: SubmitCostSavingsAPIResponse;
      if (body.vasCtlNo) {
        ({ data } = await axios.put<SubmitCostSavingsAPIResponse>(
          submitCostSavingsAPI(),
          formData
        ));
      } else {
        ({ data } = await axios.post<SubmitCostSavingsAPIResponse>(
          submitCostSavingsAPI(),
          formData
        ));
      }
      return data;
    });
  };

  const { status, mutate } = useMutation(doSubmitCostSavings, {
    onMutate: (vars) => {
      return onMutateUpdate<CostSavings>({
        queryClient,
        queryKey: findCostSavingsQueryKey,
        updatedItems: [
          {
            vasExtPrice: vars.vasExtPrice,
            creationUserName: vars.vas_creationUserName,
            description: vars.vas_description,
            costSavingsStatus: vars.costSavingsStatus,
          } as unknown as CostSavings,
        ],
        dataPath: 'items',
        findPredicates: [
          {
            miLoc: vars.miLoc,
            customerNo: vars.customerNo || vars.corpAcctNo,
            vasCtlNo: vars.vasCtlNo,
          },
        ],
        isInfiniteQuery: true,
      });
    },
    onSuccess: (data, vars) => {
      void onSuccessMutation(queryClient, findCostSavingsQueryKey, {
        ...(vars.customerNo
          ? { custFastFind: `${vars.miLoc}${toString(vars.customerNo)}` }
          : undefined),
        ...(vars.corpAcctNo
          ? { natlAcctFastFind: `${vars.miLoc}${toString(vars.corpAcctNo)}` }
          : undefined),
      });
      void onSuccessMutation(queryClient, findAttachmentsQueryKey, {
        domain: 'vas',
        miLoc: vars.miLoc,
        ctlNo: vars.vasCtlNo,
      });
      // DOC: errors first so success toast is always at the bottom
      if (data.errors) {
        map(data.errors, ({ field, message, reference_code }, index) => {
          addToast({
            type: ToastType.error,
            text: `${message} ${reference_code} ${t('common:tryAgainLater')}`,
            testid: `${field}-error-toast-${index}`,
          });
        });
      }
      if (vars.costSavingsStatus === 'I') {
        addToast({
          type: ToastType.alert,
          variant: 'mipro-toast',
          header: t('costSavings:reminderCreated'),
          text: t('costSavings:reminderText', {
            formatDate: formatCardDate(
              parseDate(vars.reminderDate),
              true,
              false,
              i18n.language
            ),
          }),
          icon: findIcon('clock'),
          testid: 'submit-cost-savings-draft-success-toast',
        });
      }
      if (vars.costSavingsStatus === 'P') {
        let successTitle = t('costSavings:csSubmittedTitle');
        if (vars.sendEmail === 'A') {
          successTitle = t('costSavings:csEmailed');
        }
        let successMessage = t('costSavings:csSubmittedMessage');
        if (vars.signature) {
          successMessage = t('costSavings:csSignedMessage');
        }
        if (vars.sendEmail === 'A') {
          successMessage = t('costSavings:csEmailDesc');
        }
        addToast({
          type: ToastType.default,
          variant: 'mipro-toast',
          header: successTitle,
          text: successMessage,
          testid: 'submit-cost-savings-success-toast',
        });
      }
    },
    onError: (error, vars, context) => {
      addToast({
        type: ToastType.error,
        text:
          vars.costSavingsStatus === 'I'
            ? t('costSavings:saveDraftError')
            : t('costSavings:submitCsError'),
        testid: 'submit-cost-savings-error-toast',
      });
      onErrorUpdate<CostSavings>({
        queryClient,
        context: context as UpdateMutationContext<CostSavings>[],
        isInfiniteQuery: true,
      });
    },
  });

  return {
    status,
    onSubmitCostSavings: (body: SubmitCostSavingsBody) => mutate(body),
  };
};

export default useSubmitCostSavings;
