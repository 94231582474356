/* eslint-disable no-console */
import { map, size } from 'lodash';
import type { FastFindQueryParams } from 'DocumentsApp/models/FastFind';
import type { Site } from 'DocumentsApp/models/Site';
import useDocumentsDB from './initDocumentsDB';

interface UseSiteDBResponse {
  createSites: (sites: Site[]) => Promise<void>;
  findSites: (props: FastFindQueryParams) => Promise<Site[]>;
  removeSites: (miLoc: string) => Promise<void>;
}

const useSiteDB = (): UseSiteDBResponse => {
  const { db, openDB, closeDB, getLikeStatement, getWhereStatement } =
    useDocumentsDB();

  const createSites = async (sites: Site[]): Promise<void> => {
    try {
      await openDB();
      const statements = map(sites, (site) => ({
        statement: `INSERT OR REPLACE INTO site (
          siteId,
          siteDesc,
          siteMiLoc,
          siteCustNo,
          displayText,
          siteName,
          custSiteId,
          customerName
          ) VALUES (?,?,?,?,?,?,?,?)`,
        values: [
          site.siteId,
          site.siteDesc,
          site.siteMiLoc,
          site.siteCustNo,
          site.displayText,
          site.siteName,
          site.custSiteId,
          site.customerName,
        ],
      }));
      if (size(statements) > 0) {
        await db.executeSet(statements, true);
      }
    } catch (error) {
      console.log('Error saving sites to database', error);
      throw new Error('Error saving sites to database');
    } finally {
      await closeDB();
    }
  };

  const findSites = async ({
    fastFind,
    miLoc,
  }: FastFindQueryParams): Promise<Site[]> => {
    try {
      const whereStatement = [];
      const vars = [];
      if (fastFind) {
        whereStatement.push(
          getLikeStatement(['siteId', 'siteDesc', 'siteCustNo'])
        );
        const likeFastFind = `%${fastFind}%`;
        vars.push(likeFastFind, likeFastFind, likeFastFind);
      }
      if (miLoc) {
        whereStatement.push(getLikeStatement('siteMiLoc', vars));
        vars.push(`%${miLoc}%`);
      }
      await openDB();
      return (
        await db.query(
          `SELECT * FROM site
          ${getWhereStatement(whereStatement)}`,
          vars
        )
      ).values as Site[];
    } catch (error) {
      console.log('Error loading sites', error);
      throw new Error('Error loading sites');
    } finally {
      await closeDB();
    }
  };

  const removeSites = async (miLoc: string): Promise<void> => {
    try {
      await openDB();
      await db.query(
        `DELETE FROM site
        WHERE siteMiLoc = ?`,
        [miLoc]
      );
    } catch (error) {
      console.log('Error removing sites', error);
      throw new Error('Error removing sites');
    } finally {
      await closeDB();
    }
  };

  return {
    createSites,
    findSites,
    removeSites,
  };
};

export default useSiteDB;
