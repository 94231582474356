import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Virtuoso } from 'react-virtuoso';
import classNames from 'classnames';
import { isEmpty, isObject, size, toString } from 'lodash';
import type { IconName } from '@fortawesome/fontawesome-svg-core';
import { IonCol, IonRow } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import Input from 'common/components/Forms/Input/Input';
import { DataTypeEnum, formatKeyValue } from 'common/utils/valueFormatter';
import { useDebounce } from 'use-debounce';
import {
  type InfiniteQueryFlags,
  SearchSuggestionTypeEnum,
  type SelectModalItem,
} from 'models/Search';
import { getErrorMessage } from 'utils/helpers';
import { findIcon } from 'utils/icons';
import Button from 'components/Button/Button';
import Loader from 'components/Loader/Loader';
import Modal from 'components/Modal/Modal';
import SearchSuggestionCard from 'components/Search/SearchSuggestionCard/SearchSuggestionCard';
import Searchbar from 'components/Searchbar/Searchbar';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './SelectModal.module.scss';

interface SelectModalProps {
  type?: string;
  subType?: string;
  modalTitle?: string;
  selItem?: SelectModalItem;
  items: SelectModalItem[];
  searchQuery: string;
  setSearchQuery: (v: string) => void;
  setSelItem: (o: SelectModalItem) => void;
  testid: string;
  showInput?: boolean;
  className?: string;
  placeholder?: string;
  iconClassName?: string;
  customContent?: React.ReactNode;
  label?: string;
  value: string;
  required?: boolean;
  disabled?: boolean;
  inputName?: string;
  inputError?: string;
  onClick?: () => void;
  onBlur?: () => void;
  icon?: string;
  hasSegments?: boolean;
  onClose?: () => void;
  allowInputEdit?: boolean;
  maxlength?: number;
  searchLink?: string;
  isReadOnly?: boolean;
}

const SelectModal = ({
  isOpen,
  setIsOpen,
  modalTitle,
  type = '',
  subType = '',
  selItem,
  error,
  enableInfiniteScroll,
  fetchNextPage,
  showLoader,
  isEmptyResponse,
  items,
  searchQuery,
  setSearchQuery,
  setSelItem,
  testid,
  showInput = true,
  className,
  placeholder = '',
  iconClassName,
  customContent,
  label,
  value,
  required,
  disabled,
  inputName,
  inputError,
  onClick,
  onBlur,
  onClose,
  icon = 'search',
  hasSegments = false,
  withCloseButton = true,
  allowInputEdit = false,
  maxlength,
  searchLink,
  isReadOnly,
}: SelectModalProps &
  React.ComponentProps<typeof Modal> &
  InfiniteQueryFlags &
  IonicReactProps): JSX.Element => {
  const { t } = useTranslation();
  const [internalSearchQuery, setInternalSearchQuery] = useState(searchQuery);
  const [searchQueryValue] = useDebounce(internalSearchQuery, 300);
  const [initialFocus, setInitialFocus] = useState(0);

  const showSelectedLine = size(selItem?.key) > 0;

  useEffect(() => {
    if (isOpen) {
      setSearchQuery(searchQueryValue);
      if (searchQueryValue.length === 0 && items.length === 0) {
        setTimeout(() => {
          setInitialFocus(Date.now());
        }, 150);
      }
    } else if (!isOpen) {
      setInternalSearchQuery('');
    }
  }, [isOpen, items, searchQueryValue, selItem, setSearchQuery, setSelItem]);

  return (
    <>
      {showInput && (
        <IonRow className={className}>
          <IonCol size="12">
            <Input
              readonly={isReadOnly}
              label={label}
              name={toString(inputName)}
              value={value}
              placeholder={placeholder}
              testid={`input-${testid}`}
              rightButton={
                !searchLink
                  ? {
                      icon: findIcon(icon as IconName),
                      testid: 'mag-button',
                      className: iconClassName,
                      onClick: () => {
                        if (!disabled && !isReadOnly) {
                          onClick?.();
                        }
                      },
                    }
                  : undefined
              }
              required={required}
              disabled={disabled}
              error={inputError}
              onFocus={() => {
                if (!allowInputEdit && !isReadOnly) {
                  // DOC: always open modal to avoid user entering text
                  setIsOpen?.(true);
                }
              }}
              onBlur={onBlur}
              setValue={(v) => {
                if (allowInputEdit) {
                  setSelItem({ title: v, key: v });
                }
              }}
              maxlength={maxlength}
              onClick={() => {
                if (!disabled && !allowInputEdit && !isReadOnly) {
                  onClick?.();
                }
              }}
              focusable={allowInputEdit}
            />
          </IonCol>
          {searchLink && (
            <Button
              className={classes.searchLink}
              variant="link"
              onClick={() => {
                if (!disabled && !isReadOnly) {
                  onClick?.();
                }
              }}
              textVariant="mipro-report-info"
              testid={`${testid}-search-link`}
              text={searchLink}
            />
          )}
        </IonRow>
      )}
      <Modal
        className={classes.modal}
        headerClassName={classes.header}
        isOpen={isOpen}
        withTitleLine={false}
        forceFullHeight
        title={
          modalTitle ||
          t('snapshot:selectAlternateTitle', {
            type: t(`snapshot:${type}`),
          })
        }
        setIsOpen={setIsOpen}
        withCloseButton={withCloseButton}
        onClose={onClose}
        header={
          <>
            <div className={classes.searchWrapper}>
              <Searchbar
                className={classes.searchbar}
                value={internalSearchQuery}
                setValue={setInternalSearchQuery}
                testid="search-input"
                triggerInputSelect={initialFocus}
              />
            </div>
            {customContent}
            <div
              className={classNames({
                [classes.noSelectedItem]: !showSelectedLine,
                [classes.segmentMargins]: hasSegments,
                [classes.clear]: !hasSegments && showSelectedLine,
              })}
            >
              {showSelectedLine && (
                <>
                  <div className={classes.selectedText}>
                    <Text
                      text={t('snapshot:currentAlternateSelected', {
                        selected: selItem?.title || t('common:none'),
                      })}
                    />
                  </div>
                  <Button
                    className={classNames({
                      [classes.disabled]: isEmpty(selItem?.key),
                    })}
                    variant="secondary"
                    text={t('snapshot:clearAlternate', {
                      type: t(`snapshot:${subType}`),
                    })}
                    onClick={() => {
                      setSelItem({ title: '', description: '', key: '' });
                    }}
                    disabled={isEmpty(selItem?.key)}
                    testid="clear-alternate-button"
                  />
                </>
              )}
            </div>
          </>
        }
        testid={testid}
      >
        <div className={classes.list}>
          {!error && !isEmpty(items) && (
            <Virtuoso
              className="ion-content-scroll-host"
              data={items}
              increaseViewportBy={{ top: 500, bottom: 500 }}
              endReached={enableInfiniteScroll ? fetchNextPage : undefined}
              itemContent={(index, it) => {
                const isSelected = it.key === selItem?.key;
                const showAvatar =
                  it.showAvatar ||
                  isObject(it.customerItem) ||
                  isObject(it.contactItem);
                return (
                  <SearchSuggestionCard
                    key={it.key}
                    className={classNames(classes.item, {
                      [classes.selectedItem]: isSelected,
                    })}
                    onClick={() => {
                      setSelItem(it);
                      setIsOpen?.(false);
                    }}
                    testid={`item-${it.key}`}
                    text={it.title}
                    searchSuggestionType={SearchSuggestionTypeEnum.result}
                    textQuery={internalSearchQuery}
                    address={
                      it.customerItem?.address ??
                      (it.contactItem?.address &&
                        formatKeyValue({
                          value: it.contactItem?.address,
                          type: DataTypeEnum.phone,
                        }))
                    }
                    description={it.description}
                    showAvatar={showAvatar}
                    account={it.customerItem?.account}
                    id={it.customerItem?.id}
                    isMainCard={it.contactItem?.isMainCard}
                  />
                );
              }}
            />
          )}
          {isEmpty(items) &&
            !searchQuery &&
            !isEmptyResponse &&
            !error &&
            !showLoader && (
              <WarningMessage
                className={classes.warningMessage}
                icon={['far', 'info-circle']}
                title={t('common:startSearch')}
              />
            )}
          {!showLoader && isEmptyResponse && (
            <WarningMessage
              className={classes.warningMessage}
              icon={['far', 'info-circle']}
              title={t('common:noResults')}
            />
          )}
          {error && (
            <WarningMessage
              className={classes.warningMessage}
              title={t('reports:errorReports')}
              body={getErrorMessage(error)}
            />
          )}
          <Loader
            className={classes.loader}
            text={t('reports:loadingReports')}
            isOpen={showLoader}
          />
        </div>
      </Modal>
    </>
  );
};

export default SelectModal;
