import type { ItemPOU } from './ItemPOU';

export enum IssueTypeEnum {
  issue = 'issue',
  return = 'return',
}

export enum IssueStatusEnum {
  open = 'open',
  close = 'close',
}

export interface Issue {
  id?: number;
  recordType: IssueTypeEnum;
  userId: string;
  userName: string;
  miLocation: string;
  storeroomNumber: string;
  creationTimestamp?: number;
  syncTimestamp?: number;
  takeOnStatus: IssueStatusEnum;
  chargeBack1?: string;
  chargeBack2?: string;
  chargeBack3?: string;
  chargeBack4?: string;
  chargeBack5?: string;
  pendingItems?: number;
}

export interface ItemIssue {
  id?: number;
  itemId: string;
  issueId: number;
  issueQuantity: number;
  itemName?: string;
  error?: string;
}

export interface ItemIssueDTO extends ItemPOU {
  id: number;
  issueId: number;
  issueQuantity: number;
  itemId: string;
  error?: string;
}

export interface IssueSearchParams {
  storeroom: string;
  issueId: string;
}
