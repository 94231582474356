import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { includes, isEmpty, isNil, toNumber, toString } from 'lodash';
import { IonContent, IonPage, IonRow, IonToolbar } from '@ionic/react';
import { and, ifFunction, or } from 'common/utils/logicHelpers';
import { setMonth, startOfToday, startOfYear, subYears } from 'date-fns';
import { namespaces } from 'i18n/i18n.constants';
import { searchURL } from 'navigation';
import {
  useReportsConfig,
  TabSalesPerformanceKey,
  ProductGroupKey,
  TabPick12ReportKey,
  TabWebSalesReportKey,
  TabCostSavingsReportKey,
  TabUnbilledReportKey,
} from 'providers/ReportsProvider';
import { useTabReset } from 'providers/TabResetProvider';
import type { ReportItemType } from 'ReportsApp/models';
import { goToEndOfMonthReport } from 'ReportsApp/navigation/navigationHelpers';
import { useDebounce } from 'use-debounce';
import useGetCustomer from 'api/customer/useGetCustomer';
import useGetAccountRep from 'api/location/useGetAccountRep';
import useGetLocation from 'api/location/useGetLocation';
import useGetProductCategory from 'api/location/useGetProductCategory';
import useGetCalendarDays from 'api/salesReports/useGetCalendarDays';
import useAccessControls, { AccessControlType } from 'hooks/useAccessControls';
import type { Customer } from 'models/Customer';
import type {
  AccountRep,
  DrillDownLocation,
  ProductCategory,
  ReportsURLParams,
} from 'models/Reports';
import type { RootState } from 'store/reducers';
import { REPORTS } from 'utils/constants';
import { getBusDayMap, getBusinessDays, getHolidays } from 'utils/date';
import { concatRoutes } from 'utils/navigations';
import {
  GetIncludeDailyTotalsMsg,
  getReportName,
  getUserRole,
} from 'utils/reports';
import { getSnapshotEyebrow, handleSearchNavigation } from 'utils/search';
import EndOfMonthDashboard from 'assets/EndOfMonthDashboard.svg';
import ActionRow from 'components/ActionRow/ActionRow';
import Button from 'components/Button/Button';
import SnapshotLink from 'components/Contacts/SnapshotLink/SnapshotLink';
import DateToolbar from 'components/DateToolbar/DateToolbar';
import Header from 'components/Header/Header';
import OverlayInfobar from 'components/Overlays/OverlayInfoBar';
import DrillDown from './DrillDown/DrillDown';
import classes from './Reports.module.scss';

interface ReportsProps {
  reportType?: ReportItemType;
}

const Reports = ({ reportType: pReportType }: ReportsProps): JSX.Element => {
  const { t } = useTranslation(namespaces.reports);
  const canViewCustomers = useAccessControls(AccessControlType.viewCustomers);

  const reportType = toString(pReportType) as ReportItemType;

  const {
    miLoc: stateMiLoc = '',
    locationTree = {},
    isCamUser,
  } = useSelector((state: RootState) => state.user);
  const stateLocation = locationTree?.[stateMiLoc];
  const team = stateLocation?.locationType === 'T';
  const fromVirtualTeam = stateLocation?.locationType === 'VT';
  let stateMiLocName = '';
  if (!fromVirtualTeam) {
    stateMiLocName = getReportName({
      miLoc: stateMiLoc,
      locName: stateLocation?.name,
      team,
    });
  }

  const {
    miLoc: routeMiLoc = '',
    teamId = '',
    id = '',
    tab = '',
    orgType = '',
    pgc1 = '',
  } = useParams<ReportsURLParams>();

  const withReportType = !isEmpty(reportType);

  const miLoc: string = routeMiLoc || teamId || stateMiLoc;
  const userRole = locationTree[miLoc]?.userRole || 'EXEC';

  const withMiLoc = !isEmpty(routeMiLoc);
  const withId = !isEmpty(id);
  const withPgc1 = !isEmpty(pgc1);
  const withRep = orgType === 'REP';
  const withTeams = orgType === 'TEAM';
  const withCustomer =
    withMiLoc &&
    withId &&
    (includes(['CUST', 'PRD_GRP_01'], orgType) || tab === 'search');
  const withCustomerTab = withCustomer && tab === 'search';
  const isUnbilledReport = reportType === 'unbilled';

  const { data: customerData } = useGetCustomer({
    searchType: 'customer',
    miLoc: routeMiLoc,
    id: withCustomer ? id : '',
  });
  const { data: locationData } = useGetLocation({
    miLoc: routeMiLoc,
    enabled: !!id,
  });
  // TODO: data not available on refresh of page, ask for customer drilldown to get it?
  const { data: pgc1Data } = useGetProductCategory({
    miLoc: routeMiLoc,
    pgc1,
    enabled: withPgc1,
  });
  const { data: accountRepData } = useGetAccountRep({
    miLoc: routeMiLoc,
    id,
    enabled: withRep,
  });
  let data:
    | Customer
    | DrillDownLocation
    | AccountRep
    | ProductCategory
    | undefined = locationData;
  if (withId) {
    data = customerData;
  }
  if (withPgc1) {
    data = pgc1Data;
  }
  if (and(or(withRep, isCamUser), !isUnbilledReport)) {
    data = accountRepData;
  }

  const { data: calendar } = useGetCalendarDays({});

  // DOC: memoizing expensive calculations
  const businessDays = useMemo(() => getBusinessDays(calendar), [calendar]);
  const busDayMap = useMemo(() => getBusDayMap(calendar), [calendar]);
  const holidays = useMemo(() => getHolidays(calendar), [calendar]);

  let reportKey = '';
  if (tab === 'search') {
    reportKey = ProductGroupKey;
  } else if (includes(['pick12', 'locationPick12'], reportType)) {
    reportKey = TabPick12ReportKey;
  } else if (includes(['webSales'], reportType)) {
    reportKey = TabWebSalesReportKey;
  } else if (includes(['costSavings'], reportType)) {
    reportKey = TabCostSavingsReportKey;
  } else if (includes(['unbilled'], reportType)) {
    reportKey = TabUnbilledReportKey;
  } else {
    reportKey = TabSalesPerformanceKey;
  }

  const {
    requestType,
    busPeriod,
    updateRequestType,
    updateBusPeriod,
    sortField,
    sortDir,
    updateSortField,
    updateSortDir,
  } = useReportsConfig({
    key: reportKey,
  });

  const salesPerformance = reportType === 'sales';
  const pickTwelve = reportType === 'pick12';
  const locationPick12 = reportType === 'locationPick12';
  const isWebSalesReport = reportType === 'webSales';
  const isPick12Report = pickTwelve || locationPick12;
  const isTableView = isPick12Report || isWebSalesReport || isUnbilledReport;

  let eyebrow = '';
  let title = '';
  let reportTypeString = 'common:sales';
  if (reportType === 'profit') {
    reportTypeString = 'common:profit';
  }

  reportTypeString = t(reportTypeString);
  if (withReportType) {
    eyebrow = reportTypeString;
    if (and(withMiLoc, withId, withRep)) {
      if (isTableView) {
        title = data?.name || 'Loading';
      } else {
        title = data?.name ? `${id}: ${data?.name}` : 'Loading';
      }
    } else if (withMiLoc && withId) {
      if (withPgc1 && isTableView) {
        title = customerData?.name ? customerData?.name : 'Loading';
      } else {
        title = data?.name ? data?.name : 'Loading';
      }
    } else if (withMiLoc) {
      title =
        data?.name && data?.miLoc ? `${data?.miLoc}: ${data?.name}` : 'Loading';
    } else if (fromVirtualTeam && !stateMiLocName) {
      const teamLocation = locationTree?.[teamId];
      title = teamLocation?.name || stateLocation.name || title;
    } else {
      title = stateMiLocName;
    }
  }

  ifFunction(and(fromVirtualTeam, withTeams, !isUnbilledReport), () => {
    title = toString(data?.name);
  });

  if (withCustomerTab) {
    eyebrow = getSnapshotEyebrow({ name: data?.name, miLoc: routeMiLoc, id });
    if (withReportType) {
      eyebrow = getSnapshotEyebrow({
        name: data?.name,
        miLoc: routeMiLoc,
        id,
        prefix: `${reportTypeString} - `,
      });
      title = data?.name || 'Loading';
    }
  }

  const { triggerResetTabMap, restoreTab } = useTabReset();
  const triggerResetReportsTab = triggerResetTabMap?.[REPORTS];

  useEffect(() => {
    if (!withReportType && toNumber(triggerResetReportsTab) > 0) {
      updateRequestType();
      updateBusPeriod(startOfToday());
      restoreTab(REPORTS);
    }
  }, [
    withReportType,
    triggerResetReportsTab,
    restoreTab,
    updateBusPeriod,
    updateRequestType,
  ]);

  const { includeDailyTotal } = GetIncludeDailyTotalsMsg({
    requestType,
    busPeriod,
  });

  const [debouncedUpdateBusPeriod, setDebouncedUpdateBusPeriod] =
    useState<boolean>();
  const [debouncedBusPeriod] = useDebounce(busPeriod, 1000);
  const reportBusPeriod = debouncedUpdateBusPeriod
    ? debouncedBusPeriod
    : busPeriod;

  let dateToolbarReportKey = '';
  if (isPick12Report) {
    dateToolbarReportKey = TabPick12ReportKey;
  }
  if (isWebSalesReport) {
    dateToolbarReportKey = TabWebSalesReportKey;
  }

  let dateToolbarMinDate: Date | undefined;
  if (isPick12Report) {
    const startOfJanuary2023 = setMonth(startOfYear(new Date(2023, 0, 1)), 0);
    dateToolbarMinDate = subYears(startOfJanuary2023, 1);
  }

  const withReportDrilldown = withReportType;
  const withCustomerLink = withCustomer && tab !== 'search' && !isTableView;
  const withEndOfMonthLink = !withCustomerLink && salesPerformance;
  const withDateToolbar = !isUnbilledReport;
  const contentRef = useRef<HTMLIonContentElement | null>(null);

  let stateUserRole = userRole || 'EXEC';
  stateUserRole = getUserRole(stateUserRole);
  const headerUserRole = orgType || stateUserRole;

  return (
    <IonPage className={classes.reports} data-testid="Reports">
      <Header
        title={!isTableView ? title : ''}
        eyebrow={!isTableView ? eyebrow : ''}
        withBackButton
        testid={`Reports-${reportType}`}
      >
        {withDateToolbar && (
          <DateToolbar
            testid="Reports"
            busPeriod={busPeriod}
            requestType={requestType}
            updateBusPeriod={(date, waitFor) => {
              if (!isNil(waitFor)) {
                setDebouncedUpdateBusPeriod(waitFor);
              }
              updateBusPeriod(date);
            }}
            updateRequestType={updateRequestType}
            businessDays={businessDays}
            holidays={holidays}
            reportKey={dateToolbarReportKey}
            minDate={dateToolbarMinDate}
          />
        )}
        {isUnbilledReport && includes(['EXEC', 'CORP'], headerUserRole) && (
          <IonToolbar className={classes.infoToolbar}>
            <OverlayInfobar
              className={classes.overlayInfoBar}
              message={t('reports:unbilledInfoText')}
            />
          </IonToolbar>
        )}
      </Header>
      <IonContent
        className={classNames(classes.content, {
          [classes.tableReport]: isTableView,
        })}
        ref={contentRef}
        scrollEvents
      >
        {withEndOfMonthLink && (
          <IonRow className={classes.reportHeaderRow}>
            <ActionRow
              className={classNames(
                classes.actionRowLink,
                classes.actionRowEOM
              )}
              href={goToEndOfMonthReport()}
              withArrow={false}
              testid="oem-report-link"
            >
              <Button
                className={classes.linkButton}
                variant="secondary"
                text={t('viewEOMReportText')}
                testid="oem-report-button"
              >
                <img
                  src={EndOfMonthDashboard}
                  className={classes.oemIcon}
                  alt={t(`viewEOMReportText`)}
                />
              </Button>
            </ActionRow>
          </IonRow>
        )}
        {withCustomerLink && (
          <IonRow className={classes.reportHeaderRow}>
            <ActionRow
              className={classNames(
                classes.actionRowLink,
                classes.actionRowEOM
              )}
              href={concatRoutes(
                searchURL(),
                handleSearchNavigation({
                  type: 'customer',
                  miLoc: routeMiLoc,
                  customerId: id,
                })
              )}
              withArrow={false}
              disabled={!canViewCustomers}
              testid="add-contact-button"
            >
              <SnapshotLink
                className={classes.snapshotLink}
                eyebrow={`${routeMiLoc}${id}`}
                text={customerData?.name || t('common:loading')}
                customerPick12={customerData?.customerPick12}
                disabled={!canViewCustomers}
                withIcon={false}
                testid="customer-snapshot-button"
              />
            </ActionRow>
          </IonRow>
        )}
        {withReportDrilldown && (
          <DrillDown
            title={title}
            reportType={reportType}
            busPeriod={reportBusPeriod}
            requestType={requestType}
            sortField={sortField}
            sortDir={sortDir}
            updateSortField={updateSortField}
            updateSortDir={updateSortDir}
            busDayMap={busDayMap}
            miLoc={miLoc}
            orgType={orgType}
            routeId={id}
            pgc1={pgc1}
            role={userRole}
            testid="reports-drilldown"
            includeDailyTotal={includeDailyTotal}
            contentRef={contentRef}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default Reports;
