import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import type { AxiosError } from 'axios';
import type { Dictionary } from 'lodash';
import { get, head, isEmpty, size, toString } from 'lodash';
import { useInfiniteQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import type {
  QueryParamsType,
  QueryFnProps,
  QueryFlags,
} from 'common/api/utils/useGetQueryFlags';
import useGetQueryFlags from 'common/api/utils/useGetQueryFlags';
import { useAxios } from 'providers/AxiosProvider';
import type { BaseReportProps, BaseReportRow } from 'ReportsApp/models';
import { doConcatDataPages, useKeyUserId, useMiLocOrTeamId } from 'api/helpers';
import useGetUserConfig from 'api/user/useGetUserConfig';
import type { CalendarDay } from 'models/Reports';
import { SortDirEnum } from 'models/Sort';
import type { RootState } from 'store/reducers';
import { pageSize } from 'utils/constants';
import useGetLocationCurrency from 'utils/currency';
import { DateFormatEnum, formatDate } from 'utils/date';

export const getSalesDashboardQueryKey = 'sales-dashboard';

export const mapGroupBy: Dictionary<string> = {
  GRP: 'GROUP',
  DIV: 'DIVISION',
  BRCH: 'BRANCH',
  CUST: 'CUSTOMER',
  PRD_GRP_01: 'PGC1',
  PRD_GRP_02: 'PGC2',
};

export const mapSortField = (field: string, groupBy: string) => {
  if (field === 'name') {
    switch (groupBy) {
      case 'TEAM':
        return 'teamName';
      case 'REP':
        return 'repName';
      case 'NATLACCT':
      case 'CUSTOMER':
        return 'customerName';
      case 'PGC1':
      case 'PGC2':
        return 'pgcName';
      default:
    }
  }
  let sortField = {
    name: 'miLocName',
    sales: 'currentSales',
    salesChange: 'currentSalesChangeToCurrentBusDay',
    gpAmount: 'currentGp',
    gpChange: 'currentGpChangeToCurrentBusDay',
    gpPercentAmount: 'currentGpPercent',
    avgDaily: 'currentAvgDaily',
    transactions: 'currentOrders',
    unbilled: 'summaryBillingSales',
    effectiveDate: 'customerPick12EffectiveDate',
    expirationDate: 'customerPick12ExpirationDate',
    previousSales: 'previousSalesToCurrentBusDay',
    webPercentOfTotalSales: 'webSalesPercentOfTotalSales',
    activeWebRegistered: 'webRegisteredPercentOfTotalCustomers',
  }[field];
  sortField ||= field;
  return sortField;
};

export interface SalesReportRow extends BaseReportRow {
  customerPick12EffectiveDate: string;
  customerPick12ExpirationDate: string;
  customerPick12: boolean;
  pgc: string;
  pgcName: string;
  summaryBillingSales: number;
  summaryBilling?: { gp: number; sales: number };
  customerPick12ActiveCount: number;
  customerPick12TotalCount: number;
  customerPick12ActivePercent: number;
  customerPick12AvgSalesPerAcct: number;
}

export interface SalesReportTrendRow {
  busDay: number;
  cost: number;
  day: number;
  gp: number;
  gpPercent: number;
  month: number;
  sales: number;
  year: number;
}

export interface SalesReportData {
  rows?: SalesReportRow[];
  summary?: SalesReportRow;
  datesWithSameBusDay: CalendarDay[];
  trend?: {
    current?: SalesReportTrendRow[];
    previous?: SalesReportTrendRow[];
  };
}

export interface UseGetSalesProps extends BaseReportProps {
  showZeroSalesPGC?: boolean;
  isPick12Report?: boolean;
  isLocationPick12Report?: boolean;
}

interface UseGetSalesDashboardResponse {
  drilldownData: SalesReportRow[];
  summaryData?: SalesReportRow;
  datesWithSameBusDay?: CalendarDay[];
}

const useGetSalesDashboard = ({
  miLoc: propsMiLoc,
  customerId,
  nationalAcctNo,
  territory,
  pgc1,
  busPeriod,
  requestType = 'MTD',
  groupBy,
  sortField = 'name',
  sortDir = SortDirEnum.ASCENDING,
  summaryOnly,
  limit = pageSize(),
  sendVirtualTeamId = true,
  showZeroSalesPGC = false,
  enabled = true,
}: UseGetSalesProps): UseGetSalesDashboardResponse & QueryFlags => {
  const { axios } = useAxios();
  const { getNewSalesReportAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const { createParams, getURLParams } = useMiLocOrTeamId({
    miLoc: propsMiLoc,
    sendVirtualTeamId,
  });
  const { userInfo, isCamUser } = useSelector((state: RootState) => state.user);
  const userId = get(userInfo, 'userid', '');
  const enableHook = enabled && !isEmpty(userId);

  const { data: currencyData } = useGetUserConfig({
    configType: 'currency',
    enabled: enableHook,
  });
  const { secondaryCurrencyType } = useGetLocationCurrency();
  let currencyType = currencyData?.currency;
  currencyType ||= secondaryCurrencyType;

  const propsGroupBy = toString(groupBy);
  let paramsGroupBy = mapGroupBy[propsGroupBy];
  paramsGroupBy ||= propsGroupBy;

  const params: QueryParamsType = {
    ...createParams(),
    customerNo: customerId,
    nationalAcctNo,
    territory,
    pgc1,
    requestDate: formatDate(busPeriod, DateFormatEnum.reportsDateAPI),
    requestType,
    usdRequested: currencyType === 'USD',
    showZeroSalesPGC,
    limit,
    showCAMView: isCamUser,
    ...(summaryOnly
      ? {}
      : {
          groupBy: paramsGroupBy,
          sortField: mapSortField(sortField, paramsGroupBy),
          sortDir,
        }),
  };

  const doGetSalesReport = async ({ pageParam = 1, signal }: QueryFnProps) => {
    const { data } = await axios.get<SalesReportData>(
      getNewSalesReportAPI(
        getURLParams({
          ...params,
          // api doesn't support page param
          start: limit * (pageParam - 1),
        })
      ),
      { signal }
    );
    return data;
  };

  const response = useInfiniteQuery<SalesReportData, AxiosError>(
    createQueryKey(getSalesDashboardQueryKey, params),
    doGetSalesReport,
    {
      enabled: enableHook,
      retry: (failureCount, err) =>
        err.response?.status === 403 ? false : failureCount >= 3,
      getNextPageParam: (lastPage, pages) =>
        size(lastPage.rows) < limit ? false : size(pages) + 1,
    }
  );

  const { data: salesData } = response;

  const drilldownData = useMemo(
    () => doConcatDataPages<SalesReportRow, SalesReportData>(salesData, 'rows'),
    [salesData]
  );

  const queryFlags = useGetQueryFlags({ ...response, enabled: enableHook });

  return {
    ...queryFlags,
    drilldownData,
    summaryData: head(salesData?.pages)?.summary,
    datesWithSameBusDay: head(salesData?.pages)?.datesWithSameBusDay,
  };
};

export default useGetSalesDashboard;
