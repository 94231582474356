import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { isEmpty, toString } from 'lodash';
import type { InsetListData } from 'common/components/InsetList/InsetList';
import InsetList from 'common/components/InsetList/InsetList';
import type { BaseComponentProps } from 'common/components/utils/renderHelpers';
import { formatKeyValue } from 'common/utils/valueFormatter';
import useAccessControls, { AccessControlType } from 'hooks/useAccessControls';
import type { Customer } from 'models/Customer';
import Button from 'components/Button/Button';
import Text from 'components/Text/Text';
import classes from './IndustryCodes.module.scss';
import SelectAlternateModal from './SelectAlternateModal';

interface IndustryCodesProps {
  data?: Customer;
  isCorpAccount?: boolean;
}

const IndustryCodes = ({
  data,
  className,
  testid,
  isCorpAccount = false,
}: IndustryCodesProps & BaseComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const canEdit = useAccessControls(AccessControlType.editIndustryCodes);

  const industryCodeList: InsetListData[] = [
    {
      label: t('snapshot:naics'),
      customValue: (
        <div className={classes.lines}>
          <Text
            text={formatKeyValue({ value: data?.naics })}
            variant="list-item-title"
          />
          <Text
            text={toString(data?.naicsDesc)}
            variant="list-item-secondaryText"
            className={classes.grayLabel}
          />
        </div>
      ),
    },
    {
      label: t('snapshot:sic'),
      customValue: (
        <div className={classes.lines}>
          <Text
            text={formatKeyValue({ value: data?.sic })}
            variant="list-item-title"
          />
          <Text
            text={toString(data?.sicDesc)}
            variant="list-item-secondaryText"
            className={classes.grayLabel}
          />
        </div>
      ),
    },
    {
      label: t('snapshot:altSic'),
      customValue: (
        <div className={classes.lines}>
          <div className={classes.flexLines}>
            <Text
              text={
                isCorpAccount || !canEdit
                  ? formatKeyValue({ value: data?.altSic })
                  : toString(data?.altSic)
              }
              variant="list-item-title"
              className={classNames({
                [classes.altSic]: !isEmpty(data?.altSic),
              })}
            />
            {canEdit && !isCorpAccount && (
              <Button
                variant="link"
                text={data?.altSic ? t('common:edit') : t('common:add')}
                rightIcon={['fas', 'pencil']}
                onClick={() => setIsOpen(true)}
                testid="add-button"
                className={classes.addEditBtn}
              />
            )}
          </div>
          <Text
            text={toString(data?.altSicDesc)}
            variant="list-item-secondaryText"
            className={classes.grayLabel}
          />
        </div>
      ),
    },
  ];

  return (
    <div
      className={classNames(className, classes.container)}
      data-testid={testid}
    >
      <Text
        text={t('snapshot:industryCodes')}
        variant="mipro-h3-headline"
        testid="title"
      />
      <InsetList data={industryCodeList} className={classes.insetList} />
      <SelectAlternateModal
        miLoc={toString(data?.miLoc)}
        customerId={toString(data?.customerNo)}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        type="altSic"
        subType="sic"
        value={data?.altSic}
        testid="select-altSic-modal"
      />
    </div>
  );
};

export default IndustryCodes;
