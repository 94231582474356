import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { filter, find, includes, isEmpty, map, sortBy, toString } from 'lodash';
import type { AppState } from '@capacitor/app';
import { App } from '@capacitor/app';
import type { PluginListenerHandle } from '@capacitor/core';
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IonPage,
  IonContent,
  IonItem,
  IonRow,
  IonGrid,
  IonCol,
} from '@ionic/react';
import {
  NativeSettings,
  AndroidSettings,
  IOSSettings,
} from 'capacitor-native-settings';
import useGetNotificationsSettings from 'api/user/useGetNotificationsSettings';
import useUpdateNotificationsSettings from 'api/user/useUpdateNotificationsSettings';
import type {
  CategorySchedule,
  EventCategoryNotificationConfig,
} from 'models/NotificationsSettings';
import { findIcon } from 'utils/icons';
import Button from 'components/Button/Button';
import Header from 'components/Header/Header';
import Loader from 'components/Loader/Loader';
import RadioButton from 'components/RadioButton/RadioButton';
import Text from 'components/Text/Text';
import Toggle from 'components/Toggle/Toggle';
import classes from './NotificationsSettings.module.scss';
import ScheduleInfoModal from './ScheduleInfoModal';
import SnoozeInfoModal from './SnoozeInfoModal';

enum SchedulePushNotificationsEnum {
  Always = 'Always',
  Weekdays = 'Weekdays',
}

const NotificationsSettings = (): JSX.Element => {
  const [isScheduleInfoModalOpen, setIsScheduleInfoModalOpen] = useState(false);
  const [isSnoozeInfoModalOpen, setIsSnoozeInfoModalOpen] = useState(false);
  const [receivePushNotifications, setReceivePushNotifications] =
    useState<boolean>(true);
  const [snoozeNotificationsAtNight, setSnoozeNotificationsAtNight] =
    useState<boolean>(false);
  const [schedulePushNotifications, setSchedulePushNotifications] =
    useState<SchedulePushNotificationsEnum>(
      SchedulePushNotificationsEnum.Always
    );
  const [eventSchedule, setEventSchedule] = useState<CategorySchedule[]>([]);
  const [instanceEventSchedule, setInstanceEventSchedule] = useState<
    EventCategoryNotificationConfig[]
  >([]);
  const [groupEventSchedule, setGroupEventSchedule] = useState<
    EventCategoryNotificationConfig[]
  >([]);
  const [pushNotifEnabled, setPushNotifEnabled] = useState(false);

  useEffect(() => {
    let pushNotifListener: PluginListenerHandle;
    const doCheckNotifPermissions = async () => {
      if (Capacitor.isPluginAvailable('PushNotifications')) {
        const pushNotifStatus = await PushNotifications.checkPermissions();
        setPushNotifEnabled(pushNotifStatus.receive === 'granted');
      }
    };
    const doAddPushNotifListener = async () => {
      pushNotifListener = await App.addListener(
        'appStateChange',
        (state: AppState) => {
          if (state.isActive) {
            void doCheckNotifPermissions();
          }
        }
      );
    };
    void doAddPushNotifListener();
    void doCheckNotifPermissions();

    return () => {
      void pushNotifListener?.remove();
    };
  }, []);

  const { t } = useTranslation();

  const { data, isLoading: isNotificationsLoading } =
    useGetNotificationsSettings();

  const { onUpdateNotificationsSettings, status: updateNotificationsStatus } =
    useUpdateNotificationsSettings();

  const isLoaderOpen =
    isNotificationsLoading || updateNotificationsStatus === 'loading';

  useEffect(() => {
    setReceivePushNotifications(
      data?.notifications?.allowNotifications || false
    );
    setSnoozeNotificationsAtNight(
      data?.notifications?.snoozeNightNotifications || false
    );
    setSchedulePushNotifications(
      data?.notifications?.weekdayNotificationOnly
        ? SchedulePushNotificationsEnum.Weekdays
        : SchedulePushNotificationsEnum.Always
    );
    const userEventSchedule = data?.notifications?.eventSchedule;
    setEventSchedule(userEventSchedule || []);
    let defaultEventSchedule = data?.defaultEventConfigurations;
    defaultEventSchedule = sortBy(defaultEventSchedule, ['name']);

    let filteredInstanceNotification = filter(
      defaultEventSchedule,
      (item) =>
        item.pushNotificationCategory === 'Instant' &&
        item.pushNotification !== 'N'
    );
    filteredInstanceNotification = map(filteredInstanceNotification, (item) => {
      const foundItem = find(eventSchedule, (userItem) => {
        return (
          (!isEmpty(userItem.categoryId) &&
            !isEmpty(item.categoryId) &&
            userItem.categoryId === item.categoryId) ||
          (!isEmpty(userItem.eventTagName) &&
            !isEmpty(item.eventTagName) &&
            userItem.eventTagName === item.eventTagName)
        );
      });
      if (foundItem) {
        const { pushNotification } = foundItem;
        return { ...item, pushNotification };
      }
      return item;
    });
    let filteredGroupNotification = filter(
      defaultEventSchedule,
      (item) =>
        item.pushNotificationCategory !== 'Instant' &&
        item.pushNotification !== 'N'
    );
    filteredGroupNotification = map(filteredGroupNotification, (item) => {
      const foundItem = find(eventSchedule, (userItem) => {
        return (
          (!isEmpty(userItem.categoryId) &&
            !isEmpty(item.categoryId) &&
            userItem.categoryId === item.categoryId) ||
          (!isEmpty(userItem.eventTagName) &&
            !isEmpty(item.eventTagName) &&
            userItem.eventTagName === item.eventTagName)
        );
      });
      if (foundItem) {
        const { pushNotification } = foundItem;
        return { ...item, pushNotification };
      }
      return item;
    });
    setInstanceEventSchedule(filteredInstanceNotification);
    setGroupEventSchedule(filteredGroupNotification);
  }, [data, eventSchedule]);

  const getCurrentEventSchedule = () => {
    let userEventSchedule: CategorySchedule[] = []; // eventSchedule;
    if (isEmpty(userEventSchedule)) {
      userEventSchedule = [
        ...instanceEventSchedule.map(
          ({ categoryId, eventTagName, pushNotification }) => ({
            categoryId,
            eventTagName,
            pushNotification,
          })
        ),
        ...groupEventSchedule.map(
          ({ categoryId, eventTagName, pushNotification }) => ({
            categoryId,
            eventTagName,
            pushNotification,
          })
        ),
      ];
    }
    return userEventSchedule;
  };

  const toggleReceivePushNotifications = () => {
    onUpdateNotificationsSettings({
      notifications: {
        allowNotifications: !receivePushNotifications,
        snoozeNightNotifications: snoozeNotificationsAtNight,
        weekdayNotificationOnly:
          schedulePushNotifications === SchedulePushNotificationsEnum.Weekdays,
        eventSchedule: getCurrentEventSchedule(),
      },
    });
    setReceivePushNotifications((prev) => !prev);
  };

  const toggleActivityPushNotification = (
    onToggleFlagLabel: 'Y' | 'A',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    e: any
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const toggledEventName = e?.target?.name;
    const userEventSchedule = getCurrentEventSchedule();
    userEventSchedule.forEach((item) => {
      const { categoryId, eventTagName } = item;
      if (
        (isEmpty(categoryId) && eventTagName === toggledEventName) ||
        (!isEmpty(categoryId) && categoryId === toggledEventName)
      ) {
        // eslint-disable-next-line no-param-reassign
        item.pushNotification =
          item.pushNotification === onToggleFlagLabel ? 'O' : onToggleFlagLabel;
      }
    });

    onUpdateNotificationsSettings({
      notifications: {
        allowNotifications: receivePushNotifications,
        snoozeNightNotifications: snoozeNotificationsAtNight,
        weekdayNotificationOnly:
          schedulePushNotifications === SchedulePushNotificationsEnum.Weekdays,
        eventSchedule: userEventSchedule,
      },
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const toggleInstanceActivityPushNotification = (e: any) => {
    toggleActivityPushNotification('Y', e);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const toggleGroupedActivityPushNotification = (e: any) => {
    toggleActivityPushNotification('A', e);
  };

  const toggleSnoozeNotificationsAtNight = () => {
    onUpdateNotificationsSettings({
      notifications: {
        allowNotifications: receivePushNotifications,
        snoozeNightNotifications: !snoozeNotificationsAtNight,
        weekdayNotificationOnly:
          schedulePushNotifications === SchedulePushNotificationsEnum.Weekdays,
        eventSchedule: getCurrentEventSchedule(),
      },
    });
    setSnoozeNotificationsAtNight((prev) => !prev);
  };

  const changeSchedulePushNotifications = (val: string) => {
    if (!val) return;

    onUpdateNotificationsSettings({
      notifications: {
        allowNotifications: receivePushNotifications,
        snoozeNightNotifications: snoozeNotificationsAtNight,
        weekdayNotificationOnly:
          schedulePushNotifications !== SchedulePushNotificationsEnum.Weekdays,
        eventSchedule: getCurrentEventSchedule(),
      },
    });

    setSchedulePushNotifications(
      val as unknown as SchedulePushNotificationsEnum
    );
  };

  const scheduleNotificationsOptions = [
    {
      value: SchedulePushNotificationsEnum.Always,
      displayValue: t('notificationSettings:always'),
      ariaLabel: 'notification settings always',
    },
    {
      value: SchedulePushNotificationsEnum.Weekdays,
      displayValue: t('notificationSettings:weekdays'),
      ariaLabel: 'notification settings weekdays',
    },
  ];

  return (
    <IonPage
      className={classes.notificationSettings}
      data-testid="notification-settings"
    >
      <Header
        title={t('common:notificationSettings')}
        withBackButton
        testid="notification-settings"
      />
      <IonContent className={classes.content}>
        <IonItem className={classNames(classes.ionPageItem, classes.pushItem)}>
          <IonGrid className={classes.ionGrid}>
            <IonRow className="ion-align-items-center">
              <Text
                className={classes.pushTitle}
                variant="title-screen-section"
                text={t('notificationSettings:pushPreferences')}
                testid="push-notifications"
              />
            </IonRow>
            <Toggle
              className={classes.pushToggle}
              checked={receivePushNotifications}
              onClick={toggleReceivePushNotifications}
              testid="notification-settings-receive"
            >
              <Text
                className={classes.darkLabelHeader}
                variant="label-header"
                text={t('notificationSettings:receive')}
                testid="notification-settings-receive-text"
              />
            </Toggle>
            {!pushNotifEnabled && !isLoaderOpen && (
              <div className={classes.pushNotifDisabled}>
                <FontAwesomeIcon icon={['far', 'triangle-exclamation']} />
                <Text
                  variant="content-small"
                  text={t('notificationSettings:pushNotifDisabledText')}
                />
                <Button
                  variant="link"
                  textVariant="content-small"
                  text={t('notificationSettings:pushNotifDisabledButton')}
                  onClick={() => {
                    void NativeSettings.open({
                      optionAndroid: AndroidSettings.ApplicationDetails,
                      optionIOS: IOSSettings.App,
                    }).catch(() => {
                      // DOC ignore error
                    });
                  }}
                  testid="settings-button"
                />
              </div>
            )}
          </IonGrid>
        </IonItem>
        <IonItem className={classes.ionPageItem}>
          <IonGrid
            className={classNames(classes.ionGrid, classes.scheduleGrid)}
          >
            <IonRow className="ion-align-items-center">
              <Text
                className={classes.scheduleTitle}
                variant="label-header"
                text={t('notificationSettings:schedule')}
                testid="notification-settings-schedule"
              />
              <FontAwesomeIcon
                className={classes.icon}
                icon={findIcon('info-circle')}
                onClick={() => setIsScheduleInfoModalOpen(true)}
              />
            </IonRow>
            <IonRow>
              <Text
                className={classes.textChoose}
                variant="content-small"
                text={t('notificationSettings:choose')}
                testid="notification-settings-choose"
              />
            </IonRow>
            <RadioButton
              testid="notifocation-setting-radio-button"
              value={schedulePushNotifications}
              options={scheduleNotificationsOptions}
              disabled={!receivePushNotifications}
              onChange={(val) =>
                receivePushNotifications
                  ? changeSchedulePushNotifications(toString(val))
                  : undefined
              }
            >
              <br />
            </RadioButton>
          </IonGrid>
        </IonItem>

        <Toggle
          className={classes.toggleSnooze}
          checked={snoozeNotificationsAtNight}
          onClick={toggleSnoozeNotificationsAtNight}
          disabled={!receivePushNotifications}
          testid="notification-settings-snooze"
        >
          <IonGrid className={classes.ionGrid}>
            <IonRow className="ion-align-items-center ion-justify-content-between">
              <IonCol className={classes.ionCol}>
                <Text
                  className={classes.darkLabelHeader}
                  variant="label-header"
                  text={t('notificationSettings:snooze')}
                  testid="notification-settings-snooze-text"
                />
                <FontAwesomeIcon
                  className={classes.icon}
                  icon={findIcon('info-circle')}
                  onClick={() => setIsSnoozeInfoModalOpen(true)}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <Text
                className={classNames(classes.textChoose, classes.snoozeNote)}
                variant="label-micro-italic"
                text={t('notificationSettings:note')}
                testid="notification-settings-snooze-note"
              />
            </IonRow>
          </IonGrid>
        </Toggle>
        <IonItem className={classes.sectionSeparator} />
        <IonItem
          className={classNames(classes.ionPageItem, classes.customizeSection)}
        >
          <IonGrid className={classes.ionGrid}>
            <IonRow className="ion-align-items-center">
              <Text
                className={classes.pushTitle}
                variant="title-screen-section"
                text={t('notificationSettings:customizePush')}
                testid="customize-push-notifications"
              />
            </IonRow>
            <IonRow>
              <Text
                className={classes.textChoose}
                variant="content-small"
                text={t('notificationSettings:customizePushInfo')}
                testid="customize-push-notification-info"
              />
            </IonRow>
            <IonRow className={classes.sectionPadding}>
              <Text
                className={classes.notificationTypeTitle}
                variant="title-action-card"
                text={t('notificationSettings:instant')}
                testid="instant-push-notificaiton"
              />
            </IonRow>
          </IonGrid>
        </IonItem>
        {map(
          instanceEventSchedule,
          ({ pushNotification, name, eventTagName, categoryId }, index) => {
            return (
              <Toggle
                key={categoryId || eventTagName}
                className={classNames(classes.activityToggle, {
                  [classes.toggleItem]: index !== groupEventSchedule.length - 1,
                  [classes.lastItem]:
                    index === instanceEventSchedule.length - 1,
                })}
                name={categoryId || eventTagName}
                checked={includes(['Y', 'A'], pushNotification)}
                onClick={toggleInstanceActivityPushNotification}
                disabled={!receivePushNotifications}
                testid={`notification-settings-receive-${
                  categoryId || eventTagName || index
                }`}
              >
                <Text
                  className={classes.activityName}
                  variant="label-header"
                  text={name}
                  testid={`notification-settings-receive-text-${
                    categoryId || eventTagName || index
                  }`}
                />
              </Toggle>
            );
          }
        )}
        <IonRow className={classes.grouped}>
          <Text
            className={classes.notificationTypeTitle}
            variant="title-action-card"
            text={t('notificationSettings:grouped')}
            testid="group-push-notificaiton"
          />
        </IonRow>
        {map(
          groupEventSchedule,
          ({ pushNotification, name, eventTagName, categoryId }, index) => {
            return (
              <Toggle
                key={categoryId || eventTagName || ''}
                className={classNames(classes.activityToggle, {
                  [classes.toggleItem]: index !== groupEventSchedule.length - 1,
                  [classes.lastItem]: index === groupEventSchedule.length - 1,
                })}
                name={categoryId || eventTagName}
                checked={includes(['Y', 'A'], pushNotification)}
                onClick={toggleGroupedActivityPushNotification}
                disabled={!receivePushNotifications}
                testid={`notification-settings-receive-${
                  categoryId || eventTagName || index
                }`}
              >
                <Text
                  className={classes.activityName}
                  variant="label-header"
                  text={name}
                  testid={`notification-settings-receivetext-${
                    categoryId || eventTagName || index
                  }`}
                />
              </Toggle>
            );
          }
        )}

        <ScheduleInfoModal
          isOpen={isScheduleInfoModalOpen}
          setIsOpen={setIsScheduleInfoModalOpen}
          testid="notification-settings-schedule-info"
        />
        <SnoozeInfoModal
          isOpen={isSnoozeInfoModalOpen}
          setIsOpen={setIsSnoozeInfoModalOpen}
          testid="notification-settings-snooze-info"
        />
        <Loader type="fullscreen" isOpen={isLoaderOpen} />
      </IonContent>
    </IonPage>
  );
};

export default NotificationsSettings;
