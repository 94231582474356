import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SentryCategories from 'constants/sentryCategories';
import type { Dictionary } from 'lodash';
import { find, get, has, isNil, toNumber, toString } from 'lodash';
import { Capacitor } from '@capacitor/core';
import type { LocalNotificationSchema } from '@capacitor/local-notifications';
import { LocalNotifications } from '@capacitor/local-notifications';
import { PushNotifications } from '@capacitor/push-notifications';
import type {
  DeliveredNotifications as PushNotificationDeliveredList,
  PushNotificationSchema,
} from '@capacitor/push-notifications/dist/esm/definitions';
import { Badge } from '@capawesome/capacitor-badge';
import { isPlatform } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import { useQueryClient } from '@tanstack/react-query';
import { goToActivity } from 'ActivitiesApp/navigation/navigationHelpers';
import { activitiesURL } from 'navigation';
import { useDevice } from 'providers/DeviceProvider';
import { useToasts } from 'providers/ToastProvider';
import { findActivitiesQueryKey } from 'api/activities/useFindActivities';
import { findActivitiesV2QueryKey } from 'api/activities/useFindActivitiesV2';
import { onSuccessMutation, useKeyUserId } from 'api/helpers';
import { unreadQueryKey } from 'api/user/useGetUnreadNotificationsCount';
import type { UnreadCount } from 'api/user/useGetUnreadNotificationsCount';
import useRegisterNotifications, {
  logFCMRegistrationQueryKey,
} from 'api/user/useRegisterNotifications';
import { ToastType } from 'models/Toast';
import type { RootState } from 'store/reducers';
import { addDeepLink, setFCMToken } from 'store/user';

interface UsePushNotifications {
  setBadgeCount: (count: number) => Promise<void>;
  hasPushNotificationSupport: () => boolean;
  subscribeBadgeCount: () => void;
  subscribePushNotifications: () => void;
  listenNotifications: () => void;
  unsubscribeNotifications: () => void;
  removeDeliveredNotifications: (
    value: string,
    needle: string
  ) => Promise<void>;
  findPushNotification: (
    value: string,
    needle: string
  ) => Promise<PushNotificationSchema | undefined>;
}

export enum PushNotificationType {
  PriceOverrideReq = 'PriceOverrideReq',
  PriceOverrideApproved = 'PriceOverrideApproved',
  PriceOverrideRejected = 'PriceOverrideRejected',
  CustomerWebActivity = 'CustomerWebActivity',
  NewCustomerContact = 'NewCustomerContact',
  LargeOrderCreated = 'LargeOrderCreated',
  LargeQuoteCreated = 'LargeQuoteCreated',
  ReengagedCustomer = 'ReengagedCustomer',
  CustomerVisit = 'CustomerVisit',
  AtRiskAccount = 'AtRiskAccount',
  LateOrder = 'LateOrder',
  AggregateActivity = 'AggregateActivity',
  CustomerVisitedByNonRep = 'CustomerVisitedByNonRep',
  SourcingOverrideReq = 'SourcingOverrideReq',
  SourcingOverrideAccepted = 'SourcingOverrideAccepted',
  SourcingOverrideRejected = 'SourcingOverrideRejected',
  GeneralTask = 'GeneralTask',
  CRMOpportunity = 'CRMOpportunity',
  Blank = '',
}

interface PushNotificationData {
  [key: string]: string;
  historyId: string;
}

const hasPushNotificationSupport = (): boolean => {
  return Capacitor.isPluginAvailable('PushNotifications');
};

const hasLocalNotificationsSupport = (): boolean => {
  return Capacitor.isPluginAvailable('LocalNotifications');
};

const hasBadgeSupport = (): boolean => {
  return Capacitor.isPluginAvailable('Badge') && Capacitor.isNativePlatform();
};

const usePushNotifications = (): UsePushNotifications => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { i18n } = useTranslation();
  const { deviceData } = useDevice();
  const { userInfo } = useSelector((state: RootState) => state.user);
  const userId = get(userInfo, 'userid', '');
  const { onLogFCMRegistration } = useRegisterNotifications();
  const setBadgeCount = async (count: number) => {
    if (!hasBadgeSupport()) {
      return;
    }
    await Badge.set({ count });
  };

  const subscribeBadgeCount = useCallback(async () => {
    if (hasBadgeSupport()) {
      let permStatus = await Badge.checkPermissions();
      if (permStatus.display === 'prompt') {
        permStatus = await Badge.requestPermissions();
      }
      if (permStatus.display !== 'granted') {
        Sentry.addBreadcrumb({
          category: SentryCategories.BADGE_COUNT,
          message: 'Badge count permission denied',
          level: 'debug',
        });
      }
    }
  }, []);

  const subscribePushNotifications = useCallback(async () => {
    if (hasPushNotificationSupport()) {
      let permStatus = await PushNotifications.checkPermissions();

      if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions();
      }

      if (permStatus.receive !== 'granted') {
        Sentry.addBreadcrumb({
          category: SentryCategories.PUSH_NOTIFICATIONS,
          message: 'Push notifications permission denied',
          level: 'debug',
        });
      }

      await PushNotifications.addListener('registration', (token) => {
        // eslint-disable-next-line no-console
        console.log('Push registration success, token:', token.value);
        dispatch(setFCMToken(token.value));
        onLogFCMRegistration({
          token: token.value,
          device:
            deviceData?.name || `${userId} - ${deviceData?.model || 'device'}`,
          allow: permStatus.receive === 'granted',
          language: i18n.language,
        });
      });

      await PushNotifications.addListener('registrationError', ({ error }) => {
        Sentry.addBreadcrumb({
          category: SentryCategories.PUSH_NOTIFICATIONS,
          message: `Registration error: ${toString(error)}`,
          level: 'debug',
        });
      });
      await PushNotifications.register();
    }
  }, [
    deviceData?.model,
    deviceData?.name,
    dispatch,
    i18n.language,
    onLogFCMRegistration,
    userId,
  ]);

  const getNotificationData = useCallback(
    (notification: PushNotificationSchema | LocalNotificationSchema) => {
      return get(
        notification,
        has(notification, 'extra') ? 'extra' : 'data',
        {}
      ) as PushNotificationData;
    },
    []
  );

  const removeDeliveredPushNotifications = useCallback(
    async (value: string, needle: string) => {
      if (Capacitor.isPluginAvailable('PushNotifications')) {
        const notificationsToBeRemoved: PushNotificationDeliveredList = {
          notifications: [],
        };

        const deliveredList =
          await PushNotifications.getDeliveredNotifications();

        if (deliveredList.notifications.length > 0) {
          notificationsToBeRemoved.notifications =
            deliveredList.notifications.filter((notification) => {
              const notificationData = getNotificationData(notification);

              return notificationData[needle] === value;
            });

          await PushNotifications.removeDeliveredNotifications(
            notificationsToBeRemoved
          );
        }
      }
    },
    [getNotificationData]
  );

  const removeDeliveredLocalNotifications = async (id: string) => {
    if (Capacitor.isPluginAvailable('LocalNotifications')) {
      await LocalNotifications.cancel({
        notifications: [{ id: toNumber(id) }],
      });
    }
  };

  /**
   * Removes delivered notifications.
   * @param value string - The notification identifier.
   * @param needle string - The notification key to compare.
   */
  const removeDeliveredNotifications = useCallback(
    async (value: string, needle: string) => {
      await removeDeliveredPushNotifications(value, needle);
      await removeDeliveredLocalNotifications(value);
    },
    [removeDeliveredPushNotifications]
  );

  const supportedNotificationTypes = useMemo(
    () => [
      PushNotificationType.PriceOverrideApproved,
      PushNotificationType.PriceOverrideRejected,
      PushNotificationType.PriceOverrideReq,
      PushNotificationType.CustomerWebActivity,
      PushNotificationType.NewCustomerContact,
      PushNotificationType.LargeOrderCreated,
      PushNotificationType.LargeQuoteCreated,
      PushNotificationType.ReengagedCustomer,
      PushNotificationType.CustomerVisit,
      PushNotificationType.AtRiskAccount,
      PushNotificationType.LateOrder,
      PushNotificationType.AggregateActivity,
      PushNotificationType.CustomerVisitedByNonRep,
      PushNotificationType.SourcingOverrideAccepted,
      PushNotificationType.SourcingOverrideReq,
      PushNotificationType.SourcingOverrideRejected,
      PushNotificationType.GeneralTask,
      PushNotificationType.CRMOpportunity,
    ],
    []
  );

  const removeHandledNotification = useCallback(
    async (notification: PushNotificationSchema | LocalNotificationSchema) => {
      const notificationData = getNotificationData(notification);
      const notificationType = get(
        notificationData,
        'notificationType',
        ''
      ) as PushNotificationType;

      if (notificationType === PushNotificationType.Blank) {
        Sentry.captureMessage(
          `Notification type "${notificationType}" not handled in removeHandledNotification`,
          'warning'
        );
        return;
      }

      if (supportedNotificationTypes.includes(notificationType)) {
        await removeDeliveredNotifications(
          notificationData.historyId,
          'historyId'
        );
      }
    },
    [
      getNotificationData,
      removeDeliveredNotifications,
      supportedNotificationTypes,
    ]
  );

  const createDeepLink = useCallback(
    (
      notificationType: PushNotificationType,
      notificationData: PushNotificationData
    ) => {
      if (notificationType === PushNotificationType.Blank) {
        Sentry.withScope((scope) => {
          scope.setContext('notification', notificationData);
          Sentry.captureMessage(
            'Outdated push notification, type is missing.',
            'warning'
          );
        });
        return;
      }

      if (supportedNotificationTypes.includes(notificationType)) {
        const deepLink = {
          url:
            notificationData.notificationType ===
            PushNotificationType.AggregateActivity
              ? activitiesURL()
              : goToActivity({
                  miLoc: notificationData.custMiLoc,
                  customerId: notificationData.custNo,
                  userId: notificationData.userId,
                  historyId: notificationData.historyId,
                  activityType: notificationData.notificationType,
                  commentId: notificationData.commentId,
                }),
          data: {
            eventRead: 'N',
            ...notificationData,
          },
        };
        dispatch(addDeepLink(deepLink));
      }
    },
    [dispatch, supportedNotificationTypes]
  );

  const queryClient = useQueryClient();
  const { createQueryKey } = useKeyUserId();

  const scheduleLocalNotifications = async (
    pushNotification: PushNotificationSchema
  ) => {
    if (isPlatform('android') && hasLocalNotificationsSupport()) {
      const { title, body } = pushNotification;
      const { historyId } = pushNotification.data as PushNotificationData;

      // validate that we have all required properties
      if (isNil(title) || isNil(body) || isNil(historyId)) {
        return;
      }

      Sentry.addBreadcrumb({
        category: SentryCategories.PUSH_NOTIFICATIONS,
        message: 'Scheduling local notifiction',
        level: 'debug',
      });
      await LocalNotifications.schedule({
        notifications: [
          {
            title: toString(title),
            body: toString(body),
            id: toNumber(historyId),
            schedule: {
              at: new Date(Date.now() + 1000),
            },
            extra: pushNotification.data as Dictionary<string>,
            smallIcon: '@mipmap/push_icon',
            iconColor: '#FFFFFF',
          },
        ],
      });
    }
  };

  const prepareDeepLink = useCallback(
    (notification: PushNotificationSchema | LocalNotificationSchema) => {
      const notificationData = getNotificationData(notification);

      const notificationType = get(
        notificationData,
        'notificationType',
        ''
      ) as PushNotificationType;

      // verify that this notification is for the currently logged in user
      if (userId !== notificationData.userId) {
        addToast({
          type: ToastType.error,
          text: 'Push notification is associated to a different user.',
          testid: 'different-userid-error-toast',
        });
        Sentry.withScope((scope) => {
          scope.setContext('notification', notificationData);
          Sentry.captureMessage(
            `Push notification associated to a different user`,
            'warning'
          );
        });
        return;
      }

      createDeepLink(notificationType, notificationData);
    },
    [addToast, createDeepLink, getNotificationData, userId]
  );

  const listenNotifications = useCallback(async () => {
    // @todo: consider add validation if user accepted push notifications via UI
    if (hasPushNotificationSupport()) {
      const permStatus = await PushNotifications.checkPermissions();
      if (permStatus.receive === 'granted') {
        await PushNotifications.addListener(
          'pushNotificationReceived',
          (notification) => {
            const notificationData = getNotificationData(notification);

            // Docs: only set unreadCount if it exists.
            if (has(notificationData, 'unreadCount')) {
              const unreadCount = get(notificationData, 'unreadCount');
              // Update query cache for unread count
              queryClient.setQueryData<UnreadCount>(
                createQueryKey(unreadQueryKey),
                { unreadCount: toNumber(unreadCount) }
              );
              // invalidate activities when cache is updated
              void onSuccessMutation(queryClient, findActivitiesQueryKey);
              void onSuccessMutation(queryClient, findActivitiesV2QueryKey);
              void setBadgeCount(toNumber(unreadCount));
            }

            if (has(notificationData, 'dismissNotification')) {
              void removeHandledNotification(notification);
            }
            // eslint-disable-next-line no-console
            console.log(
              'Push notification received: ',
              JSON.stringify(notification, null, 2)
            );
            void scheduleLocalNotifications(notification);
            Sentry.addBreadcrumb({
              category: SentryCategories.PUSH_NOTIFICATIONS,
              message: 'Push notification received',
              level: 'debug',
            });
          }
        );

        await PushNotifications.addListener(
          'pushNotificationActionPerformed',
          (notification) => {
            prepareDeepLink(notification.notification);
          }
        );
      }
    }
    if (hasLocalNotificationsSupport()) {
      await LocalNotifications.addListener(
        'localNotificationActionPerformed',
        (notification) => {
          prepareDeepLink(notification.notification);
        }
      );
    }
  }, [
    createQueryKey,
    getNotificationData,
    prepareDeepLink,
    queryClient,
    removeHandledNotification,
  ]);

  const unsubscribeNotifications = useCallback(async () => {
    await onSuccessMutation(queryClient, unreadQueryKey);
    if (hasPushNotificationSupport()) {
      await PushNotifications.removeAllDeliveredNotifications();
      await PushNotifications.removeAllListeners();
      await onSuccessMutation(queryClient, logFCMRegistrationQueryKey, {
        userId,
      });
    }
    if (hasLocalNotificationsSupport()) {
      await LocalNotifications.removeAllListeners();
    }
  }, [queryClient, userId]);

  const findPushNotification = async (
    value: string,
    needle: string
  ): Promise<PushNotificationSchema | undefined> => {
    if (!hasPushNotificationSupport()) {
      return undefined;
    }

    const deliveredList = await PushNotifications.getDeliveredNotifications();

    return find(deliveredList.notifications, (notification) => {
      const notificationData = getNotificationData(notification);
      return notificationData[needle] === value;
    });
  };

  return {
    setBadgeCount,
    hasPushNotificationSupport,
    subscribeBadgeCount,
    subscribePushNotifications,
    listenNotifications,
    unsubscribeNotifications,
    removeDeliveredNotifications,
    findPushNotification,
  };
};

export default usePushNotifications;
