import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { IonCard, IonCardSubtitle, IonCardTitle } from '@ionic/react';
import FilePreview from 'components/FilePreview/FilePreview';
import Text from 'components/Text/Text';
import classes from './FeatureCard.module.scss';

interface FeatureCardProps {
  index: number;
  featureDescription: string;
  fileContent: string;
  postDate: string;
  className?: string;
}

const allowedTags = ['a', 'img'] as string[];

const FeatureCard = ({
  index,
  featureDescription,
  postDate,
  fileContent,
  className,
}: FeatureCardProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <IonCard
      key={index}
      button
      type="button"
      className={classNames(className, classes.card)}
    >
      <div className={classes.wrapper}>
        <div className={classes.wrapper}>
          <IonCardSubtitle className={classes.subtitle}>
            <Text
              className={classes.featured}
              variant="announcement-subtitle"
              text={t('announcements:features')}
              testid="card-subtitle"
            />
            <Text
              className={classes.date}
              variant="announcement-subtitle"
              text={postDate}
              testid="card-subtitle"
            />
          </IonCardSubtitle>
        </div>
        <FilePreview
          className={classes.featureDescription}
          fileContent={fileContent}
          allowedTags={allowedTags}
          featureCard
        />

        <div className={classes.bottomDescription}>
          <IonCardTitle className={classes.description}>
            <Text
              variant="title-info-card"
              text={featureDescription}
              testid="card-title"
              className={classes.title}
            />
          </IonCardTitle>
        </div>
      </div>
    </IonCard>
  );
};

export default FeatureCard;
