export type StatusHistory = {
  creationTimestamp: string;
  creationUser: string;
  creationUserId?: string;
  creationPhone?: string;
  creationEmail?: string;
  otherReason?: string;
  primaryStatus?: string;
  primaryStatusDescription?: string;
  reason?: string;
  secondaryStatus: SecondaryStatus;
  secondaryStatusDescription?: string;
  taskId?: number;
};

enum SecondaryStatusEnum {
  'OPEN',
  'ACCEPT',
  'COMPL',
  'CANC',
  'DELETE',
  'REJECT',
  'WON',
  'LOST',
  'SCHED',
  'STALLED',
}

export type SecondaryStatus = keyof typeof SecondaryStatusEnum;

export interface GeneralTaskInfo {
  actualValue: number;
  assigned: string;
  assignedMiAccount: string;
  assignedName: string;
  creationTimestamp: string;
  creationUserId: string;
  currency: string;
  customerName: string;
  customerNo: string;
  description: string;
  dueDate: string;
  duration: number;
  estimatedValue: number;
  externalId: string;
  externalSource: string;
  lastUpdateTimestamp: string;
  lastUpdateUserid: string;
  miLoc: string;
  otherReason: string;
  primaryStatus: string;
  primaryStatusDescription: string;
  priority: number;
  reason: string;
  requester: string;
  requesterName: string;
  requesterEmail: string;
  requesterPhone: string;
  secondaryStatus: SecondaryStatus;
  secondaryStatusDescription: string;
  statusHistory: StatusHistory[];
  subject: string;
  taskId: number;
  taskType: string;
  taskTypeDescription: string;
}

export interface CrmForm {
  action?: SecondaryStatus;
  actualValue?: string;
  reason?: string;
  reasonDesc?: string;
  currency?: string;
}

export enum CrmActivityTypeEnum {
  crmTask = 'GeneralTask',
  crmOpportunity = 'CRMOpportunity',
}

export enum CrmActivityIconEnum {
  crmTask = 'list-check',
  crmOpportunity = 'hand-holding-dollar',
}

export enum CrmActivityColorEnum {
  crmTask = 'color-crmTask',
  crmOpportunity = 'color-crmOpportunity',
}

export enum CrmActivityFilterEnum {
  crmTask = 'task',
  crmOpportunity = 'crmOpportunity',
}

export interface UpdateTaskBody {
  actualValue?: string;
  reasonCd?: string;
  secondaryStatus?: string;
  historyId: number;
}
