import { useEffect, useMemo } from 'react';
import type { AxiosError } from 'axios';
import { forEach, size, toString } from 'lodash';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { doConcatDataPages, doPromiseAPI, useKeyUserId } from 'api/helpers';
import type { ArtAsset } from 'models/InspectionReport';
import type { InfiniteQueryFlags } from 'models/Search';
import { pageSize } from 'utils/constants';
import { withStringProp } from 'utils/helpers';

export const findArtAssetsQueryKey = 'search-art-assets';
export const getArtAssetQueryKey = 'artAsset';

interface UseFindArtAssetsProps {
  query: string;
  custNo: string;
  miLoc: string;
  limit?: number;
}

interface UseFindArtAssetsResponse {
  total: number;
  items: ArtAsset[];
}

const useFindArtAssets = ({
  query,
  custNo,
  miLoc,
  limit = pageSize(),
}: UseFindArtAssetsProps): UseFindArtAssetsResponse & InfiniteQueryFlags => {
  const { axios } = useAxios();
  const { findArtAssetsAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { createQueryKey } = useKeyUserId();
  const params = {
    fastFind: toString(query),
    custNo,
    miLoc,
    limit: toString(limit),
  };

  const doFindArtAssets = ({ pageParam = 1 }) => {
    return doPromiseAPI<ArtAsset[]>(async (cancelToken) => {
      const { data } = await axios.get<ArtAsset[]>(
        findArtAssetsAPI(
          toString(
            new URLSearchParams({ ...params, page: toString(pageParam) })
          )
        ),
        { cancelToken }
      );
      return data;
    });
  };

  const response = useInfiniteQuery<ArtAsset[], AxiosError>(
    createQueryKey(findArtAssetsQueryKey, params),
    doFindArtAssets,
    {
      enabled: withStringProp(query),
      getNextPageParam: (lastPage, pages) =>
        size(lastPage) < limit ? false : size(pages) + 1,
    }
  );

  const {
    data,
    error,
    status,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = response;

  const artAssets = useMemo(() => doConcatDataPages<ArtAsset>(data), [data]);

  useEffect(() => {
    forEach(artAssets, (artAsset: ArtAsset) => {
      const id = artAsset.assetNo;
      queryClient.setQueryData<ArtAsset>(
        createQueryKey(getArtAssetQueryKey, { id }),
        (prev) => ({
          ...(prev as ArtAsset),
          assetNo: artAsset.assetNo,
          miLoc: artAsset.miLoc,
          customerNo: artAsset.customerNo,
          description: artAsset.description,
          serialNo: artAsset.serialNo,
          custAssetNo: artAsset.custAssetNo,
          modelNo: artAsset.modelNo,
          itemNo: artAsset.itemNo,
          refNo: artAsset.refNo,
        })
      );
    });
  }, [artAssets, queryClient, createQueryKey]);

  const hasItems = size(artAssets) > 0;
  const hasError = status === 'error';
  const isEmptyResponse = status === 'success' && !hasNextPage && !hasItems;
  const noMoreData = status === 'success' && !hasNextPage && hasItems;
  const showLoader = status === 'loading' || isFetchingNextPage;
  const enableInfiniteScroll = !(!hasNextPage || isFetchingNextPage);
  const total = size(artAssets);

  return {
    fetchNextPage: async () => {
      await fetchNextPage();
    },
    items: artAssets,
    total,
    error,
    hasError,
    showLoader,
    isEmptyResponse,
    noMoreData,
    enableInfiniteScroll,
  };
};

export default useFindArtAssets;
