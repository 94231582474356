import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { map, filter, isEmpty } from 'lodash';
import { IonRow } from '@ionic/react';
import { useFormikContext } from 'formik';
import classes from 'ProductSearchApp/components/CheckoutForm/CheckoutForm.module.scss';
import type { CheckoutOrderForm } from 'ProductSearchApp/models/Order';
import Button from 'components/Button/Button';
import CheckBox from 'components/CheckBox/CheckBox';
import HelpModal from 'components/HelpModal/HelpModal';
import Text from 'components/Text/Text';

interface TransmitInfoProps {
  disabled?: boolean;
}

const TransmitInfo = ({ disabled }: TransmitInfoProps): JSX.Element => {
  const { t } = useTranslation();
  const namespace = 'productSearch:checkout';
  const {
    values: {
      sendUnprocessed,
      excludeFromWeb,
      emailCustomer,
      priceOverridePending,
      typedRecipients,
      contactEmail,
    },
    setFieldValue,
    errors,
  } = useFormikContext<CheckoutOrderForm>();
  const [isUnProcessedOpenModel, setIsUnProcessedOpenModel] = useState(false);
  const [isEmailCustomerModal, setIsEmailCustomerModal] =
    useState<boolean>(false);
  const emailCustomerRulesList = [
    {
      label: t(`${namespace}:isSendToUnprocessed`),
      hidden: !sendUnprocessed,
    },
    {
      label: t(`${namespace}:isPriceOverridePending`),
      hidden: !priceOverridePending,
    },
    {
      label: t(`${namespace}:isHideInMotion`),
      hidden: excludeFromWeb !== 'Y',
    },
  ];
  const emailDisabled =
    priceOverridePending || excludeFromWeb === 'Y' || sendUnprocessed;

  const resetEmailFields = () => {
    if (!isEmpty(errors.typedRecipients)) {
      setFieldValue('typedRecipients', contactEmail);
    }
    setFieldValue('emailCustomer', false);
  };

  return (
    <div className={classes.cardWrapper}>
      <Text variant="mipro-h3-headline" text={t(`${namespace}:transmitInfo`)} />
      <div className={classes.checkboxWrapper}>
        <CheckBox
          name="sendUnprocessed"
          ariaLabel="sendUnprocessed"
          label={t(`${namespace}:sendUnprocessed`)}
          testid="order-send-unprocessed"
          checked={sendUnprocessed}
          onChange={(checked) => {
            setFieldValue('sendUnprocessed', checked);
            if (checked === true) {
              resetEmailFields();
            }
          }}
          disabled={disabled}
        />
        <Button
          icon={['far', 'info-circle']}
          testid="send-unprocessed-info-btn"
          onClick={() => setIsUnProcessedOpenModel(true)}
          iconClassName={classes.infoIcon}
          className={classes.btnWrapper}
        />
      </div>
      <CheckBox
        className={classes.checkboxWrapper}
        name="excludeFromWeb"
        ariaLabel="hideFromMotion"
        label={t(`${namespace}:hideFromMotion`)}
        testid="hide--from--motion"
        checked={excludeFromWeb === 'Y'}
        value={excludeFromWeb === 'Y'}
        onChange={(e) => {
          const val = e ? 'Y' : 'N';
          setFieldValue('excludeFromWeb', val);
          if (val === 'Y') {
            resetEmailFields();
          }
        }}
        disabled={disabled}
      />
      <div className={classes.checkboxWrapper}>
        <CheckBox
          aria-label="emailCustomer"
          name="emailCustomer"
          checked={Boolean(emailCustomer)}
          testid="email-customer"
          onChange={(v) => {
            setFieldValue?.('emailCustomer', v);
            if (v) {
              setTimeout(() => {
                document
                  .querySelector('[name=typedRecipients]')
                  ?.scrollIntoView({ behavior: 'smooth', block: 'end' });
              }, 300);
            }
          }}
          label="Email Customer"
          disabled={
            emailDisabled ||
            disabled ||
            (!!typedRecipients && !!errors?.typedRecipients)
          }
        />
        {emailDisabled && (
          <Button
            icon={['far', 'triangle-exclamation']}
            testid="email-customer-warn-btn"
            onClick={() => setIsEmailCustomerModal(true)}
            iconClassName={classes.warningIcon}
            className={classes.btnWrapper}
          />
        )}
      </div>
      <HelpModal
        isOpen={isUnProcessedOpenModel}
        closeHelpModal={setIsUnProcessedOpenModel}
        testid="send-unprocessed-info-modal"
        title={t(`${namespace}:sendUnprocessed`)}
        initialBreakpoint={0.4}
      >
        <IonRow>
          <Text
            variant="mipro-body-copy"
            text={t(`${namespace}:unprocessedInfoMessage`)}
          />
        </IonRow>
      </HelpModal>
      <HelpModal
        isOpen={isEmailCustomerModal}
        closeHelpModal={setIsEmailCustomerModal}
        testid="customer-email-unavailable-modal"
        title={t(`${namespace}:emailCustomerTitle`)}
        initialBreakpoint={0.4}
        variant="warning"
      >
        <IonRow>
          <Text
            variant="mipro-body-copy"
            text={t(`${namespace}:emailCustomerMsg`)}
          />
        </IonRow>
        <ul className={classes.rulesList}>
          {map(
            filter(emailCustomerRulesList, (item) => !item.hidden),
            (rulesItem, index) => (
              <li key={`rule-${index}`}>{rulesItem.label}</li>
            )
          )}
        </ul>
      </HelpModal>
    </div>
  );
};

export default TransmitInfo;
