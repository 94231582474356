import React from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import type { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { findIcon } from 'utils/icons';
import type { TextVariantType } from 'components/Text/Text';
import Text from 'components/Text/Text';
import classes from './Badge.module.scss';

enum BadgeTypeEnum {
  'info',
  'green',
  'error',
  'warning',
  'notification',
}

export type BadgeType = keyof typeof BadgeTypeEnum;

export interface BadgeProps {
  type: BadgeType;
  icon?: IconName;
  text: string;
  testid: string;
  circle?: boolean;
  iconVariant?: 'fas' | 'far' | 'fal';
  textClassName?: string;
  iconClassName?: string;
  textVariant?: TextVariantType;
}

const Badge = ({
  type,
  icon,
  text,
  testid,
  circle,
  className,
  textClassName,
  iconVariant = 'fas',
  iconClassName,
  textVariant = 'label-micro',
}: BadgeProps & IonicReactProps): JSX.Element => {
  return (
    <div
      className={classNames(classes[type], classes.badge, className, {
        [classes.circle]: circle,
      })}
      data-testid={testid}
    >
      {!isEmpty(icon) && (
        <FontAwesomeIcon
          className={iconClassName}
          icon={findIcon(icon, iconVariant)}
        />
      )}
      <Text className={textClassName} variant={textVariant} text={text} />
    </div>
  );
};

export default Badge;
