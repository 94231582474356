import { useState } from 'react';
import { useSelector } from 'react-redux';
import type { Dictionary } from 'lodash';
import { filter, find, toNumber, map, toString, get } from 'lodash';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { MutationStatus } from '@tanstack/react-query';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import { useToasts } from 'providers/ToastProvider';
import useIssueDB from 'StoreroomsApp/database/useIssueDB';
import type { ItemIssue, ItemIssueDTO } from 'StoreroomsApp/models/Issue';
import { IssueStatusEnum, IssueTypeEnum } from 'StoreroomsApp/models/Issue';
import type { ItemPOU } from 'StoreroomsApp/models/ItemPOU';
import { useMiLocOrTeamId, onSuccessMutation, doPromiseAPI } from 'api/helpers';
import { ToastType } from 'models/Toast';
import type { RootState } from 'store/reducers';
import useFindBarcodeItems from './useFindBarcodeItems';
import { findIssuesQueryKey } from './useFindIssues';
import { findStoreroomsQueryKey } from './useFindStorerooms';

interface AddIssueBody {
  storeroomNumber: string;
  chargeBackValues?: Dictionary<string>;
  items?: ItemIssue[];
}

interface UseAddIssueResponse {
  newIssueId: string;
  status: MutationStatus;
  isLoading: boolean;
  onAddIssue: (body: AddIssueBody) => void;
  doAddIssue: (body: AddIssueBody) => Promise<string>;
  getBarcodeItem: (
    bardcode: string,
    selectedItems: ItemIssueDTO[],
    storeroomNumber: string
  ) => Promise<ItemPOU[]>;
}

const useAddIssue = (): UseAddIssueResponse => {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const { isOnline } = useNetworkStatus();
  const { createParams } = useMiLocOrTeamId({ sendTeamId: false });
  const { createIssue, updateIssueChargeBack, addItemsToIssue } = useIssueDB();
  const [isLoading, setIsLoading] = useState(false);
  const { userInfo } = useSelector((state: RootState) => state.user);
  const userId = get(userInfo, 'userid', '');
  const username = get(userInfo, 'cn', 'User Name');
  const params: Dictionary<string> = {
    ...createParams(),
  };
  const { miLoc } = params;

  const { findBarcodeItems } = useFindBarcodeItems();

  const doAddIssue = ({
    storeroomNumber,
    chargeBackValues,
    items,
  }: AddIssueBody) => {
    return doPromiseAPI(async () => {
      setIsLoading(true);
      const newIssueId = await createIssue({
        userId,
        userName: username,
        storeroomNumber,
        miLocation: miLoc,
        takeOnStatus: IssueStatusEnum.open,
        recordType: IssueTypeEnum.issue,
      });
      if (chargeBackValues) {
        await updateIssueChargeBack(newIssueId, chargeBackValues);
      }
      await addItemsToIssue(
        map(items, (i) => ({ ...i, issueId: toNumber(newIssueId) }))
      );
      return newIssueId;
    });
  };

  const { data, status, mutate } = useMutation(doAddIssue, {
    networkMode: 'always',
    onSuccess: () => {
      void onSuccessMutation(queryClient, findIssuesQueryKey);
      void onSuccessMutation(queryClient, findStoreroomsQueryKey);
      setIsLoading(false);
      if (!isOnline) {
        addToast({
          type: ToastType.warn,
          text: 'Issue created and saved in the local device. You can upload it manually later.',
          testid: 'add-issue-offline-toast',
        });
      }
    },
    onError: () => {
      addToast({
        type: ToastType.error,
        text: 'There was an error adding issue.',
        testid: 'add-issue-error-toast',
      });
      setIsLoading(false);
    },
  });

  return {
    newIssueId: toString(data),
    status,
    isLoading,
    doAddIssue,
    onAddIssue: (body: AddIssueBody) => mutate(body),
    getBarcodeItem: async (
      barcode: string,
      selectedItems: ItemIssueDTO[],
      storeroomNumber: string
    ) => {
      let barcodeItems = await findBarcodeItems({
        barcode,
        storeroomNumber,
      });
      barcodeItems = filter(barcodeItems, (item) => {
        const itemName = `${item.barcodeValue} ${item.itemDescription}`;
        const itemId = item.combinedId;
        const itemFound = find(selectedItems, (i) => i.itemId === itemId);
        if (itemFound) {
          addToast({
            text: `The item "${itemName}" is already in the issue.`,
            type: ToastType.warn,
            testid: `feedback-warning-toast-${toString(itemId)}`,
          });
          return false;
        }
        return true;
      });
      return barcodeItems;
    },
  };
};

export default useAddIssue;
