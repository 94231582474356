import React, { useEffect, useState } from 'react';
import { toString } from 'lodash';
import { IonImg } from '@ionic/react';
import useGetAttachment from 'api/attachments/useGetAttachment';
import type { ReportImage } from 'models/InspectionReport';
import Loader from 'components/Loader/Loader';
import classes from './DocumentImage.module.scss';

const width = 208;
const height = 208;

interface ImageBlobProps {
  image: ReportImage;
  onImageClick: (image: ReportImage, data?: string) => void;
}
const DocumentThumbnail = ({
  image,
  onImageClick,
}: ImageBlobProps): JSX.Element => {
  const [imageToShow, setImageToShow] = useState<ReportImage>(image);

  const { data, isLoading } = useGetAttachment({
    domain: image.entity || '',
    miLoc: toString(image.miLoc),
    ctlNo: toString(image.imageId),
    lineNo: toString(image.groupId),
    seqNo: toString(image.seqNo || ''),
    fileName: toString(image.imagePath || ''),
    width,
    height,
  });

  useEffect(() => {
    const addDataToImage = image;
    addDataToImage.image = data;
    setImageToShow(addDataToImage);
  }, [image, data]);

  if (isLoading) {
    return <Loader isOpen={isLoading} text="Loading image" />;
  }
  return (
    <IonImg
      className={classes.thumbnail}
      src={data}
      onClick={() => onImageClick(imageToShow, data)}
    />
  );
};

export default DocumentThumbnail;
