import { useTranslation } from 'react-i18next';
import { get, map, toString } from 'lodash';
import type { MutationStatus } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { namespaces } from 'i18n/i18n.constants';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import { getAttachmentQueryKey } from 'api/attachments/useGetAttachment';
import { doPromiseAPI, useKeyUserId } from 'api/helpers';
import type { MiProFile } from 'models/Attachment';
import { ToastType } from 'models/Toast';
import { getFileExtension, isImageFile } from 'utils/filesUpload';

interface UseSendEmailBody {
  subject: string;
  body: string;
  recipients: string[];
  filesToUpload: File[];
  toastText?: string;
  cc?: string[];
}

interface UseSendEmailResponse {
  status: MutationStatus;
  onSendEmail: (body: UseSendEmailBody) => void;
}

const useSendEmail = (): UseSendEmailResponse => {
  const { axios } = useAxios();
  const { emailAPI } = useAPIUrl();
  const { addToast } = useToasts();
  const queryClient = useQueryClient();
  const { createQueryKey } = useKeyUserId();

  const doSendEmail = async ({
    subject,
    body,
    recipients,
    filesToUpload,
    cc = [],
  }: UseSendEmailBody) => {
    return doPromiseAPI(async (cancelToken) => {
      const formData = new FormData();
      formData.append(
        'data',
        JSON.stringify({ subject, body, recipients, bcc: [], cc })
      );

      await Promise.all(
        map(filesToUpload, async (file) => {
          const fileName = toString(get(file, 'fileName'));
          const fileLoc = (file as MiProFile).FILE_NAME;

          if (file.type.startsWith('image/') || !fileLoc) {
            const fileData: ArrayBuffer = await new Promise((resolve) => {
              const reader = new FileReader();
              reader.onload = (fileResult) => {
                resolve(fileResult.target?.result as ArrayBuffer);
              };
              reader.readAsArrayBuffer(file);
            });
            const fileBlob = new Blob([new Uint8Array(fileData)], {
              type: file.type,
            });
            formData.append(fileName, fileBlob, fileName);
          } else {
            const existingThumbFile = file as MiProFile;
            let fileType = file.type;
            fileType ||= getFileExtension({ file: existingThumbFile });
            const isImage = isImageFile(fileType);
            const imageProps = isImage ? { width: 500, height: 500 } : {};
            const fileBlob = queryClient.getQueryData<string>(
              createQueryKey(getAttachmentQueryKey, {
                miLoc: existingThumbFile?.miLoc || existingThumbFile?.MI_LOC,
                ctlNo: existingThumbFile?.CTL_NO || existingThumbFile?.ctlNo,
                lineNo: existingThumbFile?.lineNo || existingThumbFile?.LINE_NO,
                seqNo: existingThumbFile?.seqNo || existingThumbFile?.SEQ_NO,
                fileName,
                ...imageProps,
                domain: toString(
                  existingThumbFile.ENTITY || existingThumbFile.entity
                ),
              })
            );
            const blob = await fetch(fileBlob || '').then((res) => res.blob());
            formData.append(fileName, blob, fileName);
          }
        })
      );
      await axios.post(emailAPI(), formData, {
        cancelToken,
      });
    });
  };
  const { t } = useTranslation(namespaces.email);

  const { mutate, status } = useMutation(doSendEmail, {
    onSuccess: (data, vars) =>
      addToast({
        text: vars.toastText || t('emailSentToast'),
        testid: 'email-success-toast',
        type: ToastType.success,
        variant: 'mipro-toast',
        leftIcon: ['fas', 'check-circle'],
      }),
    onError: () =>
      addToast({
        type: ToastType.error,
        text: t('emailErrorToast'),
        testid: 'email-error-toast',
      }),
  });

  return {
    status,
    onSendEmail: (body: UseSendEmailBody) => mutate(body),
  };
};

export default useSendEmail;
