import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isNil, map, size } from 'lodash';
import {
  IonCol,
  IonContent,
  IonFooter,
  IonPage,
  IonRow,
  IonToolbar,
} from '@ionic/react';
import { useDebounce } from 'use-debounce';
import useAddFavorites from 'api/favorites/useAddFavorites';
import useRemoveFavorites from 'api/favorites/useRemoveFavorites';
import { useMiLocOrTeamId } from 'api/helpers';
import useActionOperations from 'hooks/useActionOperations';
import type { FavoriteOperation } from 'models/Favorites';
import type { SearchItem } from 'models/Search';
import { findIcon } from 'utils/icons';
import ActionRow from 'components/ActionRow/ActionRow';
import Button from 'components/Button/Button';
import Header from 'components/Header/Header';
import Searchbar from 'components/Searchbar/Searchbar';
import classes from './Favorites.module.scss';
import FavoritesList from './FavoritesList/FavoritesList';

const Favorites = (): JSX.Element => {
  const [isEditable, setIsEditable] = useState(false);
  const [hasFavorites, setHasFavorites] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryValue] = useDebounce(searchQuery, 300);
  const { createParams } = useMiLocOrTeamId({ sendTeamId: false });
  const { miLoc: favMiLoc } = createParams();

  const { t } = useTranslation();
  const {
    operations,
    operationStatus,
    addOperation,
    completeOperations,
    cancelOperations,
    doOperations,
    undoOperations,
  } = useActionOperations<FavoriteOperation>();

  const { onAddFavorites } = useAddFavorites({});

  const onEdit = () => {
    setIsEditable(true);
    completeOperations();
  };

  const onUndo = () => {
    undoOperations();
    const items: SearchItem[] = [];
    map(operations, ({ type, miLoc, id, from }) => {
      if (isNil(from)) {
        items.push({ miLoc, id, type } as SearchItem);
      }
      // TODO reorder
    });
    onAddFavorites({ items });
  };

  const { onRemoveFavorites } = useRemoveFavorites({
    isMultiple: true,
    onUndo,
    toastMessage: t('favorites:favoriteToast'),
  });

  const onSave = () => {
    setIsEditable(false);
    doOperations();
    const items: SearchItem[] = [];
    map(operations, ({ type, miLoc, id, from }) => {
      if (isNil(from)) {
        items.push({ miLoc, id, type } as SearchItem);
      }
      // TODO reorder
    });
    onRemoveFavorites({ items });
  };

  const onCancel = () => {
    setIsEditable(false);
    cancelOperations();
  };

  return (
    <IonPage className={classes.page} data-testid="favorites-page">
      <Header
        className={classes.header}
        withBackButton={!isEditable}
        title={isEditable ? t('favorites:manageFavorites') : t('favorites')}
        testid="favorites"
      >
        <Searchbar
          className={classes.input}
          value={searchQuery}
          placeholder={t('favorites:searchFavorites')}
          setValue={setSearchQuery}
          testid="search-input"
        />
      </Header>
      <IonContent className={classes.content}>
        <IonRow className={classes.row}>
          <IonCol className={classes.column}>
            {hasFavorites && !isEditable && (
              <ActionRow
                className={classes.editButton}
                text={t('favorites:editFavorites')}
                icon={findIcon('cog')}
                onClick={onEdit}
                testid="edit-favorites-button"
              />
            )}
            <FavoritesList
              className={classes.list}
              type="fullscreen"
              setHasFavorites={setHasFavorites}
              isEditable={isEditable}
              operationStatus={operationStatus}
              searchQuery={searchQueryValue}
              addOperation={addOperation}
              testid="favorites-list"
              favMiLoc={favMiLoc}
            />
          </IonCol>
        </IonRow>
      </IonContent>
      {isEditable && (
        <IonFooter className={classes.footer}>
          <IonToolbar>
            <IonRow>
              <Button
                className={classes.saveButton}
                variant="action"
                text={t('save')}
                onClick={onSave}
                disabled={size(operations) === 0}
                testid="save-button"
              />
              <Button
                variant="secondary"
                text={t('cancel')}
                onClick={onCancel}
                testid="cancel-button"
              />
            </IonRow>
          </IonToolbar>
        </IonFooter>
      )}
    </IonPage>
  );
};

export default Favorites;
