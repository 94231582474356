import React from 'react';
import classNames from 'classnames';
import { toString } from 'lodash';
import { IonItemOption, IonItemOptions, IonItemSliding } from '@ionic/react';
import useDeleteActivityNote from 'ActivitiesApp/api/notes/useDeleteActivityNote';
import { goToActivityAddEditNoteURL } from 'ActivitiesApp/navigation/navigationHelpers';
import Notebook from 'common/components/Notebook/Notebook';
import type { ActivityNote } from 'models/Notebook';
import Button from 'components/Button/Button';
import type { SwipeOption } from 'components/Slider/Slider';
import classes from './ActivityNotebook.module.scss';

interface ActivityNotebookProps {
  notebook: ActivityNote;
  custNo: string;
  onSwipeLeft?: SwipeOption;
}

const ActivityNotebook = ({
  notebook,
  custNo,
  onSwipeLeft,
}: ActivityNotebookProps): JSX.Element => {
  const { title, lastUpdatedTimestamp, userId, userFullName } = notebook;

  const { deleteActivityNote, status } = useDeleteActivityNote();
  const isUpdating = status === 'loading' || notebook.isOptimisticallyUpdating;

  const onDelete = () => {
    deleteActivityNote({
      deleteFlag: 'Y',
      historyId: toString(notebook.historyId),
      id: notebook.eventNoteId,
    });
  };

  return (
    <IonItemSliding
      disabled={isUpdating}
      className={classNames(classes.wrapper, {
        [classes.isUpdating]: isUpdating,
      })}
    >
      {onSwipeLeft && (
        <IonItemOptions side="end">
          <IonItemOption
            className={classes[onSwipeLeft.className || '']}
            onClick={onDelete}
          >
            <Button
              text={onSwipeLeft?.text}
              icon={onSwipeLeft?.icon}
              testid="slide-left"
            />
          </IonItemOption>
        </IonItemOptions>
      )}

      <Notebook
        title={title}
        attachments={notebook.attachments}
        href={goToActivityAddEditNoteURL({
          miLoc: notebook.miLoc,
          customerId: custNo,
          historyId: toString(notebook.historyId),
          userId: notebook.userId,
          eventNoteId: toString(notebook.eventNoteId),
        })}
        testid={toString(notebook.eventNoteId)}
        lastUpdatedTimestamp={lastUpdatedTimestamp}
        userName={userFullName}
        userId={userId}
      />
    </IonItemSliding>
  );
};

export default ActivityNotebook;
