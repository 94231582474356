import { toString } from 'lodash';
import MIProLogo from 'assets/MIProLogo.svg';
import MIProLogoDev from 'assets/MIProLogoDev.svg';
import MIProLogoModel from 'assets/MIProLogoModel.svg';
import MIProLogoQat from 'assets/MIProLogoQat.svg';
import MIProLogoRed from 'assets/MIProLogoRed.svg';
import MIProLogoWhite from 'assets/MIProLogoWhite.svg';
import MIServicesLogo from 'assets/MIServicesLogo.svg';
import MIServicesLogoWhite from 'assets/MIServicesLogoWhite.svg';

interface UseGetAppLogoResponse {
  isServicesLogo: boolean;
  logo: string;
  whiteLogo: string;
  redLogo: string;
}

const getMiProLogo = (): string => {
  switch (toString(process.env.REACT_APP_ENV_BUILD)) {
    case 'local': {
      return MIProLogoDev;
    }
    case 'dev': {
      return MIProLogoDev;
    }
    case 'qat': {
      return MIProLogoQat;
    }
    case 'model': {
      return MIProLogoModel;
    }
    default: {
      return MIProLogo;
    }
  }
};

const useGetAppLogo = (): UseGetAppLogoResponse => {
  const isServicesLogo = process.env.REACT_APP_FEATURE_SERVICES === 'true';

  return {
    isServicesLogo,
    logo: isServicesLogo ? MIServicesLogo : getMiProLogo(),
    whiteLogo: isServicesLogo ? MIServicesLogoWhite : MIProLogoWhite,
    redLogo: MIProLogoRed,
  };
};

export default useGetAppLogo;
