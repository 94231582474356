import React from 'react';
import classNames from 'classnames';
import {
  find,
  isEmpty,
  isNil,
  kebabCase,
  map,
  toNumber,
  toString,
  toUpper,
} from 'lodash';
import type { IconName } from '@fortawesome/fontawesome-common-types';
import { IonCard, IonCardContent } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { isToday } from 'date-fns';
import useAccessControls, { AccessControlType } from 'hooks/useAccessControls';
import type {
  ReportsContextProps,
  RoleGroupType,
  SummaryItemOutput,
} from 'models/Reports';
import { SalesDrillDownFields } from 'models/Reports';
import { formatDate, parseDate } from 'utils/date';
import { withStringProp } from 'utils/helpers';
import useDrillDownData from 'pages/Reports/useDrillDownData';
import CardHeader from 'components/CardHeader/CardHeader';
import { ReportChangeText } from 'components/Charts/ReportHeader/ReportHeader';
import Text from 'components/Text/Text';
import classes from './ReportCard.module.scss';

interface ReportCardProps {
  items: SummaryItemOutput[];
  fields?: string[];
  title: string;
  customerPick12?: boolean;
  subtitle?: string;
  roleGroup?: RoleGroupType;
  showMonth?: boolean;
  displayChange?: boolean;
  iconName?: IconName;
  iconClassName?: string;
  testid: string;
}

const ReportCard = ({
  className,
  title,
  customerPick12,
  subtitle,
  requestType,
  busPeriod,
  roleGroup,
  items,
  fields,
  href,
  onClick,
  showMonth,
  displayChange = true,
  iconName = 'chevron-right',
  iconClassName,
  testid,
}: ReportCardProps &
  Pick<ReportsContextProps, 'busPeriod' | 'requestType'> &
  React.ComponentProps<typeof IonCard> &
  IonicReactProps): JSX.Element => {
  const ac = useAccessControls(AccessControlType.viewCustomers);
  const withHref = withStringProp(href);
  const hasTodayLabel =
    requestType === 'DAILY' && isToday(parseDate(busPeriod));

  const orderFields = fields || SalesDrillDownFields;

  const { translate, showAmount } = useDrillDownData();

  return (
    <div className={classNames(className)} data-testid={`ReportCard-${testid}`}>
      <IonCard
        href={href}
        routerLink={href}
        onClick={onClick}
        className={classes.reportCard}
        data-testid={`IonReportCard-${testid}`}
        disabled={!ac && roleGroup === 'CUST'}
      >
        <CardHeader
          title={title}
          withHref={withHref}
          className={subtitle ? classes.customerCardHeader : classes.cardHeader}
          suffix={`${hasTodayLabel ? 'Today' : toString(requestType)}${
            showMonth ? ` - ${toUpper(formatDate(busPeriod, 'MMM'))}` : ''
          }`}
          customerPick12={customerPick12}
          iconName={iconName}
          iconClassName={iconClassName}
          testid={testid}
        />
        <IonCardContent>
          {subtitle && (
            <Text
              className={classes.subtitle}
              text={subtitle}
              variant="content-small"
              testid={`card-subtitle-${testid}`}
            />
          )}
          {map(orderFields, (key) => {
            const {
              amount,
              change,
              Name = '',
              currencyType,
            } = find(items, { Name: key }) || {};

            if (isEmpty(Name)) {
              return null;
            }

            return (
              <div
                className={classes.cardRows}
                key={`reports-summary-sales-${Name}`}
              >
                <div className={classes.amountLabel}>
                  <Text
                    text={translate(Name)}
                    variant="label-header"
                    testid={`reports-summary-cards-amount-text-${testid}`}
                  />
                </div>
                <div className={classes.amountValue}>
                  {showAmount(Name, toNumber(amount), testid, currencyType)}
                </div>
                {Name !== 'Unbilled' && !isNil(change) && (
                  <ReportChangeText
                    displayChange={displayChange}
                    value={change}
                    testid={`reports-cards-change-${kebabCase(Name)}-${testid}`}
                  />
                )}
              </div>
            );
          })}
        </IonCardContent>
      </IonCard>
    </div>
  );
};

export default ReportCard;
