import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { startsWith, toString } from 'lodash';
import { FormikInput } from 'common/components/Forms/Input/Input';
import { useFormikContext } from 'formik';
import useCheckCarrierAccount from 'ProductSearchApp/api/checkout/useCheckCarrierAccount';
import CarrierSelect from 'ProductSearchApp/components/FastFind/CarrierSelect';
import ShippingChargesSelect from 'ProductSearchApp/components/FastFind/ShippingChargesSelect';
import ShippingMethodSelect from 'ProductSearchApp/components/FastFind/ShippingMethodSelect';
import type {
  CheckoutOrderForm,
  Order,
  ShippingCharges,
  ShippingMethod,
} from 'ProductSearchApp/models/Order';
import { OcnTypeEnum } from 'ProductSearchApp/models/Order';
import useGetCustomer from 'api/customer/useGetCustomer';
import CheckBox from 'components/CheckBox/CheckBox';
import Text from 'components/Text/Text';
import classes from './CheckoutForm.module.scss';

interface ShippingInfoProps {
  order?: Order;
  disabled?: boolean;
  ocnType: OcnTypeEnum;
}

const ShippingInfo = ({
  order,
  disabled,
  ocnType,
}: ShippingInfoProps): JSX.Element => {
  const namespace = 'productSearch:checkout';
  const { t } = useTranslation();
  const isOrder = ocnType === OcnTypeEnum.order;

  const { carrierAccountData, doCheckCarrierAccount } =
    useCheckCarrierAccount();

  const { data: customerData } = useGetCustomer({
    searchType: 'customer',
    miLoc: toString(order?.miLoc),
    id: toString(order?.shipToCustNo),
  });

  const {
    values: { carrierCd, shipMethodCd, shipChargesCd, shipComplete },
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<CheckoutOrderForm>();

  const shipMethodCdError = errors.shipMethodCd as unknown as ShippingMethod;
  const shipChargesCdError = errors.shipChargesCd as unknown as ShippingCharges;
  const isAcctCarrierDisabled =
    customerData?.barUPSOrderAccount === 'Y' &&
    startsWith(carrierCd?.codeValue, 'UP');

  const checkCarrierAccount = (
    shipChargesValue?: string,
    carrierValue?: string
  ) => {
    if (shipChargesValue && carrierValue) {
      doCheckCarrierAccount({
        miLoc: toString(order?.miLoc),
        customerNo: toString(order?.shipToCustNo),
        orderCtlNo: toString(order?.orderCtlNo),
        shipChargesCd: shipChargesValue || toString(order?.shipChargesCd),
        carrierCd: carrierValue || toString(order?.carrierCd),
      });
    }
  };

  useEffect(() => {
    if (carrierAccountData) {
      if (carrierAccountData.accountRequired === 'Y') {
        setFieldValue('carrierAccount', carrierAccountData.defCarrierAccount);
      } else {
        setFieldValue('carrierAccount', '');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carrierAccountData]);

  return (
    <div className={classes.cardWrapper}>
      <Text variant="mipro-h3-headline" text={t(`${namespace}:shippingInfo`)} />
      <CheckBox
        className={classes.checkboxWrapper}
        name="shipComplete"
        ariaLabel="shipComplete"
        label={t(`${namespace}:shipComplete`)}
        testid="ship-complete"
        checked={shipComplete === 'Y'}
        onChange={(e) => setFieldValue('shipComplete', e ? 'Y' : 'N')}
        disabled={disabled}
      />
      <ShippingChargesSelect
        className={classes.input}
        label={t(`${namespace}:shippingCharges`)}
        placeholder={t(`${namespace}:shippingChargesPlaceholder`)}
        selItem={shipChargesCd}
        setShippingChargesCode={(item) => {
          // TODO errors should handle empty object
          setFieldValue('shipChargesCd', item || {}, true);
          checkCarrierAccount(item?.codeValue, carrierCd?.codeValue);
        }}
        onClose={() => setFieldTouched('shipChargesCd', true)}
        disabled={disabled}
        required={isOrder}
        inputError={
          touched.shipChargesCd ? shipChargesCdError?.codeValue : undefined
        }
      />
      <CarrierSelect
        className={classes.input}
        label={t(`${namespace}:carrier`)}
        placeholder={t(`${namespace}:carrierPlaceholder`)}
        selItem={carrierCd}
        setCarrierCode={(item) => {
          // TODO errors should handle empty object
          setFieldValue('carrierCd', item || {});
          if (item && item?.carrierType) {
            setFieldValue(
              'shipMethodCd',
              {
                codeValue: item.carrierType,
                codeDesc: toString(item.shippingMethodDesc),
              },
              true
            );
            checkCarrierAccount(shipChargesCd?.codeValue, item?.codeValue);
          }
        }}
        onClose={() => setFieldTouched('carrierCd', true)}
        disabled={disabled}
      />
      <ShippingMethodSelect
        className={classes.input}
        label={t(`${namespace}:shippingMethod`)}
        placeholder={t(`${namespace}:shippingMethodPlaceholder`)}
        selItem={shipMethodCd}
        setShippingMethodCode={(item) => {
          // TODO errors should handle empty object
          setFieldValue('shipMethodCd', item || {}, true);
        }}
        onClose={() => setFieldTouched('shipMethodCd', true)}
        disabled={disabled}
        required={isOrder}
        inputError={
          touched.shipMethodCd ? shipMethodCdError?.codeValue : undefined
        }
      />
      <FormikInput
        className={classes.input}
        label={t(`${namespace}:carrierAccount`)}
        name="carrierAccount"
        toUpperCase
        disabled={isAcctCarrierDisabled || disabled}
        testid="carrier-account"
      />
      <FormikInput
        className={classes.input}
        label={t(`${namespace}:handlingInst`)}
        name="handlingInst"
        toUpperCase
        disabled={disabled}
        testid="handling-inst"
      />
    </div>
  );
};

export default ShippingInfo;
