import type { AxiosError } from 'axios';
import { isEmpty, toString } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import type { CostSavingsTemplate } from 'CostSavingsApp/models/CostSavings';
import { useAxios } from 'providers/AxiosProvider';
import { doGetIsLoading, doPromiseAPI, useKeyUserId } from 'api/helpers';
import type { QueryFlags } from 'models/Search';

export const getCostTemplateQueryKey = 'cost-template';

interface UseGetCostTemplateProps {
  miLoc: string;
  customerNo: string;
  vasCode: string;
  enabled?: boolean;
}

interface UseGetCostTemplateResponse {
  data?: CostSavingsTemplate;
}

const useGetCostTamplate = ({
  miLoc,
  customerNo,
  vasCode,
  enabled = true,
}: UseGetCostTemplateProps): UseGetCostTemplateResponse & QueryFlags => {
  const { axios } = useAxios();
  const { getCostTemplateAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const params = {
    miLoc,
    customerNo,
    vasCode,
  };

  const doGetCostTemplate = () => {
    return doPromiseAPI<CostSavingsTemplate>(async (cancelToken) => {
      const { data } = await axios.get<CostSavingsTemplate>(
        getCostTemplateAPI(toString(new URLSearchParams(params))),
        { cancelToken }
      );
      return data;
    });
  };
  const response = useQuery<CostSavingsTemplate, AxiosError>(
    createQueryKey(getCostTemplateQueryKey, params),
    doGetCostTemplate,
    {
      enabled:
        enabled && !isEmpty(miLoc) && !isEmpty(customerNo) && !isEmpty(vasCode),
    }
  );

  const { data, error, refetch } = response;

  return {
    data,
    error,
    isLoading: doGetIsLoading(response),
    refetch: async () => {
      await refetch();
    },
  };
};

export default useGetCostTamplate;
