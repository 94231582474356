import React from 'react';
import { useTranslation } from 'react-i18next';
import { choose } from 'common/utils/logicHelpers';
import { namespaces } from 'i18n/i18n.constants';
import {
  searchURL,
  searchCustomerURL,
  customerWebActivityURL,
  costSavingsURL,
  logAVisitURL,
  orderListURL,
} from 'navigation';
import { goToCorpCustomerSearch } from 'SearchApp/navigation/navigationHelpers';
import useMiComConnection from 'api/external/useMiComConnection';
import useAccessControls, {
  AccessControlType,
  useHasAccessControls,
} from 'hooks/useAccessControls';
import { concatRoutes } from 'utils/navigations';
import { goToProductSearch } from 'navigation/navigationHelpers';
import LinkedCard from 'components/LinkedCard/LinkedCard';
import classes from './Snapshots.module.scss';

interface CustomerDetailsProps {
  miLoc: string;
  id: string;
  isCorpAccount?: boolean;
}

const CustomerDetails = ({
  miLoc,
  id,
  isCorpAccount = false,
}: CustomerDetailsProps): JSX.Element => {
  const { t } = useTranslation(namespaces.snapshot);
  const { hasAccessControl } = useHasAccessControls();

  const canCostSavingsEntry = choose(
    isCorpAccount,
    hasAccessControl(
      AccessControlType.AddCostSavingsAccessControlsForCorpAccount
    ),
    hasAccessControl(AccessControlType.AddCostSavingsAccessControls)
  );

  const canViewOrders = useAccessControls(
    AccessControlType.ViewOrdersAccessControls
  );

  const { openMiComOrdersLink } = useMiComConnection({
    miLoc,
    id,
  });

  return (
    <div className={classes.miniCardWrapper}>
      <div className={classes.externalLinks}>
        {!isCorpAccount && (
          <>
            <LinkedCard
              className={classes.linkedCard}
              title={t('productSearch')}
              testid="product-search-link"
              href={goToProductSearch(miLoc, id)}
            />
            <LinkedCard
              className={classes.linkedCard}
              title={t('quotesAndOrderLinkButton')}
              disabled={!canViewOrders}
              testid="quotes-link"
              href={concatRoutes(
                searchURL(),
                searchCustomerURL(miLoc, id),
                orderListURL()
              )}
            />
          </>
        )}
        <LinkedCard
          className={classes.linkedCard}
          title={t('costSavings:costSavings')}
          disabled={!canCostSavingsEntry}
          href={concatRoutes(searchURL(), costSavingsURL(miLoc, id, true))}
          testid="cost-savings-link"
        />
        {isCorpAccount && (
          <LinkedCard
            className={classes.linkedCard}
            title={t('common:customers')}
            href={goToCorpCustomerSearch({ miLoc, customerNo: id })}
            testid="customers-link"
          />
        )}
      </div>
      <div className={classes.externalLinks}>
        {!isCorpAccount && (
          <LinkedCard
            className={classes.linkedCard}
            title={t('viewWebActivity')}
            href={concatRoutes(
              searchURL(),
              searchCustomerURL(miLoc, id),
              customerWebActivityURL()
            )}
            testid="web-activity-link"
          />
        )}
        <LinkedCard
          className={classes.linkedCard}
          title={t('logVisit')}
          href={concatRoutes(
            searchURL(),
            searchCustomerURL(miLoc, id),
            logAVisitURL()
          )}
          testid="log-visit-link"
        />
        {!isCorpAccount && (
          <LinkedCard
            className={classes.linkedCard}
            title={t('motionComLinkButton')}
            onClick={openMiComOrdersLink}
            testid="orders-link"
            iconName="external-link-alt"
          />
        )}
      </div>
    </div>
  );
};

export default CustomerDetails;
