import React, { useState } from 'react';
import type { AxiosError } from 'axios';
import classNames from 'classnames';
import { toNumber, isNil, kebabCase, map, includes } from 'lodash';
import { IonRow } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import type {
  RoleGroupType,
  ReportsContextProps,
  SalesTrend,
  ProfitTrend,
  SummaryItemOutput,
  CalendarDay,
  GetReportSummaryResponse,
  ReportDrillDownItem,
} from 'models/Reports';
import ReportCard from 'pages/Reports/ReportCard/ReportCard';
import type { BarColumnData } from 'components/Charts/DailyChart/Chart';
import DailyChart from 'components/Charts/DailyChart/DailyChart';
import LineChart from 'components/Charts/LineChart/LineChart';
import Filter from 'components/Filter/Filter';
import type { GetDrillDownLinkReponse } from './DrillDown';
import classes from './DrillDown.module.scss';

interface SalesDrillDownProps {
  summaryShowLoader: boolean;
  salesData: SalesTrend[];
  lastYearSalesData: SalesTrend[];
  profitData: ProfitTrend[];
  lastYearProfitData: ProfitTrend[];
  headerData: SummaryItemOutput[];
  summaryData?: GetReportSummaryResponse;
  summaryError?: AxiosError | null;
  dailySalesData?: BarColumnData;
  dailyLastYearSalesData?: BarColumnData;
  dailyProfitData?: BarColumnData;
  dailyLastYearProfitData?: BarColumnData;
  displayYoYChange: boolean;
  selectedDate: Date;
  enableBusDate: boolean;
  currentBusDay: CalendarDay;
  sharedBusinessDayLabel?: React.ReactNode;
  scrollContent?: React.ReactNode;
  drilldownData: ReportDrillDownItem[];
  hasDrilldownError?: boolean;
  getDrilldownLink: (item: ReportDrillDownItem) => GetDrillDownLinkReponse;
  testid: string;
}

const SalesDrillDown = ({
  className,
  requestType,
  busPeriod,
  summaryShowLoader,
  salesData,
  lastYearSalesData,
  profitData,
  lastYearProfitData,
  headerData,
  summaryData,
  summaryError,
  dailySalesData,
  dailyLastYearSalesData,
  dailyProfitData,
  dailyLastYearProfitData,
  displayYoYChange,
  selectedDate,
  enableBusDate,
  currentBusDay,
  sharedBusinessDayLabel,
  scrollContent,
  drilldownData,
  hasDrilldownError,
  getDrilldownLink,
  selectedItems,
  setFilterData,
  filterOptions,
  testid,
}: SalesDrillDownProps &
  Pick<
    React.ComponentProps<typeof Filter>,
    'selectedItems' | 'setFilterData' | 'filterOptions'
  > &
  IonicReactProps &
  Pick<ReportsContextProps, 'requestType' | 'busPeriod'>): JSX.Element => {
  const [tabValue, setTabValue] = useState('sales');
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const ReportCardContent = map(drilldownData, (drilldownItem, index) => {
    const { Name, miLoc: itemMiLoc, id: itemId, items } = drilldownItem;
    const { title, groupByDataKey, href, onClick } =
      getDrilldownLink(drilldownItem);

    return (
      <ReportCard
        key={`report-card-${kebabCase(Name)}-${index}` || index}
        title={title}
        subtitle={
          includes(['CUST'], groupByDataKey) ? `${itemMiLoc}${itemId}` : ''
        }
        items={items}
        displayChange={displayYoYChange}
        requestType={requestType}
        busPeriod={busPeriod}
        roleGroup={groupByDataKey as RoleGroupType}
        customerPick12={drilldownItem.customerPick12}
        onClick={onClick}
        href={href}
        testid={`sales-${kebabCase(Name)}-${index}`}
      />
    );
  });

  return (
    <div className={classNames(className)} data-testid={`DrillDown-${testid}`}>
      {includes(['MTD', 'YTD'], requestType) && (
        <LineChart
          isLoading={summaryShowLoader}
          error={summaryError}
          salesData={salesData}
          lastYearSalesData={lastYearSalesData}
          profitData={profitData}
          lastYearProfitData={lastYearProfitData}
          requestType={requestType}
          headerData={headerData}
          selectedDate={selectedDate}
          enableBusDate={enableBusDate}
          disableHighlight={isNil(currentBusDay)}
          tabValue={tabValue}
          setTabValue={setTabValue}
          busDay={toNumber(currentBusDay?.busDay)}
          testid="drilldown-test"
          currencyType={summaryData?.currencyType}
        />
      )}
      {includes(['DAILY'], requestType) && (
        <DailyChart
          isLoading={summaryShowLoader}
          error={summaryError}
          salesData={dailySalesData}
          profitData={dailyProfitData}
          lastYearSalesData={dailyLastYearSalesData}
          lastYearProfitData={dailyLastYearProfitData}
          headerData={headerData}
          displayChange={displayYoYChange}
          requestType={requestType}
          selectedDate={selectedDate}
          tabValue={tabValue}
          setTabValue={setTabValue}
          testid="daily-chart-test"
          currencyType={summaryData?.currencyType}
        />
      )}
      <IonRow className={classes.filterRow}>
        <Filter
          variant="sort"
          initialBreakpoint={0.75}
          selectedItems={selectedItems}
          setFilterData={setFilterData}
          filterOptions={filterOptions}
          isOpen={isFilterOpen}
          setIsOpen={setIsFilterOpen}
          testid="report-sorter"
        />
      </IonRow>
      {sharedBusinessDayLabel}
      {!hasDrilldownError && ReportCardContent}
      {scrollContent}
    </div>
  );
};

export default SalesDrillDown;
