import React from 'react';
import classNames from 'classnames';
import type { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonRow } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import isNil from 'lodash/isNil';
import { SalesPlayTypesEnum } from 'models/SalesPlays';
import { findIcon } from 'utils/icons';
import { convertSalesTypeForDisplay } from 'utils/salesPlays';
import Text from 'components/Text/Text';
import classes from './SalesPlayDetailHeadline.module.scss';

interface SalesPlayHeadlineProps {
  title: string;
  testid: string;
  iconColor?: string;
  iconName?: IconName;
  headerType?: string;
}

const SalesPlayDetailHeadline = ({
  className,
  title,
  testid,
  iconColor,
  iconName,
  headerType,
}: SalesPlayHeadlineProps & IonicReactProps): JSX.Element => {
  let badgeClass;
  switch (headerType) {
    case SalesPlayTypesEnum.CrossSell:
      badgeClass = classes.crossSellBadge;
      break;
    case SalesPlayTypesEnum.Declining:
      badgeClass = classes.decliningBadge;
      break;
    case SalesPlayTypesEnum.Overdue:
      badgeClass = classes.overdueBadge;
      break;
    case SalesPlayTypesEnum.UpSell:
      badgeClass = classes.upSellBadge;
      break;
    default:
      badgeClass = classes.badgeText;
  }
  return (
    <div
      className={classNames(classes.mainHeadline, className)}
      data-testid={`main-headline-${testid}`}
    >
      <IonRow className={classes.row}>
        <Text
          variant="mipro-h1-headline"
          text={title || ''}
          testid={`main-headline-title-${testid}`}
        />
        <IonRow className={classes.badgeRow}>
          {!isNil(iconName) && (
            <span
              className={classNames(classes.badge, classes.opportunityType)}
              data-testid={`sales-play-type-badge-${testid}`}
            >
              <span className={classes.opportunityTypeBadge}>
                <FontAwesomeIcon
                  icon={findIcon(iconName)}
                  data-testid={`sales-play-type-badge-icon-${testid}`}
                  color={iconColor}
                />
              </span>
              <Text
                className={badgeClass}
                text={convertSalesTypeForDisplay(headerType) || ''}
                testid={`sales-play-type-badge-text-${testid}`}
              />
            </span>
          )}
        </IonRow>
      </IonRow>
    </div>
  );
};

export default SalesPlayDetailHeadline;
