import React from 'react';
import classNames from 'classnames';
import { startCase, toString } from 'lodash';
import { IonAvatar } from '@ionic/react';
import { withStringProp, getUserInitials, colorHash } from 'utils/helpers';
import classes from './Avatar.module.scss';

const AvatarCard = ({
  data,
  displayName,
  className,
  backgroundColor,
}: {
  data?: string;
  displayName: string;
  className?: string;
  backgroundColor?: string;
}): JSX.Element => {
  const withAvatar = withStringProp(data);

  return (
    <IonAvatar
      data-testid="Avatar"
      className={classNames(classes.avatar, className)}
    >
      {withAvatar ? (
        <img
          alt="user avatar"
          src={toString(data)}
          data-testid="Avatar-image"
        />
      ) : (
        <div
          style={{
            backgroundColor: colorHash.hex(startCase(displayName)),
          }}
          className={classNames(classes.fallback, backgroundColor)}
          data-testid="Avatar-fallback"
        >
          <span>{getUserInitials(displayName)}</span>
        </div>
      )}
    </IonAvatar>
  );
};

export default AvatarCard;
