import React, { useEffect } from 'react';
import classNames from 'classnames';
import { withStringProp } from 'utils/helpers';
import ActionSheet from 'components/ActionSheet/ActionSheet';
import Button from 'components/Button/Button';
import Text from 'components/Text/Text';
import TitleLine from 'components/TitleLine/TitleLine';
import classes from './ConfirmDialog.module.scss';

interface ConfirmDialogProps {
  isOpen: boolean;
  setIsOpen?: (b: boolean) => void;
  autoClose?: boolean;
  title: string;
  text?: string;
  primaryText?: string;
  secondaryText?: string;
  onPrimaryClick?: () => void;
  onSecondaryClick?: () => void;
  onClose?: () => void;
  disabled?: boolean;
  testid: string;
  triggerResize?: (d: number) => void;
}

const ConfirmDialog = ({
  isOpen,
  setIsOpen,
  autoClose = true,
  title,
  text = '',
  primaryText,
  secondaryText,
  onPrimaryClick,
  onSecondaryClick,
  onClose,
  children,
  disabled,
  testid,
  triggerResize,
}: React.PropsWithChildren<ConfirmDialogProps>): JSX.Element => {
  const withText = withStringProp(text);
  const withPrimaryText = withStringProp(primaryText);
  const withSecondaryText = withStringProp(secondaryText);

  useEffect(() => {
    triggerResize?.call(null, Date.now());
  }, [isOpen, setIsOpen, triggerResize]);

  return (
    <ActionSheet
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClose={onClose}
      testid={testid}
    >
      <Text
        className={classes.title}
        variant="underlined-title-section"
        text={title}
      />
      <TitleLine
        className={classNames({ [classes.withText]: withText || children })}
      />
      {withText && <Text className={classes.content} text={text} />}
      {children}
      {withPrimaryText && (
        <Button
          className={classes.primaryButton}
          variant="action"
          text={primaryText}
          onClick={() => {
            if (autoClose) {
              setIsOpen?.(false);
            }
            onPrimaryClick?.();
          }}
          disabled={disabled}
          testid={`${testid}-primary-button`}
        />
      )}
      {withSecondaryText && (
        <Button
          className={classes.secondaryButton}
          variant="secondary"
          text={secondaryText}
          onClick={() => {
            if (autoClose) {
              setIsOpen?.(false);
            }
            onSecondaryClick?.();
          }}
          disabled={disabled}
          testid={`${testid}-secondary-button`}
        />
      )}
    </ActionSheet>
  );
};

export default ConfirmDialog;
