import { useMemo } from 'react';
import type { AxiosError } from 'axios';
import type { Dictionary } from 'lodash';
import { size, map, head, toLower, toString, orderBy } from 'lodash';
import { useInfiniteQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import type {
  QueryFnProps,
  QueryParamsType,
} from 'common/api/utils/useGetQueryFlags';
import { useAxios } from 'providers/AxiosProvider';
import {
  doConcatDataPages,
  doGetIsLoading,
  useKeyUserId,
  useMiLocOrTeamId,
} from 'api/helpers';
import type {
  ReportDrillDownItem,
  RoleGroupType,
  GetUnbilledDrillDownAPIResponse,
  SummaryItemOutput,
  UnbilledDrilldownItem,
} from 'models/Reports';
import type { InfiniteQueryFlags } from 'models/Search';
import { SortFieldEnum, SortDirEnum } from 'models/Sort';
import { transformUnbilledDrillDownRows } from 'utils/reports';

export interface UseGetUnbilledReportProps {
  miLoc?: string;
  groupBy: RoleGroupType;
  sortCol?: SortFieldEnum;
  sortDir?: SortDirEnum;
  limit?: number;
  enabled: boolean;
}

interface UseGetReportUnbilledResponse {
  totalsData?: SummaryItemOutput[];
  drilldownData: ReportDrillDownItem[];
  totalRows?: number;
}

const mapGroupBy: Dictionary<string> = {
  GRP: 'GROUP',
  DIV: 'DIVISION',
  BRCH: 'BRANCH',
};

export const unbilledReportKey = 'unbilled-report';

const useGetUnbilledReport = ({
  miLoc,
  groupBy,
  sortCol = SortFieldEnum.totalPossibleAmt,
  sortDir = SortDirEnum.DESCENDING,
  enabled,
  limit = 1000,
}: UseGetUnbilledReportProps): UseGetReportUnbilledResponse &
  InfiniteQueryFlags => {
  const { axios } = useAxios();
  const { getUnbilledAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const { getURLParams } = useMiLocOrTeamId({});

  const params: QueryParamsType = {
    miLoc,
    groupBy: mapGroupBy[groupBy] || groupBy,
    limit,
  };

  let sortField = sortCol;
  if (sortField === SortFieldEnum.miLoc) {
    sortField = toLower(toString(params.groupBy)) as SortFieldEnum;
  }

  const doGetUnbilledReport = async ({
    pageParam = 1,
    signal,
  }: QueryFnProps) => {
    const { data } = await axios.get<GetUnbilledDrillDownAPIResponse>(
      getUnbilledAPI(getURLParams({ ...params, page: pageParam })),
      { signal }
    );
    return data;
  };

  const response = useInfiniteQuery<
    GetUnbilledDrillDownAPIResponse,
    AxiosError
  >(createQueryKey(unbilledReportKey, params), doGetUnbilledReport, {
    enabled,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    retry: (failureCount: number, err: AxiosError<any>) =>
      err.response?.status === 403 ? false : !(failureCount < 3),
    getNextPageParam: (lastPage, pages) =>
      size(lastPage.rows) < limit ? false : size(pages) + 1,
  });

  const {
    data,
    error,
    status,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = response;

  const concatData = useMemo(
    () =>
      doConcatDataPages<UnbilledDrilldownItem, GetUnbilledDrillDownAPIResponse>(
        data,
        'rows'
      ),
    [data]
  );

  const drilldownData = useMemo(
    () =>
      map(
        orderBy(
          concatData,
          sortField,
          sortDir === SortDirEnum.ASCENDING ? 'asc' : 'desc'
        ),
        (row) => ({
          id: `${row.corp}${row.group}${row.division}${row.branch}`,
          miLoc: row.branch || row.division || row.group || row.corp,
          Name: row.name,
          items: transformUnbilledDrillDownRows(false, row),
        })
      ),
    [concatData, sortField, sortDir]
  );

  const summaryData = useMemo(
    () => transformUnbilledDrillDownRows(true, head(data?.pages)?.summary),
    [data?.pages]
  );

  const hasItems = size(drilldownData) > 0;
  const hasError = status === 'error';
  const isEmptyResponse = status === 'success' && !hasNextPage && !hasItems;
  const noMoreData = status === 'success' && !hasNextPage && hasItems;
  const showLoader = doGetIsLoading(response) || isFetchingNextPage;
  const enableInfiniteScroll = !(!hasNextPage || isFetchingNextPage);

  return {
    refetch: async () => {
      if (enabled) {
        await refetch();
      }
    },
    fetchNextPage: async () => {
      await fetchNextPage();
    },
    totalsData: summaryData,
    totalRows: head(data?.pages)?.totalRows,
    drilldownData,
    error,
    hasError,
    showLoader,
    isEmptyResponse,
    noMoreData,
    enableInfiniteScroll,
  };
};

export default useGetUnbilledReport;
