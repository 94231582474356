import type { AxiosError } from 'axios';
import type { Dictionary } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import useReplenishmentDB from 'StoreroomsApp/database/useReplenishmentDB';
import type { ItemReplenishmentDTO } from 'StoreroomsApp/models/Replenishment';
import {
  doGetIsLoading,
  doPromiseAPI,
  useKeyUserId,
  useMiLocOrTeamId,
} from 'api/helpers';
import type { QueryFlags } from 'models/Search';

export const findReplenishmentItemsQueryKey = 'replenishment-items';

interface UseFindReplenishmentItemsProps {
  query?: string;
}

interface UseFindReplenishmentItemsResponse {
  miLoc: string;
  replenishmentItems?: ItemReplenishmentDTO[];
}

const useFindReplenishmentItems = ({
  query = '',
}: UseFindReplenishmentItemsProps): QueryFlags &
  UseFindReplenishmentItemsResponse => {
  const { userId, createQueryKey } = useKeyUserId();
  const { createParams } = useMiLocOrTeamId({ sendTeamId: false });
  const { findItemsFromOpenReplenishment } = useReplenishmentDB();
  const params: Dictionary<string> = {
    ...createParams(),
    query,
  };
  const { miLoc } = params;

  const doFindReplenishmentItems = async () => {
    return doPromiseAPI<ItemReplenishmentDTO[]>(async () => {
      const data = await findItemsFromOpenReplenishment({
        miLoc,
        userId,
        query,
      });
      return data?.slice().reverse();
    });
  };

  const response = useQuery<ItemReplenishmentDTO[], AxiosError>(
    createQueryKey(findReplenishmentItemsQueryKey, params),
    doFindReplenishmentItems,
    { networkMode: 'always' }
  );

  const { data, error, refetch } = response;

  return {
    miLoc,
    replenishmentItems: data,
    error,
    isLoading: doGetIsLoading(response),
    refetch: async () => {
      await refetch();
    },
  };
};

export default useFindReplenishmentItems;
