import React, { useCallback } from 'react';
import { findIndex, slice, toString } from 'lodash';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import type { FilterOption, FilterSection } from 'models/Filters';
import { FilterSectionEnum } from 'models/Filters';
import FilterCard from './FilterCard';
import classes from './FiltersModal.module.scss';

interface FilterSectionWrapperProps {
  setFilters: React.Dispatch<React.SetStateAction<FilterSection[]>>;
  triggerResize?: (d: number) => void;
}

const FilterSectionWrapper = ({
  sectionKey,
  label,
  type,
  selectedValue,
  options,
  setFilters,
  triggerResize,
}: FilterSectionWrapperProps &
  React.ComponentProps<typeof FilterCard> &
  IonicReactProps): JSX.Element => {
  const updateFilter = useCallback(
    (iKey: string, value: unknown) => {
      setFilters((prev) => {
        const filterIndex = findIndex(prev, { key: sectionKey });
        if (filterIndex !== -1) {
          const prevFilter = prev[filterIndex];
          let optionIndex = -1;

          switch (type) {
            case FilterSectionEnum.checkbox:
              optionIndex = findIndex(prevFilter.options, {
                key: iKey,
              });
              if (optionIndex !== -1) {
                const prevOption = prevFilter.options?.[
                  optionIndex
                ] as FilterOption;
                prevFilter.options = [
                  ...slice(prevFilter.options, 0, optionIndex),
                  { ...prevOption, checked: !!value },
                  ...slice(prevFilter.options, optionIndex + 1),
                ];
              }
              break;
            case FilterSectionEnum.radiogroup:
            case FilterSectionEnum.search:
            case FilterSectionEnum.dateRange:
              prevFilter.selectedValue = toString(value);
              break;
            default:
          }

          return [
            ...slice(prev, 0, filterIndex),
            prevFilter,
            ...slice(prev, filterIndex + 1),
          ];
        }
        return prev;
      });
    },
    [sectionKey, type, setFilters]
  );

  return (
    <FilterCard
      className={classes.filterCard}
      sectionKey={sectionKey}
      label={label}
      type={type}
      selectedValue={selectedValue}
      options={options}
      updateFunction={updateFilter}
      triggerResize={triggerResize}
    />
  );
};

export default FilterSectionWrapper;
