// import './utils/wdyr';
import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { IonApp, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { ErrorBoundary } from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import AuthStateProvider from 'providers/AuthStateProvider';
import AxiosProvider from 'providers/AxiosProvider';
import DeviceProvider from 'providers/DeviceProvider';
import NetworkStatusProvider from 'providers/NetworkStatusProvider';
import ReportsProvider from 'providers/ReportsProvider';
import TabResetProvider from 'providers/TabResetProvider';
import ToastProvider from 'providers/ToastProvider';
import VaultProvider from 'providers/VaultProvider';
import store from 'store';
import MainNavigator from 'navigation/MainNavigator/MainNavigator';
import {
  ErrorFallback,
  ErrorFallbackWithRedux,
} from 'components/ErrorFallback/ErrorFallback';
import 'utils/icons';
import 'global.scss';

setupIonicReact({
  rippleEffect: false,
  mode: 'ios',
  swipeBackEnabled: false,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      retry: 3,
      cacheTime: 5 * 60 * 1000,
      staleTime: 3 * 60 * 1000,
    },
  },
});

const App = (): JSX.Element => (
  <ErrorBoundary fallback={<ErrorFallback />}>
    <IonApp>
      <ReduxProvider store={store}>
        <ErrorBoundary fallback={<ErrorFallbackWithRedux />}>
          <QueryClientProvider client={queryClient}>
            <IonReactRouter>
              <ToastProvider>
                <DeviceProvider>
                  <VaultProvider>
                    <ReportsProvider>
                      <TabResetProvider>
                        <AxiosProvider>
                          <NetworkStatusProvider>
                            <AuthStateProvider>
                              <MainNavigator />
                            </AuthStateProvider>
                          </NetworkStatusProvider>
                        </AxiosProvider>
                      </TabResetProvider>
                    </ReportsProvider>
                  </VaultProvider>
                </DeviceProvider>
              </ToastProvider>
            </IonReactRouter>
            {process.env.REACT_APP_QUERY_DEV_TOOLS === 'true' && (
              <ReactQueryDevtools
                initialIsOpen={false}
                position="bottom-right"
              />
            )}
          </QueryClientProvider>
        </ErrorBoundary>
      </ReduxProvider>
    </IonApp>
  </ErrorBoundary>
);

export default App;
