import type { ElementType } from 'react';
import React from 'react';
import classNames from 'classnames';
import { trimStart } from 'lodash';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { findIcon } from 'utils/icons';
import Input from 'components/Input/Input';
import classes from './Searchbar.module.scss';

interface WithAsProp {
  as?: ElementType;
}

interface SearchbarProps extends WithAsProp {
  onSearch?: () => void;
  onClear?: () => void;
  variant?: 'light' | 'dark';
  inputClassName?: string;
  testid: string;
  triggerInputSelect?: number;
  hideSearchIcon?: boolean;
}

const Searchbar = ({
  className,
  placeholder,
  value,
  disabled,
  setValue,
  onFocus,
  onBlur,
  onSearch,
  onClear,
  autocomplete = 'off',
  variant = 'light',
  inputClassName,
  testid,
  triggerInputSelect = 0,
  hideSearchIcon = false,
  inputRowClassName,
  as,
}: SearchbarProps &
  React.ComponentProps<typeof Input> &
  IonicReactProps): JSX.Element => {
  const Component = as || 'form';

  return (
    <Component
      className={classNames(className, classes.wrapper, {
        [classes[variant]]: !value,
      })}
      onSubmit={async (e: React.SyntheticEvent) => {
        e.preventDefault();
        onSearch?.call(null);
        if (Capacitor.isPluginAvailable('Keyboard')) {
          await Keyboard.hide();
        }
      }}
      data-testid={`${testid}-form`}
    >
      <Input
        className={classNames(classes.input, inputClassName)}
        enterkeyhint="search"
        inputmode="search"
        type="search"
        leftButton={
          !hideSearchIcon
            ? {
                icon: findIcon('search'),
                testid: `${testid}-search-button`,
              }
            : undefined
        }
        rightButton={
          value
            ? {
                icon: findIcon('times'),
                onClick: () => {
                  setValue?.call(null, '');
                  onClear?.call(null);
                },
                testid: `${testid}-clear-button`,
              }
            : undefined
        }
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        setValue={(v) => {
          setValue?.(trimStart(v));
        }}
        onFocus={onFocus}
        onBlur={onBlur}
        testid={testid}
        triggerInputSelect={triggerInputSelect}
        inputRowClassName={classNames(
          { [classes.focusedSearch]: value },
          inputRowClassName
        )}
        autocomplete={autocomplete}
      />
    </Component>
  );
};

export default Searchbar;
