/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';
import { isNil, map, toString } from 'lodash';
import type { SegmentChangeEventDetail } from '@ionic/core/components';
import { IonRow, IonSegment, IonSegmentButton } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import type { AccessControlType } from 'hooks/useAccessControls';
import type { TextVariantType } from 'components/Text/Text';
import Text from 'components/Text/Text';
import classes from './SegmentTabs.module.scss';

export interface SegmentTabOptionProps {
  key: string;
  text: string;
  total?: number;
  disabled?: boolean;
  accessControl?: AccessControlType;
}

enum SegmentVariantTypeEnum {
  'transparentTabs',
  'whiteTabs',
  'roundedTabs',
  'miproWhite',
}

type SegmentVariantType = keyof typeof SegmentVariantTypeEnum;

type SegmentTabButtonProps = Pick<
  SegmentTabsProps & React.ComponentProps<typeof IonSegment>,
  | 'selectedClassName'
  | 'variant'
  | 'value'
  | 'testid'
  | 'segmentMode'
  | 'textVariant'
> & { option: SegmentTabOptionProps };

const SegmentTabButton = ({
  variant,
  selectedClassName,
  option: { key, text, total, disabled },
  value,
  testid,
  segmentMode,
  textVariant,
}: SegmentTabButtonProps): JSX.Element | null => {
  const withTotal = !isNil(total);
  const buttonTestid = `${testid}-${key}`;

  let textStyleVariant = textVariant;
  if (!textStyleVariant) {
    textStyleVariant =
      variant === 'whiteTabs' ? 'content-default' : 'title-action-card';
  }

  return (
    <IonSegmentButton
      className={classNames(classes.button, {
        [toString(selectedClassName)]: value === key,
      })}
      mode={segmentMode}
      value={key}
      data-testid={buttonTestid}
      disabled={disabled}
    >
      <IonRow>
        <Text
          className={classNames({
            [classes.selectedTab]: value === key,
          })}
          variant={textStyleVariant}
          text={text}
          testid={`${buttonTestid}-text`}
        />
        {withTotal && (
          <Text
            className={classes.total}
            text={toString(total)}
            testid={`${buttonTestid}-total`}
          />
        )}
      </IonRow>
    </IonSegmentButton>
  );
};

interface SegmentTabsProps {
  selectedClassName?: string;
  variant?: SegmentVariantType;
  options?: SegmentTabOptionProps[];
  setValue?: (v: string) => void;
  testid: string;
  segmentMode?: 'ios' | 'md';
  isScrollable?: boolean;
  textVariant?: TextVariantType;
}

const SegmentTabs = React.forwardRef<
  HTMLIonSegmentElement,
  Omit<SegmentTabsProps, 'segmentMode'> &
    IonicReactProps &
    React.ComponentProps<typeof IonSegment>
>((props, ref): JSX.Element => {
  const {
    className,
    selectedClassName,
    variant = 'transparentTabs',
    options,
    value,
    setValue,
    testid,
    isScrollable = false,
    textVariant,
    disabled,
  } = props;
  const segmentMode = variant === 'roundedTabs' ? 'ios' : 'md';
  return (
    <IonSegment
      ref={ref}
      className={classNames(className, classes.segment, classes[variant])}
      value={value}
      mode={segmentMode}
      onIonChange={(e: CustomEvent<SegmentChangeEventDetail>) =>
        setValue?.(toString(e.detail.value))
      }
      disabled={disabled}
      data-testid={testid}
      scrollable={isScrollable}
    >
      {map(options, (option) => (
        <SegmentTabButton
          key={option.key}
          variant={variant}
          textVariant={textVariant}
          selectedClassName={selectedClassName}
          testid={testid}
          value={value}
          option={option}
        />
      ))}
    </IonSegment>
  );
});

export default SegmentTabs;
