import React, { useEffect, useMemo, useState } from 'react';
import type { Column } from 'react-table';
import classNames from 'classnames';
import {
  toString,
  find,
  map,
  range,
  head,
  toNumber,
  isNaN,
  reduce,
  values,
  get,
} from 'lodash';
import { IonCol, IonImg, IonRow } from '@ionic/react';
import { getCalcExpression } from 'CostSavingsApp/components/CostSavingsForm/CostSavingsInput';
import { getUnixTime } from 'date-fns';
import GenericFastFind from 'DocumentsApp/components/FastFind/GenericFastFind';
import WorkTasksFastFind from 'DocumentsApp/components/FastFind/WorkTasksFastFind';
import VasCodesFastFind from 'DocumentsApp/components/VasCodesFastFind/VasCodesFastFind';
import type { VasCode } from 'DocumentsApp/models/VasCode';
import type { WorkTask } from 'DocumentsApp/models/WorkTask';
import IMask from 'imask';
import type { GroupInput, TemplateImage } from 'models/DocumentTemplate';
import type {
  ArtAsset,
  Manufacturer,
  ReportJson,
  ReportRow,
  ReportValue,
  SignatureDBItem,
} from 'models/InspectionReport';
import { DateFormatEnum, formatDate, parseDate } from 'utils/date';
import CheckBox from 'components/CheckBox/CheckBox';
import DateInput from 'components/DateInput/DateInput';
import ArtFastFind from 'components/Documents/ArtFastFind/ArtFastFind';
import DocumentImage from 'components/Documents/DocumentImage/DocumentImage';
import DocumentTable from 'components/Documents/DocumentRecursiveContainer/DocumentTable';
import DocumentSignature from 'components/Documents/DocumentSignature/DocumentSignature';
import DocumentSubContainer from 'components/Documents/DocumentSubContainer/DocumentSubContainer';
import ManufacturerFastFind from 'components/Documents/ManufacturerFastFind/ManufacturerFastFind';
import Input from 'components/Input/Input';
import ImageModal from 'components/Modals/ImageModal/ImageModal';
import type { RadioOptionsProps } from 'components/RadioButton/RadioButton';
import RadioButton from 'components/RadioButton/RadioButton';
import Text from 'components/Text/Text';
import TextArea from 'components/TextArea/TextArea';
import classes from './DocumentInput.module.scss';

export interface DocumentInputProps {
  groupInput: GroupInput;
  setValue: (
    v: string,
    list?: string[],
    name?: string,
    row?: number,
    isComboBox?: boolean
  ) => void;
  value: string;
  values: string[];
  setRecValue: (
    value: ReportJson | string,
    name: string,
    row: number,
    defaultValue?: string,
    isCalc?: boolean
  ) => void;
  rowData: number;
  groupId: number;
  containerValues?: ReportValue[];
  reportId: number;
  setHasData: (hasData: boolean) => void;
  onFormUpdate: (formData: Record<number, ReportValue>) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  custNo: string;
  miLoc: string;
  inputId?: number;
  inputName?: string;
  needSync?: boolean;
  disabled?: boolean;
  templateImage?: TemplateImage;
  createSignature?: (name: string, image: string, inputId: number) => void;
  triggerInputSelect?: number;
  reportSignature?: SignatureDBItem;
  listType?: string;
  itemListData?: object;
  noPadding?: boolean;
}

const DocumentInput = ({
  groupInput,
  setValue,
  value: getValue,
  values: getValues,
  rowData,
  groupId,
  containerValues,
  reportId,
  setHasData,
  onFormUpdate,
  setRecValue,
  custNo,
  miLoc,
  inputId = groupInput.inputId,
  needSync,
  disabled: pDisabled,
  inputName = '',
  onBlur,
  onFocus,
  templateImage,
  createSignature,
  reportSignature,
  listType,
  itemListData,
  noPadding,
  triggerInputSelect,
  ...imgProps
}: DocumentInputProps &
  React.ComponentProps<typeof DocumentImage>): JSX.Element => {
  const [selectedManufacturer, setSelectedManufacturer] =
    useState<Manufacturer>();

  const [tableContainerValues, setTableContainerValues] =
    useState<ReportValue[]>();

  const [selectedArtAsset, setSelectedArtAsset] = useState<ArtAsset>();
  const [selectedItem, setSelectedItem] = useState<unknown>();

  const inputLabel = groupInput.label && (
    <div className={classes.inputTitle}>
      <Text
        text={`${groupInput.label}${groupInput.required === 'Y' ? '*' : ''}`}
        variant="content-heavy"
      />
      {needSync && (
        <Text
          className={classes.needSyncBadge}
          variant="label-micro"
          text="Pending"
        />
      )}
    </div>
  );

  const { columns: tableColumns, data: tableData } = useMemo(() => {
    let columns: Column<Record<string, unknown>>[] = [];
    const data: Record<string, unknown>[] = [];
    const { tableLayout } = groupInput;
    if (!tableContainerValues) {
      setTableContainerValues(containerValues);
    }
    if (tableLayout) {
      const { headers } = tableLayout;
      columns = map(headers, (header) => ({
        Header: header,
        accessor: header,
      }));
      const inputIndex =
        tableContainerValues?.findIndex((x) => x.inputId === inputId) || 0;
      range(0, tableLayout.rowNumber).forEach((currentRow: number) => {
        let newData = {};
        headers.forEach((header: string, index: number) => {
          let fieldValue: string | string[] | undefined = '';
          if (
            tableLayout.columns[index].type &&
            tableLayout.columns[index].type === 'label'
          ) {
            const tmp: string[] =
              (tableLayout.columns[index].values as string[]) || [];
            fieldValue = tmp[currentRow] || '';
          } else if (
            tableContainerValues?.[inputIndex]?.inputJson?.rows[0].row.find(
              (x) => inputName === x.name
            )?.rows[currentRow]
          ) {
            const reportValue = find(
              tableContainerValues?.[inputIndex]?.inputJson?.rows[0].row.find(
                (x) => inputName === x.name
              )?.rows[currentRow].row,
              (rowTmp: ReportJson) => {
                return tableLayout.columns[index].name === rowTmp.name;
              }
            );
            if (reportValue) {
              fieldValue =
                tableLayout.columns[index].inputTypeId === 1
                  ? reportValue.values
                  : toString(reportValue.value);
            }
          }
          newData = {
            ...newData,
            ...{ [header]: fieldValue },
          };
        });
        data.push(newData);
      });
    }
    return { columns, data };
  }, [groupInput, tableContainerValues, containerValues, inputId, inputName]);

  const setTableFormValue = (
    value: string,
    name: string,
    row: number,
    isComboBox: boolean
  ) => {
    const { tableLayout } = groupInput;
    let isUpdated = false;
    let rowToUpdate: ReportRow = { row: [] };
    if (tableContainerValues && tableLayout) {
      if (tableContainerValues.length === 0) {
        tableContainerValues.push({
          inputJson: { rows: [{ row: [{ name: inputName, rows: [] }] }] },
          reportId,
          groupId,
          inputId,
          inputValue: '',
        });
      }
      const inputIndex = tableContainerValues.findIndex(
        (x) => x.inputId === inputId
      );
      if (
        tableContainerValues[inputIndex].inputJson &&
        inputIndex !== undefined
      ) {
        // verify newContainerValues has all the rows.
        let rowIndex =
          tableContainerValues[inputIndex].inputJson?.rows[0].row.find(
            (x) => inputName === x.name
          )?.rows.length || 0;
        while (rowIndex < tableLayout.rowNumber && rowIndex >= 0) {
          const blankRow: ReportRow = { row: [] };
          tableLayout.columns.forEach((col) => {
            if (col.type !== 'label') {
              const blankReportJson: ReportJson = {
                name: col.name,
                rows: [],
                value: '',
                values: [],
              };
              blankRow.row.push(blankReportJson);
            }
          });
          tableContainerValues[inputIndex].inputJson?.rows[0].row
            .find((x) => inputName === x.name)
            ?.rows.push(blankRow);
          rowIndex =
            tableContainerValues[inputIndex].inputJson?.rows[0].row.find(
              (x) => inputName === x.name
            )?.rows.length || 0;
        }
        rowToUpdate = tableContainerValues[
          inputIndex
        ].inputJson?.rows[0].row.find((x) => inputName === x.name)?.rows[
          row
        ] || {
          row: [],
        };
      }

      const reportJsonList: ReportJson = rowToUpdate.row.find((rowTmp) => {
        return name === rowTmp.name;
      }) || { name, rows: [] };
      if (isComboBox) {
        isUpdated = reportJsonList.values?.[0] !== value;
        reportJsonList.values = [value];
      } else {
        isUpdated = reportJsonList.value !== value;
        reportJsonList.value = value;
      }
    }
    if (tableContainerValues && isUpdated) {
      setHasData(true);
      onFormUpdate(tableContainerValues);
      setTableContainerValues(tableContainerValues);
    }
  };
  const [isImageOpen, setIsImageOpen] = useState<boolean>(false);

  const formData = reduce(
    values(itemListData),
    (prev, attribute) => ({
      ...prev,
      [get(attribute, 'attributeName')]: toString(get(attribute, 'value')),
    }),
    {}
  );

  useEffect(() => {
    if (groupInput.additionalInformation === 'calc') {
      try {
        const calcValue = toNumber(
          // eslint-disable-next-line no-eval
          eval(getCalcExpression(formData, groupInput.defaultValue, true))
        );
        if (!isNaN(calcValue)) {
          setValue(toString(calcValue));
        }
      } catch (e) {
        // do nothing with error
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(formData), groupInput.additionalInformation]);

  const disabled = pDisabled || groupInput.additionalInformation === 'calc';

  let value = getValue;

  useEffect(() => {
    if (
      !value &&
      groupInput.defaultValue &&
      groupInput.additionalInformation !== 'calc' &&
      groupInput.type !== 'image'
    ) {
      setValue(groupInput.defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupInput.defaultValue, groupInput.additionalInformation, value]);

  // TODO: send mask to input
  const inputMask = toString(groupInput.inputMask);
  const masked = IMask.createMask({ mask: new RegExp(inputMask) });

  if (groupInput.inputMask) {
    masked.resolve(toString(value));
    value = masked.value;
  }

  return (
    <div className={classes.inputRowWrap}>
      {/* if input type is 9 (display text), the label
      should be displayed across the full row. */}
      {groupInput.inputTypeId === 9 && (
        <IonRow className={classes.documentRow}>
          <IonCol
            size="12"
            className={classNames(
              {
                [classes.documentColHeader]: groupInput.type === 'header',
              },
              classes.documentCol
            )}
          >
            {inputLabel}
          </IonCol>
        </IonRow>
      )}
      {/* if input type is 10 (text area) and there is no label,
      the value should be displayed across the full row. */}
      {groupInput.inputTypeId === 10 && !groupInput.label && (
        <IonRow className={classes.documentRow}>
          <IonCol
            size="12"
            className={classNames(
              classes.containerValue,
              classes.textAreaValue
            )}
          >
            <TextArea
              className={classes.textArea}
              value={value}
              disabled={disabled}
              testid="containerTextArea"
              setValue={setValue}
            />
          </IonCol>
        </IonRow>
      )}
      {(groupInput.inputTypeId !== 10 || groupInput.label) &&
        groupInput.inputTypeId !== 9 && (
          <IonRow className={classes.documentRow}>
            {groupInput.inputTypeId !== 3 &&
              groupInput.inputTypeId !== 13 &&
              groupInput.type !== 'image' && (
                <IonCol size="12" className={classes.documentCol}>
                  {inputLabel}
                </IonCol>
              )}
            <IonCol
              size="12"
              className={classNames(
                classes.containerValue,
                classes.documentCol,
                {
                  [classes.textAreaValue]: groupInput.inputTypeId === 10,
                }
              )}
            >
              {groupInput.inputTypeId === 4 && ( // text
                <Input
                  value={value}
                  disabled={disabled}
                  testid="containerInput"
                  setValue={setValue}
                  name={inputName}
                  triggerInputSelect={triggerInputSelect}
                  onBlur={onBlur}
                  onFocus={onFocus}
                  onClick={(e) => e.stopPropagation()}
                />
              )}
              {groupInput.inputTypeId === 10 && ( // textarea
                <TextArea
                  className={classes.textArea}
                  value={value}
                  disabled={disabled}
                  testid="containerTextArea"
                  setValue={setValue}
                />
              )}
              {groupInput.inputTypeId === 13 && (
                <DocumentSubContainer
                  key={`${rowData}-${groupInput.name}-`}
                  custNo={custNo}
                  miLoc={miLoc}
                  inputId={inputId}
                  disabled={disabled}
                  setRecValue={setRecValue}
                  fields={groupInput.fields || []}
                  title={groupInput.label}
                  groupId={groupId}
                  containerValues={containerValues}
                  reportId={reportId}
                  setHasData={setHasData}
                  onFormUpdate={onFormUpdate}
                  mainRow={rowData}
                  mainName={groupInput.name}
                  multiple={groupInput.multiple}
                  type={groupInput.type}
                  needSync={needSync}
                  listType={listType}
                  itemListData={itemListData}
                  noPadding={noPadding || !groupInput.multiple}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...imgProps}
                />
              )}
              {groupInput.inputTypeId === 5 && ( // Numeric
                <Input
                  type="number"
                  value={value}
                  disabled={disabled}
                  testid="containerInput"
                  setValue={setValue}
                />
              )}
              {/* These input types have options */}
              {groupInput.inputTypeId === 2 && ( // radio
                <RadioButton
                  value={value}
                  options={
                    groupInput.inputOptions as unknown as RadioOptionsProps[]
                  }
                  disabled={disabled}
                  valueKey="displayValue"
                  testid="document-input-radio-group"
                  onChange={(val) => setValue(toString(val))}
                  isVerticalAlign
                />
              )}
              {groupInput.inputTypeId === 1 && ( // multiple select
                <GenericFastFind
                  multiple={groupInput.multiple}
                  value={head(getValues) || value}
                  values={getValues}
                  options={map(groupInput.inputOptions, (opt) => ({
                    id: opt.displayValue,
                    text: opt.displayValue,
                  }))}
                  modalTitle={groupInput.label || groupInput.name}
                  label=""
                  setValue={(e) => setTimeout(() => setValue(e), 100)}
                  setValues={(e) => setValue('', e)}
                  disabled={disabled}
                  testid={`input-${groupInput.label}`}
                />
              )}
              {groupInput.inputTypeId === 3 && ( // checkbox
                <IonRow>
                  <CheckBox
                    ariaLabel={groupInput.label}
                    disabled={disabled}
                    name={groupInput.label}
                    checked={value === 'true'}
                    testid="checkbox-test"
                    onChange={(checked) => {
                      setValue(checked.toString());
                    }}
                  >
                    {inputLabel}
                  </CheckBox>
                </IonRow>
              )}
              {groupInput.inputTypeId === 7 && ( // manufacturer fast find
                <ManufacturerFastFind
                  input={value}
                  setInput={setValue}
                  disabled={disabled}
                  selectedManufacturer={selectedManufacturer}
                  setSelectedManufacturer={(manufacturer?: Manufacturer) => {
                    setSelectedManufacturer(manufacturer);
                    if (manufacturer) {
                      setValue(manufacturer.mfrName);
                    }
                  }}
                />
              )}
              {groupInput.inputTypeId === 11 && ( // date picker
                <DateInput
                  name={inputName}
                  value={getUnixTime(parseDate(value))}
                  testid="date-picker-input"
                  disabled={disabled}
                  setValue={(e: number) => {
                    setValue(formatDate(e, DateFormatEnum.standardDate));
                  }}
                />
              )}
              {groupInput.inputTypeId === 12 && ( // art fast find
                <ArtFastFind
                  input={value}
                  setInput={setValue}
                  custNo={custNo}
                  miLoc={miLoc}
                  disabled={disabled}
                  selectedArtAsset={selectedArtAsset}
                  setSelectedArtAsset={(artAsset?: ArtAsset) => {
                    setSelectedArtAsset(artAsset);
                    if (artAsset) {
                      setValue(artAsset.assetNo.toString());
                    }
                  }}
                />
              )}
              {groupInput.inputTypeId === 14 && (
                <DocumentTable
                  columns={tableColumns}
                  data={tableData}
                  columnLayout={groupInput.tableLayout?.columns}
                  disabled={disabled}
                  inputId={inputId}
                  groupId={groupId}
                  reportId={reportId}
                  needSync={needSync}
                  custNo={custNo}
                  miLoc={miLoc}
                  tableName={groupInput.name}
                  setValue={setTableFormValue}
                  onFormUpdate={onFormUpdate}
                />
              )}
              {groupInput.type === 'image' && (
                <IonImg
                  className={classes.staticImage}
                  src={templateImage?.src}
                  onClick={() => setIsImageOpen(true)}
                />
              )}
              {groupInput.inputTypeId === 15 && ( // signature
                <DocumentSignature
                  inputId={inputId}
                  createSignature={createSignature || (() => {})}
                  disabled={disabled}
                  reportSignature={reportSignature}
                />
              )}
              {groupInput.inputTypeId === 16 && ( // fastfind
                <>
                  {groupInput.additionalInformation === 'vasCodes' && (
                    <VasCodesFastFind
                      value={value}
                      setValue={setValue}
                      disabled={disabled}
                      vasCode={selectedItem as VasCode}
                      setVasCode={(item) => {
                        setSelectedItem(item);
                        if (item) {
                          setValue(item.codeValue);
                        }
                      }}
                      modalTitle={groupInput.label}
                      testid={`input-${groupInput.label}`}
                    />
                  )}
                  {groupInput.additionalInformation === 'workTasks' && (
                    <WorkTasksFastFind
                      value={value}
                      setValue={setValue}
                      disabled={disabled}
                      workTask={selectedItem as WorkTask}
                      setWorkTask={(item) => {
                        setSelectedItem(item);
                        if (item) {
                          setValue(item.codeValue);
                        }
                      }}
                      modalTitle={groupInput.label}
                      testid={`input-${groupInput.label}`}
                    />
                  )}
                </>
              )}
              {groupInput.inputTypeId === 17 && ( // images
                <DocumentImage
                  reportId={reportId}
                  groupId={groupId}
                  objectId={toNumber(get(itemListData, 'itemId.value')) || 0}
                  disabled={disabled}
                  noPadding
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...imgProps}
                />
              )}
            </IonCol>
            <ImageModal
              isOpen={isImageOpen}
              setIsOpen={() => setIsImageOpen(!isImageOpen)}
              title=""
              url={templateImage?.src}
              testid="testid"
            />
          </IonRow>
        )}
    </div>
  );
};

export default DocumentInput;
