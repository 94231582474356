import { useSelector } from 'react-redux';
import i18next from 'i18next';
import { AccessControlType } from 'hooks/useAccessControls';
import type { RootState } from 'store/reducers';

interface UseGetCamUserDetailsResponse {
  accessControl: AccessControlType;
  isCamUser?: boolean;
  subTitle: string;
}

const useGetCamUserDetails = (): UseGetCamUserDetailsResponse => {
  const { isCamUser } = useSelector((state: RootState) => state.user);
  const accessControl = isCamUser
    ? AccessControlType.camUserReports
    : AccessControlType.viewReports;

  return {
    accessControl,
    isCamUser,
    subTitle: i18next.t('reports:myCorporateAccounts'),
  };
};

export default useGetCamUserDetails;
