import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { RefresherEventDetail } from '@ionic/core/components';
import { IonRefresher, IonRefresherContent, IonRow } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import useHapticFeedback from 'hooks/useHapticFeedback';
import { formatLastUpdatedDate } from 'utils/date';
import { findIcon } from 'utils/icons';
import Text from 'components/Text/Text';
import classes from './Refresher.module.scss';

interface RefresherProps {
  lastUpdatedAt?: number;
  onRefresh?: () => Promise<unknown | void> | void;
  testid: string;
  variant?: string;
}

const Refresher = ({
  slot,
  className,
  lastUpdatedAt,
  onRefresh,
  testid,
  hidden,
  disabled,
  variant = 'content-default',
}: RefresherProps &
  React.ComponentProps<typeof IonRefresher> &
  IonicReactProps): JSX.Element => {
  const { hapticsImpactLight } = useHapticFeedback();
  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    try {
      await onRefresh?.();
    } catch (e) {
      // TODO: handle refresher error?
    }
    await hapticsImpactLight();
    event.detail.complete();
  };
  const { t } = useTranslation();
  return (
    <>
      <div
        className={
          variant === 'new-home'
            ? classNames(className)
            : classNames(className, classes.RefreshBanner)
        }
        hidden={hidden}
      >
        <IonRow className={classNames(classes.wrapper)}>
          <FontAwesomeIcon icon={findIcon('arrow-down')} />
          <Text
            variant="label-micro"
            text={
              variant === 'new-home'
                ? t('pullDownRefresh')
                : t('pullDownRefreshDate', {
                    updateDate: formatLastUpdatedDate(lastUpdatedAt),
                  })
            }
            testid={`reports-refresher-${testid}`}
          />
        </IonRow>
      </div>
      <IonRefresher
        className={classes.Refresher}
        slot={slot}
        onIonRefresh={doRefresh}
        data-testid={`Refresher-${testid}`}
        disabled={disabled}
      >
        <IonRefresherContent />
      </IonRefresher>
    </>
  );
};

export default Refresher;
