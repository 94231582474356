import { useMemo } from 'react';
import type { AxiosError } from 'axios';
import type { Dictionary } from 'lodash';
import { orderBy, size, toString } from 'lodash';
import { useInfiniteQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import useGetCustomer from 'api/customer/useGetCustomer';
import { doConcatDataPages, doPromiseAPI, useKeyUserId } from 'api/helpers';
import type { Customer } from 'models/Customer';
import type { Note } from 'models/Notebook';
import type { InfiniteQueryFlags } from 'models/Search';
import { pageSize } from 'utils/constants';

export const findNotesQueryKey = 'customer-notes';

interface UseFindNotesProps {
  miLoc: string;
  id: string;
  showAlertNotes?: boolean;
  showCustomerNotes?: boolean;
  sortDir?: string;
  sortCol?: string;
  limit?: number;
}

interface UseFindNotesResponse {
  notes?: Note[];
  customerData?: Customer;
}

const useFindNotes = ({
  miLoc,
  id,
  showAlertNotes = true,
  showCustomerNotes = true,
  sortDir = 'DESC',
  sortCol = 'updatedTimestamp',
  limit = pageSize(),
}: UseFindNotesProps): InfiniteQueryFlags & UseFindNotesResponse => {
  const { axios } = useAxios();
  const { notesAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const params: Dictionary<string> = {
    showAlertNotes: toString(showAlertNotes),
    showCustomerNotes: toString(showCustomerNotes),
    showAttachments: toString(true),
    sortDir,
    sortCol,
    limit: toString(limit),
  };

  if (showAlertNotes) {
    params.alertAttachmentEntity = 'mprovisi';
    params.alertAttachmentMiLoc = 'EXEC';
  }

  if (showCustomerNotes) {
    params.nbType = 'CM';
  }

  const { data: customerData } = useGetCustomer({
    searchType: 'customer',
    miLoc,
    id,
  });

  const doFindNotes = ({ pageParam = 1 }) => {
    return doPromiseAPI<Note[]>(async (cancelToken) => {
      const paramsGET = new URLSearchParams({
        ...params,
        page: toString(pageParam),
      });
      const { data } = await axios.get<Note[]>(
        notesAPI(miLoc, id, toString(paramsGET)),
        { cancelToken }
      );
      return data;
    });
  };

  const response = useInfiniteQuery<Note[], AxiosError>(
    createQueryKey(findNotesQueryKey, { miLoc, id, ...params }),
    doFindNotes,
    {
      getNextPageParam: (lastPage, pages) =>
        size(lastPage) < limit ? false : size(pages) + 1,
    }
  );

  const {
    data,
    error,
    status,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    refetch,
    fetchNextPage,
  } = response;

  const notes = useMemo(
    // DOC: sort added for optimistic UI updates in list
    () => orderBy(doConcatDataPages<Note>(data), 'updatedTimestamp', 'desc'),
    [data]
  );

  const hasItems = size(notes) > 0;
  const hasError = status === 'error';
  const isEmptyResponse = status === 'success' && !hasNextPage && !hasItems;
  const noMoreData = status === 'success' && !hasNextPage && hasItems;
  const showLoader = status === 'loading' || isFetchingNextPage;
  const enableInfiniteScroll = !(!hasNextPage || isFetchingNextPage);

  return {
    fetchNextPage: async () => {
      await fetchNextPage();
    },
    notes,
    customerData,
    error,
    hasError,
    isFetching,
    showLoader,
    isEmptyResponse,
    noMoreData,
    enableInfiniteScroll,
    refetch: async () => {
      await refetch();
    },
  };
};

export default useFindNotes;
