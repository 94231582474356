import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { filter, map, size } from 'lodash';
import { IonContent, IonRow } from '@ionic/react';
import { namespaces } from 'i18n/i18n.constants';
import { manageFavoritesURL } from 'navigation';
import { SearchResultTabTypeEnum } from 'models/Search';
import type { SearchCustomerItem } from 'models/Search';
import type { RootState } from 'store/reducers';
import { clearRecentlyViewed } from 'store/user';
import { findIcon } from 'utils/icons';
import { concatRoutes } from 'utils/navigations';
import { getSearchItemKey, handleSearchNavigation } from 'utils/search';
import FavoritesList from 'pages/Favorites/FavoritesList/FavoritesList';
import Button from 'components/Button/Button';
import ConfirmDialog from 'components/Modals/ConfirmDialog/ConfirmDialog';
import RecentlyViewedCard from 'components/Search/RecentlyViewedCard/RecentlyViewedCard';
import Text from 'components/Text/Text';
import TitleLine from 'components/TitleLine/TitleLine';
import classes from './SearchAll.module.scss';

interface SearchAllProps {
  searchType: SearchResultTabTypeEnum;
  miLoc: string;
}

interface SearchIdParam {
  sequenceNo?: string;
  customerId?: string;
  supplierId?: string;
}

const SearchAll = ({ searchType, miLoc }: SearchAllProps): JSX.Element => {
  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  const history = useHistory();
  const { recentlyViewed } = useSelector((state: RootState) => state.user);

  const [clearIsOpen, setClearIsOpen] = useState(false);
  const [hasFavorites, setHasFavorites] = useState(false);

  const filteredRecentlyViewed = filter(recentlyViewed, ({ type }) => {
    switch (searchType) {
      case SearchResultTabTypeEnum.customers:
        return type === 'customer';
      case SearchResultTabTypeEnum.suppliers:
        return type === 'supplier';
      default:
        return true;
    }
  });

  const withRecentlyViewed = size(filteredRecentlyViewed) > 0;

  const { t } = useTranslation(namespaces.search);

  return (
    <IonContent className={classes.content} data-testid="search-tab-all">
      {withRecentlyViewed && (
        <>
          <IonRow className={classes.titleRow} data-testid="recently-viewed">
            <Text
              className={classes.title}
              variant="underlined-title-section"
              text={t('recentlyViewed')}
            />
            <Button
              className={classes.clearButton}
              variant="link"
              text={t('clearRecent')}
              onClick={() => setClearIsOpen(true)}
              testid="clear-recently-viewed-button"
            />
            <ConfirmDialog
              isOpen={clearIsOpen}
              setIsOpen={setClearIsOpen}
              title={t('clearRecentTitle')}
              text={t('clearRecentMessage')}
              primaryText={t('clearRecentNo')}
              secondaryText={t('clearRecentYes')}
              onSecondaryClick={() => {
                setTimeout(() => dispatch(clearRecentlyViewed()), 0);
              }}
              testid="clear-recently-viewed-modal"
            />
          </IonRow>
          <TitleLine />
          <div
            className={classes.recentlyViewedWrapper}
            data-testid="recently-viewed-wrapper"
          >
            {map(filteredRecentlyViewed, (item) => {
              const { type, id, text, customerPick12, isCorpAccountorInfo } =
                item;
              const { miLoc: iMiLoc } = item as SearchCustomerItem;
              const key = getSearchItemKey({
                type,
                miLoc: iMiLoc || miLoc,
                customerId: id,
                supplierId: id,
              });
              let searchIdParam: SearchIdParam = { sequenceNo: id };
              if (type === 'customer') {
                searchIdParam = { customerId: id };
              } else if (type === 'supplier') {
                searchIdParam = { supplierId: id };
              }

              return (
                <RecentlyViewedCard
                  searchType={type}
                  className={classes.recentlyViewedCard}
                  key={key}
                  text={text}
                  customerPick12={customerPick12}
                  isCorpAccountorInfo={isCorpAccountorInfo}
                  description={
                    type === 'customer'
                      ? t('customerRecentlyViewed', { miLoc: iMiLoc, id })
                      : t('supplierRecentlyViewed', { id })
                  }
                  onClick={() =>
                    history.push({
                      pathname: concatRoutes(
                        url,
                        handleSearchNavigation({
                          type,
                          miLoc: iMiLoc || miLoc,
                          ...searchIdParam,
                        })
                      ),
                      state: {
                        headerTitle: text,
                      },
                    })
                  }
                  testid={`recently-viewed-${key}`}
                />
              );
            })}
          </div>
        </>
      )}
      <IonRow className={classes.titleRow} data-testid="favorites">
        <Text
          className={classes.title}
          variant="underlined-title-section"
          text={t('common:favorites')}
        />
        {hasFavorites && (
          <Button
            className={classes.mFButton}
            variant="link"
            text={t('favorites:manageFavorites')}
            rightIcon={findIcon('chevron-right')}
            href={concatRoutes(url, manageFavoritesURL())}
            testid="favorites-button"
          />
        )}
      </IonRow>
      <TitleLine />
      <FavoritesList
        searchType={searchType}
        setHasFavorites={setHasFavorites}
        testid="search-favorites-list"
        favMiLoc={miLoc}
      />
    </IonContent>
  );
};

export default SearchAll;
