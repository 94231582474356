import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import classNames from 'classnames';
import { isNil, toString } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonRow } from '@ionic/react';
import { FormikInput } from 'common/components/Forms/Input/Input';
import { Form, FormikProvider, useFormik } from 'formik';
import useGetAttachment from 'api/attachments/useGetAttachment';
import useGetDownloadAttachment from 'api/attachments/useGetDownloadAttachment';
import type { UpdateAttachmentBody } from 'api/attachments/useUpdateAttachmentDetails';
import type { MiProFile } from 'models/Attachment';
import {
  getAttachmentProps,
  getFileExtension,
  getFileName,
  iconFinder,
  isImageFile,
} from 'utils/filesUpload';
import Button from 'components/Button/Button';
import CheckBox from 'components/CheckBox/CheckBox';
import Loader from 'components/Loader/Loader';
import type Modal from 'components/Modal/Modal';
import SheetModal from 'components/Modals/SheetModal/SheetModal';
import Text from 'components/Text/Text';
import classes from './FileFormModal.module.scss';

interface AttachmentFileForm {
  caption?: string;
  internalOnly?: string;
}

interface FileFormModalProps {
  domain: string;
  file: MiProFile;
  testid: string;
  isOpen: boolean;
  editMode: boolean;
  setIsOpen: (b: boolean) => void;
  onAddClick?: ({
    miOnly,
    description,
  }: {
    miOnly: string;
    description: string;
  }) => void;
  onUpdate?: (body: UpdateAttachmentBody) => void;
}
const FileFormModal = ({
  domain,
  isOpen,
  editMode,
  setIsOpen,
  testid,
  file,
  onAddClick,
  onUpdate,
}: FileFormModalProps & React.ComponentProps<typeof Modal>): JSX.Element => {
  const { t } = useTranslation();
  const { startDownload } = useGetDownloadAttachment({
    domain,
  });
  const [isLoading, setIsLoading] = useState(true);

  let fileType = file.type;
  fileType ||= getFileExtension({ file });
  const isImage = isImageFile(fileType);

  const { data } = useGetAttachment({
    ...getAttachmentProps({ file, domain }),
    enabled: isImage,
  });

  let url = data;
  url ||= file?.fileURL;
  let seqNo = toString(file?.SEQ_NO);
  seqNo ||= toString(file?.seqNo);
  const fileName = getFileName(file);
  let getTitle = fileName;
  getTitle ||= 'file.png';

  let miOnly = toString(file?.MI_ONLY);
  miOnly ||= toString(file?.miOnly);

  let caption = toString(file?.description);
  caption ||= toString(file?.DESCRIPTION);

  const onPrimaryClick = (formValues: AttachmentFileForm) => {
    return !file?.FILE_NAME
      ? onAddClick?.({
          miOnly: toString(formValues?.internalOnly),
          description: toString(formValues?.caption),
        })
      : onUpdate?.({
          fileName,
          seqNo,
          miOnly: toString(formValues?.internalOnly),
          description: toString(formValues?.caption),
        });
  };

  const formik = useFormik<AttachmentFileForm>({
    initialValues: {
      internalOnly: !file?.FILE_NAME ? 'Y' : miOnly,
      caption,
    },
    enableReinitialize: true,
    onSubmit: (formValues) => {
      onPrimaryClick(formValues);
      setIsOpen?.(false);
      formik.setSubmitting(false);
    },
  });
  const {
    isSubmitting,
    values,
    setFieldValue,
    handleSubmit,
    resetForm,
    setValues,
  } = formik;

  useEffect(() => {
    if (isOpen) {
      void setValues({
        internalOnly: !file?.FILE_NAME ? 'Y' : miOnly,
        caption,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, setValues]);

  return (
    <SheetModal
      className={classes.modal}
      headerClassName={classes.modalHeaderToolbar}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      withCloseButton
      withRightCloseButton
      title={t('common:attachmentUpload')}
      testid={`FileModal-${testid}`}
      initialBreakpoint={1}
      contentClass={classes.formContent}
      footerButtons={
        editMode
          ? [
              {
                variant: 'secondary',
                text: t('common:cancel'),
                onClick: () => {
                  resetForm({
                    values: {
                      internalOnly: miOnly,
                      caption,
                    },
                  });
                  setIsOpen?.(false);
                },
                disabled: isSubmitting,
                testid: 'cancel-button',
              },
              {
                variant: 'mipro-action',
                text: !file?.FILE_NAME ? t('common:add') : t('common:save'),
                onClick: () => {
                  handleSubmit();
                },
                disabled: isSubmitting,
                testid: 'add-button',
              },
            ]
          : []
      }
    >
      {isImage && (
        <Loader
          className={classes.loader}
          text="Loading file"
          isOpen={isLoading}
        />
      )}
      {isImage && (
        <IonRow
          className={classNames(classes.pinchRow, classes.pinchRowPadding)}
        >
          <FontAwesomeIcon
            className={classes.pinchIcon}
            icon={['far', 'arrows-maximize']}
          />
          <Text text={t('common:pinchMsg')} variant="mipro-body-copy" />
        </IonRow>
      )}
      {!isImage && (
        <IonRow className={classes.fileRow}>
          <Button
            className={classNames(classes.pinchRow, classes.downloadBtn)}
            testid="file-title-button"
            onClick={() => startDownload(file)}
          >
            <div className={classes.downloadCont}>
              {file.FILE_NAME && (
                <Text
                  className={classes.previewText}
                  text={t('common:pressToPreview')}
                />
              )}
              <div className={classes.fileCont}>
                <IonRow className={classes.fileIconCont}>
                  <FontAwesomeIcon
                    className={classes.fileImg}
                    icon={iconFinder(fileType)}
                  />
                </IonRow>
              </div>
            </div>
          </Button>
          <Text
            className={classes.fileName}
            variant="mipro-body-copy-bold"
            text={toString(getTitle)}
          />
        </IonRow>
      )}

      {!isNil(url) && isImage && (
        <div className={classes.imageContainer}>
          <TransformWrapper centerOnInit wheel={{ step: 0.07 }}>
            <TransformComponent wrapperClass={classes.zoomWrapper}>
              <img
                src={encodeURI(url)}
                onLoad={() => setIsLoading(false)}
                alt={toString(getTitle)}
              />
            </TransformComponent>
          </TransformWrapper>
        </div>
      )}

      <FormikProvider value={formik}>
        <Form key={fileName}>
          <FormikInput
            className={classes.captionInput}
            label={t('common:caption')}
            name="caption"
            readonly={!editMode}
            value={values.caption}
            maxlength={100}
            setValue={(v) => setFieldValue('caption', v)}
            testid="caption-input"
          />
          <CheckBox
            className={classes.checkbox}
            isReadOnly={!editMode}
            disabled={!editMode}
            label={t('common:internalOnly')}
            name="internalOnly"
            checked={values.internalOnly === 'Y'}
            onChange={(v) => setFieldValue('internalOnly', v ? 'Y' : '')}
            testid="internal-only-checkbox"
          />
        </Form>
      </FormikProvider>
    </SheetModal>
  );
};

export default FileFormModal;
