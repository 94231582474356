import { useSelector } from 'react-redux';
import type { AxiosError } from 'axios';
import { get, isEmpty } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import type { QueryFunctionContext } from '@tanstack/react-query';
import useAPIUrl from 'api';
import type {
  QueryFlags,
  QueryParamsType,
} from 'common/api/utils/useGetQueryFlags';
import useGetQueryFlags from 'common/api/utils/useGetQueryFlags';
import { useAxios } from 'providers/AxiosProvider';
import { doGetIsLoading, useKeyUserId, useMiLocOrTeamId } from 'api/helpers';
import useGetUserConfig from 'api/user/useGetUserConfig';
import type { DateSegmentType, EndOfMonthReport } from 'models/Reports';
import type { RootState } from 'store/reducers';
import useGetLocationCurrency from 'utils/currency';
import { DateFormatEnum, formatDate } from 'utils/date';

export const getEomReportQueryKey = 'eom-report';

interface UseGetEomProps {
  miLoc?: string;
  requestType?: DateSegmentType;
  enabled?: boolean;
  sendVirtualTeamId?: boolean;
  busPeriod?: number;
}

export interface UseGetEomReportResponse {
  eomData?: EndOfMonthReport;
}

const useGetEomReport = ({
  miLoc: itemMiLoc,
  requestType = 'MTD',
  enabled = true,
  sendVirtualTeamId,
  busPeriod,
}: UseGetEomProps): UseGetEomReportResponse & QueryFlags<EndOfMonthReport> => {
  const { axios } = useAxios();
  const { getEndOfMonthAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const { createParams, getURLParams } = useMiLocOrTeamId({
    miLoc: itemMiLoc,
    sendVirtualTeamId,
  });

  const { userInfo, isCamUser } = useSelector((state: RootState) => state.user);
  const userId = get(userInfo, 'userid', '');

  const { data: currencyData } = useGetUserConfig({
    configType: 'currency',
    enabled: enabled && !isEmpty(userId),
  });

  const { secondaryCurrencyType } = useGetLocationCurrency();

  const currencyType = currencyData?.currency || secondaryCurrencyType;

  const params: QueryParamsType = {
    ...createParams(),
    requestType,
    usdRequested: currencyType === 'USD',
    busPeriod: formatDate(busPeriod, DateFormatEnum.reportsDateAPI),
  };

  if (isCamUser) {
    params.showCAMView = true;
  }

  const doGetEOMReport = async ({ signal }: QueryFunctionContext) => {
    const { data } = await axios.get<EndOfMonthReport>(
      getEndOfMonthAPI(getURLParams(params)),
      { signal }
    );
    return data;
  };

  const response = useQuery<EndOfMonthReport, AxiosError>(
    createQueryKey(getEomReportQueryKey, { ...params }),
    doGetEOMReport,
    {
      enabled: enabled && !isEmpty(userId),
      // TODO: refactor and extract this retry logic to be resuable across the application
      retry: (failureCount, err) =>
        err.response?.status === 403 ? false : !(failureCount < 3),
    }
  );
  const queryFlags = useGetQueryFlags({ ...response, enabled });
  return {
    eomData: response?.data,
    ...queryFlags,
    isLoading: doGetIsLoading(response),
  };
};

export default useGetEomReport;
