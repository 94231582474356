import React from 'react';
import classnames from 'classnames';
import { isNil, kebabCase, map, toString, toUpper } from 'lodash';
import { IonRow } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import type { ActivityFiltersButton } from 'models/ActivityModels';
import Button from 'components/Button/Button';
import Text from 'components/Text/Text';
import classes from './ActivityFilters.module.scss';

// TODO: this should be done in the API filter activities
const changeDonetoAchieved = (name: string): string =>
  toUpper(name) === 'DONE' ? 'Archived' : name;

interface ActivityFiltersProps {
  filterItem: ActivityFiltersButton | undefined;
  setFilterItem?: (b: ActivityFiltersButton | undefined) => void;
  buttons?: ActivityFiltersButton[];
  testid: string;
}

const ActivityFilters = ({
  filterItem,
  setFilterItem,
  className,
  buttons,
  testid,
}: ActivityFiltersProps & IonicReactProps): JSX.Element => (
  <div
    className={classnames(classes.activityFilters, className)}
    data-testid={`activity-filters-${testid}`}
  >
    {!isNil(filterItem) && (
      <IonRow className={classes.selectedFilterWrapper}>
        <Button
          className={classes.filter}
          variant={['action', 'filter']}
          text={changeDonetoAchieved(filterItem.name)}
          testid="active-filter-button"
        >
          {filterItem.showCounter && (
            <Text
              className={classes.count}
              text={toString(filterItem.count)}
              testid="active-filter-count"
            />
          )}
        </Button>
        <Button
          variant="link"
          text="Clear Filter"
          onClick={() => setFilterItem?.(undefined)}
          testid="clear-filter-button"
        />
      </IonRow>
    )}
    {isNil(filterItem) && (
      <>
        {map(buttons, (item, index) => (
          <Button
            key={index}
            variant={['secondary', 'filter']}
            text={changeDonetoAchieved(item.name)}
            className={classes.filterBtn}
            disabled={item.disabled}
            onClick={() => {
              setFilterItem?.(item);
            }}
            testid={`${kebabCase(item.name)}-filter-button`}
          >
            {item.showCounter && (
              <Text
                className={classes.count}
                text={toString(item.count)}
                testid={`${kebabCase(item.name)}-filter-count`}
              />
            )}
          </Button>
        ))}
      </>
    )}
  </div>
);

export default ActivityFilters;
