import type { AxiosError } from 'axios';
import {
  toString,
  toNumber,
  map,
  find,
  concat,
  filter,
  includes,
  get,
} from 'lodash';
import { useIonViewDidEnter } from '@ionic/react';
import { useQueryClient, useQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import useReportDB from 'DocumentsApp/database/useReportDB';
import type { Report } from 'DocumentsApp/models/Report';
import { useAxios } from 'providers/AxiosProvider';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import {
  doGetIsLoading,
  doPromiseAPI,
  getPlaceholderData,
  useKeyUserId,
} from 'api/helpers';
import type { InspectionReport } from 'models/InspectionReport';
import type { QueryFlags } from 'models/Search';
import { findDocumentsQueryKey, getDocumentQueryKey } from './useFindReports';
import { documentListConfig } from './useUpdateValues';
import {
  transformFromOfflineReport,
  transformFromOnlineReport,
} from './utils/documentHelpers';

interface UseGetReportProps {
  reportId: string;
}

interface UseGetReportResponse {
  data?: InspectionReport;
}

const useGetReport = ({
  reportId,
}: UseGetReportProps): UseGetReportResponse & QueryFlags => {
  const { axios } = useAxios();
  const { getReportAPI, getAttachmentThumbnailAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { createQueryKey } = useKeyUserId();
  const { getReportById, createReports } = useReportDB();
  const { isOnline } = useNetworkStatus();

  const doGetReport = () => {
    return doPromiseAPI<InspectionReport>(async () => {
      const report = transformFromOfflineReport(
        (await getReportById(reportId)) || {}
      );
      let onlineReport = {} as Report;
      let inspectionOnlineReport = {} as InspectionReport;
      if (isOnline && toNumber(reportId) > 0) {
        const { data } = await axios.get<InspectionReport>(
          getReportAPI(toString(reportId))
        );
        onlineReport = transformFromOnlineReport(data);
        inspectionOnlineReport = data;
      }
      const offlineReport = transformFromOnlineReport(report);
      let syncReport: Report = {
        ...(offlineReport.needSync
          ? { ...onlineReport, ...offlineReport }
          : { ...offlineReport, ...onlineReport }),
      };

      function syncReportValuesList<ItemType>(list: string, listId: string) {
        return map(
          concat(
            filter(
              isOnline
                ? get(onlineReport, `${list}List`)
                : get(offlineReport, `${list}List`),
              (p) =>
                !includes(
                  get(offlineReport, `${list}Removed`) as number[],
                  get(p, listId)
                ) &&
                !find(
                  get(offlineReport, `${list}Added`),
                  (v) => get(p, listId) === get(v, listId)
                )
            ) as ItemType[],
            (get(offlineReport, `${list}Added`) || []) as ItemType[]
          ),
          (p) => {
            const inputJson = toString(get(p, 'inputJson'));
            return {
              ...p,
              ...(JSON.parse(inputJson || '{}') as object),
            };
          }
        );
      }

      syncReport = {
        ...syncReport,
        reportSignature: onlineReport.reportSignature,
        partsList: syncReportValuesList('parts', documentListConfig.parts.id),
        vasCodesList: syncReportValuesList(
          'vasCodes',
          documentListConfig.vasCodes.id
        ),
        genericSectionsList: syncReportValuesList(
          'genericSections',
          documentListConfig.genericSections.id
        ),
        reportValues: concat(
          filter(
            isOnline ? onlineReport.reportValues : offlineReport.reportValues,
            (p) =>
              !find(offlineReport.addedValues, (v) => p.inputId === v.inputId)
          ),
          offlineReport.addedValues || []
        ),
        reportImages: concat(
          filter(
            isOnline ? onlineReport.reportImages : offlineReport.reportImages,
            (p) =>
              !includes(offlineReport.removedImages, p.imageId) &&
              !find(offlineReport.addedImages, (v) => p.imageId === v.imageId)
          ),
          offlineReport.addedImages || []
        ),
      };
      syncReport.reportImages = await Promise.all(
        map(syncReport.reportImages, async (img) => {
          const offlineImage = find(
            report.reportImages,
            (p) => img.imageId === p.imageId
          );
          if (img.entity && !offlineImage?.image && isOnline) {
            const { data: imageData } = await axios.get<Blob>(
              getAttachmentThumbnailAPI(
                img.entity || '',
                toString(img.miLoc),
                toString(img.imageId),
                toString(img.groupId),
                toString(img.seqNo || ''),
                toString(img.imagePath || ''),
                208,
                208
              ),
              { responseType: 'blob' }
            );
            const reader = new FileReader();
            reader.readAsDataURL(imageData);
            const thumbnail = await new Promise<string>((resolve) => {
              reader.onloadend = () => {
                const base64data = reader.result as string;
                resolve(base64data);
              };
            });
            return { ...img, image: thumbnail };
          }
          return { ...img, image: img.image || offlineImage?.image };
        })
      );
      await createReports([syncReport]);
      return transformFromOfflineReport(syncReport, inspectionOnlineReport);
    });
  };

  const response = useQuery<InspectionReport, AxiosError>(
    createQueryKey(getDocumentQueryKey, { reportId, isOnline }),
    doGetReport,
    {
      networkMode: 'always',
      placeholderData: () =>
        getPlaceholderData<InspectionReport>({
          queryClient,
          queryKey: findDocumentsQueryKey,
          findPredicate: { reportId: toNumber(reportId) },
        }),
    }
  );

  const { data, error, refetch } = response;

  useIonViewDidEnter(() => {
    void refetch();
  });

  return {
    data,
    error,
    isLoading: doGetIsLoading(response),
  };
};

export default useGetReport;
