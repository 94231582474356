import { useTranslation } from 'react-i18next';
import { toNumber, toString } from 'lodash';
import type { MutationStatus } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useCountPlanDB from 'InventoryApp/database/useCountPlanDB';
import type { CountGroup } from 'InventoryApp/models/InventoryPlanGroup';
import { useToasts } from 'providers/ToastProvider';
import {
  onSuccessMutation,
  doPromiseAPI,
  onMutateUpdate,
  getPlaceholderData,
} from 'api/helpers';
import { ToastType } from 'models/Toast';
import { findIcon } from 'utils/icons';
import {
  findCountGroupItemsQueryKey,
  findCountGroupTotalItemsQueryKey,
} from './useFindCountGroupItems';
import { findCountGroupsQueryKey } from './useFindCountGroups';

interface UseAddZeroCountsProps {
  groupUniqueId: string;
}

interface UseAddZeroCountsResponse {
  status: MutationStatus;
  onAddZeroCounts: () => void;
}

const useAddZeroCounts = ({
  groupUniqueId,
}: UseAddZeroCountsProps): UseAddZeroCountsResponse => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { addZeroCounts, updateCountGroups } = useCountPlanDB();

  const doUpdateItemCount = () => {
    return doPromiseAPI(async () => {
      const countedItems = await addZeroCounts(groupUniqueId);
      const cachedGroup = getPlaceholderData<CountGroup>({
        queryClient,
        queryKey: findCountGroupsQueryKey,
        objectKey: 'items',
        findPredicate: { uniqueId: groupUniqueId },
      });
      const linesUpdated = toString(
        toNumber(cachedGroup?.linesUpdated) + countedItems
      );
      await updateCountGroups([{ linesUpdated, groupUniqueId }]);
      return { countedItems, linesUpdated };
    });
  };

  const { status, mutate } = useMutation(doUpdateItemCount, {
    networkMode: 'always',
    // TODO: optimisitc update for tabs totals?
    onSuccess: ({ countedItems, linesUpdated }) => {
      void onMutateUpdate<CountGroup>({
        queryClient,
        queryKey: findCountGroupsQueryKey,
        updatedItems: [
          {
            uniqueId: groupUniqueId,
            linesUpdated,
          } as CountGroup,
        ],
        dataPath: 'items',
        findPredicates: [{ uniqueId: groupUniqueId }],
        markAsUpdated: false,
        isInfiniteQuery: true,
      });
      void onSuccessMutation(queryClient, findCountGroupItemsQueryKey);
      void onSuccessMutation(queryClient, findCountGroupTotalItemsQueryKey);
      addToast({
        type: ToastType.success,
        variant: 'mipro-toast',
        header: t('inventory:addZeroCountSuccess', { count: countedItems }),
        testid: 'add-zero-count-toast',
        leftIcon: findIcon('check-circle', 'fas'),
      });
    },
    onError: () => {
      addToast({
        type: ToastType.error,
        text: t('inventory:addZeroCountError'),
        testid: 'add-zero-count-error-toast',
      });
    },
  });

  return {
    status,
    onAddZeroCounts: () => mutate(),
  };
};

export default useAddZeroCounts;
