import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { filter, isEmpty, map } from 'lodash';
import classes from 'ProductSearchApp/components/CheckoutForm/CheckoutForm.module.scss';
import useFindAttachments from 'api/attachments/useFindAttachments';
import useRemoveAttachment from 'api/attachments/useRemoveAttachment';
import useUpdateAttachmentDetails from 'api/attachments/useUpdateAttachmentDetails';
import useUploadFileAttachment from 'api/attachments/useUploadFileAttachment';
import { AttachmentSize } from 'models/Attachment';
import FileAttachments from 'components/Attachments/FileAttachments';

interface OrderAttachmentsInterface {
  viewMode: boolean;
  summaryMode?: boolean;
  miLoc: string;
  orderCtlNo: string;
}

const OrderAttachments = ({
  viewMode,
  summaryMode = false,
  miLoc,
  orderCtlNo,
}: OrderAttachmentsInterface): JSX.Element => {
  const { t } = useTranslation();
  const { data: attachmentsData, isLoading: attachmentsLoading } =
    useFindAttachments({
      domain: 'order',
      miLoc,
      ctlNo: orderCtlNo,
      // Todo : Fix needed from API
      lineNo: '0',
    });

  const { uploadAttachment } = useUploadFileAttachment({
    domain: 'order',
    miLoc,
    ctlNo: orderCtlNo,
  });

  const { updateAttachmentDetails } = useUpdateAttachmentDetails({
    domain: 'order',
    miLoc,
    ctlNo: orderCtlNo,
    lineNo: '0',
  });

  const { removeAttachment } = useRemoveAttachment({
    domain: 'order',
    miLoc,
    ctlNo: orderCtlNo,
  });

  const filteredAttachments = useMemo(() => {
    if (!summaryMode) {
      if (viewMode) {
        return filter(attachmentsData, (item) => item.MI_ONLY !== 'Y');
      }
      return filter(attachmentsData, (item) => item.LINE_NO === '0');
    }
    return attachmentsData;
  }, [attachmentsData, summaryMode, viewMode]);

  return (
    <div
      className={classNames(classes.cardWrapper, {
        [classes.noMargin]: viewMode,
      })}
    >
      <FileAttachments
        domain="order"
        name="order"
        files={filteredAttachments}
        size={AttachmentSize.LARGE}
        enableSmallPreview
        editMode={!viewMode}
        disabled={attachmentsLoading}
        testid="order--attachments"
        onUpdate={updateAttachmentDetails}
        onAdd={(addFiles) => {
          map(addFiles, (file) => {
            uploadAttachment({
              file,
              src: file.fileURL,
              lineNo: '0',
              miOnly: file.miOnly,
              description: file.description,
            });
          });
        }}
        onRemove={(file) => removeAttachment(file)}
        label={
          viewMode && isEmpty(filteredAttachments)
            ? t('email:noAttachments')
            : t('email:attachments')
        }
      />
    </div>
  );
};

export default OrderAttachments;
