import React from 'react';
import { useTranslation } from 'react-i18next';
import { IonGrid } from '@ionic/react';
import { FormikInput } from 'common/components/Forms/Input/Input';
import ContactSelect from 'CostSavingsApp/components/FastFind/ContactSelect';
import { useFormikContext } from 'formik';
import type { CheckoutOrderForm } from 'ProductSearchApp/models/Order';
import { mergeEmailRecipients } from 'ProductSearchApp/util/ocnHelpers';
import type { ContactItem } from 'models/Search';
import Text from 'components/Text/Text';
import classes from './CheckoutForm.module.scss';

interface ContactInfoProps {
  miLoc: string;
  customerNo: string;
  disabled?: boolean;
}

const ContactInfo = ({
  miLoc,
  customerNo,
  disabled,
}: ContactInfoProps): JSX.Element => {
  const { t } = useTranslation();
  const namespace = 'productSearch:checkout';

  const {
    values: { customerContact, typedRecipients },
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
    validateField,
  } = useFormikContext<CheckoutOrderForm>();

  return (
    <IonGrid className={classes.cardWrapper}>
      <Text text={t(`${namespace}:contactInfo`)} variant="mipro-h3-headline" />
      <ContactSelect
        miLoc={miLoc}
        customerNo={customerNo}
        className={classes.emailRow}
        label={t(`${namespace}:custContact`)}
        setContact={(v) => {
          setTimeout(() => {
            const { phone = '', email = '' } =
              (v as unknown as ContactItem)?.item || {};
            setFieldTouched('contactName', true);
            setFieldValue('customerContact', v);
            setFieldValue('contactName', v.title);
            if (email) {
              setFieldValue(
                'typedRecipients',
                mergeEmailRecipients(typedRecipients, email)
              );
            }
            if (phone) {
              setFieldValue('contactNumber', phone);
            }
            setTimeout(() => validateField('contactName'));
          });
        }}
        inputError={touched.contactName ? errors.contactName : undefined}
        required
        disabled={disabled}
        selItem={customerContact}
        maxlength={25}
        searchLink={t(`${namespace}:searchLinkText`)}
      />
      <FormikInput
        className={classes.input}
        label={t(`${namespace}:custPhone`)}
        name="contactNumber"
        inputmode="tel"
        type="tel"
        mask="(000) 000-0000"
        disabled={disabled}
        testid="customer-phone"
      />
    </IonGrid>
  );
};

export default ContactInfo;
