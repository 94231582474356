import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import type { AxiosError } from 'axios';
import { toNumber, toString } from 'lodash';
import { IonContent, IonPage, useIonViewWillEnter } from '@ionic/react';
import Footer from 'common/components/Footer/Footer';
import CustomerName from 'common/components/Header/CustomerName';
import Header from 'common/components/Header/Header';
import { scrollToFieldError } from 'common/utils/formHelpers';
import type { FormikHelpers } from 'formik';
import { Formik, useFormikContext } from 'formik';
import useAddToCart from 'ProductSearchApp/api/useAddToCart';
import ProductHeader from 'ProductSearchApp/components/ProductHeader/ProductHeader';
import type { OrderURLParams, Product } from 'ProductSearchApp/models/Products';
import {
  buildItemMino,
  padGroupSerial,
  zCodedItemNo,
} from 'ProductSearchApp/util/ocnHelpers';
import useGetCustomer from 'api/customer/useGetCustomer';
import useGoBack from 'hooks/useGoBack';
import type { Customer } from 'models/Customer';
import { getErrorMessage } from 'utils/helpers';
import { removeLeadingZeros } from 'utils/number';
import { goToAddZCodedItem } from 'navigation/navigationHelpers';
import Loader from 'components/Loader/Loader';
import DiscardModal from 'components/Modals/DiscardModal/DiscardModal';
import Spinner from 'components/Spinner/Spinner';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './AddZCodedItem.module.scss';
import type { AddZCodedItemForm } from './AddZCodedItemSchema';
import { AddZCodedItemSchema } from './AddZCodedItemSchema';
import DetailsForm from './DetailsForm';
import PgcGroupsForm from './PgcGroupsForm';

interface AddZCodedItemPageProps {
  /* eslint-disable react/require-default-props */
  isSubmitting?: boolean;
  customerData?: Customer;
  customerLoading?: boolean;
  customerError?: AxiosError | null;
  detailsForm?: boolean;
  setDetailsForm?: React.Dispatch<React.SetStateAction<boolean>>;
  /* eslint-enable react/require-default-props */
}

interface AddZCodedItemPageRef {
  resetForm?: () => void;
}

const AddZCodedItemPage = React.forwardRef<
  AddZCodedItemPageRef,
  AddZCodedItemPageProps
>((props, outerRef): JSX.Element => {
  const {
    customerData,
    customerLoading,
    customerError,
    isSubmitting,
    detailsForm,
    setDetailsForm,
  } = props;
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const resetView = params.get('reset');
  const { ocn: orderCtlNo = '' } = useParams<OrderURLParams>();
  const { t } = useTranslation();
  const { goBack } = useGoBack();
  const [openDiscardModal, setOpenDiscardModal] = useState(false);

  const { values, dirty, isValid, setFieldValue, handleSubmit, resetForm } =
    useFormikContext<AddZCodedItemForm>();

  const onBackClick = () => {
    if (dirty && !detailsForm) {
      setOpenDiscardModal(true);
    } else if (detailsForm) {
      setDetailsForm?.(false);
      setFieldValue('detailsForm', false);
    } else {
      goBack();
    }
  };

  const isLoading = customerLoading || !!resetView;
  const formLoading = isLoading || isSubmitting;
  const disabledSave = formLoading || !!customerError;

  useImperativeHandle(
    outerRef,
    () => ({
      resetForm: () => resetForm({}),
    }),
    [resetForm]
  );

  const formContent = detailsForm ? (
    <>
      <ProductHeader
        className={classes.productName}
        productData={
          {
            itemNumber: zCodedItemNo,
            manufacturerName: toString(values.mfrCtlNo?.title),
            mfgPartNumber: toString(values.mfgPartNo),
            mino: buildItemMino(values.mfrCtlNo?.key, values.group4digits?.key),
          } as Product
        }
        hidePrice
        testid="product-name"
      />
      <DetailsForm isLoading={formLoading} />
    </>
  ) : (
    <PgcGroupsForm isLoading={formLoading} />
  );
  const pageContent = customerError ? (
    <WarningMessage
      className={classes.message}
      title={t('common:errorTitle')}
      body={getErrorMessage(customerError)}
      testid="customer-error"
    />
  ) : (
    formContent
  );

  return (
    <IonPage data-testid="add-z-coded-item-page">
      <Header
        customTitle={<CustomerName customerData={customerData} />}
        hideMenuButton
        backButton={{ onClick: () => onBackClick() }}
        testid="add-z-coded-item-page-header"
      />
      <IonContent className={classes.content}>
        <div className={classes.wrapper}>
          <Header
            collapse="condense"
            pageTitle={t(`productSearch:zCodedItem:addZCodedItem`)}
            customTitle={
              <div>
                <CustomerName customerData={customerData} />
                {orderCtlNo && (
                  <Text
                    className={classes.ocnTitle}
                    variant="mipro-h6-headline"
                    text={t(`productSearch:ocn:ocnNo`, {
                      orderCtlNo: removeLeadingZeros(orderCtlNo),
                    })}
                  />
                )}
              </div>
            }
            testid="add-z-coded-item-header"
          />
          <Spinner
            className={classes.formLoader}
            text={t('common:forms:updatingLoader')}
            showSpinner={isSubmitting}
            testid="add-z-coded-item-loader"
          />
          {isLoading ? (
            <Loader
              className={classes.loader}
              text={t('common:loading')}
              isOpen={isLoading}
              testid="customer-loader"
            />
          ) : (
            pageContent
          )}
        </div>
        <DiscardModal
          isOpen={openDiscardModal}
          setIsOpen={setOpenDiscardModal}
          title={t('notes:cancelNotesTitle')}
          discardMsg={t('common:discardMsg')}
          discardButtonTitle={t('notes:cancelNotesYes')}
          goBackButtonTitle={t('notes:cancelNotesNo')}
          onDiscardClick={() => goBack()}
          initialBreakpoint={0.4}
          withRightCloseButton
          testid="add-z-coded-item-discard-modal"
        />
      </IonContent>
      <Footer
        buttons={[
          {
            variant: 'secondary',
            text: t(
              `common:forms:${detailsForm ? 'backButton' : 'cancelButton'}`
            ),
            disabled: disabledSave,
            onClick: () => onBackClick(),
            testid: 'cancel-button',
          },
          {
            variant: 'mipro-action',
            text: t(
              detailsForm
                ? 'productSearch:zCodedItem:addItemButton'
                : `common:forms:nextButton`
            ),
            onClick: () => {
              if (!isValid) {
                scrollToFieldError();
              }
              handleSubmit();
            },
            disabled: disabledSave,
            testid: 'submit-button',
          },
        ]}
      />
    </IonPage>
  );
});

interface AddZCodedItemFormikProps {
  onSubmit: (
    values: AddZCodedItemForm,
    formikHelpers: FormikHelpers<AddZCodedItemForm>
  ) => void | Promise<void>;
}

export const AddZCodedItemFormik = ({
  children,
  onSubmit,
}: React.PropsWithChildren<AddZCodedItemFormikProps>): JSX.Element => {
  return (
    <Formik<AddZCodedItemForm>
      initialValues={{
        mfgPartNo: '',
        mfrCtlNo: undefined,
        group2digits: undefined,
        group4digits: undefined,
        isRepair: false,
        repairShop: undefined,
        detailsForm: false,
        custStockNo: '',
        custComment: '',
        quantity: '1',
        salesUom: undefined,
        unitCost: '0',
        promsdDelivDt: '',
      }}
      enableReinitialize
      validationSchema={AddZCodedItemSchema}
      onSubmit={onSubmit}
    >
      {() => children}
    </Formik>
  );
};

const AddZCodedItem = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const { goBack } = useGoBack();
  const params = new URLSearchParams(location.search);
  const resetView = params.get('reset');
  const {
    miLoc,
    id: customerId,
    ocn: orderCtlNo = '',
  } = useParams<OrderURLParams>();
  const [detailsForm, setDetailsForm] = useState(false);
  const pageRef = useRef<AddZCodedItemPageRef>(null);

  const {
    data: customerData,
    isLoading: customerLoading,
    error: customerError,
  } = useGetCustomer({
    searchType: 'customer',
    miLoc,
    id: customerId,
  });

  useIonViewWillEnter(() => {
    if (resetView) {
      pageRef.current?.resetForm?.();
      setDetailsForm(false);
      history.replace(
        goToAddZCodedItem({ miLoc, shipToCustNo: customerId, orderCtlNo })
      );
    }
  }, [resetView, orderCtlNo]);

  const { status, onAddToCart } = useAddToCart();

  useEffect(() => {
    if (status === 'success') {
      goBack();
    }
  }, [goBack, status]);

  const isSubmitting = status === 'loading';

  return (
    <AddZCodedItemFormik
      onSubmit={(values, helpers) => {
        if (!detailsForm) {
          setDetailsForm(true);
          helpers.setFieldValue('detailsForm', true);
          return;
        }
        const quantity = toNumber(values.quantity);
        onAddToCart({
          customerName: customerData?.name,
          mfgPartNo: values.mfgPartNo,
          customerInfo: { miLoc, customerNo: customerId },
          itemList: [
            {
              itemNo: zCodedItemNo,
              mfgPartNo: values.mfgPartNo,
              mino: buildItemMino(
                values.mfrCtlNo?.key,
                values.group4digits?.key
              ),
              mfrCtlNo: values.mfrCtlNo?.key,
              groupSerial: padGroupSerial(
                `Z${toString(values.group4digits?.key)}`
              ),
              custStockNo: values?.custStockNo,
              custComment: values.custComment,
              quantity,
              salesUOM: values.salesUom?.key,
              unitCost: values.unitCost,
              promsdDelivDt: values.promsdDelivDt,
            },
          ],
          sourceList: values.repairShop?.key
            ? [
                {
                  purchaseQty: toString(quantity),
                  sourceLoc: values.repairShop?.key,
                },
              ]
            : [],
        });
      }}
    >
      <AddZCodedItemPage
        ref={pageRef}
        customerData={customerData}
        customerLoading={customerLoading}
        customerError={customerError}
        isSubmitting={isSubmitting}
        detailsForm={detailsForm}
        setDetailsForm={setDetailsForm}
      />
    </AddZCodedItemFormik>
  );
};

export default AddZCodedItem;
