import type { AxiosError } from 'axios';
import { isEmpty, toNumber } from 'lodash';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { doPromiseAPI, getPlaceholderData, useKeyUserId } from 'api/helpers';
import type { ActivityNote } from 'models/Notebook';
import type { QueryFlags } from 'models/Search';
import { findActivityNotesQueryKey } from './useFindActivityNotes';

export const getActivityNoteQueryKey = 'activity-note';

interface UseGetActivityNoteProps {
  id: string;
  enabled?: boolean;
}

interface UseGetActivityNoteResponse {
  data?: ActivityNote;
}

const useGetActivityNote = ({
  id,
  enabled = true,
}: UseGetActivityNoteProps): UseGetActivityNoteResponse & QueryFlags => {
  const { axios } = useAxios();
  const { getActivityNoteAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { createQueryKey } = useKeyUserId();

  const doGetActivityNote = () => {
    return doPromiseAPI<ActivityNote>(async (cancelToken) => {
      const { data } = await axios.get<ActivityNote>(getActivityNoteAPI(id), {
        cancelToken,
      });
      return data;
    });
  };
  const { data, error, status, isFetching, refetch } = useQuery<
    ActivityNote,
    AxiosError
  >(
    createQueryKey(getActivityNoteQueryKey, { eventNoteId: id }),
    doGetActivityNote,
    {
      enabled: !isEmpty(id) && enabled,
      placeholderData: () =>
        getPlaceholderData<ActivityNote>({
          queryClient,
          queryKey: findActivityNotesQueryKey,
          findPredicate: { eventNoteId: toNumber(id) },
        }),
    }
  );

  return {
    data,
    error,
    isLoading: status === 'loading' || isFetching,
    isFetching,
    refetch: async () => {
      await refetch();
    },
  };
};

export default useGetActivityNote;
