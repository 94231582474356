import { useMemo } from 'react';
import type { AxiosError } from 'axios';
import { size, toString } from 'lodash';
import type { QueryFunctionContext } from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import type {
  QueryFlags,
  QueryParamsType,
} from 'common/api/utils/useGetQueryFlags';
import useGetQueryFlags from 'common/api/utils/useGetQueryFlags';
import { useAxios } from 'providers/AxiosProvider';
import { doConcatDataPages, useKeyUserId } from 'api/helpers';
import { pageSize } from 'utils/constants';

export const findNotebookTemplatesQueryKey = 'notebook-template';

interface NotebookTemplate {
  codeDesc: string;
  codeValue: string;
}

interface UseFindNotebookTemplatesProps {
  limit?: number;
  enabled?: boolean;
  query?: string;
  miLoc: string;
}

interface UseFindNotebookTemplatesResponse {
  template: NotebookTemplate[];
}

const useFindNotebookTemplates = ({
  limit = pageSize(),
  enabled = true,
  query,
  miLoc,
}: UseFindNotebookTemplatesProps): UseFindNotebookTemplatesResponse &
  QueryFlags => {
  const { axios } = useAxios();
  const { findNotebookTemplateAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const params: QueryParamsType = {
    limit,
    fastFind: query,
    sortField: 'NOTEBOOK_CD',
    sortDir: 'DESC',
    miLoc,
  };

  const doFindNotebookTemplates = async ({
    pageParam = 1,
    signal,
  }: QueryFunctionContext) => {
    const { data } = await axios.get<NotebookTemplate[]>(
      findNotebookTemplateAPI(
        toString(new URLSearchParams({ ...params, page: toString(pageParam) }))
      ),
      { signal }
    );
    return data;
  };

  const response = useInfiniteQuery<NotebookTemplate[], AxiosError>(
    createQueryKey(findNotebookTemplatesQueryKey, params),
    doFindNotebookTemplates,
    {
      enabled,
      getNextPageParam: (lastPage, pages) =>
        size(lastPage) < limit ? false : size(pages) + 1,
    }
  );

  const { data } = response;

  const template = useMemo(
    () => doConcatDataPages<NotebookTemplate>(data),
    [data]
  );

  const queryFlags = useGetQueryFlags({
    ...response,
    data: template,
    enabled,
  });

  return {
    template,
    ...queryFlags,
  };
};

export default useFindNotebookTemplates;
