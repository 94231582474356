import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { size, toNumber } from 'lodash';
import { IonContent, IonPage, useIonViewWillEnter } from '@ionic/react';
import Header from 'common/components/Header/Header';
import List from 'common/components/List/List';
import { useIonContentRef } from 'common/components/utils/renderHelpers';
import { and, choose, or } from 'common/utils/logicHelpers';
import { useGetUserInfo } from 'common/utils/userInfo';
import { searchURL } from 'navigation';
import useFindCorpCustomers from 'SearchApp/api/useFindCorpCustomers';
import { useDebounce } from 'use-debounce';
import useGetCustomer from 'api/customer/useGetCustomer';
import type { Customer } from 'models/Customer';
import { SearchSuggestionTypeEnum } from 'models/Search';
import type { SearchItem, SnapshotsURLParams } from 'models/Search';
import { setCorpCustomerSearch } from 'store/user';
import { getAddress } from 'utils/address';
import { concatRoutes } from 'utils/navigations';
import { getSearchResultData, handleSearchNavigation } from 'utils/search';
import Refresher from 'components/Refresher/Refresher';
import SearchSuggestionCard from 'components/Search/SearchSuggestionCard/SearchSuggestionCard';
import Searchbar from 'components/Searchbar/Searchbar';
import classes from './CorpCustomerSearch.module.scss';

const CorpCustomerSearch = (): JSX.Element => {
  const dispatch = useDispatch();
  const {
    userState: { corpCustomerSearch },
  } = useGetUserInfo();
  const { miLoc, id } = useParams<SnapshotsURLParams>();
  const { t } = useTranslation('SearchApp-CorpCustomerSearch');
  const isCustomerCached = and(
    corpCustomerSearch?.miLoc === miLoc,
    corpCustomerSearch?.customerNo === id
  );
  const cachedSearchQuery = choose(
    isCustomerCached,
    corpCustomerSearch?.searchQuery
  );

  const { node, nodeRef } = useIonContentRef();
  const [triggerAutoFocus, setTriggerAutoFocus] = useState(0);
  const [searchQuery, setSearchQuery] = useState(cachedSearchQuery);
  const [searchQueryValue] = useDebounce(searchQuery, 300);

  const { data: customerData, isLoading: customerIsLoading } = useGetCustomer({
    searchType: 'customer',
    miLoc,
    id,
  });

  const {
    items,
    totalItems,
    error,
    noMoreData,
    isLoading: searchIsLoading,
    isEmptyResponse,
    refetch,
    fetchNextPage,
  } = useFindCorpCustomers({
    miLoc,
    nationalAcctNo: customerData?.natlAcctNo,
    query: or(cachedSearchQuery, searchQueryValue),
  });
  const isLoading = or(customerIsLoading, searchIsLoading);

  useIonViewWillEnter(() => {
    if (!cachedSearchQuery) {
      setTimeout(() => setTriggerAutoFocus(Date.now()), 150);
    }
    if (!isCustomerCached) {
      dispatch(setCorpCustomerSearch({}));
    }
  });

  useEffect(() => {
    setSearchQuery(cachedSearchQuery);
  }, [cachedSearchQuery]);

  useEffect(() => {
    dispatch(
      setCorpCustomerSearch({
        corpCustomerSearch: {
          searchQuery: searchQueryValue,
          customerNo: customerData?.customerNo,
          miLoc,
        },
      })
    );
  }, [dispatch, searchQueryValue, miLoc, customerData?.customerNo]);

  const renderItem = (index: number, item: Customer) => {
    const { type, key, text, description, address, customerNo } =
      getSearchResultData(
        {
          ...item,
          type: 'customer',
          id: item.customerNo,
          text: item.name,
        } as SearchItem,
        index,
        t
      );

    return (
      <SearchSuggestionCard
        key={key}
        id="customers"
        searchSuggestionType={SearchSuggestionTypeEnum.result}
        text={text}
        customerNo={customerNo}
        textQuery={searchQuery}
        description={description}
        account={item.natlAcctNo}
        address={getAddress(address, ',')}
        href={concatRoutes(
          searchURL(),
          handleSearchNavigation({
            type: 'customer',
            miLoc: item.miLoc,
            customerId: item.customerNo,
          })
        )}
        testid={`corp-customer-${index}`}
        searchType={type}
      />
    );
  };

  return (
    <IonPage data-testid="corp-customer-search-page">
      <Header
        title={t('pageTitle')}
        subTitle={customerData?.name}
        testid="corp-customer-search-header"
        hideMenuButton={false}
        hideLocationSelector={false}
      >
        <Searchbar
          className={classes.searchInput}
          value={searchQuery}
          placeholder={t('searchPlaceholder')}
          setValue={setSearchQuery}
          testid="search-input"
          triggerInputSelect={triggerAutoFocus}
        />
      </Header>
      <IonContent ref={nodeRef}>
        <Refresher
          hidden
          slot="fixed"
          onRefresh={refetch}
          testid="corp-customer-search-refresher"
          disabled={isLoading}
        />
        <List
          title={t('searchResultsTitle', {
            totalItems,
            count: choose(toNumber(totalItems) >= 500, 0, 1),
          })}
          testid="corp-customer-search-list"
          data={items}
          itemContent={renderItem}
          isLoading={isLoading}
          isEmptyList={{
            isEmptyList: isEmptyResponse,
            title: t('emptyResultsTitle', {
              count: size(searchQueryValue),
              query: searchQueryValue,
            }),
          }}
          isError={!!error}
          isEndOfList={noMoreData}
          scrollParent={node}
          endReached={fetchNextPage}
        />
      </IonContent>
    </IonPage>
  );
};

export default CorpCustomerSearch;
