import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { isEmpty, map, reduce, size, some, toNumber, toString } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  useIonViewDidEnter,
  useIonViewWillLeave,
} from '@ionic/react';
import Header from 'common/components/Header/Header';
import useFindLeaderboard from 'api/leaderboard/useFindLeaderboard';
import useFindLeaderboardDetails from 'api/leaderboard/useFindLeaderboardDetails';
import type { LeaderboardItem } from 'models/LeaderBoard';
import type { RootState } from 'store/reducers';
import { getErrorMessage } from 'utils/helpers';
import { findIcon } from 'utils/icons';
import Pick12Contest from 'pages/Leaderboard/Pick12Contest/Pick12Contest';
import Pick12LeaderBoardDetailsModal from 'pages/Leaderboard/Pick12LeaderBoardCards/Pick12LeaderBoardDetailsModal';
import LeaderboardCard from 'components/LeaderboardCard/LeaderboardCard';
import LeaderboardTableRow from 'components/LeaderboardTableRow/LeaderboardTableRow';
import Loader from 'components/Loader/Loader';
import Pick12Icon from 'components/Pick12Icon/Pick12Icon';
import Refresher from 'components/Refresher/Refresher';
import SegmentTabs from 'components/SegmentTabs/SegmentTabs';
import Text from 'components/Text/Text';
import Toggle from 'components/Toggle/Toggle';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './Leaderboard.module.scss';

interface LeaderBoardProps {
  year?: number;
}

const Leaderboard = ({ year: selectedYear }: LeaderBoardProps): JSX.Element => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  const options = [
    {
      key: toString(currentYear - 1),
      text: `${currentYear - 1} ${t('common:leaderboard')}`,
    },
    {
      key: toString(currentYear),
      text: `${currentYear} ${t('common:leaderboard')}`,
    },
  ];
  const [year, setYear] = useState<number>(
    !selectedYear ? currentYear : selectedYear
  );
  const [qualifierLeaderboard, setQualifierLeaderboard] = useState(false);
  const [toggleEnabled, setToggleEnabled] = useState(false);
  const [showLeaderboardDetailCard, setLeaderboardDetailCard] = useState(false);
  const [selectedUserId, setUserId] = useState<string>('');
  const [showPick12ContestQualifcations, setPick12ContestQualifications] =
    useState(false);
  const [detailCardRank, setDetailCardRank] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const { userInfo } = useSelector((state: RootState) => state.user);
  const { data: pick12ContestRules } = useFindLeaderboardDetails({
    year,
    userId: toString(userInfo?.employeenumber),
  });

  useIonViewDidEnter(() => {
    setIsTransitioning(true);
  });

  useIonViewWillLeave(() => {
    setIsTransitioning(false);
  });

  const showContestRules = year !== currentYear;
  const { items, error, hasError, isEmptyResponse, showLoader, refetch } =
    useFindLeaderboard({
      year,
      limit: qualifierLeaderboard && showContestRules ? 10 : 50,
      eligible: qualifierLeaderboard && showContestRules ? 'Y' : 'all',
    });

  useEffect(() => {
    setToggleEnabled(some(items, (item) => item?.eligible));
  }, [items]);

  const topRankers = reduce(
    items,
    (acc: LeaderboardItem[], item) => {
      if (
        size(acc) < 3 &&
        ((qualifierLeaderboard && item.eligible) || !qualifierLeaderboard)
      ) {
        acc.push(item);
      }
      return acc;
    },
    []
  );

  return (
    <IonPage className={classes.reports} data-testid="Reports">
      <div className={classes.container}>
        <div
          className={classNames(classes.leaderBackground, {
            [classes.opacity]: isTransitioning,
            [classes.visibility]: !isTransitioning,
          })}
        />
      </div>

      <Header showLocationText testid="Reports-pick12LeaderBoard">
        <div className={classes.tabsRow}>
          <SegmentTabs
            className={classes.yearTabs}
            options={options}
            selectedClassName={classes.selectedSegmentTab}
            value={toString(year)}
            textVariant="mipro-h6-headline"
            setValue={(v: string) => setYear(toNumber(v))}
            testid="year-tabs"
          />
        </div>
      </Header>
      <IonContent
        className={classNames(classes.pick12LeaderBoardContent, {
          [classes.backgroundTransparent]: isTransitioning,
        })}
      >
        <Refresher
          slot="fixed"
          className={classes.refresher}
          onRefresh={async () => {
            await refetch?.();
          }}
          hidden
          testid="leaderboard-reports"
          disabled={showLoader}
        />
        <div className={classes.leaderboardItem}>
          <span
            className={classNames(
              classes.pick12Label,
              classes.leaderboardLabel
            )}
          >
            <>
              {year} &nbsp;
              <Pick12Icon testid="leaderboard" className={classes.pick12Icon} />
              {t('reports:pick12')} &nbsp;
            </>
          </span>
          <span
            className={classNames(
              classes.contestLabel,
              classes.leaderboardLabel
            )}
          >
            <>{t('reports:contestLeaders')}</>
          </span>
        </div>
        <div className={classes.leaderboardContent}>
          {/* TODO: this should be an iteration, not copy/paste */}
          <LeaderboardCard
            key={topRankers[1]?.employeeId || 'topRanker-2'}
            item={topRankers.length > 1 ? topRankers[1] : undefined}
            rank={2}
            showLoader={showLoader && isEmpty(items)}
            showLeaderBoardDetailCard={(userId?) => {
              setLeaderboardDetailCard(true);
              setUserId(toString(userId));
              setDetailCardRank(2);
            }}
            testid="leaderboard-second-rank"
          />
          <LeaderboardCard
            key={topRankers[0]?.employeeId || 'topRanker-1'}
            item={topRankers.length > 0 ? topRankers[0] : undefined}
            rank={1}
            showLoader={showLoader && isEmpty(items)}
            showLeaderBoardDetailCard={(userId) => {
              setLeaderboardDetailCard(true);
              setUserId(toString(userId));
              setDetailCardRank(1);
            }}
            testid="leaderboard-first-rank"
          />
          <LeaderboardCard
            key={topRankers[2]?.employeeId || 'topRanker-3'}
            item={topRankers.length > 2 ? topRankers[2] : undefined}
            rank={3}
            showLoader={showLoader && isEmpty(items)}
            showLeaderBoardDetailCard={(userId) => {
              setLeaderboardDetailCard(true);
              setUserId(toString(userId));
              setDetailCardRank(3);
            }}
            testid="leaderboard-third-rank"
          />
        </div>
        <div className={classes.divider}>
          <Text
            className={classes.leaderboardText}
            variant="title-screen-section"
            text={t('common:leaderboard')}
          />
        </div>
        {showContestRules && (
          <Toggle
            className={classes.toggle}
            color="danger"
            checked={qualifierLeaderboard}
            onClick={() => setQualifierLeaderboard(!qualifierLeaderboard)}
            testid="pick12Eligible-toggle-testid"
            disabled={!toggleEnabled}
          >
            <IonGrid
              className={classes.ionGrid}
              onClick={() => setPick12ContestQualifications(true)}
              slot="start"
            >
              <IonRow className="ion-align-items-center ion-justify-content-between">
                <IonCol className={classes.ionCol}>
                  <Text
                    variant="content-small"
                    text={t('reports:pick12Qualifiers')}
                    testid="pick12Eligible-toggle-text"
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                {/* <div className={classes.truckPickupIconWrapper}>
                  <FontAwesomeIcon
                    className={classes.truckPickupIcon}
                    icon={findIcon('truck-pickup')}
                  />
                </div> */}
                <Text
                  className={classes.note}
                  variant="content-smaller"
                  text={t('reports:pick12Eligible')}
                  testid="pick12Eligible-toggle-note"
                />

                <FontAwesomeIcon
                  className={classes.infoIcon}
                  icon={findIcon('info-circle')}
                />
              </IonRow>
            </IonGrid>
          </Toggle>
        )}
        <div className={classes.leaderboardTable}>
          <div className={classes.tableBackground}>
            <IonGrid className={classes.tableGrid}>
              <IonRow className={classes.leaderboardTableHeader}>
                <IonCol className={classes.tableHeaderCol} size="6" />
                <IonCol className={classes.tableHeaderCol} size="10">
                  <Text
                    className={classes.leaderHeaderText}
                    variant="content-smaller"
                    text={t('reports:rank')}
                  />
                </IonCol>
                <IonCol className={classes.tableHeaderCol} size="10">
                  &nbsp;
                </IonCol>
                <IonCol className={classes.tableHeaderCol} size="35">
                  <Text
                    className={classNames(
                      classes.leaderHeaderText,
                      classes.player
                    )}
                    variant="content-smaller"
                    text={t('reports:player')}
                  />
                </IonCol>
                <IonCol className={classes.tableHeaderCol} size="23">
                  <Text
                    className={classNames(
                      classes.leaderHeaderText,
                      classes.totalSales
                    )}
                    variant="content-smaller"
                    text={t('common:sales')}
                  />
                </IonCol>
                <IonCol className={classes.tableHeaderCol} size="16">
                  <Text
                    className={classes.leaderHeaderText}
                    variant="content-smaller"
                    text={t('common:gpPercentage')}
                  />
                </IonCol>
              </IonRow>
              <div>
                {map(items, (item, index) => (
                  <LeaderboardTableRow
                    key={`leaderBoardRow-${index}`}
                    item={{
                      ...item,
                      eligible: showContestRules && item.eligible,
                    }}
                    rank={item.rank}
                    showLeaderBoardDetailCard={(userId) => {
                      setLeaderboardDetailCard(true);
                      setUserId(toString(userId));
                      setDetailCardRank(item.rank);
                    }}
                    testid={`leaderBoardRow-${index}`}
                    isPersonalRank={
                      userInfo?.employeenumber === item.employeeId
                    }
                  />
                ))}
              </div>
            </IonGrid>
          </div>
        </div>
        {isEmptyResponse && !hasError && (
          <WarningMessage
            className={classes.warningMessage}
            icon={['far', 'info-circle']}
            title={t('reports:noReports')}
          />
        )}
        {hasError && (
          <WarningMessage
            className={classes.warningMessage}
            title={t('reports:errorReports')}
            body={getErrorMessage(error)}
          />
        )}
        <Loader
          className={classes.loader}
          text={`${t('common:loading')} ${t('common:leaderboard')}`}
          isOpen={showLoader}
        />
        <Pick12LeaderBoardDetailsModal
          year={year}
          userId={selectedUserId}
          rank={detailCardRank}
          closeLeaderBoardCard={() => setLeaderboardDetailCard(false)}
          isOpen={showLeaderboardDetailCard}
        />
        <Pick12Contest
          isOpen={showPick12ContestQualifcations}
          setIsOpen={(e) => setPick12ContestQualifications(e)}
          pick12ContestRules={pick12ContestRules}
        />
      </IonContent>
    </IonPage>
  );
};

export default Leaderboard;
