import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import type { Dictionary } from 'lodash';
import { size, map, take } from 'lodash';
import useFindGlobalOrders from 'ProductSearchApp/api/orders/useFindGlobalOrders';
import useFindCustomers from 'api/customer/useFindCustomers';
import useFindCustomerContacts from 'api/customerContacts/useFindCustomerContacts';
import useFindEmployees from 'api/employee/useFindEmployees';
import useFindLocations from 'api/location/useFindLocations';
import useFindSuppliers from 'api/supplier/useFindSuppliers';
import useAccessControls, { AccessControlType } from 'hooks/useAccessControls';
import type {
  InfiniteQueryFlags,
  SearchItem,
  SearchResponse,
  SearchSuggestionItem,
} from 'models/Search';
import {
  SearchSuggestionTypeEnum,
  SearchResultTabTypeEnum,
} from 'models/Search';
import { selectIsMiLocCorpOrExec } from 'store/user/selectors';
import { pageSize } from 'utils/constants';
import { getSearchResponseFlag } from './utils';

interface UseFindSearchSuggestionsProps {
  searchType: SearchResultTabTypeEnum;
  query?: string;
  limit?: number;
  linesToShow?: number;
  sortField?: string;
}

interface UseFindSearchSuggestionsResponse {
  items?: SearchSuggestionItem[];
  customers: SearchSuggestionItem[];
  suppliers: SearchSuggestionItem[];
  employees: SearchSuggestionItem[];
  customerContacts: SearchSuggestionItem[];
  motionLocations: SearchSuggestionItem[];
  corporateAccounts: SearchSuggestionItem[];
  ocns: SearchSuggestionItem[];
  noMoreDataMap?: Dictionary<boolean>;
  seeMoreCustomers: () => void;
  seeMoreSuppliers: () => void;
  seeMoreEmployees: () => void;
  seeMoreCustomerContacts: () => void;
  seeMoreMotionLocations: () => void;
  seeMoreCorporateAccounts: () => void;
  seeMoreOCNs: () => void;
  resetSeeMoreLineNumbers: () => void;
}

const useFindSearchSuggestions = ({
  searchType,
  query,
  limit = pageSize(),
  linesToShow = 3,
  sortField,
}: UseFindSearchSuggestionsProps): InfiniteQueryFlags &
  UseFindSearchSuggestionsResponse => {
  const findCustomersResponse = useFindCustomers({
    query,
    limit,
    enabled:
      searchType === SearchResultTabTypeEnum.all ||
      searchType === SearchResultTabTypeEnum.customers,
  });
  const findSuppliersResponse = useFindSuppliers({
    query,
    limit,
    enabled:
      searchType === SearchResultTabTypeEnum.all ||
      searchType === SearchResultTabTypeEnum.suppliers,
  });
  const findEmployeesResponse = useFindEmployees({
    query,
    limit,
    sortField,
    enabled:
      searchType === SearchResultTabTypeEnum.all ||
      searchType === SearchResultTabTypeEnum.employees,
  });
  const findCustomerContactsResponse = useFindCustomerContacts({
    query,
    limit,
    enabled:
      searchType === SearchResultTabTypeEnum.all ||
      searchType === SearchResultTabTypeEnum.customerContacts,
  });
  const findMotionLocationsResponse = useFindLocations({
    locTypes: ['S'],
    query,
    limit,
    enabled:
      searchType === SearchResultTabTypeEnum.all ||
      searchType === SearchResultTabTypeEnum.motionLocations,
  });
  const isCorpOrExec = useSelector(selectIsMiLocCorpOrExec);

  const findCorporateAccountsResponse = useFindCustomers({
    query,
    limit,
    nationalAccountOnly: true,
    enabled:
      isCorpOrExec &&
      (searchType === SearchResultTabTypeEnum.all ||
        searchType === SearchResultTabTypeEnum.corporateAccounts),
  });
  const canSearchOrders = useAccessControls(
    AccessControlType.ViewOrdersAccessControls
  );
  const findOCNsResponse = useFindGlobalOrders({
    query,
    limit,
    enabled:
      canSearchOrders &&
      (searchType === SearchResultTabTypeEnum.all ||
        searchType === SearchResultTabTypeEnum.ocns),
  });

  const initialLineLimits = useMemo(
    () => ({
      CUSTOMERS: linesToShow,
      SUPPLIERS: linesToShow,
      EMPLOYEES: linesToShow,
      CUSTOMERCONTACTS: linesToShow,
      MOTIONLOCATIONS: linesToShow,
      CORPORATEACCOUNTS: linesToShow,
      OCNS: linesToShow,
    }),
    [linesToShow]
  );

  const [lineLimits, setLineLimits] = useState(initialLineLimits);

  const seeMoreCustomers = () =>
    setLineLimits((prevState) => ({ ...prevState, CUSTOMERS: 25 }));
  const seeMoreSuppliers = () =>
    setLineLimits((prevState) => ({ ...prevState, SUPPLIERS: 25 }));
  const seeMoreEmployees = () =>
    setLineLimits((prevState) => ({ ...prevState, EMPLOYEES: 25 }));
  const seeMoreCustomerContacts = () =>
    setLineLimits((prevState) => ({ ...prevState, CUSTOMERCONTACTS: 25 }));
  const seeMoreMotionLocations = () =>
    setLineLimits((prevState) => ({ ...prevState, MOTIONLOCATIONS: 25 }));
  const seeMoreCorporateAccounts = () =>
    setLineLimits((prevState) => ({ ...prevState, CORPORATEACCOUNTS: 25 }));
  const seeMoreOCNs = () =>
    setLineLimits((prevState) => ({ ...prevState, OCNS: 25 }));

  const resetSeeMoreLineNumbers = useCallback(() => {
    setLineLimits(initialLineLimits);
  }, [initialLineLimits]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => resetSeeMoreLineNumbers(), [query]);

  const transformToResults = (items: SearchItem[]) => {
    return map(items, (item) => ({
      ...item,
      searchType: SearchSuggestionTypeEnum.result,
    }));
  };

  const getFlagValue = (key: keyof InfiniteQueryFlags, isOR = true) =>
    getSearchResponseFlag({
      searchType,
      customersResponse: findCustomersResponse,
      suppliersResponse: findSuppliersResponse,
      employeesResponse: findEmployeesResponse,
      customerContactsResponse: findCustomerContactsResponse,
      motionLocationsResponse: findMotionLocationsResponse,
      corporateAccountsResponse: findCorporateAccountsResponse,
      OCNsResponse: findOCNsResponse,
      key,
      isOR,
    });

  let error = findCustomersResponse.error || findSuppliersResponse.error;
  if (searchType === 'customers') {
    error = findCustomersResponse.error;
  }
  if (searchType === 'suppliers') {
    error = findSuppliersResponse.error;
  }
  if (searchType === 'employees') {
    error = findEmployeesResponse.error;
  }
  if (searchType === 'customerContacts') {
    error = findCustomerContactsResponse.error;
  }
  if (searchType === 'motionLocations') {
    error = findMotionLocationsResponse.error;
  }
  if (searchType === 'corporateAccounts') {
    error = findCorporateAccountsResponse.error;
  }
  if (searchType === 'ocns') {
    error = findOCNsResponse.error;
  }

  const getNoMoreData = (
    response: Pick<SearchResponse & InfiniteQueryFlags, 'noMoreData' | 'items'>
  ) => !!response.noMoreData && size(response.items) === 3;

  return {
    customers: transformToResults(
      take(findCustomersResponse.items, lineLimits.CUSTOMERS)
    ),
    suppliers: transformToResults(
      take(findSuppliersResponse.items, lineLimits.SUPPLIERS)
    ),
    employees: transformToResults(
      take(findEmployeesResponse.items, lineLimits.EMPLOYEES)
    ),
    customerContacts: transformToResults(
      take(findCustomerContactsResponse.items, lineLimits.CUSTOMERCONTACTS)
    ),
    motionLocations: transformToResults(
      take(findMotionLocationsResponse.items, lineLimits.MOTIONLOCATIONS)
    ),
    corporateAccounts: transformToResults(
      take(findCorporateAccountsResponse.items, lineLimits.CORPORATEACCOUNTS)
    ),
    ocns: transformToResults(take(findOCNsResponse.items, lineLimits.OCNS)),
    noMoreDataMap: {
      customers: getNoMoreData(findCustomersResponse),
      suppliers: getNoMoreData(findSuppliersResponse),
      employees: getNoMoreData(findEmployeesResponse),
      customerContacts: getNoMoreData(findCustomerContactsResponse),
      motionLocations: getNoMoreData(findMotionLocationsResponse),
      corporateAccounts: getNoMoreData(findCorporateAccountsResponse),
      ocns: getNoMoreData(findOCNsResponse),
    },
    seeMoreCustomers,
    seeMoreEmployees,
    seeMoreSuppliers,
    seeMoreCustomerContacts,
    seeMoreMotionLocations,
    seeMoreCorporateAccounts,
    seeMoreOCNs,
    resetSeeMoreLineNumbers,
    error,
    hasError: getFlagValue('hasError', true),
    showLoader: getFlagValue('showLoader', true),
    isEmptyResponse: getFlagValue('isEmptyResponse', false),
  };
};

export default useFindSearchSuggestions;
