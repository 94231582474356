import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import type { AxiosError } from 'axios';
import { get, map, size, toString } from 'lodash';
import { useIonViewDidEnter } from '@ionic/react';
import { useInfiniteQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { doConcatDataPages, doPromiseAPI, useKeyUserId } from 'api/helpers';
import type { FindFavoriteSuppliersAPIResponse } from 'models/Favorites';
import type {
  SearchItem,
  SearchResponse,
  InfiniteQueryFlags,
} from 'models/Search';
import type { RootState } from 'store/reducers';
import { pageSize } from 'utils/constants';

export const findFavoriteSuppliersQueryKey = 'favorite-suppliers';

interface UseFindFavoriteSuppliersProps {
  enabled?: boolean;
  limit?: number;
}

const useFindFavoriteSuppliers = ({
  enabled = true,
  limit = pageSize(),
}: UseFindFavoriteSuppliersProps): SearchResponse & InfiniteQueryFlags => {
  const { axios } = useAxios();
  const { findFavoriteSuppliersAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const { userInfo } = useSelector((state: RootState) => state.user);
  const userId = get(userInfo, 'userid', '');
  const params = { limit: toString(limit) };

  const doFindFavoriteSuppliers = ({ pageParam = 1 }) => {
    return doPromiseAPI<SearchResponse>(async (cancelToken) => {
      const { data } = await axios.get<FindFavoriteSuppliersAPIResponse>(
        findFavoriteSuppliersAPI(
          userId,
          toString(
            new URLSearchParams({ ...params, page: toString(pageParam) })
          )
        ),
        { cancelToken }
      );
      return {
        items: map(data, ({ supLocNo, name }) => ({
          type: 'supplier',
          id: supLocNo,
          text: name,
        })),
      };
    });
  };

  const response = useInfiniteQuery<SearchResponse, AxiosError>(
    createQueryKey(findFavoriteSuppliersQueryKey),
    doFindFavoriteSuppliers,
    {
      enabled,
      getNextPageParam: (lastPage, pages) =>
        size(lastPage.items) < limit ? false : size(pages) + 1,
    }
  );

  const {
    data,
    error,
    status,
    hasNextPage,
    isFetchingNextPage,
    refetch,
    fetchNextPage,
  } = response;

  useIonViewDidEnter(() => {
    void refetch();
  });

  const favorites = useMemo(
    () => doConcatDataPages<SearchItem, SearchResponse>(data, 'items'),
    [data]
  );

  const hasItems = size(favorites) > 0;
  const hasError = status === 'error';
  const isEmptyResponse = status === 'success' && !hasNextPage && !hasItems;
  const noMoreData = status === 'success' && !hasNextPage && hasItems;
  const showLoader = status === 'loading' || isFetchingNextPage;
  const enableInfiniteScroll = !(!hasNextPage || isFetchingNextPage);

  return {
    fetchNextPage: async () => {
      await fetchNextPage();
    },
    items: favorites,
    error,
    hasError,
    showLoader,
    isEmptyResponse,
    noMoreData,
    enableInfiniteScroll,
    refetch: async () => {
      await refetch?.call(null);
    },
  };
};

export default useFindFavoriteSuppliers;
