import React, { useEffect, useRef, useState } from 'react';
import type { getCurrentImgDataFunction } from 'react-filerobot-image-editor';
import FilerobotImageEditor, {
  TABS,
  TOOLS,
} from 'react-filerobot-image-editor';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { includes, isEmpty, set, toString } from 'lodash';
import { CameraSource } from '@capacitor/camera';
import { IonCol, IonImg, IonRow } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import { namespaces } from 'i18n/i18n.constants';
import { useToasts } from 'providers/ToastProvider';
import useGetAttachment from 'api/attachments/useGetAttachment';
import useCamera from 'hooks/useCamera';
import type { ImageUpload } from 'models/Attachment';
import type { ReportImage } from 'models/InspectionReport';
import { ToastType } from 'models/Toast';
import { transformImage } from 'utils/helpers';
import { findIcon } from 'utils/icons';
import Button from 'components/Button/Button';
import CheckBox from 'components/CheckBox/CheckBox';
import Input from 'components/Input/Input';
import Loader from 'components/Loader/Loader';
import Modal from 'components/Modal/Modal';
import classes from './ImageUploadModal.module.scss';
import './ImageEditorOverrides.css';

interface ImageUploadProps extends React.ComponentProps<typeof Modal> {
  enableCaptions?: boolean;
  // TODO should allow to sent a custom form
  enableInternalOnly?: boolean;
  disabled?: boolean;
  editable?: boolean;
  onImageAdd?: () => void;
  image?: ImageUpload | null;
  onSetImage?: (image: ImageUpload) => void;
  onRemove?: () => void;
  maxlength?: number;
  showCharCounter?: boolean;
  imageForFetching?: ReportImage | null;
  isPrimary?: boolean;
  hideButton?: boolean;
}

const ImageUploadModal = ({
  enableCaptions = false,
  enableInternalOnly = false,
  disabled,
  editable,
  onImageAdd,
  isOpen,
  setIsOpen,
  image = null,
  onSetImage,
  onRemove,
  maxlength,
  showCharCounter,
  imageForFetching,
  isPrimary,
  hideButton = false,
}: ImageUploadProps): JSX.Element => {
  const { getPhoto } = useCamera();
  const { addToast } = useToasts();
  const { t } = useTranslation(namespaces.images);
  const editedImage = useRef<getCurrentImgDataFunction>();
  const [editMode, setEditMode] = useState(false);
  const [captionForUpload, setCaptionForUpload] = useState<string>();
  const [internalOnly, setInternalOnly] = useState<string>();
  const [imageForUpload, setImageForUpload] = useState<ImageUpload | null>(
    null
  );
  const [isReset, setIsReset] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setCaptionForUpload('');
      setInternalOnly('');
    }
  }, [isOpen]);

  useEffect(() => {
    if (image && !imageForFetching?.entity && isOpen && !imageForUpload) {
      setCaptionForUpload(image?.caption || '');
      setInternalOnly(image?.internalOnly);
      setImageForUpload(image);
    }
  }, [image, imageForFetching?.entity, imageForUpload, isOpen]);

  useEffect(() => {
    if (imageForFetching && isOpen) {
      setCaptionForUpload(imageForFetching?.caption || '');
      setInternalOnly(imageForFetching?.internalOnly);
    }
  }, [imageForFetching, isOpen]);

  const takePhoto = async (source?: CameraSource) => {
    const photo = await getPhoto(source, true);

    if (photo) {
      setImageForUpload({
        ...photo,
        fileName: `camera-image-${new Date().getTime()}.${photo.format}`,
      });
      setIsOpen?.(true);
    }
  };

  const updateImageStates = () => {
    if (isReset) {
      onRemove?.();
    }
    if (imageForUpload) {
      onImageAdd?.();
      onSetImage?.({
        ...imageForUpload,
        forUpload: true,
        caption: captionForUpload,
        internalOnly,
      });
    }
  };

  const { data: attachmentData, isLoading } = useGetAttachment({
    domain: toString(imageForFetching?.entity),
    miLoc: toString(imageForFetching?.miLoc),
    ctlNo: toString(imageForFetching?.imageId),
    lineNo: toString(imageForFetching?.groupId),
    seqNo: toString(imageForFetching?.seqNo),
    fileName: toString(imageForFetching?.imagePath),
  });

  useEffect(() => {
    if (
      !isLoading &&
      attachmentData &&
      imageForFetching?.entity &&
      isOpen &&
      !imageForUpload
    ) {
      setImageForUpload(
        transformImage(
          imageForFetching,
          imageForFetching?.miLoc,
          attachmentData,
          imageForFetching?.groupId,
          isPrimary,
          imageForFetching?.reportId
        ) as ImageUpload
      );
    }
  }, [
    isLoading,
    attachmentData,
    isPrimary,
    imageForFetching,
    isOpen,
    imageForUpload,
  ]);

  const attachmentIsLoading = !!imageForFetching?.entity && isLoading;
  const [cleanupInterval, setCleanupInterval] = useState<NodeJS.Timeout>();

  useEffect(() => {
    if (editMode) {
      setCleanupInterval(
        setInterval(() => {
          if (
            document.querySelector('.FIE_tab[aria-selected="true"]')
              ?.textContent === 'Draw' &&
            !includes(
              ['Rectangle', 'Ellipse', 'Arrow'],
              document.querySelector('.FIE_tools-bar [aria-selected="true"]')
                ?.textContent
            )
          ) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            document.querySelector('.FIE_arrow-tool-button')?.click?.();
          }
        }, 500)
      );
    } else {
      clearInterval(cleanupInterval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);

  return (
    <>
      {!hideButton && (
        <Button
          variant="secondary"
          icon={findIcon('plus')}
          testid="addAttachment"
          disabled={disabled}
          className={classes.openModal}
          onClick={() => {
            void takePhoto(CameraSource.Prompt);
          }}
        />
      )}
      <Modal
        className={classes.imageUploadContainer}
        modalClassName={classes.fullWidthModal}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={t('uploadImage')}
        onClose={() => {
          setIsReset(false);
          setEditMode(false);
          setImageForUpload(null);
        }}
        forceFullHeight
        withTitleLine={false}
        testid="image-upload-modal"
        footer={
          <IonRow className={classes.buttons}>
            {editable && (
              <IonCol>
                <Button
                  variant="action"
                  text={
                    editMode
                      ? t('common:finishEditingPhoto')
                      : t('common:editPhoto')
                  }
                  testid="edit-button"
                  disabled={disabled || attachmentIsLoading}
                  className={classNames(classes.button, classes.reset)}
                  onClick={() => {
                    if (editMode) {
                      setIsReset(true);
                      let imageData;
                      let designState;
                      const img = new Image();
                      try {
                        ({ imageData, designState } =
                          editedImage?.current?.({
                            extension: imageForUpload?.format,
                            name: imageForUpload?.fileName,
                            quality: 100,
                          }) || {});
                      } catch (e) {
                        addToast({
                          type: ToastType.error,
                          text: 'There was an error processing the image. Please try again later',
                          testid: 'edit-image-error-toast',
                        });
                        Sentry.captureException(e);
                      }
                      img.crossOrigin = 'Anonymous';
                      img.src = toString(
                        includes(imageData?.imageBase64, 'data:,')
                          ? imageForUpload?.webPath
                          : imageData?.imageBase64 || imageForUpload?.webPath
                      );
                      const imgFormat = toString(
                        imageData?.extension || imageForUpload?.format
                      );
                      // release canvas memory
                      const canvas =
                        imageData?.imageCanvas as HTMLCanvasElement;
                      set(canvas, 'width', 1);
                      set(canvas, 'height', 1);
                      set(canvas, 'style', '');
                      const ctx = canvas?.getContext('2d');
                      ctx?.clearRect(0, 0, 1, 1);
                      if (
                        isEmpty(designState?.annotations) &&
                        !designState?.adjustments?.crop.height &&
                        !designState?.adjustments?.crop.width
                      ) {
                        img.src = toString(imageForUpload?.webPath);
                        setIsReset(false);
                      } else {
                        setImageForUpload({
                          format: imgFormat,
                          webPath: img.src,
                          fileName: `camera-image-${new Date().getTime()}.${imgFormat}`,
                        } as ImageUpload);
                      }
                    }
                    setEditMode((prev) => !prev);
                  }}
                />
              </IonCol>
            )}
            <IonCol>
              <Button
                variant="action"
                text={t('common:continue')}
                testid="apply-button"
                disabled={editMode || disabled || attachmentIsLoading}
                className={classNames(classes.button, classes.add)}
                onClick={() => {
                  updateImageStates();
                  setIsReset(false);
                  setEditMode(false);
                  setImageForUpload(null);
                  setIsOpen?.(false);
                }}
              />
            </IonCol>
            <IonCol>
              <Button
                variant="secondary"
                text={t('common:cancel')}
                testid="cancel-button"
                className={classNames(classes.button, classes.reset)}
                onClick={() => {
                  if (editMode) {
                    setIsReset(true);
                    let imageData;
                    try {
                      ({ imageData } =
                        editedImage?.current?.({
                          extension: imageForUpload?.format,
                          name: imageForUpload?.fileName,
                          quality: 100,
                        }) || {});
                    } catch (e) {
                      // TODO
                    }
                    // release canvas memory
                    const canvas = imageData?.imageCanvas as HTMLCanvasElement;
                    set(canvas, 'width', 1);
                    set(canvas, 'height', 1);
                    set(canvas, 'style', '');
                    const ctx = canvas?.getContext('2d');
                    ctx?.clearRect(0, 0, 1, 1);
                  }
                  setIsReset(false);
                  setEditMode(false);
                  setImageForUpload(null);
                  setIsOpen?.(false);
                }}
              />
            </IonCol>
          </IonRow>
        }
      >
        {!!enableCaptions && !editMode && (
          <IonRow className={classes.inputRow}>
            <IonCol className={classes.pb0}>
              <Input
                className={classes.captionInput}
                value={captionForUpload}
                testid="caption-input"
                disabled={disabled}
                label={t('uploadCaption')}
                setValue={(v) => setCaptionForUpload(v)}
                maxlength={maxlength}
                showCharCounter={showCharCounter}
              />
            </IonCol>
          </IonRow>
        )}
        {!!enableInternalOnly && !editMode && (
          <IonRow className={classes.inputRow}>
            <IonCol className={classes.pb0}>
              <CheckBox
                testid="internal-only-checkbox"
                label="Internal Only"
                checked={internalOnly === 'Y'}
                onChange={(b) => setInternalOnly(b ? 'Y' : 'N')}
              />
            </IonCol>
          </IonRow>
        )}
        <IonRow
          className={classNames({
            [classes.fullEditorRow]: editMode,
          })}
        >
          <IonCol class={classes.uploadCol} size="12">
            {attachmentIsLoading && <Loader isOpen text="Loading image" />}
            {!editMode &&
              !!imageForUpload &&
              (!imageForFetching?.entity || isReset) && (
                <IonImg src={imageForUpload?.webPath} />
              )}
            {!editMode &&
              imageForFetching?.entity &&
              !isReset &&
              !attachmentIsLoading && <IonImg src={attachmentData} />}
            {editMode && imageForUpload?.webPath && (
              <FilerobotImageEditor
                annotationsCommon={{
                  strokeWidth: 3,
                  stroke: '#ff0000',
                  fill: 'transparent',
                }}
                Crop={{ ratio: 'custom' }}
                source={imageForUpload?.webPath}
                getCurrentImgDataFnRef={editedImage}
                tabsIds={[TABS.ANNOTATE, TABS.ADJUST]}
                defaultTabId={TABS.ANNOTATE}
                defaultToolId={TOOLS.ARROW}
                useZoomPresetsMenu={false}
                previewPixelRatio={window.devicePixelRatio}
                savingPixelRatio={1}
                useBackendTranslations={false}
              />
            )}
          </IonCol>
        </IonRow>
      </Modal>
    </>
  );
};
export default ImageUploadModal;
