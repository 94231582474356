/* eslint-disable no-console */
import { map, size } from 'lodash';
import type { FastFindQueryParams } from 'DocumentsApp/models/FastFind';
import type { VasCode } from 'DocumentsApp/models/VasCode';
import useDocumentsDB from './initDocumentsDB';

interface UseVasCodesDBResponse {
  createVasCodes: (vasCodes: VasCode[]) => Promise<void>;
  findVasCodes: (props: FastFindQueryParams) => Promise<VasCode[]>;
  removeVasCodes: (miLoc: string) => Promise<void>;
}

const useVasCodesDB = (): UseVasCodesDBResponse => {
  const { db, openDB, closeDB, getLikeStatement, getWhereStatement } =
    useDocumentsDB();

  const createVasCodes = async (vasCodes: VasCode[]): Promise<void> => {
    try {
      await openDB();
      const statements = map(vasCodes, (vasCode) => ({
        statement: `INSERT OR REPLACE INTO vas_codes (
          UOM,
          codeDesc,
          codeValue,
          miLoc
          ) VALUES (?,?,?,?)`,
        values: [
          vasCode.UOM,
          vasCode.codeDesc,
          vasCode.codeValue,
          vasCode.miLoc,
        ],
      }));
      if (size(statements) > 0) {
        await db.executeSet(statements, true);
      }
    } catch (error) {
      console.log('Error saving vas codes to database', error);
      throw new Error('Error saving vas codes to database');
    } finally {
      await closeDB();
    }
  };

  const findVasCodes = async ({
    fastFind,
    miLoc,
  }: FastFindQueryParams): Promise<VasCode[]> => {
    try {
      const whereStatement = [];
      const vars = [];
      if (fastFind) {
        whereStatement.push(getLikeStatement(['UOM', 'codeDesc', 'codeValue']));
        const likeFastFind = `%${fastFind}%`;
        vars.push(likeFastFind, likeFastFind, likeFastFind);
      }
      if (miLoc) {
        whereStatement.push(getLikeStatement('miLoc', vars));
        vars.push(`%${miLoc}%`);
      }
      await openDB();
      return (
        await db.query(
          `SELECT * FROM vas_codes
          ${getWhereStatement(whereStatement)}`,
          vars
        )
      ).values as VasCode[];
    } catch (error) {
      console.log('Error loading vas codes', error);
      throw new Error('Error loading vas codes');
    } finally {
      await closeDB();
    }
  };

  const removeVasCodes = async (miLoc: string): Promise<void> => {
    try {
      await openDB();
      await db.query(
        `DELETE FROM vas_codes
        WHERE miLoc = ?`,
        [miLoc]
      );
    } catch (error) {
      console.log('Error removing vas codes', error);
      throw new Error('Error removing vas codes');
    } finally {
      await closeDB();
    }
  };

  return {
    createVasCodes,
    findVasCodes,
    removeVasCodes,
  };
};

export default useVasCodesDB;
