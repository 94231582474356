import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { get, lowerCase, toString } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonRow } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import VisitForm from 'ActivitiesApp/components/VisitForm/VisitForm';
import { isVisit } from 'ActivitiesApp/utils/helpers';
import { namespaces } from 'i18n/i18n.constants';
import { activitiesURL, activityDetailURL } from 'navigation';
import type { ActionCardActivity } from 'models/ActivityModels';
import type { RootState } from 'store/reducers';
import { formatCardDate } from 'utils/date';
import { findIcon } from 'utils/icons';
import { concatRoutes } from 'utils/navigations';
import Button from 'components/Button/Button';
import Text from 'components/Text/Text';
import classes from './VisitCard.module.scss';

interface VisitCardProps {
  historyId: number;
  activity: ActionCardActivity;
  testid: string;
}

const VisitCard = ({
  className,
  historyId,
  activity,
  testid,
}: VisitCardProps & IonicReactProps): JSX.Element => {
  const { userInfo } = useSelector((state: RootState) => state.user);
  const userId = get(userInfo, 'userid', '');
  const { t, i18n } = useTranslation(namespaces.activities);
  const history = useHistory();
  const isSameUser = activity.userId === userId;
  const employeeName =
    toString(activity.fieldsData?.['Employee Name']) ||
    activity.userFullName ||
    activity.userId;
  const visitEvent = isVisit(activity.extendedInfo?.visitType);

  let buttonText = t(visitEvent ? 'snapshot:viewVisit' : 'snapshot:viewCall');
  if (isSameUser) {
    buttonText = t(visitEvent ? 'snapshot:editVisit' : 'snapshot:editCall');
  }

  return (
    <div className={classNames(className, classes.card)}>
      <IonRow className={classes.container}>
        <div className={classes.header}>
          <div>
            <Text
              className={classes.aging}
              variant="title-info-card"
              text={formatCardDate(
                toString(activity.extendedInfo?.visitDateWithZimeZoneCreated),
                true,
                false,
                i18n.language
              )}
              testid={`${testid}-date`}
            />
          </div>
          <div>
            {!isSameUser && (
              <FontAwesomeIcon
                className={classes.icon}
                icon={
                  activity.fieldsData?.['Is Specialist'] === 'Y'
                    ? findIcon('wrench', 'fas')
                    : findIcon('user', 'fas')
                }
              />
            )}
            <Text
              className={classNames(classes.aging, classes.userName)}
              variant="label-header-micro"
              text={lowerCase(isSameUser ? t('you') : employeeName)}
            />
          </div>
        </div>
        <div className={classes.content}>
          <VisitForm
            historyId={historyId}
            userId={activity.userId}
            readonly
            isSameUser={isSameUser}
            activity={activity}
            activityData={{ ...activity, ...activity.extendedInfo }}
          />
          <IonRow className={classes.buttonRow}>
            <Button
              className={classes.editButton}
              onClick={() =>
                history.push(
                  concatRoutes(
                    activitiesURL(),
                    activityDetailURL(
                      activity.custMiLoc,
                      activity.custNo,
                      activity.userId,
                      toString(historyId),
                      activity.eventTagName
                    )
                  )
                )
              }
              testid="edit-button"
            >
              <Text
                text={buttonText}
                variant="content-heavy"
                className={classes.editText}
              />
            </Button>

            <div className={classes.spaceFiller} />
          </IonRow>
        </div>
      </IonRow>
    </div>
  );
};

export default VisitCard;
