import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { forEach, size, toString, toUpper } from 'lodash';
import { IonPage, IonContent, IonSearchbar } from '@ionic/react';
import useGetAgreementInformation from 'api/customer/useGetAgreementInformation';
import type { Exception } from 'models/Customer';
import type { SnapshotsURLParams } from 'models/Search';
import { getErrorMessage } from 'utils/helpers';
import { getSnapshotEyebrow } from 'utils/search';
import Header from 'components/Header/Header';
import Loader from 'components/Loader/Loader';
import Table from 'components/Table/Table';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './AgreementInformation.module.scss';

const Exceptions = (): JSX.Element => {
  const TITLE = 'EXCEPTIONS';
  const { miLoc, id } = useParams<SnapshotsURLParams>();
  const [filteredExceptions, setFilteredExceptions] = useState<Exception[]>([]);
  const [searchText, setSearchText] = useState('');
  const { customerData, agreementInformation, error, isLoading } =
    useGetAgreementInformation({ miLoc, id });
  const exceptions = agreementInformation?.exceptions;

  const tableColumns = [
    { Header: 'PRODUCT GROUP', accessor: 'productGroupDesc' },
    { Header: 'S', accessor: 'skipLockPricing' },
    { Header: 'MFG ID', accessor: 'supplierNo' },
    { Header: 'GP%', accessor: 'grossProfitPercent' },
  ];

  // TODO memoize this value, follow app patterns like favorites search
  function onInputChange(inputVal: string) {
    setSearchText(inputVal);
    const filtered: Exception[] = [];
    forEach(exceptions, (obj) => {
      forEach(Object.values(obj) as string[], (value) => {
        if (value.includes(toUpper(inputVal))) {
          filtered.push(obj);
        }
      });
    });
    setFilteredExceptions(filtered);
  }
  const { t } = useTranslation();

  return (
    <IonPage className={classes.exceptions}>
      <Header
        title={TITLE}
        eyebrow={getSnapshotEyebrow({ name: customerData?.name, miLoc, id })}
        withBackButton
        testid="exceptions"
      >
        <IonSearchbar
          placeholder="Product, Manufacturer..."
          value={searchText}
          onIonChange={(e) => onInputChange(toString(e?.detail.value))}
        />
      </Header>
      <IonContent className={classes.exceptions}>
        <Loader
          className={classes.loader}
          text="Loading Exceptions"
          isOpen={isLoading}
        />
        {!isLoading && !error && (
          <div className={classes.exceptionsTable}>
            {size(filteredExceptions) === 0 && (
              <WarningMessage
                title={t('common:noResults')}
                className={classes.warningMessage}
              />
            )}
            {size(filteredExceptions) > 0 && (
              <Table
                columns={tableColumns}
                data={
                  filteredExceptions as unknown as Record<string, unknown>[]
                }
              />
            )}
          </div>
        )}
        {error && (
          <WarningMessage
            title={getErrorMessage(error)}
            className={classes.warningMessage}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default Exceptions;
