import React, { useEffect, useState } from 'react';
import { map, toNumber } from 'lodash';
import useFindShippingCharges from 'ProductSearchApp/api/fastfind/useFindShippingCharges';
import type { ShippingCharges } from 'ProductSearchApp/models/Order';
import SelectModal from 'components/SelectModal/SelectModal';

interface ShippingChargesProps {
  selItem?: ShippingCharges;
  label?: string;
  openModal?: boolean;
  setOpenModal?: (b: boolean) => void;
  setShippingChargesCode: (o?: ShippingCharges) => void;
  triggerInput?: number;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  inputError?: string;
}

const ShippingChargesSelect = ({
  className,
  selItem,
  label = '',
  openModal = false,
  setOpenModal,
  triggerInput,
  setShippingChargesCode,
  onClose,
  placeholder,
  required,
  disabled,
  inputError,
}: ShippingChargesProps &
  // should be Omit instead
  Pick<
    React.ComponentProps<typeof SelectModal>,
    'className' | 'onClose'
  >): JSX.Element => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const { shippingCharges, error, isLoading, isEmptyResponse, fetchNextPage } =
    useFindShippingCharges({
      query: searchQuery,
    });

  const shippingChargesSelected = {
    key: selItem?.codeValue ?? '',
    title: selItem?.codeDesc ?? '',
    description: selItem?.codeDesc ?? '',
  };

  useEffect(() => {
    if (!isOpen && !openModal) {
      setSearchQuery('');
    }
  }, [isOpen, openModal, searchQuery, setSearchQuery]);

  const shippingChargesItem = map(shippingCharges, (item) => ({
    key: item.codeValue,
    title: item.codeDesc,
    description: item.codeValue,
  }));

  const inputDisplay = selItem?.codeValue
    ? `(${selItem.codeValue}) ${selItem.codeDesc}`
    : '';

  useEffect(() => {
    if (toNumber(triggerInput) > 0) {
      setIsOpen(true);
    }
  }, [triggerInput]);

  return (
    <SelectModal
      className={className}
      isOpen={setOpenModal ? openModal : isOpen}
      setIsOpen={setOpenModal || setIsOpen}
      type="shippingCharges"
      subType=""
      selItem={
        shippingChargesSelected || { title: '', description: '', key: '' }
      }
      setSelItem={(o) =>
        setShippingChargesCode(
          shippingCharges.find((i) => i.codeValue === o.key)
        )
      }
      testid="select--shippingCharges-modal"
      isEmptyResponse={!!isEmptyResponse}
      showLoader={!!isLoading}
      fetchNextPage={fetchNextPage}
      error={error}
      enableInfiniteScroll
      items={shippingChargesItem}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      onClick={() => setIsOpen(true)}
      onClose={onClose}
      label={label}
      value={inputDisplay}
      icon="caret-down"
      placeholder={placeholder}
      required={required}
      disabled={disabled}
      inputError={inputError}
    />
  );
};

export default ShippingChargesSelect;
