export const productSearchByOptions = [
  { id: 'smart', text: 'Smart Search' },
  { id: 'startsWith', text: 'Starts With' },
  { id: 'csn', text: 'CSN' },
];

export const productSelectCheckboxOptions = ['branch', 'corp', 'cust', 'sales'];

export const supplierConnectCapableTitle = 'SC';

export const sssGreenArray = [
  'M2',
  'M4',
  'M6',
  'R2',
  'R4',
  'R6',
  'P2',
  'P4',
  'P6',
  'O2',
  'O4',
  'O6',
  'RB',
];

export const productImageUrl = '/motion3/fsdb/images/item/';
