/* istanbul ignore file */

import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import type { ToastButton } from '@ionic/react';

export type ToastButtonProps = { icon?: IconProp } & Omit<ToastButton, 'icon'>;

export enum ToastType {
  default = 'default',
  info = 'info',
  alert = 'alert',
  error = 'error',
  warn = 'warn',
  success = 'success',
}
