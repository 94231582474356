export enum OrderQuoteTypeEnum {
  order = 'OrderCreated',
  largeOrder = 'LargeOrderCreated',
  orderShipped = 'OrderShipped',
  lateOrder = 'LateOrder',
  quote = 'QuoteCreated',
  largeQuote = 'LargeQuoteCreated',
}

export enum OrderQuoteIconEnum {
  largeOrder = 'sack-dollar',
  largeQuote = 'envelope-open-dollar',
  lateOrder = 'history',
}

export enum OrderQuoteColorEnum {
  largeOrder = 'color-largeOrder',
  largeQuote = 'color-largeQuote',
  lateOrder = 'color-lateOrder',
}

export enum OrderQuoteFilterEnum {
  largeQuote = 'LargeQuoteCreated',
  largeOrder = 'LargeOrderCreated',
  lateOrder = 'LateOrder',
}
