import React, { useState } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { getFileExtension, getFileNameFromURL } from 'constants/regex';
import { isNil, replace, toString } from 'lodash';
import { File } from '@awesome-cordova-plugins/file';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isPlatform } from '@ionic/react';
import { useToasts } from 'providers/ToastProvider';
import { ToastType } from 'models/Toast';
import { findIcon } from 'utils/icons';
import Button from 'components/Button/Button';
import Loader from 'components/Loader/Loader';
import Modal from 'components/Modal/Modal';
import classes from './ImageModal.module.scss';

interface ImageModalProps {
  url?: string;
  isOpen: boolean;
  title: string | undefined;
  testid: string;
  isDownloadEnabled?: boolean;
  setIsOpen: () => void;
}

const ImageModal = ({
  isOpen,
  setIsOpen,
  title,
  testid,
  url,
  isDownloadEnabled = true,
}: ImageModalProps & React.ComponentProps<typeof Modal>): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const { addToast } = useToasts();
  const isDesktop = isPlatform('mobileweb') || isPlatform('desktop');
  const getTitle = (toString(title)?.match(getFileNameFromURL) || [
    'file.png',
  ])[0];

  const savePicture = async (rawFile: string, baseName: string) => {
    setIsDownloading(true);

    const fileName = replace(
      baseName,
      getFileExtension,
      (ext) => `-${new Date().getTime()}${ext}`
    );

    const baseDirectory = isPlatform('android')
      ? `${File.externalRootDirectory}/Download/`
      : File.documentsDirectory;

    const fileBlob = await fetch(rawFile).then((response) => response.blob());

    await File.writeExistingFile(baseDirectory, fileName, fileBlob)
      .then(() => {
        addToast({
          type: ToastType.default,
          text: `File has been successfully downloaded.`,
          testid: 'download-file-toast',
        });
      })
      .catch(() => {
        addToast({
          type: ToastType.error,
          text: 'Download file operation failed. Please try again later.',
          testid: 'download-file-error-toast',
        });
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };

  return (
    <div className={classes.ImageModal} data-testid={`ImageModal-${testid}`}>
      <Modal
        className={classes.modal}
        headerClassName={classes.modalHeaderToolbar}
        isOpen={isOpen}
        withCloseButton={false}
        title={getTitle}
        withTitleLine={false}
        withTruncatedTitle
        forceFullHeight
        header={
          <div className={classes.modalHeaderIcons}>
            {isDownloadEnabled && isDesktop && !isNil(url) && (
              <a
                href={encodeURI(url)}
                download={getTitle}
                className={classes.downloadButton}
                onClick={() => {
                  setIsDownloading(true);
                  setTimeout(() => setIsDownloading(false), 300);
                }}
                data-testid={`${testid}-web-download-button`}
              >
                <FontAwesomeIcon
                  className={classes.downloadIcon}
                  icon={findIcon('download')}
                />
              </a>
            )}
            {isDownloadEnabled && !isDesktop && !isNil(url) && (
              <Button
                disabled={isDownloading}
                className={classes.downloadButton}
                icon={findIcon('download')}
                onClick={() => {
                  void (async () => {
                    await savePicture(url, getTitle);
                  })();
                }}
                testid={`${testid}-native-download-button`}
              />
            )}
            <Button
              className={classes.closeButton}
              icon={findIcon('times')}
              onClick={() => {
                setIsOpen?.call(null, false);
              }}
              testid={`${testid}-close-button`}
            />
          </div>
        }
        testid={testid}
      >
        <Loader
          className={classes.loader}
          text="Loading image"
          isOpen={isLoading}
        />
        <Loader
          className={classes.loader}
          text="Downloading image"
          isOpen={isDownloading}
        />
        {!isNil(url) && (
          <div className={classes.imageContainer}>
            <TransformWrapper centerOnInit wheel={{ step: 0.07 }}>
              <TransformComponent wrapperClass={classes.zoomWrapper}>
                <img
                  src={encodeURI(url)}
                  onLoad={() => setIsLoading(false)}
                  alt={toString(getTitle)}
                />
              </TransformComponent>
            </TransformWrapper>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ImageModal;
