import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import type { Column } from 'react-table';
import { size, toString } from 'lodash';
import { IonContent, IonPage } from '@ionic/react';
import { namespaces } from 'i18n/i18n.constants';
import useGetVendorIncreaseLetters from 'api/supplier/useGetVendorIncreaseLetters';
import type { SnapshotsURLParams } from 'models/Search';
import { DateFormatEnum, formatDate } from 'utils/date';
import { getErrorMessage } from 'utils/helpers';
import { getSnapshotEyebrow } from 'utils/search';
import Button from 'components/Button/Button';
import Header from 'components/Header/Header';
import Loader from 'components/Loader/Loader';
import Table from 'components/Table/Table';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './VendorIncreaseLetters.module.scss';

const VendorIncreaseLetters = (): JSX.Element => {
  const { id } = useParams<SnapshotsURLParams>();
  const { vendorIncreaseLetters, supplierData, error, isLoading } =
    useGetVendorIncreaseLetters({ id });
  const { t } = useTranslation(namespaces.supplier);

  const columns: Column<Record<string, unknown>>[] = [
    {
      Header: toString(t('common:Date')),
      accessor: 'effectiveDate',
      sortType: 'alphanumeric',
      Cell: ({ value }: Record<string, unknown>) => (
        <>{formatDate(toString(value), DateFormatEnum.fullDateV2)}</>
      ),
    },
    {
      Header: toString(t('common:description')),
      accessor: 'shortDescription',
      sortType: 'basic',
      Cell: ({ value }: Record<string, unknown>) => (
        <Button
          text={toString(value)}
          testid="map-button"
          className={classes.button}
        />
      ),
    },
    {
      Header: toString(t('incPercent')),
      accessor: 'increasePercentDesc',
      sortType: 'basic',
    },
  ];
  return (
    <IonPage
      className={classes.vendorIncreaseLettersContainer}
      data-testid="vendor-increase-letters-page"
    >
      <Header
        withBackButton
        eyebrow={getSnapshotEyebrow({ name: supplierData?.supLocName, id })}
        title={t('vendorIncrease')}
        testid="vendor-increase-letters-header"
      />
      <IonContent className={classes.content}>
        <Loader
          className={classes.loader}
          text={t('loadingVendorIncrease')}
          isOpen={isLoading}
        />
        {!isLoading && !error && (
          <>
            <Table
              variant="table"
              columns={columns}
              data={vendorIncreaseLetters}
            />
            {size(vendorIncreaseLetters) === 0 && (
              <WarningMessage
                className={classes.warningMessage}
                icon={['far', 'info-circle']}
                title={t('noResultsVendor')}
              />
            )}
          </>
        )}
        {error && (
          <WarningMessage
            className={classes.warningMessage}
            title={t('errorVendorIncrease')}
            body={getErrorMessage(error)}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default VendorIncreaseLetters;
