import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Dictionary } from 'lodash';
import { filter, includes, map, toString } from 'lodash';
import { IonRow } from '@ionic/react';
import ReportCell from 'ReportsApp/components/ReportCell/ReportCell';
import type { ReportField, TableData } from 'ReportsApp/models';
import classes from 'ReportsApp/pages/SalesPerformance/SalesPerformance.module.scss';
import { groupByWithNameColumn } from 'pages/Reports/DrillDown/groupOptions';
import Button from 'components/Button/Button';
import type { FilterOption } from 'components/Filter/Filter';

interface UseGetTableColumnsProps {
  selectedGroupBy: FilterOption;
  groupByDataKey: string;
  reportFields: ReportField[];
  namespace: string;
  summaryData?: unknown;
  currencyType?: string;
}

// eslint-disable-next-line import/prefer-default-export
export const useGetTableColumns = ({
  selectedGroupBy,
  groupByDataKey,
  reportFields,
  namespace,
  summaryData,
  currencyType,
}: UseGetTableColumnsProps) => {
  const { t } = useTranslation();
  const tableColumns = useMemo(
    () => [
      {
        Header: selectedGroupBy.name,
        id: includes(groupByWithNameColumn, groupByDataKey) ? 'name' : 'miLoc',
        accessor: 'data',
        sortType: 'basic',
        Cell: ({ value }: Dictionary<unknown>) => {
          const data = value as TableData;
          return (
            <IonRow className={classes.rowLink}>
              <Button
                iconClassName={classes.icon}
                text={data.title}
                href={data.href}
                rightIcon={
                  data.hideArrow ? undefined : ['far', 'chevron-right']
                }
                onClick={data.onClick}
                testid={data.testid}
                variant={data.customerLink ? 'link' : 'clear'}
              />
            </IonRow>
          );
        },
      },
      ...map(
        filter(reportFields, (field) => !field.hidden),
        (field) => ({
          Header: t(`${namespace}:${field.key}`),
          id: field.id,
          accessor: 'data',
          sortType: 'basic',
          Cell: ({ value }: Dictionary<unknown>) => {
            return (
              <ReportCell
                field={field}
                data={value}
                currencyType={currencyType}
              />
            );
          },
        })
      ),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currencyType,
      groupByDataKey,
      namespace,
      reportFields,
      selectedGroupBy.name,
    ]
  );

  const totalsRow = useMemo(
    () => [
      toString(t('common:totals')),
      ...map(
        filter(reportFields, (field) => !field.hidden),
        (field) => {
          return (
            <ReportCell
              field={field}
              data={summaryData}
              currencyType={currencyType}
            />
          );
        }
      ),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reportFields, summaryData]
  );
  return { tableColumns, totalsRow };
};
