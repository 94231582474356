import { useSelector } from 'react-redux';
import type { AxiosError } from 'axios';
import { toString } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { doPromiseAPI, useKeyUserId } from 'api/helpers';
import type { QueryFlags } from 'models/Search';
import type { WebActivityContact } from 'models/WebActivity';
import type { RootState } from 'store/reducers';

export const findWebActivityContactsQueryKey = 'customer-web-activity-contacts';

interface UseGetWebActivityContactsProps {
  miLoc: string;
  id: string;
  startDate: string;
  endDate: string;
}

interface UseGetWebActivityContactsResponse {
  contacts?: WebActivityContact[];
}

const useGetWebActivityContacts = ({
  miLoc: itemMiLoc,
  id,
  startDate,
  endDate,
}: UseGetWebActivityContactsProps): UseGetWebActivityContactsResponse &
  QueryFlags => {
  const { axios } = useAxios();
  const { findWebActivityContactsAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const { miLoc: stateMiLoc = '' } = useSelector(
    (state: RootState) => state.user
  );
  const miLoc = itemMiLoc || stateMiLoc;
  const params: Record<string, string> = {
    startDate,
    endDate,
  };

  const doGetWebActivityContacts = () =>
    doPromiseAPI<WebActivityContact[]>(async (cancelToken) => {
      const { data } = await axios.get<WebActivityContact[]>(
        findWebActivityContactsAPI(
          miLoc,
          id,
          toString(
            new URLSearchParams({
              ...params,
            })
          )
        ),
        { cancelToken }
      );

      return data;
    });

  const response = useQuery<WebActivityContact[], AxiosError>(
    createQueryKey(findWebActivityContactsQueryKey, {
      miLoc,
      id,
      ...params,
    }),
    doGetWebActivityContacts
  );

  const { data, error, isLoading, refetch } = response;

  return {
    contacts: data,
    error,
    isLoading,
    refetch: async () => {
      await refetch();
    },
  };
};

export default useGetWebActivityContacts;
