import { useMemo } from 'react';
import type { AxiosError } from 'axios';
import type { Dictionary } from 'lodash';
import { isEmpty, isNil, map, size, toString } from 'lodash';
import { useIonViewWillEnter } from '@ionic/react';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import useCountPlanDB from 'InventoryApp/database/useCountPlanDB';
import type { CountGroupItem } from 'InventoryApp/models/InventoryPlanGroup';
import {
  doConcatDataPages,
  doGetIsLoading,
  doPromiseAPI,
  useKeyUserId,
} from 'api/helpers';
import type { InfiniteQueryFlags } from 'models/Search';

export const findCountGroupItemsQueryKey = 'count-group-items';
export const findCountGroupTotalItemsQueryKey = 'count-group-total-items';

interface UseFindCountGroupItemsProps {
  countPlanId: string;
  groupId: string;
  query?: string;
  hasCount?: boolean;
  sortField?: string;
  sortDir?: string;
}

interface UseFindCountGroupItemsResponse {
  total?: number;
  items?: CountGroupItem[];
  findBarcodeItems?: (barcode?: string) => Promise<CountGroupItem[]>;
}

const useFindCountGroupItems = ({
  countPlanId,
  groupId,
  query = '',
  hasCount,
  sortField = '',
  sortDir = '',
}: UseFindCountGroupItemsProps): InfiniteQueryFlags &
  UseFindCountGroupItemsResponse => {
  const { createQueryKey } = useKeyUserId();
  const { findItemsByGroup, findTotalItemsByGroup } = useCountPlanDB();
  const enabled = !isEmpty(countPlanId) && !isEmpty(groupId);
  const params: Dictionary<string> = {
    countPlanId,
    groupId,
    query,
    sortField,
    sortDir,
  };
  if (!isNil(hasCount)) {
    params.hasCount = toString(hasCount);
  }

  const findBarcodeItems = async (barcode = '') => {
    let items: CountGroupItem[] = [];
    if (barcode) {
      items = await findItemsByGroup(countPlanId, groupId, barcode, true);
    }
    return items;
  };

  const doFindItems = () => {
    return doPromiseAPI<CountGroupItem[]>(async () => {
      const items = await findItemsByGroup(
        countPlanId,
        groupId,
        query,
        false,
        hasCount,
        sortField,
        sortDir
      );
      return map(items, (item) => ({
        ...item,
        hasCount: !!item.hasCount,
        hasEditedMinMax: !!item.hasEditedMinMax,
        allowEdit: !!item.allowEdit,
        hasLocalCount: !!item.hasLocalCount,
      }));
    });
  };

  const response = useInfiniteQuery<CountGroupItem[], AxiosError>(
    createQueryKey(findCountGroupItemsQueryKey, params),
    doFindItems,
    { networkMode: 'always', enabled }
  );

  const doFindTotal = () => {
    return doPromiseAPI<number>(async () => {
      const total = await findTotalItemsByGroup(countPlanId, groupId, hasCount);
      return total;
    });
  };

  const totalParams = { ...params };
  delete totalParams.query;
  const { data: total, refetch: refetchTotal } = useQuery<number, AxiosError>(
    createQueryKey(findCountGroupTotalItemsQueryKey, totalParams),
    doFindTotal,
    { networkMode: 'always', enabled }
  );

  const {
    data,
    status,
    error,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    refetch,
    fetchNextPage,
  } = response;

  const items = useMemo(() => doConcatDataPages<CountGroupItem>(data), [data]);
  const hasItems = size(items) > 0;
  const isEmptyResponse = status === 'success' && !hasNextPage && !hasItems;
  const noMoreData = status === 'success' && !hasNextPage && hasItems;
  const showLoader = doGetIsLoading(response) || isFetchingNextPage;
  const enableInfiniteScroll = !(!hasNextPage || isFetchingNextPage);

  useIonViewWillEnter(() => {
    if (enabled) {
      void refetch();
      void refetchTotal();
    }
  });

  return {
    fetchNextPage: async () => {
      await fetchNextPage();
    },
    refetch: async () => {
      await refetch();
      await refetchTotal();
    },
    findBarcodeItems,
    items,
    total: total || 0,
    error,
    showLoader,
    isFetching,
    isEmptyResponse,
    noMoreData,
    enableInfiniteScroll,
  };
};

export default useFindCountGroupItems;
