import React, { useEffect, useState } from 'react';
import { map, toNumber } from 'lodash';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import useFindLostSales from 'ProductSearchApp/api/fastfind/useFindLostSale';
import type { SelectModalItem } from 'models/Search';
import SelectModal from 'components/SelectModal/SelectModal';

interface LostSaleSelectProps {
  selItem?: SelectModalItem;
  className?: string;
  label?: string;
  disabled?: boolean;
  iconClassName?: string;
  placeholder?: string;
  showInput?: boolean;
  openModal?: boolean;
  setOpenModal?: (b: boolean) => void;
  setLostSalesCode: (o: SelectModalItem) => void;
  triggerInput?: number;
}

const LostSaleSelect = ({
  selItem,
  className,
  label = '',
  disabled,
  iconClassName,
  placeholder,
  showInput = true,
  openModal = false,
  setOpenModal,
  triggerInput,
  setLostSalesCode,
}: LostSaleSelectProps & IonicReactProps): JSX.Element => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const { lostSales, error, isLoading, isEmptyResponse, fetchNextPage } =
    useFindLostSales({
      query: searchQuery,
    });

  useEffect(() => {
    if (!isOpen && !openModal) {
      setSearchQuery('');
    }
  }, [isOpen, openModal, searchQuery, setSearchQuery]);

  const inputDisplay = selItem?.key ? `(${selItem.key}) ${selItem.title}` : '';
  const lostSalesItems = map(lostSales, (item) => ({
    key: item.codeValue,
    title: item.codeDesc,
    description: item.codeValue,
  }));

  useEffect(() => {
    if (toNumber(triggerInput) > 0) {
      setIsOpen(true);
    }
  }, [triggerInput]);

  return (
    <SelectModal
      isOpen={setOpenModal ? openModal : isOpen}
      setIsOpen={setOpenModal || setIsOpen}
      type="lostSale"
      subType=""
      selItem={selItem || { title: '', description: '', key: '' }}
      setSelItem={(o) => setLostSalesCode(o)}
      testid="select-lostSales-modal"
      isEmptyResponse={!!isEmptyResponse}
      showLoader={!!isLoading}
      fetchNextPage={fetchNextPage}
      error={error}
      enableInfiniteScroll
      items={lostSalesItems}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      onClick={() => setIsOpen(true)}
      label={label}
      value={inputDisplay}
      placeholder={placeholder}
      disabled={disabled}
      iconClassName={iconClassName}
      showInput={showInput}
      className={className}
      icon={selItem?.key ? 'caret-down' : 'search'}
    />
  );
};

export default LostSaleSelect;
