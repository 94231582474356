import React from 'react';
import { useTranslation } from 'react-i18next';
import { IonCol, IonRow } from '@ionic/react';
import { FormikInput } from 'common/components/Forms/Input/Input';
import { getUnixTime } from 'date-fns';
import { useFormikContext } from 'formik';
import UomTypeSelect from 'ProductSearchApp/components/FastFind/UomTypeSelect';
import { qtyScaleProps } from 'ProductSearchApp/pages/EditOrderLine/EditOrderLineSchema';
import { DateFormatEnum, formatDate, parseDate } from 'utils/date';
import DateInput from 'components/DateInput/DateInput';
import Text from 'components/Text/Text';
import classes from './AddZCodedItem.module.scss';
import type { AddZCodedItemForm } from './AddZCodedItemSchema';

interface DetailsFormProps {
  isLoading?: boolean;
}

const DetailsForm = ({ isLoading }: DetailsFormProps): JSX.Element => {
  const { t } = useTranslation();

  const { values, setFieldValue } = useFormikContext<AddZCodedItemForm>();

  return (
    <div className={classes.formContent} data-testid="details-form">
      <Text
        text={t(`productSearch:zCodedItem:detailsFormLabel`)}
        variant="mipro-h3-headline"
      />
      <FormikInput
        className={classes.input}
        label={t(`productSearch:zCodedItem:csnInput`)}
        name="custStockNo"
        disabled={isLoading}
        toUpperCase
        testid="csn-input"
      />
      <FormikInput
        className={classes.input}
        label={t(`productSearch:zCodedItem:extendedDescriptionInput`)}
        name="custComment"
        maxlength={40}
        disabled={isLoading}
        toUpperCase
        testid="description-input"
      />
      <IonRow className={classes.formRow}>
        <IonCol>
          <FormikInput
            label={t(`productSearch:zCodedItem:quantityInput`)}
            name="quantity"
            disabled={isLoading}
            numberMask={qtyScaleProps}
            testid="quantity-input"
          />
        </IonCol>
        <IonCol>
          <UomTypeSelect
            label={t(`productSearch:zCodedItem:uomSelect`)}
            modalTitle={t(`productSearch:zCodedItem:uomSelectModalTitle`)}
            selItem={values.salesUom}
            setUomTypeCode={(item) => {
              setFieldValue('salesUom', item.key ? item : '');
            }}
            disabled={isLoading}
          />
        </IonCol>
      </IonRow>
      <FormikInput
        className={classes.input}
        label={t(`productSearch:zCodedItem:costInput`)}
        name="unitCost"
        currencyMask
        disabled={isLoading}
        testid="cost-input"
      />
      <DateInput
        className={classes.dateInput}
        name="promsdDelivDt"
        value={getUnixTime(parseDate(values.promsdDelivDt))}
        setValue={(date) => {
          setFieldValue(
            'promsdDelivDt',
            formatDate(date, DateFormatEnum.standardDate)
          );
        }}
        minDate={new Date()}
        disabled={isLoading}
        label={t(`productSearch:zCodedItem:expectedDateInput`)}
        testid="expected-date-input"
      />
    </div>
  );
};

export default DetailsForm;
