import React from 'react';
import classes from './SalesPlayAccepted.module.scss';

type SalesPlayAcceptedProps = {
  visible: boolean;
};

const SalesPlayAccepted: React.FC<SalesPlayAcceptedProps> = ({
  visible,
}: SalesPlayAcceptedProps) => (
  <>
    <div
      className={classes.salesPlayAcceptedOverlay}
      style={{ visibility: visible ? 'visible' : 'hidden' }}
    >
      <h3>Sales Play Completed!</h3>
    </div>
  </>
);

export default SalesPlayAccepted;
