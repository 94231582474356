import React from 'react';
import type { TemplateGroup } from 'models/DocumentTemplate';
import Text from 'components/Text/Text';
import classes from './DocumentParagraph.module.scss';

export interface DocumentParagraphProps {
  group: TemplateGroup;
}

const DocumentParagraph = ({ group }: DocumentParagraphProps): JSX.Element => (
  <div className={classes.DocumentParagraph} data-testid="DocumentParagraph">
    <Text text={group.title || ''} variant="content-heavy" />
  </div>
);

export default DocumentParagraph;
