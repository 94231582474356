import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import type { Dictionary } from 'lodash';
import { filter, isEmpty } from 'lodash';
import { IonCol, IonContent, IonPage, IonRow } from '@ionic/react';
import { useTransition, animated, config } from '@react-spring/web';
import Header from 'common/components/Header/Header';
import { namespaces } from 'i18n/i18n.constants';
import useFindSalesPlays from 'api/salesPlays/useFindSalesPlays';
import type { SalesPlay } from 'models/SalesPlays';
import { SalesPlaysStatus } from 'models/SalesPlays';
import { formatDate } from 'utils/date';
import { getErrorMessage } from 'utils/helpers';
import Loader from 'components/Loader/Loader';
import Refresher from 'components/Refresher/Refresher';
import SalesPlaysCard from 'components/SalesPlaysCard/SalesPlaysCard';
import Text from 'components/Text/Text';
import TitleLine from 'components/TitleLine/TitleLine';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './SalesPlays.module.scss';

const SalesPlays = (): JSX.Element => {
  const [atTop] = useState<boolean>(true);

  const {
    salesPlays,
    hasError,
    error,
    showLoader,
    refetch: findSalesPlaysRefetch,
  } = useFindSalesPlays({
    filterStatus: 'NEW',
    filterType: '',
    limit: 4,
    topSalesPlays: true,
  });

  const history = useHistory();
  const { url } = useRouteMatch();

  const { t } = useTranslation(namespaces.salesPlays);

  const salesPlaysToShow: SalesPlay[] = useMemo(
    () => filter(salesPlays, (sp) => sp.status === SalesPlaysStatus.BLANK),
    [salesPlays]
  );

  const countCompletedSalesPlays = useMemo(
    () => filter(salesPlays, (s) => s.status !== SalesPlaysStatus.BLANK).length,
    [salesPlays]
  );

  const [displaySizes, setDisplaySizes] = useState<Dictionary<number>>({});
  const setDisplaySize = useCallback(
    (_id: string, height: number) => {
      setDisplaySizes((prevState: Dictionary<number>) => ({
        ...prevState,
        [_id]: height,
      }));
    },
    [setDisplaySizes]
  );

  const transitions = useTransition(salesPlaysToShow, {
    config: () => ({
      ...config.default,
      clamp: true,
    }),
    from: () => ({
      x: 0,
      y: 1000,
      opacity: 0,
    }),
    enter: (play) => ({
      x: 0,
      y: 0,
      opacity: 1,
      height: displaySizes[play.externalId],
    }),
    update: (play) => ({ height: displaySizes[play.externalId] }),
    leave: () => async (next) => {
      await next({
        opacity: 0,
        height: 0,
        transform: 'translate3d(25%, 0px, 0px)',
        x: 200,
        config: { duration: 750 },
      });
    },

    trail: 100,
    keys: (play) => play.externalId,
  });

  const getCompletedText = `${countCompletedSalesPlays}/4 ${t('completed')}`;

  return (
    <IonPage data-testid="sales-plays-and-leads-page">
      <Header
        testid="sales-plays"
        title={t('salesPlayTitle')}
        hideBackButton
        showLocationText
      />
      <IonContent>
        <Refresher
          slot="fixed"
          hidden
          onRefresh={findSalesPlaysRefetch}
          testid="sales-plays-refresher"
          disabled={showLoader || !atTop}
        />
        <IonRow className={classes.topStatus}>
          <Text
            variant="underlined-title-section"
            text={t('todayTop4')}
            testid="today-top-4"
          />
        </IonRow>
        <TitleLine className={classes.titleLine} />
        <IonRow className={classes.topStatus}>
          <IonCol>{formatDate(new Date())}</IonCol>
          {!isEmpty(salesPlays) && (
            <IonCol className="ion-text-end">{getCompletedText}</IonCol>
          )}
        </IonRow>
        {showLoader && (
          <Loader
            className={classes.loader}
            text={t('loadingSalesPlays')}
            isOpen={showLoader}
            testid="loading-sales-plays"
          />
        )}
        {!hasError && !isEmpty(salesPlaysToShow) && (
          <div>
            {transitions((style, item) => (
              <animated.div style={style}>
                <SalesPlaysCard
                  setDisplaySize={setDisplaySize}
                  salesPlay={item}
                  testid="sales-plays-card"
                  url={url}
                  history={history}
                />
              </animated.div>
            ))}
          </div>
        )}
        {!hasError &&
          !showLoader &&
          isEmpty(salesPlaysToShow) &&
          !isEmpty(salesPlays) && (
            <WarningMessage
              className={classes.salePlaysCompleted}
              icon={['far', 'check-circle']}
              title={t('salesPlaysCompleted')}
            />
          )}
        {!hasError && !showLoader && isEmpty(salesPlays) && (
          <WarningMessage
            className={classes.noSalesPlays}
            icon={['far', 'badge-dollar']}
            title={t('noSalesToDisplay')}
          />
        )}
        {hasError && (
          <WarningMessage
            className={classes.warningMessage}
            title={t('errorLoadingSalesPlays')}
            body={getErrorMessage(error)}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default SalesPlays;
