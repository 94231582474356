import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useRouteMatch } from 'react-router-dom';
import { IonPage, IonContent } from '@ionic/react';
import { namespaces } from 'i18n/i18n.constants';
import { supplierInventoryAnalysisURL } from 'navigation';
import useGetSupplier from 'api/supplier/useGetSupplier';
import type { SnapshotsURLParams } from 'models/Search';
import { getErrorMessage, stringValue } from 'utils/helpers';
import { concatRoutes } from 'utils/navigations';
import { getSnapshotEyebrow } from 'utils/search';
import ActionRow from 'components/ActionRow/ActionRow';
import Address from 'components/Contacts/Address/Address';
import PhoneNumber from 'components/Contacts/PhoneNumber/PhoneNumber';
import Header from 'components/Header/Header';
import Loader from 'components/Loader/Loader';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './SupplierInformation.module.scss';

const SupplierInformation = (): JSX.Element => {
  const { url } = useRouteMatch();
  const { id } = useParams<SnapshotsURLParams>();
  const { t } = useTranslation(namespaces.supplier);
  const {
    data: supplierData,
    error,
    isLoading,
  } = useGetSupplier({
    searchType: 'supplier',
    id,
  });

  const phoneNumber = supplierData?.contact?.phone1;

  return (
    <IonPage>
      <Header
        title={t('snapshot:supplierInfo')}
        eyebrow={getSnapshotEyebrow({ name: supplierData?.supLocName, id })}
        withBackButton
        testid="supplierInformation"
      />
      <IonContent className={classes.supInfo}>
        <Loader
          className={classes.loader}
          text={t('loadingSupplierInfo')}
          isOpen={isLoading}
        />
        {!isLoading && !error && (
          <>
            <div className={classes.infoWrapper}>
              <Text text={t('apSupplier')} variant="content-heavy" />
              <Text
                text={stringValue(supplierData, 'supLocNo', '--')}
                variant="content-default"
              />
            </div>
            <div className={classes.infoWrapper}>
              <Text text={t('poMinimum')} variant="content-heavy" />
              <Text
                text={stringValue(supplierData, 'poDollarMin', '--')}
                variant="content-default"
              />
            </div>
            <div className={classes.infoWrapper}>
              <Text text={t('supplierConnect')} variant="content-heavy" />
              <Text
                text={
                  stringValue(supplierData, 'supConCapable', '--') === 'Y'
                    ? t('common:yes')
                    : t('common:no')
                }
                variant="content-default"
              />
            </div>
            <div className={classes.infoWrapper}>
              <Text text={t('common:phone')} variant="content-heavy" />
              {phoneNumber ? (
                <div>
                  <PhoneNumber phoneNumber={phoneNumber} testid="call-button" />
                </div>
              ) : (
                <Text text="--" variant="content-default" />
              )}
            </div>
            <div className={classes.infoWrapper}>
              <Text text={t('common:address')} variant="content-heavy" />
              {supplierData?.address ? (
                <Address address={supplierData?.address} />
              ) : (
                <Text text="--" variant="content-default" />
              )}
            </div>
            <div className={classes.infoWrapper}>
              <Text text={t('manufacturerNo')} variant="content-heavy" />
              <Text
                text={stringValue(supplierData, 'mfrCtlNo', '--')}
                variant="content-default"
              />
            </div>
            <div className={classes.infoWrapper}>
              <Text text={t('common:name')} variant="content-heavy" />
              <Text
                text={stringValue(supplierData, 'supLocName', '--')}
                variant="content-default"
              />
            </div>
            <div className={classes.infoWrapper}>
              <Text text={t('enterpriseNo')} variant="content-heavy" />
              <Text
                text={stringValue(supplierData, 'enterpriseNo', '--')}
                variant="content-default"
              />
            </div>
            <div className={classes.infoWrapper}>
              <Text text={t('enterpriseName')} variant="content-heavy" />
              <Text
                text={stringValue(supplierData, 'enterpriseName', '--')}
                variant="content-default"
              />
            </div>
            <div className={classes.infoWrapper}>
              <Text text={t('supplierConnect')} variant="content-heavy" />
              <Text
                text={supplierData?.mfrSupplierConnect ? 'Yes' : 'No'}
                variant="content-default"
              />
            </div>
            <ActionRow
              className={classes.actionRow}
              text={t('inventoryAnalysis')}
              href={concatRoutes(url, supplierInventoryAnalysisURL())}
              testid="inventory-analysis-link"
            />
          </>
        )}
        {error && (
          <WarningMessage
            className={classes.warningMessage}
            title={t('errorSupplierInfo')}
            body={getErrorMessage(error)}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default SupplierInformation;
