import { isNil, map, toString } from 'lodash';
import type { MutationStatus } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import {
  onSuccessMutation,
  doPromiseAPI,
  onErrorUpdate,
  onMutateUpdate,
} from 'api/helpers';
import type { UpdateMutationContext } from 'api/helpers';
import { unreadQueryKey } from 'api/user/useGetUnreadNotificationsCount';
import usePushNotifications from 'hooks/usePushNotifications';
import type {
  ActionCardActivity,
  UpdateActivityBody,
} from 'models/ActivityModels';
import { ToastType } from 'models/Toast';
import { findActivitiesQueryKey } from './useFindActivities';
import { findActivitiesV2QueryKey } from './useFindActivitiesV2';

interface UseUpdateActivityResponse {
  status: MutationStatus;
  onUpdateActivities: (body: UpdateActivityBody) => void;
}

export const getUpdatedFlags = ({
  showAfter,
  done,
  followUp,
}: UpdateActivityBody): Record<string, string> => {
  const requestBody: Record<string, string> = {};
  if (!isNil(done)) {
    requestBody.done = done ? 'Y' : 'N';
  }
  if (showAfter) {
    requestBody.showAfter = showAfter;
  }
  if (!isNil(followUp)) {
    requestBody.followUp = followUp ? 'Y' : 'N';
  }
  return requestBody;
};

const useUpdateActivities = (): UseUpdateActivityResponse => {
  const { axios } = useAxios();
  const { updateActivitiesAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const { removeDeliveredNotifications } = usePushNotifications();

  const doUpdateActivities = (body: UpdateActivityBody) => {
    if (body.historyIds?.length === 0) {
      return new Promise<void>((resolve) => {
        resolve();
      });
    }
    return doPromiseAPI(async (cancelToken) => {
      await axios.put(
        updateActivitiesAPI(),
        { ...getUpdatedFlags({ ...body }), historyIds: body.historyIds },
        {
          cancelToken,
        }
      );
    });
  };

  const { status, mutate } = useMutation(doUpdateActivities, {
    onMutate: async (vars) => {
      const { done, historyIds } = vars;
      const updatedItems = map(historyIds, (historyId) => {
        return { done, historyId } as unknown as ActionCardActivity;
      });
      const findPredicates = map(historyIds, (historyId) => {
        return { historyId };
      });

      const activitiesContext = await onMutateUpdate<ActionCardActivity>({
        queryClient,
        queryKey: findActivitiesQueryKey,
        updatedItems,
        findPredicates,
        // TODO fix optimisticUI updates with new react version
        // sortPredicate: true,
        // filterPredicate: activitiesFilterPredicate,
        isInfiniteQuery: true,
      });

      const myVisitsContext = await onMutateUpdate<ActionCardActivity>({
        queryClient,
        queryKey: findActivitiesV2QueryKey,
        updatedItems,
        findPredicates,
        // TUDU fix optimisticUI updates with new react version
        // sortPredicate: true,
        // filterPredicate: activitiesFilterPredicate,
        isInfiniteQuery: true,
      });

      return { activitiesContext, myVisitsContext };
    },

    onSuccess: (_data, vars) => {
      setTimeout(() => {
        // invalidate unread when an activity is updated
        void onSuccessMutation(queryClient, unreadQueryKey);

        // remove delivered notifications
        const { historyIds } = vars;
        map(historyIds, (historyId) => {
          void removeDeliveredNotifications(toString(historyId), 'historyId');
        });
      }, 1000);
    },
    onError: (_error, _vars, context) => {
      addToast({
        type: ToastType.error,
        text: 'Update activity operation failed. Please try again later.',
        testid: 'update-activity-error-toast',
      });
      onErrorUpdate<ActionCardActivity>({
        queryClient,
        context:
          context?.activitiesContext as UpdateMutationContext<ActionCardActivity>[],
        // sortPredicate: true,
        isInfiniteQuery: true,
      });

      onErrorUpdate<ActionCardActivity>({
        queryClient,
        context:
          context?.myVisitsContext as UpdateMutationContext<ActionCardActivity>[],
        // sortPredicate: true,
        isInfiniteQuery: true,
      });
    },
  });

  return {
    status,
    onUpdateActivities: (body: UpdateActivityBody) => mutate(body),
  };
};

export default useUpdateActivities;
