import { useSelector } from 'react-redux';
import type { AxiosError } from 'axios';
import { isEmpty, toString } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { findBulletinsAPI } from 'api';
import { formatISO, startOfDay, subDays } from 'date-fns';
import { useAxios } from 'providers/AxiosProvider';
import { doPromiseAPI, useKeyUserId } from 'api/helpers';
import type { Bulletin } from 'models/Bulletin';
import type { QueryFlags } from 'models/Search';
import type { RootState } from 'store/reducers';

export const findSlidesBulletinsQueryKey = 'slide-bulletins';

interface UseFindSlidesBulletinsResponse {
  data?: Bulletin[];
  findSlidesBulletins: () => Promise<void>;
}

const useFindSlidesBulletins = (): UseFindSlidesBulletinsResponse &
  QueryFlags => {
  const { axios } = useAxios();
  const { createQueryKey } = useKeyUserId();
  const { bulletinDismissDate = subDays(startOfDay(new Date()), 300) } =
    useSelector((state: RootState) => state.user);
  const after = formatISO(new Date(bulletinDismissDate));
  const bulletinsHeader =
    process.env.REACT_APP_FEATURE_BULLETINS === 'true'
      ? {
          headers: {
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            Authorization: `Bearer ${process.env.REACT_APP_BULLETINS_SECRET}`,
          },
        }
      : {};

  const doFindSlidesBulletins = () => {
    return doPromiseAPI<Bulletin[]>(async (cancelToken) => {
      const paramsGET = new URLSearchParams();
      paramsGET.append('per_page', '5');
      paramsGET.append('_fields[]', 'title');
      paramsGET.append('_fields[]', 'excerpt');
      paramsGET.append('_fields[]', 'content');
      paramsGET.append('_fields[]', 'image');
      paramsGET.append('_fields[]', 'acf');
      paramsGET.append('after', after);

      const { data } = await axios.get<Bulletin[]>(
        findBulletinsAPI(toString(paramsGET)),
        {
          cancelToken,
          ...bulletinsHeader,
        }
      );
      return data;
    });
  };

  const { data, status, error, isFetching, refetch } = useQuery<
    Bulletin[],
    AxiosError
  >(
    createQueryKey(findSlidesBulletinsQueryKey, { after }),
    doFindSlidesBulletins,
    {
      enabled: process.env.REACT_APP_FEATURE_BULLETINS === 'true',
      staleTime: 2 * 60 * 1000,
      refetchOnWindowFocus: true,
      refetchInterval: 15 * 60 * 1000,
    }
  );

  return {
    data,
    error,
    isLoading: status === 'loading' || (isFetching && isEmpty(data)),
    findSlidesBulletins: async () => {
      await refetch();
    },
  };
};

export default useFindSlidesBulletins;
