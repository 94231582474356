import { useSelector } from 'react-redux';
import type { AxiosError } from 'axios';
import { get, isEmpty, isNil } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { doPromiseAPI, useKeyUserId } from 'api/helpers';
import type { QueryFlags } from 'models/Search';
import type { UserConfigs } from 'models/UserConfig';
import type { RootState } from 'store/reducers';

export const userConfigQueryKey = 'user-config';

interface UseGetReportConfigsResponse {
  data?: UserConfigs;
}

interface UseGetUserConfigProps {
  configType: 'reports' | 'currency';
  enabled: boolean;
}

const useGetUserConfig = ({
  configType,
  enabled,
}: UseGetUserConfigProps): UseGetReportConfigsResponse & QueryFlags => {
  const { axios } = useAxios();
  const { configTypeAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const { userInfo } = useSelector((state: RootState) => state.user);
  const userId = get(userInfo, 'userid', '');

  const doGetUserConfigs = () => {
    return doPromiseAPI<UserConfigs>(async (cancelToken) => {
      const { data } = await axios.get<UserConfigs>(configTypeAPI(configType), {
        cancelToken,
      });
      return data;
    });
  };

  const { data, error, status, isFetching, refetch } = useQuery<
    UserConfigs,
    AxiosError
  >(createQueryKey(userConfigQueryKey, { configType }), doGetUserConfigs, {
    enabled: !isEmpty(userId) && enabled,
  });

  return {
    data,
    error,
    isLoading: status === 'loading' || (isFetching && isNil(data)),
    refetch: async () => {
      await refetch();
    },
  };
};

export default useGetUserConfig;
