import React from 'react';
import { useSelector } from 'react-redux';
import { get, trim } from 'lodash';
import useGetAvatar from 'api/user/useGetAvatar';
import type { RootState } from 'store/reducers';
import AvatarCard from './AvatarCard';

const Avatar = ({ className }: { className?: string }): JSX.Element => {
  const { userInfo } = useSelector((state: RootState) => state.user);
  const firstName = get(userInfo, 'givenname', '');
  const lastName = get(userInfo, 'sn', '');
  const { data } = useGetAvatar();
  return (
    <AvatarCard
      className={className}
      data={data}
      displayName={trim(`${firstName} ${lastName}`)}
    />
  );
};

export default Avatar;
