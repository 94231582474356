import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { map } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonRow } from '@ionic/react';
import { and, ifRender } from 'common/utils/logicHelpers';
import useGetAvailableCSHLocations from 'CostSavingsApp/hooks/useGetAvailableCSHLocations';
import { costSavingsURL, searchURL } from 'navigation';
import useGetCostSavingsReport from 'ReportsApp/api/useGetCostSavingsReport';
import { goToCostSavingsReport } from 'ReportsApp/navigation/navigationHelpers';
import { useGetSelectedMiLoc } from 'api/helpers';
import {
  AccessControlType,
  useHasAccessControls,
} from 'hooks/useAccessControls';
import type { RoleGroupType, ViewAsRoleType } from 'models/Reports';
import type { SortOption } from 'models/Sort';
import type { RootState } from 'store/reducers';
import { findIcon } from 'utils/icons';
import { concatRoutes } from 'utils/navigations';
import { getUserRole } from 'utils/reports';
import Button from 'components/Button/Button';
import DropDown from 'components/DropDown/DropDown';
import HelpModal from 'components/HelpModal/HelpModal';
import Text from 'components/Text/Text';
import 'swiper/css';
import 'swiper/css/pagination';
import '@ionic/react/css/ionic-swiper.css';
import CostSavingsCard from './CostSavingsCard';
import classes from './CostSavingsHealth.module.scss';

const createCSHRequestParams = (
  fromVirtualTeam: boolean,
  team: boolean,
  selectedMiLoc: string,
  role: string,
  isSingleCSHExecLocation: boolean,
  selectedCSHExecLocationKey: string,
  isExecSelected: boolean
) => {
  let miLoc;
  let groupBy: RoleGroupType;
  let viewAsRole: ViewAsRoleType;

  if (isExecSelected) {
    miLoc = selectedCSHExecLocationKey;
    groupBy = 'CORP';
    viewAsRole = 'LOC';
  } else {
    miLoc = fromVirtualTeam || team ? undefined : selectedMiLoc;
    groupBy =
      fromVirtualTeam || team
        ? ('BRCH' as RoleGroupType)
        : (role as RoleGroupType);
    viewAsRole = fromVirtualTeam || team ? 'CUST' : 'LOC';
  }

  return {
    miLoc,
    groupBy,
    viewAsRole,
    sendVirtualTeamId: true,
  };
};

const CostSavingsHealthCard = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const { locationTree = {}, isCamUser } = useSelector(
    (state: RootState) => state.user
  );
  const { selectedMiLoc, fromVirtualTeam, team } = useGetSelectedMiLoc();
  let role = locationTree[selectedMiLoc.miLoc]?.userRole;
  const [isOpenInfoModal, setIsOpenInfoModal] = useState<boolean>(false);
  role = getUserRole(role);

  const { isSingleCSHExecLocation, isExecSelected, filterOptions } =
    useGetAvailableCSHLocations();

  const [selectedCSHExecLocation, setSelectedCSHExecLocation] =
    useState<SortOption>(filterOptions[0]);

  const reportParams = createCSHRequestParams(
    fromVirtualTeam,
    team,
    selectedMiLoc.miLoc,
    role,
    isSingleCSHExecLocation,
    selectedCSHExecLocation?.key,
    isExecSelected
  );

  const guaranteedResponse = useGetCostSavingsReport({
    ...reportParams,
    guaranteed: 'Y',
    enabled: true,
  });

  const goalResponse = useGetCostSavingsReport({
    ...reportParams,
    guaranteed: 'N',
    enabled: true,
  });

  const cards = [
    {
      key: 'guaranteed',
      id: `guaranteed-${selectedMiLoc.miLoc}`,
      title: t('common:costSavingsGuaranteed'),
      data: guaranteedResponse,
    },
    {
      key: 'goal',
      id: `goal-${selectedMiLoc.miLoc}`,
      title: t('common:costSavingsGoal'),
      data: goalResponse,
    },
  ];

  const { hasAccessControl } = useHasAccessControls();
  const costSavingsEntry = hasAccessControl(
    AccessControlType.AddCostSavingsAccessControls
  );

  return (
    <div
      className={classNames(classes.costSavings, classes.costSavingsWrapper)}
    >
      <IonRow className={classNames(classes.csHeader, classes.csRow)}>
        <Text
          variant="mipro-h1-headline"
          text={t('common:costSavingsHealth')}
        />

        <FontAwesomeIcon
          className={classes.icon}
          icon={findIcon('info-circle')}
          onClick={() => {
            setIsOpenInfoModal(true);
          }}
        />
      </IonRow>

      {ifRender(
        and(isExecSelected, !isSingleCSHExecLocation, isCamUser),
        <IonRow className={classes.csRow}>
          <Text
            variant="mipro-h3-headline"
            text={t('reports:myCorporateAccounts')}
          />
        </IonRow>
      )}

      <IonRow className={classNames(classes.csRow, classes.locRow)}>
        {isExecSelected && !isSingleCSHExecLocation ? (
          <>
            <Button
              variant="link"
              text={selectedCSHExecLocation.name}
              className={classNames(classes.dropdownButton)}
              rightIcon={findIcon('caret-down', 'fas')}
              testid="requestType-button"
              id="cost-savings-dropdown"
            />
            <DropDown
              trigger="cost-savings-dropdown"
              dismissOnSelect
              className={classes.dropdown}
              side="bottom"
              alignment="start"
              testid="cost-savings-dropdown"
              filterOptions={filterOptions}
              selectedItem={selectedCSHExecLocation.key}
              onOptionClick={setSelectedCSHExecLocation}
              id="cost-savings-dropdown"
            />
          </>
        ) : (
          <Text
            variant="label-header"
            text={
              fromVirtualTeam
                ? selectedMiLoc.locName
                : `${selectedMiLoc.miLoc}: ${selectedMiLoc.locName}`
            }
          />
        )}
      </IonRow>
      <div>
        {map(cards, (card) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <CostSavingsCard {...card} />
        ))}
      </div>
      <IonRow
        className={classNames(
          classes.csRow,
          classes.csHeader,
          classes.inlineRows,
          classes.csBottom
        )}
      >
        <Button
          className={classes.viewFullReport}
          variant="action"
          text={t('reports:viewFullReport')}
          testid="view-full-report"
          onClick={() => {
            history.push({
              pathname: goToCostSavingsReport({}),
              state: selectedCSHExecLocation.key,
            });
          }}
        />
        {costSavingsEntry && (
          <Button
            className={classNames(
              classes.viewFullReport,
              classes.costSavingsButton
            )}
            variant="secondary"
            text={t('costSavings:costSavings')}
            testid="add-cost-savings-button"
            icon={findIcon('piggy-bank')}
            href={concatRoutes(searchURL(), costSavingsURL('', '', true))}
          />
        )}
      </IonRow>
      <HelpModal
        isOpen={isOpenInfoModal}
        closeHelpModal={setIsOpenInfoModal}
        testid="cost-savings-info-modal"
        title={t('common:costSavingInfoTitle')}
        initialBreakpoint={window.innerHeight <= 667 ? 1 : 0.7}
      >
        <IonRow>
          <Text variant="mipro-body-copy" text={t('costSavingsMainSection')} />
        </IonRow>
        <IonRow className={classes.csHeader}>
          <Text
            variant="mipro-body-copy-bold"
            text={t('costSavingsGuaranteed')}
          />
          <Text
            variant="mipro-body-copy"
            text={t('costSavingsGuaranteedSection')}
          />
        </IonRow>
        <IonRow className={classes.csHeader}>
          <Text variant="mipro-body-copy-bold" text={t('costSavingsGoal')} />
          <Text variant="mipro-body-copy" text={t('costSavingsGoalSection')} />
        </IonRow>
      </HelpModal>
    </div>
  );
};

export default CostSavingsHealthCard;
