import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { find, pick, values } from 'lodash';
import { IonCol, IonContent, IonGrid, IonPage, IonRow } from '@ionic/react';
import { namespaces } from 'i18n/i18n.constants';
import { reportsDrillDownURL, reportsURL } from 'navigation';
import { goToSalesPerformanceReport } from 'ReportsApp/navigation/navigationHelpers';
import useGetLocationItem from 'api/location/useGetLocationItem';
import useGetAccessControl from 'api/user/useGetAccessControl';
import { ReportsAccessControls } from 'hooks/useAccessControls';
import type { SnapshotsURLParams, SearchItemType } from 'models/Search';
import { SearchResultTabTypeEnum } from 'models/Search';
import type { RootState } from 'store/reducers';
import { concatRoutes } from 'utils/navigations';
import SearchResults from 'pages/Search/SearchResults/SearchResults';
import SearchSuggestions from 'pages/Search/SearchSuggestions/SearchSuggestions';
import Address from 'components/Contacts/Address/Address';
import PhoneNumber from 'components/Contacts/PhoneNumber/PhoneNumber';
import Email from 'components/Email/Email';
import Header from 'components/Header/Header';
import LinkedCard from 'components/LinkedCard/LinkedCard';
import Loader from 'components/Loader/Loader';
import SendEmailModal from 'components/Modals/SendEmailModal/SendEmailModal';
import Text from 'components/Text/Text';
import TitleLine from 'components/TitleLine/TitleLine';
import classes from './Location.module.scss';

interface SnapshotsProps {
  searchType: SearchItemType;
}

const Location = ({ searchType }: SnapshotsProps): JSX.Element => {
  const { id } = useParams<SnapshotsURLParams>();
  const { locationTree } = useSelector((state: RootState) => state.user);

  const foundLocation = find(locationTree, { miLoc: id });
  const isTeam = foundLocation?.locationType === 'T';
  const { data: apiAccessControl } = useGetAccessControl(id);
  const { accessControl: stateAccessControl } = useSelector(
    (state: RootState) => state.user
  );
  const accessControl = isTeam ? apiAccessControl : stateAccessControl;
  const isSalesReportAvail =
    !!foundLocation &&
    values(pick(accessControl, ReportsAccessControls)).includes('Y');

  const isLocationReportAvail =
    !!foundLocation &&
    values(pick(accessControl, ReportsAccessControls)).includes('Y');

  const [sendEmailModal, setSendEmailModal] = useState(false);
  const [showAllEmployees, setShowAllEmployees] = useState(false);
  const { t } = useTranslation(namespaces.search);

  const { data: location, isLoading } = useGetLocationItem({
    miLoc: id,
  });
  const {
    locationShortName = '',
    shipToAddressLine1: addrLine1 = '',
    shipToAddressLine2: addrLine2 = '',
    shipToAddressLine3: addrLine3 = '',
    shipToCity: city = '',
    shipToState: state = '',
    shipToZIP: zip = '',
    shipToName,
    phoneNumber,
    emailAddress,
  } = location || {};

  const onSearch = () => {
    setShowAllEmployees(true);
  };

  return (
    <IonPage className={classes.container} data-testid="search-detail-page">
      <Header
        className={classes.header}
        withBackButton
        eyebrow={`${t('location')} - ${id}`}
        title={locationShortName || t('common:loading')}
        testid="search-detail"
      />
      <IonContent className={classes.content}>
        <Loader
          className={classes.loader}
          text={t('common:loading')}
          isOpen={isLoading}
          testid="loadingIndicator"
        />
        {!isLoading && (
          <>
            {(isSalesReportAvail || isLocationReportAvail) && (
              <>
                <IonRow className={classes.reportStatus}>
                  <Text
                    variant="underlined-title-section"
                    text={t('common:reports')}
                    testid="report"
                  />
                </IonRow>
                <TitleLine className={classes.titleLine} />
                <div className={classes.externalLinks}>
                  {isSalesReportAvail && (
                    <LinkedCard
                      className={classes.linkedCard}
                      title={t('snapshot:salesReports')}
                      href={goToSalesPerformanceReport({
                        orgType: isTeam ? 'TEAM' : 'BRCH',
                        miLoc: id,
                      })}
                      testid="sales-reports-link"
                    />
                  )}
                  {isLocationReportAvail && (
                    <LinkedCard
                      className={classes.linkedCard}
                      title={t('snapshot:pick12Reports')}
                      href={concatRoutes(
                        reportsURL(),
                        reportsDrillDownURL(
                          'locationPick12',
                          'BRCH',
                          id,
                          '',
                          ''
                        )
                      )}
                      testid="pick12-reports-link"
                    />
                  )}
                </div>
              </>
            )}
            <IonRow className={classes.reportStatus}>
              <Text
                variant="underlined-title-section"
                text={t('search:locationInformation')}
                testid="location-information"
              />
            </IonRow>
            <TitleLine className={classes.titleLine} />
            <IonGrid className={classes.detailsContainer}>
              <IonRow class={classes.rowWrapper}>
                <IonCol class={classes.detailsWrapper}>
                  <Text
                    text={t('snapshot:mainAddress')}
                    variant="label-header"
                    className={classes.title}
                  />
                  <Address
                    name={`${id} - ${locationShortName}`}
                    address={{
                      addrLine1,
                      addrLine2,
                      addrLine3,
                      city,
                      state,
                      zip,
                    }}
                  />
                </IonCol>
              </IonRow>
              <IonRow className={classes.rowWrapper}>
                <IonCol class={classes.detailsWrapper}>
                  <Text
                    text={t('snapshot:locationContact')}
                    variant="label-header"
                    className={classes.title}
                  />

                  <div className={classes.mainContactWrapper}>
                    {phoneNumber && (
                      <PhoneNumber
                        phoneNumber={phoneNumber}
                        testid="call-button"
                        className={classes.mainContentNumber}
                      />
                    )}
                    {emailAddress && (
                      <Email
                        emailAddress={emailAddress}
                        className={classes.mainContentEmail}
                        testid="email-button"
                        onClick={() => setSendEmailModal(true)}
                      />
                    )}
                    {emailAddress && (
                      <SendEmailModal
                        searchType={searchType}
                        miLoc={id}
                        id={id}
                        defaultRecipients={[
                          { name: shipToName, miLoc: id, email: emailAddress },
                        ]}
                        canRemoveRecipients={false}
                        isOpen={sendEmailModal}
                        setIsOpen={setSendEmailModal}
                        title={t('common:sendEmail')}
                        testid="send-email-modal"
                      />
                    )}
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>

            <IonRow className={classes.reportStatus}>
              <Text
                variant="underlined-title-section"
                text={t('snapshot:employees')}
                testid="employees-list"
              />
            </IonRow>
            <TitleLine className={classes.titleLine} />
            {!showAllEmployees && (
              <SearchSuggestions
                searchQuery={id}
                onSearch={onSearch}
                searchType={SearchResultTabTypeEnum.employees}
                miLoc={id}
                sortField="pinnedOrder"
                showTitle={false}
                className={classes.locationWrapper}
                loadingTitle={t('snapshot:loadingEmployees')}
              />
            )}
            {showAllEmployees && (
              <SearchResults
                key={`${SearchResultTabTypeEnum.employees}-all-search`}
                searchType={SearchResultTabTypeEnum.employees}
                sortField="pinnedOrder"
                searchQuery={id}
                miLoc={id}
                className={classes.locationWrapper}
              />
            )}
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Location;
