import { forEach } from 'lodash';
import { isPlatform } from '@ionic/react';

interface HandleKeypadScrollProps {
  inputDivRef?: React.RefObject<HTMLDivElement>;
  parentRef?: React.RefObject<HTMLDivElement>;
}

interface RemovePaddedChildrenProp {
  parentRef?: React.RefObject<HTMLDivElement>;
}

export const handleKeypadOnScroll = ({
  inputDivRef,
  parentRef,
}: HandleKeypadScrollProps) => {
  if (isPlatform('mobile')) {
    if (parentRef?.current) {
      const paddingDiv = document.createElement('div');
      paddingDiv.setAttribute('class', 'mi-scroll-pad');
      paddingDiv.setAttribute('style', 'height: 200px; min-height: 200px;');
      parentRef?.current?.appendChild(paddingDiv);
    }
    inputDivRef?.current?.scrollIntoView({ behavior: 'smooth' });
    setTimeout(() => {
      inputDivRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }, 500);
  }
};

export const removePaddedChildren = ({
  parentRef,
}: RemovePaddedChildrenProp) => {
  forEach(document.getElementsByClassName('mi-scroll-pad'), (node) => {
    if (node) {
      parentRef?.current?.removeChild(node);
    }
  });
};
