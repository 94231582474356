import { useEffect, useMemo, useState } from 'react';
import type { AxiosError } from 'axios';
import { map, size, toString } from 'lodash';
import * as Sentry from '@sentry/capacitor';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import type { QueryParamsType } from 'common/api/utils/useGetQueryFlags';
import useVasCodesDB from 'DocumentsApp/database/useVasCodesDB';
import type { VasCode } from 'DocumentsApp/models/VasCode';
import { useAxios } from 'providers/AxiosProvider';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import { useToasts } from 'providers/ToastProvider';
import {
  doConcatDataPages,
  doGetIsLoading,
  doPromiseAPI,
  getAPIHeadersV2,
  getOfflineNextPage,
  onDownloadData,
  onSuccessMutation,
  useKeyUserId,
  useMiLocOrTeamId,
} from 'api/helpers';
import type { InfiniteQueryFlags } from 'models/Search';
import { ToastType } from 'models/Toast';
import { pageSize } from 'utils/constants';

export const findVasCodesQueryKey = 'vasCodes';

interface UseFindVasCodesProps {
  query?: string;
  limit?: number;
  enabled?: boolean;
}

interface UseFindVasCodesResponse {
  vasCodes: VasCode[];
  loadingAPI?: boolean;
  sync: () => Promise<void>;
}

const useFindVasCodes = ({
  query,
  enabled = true,
  limit = pageSize(),
}: UseFindVasCodesProps): UseFindVasCodesResponse & InfiniteQueryFlags => {
  const { axios } = useAxios();
  const { addToast } = useToasts();
  const { findVasCodesAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { createQueryKey } = useKeyUserId();
  const { createParams, getURLParams } = useMiLocOrTeamId({
    sendTeamId: false,
  });
  const { createVasCodes, findVasCodes, removeVasCodes } = useVasCodesDB();
  const [loadingAPI, setLoadingAPI] = useState(false);
  const { isOnline } = useNetworkStatus();
  const params: QueryParamsType = {
    ...createParams(),
    fastFind: query,
    limit,
    isOnline,
  };
  const miLoc = toString(params.miLoc);

  const doFindVasCodes = ({ pageParam = 1 }) => {
    return doPromiseAPI<VasCode[]>(async (cancelToken) => {
      if (isOnline) {
        const { data } = await axios.get<VasCode[]>(
          findVasCodesAPI(getURLParams({ ...params, page: pageParam })),
          { cancelToken }
        );
        await createVasCodes(map(data, (c) => ({ ...c, miLoc })));
        return data;
      }
      const vasCodes: VasCode[] = (await findVasCodes(params)) || [];
      return vasCodes;
    });
  };

  const response = useInfiniteQuery<VasCode[], AxiosError>(
    createQueryKey(findVasCodesQueryKey, params),
    doFindVasCodes,
    {
      enabled,
      networkMode: 'always',
      getNextPageParam: getOfflineNextPage(!!isOnline),
    }
  );

  const {
    data,
    error,
    status,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = response;

  const vasCodes = useMemo(() => doConcatDataPages<VasCode>(data), [data]);

  const hasItems = size(vasCodes) > 0;
  const isEmptyResponse = status === 'success' && !hasNextPage && !hasItems;
  const noMoreData = status === 'success' && !hasNextPage && hasItems;
  const showLoader = doGetIsLoading(response) || isFetchingNextPage;
  const enableInfiniteScroll = !(!hasNextPage || isFetchingNextPage);

  useEffect(() => {
    if (enabled && !isOnline) {
      void refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, isOnline]);

  return {
    fetchNextPage: async () => {
      await fetchNextPage();
    },
    refetch: async () => {
      await refetch();
    },
    sync: async () => {
      try {
        setLoadingAPI(true);
        const { syncData } = await onDownloadData<VasCode>({
          customAxios: axios,
          getAPIUrl: findVasCodesAPI,
          getData: (r) => r as VasCode[],
          params: {
            miLoc,
          },
          headers: { ...getAPIHeadersV2() },
          method: 'get',
        });
        await removeVasCodes(miLoc);
        await createVasCodes(map(syncData, (c) => ({ ...c, miLoc })));
        void onSuccessMutation(queryClient, findVasCodesQueryKey);
      } catch (e) {
        addToast({
          type: ToastType.error,
          text: 'There was an error while downloading Vas Codes',
          testid: 'sync-error-toast',
          duration: 0,
        });
        Sentry.captureException(e);
        throw e;
      } finally {
        setLoadingAPI(false);
      }
    },
    vasCodes,
    error,
    showLoader,
    loadingAPI,
    isEmptyResponse,
    noMoreData,
    enableInfiniteScroll,
  };
};

export default useFindVasCodes;
