import React, { useEffect, useRef } from 'react';
import { useIMask } from 'react-imask';
import classNames from 'classnames';
import { isNil, toNumber, toString } from 'lodash';
import type { PluginListenerHandle } from '@capacitor/core';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import type { InputChangeEventDetail } from '@ionic/react';
import { IonCol, IonInput, IonItem, IonLabel, IonRow } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { withStringProp } from 'utils/helpers';
import {
  handleKeypadOnScroll,
  removePaddedChildren,
} from 'utils/keypadScrollHelpers';
import Button from 'components/Button/Button';
import Text from 'components/Text/Text';
import classes from './Input.module.scss';

interface InputProps {
  label?: string;
  mask?: unknown;
  error?: string;
  setValue?: (v: string) => void;
  inputClassName?: string;
  wrapperClassName?: string;
  leftButton?: React.ComponentProps<typeof Button>;
  rightButton?: React.ComponentProps<typeof Button>;
  testid: string;
  opacityDisabled?: boolean;
  showCharCounter?: boolean;
  triggerInputSelect?: number;
  inputRowClassName?: string;
  parentRef?: React.RefObject<HTMLDivElement>;
  disableAutoScroll?: boolean;
  focusable?: boolean;
}

const Input = ({
  className,
  label = '',
  leftButton,
  rightButton,
  onClick,
  onFocus,
  onBlur,
  name,
  type = 'text',
  autofocus,
  autocomplete,
  enterkeyhint,
  inputmode,
  mask = '',
  disabled,
  placeholder,
  required,
  min,
  max,
  maxlength,
  step,
  value = '',
  error,
  setValue,
  inputClassName,
  wrapperClassName,
  testid,
  opacityDisabled = false,
  showCharCounter = false,
  triggerInputSelect,
  inputRowClassName,
  parentRef,
  disableAutoScroll = true,
  focusable = true, // for fields that contain errors
  spellcheck,
  autocapitalize,
}: InputProps &
  React.ComponentProps<typeof IonInput> &
  IonicReactProps): JSX.Element => {
  const withLabel = withStringProp(label);
  const withLeftButton = !isNil(leftButton);
  const withRightButton = !isNil(rightButton);
  const inputDivRef = useRef<HTMLDivElement>(null);

  const { onClick: onRightBtnClick, ...restRightButton } = rightButton || {};

  const {
    ref: ionInputRef,
    value: maskedValue,
    unmaskedValue,
    setUnmaskedValue,
  } = useIMask(
    {
      mask: mask as RegExp,
      // thousandsSeparator: ','
    },
    {
      onAccept: (v, maskRef) => {
        if (toString(value) !== toString(maskRef.unmaskedValue)) {
          setValue?.(maskRef.unmaskedValue);
        }
      },
    }
  );

  useEffect(() => {
    const doTriggerInputSelect = async () => {
      if (toNumber(triggerInputSelect) > 0) {
        const inputEl = await (
          ionInputRef.current as HTMLIonInputElement
        )?.getInputElement();
        inputEl?.focus();
        inputEl?.select();
      }
    };
    void doTriggerInputSelect();
  }, [ionInputRef, triggerInputSelect]);

  useEffect(() => {
    let keyboardListener: PluginListenerHandle;
    const doKeyboadListener = async () => {
      if (Capacitor.isPluginAvailable('Keyboard') && !disableAutoScroll) {
        keyboardListener = await Keyboard.addListener('keyboardWillHide', () =>
          removePaddedChildren({ parentRef })
        );
      }
    };
    void doKeyboadListener();

    return () => {
      if (Capacitor.isPluginAvailable('Keyboard') && !disableAutoScroll) {
        void keyboardListener?.remove();
      }
    };
  }, [parentRef, disableAutoScroll]);

  useEffect(() => {
    if (mask && toString(value) !== toString(unmaskedValue)) {
      setUnmaskedValue?.(toString(value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div
      className={classNames(classes.inputDiv, wrapperClassName)}
      ref={inputDivRef}
      data-mipro-focusable={focusable}
      data-mipro-error={!!error}
    >
      <IonItem
        className={classNames(className, classes.input, {
          [classes.error]: error,
          [classes.disabled]: disabled,
          [classes.withoutLabel]: !withLabel,
          [classes.inputNotes]: opacityDisabled,
        })}
        lines="none"
      >
        <IonCol className={classes.inputCol}>
          {withLabel && (
            <IonLabel className={classes.label}>
              <Text
                className={classes.label}
                variant="content-heavy"
                text={label}
                testid={`${testid}-label`}
              />
              <Text
                className={classes.requiredLabel}
                variant="content-heavy"
                text={`${required ? ' *' : ''}`}
                testid={`${testid}-required-label`}
              />
            </IonLabel>
          )}
          <IonRow
            className={classNames(inputRowClassName, classes.wrapper, {
              [classes.withRightButton]: withRightButton,
              [classes.withLeftButton]: withLeftButton,
            })}
          >
            {withLeftButton && (
              <Button
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...leftButton}
                variant="clear"
                disabled={disabled}
                tabIndex={-1}
                className={classNames(
                  leftButton?.className,
                  classes.leftButton
                )}
                testid={toString(leftButton?.testid)}
              />
            )}
            <IonInput
              aria-label={label || name}
              ref={ionInputRef as React.Ref<HTMLIonInputElement>}
              className={inputClassName}
              autofocus={autofocus}
              inputmode={inputmode}
              enterkeyhint={enterkeyhint}
              autocomplete={autocomplete}
              name={name}
              type={type}
              value={mask ? maskedValue : value}
              onClick={onClick}
              onIonInput={(e: CustomEvent<InputChangeEventDetail>) => {
                if (!mask) {
                  setValue?.(toString(e.detail.value));
                }
              }}
              onIonFocus={async (e: CustomEvent<FocusEvent>) => {
                onFocus?.(
                  e as unknown as React.FocusEvent<HTMLIonInputElement>
                );
                if (type === 'number') {
                  const inputEl = await (
                    ionInputRef.current as HTMLIonInputElement
                  )?.getInputElement();
                  inputEl?.select();
                }
                setTimeout(() => {
                  if (!disableAutoScroll) {
                    handleKeypadOnScroll({ inputDivRef, parentRef });
                  }
                }, 0);
              }}
              onIonBlur={(e: CustomEvent<FocusEvent>) => {
                onBlur?.(e as unknown as React.FocusEvent<HTMLIonInputElement>);
                removePaddedChildren({ parentRef });
              }}
              onWheel={() => {
                if (
                  document.activeElement instanceof HTMLElement &&
                  type === 'number'
                ) {
                  document.activeElement.blur();
                }
              }}
              disabled={disabled}
              placeholder={placeholder}
              min={min}
              max={max}
              maxlength={maxlength}
              step={step}
              data-testid={testid}
              spellcheck={spellcheck}
              autocapitalize={autocapitalize}
            />
            {withRightButton && (
              <Button
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...restRightButton}
                onClick={(e) => {
                  if (!disableAutoScroll) {
                    handleKeypadOnScroll({ inputDivRef, parentRef });
                  }
                  onRightBtnClick?.(e);
                }}
                variant="clear"
                disabled={disabled}
                tabIndex={-1}
                className={classNames(
                  rightButton?.className,
                  classes.rightButton
                )}
                testid={toString(rightButton?.testid)}
              />
            )}
          </IonRow>
          {showCharCounter && (
            <IonRow className={classes.charCounter}>
              {toString(value).length}/{maxlength}
            </IonRow>
          )}
          {error && (
            <Text
              className={classes.errorMessage}
              variant="content-small"
              text={error}
              testid={`${testid}-error`}
            />
          )}
        </IonCol>
      </IonItem>
    </div>
  );
};

export default Input;
