import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';
import classNames from 'classnames';
import { isEmpty, toNumber } from 'lodash';
import { IonContent, IonRow } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { replenishmentStoreroomSearchURL } from 'navigation';
import { useToasts } from 'providers/ToastProvider';
import useAddReplenishment from 'StoreroomsApp/api/useAddItemsReplenishment';
import useFindStorerooms from 'StoreroomsApp/api/useFindStorerooms';
import useGoBack from 'hooks/useGoBack';
import type { RootState } from 'store/reducers';
import { setUserStoreroom } from 'store/user';
import selectIsBranchLocation from 'store/user/selectors';
import { formatCardDate } from 'utils/date';
import { getErrorMessage } from 'utils/helpers';
import { findIcon } from 'utils/icons';
import { concatRoutes } from 'utils/navigations';
import ActionRow from 'components/ActionRow/ActionRow';
import Loader from 'components/Loader/Loader';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './StoreroomsList.module.scss';

interface StoreroomsListProps {
  searchQuery: string;
  isPendingIssue?: boolean;
}

const StoreroomsList = ({
  className,
  searchQuery,
  isPendingIssue,
}: StoreroomsListProps & IonicReactProps): JSX.Element => {
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { storeroom = '', allowAddAllFromStoreroom } = useSelector(
    (state: RootState) => state.user
  );
  const isBranch = useSelector(selectIsBranchLocation);
  const { goBack } = useGoBack();

  const {
    storerooms,
    error,
    showLoader,
    isEmptyResponse,
    enableInfiniteScroll,
    fetchNextPage,
  } = useFindStorerooms({
    enabled: isBranch,
    query: searchQuery,
    isPendingIssue,
  });

  const { onAddItemsReplenishment, isLoading } = useAddReplenishment();

  const setStoreRoom = (storeroomNumber: string, storeroomName: string) => {
    dispatch(
      setUserStoreroom({
        storeroom: storeroomNumber,
        storeroomName,
      })
    );
    addToast({
      text: `New storeroom set as ${storeroomName}.`,
      testid: 'set-storeroom-toast',
    });
    goBack();
  };

  return (
    <IonContent
      className={classNames(classes.content, className)}
      data-testid="storerooms-list"
    >
      {!isBranch && (
        <WarningMessage
          className={classes.warningMessage}
          icon={['far', 'info-circle']}
          title="You must be at a branch level to use this feature."
        />
      )}
      {!error && !isEmpty(storerooms) && (
        <Virtuoso
          className="ion-content-scroll-host"
          data={storerooms}
          increaseViewportBy={{ top: 500, bottom: 500 }}
          endReached={enableInfiniteScroll ? fetchNextPage : undefined}
          itemContent={(
            index,
            { storeroomNumber, storeroomName, syncTimestamp, pendingIssues }
          ) =>
            !isPendingIssue ? (
              <ActionRow
                className={classes.itemHref}
                key={`${storeroomNumber}-${index}`}
                href={concatRoutes(
                  url,
                  replenishmentStoreroomSearchURL(storeroomNumber)
                )}
                rightButton={
                  allowAddAllFromStoreroom
                    ? {
                        className: classes.addAllButton,
                        text: `Add all items`,
                        variant: 'action',
                        disabled: isLoading,
                        onClick: () => {
                          onAddItemsReplenishment({
                            storeroomNumber,
                            hideSuccessToast: true,
                          });
                        },
                        testid: 'add-all-items-button',
                      }
                    : undefined
                }
                testid={`storeroom-${storeroomNumber}`}
              >
                <div>
                  <div className={classes.titleRow}>
                    <Text
                      className={classes.title}
                      text={`(${storeroomNumber}) ${storeroomName}`}
                      textQuery={searchQuery}
                      variant="content-heavy"
                      testid="storeroom-text"
                    />
                    {isPendingIssue && toNumber(pendingIssues) > 0 && (
                      <Text
                        className={classes.pendingIssuesBadge}
                        variant="label-micro"
                        text="Ready for Upload"
                      />
                    )}
                  </div>
                  {isPendingIssue && toNumber(pendingIssues) > 0 && (
                    <IonRow>
                      <Text
                        className={classes.description}
                        text={`Pending Issues: ${toNumber(pendingIssues)}`}
                        variant="content-small"
                      />
                    </IonRow>
                  )}
                  <IonRow>
                    <Text
                      className={classes.description}
                      text={`Last Download: ${formatCardDate(syncTimestamp)}`}
                      textQuery={`${formatCardDate(syncTimestamp)}`}
                      variant="content-small"
                    />
                  </IonRow>
                </div>
              </ActionRow>
            ) : (
              <ActionRow
                key={`${storeroomNumber}-${index}`}
                className={classNames(classes.itemSet, {
                  [classes.selectedItem]: storeroom === storeroomNumber,
                })}
                text={`(${storeroomNumber}) ${storeroomName}`}
                leftButton={{
                  className: classes.button,
                  variant: 'link',
                  text: storeroom === storeroomNumber ? '' : 'Set',
                  icon:
                    storeroom === storeroomNumber
                      ? findIcon('check')
                      : undefined,
                  onClick: () =>
                    setStoreRoom(
                      storeroomNumber,
                      `(${storeroomNumber}) ${storeroomName}`
                    ),
                  testid: `set-storeroom-button`,
                }}
                // TODO: wrong name?
                withoutHrefArrow={false}
                testid={`storeroom-${storeroomNumber}`}
              />
            )
          }
        />
      )}
      {isBranch && !error && isEmptyResponse && !showLoader && (
        <WarningMessage
          className={classes.warningMessage}
          icon={['far', 'info-circle']}
          title="No storerooms"
          body="Are you expecting data? Go online and download to sync with the server."
        />
      )}
      {error && (
        <WarningMessage
          className={classes.warningMessage}
          title="Error loading storerooms"
          body={getErrorMessage(error)}
        />
      )}
      <Loader
        className={classes.loader}
        text="Loading storerooms"
        isOpen={showLoader}
        testid="loading-storerooms"
      />
    </IonContent>
  );
};

export default StoreroomsList;
