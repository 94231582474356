import React from 'react';
import classNames from 'classnames';
import { kebabCase, map } from 'lodash';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import type { SortOption } from 'models/Sort';
import ActionRow from 'components/ActionRow/ActionRow';
import Modal from 'components/Modal/Modal';
import classes from './OptionsListModal.module.scss';

interface OptionsListModalProps {
  selectedItem?: string;
  filterOptions: SortOption[];
  onOptionClick?: (option: SortOption) => void;
  testid: string;
}

const OptionsListModal = ({
  isOpen,
  setIsOpen,
  title,
  eyebrow,
  selectedItem,
  filterOptions,
  onOptionClick,
  testid,
}: OptionsListModalProps &
  IonicReactProps &
  React.ComponentProps<typeof Modal>): JSX.Element => (
  <Modal
    className={classes.modal}
    isOpen={isOpen}
    setIsOpen={setIsOpen}
    title={title}
    eyebrow={eyebrow}
    testid={`${testid}-filter-modal`}
  >
    <div>
      {map(
        filterOptions,
        ({ key, name, icon, customContent, onClick }, index) => (
          <ActionRow
            key={key}
            className={classes.filterRow}
            leftButton={{
              iconClassName: classNames(classes.filterIcon),
              className: classes.filterText,
              icon,
              variant: key === selectedItem ? 'clear' : 'link',
              text: name,
              children: customContent,
              testid: `${testid}-${key}-button`,
            }}
            onClick={() => {
              if (onClick) {
                onClick();
              } else {
                onOptionClick?.(filterOptions[index]);
                setIsOpen?.(false);
              }
            }}
            testid={`${testid}-${kebabCase(name) || key}`}
          />
        )
      )}
    </div>
  </Modal>
);

export default OptionsListModal;
