import { useMemo } from 'react';
import type { AxiosError } from 'axios';
import type { Dictionary } from 'lodash';
import { isEmpty, map, size, toString } from 'lodash';
import { useInfiniteQuery } from '@tanstack/react-query';
import type { InfiniteQueryObserverResult } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import {
  doConcatDataPages,
  doPromiseAPI,
  useKeyUserId,
  useMiLocOrTeamId,
} from 'api/helpers';
import type {
  SearchItem,
  SearchResponse,
  InfiniteQueryFlags,
} from 'models/Search';
import type { SupplierContact } from 'models/SupplierContact';
import { pageSize } from 'utils/constants';

export const findSupplierContactsQueryKey = 'search-supplier-contacts';

interface UseFindSupplierContactsProps {
  query?: string;
  sortField?: string;
  sortDir?: string;
  limit?: number;
}

export type UseFindSupplierContactsResponse = SearchResponse &
  InfiniteQueryFlags &
  Pick<InfiniteQueryObserverResult<SearchResponse>, 'data'>;

const useFindSupplierContacts = ({
  query = '',
  sortField = 'name',
  sortDir = 'ASC',
  limit = pageSize(),
}: UseFindSupplierContactsProps): UseFindSupplierContactsResponse => {
  const { axios } = useAxios();
  const { findSupplierContactsAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const { createParams } = useMiLocOrTeamId({ sendTeamId: false });
  const miLocParams = { ...createParams() };
  const { miLoc } = miLocParams;
  const params: Dictionary<string> = {
    query: toString(query),
    miLoc,
    sortField,
    sortDir,
    limit: toString(limit),
  };

  const doFindSupplierContacts = ({ pageParam = 1 }) => {
    return doPromiseAPI<SearchResponse>(async (cancelToken) => {
      const { data } = await axios.get<SupplierContact[]>(
        findSupplierContactsAPI(
          toString(
            new URLSearchParams({ ...params, page: toString(pageParam) })
          )
        ),
        { cancelToken }
      );
      return {
        items: map(
          data,
          ({
            email,
            isMainContact,
            miLoc: iMiLoc,
            name,
            phone1,
            phone2,
            sequenceNo,
            supplierLocationNo,
            supplierName,
            typeDesc,
          }) => ({
            type: 'supplierContact',
            id: toString(sequenceNo),
            email,
            isMainContact,
            miLoc: iMiLoc || miLoc,
            text: name || '',
            phone1,
            phone2,
            sequenceNo,
            supplierLocationNo,
            supplierName,
            typeDesc,
          })
        ),
      };
    });
  };

  const response = useInfiniteQuery<SearchResponse, AxiosError>(
    createQueryKey(findSupplierContactsQueryKey, params),
    doFindSupplierContacts,
    {
      enabled: !isEmpty(query),
      getNextPageParam: (lastPage, pages) =>
        size(lastPage.items) < limit ? false : size(pages) + 1,
    }
  );

  const {
    data,
    error,
    status,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = response;

  const supplierContacts = useMemo(
    () => doConcatDataPages<SearchItem, SearchResponse>(data, 'items'),
    [data]
  );

  const hasItems = size(supplierContacts) > 0;
  const hasError = status === 'error';
  const isEmptyResponse = status === 'success' && !hasNextPage && !hasItems;
  const noMoreData = status === 'success' && !hasNextPage && hasItems;
  const showLoader = status === 'loading' || isFetchingNextPage;
  const enableInfiniteScroll = !(!hasNextPage || isFetchingNextPage);

  return {
    data,
    fetchNextPage: async () => {
      await fetchNextPage();
    },
    items: supplierContacts,
    total: !showLoader ? data?.pages[0].total : undefined,
    error,
    hasError,
    showLoader,
    isEmptyResponse,
    noMoreData,
    enableInfiniteScroll,
    refetch: async () => {
      await refetch?.call(null);
    },
  };
};

export default useFindSupplierContacts;
