import { useTranslation } from 'react-i18next';
import type { MutationStatus } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { namespaces } from 'i18n/i18n.constants';
import type { OrderNote } from 'ProductSearchApp/models/Order';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import { doPromiseAPI, onSuccessMutation } from 'api/helpers';
import { ToastType } from 'models/Toast';
import { findOrderNotesQueryKey } from './useFindOrderNotes';
import { getOrderNoteQuerykey } from './useGetOrderNote';

interface SubmitOrderLineNoteProps {
  miLoc: string;
  ocn: string;
  isUpdating: boolean;
}

interface UseSubmitOrderLineNoteResponse {
  status: MutationStatus;
  onSubmitOrderLineNote: (body: OrderNote) => void;
}

const useSubmitOrderLineNote = ({
  miLoc,
  ocn,
  isUpdating,
}: SubmitOrderLineNoteProps): UseSubmitOrderLineNoteResponse => {
  const { axios } = useAxios();
  const { addEditOrderNoteAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const { t } = useTranslation(namespaces.notes);

  const doSubmitOrderLineNote = (body: OrderNote) => {
    return doPromiseAPI<UseSubmitOrderLineNoteResponse>(async (cancelToken) => {
      let data: UseSubmitOrderLineNoteResponse;
      if (isUpdating) {
        ({ data } = await axios.put<UseSubmitOrderLineNoteResponse>(
          addEditOrderNoteAPI(miLoc, ocn),
          { ...body, source: 'OCN' },
          { cancelToken }
        ));
      } else {
        ({ data } = await axios.post<UseSubmitOrderLineNoteResponse>(
          addEditOrderNoteAPI(miLoc, ocn),
          { ...body, source: 'OCN' },
          { cancelToken }
        ));
      }

      return data;
    });
  };

  const { status, mutate } = useMutation(doSubmitOrderLineNote, {
    onSuccess: async () => {
      await onSuccessMutation(queryClient, findOrderNotesQueryKey);
      if (isUpdating) {
        await onSuccessMutation(queryClient, getOrderNoteQuerykey);
      }
      addToast({
        type: ToastType.success,
        text: isUpdating
          ? t('notes:orderNotesUpdated')
          : t('notes:orderNotesAdded'),
        testid: 'add--edit--order--note--toast',
      });
    },
    onError: () => {
      addToast({
        type: ToastType.error,
        text: isUpdating
          ? t('notes:editFailureToast')
          : t('notes:addFailureToast'),
        testid: 'add--edit--order--note-error--toast',
      });
    },
  });

  return {
    status,
    onSubmitOrderLineNote: (body: OrderNote) => mutate(body),
  };
};

export default useSubmitOrderLineNote;
