import React from 'react';
import type { Dictionary } from 'lodash';
import { head, toString, trim } from 'lodash';
import PriceOverrideForm from 'ActivitiesApp/components/PriceOverrideForm/PriceOverrideForm';
import {
  PriceOverrideTypeEnum,
  PriceOverrideIconEnum,
  PriceOverrideColorEnum,
  PriceOverrideFilterEnum,
} from 'ActivitiesApp/models/PriceOverride';
import { getActivityDataValue } from 'ActivitiesApp/utils/helpers';
import type { Contact, Recipient } from 'models/Contact';
import generateEmailList from 'utils/generateEmailList';
import type {
  ActionCardConfig,
  ActivityTypeConfig,
  GetActivityConfigExtendedProps,
  GetActivityTypeDataProps,
} from 'pages/Activities/ActivityActionCard/ActivityCardConfig';

export const getPriceOverrideConfig = ({
  activityVersion,
  activityData,
  defaultFirstLine,
  t,
}: GetActivityConfigExtendedProps): ActionCardConfig | undefined => {
  const { fieldsData, extendedInfo } = activityData;
  const orderNumber = getActivityDataValue(fieldsData?.['Order Ctl No']);
  const priceOverrideRequestor = getActivityDataValue(
    fieldsData?.['Requestor Name'] || fieldsData?.['Requestor Userid']
  );
  const miLoc = getActivityDataValue(fieldsData?.['MI Loc']);

  const priceOverrideContacts: Recipient[] = generateEmailList({
    name: trim(toString(extendedInfo?.requestorName)),
    rawEmailList: trim(toString(extendedInfo?.requestorEmail)),
    phone: toString(extendedInfo?.requestorPhone),
    splitChar: ';',
  });
  const emailContact = head(priceOverrideContacts) as Contact;
  const emailContactName = emailContact?.name || emailContact?.email;
  const modalBody = {
    hideSaveButton: true,
    refetchActivity: true,
    contactEmail: priceOverrideContacts,
    defaultData: {
      orderNumber,
      emailContactName,
      emailContact,
    },
    customContent: (props: Dictionary<unknown>) => (
      <PriceOverrideForm
        historyId={activityData.historyId}
        userId={activityData.userId}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    ),
  };

  switch (activityVersion) {
    case `${PriceOverrideTypeEnum.priceOverrideApproved}-1`:
    case `${PriceOverrideTypeEnum.priceOverrideApproved}-2`:
      return {
        card: {
          defaultTitle: t('priceOverrideApproved'),
          body: {
            description: t('priceOverrideApprovedOCN', { orderNumber }), // @todo: add name of the approver when API is ready
            lines: [...defaultFirstLine],
          },
        },
        modal: { title: t('priceOverrideApproved'), ...modalBody },
      };
    case `${PriceOverrideTypeEnum.priceOverrideRejected}-1`:
    case `${PriceOverrideTypeEnum.priceOverrideRejected}-2`:
      return {
        card: {
          defaultTitle: t('priceOverrideRejected'),
          body: {
            description: t('priceOverrideRejectedOCN', { orderNumber }), // @todo: add name of the rejector when API is ready
            lines: [...defaultFirstLine],
          },
        },
        modal: { title: t('priceOverrideRejected'), ...modalBody },
      };
    case `${PriceOverrideTypeEnum.priceOverride}-1`:
    case `${PriceOverrideTypeEnum.priceOverride}-2`:
      return {
        card: {
          defaultTitle: t('priceOverrideTitle'),
          body: {
            description: t('priceOverrideDescription', {
              priceOverrideRequestor,
              orderNumber,
            }),
            lines: [
              ...defaultFirstLine,
              {
                description: t('sourcingRequestedByDescription', {
                  sourceOverrideRequestor: priceOverrideRequestor,
                }),
                highlight: t('sourcingRequestedBy'),
              },
              {
                description: t('pricingLocationDescription', { miLoc }),
                highlight: t('pricingLocation'),
              },
            ],
          },
        },
        modal: { title: t('priceOverrideTitle'), ...modalBody },
      };
    default:
      return undefined;
  }
};

export const getPriceOverrideData = ({
  eventTagName,
  t,
}: GetActivityTypeDataProps): ActivityTypeConfig | undefined => {
  switch (eventTagName) {
    case 'OrderPriceOverrideRequest':
      return {
        cardType: PriceOverrideTypeEnum.priceOverride,
        title: t('priceOverrideTitle'),
        icon: PriceOverrideIconEnum.priceOverride,
        color: PriceOverrideColorEnum.priceOverride,
      };
    case 'PriceOverrideApproved':
      return {
        cardType: PriceOverrideTypeEnum.priceOverrideApproved,
        title: t('priceOverrideApproved'),
        icon: PriceOverrideIconEnum.priceOverrideApproved,
        color: PriceOverrideColorEnum.priceOverrideApproved,
      };
    case 'PriceOverrideRejected':
      return {
        cardType: PriceOverrideTypeEnum.priceOverrideRejected,
        title: t('priceOverrideRejected'),
        icon: PriceOverrideIconEnum.priceOverrideRejected,
        color: PriceOverrideColorEnum.priceOverrideRejected,
      };
    default:
      return undefined;
  }
};

export const getPriceOverrideFilterIcon = (
  type: string
): PriceOverrideIconEnum | undefined => {
  switch (type) {
    case PriceOverrideFilterEnum.priceOverride:
      return PriceOverrideIconEnum.priceOverride;
    default:
      return undefined;
  }
};
