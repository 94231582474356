import React, { useState } from 'react';
import classNames from 'classnames';
import { includes, isNil, map, toString, values } from 'lodash';
import { IonCol, IonRow } from '@ionic/react';
import type { GroupInput, TemplateGroup } from 'models/DocumentTemplate';
import type { ReportValue, SignatureDBItem } from 'models/InspectionReport';
import type DocumentImage from 'components/Documents/DocumentImage/DocumentImage';
import DocumentInput from 'components/Documents/DocumentInput/DocumentInput';
import Text from 'components/Text/Text';
import classes from './DocumentContainer.module.scss';

export interface DocumentContainerProps {
  group: TemplateGroup;
  reportId: number;
  containerValues?: ReportValue[];
  needSyncValues?: number[];
  disabled?: boolean;
  custNo: string;
  miLoc: string;
  setHasData: (hasData: boolean) => void;
  onFormUpdate: (formData: Record<number, ReportValue>) => void;
  createSignature?: (name: string, image: string, inputId: number) => void;
  reportSignature?: SignatureDBItem;
}

const DocumentContainer = ({
  group,
  reportId,
  containerValues,
  needSyncValues,
  disabled,
  custNo,
  miLoc,
  setHasData,
  onFormUpdate,
  createSignature,
  reportSignature,
  ...imgProps
}: DocumentContainerProps &
  React.ComponentProps<typeof DocumentImage>): JSX.Element => {
  const initialValues = () => {
    const data: Record<number, ReportValue> = {};
    if (group.templateGroupInputs) {
      map(group.templateGroupInputs, (groupInput: GroupInput) => {
        const reportValue: ReportValue = {
          reportId,
          groupId: group.groupId,
          inputId: groupInput.inputId,
          inputValue: '',
        };
        if (containerValues) {
          map(containerValues, (value: ReportValue) => {
            if (groupInput.inputId === value.inputId) {
              reportValue.inputValue = value.inputValue.toString().trim();
            }
          });
        }
        data[reportValue.inputId] = reportValue;
      });
    }
    return data;
  };

  const [initialFormData] = useState(initialValues);
  const [formData, setFormData] = useState(initialValues);

  const getFormValue = (inputId: number) => {
    const value = formData[inputId];
    return value;
  };

  const [isResetting, setIsResetting] = useState(false);

  const setFormValue = (
    value: string,
    inputId: number,
    valuesList?: string[]
  ) => {
    const newFormData: Record<number, ReportValue> = isResetting
      ? { ...initialFormData }
      : { ...formData };
    if (isResetting) {
      let isCleared = true;
      let iFormValuesArray: ReportValue[] = [];
      if (initialFormData) {
        iFormValuesArray = values(initialFormData);
      }
      map(values(formData), (rValue: ReportValue, i) => {
        // if we have initial data, compare each value to the initial data
        // otherwise, check that each input has a value length of 0
        if (
          initialFormData &&
          rValue.inputValue !== iFormValuesArray[i].inputValue
        ) {
          isCleared = false;
        } else if (!initialFormData && rValue.inputValue.length > 0) {
          isCleared = false;
        }
      });
      if (isCleared) {
        setIsResetting(false);
      }
    }
    const newData: Record<number, ReportValue> = {};
    const newReportValue: ReportValue = {
      reportId,
      groupId: group.groupId,
      inputId,
      inputValue: value,
    };
    if (valuesList && valuesList.length > 0) {
      const [firstValue] = valuesList;
      newReportValue.inputValue = firstValue;
    }
    newFormData[inputId] = newReportValue;
    newData[inputId] = newReportValue;
    setFormData(newFormData);
    let hasData = false;
    map(values(newFormData), (formValue: ReportValue) => {
      if (formValue.inputValue.length > 0) {
        hasData = true;
      }
    });
    setHasData(hasData);
    onFormUpdate(newData);
  };

  return (
    <div className={classes.documentContainer} data-testid="DocumentContainer">
      {group.title && (
        <IonRow className={classNames(classes.documentRow, classes.titleRow)}>
          <IonCol
            size="12"
            className={classNames(classes.containerTitle, classes.documentCol)}
          >
            <Text text={group.title} variant="content-default" />
          </IonCol>
        </IonRow>
      )}
      <div className={classes.inuptsWrap}>
        {!isNil(group.templateGroupInputs) &&
          map(group.templateGroupInputs, (groupInput: GroupInput, ndx) => (
            <DocumentInput
              key={groupInput.inputId}
              setRecValue={() => {}}
              containerValues={containerValues}
              disabled={disabled}
              reportId={reportId}
              groupInput={groupInput}
              onFormUpdate={onFormUpdate}
              setHasData={setHasData}
              value={toString(getFormValue(groupInput.inputId).inputValue)}
              rowData={ndx}
              values={[]}
              setValue={(e: string, listValues?: string[]) => {
                setFormValue(e, groupInput.inputId, listValues);
              }}
              custNo={custNo}
              miLoc={miLoc}
              needSync={includes(needSyncValues, groupInput.inputId)}
              createSignature={createSignature}
              reportSignature={reportSignature}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...imgProps}
              groupId={group.groupId}
            />
          ))}
      </div>
    </div>
  );
};

export default DocumentContainer;
