import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { isNil } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonRow } from '@ionic/react';
import useGetAttachment from 'api/attachments/useGetAttachment';
import type { MiProFile } from 'models/Attachment';
import {
  getAttachmentProps,
  getFileExtension,
  getFileName,
  iconFinder,
  isImageFile,
} from 'utils/filesUpload';
import Loader from 'components/Loader/Loader';
import type Modal from 'components/Modal/Modal';
import SheetModal from 'components/Modals/SheetModal/SheetModal';
import Text from 'components/Text/Text';
import classes from './FileRemoveModal.module.scss';

interface FileRemoveModalProps {
  domain: string;
  file: MiProFile;
  testid: string;
  isOpen: boolean;
  setIsOpen: (b: boolean) => void;
  onRemoveClick: () => void;
}
const FileRemoveModal = ({
  domain,
  isOpen,
  setIsOpen,
  testid,
  file,
  onRemoveClick,
}: FileRemoveModalProps & React.ComponentProps<typeof Modal>): JSX.Element => {
  const { t } = useTranslation();
  let fileType = file.type;
  fileType ||= getFileExtension({ file });
  const isImage = isImageFile(fileType);
  const { data } = useGetAttachment({
    ...getAttachmentProps({ file, domain }),
    enabled: isImage,
  });

  const [isLoading, setIsLoading] = useState(true);
  let fileName = getFileName(file);
  fileName ||= 'file.png';
  const url = data || file?.fileURL;

  return (
    <div className={classes.ImageModal} data-testid={`FileModal-${testid}`}>
      <SheetModal
        className={classes.modal}
        headerClassName={classes.modalHeaderToolbar}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        withCloseButton
        withRightCloseButton
        title={t('common:removeAttachmentTitle')}
        testid={testid}
        initialBreakpoint={1}
        footerButtons={[
          {
            variant: 'secondary',
            text: t('common:cancel'),
            onClick: () => {
              setIsOpen?.(false);
            },
            testid: 'cancel-button',
          },
          {
            variant: 'mipro-action',
            className: classes.removeButton,
            text: t('common:remove'),
            onClick: onRemoveClick,
            testid: 'remove-button',
          },
        ]}
      >
        {isImage && (
          <Loader
            className={classes.loader}
            text="Loading file"
            isOpen={isLoading}
          />
        )}
        <IonRow className={classes.removeConfirmMsg}>
          <Text
            text={`${t('common:fileRemoveConfirm', {
              fileName,
            })} ${t('common:fileRemoveInfo')}`}
            textQuery={t('common:fileRemoveInfo')}
            variant="mipro-body-copy"
          />
        </IonRow>
        {!isNil(url) && isImage && (
          <div className={classes.imageContainer}>
            <TransformWrapper centerOnInit wheel={{ step: 0.07 }}>
              <TransformComponent wrapperClass={classes.zoomWrapper}>
                <img
                  src={encodeURI(url)}
                  onLoad={() => setIsLoading(false)}
                  alt={fileName}
                />
              </TransformComponent>
            </TransformWrapper>
          </div>
        )}
        {!isImage && (
          <>
            <IonRow class={classes.fileIconRow}>
              <div className={classes.fileCont}>
                <FontAwesomeIcon
                  className={classes.fileImg}
                  icon={iconFinder(fileType)}
                />
              </div>
            </IonRow>
            <IonRow className={classes.fileNameRow}>
              <Text
                className={classes.fileName}
                variant="mipro-body-copy-bold"
                text={fileName}
              />
            </IonRow>
          </>
        )}
      </SheetModal>
    </div>
  );
};

export default FileRemoveModal;
