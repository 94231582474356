import { useSelector } from 'react-redux';
import type { AxiosInstance, CancelToken, RawAxiosRequestHeaders } from 'axios';
import axios from 'axios';
import type { Dictionary, ListIterateeCustom } from 'lodash';
import {
  isObject,
  reduce,
  isArray,
  filter,
  toNumber,
  flatten,
  includes,
  toUpper,
  orderBy,
  toString,
  head,
  size,
  find,
  findIndex,
  forEach,
  get,
  isNil,
  map,
} from 'lodash';
import type {
  InfiniteData,
  InvalidateQueryFilters,
  Query,
  QueryClient,
  QueryKey,
  UseQueryResult,
} from '@tanstack/react-query';
import type { QueryParamsType } from 'common/api/utils/useGetQueryFlags';
import type { OptionalComponentProps } from 'common/components/utils/renderHelpers';
import { or } from 'common/utils/logicHelpers';
import type { FastFindAPIResponse } from 'DocumentsApp/models/FastFind';
import type { RootState } from 'store/reducers';
import { downloadPageSize, pageSize } from 'utils/constants';
import { isNumericString } from 'utils/helpers';

export function doPromiseAPI<ResponseType = void>(
  fn: (cancelToken: CancelToken) => Promise<ResponseType>,
  cancelMessage = 'API request was cancelled.'
): Promise<ResponseType> {
  if (process.env.APP_ENV === 'localtest') {
    // eslint-disable-next-line no-console
    console.error('API called in frontend test');
  }
  const source = axios.CancelToken.source();
  const promise = new Promise<ResponseType>((resolve, reject) => {
    void (async () => {
      try {
        resolve(await fn(source.token));
      } catch (error) {
        reject(error);
      }
    })();
  });
  promise.cancel = () => source.cancel(cancelMessage);
  return promise;
}

export function doConcatDataPages<ItemType, DataType = ItemType[]>(
  data?: InfiniteData<DataType>,
  key?: string
): ItemType[] {
  let items: ItemType[] = [];
  let page = 0;
  while (!isNil(data?.pages?.[page])) {
    items = [
      ...items,
      ...(get(data, `pages.${page}${key ? `.${key}` : ''}`, []) as ItemType[]),
    ];
    page += 1;
  }
  return items;
}

export const sleep = async (ms = 0) => {
  await new Promise((resolve) => setTimeout(() => resolve(''), ms));
};

// TODO: should support actual pagination with offline data?
export const getOfflineNextPage =
  (isOnline: boolean, limit = pageSize()) =>
  (lastPage: unknown[], pages: unknown[][]): boolean | number =>
    !isOnline || size(lastPage) < limit ? false : size(pages) + 1;

type DoGetIsLoadingProps<DataType> = UseQueryResult<DataType> & {
  dataPath?: string;
};

export function doGetIsLoading<DataType>({
  isLoading,
  isFetching,
  data,
  dataPath = '',
}: DoGetIsLoadingProps<DataType>): boolean {
  return (
    // TODO: this may be simplified using isFetched, more tests needed
    (isFetching && isLoading) || (isFetching && isNil(get(data, dataPath)))
  );
}

interface UseKeyUserIdResponse {
  userId: string;
  createQueryKey: (k: string, p?: Record<string, unknown>) => QueryKey;
}

export const useKeyUserId = (): UseKeyUserIdResponse => {
  const { userInfo } = useSelector((state: RootState) => state.user);
  const userId = get(userInfo, 'userid', '');

  const createQueryKey = (
    queryKey: string,
    queryKeyParams: Record<string, unknown> = {}
  ) => {
    const newQueryKeyParams = { ...queryKeyParams };
    if (isNil(newQueryKeyParams.$userId)) {
      newQueryKeyParams.$userId = userId;
    }
    return [queryKey, newQueryKeyParams];
  };

  return { userId, createQueryKey };
};

export const getQueryCacheKeys = (
  queryClient: QueryClient,
  queryKey: string,
  queryKeyParams?: Record<string, unknown>,
  queryKeyRegExp?: RegExp
): QueryKey[] => {
  const queryCache = queryClient.getQueryCache();
  const cacheQueries: QueryKey[] = [];
  forEach(
    // DOC: sort by latest updated for placehlder data to get the most recent
    orderBy(queryCache.getAll(), 'state.dataUpdatedAt', 'desc'),
    ({ queryKey: cQueryKey }: Query) => {
      if (
        or(
          cQueryKey[0] === queryKey,
          queryKeyRegExp?.test(toString(cQueryKey[0]))
        ) &&
        (!queryKeyParams || (queryKeyParams && find(cQueryKey, queryKeyParams)))
      ) {
        cacheQueries.push(cQueryKey);
      }
    }
  );
  return cacheQueries;
};

type GetPlaceholderData<ItemType> = {
  queryClient: QueryClient;
  queryKey?: string;
  queryKeyRegExp?: RegExp;
  queryKeyParams?: Record<string, unknown>;
  objectKey?: string;
  findPredicate: ListIterateeCustom<ItemType, boolean>;
};

export function getPlaceholderData<ItemType>({
  queryClient,
  queryKey = '',
  queryKeyRegExp,
  queryKeyParams,
  objectKey,
  findPredicate,
}: GetPlaceholderData<ItemType>): ItemType | undefined {
  const cacheQueries = getQueryCacheKeys(
    queryClient,
    queryKey,
    queryKeyParams,
    queryKeyRegExp
  );
  let foundItem: ItemType | undefined;
  forEach(cacheQueries, (key) => {
    if (!isNil(foundItem)) {
      return;
    }
    const queryData = queryClient.getQueryData(key);
    const infiniteData = queryData as InfiniteData<ItemType[]>;
    if (infiniteData?.pages) {
      let page = 0;
      while (isNil(foundItem) && !isNil(infiniteData?.pages[page])) {
        foundItem = find(
          get(
            infiniteData,
            `pages.${page}${objectKey ? `.${objectKey}` : ''}`,
            []
          ) as ItemType[],
          findPredicate
        );
        page += 1;
      }
    } else {
      foundItem = find(
        get(queryData, objectKey || '') as ItemType[],
        findPredicate
      );
    }
  });
  return foundItem;
}

interface UseMiLocOrTeamIdProps {
  miLoc?: string;
  sendTeamId?: boolean;
  sendVirtualTeamId?: boolean;
  teamSearch?: boolean;
}

interface UseMiLocOrTeamIdResponse {
  createParams: () => Dictionary<string>;
  getURLParams: (params: QueryParamsType) => string;
}

export const useMiLocOrTeamId = ({
  miLoc: itemMiLoc,
  sendTeamId = true,
  sendVirtualTeamId = false,
  teamSearch = false,
}: UseMiLocOrTeamIdProps): UseMiLocOrTeamIdResponse => {
  const {
    miLoc: stateMiLoc = '',
    fallbackMiLoc = '',
    locationTree,
  } = useSelector((state: RootState) => state.user);
  const miLoc = itemMiLoc || stateMiLoc;
  const location = locationTree?.[miLoc];

  // TODO: rename to createMiLocParams to better imply the values created in here
  const createParams = () => {
    const params: Dictionary<string> = {};
    if (location?.locationType === 'T') {
      if (sendTeamId) {
        // TODO suppliers support for teams
        params.teamId = toString(location?.teamId);
      } else {
        params.miLoc = toString(fallbackMiLoc);
      }
    } else if (location?.locationType === 'VT') {
      if (teamSearch) {
        params.teamSearch = 'true';
      }
      if (sendVirtualTeamId) {
        // TODO: change the return typing to string | string[], and fix parsing in all dependencies
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        params.teamId = filter(locationTree, { parent: location?.miLoc })
          .map((x) => x.teamId)
          .map((teamId) => toString(teamId));
      } else {
        params.miLoc = toString(fallbackMiLoc);
      }
    } else {
      params.miLoc = miLoc;
    }

    return params;
  };

  const getURLParams = (params: QueryParamsType) => {
    const paramsGET = new URLSearchParams();
    forEach(params, (param, key) => {
      if (isArray(param)) {
        forEach(param, (val) => {
          paramsGET.append(key, toString(val));
        });
      } else if (!isNil(param)) {
        paramsGET.append(key, toString(param));
      }
    });
    return toString(paramsGET);
  };

  return { createParams, getURLParams };
};

export interface StateMiLocArray {
  miLoc: string;
  locName: string;
}

interface UseGetSelectedMiLoc {
  selectedMiLoc: StateMiLocArray;
  stateMiLocArray: StateMiLocArray[];
  team: boolean;
  isBranchUser: boolean;
  isMyView: boolean;
  fromVirtualTeam: boolean;
  singleTeam?: StateMiLocArray;
}

export const useGetSelectedMiLoc = (
  propsMiLoc?: string
): UseGetSelectedMiLoc => {
  const { miLoc: stateMiLoc = '', locationTree } = useSelector(
    (state: RootState) => state.user
  );
  let miLoc = propsMiLoc;
  miLoc ||= stateMiLoc;
  const stateLocation = locationTree?.[miLoc];
  const isBranchUser = stateLocation?.userRole === 'BRCH';
  const isMyView = includes(stateLocation?.name, 'My View');
  const fromVirtualTeam = miLoc === 'VT';
  const team = stateLocation?.locationType === 'T';
  const teams = filter(locationTree, { parent: miLoc });
  const hasSingleTeam = size(teams) === 1;
  const firstTeam = head(teams);
  let stateMiLocArray = [
    {
      miLoc,
      locName: stateLocation?.name || '',
    },
  ];
  if (fromVirtualTeam && hasSingleTeam) {
    stateMiLocArray = [
      {
        miLoc: firstTeam?.miLoc || '',
        locName: firstTeam?.name || '',
      },
    ];
  }

  const selectedMiLoc = stateMiLocArray[0];

  return {
    selectedMiLoc,
    stateMiLocArray,
    team,
    isBranchUser,
    isMyView,
    fromVirtualTeam,
    singleTeam: hasSingleTeam ? selectedMiLoc : undefined,
  };
};

export const onSuccessMutation = async (
  queryClient: QueryClient,
  queryKey: string,
  queryKeyParams?: Record<string, unknown>,
  queryFilters?: InvalidateQueryFilters
): Promise<void> => {
  const cacheQueries = getQueryCacheKeys(queryClient, queryKey, queryKeyParams);
  await Promise.all(
    map(cacheQueries, (key) => queryClient.invalidateQueries(key, queryFilters))
  );
};

export interface UpdateMutationContext<ItemType> {
  updatedItem?: ItemType;
  isNewItem?: boolean;
  findPredicate?: ListIterateeCustom<ItemType, boolean>;
  removedItem?: ItemType;
  removedIndex?: number;
  fromKey: QueryKey;
  fromPage?: number;
}

interface OnUpdateProps {
  queryClient: QueryClient;
  dataPath?: string;
  isSingleQuery?: boolean;
  isArrayQuery?: boolean;
  isInfiniteQuery?: boolean;
}

// DOC: from https://gist.github.com/NinaScholz/2d13314a12034f959f1f
export function EBCDICSort<ItemType>(
  itemA: ItemType,
  itemB: ItemType,
  sortField: string
): number {
  const valueA = (itemA as unknown as Dictionary<string>)[sortField];
  const valueB = (itemB as unknown as Dictionary<string>)[sortField];

  const compare = (a: boolean, b: boolean) => a || b;
  const value = (a: boolean, b: boolean, c: number) => (a && b ? c : +a || -b);

  let i = 0;
  if (compare(valueA === null, valueB === null)) {
    return value(valueA === null, valueB === null, 0);
  }
  while (
    i < valueA.length &&
    i < valueB.length &&
    valueA[i].toLocaleLowerCase() === valueB[i].toLocaleLowerCase()
  ) {
    i += 1;
  }
  if (compare(isNumericString(valueA[i]), isNumericString(valueB[i]))) {
    return value(
      isNumericString(valueA[i]),
      isNumericString(valueB[i]),
      toNumber(valueA[i]) - toNumber(valueB[i])
    );
  }
  return valueA.localeCompare(valueB);
}

type SortByFunction<ItemType> = (
  itemA: ItemType,
  itemB: ItemType,
  sortField: string
) => number;

type SortPredicate<ItemType> =
  | boolean
  | { sortField?: string; sortDir?: string; sortBy?: SortByFunction<ItemType> };

type FilterByFunction<ItemType> = (
  item: ItemType,
  fromKey: QueryKey
) => boolean;

type OnMutateUpdateProps<ItemType> = {
  queryKey: string;
  queryKeyParams?: Record<string, unknown>;
  newItems?: ItemType[];
  updatedItems?: OptionalComponentProps<ItemType>[];
  findPredicates?: ListIterateeCustom<ItemType, boolean>[];
  sortPredicate?: SortPredicate<ItemType>;
  filterPredicate?: FilterByFunction<OptionalComponentProps<ItemType>>;
  updateAll?: boolean;
  removedFindPredicates?: ListIterateeCustom<ItemType, boolean>[];
  markAsUpdated?: boolean;
} & OnUpdateProps;

type UpdateItemUtilProps<ItemType> = {
  prev: ItemType[];
  item: OptionalComponentProps<ItemType>;
  context: UpdateMutationContext<ItemType>[];
  findPredicate: ListIterateeCustom<ItemType, boolean>;
  fromKey: QueryKey;
  fromPage?: number;
  updateAll?: boolean;
  markAsUpdated?: boolean;
};

function updateItemUtil<ItemType>({
  prev,
  item,
  context,
  findPredicate,
  fromKey,
  fromPage,
  updateAll = false,
  markAsUpdated = true,
}: UpdateItemUtilProps<ItemType>): ItemType[] {
  if (findPredicate) {
    const itemIndex = findIndex(prev, findPredicate);
    if (itemIndex !== -1) {
      const prevItem = prev[itemIndex];
      context.push({
        updatedItem: prevItem,
        findPredicate,
        fromKey,
        fromPage,
      });
      return [
        ...prev.slice(0, itemIndex),
        { ...prevItem, ...item, isOptimisticallyUpdating: markAsUpdated },
        ...prev.slice(itemIndex + 1),
      ];
    }
  }
  if (updateAll) {
    return map(prev, (prevItem) => {
      context.push({
        updatedItem: prevItem,
        findPredicate: reduce(
          Object.keys(prevItem as object),
          (partial, prop) => {
            const value = get(prevItem, prop) as unknown;
            if (!isArray(value) && !isObject(value) && !get(item, prop)) {
              return { ...partial, [prop]: value };
            }
            return partial;
          },
          {}
        ),
        fromKey,
        fromPage,
      });
      return { ...prevItem, ...item };
    });
  }
  return prev;
}

function sortItemUtil<ItemType>(
  pages: ItemType[][],
  sortPredicate: SortPredicate<ItemType>,
  fromKey: QueryKey
): ItemType[][] {
  let sortField = 'sortField';
  let sortDir = 'sortDir';
  if (typeof sortPredicate === 'object') {
    sortField = sortPredicate.sortField || sortField;
    sortDir = sortPredicate.sortDir || sortDir;
  }
  // concat all pages and sort based on query-key
  let sortedPages: ItemType[] = [];
  const sortFieldFromKey = toString(get(fromKey, `1.${sortField}`));
  const sortDirFromKey = includes(
    ['ASC', 'ASCENDING'],
    toUpper(toString(get(fromKey, `1.${sortDir}`)))
  )
    ? 'asc'
    : 'desc';
  if (typeof sortPredicate === 'object' && sortPredicate.sortBy) {
    const fnSortBy = sortPredicate.sortBy;
    sortedPages = flatten(pages).sort((itemA, itemB) =>
      fnSortBy(itemA, itemB, sortFieldFromKey)
    );
    if (sortDirFromKey === 'desc') {
      sortedPages.reverse();
    }
  } else {
    sortedPages = orderBy(flatten(pages), sortFieldFromKey, sortDirFromKey);
  }
  const emptyPages: ItemType[][] = [];
  forEach(pages, (v, i) => {
    if (i !== 0) {
      emptyPages.push([]);
    }
  });
  return [sortedPages, ...emptyPages];
}

export async function onMutateUpdate<ItemType>({
  queryClient,
  queryKey,
  queryKeyParams,
  dataPath,
  newItems = [],
  updatedItems = [],
  findPredicates = [],
  sortPredicate,
  filterPredicate,
  updateAll = false,
  removedFindPredicates = [],
  isSingleQuery = false,
  isArrayQuery = false,
  isInfiniteQuery = false,
  markAsUpdated = true,
}: OnMutateUpdateProps<ItemType>): Promise<UpdateMutationContext<ItemType>[]> {
  const cacheQueries = getQueryCacheKeys(queryClient, queryKey, queryKeyParams);
  await Promise.all(map(cacheQueries, (key) => queryClient.cancelQueries(key)));
  const context: UpdateMutationContext<ItemType>[] = [];
  forEach(cacheQueries, (key) => {
    if (isSingleQuery) {
      forEach(updatedItems, (item, index) => {
        queryClient.setQueryData<ItemType>(key, (prev) => {
          const prevItem = prev as ItemType;
          const findPredicate = findPredicates[index];
          if (findPredicate) {
            const itemIndex = findIndex([prevItem], findPredicate);
            if (itemIndex !== -1) {
              context.push({
                updatedItem: prevItem,
                fromKey: key,
              });
              return { ...prev, ...item } as ItemType;
            }
          }
          if (updateAll) {
            context.push({
              updatedItem: prevItem,
              fromKey: key,
            });
            return { ...prev, ...item } as ItemType;
          }
          return prevItem;
        });
      });
    }
    // TODO: support dataPath, updateAll
    if (isArrayQuery) {
      forEach(updatedItems, (item, index) => {
        queryClient.setQueryData<ItemType[]>(key, (prev = []) =>
          updateItemUtil({
            prev,
            item,
            context,
            findPredicate: findPredicates[index],
            fromKey: key,
            markAsUpdated,
          })
        );
      });
      forEach(newItems, (item) => {
        queryClient.setQueryData<ItemType[]>(key, (prev = []) => {
          context.push({
            updatedItem: item,
            isNewItem: true,
            findPredicate: { ...item } as ListIterateeCustom<ItemType, boolean>,
            fromKey: key,
          });
          return [...prev, { ...item }];
        });
      });
      forEach(removedFindPredicates, (findPredicate) => {
        queryClient.setQueryData<ItemType[]>(key, (prev = []) => {
          const itemIndex = findIndex(prev, findPredicate);
          if (itemIndex !== -1) {
            context.push({
              removedItem: prev[itemIndex],
              removedIndex: itemIndex,
              fromKey: key,
            });
            return [...prev.slice(0, itemIndex), ...prev.slice(itemIndex + 1)];
          }
          return prev;
        });
      });
    }
    if (isInfiniteQuery) {
      forEach(updatedItems, (item, index) => {
        queryClient.setQueryData<InfiniteData<ItemType[]>>(key, (prev) => {
          const pages: ItemType[][] = [];
          forEach(prev?.pages, (page, pIndex) => {
            const dataPage = dataPath
              ? (get(page, dataPath) as ItemType[])
              : page;
            const newPage = updateItemUtil({
              prev: dataPage,
              item,
              context,
              findPredicate: findPredicates[index],
              fromKey: key,
              fromPage: pIndex,
              updateAll,
              markAsUpdated,
            });
            const pageToPush = dataPath
              ? { ...page, [dataPath]: newPage }
              : newPage;
            // DOC: filtering occurs after update so it filters over the new data
            // when filtering, updated items should be removed and added to context for rollback
            // the item to be rolledback is the one in the original page
            if (filterPredicate) {
              forEach(
                filter(newPage, (itm) => !filterPredicate(itm, key)),
                () => {
                  const itemIndex = findIndex(dataPage, findPredicates[index]);
                  if (itemIndex !== -1) {
                    const removedItem = find(dataPage, findPredicates[index]);
                    context.push({
                      removedItem,
                      removedIndex: itemIndex,
                      fromKey: key,
                      fromPage: pIndex,
                    });
                  }
                }
              );
              pages.push(
                filter(pageToPush, (itm) => filterPredicate(itm, key))
              );
            } else {
              pages.push(pageToPush);
            }
          });
          if (sortPredicate) {
            return {
              pageParams: prev?.pageParams as unknown[],
              // TODO: support dataPath
              pages: sortItemUtil(pages, sortPredicate, key),
            };
          }
          return { pageParams: prev?.pageParams as unknown[], pages };
        });
      });
      // TODO: support dataPath
      forEach(newItems, (item) => {
        queryClient.setQueryData<InfiniteData<ItemType[]>>(key, (prev) => {
          const pages: ItemType[][] = [];
          forEach(prev?.pages, (page) => pages.push(page));
          context.push({
            updatedItem: item,
            isNewItem: true,
            findPredicate: { ...item } as ListIterateeCustom<ItemType, boolean>,
            fromKey: key,
            fromPage: 0,
          });
          pages[0] = [
            ...(head(pages) || []),
            { ...item, isOptimisticallyUpdating: markAsUpdated },
          ];
          if (sortPredicate) {
            return {
              pageParams: prev?.pageParams as unknown[],
              pages: sortItemUtil(pages, sortPredicate, key),
            };
          }
          return { pageParams: prev?.pageParams as unknown[], pages };
        });
      });
      // TODO: support dataPath
      forEach(removedFindPredicates, (findPredicate) => {
        queryClient.setQueryData<InfiniteData<ItemType[]>>(key, (prev) => {
          const pages: ItemType[][] = [];
          forEach(prev?.pages, (page, pIndex) => {
            const itemIndex = findIndex(page, findPredicate);
            if (itemIndex !== -1) {
              context.push({
                removedItem: page[itemIndex],
                removedIndex: itemIndex,
                fromKey: key,
                fromPage: pIndex,
              });
              return pages.push([
                ...page.slice(0, itemIndex),
                ...page.slice(itemIndex + 1),
              ]);
            }
            return pages.push(page);
          });
          return { pageParams: prev?.pageParams as unknown[], pages };
        });
      });
    }
  });
  return context;
}

type OnErrorUpdateProps<ItemType> = {
  context?: UpdateMutationContext<ItemType>[];
  pickProps?: string[];
  sortPredicate?: SortPredicate<ItemType>;
} & OnUpdateProps;

function pickPropsFromItem<ItemType>(item: ItemType, pickProps?: string[]) {
  let rolledbackItem = {} as ItemType;
  if (size(pickProps) > 0) {
    forEach(pickProps, (prop) => {
      const key = prop as keyof ItemType;
      rolledbackItem[key] = item[key];
    });
  } else {
    rolledbackItem = { ...item };
  }
  return rolledbackItem;
}

function rollbackItemUtil<ItemType>(
  prev: ItemType[],
  item: ItemType,
  isNewItem?: boolean,
  findPredicate?: ListIterateeCustom<ItemType, boolean>,
  pickProps?: string[]
): ItemType[] {
  const rolledbackItem = pickPropsFromItem(item, pickProps);
  const itemIndex = findIndex(prev, findPredicate);
  if (itemIndex !== -1) {
    if (isNewItem) {
      return [...prev.slice(0, itemIndex), ...prev.slice(itemIndex + 1)];
    }
    return [
      ...prev.slice(0, itemIndex),
      {
        ...prev[itemIndex],
        ...rolledbackItem,
        isOptimisticallyUpdating: false,
      },
      ...prev.slice(itemIndex + 1),
    ];
  }
  return prev;
}

export function onErrorUpdate<ItemType>({
  queryClient,
  context,
  pickProps,
  sortPredicate,
  // TODO: can we take dataPath from context instead?
  // idea: context as an object and not just an array,
  // should include flags as well to avoid coding errors with mistmaches isArray/isInfinite
  dataPath,
  isSingleQuery = false,
  isArrayQuery = false,
  isInfiniteQuery = false,
}: OnErrorUpdateProps<ItemType>): void {
  forEach(
    context,
    ({
      updatedItem,
      isNewItem,
      findPredicate,
      removedItem,
      removedIndex,
      fromKey,
      fromPage,
    }) => {
      if (updatedItem) {
        // TODO: support dataPath
        if (isSingleQuery) {
          queryClient.setQueryData<ItemType>(fromKey, (prev) => {
            const rolledbackItem = pickPropsFromItem(updatedItem, pickProps);
            return { ...prev, ...rolledbackItem };
          });
        }
        // TODO: support dataPath
        if (isArrayQuery) {
          queryClient.setQueryData<ItemType[]>(fromKey, (prev = []) =>
            rollbackItemUtil(
              prev,
              updatedItem,
              isNewItem,
              findPredicate,
              pickProps
            )
          );
        }
        if (isInfiniteQuery) {
          queryClient.setQueryData<InfiniteData<ItemType[]>>(
            fromKey,
            (prev) => {
              const pages: ItemType[][] = [];
              forEach(prev?.pages, (page) => {
                const dataPage = dataPath
                  ? (get(page, dataPath) as ItemType[])
                  : page;
                const newPage = rollbackItemUtil(
                  dataPage,
                  updatedItem,
                  isNewItem,
                  findPredicate,
                  pickProps
                );
                const pageToPush = dataPath
                  ? { ...page, [dataPath]: newPage }
                  : newPage;
                return pages.push(pageToPush);
              });
              if (sortPredicate) {
                return {
                  pageParams: prev?.pageParams as unknown[],
                  // TODO: support dataPath
                  pages: sortItemUtil(pages, sortPredicate, fromKey),
                };
              }
              return { pageParams: prev?.pageParams as unknown[], pages };
            }
          );
        }
      }
      // TODO: support dataPath
      if (removedItem) {
        if (isArrayQuery) {
          queryClient.setQueryData<ItemType[]>(fromKey, (prev = []) => [
            ...prev.slice(0, removedIndex),
            removedItem,
            ...prev.slice(removedIndex),
          ]);
        }
        if (isInfiniteQuery) {
          queryClient.setQueryData<InfiniteData<ItemType[]>>(
            fromKey,
            (prev) => {
              const pages: ItemType[][] = [];
              forEach(prev?.pages, (page, index) => {
                if (index === fromPage) {
                  return pages.push([
                    ...page.slice(0, removedIndex),
                    removedItem,
                    ...page.slice(removedIndex),
                  ]);
                }
                return pages.push(page);
              });
              return { pageParams: prev?.pageParams as unknown[], pages };
            }
          );
        }
      }
    }
  );
}

interface OnDownloadDataProps<ItemType> {
  customAxios: AxiosInstance;
  getAPIUrl: (p: string) => string;
  params: Dictionary<string | string[]>;
  headers?: RawAxiosRequestHeaders;
  getData?: (response: unknown) => ItemType[];
  method?: string;
  limit?: number;
}

export async function onDownloadData<ItemType>({
  customAxios,
  getAPIUrl,
  params,
  headers,
  getData,
  method = 'post',
  limit = downloadPageSize(),
}: OnDownloadDataProps<ItemType>) {
  const syncData: ItemType[] = [];
  let totalRows = 0;

  const requestData = async (page: number) => {
    const paramsGET = new URLSearchParams({
      page: toString(page),
      limit: toString(limit),
    });
    Object.entries(params).forEach((item) => {
      if (isArray(item[1])) {
        forEach(item[1], (v) => paramsGET.append(item[0], v));
      } else {
        paramsGET.append(item[0], item[1]);
      }
    });

    const response = await customAxios({
      method,
      url: getAPIUrl(toString(paramsGET)),
      data: null,
      headers,
    });
    totalRows = (response.data as FastFindAPIResponse<ItemType>).totalRows;
    syncData.push(
      ...(getData?.(response.data) ||
        (response.data as FastFindAPIResponse<ItemType>).rows ||
        [])
    );
    // DOC: recursion to ask for more pages
    if (size(syncData) === limit * page) {
      await requestData(page + 1);
    }
  };
  // DOC: request first page
  await requestData(1);
  return { syncData, totalRows };
}

export const getAPIHeadersV2 = () => ({
  // DOC: this indicates the API to use new version of endpoint
  'content-type': 'application/vnd.mipro.v2+json',
});

export const getAPIHeaders = () => ({
  // DOC: this indicates the API to use new version of endpoint
  'content-type': 'application/json',
});
