import type { AxiosError } from 'axios';
import { isEmpty, isNil, toString } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { doPromiseAPI, useKeyUserId, useMiLocOrTeamId } from 'api/helpers';
import type { QueryFlags } from 'models/Search';
import type {
  Supplier,
  SupplierNotebookTypeEnum,
  SupplierNotebook,
} from 'models/Supplier';
import useGetSupplier from './useGetSupplier';

export const getSupplierNotebookQueryKey = 'supplier-notebooks';

interface UseGetSupplierNotebooksProps {
  nbType: SupplierNotebookTypeEnum;
  id: string;
}

interface UseGetSupplierNotebookResponse {
  notebooks?: SupplierNotebook[];
  supplierData?: Supplier;
}

const useGetSupplierNotebooks = ({
  nbType,
  id,
}: UseGetSupplierNotebooksProps): UseGetSupplierNotebookResponse &
  QueryFlags => {
  const { axios } = useAxios();
  const { getSupplierNotebooksAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const { createParams } = useMiLocOrTeamId({ sendTeamId: false });
  const params = { ...createParams() };
  const { miLoc } = params;

  const { data: supplierData } = useGetSupplier({
    searchType: 'supplier',
    id,
  });

  const doGetSupplierNotebooks = () => {
    return doPromiseAPI<SupplierNotebook[]>(async (cancelToken) => {
      const { data } = await axios.get<SupplierNotebook[]>(
        getSupplierNotebooksAPI(
          miLoc,
          toString(supplierData?.mfrCtlNo),
          id,
          nbType
        ),
        { cancelToken }
      );
      return data;
    });
  };

  const {
    data: notebooks,
    error,
    status,
    isFetching,
  } = useQuery<SupplierNotebook[], AxiosError>(
    createQueryKey(getSupplierNotebookQueryKey, { miLoc, id, nbType }),
    doGetSupplierNotebooks,
    { enabled: !isEmpty(supplierData?.mfrCtlNo) }
  );

  return {
    error,
    notebooks,
    supplierData,
    isLoading:
      status === 'loading' ||
      isNil(supplierData) ||
      (isFetching && isNil(notebooks)),
  };
};

export default useGetSupplierNotebooks;
