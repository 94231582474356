import React from 'react';
import { map } from 'lodash';
import { IonRow } from '@ionic/react';
import type { FilterOption } from 'components/Filter/Filter';
import SegmentTabs from 'components/SegmentTabs/SegmentTabs';
import classes from './GroupBy.module.scss';

interface GroupByProps {
  groupByOptions: FilterOption[];
  selectedGroupBy: FilterOption;
  onGroupBy: (value: string) => void;
}

const GroupBy = ({
  groupByOptions,
  selectedGroupBy,
  onGroupBy,
}: GroupByProps): JSX.Element => {
  return (
    <IonRow className={classes.wrapper}>
      <SegmentTabs
        className={classes.tabs}
        selectedClassName={classes.selectedTab}
        value={selectedGroupBy.key}
        setValue={onGroupBy}
        options={map(groupByOptions, (group) => ({
          key: group.key,
          text: group.name,
        }))}
        testid="groupby-tabs"
        variant="roundedTabs"
        isScrollable
      />
    </IonRow>
  );
};

export default GroupBy;
