import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toString, toNumber, min, max } from 'lodash';
import type { BarDatum } from '@nivo/bar';
import { ResponsiveBar } from '@nivo/bar';
import { formatNumber } from 'common/utils/numberHelper';
import { namespaces } from 'i18n/i18n.constants';
import type { BarColumnData } from 'components/Charts/DailyChart/Chart';
import classes from './AccountsReceivableWidget.module.scss';

export interface ArReportDatum extends BarDatum {
  id: string;
}

type AccountsReceivableWidgetProps = {
  arCurrentData: BarColumnData;
  arBeyondTermsData: BarColumnData;
  currencyType?: string;
};

const AccountsReceivableWidget = ({
  arCurrentData,
  arBeyondTermsData,
  currencyType,
}: AccountsReceivableWidgetProps): JSX.Element => {
  const [data, setData] = useState<ArReportDatum[]>([]);
  const { t } = useTranslation(namespaces.ar);
  useEffect(() => {
    setData([
      {
        id: 'arData',
        current: arCurrentData.value,
        currentColor: 'green',
        beyondTerms: arBeyondTermsData.value,
        beyondTermsColor: 'red',
      },
    ]);
  }, [arCurrentData, arBeyondTermsData]);

  return (
    <div
      className={classes.chartWrapper}
      data-testid="accounts-receivable-widget"
    >
      <ResponsiveBar
        data={data}
        margin={{ top: 0, bottom: 40 }}
        keys={['current', 'beyondTerms']}
        groupMode="stacked"
        indexBy="id"
        layout="horizontal"
        minValue={min([arCurrentData.value, arBeyondTermsData.value, 0])}
        maxValue={max([
          arCurrentData.value,
          arBeyondTermsData.value,
          arCurrentData.value + arBeyondTermsData.value,
        ])}
        colors={({ id, data: d }) => toString(d[`${id}Color`])}
        labelTextColor="transparent"
        enableGridX={false}
        enableGridY={false}
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        axisLeft={null}
        isInteractive={false}
        animate={false}
        legendLabel={(key) =>
          t(toString(key.id), {
            currency: formatNumber({
              number: toNumber(key.value),
              currencyType,
            }),
          })
        }
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'bottom-left',
            direction: 'column',
            justify: false,
            translateY: 41,
            itemsSpacing: 0,
            itemWidth: 120,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 1,
            symbolSize: 16,
          },
        ]}
      />
    </div>
  );
};

export default AccountsReceivableWidget;
