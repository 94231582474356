import { isNil } from 'lodash';
import { Preferences } from '@capacitor/preferences';
import type { WhoAmIResponse } from 'models/Signin';

const USER_INFO_KEY = 'UserInfo';

const UserInfoStorage = {
  async get(): Promise<WhoAmIResponse | undefined> {
    const result = await Preferences.get({ key: USER_INFO_KEY });
    if (!isNil(result.value)) {
      return JSON.parse(result.value) as WhoAmIResponse;
    }
    return undefined;
  },
  async set(userInfo?: WhoAmIResponse): Promise<void> {
    if (!isNil(userInfo)) {
      await Preferences.set({
        key: USER_INFO_KEY,
        value: JSON.stringify(userInfo),
      });
    }
  },
  remove(): Promise<void> {
    return Preferences.remove({ key: USER_INFO_KEY });
  },
};

export default UserInfoStorage;
