import type { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import type {
  QueryFnProps,
  QueryFlags,
} from 'common/api/utils/useGetQueryFlags';
import useGetQueryFlags from 'common/api/utils/useGetQueryFlags';
import type { OcnLineStatus } from 'ProductSearchApp/models/Order';
import { useAxios } from 'providers/AxiosProvider';
import { useKeyUserId } from 'api/helpers';

export const findOcnLineStatusQueryKey = 'order-line-status';

interface UseFindOcnLineStatusProps {
  miLoc: string;
  orderCtlNo: string;
  orderLineNo: string;
  enabled?: boolean;
}

export interface UseFindOcnLineStatusResponse extends QueryFlags {
  data?: OcnLineStatus;
}

const useFindOcnLineStatus = ({
  miLoc,
  orderCtlNo,
  orderLineNo,
  enabled = true,
}: UseFindOcnLineStatusProps): UseFindOcnLineStatusResponse => {
  const { axios } = useAxios();
  const { getOrderLineStatusAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const params = {
    miLoc,
    orderCtlNo,
    orderLineNo,
  };

  const doFindOcnLineStatus = async ({ signal }: QueryFnProps) => {
    const { data } = await axios.get<OcnLineStatus>(
      getOrderLineStatusAPI(miLoc, orderCtlNo, orderLineNo),
      { signal }
    );
    return data;
  };

  const response = useQuery<OcnLineStatus, AxiosError>(
    createQueryKey(findOcnLineStatusQueryKey, params),
    doFindOcnLineStatus,
    { enabled }
  );

  const queryFlags = useGetQueryFlags({ ...response, enabled });

  return {
    ...queryFlags,
  };
};

export default useFindOcnLineStatus;
