import React, { useEffect, useState } from 'react';
import { map } from 'lodash';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import useFindEmployees from 'api/employee/useFindEmployees';
import type { Employee } from 'models/Employee';
import type { SelectModalItem } from 'models/Search';
import SelectModal from 'components/SelectModal/SelectModal';

interface EmployeeSelectProps {
  selItem?: SelectModalItem;
  className?: string;
  label: string;
  required?: boolean;
  iconClassName?: string;
  placeholder: string;
  disabled?: boolean;
  setEmployee: (o: SelectModalItem) => void;
  showInput?: boolean;
  openModal?: boolean;
  setOpenModal?: (b: boolean) => void;
  onBlur?: () => void;
  inputError?: string;
}

const EmployeeSelect = ({
  selItem,
  className,
  label,
  required,
  iconClassName,
  placeholder,
  disabled,
  setEmployee,
  showInput = true,
  openModal = false,
  setOpenModal,
  onBlur,
  inputError,
}: EmployeeSelectProps & IonicReactProps): JSX.Element => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const {
    error,
    items,
    isEmptyResponse,
    showLoader,
    fetchNextPage,
    enableInfiniteScroll,
  } = useFindEmployees({
    query: searchQuery,
    enabled: searchQuery.length > 0,
  });

  useEffect(() => {
    if (!isOpen && !openModal) {
      setSearchQuery('');
    }
  }, [isOpen, openModal, searchQuery, setSearchQuery]);

  const employeeItems = map(items, (i) => {
    const item = i as unknown as Employee;
    return {
      item,
      key: item.id,
      title: item.name,
      description: `${item.jobPosition} ${item.miLoc}`,
    };
  });

  const inputDisplay = selItem && selItem.key.length > 0 ? selItem.title : '';

  return (
    <SelectModal
      isOpen={setOpenModal ? openModal : isOpen}
      setIsOpen={setOpenModal || setIsOpen}
      type="employee"
      subType=""
      selItem={selItem || { title: '', description: '', key: '' }}
      setSelItem={(o) => {
        setTimeout(() => {
          setEmployee(o);
        }, 100);
      }}
      testid="select-employee-modal"
      isEmptyResponse={isEmptyResponse}
      showLoader={showLoader && searchQuery.length > 0}
      fetchNextPage={fetchNextPage}
      error={error}
      enableInfiniteScroll={enableInfiniteScroll}
      items={employeeItems}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      onClick={() => setIsOpen(true)}
      onBlur={onBlur}
      inputError={inputError}
      label={label}
      required={required}
      disabled={disabled}
      value={inputDisplay}
      placeholder={placeholder}
      iconClassName={iconClassName}
      showInput={showInput}
      className={className}
      icon="caret-down"
    />
  );
};

export default EmployeeSelect;
