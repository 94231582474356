import type { WithOptimisticUI } from 'models/Search';

export interface CountGroupItem extends WithOptimisticUI {
  uniqueId: string;
  countPlanId?: string;
  groupId?: string;
  hasCount?: boolean;
  miLoc?: string;
  actualCost: number;
  actualCount: string;
  barcode: string;
  bin: string;
  custStockNo: string;
  description: string;
  groupSerialNo: string;
  itemNo: string;
  lineNo: number;
  loc1: string;
  loc2: string;
  loc3: string;
  loc4: string;
  loc5: string;
  lotNo: number;
  mfrCtlNo: string;
  miMaxQty: number;
  miMinQty: number;
  mino: string;
  upcNo: string;
  uom: string;
  onOrderQty: string;
  orderQty: number;
  pageNo: number;
  type: string;
  unprocessedReceiptQty: number;
  unprocessedUsageQty: number;
  actualItemWidth?: number;
  actualItemLength?: number;
  rowNbr: string;
  slabRef: string;
  updRec: string;
  mfrName: string;
  hasEditedMinMax?: boolean;
  allowEdit?: boolean;
  hasLocalCount?: boolean;
}

export interface CountGroup extends WithOptimisticUI {
  items?: CountGroupItem[];
  uniqueId: string;
  amtPctDiff: string;
  assignedEmpId: string;
  assignedEmpId2: string;
  assignedUserId: string;
  assignedUserId2: string;
  completedBy: string;
  confirmInput: string;
  consign: string;
  countCompleted: string;
  countDownloaded: string;
  countFinalized: string;
  countPlanId: string;
  countPrinted: string;
  countType: string;
  countTypeDescription: string;
  createdBy: string;
  custAcct: string;
  customerName: string;
  customerNo: string;
  billCustomerNo?: string;
  billCustomerName?: string;
  dateCompleted: string;
  dateCreated: string;
  dateDeleted: string;
  dateDownloaded: string;
  dateFinalized: string;
  datePrinted: string;
  defDeptNo: string;
  defHdg1: string;
  defHdg2: string;
  defHdg3: string;
  defHdg4: string;
  defHdg5: string;
  defaultAcctNo: string;
  defaultCustomerNo: string;
  deletedBy: string;
  description: string;
  disableOnOrderFl: string;
  downloadedBy: string;
  finalizedBy: string;
  groupId: string;
  groupType: string;
  issues: string;
  itemClassCd: string;
  labelAtOrd: string;
  langCd: string;
  lines: string;
  linesUpdated: string;
  locHdg1: string;
  locHdg2: string;
  locHdg3: string;
  locHdg4: string;
  locHdg5: string;
  manualLines: string;
  manualLinesUpdated: string;
  manualPages: string;
  manualPagesEntered: string;
  miLoc: string;
  model: string;
  name: string;
  oneLocFlag: string;
  optionMaint: string;
  orderItemLimit: string;
  orders: string;
  pages: string;
  pagesEntered: string;
  partIdentCD: string;
  passThru: string;
  planEndDate: string;
  planName: string;
  planStartDate: string;
  printedBy: string;
  qtyPctDiff: string;
  receipts: string;
  replen: string;
  seqOpiOrdersBy: string;
  splitOrdBySrc: string;
  sortSequenceName: string;
  localSortField: string;
  localSortDir: string;
  startScanCd: string;
  startedBy: string;
  status: string;
  storeroomCustomerNo: string;
  storeroomName: string;
  storeroomNo: string;
  systemLines: string;
  systemLinesUpdated: string;
  systemPages: string;
  systemPagesEntered: string;
  teamMember1: string;
  teamMember2: string;
  timeCompleted: string;
  timeCreated: string;
  timeDeleted: string;
  timeDownloaded: string;
  timeFinalized: string;
  timePrinted: string;
  isDeleted?: boolean;
  downloadedItems?: number;
}

interface CountPlanDetail {
  groups?: CountGroup[];
  groupedLines: string;
  manualLines: string;
  manualPages: string;
  totalLines: string;
  totalPages: string;
  totalRows: number;
  unGroupedLines: string;
  updatedLines: string;
  updatedManualLines: string;
  updatedManualPages: string;
  updatedPages: string;
}

export interface CountPlan extends CountPlanDetail {
  countPlanId: string;
  countType: string;
  miLoc: string;
}

export interface CreateCountPlanAPIResponse {
  countGroups: CountPlanDetail;
  countPlanId: string;
  countType: string;
  miLoc: string;
  planOption: CountPlanOptions;
}

export interface CreatePlanAndGroupsURLParams {
  miLoc: string;
  customerNo: string;
  countPlanId: string;
  groupId: string;
  groupUniqueId: string;
}

export interface CountPlanOptions {
  canGroupItems: string;
  closeAfterEmail: string;
  consign: string;
  forceCountAllItems: string;
  hasEmail: string;
  hasManualSheets: string;
  createOrder: string;
  reviewVariance: string;
  source: string;
  countPlanId?: string;
}

export enum CountGroupListTypeEnum {
  pending = 'pending',
  newInventory = 'newInventory',
}

export interface ProfileDefaultSort {
  uniqueId: string;
  miLoc: string;
  customerNo: string;
  sortField: string;
  sortDir: string;
}

export interface UpdateCountGroupItemsResponse {
  success: boolean;
  message: string;
}
