import type { AxiosError } from 'axios';
import { isEmpty, isNil, toString } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { doPromiseAPI, useKeyUserId, useMiLocOrTeamId } from 'api/helpers';
import useGetSupplier from 'api/supplier/useGetSupplier';
import type { QueryFlags } from 'models/Search';
import type { InventoryAnalysis, Supplier } from 'models/Supplier';

export const getInventoryAnalysisQueryKey = 'supplier-inventory-analysis';

interface UseGetInventoryAnalysisProps {
  id: string;
}

interface UseGetInventoryAnalysisResponse {
  inventoryAnalysis?: InventoryAnalysis;
  supplierData?: Supplier;
}

const useGetInventoryAnalysis = ({
  id,
}: UseGetInventoryAnalysisProps): UseGetInventoryAnalysisResponse &
  QueryFlags => {
  const { axios } = useAxios();
  const { getInventoryAnalysisAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const { createParams } = useMiLocOrTeamId({ sendTeamId: false });
  const params = { ...createParams() };
  const { miLoc } = params;

  const { data: supplierData } = useGetSupplier({ searchType: 'supplier', id });

  const doGetInventoryAnalysis = () => {
    return doPromiseAPI<InventoryAnalysis>(async (cancelToken) => {
      const { data } = await axios.get<InventoryAnalysis>(
        getInventoryAnalysisAPI(
          miLoc,
          id,
          toString(
            new URLSearchParams({
              mfrCtlNo: toString(supplierData?.mfrCtlNo),
            })
          )
        ),
        { cancelToken }
      );
      return data;
    });
  };

  const {
    data: inventoryAnalysis,
    error,
    status,
    isFetching,
  } = useQuery<InventoryAnalysis, AxiosError>(
    createQueryKey(getInventoryAnalysisQueryKey, { miLoc, id }),
    doGetInventoryAnalysis,
    { enabled: !isEmpty(supplierData?.mfrCtlNo) }
  );

  return {
    error,
    inventoryAnalysis,
    supplierData,
    isLoading:
      status === 'loading' ||
      isNil(supplierData) ||
      (isFetching && isNil(inventoryAnalysis)),
  };
};

export default useGetInventoryAnalysis;
