/* eslint-disable no-console */
import { map, size } from 'lodash';
import type { ChargeBackProfile } from 'StoreroomsApp/models/ChargeBackProfile';
import useScannerDB from './initScannerDB';

interface UseChargeBackProfileDBResponse {
  createChargeBackProfiles: (profiles: ChargeBackProfile[]) => Promise<void>;
  findChargeBackProfilesByMiLoc: (
    miLoc: string,
    customerNumber?: string
  ) => Promise<ChargeBackProfile[]>;
  removeChargeBackProfiles: (miLoc: string) => Promise<void>;
}

const useChargeBackProfileDB = (): UseChargeBackProfileDBResponse => {
  const { db, openDB, closeDB } = useScannerDB();

  const createChargeBackProfiles = async (
    profiles: ChargeBackProfile[]
  ): Promise<void> => {
    try {
      const statements = map(profiles, (item) => ({
        statement: `INSERT OR REPLACE INTO charge_back_profile (
          combinedId,
          miLocation,
          customerNumber,
          chargeBackDescription1,
          chargeBackDescription2,
          chargeBackDescription3,
          chargeBackDescription4,
          chargeBackDescription5,
          chargeBackRequired1,
          chargeBackRequired2,
          chargeBackRequired3,
          chargeBackRequired4,
          chargeBackRequired5,
          validateChargeBack1,
          validateChargeBack2,
          validateChargeBack3,
          validateChargeBack4,
          validateChargeBack5,
          validateGLNumber,
          invoiceGroupingCode,
          useGLNumber,
          glNumberRequited
          ) VALUES (?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?)`,
        values: [
          item.combinedId,
          item.miLocation,
          item.customerNumber,
          item.chargeBackDescription1,
          item.chargeBackDescription2,
          item.chargeBackDescription3,
          item.chargeBackDescription4,
          item.chargeBackDescription5,
          item.chargeBackRequired1,
          item.chargeBackRequired2,
          item.chargeBackRequired3,
          item.chargeBackRequired4,
          item.chargeBackRequired5,
          item.validateChargeBack1,
          item.validateChargeBack2,
          item.validateChargeBack3,
          item.validateChargeBack4,
          item.validateChargeBack5,
          item.validateGLNumber,
          item.invoiceGroupingCode,
          item.useGLNumber,
          item.glNumberRequited,
        ],
      }));
      await openDB();
      if (size(statements) > 0) {
        await db.executeSet(statements);
      }
    } catch (error) {
      console.log('Error saving charge back profiles to database', error);
      throw new Error('Error saving charge back profiles to database');
    } finally {
      await closeDB();
    }
  };

  const findChargeBackProfilesByMiLoc = async (
    miLoc: string,
    customerNumber?: string
  ): Promise<ChargeBackProfile[]> => {
    try {
      const vars = [miLoc];
      if (customerNumber) {
        vars.push(`${customerNumber}%`);
      }
      await openDB();
      return (
        await db.query(
          `SELECT * FROM charge_back_profile
          WHERE miLocation = ?
          ${customerNumber ? `AND customerNumber LIKE ?` : ''}`,
          vars
        )
      ).values as ChargeBackProfile[];
    } catch (error) {
      console.log('Error loading charge back profiles', error);
      throw new Error('Error loading charge back profiles');
    } finally {
      await closeDB();
    }
  };

  const removeChargeBackProfiles = async (miLoc: string): Promise<void> => {
    try {
      await openDB();
      await db.query(
        `DELETE FROM charge_back_profile
        WHERE miLocation = ?`,
        [miLoc]
      );
    } catch (error) {
      console.log('Error removing charge back profiles', error);
      throw new Error('Error removing charge back profiles');
    } finally {
      await closeDB();
    }
  };

  return {
    createChargeBackProfiles,
    findChargeBackProfilesByMiLoc,
    removeChargeBackProfiles,
  };
};

export default useChargeBackProfileDB;
