/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { filter, find, head, isNaN, isNil, last, map, toString } from 'lodash';
import type { ComputedSerie, CustomLayerProps } from '@nivo/line';
import { ResponsiveLine } from '@nivo/line';
import classes from './LineChart.module.scss';

interface LegendLineChartProps {
  maxValue: 'auto' | number;
  minValue: 'auto' | number;
  marginLeft: number;
}

const LegendLineChart = ({
  data,
  maxValue = 'auto',
  minValue = 'auto',
  marginLeft,
  axisLeft,
  axisBottom,
  enableGridY,
  gridYValues,
}: LegendLineChartProps &
  React.ComponentProps<typeof ResponsiveLine>): JSX.Element => {
  const firstPrevDataPoint = find(head(data)?.data, ({ y }) => !isNil(y));
  const firstDataPoint = find(last(data)?.data, ({ y }) => !isNil(y));

  const Lines = ({ series, lineGenerator, xScale, yScale }: CustomLayerProps) =>
    map(series, ({ id, data: lData, color }: ComputedSerie, index) => (
      <path
        key={index}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
        d={
          lineGenerator(
            map(
              filter(lData, (d) => !isNil(d.data.y) && !isNaN(d.data.y)),
              (d) => ({
                // @ts-ignore
                x: xScale(toString(d.data.x)) as number,
                // @ts-ignore
                y: yScale(toString(d.data.y)) as number,
              })
            )
          ) as string
        }
        fill="none"
        stroke={color}
        style={
          toString(id).indexOf('prev-') === -1
            ? { strokeWidth: 2 }
            : { strokeDasharray: '4', strokeWidth: 2 }
        }
      />
    ));

  const Points = ({ series, xScale, yScale }: CustomLayerProps) =>
    map(series, ({ id, data: lData, color }: ComputedSerie, index) =>
      map(lData, (d) => {
        const isPrev = toString(id).indexOf('prev-') !== -1;
        const hasYValue = !isNil(d.data.y) && !isNaN(d.data.y);
        if (
          ((!isPrev && d.data.x === firstDataPoint?.x) ||
            (isPrev && d.data.x === firstPrevDataPoint?.x)) &&
          hasYValue
        ) {
          const pointSize = isPrev ? 6 : 8;
          return (
            <rect
              key={index}
              // @ts-ignore
              x={xScale(toString(d.data.x)) as number}
              // @ts-ignore
              y={yScale(toString(d.data.y)) as number}
              width={pointSize}
              height={pointSize}
              style={
                isPrev
                  ? { fill: '#fff', strokeWidth: 2, stroke: color }
                  : { fill: color }
              }
              transform={`translate(-${pointSize / 2},-${pointSize / 2})`}
            />
          );
        }
        return null;
      })
    );

  return (
    <div className={classes.LineChartWrapper}>
      <ResponsiveLine
        data={data}
        margin={{
          top: 10,
          bottom: 25,
          right: 10,
          left: marginLeft,
        }}
        yScale={{
          type: 'linear',
          min: minValue,
          max: maxValue,
        }}
        curve="linear"
        animate
        colors={{ datum: 'color' }}
        enableGridX={false}
        enableGridY={enableGridY}
        isInteractive={false}
        enablePoints={false}
        gridYValues={gridYValues}
        axisBottom={axisBottom}
        axisLeft={axisLeft}
        layers={['grid', 'axes', Lines, Points]}
      />
    </div>
  );
};

export default LegendLineChart;
