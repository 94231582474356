import React from 'react';
import classNames from 'classnames';
import { isNaN } from 'lodash';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { emptyString } from 'common/utils/valueFormatter';
import type { FormatNumberScale } from 'utils/number';
import { customizeNumberFormat } from 'utils/number';
import Text from 'components/Text/Text';
import classes from './NumberFormat.module.scss';

interface NumberFormatProps {
  value: number;
  scale?: FormatNumberScale;
  prefix?: string;
  suffix?: string;
  display?: 'block' | 'inline';
  testid: string;
}

const NumberFormat = ({
  value,
  scale = 0,
  prefix = '',
  suffix = '',
  display = 'block',
  variant = 'content-default',
  className,
  testid,
}: NumberFormatProps &
  IonicReactProps &
  Omit<React.ComponentProps<typeof Text>, 'text'>): JSX.Element => (
  <Text
    className={classNames(className, classes[display])}
    variant={variant}
    text={
      isNaN(value)
        ? emptyString
        : `${prefix}${customizeNumberFormat(value, scale)}${suffix}`
    }
    testid={`number-format-${testid}`}
  />
);

export default NumberFormat;
