import type { MutationStatus } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { UpdateCountGroupProps } from 'InventoryApp/database/useCountPlanDB';
import useCountPlanDB from 'InventoryApp/database/useCountPlanDB';
import { useToasts } from 'providers/ToastProvider';
import { onSuccessMutation, doPromiseAPI } from 'api/helpers';
import { ToastType } from 'models/Toast';
import { getCountGroupQueryKey } from './useGetCountGroup';

interface UseUpdateCountGroupProps {
  countPlanId: string;
  groupId: string;
}

interface UseUpdateCountGroupResponse {
  status: MutationStatus;
  onUpdateCountGroup: (body: UpdateCountGroupProps) => void;
}

const useUpdateCountGroup = ({
  countPlanId,
  groupId,
}: UseUpdateCountGroupProps): UseUpdateCountGroupResponse => {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const { updateCountGroups, createProfileDefaultSort } = useCountPlanDB();

  const doUpdateCountGroup = (body: UpdateCountGroupProps) => {
    return doPromiseAPI(async () => {
      await updateCountGroups([body]);
      const {
        sortField = '',
        sortDir = '',
        miLoc = '',
        customerNo = '',
      } = body;
      if (sortField && sortDir && miLoc && customerNo) {
        await createProfileDefaultSort({
          uniqueId: `${miLoc}${customerNo}`,
          miLoc,
          customerNo,
          sortField,
          sortDir,
        });
      }
    });
  };

  const { status, mutate } = useMutation(doUpdateCountGroup, {
    networkMode: 'always',
    // TODO: optimisitc update?
    onSuccess: () => {
      void onSuccessMutation(queryClient, getCountGroupQueryKey, {
        countPlanId,
        groupId,
      });
    },
    onError: () => {
      addToast({
        type: ToastType.error,
        text: 'There was an error updating group.',
        testid: 'update-group-error-toast',
      });
    },
  });

  return {
    status,
    onUpdateCountGroup: (body: UpdateCountGroupProps) => mutate(body),
  };
};

export default useUpdateCountGroup;
