import i18next from 'i18n/i18n';
import * as yup from 'yup';
import type { MiProFile } from 'models/Attachment';
import type { Employee } from 'models/Employee';

export interface SendEmailForm {
  subject: string;
  recepients: string;
  recepientsList?: Employee[];
  message: string;
  files?: MiProFile[];
}

export const SendEmailSchema: yup.ObjectSchema<SendEmailForm> = yup
  .object()
  .shape({
    subject: yup.string().required(i18next.t('common:forms:requiredError')),
    recepients: yup.string().required(i18next.t('common:forms:requiredError')),
    recepientsList: yup.array(),
    message: yup.string().required(i18next.t('common:forms:requiredError')),
    files: yup.array<MiProFile>(),
  });
