import type { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import type {
  QueryFlags,
  QueryFnProps,
  QueryParamsType,
} from 'common/api/utils/useGetQueryFlags';
import useGetQueryFlags from 'common/api/utils/useGetQueryFlags';
import { useAxios } from 'providers/AxiosProvider';
import { useKeyUserId, useMiLocOrTeamId } from 'api/helpers';

export interface Group2Digits {
  code: string;
  text: string;
}

interface FindGroups2DigitsAPIResponse {
  prodGroups: Group2Digits[];
  totalRows: number;
}

export const findGroups2DigitsQueryKey = 'groups-2digits';

interface UseFindGroups2DigitsProps {
  mfrCtlNo?: string;
  // TODO create reusable interface with props
  enabled?: boolean;
}

interface UseFindGroups2DigitsResponse {
  groups?: Group2Digits[];
}

const useFindGroups2Digits = ({
  mfrCtlNo,
  enabled: propsEnabled = true,
}: UseFindGroups2DigitsProps): UseFindGroups2DigitsResponse & QueryFlags => {
  const { axios } = useAxios();
  const { findGroups2DigitsAPI } = useAPIUrl();
  // TODO put together common utils
  const { createQueryKey } = useKeyUserId();
  const { getURLParams } = useMiLocOrTeamId({});
  const params: QueryParamsType = { mfrCtlNo };
  const enabled = propsEnabled && !!mfrCtlNo;

  const doFindGroups2Digits = async ({
    pageParam = 1,
    signal,
  }: QueryFnProps) => {
    const { data } = await axios.get<FindGroups2DigitsAPIResponse>(
      findGroups2DigitsAPI(getURLParams({ ...params, page: pageParam })),
      { signal }
    );
    return data;
  };

  const response = useQuery<FindGroups2DigitsAPIResponse, AxiosError>(
    createQueryKey(findGroups2DigitsQueryKey, params),
    doFindGroups2Digits,
    { enabled }
  );

  const groups = response.data?.prodGroups;

  const queryFlags = useGetQueryFlags({ ...response, data: groups, enabled });

  return { groups, ...queryFlags };
};

export default useFindGroups2Digits;
