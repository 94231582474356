import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonRow } from '@ionic/react';
import Button from 'components/Button/Button';
import SheetModal from 'components/Modals/SheetModal/SheetModal';
import Text from 'components/Text/Text';
import classes from './HelpModal.module.scss';

enum HelpVariantEnum {
  'info',
  'key',
  'warning',
}

export type HelpVariant = keyof typeof HelpVariantEnum;
interface HelpModalProps {
  title: string;
  isOpen: boolean;
  closeHelpModal: (b: boolean) => void;
  children?: React.ReactNode;
  testid: string;
  initialBreakpoint?: number;
  className?: string;
  variant?: HelpVariant;
}

const HelpModal = ({
  title,
  children,
  isOpen,
  closeHelpModal,
  testid,
  initialBreakpoint = 0.5,
  className,
  variant = 'info',
}: HelpModalProps): JSX.Element => {
  const { t } = useTranslation();
  let icon: IconProp = ['fas', 'info-circle'];
  const showElements = variant === 'info' || variant === 'warning';
  if (variant === 'warning') {
    icon = ['far', 'triangle-exclamation'];
  }

  return (
    <SheetModal
      className={classes.modal}
      contentClass={classes.modalMainContent}
      isOpen={isOpen}
      title={variant === 'key' ? title : ''}
      initialBreakpoint={initialBreakpoint}
      headerClassName={classNames(classes.modalContentRow, classes.modalHeader)}
      setIsOpen={closeHelpModal}
      testid={testid}
      customTitle={
        showElements && (
          <div className={classes.infoIconWrapper}>
            <FontAwesomeIcon
              icon={icon}
              className={classNames(classes.modalInfoIcon, classes[variant])}
              data-testid="info-help-icon"
            />
          </div>
        )
      }
      withRightCloseButton
    >
      <div className={classNames(classes.modalContentRow, className)}>
        {showElements && (
          <IonRow className={classes.header}>
            <Text
              className={classes.modalInfoTitle}
              variant="mipro-h1-headline"
              text={title}
              testid="info-help-title"
            />
          </IonRow>
        )}
        {children}
      </div>
      {showElements && (
        <div className={classes.closeButtonDivWrapper}>
          <Button
            variant="action"
            text={t('close')}
            onClick={() => {
              closeHelpModal(false);
            }}
            testid="close-info-modal-button"
            data-testid="close-info-modal-button"
          />
        </div>
      )}
    </SheetModal>
  );
};

export default HelpModal;
