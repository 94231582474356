import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { isEmpty, toString } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonContent, IonImg, IonPage } from '@ionic/react';
import useGetBulletin from 'api/bulletins/useGetBulletin';
import type { BulletinURLParams } from 'models/Bulletin';
import { sanitizeTitle } from 'utils/announcements';
import {
  LinkClickHandler,
  BulletinDescriptionClickHandler,
} from 'utils/bulletins';
import { getErrorMessage } from 'utils/helpers';
import { findIcon } from 'utils/icons';
import blankImage from 'assets/blankImage.svg';
import Button from 'components/Button/Button';
import DateFormat from 'components/DateFormat/DateFormat';
import Header from 'components/Header/Header';
import Loader from 'components/Loader/Loader';
import Text from 'components/Text/Text';
import TitleLine from 'components/TitleLine/TitleLine';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './Bulletins.module.scss';

const BulletinDetail = (): JSX.Element => {
  const { id } = useParams<BulletinURLParams>();
  const { data, isLoading, error } = useGetBulletin({ id });

  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    bulletin_video_link = '',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    call_to_action_button_link = '',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    call_to_action_button_text = '',
  } = data?.acf || {};

  const { t } = useTranslation();
  const history = useHistory();

  const clickHandler = (event: React.SyntheticEvent) => {
    try {
      BulletinDescriptionClickHandler(event, history);
      // eslint-disable-next-line no-empty
    } catch (targetError) {}
  };

  return (
    <IonPage className={classes.bulletin}>
      <Header
        title={sanitizeTitle(data?.title.rendered || t('common:loading'))}
        eyebrow={t('common:bulletins')}
        withBackButton
        testid="bulletin"
      />
      <IonContent className={classes.content}>
        <Loader
          className={classes.loader}
          text={t('announcements:loadingAnnouncement')}
          isOpen={isLoading}
        />
        {!isLoading && data && !error && (
          <div>
            <DateFormat
              variant="content-small"
              date={new Date(data.date)}
              className={classes.date}
              testid="card-date"
            />
            {!isEmpty(data.title.rendered) && (
              <>
                <Text
                  className={classes.title}
                  variant="underlined-title-section"
                  text={sanitizeTitle(data.title.rendered)}
                />
                <TitleLine />
              </>
            )}
            {(bulletin_video_link || data.image?.url) && (
              <div className={classes.imageContainer}>
                <IonImg
                  className={classes.thumbnail}
                  src={data.image?.url || blankImage}
                  alt={data.image?.alt}
                />

                {bulletin_video_link && (
                  <div className={classes.overlay}>
                    <FontAwesomeIcon
                      className={classes.icon}
                      icon={findIcon('play-circle')}
                      onClick={async (e: React.SyntheticEvent) => {
                        await LinkClickHandler(
                          e,
                          call_to_action_button_link as unknown as string,
                          // eslint-disable-next-line no-restricted-globals
                          history
                        );
                      }}
                    />
                  </div>
                )}
              </div>
            )}

            <div
              className={classes.bulletinContent}
              onClick={clickHandler}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: toString(data.content.rendered),
              }}
              role="presentation"
            />

            {call_to_action_button_link && (
              <div className={classes.callToAction}>
                <Button
                  variant="action"
                  className={classes.callToActionBtn}
                  onClick={async (e: React.SyntheticEvent) => {
                    await LinkClickHandler(
                      e,
                      call_to_action_button_link as unknown as string,
                      // eslint-disable-next-line no-restricted-globals
                      history
                    );
                  }}
                  text={
                    (call_to_action_button_text ||
                      call_to_action_button_link) as unknown as string
                  }
                  testid="call_to_action-dismiss"
                />
              </div>
            )}
          </div>
        )}
        {error && (
          <WarningMessage
            className={classes.warningMessage}
            title={t('announcements:errorLoadingAnnouncement')}
            body={getErrorMessage(error)}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default BulletinDetail;
