import React from 'react';
import { map } from 'lodash';
import { IonRow } from '@ionic/react';
import SegmentTabs from 'components/SegmentTabs/SegmentTabs';
import type { SegmentTabOptionProps } from 'components/SegmentTabs/SegmentTabs';
import Text from 'components/Text/Text';
import classes from './ChartHeader.module.scss';

interface LegendItem {
  text: string;
  style: React.CSSProperties;
  show: boolean;
}
interface ChartHeaderProps {
  tabValue: string;
  setTabValue: (s: string) => void;
  items: LegendItem[];
  options: SegmentTabOptionProps[];
}

const ChartHeader = ({
  tabValue,
  setTabValue,
  items,
  options,
}: ChartHeaderProps): JSX.Element => (
  <IonRow className={classes.legendWrapper}>
    <SegmentTabs
      className={classes.segmentTabs}
      variant="whiteTabs"
      value={tabValue}
      setValue={setTabValue}
      options={options}
      testid="chart-segmented-tab"
    />
    <IonRow className={classes.legendRow}>
      {map(
        items,
        ({ text, style, show }, index) =>
          show && (
            <div key={index} className={classes.legendText}>
              <div className={classes.legendIcon} style={style} />
              <Text text={text} />
            </div>
          )
      )}
    </IonRow>
  </IonRow>
);

export default ChartHeader;
