import React from 'react';
import classNames from 'classnames';
import { map, values } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonCol, IonRow } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { findIcon } from 'utils/icons';
import type {
  PartsListItem,
  PartsListItemInput,
} from 'components/Documents/DocumentPartsList/DocumentPartsList';
import Input from 'components/Input/Input';
import Text from 'components/Text/Text';
import TextArea from 'components/TextArea/TextArea';
import classes from './PartsListPart.module.scss';

export interface PartsListPartProps {
  part: PartsListItem;
  needSync?: boolean;
  disabled?: boolean;
  onUpdatePart: (
    partIndex: number,
    attributeName: string,
    value: string
  ) => void;
  onDeletePart: (partIndex: number) => void;
  isFirstPart: boolean;
}

const PartsListPart = ({
  part,
  needSync,
  disabled,
  onUpdatePart,
  onDeletePart,
  isFirstPart,
}: PartsListPartProps & IonicReactProps): JSX.Element => {
  return (
    <div className={classes.partsListPart}>
      <IonRow className={classNames(classes.documentRow, classes.titleRow)}>
        <IonCol className={classes.documentCol}>
          <div className={classes.partTitle}>
            <Text
              text={`Part ${part.itemIndex.value}:`}
              variant="title-action-card"
            />
            {needSync && (
              <Text
                className={classes.needSyncBadge}
                variant="label-micro"
                text="Pending"
              />
            )}
          </div>
        </IonCol>
        {!isFirstPart && (
          <IonCol size="auto" className={classes.documentCol}>
            <FontAwesomeIcon
              onClick={() => {
                onDeletePart(+part.itemIndex.value);
              }}
              icon={findIcon('times')}
            />
          </IonCol>
        )}
      </IonRow>
      {map(values(part), (partInput: PartsListItemInput, attributeIndex) => (
        <div key={`attribute-${attributeIndex}`}>
          {!partInput.hidden && (
            <IonRow className={classes.documentRow}>
              <IonCol size="12" className={classes.documentCol}>
                <Text text={partInput.displayName} variant="content-default" />
              </IonCol>
              <IonCol size="12" className={classes.documentCol}>
                {partInput.type === 'textArea' && (
                  <TextArea
                    testid="containerInput"
                    disabled={disabled}
                    className={classes.textArea}
                    value={partInput.value ? (partInput.value as string) : ''}
                    setValue={(e: string) => {
                      onUpdatePart(
                        part.itemIndex.value as number,
                        partInput.attributeName,
                        e
                      );
                    }}
                  />
                )}
                {partInput.type !== 'textArea' && (
                  <Input
                    testid="containerInput"
                    disabled={disabled}
                    value={partInput.value}
                    setValue={(e: string) => {
                      onUpdatePart(
                        part.itemIndex.value as number,
                        partInput.attributeName,
                        e
                      );
                    }}
                  />
                )}
              </IonCol>
            </IonRow>
          )}
        </div>
      ))}
    </div>
  );
};

export default PartsListPart;
