import { useSelector } from 'react-redux';
import { get } from 'lodash';
import type { RootState } from 'store/reducers';
import type { UseGetAvatarResponse } from './useGetAvatarById';
import useGetAvatarById from './useGetAvatarById';

const useGetAvatar = (): UseGetAvatarResponse => {
  const { userInfo } = useSelector((state: RootState) => state.user);
  const employeeId = get(userInfo, 'employeenumber', '');

  return useGetAvatarById(employeeId);
};

export default useGetAvatar;
