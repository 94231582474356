import { useMemo } from 'react';
import type { AxiosError } from 'axios';
import type { Dictionary } from 'lodash';
import { size, toString } from 'lodash';
import type { QueryFunctionContext } from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import {
  doConcatDataPages,
  doGetIsLoading,
  doPromiseAPI,
  useKeyUserId,
  useMiLocOrTeamId,
} from 'api/helpers';
import type { IndustryCode } from 'models/Customer';
import type { InfiniteQueryFlags } from 'models/Search';
import { pageSize } from 'utils/constants';

export const findIndustryCodesQueryKey = 'machines';

interface UseFindIndustryCodesProps {
  query?: string;
  type: string;
  limit?: number;
  enabled?: boolean;
}

interface UseFindIndustryCodesResponse {
  items: IndustryCode[];
}

const useFindIndustryCodes = ({
  query,
  type,
  enabled,
  limit = pageSize(),
}: UseFindIndustryCodesProps): UseFindIndustryCodesResponse &
  InfiniteQueryFlags => {
  const { axios } = useAxios();
  const { createQueryKey } = useKeyUserId();
  const { createParams } = useMiLocOrTeamId({ sendTeamId: false });
  const { findSICCodes } = useAPIUrl();
  const params: Dictionary<string> = {
    ...createParams(),
    fastFind: toString(query),
    type,
    limit: toString(limit),
  };

  // TODO: fix cancel query logic in other places as well
  const doFindIndustryCodes = ({
    pageParam = 1,
    signal,
  }: QueryFunctionContext) => {
    return doPromiseAPI<IndustryCode[]>(async () => {
      if (params.type === 'altSic') {
        const { data } = await axios.get<IndustryCode[]>(
          findSICCodes(
            toString(
              new URLSearchParams({ ...params, page: toString(pageParam) })
            )
          ),
          { signal }
        );
        return data;
      }
      // TODO: API for alt naics
      return [];
    });
  };

  const response = useInfiniteQuery<IndustryCode[], AxiosError>(
    createQueryKey(findIndustryCodesQueryKey, params),
    doFindIndustryCodes,
    {
      enabled,
      getNextPageParam: (lastPage, pages) =>
        size(lastPage) < limit ? false : size(pages) + 1,
    }
  );

  const {
    data,
    error,
    status,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = response;

  const items = useMemo(() => doConcatDataPages<IndustryCode>(data), [data]);

  const hasItems = size(items) > 0;
  const isEmptyResponse = status === 'success' && !hasNextPage && !hasItems;
  const noMoreData = status === 'success' && !hasNextPage && hasItems;
  const showLoader = doGetIsLoading(response) || isFetchingNextPage;
  const enableInfiniteScroll = !(!hasNextPage || isFetchingNextPage);

  return {
    fetchNextPage: async () => {
      await fetchNextPage();
    },
    refetch: async () => {
      await refetch();
    },
    items,
    error,
    showLoader,
    isEmptyResponse,
    noMoreData,
    enableInfiniteScroll,
  };
};

export default useFindIndustryCodes;
