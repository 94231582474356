import { isNil } from 'lodash';
import { useQueryClient } from '@tanstack/react-query';
import type { SalesReportRow } from 'ReportsApp/api/useGetSalesDashboard';
import { getSalesDashboardQueryKey } from 'ReportsApp/api/useGetSalesDashboard';
import { getSalesReportQueryKey } from 'ReportsApp/api/useGetSalesReport';
import { getPlaceholderData } from 'api/helpers';
import { getReportDrillDownQueryKey } from 'api/salesReports/useGetReportDrillDown';
import type { ProductCategory, ReportDrillDownItem } from 'models/Reports';

export const getProductCategoryQueryKey = 'product-category';

interface UseGetProductCategoryProps {
  miLoc: string;
  pgc1: string;
  enabled?: boolean;
}

interface ProductCategoryData {
  data?: ProductCategory;
}

const useGetProductCategory = ({
  miLoc,
  pgc1,
}: UseGetProductCategoryProps): ProductCategoryData => {
  const queryClient = useQueryClient();
  const productCategory = getPlaceholderData<ReportDrillDownItem>({
    queryClient,
    queryKey: getReportDrillDownQueryKey,
    findPredicate: { miLoc, id: pgc1 },
    objectKey: 'items',
  });

  if (!isNil(productCategory)) {
    return { data: { miLoc, pgc1, name: productCategory.Name } };
  }

  const newProductCategory = getPlaceholderData<ReportDrillDownItem>({
    queryClient,
    queryKey: getSalesReportQueryKey,
    findPredicate: { miLoc, id: pgc1 },
    objectKey: 'items',
  });

  if (!isNil(newProductCategory)) {
    return { data: { miLoc, pgc1, name: newProductCategory.Name } };
  }

  const salesReportProductCategory = getPlaceholderData<SalesReportRow>({
    queryClient,
    queryKey: getSalesDashboardQueryKey,
    findPredicate: { miLoc, pgc: pgc1 },
    objectKey: 'rows',
  });

  if (!isNil(salesReportProductCategory)) {
    return {
      data: {
        miLoc,
        pgc1: salesReportProductCategory.pgc,
        name: salesReportProductCategory.pgcName,
      },
    };
  }

  return { data: undefined };
};

export default useGetProductCategory;
