import { useMemo } from 'react';
import type { AxiosError } from 'axios';
import { size } from 'lodash';
import { useInfiniteQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { doConcatDataPages, doPromiseAPI, useKeyUserId } from 'api/helpers';
import type { FeaturesAPIResponse, Feature } from 'models/Features';
import type { InfiniteQueryFlags } from 'models/Search';

export const findFeaturesQueryKey = 'features';
export const findFeaturesObjectKey = 'features';

const useFindFeatures = (): FeaturesAPIResponse & InfiniteQueryFlags => {
  const { axios } = useAxios();
  const { findFeaturesAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();

  const doFindFeatures = () => {
    return doPromiseAPI<FeaturesAPIResponse>(async (cancelToken) => {
      const { data } = await axios.get<FeaturesAPIResponse>(findFeaturesAPI(), {
        cancelToken,
      });
      return data;
    });
  };

  const response = useInfiniteQuery<FeaturesAPIResponse, AxiosError>(
    createQueryKey(`${findFeaturesQueryKey}`),
    doFindFeatures
  );

  const { data, error, status, refetch } = response;

  const features = useMemo(
    () => doConcatDataPages<Feature, FeaturesAPIResponse>(data),
    [data]
  );

  const hasItems = size(features) > 0;
  const hasError = status === 'error';
  const isEmptyResponse = status === 'success' && !hasItems;
  const showLoader = status === 'loading';

  return {
    features,
    error,
    hasError,
    showLoader,
    isEmptyResponse,
    refetch: async () => {
      await refetch();
    },
  };
};

export default useFindFeatures;
