import type { AxiosError } from 'axios';
import type { Dictionary } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import useIssueDB from 'StoreroomsApp/database/useIssueDB';
import type { Issue } from 'StoreroomsApp/models/Issue';
import {
  doGetIsLoading,
  doPromiseAPI,
  useKeyUserId,
  useMiLocOrTeamId,
} from 'api/helpers';
import type { QueryFlags } from 'models/Search';

export const findIssuesQueryKey = 'issues';

interface UseFindIssuesProps {
  storeroomNumber: string;
}

interface UseFindIssuesResponse {
  issues?: Issue[];
}

const useFindIssues = ({
  storeroomNumber,
}: UseFindIssuesProps): QueryFlags & UseFindIssuesResponse => {
  const { createQueryKey } = useKeyUserId();
  const { createParams } = useMiLocOrTeamId({ sendTeamId: false });
  const { findIssuesByStoreroom } = useIssueDB();
  const params: Dictionary<string> = {
    ...createParams(),
    storeroomNumber,
  };
  const { miLoc } = params;

  const doFindIssues = async () => {
    return doPromiseAPI<Issue[]>(async () => {
      return findIssuesByStoreroom({ miLoc, storeroomNumber });
    });
  };

  const response = useQuery<Issue[], AxiosError>(
    createQueryKey(findIssuesQueryKey, params),
    doFindIssues,
    { networkMode: 'always' }
  );

  const { data, error, refetch } = response;

  return {
    issues: data,
    error,
    isLoading: doGetIsLoading(response),
    refetch: async () => {
      await refetch();
    },
  };
};

export default useFindIssues;
