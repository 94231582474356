import { useState } from 'react';
import type { AxiosError } from 'axios';
import type { Dictionary } from 'lodash';
import { map, toString, size } from 'lodash';
import * as Sentry from '@sentry/capacitor';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import { useToasts } from 'providers/ToastProvider';
import useChargeBackProfileDB from 'StoreroomsApp/database/useChargeBackProfileDB';
import type {
  ChargeBackProfile,
  ChargeBackProfileAPI,
} from 'StoreroomsApp/models/ChargeBackProfile';
import {
  doGetIsLoading,
  doPromiseAPI,
  onSuccessMutation,
  useKeyUserId,
  useMiLocOrTeamId,
} from 'api/helpers';
import type { QueryFlags } from 'models/Search';
import { ToastType } from 'models/Toast';

export const findChargeBackProfilesQueryKey = 'charge-back-profiles';

interface UseFindChargeBackProfilesProps {
  customerNumber?: string;
  enabled?: boolean;
}

interface UseFindChargeBackProfilesResponse {
  profiles?: ChargeBackProfile[];
  loadingAPI?: boolean;
  sync: () => Promise<void>;
}

const useFindChargeBackProfiles = ({
  customerNumber,
  enabled,
}: UseFindChargeBackProfilesProps): QueryFlags &
  UseFindChargeBackProfilesResponse => {
  const { axios } = useAxios();
  const { addToast } = useToasts();
  const queryClient = useQueryClient();
  const { chargeBackProfilesAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const { createParams } = useMiLocOrTeamId({ sendTeamId: false });
  const {
    createChargeBackProfiles,
    findChargeBackProfilesByMiLoc,
    removeChargeBackProfiles,
  } = useChargeBackProfileDB();
  const [loadingAPI, setLoadingAPI] = useState(false);
  const { isOnline } = useNetworkStatus();
  const params: Dictionary<string> = {
    ...createParams(),
    customerNumber: toString(customerNumber),
  };
  const { miLoc } = params;

  const doFindChargeBackProfiles = async () => {
    return doPromiseAPI<ChargeBackProfile[]>(async () => {
      let profiles: ChargeBackProfile[] = [];
      if (isOnline) {
        const { data: profilesData } = await axios.post<ChargeBackProfileAPI[]>(
          chargeBackProfilesAPI(miLoc)
        );
        profiles = map(profilesData, (p) => ({
          combinedId: p.combinedChargeBackProfileId,
          miLocation: p.miLoc,
          customerNumber: p.customerNo,
          chargeBackDescription1: p.description1,
          chargeBackDescription2: p.description2,
          chargeBackDescription3: p.description3,
          chargeBackDescription4: p.description4,
          chargeBackDescription5: p.description5,
          chargeBackRequired1: p.required1,
          chargeBackRequired2: p.required2,
          chargeBackRequired3: p.required3,
          chargeBackRequired4: p.required4,
          chargeBackRequired5: p.required5,
          validateChargeBack1: p.validate1,
          validateChargeBack2: p.validate2,
          validateChargeBack3: p.validate3,
          validateChargeBack4: p.validate4,
          validateChargeBack5: p.validate5,
          validateGLNumber: p.validateGL,
          invoiceGroupingCode: p.invoiceGroupingCode,
          useGLNumber: p.useGL,
          glNumberRequited: p.requiredGl,
        }));
        await createChargeBackProfiles(profiles);
        return profiles;
      }
      profiles =
        (await findChargeBackProfilesByMiLoc(
          miLoc,
          toString(customerNumber).substring(
            0,
            size(toString(customerNumber)) - 3
          )
        )) || [];
      return profiles;
    });
  };

  const response = useQuery<ChargeBackProfile[], AxiosError>(
    createQueryKey(findChargeBackProfilesQueryKey, { ...params, isOnline }),
    doFindChargeBackProfiles,
    {
      enabled,
      networkMode: 'always',
      onError: () => {
        setLoadingAPI(false);
      },
    }
  );

  const { data, error, refetch } = response;

  return {
    profiles: data,
    error,
    isLoading: doGetIsLoading(response),
    loadingAPI,
    refetch: async () => {
      await refetch();
    },
    sync: async () => {
      try {
        setLoadingAPI(true);
        const { data: syncData } = await axios.post<ChargeBackProfileAPI[]>(
          chargeBackProfilesAPI(miLoc)
        );
        const profiles: ChargeBackProfile[] = map(syncData, (p) => ({
          combinedId: p.combinedChargeBackProfileId,
          miLocation: p.miLoc,
          customerNumber: p.customerNo,
          chargeBackDescription1: p.description1,
          chargeBackDescription2: p.description2,
          chargeBackDescription3: p.description3,
          chargeBackDescription4: p.description4,
          chargeBackDescription5: p.description5,
          chargeBackRequired1: p.required1,
          chargeBackRequired2: p.required2,
          chargeBackRequired3: p.required3,
          chargeBackRequired4: p.required4,
          chargeBackRequired5: p.required5,
          validateChargeBack1: p.validate1,
          validateChargeBack2: p.validate2,
          validateChargeBack3: p.validate3,
          validateChargeBack4: p.validate4,
          validateChargeBack5: p.validate5,
          validateGLNumber: p.validateGL,
          invoiceGroupingCode: p.invoiceGroupingCode,
          useGLNumber: p.useGL,
          glNumberRequited: p.requiredGl,
        }));
        await removeChargeBackProfiles(miLoc);
        await createChargeBackProfiles(profiles);
        void onSuccessMutation(queryClient, findChargeBackProfilesQueryKey);
      } catch (e) {
        addToast({
          type: ToastType.error,
          text: 'There was an error while downloading charge back profiles',
          testid: 'sync-error-toast',
          duration: 0,
        });
        Sentry.captureException(e);
        throw e;
      } finally {
        setLoadingAPI(false);
      }
    },
  };
};

export default useFindChargeBackProfiles;
