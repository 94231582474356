import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { filter, size, toNumber } from 'lodash';
import { IonCol, IonContent, IonPage, IonRow } from '@ionic/react';
import Header from 'common/components/Header/Header';
import useFindCountGroups from 'InventoryApp/api/useFindCountGroups';
import InventoryCustomTitle from 'InventoryApp/components/InventoryCustomTitle/InventoryCustomTitle';
import useInventoryHeaderActions from 'InventoryApp/hooks/useInventoryHeaderActions';
import { CountGroupListTypeEnum } from 'InventoryApp/models/InventoryPlanGroup';
import useInventoryPermissions from 'InventoryApp/util/useInventoryPermissions';
import {
  findStartCustomerURL,
  countGroupListURL,
  inventoryURL,
} from 'navigation';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import { concatRoutes } from 'utils/navigations';
import Button from 'components/Button/Button';
import Text from 'components/Text/Text';
import classes from './HomeInventory.module.scss';

const HomeInventory = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const { isOnline } = useNetworkStatus();
  const { hasInventoryPermission, hasVmiPermission } =
    useInventoryPermissions();

  const { groups } = useFindCountGroups({ offlineOnly: true });
  const localGroups = filter(
    groups,
    (group) =>
      toNumber(group.linesUpdated) > 0 || toNumber(group.downloadedItems) > 0
  );

  const { exitUrl, headerActions } = useInventoryHeaderActions({});

  return (
    <IonPage data-testid="start-inventory-page">
      <Header
        testid="start-inventory-header"
        hideMenuButton
        hideCartButton
        backButton={{ onClick: () => history.replace(exitUrl) }}
        headerActions={headerActions}
        customTitle={
          <InventoryCustomTitle title={t('inventory:homeInventory')} />
        }
      />

      <IonContent
        data-testid="start-inventory-content"
        className={classes.content}
      >
        <IonRow className={classes.titleRow}>
          <IonCol>
            <Text
              text={t('inventory:welcomeText')}
              testid="inventory-welcome-test"
              variant="mipro-h1-headline"
            />
          </IonCol>
        </IonRow>
        <IonRow className={classes.buttonWrapper}>
          {hasVmiPermission && (
            <IonCol size="12">
              <Button
                testid="create-new-vmi-count"
                variant="action"
                text={t('inventory:createNewVmiCount')}
                disabled={!isOnline}
                className={classNames(classes.button, {
                  [classes.disabled]: !isOnline,
                })}
                href={concatRoutes(inventoryURL(), findStartCustomerURL())}
              />
            </IonCol>
          )}
          {hasInventoryPermission && (
            <IonCol size="12">
              <Button
                testid="create-new-inventory-count"
                variant="action"
                text={t('inventory:createNewInventoryCount')}
                disabled={!isOnline}
                className={classNames(classes.button, {
                  [classes.disabled]: !isOnline,
                })}
                href={concatRoutes(
                  inventoryURL(),
                  countGroupListURL(CountGroupListTypeEnum.newInventory)
                )}
              />
            </IonCol>
          )}
          {(hasInventoryPermission || hasVmiPermission) && (
            <IonCol size="12">
              <Button
                testid="resume-saved-accounts"
                variant="secondary"
                text={t('inventory:resumeSavedCounts', {
                  count: size(localGroups),
                })}
                className={classes.button}
                href={concatRoutes(
                  inventoryURL(),
                  countGroupListURL(CountGroupListTypeEnum.pending)
                )}
              />
            </IonCol>
          )}
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default HomeInventory;
