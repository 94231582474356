import type { AxiosError } from 'axios';
import { includes, isEmpty, get, find, map, toNumber, toString } from 'lodash';
import type { Report } from 'DocumentsApp/models/Report';
import { useToasts } from 'providers/ToastProvider';
import type { InspectionReport } from 'models/InspectionReport';
import { ToastType } from 'models/Toast';

export const transformFromOfflineReport = (
  props: Report,
  onlineVersion?: InspectionReport
): InspectionReport => {
  const report = {
    ...props,
    ...JSON.parse(props.documentJson || '{}'),
  } as Report;

  if (isEmpty(report)) {
    return {} as InspectionReport;
  }
  return {
    coverPage: {
      customerNo: report.customerNo,
      customerName: toString(report.customerName),
      endDate: report.endDate,
      lastSyncDate: toString(report.lastSyncDate),
      machineId: report.machineId,
      machineText: toString(report.machineText),
      miLoc: report.miLoc,
      miLocText: toString(report.miLocText),
      name: report.name,
      orderCtlNo: report.orderCtlNo,
      orderLineNo: toNumber(report.orderLineNo),
      reportId: toNumber(report.reportId),
      reportType: report.reportType,
      shopLoc: report.shopLoc,
      siteId: report.siteId,
      siteText: toString(report.siteText),
      startDate: report.startDate,
      status: report.status,
      templateId: report.templateId,
      templateVersion: toNumber(report.templateVersion),
      woCtlNo: report.woCtlNo,
      customerContact: report.customerContact,
      customerContactPhone: report.customerContactPhone,
      creationUserId:
        report.creationUserId ||
        toString(onlineVersion?.coverPage.creationUserId),
      creationUserName:
        report.creationUserName ||
        toString(onlineVersion?.coverPage.creationUserName),
      creationTmstmp:
        report.creationTmstmp ||
        toString(onlineVersion?.coverPage.creationTmstmp),
      lastUpdUserId:
        report.lastUpdUserId ||
        toString(onlineVersion?.coverPage.lastUpdUserId),
      lastUpdUserName:
        report.lastUpdUserName ||
        toString(onlineVersion?.coverPage.lastUpdUserName),
      lastUpdTmstmp:
        report.lastUpdTmstmp ||
        toString(onlineVersion?.coverPage.lastUpdTmstmp),
      hasValidSignature: Boolean(report.hasValidSignature),
    },
    reportId: toNumber(report.reportId),
    templateId: toNumber(report.templateId),
    reportValues: report.reportValues,
    addedValues: report.addedValues,
    partsList: report.partsList,
    partsAdded: report.partsAdded,
    partsRemoved: report.partsRemoved,
    vasCodesList: report.vasCodesList,
    vasCodesAdded: report.vasCodesAdded,
    vasCodesRemoved: report.vasCodesRemoved,
    genericSectionsList: report.genericSectionsList,
    genericSectionsAdded: report.genericSectionsAdded,
    genericSectionsRemoved: report.genericSectionsRemoved,
    reportImages: map(report.reportImages || report.offlineImages, (img) => {
      const imgData = find(report.offlineImages, { imageId: img.imageId });
      return { ...img, image: imgData?.image || img.image };
    }),
    addedImages: report.addedImages,
    removedImages: report.removedImages,
    needSync: !!report.needSync,
    reportSignature: onlineVersion?.reportSignature,
  };
};

export const transformFromOnlineReport = (report: InspectionReport): Report => {
  if (isEmpty(report)) {
    return {} as Report;
  }

  return {
    reportId: report.reportId,
    templateId: toString(report.templateId),
    name: toString(report.coverPage.name),
    status: toString(report.coverPage.status),
    startDate: toString(report.coverPage.startDate),
    endDate: toString(report.coverPage.endDate),
    miLoc: toString(report.coverPage.miLoc),
    miLocText: toString(report.coverPage.miLoc),
    shopLoc: toString(report.coverPage.shopLoc),
    orderCtlNo: toString(report.coverPage.orderCtlNo),
    woCtlNo: toString(report.coverPage.woCtlNo),
    customerNo: toString(report.coverPage.customerNo),
    customerName: toString(report.coverPage.customerName),
    orderLineNo: toNumber(report.coverPage.orderLineNo),
    machineId: toNumber(report.coverPage.machineId),
    siteId: toNumber(report.coverPage.siteId),
    templateVersion: toNumber(report.coverPage.templateVersion),
    lastSyncDate: toString(report.coverPage.lastSyncDate),
    reportType: toString(report.coverPage.reportType),
    custMachineId: '',
    machineText: toString(report.coverPage.machineText),
    siteText: toString(report.coverPage.siteText),
    customerContact: toString(report.coverPage.customerContact),
    customerContactPhone: toString(report.coverPage.customerContactPhone),
    reportValues: report.reportValues,
    addedValues: report.addedValues,
    partsList: report.partsList,
    partsAdded: report.partsAdded,
    vasCodesList: report.vasCodesList,
    vasCodesAdded: report.vasCodesAdded,
    genericSectionsList: report.genericSectionsList,
    genericSectionsAdded: report.genericSectionsAdded,
    reportImages: report.reportImages,
    addedImages: report.addedImages,
    removedImages: report.removedImages,
    needSync: report.needSync || false,
    creationUserId: toString(report.coverPage.creationUserId),
    creationUserName: toString(report.coverPage.creationUserName),
    creationTmstmp: toString(report.coverPage.creationTmstmp),
    lastUpdUserId: toString(report.coverPage.lastUpdUserId),
    lastUpdUserName: toString(report.coverPage.lastUpdUserName),
    lastUpdTmstmp: toString(report.coverPage.lastUpdTmstmp),
    hasValidSignature: Boolean(report.coverPage.hasValidSignature),
    reportSignature: report.reportSignature,
  };
};

const duplicatedReportError = 'Document already created at shop';
const hasDuplicatedWorkOrderError = (e: AxiosError): boolean =>
  includes(
    [
      get(e?.response?.data, 'messages.0.message'),
      get(e?.cause, 'messages.0.message'),
      e.message,
    ],
    duplicatedReportError
  );
const completedReportError = 'Can not update a closed document';
const documentIsCompletedError = (e: AxiosError): boolean =>
  includes(
    [
      get(e?.response?.data, 'messages.0.message'),
      get(e?.cause, 'messages.0.message'),
      e.message,
    ],
    completedReportError
  );

interface HandleDocumentErrorsProps {
  error: AxiosError;
  name?: string;
  defaultErrorText?: string;
}

interface UseDocumentHelpersResponse {
  getDocumentErrors: (error: AxiosError) => void;
  handleDocumentErrors: (props: HandleDocumentErrorsProps) => void;
}

const useDocumentHelpers = (): UseDocumentHelpersResponse => {
  const { addToast } = useToasts();

  const getDocumentErrors = (response: AxiosError): void => {
    if (
      hasDuplicatedWorkOrderError({
        response: { data: response },
      } as AxiosError)
    ) {
      throw new Error(duplicatedReportError);
    }
    if (
      documentIsCompletedError({
        response: { data: response },
      } as AxiosError)
    ) {
      throw new Error(completedReportError);
    }
  };

  const handleDocumentErrors = ({
    error,
    name,
    defaultErrorText = 'Save report operation failed',
  }: HandleDocumentErrorsProps): void => {
    if (
      hasDuplicatedWorkOrderError(error) ||
      error.message === duplicatedReportError
    ) {
      addToast({
        type: ToastType.warn,
        text: `${
          name ? `"${name}": ` : ''
        }Selected work order already has a document in this location.`,
        testid: 'save-report-warn-toast',
      });
    } else if (
      documentIsCompletedError(error) ||
      error.message === completedReportError
    ) {
      addToast({
        type: ToastType.warn,
        text: `${
          name ? `"${name}": ` : ''
        }Changes can't be saved because the document is already completed.`,
        testid: 'completed-report-warn-toast',
      });
    } else {
      addToast({
        type: ToastType.error,
        text: `${
          name ? `"${name}": ` : ''
        }${defaultErrorText}. Please try again later.`,
        testid: 'save-report-error-toast',
      });
    }
  };

  return { getDocumentErrors, handleDocumentErrors };
};

export default useDocumentHelpers;
