import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { concat, find, isNil, map, toString } from 'lodash';
import { choose } from 'common/utils/logicHelpers';
import {
  fromUnixTime,
  isSameDay,
  isWeekend,
  lastDayOfMonth,
  startOfToday,
  subYears,
  setMonth,
  startOfYear,
} from 'date-fns';
import { namespaces } from 'i18n/i18n.constants';
import { useReportsConfig } from 'providers/ReportsProvider';
import useGetCalendarDays from 'api/salesReports/useGetCalendarDays';
import type { DateSegmentType } from 'models/Reports';
import type { SortOption } from 'models/Sort';
import {
  DateFormatEnum,
  formatDate,
  getBusinessDays,
  getHolidays,
  parseDate,
} from 'utils/date';
import { findIcon } from 'utils/icons';
import Button from 'components/Button/Button';
import DatePickerModal from 'components/DatePickerModal/DatePickerModal';
import DropDown from 'components/DropDown/DropDown';
import classes from './ReportDateDropdown.module.scss';

interface ReportDateDropdownProps {
  id?: string;
  reportKey?: string;
  busPeriod: number;
  requestType: DateSegmentType;
  setBusPeriod: (v: Date) => void;
  setRequestType: (v: DateSegmentType) => void;
  setRequestDate: (v: string) => void;
  buttonClassName?: string;
  dropdownClassName?: string;
}

const ReportDateDropdown = ({
  reportKey,
  id,
  busPeriod,
  requestType,
  setBusPeriod,
  setRequestType,
  setRequestDate,
  buttonClassName,
  dropdownClassName,
}: ReportDateDropdownProps): JSX.Element => {
  const requestTypeOptions = ['DAILY', 'MTD', 'QTD', 'YTD'];
  const today = startOfToday();
  const startOfJanuary2023 = setMonth(startOfYear(new Date(2023, 0, 1)), 0);
  const minDate =
    id === 'pick12' ? subYears(startOfJanuary2023, 1) : subYears(today, 5);
  const [maxDate, setMaxDate] = useState<Date>(lastDayOfMonth(today));
  const [showMonthYearPicker, setShowMonthYearPicker] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const { t } = useTranslation(namespaces.reports);

  const { data: calendar } = useGetCalendarDays({});

  const businessDays = useMemo(() => getBusinessDays(calendar), [calendar]);
  const holidays = useMemo(() => getHolidays(calendar), [calendar]);

  const isBusinessDay = useCallback(
    (d: Date) => !isNil(find(businessDays, (day) => isSameDay(day, d))),
    [businessDays]
  );

  useEffect(() => {
    const dateFormat = choose(
      requestType === 'QTD',
      DateFormatEnum.quarterMonth,
      DateFormatEnum.monthPicker
    );

    setRequestDate(
      choose(
        requestType === 'DAILY',
        formatDate(busPeriod),
        formatDate(busPeriod, dateFormat)
      ) as string
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [busPeriod, requestType]);

  const calendarKey = useMemo(() => toString(Date.now()), []);

  const {
    requestType: calendarRequestType,
    updateRequestType: calendarUpdateRequestType,
    updateBusPeriod: calendarUpdateBusPeriod,
  } = useReportsConfig({ key: calendarKey });

  const setCalendarTabFilters = () => {
    calendarUpdateRequestType(requestType);
    if (busPeriod) {
      calendarUpdateBusPeriod(fromUnixTime(busPeriod));
    }
  };

  useEffect(() => {
    switch (calendarRequestType) {
      case 'DAILY':
        setMaxDate(startOfToday());
        setShowMonthYearPicker(false);
        break;
      default:
        setMaxDate(lastDayOfMonth(startOfToday()));
        setShowMonthYearPicker(true);
        break;
    }
  }, [calendarRequestType]);

  const filterOptions = () => {
    const options: SortOption[] = map(requestTypeOptions, (key) => ({
      key,
      name: key,
    }));
    return concat(options, {
      key: 'key',
      name: t('reports:customDate'),
      icon: findIcon('pencil', 'far'),
      showCalendar: true,
    });
  };

  return (
    <div className={classes.content}>
      <Button
        variant="link"
        text={toString(
          filterOptions().find((option) => {
            return option.key === requestType;
          })?.name
        )}
        className={classNames(classes.dropdownButton, buttonClassName)}
        rightIcon={findIcon('caret-down', 'fas')}
        testid="requestType-button"
        id={id}
      />
      <DropDown
        trigger={id}
        dismissOnSelect
        className={dropdownClassName}
        alignment="end"
        testid="requesttype-dropdown"
        filterOptions={filterOptions()}
        selectedItem={requestType}
        onOptionClick={(option) => {
          if (option.showCalendar) {
            setCalendarTabFilters();
            setIsCalendarOpen(true);
          } else {
            setRequestType(option.key as DateSegmentType);
            setBusPeriod(new Date());
          }
        }}
        id={id}
      />
      <DatePickerModal
        isOpen={isCalendarOpen}
        setIsOpen={setIsCalendarOpen}
        onDone={(currentDate: Date) => {
          setBusPeriod(currentDate);
          setRequestType(calendarRequestType);
        }}
        date={parseDate(busPeriod)}
        maxDate={maxDate}
        minDate={minDate}
        showMonthYearPicker={showMonthYearPicker}
        filterDate={(d: Date) =>
          showMonthYearPicker ? true : !isWeekend(d) || isBusinessDay(d)
        }
        holidays={holidays}
        businessDays={businessDays}
        reportKey={reportKey}
        calendarKey={calendarKey}
        showSegmentedButtons
        testid="calendar-dashboard"
      />
    </div>
  );
};

export default ReportDateDropdown;
