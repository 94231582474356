import { useTranslation } from 'react-i18next';
import {
  filter,
  find,
  flatten,
  groupBy,
  head,
  isNil,
  map,
  max,
  reduce,
  size,
  toNumber,
  toString,
} from 'lodash';
import { emptyString } from 'common/utils/valueFormatter';
import {
  addMonths,
  differenceInHours,
  differenceInYears,
  endOfMonth,
  format,
  formatISO,
  formatDistanceToNowStrict,
  fromUnixTime,
  intervalToDuration,
  isSameDay,
  isSameYear,
  isToday,
  parseISO,
  startOfMonth,
  startOfToday,
  subYears,
  formatDuration,
  isAfter,
  isBefore,
  setMonth,
} from 'date-fns';
import en from 'date-fns/locale/en-US';
import fr from 'date-fns/locale/fr-CA';
import i18n from 'i18n/i18n';
import { namespaces } from 'i18n/i18n.constants';
import i18next from 'i18next';
import type { BusDayHashMap, CalendarDay } from 'models/Reports';

export type DateParam = Date | number | string;

export enum DateFormatEnum {
  fullDate = 'MMM d, y',
  fullDateV2 = 'MM/dd/y',
  shortMonth = 'MMM d',
  fullMonth = 'MMMM d',
  monthPicker = 'MMM y',
  fullMonthPicker = 'MMMM y',
  dayShortMonth = 'iii, MMM d',
  fullWeekDay = 'iii, MMMM d',
  monthOnly = 'MMMM',
  ampmTime = 'h:mm a',
  chatTime = 'h:mmaaa',
  snoozeTime = 'E, h:mm a',
  showAfterTime = 'y-MM-dd HH:mm:ss',
  priceOverrideTime = 'M/d/y HH:mm:ss',
  reportsDateAPI = 'MM/dd/y',
  standardDate = 'y-MM-dd',
  activitiesDateRangeAPI = 'y-MM-dd',
  documentsDateRangeAPI = 'M/d/y',
  DocumentCardDateAPI = 'MM/dd/y h:mm a',
  ISO = 'ISO',
  PlanGroupAndInventoryAPI = 'MM_dd_yyyy',
  quarterMonth = 'QQQ y',
}

export const getDateLocale = (language?: string): Locale => {
  if (process.env.REACT_APP_MULITPLE_LANGUAGE === 'true') {
    if (language !== undefined && language === 'fr') {
      return fr;
    }
  }
  return en;
};

export const parseDate = (date?: DateParam): Date => {
  if (typeof date === 'number') {
    const result = fromUnixTime(date);
    // DOC: hack to solve inconsistent API behavior of sending milliseconds instead of epoch
    if (Math.abs(differenceInYears(result, new Date())) > 100) {
      return new Date(date);
    }
    return result;
  }
  if (typeof date === 'string') {
    return parseISO(date);
  }
  if (isNil(date)) {
    return new Date();
  }
  return date;
};

export const formatDate = (
  date?: DateParam,
  dateFormat: string = DateFormatEnum.fullDate,
  language = i18next.language
): string => {
  try {
    if (dateFormat === 'ISO') {
      return formatISO(date as Date);
    }
    return format(parseDate(date), dateFormat, {
      locale: getDateLocale(language),
    });
  } catch (e) {
    // DOC: if invalid date, set to today
    return format(parseDate(), dateFormat);
  }
};

export const formatLastUpdatedDate = (
  date?: DateParam,
  dateType = DateFormatEnum.shortMonth,
  language?: string
): string => {
  try {
    const lastUpdatedDate = parseDate(date);
    return format(
      lastUpdatedDate,
      dateType === DateFormatEnum.shortMonth
        ? `${isToday(lastUpdatedDate) ? `'Today'` : `${dateType},`}${
            isSameYear(lastUpdatedDate, new Date()) ? '' : ' y'
          } ${DateFormatEnum.ampmTime}`
        : `${isToday(lastUpdatedDate) ? `'Today'` : `${dateType}`}${
            isSameYear(lastUpdatedDate, new Date()) ? '' : ', y'
          }`,
      {
        locale: getDateLocale(language),
      }
    );
  } catch (e) {
    return '';
  }
};

export const getChatDateFormat = (date?: DateParam): string => {
  try {
    const chatDate = parseDate(date);
    return `${
      isToday(chatDate)
        ? ''
        : `${DateFormatEnum.shortMonth}${
            isSameYear(chatDate, new Date()) ? '' : ', y'
          } - `
    }${DateFormatEnum.chatTime}`;
  } catch (e) {
    return '';
  }
};

export const formatCharDate = (date?: DateParam): string =>
  formatDate(date, getChatDateFormat(date));

export const formatCardDate = (
  date?: DateParam,
  fullMonth = false,
  showHours = true,
  language?: string
): string => {
  try {
    const cardDate = parseDate(date);
    if (showHours && differenceInHours(new Date(), cardDate) < 24) {
      return formatDistanceToNowStrict(cardDate, {
        locale: getDateLocale(language),
      });
    }
    return format(
      cardDate,
      `${fullMonth ? DateFormatEnum.fullMonth : DateFormatEnum.shortMonth}${
        isSameYear(cardDate, new Date()) ? '' : ', y'
      }`,
      {
        locale: getDateLocale(language),
      }
    );
  } catch (e) {
    return emptyString;
  }
};

export const formatSnoozeDate = (
  date?: DateParam,
  language?: string,
  separator = ` 'at' `
): string => {
  try {
    const cardDate = parseDate(date);
    return format(
      cardDate,
      `${DateFormatEnum.shortMonth}${
        isSameYear(cardDate, new Date()) ? '' : ', y'
      }${separator}${DateFormatEnum.ampmTime}`,
      {
        locale: getDateLocale(language),
      }
    );
  } catch (e) {
    return '';
  }
};

export const getReportsCalendarStartDate = (date?: DateParam): Date =>
  startOfMonth(subYears(parseDate(date), 5));

export const getReportsCalendarEndDate = (date?: DateParam): Date =>
  endOfMonth(addMonths(parseDate(date), 1));

export const useGetSharedBusinessDayLabel = (
  date?: DateParam,
  pBusDays: CalendarDay[] = []
): string => {
  // DOC: busDay === 0 is for weekends
  const busDays = filter(pBusDays, ({ busDay }) => toNumber(busDay) > 0);
  const {
    t,
    i18n: { language },
  } = useTranslation(namespaces.reports);
  try {
    let result = '';
    const today = startOfToday();
    const selectedDate = parseDate(date);
    const implodeBusDays = (days: CalendarDay[] = []) =>
      `${map(days.slice(0, -1), ({ gregorianDate }) =>
        formatDate(gregorianDate, DateFormatEnum.shortMonth, language)
      ).join(', ')}${size(days) > 1 ? ' and ' : ''}${formatDate(
        head(days.slice(-1))?.gregorianDate,
        DateFormatEnum.shortMonth,
        language
      )}`;

    const formatSameBusDays = (days: CalendarDay[] = []) =>
      t('totalsSameBusDay', { day: implodeBusDays(days) });

    if (!isNil(busDays) && size(busDays) > 0) {
      const busDay = find(busDays, ({ gregorianDate }) =>
        isSameDay(parseDate(gregorianDate), selectedDate)
      );
      const primaryDay = find(busDays, { primary: true });

      if (today > selectedDate) {
        if (busDay?.primary) {
          result = formatSameBusDays(busDays);
        } else {
          result = t('totalsDifferentBusDay', {
            day: toString(busDay?.busDay),
            date: formatDate(
              primaryDay?.gregorianDate,
              DateFormatEnum.shortMonth,
              i18n.language
            ),
          });
        }
      } else {
        const prevBusDays = filter(
          busDays,
          ({ gregorianDate }) => parseDate(gregorianDate) < today
        );
        if (size(prevBusDays) > 0) {
          if (isSameDay(today, selectedDate)) {
            result = t('totalDailyMultiple', {
              day: implodeBusDays(prevBusDays),
              count: size(prevBusDays),
            });
          } else {
            result = formatSameBusDays(prevBusDays);
          }
        }
      }
    }
    return result;
  } catch (e) {
    return '';
  }
};

export const getPeriorMaxBusDay = (
  busDayMap: BusDayHashMap,
  period: string | number | Date
): number => {
  return (
    toNumber(
      max(
        filter(
          busDayMap,
          (_, key) => key.indexOf(formatDate(period, 'yyyy-MM')) === 0
        )
      )?.busDay
    ) || 0
  );
};

export const getBusinessDays = (calendar?: CalendarDay[]): Date[] =>
  map(
    flatten(
      filter(
        // DOC: group by year-month-busDay as we want to show days that share busDay
        groupBy(
          calendar,
          ({ gregorianDate, busDay }) =>
            `${formatDate(gregorianDate, DateFormatEnum.monthPicker)}-${busDay}`
        ),
        // DOC: filter for groups with more that one date sharing a nonZero busDay
        (g) => size(g) > 1 && head(g)?.busDay !== '0'
      )
    ),
    ({ gregorianDate }) => parseDate(gregorianDate)
  );

export const getBusDayMap = (calendar?: CalendarDay[]): BusDayHashMap =>
  reduce(
    calendar || [],
    (hashMap: BusDayHashMap, curr: CalendarDay) => {
      const temp = { ...hashMap };
      temp[curr.gregorianDate] = { ...curr };
      return temp;
    },
    {}
  );

export const getHolidays = (calendar?: CalendarDay[]): Date[] =>
  map(filter(calendar, { dateType: 'H' }), ({ gregorianDate }) =>
    parseDate(gregorianDate)
  );

export const formatMilliseconds = (milliseconds: number): string => {
  const duration = intervalToDuration({ start: 0, end: milliseconds });
  return formatDuration(duration);
};

export const clampDate = (date: Date, minDate: Date, maxDate: Date): Date => {
  let newDate = date;

  if (isAfter(minDate, maxDate)) {
    throw Error('`minDate` must be less than `maxDate`');
  }

  if (isAfter(date, maxDate)) {
    newDate = maxDate;
  } else if (isBefore(date, minDate)) {
    newDate = minDate;
  }

  return newDate;
};

export const getQuarterMonths = (quarter: number) => {
  const months = [];

  // Determine the starting month index based on the quarter (0-based index)
  const startMonth = (quarter - 1) * 3;

  for (let i = 0; i < 3; i += 1) {
    const date = setMonth(Date.now(), startMonth + i);
    months.push(format(date, 'MMM', { locale: getDateLocale() }));
  }

  return months;
};
