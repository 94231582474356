import { useSelector } from 'react-redux';
import { filter, get, map, size } from 'lodash';
import type { MutationStatus } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import { findFavoriteCustomersQueryKey } from 'api/customer/useFindFavoriteCustomers';
import { getCustomerQueryKey } from 'api/customer/useGetCustomer';
import {
  onSuccessMutation,
  doPromiseAPI,
  onErrorUpdate,
  onMutateUpdate,
} from 'api/helpers';
import type { UpdateMutationContext } from 'api/helpers';
import { findFavoriteSuppliersQueryKey } from 'api/supplier/useFindFavoriteSuppliers';
import { getSupplierQueryKey } from 'api/supplier/useGetSupplier';
import type { Customer } from 'models/Customer';
import type { SearchCustomerItem, SearchItem } from 'models/Search';
import type { Supplier } from 'models/Supplier';
import { ToastType } from 'models/Toast';
import type { RootState } from 'store/reducers';

interface UseRemoveFavoritesProps {
  isMultiple?: boolean;
  toastMessage?: string;
  onUndo?: () => void;
}

interface RemoveFavoritesBody {
  items: SearchItem[];
}

interface UseRemoveFavoritesResponse {
  status: MutationStatus;
  onRemoveFavorites: ({ items }: RemoveFavoritesBody) => void;
}

const useRemoveFavorites = ({
  isMultiple = false,
  onUndo,
  toastMessage = '',
}: UseRemoveFavoritesProps): UseRemoveFavoritesResponse => {
  const { axios } = useAxios();
  const { removeFavoritesAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { userInfo } = useSelector((state: RootState) => state.user);
  const userId = get(userInfo, 'userid', '');
  const { addToast } = useToasts();

  const doRemoveFavorites = ({ items }: RemoveFavoritesBody) => {
    return doPromiseAPI(async (cancelToken) => {
      const customers = filter(items, { type: 'customer' });
      if (size(customers) > 0) {
        await axios.delete(removeFavoritesAPI('customer', userId), {
          cancelToken,
          data: map(
            filter(items, { type: 'customer' }),
            ({ miLoc, id }: SearchCustomerItem) => ({ miLoc, custNo: id })
          ),
        });
      }
      const suppliers = filter(items, { type: 'supplier' });
      if (size(suppliers) > 0) {
        await axios.delete(removeFavoritesAPI('supplier', userId), {
          cancelToken,
          data: map(filter(items, { type: 'supplier' }), ({ id }) => ({
            supLocNo: id,
          })),
        });
      }
    });
  };

  interface RemoveFavoriteMutationContext {
    customersContext: UpdateMutationContext<Customer>[];
    suppliersContext: UpdateMutationContext<Supplier>[];
  }

  const { status, mutate } = useMutation(doRemoveFavorites, {
    onMutate: async (vars) => {
      const customers = filter(vars.items, { type: 'customer' });
      const customersContext = await onMutateUpdate<Customer>({
        queryClient,
        queryKey: getCustomerQueryKey,
        updatedItems: map(customers, (item) => {
          const { miLoc, id } = item as SearchCustomerItem;
          return { miLoc, customerNo: id, isBookmarked: false };
        }),
        findPredicates: map(customers, (item) => {
          const { miLoc, id } = item as SearchCustomerItem;
          return { miLoc, customerNo: id };
        }),
        isSingleQuery: true,
      });
      const suppliers = filter(vars.items, { type: 'supplier' });

      const suppliersContext = await onMutateUpdate<Supplier>({
        queryClient,
        queryKey: getSupplierQueryKey,
        updatedItems: map(suppliers, ({ id }) => ({
          supLocNo: id,
          bookmarked: 'N',
        })),
        findPredicates: map(suppliers, ({ id }) => ({ supLocNo: id })),
        isSingleQuery: true,
      });
      if (isMultiple) {
        addToast({
          text: toastMessage,
          button: { role: 'undo', text: 'Undo', handler: onUndo },
          testid: 'remove-favorites-toast',
        });
      }
      return { customersContext, suppliersContext };
    },
    onSuccess: (data, vars, context) => {
      const { customersContext, suppliersContext } =
        context as RemoveFavoriteMutationContext;
      if (size(customersContext) > 0) {
        void onSuccessMutation(queryClient, findFavoriteCustomersQueryKey);
      }
      if (size(suppliersContext) > 0) {
        void onSuccessMutation(queryClient, findFavoriteSuppliersQueryKey);
      }
    },
    onError: (error, vars, context) => {
      const { customersContext, suppliersContext } =
        context as RemoveFavoriteMutationContext;
      addToast({
        type: ToastType.error,
        text: 'Remove favorites operation failed. Please try again later.',
        testid: 'remove-favorites-error-toast',
      });
      onErrorUpdate<Customer>({
        queryClient,
        context: customersContext,
        pickProps: ['isBookmarked'],
        isSingleQuery: true,
      });
      onErrorUpdate<Supplier>({
        queryClient,
        context: suppliersContext,
        pickProps: ['bookmarked'],
        isSingleQuery: true,
      });
    },
  });

  return {
    status,
    onRemoveFavorites: ({ items }: RemoveFavoritesBody) => mutate({ items }),
  };
};

export default useRemoveFavorites;
