import type { TFunction } from 'react-i18next';
import { includes, isEmpty } from 'lodash';
import { SupportedBiometricType } from '@ionic-enterprise/identity-vault';
import { UnlockMode } from 'providers/VaultProvider';

interface VaultSettingsHelperProps {
  unlockMode: UnlockMode;
  isBiometricEnabled: boolean;
  supportedBiometricTypes: SupportedBiometricType[];
  t: TFunction;
}

const vaultChangerSettings = ({
  unlockMode,
  isBiometricEnabled,
  supportedBiometricTypes,
  t,
}: VaultSettingsHelperProps) => {
  let lockTitle = '';
  let switchTitle = '';
  let isSwitcherEnable = true;
  let biometricSwitcher = false;
  let customPinSwitcher = false;
  if (
    includes(
      [UnlockMode.BiometricsScreenLock, UnlockMode.Biometrics],
      unlockMode
    )
  ) {
    switchTitle = t('settings:switchToCustomPin');
    customPinSwitcher = true;
    lockTitle = t('appPin');
  } else if (isBiometricEnabled) {
    switchTitle = t('settings:switchToBio');
    lockTitle =
      !isEmpty(supportedBiometricTypes) &&
      supportedBiometricTypes[0] === SupportedBiometricType.Face
        ? t('faceID')
        : t('touchID');
    biometricSwitcher = true;
  } else {
    isSwitcherEnable = false;
  }
  return {
    isSwitcherEnable,
    biometricSwitcher,
    customPinSwitcher,
    lockTitle,
    switchTitle,
  };
};

export default vaultChangerSettings;
