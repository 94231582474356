import { useTranslation } from 'react-i18next';
import type { AgreementInformation } from 'models/Customer';

interface PopulateCommitmentData {
  title: string;
  value?: string;
}

interface PopulateCommitmentDataResponse {
  commitmentData: PopulateCommitmentData[];
}

const usePopulateCommitmentData = (
  agreementInformation?: AgreementInformation
): PopulateCommitmentDataResponse => {
  const { t } = useTranslation();
  const getCostSavingsCommitment = (value: string | undefined) => {
    switch (value) {
      case undefined: {
        return '';
      }
      case 'Y': {
        return t('common:yes');
      }
      case 'N': {
        return t('common:no');
      }
      default: {
        return '';
      }
    }
  };

  const commitmentData = [
    {
      title: t('agreementInfo:commitment'),
      value:
        agreementInformation?.costSavingsCommitment?.timeFrameStartDate &&
        agreementInformation?.costSavingsCommitment?.timeFrameEndDate &&
        `${agreementInformation?.costSavingsCommitment?.timeFrameStartDate}${
          agreementInformation?.costSavingsCommitment?.timeFrameEndDate
            ? ` to ${agreementInformation?.costSavingsCommitment?.timeFrameEndDate}`
            : ''
        }`,
    },
    {
      title: t('agreementInfo:commitPercentage'),
      value: agreementInformation?.costSavingsCommitment?.currentCommitPercent
        ? `${agreementInformation?.costSavingsCommitment?.currentCommitPercent}%`
        : '',
    },
    {
      title: t('agreementInfo:corporateApproval'),
      value: getCostSavingsCommitment(
        agreementInformation?.costSavingsCommitment?.motionCorpApproval
      ),
    },
    {
      title: t('agreementInfo:customerApproval'),
      value: getCostSavingsCommitment(
        agreementInformation?.costSavingsCommitment?.customerApproval
      ),
    },
    {
      title: t('agreementInfo:electronicApproval'),
      value: getCostSavingsCommitment(
        agreementInformation?.costSavingsCommitment?.useApprovalRouting
      ),
    },
    {
      title: t('agreementInfo:guaranteed'),
      value: getCostSavingsCommitment(
        agreementInformation?.costSavingsCommitment?.guaranteed
      ),
    },
  ];

  return {
    commitmentData,
  };
};

export default usePopulateCommitmentData;
