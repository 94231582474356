import { useTranslation } from 'react-i18next';
import { isNil, toString, toNumber, omit, size } from 'lodash';
import type { MutationStatus } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { namespaces } from 'i18n/i18n.constants';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import type { UpdateMutationContext } from 'api/helpers';
import {
  doPromiseAPI,
  onErrorUpdate,
  onMutateUpdate,
  onSuccessMutation,
} from 'api/helpers';
import type { Contact, InviteContactBody } from 'models/Contact';
import type { SearchItemType } from 'models/Search';
import { ToastType } from 'models/Toast';
import { findContactsQueryKey } from './useFindContacts';
import { getContactQueryKey } from './useGetContact';

interface InviteContactMutationProps {
  searchType: SearchItemType;
  miLoc: string;
  id: string;
  sequenceNo?: number;
}

interface UseInviteContactResponse {
  status: MutationStatus;
  inviteContact: (body: InviteContactBody) => void;
}

interface InviteResponseData {
  data: {
    status: string;
    error?: string;
  };
}

interface ContactsMutationContext {
  contactContext: UpdateMutationContext<Contact>[];
  contactsContext: UpdateMutationContext<Contact>[];
}

const useInviteContact = ({
  searchType,
  miLoc,
  id,
  sequenceNo,
}: InviteContactMutationProps): UseInviteContactResponse => {
  const { axios } = useAxios();
  const { inviteContactAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { t } = useTranslation(namespaces.contact);
  const { addToast } = useToasts();
  const doInviteContact = (body: InviteContactBody) => {
    return doPromiseAPI(async (cancelToken) => {
      return axios.post(
        inviteContactAPI(
          searchType,
          miLoc,
          id,
          toString(sequenceNo || body.sequenceNo)
        ),
        { ...omit(body, ['resend']) },
        { cancelToken }
      );
    });
  };

  const updateQueryKeyParams = {
    searchType,
    id,
    miLoc,
  };

  const { mutate, status } = useMutation(doInviteContact, {
    onMutate: async (vars) => {
      const contactContext = await onMutateUpdate<Contact>({
        queryClient,
        queryKey: getContactQueryKey,
        queryKeyParams: {
          ...updateQueryKeyParams,
          sequenceNo,
        },
        updatedItems: [
          { ...omit(vars, ['resend']), sequenceNo: toNumber(sequenceNo) },
        ],
        findPredicates: [
          {
            sequenceNo,
          },
        ],
        isInfiniteQuery: false,
        isSingleQuery: true,
      });
      const contactsContext = await onMutateUpdate<Contact>({
        queryClient,
        queryKey: findContactsQueryKey,
        queryKeyParams: updateQueryKeyParams,
        updatedItems: [
          { ...omit(vars, ['resend']), sequenceNo: toNumber(sequenceNo) },
        ],
        findPredicates: [
          {
            sequenceNo,
          },
        ],
        isInfiniteQuery: true,
      });
      return { contactContext, contactsContext };
    },

    onSuccess: ({ data }: InviteResponseData, vars, context) => {
      const { contactContext, contactsContext } =
        context as ContactsMutationContext;
      if (!isNil(data.error)) {
        addToast({
          type: ToastType.error,
          text: data.error,
          testid: 'invite-contact-error-toast',
        });
      } else {
        addToast({
          text: vars.resend
            ? t('inviteResentToast')
            : t('inviteSentToast', {
                name: vars?.name || t('editDefaultNameToast'),
              }),
          testid: 'invite-contact-toast',
        });
      }
      if (size(contactsContext) > 0) {
        void onSuccessMutation(
          queryClient,
          findContactsQueryKey,
          updateQueryKeyParams
        );
      }
      if (size(contactContext) > 0) {
        void onSuccessMutation(queryClient, getContactQueryKey, {
          searchType,
          miLoc,
          id,
          sequenceNo: toNumber(sequenceNo),
        });
      }
    },
    onError: (error, vars, context) => {
      const { contactContext, contactsContext } =
        context as ContactsMutationContext;
      addToast({
        type: ToastType.error,
        text: t('common:standardErrorMessage'),
        testid: 'invite-contact-error-toast',
      });
      onErrorUpdate<Contact>({
        queryClient,
        context: contactsContext,
        isInfiniteQuery: true,
      });
      onErrorUpdate<Contact>({
        queryClient,
        context: contactContext,
        isSingleQuery: true,
      });
    },
  });

  return { status, inviteContact: (body: InviteContactBody) => mutate(body) };
};

export default useInviteContact;
