import type { AxiosError } from 'axios';
import { isEmpty } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { doPromiseAPI, useKeyUserId } from 'api/helpers';

const getAvatarQueryKey = 'avatar';

export interface UseGetAvatarResponse {
  data?: string;
}

const useGetAvatarById = (
  employeeId: string,
  enabled?: boolean
): UseGetAvatarResponse => {
  const { axios } = useAxios();
  const { avatarAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();

  const doGetAvatar = () => {
    return doPromiseAPI<string>(async (cancelToken) => {
      const response = await axios.get<Blob>(avatarAPI(employeeId), {
        responseType: 'blob',
        cancelToken,
      });

      // TODO: this is a hack to work around an API quirk where the response can be something other than an image
      if (!response.data.type.startsWith('image/')) {
        throw new Error(
          `Invalid data type of '${response.data.type}' received by useGetAvatar.`
        );
      }

      const reader = new FileReader();
      reader.readAsDataURL(response.data);
      return new Promise<string>((resolve) => {
        reader.onloadend = () => {
          const base64data = reader.result as string;
          resolve(base64data);
        };
      });
    });
  };

  // TODO: temporarily disable query until API data is fixed
  const isQueryEnabled = false;
  return useQuery<string, AxiosError>(
    createQueryKey(getAvatarQueryKey, { id: employeeId }),
    doGetAvatar,
    {
      enabled: isQueryEnabled && !isEmpty(employeeId) && enabled,
      staleTime: 30 * 60 * 1000,
      cacheTime: 24 * 60 * 60 * 1000,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: (failureCount, err) =>
        err.response?.status === 404 ? false : !(failureCount < 1),
    }
  );
};

export default useGetAvatarById;
