import React from 'react';
import classNames from 'classnames';
import { IonCard } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { formatCardDate } from 'utils/date';
import Text from 'components/Text/Text';
import classes from './CardMini.module.scss';

interface CardMiniProps {
  text: string;
  description: string;
  aging?: number;
  testid: string;
}

const CardMini = ({
  className,
  text,
  description,
  aging,
  href,
  onClick,
  disabled,
  testid,
}: CardMiniProps &
  React.ComponentProps<typeof IonCard> &
  IonicReactProps): JSX.Element => (
  <IonCard
    className={classNames(className, classes.cardMini)}
    href={href}
    routerLink={href}
    onClick={onClick}
    disabled={disabled}
    data-testid={`${testid}-card-mini`}
  >
    <div className={classes.wrapper}>
      <Text
        variant="label-header"
        text={text}
        testid={`${testid}-text-card-mini`}
      />
      {aging && (
        <Text
          className={classes.aging}
          variant="label-micro"
          text={formatCardDate(aging)}
          testid={`${testid}-aging-card-mini`}
        />
      )}
    </div>
    <Text
      variant="content-small"
      text={description}
      testid={`${testid}-description-card-mini`}
    />
  </IonCard>
);

export default CardMini;
