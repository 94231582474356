import SentryCategories from 'constants/sentryCategories';
import { Preferences } from '@capacitor/preferences';
import * as Sentry from '@sentry/capacitor';

const AUTH_TOKEN_KEY = 'Token';

const AuthTokenStorage = {
  async get(): Promise<string | null> {
    const token = await Preferences.get({ key: AUTH_TOKEN_KEY });
    return token.value;
  },
  set(token: string): Promise<void> {
    return Preferences.set({ key: AUTH_TOKEN_KEY, value: token });
  },
  remove(): Promise<void> {
    return Preferences.remove({ key: AUTH_TOKEN_KEY }).then(() =>
      Sentry.addBreadcrumb({
        type: 'info',
        category: SentryCategories.STORAGE,
        message: `${AUTH_TOKEN_KEY} removed from storage`,
        level: 'warning',
      })
    );
  },
};

export default AuthTokenStorage;
