import type { AxiosError } from 'axios';
import type { Dictionary } from 'lodash';
import {
  concat,
  filter,
  find,
  includes,
  map,
  size,
  toNumber,
  toString,
  union,
} from 'lodash';
import useAPIUrl from 'api';
import type { Report } from 'DocumentsApp/models/Report';
import { useAxios } from 'providers/AxiosProvider';
import type { InspectionReport, ReportImage } from 'models/InspectionReport';
import { documentListConfig } from './useUpdateValues';
import useDocumentHelpers from './utils/documentHelpers';

export interface UpdateImagesBody {
  report?: InspectionReport;
  reportId?: string | number;
  miLoc?: string;
  updatedObjects?: unknown[];
  updatedImages?: ReportImage[];
  removedImages?: number[];
}

interface UseUpdateImagesResponse {
  doUpdateImagesOnline: (vars: UpdateImagesBody) => Promise<void>;
  doUpdateImagesOffline: (vars: UpdateImagesBody) => Report;
}

const useUpdateImages = (): UseUpdateImagesResponse => {
  const { axios } = useAxios();
  const { reportImagesAPI } = useAPIUrl();
  const { getDocumentErrors, handleDocumentErrors } = useDocumentHelpers();

  const doUpdateImagesOnline = async (vars: UpdateImagesBody) => {
    try {
      const removedImagesToSend = union(
        filter(vars.removedImages, (p) => toNumber(p) > 0)
      );
      if (size(removedImagesToSend) > 0) {
        const { data: removeData } = await axios.delete<unknown>(
          reportImagesAPI(),
          { data: removedImagesToSend }
        );
        getDocumentErrors(removeData as AxiosError);
      }
    } catch (e) {
      // DOC: delete error ignored
    }
    try {
      if (size(vars.updatedImages) > 0) {
        const { data: updateData } = await axios.post<unknown>(
          reportImagesAPI(),
          map(vars.updatedImages, (p) => {
            const updatedItems = map(
              vars.updatedObjects,
              (item: Dictionary<string>) => {
                const temp = {
                  ...item,
                  ...JSON.parse(item.inputJson || '{}'),
                } as Dictionary<string>;
                temp.objectId =
                  temp[documentListConfig.parts.id] ||
                  temp[documentListConfig.vasCodes.id] ||
                  temp[documentListConfig.genericSections.id];
                return temp;
              }
            ) as unknown as Dictionary<string>[];
            const foundItem = find(updatedItems, (item) => {
              return toString(item.itemId) === toString(p.objectId);
            });
            const imageId = toNumber(p.imageId) > 0 ? toNumber(p.imageId) : 0;
            const objectId =
              toNumber(p.objectId) > 0
                ? toNumber(p.objectId)
                : toNumber(foundItem?.objectId) || 0;
            return {
              ...p,
              ...(vars.miLoc ? { miLoc: vars.miLoc } : undefined),
              reportId: vars.reportId,
              imageId,
              objectId,
            };
          })
        );
        getDocumentErrors(updateData as AxiosError);
      }
    } catch (e) {
      handleDocumentErrors({
        error: e as AxiosError,
        defaultErrorText: 'Update report images operation failed',
      });
    }
  };

  const doUpdateImagesOffline = (vars: UpdateImagesBody) => {
    const removedImages = concat(
      vars.report?.removedImages || [],
      vars.removedImages || []
    );
    const updatedImages = concat(
      filter(
        vars.report?.reportImages || [],
        (p) =>
          !includes(removedImages, p.imageId) &&
          !find(vars.updatedImages, (u) => u.imageId === p.imageId)
      ),
      vars.updatedImages || []
    );
    const addedImages = concat(
      filter(
        vars.report?.addedImages || [],
        (p) =>
          !includes(removedImages, p.imageId) &&
          !find(vars.updatedImages, (u) => u.imageId === p.imageId)
      ),
      vars.updatedImages || []
    );
    return {
      reportImages: map(updatedImages, (p) => ({
        ...p,
        reportId: vars.reportId,
      })),
      addedImages: map(addedImages, (p) => ({ ...p, reportId: vars.reportId })),
      removedImages: filter(removedImages, (p) => p > 0),
    } as Report;
  };

  return {
    doUpdateImagesOnline,
    doUpdateImagesOffline,
  };
};

export default useUpdateImages;
