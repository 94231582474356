import React from 'react';
import classNames from 'classnames';
import { IonCard, IonRow } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import CorpAccIcon from 'common/components/CorpAccIcon/CorpAccIcon';
import useAccessControls, { AccessControlType } from 'hooks/useAccessControls';
import Pick12Icon from 'components/Pick12Icon/Pick12Icon';
import Text from 'components/Text/Text';
import classes from './RecentlyViewedCard.module.scss';

interface RecentlyViewedCardProps {
  text: string;
  description: string;
  testid: string;
  searchType?: string;
  customerPick12?: boolean;
  isCorpAccountorInfo?: boolean;
}

const RecentlyViewedCard = ({
  className,
  text,
  description,
  href,
  onClick,
  testid,
  searchType,
  customerPick12,
  isCorpAccountorInfo,
}: RecentlyViewedCardProps &
  React.ComponentProps<typeof IonCard> &
  IonicReactProps): JSX.Element => {
  const ac = useAccessControls(AccessControlType.viewCustomers);

  return (
    <IonCard
      className={classNames(className, classes.card)}
      href={href}
      routerLink={href}
      onClick={onClick}
      data-testid={testid}
      disabled={!ac && searchType === 'customer'}
    >
      <IonRow className={classes.wrapper}>
        <div>
          {customerPick12 && <Pick12Icon testid={testid} />}
          <CorpAccIcon
            showIcon={isCorpAccountorInfo}
            testid={testid}
            className={classes.corpIcon}
          />
          <Text
            className={classes.title}
            variant="label-header"
            text={text}
            testid={`${testid}-text`}
          />
        </div>
        <Text
          className={classes.description}
          variant="content-small"
          text={description}
          testid={`${testid}-description`}
        />
      </IonRow>
    </IonCard>
  );
};

export default RecentlyViewedCard;
