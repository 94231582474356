import { toNumber } from 'lodash';
import i18next from 'i18n/i18n';
import * as yup from 'yup';
import type { SelectModalItem } from 'models/Search';

export interface AddZCodedItemForm {
  mfgPartNo?: string;
  mfrCtlNo?: SelectModalItem;
  group2digits?: SelectModalItem;
  group4digits?: SelectModalItem;
  isRepair?: boolean;
  repairShop?: SelectModalItem;
  detailsForm?: boolean;
  custStockNo?: string;
  custComment?: string;
  quantity?: string;
  salesUom?: SelectModalItem;
  unitCost?: string;
  promsdDelivDt?: string;
}

export const AddZCodedItemSchema: yup.ObjectSchema<AddZCodedItemForm> = yup
  .object()
  .shape({
    mfgPartNo: yup.string().required(i18next.t('common:forms:requiredError')),
    mfrCtlNo: yup
      .mixed<SelectModalItem>()
      .required(i18next.t('common:forms:requiredError')),
    group2digits: yup
      .mixed<SelectModalItem>()
      .required(i18next.t('common:forms:requiredError')),
    group4digits: yup
      .mixed<SelectModalItem>()
      .required(i18next.t('common:forms:requiredError')),
    isRepair: yup.boolean(),
    repairShop: yup.mixed<SelectModalItem>().when('isRepair', {
      is: true,
      then: (schema) =>
        schema.required(i18next.t('common:forms:requiredError')),
    }),
    detailsForm: yup.boolean(),
    custStockNo: yup.string(),
    custComment: yup.string(),
    quantity: yup.string().when('detailsForm', {
      is: true,
      then: (schema) =>
        schema
          .required(i18next.t('common:forms:requiredError'))
          .test(
            'moreThan0',
            i18next.t('common:forms:moreThanZeroError'),
            (v) => toNumber(v) > 0
          ),
    }),
    salesUom: yup.mixed<SelectModalItem>(),
    unitCost: yup.string(),
    promsdDelivDt: yup.string(),
  });
