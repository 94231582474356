import { isNil } from 'lodash';
import { useQueryClient } from '@tanstack/react-query';
import { getDigitalSalesReportQueryKey } from 'ReportsApp/api/useGetDigitalSalesReport';
import type { SalesReportRow } from 'ReportsApp/api/useGetSalesDashboard';
import { getSalesDashboardQueryKey } from 'ReportsApp/api/useGetSalesDashboard';
import { getSalesReportQueryKey } from 'ReportsApp/api/useGetSalesReport';
import { getPlaceholderData } from 'api/helpers';
import { getReportDrillDownQueryKey } from 'api/salesReports/useGetReportDrillDown';
import type { AccountRep, ReportDrillDownItem } from 'models/Reports';

export const getAccountRepQueryKey = 'account-rep';

interface UseGetAccountRepProps {
  miLoc: string;
  id: string;
  enabled?: boolean;
}

interface AccountRepData {
  data?: AccountRep;
}

const useGetAccountRep = ({
  miLoc,
  id,
}: UseGetAccountRepProps): AccountRepData => {
  const queryClient = useQueryClient();

  const accountRep = getPlaceholderData<ReportDrillDownItem>({
    queryClient,
    queryKey: getReportDrillDownQueryKey,
    findPredicate: { miLoc, id },
    objectKey: 'items',
  });

  if (!isNil(accountRep)) {
    return { data: { miLoc, id, name: accountRep.Name } };
  }

  const newAccountRep = getPlaceholderData<ReportDrillDownItem>({
    queryClient,
    queryKey: getSalesReportQueryKey,
    findPredicate: { miLoc, id },
    objectKey: 'items',
  });

  if (!isNil(newAccountRep)) {
    return { data: { miLoc, id, name: newAccountRep.Name } };
  }

  const digitalsalesReportAccountRep = getPlaceholderData<ReportDrillDownItem>({
    queryClient,
    queryKey: getDigitalSalesReportQueryKey,
    findPredicate: { miLoc, id },
    objectKey: 'items',
  });

  if (!isNil(digitalsalesReportAccountRep)) {
    return { data: { miLoc, id, name: digitalsalesReportAccountRep.Name } };
  }

  const salesReportAccountRep = getPlaceholderData<SalesReportRow>({
    queryClient,
    queryKey: getSalesDashboardQueryKey,
    findPredicate: { miLoc, repNo: id },
    objectKey: 'rows',
  });

  if (!isNil(salesReportAccountRep)) {
    return {
      data: {
        miLoc,
        id: salesReportAccountRep.repNo,
        name: salesReportAccountRep.repName,
      },
    };
  }

  return { data: undefined };
};

export default useGetAccountRep;
