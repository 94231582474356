import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { find, toNumber, toString } from 'lodash';
import { IonContent, IonPage, IonGrid, IonRow } from '@ionic/react';
import Header from 'common/components/Header/Header';
import { namespaces } from 'i18n/i18n.constants';
import i18next from 'i18next';
import { searchURL } from 'navigation';
import useGetContact from 'api/contacts/useGetContact';
import useInviteContact from 'api/contacts/useInviteContact';
import useRemoveContact from 'api/contacts/useRemoveContact';
import useUpdateContact from 'api/contacts/useUpdateContact';
import useFindDataCodes from 'api/data/useFindDataCodes';
import useAccessControls, { AccessControlType } from 'hooks/useAccessControls';
import useGoBack from 'hooks/useGoBack';
import type { Customer } from 'models/Customer';
import type {
  SearchCustomerContactItem,
  SearchItem,
  SearchItemType,
  SearchSupplierContactItem,
} from 'models/Search';
import type { Supplier } from 'models/Supplier';
import showInvite from 'utils/contact';
import { DateFormatEnum, formatDate } from 'utils/date';
import { getErrorMessage } from 'utils/helpers';
import { findIcon } from 'utils/icons';
import { concatRoutes } from 'utils/navigations';
import { handleSearchNavigation } from 'utils/search';
import ActionRow from 'components/ActionRow/ActionRow';
import AvatarCard from 'components/Avatar/AvatarCard';
import ContactModalV2 from 'components/Contacts/ContactModalV2/ContactModalV2';
import {
  PhoneCallRow,
  PhoneSMSRow,
} from 'components/Contacts/PhoneNumber/PhoneNumber';
import SnapshotLink from 'components/Contacts/SnapshotLink/SnapshotLink';
import { EmailRow } from 'components/Email/Email';
import Loader from 'components/Loader/Loader';
import ConfirmDialog from 'components/Modals/ConfirmDialog/ConfirmDialog';
import SendEmailModal from 'components/Modals/SendEmailModal/SendEmailModal';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './Contact.module.scss';

interface SnapshotsProps {
  searchType: SearchItemType;
}

interface ContactSearchURLParams {
  id: string;
  miLoc: string;
  seqNo: string;
}

const Contact = ({ searchType }: SnapshotsProps): JSX.Element => {
  const { id, miLoc, seqNo } = useParams<ContactSearchURLParams>();
  const { t } = useTranslation(namespaces.contact);
  const [sendEmailModal, setSendEmailModal] = useState(false);
  const ac = useAccessControls(AccessControlType.viewCustomers);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isOpenRemoveModal, setIsOpenRemoveModal] = useState(false);
  const [isOpenMakeMainModal, setIsOpenMakeMainModal] = useState(false);
  const { goBack } = useGoBack();

  const { data: codeList } = useFindDataCodes({
    codeType: searchType === 'customer' ? 'CUCONTYP' : 'CONTYPE',
  });

  const { data, searchTypeInfo, isLoading, error } = useGetContact({
    searchType,
    miLoc,
    id,
    seqNo,
  });
  const { text } = data as SearchItem;
  const {
    typeDesc,
    customerType,
    phone: customerPhone,
    registrationStatus,
    isWebRegistered,
    email,
    isMainContact,
    registrationCreationDate,
    customerPick12,
  } = data as SearchCustomerContactItem;
  const { supplierType, phone1, phone2 } = data as SearchSupplierContactItem;
  const { removeContact } = useRemoveContact({
    searchType,
    miLoc,
    id,
    sequenceNo: toNumber(seqNo),
  });
  const onRemoveContact = () => {
    removeContact({ name: text });
    return goBack();
  };
  const headerText = isLoading ? t('common:loading') : text;
  let snapShotEyebrow = '';
  let snapShotHref = '';
  let contactHeaderName;
  let inviteMiCom = false;
  let showResendButton = false;
  let callPhone: string | undefined;
  let callPhone2: string | undefined;
  let textPhone;
  if (searchType === 'customer') {
    snapShotEyebrow = `${miLoc}${id}`;
    snapShotHref = concatRoutes(
      searchURL(),
      handleSearchNavigation({ type: 'customer', miLoc, customerId: id })
    );
    contactHeaderName = (searchTypeInfo as Customer)?.name;
    inviteMiCom = (searchTypeInfo as Customer)?.inviteMiCom || false;
    showResendButton =
      registrationStatus === 'SENT' ||
      registrationStatus === 'EXPIRING' ||
      false;
    callPhone = customerPhone;
    textPhone = customerPhone;
  } else {
    snapShotEyebrow = `Supplier ${id}`;
    snapShotHref = concatRoutes(
      searchURL(),
      handleSearchNavigation({ type: 'supplier', miLoc, supplierId: id })
    );
    contactHeaderName = (searchTypeInfo as Supplier)?.supLocName;
    callPhone = phone1;
    callPhone2 = phone2;
  }

  const showInviteButton = showInvite(
    searchType,
    !!isWebRegistered,
    registrationStatus
  );
  const { inviteContact } = useInviteContact({
    searchType,
    miLoc,
    id,
    sequenceNo: toNumber(seqNo),
  });
  const { updateContact } = useUpdateContact({
    searchType,
    miLoc,
    id,
    sequenceNo: toNumber(seqNo),
  });

  const onMakeMainContact = () =>
    updateContact({
      name: text,
      type: customerType,
      phone: customerPhone,
      email,
      isMainContact: 'Y',
    });
  const [contactModalData, setContactModalData] =
    useState<
      Omit<
        React.ComponentProps<typeof ContactModalV2>,
        'isOpen' | 'setIsOpen' | 'searchType' | 'testid'
      >
    >();

  return (
    <IonPage className={classes.container} data-testid="search-detail-page">
      <Header
        className={classes.header}
        title={headerText}
        testid="search-detail"
        eyebrow={t('specificContactEyebrow', { searchType })}
      />
      <IonContent className={classes.content}>
        <Loader
          className={classes.loader}
          text={t('loadingSpecificContact', { searchType })}
          isOpen={isLoading}
          testid="loadingIndicator"
        />
        {error && (
          <WarningMessage
            className={classes.warningMessage}
            title={t('errorContact')}
            body={getErrorMessage(error)}
          />
        )}
        {!isLoading && (
          <IonGrid className={classes.detailsContainer}>
            <IonRow
              className={classNames(
                classes.firstRowWrapper,
                classes.rowWrapper
              )}
            >
              <AvatarCard
                displayName={text}
                className={classes.contactAvatar}
                backgroundColor={classes.contactFallback}
              />
              <Text
                className={classNames(classes.contactInfo, classes.titleUpper)}
                variant="title-info-card"
                text={text}
                testid="contact-name"
              />
              <Text
                className={classes.contactInfo}
                variant="content-default"
                text={
                  typeDesc ||
                  find(codeList, { id: customerType || supplierType })
                    ?.codeName ||
                  ''
                }
                testid="contact-title"
              />
              <Text
                className={classes.contactInfo}
                variant="content-small"
                text={miLoc}
                testid="contact-id"
              />
              {isMainContact === 'Y' && (
                <Text
                  className={classNames(
                    classes.contactInfo,
                    classes.mainContact
                  )}
                  variant="content-small"
                  text={t('snapshot:mainContact')}
                  testid="contact-main-id"
                />
              )}
            </IonRow>
            <SnapshotLink
              className={classes.snapShotLink}
              eyebrow={snapShotEyebrow}
              href={snapShotHref}
              icon={findIcon('chevron-right')}
              text={contactHeaderName}
              disabled={!ac}
              testid="customer-snapshot-link-button"
              customerPick12={customerPick12}
            />
            {callPhone && (
              <PhoneCallRow
                phoneNumber={callPhone}
                className={classes.rowWrapper}
                testid="contact-action-contact-phone-call"
              />
            )}
            {callPhone2 && (
              <PhoneCallRow
                phoneNumber={callPhone2}
                className={classes.rowWrapper}
                testid="contact-action-contact-phone2-call"
              />
            )}
            {textPhone && (
              <PhoneSMSRow
                phoneNumber={textPhone}
                className={classes.rowWrapper}
                testid="contact-action-contact-phone-sms"
              />
            )}
            {email && (
              <>
                <EmailRow
                  emailAddress={email}
                  className={classes.rowWrapper}
                  testid="contact-action-contact-email-button"
                  onClick={() => setSendEmailModal(true)}
                />
                <SendEmailModal
                  searchType={searchType}
                  id={id}
                  miLoc={miLoc}
                  defaultRecipients={[{ name: text, email }]}
                  isOpen={sendEmailModal}
                  setIsOpen={setSendEmailModal}
                  title={t('common:sendEmail')}
                  testid="contact-send-email-modal"
                  canRemoveRecipients={false}
                />
              </>
            )}
            <ActionRow
              className={classes.rowWrapper}
              leftButton={{
                variant: 'link',
                icon: findIcon('pen'),
                text: t('editContact'),
                testid: `contact-action-contact-edit-button`,
              }}
              onClick={() => {
                setIsOpenEditModal(true);
                setContactModalData({
                  miLoc,
                  inviteMiCom,
                  data: {
                    sequenceNo: toNumber(seqNo),
                    name: text,
                    type: customerType || supplierType,
                    phone: customerPhone || phone1 || '',
                    email,
                    isMainContact: isMainContact === 'Y',
                  },
                  showInvite: showInvite(
                    searchType,
                    !!isWebRegistered,
                    registrationStatus
                  ),
                  title: t('editContact'),
                });
              }}
              testid="contact-action-contact-invite"
            />
            <ActionRow
              className={classes.rowWrapper}
              leftButton={{
                variant: 'link',
                icon: findIcon('trash'),
                text: t('deleteContact'),
                testid: `contact-action-contact-delete-button`,
              }}
              onClick={() => setIsOpenRemoveModal(true)}
              testid="contact-action-contact-invite"
            />
            <ConfirmDialog
              isOpen={isOpenRemoveModal}
              setIsOpen={setIsOpenRemoveModal}
              title={t('deleteContact')}
              text={t('deleteContactTitle', { name: toString(text) })}
              primaryText={t('deleteContactNo')}
              secondaryText={t('deleteContactYes')}
              onSecondaryClick={onRemoveContact}
              testid="contact-action-remove-modal"
            />
            <ConfirmDialog
              isOpen={isOpenMakeMainModal}
              setIsOpen={setIsOpenMakeMainModal}
              title={t('makeMainContact')}
              text={t('mainContactConfirm', { text })}
              primaryText={t('makeContactConfirmNo')}
              secondaryText={t('makeContactConfirmYes')}
              onSecondaryClick={onMakeMainContact}
              testid="contact-action-make-main-modal"
            />
            <ContactModalV2
              searchType={searchType}
              isOpen={isOpenEditModal}
              setIsOpen={setIsOpenEditModal}
              testid="contact-action-edit-modal"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...contactModalData}
            />
            {showInviteButton && (
              <ActionRow
                className={classes.rowWrapper}
                leftButton={{
                  variant: 'link',
                  icon: findIcon('envelope-open-text'),
                  text: t('inviteMotion'),
                  testid: `contact-action-contact-invite-button`,
                }}
                onClick={() => inviteContact({ name: text })}
                testid="contact-action-contact-invite"
                disabled={!inviteMiCom}
              >
                {!inviteMiCom && (
                  <Text
                    className={classes.contactInfo}
                    variant="content-small"
                    text={t('invitePunchoutMessage')}
                    testid="contact-id"
                  />
                )}
              </ActionRow>
            )}
            {showResendButton && (
              <SnapshotLink
                className={classes.snapShotLink}
                eyebrow=""
                onClick={() => inviteContact({ name: text, resend: true })}
                leftIcon={findIcon('sync')}
                text={t('resendMotion')}
                disabled={!ac}
                tailRow={
                  <Text
                    variant="label-micro"
                    className={classes.text}
                    text={t('inviteSentMessage', {
                      formatDate: formatDate(
                        registrationCreationDate,
                        DateFormatEnum.fullDate,
                        i18next.language
                      ),
                    })}
                  />
                }
                testid="resend-invite-button"
              />
            )}
          </IonGrid>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Contact;
