import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { isPlatform } from '@ionic/react';

interface HapticFeedbackType {
  hapticsImpactLight: () => Promise<void>;
  hapticsImpactMedium: () => Promise<void>;
}

const useHapticFeedback = (): HapticFeedbackType => {
  const hapticsImpactMedium = async () => {
    if (isPlatform('mobile')) {
      await Haptics.impact({ style: ImpactStyle.Medium });
    }
  };

  const hapticsImpactLight = async () => {
    if (isPlatform('mobile')) {
      await Haptics.impact({ style: ImpactStyle.Light });
    }
  };

  return { hapticsImpactLight, hapticsImpactMedium };
};

export default useHapticFeedback;
