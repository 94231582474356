import type { AxiosError } from 'axios';
import { isNil, map, trim } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { doPromiseAPI, useKeyUserId } from 'api/helpers';
import type { DataCode } from 'models/DataCode';
import type { QueryFlags } from 'models/Search';

export const findDataCodesQueryKey = 'data-code';

interface UseFindDataCodesProps {
  codeType: string;
  enabled?: boolean;
}

interface UseFindDataCodesResponse {
  data?: DataCode[];
}

const useFindDataCodes = ({
  codeType,
  enabled = true,
}: UseFindDataCodesProps): UseFindDataCodesResponse & QueryFlags => {
  const { axios } = useAxios();
  const { findDataCodesAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const doFindDataCodes = () => {
    return doPromiseAPI<DataCode[]>(async (cancelToken) => {
      const { data } = await axios.get<DataCode[]>(findDataCodesAPI(codeType), {
        cancelToken,
      });
      return map(data, ({ id, codeName, ...rest }) => ({
        id: trim(id),
        codeName: trim(codeName),
        ...rest,
      }));
    });
  };

  const { data, error, status, isFetching } = useQuery<DataCode[], AxiosError>(
    createQueryKey(findDataCodesQueryKey, { codeType }),
    doFindDataCodes,
    { enabled }
  );

  return {
    data,
    error,
    isLoading: status === 'loading' || (isFetching && isNil(data)),
  };
};

export default useFindDataCodes;
