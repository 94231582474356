import React from 'react';
import classNames from 'classnames';
import { toString } from 'lodash';
import { IonCol, IonRow } from '@ionic/react';
import type { LeaderboardItem } from 'models/LeaderBoard';
import AvatarCard from 'components/Avatar/AvatarCard';
import CurrencyFormat from 'components/CurrencyFormat/CurrencyFormat';
import Text from 'components/Text/Text';
import classes from './LeaderboardTableRow.module.scss';

interface LeaderboardTableRowProps {
  testid: string;
  item: LeaderboardItem;
  rank: number;
  showLeaderBoardDetailCard: (userId: string) => void;
  isPersonalRank: boolean;
}

const LeaderboardTableRow = ({
  testid,
  item,
  rank,
  showLeaderBoardDetailCard,
  isPersonalRank,
}: LeaderboardTableRowProps): JSX.Element => {
  const name = `${item.firstName} ${item.lastName}`;
  return (
    <IonRow
      key={item.rank}
      className={
        isPersonalRank ? classes.leaderItemRowPersonal : classes.leaderItemRow
      }
      onClick={() => showLeaderBoardDetailCard(item?.employeeId)}
      data-testid={testid}
    >
      <IonCol size="2" />
      <IonCol size="4" className={classes.leaderItemCol}>
        {/* Uncomment this when there is an eligible criteria */}
        {/* {item.eligible ? (
          <FontAwesomeIcon
            className={classes.pick12Icon}
            icon={findIcon('truck-pickup', 'far')}
          />
        ) : (
          <span className={classes.pick12Icon}>&nbsp;&nbsp;&nbsp;&nbsp;</span>
        )} */}
      </IonCol>
      <IonCol size="10" className={classes.leaderItemCol}>
        <Text
          className={classes.leaderItemRank}
          variant="content-small"
          text={toString(rank)}
        />
      </IonCol>
      <IonCol
        size="10"
        className={classNames(classes.leaderItemCol, classes.avatarCol)}
      >
        <AvatarCard
          displayName={name}
          className={classes.avatar}
          backgroundColor={classes.avatarFallback}
        />
      </IonCol>
      <IonCol
        size="35"
        className={classNames(classes.leaderItemCol, classes.avatarCol)}
      >
        <div className={classes.leaderboardItemNameCol}>
          <Text
            className={classes.leaderItemName}
            variant="label-header-micro"
            text={name}
          />
          <Text
            className={classes.leaderItemLoc}
            variant="label-header-micro"
            text={item.miLoc}
          />
        </div>
      </IonCol>
      <IonCol size="23" className={classes.leaderItemCol}>
        <CurrencyFormat
          className={classes.leaderItemSalesGP}
          value={item.sales}
          variant="label-header-micro"
          testid={`${item.rank}-sales-leader-item`}
          currencyType="USD"
          abbreviated
        />
      </IonCol>
      <IonCol size="16" className={classes.leaderItemCol}>
        <Text
          className={classes.leaderItemSalesGP}
          variant="label-header-micro"
          text={`${item.gpPercent}%`}
          testid={`leader-item-gp-percent-${item.rank}`}
        />
      </IonCol>
    </IonRow>
  );
};

export default LeaderboardTableRow;
