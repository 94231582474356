import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { toString } from 'lodash';
import { Browser } from '@capacitor/browser';
import * as Sentry from '@sentry/capacitor';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import { doPromiseAPI } from 'api/helpers';
import { ToastType } from 'models/Toast';
import type { RootState } from 'store/reducers';

const MICOM_URL = process.env.REACT_APP_MICOM_URL || '';
const MICA_URL = process.env.REACT_APP_MICA_URL || '';

interface RedirectToken {
  token: string;
}

interface UseMiConnectionProps {
  miLoc: string;
  id: string;
  ocn?: string;
  itemNumber?: string;
}

interface UseMiConnectionResponse {
  getMiComProductURL: () => string;
  openMiComOCNLink: () => Promise<void>;
  openMiComQuoteLink: () => Promise<void>;
  openMiComOrdersLink: () => Promise<void>;
  openMiComQuotesLink: () => Promise<void>;
}

const useMiComConnection = ({
  miLoc = '',
  id = '',
  ocn = '',
  itemNumber = '',
}: UseMiConnectionProps): UseMiConnectionResponse => {
  const { axios } = useAxios();
  const { redirectTokenAPI } = useAPIUrl();
  const { addToast } = useToasts();
  const { corpLoc } = useSelector((state: RootState) => state.user);

  const localMIURL = useMemo(() => {
    return corpLoc === 'CN00' ? MICA_URL : MICOM_URL;
  }, [corpLoc]);

  // DOC: tokens are single use, so we can't use caching from react-query
  const doGetMiComToken = () => {
    try {
      return doPromiseAPI<string>(async (cancelToken) => {
        const { data } = await axios.get<RedirectToken>(
          redirectTokenAPI(miLoc, id, ocn),
          { cancelToken }
        );
        return toString(data.token);
      });
    } catch (e) {
      Sentry.captureException(e);
      return Promise.resolve('');
    }
  };

  const doGetRedirectURL = async (from: string): Promise<string> => {
    const micomToken = await doGetMiComToken();
    const params = new URLSearchParams();
    if (localMIURL === MICA_URL) {
      params.append('fromSisterSite', 'true');
    }
    params.append('from', from);
    return `${localMIURL}/login-redirect/${micomToken}?${params.toString()}`;
  };

  const getMiComProductURL = () => {
    return `${localMIURL}/products/sku/${toString(itemNumber)}`;
  };

  const handleMiComError = (error: string, message: string) => {
    addToast({
      type: ToastType.error,
      text: message,
      testid: 'micom-error-toast',
    });
    Sentry.captureException('MiCom error', {
      extra: {
        error: new Error(error),
        message,
      },
    });
  };

  const openMiComOCNLink = async () => {
    try {
      await Browser.open({
        url: await doGetRedirectURL(`/dashboard/orders/search/B/${ocn}`),
      });
    } catch (error) {
      handleMiComError(
        (error as Error).message,
        `Error opening MiCom OCN link.`
      );
    }
  };

  const openMiComQuoteLink = async () => {
    try {
      await Browser.open({
        url: await doGetRedirectURL(`/dashboard/quotes/search/B/${ocn}`),
      });
    } catch (error) {
      handleMiComError(
        (error as Error).message,
        `Error opening MiCom OCN link.`
      );
    }
  };

  const openMiComOrdersLink = async () => {
    try {
      await Browser.open({ url: await doGetRedirectURL(`/dashboard/orders`) });
    } catch (error) {
      handleMiComError(
        (error as Error).message,
        `Error opening MiCom orders link.`
      );
    }
  };

  const openMiComQuotesLink = async () => {
    try {
      await Browser.open({ url: await doGetRedirectURL(`/dashboard/quotes`) });
    } catch (error) {
      handleMiComError(
        (error as Error).message,
        'Error opening MiCom quotes link.'
      );
    }
  };

  return {
    getMiComProductURL,
    openMiComOCNLink,
    openMiComQuoteLink,
    openMiComOrdersLink,
    openMiComQuotesLink,
  };
};

export default useMiComConnection;
