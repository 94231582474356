import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import {
  filter,
  isNil,
  map,
  size,
  escapeRegExp,
  startCase,
  head,
  toString,
} from 'lodash';
import type { ItemReorderEventDetail } from '@ionic/core/components';
import { IonReorderGroup, IonRow } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import CorpAccIcon from 'common/components/CorpAccIcon/CorpAccIcon';
import { ifFunction } from 'common/utils/logicHelpers';
import { searchURL } from 'navigation';
import useAccessControls, { AccessControlType } from 'hooks/useAccessControls';
import type { FavoriteOperation } from 'models/Favorites';
import type { SearchCustomerItem, SearchItem } from 'models/Search';
import { colorHash, withStringProp } from 'utils/helpers';
import { findIcon } from 'utils/icons';
import { concatRoutes } from 'utils/navigations';
import type { SnapshotRoutingState } from 'utils/search';
import { getSearchResultData, handleSearchNavigation } from 'utils/search';
import ActionRow from 'components/ActionRow/ActionRow';
import Pick12Icon from 'components/Pick12Icon/Pick12Icon';
import Text from 'components/Text/Text';
import classes from './FavoritesSection.module.scss';

interface FavoritesSectionProps {
  title: string;
  emptyMessage?: string;
  searchQuery?: string;
  items: SearchItem[];
  isEditable?: boolean;
  onRemove?: ({ miLoc, id }: FavoriteOperation) => void;
  onReorder?: ({ miLoc, id, from, to }: FavoriteOperation) => void;
  testid: string;
  favMiLoc?: string;
}

const FavoritesSection = ({
  className,
  title,
  emptyMessage = '',
  searchQuery = '',
  items,
  isEditable,
  onRemove,
  onReorder,
  testid,
  favMiLoc,
}: FavoritesSectionProps & IonicReactProps): JSX.Element | null => {
  const history = useHistory();
  const { t } = useTranslation();
  const ac = useAccessControls(AccessControlType.viewCustomers);
  const withEmptyMessage = withStringProp(emptyMessage);
  const withSearchQuery = withStringProp(searchQuery);
  const filteredItems = useMemo(
    () =>
      filter(
        items,
        ({ text }) =>
          !withSearchQuery ||
          !isNil(new RegExp(escapeRegExp(searchQuery), 'i').exec(text))
      ),
    [items, searchQuery, withSearchQuery]
  );

  return size(items) > 0 || withEmptyMessage ? (
    <div
      className={classNames(className, classes.section)}
      data-testid={testid}
    >
      <Text
        className={classes.title}
        variant="title-info-card"
        text={title}
        testid="favorites-section-title"
      />
      <IonReorderGroup
        disabled={!isEditable || withSearchQuery}
        onIonItemReorder={(e: CustomEvent<ItemReorderEventDetail>) => {
          const { from, to } = e.detail;
          const { type, id } = filteredItems[from];
          const { miLoc } = filteredItems[from] as SearchCustomerItem;
          onReorder?.call(null, { type, miLoc, id, from, to });
          e.detail.complete(false);
        }}
        data-testid="favorites-section-reorder"
      >
        {map(filteredItems, (item, index) => {
          const {
            type,
            miLoc,
            id,
            key,
            text,
            description,
            customerPick12,
            isCorpAccount,
          } = getSearchResultData(item, index, t);
          const searchIdParam =
            type === 'customer' ? { customerId: id } : { supplierId: id };

          return (
            <ActionRow
              className={classes.item}
              key={key}
              onClick={() =>
                ifFunction(!isEditable, () =>
                  history.push({
                    pathname: concatRoutes(
                      searchURL(),
                      handleSearchNavigation({
                        type,
                        miLoc: miLoc || favMiLoc || '',
                        ...searchIdParam,
                      })
                    ),
                    state: {
                      headerTitle: text,
                    } as SnapshotRoutingState,
                  })
                )
              }
              leftButton={
                isEditable
                  ? {
                      className: classes.removeButton,
                      variant: 'clear',
                      icon: findIcon('minus-circle', 'fas'),
                      onClick: () => onRemove?.call(null, { type, miLoc, id }),
                      testid: `favorite-${key}-remove-button`,
                    }
                  : undefined
              }
              testid={`favorite-${key}`}
              disabled={isEditable ? false : !ac && type === 'customer'}
            >
              <IonRow className={classes.container}>
                <div
                  className={classes.avatar}
                  style={{
                    backgroundColor: colorHash.hex(startCase(text)),
                  }}
                >
                  <Text
                    className={classes.avatarTitle}
                    text={toString(head(text))}
                    variant="title-screen-section"
                  />
                </div>
                <div className={classes.wrapper}>
                  <CorpAccIcon
                    showIcon={isCorpAccount}
                    testid={testid}
                    className={classes.corpIcon}
                  />

                  {customerPick12 && (
                    <Pick12Icon
                      testid={`${testid}-favorite-section`}
                      className={classes.pick12Icon}
                    />
                  )}
                  <Text
                    className={classes.title}
                    text={text}
                    textQuery={searchQuery}
                    testid={`favorite-${key}-text`}
                  />
                  <Text
                    className={classes.tag}
                    variant="content-small"
                    text={description}
                    testid={`favorite-${key}-tag`}
                  />
                </div>
              </IonRow>
              {/* <IonReorder slot="end">
                <Button
                  variant="clear"
                  icon={findIcon('grip-lines')}
                  testid={`favorite-${key}-reorder-button`}
                />
              </IonReorder> */}
            </ActionRow>
          );
        })}
      </IonReorderGroup>
      {withSearchQuery && size(filteredItems) === 0 && size(items) > 0 && (
        <Text variant="content-small" text="No results for your search" />
      )}
      {withEmptyMessage && size(items) === 0 && (
        <Text
          variant="content-small"
          text={emptyMessage}
          data-testid="empty-favorites"
        />
      )}
    </div>
  ) : null;
};

export default FavoritesSection;
