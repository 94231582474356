import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import type { AxiosError } from 'axios';
import { find, get, isEmpty, map, toString } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { getUnixTime, startOfToday } from 'date-fns';
import { useAxios } from 'providers/AxiosProvider';
import { doPromiseAPI, useKeyUserId, useMiLocOrTeamId } from 'api/helpers';
import type { PickTwelve } from 'models/PickTwelve';
import type { DateSegmentType, GetReportSummaryResponse } from 'models/Reports';
import type { QueryFlags } from 'models/Search';
import type { RootState } from 'store/reducers';
import { DateFormatEnum, formatDate } from 'utils/date';

export const findPickTwelveQueryKey = 'pick-twelve';

interface UseFindPickTwelveProps {
  busPeriod?: number;
  requestType?: DateSegmentType;
  customerNo: string;
}

interface UseFindPickTwelveResponse {
  data?: PickTwelve[];
}

const useFindPickTwelve = ({
  busPeriod = getUnixTime(startOfToday()),
  requestType = 'MTD',
  customerNo,
}: UseFindPickTwelveProps): UseFindPickTwelveResponse & QueryFlags => {
  const { axios } = useAxios();
  const { getPickTwelveAPI, getReportSummaryAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const { createParams } = useMiLocOrTeamId({});
  const { userInfo } = useSelector((state: RootState) => state.user);
  const employeeId = get(userInfo, 'employeenumber', '');
  const params = {
    ...createParams(),
    busPeriod: formatDate(busPeriod, DateFormatEnum.reportsDateAPI),
    requestType,
    customerNo,
  };

  const doGetPickTwelve = () => {
    return doPromiseAPI<PickTwelve[]>(async (cancelToken) => {
      const { data } = await axios.get<PickTwelve[]>(
        getPickTwelveAPI(employeeId),
        { cancelToken }
      );
      return data;
    });
  };

  const { data: pickTwelveData, status: pickTwelveStatus } = useQuery<
    PickTwelve[],
    AxiosError
  >(createQueryKey(findPickTwelveQueryKey, { employeeId }), doGetPickTwelve, {
    enabled: !isEmpty(employeeId),
  });

  const doGetReportSummary = () => {
    return doPromiseAPI<GetReportSummaryResponse>(async (cancelToken) => {
      const { data } = await axios.get<GetReportSummaryResponse>(
        getReportSummaryAPI(toString(new URLSearchParams(params))),
        {
          cancelToken,
        }
      );
      return data;
    });
  };

  const {
    data: summaryData,
    error,
    status,
    isFetching,
  } = useQuery<GetReportSummaryResponse, AxiosError>(
    createQueryKey(`${findPickTwelveQueryKey}-summary`, {
      employeeId,
      ...params,
    }),
    doGetReportSummary,
    {
      enabled: !isEmpty(employeeId),
      // TODO: refactor and extract this retry logic to be resuable across the application
      retry: (failureCount, err) =>
        err.response?.status === 403 ? false : !(failureCount < 3),
    }
  );

  const result = useMemo(
    () =>
      map(pickTwelveData, (item) => {
        const salesData = find(summaryData?.sales, { Name: 'Sales' });
        return { ...item, sales: salesData?.amount };
      }),
    [pickTwelveData, summaryData]
  );

  const hasError = status === 'error';

  return {
    data: result,
    error,
    hasError,
    isLoading:
      pickTwelveStatus === 'loading' || status === 'loading' || isFetching,
  };
};

export default useFindPickTwelve;
