import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { map } from 'lodash';
import { IonCol, IonRow } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { useToasts } from 'providers/ToastProvider';
import useSendReportEmail from 'api/inspectionReports/useSendReportEmail';
import { ToastType } from 'models/Toast';
import { findIcon } from 'utils/icons';
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import Modal from 'components/Modal/Modal';
import ConfirmDialog from 'components/Modals/ConfirmDialog/ConfirmDialog';
import Text from 'components/Text/Text';
import TextArea from 'components/TextArea/TextArea';
import classes from './DocumentEmailModal.module.scss';

interface DocumentEmailModalProps {
  reportId: number;
  miLoc: string;
  shopLoc: string;
  subject?: string;
  body?: string;
}

const DocumentEmailModal = ({
  isOpen,
  setIsOpen,
  onClose,
  title,
  reportId,
  testid,
  miLoc,
  shopLoc,
  subject = '',
  body = '',
}: DocumentEmailModalProps &
  React.ComponentProps<typeof Modal> &
  IonicReactProps): JSX.Element => {
  const initialFormData = useMemo(
    () => ({ subject, message: body }),
    [body, subject]
  );
  const [formData, setFormData] = useState(initialFormData);
  const initialFormError = useMemo(() => ({ subject: '', message: '' }), []);
  const [formError, setFormError] = useState(initialFormError);
  const [discardConfirmDialogIsOpen, setDiscardConfirmDialogIsOpen] =
    useState(false);
  const [recipients, setRecipients] = useState<string[]>(['']);
  const [isEmailInputValid, setIsEmailInputValid] = useState<boolean>(false);
  // DOC: trigger children change on Modal so that it can be resized
  const [triggerModalResize, setTriggerModalResize] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (key: string) => (v: string) =>
    setFormData((prev) => ({ ...prev, [key]: v }));

  const setError = (key: string, v: string) =>
    setFormError((prev) => ({ ...prev, [key]: v }));

  useEffect(() => {
    setTriggerModalResize(Date.now());
    if (recipients && recipients.length > 0) {
      // emailValidation.test returns a boolean of true or false
      // if the string passed matches the regular expression
      let allEmailsValid = true;
      map(recipients, (recipient: string) => {
        if (
          // TODO: use yup to validate email
          !new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$').test(
            recipient.trim().toLowerCase()
          )
        ) {
          allEmailsValid = false;
        }
      });
      setIsEmailInputValid(allEmailsValid);
      return;
    }
    setIsEmailInputValid(false);
  }, [recipients]);

  const { status, onSendEmail } = useSendReportEmail();
  const { addToast } = useToasts();

  const handleSendEmail = () => {
    if (!isEmailInputValid) {
      addToast({
        type: ToastType.error,
        text: 'Invalid Email Input!',
        testid: 'invalid-email-input-error-toast',
      });
      return;
    }
    let doSend = true;
    setError('subject', '');
    setError('message', '');
    if (!formData.subject) {
      setError('subject', 'Subject is required');
      doSend = false;
    }
    if (!formData.message) {
      setError('message', 'Message is required');
      doSend = false;
    }
    if (!doSend) {
      return;
    }
    setIsSubmitting(true);
    onSendEmail({
      miLoc,
      shopLoc,
      reportId,
      recipients,
      subject: formData.subject,
      body: formData.message,
    });
  };

  useEffect(() => {
    if (isOpen) {
      setFormData(initialFormData);
      setRecipients(['']);
      setIsSubmitting(false);
    }
  }, [initialFormData, isOpen]);

  useEffect(() => {
    if (status === 'success') {
      setIsOpen?.(false);
      setFormData(initialFormData);
      setRecipients(['']);
      setIsSubmitting(false);
    }
  }, [initialFormData, setIsOpen, status]);

  return (
    <Modal
      className={classes.modal}
      headerClassName={classes.header}
      isOpen={isOpen}
      withTitleLine={false}
      title={title}
      setIsOpen={setIsOpen}
      triggerModalResize={triggerModalResize}
      onClose={onClose}
      forceFullHeight
      footer={
        <IonRow className={classes.buttonsWrapper}>
          <Button
            variant="action"
            text="Send"
            onClick={() => {
              handleSendEmail();
            }}
            disabled={isSubmitting}
            testid="send-button"
          />
          <Button
            variant="secondary"
            text="Cancel"
            onClick={() => setDiscardConfirmDialogIsOpen(true)}
            disabled={isSubmitting}
            testid="cancel-button"
          />
          <ConfirmDialog
            isOpen={discardConfirmDialogIsOpen}
            setIsOpen={setDiscardConfirmDialogIsOpen}
            title="Discard changes?"
            text="Changes you made will not be saved."
            primaryText="Go Back to Send Email"
            secondaryText="Discard Changes"
            onPrimaryClick={() => setDiscardConfirmDialogIsOpen(false)}
            onSecondaryClick={() => {
              setIsOpen?.(false);
              onClose?.call(null);
            }}
            testid="discard-changes-modal"
          />
        </IonRow>
      }
      testid={testid}
    >
      <div className={classNames(classes.content)}>
        <Text
          className={classes.recipientsTitle}
          variant="content-heavy"
          text="Recipients"
        />
        <IonRow className={classes.recipientsWrapper}>
          {map(recipients, (recipient, index) => (
            <IonCol key={index} size="12">
              <Input
                className={classes.recipientsInput}
                value={recipients[index]}
                testid="containerInput"
                setValue={(v: string) => {
                  const newRecipients = [...recipients];
                  newRecipients[index] = v;
                  setRecipients(newRecipients);
                }}
                disabled={isSubmitting}
              />
              <Button
                className={classes.deleteRecipientButton}
                variant="link"
                icon={findIcon('times-circle')}
                onClick={() => {
                  const newRecipients = [...recipients];
                  newRecipients.splice(index, 1);
                  setRecipients(newRecipients);
                }}
                testid="delete-recipient-button"
                disabled={isSubmitting}
              />
            </IonCol>
          ))}
          <IonCol size="12">
            <Button
              className={classes.addRecipientButton}
              variant="action"
              text="Add Recipient"
              icon={findIcon('plus-circle')}
              onClick={() => {
                const newRecipients = [...recipients];
                newRecipients.push('');
                setRecipients(newRecipients);
              }}
              disabled={isSubmitting}
              testid="add-recipient-button"
            />
          </IonCol>
        </IonRow>
        <Input
          className={classes.subjectInput}
          label="Subject"
          name="subject"
          value={formData.subject}
          error={formError.subject}
          setValue={handleChange('subject')}
          disabled={isSubmitting}
          testid={`${testid}-subject-input`}
          autocapitalize="sentences"
          spellcheck
        />
        <TextArea
          className={classes.messageInput}
          label="Message"
          name="message"
          value={formData.message}
          error={formError.message}
          setValue={handleChange('message')}
          disabled={isSubmitting}
          testid={`${testid}-message-input`}
        />
      </div>
    </Modal>
  );
};

export default DocumentEmailModal;
