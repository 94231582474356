import React from 'react';
import classNames from 'classnames';
import { map, toString } from 'lodash';
import { IonButtons, IonFooter, IonToolbar } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import type { OptionalComponentProps } from 'common/components/utils/renderHelpers';
import Button from 'components/Button/Button';
import classes from './Footer.module.scss';

interface FooterProps {
  buttons?: OptionalComponentProps<React.ComponentProps<typeof Button>[]>;
  children?: React.ReactNode;
}

const Footer = ({
  buttons,
  children,
  className,
}: FooterProps & IonicReactProps) => {
  return (
    <IonFooter
      data-testid="footer"
      className={classNames(classes.footer, className)}
    >
      <IonToolbar data-testid="footer--toolbar">
        <IonButtons data-testid="footer--buttons">
          {map(buttons, (buttonProps, index) => {
            const testid = toString(buttonProps?.testid);
            return (
              <Button
                testid={testid}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...buttonProps}
                key={`${testid}--${index}`}
              />
            );
          })}
        </IonButtons>
        {children}
      </IonToolbar>
    </IonFooter>
  );
};

export default Footer;
