import React from 'react';
import AppIcons from 'constants/appIcons';
import classNames from 'classnames';
import { toString } from 'lodash';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { formatPhone } from 'utils/address';
import { formatPhoneCountryCode } from 'utils/number';
import ActionRow from 'components/ActionRow/ActionRow';
import LongPressCopyGesture from 'components/LongPressCopyGesture/LongPressCopyGesture';
import classes from './PhoneNumber.module.scss';

interface PhoneNumberProps {
  phoneNumber: string;
  testid: string;
}

const PhoneNumber = ({
  className,
  phoneNumber,
  testid,
}: PhoneNumberProps & IonicReactProps): JSX.Element => (
  <LongPressCopyGesture copyableValue={phoneNumber}>
    <ActionRow
      className={classNames(classes.phoneNumber, className)}
      leftButton={{
        variant: 'link',
        icon: AppIcons.phoneIcon(),
        text: formatPhone(phoneNumber),
        testid: `${testid}-button`,
      }}
      href={`tel:${phoneNumber}`}
      withArrow={false}
      isExternalLink
      testid={testid}
    />
  </LongPressCopyGesture>
);

export default PhoneNumber;

type PhoneCallRowProps = PhoneNumberProps & {
  icon?: IconProp;
  href?: string;
  iconClassName?: string;
  phoneCountryCode?: string;
};

export const PhoneCallRow = ({
  className,
  phoneNumber,
  iconClassName,
  icon = AppIcons.phoneIcon(),
  phoneCountryCode,
  href = formatPhoneCountryCode('tel:', phoneNumber, phoneCountryCode),
  onClick,
  testid,
}: PhoneCallRowProps &
  React.ComponentProps<typeof ActionRow> &
  IonicReactProps): JSX.Element => (
  <LongPressCopyGesture
    copyableValue={phoneNumber}
    styles={[classNames(classes.actionRow, className)]}
  >
    <ActionRow
      leftButton={{
        variant: 'link',
        icon,
        iconClassName,
        text: formatPhone(phoneNumber),
        testid: `${testid}-button`,
      }}
      href={href}
      onClick={onClick}
      withArrow={false}
      isExternalLink
      testid={testid}
    />
  </LongPressCopyGesture>
);

export const PhoneSMSRow = ({
  phoneNumber,
  className,
  phoneCountryCode,
  ...props
}: React.ComponentProps<typeof PhoneCallRow> &
  IonicReactProps): JSX.Element => (
  <LongPressCopyGesture
    copyableValue={phoneNumber}
    styles={[toString(className)]}
  >
    <PhoneCallRow
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...{ phoneNumber, ...props }}
      icon={AppIcons.smsIcon()}
      href={formatPhoneCountryCode('sms:', phoneNumber, phoneCountryCode)}
      phoneCountryCode={phoneCountryCode}
    />
  </LongPressCopyGesture>
);

export const PhoneMobileRow = ({
  phoneNumber,
  iconClassName,
  className,
  phoneCountryCode,
  ...props
}: React.ComponentProps<typeof PhoneCallRow> &
  IonicReactProps): JSX.Element => (
  <LongPressCopyGesture
    copyableValue={phoneNumber}
    styles={[toString(className)]}
  >
    <PhoneCallRow
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...{ phoneNumber, iconClassName, ...props }}
      icon={AppIcons.mobileIcon()}
      href={formatPhoneCountryCode('tel:', phoneNumber, phoneCountryCode)}
      phoneCountryCode={phoneCountryCode}
    />
  </LongPressCopyGesture>
);
