import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { isEmpty, isNil, toString } from 'lodash';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { and, choose, ifFunction } from 'common/utils/logicHelpers';
import { useGetUserInfo } from 'common/utils/userInfo';
import { emptyString } from 'common/utils/valueFormatter';
import { namespaces } from 'i18n/i18n.constants';
import { searchCustomerURL, searchURL } from 'navigation';
import useGetAgreementInformation from 'api/customer/useGetAgreementInformation';
import useAccessControls, { AccessControlType } from 'hooks/useAccessControls';
import type { Customer } from 'models/Customer';
import type { SearchItemType } from 'models/Search';
import type { Supplier } from 'models/Supplier';
import { concatRoutes } from 'utils/navigations';
import { getSnapshotProp, handleSearchNavigation } from 'utils/search';
import Button from 'components/Button/Button';
import Address from 'components/Contacts/Address/Address';
import ContactModal from 'components/Contacts/ContactModal/ContactModal';
import PhoneNumber from 'components/Contacts/PhoneNumber/PhoneNumber';
import CostSavingsCustomerCard from 'components/CostSavingsCustomerCard/CostSavingsCustomerCard';
import Email from 'components/Email/Email';
import IndustryCodes from 'components/IndustryCodes/IndustryCodes';
import SendEmailModal from 'components/Modals/SendEmailModal/SendEmailModal';
import Text from 'components/Text/Text';
import classes from './Snapshots.module.scss';

interface SnapshotDetailsProps {
  searchType: SearchItemType;
  miLoc: string;
  id: string;
  data?: Customer | Supplier;
  isCorpAccount?: boolean;
}

const SnapshotDetails = ({
  searchType,
  miLoc,
  id,
  data,
  isCorpAccount = false,
}: SnapshotDetailsProps): JSX.Element => {
  const history = useHistory();
  const { t } = useTranslation(namespaces.snapshot);
  const [sendEmailModal, setSendEmailModal] = useState(false);
  const [showMainContactModal, setShowMainContactModal] = useState(false);
  const [showMotionRepContactModal, setShowMotionRepContactModal] =
    useState(false);

  const getKeyValue = (customerKey: string, supplierKey: string) =>
    getSnapshotProp(searchType, customerKey, supplierKey, data);
  const isCustomer = searchType === 'customer';
  const isSupplier = searchType === 'supplier';
  const name = getKeyValue('name', 'supLocName');
  const customerData = data as Customer;
  const mainContact = isCustomer
    ? customerData?.mainContact
    : (data as Supplier)?.contact;
  const mainContactName = getKeyValue('mainContact.name', 'contact.name');
  const phoneNumber = getKeyValue('mainContact.phone', 'contact.phone1');
  const email = getKeyValue('mainContact.email', 'contact.email');

  const hasMainContact = mainContactName || phoneNumber || email;
  const motionRep = customerData?.motionRep;
  const corporateAccountManager = customerData?.corporateAccountManager;
  const corpAcctInfo = customerData?.corpAcctInfo;
  const cxManager = customerData?.complianceManager;
  const costSavingsAC = useAccessControls(
    AccessControlType.CostSavingsAccessControls
  );

  const { agreementInformation } = useGetAgreementInformation({
    miLoc,
    id,
    enabled: !isSupplier,
  });

  const guaranteed = agreementInformation?.costSavingsCommitment?.guaranteed;
  const { hasVirtualTeam, locationTree } = useGetUserInfo();
  const hasCorpLocAccess = and(
    !hasVirtualTeam,
    locationTree?.[toString(corpAcctInfo?.corpAcctLoc)]?.hasAccess
  );

  return (
    <IonGrid className={classes.detailsContainer}>
      <IonRow className={classes.rowWrapper}>
        <IonCol class={classes.detailsWrapper}>
          <Text
            text={isCustomer ? t('mainContact') : t('locationContact')}
            variant="label-header"
            className={classes.title}
          />
          {isCustomer && (
            <div className={classes.mainContactWrapper}>
              {hasMainContact ? (
                <Button
                  variant="link"
                  text={mainContactName}
                  onClick={() => setShowMainContactModal(true)}
                  testid="contact-modal-button"
                  className={classes.contactName}
                />
              ) : (
                <Text text={emptyString} />
              )}
            </div>
          )}
          {isSupplier && (
            <div className={classes.mainContactWrapper}>
              {mainContactName && (
                <Text
                  text={mainContactName}
                  className={classes.mainContentText}
                />
              )}
              {phoneNumber && (
                <PhoneNumber
                  phoneNumber={phoneNumber}
                  testid="call-button"
                  className={classes.mainContentNumber}
                />
              )}
              {email && (
                <Email
                  emailAddress={email}
                  className={classes.mainContentEmail}
                  testid="email-button"
                  onClick={() => setSendEmailModal(true)}
                />
              )}
              {!isNil(mainContact) && email && (
                <SendEmailModal
                  searchType={searchType}
                  miLoc={miLoc}
                  id={id}
                  defaultRecipients={[mainContact]}
                  isOpen={sendEmailModal}
                  setIsOpen={setSendEmailModal}
                  title={t('common:sendEmail')}
                  testid="send-email-modal"
                />
              )}
            </div>
          )}

          {isCustomer && isCorpAccount && (
            <div className={classes.cxManager}>
              <Text text={t('cxManager')} variant="label-header" />
              <div className={classes.mainContactWrapper}>
                {!isEmpty(cxManager) ? (
                  <Button
                    variant="link"
                    text={cxManager?.name}
                    href={concatRoutes(
                      searchURL(),
                      handleSearchNavigation({
                        type: 'employee',
                        miLoc,
                        employeeId: cxManager?.employeeId,
                      })
                    )}
                    testid="cx-button"
                    className={classes.contactName}
                  />
                ) : (
                  <Text text={emptyString} />
                )}
              </div>
            </div>
          )}
        </IonCol>
        {isCustomer && (
          <IonCol class={classes.detailsWrapper}>
            <Text
              text={!isCorpAccount ? t('accountRep') : t('accountManager')}
              variant="label-header"
              className={classes.title}
            />
            {isCustomer && !isCorpAccount && (
              <div className={classes.mainContactWrapper}>
                {motionRep ? (
                  <Button
                    variant="link"
                    text={motionRep?.name}
                    onClick={() => setShowMotionRepContactModal(true)}
                    testid="contact-modal-button"
                    className={classes.contactName}
                  />
                ) : (
                  <Text text={emptyString} />
                )}
              </div>
            )}
            {isCustomer && isCorpAccount && (
              <div className={classes.mainContactWrapper}>
                {corporateAccountManager ? (
                  <Button
                    variant="link"
                    text={corporateAccountManager?.name}
                    href={concatRoutes(
                      searchURL(),
                      handleSearchNavigation({
                        type: 'employee',
                        miLoc,
                        employeeId: corporateAccountManager?.employeeId,
                      })
                    )}
                    testid="employee-page-button"
                    className={classes.contactName}
                  />
                ) : (
                  <Text text={emptyString} />
                )}
              </div>
            )}
          </IonCol>
        )}
      </IonRow>
      {isCustomer && !isCorpAccount && !isNil(corpAcctInfo) && (
        <IonRow className={classes.rowWrapper}>
          <IonCol class={classes.detailsWrapper}>
            <Text
              text={t('corporateAccount')}
              variant="label-header"
              className={classes.title}
            />
            <div className={classes.mainContactWrapper}>
              <Button
                variant={choose(hasCorpLocAccess, 'link', 'clear')}
                text={corpAcctInfo?.corpAcctName}
                leftIcon={['fas', 'globe']}
                iconClassName={classes.corpIcon}
                onClick={() =>
                  ifFunction(hasCorpLocAccess, () =>
                    history.push({
                      pathname: concatRoutes(
                        searchURL(),
                        searchCustomerURL(
                          corpAcctInfo?.corpAcctLoc,
                          corpAcctInfo?.corpAcctNum
                        )
                      ),
                      state: {
                        isCorpAccount: true,
                        headerTitle: corpAcctInfo?.corpAcctName,
                      },
                    })
                  )
                }
                testid="corporate-account-link"
                className={classes.contactName}
              />
            </div>
            <div className={classes.mainContactWrapper}>
              <Text
                text={`${corpAcctInfo?.corpAcctLoc}${corpAcctInfo?.corpAcctNum}`}
                variant="mipro-h6-headline"
                className={classes.title}
              />
            </div>
          </IonCol>
        </IonRow>
      )}
      <IonRow class={classes.rowWrapper}>
        <IonCol class={classes.detailsWrapper}>
          <Text
            text={t('mainAddress')}
            variant="label-header"
            className={classes.title}
          />
          {data?.address && <Address address={data?.address} name={name} />}
        </IonCol>
      </IonRow>
      {costSavingsAC && guaranteed && (
        <IonRow class={classes.rowWrapper}>
          <IonCol class={classes.detailsWrapper}>
            <CostSavingsCustomerCard
              guaranteed={guaranteed}
              custMiLoc={miLoc}
              customerNo={id}
              isCorpAccount={isCorpAccount}
              natlAcctNo={customerData?.natlAcctNo}
            />
          </IonCol>
        </IonRow>
      )}
      {isCustomer && (
        <IonRow className={classes.section}>
          <IonCol>
            <IndustryCodes data={customerData} isCorpAccount={isCorpAccount} />
          </IonCol>
        </IonRow>
      )}
      <ContactModal
        isOpen={showMainContactModal}
        setIsOpen={setShowMainContactModal}
        customerData={{ ...data, mainContact: { ...mainContact } } as Customer}
        enableTexting
        title={mainContact?.name}
        testid="main-contact-modal"
        excludeSnapshotLink
      />
      <ContactModal
        isOpen={showMotionRepContactModal}
        setIsOpen={setShowMotionRepContactModal}
        customerData={{ ...data, mainContact: { ...motionRep } } as Customer}
        enableTexting
        title={motionRep?.name}
        testid="motion-rep-contact-modal"
        excludeSnapshotLink
      />
    </IonGrid>
  );
};

export default SnapshotDetails;
