import React, { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { concat, map, toLower, toNumber } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { IonItem } from '@ionic/react';
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonRow,
} from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import type * as H from 'history';
import { namespaces } from 'i18n/i18n.constants';
import { salesPlayDetailURL } from 'navigation';
import useHeight from 'hooks/useHeight';
import type { SalesPlay } from 'models/SalesPlays';
import { findIcon } from 'utils/icons';
import { concatRoutes } from 'utils/navigations';
import {
  convertSalesTypeForDisplay,
  getDescription,
  getFormattedValue,
  iconAndColorFromType,
} from 'utils/salesPlays';
import Pick12Icon from 'components/Pick12Icon/Pick12Icon';
import Text from 'components/Text/Text';
import SalesPlayAccepted from './SalesPlayAccepted';
import classes from './SalesPlaysCard.module.scss';

interface SalesPlaysCardProps {
  salesPlay: SalesPlay;
  testid: string;
  url?: string;
  history?: H.History<unknown>;
  setDisplaySize?: (_id: string, height: number) => void;
  isComplete?: boolean;
}

const SalesPlaysCard = ({
  salesPlay,
  className,
  testid,
  url,
  history,
  setDisplaySize,
  isComplete = false,
}: SalesPlaysCardProps &
  React.ComponentProps<typeof IonItem> &
  IonicReactProps): JSX.Element => {
  const { t } = useTranslation(namespaces.salesPlays);

  const handleSalesCardClick = () => {
    if (history && url) {
      const { externalSource, externalId } = salesPlay;
      history.push(
        concatRoutes(url || '', salesPlayDetailURL(externalSource, externalId))
      );
    }
  };

  const [ref, height] = useHeight();

  useLayoutEffect(() => {
    if (height) {
      setDisplaySize?.(salesPlay.externalId, height);
    }
  }, [height, salesPlay.externalId, setDisplaySize]);

  return (
    <IonCard
      className={classnames(className, classes.salesPlaysCard)}
      onClick={handleSalesCardClick}
      data-testid={testid}
      ref={ref}
    >
      {/* TODO:  Fix overlay */}
      <SalesPlayAccepted visible={isComplete} />
      <IonCardHeader className={classes.cardHeader}>
        <IonCardTitle className={classes.cardTitle}>
          {convertSalesTypeForDisplay(salesPlay.type)}
          <FontAwesomeIcon
            className={classnames(classes.salesPlayTypeIcon)}
            icon={['far', iconAndColorFromType(salesPlay.type).icon]}
            color={iconAndColorFromType(salesPlay.type).color}
            data-testid={`sales-plays-type-icon-${testid}`}
          />
        </IonCardTitle>
        <IonCardSubtitle className={classes.cardSubtitle}>
          {salesPlay.customerPick12 && (
            <Pick12Icon testid={testid} className={classes.pick12Icon} />
          )}
          {salesPlay.customerName}
        </IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent className={classes.cardContent}>
        <div className={classes.cardContentTop}>
          <Text
            text={getDescription(t, salesPlay)}
            variant="content-small"
            testid={`${toLower(salesPlay.type)}-content`}
          />
        </div>
        {salesPlay?.jsonSummary && (
          <div className={classes.cardContentFooter}>
            {map(
              salesPlay?.jsonSummary,
              ({ _format, label, value, description }, key) => (
                <IonRow key={`label-${key}`}>
                  <Text
                    text={`${t(concat('', label))}:`}
                    variant="content-default"
                    testid={`label-${key}`}
                    className={classes.summaryTextMargin}
                  />
                  {getFormattedValue(
                    _format,
                    description || value,
                    toNumber(value) * 100 >= 0
                      ? classes.positiveText
                      : classes.negativeText,
                    salesPlay?.currencyType
                  )}
                </IonRow>
              )
            )}
          </div>
        )}
      </IonCardContent>
      <FontAwesomeIcon
        className={classnames(classes.chevronRightIcon)}
        icon={findIcon('arrow-right', 'far')}
        data-testid={`sales-plays-card-arrow-right-${testid}`}
      />
    </IonCard>
  );
};

const areEqual = (
  { salesPlay: prevSalesPlay }: SalesPlaysCardProps & IonicReactProps,
  { salesPlay: nextSalesPlay }: SalesPlaysCardProps & IonicReactProps
) => {
  return (
    prevSalesPlay.externalId === nextSalesPlay.externalId &&
    prevSalesPlay.status === nextSalesPlay.status
  );
};

export default React.memo(SalesPlaysCard, areEqual);
