import { or } from 'common/utils/logicHelpers';
import { reportsURL } from 'navigation';
import { concatRoutes } from 'utils/navigations';
import {
  costSavingsReportURL,
  endOfMonthReportsURL,
  openQuotesReportsURL,
  salesPerformanceReportURL,
} from './routerConfig';

export const goToSalesPerformanceReport = ({
  baseUrl = '',
  orgType = '',
  miLoc = '',
  rowId = '',
  pgc1 = '',
}) => {
  return concatRoutes(
    or(baseUrl, reportsURL()),
    salesPerformanceReportURL({ orgType, miLoc, rowId, pgc1 })
  );
};

export const goToCostSavingsReport = ({
  orgType = '',
  miLoc = '',
  rowId = '',
  pgc1 = '',
}) => {
  return concatRoutes(
    reportsURL(),
    costSavingsReportURL({ orgType, miLoc, rowId, pgc1 })
  );
};

export const goToEndOfMonthReport = () =>
  concatRoutes(reportsURL(), endOfMonthReportsURL());

export const goToOpenQuotesReport = () =>
  concatRoutes(reportsURL(), openQuotesReportsURL());
