import { useEffect, useRef } from 'react';
import { size, toNumber } from 'lodash';
import { createAnimation } from '@ionic/react';
import { useNodeRef } from 'common/components/utils/renderHelpers';

interface UseInputMaxLengthProps {
  value: string;
  maxlength: number;
}

const useInputMaxLength = (props: UseInputMaxLengthProps) => {
  const { node: charCounterRef, nodeRef: setCharCounterRef } =
    useNodeRef<HTMLDivElement>();
  const charCounterAnimation = useRef<Animation>();
  const maxlength = toNumber(props.maxlength);
  const showMaxLengthWarn = maxlength > 0 && size(props.value) >= maxlength;

  useEffect(() => {
    if (charCounterRef) {
      charCounterAnimation.current = createAnimation()
        .addElement(charCounterRef)
        .duration(400)
        .iterations(1)
        .keyframes([
          { offset: 0, transform: 'translateX(0)' },
          { offset: 0.125, transform: 'translateX(-6px) rotateY(-5deg)' },
          { offset: 0.375, transform: 'translateX(5px) rotateY(4deg)' },
          { offset: 0.625, transform: 'translateX(-3px) rotateY(-2deg)' },
          { offset: 0.875, transform: 'translateX(2px) rotateY(1deg)' },
          { offset: 1, width: 'translateX(0)' },
        ]) as unknown as Animation;
    }
  }, [charCounterRef]);

  const playCharCounterAnimation = () => {
    if (showMaxLengthWarn) {
      setTimeout(() => charCounterAnimation.current?.play());
    }
  };

  useEffect(() => {
    playCharCounterAnimation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMaxLengthWarn]);

  return { showMaxLengthWarn, setCharCounterRef, playCharCounterAnimation };
};

export default useInputMaxLength;
