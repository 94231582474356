/* eslint-disable no-console */
import { head, reduce } from 'lodash';
import type { FindReportsQueryParams } from 'DocumentsApp/models/FastFind';
import type { Report } from 'DocumentsApp/models/Report';
import type { ReportImage } from 'models/InspectionReport';
import useDocumentsDB from './initDocumentsDB';
import { createDocuments } from './utils';

interface UseReportDBResponse {
  createReports: (reports: Report[]) => Promise<void>;
  findReports: (props: FindReportsQueryParams) => Promise<Report[]>;
  getReportById: (reportId: string) => Promise<Report>;
  findReportsReadyForUpload: (miLoc: string) => Promise<Report[]>;
  removeReportsByShopLoc: (miLoc: string, completed?: boolean) => Promise<void>;
  removeReportById: (reportId: string, removeImages?: boolean) => Promise<void>;
}

const useReportDB = (): UseReportDBResponse => {
  const { db, openDB, closeDB, getLikeStatement, getWhereStatement } =
    useDocumentsDB();

  const createReports = async (reports: Report[]): Promise<void> => {
    try {
      await openDB();
      await createDocuments(db, reports);
      // saving images
      // TODO should write all images at once instead of one at a time
      await reduce(
        reports,
        async (prevReport, report) => {
          await prevReport;
          await reduce(
            report?.reportImages,
            async (prev, image) => {
              await prev;
              await db.executeSet(
                [
                  {
                    statement: `INSERT OR REPLACE INTO report_image (
                      reportId,
                      imageId,
                      image
                      ) VALUES (?,?,?)`,
                    values: [report.reportId, image.imageId, image.image],
                  },
                ],
                true
              );
            },
            Promise.resolve()
          );
          return Promise.resolve();
        },
        Promise.resolve()
      );
    } catch (error) {
      console.log('Error saving reports to database', error);
      throw new Error('Error saving reports to database');
    } finally {
      await closeDB();
    }
  };

  const findReports = async ({
    miLoc,
    startDate,
    endDate,
    status,
    templateTypeId,
    searchTerm,
  }: FindReportsQueryParams): Promise<Report[]> => {
    try {
      const whereStatement = [];
      const vars = [];
      if (miLoc) {
        whereStatement.push(getLikeStatement('shopLoc'));
        vars.push(`%${miLoc}%`);
      }
      // TODO: date comparison?
      if (startDate) {
        whereStatement.push(getLikeStatement('startDate', vars));
        vars.push(`%${startDate}%`);
      }
      if (endDate) {
        whereStatement.push(getLikeStatement('endDate', vars));
        vars.push(`%${endDate}%`);
      }
      if (status) {
        whereStatement.push(getLikeStatement('status', vars));
        vars.push(`%${status}%`);
      }
      if (templateTypeId) {
        whereStatement.push(getLikeStatement('templateId', vars));
        vars.push(`%${templateTypeId}%`);
      }
      if (searchTerm) {
        whereStatement.push(
          getLikeStatement(
            ['name', 'woCtlNo', 'orderCtlNo', 'miLoc', 'customerName'],
            vars
          )
        );
        const likeSearchTerm = `%${searchTerm}%`;
        vars.push(
          likeSearchTerm,
          likeSearchTerm,
          likeSearchTerm,
          likeSearchTerm,
          likeSearchTerm
        );
      }
      await openDB();
      return (
        await db.query(
          `SELECT * FROM report
          ${getWhereStatement(whereStatement)}
          ORDER BY needSync DESC, startDate DESC`,
          vars
        )
      ).values as Report[];
    } catch (error) {
      console.log('Error loading reports', error);
      throw new Error('Error loading reports');
    } finally {
      await closeDB();
    }
  };

  const getReportById = async (reportId: string): Promise<Report> => {
    try {
      const vars = [reportId];
      await openDB();
      const report = head(
        (
          await db.query(
            `SELECT * FROM report
            WHERE reportId = ?`,
            vars
          )
        ).values
      ) as Report;
      // get images
      const images = (
        await db.query(
          `SELECT * FROM report_image
          WHERE reportId = ?`,
          vars
        )
      ).values as ReportImage[];
      return { ...report, offlineImages: images };
    } catch (error) {
      console.log('Error loading reports', error);
      throw new Error('Error loading reports');
    } finally {
      await closeDB();
    }
  };

  const findReportsReadyForUpload = async (
    miLoc: string
  ): Promise<Report[]> => {
    try {
      const vars = [miLoc, '1'];
      await openDB();
      return (
        await db.query(
          `SELECT * FROM report
          WHERE shopLoc = ?
          AND needSync = ?
          ORDER BY name ASC`,
          vars
        )
      ).values as Report[];
    } catch (error) {
      console.log('Error loading reports', error);
      throw new Error('Error loading reports');
    } finally {
      await closeDB();
    }
  };

  const removeReportsByShopLoc = async (
    miLoc: string,
    completed = false
  ): Promise<void> => {
    try {
      await openDB();
      await db.query(
        `DELETE FROM report
        WHERE shopLoc = ?
        ${
          completed
            ? `
        AND status = 'CL'
        AND needSync = false`
            : ''
        }`,
        [miLoc]
      );
    } catch (error) {
      console.log('Error removing reports', error);
      throw new Error('Error removing reports');
    } finally {
      await closeDB();
    }
  };

  const removeReportById = async (
    reportId: string,
    removeImages = true
  ): Promise<void> => {
    try {
      const vars = [reportId];
      await openDB();
      await db.query(
        `DELETE FROM report
        WHERE reportId = ?`,
        vars
      );
      if (removeImages) {
        await db.query(
          `DELETE FROM report_image
          WHERE reportId = ?`,
          vars
        );
      }
    } catch (error) {
      console.log('Error removing reports', error);
      throw new Error('Error removing reports');
    } finally {
      await closeDB();
    }
  };

  return {
    createReports,
    findReports,
    getReportById,
    findReportsReadyForUpload,
    removeReportsByShopLoc,
    removeReportById,
  };
};

export default useReportDB;
