import { useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';
import type { RootState } from 'store/reducers';

const useEnabledByUser = ({ enabled }: { enabled: boolean }) => {
  const { userInfo } = useSelector((state: RootState) => state.user);
  const userId = get(userInfo, 'userid', '');
  return enabled && !isEmpty(userId);
};

export default useEnabledByUser;
