import React from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import List from 'common/components/List/List';
import { useNodeRef } from 'common/components/utils/renderHelpers';
import { namespaces } from 'i18n/i18n.constants';
import useFindProductOpportunities from 'ProductSearchApp/api/useFindProductOpportunties';
import ProductListItem from 'ProductSearchApp/components/ProductListItem/ProductListItem';
import { getErrorMessage } from 'utils/helpers';
import classes from './ProductSSSTab.module.scss';

interface ProductSSSTabProp {
  isSSSTab?: boolean;
  productId: string;
  id: string;
  miLoc: string;
  groupSerial: string;
  testid: string;
}

const ProductSSSTab = ({
  isSSSTab = false,
  productId,
  id,
  miLoc,
  groupSerial,
  testid,
}: ProductSSSTabProp): JSX.Element => {
  const { t } = useTranslation(namespaces.product);
  const { node, nodeRef } = useNodeRef();

  const {
    items: opportunities,
    error,
    isLoading,
    isEmptyResponse,
    noMoreData,
    fetchNextPage,
  } = useFindProductOpportunities({
    miLoc,
    customerNo: id,
    productId,
    groupSerial,
    showOpportunitiesOnly: isSSSTab ? 'Y' : 'N',
    opportunitiesOrderBy: isSSSTab ? 2 : undefined,
    returnSCAvail: true,
    enabled: !isEmpty(groupSerial),
  });

  return (
    <div ref={nodeRef} className={classes.content}>
      <List
        className={classes.list}
        title={{
          text: isSSSTab ? t('sssOpps') : t('interchanges'),
          wrapperProps: { className: classes.tableHeader },
        }}
        data={opportunities}
        itemContent={(itemIndex, item) => (
          <ProductListItem
            hideCost
            isSSSItem
            productItem={item}
            key={`${item.itemNumber}-${itemIndex}`}
            miLoc={miLoc}
            customerId={id}
            testid={`${item.itemNumber}-${itemIndex}`}
          />
        )}
        scrollParent={node}
        isLoading={{
          isLoading,
          text: isSSSTab
            ? t('loadingSSSOpportunities')
            : t('loadingInterchanges'),
        }}
        isEmptyList={{
          isEmptyList: isEmptyResponse,
          title: t('noResults'),
          body: t('noSSSResultsMessage'),
        }}
        isError={{
          isError: !!error,
          title: isSSSTab
            ? t('loadingSSSOpptError')
            : t('loadingInterchangesError'),
          body: getErrorMessage(error),
        }}
        isEndOfList={noMoreData}
        endReached={fetchNextPage}
        testid={testid}
      />
    </div>
  );
};

export default ProductSSSTab;
