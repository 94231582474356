import { useMemo } from 'react';
import type { AxiosError } from 'axios';
import { size, head } from 'lodash';
import { useInfiniteQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import type {
  QueryFlags,
  QueryFnProps,
  QueryParamsType,
} from 'common/api/utils/useGetQueryFlags';
import useGetQueryFlags from 'common/api/utils/useGetQueryFlags';
import type { TransmissionLog } from 'ProductSearchApp/models/Order';
import { useAxios } from 'providers/AxiosProvider';
import { doConcatDataPages, useKeyUserId, useMiLocOrTeamId } from 'api/helpers';
import { pageSize } from 'utils/constants';

export const findTransmissionLogsQueryKey = 'transmission-logs';

interface UseFindTransmissionLogsProps {
  miLoc?: string;
  orderCtlNo?: string;
  documentType?: string;
  limit?: number;
  enabled?: boolean;
}

interface UseFindTransmissionLogsResponse {
  log: TransmissionLog[];
  totalRows?: number;
}

const useFindTransmissionLogs = ({
  miLoc,
  orderCtlNo,
  documentType,
  limit = pageSize(),
  enabled = true,
}: UseFindTransmissionLogsProps): UseFindTransmissionLogsResponse &
  QueryFlags => {
  const { axios } = useAxios();
  const { findTransmissionLogsAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const { getURLParams } = useMiLocOrTeamId({});
  const params: QueryParamsType = {
    miLoc,
    orderCtlNo,
    documentType,
    limit,
  };

  const doFindTransmissionLogs = async ({
    pageParam = 1,
    signal,
  }: QueryFnProps) => {
    const { data } = await axios.get<UseFindTransmissionLogsResponse>(
      findTransmissionLogsAPI(getURLParams({ ...params, page: pageParam })),
      { signal }
    );
    return data;
  };

  const response = useInfiniteQuery<
    UseFindTransmissionLogsResponse,
    AxiosError
  >(
    createQueryKey(findTransmissionLogsQueryKey, params),
    doFindTransmissionLogs,
    {
      enabled,
      getNextPageParam: (lastPage, pages) =>
        size(lastPage.log) < limit ? false : size(pages) + 1,
    }
  );

  const { data } = response;

  const log = useMemo(
    () =>
      doConcatDataPages<TransmissionLog, UseFindTransmissionLogsResponse>(
        data,
        'log'
      ),
    [data]
  );

  const queryFlags = useGetQueryFlags({ ...response, data: log, enabled });

  return {
    log,
    totalRows: head(data?.pages)?.totalRows,
    ...queryFlags,
  };
};

export default useFindTransmissionLogs;
