import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { defaultExpectedDate } from 'constants/platformSpecificConstants';
import classNames from 'classnames';
import { includes, isEmpty, toNumber, toString, size, first } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IonGrid,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonRow,
} from '@ionic/react';
import Alert, { AlertVariantEnum } from 'common/components/Alert/Alert';
import { FormikInput } from 'common/components/Forms/Input/Input';
import { scaleNumber } from 'common/utils/numberHelper';
import { Form, FormikProvider, useFormik } from 'formik';
import { namespaces } from 'i18n/i18n.constants';
import { orderLineURL } from 'navigation';
import DeleteOrderItem from 'ProductSearchApp/components/DeleteOrderItem/DeleteOrderItem';
import ErrorList from 'ProductSearchApp/components/ErrorList/ErrorList';
import ProductHeader from 'ProductSearchApp/components/ProductHeader/ProductHeader';
import useGetMtqOrMoqMessage from 'ProductSearchApp/hooks/useGetMtqOrMoqMessage';
import type { CartOrderItem, Order } from 'ProductSearchApp/models/Order';
import type { UpdateToCart } from 'ProductSearchApp/models/Products';
import { EditOrderLineTabEnum } from 'ProductSearchApp/pages/EditOrderLine/EditOrderLine';
import {
  QuantitySchema,
  qtyScaleProps,
} from 'ProductSearchApp/pages/EditOrderLine/EditOrderLineSchema';
import type { UpdatedLineItemProp } from 'ProductSearchApp/pages/OrderCart/OrderCart';
import {
  canCancelWo,
  cancelWoStatus,
  getDisableByShopSourcing,
  getItemShopSourcing,
  getProductFromOcnLine,
  isUnfinishedOrder,
  sumOfSourcedQuantity,
  getOrderLineItemPayload,
  useRepriceToast,
  hasPriceOverride,
} from 'ProductSearchApp/util/ocnHelpers';
import { sssGreenArray } from 'ProductSearchApp/util/productSearchUtil';
import type { MiProFile } from 'models/Attachment';
import { AttachmentSize } from 'models/Attachment';
import type { RootState } from 'store/reducers';
import {
  DateFormatEnum,
  formatDate,
  formatLastUpdatedDate,
  parseDate,
} from 'utils/date';
import { concatRoutes } from 'utils/navigations';
import FileAttachments from 'components/Attachments/FileAttachments';
import Button from 'components/Button/Button';
import CurrencyFormat from 'components/CurrencyFormat/CurrencyFormat';
import Spinner from 'components/Spinner/Spinner';
import Text from 'components/Text/Text';
import classes from './CartLineItem.module.scss';

interface CartLineItemProps {
  order?: Order;
  lineItem: CartOrderItem;
  testid: string;
  attachments?: MiProFile[];
  baseUrl: string;
  focusedOrderLineNo?: string;
  updating?: boolean;
  selectedOrderLineNo?: string;
  triggerSubmit?: number;
  itemDate?: string;
  disabled?: boolean;
  updateStatus?: string;
  setSelectedOrderLineNo: (selectedOrderLineNo?: string) => void;
  setDatePickerDate: (date: Date) => void;
  setIsCalendarOpen: (calendarOpen: boolean) => void;
  setFocusOrderLineNo: (focusedOrderLineNo: string) => void;
  onUpdateToCart: (body: UpdateToCart) => void;
  onDeleteItem?: (item: CartOrderItem, code: string) => void;
  updateFormikError?: (orderLineNo: string, formikErr: boolean) => void;
}

const CartLineItem = ({
  order,
  lineItem,
  testid,
  attachments,
  baseUrl,
  focusedOrderLineNo,
  selectedOrderLineNo,
  triggerSubmit,
  itemDate,
  disabled,
  updateStatus,
  setSelectedOrderLineNo,
  setFocusOrderLineNo,
  setDatePickerDate,
  setIsCalendarOpen,
  onUpdateToCart,
  onDeleteItem,
  updateFormikError,
  updating,
}: CartLineItemProps): JSX.Element => {
  const namespace = 'productSearch:ocn';
  const reviewNamespace = 'productSearch:review';
  const { t } = useTranslation();
  const { showCostDetails } = useSelector((state: RootState) => state.user);
  const [isOpenRemoveItemModal, setIsOpenRemoveItemModal] = useState(false);
  const [isOpenReturnInstructions, setIsOpenReturnInstructions] =
    useState(false);
  const [quantityUpdated, setQuantityUpdated] = useState(false);
  const {
    itemNo,
    unitSellPrice,
    priceSourceDesc,
    salesUOM,
    grossProfitPct,
    costSource,
    hasOpportunities,
    orderLineNo,
    custComment,
    promsdDelivDt,
    errorList,
    opQtyOrdered,
    priceOverridePrice,
    priceOverrideGPPerc,
    itemStatusDetailDesc,
  } = lineItem;

  const lineQuantity = scaleNumber({
    number: opQtyOrdered,
    ...qtyScaleProps,
  });
  const shopLocations = getItemShopSourcing(lineItem);
  const firstShopLocation = first(shopLocations);
  const isUnfinished = isUnfinishedOrder(order?.orderTypeCd);
  const disableByShopSourcing = getDisableByShopSourcing(order, lineItem);
  const isCancelledWo = cancelWoStatus(lineItem?.itemSourcing);

  const disableEditable = disabled || !lineItem.isEditable || isCancelledWo;
  const disableQuantity =
    disableEditable || disableByShopSourcing || isCancelledWo;

  const formik = useFormik<UpdatedLineItemProp>({
    initialValues: {
      orderLineNo,
      quantity: lineQuantity,
      originalQuantity: lineQuantity,
      expectedDate: promsdDelivDt,
      isSourcing: !isEmpty(lineItem?.itemSourcing),
      isUnfinished,
      sourcedQty: sumOfSourcedQuantity(lineItem),
    },
    enableReinitialize: true,
    onSubmit: ({ quantity, originalQuantity, expectedDate }) => {
      const diffQuantity =
        scaleNumber({ number: quantity, ...qtyScaleProps }) !==
        originalQuantity;
      if (diffQuantity || expectedDate !== promsdDelivDt) {
        const payloadItems = {
          ...getOrderLineItemPayload(lineItem),
          quantity: toNumber(quantity),
          promsdDelivDt: itemDate,
        };
        const sourcingPayload = [];
        if (isUnfinished && firstShopLocation?.requestTo && diffQuantity) {
          sourcingPayload.push({
            purchaseQty: formik.values.quantity,
            sourceLoc: firstShopLocation?.requestTo,
            reqCtlNo: firstShopLocation?.ctlNo,
            purchaseQtyOrig: firstShopLocation?.qtyRequested,
          });
        }
        onUpdateToCart({
          itemList: [payloadItems],
          sourceList: sourcingPayload,
        });
      }
    },
    validationSchema: QuantitySchema,
    validateOnChange: true,
  });
  const { values: formikLineItem, errors } = formik;

  const sssGreen = includes(sssGreenArray, costSource);
  const totalItemPrice =
    (toNumber(formikLineItem?.quantity) || 0) * toNumber(unitSellPrice);

  const overridePrice = scaleNumber({
    number:
      toNumber(priceOverridePrice) > 0 ? priceOverridePrice : unitSellPrice,
  });
  const overrideGP = scaleNumber({
    number:
      toNumber(priceOverrideGPPerc) > 0 ? priceOverrideGPPerc : grossProfitPct,
  });
  const autoReprice = lineItem?.paType === 'Z' && order?.autoRepriceZPI === 'Y';
  const { showRepriceToast } = useRepriceToast(overridePrice, overrideGP);

  useEffect(() => {
    if (updateStatus === 'success' && autoReprice && quantityUpdated) {
      showRepriceToast(() => {
        setQuantityUpdated(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus, autoReprice]);

  const expectedDate = formikLineItem?.expectedDate || promsdDelivDt;
  const expectedDateShown =
    expectedDate === defaultExpectedDate ? '' : expectedDate;

  const getRepairShopTextLine = () => {
    if (size(shopLocations) > 1) {
      return t(`${namespace}:multipleRepairShop`);
    }
    const shopLoc = first(shopLocations);
    return `(${toString(shopLoc?.requestTo)}) ${toString(
      shopLoc?.requestToName
    )}`;
  };

  useEffect(() => {
    const setExpectedDate = async () => {
      if (selectedOrderLineNo === orderLineNo) {
        await formik.setFieldValue(
          'expectedDate',
          formatDate(itemDate, DateFormatEnum.standardDate)
        );
      }
    };
    void setExpectedDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerSubmit]);

  useEffect(() => {
    if (formikLineItem.quantity) {
      formik.handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikLineItem.expectedDate]);

  const overrideMode = hasPriceOverride(lineItem);

  useEffect(() => {
    updateFormikError?.(orderLineNo, !isEmpty(errors.quantity));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors.quantity, orderLineNo]);

  const { minOrderQtyMessage } = useGetMtqOrMoqMessage({
    transferMinQty: lineItem?.transferMinQty,
    quantity: toNumber(formikLineItem.quantity),
    minimumOrderQty: lineItem?.minimumOrderQty,
    dcDefaultFlag: lineItem.dcDefaultFlag,
    brDefaultFlag: lineItem.brDefaultFlag,
    orderIncrement: lineItem.orderIncrement,
  });

  const onDelete = () => {
    if (canCancelWo(order, lineItem.itemSourcing)) {
      setIsOpenReturnInstructions(true);
    } else {
      setIsOpenRemoveItemModal(true);
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form key={lineItem.orderLineNo}>
        <IonItemSliding
          disabled={disableEditable || updating}
          className={classNames(classes.wrapper, {
            [classes.isUpdating]: disableEditable,
          })}
        >
          <IonItemOptions side="end">
            <IonItemOption
              className={classes.deleteSlider}
              onClick={() => onDelete()}
            >
              <Text text={t('common:delete')} variant="mipro-body-copy-bold" />
            </IonItemOption>
          </IonItemOptions>

          <IonItem className={classes.item} lines="none">
            <IonGrid
              key={`${orderLineNo}`}
              className={classNames(classes.summaryGrid, classes.itemGrid)}
              data-testid={testid}
            >
              <Spinner
                testid="edit-line-item-loader"
                text={t('common:updating')}
                showSpinner={!disabled && updating}
              />
              <ProductHeader
                withLink
                className={classNames(
                  classes.productHeader,
                  classes.pointerAll
                )}
                lineNumber={orderLineNo}
                hideImage
                hidePrice
                productData={getProductFromOcnLine(lineItem)}
                testid={`product-detail-header-${testid}`}
                badge={{
                  status: toString(itemStatusDetailDesc),
                  isCancelledWo,
                }}
              />
              {order?.orderTypeCd === 'O' && (
                <ErrorList errorList={errorList} />
              )}
              <IonRow key={orderLineNo} className={classes.quantityPriceRow}>
                <FormikInput
                  key={orderLineNo}
                  className={classNames(classes.quantity, {
                    [classes.pointerAll]: !disableQuantity,
                  })}
                  name="quantity"
                  disabled={disableQuantity}
                  numberMask={qtyScaleProps}
                  hideError
                  autofocus={focusedOrderLineNo === orderLineNo}
                  onBlur={() => {
                    const newQuantity = scaleNumber({
                      number: formikLineItem.quantity,
                      ...qtyScaleProps,
                    });
                    if (newQuantity !== formikLineItem?.originalQuantity) {
                      setQuantityUpdated(true);
                      formik.handleSubmit();
                    }
                  }}
                  onFocus={() => setFocusOrderLineNo(orderLineNo)}
                  testid={`quantity-${orderLineNo}`}
                />
                <IonRow className={classes.priceRowBreaker}>
                  {overrideMode && (
                    <IonRow className={classes.priceOverride}>
                      <Button
                        variant="link"
                        text={t(`${namespace}:priceOverridePending`)}
                        icon={['fas', 'calculator']}
                        testid={`price-override-line-${testid}`}
                        id={`price-override-line-${testid}`}
                      />
                    </IonRow>
                  )}
                  <IonRow className={classes.quantityRow}>
                    <CurrencyFormat
                      className={classes.itemSeparator}
                      variant="title-action-card"
                      value={toNumber(unitSellPrice)}
                      scale={2}
                      currencyType="USD"
                      testid={`unit-sell-price-${testid}`}
                    />
                    <Text
                      className={classes.itemSeparator}
                      variant="content-smaller"
                      text={`${toString(priceSourceDesc)}/ ${toString(
                        salesUOM
                      )}`}
                    />
                  </IonRow>
                  <IonRow className={classes.gpRow}>
                    {showCostDetails && grossProfitPct !== undefined && (
                      <Text
                        variant="list-item-secondaryText"
                        text={t(`${namespaces.product}:gpPercent`, {
                          gpPct: toString(grossProfitPct).substring(0, 5),
                        })}
                        textQuery={t(`${namespaces.product}:gpPercent`)}
                        className={classes.itemSeparator}
                      />
                    )}
                    {sssGreen && (
                      <Text
                        variant="mipro-h6-headline"
                        text="SSS"
                        className={classNames(
                          classes.itemSeparator,
                          classes.sssText,
                          classes.secondaryTextGreen
                        )}
                      />
                    )}
                    {hasOpportunities && (
                      <Text
                        variant="mipro-h6-headline"
                        text="SSS"
                        className={classNames(
                          classes.itemSeparator,
                          classes.sssText,
                          classes.secondaryTextBlue
                        )}
                      />
                    )}
                  </IonRow>
                </IonRow>
                <CurrencyFormat
                  className={classes.totalPrice}
                  value={totalItemPrice}
                  variant="mipro-h3-headline"
                  scale={2}
                  currencyType="USD"
                  testid={`total-sell-price--${testid}`}
                />
              </IonRow>
              {errors.quantity && (
                <Alert
                  testid="quantity-error"
                  text={{ text: errors.quantity, testid: 'content-small' }}
                  variant={AlertVariantEnum.danger}
                  className={classes.error}
                />
              )}
              {isEmpty(errors.quantity) && !isEmpty(minOrderQtyMessage) && (
                <Alert
                  testid="min-order-qty-alert"
                  text={{
                    text: minOrderQtyMessage,
                    testid: 'content-small',
                  }}
                  variant={AlertVariantEnum.warning}
                  className={classes.error}
                />
              )}
              {!isEmpty(shopLocations) && (
                <IonRow className={classes.extendedInfo}>
                  <Text
                    className={classes.infoText}
                    variant="mipro-body-copy"
                    text={t(`${namespace}:repairShop`)}
                    textQuery={t(`${namespace}:repairShop`)}
                  />
                  <Text
                    className={classes.infoText}
                    variant="mipro-body-copy"
                    text={getRepairShopTextLine()}
                    testid={`repair-shop-${testid}`}
                  />
                </IonRow>
              )}
              {custComment && (
                <IonRow className={classes.extendedInfo}>
                  <Text
                    className={classes.infoText}
                    variant="mipro-body-copy"
                    text={t(`${namespace}:extendedDesc`)}
                    textQuery={t(`${namespace}:extendedDesc`)}
                  />
                  <Text
                    className={classes.infoText}
                    variant="mipro-body-copy"
                    text={custComment}
                    testid={`extended-desc-${testid}`}
                  />
                </IonRow>
              )}
              <IonRow
                className={classNames(classes.extendedInfo, classes.pointerAll)}
              >
                {/* TODO this should be a button */}
                <div
                  role="button"
                  onKeyUp={() => {}}
                  tabIndex={0}
                  data-testid={`date-picker-${testid}`}
                  onClick={() => {
                    if (disableEditable) {
                      return;
                    }
                    setSelectedOrderLineNo(orderLineNo);
                    setDatePickerDate(
                      parseDate(expectedDateShown || new Date())
                    );
                    setIsCalendarOpen(true);
                  }}
                >
                  <Text
                    text={t(`${namespace}:expectedDate`, {
                      expectedDate: !expectedDateShown
                        ? t(`${reviewNamespace}:notSet`)
                        : formatLastUpdatedDate(
                            expectedDate,
                            DateFormatEnum.dayShortMonth
                          ),
                    })}
                    textQuery={[
                      {
                        query: t(`${reviewNamespace}:expectedDate`),
                        className: classes.textBold,
                      },
                      {
                        query: t(`${reviewNamespace}:notSet`),
                        className: classes.textItalic,
                      },
                    ]}
                    variant="mipro-body-copy"
                  />
                  {!disableEditable && (
                    <FontAwesomeIcon
                      className={classes.calendarIcon}
                      icon={['far', 'calendar-days']}
                    />
                  )}
                </div>
              </IonRow>
              <IonRow
                className={classNames(
                  classes.attachmentRow,
                  classes.pointerAll
                )}
              >
                <FileAttachments
                  domain="order"
                  name="line-item-attachment"
                  files={attachments}
                  size={AttachmentSize.SMALL}
                  enableSmallPreview
                  editMode={false}
                  testid={`attachments-${testid}`}
                />
              </IonRow>

              {!disableEditable && (
                <IonRow
                  className={classNames(classes.buttonsRow, classes.pointerAll)}
                >
                  <Button
                    variant="link"
                    className={classes.uppercase}
                    href={concatRoutes(
                      baseUrl,
                      orderLineURL(orderLineNo, itemNo)
                    )}
                    textVariant="mipro-body-copy-bold"
                    text={t(`${namespace}:editOcnLineButton`)}
                    testid={`edit-button-${testid}`}
                  />
                  <span className={classes.buttonSeparator}>|</span>

                  <Button
                    variant="link"
                    className={classes.uppercase}
                    href={concatRoutes(
                      baseUrl,
                      orderLineURL(
                        orderLineNo,
                        itemNo,
                        EditOrderLineTabEnum.pricing
                      )
                    )}
                    textVariant="mipro-body-copy-bold"
                    text={t(`${namespace}:ocnLinePricingButton`)}
                    testid={`edit-button-${testid}`}
                  />
                  <span className={classes.buttonSeparator}>|</span>

                  <Button
                    variant="link"
                    className={classes.uppercase}
                    href={concatRoutes(
                      baseUrl,
                      orderLineURL(
                        orderLineNo,
                        itemNo,
                        EditOrderLineTabEnum.notes
                      )
                    )}
                    textVariant="mipro-body-copy-bold"
                    text={t(`${namespace}:notes`)}
                    disabled={disableEditable}
                    testid={`notes-button-${testid}`}
                  />

                  <span className={classes.buttonSeparator}>|</span>

                  <Button
                    icon={['far', 'trash-can']}
                    testid="remove-button"
                    className={classNames(classes.uppercase, classes.removeBtn)}
                    text={t('productSearch:ocn:remove')}
                    textVariant="mipro-body-copy-bold"
                    onClick={() => onDelete()}
                  />
                </IonRow>
              )}
            </IonGrid>
          </IonItem>
        </IonItemSliding>
        <DeleteOrderItem
          item={lineItem}
          showLostSale={!isUnfinished}
          deleteOrderLine={(lostSale) => onDeleteItem?.(lineItem, lostSale)}
          isOpenRemoveItemModal={isOpenRemoveItemModal}
          isOpenReturnInstructions={isOpenReturnInstructions}
          setIsOpenRemoveItemModal={setIsOpenRemoveItemModal}
          setIsOpenReturnInstructions={setIsOpenReturnInstructions}
        />
      </Form>
    </FormikProvider>
  );
};

export default CartLineItem;
