import { useTranslation } from 'react-i18next';
import { get, toNumber, toString } from 'lodash';
import type { MutationStatus } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useCountPlanDB from 'InventoryApp/database/useCountPlanDB';
import type {
  CountGroup,
  CountGroupItem,
} from 'InventoryApp/models/InventoryPlanGroup';
import { useToasts } from 'providers/ToastProvider';
import {
  onSuccessMutation,
  doPromiseAPI,
  onErrorUpdate,
  onMutateUpdate,
  getPlaceholderData,
} from 'api/helpers';
import { ToastType } from 'models/Toast';
import { findIcon } from 'utils/icons';
import {
  findCountGroupItemsQueryKey,
  findCountGroupTotalItemsQueryKey,
} from './useFindCountGroupItems';
import { findCountGroupsQueryKey } from './useFindCountGroups';

interface UpdateItemCountBody {
  groupUniqueId: string;
  itemId: string;
  actualCount: string;
  orderQuantity: number;
  hasCount?: boolean;
  width?: number;
  length?: number;
  hasLocalCount?: boolean;
}

interface UseUpdateItemCountResponse {
  status: MutationStatus;
  onUpdateItemCount: (body: UpdateItemCountBody) => void;
}

const useUpdateItemCount = (): UseUpdateItemCountResponse => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { updateItemCount, updateCountGroups } = useCountPlanDB();

  const doUpdateItemCount = ({
    groupUniqueId,
    itemId,
    actualCount,
    orderQuantity,
    hasCount = true,
    width,
    length,
    hasLocalCount = true,
  }: UpdateItemCountBody) => {
    return doPromiseAPI(async () => {
      await updateItemCount(
        itemId,
        actualCount,
        orderQuantity,
        hasCount,
        width,
        length,
        hasLocalCount
      );
      const cachedGroup = getPlaceholderData<CountGroup>({
        queryClient,
        queryKey: findCountGroupsQueryKey,
        objectKey: 'items',
        findPredicate: { uniqueId: groupUniqueId },
      });
      const cachedItem = getPlaceholderData<CountGroupItem>({
        queryClient,
        queryKey: findCountGroupItemsQueryKey,
        queryKeyParams: { groupId: groupUniqueId },
        findPredicate: { uniqueId: itemId },
      });
      const cachedHasCount = !!get(cachedItem, 'cachedHasCount');
      let initialCount = toNumber(cachedGroup?.linesUpdated) - 1;
      initialCount = initialCount < 0 ? 0 : initialCount;
      let linesUpdated = cachedHasCount
        ? toString(initialCount)
        : cachedGroup?.linesUpdated;
      if (hasCount) {
        linesUpdated = cachedHasCount
          ? cachedGroup?.linesUpdated
          : toString(toNumber(cachedGroup?.linesUpdated) + 1);
      }
      await updateCountGroups([{ linesUpdated, groupUniqueId }]);
      return { linesUpdated };
    });
  };

  const { status, mutate } = useMutation(doUpdateItemCount, {
    networkMode: 'always',
    // TODO: optimisitc update for tabs totals?,
    onMutate: async ({
      groupUniqueId,
      itemId,
      actualCount,
      orderQuantity,
      hasCount = true,
      width,
      length,
    }) => {
      const cachedItem = getPlaceholderData<CountGroupItem>({
        queryClient,
        queryKey: findCountGroupItemsQueryKey,
        queryKeyParams: { groupId: groupUniqueId },
        findPredicate: { uniqueId: itemId },
      });

      if (hasCount) {
        return onMutateUpdate<CountGroupItem>({
          queryClient,
          queryKey: findCountGroupItemsQueryKey,
          updatedItems: [
            {
              uniqueId: itemId,
              actualCount,
              orderQty: orderQuantity,
              hasCount: true,
              cachedHasCount: cachedItem?.hasCount,
              actualItemWidth: width,
              actualItemLength: length,
            } as unknown as CountGroupItem,
          ],
          findPredicates: [{ uniqueId: itemId }],
          isInfiniteQuery: true,
        });
      }
      return onMutateUpdate<CountGroupItem>({
        queryClient,
        queryKey: findCountGroupItemsQueryKey,
        updatedItems: [
          {
            uniqueId: itemId,
            hasCount: false,
            cachedHasCount: cachedItem?.hasCount,
          } as unknown as CountGroupItem,
        ],
        findPredicates: [{ uniqueId: itemId }],
        isInfiniteQuery: true,
      });
    },
    onSuccess: (
      { linesUpdated },
      { groupUniqueId, actualCount, hasCount = true }
    ) => {
      void onMutateUpdate<CountGroup>({
        queryClient,
        queryKey: findCountGroupsQueryKey,
        updatedItems: [
          {
            uniqueId: groupUniqueId,
            linesUpdated,
          } as CountGroup,
        ],
        dataPath: 'items',
        findPredicates: [{ uniqueId: groupUniqueId }],
        markAsUpdated: false,
        isInfiniteQuery: true,
      });
      void onSuccessMutation(queryClient, findCountGroupItemsQueryKey);
      void onSuccessMutation(queryClient, findCountGroupTotalItemsQueryKey);
      if (hasCount) {
        addToast({
          type: ToastType.success,
          variant: 'mipro-toast',
          header: t('inventory:countSaved', { value: actualCount }),
          testid: 'count-added',
          leftIcon: findIcon('check-circle', 'fas'),
        });
      } else {
        addToast({
          header: t('inventory:itemCountDeleted'),
          testid: 'count-deleted',
        });
      }
    },
    onError: (error, vars, context) => {
      addToast({
        type: ToastType.error,
        text: 'There was an error updating item count.',
        testid: 'update-item-count-error-toast',
      });
      onErrorUpdate<CountGroupItem>({
        queryClient,
        context,
        isInfiniteQuery: true,
      });
    },
  });

  return {
    status,
    onUpdateItemCount: (body: UpdateItemCountBody) => mutate(body),
  };
};

export default useUpdateItemCount;
