import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import type {
  BaseComponentProps,
  OptionalComponentProps,
} from 'common/components/utils/renderHelpers';
import useFindRepairShops from 'ProductSearchApp/api/fastfind/useFindRepairShops';
import type { SelectModalItem } from 'models/Search';
import { getSearchResultData } from 'utils/search';
import SelectModal from 'components/SelectModal/SelectModal';

interface RepairShopSelectProps extends BaseComponentProps {
  miLoc?: string;
  setRepairShop: (o: SelectModalItem) => void;
}

const RepairShopSelect = ({
  miLoc,
  setRepairShop,
  selItem,
  testid = 'repair-shop-select',
  ...props
}: RepairShopSelectProps &
  OptionalComponentProps<
    React.ComponentProps<typeof SelectModal>
  >): JSX.Element => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const { locations, error, isLoading, isEmptyResponse, fetchNextPage } =
    useFindRepairShops({ query: searchQuery, miLoc });

  const inputDisplay = selItem?.key ? `(${selItem.key}) ${selItem.title}` : '';

  useEffect(() => {
    if (!isOpen) {
      setSearchQuery('');
    }
  }, [isOpen, searchQuery, setSearchQuery]);

  const items = map(locations, (item, index) => {
    const locationText = `${item.miLoc} - ${item.locationShortName}`;
    const { description } = getSearchResultData(
      { ...item, type: 'motionLocation', id: item.miLoc, text: locationText },
      index,
      t
    );
    return {
      key: item.miLoc,
      title: locationText,
      description,
      showAvatar: true,
    };
  });

  return (
    <SelectModal
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      selItem={selItem || { title: '', description: '', key: '' }}
      setSelItem={(o) => setRepairShop(o)}
      items={items}
      error={error}
      isEmptyResponse={!!isEmptyResponse}
      showLoader={!!isLoading}
      fetchNextPage={fetchNextPage}
      enableInfiniteScroll
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      onClick={() => setIsOpen(true)}
      value={inputDisplay}
      icon="caret-down"
      testid={testid}
    />
  );
};

export default RepairShopSelect;
