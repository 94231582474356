import React, { useEffect, useState } from 'react';
import { map, toNumber } from 'lodash';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import useFindCostCodes from 'CostSavingsApp/api/useFindCostCodes';
import type { SelectModalItem } from 'models/Search';
import SelectModal from 'components/SelectModal/SelectModal';

interface VasSelectProps {
  selItem?: SelectModalItem;
  className?: string;
  label: string;
  disabled?: boolean;
  iconClassName?: string;
  placeholder: string;
  setCostSavingsCode: (o: SelectModalItem) => void;
  showInput?: boolean;
  openModal?: boolean;
  setOpenModal?: (b: boolean) => void;
  triggerInput?: number;
}

const VasSelect = ({
  selItem,
  className,
  label,
  disabled,
  iconClassName,
  placeholder,
  setCostSavingsCode,
  showInput = true,
  openModal = false,
  setOpenModal,
  triggerInput,
}: VasSelectProps & IonicReactProps): JSX.Element => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const {
    error,
    vasCodes: items,
    isEmptyResponse,
    showLoader,
    fetchNextPage,
    enableInfiniteScroll,
  } = useFindCostCodes({
    query: searchQuery,
  });

  useEffect(() => {
    if (!isOpen && !openModal) {
      setSearchQuery('');
    }
  }, [isOpen, openModal, searchQuery, setSearchQuery]);

  const inputDisplay = selItem?.key ? `(${selItem.key}) ${selItem.title}` : '';

  const vasItems = map(items, (item) => ({
    key: item.codeValue,
    title: item.codeDesc,
    description: item.codeValue,
  }));

  useEffect(() => {
    if (toNumber(triggerInput) > 0) {
      setIsOpen(true);
    }
  }, [triggerInput]);

  return (
    <SelectModal
      isOpen={setOpenModal ? openModal : isOpen}
      setIsOpen={setOpenModal || setIsOpen}
      type="vascode"
      subType=""
      selItem={selItem || { title: '', description: '', key: '' }}
      setSelItem={(o) => {
        setTimeout(() => {
          setCostSavingsCode(o);
        }, 100);
      }}
      testid="select-vas-modal"
      isEmptyResponse={isEmptyResponse}
      showLoader={showLoader}
      fetchNextPage={fetchNextPage}
      error={error}
      enableInfiniteScroll={enableInfiniteScroll}
      items={vasItems}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      onClick={() => setIsOpen(true)}
      label={label}
      value={inputDisplay}
      placeholder={placeholder}
      disabled={disabled}
      iconClassName={iconClassName}
      showInput={showInput}
      className={className}
      icon={selItem?.key ? 'caret-down' : 'search'}
    />
  );
};

export default VasSelect;
