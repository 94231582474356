import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { find, isNil, map, findIndex, times, size } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  getUnixTime,
  addDays,
  getDate,
  startOfToday,
  subDays,
  differenceInDays,
  set,
} from 'date-fns';
import { Virtual } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import type SwiperClass from 'swiper/types/swiper-class';
import type { DateSlideIcon } from 'models/DateSlider';
import 'swiper/css';
import 'swiper/css/pagination';
import { DateFormatEnum, formatDate, parseDate } from 'utils/date';
import classes from './DateSlider.module.scss';

interface DateSliderProps {
  icons?: DateSlideIcon[];
  date?: number;
  scrollTrigger?: number;
  startDate?: number;
  endDate?: number;
  onDateChange?: (date: number) => void;
  isLoading?: boolean;
}

const NUMBER_OF_DAYS_TO_ADD = 365;
const DAYS_PER_VIEW = 21;

const DateSlider = ({
  date = getUnixTime(startOfToday()),
  icons,
  scrollTrigger,
  startDate,
  endDate,
  onDateChange,
  isLoading = false,
}: DateSliderProps): JSX.Element => {
  const [centerDate, setCenterDate] = useState<number>(date);
  const [slides, setSlides] = useState<number[]>([]);
  const { i18n } = useTranslation();
  const swiperRef = useRef<SwiperClass>();

  useEffect(() => {
    setSlides(
      times(NUMBER_OF_DAYS_TO_ADD, (index) =>
        getUnixTime(subDays(addDays(parseDate(centerDate), 30 * 6), index))
      ).reverse()
    );
  }, [centerDate]);

  const updateStartDate = useCallback(
    (newDate: number) => {
      if (
        Math.abs(differenceInDays(parseDate(centerDate), parseDate(newDate))) >
        30 * 5
      ) {
        setCenterDate(newDate);
      }
    },
    [centerDate]
  );

  useEffect(() => {
    updateStartDate(date);
  }, [date, updateStartDate]);

  useEffect(() => {
    if (size(icons) > 0) {
      const index = findIndex(slides, (d) => d === date);

      if (swiperRef) {
        swiperRef.current?.slideTo(index - DAYS_PER_VIEW + 2);
      }
    }
  }, [scrollTrigger, date, slides, icons]);

  useEffect(() => {
    if (size(icons) === 0 && endDate && !isLoading) {
      const formattedDate = getUnixTime(
        set(new Date(endDate * 1000), {
          hours: 0,
          minutes: 0,
          seconds: 0,
          milliseconds: 0,
        })
      );

      const index = findIndex(slides, (d) => d === formattedDate);

      if (swiperRef) {
        swiperRef.current?.slideTo(index - DAYS_PER_VIEW + 2);
      }
    }
  }, [endDate, icons, isLoading, slides]);

  const slideTo = (swiper: SwiperClass) => {
    if (!isNil(date)) {
      const index = findIndex(slides, (d) => d === date);
      swiper.slideTo(index - DAYS_PER_VIEW + 1);
    } else {
      swiper.slideTo(slides.length);
    }
  };

  const differenceStartDate = (d: number) =>
    !isNil(startDate) && startDate > 0
      ? differenceInDays(parseDate(startDate), parseDate(d))
      : null;

  const differenceEndDate = (d: number) =>
    !isNil(endDate) && endDate > 0
      ? differenceInDays(parseDate(d), parseDate(endDate))
      : null;

  const onBeforeTransitionStart = (swiper: SwiperClass) => {
    const activeIndex = swiper.activeIndex + DAYS_PER_VIEW - 2;
    const slide = slides[activeIndex];
    if (!isNil(slide)) {
      const diffStartDate = differenceStartDate(slide);
      const diffEndDate = differenceEndDate(slide);
      if (!isNil(diffStartDate) && diffStartDate > 0) {
        const startDateIndex = activeIndex + diffStartDate;
        const startDateSlide = slides[startDateIndex];

        if (!isNil(startDateSlide)) {
          onDateChange?.call(null, startDateSlide);
          swiper.slideTo(startDateIndex - DAYS_PER_VIEW + 2);
        }
      } else if (!isNil(diffEndDate) && diffEndDate > 0) {
        const endDateIndex = activeIndex - diffEndDate;
        const endDateSlide = slides[endDateIndex];
        if (!isNil(endDateSlide)) {
          onDateChange?.call(null, endDateSlide);
          swiper.slideTo(endDateIndex - DAYS_PER_VIEW + 2);
        }
      }
    }
  };

  const onTransitionEnd = (swiper: SwiperClass) => {
    const activeIndex = swiper.activeIndex + DAYS_PER_VIEW - 2;
    const slide = slides[activeIndex];
    if (!isNil(slide)) {
      onDateChange?.call(null, slide);
    }
  };

  const options = useMemo(
    () => ({
      slidesPerView: DAYS_PER_VIEW,
      spaceBetween: 0,
      navigation: false,
      virtual: {
        slides,
        enabled: true,
      },
      resizeObserver: true,
    }),
    [slides]
  );

  const swiperFn = useCallback((swiper: SwiperClass) => {
    swiperRef.current = swiper;
  }, []);

  return (
    <div className={classes.dateSlider}>
      <div className={classes.last} />
      <Swiper
        modules={[Virtual]}
        onSwiper={swiperFn}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...options}
        onInit={slideTo}
        onTransitionEnd={onTransitionEnd}
        onBeforeTransitionStart={onBeforeTransitionStart}
      >
        {map(options.virtual.slides, (slide, index) => {
          const slideDate = getDate(parseDate(slide));
          const slideIcon = find(icons, { date: slide });
          const diffStartDate = differenceStartDate(slide);
          const diffEndDate = differenceEndDate(slide);
          const isDisableRange =
            (!isNil(diffStartDate) && diffStartDate > 0) ||
            (!isNil(diffEndDate) && diffEndDate > 0);

          return (
            <SwiperSlide
              key={slide}
              className={classNames(classes.slide, {
                [classes.disable]: isDisableRange,
              })}
              virtualIndex={index}
            >
              <div className={classes.wrapper}>
                <div className={classes.iconsWrapper}>
                  {map(slideIcon?.icons, ({ icon, active }, idx) => (
                    <FontAwesomeIcon
                      key={idx}
                      icon={icon}
                      className={classNames(classes.iconGray, {
                        [classes.iconGreen]: active,
                      })}
                    />
                  ))}
                </div>
                <div
                  className={classNames(classes.vLine, {
                    [classes.vLineDark]: slideDate === 1,
                  })}
                />
                {(slideDate % 5 === 0 || slideDate === 1) && (
                  <div className={classes.day}>
                    {slideDate}
                    {(slideDate === 1 || slideDate === 15) && (
                      <div
                        className={classNames(classes.monthLabel, {
                          [classes.monthBeginning]: slideDate === 1,
                        })}
                      >
                        {formatDate(
                          slide,
                          DateFormatEnum.fullMonthPicker,
                          i18n.language
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default DateSlider;
