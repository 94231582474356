import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { find, isEmpty, map } from 'lodash';
import { IonItem } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { useFormik } from 'formik';
import { namespaces } from 'i18n/i18n.constants';
import * as yup from 'yup';
import useAddContact from 'api/contacts/useAddContact';
import useUpdateContact from 'api/contacts/useUpdateContact';
import useFindDataCodes from 'api/data/useFindDataCodes';
import type { ContactFormData } from 'models/Contact';
import type { SearchItemType, SnapshotsURLParams } from 'models/Search';
import Button from 'components/Button/Button';
import CheckBox from 'components/CheckBox/CheckBox';
import Input from 'components/Input/Input';
import Modal from 'components/Modal/Modal';
import Select from 'components/Select/Select';
import classes from './ContactModalV2.module.scss';

interface ContactModalV2Props {
  searchType: SearchItemType;
  miLoc?: string;
  data?: ContactFormData;
  showInvite?: boolean;
  inviteMiCom?: boolean;
  onSuccess?: () => void;
  onError?: () => void;
  testid: string;
}

const ContactModalV2 = ({
  searchType,
  miLoc: itemMiLoc,
  data,
  showInvite,
  inviteMiCom,
  isOpen,
  setIsOpen,
  title,
  onSuccess,
  onError,
  testid,
}: ContactModalV2Props &
  React.ComponentProps<typeof Modal> &
  IonicReactProps): JSX.Element => {
  const { miLoc: routeMiLoc, id } = useParams<SnapshotsURLParams>();
  const { t } = useTranslation(namespaces.contact);
  const miLoc = itemMiLoc || routeMiLoc;
  const initialFormData: ContactFormData = useMemo(
    () => ({
      sequenceNo: data?.sequenceNo || -1,
      name: data?.name,
      type: data?.type,
      phone: data?.phone,
      email: data?.email,
      isMainContact: data?.isMainContact,
      sendInvite: data?.sendInvite,
    }),
    [data]
  );

  const { data: codeList } = useFindDataCodes({
    codeType: searchType === 'customer' ? 'CUCONTYP' : 'CONTYPE',
  });
  const { status: addStatus, addContact } = useAddContact({
    searchType,
    miLoc,
    id,
  });
  const sequenceNo = data?.sequenceNo || -1;
  const { status: updateStatus, updateContact } = useUpdateContact({
    searchType,
    miLoc,
    id,
    sequenceNo,
  });

  const hasSequenceNo = sequenceNo !== -1;
  const status = hasSequenceNo ? updateStatus : addStatus;
  const selectOptions = map(codeList, ({ id: codeId, codeName }) => ({
    id: codeId,
    text: codeName,
  }));

  const validationSchema = yup.object().shape({
    name: yup.string().required(t('nameRequired')).max(25, t('nameMax')),
    type: yup.string().required(t('typeRequired')),
    phone: yup
      .string()
      .test('isValidPhoneNumber', t('phoneInvalid'), (value) => {
        let repValue = value || '';
        if (repValue) {
          repValue = repValue.replace(/[^0-9]/g, '');
        }
        return repValue.length === 10 || isEmpty(repValue);
      }),
    email: yup.string().email(t('invalidEmail')),
  });

  const {
    values,
    errors,
    handleSubmit,
    setFieldValue,
    setValues,
    setSubmitting,
    setErrors,
    validateField,
    isValid,
    isSubmitting,
  } = useFormik<ContactFormData>({
    initialValues: initialFormData,
    onSubmit: (formValues) => {
      const selectedType = find(selectOptions, { id: formValues.type });
      const contactBody = {
        name: formValues.name,
        type: formValues.type,
        typeDesc: selectedType?.text,
        phone: formValues.phone?.replace(/[^0-9]/g, ''),
        email: formValues.email,
        isMainContact: formValues.isMainContact ? 'Y' : 'N',
        sendInvite: formValues.sendInvite,
      };
      if (hasSequenceNo) {
        updateContact(contactBody);
      } else {
        addContact(contactBody);
      }
    },
    validationSchema,
    validateOnChange: false,
  });

  useEffect(() => {
    if (isOpen) {
      void setValues(initialFormData);
      void setErrors({});
    } else {
      setSubmitting(false);
    }
  }, [isOpen, initialFormData, setValues, setErrors, setSubmitting]);

  useEffect(() => {
    if (status === 'success') {
      setIsOpen?.(false);
      onSuccess?.();
    }
    if (status === 'error') {
      setSubmitting(false);
      onError?.();
    }
  }, [onError, onSuccess, setIsOpen, setSubmitting, status]);

  // TODO: fix usage of formik.dirty to get this status
  // TODO translation on checkboxes
  const allFieldsValues = values.name && values.type;

  return (
    <Modal
      className={classes.modal}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={title}
      footer={
        <div className={classes.buttonWrapper}>
          <Button
            className={classes.saveButton}
            variant="action"
            onClick={() => handleSubmit()}
            text={t('common:save')}
            disabled={!isValid || isSubmitting || !allFieldsValues}
            testid={`${testid}-save-button`}
          />
        </div>
      }
      testid={testid}
    >
      <form noValidate onSubmit={handleSubmit} data-testid={`${testid}-form`}>
        <Input
          className={classes.formItem}
          inputmode="text"
          label={t('common:name')}
          name="name"
          maxlength={25}
          value={values.name}
          error={errors.name}
          setValue={(e) => {
            void setFieldValue('name', e);
            setTimeout(() => {
              void validateField('name');
            }, 100);
          }}
          disabled={isSubmitting}
          testid={`${testid}-name-input`}
        />
        <Select
          className={classes.formItem}
          label={t('common:title')}
          name="type"
          options={selectOptions}
          value={values.type}
          error={errors.type}
          setValue={async (e) => {
            await setFieldValue('type', e);
            setTimeout(() => {
              void validateField('type');
            }, 0);
          }}
          disabled={isSubmitting}
          testid={`${testid}-title-input`}
        />
        <Input
          className={classes.formItem}
          label={t('common:phone')}
          name="phone"
          inputMode="tel"
          type="tel"
          mask="(000) 000-0000"
          value={values.phone}
          error={errors.phone}
          setValue={(e) => {
            void setFieldValue('phone', e);
            setTimeout(() => {
              void validateField('phone');
            }, 0);
          }}
          disabled={isSubmitting}
          testid={`${testid}-phone-input`}
        />
        <Input
          className={classes.formItem}
          inputmode="email"
          type="email"
          label={t('common:email')}
          name="email"
          value={values.email}
          error={errors.email}
          setValue={(e) => {
            void setFieldValue('email', e);
            setTimeout(() => {
              void validateField('email');
            }, 0);
          }}
          disabled={isSubmitting}
          testid={`${testid}-email-input`}
        />
        <IonItem className={classes.formItem}>
          <CheckBox
            ariaLabel="is main contact"
            name="isMainContact"
            checked={values.isMainContact}
            onChange={(checked) => setFieldValue('isMainContact', checked)}
            testid="make-main-contact-checbox"
            label={t('makeMainContact')}
            disabled={isSubmitting}
            className={classes.checkbox}
          />
        </IonItem>
        {showInvite && (
          <IonItem className={classes.formItem}>
            <CheckBox
              ariaLabel="send invite"
              className={classes.checkbox}
              name="sendInvite"
              checked={values.sendInvite}
              onChange={(checked) => setFieldValue('sendInvite', checked)}
              disabled={isSubmitting || !inviteMiCom}
              testid={`${testid}-invite-contact-input`}
              label={t('inviteMotion')}
            />
          </IonItem>
        )}
      </form>
    </Modal>
  );
};

export default ContactModalV2;
