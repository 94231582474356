import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { map } from 'lodash';
import { namespaces } from 'i18n/i18n.constants';
import type { DataCode } from 'models/DataCode';
import ActionRow from 'components/ActionRow/ActionRow';
import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';
import TextArea from 'components/TextArea/TextArea';
import classes from './RejectionModal.module.scss';

interface RejectionModalProps extends React.ComponentProps<typeof Modal> {
  reasons: DataCode[];
  onDone?: (codeID?: string, additionalRejectText?: string) => void;
}
const RejectionModal = ({
  reasons,
  isOpen,
  eyebrow,
  title,
  setIsOpen,
  onDone,
  className,
  testid,
}: RejectionModalProps): JSX.Element => {
  const { t } = useTranslation(namespaces.salesPlays);
  const [showReasonHolder, setShowReasonHolder] = useState(false);
  const [additionalRejectText, setAdditionalRejectText] = useState<string>();
  const [rejectionReason, setRejectionReason] = useState<string>();

  useEffect(() => {
    if (isOpen) {
      setAdditionalRejectText('');
    }
  }, [isOpen]);

  return (
    <>
      <Modal
        modalClassName={classes.modalWrapper}
        className={classNames(className, classes.modal)}
        headerClassName={classes.header}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        eyebrow={eyebrow || `${t('salesPlayTitle')}`}
        title={title || `${t('rejectionReason')}`}
        testid={`rejection-modal-${testid}`}
      >
        {map(reasons, ({ id, codeName, showRejectTextBox }) => (
          <ActionRow
            key={id}
            className={classes.row}
            leftButton={{
              variant: 'link',
              text: codeName,
              testid: `rejection-reason-${id}-left-button`,
            }}
            testid={`rejection-reason-${id}`}
            onClick={() => {
              if (showRejectTextBox) {
                setShowReasonHolder(true);
                setRejectionReason(id);
              } else {
                onDone?.call(null, id);
              }
              setIsOpen?.call(null, false);
            }}
          />
        ))}
      </Modal>
      <Modal
        className={classes.modal}
        isOpen={showReasonHolder}
        setIsOpen={setShowReasonHolder}
        title={t('otherReason')}
        footer={
          <div className={classes.buttons}>
            <Button
              className={classes.customDateButton}
              variant="action"
              text={t('common:save')}
              onClick={() => {
                onDone?.call(null, rejectionReason, additionalRejectText);
                setShowReasonHolder(false);
              }}
              testid="other-reason-button"
            />
          </div>
        }
        testid={testid}
      >
        <TextArea
          className={classes.messageInput}
          label={t('additionalRejectText')}
          name="text"
          value={additionalRejectText}
          setValue={(e) => {
            setAdditionalRejectText(e);
          }}
          testid="additional-reason-modal-text-input"
          maxlength={300}
        />
      </Modal>
    </>
  );
};

export default RejectionModal;
