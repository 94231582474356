import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { filter, find, includes, isNil, map, size } from 'lodash';
import { IonCol, IonImg, IonRow } from '@ionic/react';
import { namespaces } from 'i18n/i18n.constants';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import { useMiLocOrTeamId } from 'api/helpers';
import type { ImageUpload } from 'models/Attachment';
import type { ReportImage } from 'models/InspectionReport';
import { transformImage } from 'utils/helpers';
import { findIcon } from 'utils/icons';
import Button from 'components/Button/Button';
import ImageUploadModal from 'components/ImageUploadModal/ImageUploadModal';
import ConfirmDialog from 'components/Modals/ConfirmDialog/ConfirmDialog';
import Text from 'components/Text/Text';
import classes from './DocumentImage.module.scss';
import DocumentThumbnail from './DocumentThumbnail';

interface ImageItemProps {
  groupId: number;
  reportId: number;
  isPrimary: boolean;
  image: ReportImage;
  index: number;
  disabled?: boolean;
  needSyncImages?: number[];
  maxlength?: number;
  showCharCounter?: boolean;
  onRemove: (i: ReportImage, c: boolean) => void;
  onSetImageForUpload: (i: ImageUpload) => void;
}

const ImageItem = ({
  groupId,
  reportId,
  isPrimary,
  image,
  index,
  disabled,
  needSyncImages,
  maxlength,
  showCharCounter,
  onRemove,
  onSetImageForUpload,
}: ImageItemProps): JSX.Element => {
  const { isOnline } = useNetworkStatus();
  const { createParams } = useMiLocOrTeamId({ sendTeamId: false });
  const { miLoc } = createParams();

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [imageForUpload, setImageForUpload] = useState<ImageUpload | null>(
    null
  );
  const [imageForHighResolution, setImageForHighResolution] =
    useState<ReportImage | null>(null);

  const onImageClick = (img: ReportImage, data?: string) => {
    if (img && !img.entity) {
      setImageForHighResolution(null);
    } else {
      setImageForHighResolution(img);
    }
    setImageForUpload(
      transformImage(
        img,
        miLoc,
        data,
        groupId,
        isPrimary,
        reportId
      ) as ImageUpload
    );
    setIsUploadModalOpen(true);
  };

  const getSrc = (img: ReportImage) => (img.image ? `${img.image}` : '');

  return (
    <IonRow className={classes.imageRow} key={index}>
      <IonCol className={classes.imageCol} size="4">
        <Button
          testid="remove-image-button"
          disabled={disabled}
          icon={findIcon('times')}
          className={classes.closeIcon}
          onClick={() => onRemove(image, true)}
        />
        {image.entity && !image.image && isOnline && (
          <DocumentThumbnail image={image} onImageClick={onImageClick} />
        )}
        {(!image.entity || (image.entity && image.image)) && (
          <IonImg
            className={classes.thumbnail}
            src={getSrc(image)}
            onClick={() => onImageClick(image)}
          />
        )}
        {!image.image && !isOnline && (
          <div className={classes.offlinePlaceholder}>
            <Text variant="label-micro" text="Image Unavailable Offline" />
          </div>
        )}
      </IonCol>
      <IonCol className={classes.captionCol} size="8">
        {image.internalOnly === 'Y' && (
          <Text
            className={classes.internalBadge}
            variant="label-micro"
            text="Internal Only"
          />
        )}
        <div className={classes.captionTitle}>
          <Text
            className={classes.captionText}
            text={image.caption}
            variant="content-default"
          />
          {includes(needSyncImages, image.imageId) && (
            <Text
              className={classes.needSyncBadge}
              variant="label-micro"
              text="Pending"
            />
          )}
        </div>
      </IonCol>
      <ImageUploadModal
        enableCaptions
        enableInternalOnly
        hideButton
        editable
        isOpen={isUploadModalOpen}
        setIsOpen={setIsUploadModalOpen}
        disabled={disabled}
        image={imageForUpload}
        onSetImage={onSetImageForUpload}
        onRemove={() => onRemove(image, false)}
        testid="image-upload-modal"
        maxlength={maxlength}
        showCharCounter={showCharCounter}
        imageForFetching={imageForHighResolution}
        isPrimary={isPrimary}
      />
    </IonRow>
  );
};

interface DocumentImageProps {
  groupId: number;
  reportId: number;
  objectId?: number;
  disabled?: boolean;
  imageValues?: ReportImage[];
  needSyncImages?: number[];
  setHasImageData?: (hasData: boolean) => void;
  imagesForUpload?: ReportImage[];
  setImagesForUpload?: (images: ReportImage[]) => void;
  imagesForRemoval?: ReportImage[];
  setImagesForRemoval?: (images: ReportImage[]) => void;
  isPrimary?: boolean;
  captionLength?: number;
  captionShowCharCounter?: boolean;
  noPadding?: boolean;
}

const DocumentImage = ({
  groupId,
  reportId,
  objectId,
  disabled,
  imageValues,
  needSyncImages,
  setHasImageData,
  imagesForUpload,
  setImagesForUpload,
  imagesForRemoval,
  setImagesForRemoval,
  isPrimary,
  captionLength,
  captionShowCharCounter,
  noPadding,
}: DocumentImageProps): JSX.Element => {
  const { t } = useTranslation(namespaces.images);
  const [imageForUpload, setImageForUpload] = useState<ImageUpload | null>(
    null
  );
  const [imageForRemoval, setImageForRemoval] = useState<ReportImage | null>(
    null
  );
  const [images, setImages] = useState<ReportImage[]>(imageValues || []);

  useEffect(() => {
    if (imageValues) {
      setImages(imageValues);
    }
  }, [imageValues]);

  const [imageRemoveConfirmDialogIsOpen, setImageRemoveConfirmDialogIsOpen] =
    useState(false);

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const { createParams } = useMiLocOrTeamId({ sendTeamId: false });
  const { miLoc } = createParams();

  const removeImage = (img: ReportImage) => {
    // 1) check imagesForUpload, remove if necessary, set hasData
    // 2) check images, remove if necessary, set hasData
    // 3) if img came from db, check imagesForRemoval, remove if necessary and re-add
    let isInImagesForUpload = false;
    let isInImages = false;
    let hasData = false;
    const newImagesForUpload = filter(
      imagesForUpload,
      (curImage: ReportImage) => {
        if (curImage.image && curImage.imageId === 0) {
          if (curImage.image !== img.image) {
            return true;
          }
        } else if (curImage.imageId !== 0) {
          if (curImage.imageId !== img.imageId) {
            return true;
          }
        }
        // we only get here if everything matches,
        // meaning it is in imagesForUpload and
        // needs to be filtered out (only adds elements that return true)
        isInImagesForUpload = true;
        return false;
      }
    );

    if (isInImagesForUpload) {
      setImagesForUpload?.(newImagesForUpload);

      if (newImagesForUpload.length > 0) {
        hasData = true;
      }
    } else {
      const newImages = filter(images, (curImage: ReportImage) => {
        if (curImage.imageId !== img.imageId) {
          return true;
        }
        isInImages = true;
        return false;
      });
      if (isInImages) {
        setImages(newImages);
      }
      if (newImages.length > 0) {
        hasData = true;
      }
    }
    setHasImageData?.(hasData);

    // only add to imagesForRemoval if it was an image
    // that came from the db (meaning imageId !== 0)
    if (img.imageId !== 0) {
      const newImagesForRemoval = filter(
        imagesForRemoval,
        (curImage: ReportImage) => {
          if (curImage.image && curImage.imageId === 0) {
            if (curImage.image !== img.image) {
              return true;
            }
          } else if (curImage.imageId !== 0) {
            if (curImage.imageId !== img.imageId) {
              return true;
            }
          }
          return false;
        }
      );
      newImagesForRemoval.push(img);
      setImagesForRemoval?.(newImagesForRemoval);
    }
  };

  const onImageRemoveConfirmDialogYes = () => {
    setImageRemoveConfirmDialogIsOpen(false);
    removeImage(imageForRemoval as ReportImage);
  };

  const onImageRemoveConfirmDialogNo = () => {
    setImageRemoveConfirmDialogIsOpen(false);
  };

  const onSetImageForUpload = (imageForUploadCopy: ImageUpload) => {
    if (imageForUploadCopy) {
      const newImage: ReportImage = {
        caption: imageForUploadCopy.caption || '',
        internalOnly: imageForUploadCopy.internalOnly,
        displaySequence: 0,
        imagePath: imageForUploadCopy.fileName,
        groupId,
        imageId: imageForUploadCopy?.imageId || -1 * Date.now(),
        // TODO: use new column instead of inputId
        inputId: imageForUploadCopy?.inputId || 0,
        objectId: objectId || 0,
        primaryImage: isPrimary ? 'Y' : 'N',
        image: imageForUploadCopy.webPath,
        miLoc,
        reportId,
        saveNFS: true,
      };

      // 1) check imagesForUpload, remove if necessary and re-add
      // 2) check images, remove if necessary and add to imagesForUpload
      // 3) if in neither, add to imagesForUpload
      let isInImagesForUpload = false;
      let isInImages = false;
      const mergedImagesForUpload = filter(
        imagesForUpload,
        (curImage: ReportImage) => {
          if (curImage.image && curImage.imageId === 0) {
            if (curImage.image !== newImage.image) {
              return true;
            }
          } else if (curImage.imageId !== 0) {
            if (curImage.imageId !== newImage.imageId) {
              return true;
            }
          }
          // we only get here if everything matches,
          // meaning it is in imagesForUpload and
          // needs to be filtered out (only adds elements that return true)
          isInImagesForUpload = true;
          return false;
        }
      );
      if (isInImagesForUpload) {
        mergedImagesForUpload.push(newImage);
        setImagesForUpload?.(mergedImagesForUpload);
      } else {
        const newImages = filter(images, (curImage: ReportImage) => {
          if (curImage.imageId === newImage.imageId) {
            isInImages = true;
            return false;
          }
          return true;
        });
        if (isInImages) {
          const newImagesForUpload = [...(imagesForUpload || [])];
          newImagesForUpload.push(newImage);
          setImagesForUpload?.(newImagesForUpload);
          setImages(newImages);
        }
      }
      if (!isInImagesForUpload && !isInImages) {
        const addImagesForUpload = [...(imagesForUpload || [])];
        addImagesForUpload.push(newImage);
        setImagesForUpload?.(addImagesForUpload);
        setImageForUpload(null);
      }
    }
  };

  useEffect(() => {
    if (size(imagesForRemoval) > 0) {
      const filteredImages = filter(
        imagesForUpload,
        (i) => !find(imagesForRemoval, { imageId: i.imageId })
      );
      setImagesForUpload?.(filteredImages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imagesForRemoval]);

  const renderImages = filter(
    images,
    (img) => !find(imagesForRemoval, { imageId: img.imageId })
  );

  return (
    <div
      className={classNames(classes.documentImageContainer, {
        [classes.noPadding]: noPadding,
      })}
    >
      {!isNil(imageValues) && (
        <>
          <IonRow className={classes.docImageRow}>
            <IonCol className={classes.addImagesCol} size="4">
              <ImageUploadModal
                enableCaptions
                enableInternalOnly
                editable
                isOpen={isUploadModalOpen}
                setIsOpen={setIsUploadModalOpen}
                disabled={disabled}
                image={imageForUpload}
                onSetImage={onSetImageForUpload}
                testid="image-upload-modal"
                maxlength={captionLength}
                showCharCounter={captionShowCharCounter}
                isPrimary={isPrimary}
              />
            </IonCol>
          </IonRow>
          <div>
            {map(
              [
                ...filter(
                  renderImages,
                  (i) => !find(imagesForUpload, { imageId: i.imageId })
                ),
                ...(imagesForUpload || []),
              ],
              (i, index) => (
                <ImageItem
                  key={index}
                  groupId={groupId}
                  reportId={reportId}
                  isPrimary={!!isPrimary}
                  image={i}
                  index={index}
                  disabled={disabled}
                  needSyncImages={needSyncImages}
                  maxlength={captionLength}
                  showCharCounter={captionShowCharCounter}
                  onRemove={(img, confirm) => {
                    if (confirm) {
                      setImageForRemoval(img);
                      queueMicrotask(() => {
                        setImageRemoveConfirmDialogIsOpen(true);
                      });
                    } else {
                      removeImage(img);
                    }
                  }}
                  onSetImageForUpload={onSetImageForUpload}
                />
              )
            )}
          </div>
          <ConfirmDialog
            isOpen={imageRemoveConfirmDialogIsOpen}
            setIsOpen={setImageRemoveConfirmDialogIsOpen}
            title={t('removeImageTitle')}
            text={t('removeImageMessage')}
            primaryText={t('removeImageNo')}
            secondaryText={t('removeImageYes')}
            onPrimaryClick={onImageRemoveConfirmDialogNo}
            onSecondaryClick={onImageRemoveConfirmDialogYes}
            testid="remove-document-image-modal"
          />
        </>
      )}
    </div>
  );
};

export default DocumentImage;
