import React, { useState } from 'react';
import classNames from 'classnames';
import { toNumber } from 'lodash';
import { IonRow } from '@ionic/react';
import { DataTypeEnum } from 'common/utils/valueFormatter';
import type { ItemPOU } from 'StoreroomsApp/models/ItemPOU';
import { findIcon } from 'utils/icons';
import ActionRow from 'components/ActionRow/ActionRow';
import type { ActivityModalData } from 'components/Activities/ActionCardModal/ActionCardModalData';
import ActionCardModalData from 'components/Activities/ActionCardModal/ActionCardModalData';
import Button from 'components/Button/Button';
import Text from 'components/Text/Text';
import AddItemModal from './AddItemModal';
import classes from './ItemsPOUList.module.scss';

const replenishmentItemDefinition: ActivityModalData = {
  pouStoreroom: { label: 'Storeroom Number', type: DataTypeEnum.string },
  pouMino: {
    label: 'Mino',
    type: DataTypeEnum.string,
  },
  pouItemNumber: { label: 'Item Number', type: DataTypeEnum.string },
  pouCustomerNumber: { label: 'Customer Number', type: DataTypeEnum.string },
  pouMeasureUnit: { label: 'Unit of Measure', type: DataTypeEnum.string },
};

interface ItemCardProps {
  searchQuery: string;
  itemPOU: ItemPOU;
  loading?: boolean;
  isPendingIssue?: boolean;
  onAddItem?: (v?: number) => void;
}

const ItemCard = ({
  searchQuery,
  itemPOU,
  loading,
  isPendingIssue,
  onAddItem,
}: ItemCardProps): JSX.Element => {
  const {
    customerStockNumber,
    itemDescription,
    binLocation,
    balanceOnHandQuantity,
    alreadyAddedToReplenishment,
    alreadyAddedToIssue,
  } = itemPOU;
  const [expanded, setExpanded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const hasItemsAdded = isPendingIssue
    ? toNumber(alreadyAddedToIssue) > 0
    : toNumber(alreadyAddedToReplenishment) > 0;

  const addButtonProps = {
    onClick: (e: React.MouseEvent) => {
      e.stopPropagation();
      if (hasItemsAdded || loading) {
        return;
      }
      if (isPendingIssue) {
        setIsOpen(true);
      } else {
        onAddItem?.();
      }
    },
    icon: hasItemsAdded ? findIcon('check') : undefined,
    testid: 'add-item-pou-button',
  };

  return (
    <ActionRow
      className={classNames(classes.item, {
        [classes.selectedItem]: hasItemsAdded,
      })}
      onClick={() => setExpanded((prev) => !prev)}
      testid={`item-pou-${customerStockNumber}`}
    >
      <div className={classes.wrapper}>
        <IonRow>
          {!isPendingIssue && (
            <Button
              className={classNames(classes.addButton, {
                [classes.disableButton]: loading,
              })}
              variant="link"
              text={hasItemsAdded ? '' : 'Add'}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...addButtonProps}
            />
          )}
          <div className={classes.inner}>
            <div className={classes.titleRow}>
              <Text
                className={classes.title}
                text={`${customerStockNumber || '<No CSN>'}`}
                textQuery={searchQuery}
                variant="content-heavy"
                testid="item-pou-text"
              />
              {isPendingIssue && <Text text={balanceOnHandQuantity} />}
            </div>
            <div>
              <Text
                className={classes.description}
                textQuery={searchQuery}
                text={itemDescription}
                variant="content-small"
              />
            </div>
            <div>
              <Text textQuery={searchQuery} text={binLocation} />
            </div>
          </div>
          {isPendingIssue && (
            <Button
              className={classNames(classes.selectButton, {
                [classes.disableButton]: loading,
              })}
              variant="action"
              text={hasItemsAdded ? '' : 'Select'}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...addButtonProps}
            />
          )}
          {isPendingIssue && (
            <AddItemModal
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              itemPOU={itemPOU}
              searchQuery={searchQuery}
              onAdd={(v) => onAddItem?.(v)}
              testid="add-product-modal"
            />
          )}
        </IonRow>
        {!isPendingIssue && expanded && (
          <div>
            <ActionCardModalData
              className={classes.details}
              rowClassName={classes.detailRow}
              dataDefinition={replenishmentItemDefinition}
              activityData={{
                pouStoreroom: itemPOU.storeroomNumber,
                pouMino: itemPOU.mino,
                pouItemNumber: itemPOU.itemNumber,
                pouCustomerNumber: itemPOU.customerNumber,
                pouMeasureUnit: itemPOU.unitOfMeasure,
              }}
            />
          </div>
        )}
        {!isPendingIssue && (
          <Button
            className={classes.detailsButton}
            variant="link"
            text={expanded ? 'Hide Details' : 'Show Details'}
            testid="details-button"
          />
        )}
      </div>
    </ActionRow>
  );
};

export default ItemCard;
