import React, { useRef } from 'react';
import classNames from 'classnames';
import { forEach, isEmpty } from 'lodash';
import type { OverlayEventDetail } from '@ionic/core';
import { IonContent, IonHeader, IonModal, IonRow } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import Footer from 'common/components/Footer/Footer';
import type { OptionalComponentProps } from 'common/components/utils/renderHelpers';
import { findIcon } from 'utils/icons';
import Button from 'components/Button/Button';
import type { TextVariantType } from 'components/Text/Text';
import Text from 'components/Text/Text';
import classes from './SheetModal.module.scss';

interface SheetModalProps {
  title?: string;
  titleTextVariant?: TextVariantType;
  titleClassName?: string;
  isOpen: boolean;
  backdropDismiss?: boolean;
  withCloseButton?: boolean;
  withRightCloseButton?: boolean;
  setIsOpen?: (b: boolean) => void;
  onClose?: () => void;
  headerClassName?: string;
  customHeader?: React.ReactNode;
  customTitle?: React.ReactNode;
  testid: string;
  contentClass?: string;
  footerButtons?: OptionalComponentProps<React.ComponentProps<typeof Button>[]>;
}

const SheetModal = ({
  title,
  titleTextVariant,
  titleClassName,
  isOpen,
  setIsOpen,
  onClose,
  children,
  className,
  contentClass,
  headerClassName,
  customHeader,
  backdropDismiss = true,
  customTitle,
  withCloseButton = true,
  withRightCloseButton = false,
  initialBreakpoint = 0.5,
  testid,
  footerButtons,
  onWillPresent,
}: SheetModalProps &
  IonicReactProps &
  React.ComponentProps<typeof IonModal>): JSX.Element => {
  const modal = useRef<HTMLIonModalElement>(null);

  const removeScrollShadow = () =>
    forEach(
      modal.current?.shadowRoot?.querySelectorAll('#scroll-shadow'),
      (i) => i.remove()
    );

  const CloseButton = (
    <Button
      className={classes.closeBtn}
      icon={findIcon('times')}
      onClick={() => {
        onClose?.();
        setIsOpen?.(false);
      }}
      testid="close-button"
    />
  );

  return (
    <IonModal
      className={classNames(classes.modal, className)}
      ref={modal}
      isOpen={isOpen}
      initialBreakpoint={initialBreakpoint}
      breakpoints={[0, 0.25, 0.5, 0.75, 1]}
      onDidDismiss={(e: CustomEvent<OverlayEventDetail>) => {
        // validation for failing tests as we can't mock the close event detail
        if (e?.detail.role === 'backdrop') {
          onClose?.();
        }
        setIsOpen?.(false);
      }}
      onWillDismiss={() => removeScrollShadow()}
      backdropDismiss={backdropDismiss}
      onWillPresent={(e) => {
        removeScrollShadow();
        if (isEmpty(footerButtons)) {
          const bgDiv = document.createElement('div');
          bgDiv.id = 'scroll-shadow';
          bgDiv.style.zIndex = '11';
          bgDiv.style.width = '100%';
          bgDiv.style.height = '50px';
          bgDiv.style.position = 'absolute';
          bgDiv.style.left = '0';
          bgDiv.style.bottom = '0';
          bgDiv.style.pointerEvents = 'none';
          bgDiv.style.background =
            'linear-gradient(to top, var(--ion-background-color), #f5f5f500)';
          modal.current?.shadowRoot?.append(bgDiv);
        }
        onWillPresent?.(e);
      }}
      data-testid={testid}
    >
      <IonHeader>
        <IonRow className={classNames(classes.headerRow, headerClassName)}>
          <div>{withCloseButton && !withRightCloseButton && CloseButton}</div>
          {customTitle}
          {title && (
            <Text
              className={classNames(classes.title, titleClassName)}
              variant={titleTextVariant || 'mipro-h1-headline'}
              text={title}
              testid={`${testid}-text`}
            />
          )}
          <div>
            {withCloseButton && withRightCloseButton && CloseButton}
            {customHeader}
          </div>
        </IonRow>
      </IonHeader>
      <IonContent className={classNames(classes.content, contentClass)}>
        {children}
      </IonContent>
      {!isEmpty(footerButtons) && <Footer buttons={footerButtons} />}
    </IonModal>
  );
};

export default SheetModal;
