import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SignatureCanvas from 'react-signature-canvas';
import type ReactSignatureCanvas from 'react-signature-canvas';
import { map, type Dictionary } from 'lodash';
import { IonRow } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import CostSavingsInput from 'CostSavingsApp/components/CostSavingsForm/CostSavingsInput';
import type {
  CostSavingsEntryForm,
  CostSavingsField,
} from 'CostSavingsApp/models/CostSavings';
import Button from 'components/Button/Button';
import Text from 'components/Text/Text';
import classes from './CostSavingsSignature.module.scss';

interface CostSavingsSignatureProps {
  signatureFields?: CostSavingsField[];
  formData: CostSavingsEntryForm;
  formErrors: Dictionary<unknown>;
  setFieldValue: (n: string, v: unknown) => void;
  setFieldError: (n: string, v: string) => void;
  disabled: boolean;
}

const CostSavingsSignature = ({
  signatureFields,
  formData,
  formErrors,
  setFieldValue,
  setFieldError,
  disabled,
}: CostSavingsSignatureProps & IonicReactProps): JSX.Element => {
  const { t } = useTranslation();
  const divRef = useRef<HTMLDivElement>(null);
  const signatureCanvas = useRef<ReactSignatureCanvas>(null);
  const [showPlaceholder, setShowPlaceholder] = useState(true);

  useEffect(() => {
    return () => {
      setFieldValue('signature', '');
      setFieldValue('signatureName', '');
      setFieldValue('signatureAgreement', '');
    };
  }, [setFieldError, setFieldValue]);

  const clearSignature = () => {
    if (signatureCanvas && signatureCanvas.current) {
      signatureCanvas.current.clear();
      setFieldValue('signature', '');
      setShowPlaceholder(true);
    }
  };

  return (
    <div>
      <IonRow className={classes.rowHeading}>
        <Text
          text="Customer Signature"
          variant="title-card-red"
          className={classes.textColor}
          testid="customer-signature-title"
        />
        <Button
          variant="link"
          className={classes.clearButton}
          text="Clear"
          testid="clear-signature"
          onClick={clearSignature}
        />
      </IonRow>
      <div className={classes.signatureWrapper}>
        {!formData.signature && showPlaceholder && (
          <div className={classes.signaturePlaceholder}>
            <Text text={t('costSavings:signHere')} />
          </div>
        )}
        <SignatureCanvas
          penColor="black"
          canvasProps={{ className: classes.signatureCanvas }}
          ref={signatureCanvas}
          clearOnResize={false}
          onBegin={() => setShowPlaceholder(false)}
          onEnd={() => {
            setFieldValue('signature', signatureCanvas?.current?.toDataURL());
          }}
        />
      </div>
      <div ref={divRef}>
        {map(signatureFields, (field) => (
          <CostSavingsInput
            key={field.columnName}
            formData={formData}
            formErrors={formErrors}
            setFieldValue={setFieldValue}
            setFieldError={setFieldError}
            field={field}
            disabled={disabled}
            testid={`${field.columnName}-cs-input`}
            parentRef={divRef}
            disableAutoScroll={false}
          />
        ))}
      </div>
    </div>
  );
};

export default CostSavingsSignature;
