import React from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty, toString } from 'lodash';
import { useScrollIntoFieldError } from 'common/utils/formHelpers';
import { useFormikContext } from 'formik';
import ErrorList from 'ProductSearchApp/components/ErrorList/ErrorList';
import OrderAttachments from 'ProductSearchApp/components/OrderAttachments/OrderAttachments';
import OrderNotes from 'ProductSearchApp/components/OrderNotes/OrderNotes';
import { OcnTypeEnum } from 'ProductSearchApp/models/Order';
import type { CheckoutOrderForm, Order } from 'ProductSearchApp/models/Order';
import classes from './CheckoutForm.module.scss';
import ContactInfo from './ContactInfo';
import EmailInfo from './EmailInfo';
import OrderInfo from './OrderInfo';
import ShippingInfo from './ShipperInfo';
import TransmitInfo from './TransmitInfo';

interface CheckoutFormInterface {
  order?: Order;
  miLoc: string;
  customerNo: string;
  ocn: string;
  disabled?: boolean;
  ocnType: OcnTypeEnum;
  ocnMode: 'review' | 'checkout';
}

const CheckoutForm = ({
  order,
  disabled,
  miLoc,
  customerNo,
  ocnType,
  ocnMode,
  ocn,
}: CheckoutFormInterface): JSX.Element => {
  const headerErrorList = order?.headerErrorList;
  const namespace = 'productSearch:checkout';
  const { t } = useTranslation();
  const showHeaderErrors =
    !isEmpty(headerErrorList) && ocnType === OcnTypeEnum.order;

  const { isSubmitting } = useFormikContext<CheckoutOrderForm>();

  useScrollIntoFieldError('order-checkout-form', isSubmitting);

  return (
    <div data-testid="order-checkout-form">
      {showHeaderErrors && (
        <div className={classes.cardWrapper}>
          <ErrorList
            errorList={headerErrorList}
            className={classes.errorList}
            title={t(`${namespace}:fixErrorTitle`)}
          />
        </div>
      )}
      <ContactInfo miLoc={miLoc} customerNo={customerNo} disabled={disabled} />
      <OrderInfo
        reOrderReminder={toString(order?.reOrderReminder)}
        disabled={disabled}
        ocnType={ocnType}
      />
      <ShippingInfo order={order} disabled={disabled} ocnType={ocnType} />
      <OrderNotes
        customerNo={customerNo}
        ocnType={ocnType}
        ocnMode={ocnMode}
        miLoc={miLoc}
        orderCtlNo={ocn}
        viewMode={false}
      />
      <OrderAttachments miLoc={miLoc} orderCtlNo={ocn} viewMode={false} />
      <TransmitInfo disabled={disabled} />
      <EmailInfo
        miLoc={miLoc}
        customerNo={customerNo}
        disabled={disabled}
        ocnType={ocnType}
      />
    </div>
  );
};

export default CheckoutForm;
