import { useMemo } from 'react';
import type { AxiosError } from 'axios';
import { size, get, flatMap, head } from 'lodash';
import type {
  InfiniteData,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import type {
  QueryFlags,
  QueryFnProps,
  QueryParamsType,
} from 'common/api/utils/useGetQueryFlags';
import useGetQueryFlags from 'common/api/utils/useGetQueryFlags';
import { and, choose, or } from 'common/utils/logicHelpers';
import { useGetUserInfo } from 'common/utils/userInfo';
import { useKeyUserId } from 'api/helpers';
import { pageSize } from 'utils/constants';

type UseQuerySetupProps<APIDataType = unknown> = {
  queryKey: string;
  queryParams?: QueryParamsType;
  onAPIRequest: (props: QueryFnProps) => Promise<APIDataType>;
  options?: UseQueryOptions<APIDataType, AxiosError> &
    UseInfiniteQueryOptions<APIDataType, AxiosError>;
  dataPath?: string;
  totalDataPath?: string;
  limit?: number;
  enabled?: boolean;
  enabledWithUserId?: boolean;
  isInfiniteQuery?: boolean;
};

type UseQuerySetupResponse<DataType = unknown, APIDataType = DataType> = {
  items: DataType[];
  totalItems?: number;
  data?: APIDataType | InfiniteData<APIDataType>;
};

function useQuerySetup<DataType = unknown, APIDataType = DataType>({
  queryKey,
  queryParams,
  onAPIRequest,
  options,
  dataPath = '',
  totalDataPath = '',
  limit = pageSize(),
  enabled,
  enabledWithUserId = true,
  isInfiniteQuery = false,
}: UseQuerySetupProps<APIDataType>): UseQuerySetupResponse<
  DataType,
  APIDataType
> &
  QueryFlags<DataType> {
  const { createQueryKey } = useKeyUserId();
  const { userId } = useGetUserInfo();
  const queryEnabled = and(or(!enabledWithUserId, !!userId), enabled);

  const response = choose<
    UseInfiniteQueryResult<APIDataType, AxiosError>,
    UseQueryResult<APIDataType, AxiosError>
  >(
    isInfiniteQuery,
    useInfiniteQuery<APIDataType, AxiosError>(
      createQueryKey(queryKey, queryParams),
      onAPIRequest,
      {
        enabled: and(isInfiniteQuery, queryEnabled),
        getNextPageParam: (lastPage, pages) =>
          size(get(lastPage, dataPath) as DataType[]) < limit
            ? false
            : size(pages) + 1,
        ...options,
      }
    ),
    useQuery<APIDataType, AxiosError>(
      createQueryKey(queryKey, queryParams),
      onAPIRequest,
      { enabled: and(!isInfiniteQuery, queryEnabled), ...options }
    )
  );

  const data = response?.data;
  const dataPages = (data as InfiniteData<APIDataType>)?.pages;
  const items = useMemo(
    () => flatMap(dataPages, (page) => get(page, dataPath) as DataType[]),
    [dataPages, dataPath]
  );

  const queryFlags = useGetQueryFlags<DataType>({
    ...response,
    data: items,
    enabled: queryEnabled,
  });

  return {
    ...queryFlags,
    items,
    totalItems: get(head(dataPages), totalDataPath) as number,
    data,
  };
}

export default useQuerySetup;
