import React from 'react';
import { find, toNumber, head, toString, trim } from 'lodash';
import NewContactCustomerForm from 'ActivitiesApp/components/NewContactCustomer/NewContactCustomer';
import {
  CustomerAccountTypeEnum,
  CustomerAccountIconEnum,
  CustomerAccountColorEnum,
  CustomerAccountFilterEnum,
} from 'ActivitiesApp/models/CustomerAccount';
import {
  getActivityDataValue,
  noActivityData,
} from 'ActivitiesApp/utils/helpers';
import { formatNumber } from 'common/utils/numberHelper';
import { DataTypeEnum } from 'common/utils/valueFormatter';
import type { Contact, Recipient } from 'models/Contact';
import { DateFormatEnum, formatDate } from 'utils/date';
import generateEmailList from 'utils/generateEmailList';
import classes from 'pages/Activities/ActivityActionCard/ActivityActionCard.module.scss';
import type {
  ActionCardConfig,
  ActivityTypeConfig,
  GetActivityConfigExtendedProps,
  GetActivityTypeDataProps,
} from 'pages/Activities/ActivityActionCard/ActivityCardConfig';
import AccountsReceivableCard from 'pages/Snapshots/AccountsReceivableCard';
import type { ActivityModalData } from 'components/Activities/ActionCardModal/ActionCardModalData';
import ActionCardModalData from 'components/Activities/ActionCardModal/ActionCardModalData';
import Text from 'components/Text/Text';

const atRiskCustomerActivityDefinition: ActivityModalData = {
  empDisplayName: { label: 'snapshot:accountRep', type: DataTypeEnum.contact },
  priorYoYSales: { label: 'activities:priorYear', type: DataTypeEnum.currency },
  currentYearlySales: {
    label: 'activities:ytdSales',
    type: DataTypeEnum.currency,
  },
  yoySalesPct: { label: 'activities:yoySales', type: DataTypeEnum.percent },
};

export const getCustomerAccountConfig = ({
  activityVersion,
  activityData,
  codeList,
  defaultFirstLine,
  t,
}: GetActivityConfigExtendedProps): ActionCardConfig | undefined => {
  const { fieldsData, extendedInfo, currency } = activityData;
  const orderNumber = getActivityDataValue(fieldsData?.ORDER_CTL_NO);
  const orderLines = getActivityDataValue(extendedInfo?.lines);
  let totalOrderAmount: string | number = getActivityDataValue(
    fieldsData?.TOTAL_ORDER_AMT
  );
  let formattedTotalAmount = noActivityData;
  if (totalOrderAmount !== noActivityData) {
    totalOrderAmount = toNumber(totalOrderAmount);
    formattedTotalAmount = formatNumber({
      number: toNumber(totalOrderAmount),
      scale: 2,
      currencyType: currency,
    });
  }
  const activityDate = activityData.creationTimestampISO;
  const custPONumber = getActivityDataValue(extendedInfo?.custPONo);
  const extOrderTypeCd = toString(extendedInfo?.orderTypeCd);
  const fldsOrderTypeCd = toString(fieldsData?.ORDER_TYPE_CD);
  const orderType =
    (extOrderTypeCd || fldsOrderTypeCd) === 'O' ? 'order' : 'quote';
  const daysAtRisk = toString(fieldsData?.DAYS_AT_RISK);
  const roleTypeId = toString(fieldsData?.CONTACT_TYPE);
  const role = toString(find(codeList, { id: roleTypeId })?.text);
  const newContactName = toString(fieldsData?.CONTACT_NAME);
  const inactiveDays = toString(fieldsData?.INACTIVE_DAYS) || 'at least 30';

  const ocnContacts: Recipient[] = generateEmailList({
    name: trim(
      toString(extendedInfo?.ocnContactName || extendedInfo?.empDisplayName)
    ),
    rawEmailList: trim(
      toString(extendedInfo?.ocnContactEmail || extendedInfo?.empEmail)
    ),
    phone: toString(
      extendedInfo?.ocnContactPhone || extendedInfo?.empTelephone
    ),
    splitChar: ';',
  });
  const ocnContact = head(ocnContacts) as Contact;
  const ocnContactName = getActivityDataValue(
    ocnContact?.name || ocnContact?.email || ocnContact?.phone
  );
  const emailContact = ocnContact;
  const emailContactName = emailContact?.name || emailContact?.email;

  const customerName = getActivityDataValue(
    activityData.customerName ||
      fieldsData?.['Customer Name'] ||
      fieldsData?.CUSTOMER_NAME
  );

  const empDisplayName = getActivityDataValue(extendedInfo?.empDisplayName);
  const priorYoYSales = getActivityDataValue(extendedInfo?.priorYoYSales);
  const currentYearlySales = getActivityDataValue(
    extendedInfo?.currentYearlySales
  );
  const yoySalesPct = getActivityDataValue(extendedInfo?.yoySalesPct);
  const atRiskCustomerData = {
    emailContact,
    emailContactName,
    empDisplayName,
    priorYoYSales,
    currentYearlySales,
    yoySalesPct,
  };

  switch (activityVersion) {
    case `${CustomerAccountTypeEnum.reengagedCustomerAccount}-1`:
    case `${CustomerAccountTypeEnum.reengagedCustomerAccount}-2`:
      return {
        card: {
          defaultTitle: t('reengagedCustomerAccount'),
          body: {
            description: t('reengagedCustomerAccountLabel', {
              orderType,
              inactiveDays,
            }),
            lines: [
              ...defaultFirstLine,
              {
                description: t('reengagedCustomerAccountDescription', {
                  inactiveDays,
                }),
                highlight: t('reengagedCustomerAccountHighlight', {
                  inactiveDays,
                }),
              },
              {
                description: t('reengagedCustomerAccountDescription2', {
                  orderType,
                  formattedTotalAmount,
                }),
                highlight: t('reengagedCustomerAccountHighlight2', {
                  orderType,
                }),
              },
            ],
          },
          highlight: [{ query: inactiveDays }],
        },
        modal: {
          title: t('reengagedCustomerAccount'),
          refetchActivity: true,
          contactEmail: ocnContacts,
          defaultData: {
            orderNumber,
            lines: orderLines,
            custPONumber,
            total: totalOrderAmount,
            ocnContactName,
            created: formatDate(activityDate, DateFormatEnum.standardDate),
            orderTypeCD: orderType,
            emailContactName,
            emailContact,
          },
          customHeader: () => (
            <Text
              text={t('reengagedCustomerAccountModalDesc', {
                orderType,
                inactiveDays,
              })}
              textQuery={inactiveDays}
              testid="reengaged-customer-desc"
            />
          ),
        },
      };
    case `${CustomerAccountTypeEnum.newCustomerContact}-1`:
      return {
        card: {
          defaultTitle: t('newCustomerContact'),
          body: {
            description: t('newCustomerContactLabel', {
              role,
              name: newContactName,
              customer: customerName,
            }),
            lines: [
              ...defaultFirstLine,
              {
                description: t('newCustomerContactDescription', {
                  role,
                  name: newContactName,
                  customer: customerName,
                }),
              },
              {
                description: t('newCustomerContactDescription2', {
                  role,
                  name: newContactName,
                  customer: customerName,
                }),
              },
            ],
          },
          highlight: [{ query: newContactName }],
        },
        modal: {
          title: t('newCustomerContact'),
          refetchActivity: true,
          defaultData: { emailContactName, emailContact },
          customContent: (props) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <NewContactCustomerForm activity={activityData} {...props} />
          ),
        },
      };
    case `${CustomerAccountTypeEnum.atRiskCustomerAccount}-1`:
      return {
        card: {
          defaultTitle: t('atRiskCustomerAccount'),
          body: {
            description: t('riskCustomerAccountLabel', {
              daysAtRisk,
            }),
            lines: [
              ...defaultFirstLine,
              {
                description: t('riskCustomerAccountDescription', {
                  daysAtRisk,
                }),
              },
            ],
          },
        },
        modal: {
          title: t('atRiskCustomerAccount'),
          defaultData: atRiskCustomerData,
          customContent: (props) => (
            <div>
              <Text
                className={classes.riskCustomerText}
                text={t('riskCustomerModalDesc', { daysAtRisk })}
                testid="risk-cusstomer-desc"
              />
              <ActionCardModalData
                dataDefinition={atRiskCustomerActivityDefinition}
                activityData={atRiskCustomerData}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
              <div className={classes.riskCustomerWrapper}>
                <AccountsReceivableCard
                  miLoc={activityData.custMiLoc}
                  id={activityData.custNo}
                />
              </div>
            </div>
          ),
        },
      };
    default:
      return undefined;
  }
};

export const getCustomerAccountData = ({
  eventTagName,
  t,
}: GetActivityTypeDataProps): ActivityTypeConfig | undefined => {
  switch (eventTagName) {
    case 'ReengagedCustomerAccount':
      return {
        cardType: CustomerAccountTypeEnum.reengagedCustomerAccount,
        title: t('reengagedCustomerAccount'),
        icon: CustomerAccountIconEnum.reengagedCustomerAccount,
        color: CustomerAccountColorEnum.reengagedCustomerAccount,
      };
    case 'NewCustomerContact':
      return {
        cardType: CustomerAccountTypeEnum.newCustomerContact,
        title: t('newCustomerContact'),
        icon: CustomerAccountIconEnum.newCustomerContact,
        color: CustomerAccountColorEnum.newCustomerContact,
      };
    case 'AtRiskCustomerAccount':
      return {
        cardType: CustomerAccountTypeEnum.atRiskCustomerAccount,
        title: t('atRiskCustomerAccount'),
        icon: CustomerAccountIconEnum.atRiskCustomerAccount,
        color: CustomerAccountColorEnum.atRiskCustomerAccount,
      };
    default:
      return undefined;
  }
};

export const getCustomerAccountFilterIcon = (
  type: string
): CustomerAccountIconEnum | undefined => {
  switch (type) {
    case CustomerAccountFilterEnum.reengagedCustomerAccount:
      return CustomerAccountIconEnum.reengagedCustomerAccount;
    case CustomerAccountFilterEnum.newCustomerContact:
      return CustomerAccountIconEnum.newCustomerContact;
    case CustomerAccountFilterEnum.atRiskCustomerAccount:
      return CustomerAccountIconEnum.atRiskCustomerAccount;
    default:
      return undefined;
  }
};
