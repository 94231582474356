import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, toString } from 'lodash';
import type { MutationStatus } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { getOrderQueryKey } from 'ProductSearchApp/api/useGetOrder';
import type { Order } from 'ProductSearchApp/models/Order';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import type { UpdateMutationContext } from 'api/helpers';
import { onMutateUpdate, onSuccessMutation, useKeyUserId } from 'api/helpers';
import { ToastType } from 'models/Toast';
import type { RootState } from 'store/reducers';
import { clearCurrentCartCustomer } from 'store/user';
import { findOrdersQueryKey } from './useFindOrders';

export interface DeleteOrderItemBody {
  deleteOption: string;
  orderLineNo: string;
  lostSale: string;
}

interface UseDeleteOrderItemResponse {
  status: MutationStatus;
  onDeleteOrderItem: (body: DeleteOrderItemBody) => void;
  onOptimisticDeleteItem: (
    orderLineNo: string
  ) => Promise<UpdateMutationContext<Order>[]>;
  rollbackDeleteItem: (
    orderLineNo: string
  ) => Promise<UpdateMutationContext<Order>[]>;
}

const useDeleteOrderItem = (): UseDeleteOrderItemResponse => {
  const { axios } = useAxios();
  const { deleteOrderItemAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const { createQueryKey } = useKeyUserId();
  const { t } = useTranslation();
  const namespace = 'productSearch:ocn';
  const { currentCartCustomer, userInfo } = useSelector(
    (state: RootState) => state.user
  );
  const dispatch = useDispatch();

  const doDeleteOrderItem = async (body: DeleteOrderItemBody) => {
    const bodyData = {
      customerInfo: {
        customerNo: currentCartCustomer?.shipToCustNo,
        miLoc: currentCartCustomer?.miLoc,
        billToNo: currentCartCustomer?.billToCustNo,
      },
      orderInfo: {
        miLoc: currentCartCustomer?.miLoc,
        orderCtlNo: currentCartCustomer?.orderCtlNo,
        shipToCustNo: currentCartCustomer?.shipToCustNo,
      },
      itemList: [
        {
          orderLineNo: body.orderLineNo,
          deleteOption: body.deleteOption,
        },
      ],
      lostSale: body.lostSale,
    };
    const { data } = await axios.post<Order>(deleteOrderItemAPI(), bodyData);
    return data;
  };

  const onOptimisticDeleteItem = async (orderLineNo: string) => {
    const cachedOrder = queryClient.getQueryData<Order>([
      getOrderQueryKey,
      {
        orderCtlNo: toString(currentCartCustomer?.orderCtlNo),
        miLoc: toString(currentCartCustomer?.miLoc),
        $userId: toString(userInfo?.userid),
      },
    ]) as Order;
    const linesUpdating = cachedOrder?.linesUpdating || [];
    linesUpdating.push(orderLineNo);

    return onMutateUpdate<Order>({
      queryClient,
      queryKey: getOrderQueryKey,
      updatedItems: [{ linesUpdating } as Order],
      dataPath: 'orders',
      isSingleQuery: true,
      findPredicates: [
        {
          orderCtlNo: toString(currentCartCustomer?.orderCtlNo),
          miLoc: toString(currentCartCustomer?.miLoc),
        },
      ],
    });
  };

  const rollbackDeleteItem = async (orderLineNo: string) => {
    const cachedOrder = queryClient.getQueryData<Order>([
      getOrderQueryKey,
      {
        orderCtlNo: toString(currentCartCustomer?.orderCtlNo),
        miLoc: toString(currentCartCustomer?.miLoc),
        $userId: toString(userInfo?.userid),
      },
    ]) as Order;
    const tmpLinesUpdating = cachedOrder?.linesUpdating || [];
    const linesUpdating = tmpLinesUpdating.filter(
      (obj) => obj !== toString(orderLineNo)
    );
    return onMutateUpdate<Order>({
      queryClient,
      queryKey: getOrderQueryKey,
      updatedItems: [{ linesUpdating } as Order],
      dataPath: 'orders',
      isSingleQuery: true,
      findPredicates: [
        {
          orderCtlNo: toString(currentCartCustomer?.orderCtlNo),
          miLoc: toString(currentCartCustomer?.miLoc),
        },
      ],
    });
  };

  const { mutate, status } = useMutation(doDeleteOrderItem, {
    onMutate: (vars) => onOptimisticDeleteItem(vars.orderLineNo),

    onSuccess: (data) => {
      if (isEmpty(data.orderCtlNo)) {
        void onSuccessMutation(queryClient, findOrdersQueryKey);
        dispatch(clearCurrentCartCustomer());
      } else {
        // TODO create utils to update query data
        queryClient.setQueryData<Order>(
          createQueryKey(getOrderQueryKey, {
            miLoc: currentCartCustomer?.miLoc,
            orderCtlNo: currentCartCustomer?.orderCtlNo,
          }),
          data
        );
      }
    },
    onError: async (error, vars) => {
      addToast({
        type: ToastType.error,
        text: t(`${namespace}:deleteItemErrorToast`, {
          orderLineNo: vars.orderLineNo,
        }),
        testid: 'delete-item-error-toast',
      });
      await rollbackDeleteItem(vars.orderLineNo);
    },
  });

  return {
    status,
    onDeleteOrderItem: (body: DeleteOrderItemBody) => mutate(body),
    onOptimisticDeleteItem,
    rollbackDeleteItem,
  };
};

export default useDeleteOrderItem;
