import React, { useEffect, useState } from 'react';
import { capitalize, map, toNumber, toString } from 'lodash';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { CustomerSearchTabTypeEnum } from 'CostSavingsApp/models/CostSavings';
import useFindCustomers from 'api/customer/useFindCustomers';
import {
  SearchSuggestionTypeEnum,
  type SearchCustomerItem,
  type SelectModalItem,
  SearchResultTabTypeEnum,
} from 'models/Search';
import { getAddress } from 'utils/address';
import { getCustomerShortName } from 'utils/search';
import SegmentTabs from 'components/SegmentTabs/SegmentTabs';
import SelectModal from 'components/SelectModal/SelectModal';

interface CustomerSelectProps {
  selItem?: SelectModalItem;
  defaultSelected?: CustomerSearchTabTypeEnum;
  className?: string;
  label: string;
  iconClassName?: string;
  placeholder: string;
  setCustomerInfo: (o: SelectModalItem) => void;
  showInput?: boolean;
  openModal?: boolean;
  setOpenModal?: (b: boolean) => void;
  triggerInput?: number;
  showTabOptions?: boolean;
  selCustomer?: SelectModalItem;
  onClose?: () => void;
  testid: string;
}

const CustomerSelect = ({
  selItem,
  defaultSelected = CustomerSearchTabTypeEnum.CustomerSearch,
  className,
  label,
  iconClassName,
  placeholder,
  setCustomerInfo,
  showInput = true,
  openModal = false,
  setOpenModal,
  onClose,
  triggerInput,
  showTabOptions = true,
  selCustomer,
  testid,
}: CustomerSelectProps & IonicReactProps): JSX.Element => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isOpen, setIsOpen] = useState(
    selItem !== undefined && selItem.key !== ''
  );
  const [selectedTab, setSelectedTab] =
    useState<CustomerSearchTabTypeEnum>(defaultSelected);

  const {
    error,
    items,
    isEmptyResponse,
    showLoader,
    fetchNextPage,
    enableInfiniteScroll,
  } = useFindCustomers({
    query: searchQuery,
    nationalAccountOnly:
      selectedTab === CustomerSearchTabTypeEnum.CorporateSearch,
  });

  useEffect(() => {
    if (isOpen || openModal) {
      if (selItem && selItem.type) {
        setSelectedTab(selItem.type as CustomerSearchTabTypeEnum);
      } else {
        setSelectedTab(defaultSelected);
      }
    }
  }, [defaultSelected, isOpen, openModal, selItem]);

  useEffect(() => {
    if (!isOpen && !openModal) {
      setSearchQuery('');
    }
  }, [isOpen, openModal, searchQuery, setSearchQuery]);

  const customerItems = map(items, (item) => {
    const { miLoc, id } = item as SearchCustomerItem;
    const shortName = getCustomerShortName(item.compressedName);

    const key = `${miLoc}${id}`;

    return {
      key,
      title: item.text,
      description: `${capitalize(item.type)} ${miLoc}${id}${shortName}`,
      type: showTabOptions ? 'customerCorpAcct' : 'customer',
      customerItem: {
        text: item.text,
        address: getAddress(item.address),
        account: item.natlAcctNo,
        testid: 'customeritem',
        searchSuggestionType: SearchSuggestionTypeEnum.result,
        id:
          selectedTab === CustomerSearchTabTypeEnum.CustomerSearch
            ? SearchResultTabTypeEnum.customers
            : undefined,
      },
    };
  });

  useEffect(() => {
    if (toNumber(triggerInput) > 0) {
      setIsOpen(true);
    }
  }, [triggerInput]);

  return (
    <SelectModal
      isOpen={setOpenModal ? openModal : isOpen}
      setIsOpen={setOpenModal || setIsOpen}
      type={showTabOptions ? 'customerCorpAcct' : 'customer'}
      subType=""
      selItem={selItem || { title: '', description: '', key: '' }}
      setSelItem={(o) => {
        const Item = {
          ...o,
          type: selectedTab,
        };
        setTimeout(() => {
          setCustomerInfo(Item);
        }, 100);
      }}
      onClose={onClose}
      testid={`${testid}-select-customer-modal`}
      isEmptyResponse={isEmptyResponse}
      showLoader={showLoader}
      fetchNextPage={fetchNextPage}
      error={error}
      enableInfiniteScroll={enableInfiniteScroll}
      items={customerItems}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      onClick={() => setIsOpen(true)}
      label={label}
      value={toString(selCustomer?.title)}
      placeholder={placeholder}
      iconClassName={iconClassName}
      showInput={showInput}
      className={className}
      hasSegments={showTabOptions}
      customContent={
        showTabOptions ? (
          <SegmentTabs
            options={[
              {
                key: CustomerSearchTabTypeEnum.CustomerSearch,
                text: 'Customer',
              },
              {
                key: CustomerSearchTabTypeEnum.CorporateSearch,
                text: 'Corporate Account',
              },
            ]}
            value={selectedTab}
            setValue={(v: string) => {
              setSelectedTab(v as CustomerSearchTabTypeEnum);
            }}
            testid="replenishments-tabs"
          />
        ) : undefined
      }
    />
  );
};

export default CustomerSelect;
