import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import { useTable, useSortBy } from 'react-table';
import type { Column, SortingRule } from 'react-table';
import classNames from 'classnames';
import type { Dictionary } from 'lodash';
import { last, size, isNil, map, toString } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { SortDirEnum } from 'models/Sort';
import { findIcon } from 'utils/icons';
import classes from './Table.module.scss';

interface TableProps {
  variant?: 'grid' | 'table';
  columns: Column<Dictionary<unknown>>[];
  data?: Dictionary<unknown>[];
  totals?: React.ReactNode[];
  initialState?: Dictionary<unknown>;
  theadClassName?: string;
  theadStyle?: React.CSSProperties;
  thClassName?: string;
  tdClassName?: string;
  onSortBy?: (sortBy: SortingRule<Dictionary<unknown>>[]) => void;
  sortField?: string;
  sortDir?: string;
  disableSortBy?: boolean;
}

const Table = ({
  variant = 'grid',
  columns: propColumns,
  data: propsData,
  totals,
  initialState,
  onSortBy,
  sortField,
  sortDir,
  className,
  theadClassName,
  theadStyle,
  thClassName,
  tdClassName,
  disableSortBy = false,
}: TableProps & IonicReactProps): JSX.Element => {
  const columns = useMemo(
    () =>
      map(propColumns, (c) => ({
        ...c,
        id: toString(c.id || c.accessor),
        sortDescFirst: true,
      })),
    [propColumns]
  );

  const data = useMemo(() => propsData || [], [propsData]);

  const {
    headerGroups,
    rows,
    prepareRow,
    setSortBy,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState,
      disableSortBy,
      disableSortRemove: true,
      disableMultiSort: true,
      manualSortBy: !isNil(onSortBy),
    },
    useSortBy
  );
  const [scrollSyncKey, setScrollSyncKey] = useState<string>('');

  useEffect(() => {
    if (sortField) {
      setSortBy([{ id: sortField, desc: sortDir === SortDirEnum.DESCENDING }]);
    }
  }, [setSortBy, sortField, sortDir, scrollSyncKey]);

  const sortByString = JSON.stringify(sortBy);
  useEffect(() => {
    onSortBy?.(sortBy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortByString]);

  const headerRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);

  // Hack around ScrollSync when sorting
  useEffect(() => {
    setScrollSyncKey(new Date().toString());
  }, [data]);

  return (
    <ScrollSync key={scrollSyncKey}>
      <div className={classNames(classes.tableContainer, className)}>
        <div data-testid="table" className={classes.tableWrapper}>
          <div
            className={classNames(classes.table, {
              [classes.tableDisplay]: variant === 'table',
            })}
          >
            <ScrollSyncPane>
              <div
                ref={headerRef}
                className={classNames(classes.thead, theadClassName, {
                  [classes.tableDisplay]: variant === 'table',
                })}
                style={theadStyle}
              >
                {map(headerGroups, (headerGroup, headerIndex) => (
                  <div className={classes.tr} key={headerIndex}>
                    {map(
                      headerGroup.headers,
                      ({
                        id,
                        isSorted,
                        isSortedDesc,
                        render,
                        getHeaderProps,
                        getSortByToggleProps,
                      }) => (
                        <div
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...getHeaderProps(getSortByToggleProps())}
                          key={id}
                          className={classNames(classes.th, thClassName, {
                            [classes.sorted]: isSorted,
                          })}
                        >
                          <div className={classes.thWrapper}>
                            {isSortedDesc ? (
                              <FontAwesomeIcon
                                className={classNames(classes.sortDownIcon, {
                                  [classes.sortedIcon]: isSorted,
                                })}
                                icon={findIcon('long-arrow-down', 'fal')}
                              />
                            ) : (
                              <FontAwesomeIcon
                                className={classNames(classes.sortUpIcon, {
                                  [classes.sortedIcon]: isSorted,
                                })}
                                icon={findIcon('long-arrow-up', 'fal')}
                              />
                            )}
                            {render('Header')}
                          </div>
                        </div>
                      )
                    )}
                    <div className={classes.endSpacing} />
                  </div>
                ))}
                {size(totals) > 0 && size(rows) > 0 && (
                  <div className={classes.tr}>
                    {map(totals, (node, index) => (
                      <div
                        key={index}
                        className={classNames(
                          classes.th,
                          classes.sum,
                          thClassName,
                          {
                            [classes.sorted]:
                              last(headerGroups)?.headers[index].isSorted,
                          }
                        )}
                      >
                        <div className={classes.thWrapper}>{node}</div>
                      </div>
                    ))}
                    <div className={classes.endSpacing} />
                  </div>
                )}
              </div>
            </ScrollSyncPane>
            <ScrollSyncPane>
              <div
                ref={bodyRef}
                className={classNames(classes.tbody, {
                  [classes.tableDisplay]: variant === 'table',
                })}
              >
                {map(rows, (row, rowIndex) => {
                  prepareRow(row);
                  return (
                    <div className={classes.tr} key={row.id}>
                      {map(row.cells, (cell) => (
                        <div
                          key={`${row.id}${cell.column.id}`}
                          className={classNames(classes.td, tdClassName, {
                            [classes.sorted]: cell.column.isSorted,
                            [classes.row1Color]: rowIndex % 2 !== 0,
                            [classes.row2Color]: rowIndex % 2 === 0,
                          })}
                        >
                          <div className={classes.tdWrapper}>
                            {cell.render('Cell')}
                          </div>
                        </div>
                      ))}
                      <div className={classes.endSpacing} />
                    </div>
                  );
                })}
              </div>
            </ScrollSyncPane>
            <div className={classes.endShadow} />
          </div>
        </div>
      </div>
    </ScrollSync>
  );
};

export default Table;
