import type { AxiosError } from 'axios';
import type { Dictionary } from 'lodash';
import { head } from 'lodash';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import useStoreroomDB from 'StoreroomsApp/database/useStoreroomDB';
import type { Storeroom } from 'StoreroomsApp/models/Storeroom';
import {
  doGetIsLoading,
  doPromiseAPI,
  getPlaceholderData,
  useKeyUserId,
  useMiLocOrTeamId,
} from 'api/helpers';
import type { QueryFlags } from 'models/Search';
import { findStoreroomsQueryKey } from './useFindStorerooms';

const getStoreroomsQueryKey = 'storeroom';

interface UseGetStoreroomProps {
  storeroomNumber: string;
}

interface UseGetStoreroomResponse {
  storeroom?: Storeroom;
}

const useGetStoreroom = ({
  storeroomNumber,
}: UseGetStoreroomProps): QueryFlags & UseGetStoreroomResponse => {
  const queryClient = useQueryClient();
  const { createQueryKey } = useKeyUserId();
  const { createParams } = useMiLocOrTeamId({ sendTeamId: false });
  const { findStoreroomsByMiLoc } = useStoreroomDB();
  const params: Dictionary<string> = {
    ...createParams(),
    storeroomNumber,
  };
  const { miLoc } = params;

  const doGetStoreroom = async () => {
    return doPromiseAPI<Storeroom>(async () => {
      const storerooms =
        (await findStoreroomsByMiLoc({ miLoc, storeroomNumber })) || [];
      return head(storerooms) as Storeroom;
    });
  };

  const response = useQuery<Storeroom, AxiosError>(
    createQueryKey(getStoreroomsQueryKey, params),
    doGetStoreroom,
    {
      networkMode: 'always',
      placeholderData: () =>
        getPlaceholderData<Storeroom>({
          queryClient,
          queryKey: findStoreroomsQueryKey,
          findPredicate: {
            miLocation: miLoc,
            storeroomNumber,
          },
        }),
    }
  );

  const { data, error, refetch } = response;

  return {
    storeroom: data,
    error,
    isLoading: doGetIsLoading({ ...response, dataPath: 'storeroomNumber' }),
    refetch: async () => {
      await refetch();
    },
  };
};

export default useGetStoreroom;
