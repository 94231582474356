import { useEffect, useMemo, useState } from 'react';
import type { AxiosError } from 'axios';
import { map, size, toString } from 'lodash';
import * as Sentry from '@sentry/capacitor';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import type { QueryParamsType } from 'common/api/utils/useGetQueryFlags';
import useMachineDB from 'DocumentsApp/database/useMachineDB';
import type { FastFindAPIResponse } from 'DocumentsApp/models/FastFind';
import { useAxios } from 'providers/AxiosProvider';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import { useToasts } from 'providers/ToastProvider';
import {
  doConcatDataPages,
  doGetIsLoading,
  doPromiseAPI,
  getAPIHeadersV2,
  getOfflineNextPage,
  onDownloadData,
  onSuccessMutation,
  useKeyUserId,
  useMiLocOrTeamId,
} from 'api/helpers';
import type { Machine } from 'models/InspectionReport';
import type { InfiniteQueryFlags } from 'models/Search';
import { ToastType } from 'models/Toast';
import { pageSize } from 'utils/constants';

export const findMachinesQueryKey = 'machines';

interface UseFindMachinesProps {
  query?: string;
  siteId?: number;
  limit?: number;
  enabled?: boolean;
}

interface UseFindMachinesResponse {
  machines: Machine[];
  loadingAPI?: boolean;
  sync: () => Promise<void>;
}

const useFindMachines = ({
  query,
  siteId,
  enabled = true,
  limit = pageSize(),
}: UseFindMachinesProps): UseFindMachinesResponse & InfiniteQueryFlags => {
  const { axios } = useAxios();
  const { addToast } = useToasts();
  const { getMachinesDatabaseAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { createQueryKey } = useKeyUserId();
  const { createParams, getURLParams } = useMiLocOrTeamId({
    sendTeamId: false,
  });
  const { createMachines, findMachines, removeMachines } = useMachineDB();
  const [loadingAPI, setLoadingAPI] = useState(false);
  const { isOnline } = useNetworkStatus();
  const params: QueryParamsType = {
    ...createParams(),
    fastFind: query,
    siteId,
    limit,
    isOnline,
  };
  const miLoc = toString(params.miLoc);

  const doFindMachines = ({ pageParam = 1 }) => {
    return doPromiseAPI<Machine[]>(async () => {
      if (isOnline) {
        const {
          data: { rows: machinesData },
        } = await axios.post<FastFindAPIResponse<Machine>>(
          getMachinesDatabaseAPI(
            getURLParams({
              ...params,
              search: params.fastFind,
              page: pageParam,
            })
          ),
          null,
          { headers: { ...getAPIHeadersV2() } }
        );
        await createMachines(
          map(machinesData, (machine) => ({ ...machine, siteMiLoc: miLoc }))
        );
        return machinesData;
      }
      const machines: Machine[] = (await findMachines(params)) || [];
      return machines;
    });
  };

  const response = useInfiniteQuery<Machine[], AxiosError>(
    createQueryKey(findMachinesQueryKey, params),
    doFindMachines,
    {
      enabled,
      networkMode: 'always',
      getNextPageParam: getOfflineNextPage(!!isOnline),
    }
  );

  const {
    data,
    error,
    status,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = response;

  const machines = useMemo(() => doConcatDataPages<Machine>(data), [data]);

  const hasItems = size(machines) > 0;
  const isEmptyResponse = status === 'success' && !hasNextPage && !hasItems;
  const noMoreData = status === 'success' && !hasNextPage && hasItems;
  const showLoader = doGetIsLoading(response) || isFetchingNextPage;
  const enableInfiniteScroll = !(!hasNextPage || isFetchingNextPage);

  useEffect(() => {
    if (enabled && !isOnline) {
      void refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, isOnline]);

  return {
    fetchNextPage: async () => {
      await fetchNextPage();
    },
    refetch: async () => {
      await refetch();
    },
    sync: async () => {
      try {
        setLoadingAPI(true);
        const { syncData } = await onDownloadData<Machine>({
          customAxios: axios,
          getAPIUrl: getMachinesDatabaseAPI,
          params: { miLoc },
          headers: { ...getAPIHeadersV2() },
        });
        await removeMachines(miLoc);
        await createMachines(
          map(syncData, (machine) => ({ ...machine, siteMiLoc: miLoc }))
        );
        void onSuccessMutation(queryClient, findMachinesQueryKey);
      } catch (e) {
        addToast({
          type: ToastType.error,
          text: 'There was an error while downloading machines',
          testid: 'sync-error-toast',
          duration: 0,
        });
        Sentry.captureException(e);
        throw e;
      } finally {
        setLoadingAPI(false);
      }
    },
    machines,
    error,
    showLoader,
    loadingAPI,
    isEmptyResponse,
    noMoreData,
    enableInfiniteScroll,
  };
};

export default useFindMachines;
