import React from 'react';
import classNames from 'classnames';
import { toString } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findIcon } from 'utils/icons';
import CurrencyFormat from 'components/CurrencyFormat/CurrencyFormat';
import NumberFormat from 'components/NumberFormat/NumberFormat';
import type Text from 'components/Text/Text';
import classes from './ChangeFormat.module.scss';

interface ChangeFormatProps {
  value: number;
  positiveClassName?: string;
  negativeClassName?: string;
  hideSign?: boolean;
  testid: string;
  typeVariant?: 'number' | 'currency' | 'bp';
  currencyType?: string;
}

const ChangeFormat = ({
  value,
  className,
  positiveClassName,
  negativeClassName,
  hideSign,
  variant = 'content-small',
  testid,
  typeVariant = 'number',
  currencyType,
}: ChangeFormatProps &
  Omit<React.ComponentProps<typeof Text>, 'text'>): JSX.Element => {
  const renderFormatType = () => {
    switch (typeVariant) {
      case 'currency':
        return (
          <CurrencyFormat
            value={value}
            testid={testid}
            className={classes.value}
            currencyType={currencyType}
          />
        );
      case 'bp':
        return (
          <NumberFormat
            value={hideSign ? Math.abs(value) : value}
            scale={0}
            className={classes.value}
            variant={variant}
            testid={`change-format-value-${testid}`}
          />
        );
      case 'number':
      default:
        return (
          <NumberFormat
            value={hideSign ? Math.abs(value) : value}
            scale={1}
            suffix="%"
            className={classes.value}
            variant={variant}
            testid={`change-format-value-${testid}`}
          />
        );
    }
  };

  return (
    <div
      className={classNames(className, classes.changeFormat, {
        [classes.positive]: value > 0,
        [toString(positiveClassName)]: value > 0,
        [classes.negative]: value < 0,
        [toString(negativeClassName)]: value < 0,
      })}
      data-testid={`change-format-${testid}`}
    >
      {value !== 0 && typeVariant !== 'currency' && (
        <FontAwesomeIcon
          className={classes.icon}
          icon={findIcon(value > 0 ? 'caret-up' : 'caret-down', 'fas')}
        />
      )}
      {renderFormatType()}
    </div>
  );
};

export default ChangeFormat;
