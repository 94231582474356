import React, { useState } from 'react';
import type { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { get, toString } from 'lodash';
import { searchURL } from 'navigation';
import useAccessControls, { AccessControlType } from 'hooks/useAccessControls';
import type { WebActivityContactModalProps } from 'models/WebActivity';
import { findIcon } from 'utils/icons';
import { concatRoutes } from 'utils/navigations';
import { handleSearchNavigation } from 'utils/search';
import ActionRow from 'components/ActionRow/ActionRow';
import { PhoneCallRow } from 'components/Contacts/PhoneNumber/PhoneNumber';
import SnapshotLink from 'components/Contacts/SnapshotLink/SnapshotLink';
import Modal from 'components/Modal/Modal';
import SendEmailModal from 'components/Modals/SendEmailModal/SendEmailModal';
import classes from './ContactModal.module.scss';

interface ContactModalProps {
  contactModalData?: WebActivityContactModalProps;
  testid: string;
}

const ContactModal = ({
  isOpen,
  setIsOpen,
  title,
  contactModalData,
  testid,
}: ContactModalProps & ComponentProps<typeof Modal>): JSX.Element => {
  const ac = useAccessControls(AccessControlType.viewCustomers);
  const getKeyValue = (customerKey: string) =>
    toString(get(contactModalData, customerKey));

  const phone = getKeyValue('contactData.phone');
  const email = getKeyValue('contactData.email');
  const customerName = getKeyValue('customerData.name');
  const miLoc = getKeyValue('customerData.miLoc');
  const customerNo = getKeyValue('customerData.customerNo');
  const searchPhrase = getKeyValue('searchPhrase');
  const { t } = useTranslation();

  const [sendEmailModal, setSendEmailModal] = useState(false);

  return (
    <>
      <Modal
        className={classes.modal}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        eyebrow={
          <SnapshotLink
            className={classes.snapshotLink}
            eyebrow={`${miLoc}${customerNo}`}
            onClick={() => {
              setIsOpen?.call(null, false);
            }}
            href={concatRoutes(
              searchURL(),
              handleSearchNavigation({
                type: 'customer',
                miLoc,
                customerId: customerNo,
              })
            )}
            text={customerName}
            disabled={!ac}
            testid="customer-snapshot-button"
          />
        }
        title={title}
        testid={testid}
      >
        <ActionRow
          className={classes.actionRow}
          leftButton={{
            variant: 'link',
            icon: findIcon('search'),
            text: `Search: ${searchPhrase}`,
            testid: `${testid}-action-search-term-button`,
          }}
          withArrow={false}
          testid={`${testid}-action-search-term`}
        />
        {phone && (
          <PhoneCallRow
            className={classes.actionRow}
            phoneNumber={phone}
            testid={`${testid}-action-contact-phone`}
          />
        )}
        <ActionRow
          className={classes.actionRow}
          leftButton={{
            variant: 'link',
            icon: findIcon('envelope'),
            text: email,
            testid: `${testid}-action-contact-email-button`,
          }}
          onClick={() => {
            setIsOpen?.call(null, false);
            setSendEmailModal(true);
          }}
          testid={`${testid}-action-contact-email`}
        />
      </Modal>
      <SendEmailModal
        searchType="customer"
        id={customerNo}
        miLoc={miLoc}
        isOpen={sendEmailModal}
        defaultRecipients={[{ name: toString(title), email }]}
        setIsOpen={setSendEmailModal}
        title={t('common:sendEmail')}
        testid={`${testid}-send-email-modal`}
      />
    </>
  );
};
export default ContactModal;
