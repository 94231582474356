import type { AxiosError } from 'axios';
import { isNil } from 'lodash';
import { useIonViewDidEnter } from '@ionic/react';
import { useQueryClient, useQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { doPromiseAPI, getPlaceholderData, useKeyUserId } from 'api/helpers';
import type { SalesPlay } from 'models/SalesPlays';
import type { QueryFlags } from 'models/Search';
import { findSalesPlaysQueryKey } from './useFindSalesPlays';

export const getSalesPlayQueryKey = 'sales-play';

interface UseGetSalesPlayResponse {
  data?: SalesPlay;
}

const useGetSalesPlay = ({
  externalSource,
  externalId,
}: Pick<SalesPlay, 'externalSource' | 'externalId'>): UseGetSalesPlayResponse &
  QueryFlags => {
  const { axios } = useAxios();
  const { getSalesPlayAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { createQueryKey } = useKeyUserId();

  const doGetSalesPlay = () => {
    return doPromiseAPI<SalesPlay>(async (cancelToken) => {
      const { data } = await axios.get<SalesPlay>(
        getSalesPlayAPI(externalSource, externalId),
        {
          cancelToken,
        }
      );
      return data;
    });
  };

  const { data, error, status, isFetching, refetch } = useQuery<
    SalesPlay,
    AxiosError
  >(
    createQueryKey(getSalesPlayQueryKey, { externalSource, externalId }),
    doGetSalesPlay,
    {
      enabled: isNil(externalSource) && isNil(externalId),
      placeholderData: () =>
        getPlaceholderData<SalesPlay>({
          queryClient,
          queryKey: findSalesPlaysQueryKey,
          findPredicate: { externalSource, externalId },
        }),
    }
  );

  useIonViewDidEnter(() => {
    void refetch();
  });

  return {
    data,
    error,
    isLoading: status === 'loading' || (isFetching && isNil(data?.externalId)),
  };
};

export default useGetSalesPlay;
