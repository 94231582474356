import React from 'react';
import classNames from 'classnames';
import classes from './ActivitiesGroupContent.module.scss';

interface ActivitiesGroupContentProps {
  index: number;
  groupCount: number;
}

const ActivitiesGroupContent = ({
  index,
  groupCount,
}: ActivitiesGroupContentProps) => {
  return (
    <div
      key={index}
      className={classNames({
        [classes.divider]: groupCount !== 0,
        [classes.hidden]: groupCount === 0,
      })}
    >
      {index === 0 && 'Upcoming Activities'}
      {index === 1 && "Today's Activities"}
      {index === 2 && 'Last Two Weeks'}
      {index === 3 && 'Older Activities'}
    </div>
  );
};

export default ActivitiesGroupContent;
