import React from 'react';
import classNames from 'classnames';
import { find, isNil, map, toString } from 'lodash';
import type { InputChangeEventDetail } from '@ionic/core/components';
import {
  IonCol,
  IonItem,
  IonLabel,
  IonRow,
  IonSelectOption,
  IonSelect,
} from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import type { SelectOptions } from 'models/Select';
import { withStringProp } from 'utils/helpers';
import Text from 'components/Text/Text';
import classes from './Select.module.scss';

enum StyleTypeEnum {
  'action-sheet',
  alert,
  popover,
}

interface SelectProps {
  label?: string;
  error?: string;
  setValue?: (v: string) => void;
  options?: SelectOptions[];
  interfaceStyle?: keyof typeof StyleTypeEnum;
  required?: boolean;
  testid: string;
}

const Select = ({
  className,
  label = '',
  name,
  value,
  disabled,
  multiple = false,
  options,
  required,
  error,
  setValue,
  interfaceStyle = 'alert',
  testid,
  toggleIcon,
}: SelectProps &
  React.ComponentProps<typeof IonSelect> &
  IonicReactProps): JSX.Element => {
  const withLabel = withStringProp(label);
  return (
    <IonItem
      className={classNames(className, classes.selectWrapper, {
        [classes.error]: error,
        [classes.disabled]: disabled,
        [classes.withoutLabel]: !withLabel,
      })}
      lines="none"
    >
      <IonCol>
        {withLabel && (
          <IonLabel>
            <Text
              className={classes.label}
              variant="content-heavy"
              text={`${label}${required ? '*' : ''}`}
              testid={`${testid}-label`}
            />
          </IonLabel>
        )}
        <IonRow className={classes.wrapper}>
          <IonSelect
            aria-label={label || name}
            name={name}
            className={classes.select}
            value={toString(value)}
            multiple={multiple}
            interface={interfaceStyle}
            onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
              const val = find(options, ({ id }) => id === e.detail.value);
              if (!isNil(val)) {
                setValue?.call(null, val.id);
              }
            }}
            disabled={disabled}
            data-testid={testid}
            toggleIcon={toggleIcon}
          >
            {map(options, ({ id, text: optionName }) => (
              <IonSelectOption value={id} key={id}>
                {optionName}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonRow>
        {error && (
          <Text
            className={classes.errorMessage}
            variant="content-small"
            text={error}
            testid={`${testid}-error`}
          />
        )}
      </IonCol>
    </IonItem>
  );
};

export default Select;
