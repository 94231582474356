import React, { useEffect, useState } from 'react';
import { map, toString } from 'lodash';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import useFindContacts from 'api/contacts/useFindContacts';
import type { SearchCustomerContactItem, SelectModalItem } from 'models/Search';
import { SearchSuggestionTypeEnum } from 'models/Search';
import SelectModal from 'components/SelectModal/SelectModal';

interface ContactSelectProps {
  miLoc: string;
  customerNo: string;
  selItem?: SelectModalItem;
  className?: string;
  label: string;
  required?: boolean;
  iconClassName?: string;
  placeholder?: string;
  disabled?: boolean;
  setContact: (o: SelectModalItem) => void;
  showInput?: boolean;
  openModal?: boolean;
  setOpenModal?: (b: boolean) => void;
  onBlur?: () => void;
  inputError?: string;
  maxlength?: number;
  searchLink?: string;
}

const ContactSelect = ({
  miLoc,
  customerNo,
  selItem,
  className,
  label,
  required,
  iconClassName,
  placeholder,
  disabled,
  setContact,
  showInput = true,
  openModal = false,
  setOpenModal,
  onBlur,
  inputError,
  maxlength,
  searchLink,
}: ContactSelectProps & IonicReactProps): JSX.Element => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const {
    error,
    contacts,
    isEmptyResponse,
    showLoader,
    fetchNextPage,
    enableInfiniteScroll,
  } = useFindContacts({
    query: searchQuery,
    searchType: 'customer',
    miLoc,
    id: customerNo,
  });

  useEffect(() => {
    if (!isOpen && !openModal) {
      setSearchQuery('');
    }
  }, [isOpen, openModal, searchQuery, setSearchQuery]);

  const contactsItems = map(contacts, (item) => {
    const {
      name = '',
      typeDesc = '',
      phone = '',
      sequenceNo,
      isMainContact,
    } = item as SearchCustomerContactItem;
    return {
      item,
      key: toString(sequenceNo),
      title: name,
      description: typeDesc,
      contactItem: {
        address: phone,
        text: '',
        searchSuggestionType: SearchSuggestionTypeEnum.search,
        testid: 'contactitem',
        isMainCard: isMainContact === 'Y',
      },
    };
  });

  const inputDisplay = selItem && selItem.key.length > 0 ? selItem.title : '';

  return (
    <SelectModal
      isOpen={setOpenModal ? openModal : isOpen}
      setIsOpen={setOpenModal || setIsOpen}
      type="contact"
      subType=""
      selItem={selItem || { title: '', description: '', key: '' }}
      setSelItem={setContact}
      testid="select-contact-modal"
      isEmptyResponse={isEmptyResponse}
      showLoader={showLoader && searchQuery.length > 0}
      fetchNextPage={fetchNextPage}
      error={error}
      enableInfiniteScroll={enableInfiniteScroll}
      items={contactsItems}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      onClick={() => setIsOpen(true)}
      onBlur={onBlur}
      inputError={inputError}
      label={label}
      required={required}
      value={inputDisplay}
      placeholder={placeholder}
      disabled={disabled}
      iconClassName={iconClassName}
      showInput={showInput}
      className={className}
      allowInputEdit
      maxlength={maxlength}
      icon="caret-down"
      searchLink={searchLink}
    />
  );
};

export default ContactSelect;
