import {
  forEach,
  size,
  trim,
  map,
  keyBy,
  head,
  orderBy,
  filter,
  groupBy,
} from 'lodash';
import type { Dictionary } from 'lodash';
import type { Territory, TerritoryLocation } from 'models/Territory';

export const mapLocations = (
  rollupTree: Territory[] = [],
  parent = '',
  depth = 0
): TerritoryLocation[] => {
  let result: TerritoryLocation[] = [];
  forEach(
    rollupTree,
    ({
      mi_loc,
      SHORT_NAME,
      LOC_TYPE,
      team_id,
      hasRealmAccess,
      hasRollupAccess,
      PERCEIVED_USER_ROLE,
      items,
      id,
    }) => {
      result.push({
        miLoc: mi_loc,
        locationType: LOC_TYPE,
        teamId: trim(team_id),
        name: trim(SHORT_NAME),
        userRole: trim(PERCEIVED_USER_ROLE),
        parent,
        hasAccess: hasRealmAccess || hasRollupAccess,
        hasRollupAccess,
        hasChildren: size(items) > 0,
        depth,
        id,
      });
      result = result.concat(mapLocations(items, mi_loc, depth + 1));
    }
  );
  return result;
};

export const getLocationTree = (
  rollupTree?: Territory[]
): Dictionary<TerritoryLocation> =>
  keyBy(
    map(mapLocations(rollupTree), (item, index) => ({ ...item, order: index })),
    'miLoc'
  );

export const getFirstAccessibleLocation = (
  locationTree: Dictionary<TerritoryLocation>
): TerritoryLocation | undefined =>
  head(
    orderBy(
      filter(locationTree, { hasAccess: true }),
      ['hasRollupAccess', 'depth', 'order'],
      ['desc', 'asc', 'asc']
    )
  );

export const getFallbackDeepestLocation = (
  locationTree: Dictionary<TerritoryLocation>
): TerritoryLocation | undefined => {
  let fallbackItem: TerritoryLocation | undefined;
  const locationGroups = orderBy(
    groupBy(
      filter(
        locationTree,
        ({ hasAccess, locationType }) =>
          hasAccess === true && !['VT', 'T'].includes(locationType)
      ),
      'depth'
    ),
    ['depth'],
    ['asc']
  );

  locationGroups.forEach((locationItemArray) => {
    if (
      locationItemArray.length === 1 &&
      (!fallbackItem ||
        (fallbackItem.depth < locationItemArray[0].depth &&
          locationItemArray[0].hasAccess))
    ) {
      // eslint-disable-next-line prefer-destructuring
      fallbackItem = locationItemArray[0];
    }
  });
  return fallbackItem;
};
