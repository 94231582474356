import React from 'react';
import { useTranslation } from 'react-i18next';
import { defaultExpectedDate } from 'constants/platformSpecificConstants';
import { isEmpty, size, toNumber } from 'lodash';
import { IonCol, IonRow } from '@ionic/react';
import Alert, { AlertVariantEnum } from 'common/components/Alert/Alert';
import { FormikInput } from 'common/components/Forms/Input/Input';
import { useScrollIntoFieldError } from 'common/utils/formHelpers';
import { scaleNumber } from 'common/utils/numberHelper';
import { getUnixTime } from 'date-fns';
import { useFormikContext } from 'formik';
import RepairShopSelect from 'ProductSearchApp/components/FastFind/RepairShopSelect';
import UomTypeSelect from 'ProductSearchApp/components/FastFind/UomTypeSelect';
import useGetMtqOrMoqMessage from 'ProductSearchApp/hooks/useGetMtqOrMoqMessage';
import type { CartOrderItem, Order } from 'ProductSearchApp/models/Order';
import {
  qtyScaleProps,
  type EditOrderLineForm,
} from 'ProductSearchApp/pages/EditOrderLine/EditOrderLineSchema';
import {
  getDisableByShopSourcing,
  getItemShopSourcing,
} from 'ProductSearchApp/util/ocnHelpers';
import { DateFormatEnum, formatDate, parseDate } from 'utils/date';
import DateInput from 'components/DateInput/DateInput';
import Spinner from 'components/Spinner/Spinner';
import Text from 'components/Text/Text';
import classes from './EditOrderLine.module.scss';

interface DetailsFormProps {
  order?: Order;
  miLoc: string;
  lineItem?: CartOrderItem;
  isLoading?: boolean;
  onAutoReprice?: () => void;
}

const DetailsForm = ({
  order,
  miLoc,
  lineItem,
  isLoading,
  onAutoReprice,
}: DetailsFormProps): JSX.Element => {
  const { t } = useTranslation();
  const namespace = 'productSearch:ocn';
  const checkoutNamespace = 'productSearch:checkout';
  const reviewNamespace = 'productSearch:review';

  const {
    values,
    errors,
    isSubmitting,
    setFieldValue,
    validateField,
    setFieldTouched,
  } = useFormikContext<EditOrderLineForm>();

  useScrollIntoFieldError('details-form', isSubmitting);

  const shopLocations = getItemShopSourcing(lineItem);
  const isDefaultExpectedDate = values.promsdDelivDt === defaultExpectedDate;
  const disableByShopSourcing = getDisableByShopSourcing(order, lineItem);

  const { minOrderQtyMessage } = useGetMtqOrMoqMessage({
    transferMinQty: lineItem?.transferMinQty,
    quantity: toNumber(values.quantity),
    minimumOrderQty: lineItem?.minimumOrderQty,
    dcDefaultFlag: lineItem?.dcDefaultFlag,
    brDefaultFlag: lineItem?.brDefaultFlag,
    orderIncrement: lineItem?.orderIncrement,
  });

  return (
    <div className={classes.tabContent} data-testid="details-form">
      <Spinner
        text={t('common:updating')}
        showSpinner={isLoading}
        testid="details-loader"
      />
      <div className={classes.tabWrapper}>
        <div className={classes.formWrapper}>
          <Text
            text={t(`${namespace}:ocnLineDetails`)}
            variant="mipro-h3-headline"
          />
          {!isEmpty(shopLocations) &&
            (size(shopLocations) > 1 ? (
              <IonRow className={classes.labelRow}>
                <Text
                  variant="mipro-body-copy-bold"
                  text={t(`${namespace}:repairShop`)}
                  textQuery={t(`${namespace}:repairShop`)}
                />
                <Text
                  variant="mipro-body-copy"
                  text={t(`${namespace}:multipleRepairShop`)}
                  testid="repair-shop-line"
                />
              </IonRow>
            ) : (
              <RepairShopSelect
                className={classes.input}
                miLoc={miLoc}
                label={t('productSearch:zCodedItem:repairShopSelect')}
                modalTitle={t(
                  `productSearch:zCodedItem:repairShopSelectModalTitle`
                )}
                selItem={values.repairShop}
                setRepairShop={(item) => {
                  setFieldValue('repairShop', item.key ? item : '');
                  setTimeout(() => validateField('repairShop'));
                }}
                onClose={() => setFieldTouched('repairShop', true)}
                disabled={isLoading || !values.isUnfinished}
                inputError={errors.repairShop}
                required={values.isUnfinished}
              />
            ))}
          <FormikInput
            className={classes.input}
            label={t('product:csn')}
            name="csn"
            disabled={isLoading}
            toUpperCase
            testid="csn-input"
          />
          <FormikInput
            className={classes.input}
            label={t(`${namespace}:extendedDescriptionInput`)}
            name="custComment"
            maxlength={40}
            disabled={isLoading}
            toUpperCase
            testid="description-input"
          />
          <IonRow className={classes.formRow}>
            <IonCol>
              <FormikInput
                label={t(`${namespace}:qty`)}
                name="quantity"
                disabled={isLoading || disableByShopSourcing}
                required
                numberMask={qtyScaleProps}
                onBlur={() => {
                  const newQuantity = scaleNumber({
                    number: values.quantity,
                    ...qtyScaleProps,
                  });
                  if (
                    values.autoReprice &&
                    newQuantity !== values.originalQuantity
                  ) {
                    onAutoReprice?.();
                  }
                }}
                testid="quantity-input"
              />
            </IonCol>
            <IonCol>
              <UomTypeSelect
                label={t(`${namespace}:uom`)}
                modalTitle={t(`${namespace}:uomTypeSelect`)}
                setUomTypeCode={(item) => {
                  setFieldValue('uom', item.key ? item : '');
                }}
                disabled={!values.isZCoded || isLoading}
                selItem={values.uom}
              />
            </IonCol>
          </IonRow>
          {isEmpty(errors.quantity) && !isEmpty(minOrderQtyMessage) && (
            <Alert
              testid="min-order-qty-alert"
              text={{
                text: minOrderQtyMessage,
                testid: 'content-small',
              }}
              variant={AlertVariantEnum.warning}
              className={classes.error}
            />
          )}
          <DateInput
            className={classes.dateInput}
            name="promsdDelivDt"
            value={getUnixTime(parseDate(values.promsdDelivDt))}
            setValue={(date) => {
              setFieldValue(
                'promsdDelivDt',
                formatDate(date, DateFormatEnum.standardDate)
              );
            }}
            label={t(`${checkoutNamespace}:expectedDt`)}
            placeholder={
              isDefaultExpectedDate ? t(`${reviewNamespace}:notSet`) : ''
            }
            minDate={new Date()}
            disabled={isLoading}
            testid="expected-date-input"
          />
        </div>
      </div>
    </div>
  );
};

export default DetailsForm;
