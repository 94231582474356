import { useMemo } from 'react';
import type { AxiosError } from 'axios';
import { size, toString, filter } from 'lodash';
import { useInfiniteQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { doConcatDataPages, doPromiseAPI, useKeyUserId } from 'api/helpers';
import type {
  Announcement,
  AnnouncementAPIResponse,
} from 'models/Announcement';
import type { InfiniteQueryFlags } from 'models/Search';
import { pageSize } from 'utils/constants';

export const findAnnouncementsQueryKey = 'announcements';
export const findAnnouncementsObjectKey = 'announcements';

interface UseFindAnnouncementsProps {
  limit?: number;
  read?: string;
}

interface UseFindAnnouncementsResponse extends AnnouncementAPIResponse {
  unreadAnnouncements: Announcement[];
  readAnnouncements: Announcement[];
}

const useFindAnnouncements = ({
  limit = pageSize(),
  read = 'all',
}: UseFindAnnouncementsProps): UseFindAnnouncementsResponse &
  InfiniteQueryFlags => {
  const { axios } = useAxios();
  const { findAnnouncementsAPI } = useAPIUrl();
  const params = { limit: toString(limit), read };
  const { createQueryKey } = useKeyUserId();

  const doFindAnnouncements = ({ pageParam = 1 }) => {
    return doPromiseAPI<AnnouncementAPIResponse>(async (cancelToken) => {
      const { data } = await axios.get<AnnouncementAPIResponse>(
        findAnnouncementsAPI(
          toString(
            new URLSearchParams({ ...params, page: toString(pageParam) })
          )
        ),
        { cancelToken }
      );
      return data;
    });
  };

  const response = useInfiniteQuery<AnnouncementAPIResponse, AxiosError>(
    createQueryKey(findAnnouncementsQueryKey, { limit, read }),
    doFindAnnouncements,
    {
      getNextPageParam: (lastPage, pages) =>
        size(lastPage.announcements) < limit ? false : size(pages) + 1,
    }
  );

  const {
    data,
    error,
    status,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = response;

  const announcements = useMemo(
    () =>
      doConcatDataPages<Announcement, AnnouncementAPIResponse>(
        data,
        findAnnouncementsObjectKey
      ),
    [data]
  );

  const unreadAnnouncements = filter(announcements, ({ READ }) => READ === 'N');
  const readAnnouncements = filter(announcements, ({ READ }) => READ === 'Y');

  const hasItems = size(announcements) > 0;
  const hasError = status === 'error';
  const isEmptyResponse = status === 'success' && !hasNextPage && !hasItems;
  const noMoreData = status === 'success' && !hasNextPage && hasItems;
  const showLoader = status === 'loading' || isFetchingNextPage;
  const enableInfiniteScroll = !(!hasNextPage || isFetchingNextPage);

  return {
    counts: data?.pages[0].counts || { read: 0, unread: 0 },
    fetchNextPage: async () => {
      await fetchNextPage();
    },
    announcements,
    unreadAnnouncements,
    readAnnouncements,
    error,
    hasError,
    showLoader,
    isEmptyResponse,
    noMoreData,
    enableInfiniteScroll,
    refetch: async () => {
      await refetch();
    },
  };
};

export default useFindAnnouncements;
