import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AppIcons from 'constants/appIcons';
import classNames from 'classnames';
import { get, isNil, map, size, toString } from 'lodash';
import useFindActivityNotes from 'ActivitiesApp/api/notes/useFindActivityNotes';
import { goToActivityAddEditNoteURL } from 'ActivitiesApp/navigation/navigationHelpers';
import { namespaces } from 'i18n/i18n.constants';
import type { RootState } from 'store/reducers';
import { getErrorMessage } from 'utils/helpers';
import { findIcon } from 'utils/icons';
import Button from 'components/Button/Button';
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll';
import Loader from 'components/Loader/Loader';
import type { SwipeOption } from 'components/Slider/Slider';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import ActivityNotebook from './ActivityNotebook';
import classes from './ActivityNotesList.module.scss';

interface ActivityNotesListProps {
  historyId: number;
  eventUserId?: string;
  className?: string;
  miLoc: string;
  custNo: string;
  userId: string;
}

const ActivityNotesList = ({
  historyId,
  eventUserId,
  className,
  miLoc,
  custNo,
  userId,
}: ActivityNotesListProps): JSX.Element => {
  const {
    activityNotes,
    error,
    fetchNextPage,
    hasError,
    showLoader,
    enableInfiniteScroll,
    isEmptyResponse,
  } = useFindActivityNotes({
    historyId,
    eventUserId,
  });
  const { userInfo } = useSelector((state: RootState) => state.user);
  const { t } = useTranslation(namespaces.notes);

  const loggedInUserId = get(userInfo, 'userid', '');

  const isMatchedLoggedInOwner = (activityNoteUser: string) =>
    activityNoteUser === loggedInUserId;

  const onDeleteOption: SwipeOption = {
    icon: AppIcons.doneIcon(false),
    text: t('common:delete'),
    className: 'deleteSlider',
  };

  return (
    <div>
      {!isNil(historyId) && (
        <div className={classNames(classes.card, className)}>
          <div className={classes.cardContent}>
            <div className={classes.header}>
              <Text
                text={t('notes')}
                className={classes.title}
                testid="note-list-header"
              />
              <Button
                icon={findIcon('sticky-note')}
                testid="add-note-button"
                slot="end"
                text={t('addNote')}
                className={classes.addNote}
                href={goToActivityAddEditNoteURL({
                  miLoc,
                  customerId: custNo,
                  historyId: toString(historyId),
                  userId,
                  eventNoteId: 'add',
                })}
              />
            </div>
          </div>
          {size(activityNotes) > 0 && (
            <div className={classes.items}>
              {map(activityNotes, (note) => (
                <ActivityNotebook
                  notebook={note}
                  custNo={custNo}
                  key={note.eventNoteId}
                  onSwipeLeft={
                    isMatchedLoggedInOwner(note.userId)
                      ? onDeleteOption
                      : undefined
                  }
                />
              ))}
            </div>
          )}
          {hasError && (
            <WarningMessage
              className={classes.warningMessage}
              title={t('errorLoadingNotes')}
              body={getErrorMessage(error)}
            />
          )}
          {isEmptyResponse && (
            <WarningMessage
              className={classes.warningMessage}
              icon={['far', 'info-circle']}
              title={t('noActivityNotes')}
            />
          )}
          <Loader
            className={classes.loader}
            text={t('loadingActivityNotes')}
            isOpen={showLoader}
          />
          <InfiniteScroll
            threshold="0px"
            disabled={!enableInfiniteScroll}
            onLoadMore={fetchNextPage}
            testid="infinite-scroll"
          />
        </div>
      )}
    </div>
  );
};
export default ActivityNotesList;
