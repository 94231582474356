import { useMemo } from 'react';
import type { AxiosError } from 'axios';
import type { Dictionary } from 'lodash';
import { size, toString } from 'lodash';
import { useInfiniteQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { doConcatDataPages, doPromiseAPI, useKeyUserId } from 'api/helpers';
import type { ActivityNote } from 'models/Notebook';
import type { InfiniteQueryFlags } from 'models/Search';
import { pageSize } from 'utils/constants';

export const findActivityNotesQueryKey = 'activity-notes';

interface UseActivityNotesProps {
  historyId?: number;
  eventUserId?: string;
  sortCol?: string;
  sortDir?: string;
  enabled?: boolean;
  limit?: number;
}

interface UseActivityNotesResponse {
  activityNotes: ActivityNote[];
}

const useFindActivityNotes = ({
  historyId,
  eventUserId,
  sortCol = 'lastUpdatedTimestamp',
  sortDir = 'DESC',
  enabled = true,
  limit = pageSize(),
}: UseActivityNotesProps): InfiniteQueryFlags & UseActivityNotesResponse => {
  const { axios } = useAxios();
  const { findActivityNotesAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();

  const params: Dictionary<string> = {
    historyId: toString(historyId),
    showAttachments: 'true',
    sortCol,
    sortDir,
    limit: toString(limit),
  };
  if (eventUserId) {
    params.userid = eventUserId;
  }

  const doFindActivityNotes = ({ pageParam = 1 }) => {
    return doPromiseAPI<ActivityNote[]>(async (cancelToken) => {
      const { data } = await axios.get<ActivityNote[]>(
        findActivityNotesAPI(
          toString(
            new URLSearchParams({ ...params, page: toString(pageParam) })
          )
        ),
        { cancelToken }
      );
      return data;
    });
  };

  const response = useInfiniteQuery<ActivityNote[], AxiosError>(
    createQueryKey(findActivityNotesQueryKey, { ...params }),
    doFindActivityNotes,
    {
      enabled,
      getNextPageParam: (lastPage, pages) =>
        size(lastPage) < limit ? false : size(pages) + 1,
    }
  );

  const {
    data,
    error,
    status,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = response;

  const activityNotes = useMemo(
    () => doConcatDataPages<ActivityNote>(data),
    [data]
  );

  const hasItems = size(activityNotes) > 0;
  const hasError = status === 'error';
  const isEmptyResponse = status === 'success' && !hasNextPage && !hasItems;
  const noMoreData = status === 'success' && !hasNextPage && hasItems;
  const showLoader = status === 'loading' || isFetchingNextPage;
  const enableInfiniteScroll = !(!hasNextPage || isFetchingNextPage);

  return {
    fetchNextPage: async () => {
      if (enabled && hasNextPage && !isFetchingNextPage) {
        await fetchNextPage();
      }
    },
    activityNotes,
    error,
    hasError,
    showLoader,
    isEmptyResponse,
    noMoreData,
    enableInfiniteScroll,
    refetch: async () => {
      await refetch();
    },
  };
};

export default useFindActivityNotes;
