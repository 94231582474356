import { useMemo } from 'react';
import type { AxiosError } from 'axios';
import { head, map, size, toNumber, toString } from 'lodash';
import { useInfiniteQuery } from '@tanstack/react-query';
import type { QueryFunctionContext } from '@tanstack/react-query';
import type { QueryParamsType } from 'common/api/utils/useGetQueryFlags';
import { and, or } from 'common/utils/logicHelpers';
import { fromUnixTime, getUnixTime, isThisMonth, isToday } from 'date-fns';
import { useAxios } from 'providers/AxiosProvider';
import useEnabledByUser from 'ReportsApp/hooks/useEnabledByUser';
import useGetApiUrl from 'ReportsApp/hooks/useGetAPIUrl';
import {
  doConcatDataPages,
  doGetIsLoading,
  useKeyUserId,
  useMiLocOrTeamId,
} from 'api/helpers';
import useGetUserConfig from 'api/user/useGetUserConfig';
import type {
  DateSegmentType,
  DrillDownItem,
  GetReportSummaryResponse,
  ProfitTrend,
  ReportDrillDownItem,
  ReportLineData,
  SalesTrend,
  SummaryItemOutput,
} from 'models/Reports';
import type { InfiniteQueryFlags } from 'models/Search';
import { SortDirEnum } from 'models/Sort';
import { pageSize } from 'utils/constants';
import useGetLocationCurrency from 'utils/currency';
import { DateFormatEnum, formatDate } from 'utils/date';
import { getTrendData, transformDrillDownRows } from 'utils/reports';
import type {
  SalesReportData,
  SalesReportTrendRow,
  UseGetSalesProps,
} from './useGetSalesDashboard';
import { mapGroupBy, mapSortField } from './useGetSalesDashboard';

export const getSalesReportQueryKey = 'sales-report';
export const usdRequested = (cuurencyType?: string) => cuurencyType === 'USD';

export const getParamsGroupBy = (groupBy?: string) => {
  const propsGroupBy = toString(groupBy);
  return or(mapGroupBy[propsGroupBy], propsGroupBy);
};

// TODO use this enum in a later refactor
// export interface SalesReportGroupByEnum {
//   EXEC: 'EXEC';
//   CORPORATION: 'CORPORATION';
//   GROUP: 'GROUP';
//   DIVISION: 'DIVISION';
//   BRANCH: 'BRANCH';
//   CUSTOMER: 'CUSTOMER';
//   TEAM: 'TEAM';
//   REP: 'REP';
//   PGC1: 'PGC1';
//   PGC2: 'PGC2';
// }

interface GetReportDrillDownData {
  totals?: SummaryItemOutput[];
  items: ReportDrillDownItem[];
  summaryData?: GetReportSummaryResponse;
  prevSummaryData?: GetReportSummaryResponse;
}

interface UseGetSalesReportResponse {
  totalRows?: number;
  totalsData?: SummaryItemOutput[];
  drilldownData: ReportDrillDownItem[];
  summaryData?: GetReportSummaryResponse;
  prevSummaryData?: GetReportSummaryResponse;
}

const transformNewTrendData = (
  trendData?: SalesReportTrendRow[],
  requestType?: DateSegmentType,
  isProfit = false
) =>
  map(trendData, (row) => ({
    ...row,
    busDate: row.busDay,
    date: requestType === 'YTD' ? row.month : row.day,
    show: row.busDay !== 0,
    ...(isProfit ? {} : { dollars: row.sales }),
  }));

const getGroupSortProps = ({
  paramsGroupBy,
  summaryOnly,
  sortDir,
  sortField,
}: {
  paramsGroupBy: string;
  summaryOnly?: boolean;
  sortField: string;
  sortDir?: SortDirEnum;
  isPickorLocationPick12: boolean;
}) => {
  if (summaryOnly) {
    return {};
  }

  return summaryOnly
    ? {}
    : {
        groupBy: paramsGroupBy,
        sortField: mapSortField(sortField, paramsGroupBy),
        sortDir,
      };
};

const isUnbilled = ({
  busPeriod,
  requestType,
}: {
  busPeriod?: number;
  requestType: DateSegmentType;
}): boolean => {
  const unixBusPeriod = fromUnixTime(or(busPeriod, 0));
  const todayRequest = requestType === 'DAILY' && isToday(unixBusPeriod);
  const currentMonthRequest =
    requestType === 'MTD' && isThisMonth(unixBusPeriod);
  return or(todayRequest, currentMonthRequest);
};

const useGetSalesReport = ({
  miLoc: propsMiLoc,
  customerId,
  territory,
  pgc1,
  busPeriod,
  requestType = 'MTD',
  groupBy,
  sortField = 'name',
  sortDir = SortDirEnum.ASCENDING,
  summaryOnly,
  limit = pageSize(),
  isPick12Report = false,
  isLocationPick12Report = false,
  sendVirtualTeamId = true,
  showZeroSalesPGC = false,
  enabled: isEnabled = true,
}: UseGetSalesProps): UseGetSalesReportResponse & InfiniteQueryFlags => {
  const { axios } = useAxios();
  const { createQueryKey } = useKeyUserId();
  const { createParams, getURLParams } = useMiLocOrTeamId({
    miLoc: propsMiLoc,
    sendVirtualTeamId,
  });

  const enabled = useEnabledByUser({ enabled: isEnabled });
  const apiUrl = useGetApiUrl({ isPick12Report, isLocationPick12Report });

  const { data: currencyData } = useGetUserConfig({
    configType: 'currency',
    enabled,
  });
  const { secondaryCurrencyType: currencyType } = useGetLocationCurrency(
    currencyData?.currency
  );

  const paramsGroupBy = getParamsGroupBy(groupBy);

  const isPickorLocationPick12 = or(isPick12Report, isLocationPick12Report);

  const params: QueryParamsType = {
    ...createParams(),
    customerNo: customerId,
    territory,
    pgc1,
    requestDate: formatDate(busPeriod, DateFormatEnum.reportsDateAPI),
    requestType,
    usdRequested: usdRequested(currencyType),
    showZeroSalesPGC,
    limit,
    ...getGroupSortProps({
      sortDir,
      sortField,
      paramsGroupBy,
      summaryOnly,
      isPickorLocationPick12,
    }),
  };

  const doGetSalesReport = async ({
    pageParam = 1,
    signal,
  }: QueryFunctionContext) => {
    const { data } = await axios.get<SalesReportData>(
      apiUrl(
        getURLParams({
          ...params,
          // api doesn't support page param
          start: limit * (pageParam - 1),
        })
      ),
      { signal }
    );
    const isUnbilledAvail = isUnbilled({ requestType, busPeriod });
    const summary = data?.summary;
    const dataCurrencyType = or(summary?.currency, currencyType);
    const trendData = transformNewTrendData(data.trend?.current, requestType);
    const profitTrendData = transformNewTrendData(
      data.trend?.current,
      requestType,
      true
    );
    const prevTrendData = transformNewTrendData(
      data.trend?.previous,
      requestType
    );
    const profitPrevTrendData = transformNewTrendData(
      data.trend?.previous,
      requestType,
      true
    );

    return {
      summaryData: {
        sales: [
          {
            Name: 'Sales',
            amount: or(summary?.currentSales, 0),
            change: or(summary?.currentSalesChangeToCurrentBusDay, 0),
            trend: getTrendData(trendData, 'dollars', 'sales'),
            currencyType: dataCurrencyType,
          },

          {
            Name: 'Orders',
            amount: toNumber(summary?.currentOrders),
            change: toNumber(summary?.currentOrdersChangeToCurrentBusDay),
            currencyType: dataCurrencyType,
          },
          ...(requestType !== 'DAILY'
            ? [
                {
                  Name: 'Avg Daily',
                  amount: toNumber(summary?.currentAvgDaily),
                  change: toNumber(
                    data.summary?.currentAvgDailyChangeToCurrentBusDay
                  ),
                  currencyType: dataCurrencyType,
                },
              ]
            : []),
          ...(isUnbilledAvail
            ? [
                {
                  Name: 'Unbilled',
                  amount: toNumber(data.summary?.summaryBilling?.sales),
                  change: 0,
                  currencyType: dataCurrencyType,
                },
              ]
            : []),
        ],
        profit: [
          {
            Name: 'GP',
            amount: or(data.summary?.currentGp, 0),
            change: or(data.summary?.currentGpChangeToCurrentBusDay, 0),
            trend: getTrendData(profitTrendData, 'gp', 'profit'),
            currencyType: dataCurrencyType,
          },
          {
            Name: 'GP %',
            amount: toNumber(data.summary?.currentGpPercent),
            change: toNumber(
              data.summary?.currentGpPercentChangeToCurrentBusDay
            ),
            currencyType: dataCurrencyType,
          },
          ...(isUnbilledAvail
            ? [
                {
                  Name: 'Unbilled GP',
                  amount: toNumber(data.summary?.summaryBilling?.gp),
                  change: 0,
                  currencyType: dataCurrencyType,
                },
              ]
            : []),
        ],
        salesTrend: trendData as unknown as SalesTrend[],
        profitTrend: profitTrendData as unknown as ProfitTrend[],
        sameBusDay: data.datesWithSameBusDay,
        currencyType: dataCurrencyType,
        pick12CustomerCount: toString(summary?.customerPick12ActiveCount),
        totalPick12CustCount: toString(summary?.customerPick12TotalCount),
        pick12Pct: toString(summary?.customerPick12ActivePercent),
        previous: {
          gp: data.summary?.previousGp,
          sales: data.summary?.previousSales,
        } as ReportLineData,
      },
      prevSummaryData: {
        sales: [
          {
            Name: 'Sales',
            amount: toNumber(data.summary?.previousSales),
            change: 0,
            trend: getTrendData(prevTrendData, 'dollars', 'sales'),
            currencyType: dataCurrencyType,
          },
        ],
        profit: [
          {
            Name: 'GP',
            amount: toNumber(data.summary?.previousGp),
            change: 0,
            trend: getTrendData(profitPrevTrendData, 'gp', 'profit'),
            currencyType: dataCurrencyType,
          },
        ],
        salesTrend: prevTrendData as unknown as SalesTrend[],
        profitTrend: profitPrevTrendData as unknown as ProfitTrend[],
        currencyType: dataCurrencyType,
      },
      totals: transformDrillDownRows({
        ...summary,
        currencyType: dataCurrencyType,
        sales: summary?.currentSales,
        salesChange: summary?.currentSalesChangeToCurrentBusDay,
        transactions: summary?.currentOrders,
        transactionsChange: summary?.currentOrdersChangeToCurrentBusDay,
        avgDaily: summary?.currentAvgDaily,
        avgDailyChange: summary?.currentAvgDailyChangeToCurrentBusDay,
        gpAmount: summary?.currentGp,
        gpChange: summary?.currentGpChangeToCurrentBusDay,
        gpPercentAmount: summary?.currentGpPercent,
        ...(isPickorLocationPick12
          ? {
              gpPercentAmount: summary?.currentGpPercent,
              previousSales: summary?.previousSalesToCurrentBusDay,
              effectiveDate: summary?.customerPick12EffectiveDate,
              expirationDate: summary?.customerPick12ExpirationDate,
              pick12CustomerCount: summary?.customerPick12ActiveCount,
              totalPick12CustCount: summary?.customerPick12TotalCount,
              pick12Pct: summary?.customerPick12ActivePercent,
              avgSalesPerAcct: summary?.customerPick12AvgSalesPerAcct,
            }
          : {}),
        gpPercentChange: summary?.currentGpPercentChangeToCurrentBusDay,
      } as unknown as DrillDownItem),
      items: map(data.rows, (row) => {
        const itemCurrencyType = or(row.currency, dataCurrencyType);
        const unbilled = or(row.summaryBillingSales, row.summaryBilling?.sales);
        let rowId = row.miLoc;
        let rowName = row.miLocName;
        switch (paramsGroupBy) {
          case 'REP':
            rowId = row.repNo;
            rowName = row.repName;
            break;
          case 'CUSTOMER':
            rowId = row.customerNo;
            rowName = row.customerName;
            break;
          case 'PGC1':
          case 'PGC2':
            rowId = row.pgc;
            rowName = row.pgcName;
            break;
          default:
        }

        return {
          miLoc: row.miLoc,
          id: rowId,
          Name: rowName,
          disabledDrilldown: row.disabled,
          customerPick12: and(groupBy === 'CUST', row.customerPick12),
          items: transformDrillDownRows({
            ...row,
            currencyType: itemCurrencyType,
            sales: row.currentSales,
            salesChange: row.currentSalesChangeToCurrentBusDay,
            transactions: row.currentOrders,
            transactionsChange: row.currentOrdersChangeToCurrentBusDay,
            avgDaily: row.currentAvgDaily,
            avgDailyChange: row.currentAvgDailyChangeToCurrentBusDay,
            gpAmount: row.currentGp,
            gpChange: row.currentGpChangeToCurrentBusDay,
            gpPercentAmount: row.currentGpPercent,
            ...(isPickorLocationPick12
              ? {
                  gpPercentAmount: row.currentGpPercent,
                  previousSales: row.previousSalesToCurrentBusDay,
                  effectiveDate: row.customerPick12EffectiveDate,
                  expirationDate: row.customerPick12ExpirationDate,
                  pick12CustomerCount: row.customerPick12ActiveCount,
                  totalPick12CustCount: row.customerPick12TotalCount,
                  pick12Pct: row.customerPick12ActivePercent,
                  avgSalesPerAcct: row.customerPick12AvgSalesPerAcct,
                }
              : {}),
            gpPercentChange: row.currentGpPercentChangeToCurrentBusDay,
            ...(requestType === 'DAILY'
              ? {
                  unbilled,
                }
              : {}),
          } as unknown as DrillDownItem),
        };
      }),
    };
  };

  const response = useInfiniteQuery<GetReportDrillDownData, AxiosError>(
    createQueryKey(getSalesReportQueryKey, params),
    doGetSalesReport,
    {
      enabled,
      // TODO: refactor and extract this retry logic to be resuable across the application
      retry: (failureCount, err) =>
        err.response?.status === 403 ? false : !(failureCount < 3),
      getNextPageParam: (lastPage, pages) =>
        size(lastPage.items) < limit ? false : size(pages) + 1,
    }
  );

  const {
    data,
    error,
    status,
    hasNextPage,
    isFetchingNextPage,
    dataUpdatedAt,
    refetch,
    fetchNextPage,
  } = response;

  const drilldownData = useMemo(
    () =>
      doConcatDataPages<ReportDrillDownItem, GetReportDrillDownData>(
        data,
        'items'
      ),
    [data]
  );

  // TODO use useGetQueryFlags instead
  const hasItems = size(drilldownData) > 0;
  const hasError = status === 'error';
  const isEmptyResponse = status === 'success' && !hasNextPage && !hasItems;
  const noMoreData = status === 'success' && !hasNextPage && hasItems;
  const showLoader = or(doGetIsLoading(response), isFetchingNextPage);
  const enableInfiniteScroll = !or(!hasNextPage, isFetchingNextPage);
  let lastUpdatedAt = new Date();
  if (toNumber(dataUpdatedAt) > 0) {
    lastUpdatedAt = new Date(dataUpdatedAt);
  }

  return {
    drilldownData,
    totalsData: head(data?.pages)?.totals,
    summaryData: head(data?.pages)?.summaryData,
    prevSummaryData: head(data?.pages)?.prevSummaryData,
    refetch: async () => {
      await refetch();
    },
    fetchNextPage: async () => {
      await fetchNextPage();
    },
    error: error as AxiosError,
    hasError,
    showLoader,
    isEmptyResponse,
    noMoreData,
    enableInfiniteScroll,
    lastUpdatedAt: getUnixTime(lastUpdatedAt),
  };
};

export default useGetSalesReport;
