import { useEffect, useMemo, useState } from 'react';
import type { AxiosError } from 'axios';
import {
  find,
  forEach,
  filter,
  map,
  size,
  toNumber,
  toString,
  concat,
} from 'lodash';
import * as Sentry from '@sentry/capacitor';
import {
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import useAPIUrl from 'api';
import type { QueryParamsType } from 'common/api/utils/useGetQueryFlags';
import { getUnixTime } from 'date-fns';
import useReportDB from 'DocumentsApp/database/useReportDB';
import type { FindReportsQueryParams } from 'DocumentsApp/models/FastFind';
import type { Report } from 'DocumentsApp/models/Report';
import { useAxios } from 'providers/AxiosProvider';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import { useToasts } from 'providers/ToastProvider';
import {
  doConcatDataPages,
  doGetIsLoading,
  doPromiseAPI,
  getOfflineNextPage,
  onMutateUpdate,
  onSuccessMutation,
  useKeyUserId,
  useMiLocOrTeamId,
} from 'api/helpers';
import useAccessControls, { AccessControlType } from 'hooks/useAccessControls';
import type { InspectionReport } from 'models/InspectionReport';
import type { InfiniteQueryFlags } from 'models/Search';
import { ToastType } from 'models/Toast';
import { pageSize } from 'utils/constants';
import useUpdateImages from './useUpdateImages';
import useUpdateValues, { documentListConfig } from './useUpdateValues';
import useDocumentHelpers, {
  transformFromOfflineReport,
  transformFromOnlineReport,
} from './utils/documentHelpers';

export const findDocumentsQueryKey = 'documents';
export const getDocumentQueryKey = 'document-detail';
export const reportsToSyncQueryKey = 'report-to-sync';

interface UploadReportsProps {
  reportId?: string;
  shouldRefetch?: boolean;
  shouldThrowError?: boolean;
}

interface UseFindReportsProps {
  enabled?: boolean;
  searchTerm?: string;
  reportStatus?: string;
  templateTypeId?: string;
  startDate?: string;
  endDate?: string;
  limit?: number;
}

interface UseFindReportsResponse {
  reports: InspectionReport[];
  loadingAPI?: boolean;
  reportsToSync: number;
  sync: (uploadOnly?: boolean) => Promise<number>;
  uploadReport: (props: UploadReportsProps) => Promise<string>;
  discardOffline: (reportId: string) => Promise<void>;
}

const useFindReports = ({
  enabled = true,
  searchTerm = '',
  reportStatus,
  templateTypeId = '',
  startDate = '',
  endDate = '',
  limit = pageSize(),
}: UseFindReportsProps): UseFindReportsResponse & InfiniteQueryFlags => {
  const { axios } = useAxios();
  const { findDocumentsAPI, getReportAPI, createReportAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const { createQueryKey } = useKeyUserId();
  const { createParams, getURLParams } = useMiLocOrTeamId({
    sendTeamId: false,
  });
  const {
    createReports,
    findReports,
    findReportsReadyForUpload,
    removeReportById,
    removeReportsByShopLoc,
  } = useReportDB();
  const { getDocumentErrors, handleDocumentErrors } = useDocumentHelpers();
  const [loadingAPI, setLoadingAPI] = useState(false);
  const { isOnline } = useNetworkStatus();
  const params: QueryParamsType = {
    ...createParams(),
    searchTerm,
    status: reportStatus,
    templateTypeId,
    limit,
    startDate,
    endDate,
    isOnline,
  };
  const miLoc = toString(params.miLoc);

  const { doUpdateImagesOnline } = useUpdateImages();
  const { doUpdateValuesOnline, doUpdateListOnline } = useUpdateValues();

  const doFindDocuments = ({ pageParam = 1 }) => {
    return doPromiseAPI<InspectionReport[]>(async () => {
      const reports: InspectionReport[] = map(
        (await findReports({
          ...params,
          page: toString(pageParam),
        } as FindReportsQueryParams)) || [],
        (r) => transformFromOfflineReport(r)
      );
      if (!isOnline) {
        return filter(
          reports,
          (report) =>
            report.coverPage.status === 'IP' ||
            (report.coverPage.status === 'CL' && !!report.needSync)
        );
      }
      const { data } = await axios.get<InspectionReport[]>(
        findDocumentsAPI(getURLParams({ ...params, page: pageParam }))
      );
      const readyToSyncReports = await findReportsReadyForUpload(miLoc);
      const response: InspectionReport[] = [];
      // TODO: should update offline data with list?
      forEach(data, (report) => {
        const offlineVersion = find(readyToSyncReports, {
          reportId: report.reportId,
        });
        if (offlineVersion) {
          response.unshift(transformFromOfflineReport(offlineVersion, report));
        } else {
          response.push(report);
        }
      });
      forEach(
        filter(readyToSyncReports, (report) => toNumber(report.reportId) < 0),
        (report) => {
          response.unshift(transformFromOfflineReport(report));
        }
      );
      return response;
    });
  };

  const response = useInfiniteQuery<InspectionReport[], AxiosError>(
    createQueryKey(findDocumentsQueryKey, params),
    doFindDocuments,
    {
      enabled,
      networkMode: 'always',
      getNextPageParam: getOfflineNextPage(!!isOnline),
    }
  );

  const {
    data,
    error,
    status,
    hasNextPage,
    isFetchingNextPage,
    refetch,
    fetchNextPage,
    dataUpdatedAt,
  } = response;

  const reports = useMemo(
    () => doConcatDataPages<InspectionReport>(data),
    [data]
  );

  const hasItems = size(reports) > 0;
  const isEmptyResponse = status === 'success' && !hasNextPage && !hasItems;
  const noMoreData = status === 'success' && !hasNextPage && hasItems;
  const showLoader = doGetIsLoading(response) || isFetchingNextPage;
  const enableInfiniteScroll = !(!hasNextPage || isFetchingNextPage);

  let lastUpdatedAt = new Date();
  if (toNumber(dataUpdatedAt) > 0) {
    lastUpdatedAt = new Date(dataUpdatedAt);
  }

  const documentEditPermissions = useAccessControls(
    AccessControlType.editDocuments
  );

  const onUploadReports = async ({
    reportId,
    shouldRefetch = true,
    shouldThrowError = false,
  }: UploadReportsProps) => {
    let newReportId = '';
    let offlineReport: InspectionReport;
    let newReport: Report;

    let readyToSyncReports = await findReportsReadyForUpload(miLoc);
    if (reportId) {
      readyToSyncReports = filter(readyToSyncReports, {
        reportId: toNumber(reportId),
      });
    }
    if (size(readyToSyncReports) > 0) {
      await Promise.all(
        map(readyToSyncReports, async (report) => {
          try {
            offlineReport = transformFromOfflineReport(report);
            const documentToUpload: Report = {
              templateId: report.templateId,
              templateVersion: toNumber(report.templateVersion),
              startDate: report.startDate,
              endDate: report.endDate,
              reportType: report.reportType,
              name: report.name,
              shopLoc: report.shopLoc,
              miLoc: report.miLoc,
              customerContact: report.customerContact,
              customerContactPhone: report.customerContactPhone,
              customerNo: report.customerNo,
              machineId: report.machineId,
              orderCtlNo: report.orderCtlNo,
              orderLineNo: toNumber(report.orderLineNo),
              siteId: report.siteId,
              status: report.status,
              woCtlNo: report.woCtlNo,
              creationUserId: report.creationUserId,
              creationUserName: report.creationUserName,
              creationTmstmp: report.creationTmstmp,
              lastUpdUserId: report.lastUpdUserId,
              lastUpdUserName: report.lastUpdUserName,
              lastUpdTmstmp: report.lastUpdTmstmp,
            };
            if (toNumber(report.reportId) > 0) {
              documentToUpload.reportId = report.reportId;
            }
            ({ data: newReport } = await axios.post<Report>(
              createReportAPI(),
              documentToUpload
            ));
            getDocumentErrors(newReport as unknown as AxiosError);
            newReportId = toString(newReport.reportId);

            await doUpdateValuesOnline({
              vars: {
                reportId: newReportId,
                reportValues: offlineReport.addedValues,
              },
            });
            const updateVars = { ...offlineReport, reportId: newReportId };
            const updatedParts = await doUpdateListOnline({
              vars: updateVars,
              list: 'parts',
              listId: documentListConfig.parts.id,
            });
            const updatedVasCodes = await doUpdateListOnline({
              vars: updateVars,
              list: 'vasCodes',
              listId: documentListConfig.vasCodes.id,
            });
            const updatedGenericSections = await doUpdateListOnline({
              vars: updateVars,
              list: 'genericSections',
              listId: documentListConfig.genericSections.id,
            });
            await doUpdateImagesOnline({
              removedImages: offlineReport.removedImages,
              updatedImages: offlineReport.addedImages,
              updatedObjects: concat(
                updatedParts,
                updatedVasCodes,
                updatedGenericSections
              ),
              miLoc,
              reportId: newReportId,
            });
            await removeReportById(toString(report.reportId), shouldRefetch);
          } catch (e) {
            if (shouldThrowError) {
              throw e;
            } else {
              handleDocumentErrors({
                error: e as AxiosError,
                name: report.name,
              });
            }
          }
        })
      );
    }
    return newReportId;
  };

  const onDownloadReports = async () => {
    const { data: syncData } = await axios.get<InspectionReport[]>(
      findDocumentsAPI(toString(new URLSearchParams({ ...params, limit: '' })))
    );
    const readyToSyncReports = await findReportsReadyForUpload(miLoc);
    const syncResponse: InspectionReport[] = [];
    forEach(syncData, (report) => {
      const offlineVersion = find(readyToSyncReports, {
        reportId: report.reportId,
      });
      if (offlineVersion) {
        syncResponse.push(transformFromOfflineReport(offlineVersion, report));
      } else {
        syncResponse.push(report);
      }
    });
    forEach(
      filter(readyToSyncReports, (report) => toNumber(report.reportId) < 0),
      (report) => {
        syncResponse.unshift(transformFromOfflineReport(report));
      }
    );
    const offlineReports = await findReports({
      miLoc,
    } as FindReportsQueryParams);
    await createReports(
      await Promise.all(
        map(syncResponse, async (report) => {
          const syncReport = transformFromOnlineReport(report);
          const offlineReport = find(
            offlineReports,
            (r) => r.reportId === syncReport.reportId
          );
          if (
            toNumber(report.reportId) > 0 &&
            syncReport.lastSyncDate !== offlineReport?.lastSyncDate
          ) {
            const { data: reportData } = await axios.get<InspectionReport>(
              getReportAPI(toString(report.reportId))
            );
            syncReport.partsList = reportData.partsList;
            syncReport.vasCodesList = reportData.vasCodesList;
            syncReport.genericSectionsList = reportData.genericSectionsList;
            syncReport.reportImages = reportData.reportImages;
            syncReport.reportValues = reportData.reportValues;
            syncReport.reportSignature = reportData.reportSignature;
          }
          return syncReport;
        })
      )
    );
    return size(syncData);
  };

  const { data: reportsToSync } = useQuery<number, AxiosError>(
    createQueryKey(reportsToSyncQueryKey, { miLoc }),
    async () => {
      return size(await findReportsReadyForUpload(miLoc));
    },
    { networkMode: 'always' }
  );

  useEffect(() => {
    if (enabled && !isOnline) {
      void refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, isOnline]);

  return {
    refetch: async () => {
      await refetch();
    },
    fetchNextPage: async () => {
      await fetchNextPage();
    },
    sync: async (uploadOnly = false) => {
      let totalRows = 0;
      try {
        setLoadingAPI(true);
        if (documentEditPermissions) {
          await onUploadReports({});
        }
        if (!uploadOnly) {
          totalRows = await onDownloadReports();
        }
        // TODO: is this remove necessary?
        await removeReportsByShopLoc(miLoc, true);
        void onSuccessMutation(queryClient, findDocumentsQueryKey);
        void onSuccessMutation(queryClient, reportsToSyncQueryKey);
      } catch (e) {
        addToast({
          type: ToastType.error,
          text: 'There was an error while downloading pending documents',
          testid: 'sync-error-toast',
          duration: 0,
        });
        Sentry.captureException(e);
        throw e;
      } finally {
        setLoadingAPI(false);
      }
      return totalRows;
    },
    uploadReport: async ({
      reportId,
      shouldRefetch = true,
      shouldThrowError = false,
    }: UploadReportsProps) => {
      let newReportId = '';
      if (!isOnline) {
        return newReportId;
      }
      try {
        setLoadingAPI(true);
        void onMutateUpdate<InspectionReport>({
          queryClient,
          queryKey: findDocumentsQueryKey,
          updatedItems: [
            {
              reportId: toNumber(reportId),
              needSync: false,
            } as InspectionReport,
          ],
          findPredicates: [{ reportId: toNumber(reportId) }],
          isInfiniteQuery: true,
        });
        newReportId = await onUploadReports({
          reportId,
          shouldRefetch,
          shouldThrowError,
        });
        if (shouldRefetch) {
          void onSuccessMutation(queryClient, getDocumentQueryKey);
          void onSuccessMutation(queryClient, findDocumentsQueryKey);
          void onSuccessMutation(queryClient, reportsToSyncQueryKey);
        }
      } catch (e) {
        if (shouldThrowError) {
          throw e;
        }
      } finally {
        setLoadingAPI(false);
      }
      return newReportId;
    },
    discardOffline: async (reportId: string) => {
      await removeReportById(toString(reportId));
      void onSuccessMutation(queryClient, getDocumentQueryKey);
      void onSuccessMutation(queryClient, findDocumentsQueryKey);
      void onSuccessMutation(queryClient, reportsToSyncQueryKey);
    },
    reports,
    reportsToSync: reportsToSync || 0,
    error,
    showLoader,
    loadingAPI,
    isEmptyResponse,
    noMoreData,
    enableInfiniteScroll,
    lastUpdatedAt: getUnixTime(lastUpdatedAt),
  };
};

export default useFindReports;
