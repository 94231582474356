import React from 'react';
import { useTranslation } from 'react-i18next';
import type { AxiosError } from 'axios';
import { and, ifRender } from 'common/utils/logicHelpers';
import { namespaces } from 'i18n/i18n.constants';
import { getErrorMessage } from 'utils/helpers';
import Loader from 'components/Loader/Loader';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './TabSubComponent.module.scss';

interface TabSubComponentProps {
  filterName?: string;
  isMyVisitsTab?: boolean;
  showLoader?: boolean;
  isEmptyResponse?: boolean;
  hasError?: boolean;
  error?: AxiosError | null;
}

const TabSubComponent = ({
  filterName,
  isMyVisitsTab = false,
  isEmptyResponse = false,
  showLoader = false,
  hasError = false,
  error,
}: TabSubComponentProps) => {
  const { t } = useTranslation(namespaces.activities);

  return (
    <>
      {ifRender(
        and(isEmptyResponse, !showLoader),
        <WarningMessage
          className={classes.warningMessage}
          icon={
            isMyVisitsTab
              ? ['far', 'calendar-circle-exclamation']
              : ['far', 'info-circle']
          }
          title={
            isMyVisitsTab
              ? t('emptyMyVisitsMsg', { filterName })
              : t('noActivitiesMessage')
          }
        />
      )}
      {ifRender(
        hasError,
        <WarningMessage
          className={classes.warningMessage}
          title={t('errorLoading')}
          body={getErrorMessage(error)}
        />
      )}
      <Loader
        className={classes.loader}
        text={t('loadingActivities')}
        isOpen={showLoader}
        testid="loading-activities"
      />
    </>
  );
};

export default TabSubComponent;
