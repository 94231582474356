import { useMemo } from 'react';
import type { AxiosError } from 'axios';
import type { Dictionary } from 'lodash';
import { isEmpty, map, size, toString } from 'lodash';
import { useInfiniteQuery } from '@tanstack/react-query';
import type { InfiniteQueryObserverResult } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import {
  doConcatDataPages,
  doGetIsLoading,
  doPromiseAPI,
  useKeyUserId,
  useMiLocOrTeamId,
} from 'api/helpers';
import type {
  FindCustomersAPIResponse,
  SearchItem,
  SearchResponse,
  InfiniteQueryFlags,
} from 'models/Search';
import { pageSize } from 'utils/constants';

export const findCustomersQueryKey = 'search-customers';

interface UseFindCustomersProps {
  query?: string;
  sortCol?: string;
  sortDir?: string;
  limit?: number;
  enabled?: boolean;
  nationalAccountOnly?: boolean;
}

export type UseFindCustomersResponse = SearchResponse &
  InfiniteQueryFlags &
  Pick<InfiniteQueryObserverResult<SearchResponse>, 'data'>;

const useFindCustomers = ({
  query = '',
  sortCol = 'name',
  sortDir = 'ASC',
  limit = pageSize(),
  enabled = true,
  nationalAccountOnly = false,
}: UseFindCustomersProps): UseFindCustomersResponse => {
  const { axios } = useAxios();
  const { findCustomersAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const { createParams } = useMiLocOrTeamId({
    sendTeamId: false,
    teamSearch: true,
  });
  const params: Dictionary<string | boolean> = {
    ...createParams(),
    query,
    sortCol,
    sortDir,
    limit: toString(limit),
    nationalAccountOnly,
  };

  const doFindCustomers = ({ pageParam = 1 }) => {
    return doPromiseAPI<SearchResponse>(async (cancelToken) => {
      const { data } = await axios.get<FindCustomersAPIResponse>(
        findCustomersAPI(
          toString(
            new URLSearchParams({ ...params, page: toString(pageParam) })
          )
        ),
        { cancelToken }
      );
      return {
        total: data.totalRecords,
        items: map(
          data.records,
          ({
            customerNo,
            miLoc: itemMiLoc,
            name = '',
            natlAcctNo,
            address,
            compressedName,
            customerPick12,
          }) => ({
            type: 'customer',
            miLoc: itemMiLoc,
            id: customerNo,
            customerNo,
            text: name,
            natlAcctNo,
            address,
            compressedName,
            customerPick12,
          })
        ),
      };
    });
  };

  const response = useInfiniteQuery<SearchResponse, AxiosError>(
    createQueryKey(findCustomersQueryKey, params),
    doFindCustomers,
    {
      enabled: !isEmpty(query) && enabled,
      getNextPageParam: (lastPage, pages) =>
        size(lastPage.items) < limit ? false : size(pages) + 1,
    }
  );

  const {
    data,
    error,
    status,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = response;

  const customers = useMemo(
    () => doConcatDataPages<SearchItem, SearchResponse>(data, 'items'),
    [data]
  );

  const hasItems = size(customers) > 0;
  const hasError = status === 'error';
  const isEmptyResponse = status === 'success' && !hasNextPage && !hasItems;
  const noMoreData = status === 'success' && !hasNextPage && hasItems;
  const showLoader = doGetIsLoading(response) || isFetchingNextPage;
  const enableInfiniteScroll = !(!hasNextPage || isFetchingNextPage);

  return {
    data,
    fetchNextPage: async () => {
      await fetchNextPage();
    },
    items: customers,
    total: !showLoader ? data?.pages[0].total : undefined,
    error,
    hasError,
    showLoader,
    isEmptyResponse,
    noMoreData,
    enableInfiniteScroll,
    refetch: async () => {
      await refetch?.call(null);
    },
  };
};

export default useFindCustomers;
