/* istanbul ignore file */

import type { IconName, IconProp } from '@fortawesome/fontawesome-svg-core';
import type { Address } from './Address';
import type { WithOptimisticUI } from './Search';

export enum SalesPlaysStatus {
  'ACCEPTED' = 'ACCEPTED',
  'LOST' = 'LOST',
  'NEW' = 'NEW',
  'REJECTED' = 'REJECTED',
  'WON' = 'WON',
  'ARCHIVED' = 'ARCHIVED',
  'BLANK' = '',
}

export enum SalesPlayFormat {
  'Date' = 'Date',
  'Decimal' = 'Decimal',
  'Currency' = 'Currency',
  'Percent' = 'Percent',
  'Sku' = 'Sku',
  'Boolean' = 'Boolean',
  'Text' = 'Text',
  'Item' = 'Item',
  'PGC4' = 'PGC4',
}

export enum SalesPlayTypesEnum {
  'CrossSell' = 'CrossSell',
  'UpSell' = 'UpSell',
  'Declining' = 'Declining',
  'Overdue' = 'Overdue',
  'Blank' = '',
}

export interface UpdateLeadBody {
  status?: SalesPlaysStatus;
  externalSource?: string;
  active?: string;
  externalId?: string;
  miAccount?: string;
  miLoc?: string;
  customerNo?: string;
  comments?: string;
  notesSummary?: string;
  externalAssigneeId?: string;
  noOppReason?: string;
  score?: string;
  type?: string;
  action?: string;
}

interface EventParams {
  followUp: string;
  showAfter?: string;
  done?: string;
}

export interface LeadContact extends Address {
  fullName: string;
  company: string;
  phone: string;
  email: string;
}

export interface Lead {
  status: SalesPlaysStatus;
  internalId: number;
  contacts: LeadContact[];
  creationTimestamp: string;
  comments: string;
  miAccount: string;
}

export interface UpdateSalesPlayBody {
  status?: SalesPlaysStatus;
  externalSource?: string;
  active?: string;
  externalId?: string;
  assignedUserId?: string;
  miAccount?: string;
  miLoc?: string;
  customerNo?: string;
  comments?: string;
  notesSummary?: string;
  externalAssigneeId?: string;
  noOppReason?: string;
  score?: string;
  type?: string;
  eventParams?: EventParams;
  createEvent?: boolean;
  removeEvent?: boolean;
  historyId?: number;
  showAfter?: string;
  // TODO: move this to a global definition
  undoData?: (data: UpdateSalesPlayBody) => UpdateSalesPlayBody;
  successToastText?: string;
  skipSuccessToast?: boolean;
  markAsUpdated?: boolean;
  additionalRejectText?: string;
}

export type SalesPlaysJsonSummary = {
  _format: keyof typeof SalesPlayFormat;
  label: string;
  value: string;
  description?: string;
  manufacturerName?: string;
  mfgPartNo?: string;
  key: string;
};

interface SalesPlayAction {
  primary: boolean;
  text: string;
  newStatus: SalesPlaysStatus;
  testId: string;
  noOppReason?: string;
  icon?: IconProp;
}

export interface SalesPlayStatusButtons {
  status: SalesPlaysStatus;
  active: string;
  buttons: SalesPlayAction[];
}

export interface SalesPlayDescription {
  type: SalesPlayTypesKeys;
  materialType?: 'Sku' | 'PGC4';
  manufacturerName: string;
  productGroupDescription?: string;
  materialNo?: string;
  mfgPartNo: string;
  jsonSummary?: SalesPlaysJsonSummary[];
}

export interface SalesPlay
  extends Address,
    SalesPlayDescription,
    WithOptimisticUI {
  externalSource: string;
  externalId: string;
  creationTimestamp: string;
  salesSalesPlay?: string;
  projectedValue?: string;
  historyId?: number;
  // not used
  salesOrgType?: string;
  customerLevel?: string;
  customerNo?: string;
  miLoc?: string;
  itemNo?: string;
  active?: string;
  creationSource?: string;
  updatedSource?: string;
  updatedTimestamp?: string;
  status: SalesPlaysStatus;
  salesOpportunity?: string;
  currencyType?: string;
  noOppReason?: string;
  miAccount?: string;
  customerName?: string;
  county?: string;
  itemDescription?: string;
  mfrCtlNo?: string;
  parseError?: string;
  additionalRejectText?: string;
  readOnly?: boolean;
  customerPick12?: boolean;
}

export type SalesPlayTypesKeys = keyof typeof SalesPlayTypesEnum;

export type SalesPlayType = {
  stage: string;
  oppTypes: SalesPlayTypesKeys[];
};

export type SalesPlaysTypesLegend = {
  icon: IconName;
  color?: string;
  text?: string;
};
