import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { IonMenuToggle } from '@ionic/react';
import useChangeLocation from 'hooks/useChangeLocation';
import type { RootState } from 'store/reducers';
import Text from 'components/Text/Text';
import classes from './HomeMenu.module.scss';

const UserLocation = (): JSX.Element => {
  const { isLocatorAvailable, locatorURL } = useChangeLocation();
  const { miLoc = '' } = useSelector((state: RootState) => state.user);

  return (
    <>
      <Text variant="label-header" text={miLoc} testid="home-menu-location" />
      <IonMenuToggle>
        {isLocatorAvailable && (
          <Link className={classes.setLocationButton} to={locatorURL}>
            <Text variant="content-small" text="Change" />
          </Link>
        )}
      </IonMenuToggle>
    </>
  );
};

export default UserLocation;
