import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { filter, get, isEmpty } from 'lodash';
import {
  IonContent,
  IonFooter,
  IonPage,
  IonRow,
  IonToolbar,
  useIonViewWillEnter,
} from '@ionic/react';
import useSendFeedback from 'api/feedback/useSendFeedback';
import useGoBack from 'hooks/useGoBack';
import type { MiProFile } from 'models/Attachment';
import type { RootState } from 'store/reducers';
import Attachments from 'components/Attachments/Attachments';
import Button from 'components/Button/Button';
import Header from 'components/Header/Header';
import Text from 'components/Text/Text';
import TextArea from 'components/TextArea/TextArea';
import classes from './Feedback.module.scss';

const Feedback = (): JSX.Element => {
  const { userInfo } = useSelector((state: RootState) => state.user);
  const userName = get(userInfo, 'cn', 'User Name');
  const userEmail = get(userInfo, 'email', 'User Email');

  const { goBack } = useGoBack();

  const { t } = useTranslation();

  const [userComment, setComment] = useState('');
  const [commentError, setCommentError] = useState('');

  const { onSendFeedback, status } = useSendFeedback();

  const handleKeyUp = (value: string) => {
    setComment(value);
    setCommentError('');
  };

  const [filesForUpload, setFilesForUpload] = useState<MiProFile[]>([]);
  const [filesForRemoval, setFilesForRemoval] = useState<MiProFile[]>([]);

  const handleSubmit = () => {
    if (!userComment) {
      setCommentError(t('feedback:commentRequired'));
    } else {
      onSendFeedback({
        userName,
        userEmail,
        userComment,
        filesForUpload,
      });
    }
  };

  useEffect(() => {
    if (status === 'success') {
      goBack();
    }
  }, [goBack, status]);

  useIonViewWillEnter(() => {
    setComment('');
    setCommentError('');
  });

  const isSubmitting = status === 'loading';

  const [files] = useState<MiProFile[]>([]);

  const isViewOnly = false;

  return (
    <IonPage data-testid="feedback-page">
      <Header
        title={t('feedback:provideFeedback')}
        withBackButton
        testid="feedback"
      />
      <IonContent>
        <div className={classes.feedbackContainer}>
          <div className={classes.feedbackWrapper}>
            <Text text={t('yourName')} variant="mipro-h3-headline" />
            <Text text={userName} variant="content-small" />
          </div>
          <div className={classes.feedbackWrapper}>
            <Text text={t('email')} variant="mipro-h3-headline" />
            <Text text={userEmail} variant="content-small" />
          </div>
        </div>
        <TextArea
          className={classes.textarea}
          placeholder={t('feedback:textAreaPlaceholder')}
          inputMode="text"
          label={t('comments')}
          value={userComment}
          error={commentError}
          setValue={handleKeyUp}
          testid="comments-input"
          disabled={isSubmitting}
          textVariant="mipro-h3-headline"
        />
        <div
          className={classnames(classes.attachmentWrapper, {
            [classes.isViewOnly]: isViewOnly,
          })}
        >
          <Text
            className={classes.attachmentsTitle}
            text={
              isEmpty(files) && isViewOnly
                ? t('email:noAttachments')
                : t('email:attachments')
            }
            variant="mipro-h3-headline"
          />
          <Attachments
            className={classnames({ [classes.smallSize]: isViewOnly })}
            domain="customer"
            name="customer"
            files={files}
            onAdd={(addFiles) => {
              setFilesForUpload([...(filesForUpload || []), ...addFiles]);
            }}
            onRemove={(file) => {
              const filesFiltered = filter([...filesForUpload], (i) => {
                // fileName or userFile or USER_FILE is used as the unique identifier for files
                if ('fileName' in file) {
                  return 'fileName' in file
                    ? i.fileName !== file.fileName
                    : true;
                }
                return true;
              });

              if (file.userFile || file.USER_FILE) {
                setFilesForRemoval([...(filesForRemoval || []), file]);
              }
              setFilesForUpload(filesFiltered);
            }}
            disabled={isSubmitting}
            editMode={!isViewOnly}
            enableSmallPreview
            testid="attachments"
          />
        </div>
      </IonContent>
      <IonFooter className={classes.footer}>
        <IonToolbar>
          <IonRow>
            <Button
              className={classes.sendButton}
              variant="action"
              type="submit"
              text={t('feedback:provideFeedback')}
              testid="send-feedback-button"
              onClick={handleSubmit}
              disabled={isSubmitting}
            />
          </IonRow>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default Feedback;
