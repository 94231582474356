import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { first, values } from 'lodash';
import { IonRow } from '@ionic/react';
import LostSaleSelect from 'ProductSearchApp/components/FastFind/LostSaleSelect';
import type { CartOrderItem } from 'ProductSearchApp/models/Order';
import Button from 'components/Button/Button';
import SheetModal from 'components/Modals/SheetModal/SheetModal';
import Text from 'components/Text/Text';
import classes from './DeleteOrderItem.module.scss';
import ReturnInstructions from './ReturnInstructions';

interface RomveItemProps {
  item: CartOrderItem;
  showLostSale?: boolean;
  deleteOrderLine: (lostSale: string) => void;
  isOpenRemoveItemModal: boolean;
  isOpenReturnInstructions?: boolean;
  setIsOpenRemoveItemModal: (b: boolean) => void;
  setIsOpenReturnInstructions: (b: boolean) => void;
}

const RemoveItem = ({
  item,
  showLostSale = true,
  deleteOrderLine,
  isOpenRemoveItemModal,
  isOpenReturnInstructions,
  setIsOpenRemoveItemModal,
  setIsOpenReturnInstructions,
}: RomveItemProps): JSX.Element => {
  const [lostSalesModalIsOpen, setLostSalesModalIsOpen] = useState(false);

  const namespace = 'productSearch:ocn';

  const { t } = useTranslation();

  return (
    <>
      <SheetModal
        title={t(`${namespace}:deleteItem`)}
        titleTextVariant="mipro-h3-headline"
        className={classes.deleteModal}
        titleClassName={classes.deleteTitle}
        contentClass={classes.deleteContent}
        isOpen={isOpenRemoveItemModal}
        setIsOpen={setIsOpenRemoveItemModal}
        initialBreakpoint={0.4}
        withRightCloseButton
        testid="delete-quote-modal"
      >
        <IonRow className={classes.msgRow}>
          <Text
            className={classes.deleteMsg}
            variant="mipro-body-copy"
            text={t(`${namespace}:deleteItemMsg`, {
              mfrCtlNo: item.mfrCtlNo,
              mfgPartNo: item.mfgPartNo,
            })}
          />
        </IonRow>

        <Button
          className={classNames(classes.deleteButton, classes.primaryButton, {
            [classes.deleteOption]: !showLostSale,
          })}
          variant="action"
          text={t(`${namespace}:deleteItem`)}
          onClick={() => {
            setIsOpenRemoveItemModal(false);
            deleteOrderLine('');
          }}
          testid="delete-quote-button"
        />
        {showLostSale && (
          <Button
            className={classNames(classes.deleteButton, classes.primaryButton)}
            variant="action"
            text={t(`${namespace}:lostSale`)}
            onClick={() => {
              setLostSalesModalIsOpen(true);
            }}
            testid="lost-sale-button"
          />
        )}
        <Button
          className={classNames(classes.deleteButton)}
          variant="secondary"
          text={t('common:cancel')}
          onClick={() => {
            setIsOpenRemoveItemModal(false);
          }}
          testid="delete-cancel-button"
        />
      </SheetModal>
      <LostSaleSelect
        showInput={false}
        openModal={lostSalesModalIsOpen}
        setOpenModal={setLostSalesModalIsOpen}
        setLostSalesCode={(lostSaleReason) => {
          setIsOpenRemoveItemModal(false);
          deleteOrderLine(lostSaleReason.key);
        }}
      />
      <ReturnInstructions
        isOpen={isOpenReturnInstructions}
        setIsOpen={setIsOpenReturnInstructions}
        itemSource={first(values(item.itemSourcing))}
        orderLineNo={item.orderLineNo}
      />
    </>
  );
};

export default RemoveItem;
