import React, { useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { map, size, toNumber, toString } from 'lodash';
import { IonItem, useIonViewWillEnter } from '@ionic/react';
import useFindActivityNotes from 'ActivitiesApp/api/notes/useFindActivityNotes';
import type { ActivityDetailURLParams } from 'ActivitiesApp/models/ActivityDetail';
import { goToActivity } from 'ActivitiesApp/navigation/navigationHelpers';
import List from 'common/components/List/List';
import {
  getIntervalRender,
  type BaseComponentProps,
} from 'common/components/utils/renderHelpers';
import { and, ifFunction, ifRender } from 'common/utils/logicHelpers';
import { namespaces } from 'i18n/i18n.constants';
import type { ActivityNote, UpdateActivityNoteBody } from 'models/Notebook';
import { getErrorMessage } from 'utils/helpers';
import Button from 'components/Button/Button';
import Text from 'components/Text/Text';
import Comment from './Comment';
import classes from './CommentList.module.scss';

interface CommentListProps extends BaseComponentProps {
  /* eslint-disable react/require-default-props */
  historyId?: number;
  eventUserId?: string;
  commentCount?: string;
  isLoading?: boolean;
  scrollParent?: HTMLElement | null;
  onAddComment?: () => void;
  onEditComment?: (n: UpdateActivityNoteBody) => void;
  disableComments?: boolean;
  /* eslint-enable react/require-default-props */
}

export interface CommentListRef {
  refetch?: () => void;
}

export const onScroll = (commentNode: Element) => {
  ifFunction(!!commentNode, () => {
    commentNode.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
    commentNode.setAttribute(
      'style',
      '--background:var(--mipro-color-background)'
    );
    setTimeout(() => commentNode.setAttribute('style', ''), 3000);
  });
};

const CommentList = React.forwardRef(
  (props: CommentListProps, outerRef: React.ForwardedRef<CommentListRef>) => {
    const history = useHistory();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const routeCommentId = params.get('commentId');
    const { t } = useTranslation(namespaces.comments);
    const routeParams = useParams<ActivityDetailURLParams>();
    const [scrollCommentId, setScrollCommentId] = useState('');

    const {
      className,
      historyId,
      eventUserId = '',
      commentCount = '',
      isLoading,
      scrollParent,
      onAddComment,
      onEditComment,
      disableComments = false,
    } = props;

    const {
      activityNotes,
      error,
      showLoader,
      isEmptyResponse,
      fetchNextPage,
      refetch,
    } = useFindActivityNotes({
      historyId,
      eventUserId,
      sortDir: 'ASC',
      sortCol: 'creationTimestamp',
      limit: 100,
    });
    const hasNotes = size(activityNotes) > 0;

    useIonViewWillEnter(() => {
      ifFunction(!!routeCommentId, () => {
        setScrollCommentId(toString(routeCommentId));
        history.replace(
          goToActivity({ ...routeParams, customerId: routeParams.id })
        );
      });
    }, [routeCommentId]);

    useEffect(() => {
      let interval: NodeJS.Timer;
      ifFunction(
        and(!!scrollCommentId, !showLoader, hasNotes, !isLoading),
        () => {
          const scrollToComment = () => {
            const commentNode = document.querySelector(
              `[data-testid=comment-${scrollCommentId}]`
            );
            ifFunction(!!commentNode, () => setScrollCommentId(''));
            onScroll(commentNode as Element);
          };
          interval = getIntervalRender({
            timeout: 150,
            fn: scrollToComment,
          });
        }
      );
      return () => {
        clearInterval(interval);
      };
    }, [scrollCommentId, showLoader, hasNotes, isLoading]);

    const renderComment = (item: ActivityNote) => (
      <Comment
        className={classes.commentContainer}
        key={item.eventNoteId}
        notebook={item}
        onEditComment={onEditComment}
        disabledComment={disableComments}
      />
    );

    useImperativeHandle(outerRef, () => ({ refetch }), [refetch]);

    return (
      <div>
        <IonItem detail={false} lines="none" className={classes.commentsHeader}>
          <Text
            text={t('comments', { count: toNumber(commentCount) })}
            variant="mipro-h3-headline"
          />
          <Button
            slot="end"
            icon={['far', 'comment']}
            testid="add-comment-button"
            text={t('addComment')}
            variant="link"
            textVariant="mipro-body-copy-bold"
            className={classes.addComment}
            onClick={onAddComment}
            disabled={disableComments}
          />
        </IonItem>
        {/* TUDU has to render the whole list, otherise can't scroll...
      need to look for a scroll util inside virtuoso */}
        <div className={classes.commentsWrapper}>
          {map(activityNotes, renderComment)}
        </div>
        {ifRender(
          size(activityNotes) === 0,
          <List
            className={classNames(classes.list, className)}
            data={activityNotes}
            scrollParent={scrollParent}
            isLoading={{
              isLoading: showLoader,
              text: t('comments:loading'),
            }}
            isEmptyList={{
              className: classes.emptyList,
              isEmptyList: isEmptyResponse,
              title: t('noComments'),
            }}
            isError={{
              isError: !!error,
              title: t('errorLoadingComments'),
              body: getErrorMessage(error),
            }}
            endReached={fetchNextPage}
            testid="comment-list"
          />
        )}
        <div
          style={{ height: 1, width: '100%' }}
          data-testid="comments-scroll-end"
        />
      </div>
    );
  }
);

export default CommentList;
