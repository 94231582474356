import { useTranslation } from 'react-i18next';
import type { MutationStatus } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { findOrdersQueryKey } from 'ProductSearchApp/api/orders/useFindOrders';
import { getOrderQueryKey } from 'ProductSearchApp/api/useGetOrder';
import type { CheckoutOrderForm, Order } from 'ProductSearchApp/models/Order';
import { mergeEmailRecipients } from 'ProductSearchApp/util/ocnHelpers';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import { doPromiseAPI, onSuccessMutation, useKeyUserId } from 'api/helpers';
import { ToastType } from 'models/Toast';

interface UseUpdateHeaderProps {
  miLoc: string;
  orderCtlNo: string;
  shipToCustNo: string;
  billToCustNo: string;
}

interface UseUpdateHeaderResponse {
  status: MutationStatus;
  onUpdateHeader: (body: CheckoutOrderForm) => void;
}

const useUpdateHeader = ({
  miLoc,
  orderCtlNo,
  shipToCustNo,
  billToCustNo,
}: UseUpdateHeaderProps): UseUpdateHeaderResponse => {
  const { axios } = useAxios();
  const { updateHeaderAPI } = useAPIUrl();
  const { addToast } = useToasts();
  const { createQueryKey } = useKeyUserId();
  const namespace = 'productSearch:checkout';
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const doUpdateHeader = (body: CheckoutOrderForm) => {
    return doPromiseAPI<Order>(async () => {
      const {
        shipComplete,
        handlingInst,
        shipMethodCd,
        carrierCd,
        poNumber,
        custReleaseNo,
        carrierAccount,
        excludeFromWeb,
        contactName,
        contactNumber,
        dueDate,
        shipChargesCd,
        processStatus,
        typedRecipients,
      } = body;

      const requestBody = {
        headerInfo: {
          shipComplete,
          handlingInst,
          shipChargesCd: shipChargesCd?.codeValue,
          carrierCd: carrierCd?.codeValue,
          shipMethodCd: shipMethodCd?.codeValue,
          poNumber,
          custReleaseNo,
          carrierAccount,
          excludeFromWeb,
          contactName,
          contactEmail: mergeEmailRecipients(typedRecipients),
          contactNumber,
          dueDate,
          processStatus,
        },
        customerInfo: {
          customerNo: shipToCustNo,
          miLoc,
          billToNo: billToCustNo,
        },
        orderInfo: { miLoc, orderCtlNo, shipToCustNo },
      };
      const { data } = await axios.post<Order>(updateHeaderAPI(), {
        ...requestBody,
      });
      return data;
    });
  };

  const { status, mutate } = useMutation(doUpdateHeader, {
    onSuccess: (data) => {
      void onSuccessMutation(queryClient, findOrdersQueryKey);
      // TODO create utils to update query data
      queryClient.setQueryData<Order>(
        createQueryKey(getOrderQueryKey, {
          miLoc,
          orderCtlNo,
        }),
        data
      );
    },
    onError: () => {
      addToast({
        type: ToastType.error,
        text: t(`${namespace}:updateHeaderFailureToast`),
        testid: 'order--review--success--toast',
      });
    },
  });

  return {
    status,
    onUpdateHeader: (body: CheckoutOrderForm) => mutate(body),
  };
};

export default useUpdateHeader;
