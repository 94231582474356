import { useMemo } from 'react';
import type { AxiosError } from 'axios';
import type { Dictionary } from 'lodash';
import { size, toString, toNumber, head } from 'lodash';
import { useInfiniteQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { getUnixTime } from 'date-fns';
import type { Product } from 'ProductSearchApp/models/Products';
import { useAxios } from 'providers/AxiosProvider';
import {
  doConcatDataPages,
  doGetIsLoading,
  doPromiseAPI,
  useKeyUserId,
} from 'api/helpers';
import type { InfiniteQueryFlags } from 'models/Search';
import { pageSize } from 'utils/constants';
import { withStringProp } from 'utils/helpers';

export const findProductsQueryKey = 'search-products';

interface UseFindProductsProps {
  miLoc: string;
  query: string;
  inStockOnly?: boolean;
  limit?: number;
  customerNo?: string;
  limitToBranch?: boolean;
  limitToCustomer?: boolean;
  searchMode?: string;
  returnSCAvail?: boolean;
  mfrCtlNo?: string;
  branchItemBalance?: boolean;
  enabled?: boolean;
}

interface UseFindProductsResponse {
  products: Product[];
  totalRows?: number;
}

const useFindProducts = ({
  miLoc,
  query,
  inStockOnly = false,
  limit = pageSize(),
  customerNo,
  limitToBranch = false,
  limitToCustomer = false,
  searchMode = 'smart',
  returnSCAvail = false,
  branchItemBalance = false,
  mfrCtlNo,
  enabled = false,
}: UseFindProductsProps): UseFindProductsResponse & InfiniteQueryFlags => {
  const { axios } = useAxios();
  const { findProductsAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const params: Dictionary<string> = {
    limitToBranch: toString(limitToBranch),
    inStockOnly: toString(inStockOnly),
    limit: toString(limit),
    miLoc,
    limitToCustomer: toString(limitToCustomer),
    searchMode,
    returnSCAvail: toString(returnSCAvail),
    branchItemBalance: toString(branchItemBalance),
  };

  if (customerNo) {
    params.customerNo = customerNo;
  }
  if (mfrCtlNo) {
    params.mfrCtlNo = mfrCtlNo;
  }
  if (query) {
    params.searchFor = query;
  }

  const doFindProducts = ({ pageParam = 1 }) => {
    return doPromiseAPI<UseFindProductsResponse>(async (cancelToken) => {
      const { data } = await axios.get<UseFindProductsResponse>(
        findProductsAPI(
          toString(
            new URLSearchParams({ ...params, page: toString(pageParam) })
          )
        ),
        { cancelToken }
      );
      return data;
    });
  };

  const response = useInfiniteQuery<UseFindProductsResponse, AxiosError>(
    createQueryKey(findProductsQueryKey, params),
    doFindProducts,
    {
      enabled: withStringProp(query) || enabled,
      getNextPageParam: (lastPage, pages) =>
        size(lastPage.products) < limit ? false : size(pages) + 1,
    }
  );

  const {
    data,
    error,
    status,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    dataUpdatedAt,
    refetch,
  } = response;

  let lastUpdatedAt = new Date();
  if (toNumber(dataUpdatedAt) > 0) {
    lastUpdatedAt = new Date(dataUpdatedAt);
  }

  const products = useMemo(
    () => doConcatDataPages<Product, UseFindProductsResponse>(data, 'products'),
    [data]
  );

  const hasItems = size(products) > 0;
  const hasError = status === 'error';
  const isEmptyResponse = status === 'success' && !hasNextPage && !hasItems;
  const noMoreData = status === 'success' && !hasNextPage && hasItems;
  const showLoader = doGetIsLoading(response) || isFetchingNextPage;
  const enableInfiniteScroll = !(!hasNextPage || isFetchingNextPage);

  return {
    fetchNextPage: async () => {
      await fetchNextPage();
    },
    products,
    totalRows: head(data?.pages)?.totalRows,
    error,
    hasError,
    showLoader,
    isEmptyResponse,
    noMoreData,
    enableInfiniteScroll,
    lastUpdatedAt: getUnixTime(lastUpdatedAt),
    refetch: async () => {
      await refetch();
    },
  };
};

export default useFindProducts;
