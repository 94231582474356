import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { IonPage, IonContent } from '@ionic/react';
import { namespaces } from 'i18n/i18n.constants';
import useGetInventoryAnalysis from 'api/supplier/useGetInventoryAnalysis';
import type { SnapshotsURLParams } from 'models/Search';
import { getErrorMessage, stringValue } from 'utils/helpers';
import { getSnapshotEyebrow } from 'utils/search';
import Header from 'components/Header/Header';
import Loader from 'components/Loader/Loader';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './SupplierInformation.module.scss';

const SupplierInformationAnalysis = (): JSX.Element => {
  const { id } = useParams<SnapshotsURLParams>();
  const { inventoryAnalysis, supplierData, error, isLoading } =
    useGetInventoryAnalysis({ id });
  const { t } = useTranslation(namespaces.supplier);
  return (
    <IonPage>
      <Header
        title={t('inventoryAnalysis')}
        eyebrow={getSnapshotEyebrow({ name: supplierData?.supLocName, id })}
        withBackButton
        testid="supplierInventoryAnalysis"
      />
      <IonContent className={classes.supInfo}>
        <Loader
          className={classes.loader}
          text="Loading Inventory Analysis"
          isOpen={isLoading}
        />
        {!isLoading && !error && (
          <>
            <div className={classes.infoWrapper}>
              <Text text={t('balanceOnHand')} variant="content-heavy" />
              <Text
                text={stringValue(inventoryAnalysis, 'balanceOnHand', '--')}
                variant="content-default"
              />
            </div>
            <div className={classes.infoWrapper}>
              <Text text={t('inventory')} variant="content-heavy" />
              <Text
                text={stringValue(inventoryAnalysis, 'invValue', '--')}
                variant="content-default"
              />
            </div>
            <div className={classes.infoWrapper}>
              <Text text={t('itemCount')} variant="content-heavy" />
              <Text
                text={stringValue(inventoryAnalysis, 'itemCount', '--')}
                variant="content-default"
              />
            </div>
            <div className={classes.infoWrapper}>
              <Text text={t('nonMoving')} variant="content-heavy" />
              <Text
                text={stringValue(inventoryAnalysis, 'nonMovingInvValue', '--')}
                variant="content-default"
              />
            </div>
            <div className={classes.infoWrapper}>
              <Text text={t('excess')} variant="content-heavy" />
              <Text
                text={stringValue(inventoryAnalysis, 'excessInvValue', '--')}
                variant="content-default"
              />
            </div>
            <div className={classes.infoWrapper}>
              <Text text={t('turns')} variant="content-heavy" />
              <Text
                text={stringValue(inventoryAnalysis, 'turns', '--')}
                variant="content-default"
              />
            </div>
          </>
        )}
        {error && (
          <WarningMessage
            className={classes.warningMessage}
            title={t('errorSupplierInfo')}
            body={getErrorMessage(error)}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default SupplierInformationAnalysis;
