import { useSelector } from 'react-redux';
import SentryCategories from 'constants/sentryCategories';
import { get, toString } from 'lodash';
import * as Sentry from '@sentry/capacitor';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { MutationStatus } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { findActivitiesQueryKey } from 'api/activities/useFindActivities';
import { doPromiseAPI, onMutateUpdate, onSuccessMutation } from 'api/helpers';
import { unreadQueryKey } from 'api/user/useGetUnreadNotificationsCount';
import usePushNotifications from 'hooks/usePushNotifications';
import type { ActionCardActivity } from 'models/ActivityModels';
import type { RootState } from 'store/reducers';

interface MarkActivityAsReadBody {
  historyId: number;
}
interface UseMarkActivityAsReadResponse {
  status: MutationStatus;
  onMarkActivityAsRead: (body: MarkActivityAsReadBody) => void;
}

const useMarkActivityAsRead = (): UseMarkActivityAsReadResponse => {
  const { axios } = useAxios();
  const { markActivityReadAPI } = useAPIUrl();
  const { userInfo } = useSelector((state: RootState) => state.user);
  const userId = get(userInfo, 'userid', '');
  const queryClient = useQueryClient();
  const { removeDeliveredNotifications } = usePushNotifications();

  const doMarkActivityAsRead = (body: MarkActivityAsReadBody) => {
    return doPromiseAPI(async (cancelToken) => {
      try {
        await axios.post(
          markActivityReadAPI(),
          { historyId: body.historyId },
          { cancelToken }
        );
        return { ...body, success: true };
      } catch (error) {
        return { ...body, success: false };
      }
    });
  };

  const { status, mutate } = useMutation(doMarkActivityAsRead, {
    onMutate: async (body) => {
      return onMutateUpdate<ActionCardActivity>({
        queryClient,
        queryKey: findActivitiesQueryKey,
        updatedItems: [
          { eventRead: 'Y', historyId: body.historyId } as ActionCardActivity,
        ],
        findPredicates: [{ historyId: body.historyId }],
        isInfiniteQuery: true,
        markAsUpdated: false,
      });
    },
    onSuccess: (vars) => {
      // invalidate unread when an activity is updated
      void onSuccessMutation(queryClient, unreadQueryKey);

      // remove delivered notifications
      void removeDeliveredNotifications(toString(vars.historyId), 'historyId');

      Sentry.addBreadcrumb({
        category: SentryCategories.ACTIVITIES,
        message: 'Activity marked as read successfully.',
        level: 'info',
        data: { userId, ...vars },
      });
    },
    onError: () => {
      Sentry.addBreadcrumb({
        category: SentryCategories.ACTIVITIES,
        message: 'Activity marked as read failed.',
        level: 'error',
        data: { userId },
      });
    },
  });

  return {
    status,
    onMarkActivityAsRead: (body: MarkActivityAsReadBody) => mutate(body),
  };
};

export default useMarkActivityAsRead;
