import { useMemo } from 'react';
import type { AxiosError } from 'axios';
import { head, isEmpty, map, size, toString } from 'lodash';
import { useInfiniteQuery } from '@tanstack/react-query';
import type { InfiniteQueryObserverResult } from '@tanstack/react-query';
import useAPIUrl from 'api';
import type {
  QueryFnProps,
  QueryParamsType,
} from 'common/api/utils/useGetQueryFlags';
import { useAxios } from 'providers/AxiosProvider';
import { doConcatDataPages, useKeyUserId, useMiLocOrTeamId } from 'api/helpers';
import type {
  SearchItem,
  SearchResponse,
  InfiniteQueryFlags,
} from 'models/Search';
import { pageSize } from 'utils/constants';
import type { UseFindOrdersResponse } from './useFindOrders';

export const findGlobalOCNQueryKey = 'global-ocn';

interface UseFindGlobalOrdersProps {
  query?: string;
  miLoc?: string;
  customerNo?: string;
  orderTypeCd?: string;
  processStatus?: string;
  includeDocumentInfo?: boolean;
  orderCtlNo?: string;
  startDate?: string;
  limit?: number;
  enabled?: boolean;
}

export type UseFindGlobalOrdersResponse = SearchResponse &
  InfiniteQueryFlags &
  Pick<InfiniteQueryObserverResult<SearchResponse>, 'data'>;

const useFindGlobalOrders = ({
  query = '',
  miLoc,
  customerNo = '',
  orderTypeCd = '',
  processStatus = '',
  includeDocumentInfo = false,
  orderCtlNo = '',
  startDate = '',
  limit = pageSize(),
  enabled = true,
}: UseFindGlobalOrdersProps): UseFindGlobalOrdersResponse => {
  const { axios } = useAxios();
  const { findGlobalOrdersAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const { createParams, getURLParams } = useMiLocOrTeamId({
    sendTeamId: false,
    teamSearch: true,
  });
  const tmpParams = { ...createParams() };
  const { miLoc: stateMiLoc, teamSearch } = tmpParams;
  const urlMiLoc = isEmpty(miLoc) ? stateMiLoc : miLoc;
  const params: QueryParamsType = {
    query: toString(query),
    miLoc: urlMiLoc,
    customerNo,
    orderTypeCd,
    processStatus,
    includeDocumentInfo,
    orderCtlNo,
    startDate,
    teamSearch,
    limit: toString(limit),
  };

  const doFindGlobalOrders = async ({
    pageParam = 1,
    signal,
  }: QueryFnProps) => {
    const { data } = await axios.get<UseFindOrdersResponse>(
      findGlobalOrdersAPI(getURLParams({ ...params, page: pageParam })),
      { signal }
    );
    return {
      items: map(
        data.orders,
        ({
          miLoc: orderMiLoc,
          orderCtlNo: orderOrderCtlNo,
          shipToCustNo,
          ...rest
        }) => ({
          type: 'ocns',
          id: `${orderMiLoc || ''}${orderOrderCtlNo}`,
          text: `${orderMiLoc || ''}${shipToCustNo} ${orderOrderCtlNo}`,
          orderCtlNo: orderOrderCtlNo,
          miLoc: orderMiLoc,
          shipToCustNo,
          ...rest,
        })
      ),
      total: data.totalRows,
    } as SearchResponse;
  };

  const response = useInfiniteQuery<SearchResponse, AxiosError>(
    createQueryKey(findGlobalOCNQueryKey, params),
    doFindGlobalOrders,
    {
      enabled,
      getNextPageParam: (lastPage, pages) =>
        size(lastPage.items) < limit ? false : size(pages) + 1,
    }
  );

  const {
    data,
    error,
    status,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = response;

  const orders = useMemo(
    () => doConcatDataPages<SearchItem, SearchResponse>(data, 'items'),
    [data]
  );
  const hasItems = !isEmpty(orders);
  const hasError = status === 'error';
  const isEmptyResponse = status === 'success' && !hasNextPage && !hasItems;
  const noMoreData = status === 'success' && !hasNextPage && hasItems;
  const showLoader = status === 'loading' || isFetchingNextPage;
  const enableInfiniteScroll = !(!hasNextPage || isFetchingNextPage);
  return {
    data,
    fetchNextPage: async () => {
      await fetchNextPage();
    },
    items: orders,
    total: head(data?.pages)?.total,
    error,
    hasError,
    showLoader,
    isEmptyResponse,
    noMoreData,
    enableInfiniteScroll,
    refetch: async () => {
      await refetch?.call(null);
    },
  };
};

export default useFindGlobalOrders;
