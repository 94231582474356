import type { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { doPromiseAPI, useKeyUserId } from 'api/helpers';
import type { QueryFlags } from 'models/Search';

export const getAttachmentQueryKey = 'attachment';

interface UseGetAttachmentProps {
  domain: string;
  miLoc: string;
  ctlNo: string;
  lineNo: string;
  seqNo: string;
  fileName: string;
  width?: number;
  height?: number;
  enabled?: boolean;
}

interface UseGetAttachmentResponse {
  data?: string;
}

const useGetAttachment = ({
  domain,
  miLoc,
  ctlNo,
  lineNo,
  seqNo,
  fileName,
  width,
  height,
  enabled = true,
}: UseGetAttachmentProps): UseGetAttachmentResponse & QueryFlags => {
  const { axios } = useAxios();
  const { getAttachmentThumbnailAPI, getAttachmentImageAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();

  const doGetAttachment = () => {
    return doPromiseAPI<string>(async (cancelToken) => {
      const response = await axios.get<Blob>(
        width && height
          ? getAttachmentThumbnailAPI(
              domain,
              miLoc,
              ctlNo,
              lineNo,
              seqNo,
              fileName,
              width,
              height
            )
          : getAttachmentImageAPI(
              domain,
              miLoc,
              ctlNo,
              lineNo,
              seqNo,
              fileName
            ),
        {
          responseType: 'blob',
          cancelToken,
        }
      );
      const reader = new FileReader();
      reader.readAsDataURL(response.data);
      return new Promise<string>((resolve) => {
        reader.onloadend = () => {
          const base64data = reader.result as string;
          resolve(base64data);
        };
      });
    });
  };

  const { data, error, isLoading } = useQuery<string, AxiosError>(
    createQueryKey(getAttachmentQueryKey, {
      miLoc,
      ctlNo,
      lineNo,
      seqNo,
      fileName,
      width,
      height,
      domain,
    }),
    doGetAttachment,
    {
      enabled:
        enabled && !!miLoc && !!ctlNo && !!lineNo && !!seqNo && !!fileName,
    }
  );

  return { data, error, isLoading };
};

export default useGetAttachment;
