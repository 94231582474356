import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { get, toString } from 'lodash';
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonLabel,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import type { AuthStateEvent } from 'providers/AuthStateProvider';
import { AuthStateEvents } from 'providers/AuthStateProvider';
import { findIcon } from 'utils/icons';
import Button from 'components/Button/Button';
import classes from './AuthPinModal.module.scss';

interface AuthPinModalProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDismiss: (opts: { data: any; role?: string }) => Promise<void>;
  setPasscodeMode: boolean;
  send?: (event: AuthStateEvent) => void;
  event?: AuthStateEvent;
}

const AuthPinModal = ({
  onDismiss,
  setPasscodeMode,
  send,
  event,
}: AuthPinModalProps): JSX.Element => {
  const { t } = useTranslation();

  const [title, setTitle] = useState<string>('');
  const [prompt, setPrompt] = useState<string>('');
  const [displayPin, setDisplayPin] = useState<string>('');
  const [verifyPin, setVerifyPin] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [disableEnter, setDisableEnter] = useState<boolean>(false);
  const [disableInput, setDisableInput] = useState<boolean>(false);
  const [disableDelete, setDisableDelete] = useState<boolean>(false);
  const [pin, setPin] = useState<string>('');

  const initSetPasscodeMode = () => {
    setTitle('Create PIN');
    setVerifyPin('');
    setDisplayPin('');
    setPin('');
  };

  const initUnlockMode = () => {
    setPrompt('Enter PIN');
    setTitle('Unlock');
    setDisplayPin('');
    setPin('');
  };

  const initVerifyMode = () => {
    setPrompt('Verify PIN');
    setVerifyPin(pin);
    setDisplayPin('');
    setPin('');
  };

  useEffect(() => {
    setDisableEnter(pin.length < 4);
    setDisableDelete(!pin.length);
    setDisableInput(!!(pin.length > 8));
    setDisplayPin('*********'.slice(0, pin.length));
  }, [pin]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (setPasscodeMode) {
      initSetPasscodeMode();
    } else {
      return initUnlockMode();
    }
  }, [setPasscodeMode]);

  const appendPin = (n: number) => {
    setError('');
    setPin(pin.concat(n.toString()));
  };

  const truncate = () => {
    if (pin.length) {
      setPin(pin.slice(0, pin.length - 1));
    }
  };

  const enter = async () => {
    if (setPasscodeMode) {
      if (!verifyPin.length) {
        initVerifyMode();
      } else if (verifyPin === pin) {
        await onDismiss({ data: pin });
        const motionToken = toString(get(event, 'payload.motionToken'));
        if (send && motionToken) {
          send({
            type: AuthStateEvents.SAVE_VAULT_CREDENTIALS,
            payload: {
              motionToken,
            },
          });
        }
      } else {
        setError(t('common:pinMisMatch'));
        initSetPasscodeMode();
      }
    } else {
      await onDismiss({ data: pin });
    }
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{title}</IonTitle>
          {!setPasscodeMode && (
            <IonButtons slot="primary">
              <Button
                className={classes.closeButton}
                icon={findIcon('times')}
                onClick={async () =>
                  onDismiss({ data: undefined, role: 'cancel' })
                }
                testid="pin-dialog-close-button"
              />
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding ion-text-center">
        <IonLabel>
          <div className={classes.prompt}>{prompt}</div>
        </IonLabel>
        <IonLabel>
          <div className={classes.pin}>{displayPin}</div>
        </IonLabel>
        <IonLabel>
          <div className={classes.error}>{error}</div>
        </IonLabel>
      </IonContent>
      <IonFooter>
        <IonGrid>
          <IonRow>
            {[1, 2, 3].map((n) => (
              <IonCol key={n}>
                <IonButton
                  expand="block"
                  fill="outline"
                  onClick={() => appendPin(n)}
                  disabled={disableInput}
                >
                  {n}
                </IonButton>
              </IonCol>
            ))}
          </IonRow>
          <IonRow>
            {[4, 5, 6].map((n) => (
              <IonCol key={n}>
                <IonButton
                  expand="block"
                  fill="outline"
                  onClick={() => appendPin(n)}
                  disabled={disableInput}
                >
                  {n}
                </IonButton>
              </IonCol>
            ))}
          </IonRow>
          <IonRow>
            {[7, 8, 9].map((n) => (
              <IonCol key={n}>
                <IonButton
                  expand="block"
                  fill="outline"
                  onClick={() => appendPin(n)}
                  disabled={disableInput}
                >
                  {n}
                </IonButton>
              </IonCol>
            ))}
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton
                color="tertiary"
                expand="block"
                onClick={() => truncate()}
                disabled={disableDelete}
              >
                Delete
              </IonButton>
            </IonCol>
            <IonCol>
              <IonButton
                expand="block"
                fill="outline"
                onClick={() => appendPin(0)}
                disabled={disableInput}
              >
                0
              </IonButton>
            </IonCol>
            <IonCol>
              <IonButton
                color="secondary"
                expand="block"
                onClick={() => enter()}
                disabled={disableEnter}
              >
                Enter
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonFooter>
    </>
  );
};

export default AuthPinModal;
