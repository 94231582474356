import React from 'react';
import { isNil, toNumber } from 'lodash';
import type { BarDatum } from '@nivo/bar';
import { ResponsiveBar } from '@nivo/bar';
import { formatNumber } from 'common/utils/numberHelper';
import classes from './AccountsReceivableChart.module.scss';

export interface AccountsReceivableChartData extends BarDatum {
  id: string;
  color: string;
  value: number;
  label: string;
}

export interface AccountsReceivableChartProps {
  data: AccountsReceivableChartData[];
  testid: string;
  currencyType?: string;
}

const AccountsReceivableChart = ({
  data,
  testid,
  currencyType,
}: AccountsReceivableChartProps): JSX.Element => (
  <div className={classes.accountsReceivableChart} data-testid={testid}>
    <ResponsiveBar
      data={data}
      indexBy="label"
      margin={{ top: 0, right: 16, bottom: 8, left: 80 }}
      padding={0.2}
      layout="horizontal"
      colors={data.map((c) => c.color)}
      colorBy="indexValue"
      axisLeft={{
        renderTick: (axisTickProps) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const { x, y, textAnchor, textX, textY, value } = axisTickProps;
          return (
            <g transform={`translate(${x},${y})`}>
              <text
                textAnchor={textAnchor}
                transform={`translate(${textX - 8},${textY + 4})`}
              >
                {value}
              </text>
            </g>
          );
        },
      }}
      axisBottom={null}
      enableGridX={false}
      enableGridY={false}
      label={({ value }) =>
        !isNil(value) && value > 0
          ? formatNumber({ number: toNumber(value), currencyType })
          : `(${formatNumber({
              number: Math.abs(toNumber(value)),
              currencyType,
            })})`
      }
      labelTextColor="#fff"
      labelSkipWidth={50} // labelSkipWidth = minimum bar size needed for a normal label
      layers={[
        'axes',
        'bars',
        ({ bars, labelSkipWidth }) => {
          return (
            <g>
              {bars.map(({ width, height, x, y, data: dataBars }) => {
                const { value } = dataBars;
                // only show this custom outer label on bars that are too small and greater than 0
                return width < labelSkipWidth && value ? (
                  <text
                    textAnchor="start"
                    transform={`translate(${x + width + 8}, ${y + height / 2})`}
                    dominantBaseline="central"
                    key={dataBars.data.id}
                  >
                    {value > 0
                      ? formatNumber({
                          number: toNumber(value),
                          currencyType,
                        })
                      : `(${formatNumber({
                          number: Math.abs(toNumber(value)),
                          currencyType,
                        })})`}
                  </text>
                ) : null;
              })}
            </g>
          );
        },
      ]}
    />
  </div>
);

export default AccountsReceivableChart;
