import type { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import type {
  QueryFlags,
  QueryFnProps,
  QueryParamsType,
} from 'common/api/utils/useGetQueryFlags';
import useGetQueryFlags from 'common/api/utils/useGetQueryFlags';
import { useAxios } from 'providers/AxiosProvider';
import { useKeyUserId, useMiLocOrTeamId } from 'api/helpers';

export interface Group4Digits {
  code: string;
  text: string;
}

interface FindGroups4DigitsAPIResponse {
  prodGroups: Group4Digits[];
  totalRows: number;
}

export const findGroups4DigitsQueryKey = 'groups-4digits';

interface UseFindGroups4DigitsProps {
  prodGroup?: string;
  // TODO create reusable interface with props
  enabled?: boolean;
}

interface UseFindGroups4DigitsResponse {
  groups?: Group4Digits[];
}

const useFindGroups4Digits = ({
  prodGroup,
  enabled: propsEnabled = true,
}: UseFindGroups4DigitsProps): UseFindGroups4DigitsResponse & QueryFlags => {
  const { axios } = useAxios();
  const { findGroups4DigitsAPI } = useAPIUrl();
  // TODO put together common utils
  const { createQueryKey } = useKeyUserId();
  const { getURLParams } = useMiLocOrTeamId({});
  const params: QueryParamsType = { prodGroup };
  const enabled = propsEnabled && !!prodGroup;

  const doFindGroups4Digits = async ({
    pageParam = 1,
    signal,
  }: QueryFnProps) => {
    const { data } = await axios.get<FindGroups4DigitsAPIResponse>(
      findGroups4DigitsAPI(getURLParams({ ...params, page: pageParam })),
      { signal }
    );
    return data;
  };

  const response = useQuery<FindGroups4DigitsAPIResponse, AxiosError>(
    createQueryKey(findGroups4DigitsQueryKey, params),
    doFindGroups4Digits,
    { enabled }
  );

  const groups = response.data?.prodGroups;

  const queryFlags = useGetQueryFlags({ ...response, data: groups, enabled });

  return { groups, ...queryFlags };
};

export default useFindGroups4Digits;
