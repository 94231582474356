import React from 'react';
import classNames from 'classnames';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import classes from './TitleLine.module.scss';

const TitleLine = ({ className }: IonicReactProps): JSX.Element => (
  <hr className={classNames(className, classes.border)} />
);

export default TitleLine;
