import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import type { Column } from 'react-table';
import classNames from 'classnames';
import type { Dictionary } from 'lodash';
import { map, toNumber, toString } from 'lodash';
import { IonContent, IonPage, IonRow } from '@ionic/react';
import Header from 'common/components/Header/Header';
import { differenceInMonths } from 'date-fns';
import type { PriceHistoryModel } from 'ProductSearchApp/api/useFindPriceHistory';
import useFindPriceHistory from 'ProductSearchApp/api/useFindPriceHistory';
import type { ProductDetailURLParams } from 'ProductSearchApp/models/Products';
import useGetProductDetail from 'api/customer/useGetProductDetailV2';
import useShowCostDetailsDispatcher from 'hooks/useToggleCostDetailsDispatcher';
import { SortDirEnum } from 'models/Sort';
import type { RootState } from 'store/reducers';
import { formatCardDate, parseDate } from 'utils/date';
import { getErrorMessage } from 'utils/helpers';
import { removeLeadingZeros } from 'utils/number';
import {
  goToProductDetail,
  goToReviewCart,
} from 'navigation/navigationHelpers';
import Button from 'components/Button/Button';
import CurrencyFormat from 'components/CurrencyFormat/CurrencyFormat';
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll';
import Loader from 'components/Loader/Loader';
import NumberFormat from 'components/NumberFormat/NumberFormat';
import Refresher from 'components/Refresher/Refresher';
import Table from 'components/Table/Table';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './PriceHistory.module.scss';

const PriceHistory = (): JSX.Element => {
  const { miLoc, id, productId } = useParams<ProductDetailURLParams>();
  const { toggleCostDetailsOption } = useShowCostDetailsDispatcher();
  const { showCostDetails } = useSelector((state: RootState) => state.user);

  const { t } = useTranslation();
  const namespace = 'productSearch:priceHistory';

  const {
    productData,
    customerData,
    isLoading: isProductLoading,
    error: productError,
  } = useGetProductDetail({ miLoc, id, productId });

  const headerRef = useRef<HTMLDivElement>(null);

  const [headerTop, setHeaderTop] = useState(0);
  const onResize = () => setHeaderTop(headerRef.current?.clientHeight || 0);

  const {
    items,
    error,
    refetch,
    fetchNextPage,
    noMoreData,
    isEmptyResponse,
    isLoading,
  } = useFindPriceHistory({
    miLoc,
    itemNo: productId,
    customerNo: id,
    enabled: true,
  });

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  const tableColumns: Column<Dictionary<unknown>>[] = useMemo(() => {
    return [
      {
        Header: toString(t(`${namespace}:ocn`)),
        id: 'ocn',
        accessor: 'data',
        Cell: ({ value }: Dictionary<unknown>) => {
          const { orderCtlNo } = value as PriceHistoryModel;
          return (
            <Button
              text={removeLeadingZeros(orderCtlNo)}
              href={goToReviewCart({
                miLoc,
                shipToCustNo: customerData?.customerNo,
                orderCtlNo,
                ocnType: 'order',
                ocnMode: 'review',
              })}
              testid={toString(orderCtlNo)}
              variant="link"
            />
          );
        },
      },
      {
        Header: toString(t(`${namespace}:orderDate`)),
        id: 'orderDate',
        accessor: 'data',
        sortType: 'basic',
        Cell: ({ value }: Dictionary<unknown>) => {
          const { orderDate, orderCtlNo } = value as PriceHistoryModel;
          return (
            <Text
              className={classes.tableItem}
              variant="list-item-overlay"
              text={formatCardDate(toString(orderDate))}
              testid={`${orderCtlNo}-orderDate`}
            />
          );
        },
      },
      {
        Header: toString(t(`${namespace}:customerPrice`)),
        id: 'unitSellPrice',
        accessor: 'data',
        Cell: ({ value }: Dictionary<unknown>) => {
          const { unitSellPrice, orderCtlNo, orderDate } =
            value as PriceHistoryModel;
          const diff = differenceInMonths(new Date(), parseDate(orderDate));
          return diff < 6 ? (
            <CurrencyFormat
              value={toNumber(unitSellPrice)}
              className={classes.tableItem}
              variant="list-item-overlay"
              scale={2}
              currencyType="USD"
              testid={`${orderCtlNo}-unitSellPrice`}
            />
          ) : (
            <Text
              text={t(`${namespace}:unavailable`)}
              className={classes.tableItem}
              variant="list-item-overlay"
            />
          );
        },
      },
      ...(showCostDetails
        ? [
            {
              Header: toString(t(`${namespace}:gpPercentage`)),
              id: 'gpPct',
              accessor: 'data',
              Cell: ({ value }: Dictionary<unknown>) => {
                const { gpPct, orderCtlNo, orderDate } =
                  value as PriceHistoryModel;
                const diff = differenceInMonths(
                  new Date(),
                  parseDate(orderDate)
                );
                return diff < 6 ? (
                  <NumberFormat
                    value={toNumber(gpPct)}
                    scale={2}
                    suffix="%"
                    className={classes.tableItem}
                    variant="list-item-overlay"
                    testid={`change-format-value-${orderCtlNo}`}
                  />
                ) : (
                  <Text
                    text={t(`${namespace}:unavailable`)}
                    className={classes.tableItem}
                    variant="list-item-overlay"
                  />
                );
              },
            },
            {
              Header: toString(t(`${namespace}:cost`)),
              id: 'customerCost',
              accessor: 'data',
              Cell: ({ value }: Dictionary<unknown>) => {
                const { customerCost, orderCtlNo, orderDate } =
                  value as PriceHistoryModel;
                const diff = differenceInMonths(
                  new Date(),
                  parseDate(orderDate)
                );
                return diff < 6 ? (
                  <CurrencyFormat
                    value={toNumber(customerCost)}
                    variant="list-item-overlay"
                    className={classes.tableItem}
                    scale={2}
                    currencyType="USD"
                    testid={`${orderCtlNo}-customerCost`}
                  />
                ) : (
                  <Text
                    text={t(`${namespace}:unavailable`)}
                    className={classes.tableItem}
                    variant="list-item-overlay"
                  />
                );
              },
            },
          ]
        : []),
      {
        Header: toString(t(`${namespace}:qty`)),
        id: 'opQtyOrdered',
        accessor: 'data',
        Cell: ({ value }: Dictionary<unknown>) => {
          const { opQtyOrdered, orderCtlNo } = value as PriceHistoryModel;
          return (
            <NumberFormat
              value={toNumber(opQtyOrdered)}
              scale={1}
              className={classes.tableItem}
              variant="list-item-overlay"
              testid={`${orderCtlNo}-quantity}`}
            />
          );
        },
      },
    ];
  }, [customerData?.customerNo, miLoc, showCostDetails, t]);

  const tableData = useMemo(() => {
    return map(items, (item) => {
      const {
        orderCtlNo,
        orderDate,
        unitSellPrice,
        gpPct,
        customerCost,
        opQtyOrdered,
      } = item;

      return {
        data: {
          orderCtlNo,
          orderDate,
          unitSellPrice,
          gpPct,
          customerCost,
          opQtyOrdered,
        },
      };
    });
  }, [items]);

  return (
    <IonPage className={classes.page} data-testid="price-history-page">
      <Header
        title={t(`${namespace}:title`)}
        subTitle={productData?.mfgPartNumber}
        hideMenuButton
        hideLocationSelector
        headerActions={{
          title: t(`${namespace}:manage`),
          initialBreakpoint: 0.2,
          options: [toggleCostDetailsOption(false)],
        }}
        testid="price-history-page-header"
      />
      <IonContent className={classes.content}>
        <Refresher
          slot="fixed"
          onRefresh={refetch}
          disabled={isProductLoading}
          hidden
          testid="order-list-refresher"
        />
        {isProductLoading && (
          <Loader
            isOpen={isProductLoading}
            className={classes.loader}
            text={t('common:loading')}
            testid="customer-loader"
          />
        )}
        {(productError || error) && (
          <WarningMessage
            className={classes.message}
            title={t(`${namespace}:errorTitle`)}
            body={getErrorMessage(productError || error)}
            testid="customer-error"
          />
        )}
        {!isProductLoading && !productError && (
          <>
            <Header
              className={classes.header}
              collapse="condense"
              pageTitle={t(`${namespace}:title`)}
              customTitle={
                <div>
                  <IonRow className={classes.mfgPartRow}>
                    <Button
                      variant="link"
                      href={goToProductDetail({
                        miLoc,
                        customerNo: customerData?.customerNo,
                        itemNo: productData?.itemNumber,
                      })}
                      testid="produe-detail-link"
                      className={classNames(classes.customerLink)}
                    >
                      <Text
                        className={classes.productName}
                        text={toString(productData?.mfgPartNumber)}
                        variant="mipro-product-headline"
                      />
                    </Button>
                  </IonRow>
                  <IonRow>
                    <Text
                      className={classes.customerEyebrow}
                      variant="list-item-subtitle"
                      text={toString(customerData?.name)}
                    />
                  </IonRow>
                </div>
              }
              testid="pricing-history-header"
            />
            <Table
              className={classes.tableContainer}
              theadStyle={{ top: headerTop }}
              thClassName={classes.tableTH}
              tdClassName={classes.tableTD}
              columns={tableColumns}
              data={tableData}
              disableSortBy
              sortDir={SortDirEnum.DESCENDING}
              sortField="orderDate"
            />
            {isEmptyResponse && !isLoading && (
              <WarningMessage
                className={classes.warningMessage}
                icon={['far', 'info-circle']}
                title={t(`${namespace}:emptyTitle`)}
                body={t(`${namespace}:emptyMsg`)}
              />
            )}
            <Loader
              className={classes.loader}
              text="Loading results"
              isOpen={isLoading}
            />
            <InfiniteScroll
              disabled={noMoreData}
              onLoadMore={fetchNextPage}
              testid="infinite-scroll"
            />
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default PriceHistory;
