/* eslint-disable no-console */
import { map, size } from 'lodash';
import type { FastFindQueryParams } from 'DocumentsApp/models/FastFind';
import type { Machine } from 'DocumentsApp/models/Machine';
import useDocumentsDB from './initDocumentsDB';

interface UseMachineDBResponse {
  createMachines: (machines: Machine[]) => Promise<void>;
  findMachines: (props: FastFindQueryParams) => Promise<Machine[]>;
  removeMachines: (miLoc: string) => Promise<void>;
}

const useMachineDB = (): UseMachineDBResponse => {
  const { db, openDB, closeDB, getLikeStatement, getWhereStatement } =
    useDocumentsDB();

  const createMachines = async (machines: Machine[]): Promise<void> => {
    try {
      await openDB();
      const statements = map(machines, (machine) => ({
        statement: `INSERT OR REPLACE INTO machine (
          machineId,
          siteId,
          siteMiLoc,
          machineDesc,
          custMachineId,
          displayText
          ) VALUES (?,?,?,?,?,?)`,
        values: [
          machine.machineId,
          machine.siteId,
          machine.siteMiLoc,
          machine.machineDesc,
          machine.custMachineId,
          machine.displayText,
        ],
      }));
      if (size(statements) > 0) {
        await db.executeSet(statements, true);
      }
    } catch (error) {
      console.log('Error saving machines to database', error);
      throw new Error('Error saving machines to database');
    } finally {
      await closeDB();
    }
  };

  const findMachines = async ({
    fastFind,
    miLoc,
    siteId,
  }: FastFindQueryParams): Promise<Machine[]> => {
    try {
      const whereStatement = [];
      const vars = [];
      if (fastFind) {
        whereStatement.push(
          getLikeStatement([
            // 'machineId',
            'machineDesc',
            'custMachineId',
          ])
        );
        const likeFastFind = `%${fastFind}%`;
        vars.push(likeFastFind, likeFastFind);
      }
      if (miLoc) {
        whereStatement.push(getLikeStatement('siteMiLoc', vars));
        vars.push(`%${miLoc}%`);
      }
      if (siteId) {
        whereStatement.push(getLikeStatement('siteId', vars));
        vars.push(`%${siteId}%`);
      }
      await openDB();
      return (
        await db.query(
          `SELECT * FROM machine
          ${getWhereStatement(whereStatement)}`,
          vars
        )
      ).values as Machine[];
    } catch (error) {
      console.log('Error loading machines', error);
      throw new Error('Error loading machines');
    } finally {
      await closeDB();
    }
  };

  const removeMachines = async (miLoc: string): Promise<void> => {
    try {
      await openDB();
      await db.query(
        `DELETE FROM machine
        WHERE siteMiLoc = ?`,
        [miLoc]
      );
    } catch (error) {
      console.log('Error removing machines', error);
      throw new Error('Error removing machines');
    } finally {
      await closeDB();
    }
  };

  return {
    createMachines,
    findMachines,
    removeMachines,
  };
};

export default useMachineDB;
