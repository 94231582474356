import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';
import { isEmpty, size } from 'lodash';
import { IonContent, IonLoading, IonPage, IonRow } from '@ionic/react';
import {
  issueStoreroomListURL,
  newIssueURL,
  reviewIssuesURL,
} from 'navigation';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import { useToasts } from 'providers/ToastProvider';
import useFindIssues from 'StoreroomsApp/api/useFindIssues';
import useUploadIssues from 'StoreroomsApp/api/useUploadIssues';
import useIssueSync from 'StoreroomsApp/hooks/useIssueSync';
import type { Issue } from 'StoreroomsApp/models/Issue';
import { ToastType } from 'models/Toast';
import type { RootState } from 'store/reducers';
import selectIsBranchLocation from 'store/user/selectors';
import { findIcon } from 'utils/icons';
import { concatRoutes } from 'utils/navigations';
import Button from 'components/Button/Button';
import Header from 'components/Header/Header';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './IssueStorerooms.module.scss';

const IssueStorerooms = (): JSX.Element => {
  const { url } = useRouteMatch();
  const { isOnline } = useNetworkStatus();
  const { addToast } = useToasts();
  const { storeroom = '', storeroomName } = useSelector(
    (state: RootState) => state.user
  );
  const isBranch = useSelector(selectIsBranchLocation);
  const [uploadPressed, setUploadPressed] = useState(false);

  const { loader, downloadData, offlineBanner } = useIssueSync();

  const { issues, isLoading: issuesLoading } = useFindIssues({
    storeroomNumber: storeroom,
  });

  const {
    onUploadIssues,
    isLoading: uploadLoading,
    status: uploadStatus,
  } = useUploadIssues();

  const uploadIsLoading = uploadLoading || uploadPressed;

  const isLoading = issuesLoading || uploadIsLoading;
  const isCreateDisabled = isEmpty(storeroom) || uploadIsLoading;
  const itemsSize = size(issues);
  const hasUploadIssues = itemsSize > 0;

  useEffect(() => {
    if (uploadStatus !== 'loading') {
      setUploadPressed(false);
    }
  }, [uploadStatus]);

  return (
    <IonPage data-testid="issue">
      <Header
        title={storeroom ? storeroomName || storeroom : '<Select a storeroom>'}
        showLocationText
        testid="issue-header"
      >
        {offlineBanner}
      </Header>
      {loader}
      <IonContent className={classes.content} data-testid="storerooms-list">
        <IonLoading
          isOpen={uploadIsLoading}
          message="Uploading issues in progress. This could take a couple minutes."
        />
        {!isBranch ? (
          <WarningMessage
            className={classes.warningMessage}
            icon={['far', 'info-circle']}
            title="You must be at a branch level to use this feature."
          />
        ) : (
          <>
            <IonRow>
              <Button
                testid="create-issue-button"
                className={classNames(classes.button, {
                  [classes.disabled]: isCreateDisabled,
                })}
                variant="action"
                text="Create Issue"
                icon={findIcon('file-plus')}
                color="success"
                onClick={(e) => {
                  if (isCreateDisabled) {
                    e.preventDefault();
                    addToast({
                      type: ToastType.warn,
                      text: 'Please select a storeroom first.',
                      testid: 'select-storeroom-warning-toast',
                    });
                  }
                }}
                href={concatRoutes(url, newIssueURL())}
              />
            </IonRow>
            <IonRow>
              <Button
                testid="download-button"
                className={classes.button}
                variant="action"
                icon={findIcon('download')}
                disabled={!isOnline || uploadIsLoading || hasUploadIssues}
                text="Download"
                onClick={downloadData}
              />
              {hasUploadIssues && (
                <Text
                  className={classes.disabledText}
                  text="There are issues to upload"
                />
              )}
            </IonRow>
            <IonRow>
              <Button
                testid="upload-issue-button"
                className={classes.button}
                variant="action"
                onClick={() => {
                  setUploadPressed(true);
                  setTimeout(() => {
                    onUploadIssues({ issues: issues as Issue[] });
                  }, 0);
                }}
                icon={findIcon('upload')}
                text={`Upload Issues${
                  hasUploadIssues ? ` (${itemsSize})` : ''
                }`}
                disabled={!isOnline || isLoading || itemsSize === 0}
              />
              {hasUploadIssues && (
                <Button
                  variant="link"
                  className={classes.linkButton}
                  text="Issues List"
                  href={concatRoutes(url, reviewIssuesURL())}
                  testid="issues-list-button"
                />
              )}
            </IonRow>
            <IonRow>
              <Button
                testid="select-storeroom-button"
                className={classes.button}
                variant="action"
                text={
                  isEmpty(storeroom) ? 'Select Storeroom' : 'Change Storeroom'
                }
                icon={findIcon('archive')}
                disabled={uploadIsLoading}
                href={concatRoutes(url, issueStoreroomListURL())}
              />
            </IonRow>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default IssueStorerooms;
