import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { get, join, map, size, toNumber, toString } from 'lodash';
import { useIonViewWillEnter } from '@ionic/react';
import type { ActivityDetailURLParams } from 'ActivitiesApp/models/ActivityDetail';
import { goToShareActivityByEmail } from 'ActivitiesApp/navigation/navigationHelpers';
import { getVisitAttachmentsIds, isVisit } from 'ActivitiesApp/utils/helpers';
import {
  SendEmailFormik,
  SendEmailPage,
} from 'common/components/SendEmail/SendEmail';
import type { SendEmailPageRef } from 'common/components/SendEmail/SendEmail';
import useGetActivity from 'api/activities/useGetActivity';
import useSendEmail from 'api/activities/useSendEmail';
import useFindAttachments from 'api/attachments/useFindAttachments';
import useGetCustomer from 'api/customer/useGetCustomer';
import useGoBack from 'hooks/useGoBack';
import type { Contact } from 'models/Contact';
import type { RootState } from 'store/reducers';
import { formatSnoozeDate } from 'utils/date';

const ShareVisitOrCall = (): JSX.Element => {
  const { goBack } = useGoBack();
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const resetView = params.get('reset');
  const pageRef = useRef<SendEmailPageRef>(null);
  const { miLoc, id, userId, historyId, activityType } =
    useParams<ActivityDetailURLParams>();
  const { userInfo } = useSelector((state: RootState) => state.user);
  const loggedInUserId = get(userInfo, 'userid', '');
  const userName = get(userInfo, 'name', '');

  useIonViewWillEnter(() => {
    if (resetView) {
      pageRef.current?.resetForm?.();
      history.replace(
        goToShareActivityByEmail({
          miLoc,
          customerId: id,
          userId,
          historyId,
          activityType,
        })
      );
    }
  }, [resetView]);

  const { onSendEmail, status } = useSendEmail();

  useEffect(() => {
    if (status === 'success') {
      goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const {
    data: customerData,
    isLoading: customerLoading,
    error: customerError,
  } = useGetCustomer({ searchType: 'customer', miLoc, id });

  const {
    data: activity,
    isLoading: activityLoading,
    error: activityError,
  } = useGetActivity({
    historyId: toNumber(historyId),
    userId,
  });

  const { attachmentUserId, filteredHistoryId } =
    getVisitAttachmentsIds(activity);

  const {
    data: attachmentsData,
    isLoading: attachmentsLoading,
    error: attachmentsError,
  } = useFindAttachments({
    domain: 'mprovisi',
    miLoc: 'EXEC',
    ctlNo: attachmentUserId,
    lineNo: filteredHistoryId,
    enabled: !activityLoading,
    refetchOnEnter: true,
  });

  const isSubmitting = status === 'loading';
  const type = isVisit(activity?.extendedInfo?.visitType)
    ? t('common:visit')
    : t('activities:call');

  const subject = t('activities:sendEmail:defaultSubject', {
    userName,
    ownerName: activity?.userFullName,
    count: loggedInUserId === activity?.userId ? 0 : 1,
    customerName: activity?.customerName,
    type,
  });

  const message = t('activities:sendEmail:defaultMessage', {
    customerName: activity?.customerName,
    type,
    count: size(activity?.extendedInfo?.contacts as Contact[]),
    contactName: join(
      map(
        activity?.extendedInfo?.contacts as Contact[],
        (contact) => contact.contactName
      ),
      ', '
    ),
    date: formatSnoozeDate(
      toString(activity?.extendedInfo?.visitDateWithZimeZoneCreated)
    ),
    noteTitle: toString(activity?.extendedInfo?.notebookTitle),
    note: toString(activity?.extendedInfo?.notebookText),
  });

  const visitType = toString(activity?.extendedInfo?.visitType);

  return (
    <SendEmailFormik
      onSubmit={(values) => {
        onSendEmail({
          subject: values.subject,
          recipients: map(values.recepientsList, ({ email }) =>
            toString(email)
          ),
          toastText: t(
            isVisit(visitType)
              ? 'activities:emailVisitSuccess'
              : 'activities:emailCallSuccess',
            { recipients: map(values.recepientsList, (i) => i.name).join('; ') }
          ),
          body: toString(values.message),
          filesToUpload: values.files as File[],
        });
      }}
      values={{ subject, message, recepients: '', files: attachmentsData }}
    >
      <SendEmailPage
        ref={pageRef}
        customerData={customerData}
        isLoading={customerLoading || activityLoading || attachmentsLoading}
        error={customerError || activityError || attachmentsError}
        isSubmitting={isSubmitting}
        title={
          isVisit(visitType)
            ? t('activities:emailVisit')
            : t('activities:emailCall')
        }
      />
    </SendEmailFormik>
  );
};

export default ShareVisitOrCall;
