import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import type * as Sentry from '@sentry/capacitor';

// eslint-disable-next-line import/prefer-default-export
export const addAxiosContextToSentry = (
  sentryScope: Sentry.Scope,
  axiosRequest: AxiosRequestConfig,
  axiosResponse?: AxiosResponse
): void => {
  sentryScope.setContext('request', {
    method: String(axiosRequest?.method),
    url: String(axiosRequest?.url),
    headers: axiosRequest?.headers,
    data: axiosRequest?.data,
  });
  sentryScope.setContext('response', {
    status: String(axiosResponse?.status),
    statusText: String(axiosResponse?.statusText),
    headers: axiosResponse?.headers,
    data: axiosResponse?.data,
  });
};
