import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { IonRow } from '@ionic/react';
import { FormikInput } from 'common/components/Forms/Input/Input';
import ManufacturerSelect from 'CostSavingsApp/components/FastFind/ManufacturerSelect';
import { useFormikContext } from 'formik';
import Group2DigitsSelect from 'ProductSearchApp/components/FastFind/Group2DigitsSelect';
import Group4DigitsSelect from 'ProductSearchApp/components/FastFind/Group4DigitsSelect';
import RepairShopSelect from 'ProductSearchApp/components/FastFind/RepairShopSelect';
import type { OrderURLParams } from 'ProductSearchApp/models/Products';
import { buildItemMino } from 'ProductSearchApp/util/ocnHelpers';
import CheckBox from 'components/CheckBox/CheckBox';
import Text from 'components/Text/Text';
import classes from './AddZCodedItem.module.scss';
import type { AddZCodedItemForm } from './AddZCodedItemSchema';

interface PgcGroupsFormProps {
  isLoading?: boolean;
}

const PgcGroupsForm = ({ isLoading }: PgcGroupsFormProps): JSX.Element => {
  const { t } = useTranslation();
  const { miLoc } = useParams<OrderURLParams>();

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    validateField,
  } = useFormikContext<AddZCodedItemForm>();

  return (
    <div className={classes.formContent} data-testid="pcg-groups-form">
      <FormikInput
        label={t(`productSearch:zCodedItem:itemDescriptionInput`)}
        name="mfgPartNo"
        disabled={isLoading}
        toUpperCase
        required
        testid="description-input"
      />
      <ManufacturerSelect
        className={classes.input}
        label={t('productSearch:zCodedItem:mfrCtlSelect')}
        selItem={values.mfrCtlNo}
        setManufacturer={(item) => {
          // TODO due to yup scehma typing, validation needs to exists in common/Select component
          setFieldValue('mfrCtlNo', item.key ? item : '');
          setFieldValue('group2digits', '');
          setFieldValue('group4digits', '');
          setTimeout(() => validateField('mfrCtlNo'));
        }}
        // TODO onClose is not being called when selecting item
        onClose={() => setFieldTouched('mfrCtlNo', true)}
        inputError={touched.mfrCtlNo ? errors.mfrCtlNo : ''}
        disabled={isLoading}
        required
      />
      <Group2DigitsSelect
        className={classes.input}
        label={t('productSearch:zCodedItem:group2DigitsSelect')}
        modalTitle={t(`productSearch:zCodedItem:groupSelectModalTitle`)}
        mfrCtlNo={values.mfrCtlNo?.key}
        selItem={values.group2digits}
        setGroup2Digits={(item) => {
          setFieldValue('group2digits', item.key ? item : '');
          setFieldValue('group4digits', '');
          setTimeout(() => validateField('group2digits'));
        }}
        onClose={() => setFieldTouched('group2digits', true)}
        inputError={touched.group2digits ? errors.group2digits : ''}
        disabled={isLoading || !values.mfrCtlNo?.key}
        required
      />
      <Group4DigitsSelect
        className={classes.input}
        label={t('productSearch:zCodedItem:group4DigitsSelect')}
        modalTitle={t(`productSearch:zCodedItem:groupSelectModalTitle`)}
        prodGroup={values.group2digits?.key}
        selItem={values.group4digits}
        setGroup4Digits={(item) => {
          setFieldValue('group4digits', item.key ? item : '');
          setTimeout(() => validateField('group4digits'));
        }}
        onClose={() => setFieldTouched('group4digits', true)}
        inputError={touched.group4digits ? errors.group4digits : ''}
        disabled={isLoading || !values.group2digits?.key}
        required
      />
      <IonRow className={classes.minoRow}>
        <Text
          className={classes.label}
          text={t('productSearch:zCodedItem:minoLabel')}
        />
        <Text
          text={buildItemMino(values.mfrCtlNo?.key, values.group4digits?.key)}
          testid="mino-text"
        />
      </IonRow>
      <CheckBox
        className={classes.checkbox}
        label={t(`productSearch:zCodedItem:repairItemLabel`)}
        checked={values.isRepair}
        onChange={(v) => {
          setFieldValue('isRepair', v);
          if (!v) {
            setTimeout(() => setFieldValue('repairShop', ''));
          }
          if (v) {
            setTimeout(() => {
              document
                .querySelector('[name=repairShop]')
                ?.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }, 300);
          }
        }}
        disabled={isLoading}
        testid="is-repair-checkbox"
      />
      {values.isRepair && (
        <RepairShopSelect
          className={classes.input}
          inputName="repairShop"
          label={t('productSearch:zCodedItem:repairShopSelect')}
          modalTitle={t(`productSearch:zCodedItem:repairShopSelectModalTitle`)}
          miLoc={miLoc}
          selItem={values.repairShop}
          setRepairShop={(item) => {
            setFieldValue('repairShop', item.key ? item : '');
            setTimeout(() => validateField('repairShop'));
          }}
          onClose={() => setFieldTouched('repairShop', true)}
          inputError={touched.repairShop ? errors.repairShop : ''}
          disabled={isLoading || !values.isRepair}
          required={values.isRepair}
        />
      )}
    </div>
  );
};

export default PgcGroupsForm;
