import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { isEmpty, toString, some, filter, size } from 'lodash';
import { IonContent, IonPage, useIonViewWillEnter } from '@ionic/react';
import { useQueryClient } from '@tanstack/react-query';
import Footer from 'common/components/Footer/Footer';
import CustomerName from 'common/components/Header/CustomerName';
import Header from 'common/components/Header/Header';
import { Formik } from 'formik';
import { searchCustomerURL, searchURL } from 'navigation';
import useUpdateHeader from 'ProductSearchApp/api/checkout/useUpdateHeader';
import { findOrderNotesQueryKey } from 'ProductSearchApp/api/orders/useFindOrderNotes';
import useGetOrder from 'ProductSearchApp/api/useGetOrder';
import useSubmitOCN from 'ProductSearchApp/api/useSubmitOCN';
import CheckoutForm from 'ProductSearchApp/components/CheckoutForm/CheckoutForm';
import { OcnBadge } from 'ProductSearchApp/components/OcnBadge/OcnBadge';
import OrderAttachments from 'ProductSearchApp/components/OrderAttachments/OrderAttachments';
import OrderNotes from 'ProductSearchApp/components/OrderNotes/OrderNotes';
import OcnHeader from 'ProductSearchApp/components/ReviewQuotes/OcnHeader';
import OcnLinesReview from 'ProductSearchApp/components/ReviewQuotes/OcnLinesReview';
import { OcnTypeEnum } from 'ProductSearchApp/models/Order';
import type {
  CheckoutOrderBaseForm,
  CheckoutOrderForm,
} from 'ProductSearchApp/models/Order';
import type {
  OrderURLParams,
  SubmitOCNBody,
} from 'ProductSearchApp/models/Products';
import {
  canEditOCN,
  getOcnType,
  hasPriceOverride,
  isClosedOrder,
  isUnfinishedOrder,
  mergeEmailRecipients,
} from 'ProductSearchApp/util/ocnHelpers';
import { useToasts } from 'providers/ToastProvider';
import { findAttachmentsQueryKey } from 'api/attachments/useFindAttachments';
import useGetCustomer from 'api/customer/useGetCustomer';
import useAccessControls, { AccessControlType } from 'hooks/useAccessControls';
import useGoBack from 'hooks/useGoBack';
import useShowCostDetailsDispatcher from 'hooks/useToggleCostDetailsDispatcher';
import type { RootState } from 'store/reducers';
import { setCurrentCartCustomer } from 'store/user';
import { getErrorMessage } from 'utils/helpers';
import { concatRoutes } from 'utils/navigations';
import { removeLeadingZeros } from 'utils/number';
import {
  goToOrderCart,
  goToReviewCart,
  goToTransmissionLog,
} from 'navigation/navigationHelpers';
import type Button from 'components/Button/Button';
import { ButtonVariantEnum } from 'components/Button/Button';
import Loader from 'components/Loader/Loader';
import DiscardModal from 'components/Modals/DiscardModal/DiscardModal';
import Refresher from 'components/Refresher/Refresher';
import Text from 'components/Text/Text';
import WarningMessage, {
  WarningMessageVarianEnum,
} from 'components/WarningMessage/WarningMessage';
import { CheckoutOrderSchema } from './CheckoutOrderForm';
import classes from './ReviewOrder.module.scss';

interface SwitchOrderToastProps {
  ocn: string;
  values: CheckoutOrderForm;
  sameCart?: boolean;
  nextRoute: string;
  orderStayedUM?: boolean;
}

interface ReviewOrderProps {
  ocnType: OcnTypeEnum;
  ocnMode: 'review' | 'checkout';
}

const ReviewOrder = ({
  ocnType: routeOcnType,
  ocnMode,
}: ReviewOrderProps): JSX.Element => {
  const {
    miLoc,
    id: shipToCustNo,
    ocn: orderCtlNo,
  } = useParams<OrderURLParams>();
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const resetView = params.get('reset');
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const { toggleCostDetailsOption } = useShowCostDetailsDispatcher();
  const { currentCartCustomer } = useSelector((state: RootState) => state.user);
  const canCreateEditOrder = useAccessControls(
    AccessControlType.EditOrdersAccessControls
  );

  const [isOpenDiscardModal, setIsOpenDiscardModal] = useState(false);
  const [cartModalIsOpen, setCartModalIsOpen] = useState(false);
  const [isGoToReviewCart, setIsGoToReviewCart] = useState(false);
  const [isReviewAfterCheckout, setIsReviewAfterCheckout] = useState(false);
  const [stayedUM, setStayedUM] = useState(false);
  const [fetchingOrder, setFetchingOrder] = useState(false);
  const [urlAfterUpdate, setUrlAfterUpdate] = useState('');
  const summaryMode = ocnMode === 'review';

  const {
    data: customerData,
    isLoading: customerIsLoading,
    error: customerError,
  } = useGetCustomer({ searchType: 'customer', miLoc, id: shipToCustNo });

  const cartNameSpace = 'productSearch:ocn';
  const reviewNameSpace = 'productSearch:review';

  const { t } = useTranslation();

  useIonViewWillEnter(() => {
    if (resetView && ocnMode === 'checkout') {
      setIsReviewAfterCheckout(false);
      history.replace(
        goToReviewCart({
          miLoc,
          shipToCustNo,
          orderCtlNo,
          ocnType: routeOcnType,
          ocnMode: 'checkout',
        })
      );
    } else if (ocnMode === 'review') {
      setIsReviewAfterCheckout(true);
    }
  }, [ocnMode, resetView, orderCtlNo]);

  const {
    order,
    error: orderError,
    isLoading: orderLoading,
    orderChangedToOP,
    refetch,
  } = useGetOrder({ miLoc, orderCtlNo, refetchOnEnter: true });

  let ocnType = routeOcnType;
  if (routeOcnType === OcnTypeEnum.closed) {
    ocnType = OcnTypeEnum.order;
  }
  if (summaryMode && !orderLoading) {
    ocnType = isClosedOrder(order) ? OcnTypeEnum.order : getOcnType(order);
  }
  const isOrder = ocnType === 'order';
  const isUnfinished = ocnType === 'unfinished';
  const error = customerError || orderError;
  // eslint-disable-next-line no-nested-ternary
  let pageTitle = !isReviewAfterCheckout
    ? t(`${cartNameSpace}:${ocnType}`)
    : ocnType === 'quote'
    ? t('productSearch:ocn:quote')
    : t('productSearch:ocn:order');
  let primaryButtonTestId = !isReviewAfterCheckout
    ? `${ocnType}-review-button`
    : `${ocnType}-submit-button`;
  // eslint-disable-next-line no-nested-ternary
  let primaryButtonText = isReviewAfterCheckout
    ? isOrder
      ? t(`${reviewNameSpace}:submitOrder`)
      : t(`${reviewNameSpace}:submitQuote`)
    : isOrder
    ? t(`${reviewNameSpace}:reviewOrder`)
    : t(`${reviewNameSpace}:reviewQuote`);
  let secondaryButtonTestId = !isReviewAfterCheckout
    ? `${ocnType}-cancel-button`
    : `${ocnType}-edit-ocn-button`;
  let secondaryButtonText = isReviewAfterCheckout
    ? t('common:edit')
    : t('common:cancel');

  if (summaryMode) {
    pageTitle = t(`${cartNameSpace}:${ocnType}`);
    primaryButtonText = t(`${reviewNameSpace}:editOcn`);
    primaryButtonTestId = `${ocnType}-edit-ocn-button`;
    secondaryButtonText = t(`${reviewNameSpace}:convertToOrder`);
    secondaryButtonTestId = 'convert-order-button';
  }

  const { onUpdateHeader, status: updateHeaderStatus } = useUpdateHeader({
    miLoc,
    orderCtlNo,
    shipToCustNo,
    billToCustNo: toString(order?.billToCustNo),
  });

  const { status: saveAsQuoteStatus, onSubmitOCN } = useSubmitOCN(isOrder);

  useEffect(() => {
    if (saveAsQuoteStatus === 'success') {
      history.replace(
        concatRoutes(searchURL(), searchCustomerURL(miLoc, shipToCustNo))
      );
    }
  }, [saveAsQuoteStatus, history, miLoc, shipToCustNo]);

  const priceOverridePending = some(order?.items, (item) =>
    hasPriceOverride(item)
  );
  const priceOverridePendingCount = filter(order?.items, (item) =>
    hasPriceOverride(item)
  );
  const sendUnprocessed = order?.processStatus === 'UM' || isOrder;
  const orderCustomerEmail = mergeEmailRecipients(order?.custContactEmail);

  let warningMessage = '';
  if (priceOverridePending) {
    const text =
      size(priceOverridePendingCount) > 1
        ? 'priceOverridePendingForMultiple'
        : 'priceOverridePendingFor';
    warningMessage = t(`${reviewNameSpace}:${text}`, {
      priceOverridePendingCount: size(priceOverridePendingCount),
    });
  }

  if (!priceOverridePending && order?.approvedAfterLastTransmission) {
    warningMessage = t(`${cartNameSpace}:approvedPriceOverrideNotEmailed`);
  }

  useEffect(() => {
    if (updateHeaderStatus === 'success') {
      if (urlAfterUpdate) {
        setUrlAfterUpdate('');
        history.push(urlAfterUpdate);
      } else {
        setIsReviewAfterCheckout(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, updateHeaderStatus]);

  const isUpdateHeaderLoading = updateHeaderStatus === 'loading';
  const isSaveAsQuoteLoading = saveAsQuoteStatus === 'loading';
  const { goBack } = useGoBack();

  const pageLoading = (customerIsLoading || orderLoading) && !order?.orderCtlNo;
  const isLoading =
    isUpdateHeaderLoading || isSaveAsQuoteLoading || orderLoading;
  const disableOrderStatus = !canEditOCN(order);
  const queryClient = useQueryClient();

  const switchCartToast = ({
    ocn,
    values,
    sameCart = false,
    nextRoute,
    orderStayedUM,
  }: SwitchOrderToastProps) => {
    if (!sameCart) {
      dispatch(
        setCurrentCartCustomer({
          currentCartCustomer: {
            customerName: customerData?.name,
            miLoc,
            shipToCustNo: toString(order?.shipToCustNo),
            billToCustNo: order?.billToCustNo,
            orderCtlNo: ocn,
          },
        })
      );
      addToast({
        variant: 'mipro-toast',
        text: t('productSearch:ocn:switchOcnSuccess', {
          ocn: removeLeadingZeros(ocn),
        }),
        testid: 'switch-cart-success-toast',
      });
    }
    if (orderStayedUM) {
      setStayedUM(false);
      setUrlAfterUpdate(nextRoute);
      onUpdateHeader({ ...values, processStatus: 'OP' });
    } else {
      history.push(nextRoute);
    }
  };

  const handlePrimaryButtonClick = async (
    values: CheckoutOrderForm,
    handleSubmit: () => void
  ) => {
    if (summaryMode) {
      try {
        setFetchingOrder(true);
        const orderChange = await orderChangedToOP?.(order, false);
        setStayedUM(!!orderChange?.stayedUM);
        if (!orderChange?.changedToOP) {
          if (
            isEmpty(currentCartCustomer) ||
            (currentCartCustomer?.miLoc === miLoc &&
              currentCartCustomer?.orderCtlNo === orderCtlNo)
          ) {
            switchCartToast({
              ocn: orderCtlNo,
              values,
              orderStayedUM: !!orderChange?.stayedUM,
              sameCart:
                currentCartCustomer?.miLoc === miLoc &&
                currentCartCustomer?.orderCtlNo === orderCtlNo,
              nextRoute: goToOrderCart(miLoc, shipToCustNo, orderCtlNo),
            });
          } else {
            setCartModalIsOpen(true);
          }
        }
      } catch (e) {
        // DOC: error handled in util
      }
      setFetchingOrder(false);
    } else {
      handleSubmit();
    }
  };

  const customBackButtonClick = async (
    values: CheckoutOrderForm,
    dirty: boolean,
    backButton = false
  ) => {
    if (summaryMode) {
      if (backButton) {
        goBack();
      } else {
        try {
          setFetchingOrder(true);
          const orderChange = await orderChangedToOP?.(order, false);
          setStayedUM(!!orderChange?.stayedUM);
          if (orderChange?.changedToOP) {
            return;
          }
          if (
            isEmpty(currentCartCustomer) ||
            (currentCartCustomer?.miLoc === miLoc &&
              currentCartCustomer?.orderCtlNo === orderCtlNo)
          ) {
            switchCartToast({
              ocn: orderCtlNo,
              values,
              orderStayedUM: !!orderChange?.stayedUM,
              sameCart:
                currentCartCustomer?.miLoc === miLoc &&
                currentCartCustomer?.orderCtlNo === orderCtlNo,
              nextRoute: goToReviewCart({
                miLoc,
                shipToCustNo,
                orderCtlNo,
                ocnType: 'order',
                ocnMode: 'checkout',
                reset: true,
              }),
            });
          } else {
            setIsGoToReviewCart(true);
            setCartModalIsOpen(true);
          }
        } catch (e) {
          // DOC: error handled in util
        }
        setFetchingOrder(false);
      }
    } else if (dirty && !isReviewAfterCheckout) {
      setIsOpenDiscardModal(true);
    } else if (isReviewAfterCheckout) {
      setIsReviewAfterCheckout(false);
    } else {
      goBack();
    }
  };

  const headerOptions: React.ComponentProps<typeof Button>[] = [];
  if (summaryMode) {
    headerOptions.push({
      text: t('productSearch:ocn:viewTransmissionLog'),
      href: goToTransmissionLog({
        miLoc,
        shipToCustNo,
        orderCtlNo,
        ocnType,
        ocnMode,
      }),
      testid: 'view-transmission-log-button',
    });
  }
  if (summaryMode || isReviewAfterCheckout) {
    headerOptions.push(toggleCostDetailsOption(false));
  }

  const ocnNotesLoading = !!queryClient.isFetching([findOrderNotesQueryKey]);
  const ocnAttachmentLoading = !!queryClient.isFetching([
    findAttachmentsQueryKey,
  ]);

  return (
    <Formik<CheckoutOrderBaseForm>
      initialValues={{
        emailCustomer: false,
        typedRecipients: '',
        emailMsg: '',
        includeAttachments: false,
        emailAckType: 'CO',
      }}
      key={`review-${miLoc}-${shipToCustNo}-${orderCtlNo}`}
      enableReinitialize
      onSubmit={() => {}}
    >
      {({ values: baseValues, setValues: baseSetValues }) => (
        <Formik<CheckoutOrderForm>
          initialValues={{
            customerContact: {
              key: toString(order?.custContactName),
              title: toString(order?.custContactName),
            },
            contactName: order?.custContactName,
            contactNumber: order?.custContactPhone,
            contactEmail: orderCustomerEmail,
            excludeFromWeb: order?.excludeFromWeb,
            poNumber: order?.custPoNo,
            custReleaseNo: order?.custReleaseNo,
            dueDate: order?.dueDate,
            shipComplete: order?.shipComplete,
            shipChargesCd: {
              codeValue: toString(order?.shipChargesCd),
              codeDesc: toString(order?.shipChargesDesc),
            },
            carrierCd: {
              codeValue: toString(order?.carrierCd),
              codeDesc: toString(order?.carrierDesc),
            },
            shipMethodCd: {
              codeValue: toString(order?.shipMethodCd),
              codeDesc: toString(order?.shipMethodDesc),
            },
            carrierAccount: order?.carrierAccount,
            handlingInst: order?.handlingInst,
            orderSourceDesc: order?.orderSourceDesc,
            priceOverridePending,
            sendUnprocessed,
            ...baseValues,
            typedRecipients: baseValues.typedRecipients || orderCustomerEmail,
          }}
          enableReinitialize
          validationSchema={CheckoutOrderSchema(isOrder)}
          onSubmit={(values) => {
            baseSetValues(values);
            if (!isReviewAfterCheckout) {
              onUpdateHeader({ ...values });
            } else {
              const saveAsQuoteOrOrder: SubmitOCNBody = {
                miLoc,
                orderCtlNo,
                shipToCustNo,
                storeroom: toString(order?.storeroomNo),
                emailToCustomer: values.emailCustomer ?? false,
                emailAddress: values.emailCustomer
                  ? toString(values.typedRecipients)
                  : '',
                emailSubject: toString(values.emailMsg),
                includeAttachments: values.includeAttachments ?? false,
                sendUnprocessed: values.sendUnprocessed ?? false,
              };
              if (isOrder) {
                saveAsQuoteOrOrder.emailAckType = values.emailAckType;
              }
              onSubmitOCN(saveAsQuoteOrOrder);
            }
          }}
        >
          {({ dirty, values, handleSubmit }) => {
            const disableButtons =
              isLoading || disableOrderStatus || fetchingOrder || error;
            const footerButtons = [
              {
                testid: primaryButtonTestId,
                text: primaryButtonText,
                variant: ButtonVariantEnum['mipro-action'],
                disabled: !!disableButtons,
                onClick: () => handlePrimaryButtonClick(values, handleSubmit),
              },
            ];
            if (!summaryMode || (summaryMode && !isOrder && !isUnfinished)) {
              footerButtons.unshift({
                testid: secondaryButtonTestId,
                text: secondaryButtonText,
                variant: ButtonVariantEnum.secondary,
                disabled: !!disableButtons,
                onClick: () => customBackButtonClick(values, dirty),
              });
            }

            return (
              <IonPage
                className={classes.reviewOrderPage}
                data-testid="review-order-page"
              >
                <Header
                  testid="quote-cart-header"
                  hideMenuButton
                  title={`${pageTitle}: ${removeLeadingZeros(orderCtlNo)}`}
                  subTitle={customerData?.name}
                  headerActions={{
                    title: t('productSearch:ocn:manageOcn'),
                    initialBreakpoint: 0.3,
                    disabled: false,
                    options: headerOptions,
                  }}
                  backButton={{
                    onClick: () => customBackButtonClick(values, dirty, true),
                  }}
                />
                <IonContent className={classes.content}>
                  <Refresher
                    slot="fixed"
                    onRefresh={async () => {
                      await Promise.all([
                        queryClient.invalidateQueries([findOrderNotesQueryKey]),
                        queryClient.invalidateQueries([
                          findAttachmentsQueryKey,
                        ]),
                        refetch?.(),
                      ]);
                    }}
                    disabled={
                      orderLoading ||
                      !summaryMode ||
                      ocnNotesLoading ||
                      ocnAttachmentLoading
                    }
                    hidden
                    testid="review-order-refresher"
                  />
                  <Header
                    collapse="condense"
                    customTitle={
                      <>
                        <div
                          className={classNames(
                            classes.flex,
                            classes.headerRow
                          )}
                        >
                          <Text
                            text={`${pageTitle}: ${removeLeadingZeros(
                              orderCtlNo
                            )}`}
                            variant="mipro-h1-headline"
                            testid="shopping-cart-cust-title"
                          />
                          {order?.processStatus && (
                            <div className={classes.badgeWrapper}>
                              <OcnBadge
                                className={classes.badge}
                                statusId={order?.processStatus}
                              />
                            </div>
                          )}
                        </div>
                        <CustomerName customerData={customerData} />
                        {summaryMode && !isEmpty(order?.creationUsername) && (
                          <Text
                            text={t(
                              `productSearch:orderList:OCNListSecondary`,
                              {
                                username: order?.creationUsername,
                              }
                            )}
                            className={classNames(
                              classes.flex,
                              classes.headerRow,
                              classes.createdBy
                            )}
                            variant="mipro-h6-headline"
                          />
                        )}
                      </>
                    }
                    testid="quote-cart-header"
                  />
                  {!isEmpty(warningMessage) &&
                    isReviewAfterCheckout &&
                    !orderLoading &&
                    !isUnfinishedOrder(order?.orderTypeCd) &&
                    !isClosedOrder(order) && (
                      <WarningMessage
                        className={classes.importantNotices}
                        title={t(`${reviewNameSpace}:importantNotices`)}
                        variant={WarningMessageVarianEnum.warning}
                        hideIcon
                        body={warningMessage}
                      />
                    )}
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {pageLoading ? (
                    <Loader
                      className={classes.loader}
                      text={t('common:loading')}
                      isOpen={pageLoading}
                    />
                  ) : error ? (
                    <WarningMessage
                      className={classes.warningMessage}
                      title={t(`${cartNameSpace}:errorOcn`, {
                        ocn: removeLeadingZeros(orderCtlNo),
                      })}
                      body={getErrorMessage(error)}
                      testid="error-holder"
                    />
                  ) : (
                    <>
                      {!isReviewAfterCheckout && !summaryMode && (
                        <CheckoutForm
                          order={order}
                          miLoc={miLoc}
                          customerNo={shipToCustNo}
                          disabled={isUpdateHeaderLoading}
                          ocnType={ocnType}
                          ocnMode={ocnMode}
                          ocn={orderCtlNo}
                        />
                      )}
                      {isReviewAfterCheckout && (
                        <div className={classes.reviewWrapper}>
                          <OcnHeader
                            summaryMode={summaryMode}
                            ocnType={ocnType}
                            order={order}
                          />
                          <OcnLinesReview order={order} />
                          <OrderNotes
                            customerNo={shipToCustNo}
                            ocnType={ocnType}
                            ocnMode={ocnMode}
                            miLoc={miLoc}
                            orderCtlNo={orderCtlNo}
                            viewMode
                          />
                          <OrderAttachments
                            miLoc={miLoc}
                            orderCtlNo={orderCtlNo}
                            summaryMode={summaryMode}
                            viewMode
                          />
                        </div>
                      )}
                    </>
                  )}
                  <DiscardModal
                    isOpen={isOpenDiscardModal}
                    setIsOpen={setIsOpenDiscardModal}
                    title={t('notes:cancelNotesTitle')}
                    discardMsg={t('common:discardMsg')}
                    testid="edit--order--note--discard--modal"
                    discardButtonTitle={t('notes:cancelNotesYes')}
                    goBackButtonTitle={t('notes:cancelNotesNo')}
                    initialBreakpoint={0.4}
                    withRightCloseButton
                    onDiscardClick={() => goBack()}
                  />
                  {order && summaryMode && (
                    <DiscardModal
                      title={t('productSearch:ocn:switchOcnTitle')}
                      discardMsg={t('productSearch:ocn:switchOcnBody', {
                        customerName: currentCartCustomer?.customerName,
                        ocn:
                          currentCartCustomer?.orderCtlNo &&
                          removeLeadingZeros(currentCartCustomer.orderCtlNo),
                      })}
                      isOpen={cartModalIsOpen}
                      setIsOpen={setCartModalIsOpen}
                      initialBreakpoint={0.4}
                      backdropDismiss={false}
                      withRightCloseButton
                      discardButtonTitle={t('common:cancel')}
                      goBackButtonTitle={t('productSearch:ocn:switchOcnYes')}
                      onGoBackClick={() => {
                        switchCartToast({
                          ocn: toString(order?.orderCtlNo),
                          values,
                          orderStayedUM: stayedUM,
                          nextRoute: isGoToReviewCart
                            ? goToReviewCart({
                                miLoc,
                                shipToCustNo,
                                orderCtlNo,
                                ocnType: 'order',
                                ocnMode: 'checkout',
                                reset: true,
                              })
                            : goToOrderCart(
                                miLoc,
                                shipToCustNo,
                                toString(order?.orderCtlNo)
                              ),
                        });
                        setIsGoToReviewCart(false);
                      }}
                      testid="swtich-cart-modal"
                    />
                  )}
                </IonContent>
                {canCreateEditOrder && !isClosedOrder(order) && (
                  <Footer buttons={footerButtons} />
                )}
              </IonPage>
            );
          }}
        </Formik>
      )}
    </Formik>
  );
};

export default ReviewOrder;
