import React from 'react';
import { map } from 'lodash';
import ActionRow from 'components/ActionRow/ActionRow';
import type Modal from 'components/Modal/Modal';
import SheetModal from 'components/Modals/SheetModal/SheetModal';
import classes from './ListModal.module.scss';

export interface OptionItem {
  label: string;
  value: string;
}

interface ReasonModalProps {
  selectedOption?: string;
  options?: OptionItem[];
  onClick?: (value: string, label: string) => void;
  title?: string;
}

const ListModal = ({
  onClick,
  selectedOption,
  options,
  isOpen,
  setIsOpen,
  testid,
  title,
}: ReasonModalProps &
  Omit<
    React.ComponentProps<typeof Modal>,
    'title' | 'onClick'
  >): JSX.Element => {
  return (
    <SheetModal
      testid={`${testid}-reason-modal`}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      withRightCloseButton
      title={title}
      className={classes.modal}
    >
      {map(options, ({ label, value }, i) => (
        <ActionRow
          key={i}
          onClick={() => {
            onClick?.(value, label);
            setIsOpen?.(false);
          }}
          icon={selectedOption === value ? ['fas', 'check'] : undefined}
          testid={`${testid}-reason-modal-${i}`}
          text={label}
          textVariant="mipro-h4-headline"
          className={classes.actionRow}
        />
      ))}
    </SheetModal>
  );
};

export default ListModal;
