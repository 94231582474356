import React, { useState, useEffect } from 'react';
import type { TFunction } from 'react-i18next';
import { upperCase } from 'lodash';
import { IonCard, IonCardContent, IonCardHeader } from '@ionic/react';
import { ProductGroupKey, useReportsConfig } from 'providers/ReportsProvider';
import useGetSalesReport from 'ReportsApp/api/useGetSalesReport';
import JobWarning from 'ReportsApp/components/JobWarning/JobWarning';
import useGetProductGroups from 'api/productgroups/useGetProductGroups';
import { SortDirEnum } from 'models/Sort';
import { getErrorMessage } from 'utils/helpers';
import { isSalesJobRunning } from 'utils/reports';
import Button from 'components/Button/Button';
import Loader from 'components/Loader/Loader';
import ReportDateDropdown from 'components/ReportDateDropdown/ReportDateDropdown';
import SalesProductGroupDetails from 'components/SalesProductGroupDetails/SalesProductGroupDetails';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './Snapshots.module.scss';

type Props = {
  miLoc: string;
  id: string;
  onProductGroupClick: () => void;
  onPGC1Click: (pgc1Id: string) => string;
  t: TFunction;
};

const SalesProductGroupCard = ({
  miLoc,
  id,
  onProductGroupClick,
  onPGC1Click,
  t,
}: Props): JSX.Element => {
  const [requestDate, setRequestDate] = useState('');

  const reportKey = ProductGroupKey;
  const { requestType, busPeriod, updateRequestType, updateBusPeriod } =
    useReportsConfig({ key: reportKey });

  const { data: productGroups, error } = useGetProductGroups();

  useEffect(() => {
    updateRequestType('YTD');
    updateBusPeriod(new Date());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [miLoc, id]);

  const {
    drilldownData: pg1DrilldownData,
    error: pgc1Error,
    showLoader: drilldownIsLoading,
    summaryData: pg1Summary,
  } = useGetSalesReport({
    miLoc,
    customerId: id,
    busPeriod,
    requestType,
    groupBy: 'PRD_GRP_01',
    sortField: 'sales',
    sortDir: SortDirEnum.ASCENDING,
    showZeroSalesPGC: true,
    limit: 100,
  });

  const { drilldownData: pg2DrilldownData = [], error: pgc2Error } =
    useGetSalesReport({
      miLoc,
      customerId: id,
      busPeriod,
      requestType,
      groupBy: 'PRD_GRP_02',
      sortField: 'sales',
      sortDir: SortDirEnum.ASCENDING,
      showZeroSalesPGC: true,
      limit: 100,
    });

  const pg1SalesSummary = pg1Summary?.sales?.find(
    (item) => item.Name === 'Sales'
  );

  const isJobRunning =
    isSalesJobRunning(pgc1Error) || isSalesJobRunning(pgc2Error);

  if (error || (!isJobRunning && (pgc1Error || pgc2Error))) {
    return (
      <WarningMessage
        className={classes.warningMessage}
        title={t('errorLoading')}
        body={getErrorMessage(error || pgc1Error || pgc2Error)}
      />
    );
  }

  return (
    <IonCard>
      <IonCardHeader className={classes.salesPGCardHeader}>
        <Button
          variant="link"
          testid="ar_card_header_btn"
          onClick={onProductGroupClick}
        >
          <Text
            text={upperCase(t('snapshot:salesProductGroup'))}
            className={classes.salesPGCardHeaderTitle}
          />
        </Button>
        <div className={classes.requestTypeWrapper}>
          <Text
            variant="label-header-micro"
            text={requestDate}
            className={classes.requestDate}
          />
          <ReportDateDropdown
            reportKey={reportKey}
            id="PRD-GRP"
            busPeriod={busPeriod}
            requestType={requestType}
            setBusPeriod={updateBusPeriod}
            setRequestType={updateRequestType}
            setRequestDate={setRequestDate}
            buttonClassName={classes.salesPGCBtn}
          />
        </div>
      </IonCardHeader>
      {isJobRunning && (
        <JobWarning
          className={classes.warningMessage}
          reportName={t('snapshot:salesProductGroup')}
        />
      )}
      {drilldownIsLoading && <Loader isOpen />}
      {!drilldownIsLoading && !isJobRunning && (
        <IonCardContent className={classes.salesProductGroupCard}>
          <SalesProductGroupDetails
            pg1DrilldownData={pg1DrilldownData}
            pg2DrilldownData={pg2DrilldownData}
            productGroups={productGroups}
            onPGC1Click={onPGC1Click}
            pg1SalesSummary={pg1SalesSummary}
            t={t}
            currencyType={pg1Summary?.currencyType}
          />
        </IonCardContent>
      )}
    </IonCard>
  );
};

export default SalesProductGroupCard;
