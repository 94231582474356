import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  head,
  keys,
  map,
  max,
  maxBy,
  minBy,
  remove,
  size,
  times,
  toNumber,
  toString,
} from 'lodash';
import type { BarDatum } from '@nivo/bar';
import { formatNumber } from 'common/utils/numberHelper';
import type { DateSegmentType } from 'models/Reports';
import type { SegmentTabOptionProps } from 'components/SegmentTabs/SegmentTabs';

const monthIntialChar = [
  'J',
  'F',
  'M',
  'A',
  'M',
  'J',
  'J',
  'A',
  'S',
  'O',
  'N',
  'D',
];

export const getTrendData = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  data: BarDatum[] = [],
  requestType: DateSegmentType
): BarDatum[] => {
  if (size(data) > 0) {
    return data;
  }
  if (requestType === 'MTD') {
    // DOC: mock 20 bussinessDays instead of 30 calendar days
    return times(20, (i) => ({
      date: i + 1,
      busDate: i + 1,
      dollars: 0,
      quantity: 0,
    }));
  }
  if (requestType === 'YTD') {
    return times(12, (i) => ({
      date: i + 1,
      busDate: i + 1,
      dollars: 0,
      quantity: 0,
    }));
  }
  return [];
};

const formatBottomAxisValues =
  (
    data: BarDatum[],
    requestType: DateSegmentType,
    selectedDate: Date,
    enableBusDate?: boolean,
    busDay?: number
  ) =>
  (value: number | string, index: number) => {
    const selectedMonth = selectedDate.getMonth();
    const selectedYear = selectedDate.getFullYear();
    const currentDate = new Date();

    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    // const isDaily = requestType === 'DAILY';
    const isChartMonthly = requestType === 'MTD';
    const isChartYearly = requestType === 'YTD';
    if (isChartMonthly) {
      // returns current date
      if (busDay && enableBusDate && toString(busDay) === toString(value)) {
        return value;
      }
      if (
        currentDate.getDate() === value &&
        selectedMonth === currentMonth &&
        selectedYear === currentYear
      ) {
        return value;
      }
      // always display last date
      if (size(data) - 1 === index) {
        return value;
      }
      // don't display 1-4 days before last date
      if (index >= Math.floor(size(data) / 5) * 4) {
        return '';
      }
      // display every 5th days
      if (index % 5 === 0) {
        return value;
      }
      return '';
    }
    if (isChartYearly) {
      return monthIntialChar[toNumber(value) - 1];
    }
    return value;
  };

const handleCurrentDateHighlight =
  (
    requestType: DateSegmentType,
    selectedDate: Date,
    enableBusDate?: boolean,
    busDay?: number
  ) =>
  (currentTickValue: number | string): boolean => {
    const selectedMonth = selectedDate.getMonth();
    const selectedYear = selectedDate.getFullYear();
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    // const isDaily = requestType === 'DAILY';
    const isChartMonthly = requestType === 'MTD';
    if (isChartMonthly) {
      if (
        busDay &&
        enableBusDate &&
        toString(busDay) === toString(currentTickValue)
      ) {
        return true;
      }

      if (
        !enableBusDate &&
        selectedMonth === currentMonth &&
        selectedYear === currentYear
      ) {
        return currentTickValue === currentDate.getDate();
      }
    } else if (selectedYear === currentYear) {
      return (
        currentTickValue ===
        currentDate.toLocaleString('default', { month: 'short' })
      );
    }
    return false;
  };

export interface TickProps {
  value: unknown;
  x: number;
  y: number;
  tickIndex: number;
}

export const customXAxisTick =
  (
    data: BarDatum[],
    requestType: DateSegmentType,
    selectedDate: Date,
    enableBusDate?: boolean,
    disableHighlight?: boolean,
    busDay?: number
  ) =>
  (tick: unknown): JSX.Element => {
    const isChartMonthly = requestType === 'MTD';
    const { value, x, y, tickIndex } = tick as TickProps;
    const tickValue = isChartMonthly ? toNumber(value) : toString(value);
    const currentTickValue = formatBottomAxisValues(
      data,
      requestType,
      selectedDate,
      enableBusDate,
      busDay
    )(tickValue, tickIndex);
    const isCurrentDate =
      !disableHighlight &&
      handleCurrentDateHighlight(
        requestType,
        selectedDate,
        enableBusDate,
        busDay
      )(currentTickValue);
    return (
      <g transform={`translate(${x},${y + 12})`}>
        {isCurrentDate && (
          <rect
            x={isChartMonthly ? -10 : -20}
            y={-10}
            ry={10}
            width={isChartMonthly ? 20 : 39}
            height={20}
            fill="rgb(216, 216, 216)"
          />
        )}
        <text
          textAnchor="middle"
          alignmentBaseline="middle"
          dominantBaseline="middle"
          fill={isCurrentDate ? '#333333' : '#999999'}
        >
          {currentTickValue}
        </text>
      </g>
    );
  };

export const formatLeftYAxisValues =
  (isLoading: boolean, currencyType?: string) =>
  (n: string | number | Date): string =>
    isLoading
      ? ''
      : formatNumber({
          number: toNumber(n),
          abbreviated: true,
          currencyType,
        });

export const formatRightYAxisValues =
  (leftRightRatio: number, rightYKey: string) =>
  (n: string | number | Date, useRation = true): string => {
    const val = formatNumber({
      number: toNumber(n) / (useRation ? leftRightRatio : 1),
      abbreviated: true,
    }).replace('$', '');
    switch (rightYKey) {
      case 'gpPercent':
        return `${val}%`;
      case 'quantity':
      case 'transactions':
      default:
        return `${val}`;
    }
  };

interface GetChartKeys {
  leftYKey: string;
  rightYKey: string;
}

export const getChartKeys = (data: BarDatum[]): GetChartKeys => {
  const dataKeys = keys(head(data));
  remove(dataKeys, (val) => val === 'date');
  let leftYKey = '';
  let rightYKey = '';
  for (let i = 0; i < dataKeys.length; i += 1) {
    switch (dataKeys[i]) {
      case 'dollars':
      case 'gp':
        leftYKey = dataKeys[i];
        break;
      case 'quantity':
      case 'transactions':
      case 'gpPercent':
        rightYKey = dataKeys[i];
        break;
      default:
        break;
    }
  }
  return { leftYKey, rightYKey };
};

interface GetChartBoundariesReponse {
  maxLeftYValue: number;
  minLeftYValue: number;
  marginLeft: number;
  marginRight: number;
  leftRightRatio: number;
  minRightYValueWithRation: number;
  chartData: BarDatum[];
}

export const getChartBoundaries = (
  data: BarDatum[],
  leftYKey: string,
  rightYKey: string,
  currencyType?: string,
  isLineChart = false
): GetChartBoundariesReponse => {
  const getOrderOfMagnitude = (n: number) =>
    10 ** Math.floor(Math.log(Math.abs(n)) / Math.LN10 + 0.000000001) || 1;

  const getYMaxValue = (key: string) => {
    const maxValue = toNumber(maxBy(data, key)?.[key]) || 0;
    const order = getOrderOfMagnitude(maxValue);
    return Math.ceil(maxValue / order) * order;
  };

  const getYMinValue = (key: string) => {
    const minValue = toNumber(minBy(data, key)?.[key]) || 0;
    const order = getOrderOfMagnitude(minValue);
    return Math.floor(minValue / order) * order;
  };

  let maxLeftYValue = getYMaxValue(leftYKey);
  maxLeftYValue = maxLeftYValue < 3 ? 3 : maxLeftYValue;

  let minLeftYValue = getYMinValue(leftYKey);
  // DOC: if there are no negative values, use 0 as min value
  minLeftYValue = minLeftYValue > 0 ? 0 : minLeftYValue;
  let maxRightYValue = getYMaxValue(rightYKey);
  maxRightYValue = maxRightYValue < 3 ? 3 : maxRightYValue;
  let minRightYValue = getYMinValue(rightYKey);
  minRightYValue = minRightYValue > 0 ? 0 : minRightYValue;
  const leftRightRatio = maxLeftYValue / maxRightYValue;

  /**
   * DOC: we still calculate the tick values to get the margin needed for the legends,
   * as nivo native implementation seems to be really similar
   */
  const getYAxisStepValues = (maxValue: number, minValue: number) => {
    const stepValues: number[] = [];
    let tickIntervalVal = Math.abs(maxValue - minValue) / 5;
    tickIntervalVal = tickIntervalVal <= 5 ? 1 : tickIntervalVal;
    for (let i = 0; i < 6; i += 1) {
      stepValues.push(minValue + tickIntervalVal * i);
    }
    return stepValues;
  };
  const leftYAxisValues = getYAxisStepValues(maxLeftYValue, minLeftYValue);
  const rightYAxisValues = getYAxisStepValues(maxRightYValue, minRightYValue);

  const maxLeftValueLength =
    max(
      map(leftYAxisValues, (val) =>
        size(
          formatNumber({
            number: toNumber(val),
            abbreviated: true,
            currencyType,
          })
        )
      )
    ) || 0;
  const marginLeft = 16 + maxLeftValueLength * 6;
  const maxRightValueLength =
    max(
      map(rightYAxisValues, (val) =>
        size(formatRightYAxisValues(leftRightRatio, rightYKey)(val, false))
      )
    ) || 0;
  const marginRight = 16 + maxRightValueLength * 5;

  const chartData = map(data, (value) => {
    const leftValue = toNumber(value[leftYKey]) || 0;
    const rightValue = (toNumber(value[rightYKey]) || 0) * leftRightRatio;
    // DOC: no min values for lineChart and keep nulls
    if (isLineChart) {
      return { ...value, [leftYKey]: leftValue === 0 ? null : leftValue };
    }
    return {
      ...value,
      [leftYKey]: leftValue === 0 ? maxLeftYValue / 25 : leftValue,
      [rightYKey]: rightValue === 0 ? maxLeftYValue / 25 : rightValue,
    };
  }) as BarDatum[];
  const minRightYValueWithRation = minRightYValue * leftRightRatio;

  return {
    maxLeftYValue,
    minLeftYValue,
    marginLeft,
    marginRight,
    leftRightRatio,
    minRightYValueWithRation,
    chartData,
  };
};

export const lineChartColors: Record<string, string> = {
  sales: '#f6a522',
  'prev-sales': '#a5a5a5',
  profit: '#3bc1ca',
  'prev-profit': '#a5a5a5',
};

export const barDailyChartColors: Record<string, string> = {
  sales: '#f6a522',
  'prev-sales': '#a5a5a5',
  profit: '#3bc1ca',
  'prev-profit': '#a5a5a5',
};

export const useGetChartHeaderTabs = (): SegmentTabOptionProps[] => {
  const { t } = useTranslation();
  return [
    { key: 'sales', text: t('sales') },
    { key: 'profit', text: t('profit') },
  ];
};
