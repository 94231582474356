import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { find, isEmpty, map, size, toString, trim } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonCol, IonRow } from '@ionic/react';
import { fromUnixTime, getUnixTime } from 'date-fns';
import useFindTemplates from 'DocumentsApp/api/useFindTemplates';
import MachinesFastFind from 'DocumentsApp/components/FastFind/MachinesFastFind';
import SitesFastFind from 'DocumentsApp/components/FastFind/SitesFastFind';
import WorkOrdersFastFind from 'DocumentsApp/components/FastFind/WorkOrdersFastFind';
import { useMiLocOrTeamId } from 'api/helpers';
import useAccessControls, { AccessControlType } from 'hooks/useAccessControls';
import type {
  ReportImage,
  WorkOrderFastFind,
  Site,
  Machine,
} from 'models/InspectionReport';
import type { RootState } from 'store/reducers';
import { DateFormatEnum, formatDate, parseDate } from 'utils/date';
import { findIcon } from 'utils/icons';
import DateInput from 'components/DateInput/DateInput';
import DocumentImage from 'components/Documents/DocumentImage/DocumentImage';
import Input from 'components/Input/Input';
import Loader from 'components/Loader/Loader';
import Select from 'components/Select/Select';
import Text from 'components/Text/Text';
import TitleLine from 'components/TitleLine/TitleLine';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './DocumentCoverPage.module.scss';

export interface DocumentCoverPageProps {
  templateId?: number;
  reportId?: number;
  isLoading: boolean;
  isExpanded: boolean;
  disabled?: boolean;
  setIsExpanded: (newVal: boolean) => void;
  isNewDoc?: boolean;
  formData: CoverPageForm;
  setFormData: (formData: CoverPageForm) => void;
  coverImage: ReportImage | undefined;
  setCoverImage: (img: ReportImage | undefined) => void;
  coverImageForUpload: ReportImage | undefined;
  setCoverImageForUpload: (img: ReportImage) => void;
  coverImageForRemoval: ReportImage | undefined;
  setCoverImageForRemoval: (img: ReportImage) => void;
  startingSaveSection: boolean;
}

export interface CoverPageForm {
  data: Record<string, string | number | boolean>;
}

const DocumentCoverPage = ({
  reportId,
  isLoading,
  isNewDoc = false,
  isExpanded,
  disabled,
  setIsExpanded,
  formData,
  setFormData,
  coverImage,
  setCoverImage,
  coverImageForUpload,
  setCoverImageForUpload,
  coverImageForRemoval,
  setCoverImageForRemoval,
  startingSaveSection = false,
}: DocumentCoverPageProps): JSX.Element => {
  const { locationTree = {} } = useSelector((state: RootState) => state.user);
  const { templates } = useFindTemplates({});
  const [workOrder, setWorkOrder] = useState<WorkOrderFastFind>();
  const [site, setSite] = useState<Site>();
  const [machine, setMachine] = useState<Machine>();

  const documentEditPermissions = useAccessControls(
    AccessControlType.editDocuments
  );

  const [disabledView, setDisabledView] = useState(startingSaveSection);

  const isGearboxDocumentType = formData.data.templateId === '1';

  const templateTypes = map(
    templates,
    ({ templateId, templateName, templateType, templateVersion }) => ({
      id: toString(templateId),
      text: templateName,
      template_type: templateType,
      template_ver: templateVersion,
    })
  );

  const onCoverPageHeaderClick: () => void = () => {
    if (!disabledView) {
      setIsExpanded(!isExpanded);
    }
  };

  useEffect(() => {
    setDisabledView(startingSaveSection);
  }, [startingSaveSection]);

  const selectStatuses = [
    { id: 'IP', text: 'In Progress' },
    { id: 'CL', text: 'Complete' },
  ];

  const updateFormValue = (newVal: string, attributeName: string) => {
    const newValues: CoverPageForm = {
      data: {
        templateId:
          attributeName === 'templateId' ? newVal : formData.data.templateId,
        reportType:
          attributeName === 'templateId'
            ? find(templateTypes, ['id', newVal])?.template_type || ''
            : formData.data.reportType,
        templateVersion:
          attributeName === 'templateId'
            ? find(templateTypes, ['id', newVal])?.template_ver || 0
            : formData.data.templateVersion,
        documentTitle:
          attributeName === 'documentTitle'
            ? newVal
            : formData.data.documentTitle,
        startDate:
          attributeName === 'startDate' ? newVal : formData.data.startDate,
        endDate: attributeName === 'endDate' ? newVal : formData.data.endDate,
        status: attributeName === 'status' ? newVal : formData.data.status,
        shop: attributeName === 'shop' ? newVal : formData.data.shop,
        woNo: attributeName === 'woNo' ? newVal : formData.data.woNo,
        ocn: attributeName === 'ocn' ? newVal : formData.data.ocn,
        branch: attributeName === 'branch' ? newVal : formData.data.branch,
        customer:
          attributeName === 'customer' ? newVal : formData.data.customer,
        customerName:
          attributeName === 'customerName'
            ? newVal
            : formData.data.customerName,
        customerNo:
          attributeName === 'customerNo' ? newVal : formData.data.customerNo,
        orderLineNo:
          attributeName === 'orderLineNo' ? newVal : formData.data.orderLineNo,
        machineId:
          attributeName === 'machineId' ? newVal : formData.data.machineId,
        machineText:
          attributeName === 'machineText' ? newVal : formData.data.machineText,
        siteId: attributeName === 'siteId' ? newVal : formData.data.siteId,
        siteText:
          attributeName === 'siteText' ? newVal : formData.data.siteText,
        custMachineId:
          attributeName === 'custMachineId'
            ? newVal
            : formData.data.custMachineId,
        customerContact:
          attributeName === 'customerContact'
            ? newVal
            : formData.data.customerContact,
        customerContactPhone:
          attributeName === 'customerContactPhone'
            ? newVal
            : formData.data.customerContactPhone,
        creationUserId:
          attributeName === 'creationUserId'
            ? newVal
            : formData.data.creationUserId,
        creationUserName:
          attributeName === 'creationUserName'
            ? newVal
            : formData.data.creationUserName,
        creationTmstmp:
          attributeName === 'creationTmstmp'
            ? newVal
            : formData.data.creationTmstmp,
        lastUpdUserId:
          attributeName === 'lastUpdUserId'
            ? newVal
            : formData.data.lastUpdUserId,
        lastupdUserName:
          attributeName === 'lastupdUserName'
            ? newVal
            : formData.data.lastupdUserName,
        lastUpdTmstmp:
          attributeName === 'lastUpdTmstmp'
            ? newVal
            : formData.data.lastUpdTmstmp,
      },
    };
    setFormData(newValues);
  };

  const [containsImages] = useState(false);
  const [containsData] = useState(false);

  const [formError, setFormError] = useState({ title: '', startDate: '' });
  const { createParams } = useMiLocOrTeamId({ sendTeamId: false });
  const { miLoc } = createParams();

  useEffect(() => {
    if (formData.data.reportType === 'machine' && isNewDoc) {
      updateFormValue(miLoc, 'branch');
    } else if (!isEmpty(formData.data.reportType) && isNewDoc) {
      updateFormValue('', 'branch');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.data.reportType, isNewDoc, miLoc]);

  const branchName = toString(
    locationTree[toString(formData.data.branch)]?.name
  );
  const hasCoverImage = formData.data.reportType !== 'fieldservice';

  const imagesForUpload = useMemo(
    () => (coverImageForUpload ? [coverImageForUpload] : []),
    [coverImageForUpload]
  );
  const imagesForRemoval = useMemo(
    () => (coverImageForRemoval ? [coverImageForRemoval] : []),
    [coverImageForRemoval]
  );

  if (isNewDoc && size(templates) === 0) {
    return (
      <WarningMessage
        className={classes.warningMessage}
        icon={['far', 'info-circle']}
        title="No Document Templates"
        body="Are you expecting data? Go online and download to sync with the server."
      />
    );
  }

  return (
    <div className={classes.DocumentCoverTitle}>
      <IonRow
        className={classNames(classes.DocumentCoverPage, {
          [classes.noBottomBorder]: isExpanded,
          [classes.isLoading]: isLoading,
        })}
        data-testid="DocumentCoverPage"
        role="button"
        onKeyPress={() => {}}
        tabIndex={0}
        onClick={() => {
          onCoverPageHeaderClick();
        }}
      >
        <IonCol className={classes.p0}>
          <Text text="Cover Page" variant="underlined-title-section" />
          <TitleLine />
        </IonCol>
        <IonCol size="auto" className={classes.p0}>
          {isLoading && (
            <span className={classes.sectionLoaderWrap}>
              <Loader isOpen={isLoading} />
            </span>
          )}
          {containsImages && (
            <FontAwesomeIcon
              className={classes.rightIcon}
              icon={findIcon('file-image')}
            />
          )}
          {containsData && (
            <FontAwesomeIcon
              className={classes.rightIcon}
              icon={findIcon('file-edit')}
            />
          )}
        </IonCol>
      </IonRow>
      {isExpanded && (
        <div className={classes.coverPanelWrap}>
          {isNewDoc && (
            <IonRow className={classes.documentRow}>
              <IonCol size="12" className={classes.documentCol}>
                <Select
                  required
                  label="Document Type"
                  options={templateTypes}
                  value={formData.data.templateId}
                  setValue={(v: string) => {
                    updateFormValue(v, 'templateId');
                  }}
                  testid="reportTypeSelect"
                />
              </IonCol>
            </IonRow>
          )}
          {!isEmpty(formData.data.reportType) && (
            <>
              {(formData.data.reportType === 'workorder' ||
                formData.data.reportType === 'fieldservice') && (
                <IonRow className={classes.documentRow}>
                  <IonCol size="12" className={classes.documentCol}>
                    <Input
                      disabled
                      label="Motion Shop"
                      value={`${toString(formData.data.shop)} - ${toString(
                        locationTree[toString(formData.data.shop)]?.name
                      )}`}
                      testid="shop-input"
                    />
                  </IonCol>
                </IonRow>
              )}
              <IonRow className={classes.documentRow}>
                <IonCol size="12" className={classes.documentCol}>
                  <Input
                    required
                    label="Document Title"
                    disabled={disabled || isLoading}
                    value={toString(formData.data.documentTitle)}
                    testid="containerInput"
                    setValue={(eData: string) => {
                      updateFormValue(eData, 'documentTitle');
                    }}
                    error={formError.title}
                    onBlur={() => {
                      if (!formData.data.documentTitle) {
                        setFormError((prev) => ({
                          ...prev,
                          title: 'Document Title is required',
                        }));
                      } else {
                        setFormError((prev) => ({ ...prev, title: '' }));
                      }
                    }}
                    autocapitalize="sentences"
                    spellcheck
                  />
                </IonCol>
              </IonRow>
              <IonRow className={classes.documentRow}>
                <IonCol size="12" className={classes.documentCol}>
                  <DateInput
                    testid="start-date-input"
                    required
                    name="start-date"
                    label="Start Date"
                    disabled={disabled || isLoading}
                    value={
                      formData.data.startDate
                        ? getUnixTime(
                            parseDate(toString(formData.data.startDate))
                          )
                        : 0
                    }
                    maxDate={
                      formData.data.endDate
                        ? parseDate(toString(formData.data.endDate))
                        : undefined
                    }
                    setValue={(v: number) => {
                      setFormError((prev) => ({ ...prev, startDate: '' }));
                      updateFormValue(
                        formatDate(fromUnixTime(v), DateFormatEnum.ISO),
                        'startDate'
                      );
                    }}
                    error={formError.startDate}
                    onBlur={() => {
                      if (!formData.data.startDate) {
                        setFormError((prev) => ({
                          ...prev,
                          startDate: 'Start Date is required',
                        }));
                      } else {
                        setFormError((prev) => ({ ...prev, startDate: '' }));
                      }
                    }}
                  />
                </IonCol>
              </IonRow>
              <IonRow className={classes.documentRow}>
                <IonCol size="12" className={classes.documentCol}>
                  <Select
                    required
                    label="Status"
                    options={selectStatuses}
                    disabled={
                      isLoading ||
                      !documentEditPermissions ||
                      Boolean(formData.data.hasValidSignature)
                    }
                    value={formData.data.status}
                    setValue={(v) => {
                      const newValues: CoverPageForm = { ...formData };
                      newValues.data.status = v;
                      if (v !== 'CL') {
                        newValues.data.endDate = '';
                      }
                      setFormData(newValues);
                    }}
                    testid="reportTypeSelect"
                  />
                </IonCol>
              </IonRow>
              <IonRow className={classes.documentRow}>
                <IonCol size="12" className={classes.documentCol}>
                  <DateInput
                    testid="end-date-input"
                    name="end-date"
                    label="Completion Date of Inspection"
                    disabled={
                      formData.data.status !== 'CL' ||
                      isLoading ||
                      !documentEditPermissions ||
                      Boolean(formData.data.hasValidSignature)
                    }
                    required={formData.data.status === 'CL'}
                    value={
                      formData.data.endDate
                        ? getUnixTime(
                            parseDate(toString(toString(formData.data.endDate)))
                          )
                        : 0
                    }
                    minDate={
                      formData.data.startDate
                        ? parseDate(toString(formData.data.startDate))
                        : undefined
                    }
                    setValue={(v: number) => {
                      updateFormValue(
                        formatDate(fromUnixTime(v), DateFormatEnum.ISO),
                        'endDate'
                      );
                    }}
                  />
                </IonCol>
              </IonRow>
            </>
          )}
          {(formData.data.reportType === 'workorder' ||
            formData.data.reportType === 'fieldservice') && (
            <>
              <IonRow className={classes.documentRow}>
                <IonCol size="12" className={classes.documentCol}>
                  <WorkOrdersFastFind
                    required
                    value={toString(formData.data.woNo)}
                    disabled={disabled || isLoading}
                    setValue={(v) => updateFormValue(v, 'woNo')}
                    templateId={toString(formData.data.templateId)}
                    workOrder={workOrder}
                    setWorkOrder={(item) => {
                      setWorkOrder(item);
                      const newValues: CoverPageForm = { ...formData };
                      newValues.data.woNo = trim(item.woCtlNo);
                      newValues.data.branch = trim(item.miLoc);
                      newValues.data.ocn = trim(item.orderCtlNo);
                      newValues.data.customer = trim(item.custNo);
                      newValues.data.customerNo = trim(item.custNo);
                      newValues.data.customerName = trim(item.customerName);
                      newValues.data.orderLineNo = item.orderLineNo;
                      setFormData(newValues);
                    }}
                    label="Work Order Number"
                    testid="workorder-input"
                  />
                </IonCol>
              </IonRow>
              <IonRow className={classes.documentRow}>
                <IonCol size="12" className={classes.documentCol}>
                  <Input
                    disabled
                    label="Motion Branch"
                    value={toString(formData.data.branch)}
                    testid="containerInput"
                  />
                </IonCol>
              </IonRow>
              <IonRow className={classes.documentRow}>
                <IonCol size="12" className={classes.documentCol}>
                  <Input
                    disabled
                    label="OCN"
                    value={toString(formData.data.ocn)}
                    testid="containerInput"
                  />
                </IonCol>
              </IonRow>
              <IonRow className={classes.documentRow}>
                <IonCol size="12" className={classes.documentCol}>
                  <Input
                    disabled
                    label="Customer"
                    value={toString(
                      formData.data.customerName || formData.data.customer
                    )}
                    testid="containerInput"
                  />
                </IonCol>
              </IonRow>
              {formData.data.reportType === 'fieldservice' && (
                <>
                  <IonRow className={classes.documentRow}>
                    <IonCol size="12" className={classes.documentCol}>
                      <Input
                        label="Customer Contact"
                        disabled={disabled || isLoading}
                        value={toString(formData.data.customerContact)}
                        setValue={(eData: string) => {
                          updateFormValue(eData, 'customerContact');
                        }}
                        testid="containerInput"
                      />
                    </IonCol>
                  </IonRow>
                  <IonRow className={classes.documentRow}>
                    <IonCol size="12" className={classes.documentCol}>
                      <Input
                        label="Customer Contact Phone"
                        disabled={disabled || isLoading}
                        value={toString(formData.data.customerContactPhone)}
                        setValue={(eData: string) => {
                          updateFormValue(eData, 'customerContactPhone');
                        }}
                        testid="containerInput"
                      />
                    </IonCol>
                  </IonRow>
                </>
              )}
            </>
          )}
          {formData.data.reportType === 'machine' && (
            <>
              <IonRow className={classes.documentRow}>
                <IonCol size="12" className={classes.documentCol}>
                  <Input
                    disabled
                    label="Branch"
                    value={`${toString(formData.data.branch)}${
                      branchName ? ` - ${branchName}` : ''
                    }`}
                    testid="branch-input"
                  />
                </IonCol>
              </IonRow>
              <IonRow className={classes.documentRow}>
                <IonCol size="12" className={classes.documentCol}>
                  <SitesFastFind
                    required
                    value={toString(formData.data.siteId)}
                    disabled={disabled || isLoading}
                    setValue={(v) => updateFormValue(v, 'siteId')}
                    site={{ ...site, siteText: formData.data.siteText } as Site}
                    setSite={(item) => {
                      setSite(item);
                      const newValues: CoverPageForm = { ...formData };
                      newValues.data.siteId = item.siteId;
                      newValues.data.siteText = item.displayText;
                      newValues.data.customer = trim(item.siteCustNo);
                      newValues.data.customerNo = trim(item.siteCustNo);
                      newValues.data.customerName = trim(item.customerName);
                      // DOC: reset machine because site has changed
                      setMachine(undefined);
                      newValues.data.machineId = '';
                      newValues.data.custMachineId = '';
                      newValues.data.machineText = '';
                      setFormData(newValues);
                    }}
                    label="Site"
                    testid="site-input"
                  />
                </IonCol>
              </IonRow>
              <IonRow className={classes.documentRow}>
                <IonCol size="12" className={classes.documentCol}>
                  <MachinesFastFind
                    required
                    value={toString(formData.data.machineId)}
                    disabled={disabled || isLoading}
                    siteId={toString(formData.data.siteId)}
                    setValue={(v) => updateFormValue(v, 'machineId')}
                    machine={
                      {
                        ...machine,
                        machineText: formData.data.machineText,
                      } as Machine
                    }
                    setMachine={(item) => {
                      setMachine(item);
                      const newValues: CoverPageForm = { ...formData };
                      newValues.data.machineId = item.machineId;
                      newValues.data.custMachineId = item.custMachineId;
                      newValues.data.machineText = item.displayText;
                      setFormData(newValues);
                    }}
                    label="Machine"
                    testid="machine-input"
                  />
                </IonCol>
              </IonRow>
              <IonRow className={classes.documentRow}>
                <IonCol size="12" className={classes.documentCol}>
                  <Input
                    disabled
                    required
                    label="Customer"
                    value={toString(
                      formData.data.customerName || formData.data.customer
                    )}
                    testid="containerInput"
                  />
                </IonCol>
              </IonRow>
            </>
          )}
          {!isEmpty(formData.data.reportType) && (
            <IonRow
              className={classNames(
                classes.documentRow,
                classes.coverImageWrap
              )}
            >
              {hasCoverImage && (
                <>
                  <IonCol className={classes.documentCol} size="12">
                    <Text text="Cover Photo" variant="content-heavy" />
                  </IonCol>
                  <IonCol className={classes.p0} size="12">
                    <DocumentImage
                      reportId={reportId || 0}
                      disabled={disabled || isLoading}
                      imagesForUpload={imagesForUpload}
                      setImagesForUpload={(images: ReportImage[]) => {
                        const newImgForUpload = images[images.length - 1];
                        setCoverImageForUpload(newImgForUpload);
                        // if we are uploading an image, and we have a cover image,
                        // check if the incoming imageId is the same as the coverImage
                        // if so, it is an edit, and we should just remove the
                        // coverImage from state. If the ID is different, they have
                        // taken a new image, and we should remove the old since
                        // there is only 1 coverImage allowed per report
                        const prevImg = coverImage || coverImageForUpload;
                        if (prevImg) {
                          setCoverImage(undefined);
                          if (newImgForUpload?.imageId !== prevImg?.imageId) {
                            setCoverImageForRemoval(prevImg);
                          }
                        }
                      }}
                      imagesForRemoval={imagesForRemoval}
                      setImagesForRemoval={(images: ReportImage[]) => {
                        setCoverImageForRemoval(images[images.length - 1]);
                      }}
                      groupId={0} // cover page is not a group defined in the template
                      imageValues={
                        coverImage &&
                        !coverImageForUpload &&
                        !coverImageForRemoval
                          ? [coverImage]
                          : []
                      }
                      setHasImageData={() => {}}
                      isPrimary
                      captionLength={isGearboxDocumentType ? 40 : undefined}
                      captionShowCharCounter={isGearboxDocumentType}
                    />
                  </IonCol>
                </>
              )}
            </IonRow>
          )}
        </div>
      )}
    </div>
  );
};

export default DocumentCoverPage;
