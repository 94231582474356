import React, { useEffect, useState } from 'react';
import { map, toNumber } from 'lodash';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import useFindNotebookTemplates from 'ProductSearchApp/api/fastfind/useFindNotebookTemplate';
import type { SelectModalItem } from 'models/Search';
import SelectModal from 'components/SelectModal/SelectModal';

interface NotebookTemplateSelectProps {
  selItem?: SelectModalItem;
  label?: string;
  openModal?: boolean;
  setOpenModal?: (b: boolean) => void;
  setNoteBookTemplateCode: (o: SelectModalItem) => void;
  triggerInput?: number;
  miLoc: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  isReadOnly?: boolean;
}

const NotebookTemplateSelect = ({
  selItem,
  label = '',
  openModal = false,
  setOpenModal,
  triggerInput,
  setNoteBookTemplateCode,
  miLoc,
  placeholder,
  required,
  disabled,
  isReadOnly,
}: NotebookTemplateSelectProps & IonicReactProps): JSX.Element => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const { template, error, isLoading, isEmptyResponse, fetchNextPage } =
    useFindNotebookTemplates({
      query: searchQuery,
      miLoc,
    });

  useEffect(() => {
    if (!isOpen && !openModal) {
      setSearchQuery('');
    }
  }, [isOpen, openModal, searchQuery, setSearchQuery]);

  const inputDisplay = selItem?.key ? `(${selItem.key}) ${selItem.title}` : '';
  const templateItems = map(template, (item) => ({
    key: item.codeValue,
    title: item.codeValue,
    description: item.codeDesc,
  }));

  useEffect(() => {
    if (toNumber(triggerInput) > 0) {
      setIsOpen(true);
    }
  }, [triggerInput]);

  return (
    <SelectModal
      isOpen={setOpenModal ? openModal : isOpen}
      setIsOpen={setOpenModal || setIsOpen}
      type="notebookTemplate"
      subType=""
      selItem={selItem || { title: '', description: '', key: '' }}
      setSelItem={(o) => setNoteBookTemplateCode(o)}
      testid="select--notebook--template-modal"
      isEmptyResponse={!!isEmptyResponse}
      showLoader={!!isLoading}
      fetchNextPage={fetchNextPage}
      error={error}
      enableInfiniteScroll
      items={templateItems}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      onClick={() => setIsOpen(true)}
      label={label}
      value={inputDisplay}
      icon="caret-down"
      placeholder={placeholder}
      required={required}
      disabled={disabled}
      isReadOnly={isReadOnly}
    />
  );
};

export default NotebookTemplateSelect;
