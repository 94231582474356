import type { AxiosError } from 'axios';
import { isNil } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { doPromiseAPI, useKeyUserId, useMiLocOrTeamId } from 'api/helpers';
import type { QueryFlags } from 'models/Search';
import type { DCApprovedVendor, Supplier } from 'models/Supplier';
import useGetSupplier from './useGetSupplier';

export const getDCApprovedVendorsQueryKey = 'supplier-dc-approved-vendors';

interface UseGetDCApprovedVendorsProps {
  id: string;
}

interface UseGetDCApprovedVendorsResponse {
  dcApprovedVendors?: DCApprovedVendor[];
  supplierData?: Supplier;
}

const useGetDCApprovedVendors = ({
  id,
}: UseGetDCApprovedVendorsProps): UseGetDCApprovedVendorsResponse &
  QueryFlags => {
  const { axios } = useAxios();
  const { getDCApprovedVendorsAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const { createParams } = useMiLocOrTeamId({ sendTeamId: false });
  const params = { ...createParams() };
  const { miLoc } = params;

  const { data: supplierData } = useGetSupplier({ searchType: 'supplier', id });

  const doGetDCApprovedVendors = () => {
    return doPromiseAPI<DCApprovedVendor[]>(async (cancelToken) => {
      const { data } = await axios.get<DCApprovedVendor[]>(
        getDCApprovedVendorsAPI(miLoc, id),
        { cancelToken }
      );
      return data;
    });
  };

  const {
    data: dcApprovedVendors,
    error,
    status,
    isFetching,
  } = useQuery<DCApprovedVendor[], AxiosError>(
    createQueryKey(getDCApprovedVendorsQueryKey, { miLoc, id }),
    doGetDCApprovedVendors
  );

  return {
    error,
    dcApprovedVendors,
    supplierData,
    isLoading: status === 'loading' || (isFetching && isNil(dcApprovedVendors)),
  };
};

export default useGetDCApprovedVendors;
