import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import {
  concat,
  get,
  isEmpty,
  isNil,
  map,
  toLower,
  toNumber,
  toString,
} from 'lodash';
import type { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonCol, IonImg, IonRow } from '@ionic/react';
import { useFormik } from 'formik';
import { namespaces } from 'i18n/i18n.constants';
import useGetProductDetail from 'api/customer/useGetProductDetailV2';
import useUpdateSalesPlay from 'api/salesPlays/useUpdateSalesPlay';
import type {
  ActionCardActivity,
  UpdateActivityBody,
} from 'models/ActivityModels';
import type { Customer } from 'models/Customer';
import type {
  SalesPlayDescription,
  SalesPlaysJsonSummary,
  SalesPlayTypesKeys,
} from 'models/SalesPlays';
import { SalesPlaysStatus } from 'models/SalesPlays';
import type { RootState } from 'store/reducers';
import { findIcon } from 'utils/icons';
import {
  getDescription,
  getFormattedValue,
  iconAndColorFromType,
} from 'utils/salesPlays';
import noImage from 'assets/noImage.png';
import Button from 'components/Button/Button';
import IndustryCodes from 'components/IndustryCodes/IndustryCodes';
import Loader from 'components/Loader/Loader';
import SalesPlayDetailHeadline from 'components/SalesPlayDetailHeadline/SalesPlayDetailHeadline';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './SalesPlayForm.module.scss';

interface SalesPlayStatusTextProps {
  extendedInfo?: Record<string, unknown>;
}
const SalesPlayStatusText = ({
  extendedInfo,
}: SalesPlayStatusTextProps): JSX.Element | null => {
  const { t } = useTranslation(namespaces.salesPlays);
  if (isNil(extendedInfo)) {
    return null;
  }

  const { active, status, noOppReason } = extendedInfo;

  if (
    (active !== 'Y' && status !== SalesPlaysStatus.ARCHIVED) ||
    status === SalesPlaysStatus.ARCHIVED
  ) {
    const text =
      noOppReason === 'NOTACTIV' ? 'archiveNotActive' : 'archiveExpired';
    return (
      <IonRow
        className={classnames(classes.notActiveRow, classes.rowSeparator)}
      >
        <FontAwesomeIcon
          icon={findIcon('exclamation-circle', 'fas')}
          className={classnames(classes.notActive)}
        />
        <Text
          className={classnames(classes.notActiveText)}
          variant="content-small-heavy"
          text={t(text)}
          testid="sales-play-status-text"
        />
      </IonRow>
    );
  }
  return null;
};

interface SalesPlayFormProps {
  historyId?: number;
  userId: string;
  activity: ActionCardActivity;
  isLoading?: boolean;
  updateActivityData?: UpdateActivityBody;
  customerData?: Customer;
  didChange?: boolean;
  footerRef?: React.RefObject<HTMLDivElement>;
  onDone?: () => void;
  onContentChange?: () => void;
  triggerResize?: (d: number) => void;
  onCompleteActivityFlag?: (b: boolean) => void;
  onFollowUpActivityFlag?: (b: boolean) => void;
  onUnSnooze?: () => void;
  disableActivityFlags?: (b: boolean) => void;
}

const SalesPlayForm = ({
  historyId,
  userId,
  activity,
  isLoading: propsIsLoading,
  updateActivityData,
  customerData,
  didChange,
  footerRef,
  onDone,
  onContentChange,
  triggerResize,
  onCompleteActivityFlag,
  onFollowUpActivityFlag,
  disableActivityFlags,
  onUnSnooze,
}: SalesPlayFormProps): JSX.Element => {
  const { t } = useTranslation(namespaces.activities);
  const { userInfo } = useSelector((state: RootState) => state.user);
  const currentUserId = get(userInfo, 'userid', '');
  const miLoc = toString(activity?.custMiLoc);
  const custNo = toString(activity?.custNo);
  const [img, setImage] = useState<string>(noImage);
  const { extendedInfo } = activity || {};
  const type: SalesPlayTypesKeys = toString(
    extendedInfo?.type
  ) as unknown as SalesPlayTypesKeys;
  const badSalesPlayData =
    isEmpty(extendedInfo) || isEmpty(toString(extendedInfo?.status));
  const status: SalesPlaysStatus = (toString(extendedInfo?.status) ||
    'ACCEPTED') as unknown as SalesPlaysStatus;
  const itemNo = toString(extendedInfo?.itemNo);
  const itemDescription = toString(extendedInfo?.itemDescription);
  const manufacturerName = toString(extendedInfo?.manufacturerName);
  const active = toString(extendedInfo?.active) === 'Y';
  const externalId = toString(extendedInfo?.externalId);
  const externalSource = toString(extendedInfo?.externalSource);
  const noOppReason = toString(extendedInfo?.noOppReason);
  const [iconName, setIconName] = useState<IconName>();
  const [iconColor, setIconColor] = useState<string | undefined>();
  const mfgPartNo = toString(extendedInfo?.mfgPartNo);
  const materialType = toString(extendedInfo?.materialType) as
    | 'Sku'
    | 'PGC4'
    | undefined;
  const materialNo = toString(extendedInfo?.materialNo);
  const jsonSummary = extendedInfo?.jsonSummary as SalesPlaysJsonSummary[];
  const productGroupDescription = toString(
    extendedInfo?.productGroupDescription
  );

  const salesPlayDesc: SalesPlayDescription = {
    type,
    materialType,
    manufacturerName,
    productGroupDescription,
    materialNo,
    mfgPartNo,
    jsonSummary,
  };

  const { productData, isLoading: isLoadingProductData } = useGetProductDetail({
    miLoc,
    id: custNo,
    productId: itemNo,
  });

  const image = productData?.imageURL;

  useEffect(() => {
    if (image) {
      setImage(
        `${toString(process.env.REACT_APP_IMAGES_URL)}${toString(image)}`
      );
    }
  }, [image]);

  useEffect(() => {
    if (!isNil(type)) {
      const legendType = iconAndColorFromType(type);
      setIconName(legendType.icon);
      setIconColor(legendType.color);
    }
  }, [type, t]);

  useEffect(() => {
    triggerResize?.(Date.now());
  }, [propsIsLoading, isLoadingProductData, triggerResize]);

  const showButton = status === SalesPlaysStatus.ACCEPTED;
  const [salesPlayStatus, setSalesPlayStatus] =
    useState<SalesPlaysStatus>(status);

  const handleButtonClick = (updatedStatus: SalesPlaysStatus) => {
    setSalesPlayStatus(updatedStatus);
    onContentChange?.call(null);
    onCompleteActivityFlag?.(true);
    onFollowUpActivityFlag?.(false);
    disableActivityFlags?.(true);
    onUnSnooze?.();
    triggerResize?.(Date.now());
  };

  const { onUpdateSalesPlay } = useUpdateSalesPlay({
    externalSource,
    externalId,
  });

  const { handleSubmit, isSubmitting } = useFormik({
    initialValues: {
      status,
      assignedUserId: userId,
      noOppReason: '',
      historyId,
      eventParams: {
        showAfter: updateActivityData?.showAfter,
        done: updateActivityData?.done ? 'Y' : 'N',
        followUp: updateActivityData?.followUp ? 'Y' : 'N',
      },
    },
    onSubmit: () => {
      const { followUp, done, showAfter } = activity;
      onUpdateSalesPlay({
        status: salesPlayStatus,
        assignedUserId: currentUserId,
        noOppReason: '',
        eventParams: {
          showAfter: updateActivityData?.showAfter,
          done: updateActivityData?.done ? 'Y' : 'N',
          followUp: updateActivityData?.followUp ? 'Y' : 'N',
        },
        historyId,
        successToastText: t('updateSalesPlayToast'),
        undoData: () => ({
          status,
          assignedUserId: userId,
          noOppReason,
          eventParams: {
            followUp,
            done,
            showAfter,
          },
          historyId,
        }),
      });
      onDone?.();
    },
  });

  return (
    <div className={classes.salesPlayForm}>
      {isLoadingProductData && (
        <Loader
          testid="loader"
          text={t('common:loading')}
          className={classes.loader}
          isOpen={isLoadingProductData}
        />
      )}
      {!isLoadingProductData && badSalesPlayData && (
        <WarningMessage title={t('activities:badSalesPlayData')} />
      )}
      {!isLoadingProductData && !badSalesPlayData && (
        <div className={classes.card}>
          <div className={classes.cardContent}>
            <SalesPlayDetailHeadline
              title=""
              testid="sales-play-detail-title"
              iconColor={iconColor}
              iconName={iconName}
              headerType={toString(type)}
            />
            <div className={classnames(classes.blockHeading, classes.details)}>
              <IonRow className={classes.rowSeparator}>
                {type !== 'UpSell' ? (
                  <Text
                    text={getDescription(t, salesPlayDesc)}
                    variant="content-small"
                    testid={`${toLower(type)}-content`}
                  />
                ) : (
                  <div className={classes.cardContentTop}>
                    <Text
                      text={getDescription(t, salesPlayDesc)}
                      variant="content-small"
                      testid="upsell-content"
                    />
                  </div>
                )}
              </IonRow>
            </div>
            <SalesPlayStatusText extendedInfo={extendedInfo} />
            {!isEmpty(itemNo) && (
              <>
                <IonRow className={classes.rowSeparator}>
                  <Button
                    text={toString(extendedInfo?.mfgPartNo)}
                    // TODO Fix this with full page implementation with route
                    variant="clear"
                    // rightIcon="chevron-right"
                    className={classes.productName}
                    testid="part-number"
                  />
                </IonRow>
                <IonRow className={classes.rowSeparator}>
                  <Text
                    text={toString(manufacturerName)}
                    variant="content-heavy"
                    testid="manufacturer-name"
                  />
                </IonRow>
                <IonRow className={classes.rowSeparator}>
                  <Text
                    text={itemDescription || 'Recommended Product'}
                    variant="content-small"
                    testid="item-description-link"
                  />
                </IonRow>
                <IonRow
                  className={classnames(classes.imgRow, classes.rowSeparator)}
                >
                  <Button testid="item-thumbnail-link">
                    <IonImg
                      src={img}
                      className={classes.img}
                      onIonError={() => {
                        setImage(noImage);
                      }}
                    />
                  </Button>
                </IonRow>
              </>
            )}
            {showButton && (
              <div className={classes.wrapper}>
                {active && (
                  <IonRow
                    key="show-action-button"
                    className={classes.showButton}
                  >
                    <Button
                      key="won-button"
                      className={classnames(classes.won, {
                        [classes.active]:
                          salesPlayStatus === SalesPlaysStatus.WON,
                      })}
                      text={t('common:won')}
                      variant="secondary"
                      onClick={() => {
                        handleButtonClick(SalesPlaysStatus.WON);
                      }}
                      testid="won-button"
                    />
                    <Button
                      key="lost-button"
                      className={classnames(classes.lost, {
                        [classes.active]:
                          salesPlayStatus === SalesPlaysStatus.LOST,
                      })}
                      text={t('common:lost')}
                      variant="secondary"
                      onClick={() => {
                        handleButtonClick(SalesPlaysStatus.LOST);
                      }}
                      testid="lost-button"
                    />
                  </IonRow>
                )}
                {!active && (
                  <IonRow key="show-discard-button">
                    <Button
                      key="discard-button"
                      className={classnames(classes.discard, {
                        [classes.active]:
                          salesPlayStatus === SalesPlaysStatus.ARCHIVED,
                      })}
                      text={t('common:archive')}
                      variant="secondary"
                      onClick={() => {
                        handleButtonClick(SalesPlaysStatus.ARCHIVED);
                      }}
                      testid="discard-button"
                    />
                  </IonRow>
                )}
              </div>
            )}
            <div className={classes.section}>
              {!!extendedInfo?.jsonSummary && (
                <>
                  <Text
                    text={t('common:details')}
                    variant="label-header"
                    className={classnames(
                      classes.blockHeading,
                      classes.rowSeparator
                    )}
                    testid="sales-play-data-heading"
                  />
                  <div className={classes.salesPlayData}>
                    {map(
                      extendedInfo?.jsonSummary as SalesPlaysJsonSummary[],
                      ({ _format, label, value }, key) => (
                        <IonRow key={`label-${key}`}>
                          <IonCol size="7">
                            <Text
                              text={t(concat('salesPlays:', label))}
                              variant="content-small"
                              testid={`label-${key}`}
                            />
                          </IonCol>
                          <IonCol size="5">
                            {getFormattedValue(
                              _format,
                              value,
                              toNumber(value) * 100 >= 0
                                ? classes.positiveText
                                : classes.negativeText,
                              toString(extendedInfo?.currencyType)
                            )}
                          </IonCol>
                        </IonRow>
                      )
                    )}
                  </div>
                </>
              )}
              <IndustryCodes
                className={classes.industryCodes}
                data={customerData}
              />
            </div>
          </div>
        </div>
      )}
      {footerRef?.current &&
        createPortal(
          <Button
            className={classes.button}
            variant="action"
            text="Save"
            onClick={() => handleSubmit()}
            disabled={!didChange || isSubmitting}
            testid="save-button"
          />,
          footerRef?.current
        )}
    </div>
  );
};

export default SalesPlayForm;
