import React from 'react';
import type { TFunction } from 'react-i18next';
import classNames from 'classnames';
import type { Dictionary } from 'lodash';
import { toNumber, toString } from 'lodash';
import CrmTaskForm from 'ActivitiesApp/components/CrmTaskForm/CrmTaskForm';
import {
  CrmActivityIconEnum,
  CrmActivityColorEnum,
  CrmActivityFilterEnum,
  CrmActivityTypeEnum,
} from 'ActivitiesApp/models/CrmActivity';
import {
  BLANK_DUE_DATE,
  crmHasCancelStatus,
} from 'ActivitiesApp/utils/helpers';
import { ifRender, or } from 'common/utils/logicHelpers';
import { formatNumber } from 'common/utils/numberHelper';
import type { ActivityCardLines } from 'models/ActivityModels';
import { formatCardDate } from 'utils/date';
import type {
  ActionCardConfig,
  ActivityTypeConfig,
  GetActivityConfigExtendedProps,
  GetActivityTypeDataProps,
} from 'pages/Activities/ActivityActionCard/ActivityCardConfig';
import classes from 'components/Activities/ActionCard/ActionCard.module.scss';
import Badge from 'components/Badge/Badge';

export const getCrmActivityData = ({
  eventTagName,
  t,
}: GetActivityTypeDataProps): ActivityTypeConfig | undefined => {
  switch (eventTagName) {
    case CrmActivityTypeEnum.crmTask:
      return {
        cardType: CrmActivityTypeEnum.crmTask,
        title: t('corpTask'),
        icon: CrmActivityIconEnum.crmTask,
        color: CrmActivityColorEnum.crmTask,
      };
    case CrmActivityTypeEnum.crmOpportunity:
      return {
        cardType: CrmActivityTypeEnum.crmOpportunity,
        title: t('corpOpportunity'),
        icon: CrmActivityIconEnum.crmOpportunity,
        color: CrmActivityColorEnum.crmOpportunity,
      };
    default:
      return undefined;
  }
};

const getPriorityPrefix = ({
  priority,
  dueDateStr = BLANK_DUE_DATE,
  estimatedValue,
  currency,
  t,
}: {
  priority: number;
  dueDateStr: string;
  estimatedValue?: string;
  currency?: string;
  t: TFunction;
}) => {
  const priorityBadge =
    priority === 2 ? (
      <Badge
        type="error"
        icon="flag"
        text={t('activities:high')}
        testid="on-hold"
        className={classes.badge}
        textVariant="content-smaller"
        textClassName={classNames(classes.linePrefixText, classes.priority)}
        iconVariant="far"
        iconClassName={classNames(classes.prefixIcon, classes.priority)}
      />
    ) : undefined;

  let priorityLine: ActivityCardLines[] = [];
  let estVal = estimatedValue;
  estVal ||= '0';
  const estValue = formatNumber({
    number: toNumber(estVal),
    currencyType: currency,
  });
  const isNonBlankDueDate = dueDateStr !== BLANK_DUE_DATE;

  if (isNonBlankDueDate || priorityBadge || estimatedValue) {
    const dueDate = formatCardDate(dueDateStr, false, false);
    priorityLine = [
      {
        description: isNonBlankDueDate
          ? t('crmActivityDue', {
              dueDate,
            })
          : '',
        highlight: t('activities:crmActivityDue'),
        followUpDescription: estimatedValue
          ? t('activities:crmActivityEst', { estValue })
          : '',
        followUpHighlight: t('activities:crmActivityEst'),
        badge: priorityBadge,
        className: classes.priority,
      },
    ];
  }
  return priorityLine;
};

export const getCrmActivityConfig = ({
  activityVersion,
  activityData,
  defaultFirstLine,
  t,
}: GetActivityConfigExtendedProps): ActionCardConfig | undefined => {
  const { extendedInfo, commentCount } = activityData;

  const title = toString(extendedInfo?.subject);
  const dueDateStr = toString(extendedInfo?.dueDate);
  const estimatedValue = toString(extendedInfo?.estimatedValue);
  const priority = extendedInfo?.priority as number;
  const currency = extendedInfo?.currency as string;
  let commentLines: ActivityCardLines[] = [];
  const hasCommentCounts = toNumber(commentCount) > 0;
  const hasCancelStatus = crmHasCancelStatus(activityData);

  if (or(hasCommentCounts, hasCancelStatus)) {
    commentLines = [
      {
        description: '',
        badge: (
          <div className={classes.badgeRow}>
            {ifRender(
              hasCommentCounts,
              <Badge
                type="info"
                icon="comment"
                text={t('comments', {
                  notesCount: commentCount,
                  count: toNumber(commentCount),
                })}
                testid="on-hold"
                className={classes.badge}
                textVariant="content-smaller"
                textClassName={classNames(
                  classes.linePrefixText,
                  classes.commentText
                )}
                iconVariant="far"
                iconClassName={classNames(classes.prefixIcon, classes.comment)}
              />
            )}
            {ifRender(
              hasCancelStatus,
              <Badge
                type="info"
                testid="crm-cancel-status"
                text={t('cancelled')}
                className={classes.cancelBadge}
              />
            )}
          </div>
        ),
      },
    ];
  }

  switch (activityVersion) {
    case `${CrmActivityTypeEnum.crmTask}-1`:
      return {
        card: {
          defaultTitle: t('corpTask'),
          highlight: [{ query: t('corpTask') }],
          body: {
            description: '',
            lines: [
              ...defaultFirstLine,
              {
                description: title,
              },
              ...getPriorityPrefix({
                priority,
                dueDateStr,
                t,
              }),
              ...commentLines,
            ],
          },
        },
        hasComments: true,
        modal: {
          title: t('activities:corporateAccountTask'),
          hideSaveButton: true,
          customContent: (props: Dictionary<unknown>) => (
            <CrmTaskForm
              activity={activityData}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
            />
          ),
        },
      };
    case `${CrmActivityTypeEnum.crmOpportunity}-1`:
      return {
        card: {
          defaultTitle: t('corpOpportunity'),
          highlight: [{ query: t('corpOpportunity') }],
          body: {
            description: '',
            lines: [
              ...defaultFirstLine,
              {
                description: title,
              },
              ...getPriorityPrefix({
                priority,
                dueDateStr,
                estimatedValue,
                currency,
                t,
              }),
              ...commentLines,
            ],
          },
        },
        hasComments: true,
        modal: {
          title: t('activities:corporateAccountOpportunity'),
          hideSaveButton: true,
          customContent: (props: Dictionary<unknown>) => (
            <CrmTaskForm
              activity={activityData}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
            />
          ),
        },
      };
    default:
      return undefined;
  }
};

export const getCrmActivityFilterIcon = (
  type: string
): CrmActivityIconEnum | undefined => {
  switch (type) {
    case CrmActivityFilterEnum.crmTask:
      return CrmActivityIconEnum.crmTask;
    case CrmActivityFilterEnum.crmOpportunity:
      return CrmActivityIconEnum.crmOpportunity;
    default:
      return undefined;
  }
};
