import CENRAL_TRANSPORT_ICON from 'assets/centralTransport.svg';
import FEDEX_ICON from 'assets/fedEx.svg';
import MOTION_ICON from 'assets/miCarrier.svg';
import OLD_DOMINION_ICON from 'assets/oldDominion.svg';
import RL_ICON from 'assets/RL.svg';
import UPS_ICON from 'assets/UPS.svg';

export enum CarrierTrackingURLPrefix {
  UPS = 'http://wwwapps.ups.com/WebTracking/processInputRequest?tracknum=',
  FEDEX = 'https://www.fedex.com/fedextrack/?trknbr=',
  OLD_DOM = 'http://www.odfl.com/trace/Trace.jsp?pronum=',
  HOLLAND = 'http://apps.yrcregional.com/shipmentStatus/track.do?trackType=H&proNumber=',
  R_L = 'https://www.rlcarriers.com/freight/shipping/shipment-tracing?docType=PRO&source=web&pro=',
  PUROLATOR = 'http://www.purolator.com/purolator/ship-track/tracking-details.page?pin=',
  CENTRAL_TRANSPORT = 'http://www.centraltransportint.com/confirm/trace.aspx?pronum=',
  CENTRAL_FREIGHT = 'http://www.centralfreight.com/website/mf/mfInquiry.aspx?inqmode=PRO&pro=',
}

export const Carrier = {
  UPS: { prefixUrl: CarrierTrackingURLPrefix.UPS, icon: UPS_ICON },
  FEDEX: {
    prefixUrl: CarrierTrackingURLPrefix.FEDEX,
    icon: FEDEX_ICON,
  },
  OLD_DOMINION: {
    prefixUrl: CarrierTrackingURLPrefix.OLD_DOM,
    icon: OLD_DOMINION_ICON,
  },
  RL: { prefixUrl: CarrierTrackingURLPrefix.R_L, icon: RL_ICON },
  HOLLAND: { prefixUrl: CarrierTrackingURLPrefix.HOLLAND },
  PUROLATOR: {
    prefixUrl: CarrierTrackingURLPrefix.PUROLATOR,
  },
  CENTRAL_TRANSPORT: {
    prefixUrl: CarrierTrackingURLPrefix.CENTRAL_TRANSPORT,
    icon: CENRAL_TRANSPORT_ICON,
  },
  CENTRAL_FREIGHT: {
    prefixUrl: CarrierTrackingURLPrefix.CENTRAL_FREIGHT,
  },
  MOTION: { icon: MOTION_ICON },
  NONE: { prefixUrl: null },
};

export const MOTION_CARRIER_LABEL = 'Motion';
export const UPS_CARRIER_LABEL = 'UPS';
