import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { lowerCase, replace, startCase, trim } from 'lodash';
import { IonLabel } from '@ionic/react';
import { formatNumber } from 'common/utils/numberHelper';
import { DateFormatEnum, formatDate } from 'utils/date';
import ActionRow from 'components/ActionRow/ActionRow';
import Text from 'components/Text/Text';
import classes from './CostSavingInProgressItem.module.scss';

interface CostSavingsInProgressItemsProps {
  className?: string;
  vasExtPrice: number;
  createdTime: string;
  custVasDesc: string;
  creationUserName: string;
  status: string;
  href?: string;
  withoutHrefArrow?: boolean;
}

const CostSavingsInProgressItem = ({
  className,
  vasExtPrice,
  createdTime,
  custVasDesc,
  creationUserName,
  status,
  href,
  withoutHrefArrow = true,
}: CostSavingsInProgressItemsProps): JSX.Element => {
  const { t } = useTranslation();
  const description = t('costSavings:csInformationStatusDesc', {
    vasCode: startCase(lowerCase(custVasDesc)),
    createdDate: formatDate(
      new Date(replace(createdTime, '[UTC]', '')),
      DateFormatEnum.fullDate
    ),
    employeeName: creationUserName,
  });

  return (
    <ActionRow
      testid="cs-draft-information"
      href={href}
      withoutHrefArrow={withoutHrefArrow}
      className={classNames(classes.csInProgressItem, className)}
    >
      <IonLabel slot="start" className={classes.label}>
        <h3>
          <Text
            text={formatNumber({
              number: vasExtPrice,
              currencyType: 'USD',
              scale: 2,
            })}
            variant="mipro-body-copy"
          />

          <Text
            text={status}
            variant="mipro-body-copy"
            className={classes.text}
          />
        </h3>
        <p>{trim(description)}</p>
      </IonLabel>
    </ActionRow>
  );
};

export default CostSavingsInProgressItem;
