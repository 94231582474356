import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Virtuoso } from 'react-virtuoso';
import { forEach, includes, isEmpty, map, toNumber, toString } from 'lodash';
import {
  IonPage,
  IonContent,
  IonRow,
  IonLoading,
  IonFooter,
} from '@ionic/react';
import { startOfToday, subMonths, subYears } from 'date-fns';
import useFindReports from 'DocumentsApp/api/useFindReports';
import useFindTemplates from 'DocumentsApp/api/useFindTemplates';
import DocumentCard from 'DocumentsApp/components/DocumentCard/DocumentCard';
import useDocumentSync from 'DocumentsApp/hooks/useDocumentSync';
import {
  createDocumentURL,
  documentDetailsURL,
  documentsURL,
} from 'navigation';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import { useDebounce } from 'use-debounce';
import useAccessControls, { AccessControlType } from 'hooks/useAccessControls';
import type { FilterSection } from 'models/Filters';
import { FilterSectionEnum } from 'models/Filters';
import type { InspectionReport } from 'models/InspectionReport';
import { selectIsBranchLocation } from 'store/user/selectors';
import { DateFormatEnum, formatDate } from 'utils/date';
import { getErrorMessage } from 'utils/helpers';
import { findIcon } from 'utils/icons';
import { concatRoutes } from 'utils/navigations';
import ActionRow from 'components/ActionRow/ActionRow';
import Button from 'components/Button/Button';
import DocumentEmailModal from 'components/Documents/DocumentEmailModal/DocumentEmailModal';
import Header from 'components/Header/Header';
import Loader from 'components/Loader/Loader';
import FiltersModal from 'components/Modals/FiltersModal/FiltersModal';
import Refresher from 'components/Refresher/Refresher';
import Searchbar from 'components/Searchbar/Searchbar';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './DocumentList.module.scss';

const rangeFilterOptions = [
  { key: 'lastTwelve', text: 'Last 12 Months' },
  { key: 'lastSix', text: 'Last 6 Months' },
  { key: 'lastThree', text: 'Last 3 Months' },
  { key: 'lastOne', text: 'Last 1 Month' },
];
const statusFilterOptions = [
  { key: 'IP', text: 'In Progress' },
  { key: 'CL', text: 'Complete' },
];

type DateRangeFilterProps =
  | ''
  | 'lastOne'
  | 'lastThree'
  | 'lastSix'
  | 'lastTwelve';

const dateRangeFilter = {
  lastSix: 6,
  lastThree: 3,
  lastOne: 1,
  '': 0,
};

const DocumentList = (): JSX.Element => {
  const today = startOfToday();
  const { isOnline } = useNetworkStatus();
  const isBranch = useSelector(selectIsBranchLocation);
  const documentEdit = useAccessControls(AccessControlType.editDocuments);

  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryValue] = useDebounce(searchQuery, 300);
  const [dateRange, setDateRange] = useState<DateRangeFilterProps>('');
  const [status, setStatus] = useState('');
  const [templateTypeId, setType] = useState('');
  const [uploadPressed, setUploadPressed] = useState(false);
  const [sendEmailModal, setSendEmailModal] = useState(false);
  const [selectedReport, setSelectedReport] = useState<InspectionReport>();

  let startDate = '';
  let endDate = '';
  if (dateRange === 'lastTwelve') {
    startDate = formatDate(
      subYears(today, 1),
      DateFormatEnum.documentsDateRangeAPI
    );
    endDate = formatDate(today, DateFormatEnum.documentsDateRangeAPI);
  } else if (includes(['lastSix', 'lastThree', 'lastOne'], dateRange)) {
    startDate = formatDate(
      subMonths(today, dateRangeFilter[dateRange]),
      DateFormatEnum.documentsDateRangeAPI
    );
    endDate = formatDate(today, DateFormatEnum.documentsDateRangeAPI);
  }

  const { templates } = useFindTemplates({ enabled: isBranch });

  const templateTypes = map(templates, ({ templateId, templateName }) => ({
    key: toString(templateId),
    text: templateName,
  }));

  const {
    reports,
    error,
    refetch,
    fetchNextPage,
    showLoader,
    isEmptyResponse,
    enableInfiniteScroll,
    reportsToSync,
    loadingAPI: uploadLoading,
    sync: onUploadReports,
  } = useFindReports({
    enabled: isBranch,
    searchTerm: searchQueryValue,
    reportStatus: status,
    templateTypeId,
    startDate,
    endDate,
  });

  const filters = [
    {
      key: 'dateRange',
      label: 'Date',
      type: FilterSectionEnum.radiogroup,
      selectedValue: dateRange as string,
      options: rangeFilterOptions,
    },
  ];
  if (isOnline) {
    filters.push({
      key: 'status',
      label: 'Status',
      type: FilterSectionEnum.radiogroup,
      selectedValue: status,
      options: statusFilterOptions,
    });
  }
  filters.push({
    key: 'templateTypeId',
    label: 'Type',
    type: FilterSectionEnum.radiogroup,
    selectedValue: templateTypeId,
    options: templateTypes,
  });

  const onDone = (iFilters: FilterSection[]) => {
    forEach(iFilters, ({ key, selectedValue = '' }) => {
      if (key === 'status') {
        setStatus(selectedValue);
      }
      if (key === 'dateRange') {
        setDateRange(selectedValue as DateRangeFilterProps);
      }
      if (key === 'templateTypeId') {
        setType(selectedValue);
      }
    });
  };

  const { downloadButton, offlineBanner, loader } = useDocumentSync();

  const uploadIsLoading = uploadLoading || uploadPressed;

  useEffect(() => {
    if (!uploadLoading) {
      setUploadPressed(false);
    }
  }, [uploadLoading]);

  return (
    <IonPage className={classes.documentList}>
      <Header
        title="DOCUMENTS"
        testid="DocumentsHeader"
        showLocationText
        toolbarChildren={downloadButton}
      >
        <IonRow className={classes.documentSearchRow}>
          <Searchbar
            className={classes.searchbar}
            placeholder="Search Documents..."
            value={searchQuery}
            setValue={setSearchQuery}
            testid="search-input"
          />
          <FiltersModal
            className={classes.filters}
            title="Filter Documents"
            filters={filters}
            onDone={onDone}
          />
        </IonRow>
        <IonLoading
          isOpen={!!uploadIsLoading}
          message="Sync data in progress. This could take a couple minutes."
        />
        {loader}
        {offlineBanner}
      </Header>
      <IonContent>
        <Refresher
          slot="fixed"
          hidden
          disabled={!isBranch}
          onRefresh={refetch}
          testid="documents-list-refresher"
        />
        {documentEdit && (
          <div className={classes.filterWrapper}>
            <ActionRow
              className={classes.addDocumentRow}
              text="Create Document"
              icon={findIcon('file-plus')}
              href={concatRoutes(documentsURL(), createDocumentURL())}
              testid="add-document-button"
              disabled={!isBranch}
            />
          </div>
        )}
        {/* TODO: remove showLoader and instead do optimisticUI updates on list */}
        {!error && !isEmpty(reports) && (
          <Virtuoso
            data={reports}
            increaseViewportBy={{ top: 500, bottom: 500 }}
            endReached={enableInfiniteScroll ? fetchNextPage : undefined}
            itemContent={(index, report) => (
              <DocumentCard
                key={index}
                document={report}
                searchQuery={searchQueryValue}
                disabled={report.isOptimisticallyUpdating}
                href={concatRoutes(
                  documentsURL(),
                  documentDetailsURL(toString(report.reportId))
                )}
                id={toString(report?.reportId)}
                onSendEmail={() => {
                  setSelectedReport(report);
                  setSendEmailModal(true);
                }}
              />
            )}
          />
        )}
        <DocumentEmailModal
          isOpen={sendEmailModal}
          reportId={toNumber(selectedReport?.reportId)}
          miLoc={toString(selectedReport?.coverPage.miLoc)}
          shopLoc={toString(selectedReport?.coverPage.shopLoc)}
          setIsOpen={setSendEmailModal}
          title="Send Email"
          testid="send-email-modal"
        />
        {!isBranch && (
          <WarningMessage
            className={classes.warningMessage}
            icon={['far', 'info-circle']}
            title="You must be at a branch level to use this feature."
          />
        )}
        {isEmptyResponse && isBranch && !showLoader && (
          <WarningMessage
            className={classes.warningMessage}
            icon={['far', 'info-circle']}
            title="No Documents available"
            body="Are you expecting data? Go online and download to sync with the server."
          />
        )}
        {error && (
          <WarningMessage
            className={classes.warningMessage}
            title="Error loading documents"
            body={getErrorMessage(error)}
          />
        )}
        <Loader
          className={classes.loaderWrap}
          text="Loading Documents"
          isOpen={showLoader}
        />
      </IonContent>
      {documentEdit && (
        <IonFooter className={classes.footer}>
          <IonRow>
            <Button
              disabled={reportsToSync === 0 || !isOnline || uploadIsLoading}
              testid="upload-documents-button"
              className={classes.uploadButton}
              variant="action"
              onClick={() => {
                setUploadPressed(true);
                setTimeout(() => onUploadReports(true), 0);
              }}
              text={`Upload Documents ${
                reportsToSync > 0 ? `(${reportsToSync})` : ''
              }`}
              leftIcon={findIcon('upload')}
            />
          </IonRow>
        </IonFooter>
      )}
    </IonPage>
  );
};

export default DocumentList;
