import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import type { SearchCustomerItem, SearchItem } from 'models/Search';
import { addRecentlyViewed } from 'store/user';

const useRecentlyViewed = ({
  type,
  id,
  text,
  natlAcctNo,
  customerPick12,
  isCorpAccountorInfo,
  ...item
}: SearchItem): void => {
  const dispatch = useDispatch();
  const { miLoc } = item as SearchCustomerItem;

  useEffect(() => {
    if (!isEmpty(text)) {
      if (type === 'customer') {
        dispatch(
          addRecentlyViewed({
            type,
            miLoc,
            id,
            text,
            customerPick12,
            isCorpAccountorInfo,
          })
        );
      } else {
        dispatch(addRecentlyViewed({ type, id, text }));
      }
    }
  }, [
    customerPick12,
    dispatch,
    id,
    miLoc,
    text,
    type,
    natlAcctNo,
    isCorpAccountorInfo,
  ]);
};

export default useRecentlyViewed;
