import type { MutationStatus } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import { doPromiseAPI } from 'api/helpers';
import { ToastType } from 'models/Toast';

interface DocumentEmail {
  recipients: string[];
  reportId: number;
  miLoc: string;
  shopLoc: string;
  subject: string;
  body: string;
}

interface UseSendReportEmailResponse {
  status: MutationStatus;
  onSendEmail: (documentEmail: DocumentEmail) => void;
}

const useSendReportEmail = (): UseSendReportEmailResponse => {
  const { axios } = useAxios();
  const { sendDocumentEmailAPI } = useAPIUrl();
  const { addToast } = useToasts();

  const doSendReportEmail = ({
    miLoc,
    shopLoc,
    reportId,
    recipients,
    subject,
    body,
  }: DocumentEmail) => {
    return doPromiseAPI(async (cancelToken) => {
      return axios.post(
        sendDocumentEmailAPI(),
        { miLoc, corpLoc: shopLoc, reportId, recipients, subject, body },
        { cancelToken }
      );
    });
  };

  const { mutate, status } = useMutation(doSendReportEmail, {
    onSuccess: () =>
      addToast({
        text: 'Your email was sent!',
        testid: 'email-success-toast',
      }),
    onError: () =>
      addToast({
        type: ToastType.error,
        text: 'Send Email operation failed. Please try again later.',
        testid: 'send-email-error-toast',
      }),
  });

  return {
    status,
    onSendEmail: (documentEmail: DocumentEmail) => mutate(documentEmail),
  };
};

export default useSendReportEmail;
