import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import useChangeLocation from 'hooks/useChangeLocation';
import type { RootState } from 'store/reducers';
import Button from 'components/Button/Button';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './ChangeLocation.module.scss';

interface ChangeLocationProps {
  fromInventory?: boolean;
  offlineMsg?: string;
  onlineMsg: string;
  testid: string;
}

const ChangeLocation = ({
  fromInventory = false,
  offlineMsg = '',
  onlineMsg,
  testid,
}: ChangeLocationProps): JSX.Element => {
  const { t } = useTranslation();
  const { isOnline } = useNetworkStatus();
  const { miLoc = '' } = useSelector((state: RootState) => state.user);
  const disabledExecOffline = !isOnline && miLoc === 'EXEC';
  const { isLocatorAvailable, locatorURL } = useChangeLocation();

  return (
    <WarningMessage
      icon={['far', 'info-circle']}
      title={fromInventory && disabledExecOffline ? offlineMsg : onlineMsg}
      className={classes.changeLocationWrapper}
    >
      {(fromInventory || isLocatorAvailable) && (
        <Button
          variant="action"
          text={t('common:changeLocation')}
          testid={`${testid}-change-loc`}
          className={classes.changeLocBtn}
          disabled={fromInventory ? disabledExecOffline : false}
          href={locatorURL}
        />
      )}
    </WarningMessage>
  );
};

export default ChangeLocation;
