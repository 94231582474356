import React from 'react';
import classNames from 'classnames';
import { isEmpty, toString } from 'lodash';
import { IonRow } from '@ionic/react';
import type { Employee } from 'models/Employee';
import ActionRow from 'components/ActionRow/ActionRow';
import Text from 'components/Text/Text';
import classes from './EmployeeListItem.module.scss';

interface EmployeeListItemProps {
  item: Employee;
  selected: boolean;
  onAdd: () => void;
  onRemove: () => void;
}

const EmployeeListItem = ({
  item,
  selected,
  onAdd,
  onRemove,
}: EmployeeListItemProps) => {
  const { name, email, jobPosition, miLoc, id } = item;

  return (
    <ActionRow
      className={classNames(classes.employeeCard, {
        [classes.selected]: selected,
      })}
      leftButton={{
        variant: 'link',
        icon: selected ? ['fas', 'check-circle'] : ['far', 'plus-circle'],
        testid: `contact-${item.id}-add-button`,
      }}
      onClick={() => {
        if (!selected) {
          onAdd();
        } else {
          onRemove();
        }
      }}
      testid={`contact-${id}`}
    >
      <IonRow className={classes.employeeRow}>
        <div>
          {!isEmpty(name) && (
            <Text
              className={classes.employeeListName}
              text={toString(name)}
              variant="mipro-product-headline"
            />
          )}
        </div>
        <Text variant="content-small" text={toString(email)} />
        <Text variant="content-small" text={`${jobPosition} ${miLoc}`} />
      </IonRow>
    </ActionRow>
  );
};

export default EmployeeListItem;
