import type { AxiosError } from 'axios';
import { isEmpty } from 'lodash';
import type { QueryFunctionContext } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import type {
  CostSavings,
  CostSavingsField,
} from 'CostSavingsApp/models/CostSavings';
import { useAxios } from 'providers/AxiosProvider';
import { doGetIsLoading, doPromiseAPI, useKeyUserId } from 'api/helpers';
import type { QueryFlags } from 'models/Search';

interface UseGetCostSavingsDetailsProps {
  miLoc: string;
  vasCtlNo: string;
  enabled?: boolean;
}

interface UseGetCostSavingsDetailsResponse {
  costSavings?: CostSavings;
  templateFields?: CostSavingsField[];
  instructions?: string;
}

const getCostSavingsDetailsQueryKey = 'cost-savings-details';

const useGetCostSavingsDetails = ({
  miLoc,
  vasCtlNo,
  enabled = true,
}: UseGetCostSavingsDetailsProps): UseGetCostSavingsDetailsResponse &
  QueryFlags => {
  const { getCostSavingsDetailsAPI } = useAPIUrl();
  const { axios } = useAxios();
  const { createQueryKey } = useKeyUserId();
  const doGetCostSavingsDetails = async ({ signal }: QueryFunctionContext) => {
    return doPromiseAPI<UseGetCostSavingsDetailsResponse>(async () => {
      const { data } = await axios.get<UseGetCostSavingsDetailsResponse>(
        getCostSavingsDetailsAPI(miLoc, vasCtlNo),
        { signal }
      );
      return data;
    });
  };

  const response = useQuery<UseGetCostSavingsDetailsResponse, AxiosError>(
    createQueryKey(getCostSavingsDetailsQueryKey, { miLoc, vasCtlNo }),
    doGetCostSavingsDetails,
    {
      enabled: enabled && !isEmpty(miLoc) && !isEmpty(vasCtlNo),
    }
  );

  const { data, error, refetch } = response;

  return {
    costSavings: data?.costSavings,
    templateFields: data?.templateFields,
    instructions: data?.instructions,
    error,
    isLoading: doGetIsLoading(response),
    refetch: async () => {
      await refetch();
    },
  };
};

export default useGetCostSavingsDetails;
