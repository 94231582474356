import React from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IonContent,
  IonFooter,
  IonPage,
  IonRow,
  IonToolbar,
} from '@ionic/react';
import { homeURL, feedbackURL } from 'navigation';
import type { RootState } from 'store/reducers';
import { findIcon } from 'utils/icons';
import { concatRoutes } from 'utils/navigations';
import errorWave from 'assets/errorWave.svg';
import Button from 'components/Button/Button';
import classes from './ErrorFallback.module.scss';

interface ErrorFallbackProps {
  userId?: string;
}

export const ErrorFallback = ({ userId }: ErrorFallbackProps): JSX.Element => {
  const onTryAgainButtonClick = () => window.location.reload();

  const onLeaveFeedbackButtonClick = () => {
    window.location.href = concatRoutes(homeURL(), feedbackURL());
  };

  return (
    <IonPage className={classes.ErrorFallback} data-testid="NotAvailable-page">
      <IonContent>
        <div className={classes.title}>UH-OH</div>
        <FontAwesomeIcon className={classes.icon} icon={findIcon('cogs')} />
        <div className={classes.bigCircle} />
        <div className={classes.smallCircle} />
        <div className={classes.errorWave}>
          <img data-testid="error-wave-icon" src={errorWave} alt="Error Wave" />
        </div>
        <div className={classes.text}>
          We had a problem loading <br /> this page.
        </div>
      </IonContent>
      <IonFooter className={classes.footer}>
        <IonToolbar>
          <IonRow>
            <Button
              className={classes.tryAgainButton}
              variant="action"
              text="Try again"
              testid="try-again-button"
              onClick={onTryAgainButtonClick}
            />
          </IonRow>
          {userId && (
            <IonRow>
              <Button
                variant="secondary"
                text="Leave Feedback"
                testid="leave-feedback-button"
                onClick={onLeaveFeedbackButtonClick}
              />
            </IonRow>
          )}
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export const ErrorFallbackWithRedux = (): JSX.Element => {
  const { userInfo } = useSelector((state: RootState) => state.user);
  const userId = get(userInfo, 'userid', '');

  return <ErrorFallback userId={userId} />;
};
