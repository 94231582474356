/* eslint-disable no-console */
import { map, size, trim } from 'lodash';
import type { Storeroom } from 'StoreroomsApp/models/Storeroom';
import useScannerDB from './initScannerDB';

interface FindStoreroomsByMiLocProps {
  miLoc: string;
  query?: string;
  storeroomNumber?: string;
  isPendingIssue?: boolean;
}

interface UseStoreroomDBResponse {
  createStorerooms: (storerooms: Storeroom[]) => Promise<void>;
  findStoreroomsByMiLoc: (
    props: FindStoreroomsByMiLocProps
  ) => Promise<Storeroom[]>;
  removeStorerooms: (miLoc: string) => Promise<void>;
}

const useStoreroomDB = (): UseStoreroomDBResponse => {
  const { db, openDB, closeDB } = useScannerDB();

  const createStorerooms = async (storerooms: Storeroom[]): Promise<void> => {
    try {
      const today = Date.now();
      const statements = map(storerooms, (storeroom) => ({
        statement: `INSERT OR REPLACE INTO storeroom (
          combinedId,
          miLocation,
          storeroomNumber,
          customerNumber,
          storeroomName,
          creationUserID,
          creationProgram,
          creationTimestamp,
          lastUpdateUserID,
          lastUpdateProgram,
          lastUpdateTimestamp,
          syncTimestamp
          ) VALUES (?,?,?,?,?,?,?,?,?,?,?,?)`,
        values: [
          storeroom.combinedId,
          storeroom.miLocation,
          storeroom.storeroomNumber,
          storeroom.customerNumber,
          storeroom.storeroomName,
          storeroom.creationUserID,
          storeroom.creationProgram,
          storeroom.creationTimestamp,
          storeroom.lastUpdateUserID,
          storeroom.lastUpdateProgram,
          storeroom.lastUpdateTimestamp,
          today,
        ],
      }));
      await openDB();
      if (size(statements) > 0) {
        await db.executeSet(statements);
      }
    } catch (error) {
      console.log('Error saving storerooms to database', error);
      throw new Error('Error saving storerooms to database');
    } finally {
      await closeDB();
    }
  };

  const findStoreroomsByMiLoc = async ({
    miLoc,
    isPendingIssue,
    ...props
  }: FindStoreroomsByMiLocProps): Promise<Storeroom[]> => {
    try {
      const vars = [miLoc];
      const query = trim(props.query);
      const queryLike = `%${query}%`;
      if (query) {
        vars.push(queryLike, queryLike);
      }
      const storeroomNumber = trim(props.storeroomNumber);
      if (storeroomNumber) {
        vars.push(storeroomNumber);
      }
      await openDB();
      return (
        await db.query(
          `SELECT storeroom.*
          ${
            isPendingIssue
              ? `,
            (SELECT COUNT(*)
            FROM issue
            WHERE issue.storeroomNumber = storeroom.storeroomNumber
            AND storeroom.miLocation = issue.miLocation
            AND issue.syncTimestamp ISNULL) as pendingIssues`
              : ''
          }
          FROM storeroom
          WHERE miLocation LIKE ?
          ${query ? `AND (storeroomNumber LIKE ? OR storeroomName LIKE ?)` : ''}
          ${storeroomNumber ? `AND storeroomNumber LIKE ?` : ''}
          ORDER BY storeroomNumber ASC`,
          vars
        )
      ).values as Storeroom[];
    } catch (error) {
      console.log('Error loading storerooms', error);
      throw new Error('Error loading storerooms');
    } finally {
      await closeDB();
    }
  };

  const removeStorerooms = async (miLoc: string): Promise<void> => {
    try {
      await openDB();
      await db.query(
        `DELETE FROM storeroom
        WHERE miLocation = ?`,
        [miLoc]
      );
    } catch (error) {
      console.log('Error removing storerooms', error);
      throw new Error('Error removing storerooms');
    } finally {
      await closeDB();
    }
  };

  return {
    createStorerooms,
    findStoreroomsByMiLoc,
    removeStorerooms,
  };
};

export default useStoreroomDB;
