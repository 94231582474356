import React, { useState } from 'react';
import { toString } from 'lodash';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import useFindVasCodes from 'DocumentsApp/api/useFindVasCodes';
import FastFind from 'DocumentsApp/components/FastFind/FastFind';
import type { VasCode } from 'DocumentsApp/models/VasCode';
import { useDebounce } from 'use-debounce';
import type Input from 'components/Input/Input';

interface VasCodesFastFindProps {
  vasCode?: VasCode;
  modalTitle?: string;
  setVasCode?: (i: VasCode) => void;
}

const VasCodesFastFind = ({
  label,
  modalTitle,
  value,
  required,
  disabled,
  setValue,
  vasCode,
  setVasCode,
  testid,
}: VasCodesFastFindProps &
  React.ComponentProps<typeof Input> &
  IonicReactProps): JSX.Element => {
  const [searchQuery, setSearchQuery] = useState(toString(value));
  const [searchQueryValue] = useDebounce(searchQuery, 300);

  const {
    vasCodes,
    showLoader,
    isEmptyResponse,
    enableInfiniteScroll,
    fetchNextPage,
  } = useFindVasCodes({ query: searchQueryValue });

  return (
    <FastFind<VasCode>
      items={vasCodes}
      getId={(i) => i.codeValue}
      getTitle={(i) => i.codeValue}
      getDescription={(i) => i.codeDesc}
      getSelectedText={(i) => `(${i.codeValue}) ${i.codeDesc}`}
      getLine={(i) => i.UOM}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      showLoader={showLoader}
      isEmptyResponse={isEmptyResponse}
      enableInfiniteScroll={enableInfiniteScroll}
      fetchNextPage={fetchNextPage}
      label={label}
      modalTitle={modalTitle}
      value={value}
      required={required}
      disabled={disabled}
      setValue={setValue}
      selectedItem={vasCode}
      setSelectedItem={setVasCode}
      testid={testid}
    />
  );
};

export default VasCodesFastFind;
