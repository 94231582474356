import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { BaseComponentProps } from 'common/components/utils/renderHelpers';
import { ifRender } from 'common/utils/logicHelpers';
import classes from './CorpAccIcon.module.scss';

interface CorpAccIconProps extends BaseComponentProps {
  className?: string;
  showIcon?: boolean;
}

const CorpAccIcon = ({
  showIcon,
  className,
  testid = '',
}: CorpAccIconProps) => {
  return (
    <>
      {ifRender(
        showIcon,
        <FontAwesomeIcon
          icon={['fas', 'globe']}
          className={classNames(classes.corpIcon, className)}
          data-testid={`${testid}-globe-icon`}
        />
      )}
    </>
  );
};

export default CorpAccIcon;
