import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { includes, join, map, split, toLower, toString } from 'lodash';
import { IonContent, IonPage } from '@ionic/react';
import Alert, { AlertVariantEnum } from 'common/components/Alert/Alert';
import CustomerName from 'common/components/Header/CustomerName';
import Header from 'common/components/Header/Header';
import InsetList from 'common/components/InsetList/InsetList';
import List from 'common/components/List/List';
import { useIonContentRef } from 'common/components/utils/renderHelpers';
import { emptyString } from 'common/utils/valueFormatter';
import useFindTransmissionLogs from 'ProductSearchApp/api/orders/useFindTransmissionLogs';
import type { OrderURLParams } from 'ProductSearchApp/models/Products';
import useGetCustomer from 'api/customer/useGetCustomer';
import { formatPhone } from 'utils/address';
import { formatSnoozeDate } from 'utils/date';
import { getErrorMessage } from 'utils/helpers';
import { removeLeadingZeros } from 'utils/number';
import Refresher from 'components/Refresher/Refresher';
import type { TextQueryProps } from 'components/Text/Text';
import Text from 'components/Text/Text';
import classes from './TransmissionLog.module.scss';

const phoneFormatMethods = ['fax', 'text'];

const sendToRegExp = /(\((?<g1>.*)\))*\s*(?<g2>.*)/;
const formatSendTo = (value = '', method = '') => {
  const result: string[] = [];
  const textQuery: TextQueryProps[] = [
    { query: emptyString, className: classes.emailText },
  ];
  const phoneFormat = includes(phoneFormatMethods, toLower(method));
  map(split(value, /\s*;\s*/), (v) => {
    const match = sendToRegExp.exec(v);
    const g2Value = phoneFormat
      ? formatPhone(match?.groups?.g2)
      : toString(match?.groups?.g2);
    if (match?.groups?.g1) {
      textQuery.push({
        query: phoneFormat ? g2Value : `(${g2Value})`,
        className: classes.emailText,
      });
      result.push(
        `${match?.groups?.g1} ${phoneFormat ? g2Value : `(${g2Value})`}`
      );
    } else if (g2Value) {
      textQuery.push({
        query: g2Value,
        className: classes.onlyEmailText,
      });
      result.push(g2Value);
    }
  });
  return {
    text: join(result, ',\n') || emptyString,
    textQuery,
  };
};

const TransmissionLog = (): JSX.Element => {
  const {
    miLoc,
    id: shipToCustNo,
    ocn: orderCtlNo,
  } = useParams<OrderURLParams>();
  const { t } = useTranslation();
  const { nodeRef, node } = useIonContentRef();

  const {
    data: customerData,
    isLoading: customerIsLoading,
    error: customerError,
  } = useGetCustomer({ searchType: 'customer', miLoc, id: shipToCustNo });

  const {
    log,
    error: logError,
    isLoading: logIsLoading,
    isEmptyResponse,
    noMoreData,
    refetch,
    fetchNextPage,
  } = useFindTransmissionLogs({ miLoc, orderCtlNo });

  const error = customerError || logError;
  const isLoading = customerIsLoading || logIsLoading;

  return (
    <IonPage
      className={classes.TransmissionLogPage}
      data-testid="transmission-log-page"
    >
      <Header
        testid="transmission-log-header"
        hideMenuButton
        customTitle={<CustomerName customerData={customerData} />}
      />
      <IonContent ref={nodeRef} className={classes.content}>
        <Refresher
          slot="fixed"
          onRefresh={refetch}
          disabled={isLoading}
          hidden
          testid="order-list-refresher"
        />
        <Header
          collapse="condense"
          pageTitle={t(`productSearch:transmissionLog:title`)}
          customTitle={
            <div>
              <CustomerName customerData={customerData} />
              <Text
                className={classes.ocnTitle}
                variant="mipro-h6-headline"
                text={t(`productSearch:ocn:ocnNo`, {
                  orderCtlNo: removeLeadingZeros(orderCtlNo),
                })}
              />
            </div>
          }
          testid="transmission-log-header"
        />
        <List
          className={classes.list}
          data={log}
          itemContent={(itemIndex, item) => (
            <div key={itemIndex} className={classes.card}>
              <Text
                variant="mipro-h3-headline"
                text={formatSnoozeDate(item.transmissionTmstmp)}
              />
              {toLower(item.transmissionStatus) === 'failed' && (
                <Alert
                  variant={AlertVariantEnum.danger}
                  text={t('productSearch:transmissionLog:methodUnsuccessful', {
                    method: item.transmissionMethod,
                  })}
                  className={classes.errorText}
                />
              )}
              <div className={classes.wrapper}>
                <InsetList
                  data={[
                    {
                      label: t(`productSearch:transmissionLog:documentType`),
                      value: item.docType,
                    },
                    {
                      label: t(`productSearch:transmissionLog:method`),
                      value: item.transmissionMethod,
                    },
                    {
                      label: t(`productSearch:transmissionLog:sentTo`),
                      value: {
                        ...formatSendTo(item.sendTo, item.transmissionMethod),
                        className: classes.sendToText,
                      },
                    },
                    {
                      label: t(`productSearch:transmissionLog:sentBy`),
                      value: item.creationUsername,
                    },
                  ]}
                  testid="log-attributes"
                />
              </div>
            </div>
          )}
          scrollParent={node}
          isLoading={isLoading}
          isEmptyList={{
            isEmptyList: isEmptyResponse,
            title: t(`productSearch:transmissionLog:emptyMessage`),
            className: classes.emptyResponse,
          }}
          isError={{
            isError: !!error,
            title: t(`productSearch:transmissionLog:errorMessage`),
            body: getErrorMessage(error),
          }}
          isEndOfList={noMoreData}
          endReached={fetchNextPage}
          testid="transmission-log-list"
        />
      </IonContent>
    </IonPage>
  );
};

export default TransmissionLog;
