import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { IonCol, IonImg, IonItem } from '@ionic/react';
import HelpButton from 'common/components/HelpButton/HelpButton';
import { or } from 'common/utils/logicHelpers';
import { searchURL } from 'navigation';
import useGetLocationItem from 'api/location/useGetLocationItem';
import type { ReportsURLParams } from 'models/Reports';
import type { RootState } from 'store/reducers';
import { concatRoutes } from 'utils/navigations';
import { handleSearchNavigation } from 'utils/search';
import Button from 'components/Button/Button';
import Text from 'components/Text/Text';
import classes from './ReportHeader.module.scss';

interface ReportHeaderProps {
  miLoc: string;
  headerText: string;
  headerTextClass?: string;
  title: string;
  customHeader?: React.ReactNode;
  withImage?: string;
  mainHeaderClass?: string;
  className?: string;
  helpButton?: React.ComponentProps<typeof HelpButton>;
}

const ReportHeader = ({
  miLoc: propsMiLoc,
  headerText,
  headerTextClass,
  title,
  customHeader,
  withImage,
  mainHeaderClass,
  className,
  helpButton,
}: ReportHeaderProps): JSX.Element => {
  const {
    miLoc: routeMiLoc = '',
    id: routeId = '',
    orgType,
  } = useParams<ReportsURLParams>();
  const { locationTree } = useSelector((state: RootState) => state.user);
  const miLoc = routeMiLoc || propsMiLoc;
  const storeLocation = locationTree?.[miLoc];

  // TODO: there is no customer drilldown for now
  // const showCustomerLink =
  //   head(groupByOptions)?.key === 'PRD_GRP_01' ||
  //   head(groupByOptions)?.key === 'PRD_GRP_02';

  const { data: location, isLoading } = useGetLocationItem({
    miLoc,
    enabled: !storeLocation || storeLocation?.locationType === 'S',
  });
  const locationWithoutLink =
    isLoading || !location || location.locType !== 'S';

  return (
    <IonItem className={classNames(classes.mainHeader, mainHeaderClass)}>
      <IonCol slot="start" className={classNames(className, classes.headerCol)}>
        <div className={classes.headerWrapper}>
          {!isEmpty(withImage) && (
            <IonImg
              src={withImage}
              className={classes.headerImage}
              data-testid="header-image"
            />
          )}
          <Text
            className={headerTextClass}
            text={headerText}
            variant="mipro-h1-headline"
            testid="header-text"
          />

          {
            // eslint-disable-next-line react/jsx-props-no-spreading
            helpButton && <HelpButton {...helpButton} />
          }
        </div>
        <Button
          className={classNames(classes.subHeaderText, {
            [classes.locationWithoutLink]: locationWithoutLink,
          })}
          text={title}
          testid="header-link"
          variant="link"
          disabled={locationWithoutLink}
          href={
            // !isCamUser
            concatRoutes(
              searchURL(),
              handleSearchNavigation({
                type: or(orgType === 'BRCH', isEmpty(routeId))
                  ? 'motionLocation'
                  : 'employee',
                miLoc,
                sequenceNo: miLoc,
                employeeId: routeId,
              })
            )
            // : undefined
          }
        />
      </IonCol>
      {customHeader}
    </IonItem>
  );
};

export default ReportHeader;
