import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';
import { isEmpty, map } from 'lodash';
import { IonRow } from '@ionic/react';
import { isToday } from 'date-fns';
import { announcementsURL } from 'navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import useFindAnnouncements from 'api/announcements/useFindAnnouncements';
import useFindFeatures from 'api/features/useFindFeatures';
import type { Announcement } from 'models/Announcement';
import { formatCardDate } from 'utils/date';
import { concatRoutes } from 'utils/navigations';
import AnnouncementCard from 'pages/Menu/Announcements/AnnouncementCard';
import FeatureCard from 'pages/Menu/Features/FeatureCard';
import Button from 'components/Button/Button';
import Loader from 'components/Loader/Loader';
import Text from 'components/Text/Text';
import classes from './HomeAnnouncements.module.scss';

const HomeAnnouncements = (): JSX.Element => {
  const { announcements, hasError, showLoader } = useFindAnnouncements({
    limit: 25,
  });
  const {
    features,
    hasError: hasErrorFeatures,
    showLoader: showLoaderFeatures,
  } = useFindFeatures();
  const { url } = useRouteMatch();
  const todayAnnouncements = announcements.filter((announcement) =>
    isToday(new Date(announcement.POST_DATE))
  );
  const previousAnnouncements = announcements
    .filter((announcement) => !isToday(new Date(announcement.POST_DATE)))
    .filter((announcement, index) => index < 4);
  const { t, i18n } = useTranslation();

  const announcementListToShow = (announceToShow: Announcement[]) => {
    return map(
      announceToShow,
      ({ TYPE_NAME, POST_DATE, PAGE_NAME, LINK_DESC, READ }, index) => (
        <IonRow className={classes.annoucementList} key={PAGE_NAME || index}>
          <AnnouncementCard
            index={index}
            pageName={PAGE_NAME}
            read={READ}
            typeAnnouncement={TYPE_NAME}
            postDate={
              isToday(Date.parse(POST_DATE))
                ? t('common:today')
                : formatCardDate(
                    Date.parse(POST_DATE),
                    false,
                    true,
                    i18n.language
                  )
            }
            linkDescription={LINK_DESC}
            beginingURL={announcementsURL().concat('/')}
            showImage={false}
            className={classes.announcementCard}
          />
        </IonRow>
      )
    );
  };

  return (
    <div className={classes.wrapper}>
      <IonRow className={classes.titleRow}>
        <Text
          className={classNames(classes.title, {
            [classes.noFeatures]: features.length === 0,
          })}
          variant="content-heavy"
          text={t('announcements')}
        />
      </IonRow>
      <IonRow className={classes.slidesRow}>
        <Swiper className={classes.slides} slidesPerView="auto" centeredSlides>
          {!hasErrorFeatures &&
            map(
              features,
              ({ featureDescription, postDate, fileContent }, index) => (
                <SwiperSlide key={index}>
                  <FeatureCard
                    key={index}
                    index={index}
                    // eslint-disable-next-line
                    fileContent={fileContent}
                    featureDescription={featureDescription}
                    postDate={formatCardDate(
                      Date.parse(postDate),
                      false,
                      true,
                      i18n.language
                    )}
                  />
                </SwiperSlide>
              )
            )}
        </Swiper>
      </IonRow>
      {!hasError && !isEmpty(todayAnnouncements) && (
        <>
          <IonRow className={classes.divider}>
            <Text text={t('announcements:todayAnnounce')} />
          </IonRow>
          {announcementListToShow(todayAnnouncements)}
        </>
      )}
      {!hasError && !isEmpty(previousAnnouncements) && (
        <>
          <IonRow className={classes.divider}>
            <Text text={t('announcements:previousAnnounce')} />
          </IonRow>
          {announcementListToShow(previousAnnouncements)}
        </>
      )}
      <Loader
        className={classes.loader}
        text={t('announcements:loadingAnnouncements')}
        isOpen={showLoader}
      />
      {!showLoader && !showLoaderFeatures && (
        <IonRow>
          <Button
            className={classes.seeMoreButton}
            variant="link"
            text={t('announcements:seeAllAnnoucements')}
            href={concatRoutes(url, announcementsURL())}
            testid="see-more-announcements-button"
          />
        </IonRow>
      )}
    </div>
  );
};

export default HomeAnnouncements;
