import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { isEmpty, toString } from 'lodash';
import { IonCol, IonContent, IonPage, IonRow } from '@ionic/react';
import Footer from 'common/components/Footer/Footer';
import Input from 'common/components/Forms/Input/Input';
import CustomerName from 'common/components/Header/CustomerName';
import Header from 'common/components/Header/Header';
import { useUserInfo } from 'common/utils/userInfo';
import { useFormik } from 'formik';
import useGetOrderNote from 'ProductSearchApp/api/orders/useGetOrderNote';
import useSubmitOrderLineNote from 'ProductSearchApp/api/orders/useSubmitOrderLineNote';
import NotebookTemplateSelect from 'ProductSearchApp/components/FastFind/NotebookTemplateSelect';
import type {
  OrderNote,
  OrderNotesURLParams,
} from 'ProductSearchApp/models/Order';
import type { ProductDetailURLParams } from 'ProductSearchApp/models/Products';
import * as yup from 'yup';
import useGetCustomer from 'api/customer/useGetCustomer';
import useGoBack from 'hooks/useGoBack';
import type { SelectModalItem } from 'models/Search';
import { formatDate } from 'utils/date';
import CheckBox from 'components/CheckBox/CheckBox';
import DiscardModal from 'components/Modals/DiscardModal/DiscardModal';
import Text from 'components/Text/Text';
import classes from './OrderNotebook.module.scss';

interface OrderNotebookForm {
  templateCD?: SelectModalItem;
  printFaxFlag?: boolean;
  quotePrintFlag?: boolean;
  printOnInvoice?: boolean;
  printOnPick?: boolean;
  text?: string;
}

interface OrderNotebookProps {
  mode?: 'add' | 'edit' | 'view';
}

const OrderNotebook = ({ mode }: OrderNotebookProps): JSX.Element => {
  const { t } = useTranslation();
  const { miLoc, id, ocn, lineNo } = useParams<ProductDetailURLParams>();
  const { micro, date, time } = useParams<OrderNotesURLParams>();
  const [isOpenDiscardModal, setIsOpenDiscardModal] = useState(false);
  const decodedDate = decodeURIComponent(toString(date));
  const isUpdating = !isEmpty(micro) && !isEmpty(date) && !isEmpty(time);

  const { data: customerData } = useGetCustomer({
    searchType: 'customer',
    miLoc,
    id,
  });

  const { data: orderNote, isLoading } = useGetOrderNote({
    miLoc,
    ocn,
    micro: toString(micro),
    date: decodedDate,
    time: toString(time),
    enabled: isUpdating,
  });

  // Todo : Reuse this hook to check if the user is owner, to get userInfo and user Id
  const { isOwner } = useUserInfo({ userId: orderNote?.creationUserid });
  const isViewMode = (!isOwner && isUpdating) || mode === 'view';

  const namespace = 'productSearch:ocn';

  const validationSchema = yup.object().shape({
    text: yup.string().required(t('activities:customerVisitNoteRequired')),
  });

  const { status: submitOrderLineStatus, onSubmitOrderLineNote } =
    useSubmitOrderLineNote({
      miLoc,
      ocn,
      isUpdating,
    });

  const {
    values,
    handleSubmit,
    setFieldValue,
    resetForm,
    dirty,
    errors,
    setErrors,
    isValid,
    validateField,
  } = useFormik<OrderNotebookForm>({
    initialValues: {},
    onSubmit: (formValues) => {
      let body: OrderNote = {
        templateCD: toString(formValues.templateCD?.key),
        text: toString(formValues.text),
        printFaxFlag: formValues.printFaxFlag ? 'Y' : 'N',
        quotePrintFlag: formValues.quotePrintFlag ? 'Y' : 'N',
        printOnInvoice: formValues.printOnInvoice ? 'Y' : 'N',
        printOnPick: formValues.printOnPick ? 'Y' : 'N',
        orderLineNo: lineNo || '0',
      };

      if (isUpdating) {
        body = { ...orderNote, ...body };
      }

      onSubmitOrderLineNote(body);
    },
    validationSchema,
    validateOnChange: false,
  });

  useEffect(() => {
    if (!isLoading) {
      resetForm({
        values: {
          text: orderNote?.text,
          printFaxFlag: orderNote?.printFaxFlag === 'Y',
          quotePrintFlag: orderNote?.quotePrintFlag === 'Y',
          printOnInvoice: orderNote?.printOnInvoice === 'Y',
          printOnPick: orderNote?.printOnPick === 'Y',
          templateCD: {
            key: toString(orderNote?.templateCD),
            title: toString(orderNote?.templateCD),
          },
        },
      });

      void setErrors({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderNote?.text, isLoading]);

  const { goBack } = useGoBack();

  const customBackButtonClick = () => {
    if (dirty) {
      setIsOpenDiscardModal(true);
    } else {
      goBack();
    }
  };

  useEffect(() => {
    if (submitOrderLineStatus === 'success') {
      goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitOrderLineStatus]);

  const isSubmitOrderLineLoading = submitOrderLineStatus === 'loading';

  return (
    <IonPage>
      <Header
        testid="order--notes--header"
        hideMenuButton
        customTitle={<CustomerName customerData={customerData} />}
        backButton={{
          onClick: customBackButtonClick,
        }}
      />
      <IonContent className={classes.content}>
        <Header
          className={classes.header}
          collapse="condense"
          pageTitle={t(`notes:notes`)}
          customTitle={<CustomerName customerData={customerData} />}
          testid="order--notes--header"
        />
        <div className={classes.contentWrapper}>
          <IonRow>
            <IonCol size="12">
              <NotebookTemplateSelect
                miLoc={miLoc}
                setNoteBookTemplateCode={(item) => {
                  void setFieldValue('templateCD', item);
                  void setFieldValue('text', item.description);
                  setTimeout(() => {
                    void validateField('text');
                  }, 100);
                }}
                label={t(`${namespace}:template`)}
                placeholder={t(`${namespace}:selectTemplate`)}
                selItem={values.templateCD as SelectModalItem}
                disabled={isSubmitOrderLineLoading}
                isReadOnly={isViewMode}
              />
            </IonCol>
            <IonCol size="12" className={classes.pd16}>
              <Input
                textarea
                testid="order--notes--description"
                label={t(`notes:notes`)}
                required
                placeholder={t('feedback:textAreaPlaceholder')}
                value={values.text}
                setValue={(e) => {
                  void setFieldValue('text', e);
                  setTimeout(() => {
                    void validateField('text');
                  }, 100);
                }}
                error={errors.text}
                name="text"
                disabled={isSubmitOrderLineLoading}
                readonly={isViewMode}
              />
            </IonCol>
            {isUpdating && (
              <IonCol size="12" className={classes.pd16}>
                <Text
                  text={t('notes:updateDateText', {
                    updateDate: formatDate(orderNote?.lastUpdTmstmp),
                  })}
                  variant="content-smaller"
                />
              </IonCol>
            )}
            <IonCol size="12" className={classes.pd16}>
              <CheckBox
                testid="notes--supplier"
                label={t(`${namespace}:sendToSupplier`)}
                labelTextVariant="mipro-body-copy"
                checked={values.printFaxFlag}
                onChange={(checked) => setFieldValue('printFaxFlag', checked)}
                ariaLabel="printFaxFlag"
                name="printFaxFlag"
                disabled={isSubmitOrderLineLoading}
                isReadOnly={isViewMode}
              />
            </IonCol>
            <IonCol size="12" className={classes.pd16}>
              <CheckBox
                testid="notes--customer"
                label={t(`${namespace}:sendToCustomer`)}
                labelTextVariant="mipro-body-copy"
                checked={values.quotePrintFlag}
                onChange={(checked) => setFieldValue('quotePrintFlag', checked)}
                ariaLabel="quotePrintFlag"
                name="quotePrintFlag"
                disabled={isSubmitOrderLineLoading}
                isReadOnly={isViewMode}
              />
            </IonCol>
            <IonCol size="12" className={classes.pd16}>
              <CheckBox
                testid="notes--printinvoice"
                label={t(`${namespace}:printInvoice`)}
                labelTextVariant="mipro-body-copy"
                checked={values.printOnInvoice}
                onChange={(checked) => setFieldValue('printOnInvoice', checked)}
                ariaLabel="printOnInvoice"
                name="printOnInvoice"
                disabled={isSubmitOrderLineLoading}
                isReadOnly={isViewMode}
              />
            </IonCol>
            <IonCol size="12" className={classes.pd16}>
              <CheckBox
                testid="notes--picking"
                label={t(`${namespace}:sendToPicking`)}
                labelTextVariant="mipro-body-copy"
                checked={values.printOnPick}
                onChange={(checked) => setFieldValue('printOnPick', checked)}
                ariaLabel="printOnPick"
                name="printOnPick"
                disabled={isSubmitOrderLineLoading}
                isReadOnly={isViewMode}
              />
            </IonCol>
          </IonRow>
        </div>

        <DiscardModal
          isOpen={isOpenDiscardModal}
          setIsOpen={setIsOpenDiscardModal}
          title={t('notes:cancelNotesTitle')}
          discardMsg={t('common:discardMsg')}
          testid="edit--order--note--discard--modal"
          discardButtonTitle={t('notes:cancelNotesYes')}
          goBackButtonTitle={t('notes:cancelNotesNo')}
          initialBreakpoint={0.4}
          withRightCloseButton
          onDiscardClick={() => {
            goBack();
          }}
        />
      </IonContent>
      {!isViewMode && (
        <Footer
          buttons={[
            {
              testid: 'order--notes--save--btn',
              text: t('common:save'),
              variant: 'mipro-action',
              disabled: !dirty || isSubmitOrderLineLoading || !isValid,
              onClick: () => handleSubmit(),
            },
          ]}
        />
      )}
    </IonPage>
  );
};

export default OrderNotebook;
