import { useMemo } from 'react';
import type { AxiosError } from 'axios';
import { size, toString, head } from 'lodash';
import { useInfiniteQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import type {
  QueryFlags,
  QueryFnProps,
  QueryParamsType,
} from 'common/api/utils/useGetQueryFlags';
import useGetQueryFlags from 'common/api/utils/useGetQueryFlags';
import { subYears } from 'date-fns';
import type { Order } from 'ProductSearchApp/models/Order';
import { useAxios } from 'providers/AxiosProvider';
import { doConcatDataPages, useKeyUserId, useMiLocOrTeamId } from 'api/helpers';
import { pageSize } from 'utils/constants';
import { DateFormatEnum, formatDate } from 'utils/date';

export const findOrdersQueryKey = 'orders';

interface UseFindOrdersProps {
  miLoc?: string;
  customerNo?: string;
  orderTypes?: string[];
  limit?: number;
  enabled?: boolean;
  processStatus?: string;
}

export interface UseFindOrdersResponse {
  orders: Order[];
  totalRows?: number;
}

const useFindOrders = ({
  miLoc,
  customerNo,
  orderTypes,
  limit = pageSize(),
  enabled = true,
  processStatus,
}: UseFindOrdersProps): UseFindOrdersResponse & QueryFlags => {
  const { axios } = useAxios();
  const { findOrdersAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const { getURLParams } = useMiLocOrTeamId({});
  const params: QueryParamsType = {
    miLoc,
    customerNo,
    orderTypes,
    limit,
  };

  if (processStatus) {
    params.processStatus = processStatus;
    params.minOrderDate = formatDate(
      subYears(new Date(), 1),
      DateFormatEnum.standardDate
    );
  }

  const doFindOrders = async ({ pageParam = 1, signal }: QueryFnProps) => {
    const { data } = await axios.get<UseFindOrdersResponse>(
      findOrdersAPI(
        toString(params.miLoc),
        toString(params.customerNo),
        getURLParams({ ...params, page: pageParam })
      ),
      { signal }
    );
    return data;
  };

  const response = useInfiniteQuery<UseFindOrdersResponse, AxiosError>(
    createQueryKey(findOrdersQueryKey, params),
    doFindOrders,
    {
      enabled,
      getNextPageParam: (lastPage, pages) =>
        size(lastPage?.orders) < limit ? false : size(pages) + 1,
    }
  );

  const { data } = response;

  const orders = useMemo(
    () => doConcatDataPages<Order, UseFindOrdersResponse>(data, 'orders'),
    [data]
  );

  const queryFlags = useGetQueryFlags({ ...response, data: orders, enabled });

  return {
    orders,
    totalRows: head(data?.pages)?.totalRows,
    ...queryFlags,
  };
};

export default useFindOrders;
