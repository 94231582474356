import React from 'react';
import AppIcons from 'constants/appIcons';
import classNames from 'classnames';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import ActionRow from 'components/ActionRow/ActionRow';
import LongPressCopyGesture from 'components/LongPressCopyGesture/LongPressCopyGesture';
import classes from './Email.module.scss';

type EmailProps = {
  emailAddress: string;
  testid: string;
};

const Email = ({
  emailAddress,
  testid,
  onClick,
  className,
}: EmailProps &
  IonicReactProps &
  React.ComponentProps<typeof ActionRow>): JSX.Element => {
  return (
    <LongPressCopyGesture copyableValue={emailAddress}>
      <ActionRow
        className={classNames(classes.emailAddress, className)}
        leftButton={{
          variant: 'link',
          icon: AppIcons.emailIcon(),
          text: emailAddress,
          testid: `${testid}-button`,
        }}
        onClick={onClick}
        withArrow={false}
        isExternalLink
        testid={testid}
      />
    </LongPressCopyGesture>
  );
};

export default Email;

export const EmailRow = ({
  className,
  emailAddress,
  testid,
  onClick,
}: EmailProps &
  IonicReactProps &
  React.ComponentProps<typeof ActionRow>): JSX.Element => {
  return (
    <LongPressCopyGesture
      copyableValue={emailAddress}
      styles={[classNames(className)]}
    >
      <ActionRow
        leftButton={{
          variant: 'link',
          icon: AppIcons.emailIcon(),
          text: emailAddress,
          testid: `${testid}-action-contact-email-button`,
        }}
        onClick={onClick}
        testid={`${testid}-action-contact-email`}
      />
    </LongPressCopyGesture>
  );
};
