import React from 'react';
import classNames from 'classnames';
import type { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonItem, IonLabel } from '@ionic/react';
import { findIcon } from 'utils/icons';
import classes from './Pick12Contest.module.scss';

interface Pick12ContestItem {
  title: string;
  description: string;
  icon: IconName;
  testid?: string;
}

const Pick12ContestItemList = ({
  title,
  description,
  icon,
  testid,
}: Pick12ContestItem): JSX.Element => {
  return (
    <IonItem
      lines="none"
      className={classes.pick12ContestItems}
      data-testid={testid}
    >
      <FontAwesomeIcon
        icon={findIcon(icon)}
        className={classNames(classes.icon, {
          [classes.iconRed]: icon === 'times',
          [classes.iconGreen]: icon === 'check',
        })}
      />
      <IonLabel>
        <h5 data-testid="contest-title">{title}</h5>
        <p data-testid="contest-description">{description}</p>
      </IonLabel>
    </IonItem>
  );
};

export default Pick12ContestItemList;
