import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import type { AxiosError } from 'axios';
import { size, head, map, toNumber, isEmpty, get } from 'lodash';
import { useInfiniteQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import type {
  QueryFnProps,
  QueryParamsType,
} from 'common/api/utils/useGetQueryFlags';
import { formatNumber } from 'common/utils/numberHelper';
import { emptyString } from 'common/utils/valueFormatter';
import i18next from 'i18next';
import { getPoText } from 'ProductSearchApp/util/ocnHelpers';
import { useAxios } from 'providers/AxiosProvider';
import {
  doConcatDataPages,
  doGetIsLoading,
  useKeyUserId,
  useMiLocOrTeamId,
} from 'api/helpers';
import type { InfiniteQueryFlags } from 'models/Search';
import { SortDirEnum } from 'models/Sort';
import type { RootState } from 'store/reducers';
import { DateFormatEnum, formatDate } from 'utils/date';

export interface UseGetOpenQuoteReportProps {
  miLoc?: string;
  sortField?: string;
  sortDir?: SortDirEnum;
  limit?: number;
  enabled: boolean;
  fromVirtualTeam?: boolean;
  onlyMe?: boolean;
  onlyWeb?: boolean;
  onlyToday?: boolean;
}

export interface OpenQuoteReportItem {
  creationTmstmp: string;
  creationUserId: string;
  creationUserName: string;
  custPoNo: string;
  customerName: string;
  dueDate: string;
  lineCount: string;
  miLoc: string;
  orderCtlNo: string;
  shipToCustNo: string;
  totalOrderAmt: string;
  custReleaseNo: string;
  custContactName: string;
  custContactPhone?: string;
  custContactFax?: string;
  custContactEmail?: string;
}

interface GetOpenQuoteReportItemAPIResponse {
  rows: OpenQuoteReportItem[];
  summary: OpenQuoteReportItem;
  totalRows: number;
}

interface UseGetReportOpenQuoteResponse {
  openQuoteData: OpenQuoteReportItem[];
  totalRows?: number;
}

export const openQuoteReportKey = 'open-quote-report';

const useGetOpenQuotesReport = ({
  miLoc,
  sortField,
  sortDir = SortDirEnum.DESCENDING,
  enabled,
  limit = 25,
  fromVirtualTeam,
  onlyMe = false,
  onlyWeb = false,
  onlyToday = false,
}: UseGetOpenQuoteReportProps): UseGetReportOpenQuoteResponse &
  InfiniteQueryFlags => {
  const { axios } = useAxios();
  const { getOpenQuotesAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const { getURLParams } = useMiLocOrTeamId({});
  const { userInfo } = useSelector((state: RootState) => state.user);
  const userIdApp = get(userInfo, 'userid', '');

  const params: QueryParamsType = {
    miLoc,
    limit,
    sortField,
    sortDir,
    onlyMe,
    onlyWeb,
    onlyToday,
  };

  if (fromVirtualTeam) {
    params.teamSearch = fromVirtualTeam;
  }

  const doGetOpenQuotes = async ({ pageParam = 1, signal }: QueryFnProps) => {
    const { data } = await axios.get<GetOpenQuoteReportItemAPIResponse>(
      getOpenQuotesAPI(getURLParams({ ...params, page: pageParam })),
      { signal }
    );
    return data;
  };

  const response = useInfiniteQuery<
    GetOpenQuoteReportItemAPIResponse,
    AxiosError
  >(createQueryKey(openQuoteReportKey, params), doGetOpenQuotes, {
    enabled,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    retry: (failureCount: number, err: AxiosError<any>) =>
      err.response?.status === 403 ? false : !(failureCount < 3),
    getNextPageParam: (lastPage, pages) =>
      size(lastPage.rows) < limit ? false : size(pages) + 1,
  });

  const {
    data,
    error,
    status,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = response;

  const openQuoteData = useMemo(
    () =>
      doConcatDataPages<OpenQuoteReportItem, GetOpenQuoteReportItemAPIResponse>(
        data,
        'rows'
      ),
    [data]
  );

  const transformedOpenQuoteData = useMemo(
    () =>
      map(openQuoteData, (row) => {
        const {
          custPoNo,
          custReleaseNo,
          dueDate,
          totalOrderAmt,
          creationUserName,
          creationTmstmp,
          creationUserId,
        } = row;
        return {
          ...row,
          creationTmstmp: formatDate(creationTmstmp, DateFormatEnum.fullDateV2),
          dueDate: !isEmpty(dueDate)
            ? formatDate(dueDate, DateFormatEnum.fullDateV2)
            : emptyString,
          totalOrderAmt: formatNumber({
            number: toNumber(totalOrderAmt),
            currencyType: 'USD',
            ignoreScale: true,
          }),
          custPoNo: getPoText(custPoNo, custReleaseNo),
          creationUserName:
            userIdApp === creationUserId
              ? i18next.t('common:you')
              : creationUserName,
        };
      }),
    [openQuoteData, userIdApp]
  );

  const hasItems = size(openQuoteData) > 0;
  const hasError = status === 'error';
  const isEmptyResponse = status === 'success' && !hasNextPage && !hasItems;
  const noMoreData = status === 'success' && !hasNextPage && hasItems;
  const showLoader = doGetIsLoading(response) || isFetchingNextPage;
  const enableInfiniteScroll = !(!hasNextPage || isFetchingNextPage);

  return {
    refetch: async () => {
      if (enabled) {
        await refetch();
      }
    },
    fetchNextPage: async () => {
      await fetchNextPage();
    },
    totalRows: head(data?.pages)?.totalRows,
    openQuoteData: transformedOpenQuoteData,
    error,
    hasError,
    showLoader,
    isEmptyResponse,
    noMoreData,
    enableInfiniteScroll,
  };
};

export default useGetOpenQuotesReport;
