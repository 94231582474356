import { toNumber } from 'lodash';
import { useQueryClient } from '@tanstack/react-query';
import useGetCustomer from 'api/customer/useGetCustomer';
import { getPlaceholderData } from 'api/helpers';
import type { Customer } from 'models/Customer';
import type { Note } from 'models/Notebook';
import type { QueryFlags } from 'models/Search';
import { findNotesQueryKey } from './useFindNotes';

export const getNoteQueryKey = 'notebook';

interface UseGetNoteProps {
  miLoc: string;
  id: string;
  noteType: string;
  noteId: string;
}

interface UseGetNoteResponse {
  customerData?: Customer;
  notebook?: Note;
}

const useGetNote = ({
  miLoc,
  id,
  noteType,
  noteId,
}: UseGetNoteProps): UseGetNoteResponse & QueryFlags => {
  const queryClient = useQueryClient();

  const { data: customerData } = useGetCustomer({
    searchType: 'customer',
    miLoc,
    id,
  });

  const notebook = getPlaceholderData<Note>({
    queryClient,
    queryKey: findNotesQueryKey,
    queryKeyParams: { miLoc, id },
    findPredicate: {
      miLoc,
      customerNo: id,
      notebookType: noteType,
      ...(noteType === 'ALERT'
        ? { historyId: toNumber(noteId) }
        : { itemLineNo: toNumber(noteId) }),
    },
  });

  return { customerData, notebook, isLoading: false };
};

export default useGetNote;
