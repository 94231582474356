import { useTranslation } from 'react-i18next';
import { toNumber, toString } from 'lodash';
import type { MutationStatus } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { choose, or } from 'common/utils/logicHelpers';
import { namespaces } from 'i18n/i18n.constants';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import { findActivitiesQueryKey } from 'api/activities/useFindActivities';
import { getActivityQueryKey } from 'api/activities/useGetActivity';
import {
  doPromiseAPI,
  getPlaceholderData,
  onMutateUpdate,
  onSuccessMutation,
  onErrorUpdate,
  getQueryCacheKeys,
} from 'api/helpers';
import type { ActionCardActivity } from 'models/ActivityModels';
import type { DeleteActivityNotebookParams } from 'models/Notebook';
import { ToastType } from 'models/Toast';
import { findActivityNotesQueryKey } from './useFindActivityNotes';

interface UseDeleteNotebookResponse {
  status: MutationStatus;
  deleteActivityNote: (body: DeleteActivityNotebookParams) => void;
}

const useDeleteActivityNote = (): UseDeleteNotebookResponse => {
  const { axios } = useAxios();
  const { deleteActivityNotesAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const { t } = useTranslation(namespaces.notes);

  const doDeleteActivityNotebook = (body: DeleteActivityNotebookParams) => {
    return doPromiseAPI(async (cancelToken) => {
      return axios.delete(deleteActivityNotesAPI(body.id, body.deleteFlag), {
        cancelToken,
      });
    });
  };

  const { mutate, status } = useMutation(doDeleteActivityNotebook, {
    onMutate: async (vars) => {
      const historyId = toNumber(vars.historyId);
      const cachedActivity = or(
        getPlaceholderData<ActionCardActivity>({
          queryClient,
          queryKey: findActivitiesQueryKey,
          findPredicate: { historyId },
        }),
        queryClient.getQueryData<ActionCardActivity>(
          getQueryCacheKeys(queryClient, getActivityQueryKey, {
            historyId: toNumber(historyId),
          })?.[0]
        )
      );
      const newCommentCount =
        or(toNumber(cachedActivity?.commentCount), 0) +
        toNumber(choose(vars.deleteFlag === 'N', 1, -1));
      const activitiesListContext = await onMutateUpdate<ActionCardActivity>({
        queryClient,
        queryKey: findActivitiesQueryKey,
        updatedItems: [{ historyId, commentCount: toString(newCommentCount) }],
        findPredicates: [{ historyId }],
        isInfiniteQuery: true,
      });
      const activityContext = await onMutateUpdate<ActionCardActivity>({
        queryClient,
        queryKey: getActivityQueryKey,
        updatedItems: [{ historyId, commentCount: toString(newCommentCount) }],
        findPredicates: [{ historyId }],
        isSingleQuery: true,
      });
      return { activityContext, activitiesListContext };
    },
    onSuccess: (data, vars) => {
      void onSuccessMutation(queryClient, findActivityNotesQueryKey, {
        historyId: vars.historyId,
      });
      void onSuccessMutation(queryClient, findActivitiesQueryKey);
      if (!vars.skipSuccessToast) {
        addToast({
          variant: 'mipro-toast',
          type: ToastType.success,
          leftIcon: ['fas', 'check-circle'],
          text: or(vars.successToastText, t('images:deleteNotebookToast')),
          button: {
            role: 'undo',
            text: t('common:undo'),
            handler: () =>
              mutate({
                skipSuccessToast: true,
                deleteFlag: 'N',
                id: vars.id,
                historyId: vars.historyId,
              }),
          },
          testid: 'delete--event-notebook-toast',
        });
      }
    },
    onError: (_data, _vars, context) => {
      addToast({
        type: ToastType.error,
        text: t('deleteEventNoteFailureToast'),
        testid: 'delete-notebook-error-toast',
      });
      onErrorUpdate<ActionCardActivity>({
        queryClient,
        context: context?.activityContext,
        isSingleQuery: true,
      });
      onErrorUpdate<ActionCardActivity>({
        queryClient,
        context: context?.activitiesListContext,
        isInfiniteQuery: true,
      });
    },
  });

  return {
    status,
    deleteActivityNote: (body: DeleteActivityNotebookParams) => mutate(body),
  };
};

export default useDeleteActivityNote;
