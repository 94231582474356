import { useSelector } from 'react-redux';
import { find, isNil } from 'lodash';
import { useQueryClient } from '@tanstack/react-query';
import type { SalesReportRow } from 'ReportsApp/api/useGetSalesDashboard';
import { getSalesDashboardQueryKey } from 'ReportsApp/api/useGetSalesDashboard';
import { getSalesReportQueryKey } from 'ReportsApp/api/useGetSalesReport';
import { getPlaceholderData } from 'api/helpers';
import { getReportDrillDownQueryKey } from 'api/salesReports/useGetReportDrillDown';
import type { DrillDownLocation, ReportDrillDownItem } from 'models/Reports';
import type { TerritoryLocation } from 'models/Territory';
import type { RootState } from 'store/reducers';

export const getLocationQueryKey = 'location';

interface UseGetLocationProps {
  miLoc: string;
  enabled?: boolean;
}

interface UseGetLocationResponse {
  data?: DrillDownLocation & TerritoryLocation;
}

const useGetLocation = ({
  miLoc: propsMiLoc,
}: UseGetLocationProps): UseGetLocationResponse => {
  const queryClient = useQueryClient();
  const { locationTree, miLoc: stateMiLoc = '' } = useSelector(
    (state: RootState) => state.user
  );

  let miLoc = propsMiLoc;
  miLoc ||= stateMiLoc;

  const location = find(locationTree, { miLoc });

  if (!isNil(location)) {
    return { data: location };
  }

  const drilldownLocation = getPlaceholderData<ReportDrillDownItem>({
    queryClient,
    queryKey: getReportDrillDownQueryKey,
    findPredicate: { miLoc, id: miLoc },
    objectKey: 'items',
  });

  if (!isNil(drilldownLocation)) {
    return {
      data: { miLoc, name: drilldownLocation.Name } as TerritoryLocation,
    };
  }

  const newDrilldownLocation = getPlaceholderData<ReportDrillDownItem>({
    queryClient,
    queryKey: getSalesReportQueryKey,
    findPredicate: { miLoc, id: miLoc },
    objectKey: 'items',
  });

  if (!isNil(newDrilldownLocation)) {
    return {
      data: { miLoc, name: newDrilldownLocation.Name } as TerritoryLocation,
    };
  }

  const salesReportLocation = getPlaceholderData<SalesReportRow>({
    queryClient,
    queryKey: getSalesDashboardQueryKey,
    findPredicate: { miLoc },
    objectKey: 'rows',
  });

  if (!isNil(salesReportLocation)) {
    return {
      data: { miLoc, name: salesReportLocation.miLocName } as TerritoryLocation,
    };
  }

  return { data: undefined };
};

export default useGetLocation;
