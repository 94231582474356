import React from 'react';
import classNames from 'classnames';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonCol, IonRow } from '@ionic/react';
import CorpAccIcon from 'common/components/CorpAccIcon/CorpAccIcon';
import { findIcon } from 'utils/icons';
import Button from 'components/Button/Button';
import Pick12Icon from 'components/Pick12Icon/Pick12Icon';
import type { TextVariantType } from 'components/Text/Text';
import Text from 'components/Text/Text';
import classes from './CustomerName.module.scss';

interface ICustomerNameProps {
  customerName: string;
  customerPick12?: boolean;
  hasCorpGlobalIcon?: boolean;
  linkClassName?: string;
  textClassName?: string;
  iconClassName?: string;
  leftIcon?: IconProp;
  rightIcon?: IconProp;
  disabled?: boolean;
  href?: string;
  testid?: string;
  onClick?: (e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => void;
  textVariant?: TextVariantType;
  headerCollapsed?: boolean;
  isFavorite?: boolean;
  customContent?: React.ReactNode;
  customContentClassName?: string;
}

const CustomerName = ({
  customerName,
  linkClassName,
  textClassName,
  customerPick12,
  hasCorpGlobalIcon,
  onClick,
  leftIcon,
  rightIcon,
  iconClassName,
  disabled,
  testid,
  href,
  textVariant,
  headerCollapsed,
  isFavorite,
  customContent,
  customContentClassName,
}: ICustomerNameProps): JSX.Element => {
  return (
    <IonRow className={classes.flex}>
      <IonCol
        className={classNames(classes.flex, customContentClassName, {
          [classes.removePdBottom]: !headerCollapsed,
        })}
      >
        {isFavorite && <FontAwesomeIcon icon={findIcon('star', 'fas')} />}
        {customerPick12 && (
          <Pick12Icon
            testid={`${testid || 'pick12-icon'}-link`}
            className={classNames(iconClassName, classes.pick12Icon)}
          />
        )}
        <CorpAccIcon
          showIcon={hasCorpGlobalIcon}
          testid={testid}
          className={classes.corpIcon}
        />
        <Button
          variant="link"
          className={classNames(classes.link, linkClassName)}
          rightIcon={rightIcon || undefined}
          onClick={onClick}
          leftIcon={leftIcon}
          disabled={disabled}
          testid={`${testid || 'customer-name'}-link`}
          href={href}
        >
          <Text
            text={customerName}
            className={classNames(textClassName, classes.customerNameText)}
            testid="customerName"
            variant={textVariant}
          />
        </Button>
        {customContent}
      </IonCol>
    </IonRow>
  );
};

export default CustomerName;
