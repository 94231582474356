import { useEffect, useRef, useState } from 'react';
import type { Dictionary } from 'lodash';
import { toNumber } from 'lodash';

interface UseGetFixedHeaderProps {
  tableData: Dictionary<unknown>[];
}

const useGetFixedHeader = ({ tableData }: UseGetFixedHeaderProps) => {
  const headerRef = useRef<HTMLDivElement>(null);

  const [headerTop, setHeaderTop] = useState(0);
  const onResize = () =>
    setHeaderTop(toNumber(headerRef.current?.clientHeight));

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  useEffect(() => {
    onResize();
  }, [tableData]);

  return { headerRef, headerTop };
};

export default useGetFixedHeader;
