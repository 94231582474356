import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { first, map, toString } from 'lodash';
import { IonRow } from '@ionic/react';
import Footer from 'common/components/Footer/Footer';
import { FormikInput } from 'common/components/Forms/Input/Input';
import type { OptionalComponentProps } from 'common/components/utils/renderHelpers';
import { FormikProvider, useFormik } from 'formik';
import useUpdateCancelWo from 'ProductSearchApp/api/useUpdateCancelWo';
import useUpdateToCart from 'ProductSearchApp/api/useUpdateToCart';
import type { ItemSourcing } from 'ProductSearchApp/models/Order';
import type { OrderURLParams } from 'ProductSearchApp/models/Products';
import { useToasts } from 'providers/ToastProvider';
import useFindDataCodes from 'api/data/useFindDataCodes';
import Loader from 'components/Loader/Loader';
import SheetModal from 'components/Modals/SheetModal/SheetModal';
import RadioButton from 'components/RadioButton/RadioButton';
import classes from './ReturnInstructions.module.scss';

interface ReturnInstructionProps {
  itemSource?: ItemSourcing;
  orderLineNo: string;
}

export interface ReturnInstructionForm {
  cancelwo: string;
  notebookText?: string;
}

const ReturnInstructions = ({
  isOpen = false,
  setIsOpen,
  itemSource,
  orderLineNo,
  testid = 'return-instructions',
}: OptionalComponentProps<React.ComponentProps<typeof SheetModal>> &
  ReturnInstructionProps): JSX.Element => {
  const { t } = useTranslation();
  const { data, isLoading } = useFindDataCodes({
    codeType: 'cancelwo',
    enabled: isOpen,
  });
  const { ocn } = useParams<OrderURLParams>();
  const { onUpdateCancelWo } = useUpdateCancelWo();
  const { onOptismitcUpdateItem, onRollbackItem } = useUpdateToCart({});
  const { addToast } = useToasts();

  const radioOptions = map(data, (item) => {
    return {
      value: item.id,
      displayValue: item.codeName,
    };
  });

  const formik = useFormik<ReturnInstructionForm>({
    initialValues: {
      cancelwo: '',
    },
    onSubmit: async (values) => {
      setIsOpen?.(false);
      await onOptismitcUpdateItem?.(orderLineNo);
      addToast({
        variant: 'mipro-toast',
        text: t(`productSearch:ocn:cancelWoSuccessToast`, {
          orderLineNo,
          ocn,
        }),
        button: {
          text: t('common:undo'),
          handler: async () => {
            await onRollbackItem?.(orderLineNo);
          },
        },
        onClose: () => {
          onUpdateCancelWo({
            cancelCode: values.cancelwo,
            notebookText: values.notebookText,
            orderLineNo,
            shopMiLoc: toString(itemSource?.requestTo),
            workOrderCtlNo: toString(itemSource?.ctlNo),
          });
        },
        testid: 'delete-item-success-toast',
      });
    },
  });

  useEffect(() => {
    if (isOpen && !isLoading) {
      formik.resetForm();
      void formik?.setFieldValue('cancelwo', toString(first(data)?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, isLoading, data]);

  return (
    <SheetModal
      isOpen={isOpen}
      testid={testid}
      setIsOpen={setIsOpen}
      title={t('productSearch:ocn:returnInstructionModalTitle')}
      initialBreakpoint={0.55}
      withRightCloseButton
      className={classes.returnInstructionModal}
      backdropDismiss={false}
    >
      <Loader
        isOpen={isLoading}
        className={classes.loader}
        testid="return-instructions-loader"
        text={t('common:loading')}
      />
      <FormikProvider value={formik}>
        {!isLoading && (
          <>
            <IonRow className={classes.row}>
              <RadioButton
                options={radioOptions}
                testid="choose-cancelwo"
                labelTextVariant="mipro-body-copy"
                isVerticalAlign
                onChange={(val) => formik.setFieldValue('cancelwo', val)}
                value={formik.values.cancelwo}
              />
            </IonRow>
            <IonRow className={classes.row}>
              <FormikInput
                name="notebookText"
                testid="notebook-text-input"
                textarea
                rows={5}
                className={classes.input}
                placeholder={t('productSearch:ocn:returnInstructionMsg')}
                value={formik.values.notebookText}
              />
            </IonRow>
            <Footer
              buttons={[
                {
                  text: t('common:cancel'),
                  testid: 'cancel-btn',
                  variant: 'secondary',
                  onClick: () => setIsOpen?.(false),
                },
                {
                  text: t('common:ok'),
                  testid: 'save-btn',
                  variant: 'mipro-action',
                  onClick: () => formik.handleSubmit(),
                },
              ]}
              className={classes.footer}
            />
          </>
        )}
      </FormikProvider>
    </SheetModal>
  );
};

export default ReturnInstructions;
