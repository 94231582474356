import { useTranslation } from 'react-i18next';
import type { MutationStatus } from '@tanstack/react-query';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import type { UpdateMutationContext } from 'api/helpers';
import {
  doPromiseAPI,
  onErrorUpdate,
  onMutateUpdate,
  onSuccessMutation,
} from 'api/helpers';
import type { Announcement } from 'models/Announcement';
import { ToastType } from 'models/Toast';
import {
  findAnnouncementsObjectKey,
  findAnnouncementsQueryKey,
} from './useFindAnnouncements';

interface MarkAllAsReadBody {
  context?: UpdateMutationContext<Announcement>[];
}

interface UseMarkAllAnnouncementsAsReadResponse {
  status: MutationStatus;
  onMarkAllAsRead: (body: MarkAllAsReadBody) => void;
}

const useMarkAllAnnouncementsAsRead =
  (): UseMarkAllAnnouncementsAsReadResponse => {
    const { axios } = useAxios();
    const { markAllAnnouncementsAsReadAPI } = useAPIUrl();
    const queryClient = useQueryClient();
    const { addToast } = useToasts();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const doMarkAllAsRead = (body: MarkAllAsReadBody) => {
      return doPromiseAPI(async (cancelToken) => {
        return axios.post(markAllAnnouncementsAsReadAPI(), {}, { cancelToken });
      });
    };
    const { t } = useTranslation();

    const { mutate, status } = useMutation(doMarkAllAsRead, {
      onMutate: async () =>
        onMutateUpdate<Announcement>({
          queryClient,
          queryKey: findAnnouncementsQueryKey,
          dataPath: findAnnouncementsObjectKey,
          updateAll: true,
          updatedItems: [{ read: 'Y', READ: 'Y' } as Announcement],
          isInfiniteQuery: true,
        }),
      onSuccess: () => {
        void onSuccessMutation(queryClient, findAnnouncementsQueryKey);
      },
      onError: (_err, vars, context) => {
        onErrorUpdate<Announcement>({
          queryClient,
          dataPath: findAnnouncementsObjectKey,
          context: vars.context || context,
          isInfiniteQuery: true,
        });
        addToast({
          type: ToastType.error,
          text: t('announcements:announcementsReadError'),
          testid: 'mark-all-as-read-error-toast',
        });
      },
    });

    return {
      status,
      onMarkAllAsRead: (body) => mutate(body),
    };
  };

export default useMarkAllAnnouncementsAsRead;
