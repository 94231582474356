import { useSelector } from 'react-redux';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import { doPromiseAPI } from 'api/helpers';
import type { SignInResponse } from 'models/Signin';
import UserInfoStorage from 'storage/UserInfoStorage';
import type { RootState } from 'store/reducers';

interface UseGetWhoAmIResponse {
  getWhoAmI: () => Promise<SignInResponse>;
}

const useGetWhoAmI = (): UseGetWhoAmIResponse => {
  const { axios } = useAxios();
  const { whoAmIAPI } = useAPIUrl();
  const { isOnline } = useNetworkStatus();
  const userState = useSelector((state: RootState) => state.user);

  const getWhoAmI = (): Promise<SignInResponse> => {
    return doPromiseAPI<SignInResponse>(async () => {
      if (isOnline) {
        const { data } = await axios.get<SignInResponse>(whoAmIAPI(), {
          params: { useVirtualTeam: true },
        });
        return data;
      }
      const userInfo = await UserInfoStorage.get();
      return { ...userState, ...userInfo };
    });
  };

  return { getWhoAmI };
};

export default useGetWhoAmI;
