import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ManufacturerSelect from 'CostSavingsApp/components/FastFind//ManufacturerSelect';
import { useFormik } from 'formik';
import type { AdvancedSearchForm } from 'ProductSearchApp/models/Products';
import type { SelectModalItem } from 'models/Search';
import Button from 'components/Button/Button';
import SheetModal from 'components/Modals/SheetModal/SheetModal';
import classes from './AdvancedSearchModal.module.scss';

interface AdvancedSearchModalProps {
  isOpen: boolean;
  setIsOpen?: (b: boolean) => void;
  testid: string;
  setAdvancedSearch?: (values?: AdvancedSearchForm) => void;
  advancedSearchFields?: AdvancedSearchForm;
}

const AdvancedSearchModal = ({
  isOpen,
  setIsOpen,
  testid,
  setAdvancedSearch,
  advancedSearchFields,
}: AdvancedSearchModalProps) => {
  const { t } = useTranslation();

  const {
    values,
    errors,
    isSubmitting,
    handleSubmit,
    setFieldValue,
    setSubmitting,
    resetForm,
    dirty,
  } = useFormik<AdvancedSearchForm>({
    initialValues: {},
    onSubmit: (formValues) => {
      setAdvancedSearch?.(formValues);
      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (isOpen) {
      resetForm({
        values: {
          ...values,
          mfrCtlNo: advancedSearchFields?.mfrCtlNo,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <SheetModal
      isOpen={isOpen}
      testid={testid}
      setIsOpen={setIsOpen}
      title={t('common:filter')}
      initialBreakpoint={0.5}
      withRightCloseButton
    >
      <div className={classes.content}>
        <ManufacturerSelect
          label={t('productSearch:manufacturer')}
          placeholder={t('productSearch:searchManufacturers')}
          setManufacturer={(item) => {
            void setFieldValue('mfrCtlNo', item);
          }}
          inputError={errors.mfrCtlNo}
          required
          selItem={values.mfrCtlNo as unknown as SelectModalItem}
        />
        <div className={classes.footer}>
          <div className={classes.buttonBar}>
            <Button
              variant="secondary"
              text={t('common:reset')}
              testid="reset-filter"
              className={classes.fullWidth}
              onClick={() => {
                resetForm();
                setAdvancedSearch?.();
                setIsOpen?.(false);
              }}
            />
            <Button
              variant="action"
              text={t('common:apply')}
              testid="reset-filter"
              className={classes.fullWidth}
              disabled={!values.mfrCtlNo?.key || isSubmitting || !dirty}
              onClick={() => {
                handleSubmit();
                setIsOpen?.(false);
              }}
            />
          </div>
        </div>
      </div>
    </SheetModal>
  );
};

export default AdvancedSearchModal;
