import React from 'react';
import { useTranslation } from 'react-i18next';
import type { AxiosError } from 'axios';
import { map, size } from 'lodash';
import type { BarDatum } from '@nivo/bar';
import { subYears } from 'date-fns';
import { namespaces } from 'i18n/i18n.constants';
import type { DateSegmentType, SummaryItemOutput } from 'models/Reports';
import { formatDate } from 'utils/date';
import { getErrorMessage, getInvalidBusDay } from 'utils/helpers';
import {
  getTrendData,
  customXAxisTick,
  formatLeftYAxisValues,
  getChartBoundaries,
  getChartKeys,
  lineChartColors,
  useGetChartHeaderTabs,
} from 'components/Charts/helpers/utils';
import Chart from 'components/Charts/LineChart/Chart';
import ChartHeader from 'components/Charts/ReportHeader/ChartHeader';
import ReportHeader from 'components/Charts/ReportHeader/ReportHeader';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './LineChart.module.scss';
import 'components/Charts/helpers/NivoChartOverrides.scss';

interface BarChartProps {
  isLoading?: boolean;
  error?: AxiosError | null;
  salesData: BarDatum[];
  lastYearSalesData?: BarDatum[];
  profitData: BarDatum[];
  lastYearProfitData?: BarDatum[];
  requestType: DateSegmentType;
  headerData: SummaryItemOutput[];
  selectedDate: Date;
  enableBusDate?: boolean;
  disableHighlight?: boolean;
  tabValue: string;
  busDay?: number;
  setTabValue: (s: string) => void;
  testid: string;
  currencyType?: string;
}

const LineChart = ({
  isLoading,
  error,
  salesData,
  lastYearSalesData,
  profitData,
  lastYearProfitData,
  requestType,
  headerData,
  selectedDate = new Date(),
  enableBusDate,
  busDay,
  disableHighlight,
  tabValue,
  setTabValue,
  testid,
  currencyType,
}: BarChartProps): JSX.Element => {
  const { t } = useTranslation(namespaces.reports);

  const trendData: BarDatum[] = getTrendData(
    tabValue === 'sales' ? salesData : profitData,
    requestType
  );
  const prevData =
    tabValue === 'sales' ? lastYearSalesData : lastYearProfitData;
  const prevTrendData: BarDatum[] = getTrendData(prevData, requestType);

  const { leftYKey, rightYKey } = getChartKeys(trendData);
  const { leftYKey: prevLeftYKey, rightYKey: prevRightYKey } =
    getChartKeys(prevTrendData);

  const { maxLeftYValue, minLeftYValue, marginLeft, chartData } =
    getChartBoundaries(trendData, leftYKey, rightYKey, currencyType, true);
  const {
    maxLeftYValue: prevMaxLeftYValue,
    minLeftYValue: prevMinLeftYValue,
    marginLeft: prevMarginLeft,
    chartData: prevChartData,
  } = getChartBoundaries(
    prevTrendData,
    prevLeftYKey,
    prevRightYKey,
    currencyType,
    true
  );

  const lineData = [
    {
      id: `prev-${tabValue}`,
      color: lineChartColors[`prev-${tabValue}`],
      data: map(prevChartData, ({ date, busDate, dollars, gp }) => ({
        x: requestType === 'YTD' ? date : busDate,
        y: tabValue === 'sales' ? dollars : gp,
      })),
    },
  ];
  lineData.push({
    id: tabValue,
    color: lineChartColors[tabValue],
    data: map(chartData, ({ date, busDate, dollars, gp }) => ({
      x: requestType === 'YTD' ? date : busDate,
      y: tabValue === 'sales' ? dollars : gp,
    })),
  });

  const isInvalidBusDay = getInvalidBusDay(error);

  return (
    <div className={classes.barChart} data-testid={testid}>
      <ReportHeader
        isLoading={isLoading}
        requestType={requestType}
        headerData={headerData}
        testid={testid}
        currencyType={currencyType}
      />
      <div className={classes.chartWrapper}>
        <ChartHeader
          tabValue={tabValue}
          setTabValue={setTabValue}
          options={useGetChartHeaderTabs()}
          items={[
            {
              text: formatDate(subYears(selectedDate, 1), 'y'),
              style: {
                border: `2px solid ${lineChartColors[`prev-${tabValue}`]}`,
              },
              show: size(prevTrendData) > 0,
            },
            {
              text: formatDate(selectedDate, 'y'),
              style: { background: lineChartColors[tabValue] },
              show: true,
            },
          ]}
        />
        {error && (
          <WarningMessage
            className={classes.warningMessage}
            title={
              isInvalidBusDay
                ? t('reports:emptyReportHeader')
                : t('errorSummary')
            }
            body={
              isInvalidBusDay
                ? t('reports:invalidBusDayMsg', {
                    requestType:
                      requestType === 'MTD'
                        ? t('common:month')
                        : t('common:year'),
                  })
                : getErrorMessage(error)
            }
          />
        )}
        {!error && (
          <Chart
            data={lineData}
            maxValue={
              maxLeftYValue > prevMaxLeftYValue
                ? maxLeftYValue
                : prevMaxLeftYValue
            }
            minValue={
              minLeftYValue < prevMinLeftYValue
                ? minLeftYValue
                : prevMinLeftYValue
            }
            marginLeft={
              marginLeft > prevMarginLeft ? marginLeft : prevMarginLeft
            }
            enableGridY
            gridYValues={4}
            axisLeft={{
              tickSize: 0,
              tickPadding: 5,
              tickValues: 4,
              format: formatLeftYAxisValues(!!isLoading, currencyType),
            }}
            axisBottom={{
              tickSize: 0,
              tickPadding: 5,
              renderTick: customXAxisTick(
                size(trendData) > size(prevTrendData)
                  ? trendData
                  : prevTrendData,
                requestType,
                selectedDate,
                enableBusDate,
                disableHighlight,
                busDay
              ),
            }}
          />
        )}
      </div>
    </div>
  );
};

export default LineChart;
