import { includes } from 'lodash';
import i18next from 'i18n/i18n';
import * as yup from 'yup';

const crmTaskFormSchema = yup.object().shape({
  action: yup.string(),
  reason: yup.string().when('action', {
    is: (action: string) => includes(['WON', 'LOST'], action),
    then: (schema) => schema.required(i18next.t('common:requiredError')),
  }),
  actualValue: yup.string().when('action', {
    is: 'WON',
    then: (schema) =>
      schema
        .required(i18next.t('common:requiredError'))
        .test('invalidValue', '', (v) => Number(v) >= 0),
  }),
  reasonDesc: yup.string().when('action', {
    is: (action: string) => includes(['WON', 'LOST'], action),
    then: (schema) => schema.required(i18next.t('common:requiredError')),
  }),
});

export default crmTaskFormSchema;
