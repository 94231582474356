import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { isEmpty, isNil, isObject, map, size, toString } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonImg, IonRow } from '@ionic/react';
import Alert from 'common/components/Alert/Alert';
import type { OptionalRenderProp } from 'common/components/utils/renderHelpers';
import type { InventoryPlanIcon } from 'InventoryApp/util/inventoryUtil';
import MIIcon from 'assets/MIIcon.svg';
import ActionRow from 'components/ActionRow/ActionRow';
import Badge from 'components/Badge/Badge';
import Button from 'components/Button/Button';
import Text from 'components/Text/Text';
import classes from './ListItem.module.scss';

export interface SecondaryTextProps {
  text?: string;
  children?: React.ReactNode;
}

interface ListItemInterface {
  title: OptionalRenderProp<React.ComponentProps<typeof Button>>;
  href?: string;
  titlePrefix?: string;
  titleClassPrefix?: string;
  overlay?: string;
  subTitle?: string;
  subTitleClassName?: string;
  secondaryTextArray?: SecondaryTextProps[];
  secondaryClassName?: string;
  badge?: OptionalRenderProp<React.ComponentProps<typeof Badge>>;
  alert?: OptionalRenderProp<React.ComponentProps<typeof Alert>>;
  disabled?: boolean;
  footer?: React.ReactNode;
  // Todo: Make props a list instead of adding new ones.
  leftText?: string;
  planIcon?: InventoryPlanIcon;
  leftImage?: string;
  customBadge?: React.ReactNode;
}

type ListItemProps = Pick<
  React.ComponentProps<typeof ActionRow>,
  | 'testid'
  | 'onClick'
  | 'className'
  | 'href'
  | 'withoutHrefArrow'
  | 'withArrow'
  | 'icon'
>;

const ListItem = ({
  testid,
  href,
  icon,
  className,
  titlePrefix,
  titleClassPrefix,
  onClick,
  withoutHrefArrow,
  withArrow = true,
  overlay = '',
  subTitle = '',
  subTitleClassName,
  secondaryClassName,
  secondaryTextArray = [],
  disabled = false,
  planIcon,
  leftImage,
  leftText,
  footer,
  customBadge,
  ...props
}: ListItemInterface & ListItemProps): JSX.Element => {
  const [img, setImage] = useState<string>(MIIcon);
  useEffect(() => {
    if (!isEmpty(toString(leftImage))) {
      setImage(toString(leftImage));
    }
  }, [leftImage]);
  const alertProps = isObject(props.alert) ? props.alert : undefined;
  const alertText = isObject(props.alert) ? props.alert.text : props.alert;
  const badgeProps = isObject(props.badge) ? props.badge : undefined;
  const badgeText = isObject(props.badge) ? props.badge.text : props.badge;
  const titleProps = isObject(props.title) ? props.title : undefined;
  const titleText = isObject(props.title) ? props.title.text : props.title;

  return (
    <ActionRow
      testid={testid}
      className={classNames(
        {
          [classes.disabled]: disabled,
        },
        classes.listItemWrapper,
        className
      )}
      href={href}
      icon={icon}
      onClick={onClick}
      disabled={disabled}
      withoutHrefArrow={withoutHrefArrow}
      withArrow={false}
    >
      {planIcon && (
        <div className={classes.circle}>
          <FontAwesomeIcon
            icon={planIcon?.icon}
            style={{
              color: planIcon?.color,
            }}
          />
        </div>
      )}
      {leftImage && (
        <div className={classes.imagecircle}>
          <IonImg
            src={img}
            className={classes.img}
            onIonError={() => {
              setImage(MIIcon);
            }}
          />
        </div>
      )}
      {!isNil(leftText) && (
        <IonRow className={classes.leftText}>
          <Text text={leftText} variant="mipro-h3-headline" />
        </IonRow>
      )}
      <IonRow className={classes.mainIonRow}>
        {!isEmpty(overlay) && (
          <IonRow className={classes.overlayRow}>
            <Text
              text={overlay}
              className={classNames({
                [classes.shrinkText]: !isEmpty(badgeText),
              })}
              variant="list-item-overlay"
            />
          </IonRow>
        )}
        <IonRow
          className={classNames(
            {
              [classes.titleRowExtraMargin]:
                !isEmpty(badgeText) && isEmpty(overlay),
            },
            classes.titleRow
          )}
        >
          <div className={classes.titleWrapper}>
            {titlePrefix && (
              <Text
                className={classNames(titleClassPrefix)}
                text={titlePrefix}
                variant="list-item-title"
              />
            )}
            <Button
              text={titleText}
              testid="item-title"
              textVariant="list-item-title"
              variant="clear"
              className={classNames(classes.title, {
                [classes.shrinkText]: !isEmpty(badgeText) && isEmpty(overlay),
              })}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...titleProps}
            />
          </div>
        </IonRow>
        {!isEmpty(subTitle) && (
          <IonRow
            className={classNames(classes.subTitleRow, subTitleClassName)}
          >
            <Text
              text={subTitle}
              variant="list-item-subtitle"
              className={classes.desc}
            />
          </IonRow>
        )}
        {map(secondaryTextArray, (secondaryText, index) =>
          !isEmpty(secondaryText.text) || secondaryText.children ? (
            <IonRow
              className={classNames(classes.secondaryText, secondaryClassName)}
              key={`secondaryText-${index}`}
            >
              {!isEmpty(secondaryText.text) && (
                <Text
                  text={toString(secondaryText.text)}
                  variant="list-item-secondaryText"
                  className={classes.desc}
                />
              )}
              {secondaryText.children}
            </IonRow>
          ) : undefined
        )}
        {!isEmpty(alertText) && (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Alert text={alertText} {...alertProps} />
        )}
        {footer}
      </IonRow>
      <IonRow
        className={classNames({
          [classes.iconRow]:
            size(secondaryTextArray) < 2 && !isEmpty(badgeText),
        })}
      >
        {!isEmpty(badgeText) && (
          <div className={classes.badge}>
            <Badge
              testid={`${testid}-badge`}
              text={toString(badgeText)}
              type="info"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...badgeProps}
            />
          </div>
        )}
        {!isEmpty(customBadge) && (
          <div className={classes.badge}>{customBadge}</div>
        )}
        {withArrow && (
          <FontAwesomeIcon
            icon={['far', 'chevron-right']}
            className={classes.arrow}
          />
        )}
      </IonRow>
    </ActionRow>
  );
};

export default ListItem;
