import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { toString } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonAvatar, IonSkeletonText } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import type { LeaderboardItem } from 'models/LeaderBoard';
import { findIcon } from 'utils/icons';
import { ordinalNumber } from 'utils/number';
import AvatarCard from 'components/Avatar/AvatarCard';
import Text from 'components/Text/Text';
import classes from './LeaderboardCard.module.scss';

interface LeaderboardCardProps {
  testid: string;
  rank?: 1 | 2 | 3;
  item?: LeaderboardItem;
  showLoader?: boolean;
  showLeaderBoardDetailCard?: (userId: string) => void;
}

const LeaderboardCard = ({
  testid,
  item,
  rank,
  showLoader = false,
  showLeaderBoardDetailCard,
}: IonicReactProps & LeaderboardCardProps): JSX.Element => {
  const { t } = useTranslation();
  let rankText = ordinalNumber(1);
  const rankerFirstName = t('reports:future');
  let rankerLastName = t('reports:firstPlace');
  if (rank === 2) {
    rankText = ordinalNumber(rank);
    rankerLastName = t('reports:secondPlace');
  } else if (rank === 3) {
    rankText = ordinalNumber(rank);
    rankerLastName = t('reports:thirdPlace');
  }
  const name = `${toString(item?.firstName)} ${toString(item?.lastName)}`;
  return (
    <div
      role="button"
      className={classNames(classes.leaderboardCard, {
        [classes.runnerUpExperiment]: rank !== 1,
      })}
      onClick={() =>
        item && showLeaderBoardDetailCard?.(toString(item?.employeeId))
      }
      tabIndex={rank}
      onKeyUp={() => {}}
      data-testid={`${testid}-card`}
    >
      <Text
        variant="label-header-micro"
        className={classNames(classes.rank)}
        text={rankText}
      />
      <div className={classes.cardBorder}>
        <div className={classes.cardContent}>
          {item ? (
            <AvatarCard
              displayName={name}
              className={classNames(classes.circle, {
                [classes.runnerUpCircle]: rank !== 1,
              })}
              backgroundColor={classes.avatarFallback}
            />
          ) : (
            <IonAvatar
              data-testid="Avatar"
              className={classNames(classes.circle, {
                [classes.runnerUpCircle]: rank !== 1,
                [classes.userAltAvatar]: !showLoader,
              })}
            >
              {!showLoader && (
                <FontAwesomeIcon
                  className={classes.userAlt}
                  icon={findIcon('user-alt', 'fal')}
                />
              )}
            </IonAvatar>
          )}
          <div className={classes.rankerName}>
            {!showLoader ? (
              <Text
                className={classNames(classes.leaderName, {
                  [classes.firstRankerName]: rank === 1,
                  [classes.runnerUpRankerName]: rank === 2 || rank === 3,
                })}
                variant="title-info-card"
                text={item?.firstName || rankerFirstName}
              />
            ) : (
              <IonSkeletonText animated className={classes.leaderName} />
            )}
            {!showLoader ? (
              <Text
                className={classNames(classes.leaderName, {
                  [classes.firstRankerName]: rank === 1,
                  [classes.runnerUpRankerName]: rank === 2 || rank === 3,
                })}
                variant="title-info-card"
                text={item?.lastName || rankerLastName}
              />
            ) : (
              <IonSkeletonText animated className={classes.leaderName} />
            )}

            {!showLoader ? (
              <Text
                className={classNames(classes.leaderLoc, {
                  [classes.firstLeaderLoc]: rank === 1,
                })}
                variant="title-info-card"
                text={item?.miLoc || ''}
              />
            ) : (
              <IonSkeletonText animated className={classes.leaderLoc} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaderboardCard;
