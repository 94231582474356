import type { AxiosError } from 'axios';
import { isEmpty } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { doGetIsLoading, doPromiseAPI, useKeyUserId } from 'api/helpers';
import type { AgreementInformation, Customer } from 'models/Customer';
import type { QueryFlags } from 'models/Search';
import useGetCustomer from './useGetCustomer';

const getAgreementInformationQueryKey = 'customer-agreement-information';

interface UseGetAgreementInformationProps {
  miLoc: string;
  id: string;
  enabled?: boolean;
}

interface UseGetAgreementInformationResponse {
  agreementInformation?: AgreementInformation;
  customerData?: Customer;
}

const useGetAgreementInformation = ({
  miLoc,
  id,
  enabled = true,
}: UseGetAgreementInformationProps): UseGetAgreementInformationResponse &
  QueryFlags => {
  const { axios } = useAxios();
  const { getAgreementInformationAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();

  const {
    data: customerData,
    error: customerError,
    isLoading: customerLoading,
  } = useGetCustomer({
    searchType: 'customer',
    miLoc,
    id,
    enabled,
  });

  const doGetAgreementInformation = () => {
    return doPromiseAPI<AgreementInformation>(async (cancelToken) => {
      const { data } = await axios.get<AgreementInformation>(
        getAgreementInformationAPI(miLoc, id),
        { cancelToken }
      );
      return data;
    });
  };

  const response = useQuery<AgreementInformation, AxiosError>(
    createQueryKey(getAgreementInformationQueryKey, { miLoc, id }),
    doGetAgreementInformation,
    { enabled: !isEmpty(miLoc) && !isEmpty(id) && enabled }
  );

  const { data: agreementInformation, error: agreementError } = response;

  return {
    error: customerError || agreementError,
    agreementInformation,
    customerData,
    isLoading: customerLoading || doGetIsLoading(response),
  };
};

export default useGetAgreementInformation;
