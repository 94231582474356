import React from 'react';
import classNames from 'classnames';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonCol, IonRow } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import Text from 'components/Text/Text';
import classes from './WarningMessage.module.scss';

export enum WarningMessageVarianEnum {
  default = 'default',
  warning = 'warning',
  alert = 'alert',
}

interface WarningMessageProps {
  icon?: IconProp;
  title: string;
  body?: string;
  children?: React.ReactNode;
  iconClassName?: string;
  testid?: string;
  variant?: WarningMessageVarianEnum;
  hideIcon?: boolean;
}

const WarningMessage = ({
  icon = ['fas', 'exclamation-triangle'],
  title,
  body,
  children,
  className,
  iconClassName,
  testid,
  variant = WarningMessageVarianEnum.default,
  hideIcon = false,
}: WarningMessageProps & IonicReactProps): JSX.Element => (
  <IonRow
    className={classNames(className, classes.message)}
    data-testid={testid}
  >
    <IonCol className={classNames(classes[variant])}>
      {!hideIcon && (
        <FontAwesomeIcon
          className={classNames(iconClassName, classes.icon)}
          icon={icon}
        />
      )}
      <Text
        className={classes.title}
        variant="title-info-card"
        text={title}
        testid="warning-message-title"
      />
      {body && (
        <Text
          className={classes.body}
          variant="content-small"
          text={body}
          testid="warning-message-body"
        />
      )}
      {children}
    </IonCol>
  </IonRow>
);

export default WarningMessage;
