import React from 'react';
import { useTranslation } from 'react-i18next';
import { includes, isEmpty, map } from 'lodash';
import type { QueryFlags } from 'common/api/utils/useGetQueryFlags';
import Accordion from 'common/components/Accordion/Accordion';
import List from 'common/components/List/List';
import TextList from 'common/components/TextList/TextList';
import { useNodeRef } from 'common/components/utils/renderHelpers';
import { namespaces } from 'i18n/i18n.constants';
import type { SearchProductSourcesResponse } from 'ProductSearchApp/api/useFindProductSources';
import useFindProductSources from 'ProductSearchApp/api/useFindProductSources';
import type { ProductSourcesModel } from 'ProductSearchApp/models/Products';
import { supplierConnectCapableTitle } from 'ProductSearchApp/util/productSearchUtil';
import { getErrorMessage } from 'utils/helpers';
import { customizeNumberFormat } from 'utils/number';
import Button from 'components/Button/Button';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './ProductStockTab.module.scss';

interface ProductStockTabProp {
  expanded?: string;
  groupSerial: string;
  id: string;
  productId: string;
  miLoc: string;
  mfrCtlNo: string;
  onEmptyBtnClick?: () => void;
  onAccordionClick?: (expanded?: string) => void;
  testid: string;
  primarySourcesResponse: SearchProductSourcesResponse & QueryFlags;
}

interface ProductStockData {
  title: string;
  subTitle?: string;
  titlePrefix?: string;
  value?: string;
}

const transformSources = (
  sources?: ProductSourcesModel[]
): ProductStockData[] => {
  return map(sources, (item) => {
    const {
      qtyAvail,
      loc,
      locTypeDesc,
      locStoreroomNo,
      supplierConnectCapable,
      supplierName,
      supplierCity,
      supplierState,
      supplierZip,
    } = item;
    let titlePrefix = '';
    let title;
    let subTitle;
    if (includes(['Supplier', 'Default Supplier'], locTypeDesc)) {
      if (supplierConnectCapable === 'Y') {
        titlePrefix = supplierConnectCapableTitle;
      }
      title = supplierName;
      subTitle = `${loc} - ${supplierCity}, ${supplierState} ${supplierZip}`;
    } else {
      if (locStoreroomNo === '000' || !locStoreroomNo) {
        title = loc;
      } else {
        title = `${loc}-${locStoreroomNo}`;
      }
      subTitle = locTypeDesc;
    }

    return {
      title,
      subTitle,
      value: customizeNumberFormat(qtyAvail),
      titlePrefix,
    };
  });
};

const ProductStockTab = ({
  expanded,
  groupSerial,
  id,
  productId,
  miLoc,
  mfrCtlNo,
  onEmptyBtnClick,
  onAccordionClick,
  testid,
  primarySourcesResponse,
}: ProductStockTabProp): JSX.Element => {
  const { t } = useTranslation(namespaces.product);
  const { node, nodeRef } = useNodeRef();

  const {
    items: primarySources,
    error: primaryError,
    isLoading: primaryIsLoading,
    isEmptyResponse: primaryIsEmpty,
    fetchNextPage: primaryFetchNextPage,
  } = primarySourcesResponse;

  const {
    items: locationSources,
    error: locationError,
    isLoading: locationIsLoading,
    isEmptyResponse: locationIsEmpty,
    fetchNextPage: locationFetchNextPage,
  } = useFindProductSources({
    miLoc,
    id,
    productId,
    groupSerial,
    mfrCtlNo,
    enabled: !isEmpty(groupSerial) && !isEmpty(mfrCtlNo),
    allLocation: true,
    primarySource: false,
  });

  const primarySourcesData = transformSources(primarySources);
  const locationSourcesData = transformSources(locationSources);
  const globalError = primaryError && locationError;

  const interchangeButton = (
    <Button
      className={classes.interchangeBtn}
      variant="action"
      text="View Interchanges"
      testid="viewInterchanges"
      onClick={onEmptyBtnClick}
    />
  );

  const itemContent = (index: number, item: ProductStockData) => (
    <TextList
      title={item.title}
      titlePrefix={item.titlePrefix}
      subTitle={item.subTitle}
      value={item.value}
      valueVariant="mipro-product-headline"
      testid={`${testid}-item-${index}`}
    />
  );

  return (
    <div ref={nodeRef} className={classes.content}>
      {(primaryIsEmpty &&
        !primaryIsLoading &&
        locationIsEmpty &&
        !locationIsLoading) ||
      globalError ? (
        <WarningMessage
          className={classes.message}
          icon={
            globalError
              ? ['fas', 'triangle-exclamation']
              : ['far', 'info-circle']
          }
          title={globalError ? t('stockErrorTitle') : t('noStockFound')}
          body={
            globalError
              ? getErrorMessage(primaryError || locationError)
              : t('noAllLocationFound')
          }
        >
          {interchangeButton}
        </WarningMessage>
      ) : (
        <Accordion
          className={classes.accordion}
          sections={[
            {
              value: 'primary',
              title: t('primarySources'),
              content: (
                <List
                  className={classes.list}
                  data={primarySourcesData}
                  itemContent={itemContent}
                  scrollParent={node}
                  isLoading={primaryIsLoading}
                  isEmptyList={{
                    isEmptyList: primaryIsEmpty,
                    title: t('noStockFound'),
                    body: t('noPrimaryFound'),
                    children: interchangeButton,
                  }}
                  isError={{
                    isError: !!primaryError,
                    title: t('stockErrorTitle'),
                    body: getErrorMessage(primaryError),
                    children: interchangeButton,
                  }}
                  endReached={primaryFetchNextPage}
                  testid="primary-sources-list"
                />
              ),
            },
            {
              value: 'all',
              title: t('allSources'),
              content: (
                <List
                  className={classes.list}
                  data={locationSourcesData}
                  itemContent={itemContent}
                  scrollParent={node}
                  isLoading={locationIsLoading}
                  isEmptyList={{
                    isEmptyList: locationIsEmpty,
                    title: t('noStockFound'),
                    body: t('noAllLocationFound'),
                    children: interchangeButton,
                  }}
                  isError={{
                    isError: !!locationError,
                    title: t('stockErrorTitle'),
                    body: getErrorMessage(locationError),
                    children: interchangeButton,
                  }}
                  endReached={locationFetchNextPage}
                  testid="all-sources-list"
                />
              ),
            },
          ]}
          value={expanded}
          onChange={onAccordionClick}
          scrollParent={node}
          testid={testid}
        />
      )}
    </div>
  );
};

export default ProductStockTab;
