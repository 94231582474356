import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Virtuoso } from 'react-virtuoso';
import classNames from 'classnames';
import { find, isEmpty } from 'lodash';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { useDebounce } from 'use-debounce';
import useFindIndustryCodes from 'api/customer/useFindIndustryCodes';
import useUpdateIndustryCode from 'api/customer/useUpdateIndustryCode';
import type { IndustryCode } from 'models/Customer';
import { getErrorMessage } from 'utils/helpers';
import ActionRow from 'components/ActionRow/ActionRow';
import Button from 'components/Button/Button';
import Loader from 'components/Loader/Loader';
import Modal from 'components/Modal/Modal';
import Searchbar from 'components/Searchbar/Searchbar';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './IndustryCodes.module.scss';

interface SelectAlternateModalProps {
  miLoc: string;
  customerId: string;
  type: string;
  subType: string;
  value?: string;
}

const SelectAlternateModal = ({
  miLoc,
  customerId,
  isOpen,
  setIsOpen,
  type,
  subType,
  value = '',
  testid,
}: SelectAlternateModalProps &
  React.ComponentProps<typeof Modal> &
  IonicReactProps): JSX.Element => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState('1');
  const [searchQueryValue] = useDebounce(searchQuery, 300);
  const [selected, setSelected] = useState(value);

  useEffect(() => {
    if (isOpen) {
      setSelected(value);
      setSearchQuery('');
    }
  }, [value, isOpen]);

  const {
    items,
    error,
    showLoader,
    isEmptyResponse,
    enableInfiniteScroll,
    fetchNextPage,
  } = useFindIndustryCodes({
    query: searchQueryValue,
    type,
  });

  const { updateIndustryCode } = useUpdateIndustryCode({
    miLoc,
    id: customerId,
    type,
  });

  return (
    <Modal
      className={classes.modal}
      headerClassName={classes.header}
      isOpen={isOpen}
      withTitleLine={false}
      forceFullHeight
      title={t('snapshot:selectAlternateTitle', {
        type: t(`snapshot:${type}`),
      })}
      setIsOpen={setIsOpen}
      header={
        <>
          <div className={classes.searchWrapper}>
            <Searchbar
              className={classes.searchbar}
              value={searchQuery}
              setValue={setSearchQuery}
              testid="search-input"
            />
          </div>
          <div className={classes.clearAlternate}>
            <div>
              <Text
                text={t('snapshot:currentAlternateSelected', {
                  selected: selected || t('common:none'),
                })}
              />
            </div>
            <Button
              className={classNames({ [classes.disabled]: !selected })}
              variant="secondary"
              text={t('snapshot:clearAlternate', {
                type: t(`snapshot:${subType}`),
              })}
              onClick={() => setSelected('')}
              disabled={!selected}
              testid="clear-alternate-button"
            />
          </div>
        </>
      }
      footer={
        <div className={classes.footer}>
          <Button
            variant="action"
            text={t('common:apply')}
            onClick={() => {
              updateIndustryCode({
                codeValue: selected,
                ...find(items, { codeValue: selected }),
              } as IndustryCode);
              setIsOpen?.(false);
            }}
            testid="apply-button"
          />
        </div>
      }
      testid={testid}
    >
      <div className={classes.list}>
        {!error && !isEmpty(items) && (
          <Virtuoso
            className="ion-content-scroll-host"
            data={items}
            increaseViewportBy={{ top: 500, bottom: 500 }}
            endReached={enableInfiniteScroll ? fetchNextPage : undefined}
            itemContent={(index, item) => {
              const isSelected = item.codeValue === selected;
              return (
                <ActionRow
                  key={item.codeValue}
                  className={classNames(classes.item, {
                    [classes.selectedItem]: isSelected,
                  })}
                  onClick={() => setSelected(item.codeValue)}
                  testid={`item-${item.codeValue}`}
                >
                  <div className={classes.wrapper}>
                    <Text
                      className={classes.title}
                      text={item.codeValue}
                      textQuery={searchQuery}
                    />
                    <Text
                      className={classes.description}
                      textQuery={searchQuery}
                      text={item.codeDesc}
                    />
                  </div>
                </ActionRow>
              );
            }}
          />
        )}
        {!showLoader && isEmptyResponse && (
          <WarningMessage
            className={classes.warningMessage}
            icon={['far', 'info-circle']}
            title="No results for your search"
          />
        )}
        {error && (
          <WarningMessage
            className={classes.warningMessage}
            title="Error loading results"
            body={getErrorMessage(error)}
          />
        )}
        <Loader
          className={classes.loader}
          text="Loading results..."
          isOpen={showLoader}
        />
      </div>
    </Modal>
  );
};

export default SelectAlternateModal;
