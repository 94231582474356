const ALLOW = {
  // allowNotifications: true,
  // snoozeNightNotifications: false,
  // weekdayNotificationOnly: false,
  eventSchedule: [
    {
      schedule: [
        { day: 1, startTime: '00:00', endTime: '23:59' },
        { day: 2, startTime: '00:00', endTime: '23:59' },
        { day: 3, startTime: '00:00', endTime: '23:59' },
        { day: 4, startTime: '00:00', endTime: '23:59' },
        { day: 5, startTime: '00:00', endTime: '23:59' },
        { day: 6, startTime: '00:00', endTime: '23:59' },
        { day: 7, startTime: '00:00', endTime: '23:59' },
      ],
    },
  ],
};

const ALLOW_WEEKDAY = {
  // allowNotifications: true,
  // snoozeNightNotifications: false,
  // weekdayNotificationOnly: true,
  eventSchedule: [
    {
      schedule: [
        {
          day: 1,
          startTime: '00:00',
          endTime: '23:59',
        },
        {
          day: 2,
          startTime: '00:00',
          endTime: '23:59',
        },
        {
          day: 3,
          startTime: '00:00',
          endTime: '23:59',
        },
        {
          day: 4,
          startTime: '00:00',
          endTime: '23:59',
        },
        {
          day: 5,
          startTime: '00:00',
          endTime: '23:59',
        },
      ],
    },
  ],
};

const ALLOW_NIGHT = {
  // allowNotifications: true,
  // snoozeNightNotifications: true,
  // weekdayNotificationOnly: false,
  eventSchedule: [
    {
      schedule: [
        {
          day: 1,
          startTime: '07:00',
          endTime: '18:00',
        },
        {
          day: 2,
          startTime: '07:00',
          endTime: '18:00',
        },
        {
          day: 3,
          startTime: '07:00',
          endTime: '18:00',
        },
        {
          day: 4,
          startTime: '07:00',
          endTime: '18:00',
        },
        {
          day: 5,
          startTime: '07:00',
          endTime: '18:00',
        },
        {
          day: 6,
          startTime: '07:00',
          endTime: '18:00',
        },
        {
          day: 7,
          startTime: '07:00',
          endTime: '18:00',
        },
      ],
    },
  ],
};

const ALLOW_WEEKDAY_NIGHT = {
  // allowNotifications: true,
  // snoozeNightNotifications: true,
  // weekdayNotificationOnly: true,
  eventSchedule: [
    {
      schedule: [
        {
          day: 1,
          startTime: '07:00',
          endTime: '18:00',
        },
        {
          day: 2,
          startTime: '07:00',
          endTime: '18:00',
        },
        {
          day: 3,
          startTime: '07:00',
          endTime: '18:00',
        },
        {
          day: 4,
          startTime: '07:00',
          endTime: '18:00',
        },
        {
          day: 5,
          startTime: '07:00',
          endTime: '18:00',
        },
      ],
    },
  ],
};

const getEventSchedule = (
  allowNotifications: boolean,
  snoozeNightNotifications: boolean,
  weekdayNotificationOnly: boolean
) => {
  if (
    allowNotifications &&
    !snoozeNightNotifications &&
    !weekdayNotificationOnly
  ) {
    return ALLOW.eventSchedule;
  }
  if (
    allowNotifications &&
    !snoozeNightNotifications &&
    weekdayNotificationOnly
  ) {
    return ALLOW_WEEKDAY.eventSchedule;
  }
  if (
    allowNotifications &&
    snoozeNightNotifications &&
    !weekdayNotificationOnly
  ) {
    return ALLOW_NIGHT.eventSchedule;
  }
  if (
    allowNotifications &&
    snoozeNightNotifications &&
    weekdayNotificationOnly
  ) {
    return ALLOW_WEEKDAY_NIGHT.eventSchedule;
  }
  return [];
};

export default getEventSchedule;
