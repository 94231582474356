import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { split, toString } from 'lodash';
import { useIonViewWillLeave } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import { namespaces } from 'i18n/i18n.constants';
import { useToasts } from 'providers/ToastProvider';
import type { MiProFile } from 'models/Attachment';
import { ToastType } from 'models/Toast';
import {
  UNSUPPORTED_FILE_TYPE,
  mimetypeFromExtension,
} from 'utils/announcements';
import { getAttachmentProps, getFileName } from 'utils/filesUpload';
import { downloadFile } from 'utils/helpers';
import useGetAttachment from './useGetAttachment';

export const getAttachmentQueryKey = 'attachment';

interface UseGetDownloadAttachmentProps {
  domain: string;
}

interface UseGetAttachmentResponse {
  isLoading: boolean;
  startDownload: (f: MiProFile) => void;
}

const useGetDownloadAttachment = ({
  domain,
}: UseGetDownloadAttachmentProps): UseGetAttachmentResponse => {
  const { t } = useTranslation(namespaces.common);
  const [isLoading, setIsLoading] = useState(false);
  const [fileToDownload, setFileToDownload] = useState<MiProFile | undefined>();
  const [downloaded, setDownloaded] = useState(false);
  const { addToast } = useToasts();
  const { data } = useGetAttachment({
    ...getAttachmentProps({ file: fileToDownload, domain }),
    enabled: downloaded,
  });

  const startDownload = (f: MiProFile) => {
    setFileToDownload(f);
    setDownloaded(true);
  };

  useIonViewWillLeave(() => {
    setDownloaded(false);
  });

  const downloadAttachedFile = async () => {
    try {
      setIsLoading(true);
      if (data) {
        const fileName = getFileName(fileToDownload);
        const fileExtension = toString(split(fileName, '.').pop());
        const mimeType = mimetypeFromExtension(fileExtension);
        if (mimeType === UNSUPPORTED_FILE_TYPE) {
          addToast({
            type: ToastType.error,
            text: UNSUPPORTED_FILE_TYPE,
            testid: 'unsupported-file-type-error-toast',
          });
          return;
        }
        const blob = await fetch(data).then((res) => res.blob());
        await downloadFile(fileName, mimeType, blob, addToast);
      }
    } catch (e) {
      Sentry.captureException(e);
      if (e instanceof Error) {
        addToast({
          type: ToastType.error,
          text: t(`${namespaces.common}:downloadErrorToast`),
          testid: 'preview-file-error-toast',
        });
      } else {
        addToast({
          type: ToastType.error,
          text: t(`${namespaces.common}:openFileErrorToast`),
          testid: 'download-file-error-toast',
        });
      }
    } finally {
      setDownloaded?.(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (data && downloaded) {
      void (async () => {
        await downloadAttachedFile();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, downloaded]);

  return {
    startDownload,
    isLoading,
  };
};

export default useGetDownloadAttachment;
