import { useTranslation } from 'react-i18next';
import type { MutationStatus } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { namespaces } from 'i18n/i18n.constants';
import useCountPlanDB from 'InventoryApp/database/useCountPlanDB';
import type { CountGroup } from 'InventoryApp/models/InventoryPlanGroup';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import {
  doPromiseAPI,
  onErrorUpdate,
  onMutateUpdate,
  onSuccessMutation,
} from 'api/helpers';
import { ToastType } from 'models/Toast';
import { findCountGroupsQueryKey } from './useFindCountGroups';

interface RemoveCountGroupBody {
  name: string;
  countPlanId: string;
  groupId: string;
  isDeleted?: boolean;
  // DOC  if true it will mark the group as deleted on the server and can't get it back. Non Start-VMI  this should never be true
  // DOC  if false will just unassign the group from the current user.
  deleteGroupOnServer: boolean;
  groupUniqueId: string;
}

interface UseRemoveCountGroupResponse {
  status: MutationStatus;
  onRemoveCountGroup: (body: RemoveCountGroupBody) => void;
}

const useRemoveCountGroup = (): UseRemoveCountGroupResponse => {
  const { axios } = useAxios();
  const { deleteCountGroupAPI, unassignCountPlanAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const { removeCountGroups } = useCountPlanDB();
  const { t } = useTranslation(namespaces.notes);

  const doRemoveCountGroup = ({
    countPlanId,
    groupId,
    isDeleted,
    deleteGroupOnServer,
    groupUniqueId,
  }: RemoveCountGroupBody) => {
    return doPromiseAPI(async (cancelToken) => {
      if (!isDeleted) {
        if (deleteGroupOnServer) {
          await axios.delete(deleteCountGroupAPI(countPlanId), {
            cancelToken,
          });
        } else {
          await axios.post(unassignCountPlanAPI(countPlanId, groupId), {
            cancelToken,
          });
        }
      }
      await removeCountGroups([{ countPlanId, groupId: groupUniqueId }]);
    });
  };

  const { mutate, status } = useMutation(doRemoveCountGroup, {
    onMutate: ({ groupUniqueId }) =>
      onMutateUpdate<CountGroup>({
        queryClient,
        queryKey: findCountGroupsQueryKey,
        updatedItems: [
          {
            uniqueId: groupUniqueId,
          } as CountGroup,
        ],
        dataPath: 'items',
        findPredicates: [{ uniqueId: groupUniqueId }],
        isInfiniteQuery: true,
      }),
    onSuccess: (data, vars) => {
      void onSuccessMutation(queryClient, findCountGroupsQueryKey);
      addToast({
        text: t('inventory:deleteGroupCountSuccessToast', { name: vars.name }),
        testid: 'remove-group-count-toast',
      });
    },
    onError: (error, vars, context) => {
      addToast({
        type: ToastType.error,
        text: t('inventory:deleteGroupCountFailureToast'),
        testid: 'remove-group-count-error-toast',
      });
      onErrorUpdate<CountGroup>({
        queryClient,
        context,
        isInfiniteQuery: true,
      });
    },
  });

  return {
    status,
    onRemoveCountGroup: (body: RemoveCountGroupBody) => mutate(body),
  };
};

export default useRemoveCountGroup;
