import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { IonPage, IonRow, IonToolbar } from '@ionic/react';
import StoreroomsList from 'StoreroomsApp/components/StoreroomsList/StoreroomsList';
import useIssueSync from 'StoreroomsApp/hooks/useIssueSync';
import { useDebounce } from 'use-debounce';
import type { RootState } from 'store/reducers';
import Header from 'components/Header/Header';
import Searchbar from 'components/Searchbar/Searchbar';
import classes from './IssueStoreroomList.module.scss';

const IssueStoreroomList = (): JSX.Element => {
  const { miLoc = '', locationTree } = useSelector(
    (state: RootState) => state.user
  );
  const locationName = locationTree?.[miLoc].name;

  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryString] = useDebounce(searchQuery, 300);

  const doSearch = (query: string) => {
    if (!isEmpty(query)) {
      return;
    }
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };

  const onSearch = (query: string) => {
    setSearchQuery(query);
    setTimeout(() => {
      void doSearch(query);
    }, 300);
  };

  const { offlineBanner } = useIssueSync();

  return (
    <IonPage data-testid="issue-storeroom-list">
      <Header
        withBackButton
        theme="light"
        title={`${miLoc}${locationName ? ` - ${locationName}` : ''}`}
        hideHomeMenu
        testid="issue-storeroom-list"
      >
        <IonToolbar className={classes.toolbar}>
          <IonRow className={classes.searchRow}>
            <Searchbar
              className={classes.searchBar}
              value={searchQuery}
              placeholder="Storerooms..."
              setValue={(v) => setSearchQuery(v)}
              onSearch={() => onSearch(searchQuery)}
              onClear={() => setSearchQuery('')}
              testid="search-input"
            />
          </IonRow>
        </IonToolbar>
        {offlineBanner}
      </Header>
      <StoreroomsList
        className={classes.content}
        searchQuery={searchQueryString}
        isPendingIssue
      />
    </IonPage>
  );
};

export default IssueStoreroomList;
