import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';
import { toLower } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonCol, IonItem, IonRow } from '@ionic/react';
import { announcementDetailURL } from 'navigation';
import { findIcon } from 'utils/icons';
import { concatRoutes } from 'utils/navigations';
import Text from 'components/Text/Text';
import classes from './AnnouncementCard.module.scss';

interface AnnouncementCardProps {
  index: number;
  pageName: string;
  read: string;
  typeAnnouncement: string;
  postDate: string;
  linkDescription: string;
  beginingURL?: string;
  showImage?: boolean;
  className?: string;
  showReadBullet?: boolean;
}

const AnnouncementCard = ({
  index,
  pageName,
  read,
  typeAnnouncement,
  postDate,
  linkDescription,
  beginingURL = '',
  showImage = true,
  className,
  showReadBullet = false,
}: AnnouncementCardProps): JSX.Element => {
  const { url } = useRouteMatch();

  function getAnnouncementIcon(typeName: string) {
    const type = toLower(typeName.replace(/\s/g, ''));
    return findIcon(type === 'pressrelease' ? 'bullhorn' : 'file-alt');
  }

  return (
    <IonItem
      key={pageName || index}
      button
      detail={false}
      type="button"
      className={classNames(className, classes.card)}
      href={concatRoutes(url, beginingURL, announcementDetailURL(pageName))}
      routerLink={concatRoutes(
        url,
        beginingURL,
        announcementDetailURL(pageName)
      )}
    >
      <IonRow className={classes.wrapper}>
        {showReadBullet && (
          <IonCol className={classNames(classes.readCol)}>
            <div
              className={
                read === 'Y'
                  ? classNames(classes.readSpacing)
                  : classNames(classes.readDot)
              }
            />
          </IonCol>
        )}
        {showImage && (
          <IonCol className={classes.logoCol}>
            <div className={classes.typeLogo}>
              <FontAwesomeIcon
                className={classes.icon}
                icon={
                  typeAnnouncement !== undefined
                    ? getAnnouncementIcon(typeAnnouncement)
                    : findIcon('file-alt')
                }
              />
            </div>
          </IonCol>
        )}
        <IonCol>
          <div className={classes.content}>
            <div className={classes.wrapper}>
              <div className={classes.subtitle}>
                <Text
                  variant="announcement-subtitle"
                  text={typeAnnouncement}
                  testid="card-subtitle"
                />
                <Text
                  variant="announcement-subtitle"
                  text={postDate}
                  testid="card-subtitle"
                />
              </div>
            </div>
            <div className={classes.description}>
              <Text
                variant="title-info-card"
                text={linkDescription}
                testid="card-title"
                className={classNames(classes.title, {
                  [classes.titleRead]: read === 'Y',
                })}
              />
            </div>
          </div>
        </IonCol>
      </IonRow>
    </IonItem>
  );
};

export default AnnouncementCard;
