import React from 'react';
import { useTranslation } from 'react-i18next';
import { toNumber } from 'lodash';
import type { CountGroupItem } from 'InventoryApp/models/InventoryPlanGroup';
import * as yup from 'yup';
import { removeLeadingZeros } from 'utils/number';
import type { TextVariantType } from 'components/Text/Text';
import Text from 'components/Text/Text';
import classes from './OrderModal.module.scss';

export const MAX_WIDTH = 144;
export const MAX_LENGTH = 2000;
export const MAX_QUANTITY = 999999;
export const WIDTH_REGEXP = '^[0-9]{0,3}((\\.)[0-9]{0,2})?$';
export const LENGTH_REGEXP = '^[0-9]{0,4}((\\.)[0-9]{0,2})?$';
export const QUANTITY_REGEXP = '^[0-9]{0,6}$';

export interface OrderFormData {
  quantity?: string;
  width?: string;
  length?: string;
}

export interface MinMaxFormData {
  miMinQty?: string;
  miMaxQty?: string;
}

interface UseFormHelpersResponse {
  orderSchema: unknown;
  piBeltingSchema: unknown;
  minMaxSchema: unknown;
}

export const useFormHelpers = (): UseFormHelpersResponse => {
  const { t } = useTranslation();

  const orderSchema = yup.object().shape({
    quantity: yup
      .number()
      .typeError(
        t('inventory:fieldRealNumber', { field: t('common:quantity') })
      )
      .integer(t('inventory:fieldRealNumber', { field: t('common:quantity') }))
      .min(0, t('inventory:fieldMinNumber', { field: t('common:quantity') }))
      .max(
        MAX_QUANTITY,
        t('inventory:fieldMaxNumber', {
          max: MAX_QUANTITY,
          field: t('common:quantity'),
        })
      )
      .required(t('inventory:fieldRequired', { field: t('common:quantity') })),
  });

  const piBeltingSchema = yup.object().shape({
    quantity: yup
      .number()
      .typeError(
        t('inventory:fieldRealNumber', { field: t('inventory:quantity') })
      )
      .min(0, t('inventory:fieldMinNumber', { field: t('common:quantity') }))
      .max(
        MAX_QUANTITY,
        t('inventory:fieldMaxNumber', {
          max: MAX_QUANTITY,
          field: t('common:quantity'),
        })
      )
      .required(t('inventory:fieldRequired', { field: t('common:quantity') }))
      .test(
        'is-both-greater-than-zero',
        t('inventory:quantityWAndLGreaterThanZero'),
        (val, context) => {
          if (val !== undefined) {
            const p = context.parent as OrderFormData;
            if (
              (toNumber(p.width) > 0 && toNumber(p.length) <= 0) ||
              (toNumber(p.width) <= 0 && toNumber(p.length) > 0)
            ) {
              return false;
            }
          }
          return true;
        }
      ),
    width: yup
      .number()
      .typeError(
        t('inventory:fieldRealNumber', { field: t('inventory:width') })
      )
      .min(0, t('inventory:fieldMinNumber', { field: t('common:quantity') }))
      .max(
        MAX_WIDTH,
        t('inventory:fieldMaxNumber', {
          field: t('inventory:width'),
          max: MAX_WIDTH,
        })
      )
      .required(
        t('inventory:fieldRealNumber', { field: t('inventory:width') })
      ),
    length: yup
      .number()
      .typeError(
        t('inventory:fieldRealNumber', { field: t('inventory:length') })
      )
      .min(0, t('inventory:fieldMinNumber', { field: t('common:quantity') }))
      .max(
        MAX_LENGTH,
        t('inventory:fieldMaxNumber', {
          field: t('inventory:length'),
          max: MAX_LENGTH,
        })
      )
      .required(t('inventory:fieldRequired', { field: t('inventory:length') })),
  });

  const minMaxSchema = yup.object().shape({
    miMinQty: yup
      .number()
      .typeError(
        t('inventory:fieldRealNumber', {
          field: t('inventory:minQuantityTitle'),
        })
      )
      .integer(
        t('inventory:fieldRealNumber', {
          field: t('inventory:minQuantityTitle'),
        })
      )
      .min(
        0,
        t('inventory:fieldMinNumber', {
          field: t('inventory:minQuantityTitle'),
        })
      )
      .max(
        MAX_QUANTITY,
        t('inventory:fieldMaxNumber', {
          max: MAX_QUANTITY,
          field: t('inventory:minQuantityTitle'),
        })
      )
      .test(
        'is-min-greater-than-max',
        t('inventory:fieldMaxNumber', {
          field: t('inventory:minQuantityTitle'),
          max: t('inventory:maxQuantityTitle'),
        }),
        (val, context) => {
          if (val !== undefined) {
            const p = context.parent as MinMaxFormData;
            if (val > toNumber(p.miMaxQty)) {
              return false;
            }
          }
          return true;
        }
      )
      .required(
        t('inventory:fieldRequired', {
          field: t('inventory:minQuantityTitle'),
        })
      ),
    miMaxQty: yup
      .number()
      .typeError(
        t('inventory:fieldRealNumber', {
          field: t('inventory:maxQuantityTitle'),
        })
      )
      .integer(
        t('inventory:fieldRealNumber', {
          field: t('inventory:maxQuantityTitle'),
        })
      )
      .min(
        0,
        t('inventory:fieldMinNumber', {
          field: t('inventory:maxQuantityTitle'),
        })
      )
      .max(
        MAX_QUANTITY,
        t('inventory:fieldRequired', {
          max: MAX_QUANTITY,
          field: t('inventory:maxQuantityTitle'),
        })
      )
      .required(
        t('inventory:fieldRequired', {
          field: t('inventory:minQuantityTitle'),
        })
      ),
  });

  return {
    orderSchema,
    piBeltingSchema,
    minMaxSchema,
  };
};

interface DataRowProps {
  field: string;
  item?: CountGroupItem;
  isStartVMI: boolean;
  isMinMaxEdit: boolean;
  minValue?: string;
  maxValue?: string;
}

export const DataRow = ({
  field,
  item,
  isStartVMI,
  isMinMaxEdit,
  minValue,
  maxValue,
}: DataRowProps) => {
  const { t } = useTranslation();

  let variant: TextVariantType = 'mipro-report-info';
  let label;

  switch (field) {
    case 'description':
      variant = 'mipro-product-headline';
      label = item?.description;
      break;
    case 'bin':
      label = t('inventory:bin', { bin: `${item?.bin || '--'}` });
      break;
    case 'itemNo':
      label = t('inventory:itemNo', {
        itemNo: removeLeadingZeros(item?.itemNo) || '--',
      });
      break;
    case 'mino':
      label = t('inventory:mino', { mino: item?.mino || '--' });
      break;
    case 'csn':
      label = t('inventory:csn', { csn: item?.custStockNo || '--' });
      break;
    case 'upcNo':
      label = t('inventory:upcNo', { upcNo: item?.upcNo || '--' });
      break;
    case 'mfr':
      label = t('inventory:mfr', {
        mfrCtrNo: `${
          (isStartVMI ? item?.mfrCtlNo : item?.mfrName || item?.mfrCtlNo) ||
          '--'
        }`,
      });
      break;
    case 'uom':
      label = t('inventory:uom', { uom: item?.uom || '--' });
      break;
    case 'min':
      variant = isMinMaxEdit ? 'mipro-body-copy-bold' : 'mipro-report-info';
      label = t('inventory:min', { min: minValue });
      break;
    case 'max':
      variant = isMinMaxEdit ? 'mipro-body-copy-bold' : 'mipro-report-info';
      label = t('inventory:max', { max: maxValue });
      break;
    case 'lotNo':
      label = t('inventory:lotNo', { lotNo: item?.lotNo || '--' });
      break;
    case 'slabRef':
      label = t('inventory:slabRef', { slabRef: item?.slabRef || '--' });
      break;
    default:
      variant = 'mipro-report-info';
      label = '--';
  }
  return isStartVMI ? (
    <div>
      <Text className={classes.text} variant={variant} text={label || '--'} />
    </div>
  ) : (
    <Text className={classes.text} variant={variant} text={label || '--'} />
  );
};
