import { useSelector } from 'react-redux';
import type { AxiosError } from 'axios';
import { get, isEmpty, isNil } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { doPromiseAPI, useKeyUserId } from 'api/helpers';
import type { ProductGroup } from 'models/ProductGroup';
import type { QueryFlags } from 'models/Search';
import type { RootState } from 'store/reducers';

export const getProductGroupsQueryKey = 'product-groups';

interface UseGetProductGroups {
  data?: ProductGroup[];
}

const useGetProductGroups = (): UseGetProductGroups & QueryFlags => {
  const { axios } = useAxios();
  const { createQueryKey } = useKeyUserId();
  const { getProductGroups } = useAPIUrl();
  const { userInfo } = useSelector((state: RootState) => state.user);
  const userId = get(userInfo, 'userid', '');

  const doGetProductGroups = () => {
    return doPromiseAPI<ProductGroup[]>(async () => {
      const { data } = await axios.get<ProductGroup[]>(getProductGroups());
      return data;
    });
  };

  const { data, error, status, isFetching } = useQuery<
    ProductGroup[],
    AxiosError
  >(createQueryKey(getProductGroupsQueryKey), doGetProductGroups, {
    enabled: !isEmpty(userId),
  });

  return {
    data,
    error,
    isLoading: status === 'loading' || (isFetching && isNil(data)),
  };
};

export default useGetProductGroups;
