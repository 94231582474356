import React from 'react';
import { useParams } from 'react-router-dom';
import { toNumber, toString } from 'lodash';
import useAddActivityNote from 'ActivitiesApp/api/notes/useAddActivityNote';
import useDeleteActivityNote from 'ActivitiesApp/api/notes/useDeleteActivityNote';
import useGetActivityNote from 'ActivitiesApp/api/notes/useGetActivityNote';
import useUpdateActivityNote from 'ActivitiesApp/api/notes/useUpdateActivityNote';
import type { AddActivityNoteURLParams } from 'ActivitiesApp/models/ActivityDetail';
import { goToShareActivityNoteByEmail } from 'ActivitiesApp/navigation/navigationHelpers';
import useGetCustomer from 'api/customer/useGetCustomer';
import AddEditNoteForm from 'pages/Snapshots/Customers/NotesAttachments/AddEditNoteForm';

const ActivityAddEditNote = (): JSX.Element => {
  const {
    miLoc,
    id: custNo,
    historyId,
    userId,
    eventNoteId,
  } = useParams<AddActivityNoteURLParams>();

  const { data: activityNote } = useGetActivityNote({
    id: eventNoteId,
    enabled: eventNoteId !== 'add',
  });
  const { data: customerData } = useGetCustomer({
    searchType: 'customer',
    miLoc,
    id: custNo,
  });
  const { status: updateStatus, onUpdateActivityNote } = useUpdateActivityNote({
    id: eventNoteId,
    historyId,
    miLoc,
  });
  const {
    data: addData,
    status: addStatus,
    onAddActivityNote,
  } = useAddActivityNote({
    historyId,
    miLoc,
  });
  const { status: deleteStatus, deleteActivityNote } = useDeleteActivityNote();

  return (
    <AddEditNoteForm
      noteId={eventNoteId}
      customerData={customerData}
      notebook={{
        title: toString(activityNote?.title),
        text: activityNote?.text,
        publicFlag: activityNote?.publicFlag,
        attachments: activityNote?.attachments,
        userId: activityNote?.lastUpdatedUserId,
        employeeId: activityNote?.employeeId,
        userFullName: activityNote?.userFullName,
        updatedAt: activityNote?.lastUpdatedTimestamp,
      }}
      updateStatus={updateStatus}
      deleteStatus={deleteStatus}
      addStatus={addStatus}
      withToggle
      onAddNotebook={(data) => onAddActivityNote(data)}
      onUpdateNotebook={(data) => onUpdateActivityNote(data)}
      onDeleteNotebook={() => {
        deleteActivityNote({
          deleteFlag: 'Y',
          historyId: toString(historyId),
          id: toNumber(eventNoteId),
        });
      }}
      newId={addData?.ID}
      buildNoteShareHref={(newId) =>
        goToShareActivityNoteByEmail({
          miLoc,
          customerId: custNo,
          userId,
          historyId,
          eventNoteId: newId || eventNoteId,
          reset: true,
        })
      }
    />
  );
};

export default ActivityAddEditNote;
