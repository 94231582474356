import React from 'react';
import classNames from 'classnames';
import { isNil } from 'lodash';
import type {
  CheckboxChangeEventDetail,
  IonCheckboxCustomEvent,
} from '@ionic/core';
import { IonCheckbox } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import type { FirebaseCheckBoxChangeParams } from 'utils/firebaseAnalytics';
import { logFirebaseEvent } from 'utils/firebaseAnalytics';
import type { TextVariantType } from 'components/Text/Text';
import Text from 'components/Text/Text';
import classes from './CheckBox.module.scss';

interface CheckBoxProps {
  label?: string;
  children?: React.ReactNode;
  onChange?: (e: boolean) => void;
  testid: string;
  track?: boolean;
  ariaLabel?: string;
  labelTextVariant?: TextVariantType;
  isReadOnly?: boolean;
}

const CheckBox = ({
  labelPlacement = 'end',
  label,
  children,
  checked,
  name,
  testid,
  track,
  ariaLabel,
  onChange,
  disabled,
  className,
  labelTextVariant,
  isReadOnly = false,
}: CheckBoxProps &
  React.ComponentProps<typeof IonCheckbox> &
  IonicReactProps) => {
  const onChangeWithTracking = (
    e: IonCheckboxCustomEvent<CheckboxChangeEventDetail>
  ) => {
    if (track) {
      const params: FirebaseCheckBoxChangeParams = {
        testid,
        label,
        checked,
        name,
      };
      logFirebaseEvent('checkbox_change', params);
    }
    onChange?.(e.detail.checked);
  };

  return (
    <IonCheckbox
      justify="start"
      labelPlacement={labelPlacement}
      className={classNames(classes.checkbox, className, {
        [classes.readOnly]: isReadOnly,
      })}
      checked={checked}
      aria-label={ariaLabel}
      name={name}
      onIonChange={onChangeWithTracking}
      data-testid={testid}
      disabled={disabled}
      mode="md"
      onClick={(e) => {
        if (isReadOnly) {
          e.preventDefault();
        }
      }}
    >
      {!isNil(label) && (
        <Text
          className={classes.text}
          text={label}
          variant={labelTextVariant}
        />
      )}
      {!isNil(children) && children}
    </IonCheckbox>
  );
};

export default CheckBox;
