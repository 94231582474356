import { useEffect, useMemo, useState } from 'react';
import type { AxiosError } from 'axios';
import { map, size, toString } from 'lodash';
import * as Sentry from '@sentry/capacitor';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import type { QueryParamsType } from 'common/api/utils/useGetQueryFlags';
import useWorkTasksDB from 'DocumentsApp/database/useWorkTasksDB';
import type { WorkTask } from 'DocumentsApp/models/WorkTask';
import { useAxios } from 'providers/AxiosProvider';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import { useToasts } from 'providers/ToastProvider';
import {
  doConcatDataPages,
  doGetIsLoading,
  doPromiseAPI,
  getAPIHeadersV2,
  getOfflineNextPage,
  onDownloadData,
  onSuccessMutation,
  useKeyUserId,
  useMiLocOrTeamId,
} from 'api/helpers';
import type { InfiniteQueryFlags } from 'models/Search';
import { ToastType } from 'models/Toast';
import { pageSize } from 'utils/constants';

export const findWorkTasksQueryKey = 'workTasks';

interface UseFindWorkTasksProps {
  query?: string;
  limit?: number;
  enabled?: boolean;
}

interface UseFindWorkTasksResponse {
  workTasks: WorkTask[];
  loadingAPI?: boolean;
  sync: () => Promise<void>;
}

const useFindWorkTasks = ({
  query,
  enabled = true,
  limit = pageSize(),
}: UseFindWorkTasksProps): UseFindWorkTasksResponse & InfiniteQueryFlags => {
  const { axios } = useAxios();
  const { addToast } = useToasts();
  const { findWorkTasksAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { createQueryKey } = useKeyUserId();
  const { createParams, getURLParams } = useMiLocOrTeamId({
    sendTeamId: false,
  });
  const { createWorkTasks, findWorkTasks, removeWorkTasks } = useWorkTasksDB();
  const [loadingAPI, setLoadingAPI] = useState(false);
  const { isOnline } = useNetworkStatus();
  const params: QueryParamsType = {
    ...createParams(),
    fastFind: query,
    limit,
    isOnline,
  };
  const miLoc = toString(params.miLoc);

  const doFindWorkTasks = ({ pageParam = 1 }) => {
    return doPromiseAPI<WorkTask[]>(async (cancelToken) => {
      if (isOnline) {
        const { data } = await axios.get<WorkTask[]>(
          findWorkTasksAPI(getURLParams({ ...params, page: pageParam })),
          { cancelToken }
        );
        await createWorkTasks(map(data, (c) => ({ ...c, miLoc })));
        return data;
      }
      const workTasks: WorkTask[] = (await findWorkTasks(params)) || [];
      return workTasks;
    });
  };

  const response = useInfiniteQuery<WorkTask[], AxiosError>(
    createQueryKey(findWorkTasksQueryKey, params),
    doFindWorkTasks,
    {
      enabled,
      networkMode: 'always',
      getNextPageParam: getOfflineNextPage(!!isOnline),
    }
  );

  const {
    data,
    error,
    status,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = response;

  const workTasks = useMemo(() => doConcatDataPages<WorkTask>(data), [data]);

  const hasItems = size(workTasks) > 0;
  const isEmptyResponse = status === 'success' && !hasNextPage && !hasItems;
  const noMoreData = status === 'success' && !hasNextPage && hasItems;
  const showLoader = doGetIsLoading(response) || isFetchingNextPage;
  const enableInfiniteScroll = !(!hasNextPage || isFetchingNextPage);

  useEffect(() => {
    if (enabled && !isOnline) {
      void refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, isOnline]);

  return {
    fetchNextPage: async () => {
      await fetchNextPage();
    },
    refetch: async () => {
      await refetch();
    },
    sync: async () => {
      try {
        setLoadingAPI(true);
        const { syncData } = await onDownloadData<WorkTask>({
          customAxios: axios,
          getAPIUrl: findWorkTasksAPI,
          getData: (r) => r as WorkTask[],
          params: {
            miLoc,
          },
          headers: { ...getAPIHeadersV2() },
          method: 'get',
        });
        await removeWorkTasks(miLoc);
        await createWorkTasks(map(syncData, (c) => ({ ...c, miLoc })));
        void onSuccessMutation(queryClient, findWorkTasksQueryKey);
      } catch (e) {
        addToast({
          type: ToastType.error,
          text: 'There was an error while downloading Work Tasks',
          testid: 'sync-error-toast',
          duration: 0,
        });
        Sentry.captureException(e);
        throw e;
      } finally {
        setLoadingAPI(false);
      }
    },
    workTasks,
    error,
    showLoader,
    loadingAPI,
    isEmptyResponse,
    noMoreData,
    enableInfiniteScroll,
  };
};

export default useFindWorkTasks;
