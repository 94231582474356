import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { toNumber } from 'lodash';
import { getUnixTime } from 'date-fns';
import { DateFormatEnum, formatDate, parseDate } from 'utils/date';
import { findIcon } from 'utils/icons';
import type { DatePickerModalProps } from 'components/DatePickerModal/DatePickerModal';
import DatePickerModal from 'components/DatePickerModal/DatePickerModal';
import Input from 'components/Input/Input';
import classes from './DateInput.module.scss';

interface DateInputProps
  extends Omit<DatePickerModalProps, 'date' | 'isOpen' | 'value' | 'onChange'> {
  setValue?: (v: number) => void;
  showFormattedValue?: boolean;
}

const DateInput = ({
  className,
  inputRowClassName,
  name,
  label,
  placeholder,
  value: pValue,
  setValue,
  maxDate,
  minDate,
  showTimeSelect,
  error,
  onBlur,
  filterDate,
  filterTime,
  disabled,
  required,
  testid,
  parentRef,
  disableAutoScroll = true,
  showFormattedValue = true,
  showSegmentedButtons,
}: DateInputProps &
  Omit<React.ComponentProps<typeof Input>, 'setValue'>): JSX.Element => {
  const value = toNumber(pValue);
  const [isOpen, setIsOpen] = useState(false);
  const [didFocus, setDidFocus] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    value > 0 ? value : getUnixTime(new Date())
  );

  useEffect(() => {
    if (value > 0) {
      setSelectedDate(value);
    }
  }, [value]);

  let formattedValue = '';
  if (value > 0 && showFormattedValue) {
    formattedValue = formatDate(
      value,
      showTimeSelect ? DateFormatEnum.ampmTime : DateFormatEnum.fullDate
    );
  }

  const onCancel = () => {
    if (value > 0) {
      setSelectedDate(value);
    } else {
      setSelectedDate(getUnixTime(new Date()));
    }
  };

  let modalTitle = label;
  modalTitle ||= placeholder;

  return (
    <>
      <Input
        className={classNames(className, classes.input)}
        inputRowClassName={inputRowClassName}
        required={required}
        label={label}
        placeholder={placeholder}
        value={formattedValue}
        rightButton={{
          icon: showTimeSelect ? findIcon('clock') : findIcon('calendar-day'),
          onClick: () => setIsOpen(true),
          testid: 'start-date-button',
        }}
        error={error}
        onFocus={() => {
          // DOC: always open modal to avoid user entering text
          setIsOpen(true);
          // DOC: datepicker tries to focus previous focused element when dismissed
          if (didFocus) {
            setDidFocus(false);
            return;
          }
          setDidFocus(true);
        }}
        onBlur={onBlur}
        disabled={disabled}
        testid={testid}
        parentRef={parentRef}
        disableAutoScroll={disableAutoScroll}
      />
      <DatePickerModal
        name={name}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onDone={(date) => {
          setValue?.(getUnixTime(date));
          setSelectedDate(getUnixTime(date));
        }}
        onCancel={onCancel}
        date={selectedDate > 0 ? parseDate(selectedDate) : new Date()}
        maxDate={maxDate}
        minDate={minDate}
        showTimeSelect={showTimeSelect}
        showSegmentedButtons={showSegmentedButtons}
        filterDate={filterDate}
        filterTime={filterTime}
        testid={`${testid}-picker`}
        title={modalTitle}
      />
    </>
  );
};
export default DateInput;
