import type { Dictionary } from 'lodash';
import { isEmpty, isFinite, isNaN, toNumber, toString } from 'lodash';

const amounts = {
  trillion: { value: 10 ** 12, name: 't' },
  billion: { value: 10 ** 9, name: 'b' },
  million: { value: 10 ** 6, name: 'm' },
  thousand: { value: 10 ** 3, name: 'k' },
};

interface FormatNumberProps {
  number: number;
  abbreviated?: boolean;
  scale?: number;
  ignoreScale?: boolean;
  currencyType?: string;
}

export const currencyMap: Dictionary<string> = {
  USD: '$',
  MXN: 'M$',
  CAD: 'C$',
};

export const prefixCurrencyType = (output: string, currencyType?: string) => {
  const prefix = currencyType ? currencyMap[currencyType] : '';
  return prefix?.concat(output);
};

export const formatNumber = ({
  number,
  abbreviated = false,
  scale = 0,
  ignoreScale = false,
  currencyType,
}: FormatNumberProps): string => {
  let abbr = '';
  let maximumFractionDigits = scale;

  // make sure we never format a null value
  let value = number || 0;
  const abs = Math.abs(value);

  if (abbreviated) {
    if (abs >= amounts.trillion.value) {
      // trillion
      abbr = amounts.trillion.name;
      value /= amounts.trillion.value;
      maximumFractionDigits = 1;
    } else if (abs < amounts.trillion.value && abs >= amounts.billion.value) {
      // billion
      abbr = amounts.billion.name;
      value /= amounts.billion.value;
      if (Math.round(value / 10) * 10 >= 1000) {
        abbr = amounts.trillion.name;
        value /= amounts.thousand.value;
      }
      maximumFractionDigits = 1;
    } else if (abs < amounts.billion.value && abs >= amounts.million.value) {
      // million
      abbr = amounts.million.name;
      value /= amounts.million.value;
      if (Math.round(value / 10) * 10 >= 1000) {
        abbr = amounts.billion.name;
        value /= amounts.thousand.value;
      }
      maximumFractionDigits = 1;
    } else if (abs < amounts.million.value && abs >= amounts.thousand.value) {
      // thousand
      abbr = amounts.thousand.name;
      value /= amounts.thousand.value;
      if (Math.round(value / 10) * 10 >= 1000) {
        abbr = amounts.million.name;
        value /= amounts.thousand.value;
      }
    }
  }

  let output = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    ...(ignoreScale
      ? undefined
      : {
          minimumFractionDigits: scale || 0,
          maximumFractionDigits:
            scale || maximumFractionDigits < 0
              ? scale || 0
              : maximumFractionDigits,
        }),
  }).format(value);

  if (!isEmpty(currencyType)) {
    output = prefixCurrencyType(output, currencyType);
  }

  output += abbr;

  return output;
};

// DOC: separate horizontal bar symbil with thin-space
export const emptyNumber = '\u2015\u2009\u2015';

interface ScaleNumberProps {
  number?: number | string;
  scale?: number;
  padFractionalZeros?: boolean;
  useGrouping?: boolean;
  currencyType?: string;
}

// DOC: rounds number and sets significant digits
export const scaleNumber = ({
  number = 0,
  scale = 2,
  padFractionalZeros = true,
  useGrouping = false,
  currencyType = '',
}: ScaleNumberProps) => {
  const value = toNumber(number);
  if (isNaN(value) || !isFinite(value)) {
    return emptyNumber;
  }
  const result = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: padFractionalZeros ? scale : 0,
    maximumFractionDigits: scale,
    useGrouping,
  }).format(value);
  return `${toString(currencyMap[currencyType])}${result}`;
};

export const formatNotificationCount = (number: number) => {
  if (number > 999) {
    return `999+`;
  }
  return toString(number);
};

interface IsMultipleProps {
  a?: number | string;
  b?: number | string;
}

export const isMultiple = ({ a, b }: IsMultipleProps) =>
  toNumber(a) % toNumber(b) === 0;
