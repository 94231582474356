import { useEffect, useMemo } from 'react';
import type { AxiosError } from 'axios';
import { forEach, size, toString } from 'lodash';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { doConcatDataPages, doPromiseAPI, useKeyUserId } from 'api/helpers';
import type { Manufacturer } from 'models/InspectionReport';
import type { InfiniteQueryFlags } from 'models/Search';
import { pageSize } from 'utils/constants';
import { withStringProp } from 'utils/helpers';

export const findManufacturersQueryKey = 'search-manufacturers';
export const getManufacturerQueryKey = 'manufacturer';

interface UseFindManufacturersProps {
  query: string;
  limit?: number;
}

interface UseFindManufacturersResponse {
  total: number;
  items: Manufacturer[];
}

const useFindManufacturers = ({
  query,
  limit = pageSize(),
}: UseFindManufacturersProps): UseFindManufacturersResponse &
  InfiniteQueryFlags => {
  const { axios } = useAxios();
  const { findManufacturersAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { createQueryKey } = useKeyUserId();
  const params = {
    fastFind: toString(query),
    limit: toString(limit),
  };

  const doFindManufacturers = ({ pageParam = 1 }) => {
    return doPromiseAPI<Manufacturer[]>(async (cancelToken) => {
      const { data } = await axios.get<Manufacturer[]>(
        findManufacturersAPI(
          toString(
            new URLSearchParams({ ...params, page: toString(pageParam) })
          )
        ),
        { cancelToken }
      );
      return data;
    });
  };

  const response = useInfiniteQuery<Manufacturer[], AxiosError>(
    createQueryKey(findManufacturersQueryKey, params),
    doFindManufacturers,
    {
      enabled: withStringProp(query),
      getNextPageParam: (lastPage, pages) =>
        size(lastPage) < limit ? false : size(pages) + 1,
    }
  );

  const {
    data,
    error,
    status,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = response;

  const manufacturers = useMemo(
    () => doConcatDataPages<Manufacturer>(data),
    [data]
  );

  useEffect(() => {
    forEach(manufacturers, (manufacturer: Manufacturer) => {
      const id = manufacturer.mfrCtlNo;
      queryClient.setQueryData<Manufacturer>(
        createQueryKey(getManufacturerQueryKey, { id }),
        (prev) => ({
          ...(prev as Manufacturer),
          mfrCtlNo: manufacturer.mfrCtlNo,
          mfrName: manufacturer.mfrName,
        })
      );
    });
  }, [manufacturers, queryClient, createQueryKey]);

  const hasItems = size(manufacturers) > 0;
  const hasError = status === 'error';
  const isEmptyResponse = status === 'success' && !hasNextPage && !hasItems;
  const noMoreData = status === 'success' && !hasNextPage && hasItems;
  const showLoader = status === 'loading' || isFetchingNextPage;
  const enableInfiniteScroll = !(!hasNextPage || isFetchingNextPage);
  const total = size(manufacturers);

  return {
    fetchNextPage: async () => {
      await fetchNextPage();
    },
    items: manufacturers,
    total,
    error,
    hasError,
    showLoader,
    isEmptyResponse,
    noMoreData,
    enableInfiniteScroll,
  };
};

export default useFindManufacturers;
