import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { map } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonCol, IonContent, IonRow } from '@ionic/react';
import { useDebounce } from 'use-debounce';
import useFindArtAssets from 'api/inspectionReports/useFindArtAssets';
import type { ArtAsset } from 'models/InspectionReport';
import { findIcon } from 'utils/icons';
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll';
import Input from 'components/Input/Input';
import Loader from 'components/Loader/Loader';
import Text from 'components/Text/Text';
import classes from './ArtFastFind.module.scss';

export interface ArtFastFindProps {
  input: string;
  setInput: (v: string) => void;
  selectedArtAsset?: ArtAsset;
  disabled?: boolean;
  custNo: string;
  miLoc: string;
  setSelectedArtAsset: (artAsset?: ArtAsset) => void;
}

const ArtFastFind = ({
  input,
  setInput,
  custNo,
  miLoc,
  disabled,
  selectedArtAsset,
  setSelectedArtAsset,
}: ArtFastFindProps): JSX.Element => {
  const [queryString, setQueryString] = useState('');
  const [query] = useDebounce(queryString, 300);
  const findArtAssets = useFindArtAssets({ query, custNo, miLoc });

  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    setQueryString('');

    if (input.length >= 3) {
      setQueryString(input);
    }
  }, [input]);

  const showValues: () => boolean = () => {
    if (
      query.length > 0 &&
      input.length > 0 &&
      isFocused &&
      findArtAssets.items
    ) {
      return true;
    }
    return false;
  };

  return (
    <div
      className={classNames(classes.artFastFind, {
        [classes.boxFocus]: showValues(),
      })}
      data-testid="artFastFind"
    >
      <IonRow>
        <IonCol className={classes.inputCol} size="12">
          <Input
            className={classes.fastFindInput}
            value={input}
            testid="containerInput"
            disabled={disabled}
            setValue={(v: string) => {
              setInput(v);
            }}
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={() => {
              // onBlur fires before onClick below
              // making it impossible to click the child
              setTimeout(() => {
                setIsFocused(false);
              }, 150);
            }}
          />
          {(selectedArtAsset || input.length > 0) && !disabled && (
            <FontAwesomeIcon
              onClick={() => {
                setInput('');
                setSelectedArtAsset(undefined);
              }}
              className={classes.clearIcon}
              icon={findIcon('times')}
            />
          )}
        </IonCol>
        <IonCol className={classes.p0} size="12">
          {showValues() && (
            <IonContent
              className={classNames(classes.ionContent, {
                [classes.setHeight]: findArtAssets.items.length > 1,
              })}
            >
              <div className={classes.resultWrap}>
                {map(findArtAssets.items, (artAsset: ArtAsset, i) => (
                  // TODO: this should be a button
                  <div
                    onClick={() => setSelectedArtAsset(artAsset)}
                    onKeyUp={() => {}}
                    role="button"
                    tabIndex={i}
                    key={`${i}`}
                    className={classes.artAsset}
                  >
                    <IonRow className={classes.alignCenter}>
                      <IonCol>
                        <Text
                          text={artAsset.assetNo.toString()}
                          variant="content-default"
                        />
                      </IonCol>
                      <IonCol size="auto">
                        <Text
                          text={`${artAsset.miLoc}: ${artAsset.customerNo}`}
                          variant="content-default"
                        />
                      </IonCol>
                    </IonRow>
                  </div>
                ))}
              </div>
              <div className={classes.loaderWrap}>
                <Loader
                  className={classes.loader}
                  text="Loading results"
                  isOpen={findArtAssets.showLoader}
                />
                {findArtAssets.noMoreData && (
                  <div className={classes.emptyReponse}>
                    <Text
                      className={classes.titleWrap}
                      text="No more art assets!"
                      variant="content-heavy"
                    />
                  </div>
                )}
                {findArtAssets.isEmptyResponse && (
                  <div className={classes.emptyReponse}>
                    <Text
                      className={classes.titleWrap}
                      text="No art assets found!"
                      variant="content-heavy"
                    />
                  </div>
                )}
              </div>
              <InfiniteScroll
                threshold="600px"
                disabled={!findArtAssets.enableInfiniteScroll}
                onLoadMore={async () => {
                  const promises = [];
                  if (findArtAssets.enableInfiniteScroll) {
                    promises.push(findArtAssets.fetchNextPage?.call(null));
                  }
                  await Promise.all(promises);
                }}
                testid="infinite-scroll"
              />
            </IonContent>
          )}
        </IonCol>
      </IonRow>
    </div>
  );
};

export default ArtFastFind;
