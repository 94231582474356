import { Preferences } from '@capacitor/preferences';

const USER_NAME_KEY = 'username';

const UsernameStorage = {
  async get(): Promise<string | null> {
    const username = await Preferences.get({ key: USER_NAME_KEY });
    if (typeof username.value === 'string') {
      return username.value;
    }
    return null;
  },
  set(username: string): Promise<void> {
    return Preferences.set({ key: USER_NAME_KEY, value: username });
  },
  remove(): Promise<void> {
    return Preferences.remove({ key: USER_NAME_KEY });
  },
};

export default UsernameStorage;
