import { toInteger } from 'lodash';

// eslint-disable-next-line import/prefer-default-export
export const getLockAfterBackgroundedConfig = (): number | undefined => {
  const backgroundLockTime = toInteger(
    process.env.REACT_APP_VAULT_BACKGROUND_LOCK_TIME
  );

  if (process.env.REACT_APP_VAULT_BACKGROUND_LOCK === 'true') {
    if (backgroundLockTime > 0) {
      return backgroundLockTime;
    }
    // default to 2 hours (in milliseconds)
    return 2 * 60 * 60 * 1000;
  }
  // 'undefined' will disable background locking
  return undefined;
};
