import { useSelector } from 'react-redux';
import type { AxiosError } from 'axios';
import { get, isEmpty, isNil } from 'lodash';
import { useIonViewDidEnter } from '@ionic/react';
import { useQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { doPromiseAPI, useKeyUserId } from 'api/helpers';
import type { NotificationsSettings } from 'models/NotificationsSettings';
import type { QueryFlags } from 'models/Search';
import type { RootState } from 'store/reducers';

export const notificationsSettingsKey = 'notifications-settings';

export interface UseFindNotificationsSettingsResponse {
  data?: NotificationsSettings;
}

const useGetNotificationsSettings = (): UseFindNotificationsSettingsResponse &
  QueryFlags => {
  const { axios } = useAxios();
  const { notificationsSettingsAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const { userInfo } = useSelector((state: RootState) => state.user);
  const userId = get(userInfo, 'userid', '');

  const doGetNotificationsSettings = () => {
    return doPromiseAPI<NotificationsSettings>(async (cancelToken) => {
      const { data } = await axios.get<NotificationsSettings>(
        notificationsSettingsAPI(),
        { cancelToken }
      );
      return data;
    });
  };

  const { data, error, status, isFetching, refetch } = useQuery<
    NotificationsSettings,
    AxiosError
  >(
    createQueryKey(notificationsSettingsKey, { userId }),
    doGetNotificationsSettings,
    { enabled: !isEmpty(userId) }
  );

  useIonViewDidEnter(() => {
    void refetch();
  });

  return {
    data,
    error,
    isLoading: status === 'loading' || (isFetching && isNil(data)),
    refetch: async () => {
      await refetch();
    },
  };
};

export default useGetNotificationsSettings;
