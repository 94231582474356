import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { map } from 'lodash';
import { IonContent, IonPage } from '@ionic/react';
import { useTabReset } from 'providers/TabResetProvider';
import { useDebounce } from 'use-debounce';
import { useMiLocOrTeamId } from 'api/helpers';
import { SearchResultTabTypeEnum } from 'models/Search';
import { addRecentlySearched } from 'store/user';
import { SEARCH } from 'utils/constants';
import { withStringProp } from 'utils/helpers';
import { getSearchResultTabLabel } from 'utils/search';
import classes from './Search.module.scss';
import SearchAll from './SearchAll/SearchAll';
import SearchHeader from './SearchHeader/SearchHeader';
import SearchResults from './SearchResults/SearchResults';
import SearchSuggestions from './SearchSuggestions/SearchSuggestions';

const Search = (): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { createParams } = useMiLocOrTeamId({ sendTeamId: false });
  const { miLoc } = createParams();
  const [selectedTab, setSelectedTab] = useState<SearchResultTabTypeEnum>(
    SearchResultTabTypeEnum.all
  );

  const [showResults, setShowResults] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryValue] = useDebounce(searchQuery, 300);
  const { triggerResetTabMap } = useTabReset();
  const triggerResetSearchTab = triggerResetTabMap?.[SEARCH];

  const doSearch = useCallback(
    (query: string) => {
      if (!withStringProp(query)) {
        return;
      }
      setShowResults(true);
      dispatch(addRecentlySearched({ text: query }));
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    },
    [dispatch]
  );

  const onSearch = (query: string) => {
    setSearchQuery(query);
    setTimeout(() => {
      void doSearch(query);
    }, 300);
  };

  useEffect(() => {
    setSearchQuery('');
    setSelectedTab(SearchResultTabTypeEnum.all);
  }, [triggerResetSearchTab]);

  return (
    <IonPage className={classes.page} data-testid="search-page">
      <SearchHeader
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        searchQuery={searchQuery}
        setSearchQuery={(v: string) => {
          setSearchQuery(v);
          if (v) {
            setShowResults(false);
          }
        }}
        onSearch={() => onSearch(searchQuery)}
        onClearSearch={() => {
          setShowResults(false);
          setSearchQuery('');
        }}
      />
      {searchQueryValue &&
        (selectedTab === SearchResultTabTypeEnum.all || !showResults) && (
          <IonContent
            className={classes.content}
            key={`search-result-tab-${selectedTab}`}
          >
            <SearchSuggestions
              searchQuery={searchQueryValue}
              onSearch={onSearch}
              setSelectedTab={setSelectedTab}
              searchType={selectedTab}
              miLoc={miLoc}
              loadingTitle={t('search:loadingSuggest')}
            />
          </IonContent>
        )}
      {searchQueryValue &&
        showResults &&
        selectedTab !== SearchResultTabTypeEnum.all &&
        map(
          Object.keys(SearchResultTabTypeEnum),
          (key) =>
            selectedTab === key && (
              <IonContent
                className={classes.content}
                key={`search-result-tab-${key}`}
                data-testid={`search-result-tab-${key}`}
              >
                <SearchResults
                  key={key}
                  searchType={key}
                  searchQuery={searchQueryValue}
                  miLoc={miLoc}
                  title={t(getSearchResultTabLabel(key))}
                />
              </IonContent>
            )
        )}
      {!searchQueryValue &&
        map(
          Object.keys(SearchResultTabTypeEnum),
          (key) =>
            selectedTab === key && (
              <SearchAll key={key} searchType={key} miLoc={miLoc} />
            )
        )}
    </IonPage>
  );
};

export default Search;
