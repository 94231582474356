import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { filter, find, join, map, split, toNumber, toString } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonCard, IonItem, IonRow } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { endOfMonth, getTime, getUnixTime, subMonths } from 'date-fns';
import { useDebounce } from 'use-debounce';
import { FilterSectionEnum } from 'models/Filters';
import type { FilterOption } from 'models/Filters';
import { DateFormatEnum, formatDate, parseDate } from 'utils/date';
import { hasFilterApplied } from 'utils/helpers';
import { findIcon } from 'utils/icons';
import Button from 'components/Button/Button';
import CheckBox from 'components/CheckBox/CheckBox';
import DateInput from 'components/DateInput/DateInput';
import Input from 'components/Input/Input';
import type { RadioOptionsProps } from 'components/RadioButton/RadioButton';
import RadioButton from 'components/RadioButton/RadioButton';
import Text from 'components/Text/Text';
import classes from './FilterCard.module.scss';

interface FilterCardProps {
  sectionKey: string;
  label: string;
  type: FilterSectionEnum;
  selectedValue?: string;
  options?: FilterOption[];
  updateFunction?: (key: string, value: unknown) => void;
  triggerResize?: (d: number) => void;
}

const FilterCard = ({
  className,
  sectionKey,
  label,
  type,
  selectedValue,
  options,
  updateFunction,
  triggerResize,
}: FilterCardProps & IonicReactProps): JSX.Element => {
  const hasFilterValue = useMemo(
    () => hasFilterApplied({ type, selectedValue, options }),
    [options, selectedValue, type]
  );
  const dateRange = useMemo(() => split(selectedValue, '#'), [selectedValue]);
  const startDateRange = toNumber(dateRange[0] || 0);
  const endDateRange = toNumber(dateRange[1] || 0);
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState(selectedValue);
  const [searchQueryValue] = useDebounce(searchQuery, 300);

  const [startDate, setStartDate] = useState<number>(0);
  const [endDate, setEndDate] = useState<number>(0);

  const previousMonth = getTime(subMonths(new Date(), 1));

  useEffect(() => {
    triggerResize?.(Date.now());
  }, [isOpen, triggerResize]);

  useEffect(() => {
    switch (type) {
      case FilterSectionEnum.search:
        setSearchQuery(selectedValue);
        break;
      case FilterSectionEnum.dateRange:
        setStartDate(startDateRange);
        setEndDate(endDateRange);
        break;
      default:
    }
  }, [endDateRange, startDateRange, selectedValue, type, startDate]);

  useEffect(() => {
    switch (type) {
      case FilterSectionEnum.search:
        updateFunction?.(sectionKey, searchQueryValue);
        break;
      default:
    }
  }, [searchQueryValue, sectionKey, type, updateFunction]);

  return (
    <IonCard className={classNames(className, classes.filterCard)}>
      <Button
        className={classes.headerButton}
        onClick={() => setIsOpen((prev) => !prev)}
        testid="filter-section-header"
      >
        <IonRow className={classes.headerRow}>
          <FontAwesomeIcon
            className={classes.cardIcon}
            icon={findIcon(isOpen ? 'chevron-up' : 'chevron-down')}
          />
          <Text
            className={classes.label}
            text={label}
            variant="content-heavy"
          />
        </IonRow>
      </Button>
      {!isOpen && hasFilterValue && (
        <IonRow className={classes.filterButtonsWrapper}>
          {type === FilterSectionEnum.checkbox &&
            map(filter(options, { checked: true }), ({ key, text }) => (
              <IonRow key={key} className={classes.filterButton}>
                <Text variant="content-heavy" text={text} />
                <Button
                  icon={findIcon('times-circle', 'fas')}
                  onClick={() => updateFunction?.call(null, key, false)}
                  testid="remove-filter-button"
                />
              </IonRow>
            ))}
          {type === FilterSectionEnum.radiogroup && (
            <IonRow className={classes.filterButton}>
              <Text
                variant="content-heavy"
                text={toString(find(options, { key: selectedValue })?.text)}
              />
              <Button
                icon={findIcon('times-circle', 'fas')}
                onClick={() => updateFunction?.call(null, sectionKey, null)}
                testid="remove-filter-button"
              />
            </IonRow>
          )}
          {type === FilterSectionEnum.search && (
            <IonRow className={classes.filterButton}>
              <Text variant="content-heavy" text={toString(selectedValue)} />
              <Button
                icon={findIcon('times-circle', 'fas')}
                onClick={() => updateFunction?.call(null, sectionKey, null)}
                testid="remove-filter-button"
              />
            </IonRow>
          )}
          {type === FilterSectionEnum.dateRange && (
            <IonRow className={classes.filterButton}>
              <Text
                variant="content-heavy"
                text={`${
                  startDateRange > 0
                    ? formatDate(startDateRange, DateFormatEnum.fullDate)
                    : '*'
                } - ${
                  endDateRange > 0
                    ? formatDate(endDateRange, DateFormatEnum.fullDate)
                    : '*'
                }`}
              />
              <Button
                icon={findIcon('times-circle', 'fas')}
                onClick={() => updateFunction?.call(null, sectionKey, '')}
                testid="remove-filter-button"
              />
            </IonRow>
          )}
        </IonRow>
      )}
      {isOpen && (
        <div className={classes.content}>
          {type === FilterSectionEnum.checkbox &&
            map(options, ({ key, checked, text }) => (
              <IonItem
                key={key}
                className={classes.formItem}
                data-testid={`${key}-checkbox`}
              >
                <CheckBox
                  ariaLabel={text}
                  className={classes.checkbox}
                  name={key}
                  checked={checked}
                  onChange={(e) => updateFunction?.call(null, key, e)}
                  label={text}
                  testid={`filter-card-${text}`}
                />
              </IonItem>
            ))}
          {type === FilterSectionEnum.radiogroup && (
            <RadioButton
              allowEmptySelection
              testid="document-input-radio"
              value={selectedValue}
              options={options as unknown as RadioOptionsProps[]}
              valueKey="key"
              displayValueKey="text"
              onChange={(val) => updateFunction?.call(null, sectionKey, val)}
              isVerticalAlign
              className={classes.radioItem}
            />
          )}
          {type === FilterSectionEnum.search && (
            <Input
              value={searchQuery}
              setValue={setSearchQuery}
              testid={`${sectionKey}-search`}
            />
          )}
          {type === FilterSectionEnum.dateRange && (
            <div>
              <DateInput
                className={classes.dateInput}
                value={startDate > 0 ? startDate : previousMonth}
                name={`start-date-${sectionKey}`}
                setValue={(d) => {
                  const newValue = getUnixTime(parseDate(d));
                  setStartDate(newValue);
                  updateFunction?.(sectionKey, join([newValue, endDate], '#'));
                }}
                placeholder={t('startDate')}
                maxDate={
                  endDate > 0 ? parseDate(endDate) : endOfMonth(new Date())
                }
                testid="start-date-picker"
                showFormattedValue={startDate > 0}
              />
              <DateInput
                className={classes.dateInput}
                value={endDate}
                name={`end-date-${sectionKey}`}
                setValue={(d) => {
                  setEndDate(d);
                  updateFunction?.(sectionKey, join([startDate, d], '#'));
                }}
                maxDate={endOfMonth(new Date())}
                minDate={startDate > 0 ? parseDate(startDate) : undefined}
                placeholder={t('endDate')}
                testid="end-date-picker"
              />
            </div>
          )}
        </div>
      )}
    </IonCard>
  );
};
export default FilterCard;
