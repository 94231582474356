import { forEach } from 'lodash';

type Nil = undefined | null;

export const or = <T>(a: T | Nil, ...b: Array<T | Nil>) => {
  let result = a;
  forEach(b, (item) => {
    result ||= item;
  });
  return result as T;
};

export const and = <T>(a: T | Nil, ...b: Array<T | Nil>) => {
  let result = a;
  b.forEach((item) => {
    result &&= item;
  });
  return result as T;
};

export const ifRender = and<React.ReactNode>;

export const ifFunction = (b: boolean | Nil, fn?: () => void) => {
  if (b) {
    fn?.();
  }
};

export const choose = <T, F = T>(
  conditional: unknown,
  truthy: T,
  falsy?: F
) => {
  return conditional ? truthy : falsy;
};
