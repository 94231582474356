import { useMemo, useState } from 'react';
import type { AxiosError } from 'axios';
import type { Dictionary } from 'lodash';
import { map, toString, size } from 'lodash';
import * as Sentry from '@sentry/capacitor';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import { useToasts } from 'providers/ToastProvider';
import useChargeBackValueDB from 'StoreroomsApp/database/useChargeBackValueDB';
import type {
  ChargeBackValue,
  ChargeBackValueAPI,
} from 'StoreroomsApp/models/ChargeBackProfile';
import {
  doConcatDataPages,
  doGetIsLoading,
  doPromiseAPI,
  getAPIHeadersV2,
  getOfflineNextPage,
  onDownloadData,
  onSuccessMutation,
  useKeyUserId,
  useMiLocOrTeamId,
} from 'api/helpers';
import type { InfiniteQueryFlags } from 'models/Search';
import { ToastType } from 'models/Toast';
import { pageSize } from 'utils/constants';

export const findChargeBackValuesQueryKey = 'charge-back-values';

interface UseFindChargeBackValuesProps {
  query?: string;
  descriptionId?: string;
  customerNumber?: string;
  enabled?: boolean;
  limit?: number;
}

interface UseFindChargeBackValuesResponse {
  chargeBackValues?: ChargeBackValue[];
  loadingAPI?: boolean;
  sync: () => Promise<void>;
}

const useFindChargeBackValues = ({
  query,
  descriptionId,
  customerNumber,
  enabled,
  limit = pageSize(),
}: UseFindChargeBackValuesProps): InfiniteQueryFlags &
  UseFindChargeBackValuesResponse => {
  const { axios } = useAxios();
  const { addToast } = useToasts();
  const queryClient = useQueryClient();
  const { chargeBackValuesAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const { createParams } = useMiLocOrTeamId({ sendTeamId: false });
  const {
    createChargeBackvalues,
    findChargeBackValuesByMiLoc,
    removeChargeBackValues,
  } = useChargeBackValueDB();
  const [loadingAPI, setLoadingAPI] = useState(false);
  const { isOnline } = useNetworkStatus();
  const params: Dictionary<string> = {
    ...createParams(),
    query: toString(query),
    customerNumber: toString(customerNumber),
    descriptionId: toString(descriptionId),
    limit: toString(limit),
  };
  const { miLoc } = params;

  const doFindChargeBackValues = async ({ pageParam = 1 }) => {
    return doPromiseAPI<ChargeBackValue[]>(async () => {
      let profiles: ChargeBackValue[] = [];
      if (isOnline) {
        const { data: profilesData } = await axios.post<ChargeBackValueAPI[]>(
          chargeBackValuesAPI(
            toString(
              new URLSearchParams({
                ...params,
                descriptionNo: params.descriptionId,
                page: toString(pageParam),
              })
            )
          ),
          null,
          { headers: { ...getAPIHeadersV2() } }
        );
        profiles = map(profilesData, (p) => ({
          combinedId: p.combinedValidateChargeBackId,
          miLocation: p.miLoc,
          customerNumber: p.customerNo,
          descriptionNumber: p.descriptionNo,
          valueId: p.valueId,
          valueDescription: p.valueDescription,
          chargeBackGLNumber: p.chargeBackGL,
        }));
        await createChargeBackvalues(profiles);
        return profiles;
      }
      profiles =
        (await findChargeBackValuesByMiLoc(
          miLoc,
          query,
          toString(customerNumber).substring(
            0,
            size(toString(customerNumber)) - 2
          ),
          descriptionId
        )) || [];
      return profiles;
    });
  };

  const response = useInfiniteQuery<ChargeBackValue[], AxiosError>(
    createQueryKey(findChargeBackValuesQueryKey, { ...params, isOnline }),
    doFindChargeBackValues,
    {
      enabled,
      networkMode: 'always',
      getNextPageParam: getOfflineNextPage(!!isOnline),
    }
  );

  const {
    data,
    error,
    status,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = response;

  const chargeBackValues = useMemo(
    () => doConcatDataPages<ChargeBackValue>(data),
    [data]
  );

  const hasItems = size(chargeBackValues) > 0;
  const isEmptyResponse = status === 'success' && !hasNextPage && !hasItems;
  const noMoreData = status === 'success' && !hasNextPage && hasItems;
  const showLoader = doGetIsLoading(response) || isFetchingNextPage;
  const enableInfiniteScroll = !(!hasNextPage || isFetchingNextPage);

  return {
    chargeBackValues,
    error,
    showLoader,
    loadingAPI,
    isEmptyResponse,
    noMoreData,
    enableInfiniteScroll,
    fetchNextPage: async () => {
      await fetchNextPage();
    },
    refetch: async () => {
      await refetch();
    },
    sync: async () => {
      try {
        setLoadingAPI(true);
        const { syncData } = await onDownloadData<ChargeBackValueAPI>({
          customAxios: axios,
          getAPIUrl: chargeBackValuesAPI,
          getData: (r) => r as ChargeBackValueAPI[],
          params: { miLoc },
          headers: { ...getAPIHeadersV2() },
        });
        const profiles: ChargeBackValue[] = map(syncData, (p) => ({
          combinedId: p.combinedValidateChargeBackId,
          miLocation: p.miLoc,
          customerNumber: p.customerNo,
          descriptionNumber: p.descriptionNo,
          valueId: p.valueId,
          valueDescription: p.valueDescription,
          chargeBackGLNumber: p.chargeBackGL,
        }));
        await removeChargeBackValues(miLoc);
        await createChargeBackvalues(profiles);
        void onSuccessMutation(queryClient, findChargeBackValuesQueryKey);
      } catch (e) {
        addToast({
          type: ToastType.error,
          text: 'There was an error while downloading charge back values',
          testid: 'sync-error-toast',
          duration: 0,
        });
        Sentry.captureException(e);
        throw e;
      } finally {
        setLoadingAPI(false);
      }
    },
  };
};

export default useFindChargeBackValues;
