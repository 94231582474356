import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GroupedVirtuoso } from 'react-virtuoso';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { IonPage, IonContent, IonToolbar } from '@ionic/react';
import { useQueryClient } from '@tanstack/react-query';
import { endOfDay, isAfter, isToday, startOfDay, subWeeks } from 'date-fns';
import { useToasts } from 'providers/ToastProvider';
import useFindAnnouncements, {
  findAnnouncementsObjectKey,
  findAnnouncementsQueryKey,
} from 'api/announcements/useFindAnnouncements';
import useMarkAllAnnouncementsAsRead from 'api/announcements/useMarkAllAnnouncementsAsRead';
import { onErrorUpdate, onMutateUpdate } from 'api/helpers';
import type { Announcement } from 'models/Announcement';
import { pageSize } from 'utils/constants';
import { formatCardDate } from 'utils/date';
import { getErrorMessage } from 'utils/helpers';
import { findIcon } from 'utils/icons';
import type { FilterOption } from 'components/Filter/Filter';
import Filter from 'components/Filter/Filter';
import Header from 'components/Header/Header';
import HeaderActions from 'components/HeaderActions/HeaderActions';
import Loader from 'components/Loader/Loader';
import Refresher from 'components/Refresher/Refresher';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import AnnouncementCard from './AnnouncementCard';
import classes from './Announcements.module.scss';

const AnnouncementList = (): JSX.Element => {
  const [isOpenFilterModal, setIsOpenFilterModal] = useState(false);
  const [isOpenMoreModal, setIsOpenMoreModal] = useState(false);

  const [filterItem, setFilterItem] = useState<FilterOption>();

  const [readAPI, setReadAPI] = useState('all');

  const { addToast } = useToasts();
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const {
    announcements,
    error,
    fetchNextPage,
    hasError,
    showLoader,
    isEmptyResponse,
    enableInfiniteScroll,
    refetch: announcementsRefetch,
  } = useFindAnnouncements({ limit: pageSize(), read: readAPI });

  const { status, onMarkAllAsRead } = useMarkAllAnnouncementsAsRead();

  const isSubmitting = status === 'loading';

  const announcementFilters: FilterOption[] = [
    {
      name: 'Read',
      key: 'Y',
    },
    {
      name: 'Unread',
      key: 'N',
    },
  ];

  useEffect(() => {
    if (filterItem) {
      setReadAPI(filterItem.key);
    } else {
      setReadAPI('all');
    }
  }, [filterItem]);

  const markAllAsRead = async () => {
    setIsOpenMoreModal(false);

    // DOC: optimistic update before api call
    const context = await onMutateUpdate<Announcement>({
      queryClient,
      queryKey: findAnnouncementsQueryKey,
      dataPath: findAnnouncementsObjectKey,
      updateAll: true,
      updatedItems: [{ read: 'Y', READ: 'Y' } as Announcement],
      isInfiniteQuery: true,
    });

    addToast({
      text: t('announcements:announcementsReadToast'),
      button: {
        text: t('common:undo'),
        handler: () =>
          onErrorUpdate<Announcement>({
            queryClient,
            dataPath: findAnnouncementsObjectKey,
            context,
            isInfiniteQuery: true,
          }),
      },
      onClose: () => onMarkAllAsRead({ context }),
      testid: 'mark-all-as-read-success-toast',
    });
  };

  const groupCounts = useMemo((): number[] => {
    let future = 0;
    let today = 0;
    let lastTwoWeeks = 0;
    let older = 0;

    announcements.forEach((announcement) => {
      const showAfterDate = new Date(announcement.POST_DATE);

      // future group
      if (isAfter(showAfterDate, endOfDay(new Date()))) {
        future += 1;
        return;
      }

      // today group
      if (isToday(showAfterDate)) {
        today += 1;
        return;
      }

      // last two weeks group
      if (isAfter(showAfterDate, startOfDay(subWeeks(new Date(), 2)))) {
        lastTwoWeeks += 1;
        return;
      }

      // older group (fallback)
      older += 1;
    });
    return [future, today, lastTwoWeeks, older];
  }, [announcements]);

  return (
    <IonPage
      className={classes.announcementList}
      data-testid="announcement-list"
    >
      <Header title={t('announcements')} withBackButton testid="announcements">
        <IonToolbar className={classes.toolbar}>
          <div slot="start">
            <Filter
              className={classes.filter}
              selectedItems={[filterItem]}
              setFilterData={[setFilterItem]}
              initialBreakpoint={0.3}
              filterOptions={[
                {
                  title: t('announcements:announcementFilter'),
                  options: announcementFilters,
                },
              ]}
              isOpen={isOpenFilterModal}
              setIsOpen={setIsOpenFilterModal}
              testid="announcment-filters"
              toggleOnSelection
            />
          </div>
          <div slot="end">
            {filterItem?.key !== 'Y' &&
              !hasError &&
              !isEmpty(announcements) && (
                <HeaderActions
                  className={classes.headerActions}
                  title={t('announcements:manageAnnouncements')}
                  isOpen={isOpenMoreModal}
                  setIsOpen={setIsOpenMoreModal}
                  initialBreakpoint={0.3}
                  options={[
                    {
                      text: t('announcements:markAllAsRead'),
                      icon: findIcon('check-double', 'fal'),
                      onClick: markAllAsRead,
                      disabled: isSubmitting,
                      testid: 'mark-all-as-read-button',
                    },
                  ]}
                  testid="announcements-header-actions"
                />
              )}
          </div>
        </IonToolbar>
      </Header>
      <IonContent>
        <>
          <Refresher
            slot="fixed"
            hidden
            onRefresh={announcementsRefetch}
            testid="announcements-refresher"
            disabled={showLoader}
          />
          {!hasError && !isEmpty(announcements) && (
            <GroupedVirtuoso
              className={classes.list}
              increaseViewportBy={{
                top: 500,
                bottom: 500,
              }}
              endReached={enableInfiniteScroll ? fetchNextPage : undefined}
              groupCounts={groupCounts}
              groupContent={(index) => {
                return (
                  <div
                    key={index}
                    className={classNames({
                      [classes.divider]: groupCounts[index] !== 0,
                      [classes.hidden]: groupCounts[index] === 0,
                    })}
                  >
                    <>
                      {index === 0 && t('announcements:upcomingAnnounce')}
                      {index === 1 && t('announcements:todayAnnounce')}
                      {index === 2 && t('announcements:twoWeekAnnounce')}
                      {index === 3 && t('announcements:oldAnnounce')}
                    </>
                  </div>
                );
              }}
              itemContent={(index) => {
                const announcement = announcements[index];
                const creationDate = Date.parse(announcement.POST_DATE);

                return (
                  <AnnouncementCard
                    key={announcement.PAGE_NAME || index}
                    index={index}
                    pageName={announcement.PAGE_NAME}
                    read={announcement.READ}
                    typeAnnouncement={announcement.TYPE_NAME}
                    postDate={
                      isToday(creationDate)
                        ? t('common:today')
                        : formatCardDate(creationDate, false, true)
                    }
                    linkDescription={announcement.LINK_DESC}
                    showReadBullet
                    className={classes.cardOverwrite}
                  />
                );
              }}
            />
          )}
        </>
        {isEmptyResponse && (
          <WarningMessage
            className={classes.warningMessage}
            icon={['far', 'info-circle']}
            title={t('announcements:noAnnouncements')}
          />
        )}
        {hasError && (
          <WarningMessage
            className={classes.warningMessage}
            title={t('announcements:errorLoadingAnnouncements')}
            body={getErrorMessage(error)}
          />
        )}
        <Loader
          className={classes.loader}
          text={t('announcements:loadingAnnouncements')}
          isOpen={showLoader}
        />
      </IonContent>
    </IonPage>
  );
};

export default AnnouncementList;
