import type { AxiosError } from 'axios';
import { isNil } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { doPromiseAPI, useKeyUserId, useMiLocOrTeamId } from 'api/helpers';
import type { QueryFlags } from 'models/Search';
import type { Supplier, VendorIncreaseLetter } from 'models/Supplier';
import useGetSupplier from './useGetSupplier';

export const getVendorIncreaseLettersQueryKey =
  'supplier-vendor-increase-letters';

interface UseGetVendorIncreaseLettersProps {
  id: string;
}

interface UseGetVendorIncreaseLettersResponse {
  vendorIncreaseLetters?: VendorIncreaseLetter[];
  supplierData?: Supplier;
}

const useGetVendorIncreaseLetters = ({
  id,
}: UseGetVendorIncreaseLettersProps): UseGetVendorIncreaseLettersResponse &
  QueryFlags => {
  const { axios } = useAxios();
  const { getVendorIncreaseLettersAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const { createParams } = useMiLocOrTeamId({ sendTeamId: false });
  const params = { ...createParams() };
  const { miLoc } = params;

  const { data: supplierData } = useGetSupplier({ searchType: 'supplier', id });

  const doGetVendorIncreaseLetters = () => {
    return doPromiseAPI<VendorIncreaseLetter[]>(async (cancelToken) => {
      const { data } = await axios.get<VendorIncreaseLetter[]>(
        getVendorIncreaseLettersAPI(miLoc, id),
        { cancelToken }
      );
      return data;
    });
  };

  const {
    data: vendorIncreaseLetters,
    error,
    status,
    isFetching,
  } = useQuery<VendorIncreaseLetter[], AxiosError>(
    createQueryKey(getVendorIncreaseLettersQueryKey, { id }),
    doGetVendorIncreaseLetters
  );

  return {
    error,
    vendorIncreaseLetters,
    supplierData,
    isLoading:
      status === 'loading' || (isFetching && isNil(vendorIncreaseLetters)),
  };
};

export default useGetVendorIncreaseLetters;
