import { map, size, toString } from 'lodash';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import { useToasts } from 'providers/ToastProvider';
import useItemPOUDB from 'StoreroomsApp/database/useItemPOUDB';
import type { ItemPOU, ItemPOUAPI } from 'StoreroomsApp/models/ItemPOU';
import { useMiLocOrTeamId } from 'api/helpers';
import { ToastType } from 'models/Toast';

interface UseFindBarcodeItemsResponse {
  findBarcodeItems: (props: FindBarcodeItemsProps) => Promise<ItemPOU[]>;
}

interface FindBarcodeItemsProps {
  barcode: string;
  storeroomNumber?: string;
  showToast?: boolean;
  doOnlineSearch?: boolean;
}

const useFindBarcodeItems = (): UseFindBarcodeItemsResponse => {
  const { axios } = useAxios();
  const { addToast } = useToasts();
  const { itemsAPISearch } = useAPIUrl();
  const { createItemsPOU, findItemsPOUByStoreroom } = useItemPOUDB();
  const { isOnline } = useNetworkStatus();
  const { createParams } = useMiLocOrTeamId({ sendTeamId: false });
  const params = createParams();

  const findBarcodeItems = async ({
    barcode,
    storeroomNumber,
    showToast = true,
    doOnlineSearch = true,
  }: FindBarcodeItemsProps) => {
    let barcodeItems: ItemPOU[] = [];
    if (doOnlineSearch && isOnline) {
      const {
        data: { rows: itemsData },
      } = await axios.post<{ rows: ItemPOUAPI[] }>(
        itemsAPISearch(
          toString(
            new URLSearchParams({
              ...params,
              barCode: barcode,
              ...(storeroomNumber
                ? { storeRoomNumber: storeroomNumber }
                : undefined),
            })
          )
        )
      );
      barcodeItems = map(itemsData, (i) => ({
        ...i,
        combinedId: i.combinedStoreroomItemId,
      }));
      await createItemsPOU(barcodeItems);
    } else {
      barcodeItems = await findItemsPOUByStoreroom({
        miLoc: params.miLoc,
        // DOC: is storeroomNumber is a param, then don't take it from the barcode string
        storeroomNumber: storeroomNumber || barcode.slice(0, 3),
        barcode: storeroomNumber ? barcode : barcode.slice(3, barcode.length),
      });
    }
    if (size(barcodeItems) === 0 && showToast) {
      addToast({
        type: ToastType.error,
        text: `Can't find an item with the scanned barcode (${barcode})`,
        testid: 'item-scan-error-toast',
      });
    }
    return barcodeItems;
  };

  return { findBarcodeItems };
};

export default useFindBarcodeItems;
