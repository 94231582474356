import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import type { IconName, IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  IonCol,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonRow,
} from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import type { IconColor } from 'models/Legend';
import type { SnoozedData } from 'models/SnoozedData';
import { formatCardDate } from 'utils/date';
import OwnerTitle from 'components/Activities/OwnerTitle/OwnerTitle';
import SnoozeTitle from 'components/Activities/SnoozeTitle/SnoozeTitle';
import Button from 'components/Button/Button';
import CardAvatar from 'components/CardAvatar/CardAvatar';
import type { SwipeOption } from 'components/Slider/Slider';
import Slider from 'components/Slider/Slider';
import Text from 'components/Text/Text';
import classes from './ActionCard.module.scss';

interface ActionCardProps {
  text: string;
  description: string;
  aging: Date | number | string;
  snoozedData?: SnoozedData;
  icon?: IconName;
  iconColor?: IconColor | string;
  backgroundColor?: IconColor | string;
  onLongPress?: () => void;
  onMultiSelectAction?: () => void;
  avatarContainerClass?: string;
  testid: string;
  onSlideLeft?: SwipeOption;
  nonLoggedInOwnerEmpId?: string;
  ownerName?: string;
}

// TODO: reuse activity card
const DefaultActionCard = ({
  className,
  text,
  description,
  aging,
  snoozedData,
  icon,
  iconColor,
  backgroundColor,
  onLongPress,
  onMultiSelectAction,
  avatarContainerClass,
  testid,
  onSlideLeft,
  nonLoggedInOwnerEmpId,
  ownerName,
}: ActionCardProps &
  React.ComponentProps<typeof IonItem> &
  IonicReactProps): JSX.Element => {
  const { i18n } = useTranslation();

  return (
    <Slider onLongPress={onLongPress}>
      <IonItemSliding>
        <IonItem
          className={classNames(className, classes.card)}
          lines="none"
          data-testid={testid}
        >
          <IonRow className={classes.container}>
            {onMultiSelectAction && (
              <div className={classes.avatarDiv} aria-hidden="true">
                <IonCol className={classes.readCol}>
                  <div className={classes.readSpacing} />
                </IonCol>
                <IonCol>
                  <div
                    className={classNames(classes.avatar, avatarContainerClass)}
                    aria-hidden="true"
                  >
                    <CardAvatar
                      icon={icon}
                      testid={`${testid}-icon`}
                      name={icon || ''}
                      color={iconColor}
                      backgroundColor={backgroundColor}
                      transform="shrink-3"
                      onClick={onMultiSelectAction}
                    />
                  </div>
                </IonCol>
              </div>
            )}
            <IonRow className={classes.wrapper}>
              <IonCol>
                <IonRow className={classes.title}>
                  <Text
                    className={classes.text}
                    variant="title-info-card-activity"
                    text={text}
                    testid={`${testid}-text`}
                  />
                  <Text
                    className={classes.aging}
                    variant="label-micro"
                    text={formatCardDate(aging, false, true, i18n.language)}
                    testid={`${testid}-aging`}
                  />
                </IonRow>
                <IonRow className={classes.contentRow}>
                  <Text
                    className={classes.description}
                    variant="content-small"
                    text={description}
                    testid={`${testid}-description`}
                  />
                </IonRow>
                <SnoozeTitle snoozedData={snoozedData} testid={testid} />
                <OwnerTitle
                  nonLoggedInOwnerEmpId={nonLoggedInOwnerEmpId}
                  ownerName={ownerName}
                  testid="non-logged-in-employee"
                />
              </IonCol>
            </IonRow>
          </IonRow>
        </IonItem>
        <IonItemOptions side="end">
          <IonItemOption
            className={classes[onSlideLeft?.className || '']}
            onClick={onSlideLeft?.onSwipeComplete}
          >
            <Button
              icon={onSlideLeft?.icon as IconProp}
              text={onSlideLeft?.text}
              testid="slide-left"
            />
          </IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
    </Slider>
  );
};

export default DefaultActionCard;
