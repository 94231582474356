import type { RefObject } from 'react';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { toNumber, toString } from 'lodash';
import { Haptics } from '@capacitor/haptics';
import { Style as StatusBarStyle } from '@capacitor/status-bar/dist/esm/definitions';
import type { ScanResult } from '@capacitor-community/barcode-scanner';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { NativeAudio } from '@capacitor-community/native-audio';
import { Mute } from '@capgo/capacitor-mute';
import { IonFooter, IonPage, IonRow, IonToolbar } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import Header, { HeaderThemeEnum } from 'common/components/Header/Header';
import { namespaces } from 'i18n/i18n.constants';
import { useToasts } from 'providers/ToastProvider';
import { ToastType } from 'models/Toast';
import { setStatusBarTheme } from 'utils/helpers';
import { findIcon } from 'utils/icons';
import Button from 'components/Button/Button';
import Text from 'components/Text/Text';
import './Scanner.css';
import classes from './Scanner.module.scss';

interface ScannerProps {
  pageRef?: RefObject<HTMLIonContentElement>;
  onScan?: (result?: ScanResult) => Promise<string | undefined>;
  onDone?: (result?: ScanResult) => void;
  scanButton?: React.ComponentProps<typeof Button>;
  testid: string;
  triggerScan?: number;
  showCameraAcessMsg?: boolean;
}

const Scanner = ({
  className,
  pageRef,
  onScan,
  onDone,
  scanButton,
  triggerScan,
  showCameraAcessMsg = false,
  testid,
}: ScannerProps & IonicReactProps): JSX.Element => {
  const { addToast } = useToasts();
  const { t } = useTranslation(namespaces.common);
  const [scannerActive, setScannerActive] = useState(false);
  const [scanResult, setScanResult] = useState<ScanResult>();
  const [scanText, setScanText] = useState('');

  const cancelBarcodeScanner = () => {
    void BarcodeScanner.showBackground();
    void BarcodeScanner.stopScan();
    void setStatusBarTheme(StatusBarStyle.Dark);
    pageRef?.current?.classList?.remove('scanner-active-page');
    document.body.classList.remove('scanner-active');
    setScannerActive(false);
  };

  const saveBarcodeScanner = () => {
    cancelBarcodeScanner();
    onDone?.(scanResult);
  };

  const startBarcodeScanner = async () => {
    try {
      setScannerActive(true);
      setScanResult(undefined);
      setScanText('');
      const status = await BarcodeScanner.checkPermission({ force: true });
      if (
        ((status.asked && !status.granted) || status.denied) &&
        showCameraAcessMsg
      ) {
        throw new Error(t('cameraAccessMsg'));
      }
      await BarcodeScanner.hideBackground();
      pageRef?.current?.classList?.add('scanner-active-page');
      document.body.classList.add('scanner-active');
      await BarcodeScanner.startScanning({}, async (result) => {
        const barcode = toString(result.content);
        const description = await onScan?.(result);
        if (description) {
          await Haptics.vibrate();
          const isMuted = await Mute.isMuted();
          if (!isMuted.value) {
            await NativeAudio.play({ assetId: 'scanning-audio' }).catch(() => {
              // DOC do nothing with play error
            });
          }
        }
        setScanResult(result);
        setScanText(
          description
            ? `(${barcode}) ${description}`
            : `Item not found (${barcode})`
        );
      });
    } catch (e) {
      const err = e as Error;
      addToast({
        type: ToastType.error,
        text:
          err?.message === t('cameraAccessMsg')
            ? t('cameraAccessMsg')
            : t('scannerError'),
        testid: 'scanner-error-toast',
      });
      cancelBarcodeScanner();
    }
  };

  useEffect(() => {
    if (toNumber(triggerScan) > 0) {
      void startBarcodeScanner();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerScan]);

  // DOC: make sure we close the scanner
  useEffect(() => {
    return () => {
      cancelBarcodeScanner();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={className}>
      <Button
        variant="action"
        icon={findIcon('barcode-read')}
        text="Scan"
        testid={`${testid}-button`}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...scanButton}
        className={classNames(scanButton?.className, classes.scanButton)}
        onClick={(e) => {
          scanButton?.onClick?.(e);
          void startBarcodeScanner();
        }}
      />
      {scannerActive &&
        createPortal(
          <>
            <IonPage className={classes.portalWrapper}>
              <Header
                theme={HeaderThemeEnum.light}
                testid={`${testid}-header`}
                hideCartButton
                hideMenuButton
                hideBackButton
                title={t('inventory:scanBarcode')}
                className={classes.header}
              />
              <IonFooter className={classes.footer}>
                <IonRow className={classes.scanResult}>
                  <Text text={scanText || '--'} testid={`${testid}-result`} />
                </IonRow>
                <IonRow className={classes.scanUsage}>
                  <Text
                    text={t('inventory:alginBarcodeText')}
                    testid={`${testid}-usage`}
                  />
                </IonRow>
                <IonToolbar>
                  <IonRow>
                    <Button
                      className={classes.cancelButton}
                      variant="secondary"
                      text={t('common:cancel')}
                      testid={`${testid}-cancel-button`}
                      onClick={cancelBarcodeScanner}
                    />
                    <Button
                      variant="action"
                      text={t('common:confirm')}
                      testid={`${testid}-scan-button`}
                      disabled={!scanResult?.content}
                      onClick={saveBarcodeScanner}
                    />
                  </IonRow>
                </IonToolbar>
              </IonFooter>
            </IonPage>
            <div className={classes.center}>
              <div className={classes.redBox} />
            </div>
          </>,
          document.body
        )}
    </div>
  );
};

export default Scanner;
