import React from 'react';
import { IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';

interface InfiniteScrollProps {
  onLoadMore?: () => void;
  testid: string;
}

const InfiniteScroll = ({
  onLoadMore,
  threshold = '150%',
  position = 'bottom',
  disabled,
  children,
  testid,
}: InfiniteScrollProps &
  React.ComponentProps<typeof IonInfiniteScroll> &
  IonicReactProps): JSX.Element => {
  const onIonInfinite = async (e: CustomEvent) => {
    onLoadMore?.call(null);
    try {
      await (e.target as HTMLIonInfiniteScrollElement).complete();
    } catch (err) {
      void null;
    }
  };

  return (
    <IonInfiniteScroll
      threshold={threshold}
      disabled={disabled}
      onIonInfinite={onIonInfinite}
      position={position}
      data-testid={testid}
    >
      <IonInfiniteScrollContent>{children}</IonInfiniteScrollContent>
    </IonInfiniteScroll>
  );
};

export default InfiniteScroll;
