import { useTranslation } from 'react-i18next';
import { toString } from 'lodash';
import type { MutationStatus } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { namespaces } from 'i18n/i18n.constants';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import useUpdateActivity from 'api/activities/useUpdateActivity';
import { doPromiseAPI, onErrorUpdate, onSuccessMutation } from 'api/helpers';
import type { DeleteNotebookParams, Note } from 'models/Notebook';
import { ToastType } from 'models/Toast';
import { findNotesQueryKey } from './useFindNotes';

interface UseDeleteNotebookProps {
  miLoc: string;
  id: string;
  ctlNo: string;
  nbType?: string;
  historyId: number;
  userId?: string;
}

interface UseDeleteNotebookResponse {
  status: MutationStatus;
  deleteNotebook: (body: DeleteNotebookParams) => void;
}

const useDeleteNotebook = ({
  miLoc,
  id,
  ctlNo,
  nbType = 'CM',
  historyId,
  userId,
}: UseDeleteNotebookProps): UseDeleteNotebookResponse => {
  const { axios } = useAxios();
  const { deleteNotebookAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const { t } = useTranslation(namespaces.notes);

  const { onUpdateActivity } = useUpdateActivity({
    userId: toString(userId),
    historyId,
  });

  const doDeleteNotebook = (body: DeleteNotebookParams) => {
    onUpdateActivity({ historyId: body.historyId, done: body.delete === 'Y' });
    const paramsDELETE = new URLSearchParams({ delete: body.delete || 'N' });
    return doPromiseAPI(async (cancelToken) => {
      return axios.delete(
        deleteNotebookAPI(nbType, ctlNo, toString(paramsDELETE)),
        { cancelToken }
      );
    });
  };

  const { mutate, status } = useMutation(doDeleteNotebook, {
    onSuccess: async (data, vars) => {
      await onSuccessMutation(queryClient, findNotesQueryKey, {
        miLoc,
        id,
      });
      if (!vars.skipSuccessToast) {
        addToast({
          text: t('images:deleteNotebookToast'),
          button: {
            role: 'undo',
            text: t('common:undo'),
            handler: () =>
              mutate({
                skipSuccessToast: true,
                delete: 'N',
                historyId,
              }),
          },
          testid: 'delete-notebook-toast',
        });
      }
    },
    onError: () => {
      addToast({
        type: ToastType.error,
        text: t('addFailureToast'),
        testid: 'add-notebook-error-toast',
      });
      onErrorUpdate<Note>({
        queryClient,
        isArrayQuery: true,
      });
    },
  });

  return {
    status,
    deleteNotebook: (body: DeleteNotebookParams) => mutate(body),
  };
};

export default useDeleteNotebook;
