import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonRow } from '@ionic/react';
import { findIcon } from 'utils/icons';
import Button from 'components/Button/Button';
import Text from 'components/Text/Text';
import classes from './Overlay.module.scss';

interface OverlalyCardProps {
  infoHeader: string;
  detailText: string;
  onDismiss: (isOpenOverlay: boolean) => void;
}

const OverlalyCard = ({
  infoHeader,
  detailText,
  onDismiss,
}: OverlalyCardProps): JSX.Element => {
  return (
    <div className={classes.overlay}>
      <IonRow className={classes.iconRow}>
        <FontAwesomeIcon
          className={classes.icon}
          icon={findIcon('info-circle', 'fas')}
        />
      </IonRow>
      <IonRow>
        <Text
          className={classes.overlayHeaderText}
          variant="title-info-card"
          text={infoHeader}
        />
      </IonRow>
      <IonRow className={classes.msgRow}>
        <Text
          className={classes.overlayDetailText}
          variant="content-default"
          text={detailText}
        />
      </IonRow>
      <IonRow>
        <Button
          className={classes.dismissButton}
          variant="secondary"
          testid="dismiss-button"
          text="Dismiss"
          onClick={() => onDismiss?.call(null, false)}
        />
      </IonRow>
    </div>
  );
};

export default OverlalyCard;
