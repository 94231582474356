import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { filter, isEmpty, map, toString } from 'lodash';
import { Browser } from '@capacitor/browser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ContactModalProps } from 'common/components/ContactModal/ContactModal';
import ContactModal from 'common/components/ContactModal/ContactModal';
import type { InsetListData } from 'common/components/InsetList/InsetList';
import InsetList from 'common/components/InsetList/InsetList';
import type { BaseComponentProps } from 'common/components/utils/renderHelpers';
import { formatKeyValue } from 'common/utils/valueFormatter';
import { Carrier } from 'ProductSearchApp/models/Carrier';
import type {
  OcnLineStatus,
  OcnLineStatusItem,
  Order,
} from 'ProductSearchApp/models/Order';
import {
  getCarrierDisplay,
  getCarrierURLPrefix,
  getCarrierValue,
  getPODAttachments,
  getPoValue,
  getQuantityValue,
  getShipDateValue,
  getSourceValue,
  getTrackingValues,
  isUPSTracking,
} from 'ProductSearchApp/util/lineShippingHelpers';
import useGetSupplier from 'api/supplier/useGetSupplier';
import type { FileAttachmentProp } from 'models/Attachment';
import type { Customer } from 'models/Customer';
import { DateFormatEnum, formatDate } from 'utils/date';
import Button from 'components/Button/Button';
import LongPressCopyGesture from 'components/LongPressCopyGesture/LongPressCopyGesture';
import Text from 'components/Text/Text';
import classes from './OcnLineShippingDetails.module.scss';
import PODAttachment from './PODAttachment';

interface OcnLineShippingDetailsProps extends BaseComponentProps {
  type: 'customer' | 'branch' | string;
  totalCount: number;
  counter: number;
  totalQtd?: string;
  order?: Order;
  customer?: Customer;
}

const OcnLineShippingDetails = (
  props: OcnLineShippingDetailsProps &
    OcnLineStatusItem &
    Pick<OcnLineStatus, 'miLoc'>
) => {
  const { t, i18n } = useTranslation();
  const [isOpenOcnContactModal, setIsOpenOcnContactModal] =
    useState<boolean>(false);
  const [selectedOcnContact, setSelectedOcnContact] =
    useState<ContactModalProps>();
  const {
    type,
    totalCount,
    counter,
    details,
    totalQtd,
    fcdcShip,
    fcdc,
    testid = '',
    shipmentStatusDesc,
    order,
    customer,
  } = props;

  const namespace = 'productSearch:shippingDetails';
  const trackingNotAvailText = t(`${namespace}:trackingNotAvailable`);

  const carrierValue = getCarrierValue(details);
  const carrierLabel = toString(carrierValue.label);
  const poValue = getPoValue(details);
  const qtyValue = getQuantityValue(toString(totalQtd), props);
  const shipDateValue = getShipDateValue(details);
  const sourceValue = getSourceValue(props);
  const podAttachments = getPODAttachments(details) as FileAttachmentProp[];
  const trackingValues = getTrackingValues(details, trackingNotAvailText);
  const hasPodValues = !isEmpty(podAttachments);

  let title = type === 'customer' ? 'customerShipment' : 'shipmentToBranch';
  if (fcdcShip) {
    title = 'shipmentToFcdc';
  }

  const { prefixUrl, icon: carrierIcon } = getCarrierURLPrefix({
    carrierLabel,
  });

  let isUPSCarrier = false;

  const trackingDetails = map(trackingValues, (text) => {
    let href;
    if (text !== trackingNotAvailText) {
      isUPSCarrier = isUPSTracking(text);
      if (isUPSCarrier) {
        const upsCarrier = Carrier.UPS;
        return {
          href: `${upsCarrier.prefixUrl}${text}`,
          text,
        };
      }

      href = prefixUrl ? `${prefixUrl}${text}` : href;
    }
    return { text, href };
  });

  const { displayCarrierIcon, displayCarrierName } = getCarrierDisplay({
    hasPodValues,
    icon: carrierIcon,
    label: carrierLabel,
    isUPSCarrier,
  });

  const onTrackingLinkClick = (href?: string) => {
    return href
      ? async () => {
          await Browser.open({ url: href });
        }
      : undefined;
  };

  const isSupplier = sourceValue.sourceLocType === 'SP';

  // Todo : Refractor to get value from status API
  const { data } = useGetSupplier({
    id: toString(props?.sourceLoc),
    searchType: 'supplier',
    enabled: isSupplier,
  });

  const ocnLineShippingDetails: InsetListData[] = [
    {
      label: t(`${namespace}:status`),
      value: shipmentStatusDesc,
    },
    {
      className: classes.podOrTracking,
      label: t(`${namespace}:${hasPodValues ? 'pod' : 'tracking'}`),
      customValue:
        !isEmpty(trackingValues) || !isEmpty(hasPodValues) ? (
          <>
            {map(podAttachments, (pod, idx) => (
              <PODAttachment
                key={`shipping-detail-line-1-${idx}`}
                pod={pod}
                order={order}
                customer={customer}
              />
            ))}
            {!hasPodValues &&
              map(trackingDetails, ({ text, href }, idx) => (
                <div
                  key={`${text}-${idx}`}
                  className={classNames(classes.flex, classes.tracking)}
                >
                  <Button
                    textVariant="mipro-body-copy"
                    text={text}
                    isExternalLink
                    onClick={onTrackingLinkClick(href)}
                    testid={`shipping-detail-line-1-${idx}`}
                    variant={!isEmpty(href) ? 'link' : 'clear'}
                    className={classNames({
                      [classes.noTrackingStatus]: isEmpty(href),
                    })}
                  />
                  {href && (
                    <LongPressCopyGesture
                      copyableValue={text}
                      longPressDuration={1}
                    >
                      <FontAwesomeIcon icon={['fal', 'copy']} />
                    </LongPressCopyGesture>
                  )}
                </div>
              ))}
          </>
        ) : undefined,
    },
    {
      label: t(`${namespace}:shipDate`),
      value:
        shipDateValue &&
        formatDate(
          new Date(shipDateValue),
          DateFormatEnum.fullDate,
          i18n.language
        ),
    },
    {
      label: t(`${namespace}:carrier`),
      customValue: !isEmpty(displayCarrierName) && (
        <div className={classes.flex}>
          {displayCarrierIcon && (
            <img
              src={displayCarrierIcon}
              alt=""
              data-testid={`${testid}-carrier-icon`}
            />
          )}

          <Text text={displayCarrierName} variant="mipro-body-copy" />
        </div>
      ),
    },
    {
      label: t(`${namespace}:poNumber`),
      value: poValue,
    },
    {
      label: t(`${namespace}:source`),
      customValue: (
        <Button
          variant="link"
          textVariant="mipro-body-copy"
          href={
            sourceValue.sourceLocType !== 'SP' ? sourceValue?.href : undefined
          }
          text={toString(sourceValue?.label)}
          testid="shipping-detail-line-5-button"
          onClick={() => {
            if (isSupplier) {
              setSelectedOcnContact({
                name: sourceValue.label,
                titleHref: sourceValue.href,
                phoneNumber: data?.contact?.phone1,
                email: data?.contact?.email,
              });
              setIsOpenOcnContactModal(true);
            }
          }}
        />
      ),
    },
    {
      label: t(`${namespace}:qty`),
      value: t(`${namespace}:qtyOf`, {
        counterQtd: qtyValue.counterQty,
        totalQtd: qtyValue.totalQty,
      }),
    },
  ];

  const ocnShippingList = map(
    filter(ocnLineShippingDetails, (shipItem) => {
      return !isEmpty(shipItem.customValue) || !isEmpty(shipItem.value);
    }),
    (item) => {
      return {
        ...item,
        value: {
          variant: 'mipro-body-copy',
          text: formatKeyValue({ value: item.value }),
        },
        label: { variant: 'mipro-body-copy', text: item.label },
      } as InsetListData;
    }
  );

  return (
    <div className={classes.orderLineShippingSection}>
      <Text
        variant="mipro-h3-headline"
        text={t(`${namespace}:${title}`, { counter, totalCount, fcdc })}
        className={classes.title}
      />

      <InsetList data={ocnShippingList} testid="ocn-line-shpping-details" />

      <ContactModal
        isOpen={isOpenOcnContactModal}
        setIsOpen={setIsOpenOcnContactModal}
        testid="supplier-contact-modal"
        searchType="supplier"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...selectedOcnContact}
      />
    </div>
  );
};

export default OcnLineShippingDetails;
