import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import type { MutationStatus } from '@tanstack/react-query';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import type { UpdateMutationContext } from 'api/helpers';
import {
  doPromiseAPI,
  onErrorUpdate,
  onMutateUpdate,
  onSuccessMutation,
} from 'api/helpers';
import type { UnreadCount } from 'api/user/useGetUnreadNotificationsCount';
import { unreadQueryKey } from 'api/user/useGetUnreadNotificationsCount';
import type { ActionCardActivity } from 'models/ActivityModels';
import { ToastType } from 'models/Toast';
import type { RootState } from 'store/reducers';
import { findActivitiesQueryKey } from './useFindActivities';

interface MarkAllAsReadBody {
  activitiesContext?: UpdateMutationContext<ActionCardActivity>[];
  unreadContext?: UpdateMutationContext<UnreadCount>[];
}

interface UseMarkAllActivitiesAsReadResponse {
  status: MutationStatus;
  onMarkAllAsRead: (body: MarkAllAsReadBody) => void;
}

const useMarkAllActivitiesAsRead = (): UseMarkAllActivitiesAsReadResponse => {
  const { axios } = useAxios();
  const { markAllActivitiesAsReadAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { userInfo } = useSelector((state: RootState) => state.user);
  const userId = get(userInfo, 'userid', '');
  const { addToast } = useToasts();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const doMarkAllAsRead = (body: MarkAllAsReadBody) => {
    return doPromiseAPI(async (cancelToken) => {
      return axios.post(
        markAllActivitiesAsReadAPI(userId),
        {},
        { cancelToken }
      );
    });
  };
  const { t } = useTranslation();

  interface MarkAllAsReadMutationContext {
    activitiesContext: UpdateMutationContext<ActionCardActivity>[];
    unreadContext: UpdateMutationContext<UnreadCount>[];
  }

  const { mutate, status } = useMutation(doMarkAllAsRead, {
    onMutate: async () => {
      const activitiesContext = await onMutateUpdate<ActionCardActivity>({
        queryClient,
        queryKey: findActivitiesQueryKey,
        updateAll: true,
        updatedItems: [{ eventRead: 'Y' } as ActionCardActivity],
        isInfiniteQuery: true,
      });
      const unreadContext = await onMutateUpdate<UnreadCount>({
        queryClient,
        queryKey: unreadQueryKey,
        updatedItems: [{ unreadCount: 0 }],
        updateAll: true,
        isSingleQuery: true,
      });
      return { activitiesContext, unreadContext };
    },
    onSuccess: () => {
      void onSuccessMutation(queryClient, findActivitiesQueryKey);
      void onSuccessMutation(queryClient, unreadQueryKey);
      // TODO: should removeDeliveredNotifications?
    },
    onError: (_err, vars, context) => {
      const { activitiesContext, unreadContext } =
        context as MarkAllAsReadMutationContext;
      onErrorUpdate<ActionCardActivity>({
        queryClient,
        context: vars.activitiesContext || activitiesContext,
        isInfiniteQuery: true,
      });
      onErrorUpdate<UnreadCount>({
        queryClient,
        context: vars.unreadContext || unreadContext,
        isSingleQuery: true,
      });
      addToast({
        type: ToastType.error,
        text: t('activities:activitiesReadError'),
        testid: 'mark-all-as-read-error-toast',
      });
    },
  });

  return {
    status,
    onMarkAllAsRead: (body) => mutate(body),
  };
};

export default useMarkAllActivitiesAsRead;
