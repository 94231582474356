import React from 'react';
import { useTranslation } from 'react-i18next';
import { map, toString } from 'lodash';
import { IonCol, IonRow } from '@ionic/react';
import Accordion from 'common/components/Accordion/Accordion';
import InsetList from 'common/components/InsetList/InsetList';
import { useNodeRef } from 'common/components/utils/renderHelpers';
import { namespaces } from 'i18n/i18n.constants';
import type { Product } from 'ProductSearchApp/models/Products';
import type { QueryFlags } from 'models/Search';
import Text from 'components/Text/Text';
import classes from './ProductDetailTab.module.scss';

interface ProductDetailProp {
  product?: Product;
  testid: string;
  expanded?: string;
  onAccordionClick?: (expanded?: string) => void;
}

const ProductDetailTab = ({
  product,
  testid,
  expanded,
  onAccordionClick,
}: ProductDetailProp & QueryFlags): JSX.Element => {
  const { t } = useTranslation(namespaces.product);
  const { node, nodeRef } = useNodeRef();

  const specsData =
    product?.pdpAttributes &&
    map(product.pdpAttributes, (item) => {
      const val = item.value.join(', ');
      return { label: item.name, value: val };
    });
  const detailData = [
    {
      label: t('productGroupNo'),
      value: `${toString(product?.productGroupNo)} ${toString(
        product?.productGroupDesc
      )}`,
    },
    {
      label: t('edpNumber'),
      value: product?.edpNumber,
    },
    {
      label: t('upcNunber'),
      value: product?.upcNumber,
    },
    {
      label: t('itemUOM'),
      value: product?.itemUOM,
    },
    {
      label: t('casePackQty'),
      value: product?.casePackQty,
    },
    {
      label: t('casePackWeight'),
      value: product?.casePackWeight,
    },
    {
      label: t('casePackUOM'),
      value: product?.casePackUOM,
    },
  ];

  return (
    <div ref={nodeRef} className={classes.content}>
      <Accordion
        className={classes.accordion}
        sections={[
          {
            value: 'details',
            title: t('productDetail'),
            hidden: !product,
            content: (
              <div className={classes.section}>
                <IonRow>
                  <IonCol size="12">
                    <Text
                      text={toString(product?.priDescription)}
                      variant="list-item-subtitle"
                      testid="product-pri-description"
                    />
                  </IonCol>
                </IonRow>
                {product?.extendedDescr && (
                  <IonRow>
                    <IonCol size="12">
                      <Text
                        text={`${t('extendedDescription')} ${
                          product.extendedDescr
                        }`}
                        textQuery={t('extendedDescription')}
                        variant="list-item-subtitle"
                        testid="product-pri-description"
                      />
                    </IonCol>
                  </IonRow>
                )}
                <InsetList data={detailData} testid="productdetail" />
              </div>
            ),
          },
          {
            value: 'attributes',
            title: t('productAttributes'),
            hidden: !product || !product.pdpAttributes,
            content: (
              <div className={classes.section}>
                <InsetList data={specsData} testid="productattributes" />
              </div>
            ),
          },
          {
            value: 'features',
            title: t('featuresBenefits'),
            hidden: !product || !product?.featuresAndBenefits,
            content: (
              <div className={classes.section}>
                <ul>
                  {map(product?.featuresAndBenefits, (value, key) => (
                    <li key={`feature-${key}`}>
                      <IonRow>
                        <Text
                          text={value}
                          variant="list-item-subtitle"
                          testid={`label-${key}`}
                        />
                      </IonRow>
                    </li>
                  ))}
                </ul>
              </div>
            ),
          },
        ]}
        value={expanded}
        onChange={onAccordionClick}
        scrollParent={node}
        testid={testid}
      />
    </div>
  );
};

export default ProductDetailTab;
