import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { isEmpty, map } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IonContent,
  IonPage,
  IonList,
  IonItemSliding,
  IonItemOption,
  IonItemOptions,
} from '@ionic/react';
import CustomerName from 'common/components/Header/CustomerName';
import Header from 'common/components/Header/Header';
import useFindCostSavings from 'CostSavingsApp/api/useFindCostSavings';
import CostSavingsIndicator from 'CostSavingsApp/components/CostSavingsIndicator/CostSavingsIndicator';
import CostSavingsInProgressItem from 'CostSavingsApp/components/CostSavingsInProgressItem/CostSavingInProgressItem';
import { costSavingsDetailsURL, costSavingsURL, searchURL } from 'navigation';
import useGetAgreementInformation from 'api/customer/useGetAgreementInformation';
import {
  AccessControlType,
  useHasAccessControls,
} from 'hooks/useAccessControls';
import usePopulateCommitmentData from 'hooks/usePopulateCommitmentData';
import type { SnapshotsURLParams } from 'models/Search';
import { getErrorMessage } from 'utils/helpers';
import { findIcon } from 'utils/icons';
import { concatRoutes } from 'utils/navigations';
import { getIsCorpAccount } from 'utils/search';
import Button from 'components/Button/Button';
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll';
import Loader from 'components/Loader/Loader';
import Refresher from 'components/Refresher/Refresher';
import RowTable from 'components/RowTable/RowTable';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './CostSavingsInformation.module.scss';

const CostSavingsInformation = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const { miLoc, id } = useParams<SnapshotsURLParams>();

  const { customerData, agreementInformation, error, isLoading } =
    useGetAgreementInformation({
      miLoc,
      id,
    });

  const { commitmentData } = usePopulateCommitmentData(agreementInformation);
  const isCorpAccount = getIsCorpAccount(
    customerData?.customerNo,
    customerData?.natlAcctNo
  );

  const {
    costSavingsList,
    fetchNextPage,
    enableInfiniteScroll,
    isEmptyResponse,
    showLoader,
    hasError,
    error: costSavingsError,
    refetch,
    lastUpdatedAt,
  } = useFindCostSavings({
    miLoc,
    enabled: !isLoading,
    id,
    csStatus: ['I', 'P'],
    isCorpAccount,
  });

  const pendingList = costSavingsList?.filter(
    (costSavingsItem) => costSavingsItem.costSavingsStatus === 'P'
  );

  const draftList = costSavingsList?.filter(
    (costSavingsItem) => costSavingsItem.costSavingsStatus === 'I'
  );

  const { hasAccessControl } = useHasAccessControls();
  const earMode =
    agreementInformation?.costSavingsCommitment?.useApprovalRouting === 'Y';

  const showAddCostSavingsBtn = isCorpAccount
    ? hasAccessControl(
        AccessControlType.AddCostSavingsAccessControlsForCorpAccount
      )
    : hasAccessControl(AccessControlType.AddCostSavingsAccessControls);

  return (
    <IonPage
      data-testid="cost-savings-information"
      className={classes.container}
    >
      <Header
        testid="cs-information-header"
        customTitle={<CustomerName customerData={customerData} />}
      />
      <IonContent
        className={classNames(classes.content, classes.costSavingsContent)}
      >
        <Refresher
          slot="fixed"
          onRefresh={refetch}
          testid="costsaving-information"
          lastUpdatedAt={lastUpdatedAt}
          disabled={showLoader}
          hidden
        />

        <Header
          collapse="condense"
          testid="cs-information-condensed-header"
          pageTitle={{
            text: t('costSavings:costSavingsInformation'),
            className: classes.pageTitle,
          }}
          customTitle={<CustomerName customerData={customerData} />}
        />

        <Loader
          isOpen={isLoading}
          className={classes.loader}
          text={t('costSavings:loading')}
        />
        {!isLoading && !error && (
          <>
            <CostSavingsIndicator earMode={earMode} />
            {showAddCostSavingsBtn && (
              <div className={classes.createCsBtnWrapper}>
                <Button
                  variant="secondary"
                  text={t('costSavings:createCS')}
                  testid="create-cs-btn"
                  className={classes.createCsBtn}
                  leftIcon={findIcon('piggy-bank', 'far')}
                  iconClassName={classes.icon}
                  href={concatRoutes(
                    searchURL(),
                    costSavingsURL(miLoc, id, true)
                  )}
                />
              </div>
            )}
            <div className={classes.spacer} />
            <div className={classes.agreementWrapper}>
              <RowTable
                sectionTitle={t('costSavings:agreement')}
                tableData={commitmentData}
                testid="cs-information-table"
                sectionWrapperClass={classes.sectionWrapper}
                titleVariant="mipro-h3-headline"
                rowClassName={classes.rowClassName}
                valueVaraint="mipro-product-headline"
              />
            </div>
            <div className={classes.spacer} />
            <div className={classes.costSavingInProgressWrapper}>
              <div className={classes.costSavingsTitleWrapper}>
                <Text
                  text={t('costSavings:costSavingsInProgress')}
                  variant="mipro-h3-headline"
                />
              </div>
              {!isEmpty(draftList) && (
                <>
                  <Text
                    text={t('costSavings:draft')}
                    variant="mipro-h4-headline"
                  />
                  <IonList className={classes.draftList}>
                    {map(draftList, (draftListItem, index) => {
                      let draftURL = concatRoutes(
                        searchURL(),
                        costSavingsURL(
                          draftListItem.miLoc,
                          draftListItem.customerNo
                        ),
                        costSavingsDetailsURL(
                          'edit',
                          draftListItem.vasCtlNo,
                          true
                        )
                      );
                      const disabledItem =
                        !showAddCostSavingsBtn ||
                        draftListItem.isOptimisticallyUpdating;
                      if (disabledItem) {
                        draftURL = '';
                      }
                      return (
                        <IonItemSliding
                          className={classNames({
                            [classes.isUpdating]:
                              draftListItem.isOptimisticallyUpdating,
                          })}
                          key={`${draftListItem.vasCtlNo}-${index}`}
                          disabled={disabledItem}
                        >
                          <IonItemOptions side="end">
                            <IonItemOption
                              className={classes.edit}
                              onClick={() => history.push(draftURL)}
                            >
                              <FontAwesomeIcon
                                icon={findIcon('pencil', 'far')}
                              />
                            </IonItemOption>
                          </IonItemOptions>
                          <CostSavingsInProgressItem
                            vasExtPrice={draftListItem.vasExtPrice}
                            custVasDesc={draftListItem.description}
                            creationUserName={draftListItem.creationUserName}
                            createdTime={draftListItem.creationTimestamp.time}
                            href={draftURL}
                            status={t('costSavings:draft')}
                            withoutHrefArrow={!disabledItem}
                          />
                        </IonItemSliding>
                      );
                    })}
                  </IonList>
                </>
              )}
              {!isEmpty(pendingList) && (
                <>
                  <Text
                    text={t('costSavings:pending')}
                    variant="mipro-h4-headline"
                  />
                  <IonList className={classes.pendingList}>
                    {map(pendingList, (pendingListItem, index) => (
                      <CostSavingsInProgressItem
                        key={`${pendingListItem.vasCtlNo}-${index}`}
                        className={classNames({
                          [classes.isUpdating]:
                            pendingListItem.isOptimisticallyUpdating,
                        })}
                        vasExtPrice={pendingListItem.vasExtPrice}
                        custVasDesc={pendingListItem.description}
                        creationUserName={pendingListItem.creationUserName}
                        createdTime={pendingListItem.creationTimestamp.time}
                        status={t('costSavings:pending')}
                        href={concatRoutes(
                          searchURL(),
                          costSavingsURL(
                            pendingListItem.miLoc,
                            pendingListItem.customerNo
                          ),
                          costSavingsDetailsURL(
                            'view',
                            pendingListItem.vasCtlNo,
                            true
                          )
                        )}
                      />
                    ))}
                  </IonList>
                </>
              )}
              {isEmptyResponse && (
                <WarningMessage
                  icon={['far', 'info-circle']}
                  title={t('costSavings:noCostSavings')}
                />
              )}
              {hasError && (
                <WarningMessage
                  title={t('costSavings:error')}
                  body={getErrorMessage(costSavingsError)}
                />
              )}
              <Loader text={t('costSavings:loading')} isOpen={showLoader} />
              <InfiniteScroll
                onLoadMore={fetchNextPage}
                testid="cost-saving-infinite-scroll"
                disabled={!enableInfiniteScroll}
                threshold="50px"
              />
            </div>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default CostSavingsInformation;
