import React from 'react';
import classNames from 'classnames';
import { isNaN } from 'lodash';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { formatNumber } from 'common/utils/numberHelper';
import { emptyString } from 'common/utils/valueFormatter';
import Text from 'components/Text/Text';
import classes from './CurrencyFormat.module.scss';

interface CurrencyFormatProps {
  value: number;
  abbreviated?: boolean;
  prefix?: string;
  suffix?: string;
  scale?: number;
  ignoreScale?: boolean;
  display?: 'block' | 'inline' | 'inlineBlock';
  testid: string;
  currencyType?: string;
}

const CurrencyFormat = ({
  value,
  abbreviated = false,
  prefix = '',
  suffix = '',
  scale,
  ignoreScale,
  display = 'block',
  variant = 'content-default',
  className,
  testid,
  currencyType,
}: CurrencyFormatProps &
  Omit<React.ComponentProps<typeof Text>, 'text'> &
  IonicReactProps): JSX.Element => (
  <Text
    className={classNames(className, classes[display])}
    variant={variant}
    text={
      isNaN(value)
        ? emptyString
        : `${prefix}${formatNumber({
            number: value,
            abbreviated,
            scale,
            ignoreScale,
            currencyType,
          })}${suffix}`
    }
    testid={`currency-format-${testid}`}
  />
);

export default CurrencyFormat;
