import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import type { SQLiteDBConnection } from 'react-sqlite-hook';
import { get, includes, isNil, map, toString } from 'lodash';
import { Preferences } from '@capacitor/preferences';
import { IonContent, IonPage, IonRow } from '@ionic/react';
import { useQueryClient } from '@tanstack/react-query';
import useDocumentsDB from 'DocumentsApp/database/initDocumentsDB';
import { availableLanguages } from 'i18n/i18n.constants';
import useInventoryDB from 'InventoryApp/database/initInventoryDB';
import { AuthStateEvents, useAuthState } from 'providers/AuthStateProvider';
import {
  DOCUMENTS_DB_NAME,
  SCANNER_DB_NAME,
  INVENTORY_DB_NAME,
  useDevice,
} from 'providers/DeviceProvider';
import { useToasts } from 'providers/ToastProvider';
import useScannerDB from 'StoreroomsApp/database/initScannerDB';
import useRegisterNotifications from 'api/user/useRegisterNotifications';
import type { RootState } from 'store/reducers';
import {
  setAllowAddAllFromStoreroom,
  setBulletinDismissDate,
} from 'store/user';
import { downloadFile } from 'utils/helpers';
import { findIcon } from 'utils/icons';
import Button from 'components/Button/Button';
import Header from 'components/Header/Header';
import Loader from 'components/Loader/Loader';
import SheetModal from 'components/Modals/SheetModal/SheetModal';
import Select from 'components/Select/Select';
import Text from 'components/Text/Text';
import Toggle from 'components/Toggle/Toggle';
import classes from './DevTools.module.scss';

interface DBActionsProps {
  label: string;
  database: string;
  db: SQLiteDBConnection;
  openDB: () => Promise<void>;
  closeDB: () => Promise<void>;
}

const DBActions = ({
  label,
  database,
  db,
  openDB,
  closeDB,
}: DBActionsProps): JSX.Element => {
  const { addToast } = useToasts();
  const queryClient = useQueryClient();
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const downloadDatabase = async () => {
    try {
      setIsLoading(true);
      await openDB();
      const data = await db.exportToJson('full');
      await downloadFile(
        `${Date.now()}-mipro-db-${toString(database)}.json`,
        'application/json',
        JSON.stringify(data),
        addToast,
        `${label} has been downloaded.`
      );
    } catch (e) {
      // TODO
    } finally {
      await closeDB();
      setIsLoading(false);
    }
  };

  const deleteDatabase = async () => {
    try {
      setIsLoading(true);
      await openDB();
      await db.delete();
    } catch (e) {
      // TODO
    } finally {
      await closeDB();
      addToast({
        text: `${label} has been deleted.`,
        testid: 'delete-db-toast',
      });
      void queryClient.invalidateQueries();
      setIsLoading(false);
    }
  };

  return (
    <IonRow className={classes.dbActions}>
      <Loader className={classes.loader} isOpen={isLoading} />
      <Text className={classes.label} variant="content-heavy" text={label} />
      <Button
        className={classes.downloadButton}
        variant="action"
        icon={findIcon('download')}
        text="Download"
        testid="download-database-button"
        onClick={downloadDatabase}
      />
      <Button
        className={classes.deleteButton}
        variant="action"
        icon={findIcon('times')}
        text="Delete"
        testid="delete-database-button"
        onClick={() => setDeleteIsOpen(true)}
      />
      <SheetModal
        title={`Delete ${label}`}
        titleTextVariant="mipro-h3-headline"
        className={classes.discardModal}
        titleClassName={classes.discardTitle}
        contentClass={classes.discardContent}
        isOpen={deleteIsOpen}
        setIsOpen={setDeleteIsOpen}
        initialBreakpoint={0.5}
        backdropDismiss={false}
        withRightCloseButton
        testid="discard-changes-modal"
      >
        <Text
          className={classes.discardMsg}
          variant="mipro-body-copy"
          text={`Are you sure you want to delete ${label}? All offline data will be lost.`}
        />
        <Button
          variant="action"
          text="Yes"
          onClick={() => {
            setDeleteIsOpen(false);
            void deleteDatabase();
          }}
          testid="discard-primary-button"
        />
        <Button
          variant="secondary"
          text="No"
          onClick={() => setDeleteIsOpen(false)}
          testid="discard-secondary-button"
        />
      </SheetModal>
    </IonRow>
  );
};

const DevTools = (): JSX.Element => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { send } = useAuthState();
  const { deviceData, deviceLanguage } = useDevice();
  const {
    db: scannerDB,
    openDB: openScannerDB,
    closeDB: closeScannerDB,
  } = useScannerDB();
  const {
    db: documentsDB,
    openDB: openDocumentsDB,
    closeDB: closeDocumentsDB,
  } = useDocumentsDB();
  const {
    db: inventoryDB,
    openDB: openInventoryDB,
    closeDB: closeInventoryDB,
  } = useInventoryDB();
  const { fcmToken, userInfo, allowAddAllFromStoreroom } = useSelector(
    (state: RootState) => state.user
  );
  const userId = get(userInfo, 'userid', '');

  const wipeData = async () => {
    await Preferences.clear();
    const queryCache = queryClient.getQueryCache();
    queryCache.clear();
    send({ type: AuthStateEvents.LOGOUT });
  };

  const resetBulletinDismissDate = (): void => {
    dispatch(setBulletinDismissDate(undefined));
  };

  const { i18n } = useTranslation();
  const { onLogFCMRegistration } = useRegisterNotifications();

  const selectOptions = map(availableLanguages, (language) => ({
    id: language,
    text: language,
  }));

  return (
    <IonPage data-testid="devtools-page">
      <Header title="DEVELOPER TOOLS" withBackButton testid="devtools" />
      <IonContent className={classes.content}>
        <Button
          className={classes.button}
          variant="action"
          text="Wipe data"
          testid="wipe-data-button"
          onClick={wipeData}
        />
        <Button
          className={classes.button}
          variant="action"
          text="Reset announcement dismiss date"
          testid="reset-announcement-dismiss-date-button"
          onClick={resetBulletinDismissDate}
        />
        <DBActions
          label="Scanner DB"
          database={SCANNER_DB_NAME}
          db={scannerDB}
          openDB={openScannerDB}
          closeDB={closeScannerDB}
        />
        <DBActions
          label="Documents DB"
          database={DOCUMENTS_DB_NAME}
          db={documentsDB}
          openDB={openDocumentsDB}
          closeDB={closeDocumentsDB}
        />
        <DBActions
          label="Inventory DB"
          database={INVENTORY_DB_NAME}
          db={inventoryDB}
          openDB={openInventoryDB}
          closeDB={closeInventoryDB}
        />
        {includes(['local', 'dev'], process.env.REACT_APP_ENV_BUILD) && (
          <Toggle
            className={classes.toggle}
            wrapperClassName={classes.toggleWrapper}
            checked={!!allowAddAllFromStoreroom}
            lines="none"
            onClick={() =>
              dispatch(setAllowAddAllFromStoreroom(!allowAddAllFromStoreroom))
            }
            testid="allow-add-all-from-storeroom-flag"
          >
            <Button
              testid="toggle-button"
              textVariant="mipro-body-copy"
              text="Allow Add All From Storeroom"
            />
          </Toggle>
        )}
        <Select
          className={classes.button}
          label="Language"
          name="type"
          options={selectOptions}
          value={i18n.language}
          setValue={(e) => {
            void i18n.changeLanguage(e);
            if (!isNil(fcmToken)) {
              onLogFCMRegistration({
                token: fcmToken,
                device:
                  deviceData?.name ||
                  `${userId} - ${deviceData?.model || 'device'}`,
                allow: true,
                language: i18n.language || toString(deviceLanguage?.value),
              });
            }
          }}
          testid="change-language"
        />
      </IonContent>
    </IonPage>
  );
};

export default DevTools;
