import type { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { doPromiseAPI, useKeyUserId } from 'api/helpers';
import type { QueryFlags } from 'models/Search';

const pingQueryKey = 'access-control';

interface UseGetPingProps {
  refetchInterval?: number;
}

interface BuildInfoProps {
  branch: string;
  latestCommit: string;
  time: string;
}

interface UseGetPingDataProps {
  msg: string;
  buildInfo?: BuildInfoProps;
}

interface UseGetPingResponse {
  data?: UseGetPingDataProps;
  errorUpdatedAt: number;
  dataUpdatedAt: number;
}

const useGetPing = ({
  refetchInterval,
}: UseGetPingProps): UseGetPingResponse & QueryFlags => {
  const { axios } = useAxios();
  const { createQueryKey } = useKeyUserId();
  const { pingAPI } = useAPIUrl();

  const doGetPing = (): Promise<UseGetPingDataProps> => {
    return doPromiseAPI(async (cancelToken) => {
      const { data } = await axios.get<UseGetPingDataProps>(pingAPI(), {
        cancelToken,
        timeout: 5 * 1000,
      });
      return data;
    });
  };

  const { data, error, isFetching, dataUpdatedAt, errorUpdatedAt, refetch } =
    useQuery<UseGetPingDataProps, AxiosError>(
      createQueryKey(pingQueryKey),
      doGetPing,
      {
        retry: false,
        refetchOnReconnect: 'always',
        refetchInterval,
      }
    );

  return {
    data,
    error,
    isLoading: isFetching,
    refetch: async () => {
      await refetch();
    },
    dataUpdatedAt,
    errorUpdatedAt,
  };
};

export default useGetPing;
