import type { AxiosError } from 'axios';
import type { Dictionary } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import useReplenishmentDB from 'StoreroomsApp/database/useReplenishmentDB';
import type { Replenishment } from 'StoreroomsApp/models/Replenishment';
import {
  doGetIsLoading,
  doPromiseAPI,
  useKeyUserId,
  useMiLocOrTeamId,
} from 'api/helpers';
import type { QueryFlags } from 'models/Search';

export const findOpenReplenishmentQueryKey = 'open-replenishment';

interface UseFindOpenReplenishmentResponse {
  replenishment?: Replenishment;
}

const useFindOpenReplenishment = (): QueryFlags &
  UseFindOpenReplenishmentResponse => {
  const { userId, createQueryKey } = useKeyUserId();
  const { createParams } = useMiLocOrTeamId({ sendTeamId: false });
  const { getOpenReplenishment } = useReplenishmentDB();
  const params: Dictionary<string> = {
    ...createParams(),
  };
  const { miLoc } = params;

  const doFindOpenReplenishment = async () => {
    return doPromiseAPI<Replenishment>(async () => {
      return getOpenReplenishment({ miLoc, userId });
    });
  };

  const response = useQuery<Replenishment, AxiosError>(
    createQueryKey(findOpenReplenishmentQueryKey, params),
    doFindOpenReplenishment,
    { networkMode: 'always' }
  );

  const { data, error, refetch } = response;

  return {
    replenishment: data,
    error,
    isLoading: doGetIsLoading(response),
    refetch: async () => {
      await refetch();
    },
  };
};

export default useFindOpenReplenishment;
