import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import type { SortingRule } from 'react-table';
import {
  map,
  toString,
  type Dictionary,
  find,
  debounce,
  head,
  isEmpty,
  includes,
  isNil,
  size,
  toNumber,
  split,
} from 'lodash';
import { IonContent, IonPage, useIonViewDidEnter } from '@ionic/react';
import Header from 'common/components/Header/Header';
import { fromUnixTime, isToday } from 'date-fns';
import {
  ProductGroupKey,
  TabSalesPerformanceKey,
  useReportsConfig,
} from 'providers/ReportsProvider';
import type { SalesReportRow } from 'ReportsApp/api/useGetSalesDashboard';
import useGetSalesDashboard from 'ReportsApp/api/useGetSalesDashboard';
import GroupBy from 'ReportsApp/components/GroupBy/GroupBy';
import ReportError from 'ReportsApp/components/ReportError/ReportError';
import useGetFixedHeader from 'ReportsApp/hooks/useGetFixedHeader';
import useGetGroupBy from 'ReportsApp/hooks/useGetGroupBy';
import useReportHeader from 'ReportsApp/hooks/useReportHeader';
import { type BaseReportURLParams } from 'ReportsApp/models';
import { goToEndOfMonthReport } from 'ReportsApp/navigation/navigationHelpers';
import { useDebounce } from 'use-debounce';
import useGetCalendarDays from 'api/salesReports/useGetCalendarDays';
import { SortDirEnum } from 'models/Sort';
import {
  getBusinessDays,
  getHolidays,
  useGetSharedBusinessDayLabel,
} from 'utils/date';
import {
  locationSortOptions,
  nameSortOptions,
} from 'pages/Reports/DrillDown/sortOptions';
import EndOfMonthDashboard from 'assets/EndOfMonthDashboard.svg';
import ActionRow from 'components/ActionRow/ActionRow';
import Button from 'components/Button/Button';
import DateToolbar from 'components/DateToolbar/DateToolbar';
import type { FilterOption } from 'components/Filter/Filter';
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll';
import Loader from 'components/Loader/Loader';
import Refresher from 'components/Refresher/Refresher';
import Table from 'components/Table/Table';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './SalesPerformance.module.scss';
import { useGetSalesTableData } from './salesReportHelpers';

export interface SalesPerformanceURLParams extends BaseReportURLParams {
  tab?: string;
  pgc1?: string;
}

const SalesPerformance = (): JSX.Element => {
  const { t } = useTranslation();

  const {
    tab = '',
    orgType = '',
    rowId: routeRowId = '',
    pgc1 = '',
  } = useParams<SalesPerformanceURLParams>();

  const [rowId] = split(routeRowId, '-');

  const { data: calendar } = useGetCalendarDays({});
  const businessDays = useMemo(() => getBusinessDays(calendar), [calendar]);
  const holidays = useMemo(() => getHolidays(calendar), [calendar]);

  const reportKey = tab === 'search' ? ProductGroupKey : TabSalesPerformanceKey;

  const {
    requestType,
    busPeriod: configBusPeriod,
    sortField,
    sortDir,
    updateRequestType,
    updateBusPeriod,
    updateSortField,
    updateSortDir,
  } = useReportsConfig({ key: reportKey });

  const [debouncedUpdateBusPeriod, setDebouncedUpdateBusPeriod] =
    useState(false);
  const [debouncedBusPeriod] = useDebounce(configBusPeriod, 1000);
  const busPeriod = debouncedUpdateBusPeriod
    ? debouncedBusPeriod
    : configBusPeriod;

  const [canChangeTab, setCanChangeTab] = useState(false);

  useIonViewDidEnter(() => {
    setCanChangeTab(true);
  });

  const reportFields = useMemo(
    () => [
      {
        key: 'sales',
        type: 'currency',
        id: 'currentSales',
      },
      {
        key: 'salesYoY',
        type: 'yoy',
        id: 'currentSalesChangeToCurrentBusDay',
      },
      {
        key: 'gp',
        type: 'currency',
        id: 'currentGp',
      },
      {
        key: 'gpYoY',
        type: 'yoy',
        id: 'currentGpChangeToCurrentBusDay',
      },
      {
        key: 'gpbps',
        type: 'bp',
        id: 'currentBp',
      },
      {
        key: 'gpbpsYoY',
        type: 'bp-yoy',
        id: 'currentBpChangeToCurrentBusDay',
      },
      {
        key: 'avgDaily',
        type: 'currency',
        id: 'currentAvgDaily',
        hidden: requestType === 'DAILY',
      },
      {
        key: 'avgDailyYoY',
        type: 'yoy',
        id: 'currentAvgDailyChangeToCurrentBusDay',
        hidden: requestType === 'DAILY',
      },
      {
        key: 'unbilled',
        type: 'currency',
        id: 'summaryBillingSales',
        getValue: (v: unknown) => {
          const item = v as SalesReportRow;
          let value = item?.summaryBillingSales;
          value ||= toNumber(item?.summaryBilling?.sales);
          return value;
        },
        hidden: requestType !== 'DAILY' || !isToday(fromUnixTime(busPeriod)),
      },
    ],
    [busPeriod, requestType]
  );

  // #region group and sort
  const { miLoc, userRole, groupByOptions, selectedGroupBy, setGroupByData } =
    useGetGroupBy('sales');
  const groupByDataKey = selectedGroupBy.key;
  const showGroupBy = size(groupByOptions) > 1;

  const onGroupBy = (value: string) => {
    if (canChangeTab) {
      setGroupByData((prev) => ({
        ...prev,
        [userRole]: find(groupByOptions, (group) => group.key === value),
      }));
    }
  };

  const sortFieldOptions = useCallback(
    (groupByKey?: string) => {
      const showLocationSortOptions = includes(
        ['EXEC', 'CORP', 'GRP', 'DIV', 'BRCH'],
        groupByKey
      );

      return [
        ...(showLocationSortOptions ? locationSortOptions : nameSortOptions),
        ...map(reportFields, (field) => ({
          key: field.id,
          name: field.id,
          type: 'number',
        })),
      ].map((sortItem) => ({
        ...sortItem,
        name: t(sortItem.name),
      })) as FilterOption[];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [busPeriod, requestType]
  );

  const sortFieldSelected = useMemo(
    () => {
      let selectedSort = find(sortFieldOptions(groupByDataKey), {
        key: sortField,
      });
      selectedSort ||= head(sortFieldOptions(groupByDataKey));
      return selectedSort;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [groupByDataKey, sortField]
  ) as FilterOption;

  const onSortBy = debounce(
    (sortOption: SortingRule<Dictionary<unknown>>[]) => {
      updateSortField?.(toString(head(sortOption)?.id));
      if (!isEmpty(sortOption)) {
        updateSortDir?.(
          head(sortOption)?.desc
            ? SortDirEnum.DESCENDING
            : SortDirEnum.ASCENDING
        );
      }
    },
    300
  );
  // #endregion group and sort

  const {
    drilldownData,
    summaryData,
    datesWithSameBusDay,
    dataUpdatedAt,
    isLoading,
    error,
    noMoreData,
    refetch,
    fetchNextPage,
  } = useGetSalesDashboard({
    miLoc,
    customerId: includes(['CUST', 'PRD_GRP_01', 'PRD_GRP_02'], orgType)
      ? rowId
      : undefined,
    territory: orgType === 'REP' ? rowId : undefined,
    nationalAcctNo:
      !includes(['REP', 'CUST', 'PRD_GRP_01', 'PRD_GRP_02'], orgType) && rowId
        ? rowId
        : undefined,
    pgc1,
    busPeriod,
    requestType,
    groupBy: groupByDataKey,
    sortField: sortFieldSelected.key,
    sortDir: sortDir as SortDirEnum,
  });

  const isEmptyResponse = !isLoading && size(drilldownData) === 0;

  // #region messages
  const sharedBusinessDayLabel = useGetSharedBusinessDayLabel(
    busPeriod,
    datesWithSameBusDay
  );

  let errorContent;
  if (isEmptyResponse) {
    errorContent = (
      <WarningMessage
        className={classes.warningMessage}
        icon={['far', 'info-circle']}
        title={t('reports:noReports')}
        testid="empty-response"
      />
    );
  }

  if (error) {
    errorContent = (
      <ReportError
        className={classes.warningMessage}
        error={error}
        requestType={requestType}
        reportName={t('salesPerformance')}
        testid="report-error"
      />
    );
  }
  // #endregion messages

  const { tableData, tableColumns, totalsRow } = useGetSalesTableData({
    drilldownData,
    summaryData,
    reportFields,
    selectedGroupBy,
    setCanChangeTab,
    setGroupByData,
  });

  // #region fixed header
  const { headerRef, headerTop } = useGetFixedHeader({ tableData });

  const { title, reportHeader } = useReportHeader();
  // #endregion fixed header

  return (
    <IonPage data-testid="sales-performance-page">
      <Header
        testid="sales-performance-page-header"
        title={t('reports:salesPerformance')}
        className={classes.pageHeader}
        subTitle={title}
      >
        <DateToolbar
          className={classes.datetoolbar}
          testid="Reports"
          busPeriod={configBusPeriod}
          requestType={requestType}
          updateBusPeriod={(date, waitFor) => {
            if (!isNil(waitFor)) {
              setDebouncedUpdateBusPeriod(waitFor);
            }
            updateBusPeriod(date);
          }}
          updateRequestType={updateRequestType}
          businessDays={businessDays}
          holidays={holidays}
          reportKey={reportKey}
        />
        <ActionRow
          className={classes.actionRowLink}
          href={goToEndOfMonthReport()}
          testid="oem-report-link"
        >
          <Button
            className={classes.linkButton}
            variant="secondary"
            text={t('reports:viewEOMReportText')}
            testid="oem-report-button"
          >
            <img src={EndOfMonthDashboard} alt="" />
          </Button>
        </ActionRow>
      </Header>
      <IonContent>
        <Refresher
          slot="fixed"
          lastUpdatedAt={dataUpdatedAt}
          onRefresh={refetch}
          hidden={requestType !== 'DAILY'}
          disabled={isLoading}
          testid="sales-performance"
        />
        <Header
          collapse="condense"
          testid="sales-performance-header"
          pageTitle={t('reports:salesPerformance')}
          className={classes.header}
          customTitle={<div className={classes.wrapper}>{reportHeader}</div>}
        />
        {sharedBusinessDayLabel && (
          <Text
            className={classes.sharedBusinessDayLabel}
            variant="label-header"
            text={sharedBusinessDayLabel}
          />
        )}
        <div ref={headerRef} className={classes.stickyHeader}>
          {showGroupBy && (
            <GroupBy
              groupByOptions={groupByOptions}
              selectedGroupBy={selectedGroupBy}
              onGroupBy={onGroupBy}
            />
          )}
        </div>
        {!error && (
          <Table
            className={classes.drilldownTable}
            theadStyle={{ top: headerTop }}
            thClassName={classes.tableTH}
            tdClassName={classes.tableTD}
            columns={tableColumns}
            data={tableData}
            totals={totalsRow}
            onSortBy={onSortBy}
            sortField={sortFieldSelected.key}
            sortDir={sortDir}
          />
        )}
        <Loader
          className={classes.loader}
          text={t('reports:loadingReports')}
          isOpen={isLoading}
          testid="loader"
        />
        {errorContent}
        {!noMoreData && (
          <InfiniteScroll onLoadMore={fetchNextPage} testid="infinite-scroll" />
        )}
      </IonContent>
    </IonPage>
  );
};

export default SalesPerformance;
