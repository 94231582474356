import { useCallback, useContext } from 'react';
import { NavContext } from '@ionic/react';
import { homeURL, tabsURL } from 'navigation';
import useChangeAppMode from './useChangeAppMode';

interface UseGoBackResponse {
  goBack: () => void;
  lastPathname: string;
}

const useGoBack = (): UseGoBackResponse => {
  const { goBack, routeInfo } = useContext(NavContext);
  const { lastPathname = '' } = routeInfo || {};
  const { isMiProApp } = useChangeAppMode();
  return {
    goBack: useCallback(
      () => goBack(isMiProApp ? homeURL() : tabsURL()),
      [goBack, isMiProApp]
    ),
    lastPathname,
  };
};

export default useGoBack;
