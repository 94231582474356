import type { AxiosError } from 'axios';
import { isNil, toNumber } from 'lodash';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getBulletinAPI } from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { findBulletinsQueryKey } from 'api/bulletins/useFindBulletins';
import { doPromiseAPI, getPlaceholderData, useKeyUserId } from 'api/helpers';
import type { Bulletin } from 'models/Bulletin';
import type { QueryFlags } from 'models/Search';

export const getBulletinQueryKey = 'bulletin';

interface UseGetBulletinProps {
  id: string;
}

interface UseGetBulletinResponse {
  data?: Bulletin;
}

const useGetBulletin = ({
  id,
}: UseGetBulletinProps): UseGetBulletinResponse & QueryFlags => {
  const { axios } = useAxios();
  const { createQueryKey } = useKeyUserId();
  const queryClient = useQueryClient();

  const doGetBulletin = () => {
    return doPromiseAPI<Bulletin>(async (cancelToken) => {
      const { data } = await axios.get<Bulletin>(getBulletinAPI(id), {
        cancelToken,
      });
      return data;
    });
  };

  const { data, status, error, isFetching } = useQuery<Bulletin, AxiosError>(
    createQueryKey(getBulletinQueryKey, { id }),
    doGetBulletin,
    {
      placeholderData: () =>
        getPlaceholderData<Bulletin>({
          queryClient,
          queryKey: findBulletinsQueryKey,
          findPredicate: { id: toNumber(id) },
        }),
    }
  );

  return {
    data,
    error,
    isLoading: status === 'loading' || (isFetching && isNil(data?.content)),
  };
};

export default useGetBulletin;
