import { toString } from 'lodash';
import type { MutationStatus } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { doPromiseAPI } from 'api/helpers';

interface CheckCarrierAccountProps {
  miLoc: string;
  customerNo: string;
  shipChargesCd: string;
  carrierCd: string;
  orderCtlNo: string;
  enabled?: boolean;
}

interface CheckCarrierAccountAPIResponse {
  accountRequired: string;
  defCarrierAccount: string;
}

interface UseCheckCarrierAccountResponse {
  carrierAccountData?: CheckCarrierAccountAPIResponse;
  status: MutationStatus;
  doCheckCarrierAccount: (body: CheckCarrierAccountProps) => void;
}

const useCheckCarrierAccount = (): UseCheckCarrierAccountResponse => {
  const { axios } = useAxios();
  const { checkCarrierAccountAPI } = useAPIUrl();

  const doCheckCarrierAccount = (body: CheckCarrierAccountProps) => {
    const params = {
      miLoc: body.miLoc,
      customerNo: body.customerNo,
      shipChargesCd: body.shipChargesCd,
      carrierCd: body.carrierCd,
      orderCtlNo: body.orderCtlNo,
    };
    return doPromiseAPI<CheckCarrierAccountAPIResponse>(async (cancelToken) => {
      const { data } = await axios.get<CheckCarrierAccountAPIResponse>(
        checkCarrierAccountAPI(toString(new URLSearchParams({ ...params }))),
        { cancelToken }
      );
      return data;
    });
  };
  const {
    status,
    mutate,
    data: carrierAccountData,
  } = useMutation(doCheckCarrierAccount, {});

  return {
    carrierAccountData,
    status,
    doCheckCarrierAccount: (body: CheckCarrierAccountProps) => mutate(body),
  };
};

export default useCheckCarrierAccount;
