import { useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';
import type { MutationStatus } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import {
  onSuccessMutation,
  doPromiseAPI,
  onErrorUpdate,
  onMutateUpdate,
} from 'api/helpers';
import type { UpdateMutationContext } from 'api/helpers';
import type {
  UpdateNotificationsSettingsBody,
  EventSchedule,
  CategorySchedule,
} from 'models/NotificationsSettings';
import { ToastType } from 'models/Toast';
import type { RootState } from 'store/reducers';
import getEventSchedule from 'pages/Menu/NotificationsSettings/NotificationsSettingsConfig';
import { notificationsSettingsKey } from './useGetNotificationsSettings';

interface UseUpdateNotificationsSettingsResponse {
  status: MutationStatus;
  onUpdateNotificationsSettings: (
    body: UpdateNotificationsSettingsBody
  ) => void;
}

const useUpdateNotificationsSettings =
  (): UseUpdateNotificationsSettingsResponse => {
    const { userInfo } = useSelector((state: RootState) => state.user);
    const userId = get(userInfo, 'userid', '');
    const { axios } = useAxios();
    const { notificationsSettingsAPI } = useAPIUrl();
    const queryClient = useQueryClient();
    const { addToast } = useToasts();

    const doUpdateNotificationsSettings = (
      body: UpdateNotificationsSettingsBody
    ) => {
      const {
        allowNotifications,
        snoozeNightNotifications,
        weekdayNotificationOnly,
        eventSchedule,
      } = body.notifications;
      const eventScheduleArray: EventSchedule[] = getEventSchedule(
        allowNotifications,
        snoozeNightNotifications,
        weekdayNotificationOnly
      );
      let latestEventSchedule: CategorySchedule[] = [];
      if (!isEmpty(eventScheduleArray)) {
        let isEventScheduleObjectAvail = false;
        latestEventSchedule = eventSchedule.map((item) => {
          const { categoryId, eventTagName, pushNotification } = item;
          if (!categoryId && !eventTagName && !pushNotification) {
            isEventScheduleObjectAvail = true;
            return eventScheduleArray[0];
          }

          return {
            categoryId,
            eventTagName,
            pushNotification,
            ...eventScheduleArray[0],
          };
        });
        latestEventSchedule = !isEventScheduleObjectAvail
          ? [...eventScheduleArray, ...latestEventSchedule]
          : latestEventSchedule;
      }

      const bodyWithEventSchedule = {
        ...body,
        notifications: {
          allowNotifications,
          snoozeNightNotifications,
          weekdayNotificationOnly,
          eventSchedule: isEmpty(eventScheduleArray)
            ? eventSchedule
            : latestEventSchedule,
        },
      };

      return doPromiseAPI(async (cancelToken) => {
        await axios.post(notificationsSettingsAPI(), bodyWithEventSchedule, {
          cancelToken,
        });
      });
    };

    const { status, mutate } = useMutation(doUpdateNotificationsSettings, {
      onMutate: async (vars) => {
        return onMutateUpdate<UpdateNotificationsSettingsBody>({
          queryClient,
          queryKey: notificationsSettingsKey,
          updatedItems: [
            { ...vars, userId } as UpdateNotificationsSettingsBody,
          ],
          findPredicates: [{ userId }],
          sortPredicate: true,
          isInfiniteQuery: false,
          isSingleQuery: true,
        });
      },
      onSuccess: () => {
        setTimeout(() => {
          void onSuccessMutation(queryClient, notificationsSettingsKey);
        }, 1000);
      },
      onError: (_error, _vars, context) => {
        addToast({
          type: ToastType.error,
          text: 'Update Notifications Settings operation failed. Please try again later.',
          testid: 'update-notifications-settings-error-toast',
        });
        onErrorUpdate<UpdateNotificationsSettingsBody>({
          queryClient,
          context:
            context as UpdateMutationContext<UpdateNotificationsSettingsBody>[],
          sortPredicate: true,
          isInfiniteQuery: false,
          isSingleQuery: true,
        });
      },
    });

    return {
      status,
      onUpdateNotificationsSettings: (body: UpdateNotificationsSettingsBody) =>
        mutate(body),
    };
  };

export default useUpdateNotificationsSettings;
