import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useRouteMatch } from 'react-router-dom';
import { map, size } from 'lodash';
import { IonPage, IonContent } from '@ionic/react';
import { namespaces } from 'i18n/i18n.constants';
import { customerExceptionsURL } from 'navigation';
import useGetAgreementInformation from 'api/customer/useGetAgreementInformation';
import usePopulateCommitmentData from 'hooks/usePopulateCommitmentData';
import type { SnapshotsURLParams } from 'models/Search';
import { getErrorMessage, isNumericString } from 'utils/helpers';
import { concatRoutes } from 'utils/navigations';
import { getSnapshotEyebrow } from 'utils/search';
import Button from 'components/Button/Button';
import Header from 'components/Header/Header';
import Loader from 'components/Loader/Loader';
import RowTable from 'components/RowTable/RowTable';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './AgreementInformation.module.scss';

const TITLE = 'snapshot:aggreementInfo';
const AgreementInformation = (): JSX.Element => {
  const { url } = useRouteMatch();
  const { miLoc, id } = useParams<SnapshotsURLParams>();
  const { t } = useTranslation(namespaces.agreementInfo);

  const { customerData, agreementInformation, error, isLoading } =
    useGetAgreementInformation({
      miLoc,
      id,
    });

  const lastRevisedRow = {
    title: t('lastRevisied'),
    value:
      agreementInformation?.revisionDate &&
      `${agreementInformation?.revisionDate}${
        agreementInformation?.revisedBy
          ? ` by ${agreementInformation?.revisedBy}`
          : ''
      }`,
  };

  const statusData = [
    { title: t('agreementType'), value: agreementInformation?.type },
    {
      title: t('common:status'),
      value:
        agreementInformation?.status === 'A'
          ? t('common:active')
          : t('common:inactive'),
    },
    {
      title: t('agreementDuration'),
      value:
        agreementInformation?.startDate &&
        `${agreementInformation?.startDate}${
          agreementInformation?.endDate
            ? ` to ${agreementInformation?.endDate}`
            : ''
        }`,
    },
    lastRevisedRow,
  ];

  const productsData = [
    ...map(
      agreementInformation?.margins,
      ({ productGroupDesc, grossProfitPercent }) => ({
        title: productGroupDesc,
        value: isNumericString(grossProfitPercent) && `${grossProfitPercent}%`,
      })
    ),
  ];

  const { commitmentData } = usePopulateCommitmentData(agreementInformation);

  return (
    <IonPage>
      <Header
        title={t(TITLE)}
        eyebrow={getSnapshotEyebrow({ name: customerData?.name, miLoc, id })}
        withBackButton
        testid="agreementInformation"
      />
      <IonContent className={classes.content}>
        <Loader
          className={classes.loader}
          text={t('loadingAgreement')}
          isOpen={isLoading}
        />
        {!isLoading && !error && (
          <>
            <RowTable
              tableData={statusData}
              sectionTitleClass={classes.sectionTitle}
              sectionWrapperClass={classes.sectionWrapper}
              testid="status-info-section"
            />
            <RowTable
              sectionTitle={t('productMargins')}
              tableData={productsData}
              sectionTitleClass={classes.sectionTitle}
              sectionWrapperClass={classes.sectionWrapper}
              testid="products-margin-section"
            >
              {size(agreementInformation?.exceptions) > 0 && (
                <Button
                  className={classes.exceptionBtn}
                  variant="link"
                  text={t('viewExceptions')}
                  href={concatRoutes(url, customerExceptionsURL())}
                  testid="view-exceptions-button"
                />
              )}
            </RowTable>
            <RowTable
              sectionTitle={t('costSavings')}
              tableData={commitmentData}
              sectionTitleClass={classes.sectionTitle}
              sectionWrapperClass={classes.sectionWrapper}
              testid="cost-commitment-section"
            />
          </>
        )}
        {error && (
          <WarningMessage
            className={classes.warningMessage}
            title={t('errorAgreement')}
            body={getErrorMessage(error)}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default AgreementInformation;
