import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { toNumber, toString, isNumber } from 'lodash';
import { IonRow } from '@ionic/react';
import { namespaces } from 'i18n/i18n.constants';
import classesProductListItem from 'ProductSearchApp/components/ProductListItem/ProductListItem.module.scss';
import type { Product } from 'ProductSearchApp/models/Products';
import { sssGreenArray } from 'ProductSearchApp/util/productSearchUtil';
import type { RootState } from 'store/reducers';
import NumberFormat from 'components/NumberFormat/NumberFormat';
import Text from 'components/Text/Text';
import classes from './ProductPrice.module.scss';

interface ProductHeaderProps {
  productData?: Product;
  testid: string;
}

const ProductPrice = ({
  testid,
  productData,
}: ProductHeaderProps): JSX.Element => {
  const { t } = useTranslation(namespaces.product);
  const sssGreen = sssGreenArray.includes(toString(productData?.costSource));
  const gpPct = toNumber(productData?.gpPct);
  const { showCostDetails } = useSelector((state: RootState) => state.user);

  return (
    <IonRow
      className={classes.infoContainer}
      data-testid={`product-price--${testid}`}
    >
      <IonRow className={classes.priceAndCostWrapper}>
        <div className={classes.priceWrapper}>
          <NumberFormat
            value={toNumber(productData?.unitSellPrice)}
            scale={2}
            prefix="$"
            className={classes.priceNumber}
            variant="title-screen-section"
            testid="amount"
          />

          <Text
            variant="label-header-micro"
            text={`${toString(productData?.priceSourceDesc)}/ ${toString(
              productData?.itemUOMDesc
            )}`}
            className={classes.priceLabel}
          />
        </div>
        {showCostDetails && (
          <div className={classes.costWrapper}>
            <NumberFormat
              value={toNumber(productData?.customerCost)}
              scale={2}
              prefix="$"
              className={classes.costNumber}
              variant="title-screen-section"
              testid="amount"
            />
          </div>
        )}
      </IonRow>
      <IonRow className={classes.gpAndSSSWrapper}>
        {showCostDetails && (
          <div className={classes.gpWrapper}>
            <Text
              text={`${t('common:gp')}:`}
              variant="label-header-micro"
              className={classes.costTitle}
            />
            <NumberFormat
              value={gpPct}
              suffix="%"
              scale={2}
              className={classnames(classes.costNumber, {
                [classes.negativeCostNumber]: isNumber(gpPct) && gpPct < 0,
              })}
              variant="title-screen-section"
              testid="gp-pct"
            />
          </div>
        )}
        {sssGreen && (
          <Text
            variant="list-item-secondaryText"
            text="SSS"
            className={classnames(
              classesProductListItem.secondaryText,
              classesProductListItem.secondaryTextGreen
            )}
          />
        )}
        {productData?.hasOpportunities === 'Y' && (
          <Text
            variant="list-item-secondaryText"
            text="SSS"
            className={classnames(
              classesProductListItem.secondaryText,
              classesProductListItem.secondaryTextBlue
            )}
          />
        )}
      </IonRow>
    </IonRow>
  );
};

export default ProductPrice;
