import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useAccessControls, { AccessControlType } from 'hooks/useAccessControls';
import useFeatureFlags, { FeatureFlagType } from 'hooks/useFeatureFlags';
import type { RootState } from 'store/reducers';
import { AppModeEnum, setAppMode } from 'store/user';
import defaultAppUrl from 'utils/defaultAppUrl';

interface UseChangeAppMode {
  isMiProApp: boolean;
  changeAppMode: (appMode: AppModeEnum) => void;
}

const useChangeAppMode = (): UseChangeAppMode => {
  const { appMode } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const workOrderFeatureFlag = useFeatureFlags(FeatureFlagType.workOrders);
  const workOrdersAccessControl = useAccessControls(
    AccessControlType.workOrders
  );
  const documentsAccessControl = useAccessControls(
    AccessControlType.viewDocuments
  );
  const replenishmentsAccessControl = useAccessControls(
    AccessControlType.viewItemReplenishment
  );
  const issueProcessingAccessControl = useAccessControls(
    AccessControlType.viewIssueProcessing
  );

  const isMiProApp = appMode === AppModeEnum.mipro;

  const changeAppMode = (newAppMode: AppModeEnum) => {
    dispatch(setAppMode({ appMode: newAppMode }));
    return queueMicrotask(() => {
      const url = defaultAppUrl({
        isMiProApp: newAppMode === AppModeEnum.mipro,
        workOrderFeatureFlag,
        workOrdersAccessControl,
        documentsAccessControl,
        replenishmentsAccessControl,
        issueProcessingAccessControl,
      });

      history.replace(url);
    });
  };

  return {
    isMiProApp,
    changeAppMode,
  };
};

export default useChangeAppMode;
