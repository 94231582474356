/* eslint-disable no-console */
import { head, toString, trim } from 'lodash';
import type {
  ItemReplenishment,
  ItemReplenishmentDTO,
  Replenishment,
} from 'StoreroomsApp/models/Replenishment';
import useScannerDB from './initScannerDB';
import { createReplenishmentItems } from './utils';

interface CreateReplenishmentProps {
  miLoc: string;
  userId: string;
  query?: string;
}

interface UseReplenishmentDBResponse {
  addItemsToReplenishment: (items: ItemReplenishment[]) => Promise<void>;
  getOpenReplenishment: (
    props: CreateReplenishmentProps
  ) => Promise<Replenishment>;
  findItemsFromOpenReplenishment: (
    props: CreateReplenishmentProps
  ) => Promise<ItemReplenishmentDTO[]>;
  updateItemReplenishmentQuantity: (
    itemId: number,
    quantity: number
  ) => Promise<void>;
  removeItemReplenishment: (itemId: number) => Promise<void>;
  closeReplenishment: (replenishmentId: number) => Promise<void>;
}

const useReplenishmentDB = (): UseReplenishmentDBResponse => {
  const { db, openDB, closeDB } = useScannerDB();

  const addItemsToReplenishment = async (
    items: ItemReplenishment[]
  ): Promise<void> => {
    try {
      await openDB();
      await createReplenishmentItems(db, items);
    } catch (error) {
      console.log('Error adding items to replenishment', error);
      throw new Error('Error adding items to replenishment');
    } finally {
      await closeDB();
    }
  };

  const createReplenishment = async ({
    miLoc,
    userId,
  }: CreateReplenishmentProps): Promise<Replenishment> => {
    try {
      // TODO find customer number from D6 Get Scanner User Profile
      const customerNumber = '';
      await openDB();
      await db.query(
        `INSERT OR IGNORE INTO replenishment (
        recordType,
        miLocation,
        userId,
        customerNumber,
        creationTimestamp
        ) VALUES (?,?,?,?,?)`,
        ['RP', miLoc, userId, customerNumber, Date.now()]
      );
      return head(
        (
          await db.query(
            `SELECT * FROM replenishment
            WHERE miLocation = ?
            AND syncTimestamp ISNULL
            ORDER BY creationTimestamp
            DESC LIMIT 1`,
            [miLoc]
          )
        ).values as Replenishment[]
      ) as Replenishment;
    } catch (error) {
      console.log('Error creating replenishment', error);
      throw new Error('Error creating replenishment');
    } finally {
      await closeDB();
    }
  };

  const getOpenReplenishment = async ({
    miLoc,
    userId,
  }: CreateReplenishmentProps): Promise<Replenishment> => {
    try {
      await openDB();
      const openReplenishment = head(
        (
          await db.query(
            `SELECT * FROM replenishment
            WHERE miLocation = ?
            AND syncTimestamp ISNULL
            ORDER BY creationTimestamp
            DESC LIMIT 1`,
            [miLoc]
          )
        ).values as Replenishment[]
      );
      // if there is no open replenishment, create a new one
      if (!openReplenishment) {
        return await createReplenishment({ miLoc, userId });
      }

      return openReplenishment;
    } catch (error) {
      console.log('Error loading open replenishment', error);
      throw new Error('Error loading open replenishment');
    } finally {
      await closeDB();
    }
  };

  const findItemsFromOpenReplenishment = async ({
    miLoc,
    userId,
    ...props
  }: CreateReplenishmentProps): Promise<ItemReplenishmentDTO[]> => {
    try {
      const openReplenishment = await getOpenReplenishment({ miLoc, userId });
      const vars = [toString(openReplenishment?.id)];
      const query = trim(props.query);
      const queryLike = `%${query}%`;
      if (query) {
        vars.push(queryLike, queryLike);
      }
      await openDB();
      return (
        await db.query(
          `SELECT itemspou.*, item_replenishment.*
          FROM item_replenishment
          INNER JOIN replenishment ON replenishment.id = item_replenishment.replenishmentId
          INNER JOIN itemspou ON itemspou.combinedId = item_replenishment.itemId
          WHERE item_replenishment.replenishmentId = ?
          ${
            query
              ? `AND (itemspou.customerStockNumber LIKE ? OR itemspou.itemDescription LIKE ?)`
              : ''
          }`,
          vars
        )
      ).values as ItemReplenishmentDTO[];
    } catch (error) {
      console.log('Error loading items from replenishment', error);
      throw new Error('Error loading items from replenishment');
    } finally {
      await closeDB();
    }
  };

  const updateItemReplenishmentQuantity = async (
    itemId: number,
    quantity: number
  ): Promise<void> => {
    try {
      await openDB();
      await db.query(
        `UPDATE item_replenishment
        SET orderQuantity = ?
        WHERE id = ?`,
        [quantity, itemId]
      );
    } catch (error) {
      console.log('Error updating item replenishment quantity', error);
      throw new Error('Error updating item replenishment quantity');
    } finally {
      await closeDB();
    }
  };

  const removeItemReplenishment = async (itemId: number): Promise<void> => {
    try {
      await openDB();
      await db.query(
        `DELETE FROM item_replenishment
        WHERE id = ?`,
        [itemId]
      );
    } catch (error) {
      console.log('Error removing item from replenishment', error);
      throw new Error('Error removing item from replenishment');
    } finally {
      await closeDB();
    }
  };

  const closeReplenishment = async (replenishmentId: number): Promise<void> => {
    try {
      await openDB();
      await db.query(
        `DELETE FROM item_replenishment
        WHERE replenishmentId = ?`,
        [replenishmentId]
      );
      await db.query(
        `DELETE FROM replenishment
        WHERE id = ?`,
        [replenishmentId]
      );
    } catch (error) {
      console.log('Error closing replenishment', error);
      throw new Error('Error closing replenishment');
    } finally {
      await closeDB();
    }
  };

  return {
    addItemsToReplenishment,
    getOpenReplenishment,
    findItemsFromOpenReplenishment,
    updateItemReplenishmentQuantity,
    removeItemReplenishment,
    closeReplenishment,
  };
};

export default useReplenishmentDB;
