import React from 'react';
import classNames from 'classnames';
import { IonCol, IonLoading, IonRow, IonSpinner } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { withStringProp } from 'utils/helpers';
import Text from 'components/Text/Text';
import classes from './Loader.module.scss';

enum LoaderTypeEnum {
  fullscreen,
  inline,
}

type LoaderType = keyof typeof LoaderTypeEnum;

interface LoaderProps {
  text?: string;
  type?: LoaderType;
  testid?: string;
}

const Loader = ({
  className,
  text = '',
  type = 'inline',
  isOpen,
  testid,
}: LoaderProps &
  React.ComponentProps<typeof IonLoading> &
  IonicReactProps): JSX.Element | null => {
  const withText = withStringProp(text);

  if (type === 'fullscreen') {
    return (
      <IonLoading
        cssClass={classNames(className, classes.loader)}
        isOpen={isOpen}
        message={text}
        spinner="crescent"
      />
    );
  }

  return isOpen ? (
    <IonRow className={className}>
      <IonCol className={classes.wrapper}>
        <IonSpinner color="secondary" name="crescent" />
        {withText && (
          <Text className={classes.text} text={text} testid={testid} />
        )}
      </IonCol>
    </IonRow>
  ) : null;
};

export default Loader;
