import type { SQLiteDBConnection } from 'react-sqlite-hook';
import { map, reduce, size, slice } from 'lodash';
import type { Issue, ItemIssue } from 'StoreroomsApp/models/Issue';
import type { ItemPOU } from 'StoreroomsApp/models/ItemPOU';
import type {
  ItemReplenishment,
  Replenishment,
} from 'StoreroomsApp/models/Replenishment';
import { downloadPageSize } from 'utils/constants';

export const createItems = async (db: SQLiteDBConnection, items: ItemPOU[]) => {
  const batchSize = downloadPageSize();
  const batches: ItemPOU[][] = [];
  for (let i = 0; i < size(items); i += batchSize) {
    batches.push(slice(items, i, i + batchSize));
  }
  await reduce(
    batches,
    async (prev, batch) => {
      await prev;
      const statements = map(batch, (item) => ({
        statement: `INSERT OR REPLACE INTO itemspou (
          combinedId,
          itemNumber,
          barcodeValue,
          barcodeType,
          binLocation,
          customerNumber,
          customerStockNumber,
          mino,
          itemDescription,
          miLocation,
          storeroomNumber,
          unitOfMeasure,
          fixedOrderQuantity,
          minStockingQuantity,
          maxStockingQuantity,
          balanceOnHandQuantity,
          replenishmentType,
          orderIncrement
          ) VALUES (?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?)`,
        values: [
          item.combinedId,
          item.itemNumber,
          item.barcodeValue,
          item.barcodeType,
          item.binLocation,
          item.customerNumber,
          item.customerStockNumber,
          item.mino,
          item.itemDescription,
          item.miLocation,
          item.storeroomNumber,
          item.unitOfMeasure,
          item.fixedOrderQuantity,
          item.minStockingQuantity,
          item.maxStockingQuantity,
          item.balanceOnHandQuantity,
          item.replenishmentType,
          item.orderIncrement,
        ],
      }));
      if (size(statements) > 0) {
        await db.executeSet(statements, true);
      }
    },
    Promise.resolve()
  );
};

export const createReplenishments = async (
  db: SQLiteDBConnection,
  replenishments: Replenishment[]
) => {
  const statements = map(replenishments, (replenishment) => ({
    statement: `INSERT OR IGNORE INTO replenishment (
      id,
      recordType,
      miLocation,
      userId,
      customerNumber,
      creationTimestamp
      ) VALUES (?,?,?,?,?,?)`,
    values: [
      replenishment.id,
      replenishment.recordType,
      replenishment.miLocation,
      replenishment.userId,
      replenishment.customerNumber,
      replenishment.creationTimestamp,
    ],
  }));
  if (size(statements) > 0) {
    await db.executeSet(statements, true);
  }
};

export const createReplenishmentItems = async (
  db: SQLiteDBConnection,
  items: ItemReplenishment[]
) => {
  const statements = map(items, (item) => ({
    statement: `INSERT OR IGNORE INTO item_replenishment (
      itemId,
      replenishmentId,
      balanceOnHandQuantity,
      orderQuantity,
      errorCode,
      error
      ) VALUES (?,?,?,?,?,?)`,
    values: [
      item.itemId,
      item.replenishmentId,
      item.balanceOnHandQuantity,
      item.orderQuantity,
      item.errorCode,
      item.error,
    ],
  }));
  if (size(statements) > 0) {
    await db.executeSet(statements, true);
  }
};

export const createIssues = async (db: SQLiteDBConnection, issues: Issue[]) => {
  const statements = map(issues, (issue) => ({
    statement: `INSERT OR IGNORE INTO issue (
      id,
      recordType,
      userId,
      userName,
      miLocation,
      storeroomNumber,
      creationTimestamp,
      takeOnStatus
      ) VALUES (?,?,?,?,?,?,?,?)`,
    values: [
      issue.id,
      issue.recordType,
      issue.userId,
      issue.userName,
      issue.miLocation,
      issue.storeroomNumber,
      issue.creationTimestamp,
      issue.takeOnStatus,
    ],
  }));
  if (size(statements) > 0) {
    await db.executeSet(statements, true);
  }
};

export const createIssueItems = async (
  db: SQLiteDBConnection,
  items: ItemIssue[]
) => {
  const statements = map(items, (item) => ({
    statement: `INSERT OR IGNORE INTO item_issue (
      itemId,
      issueId,
      issueQuantity,
      error
      ) VALUES (?,?,?,?)`,
    values: [item.itemId, item.issueId, item.issueQuantity, item.error],
  }));
  if (size(statements) > 0) {
    await db.executeSet(statements, true);
  }
};
