import type { AxiosError } from 'axios';
import type { Dictionary } from 'lodash';
import {
  concat,
  filter,
  find,
  get,
  includes,
  map,
  size,
  toNumber,
  toString,
  union,
} from 'lodash';
import useAPIUrl from 'api';
import type { Report } from 'DocumentsApp/models/Report';
import { useAxios } from 'providers/AxiosProvider';
import type { UpdateSectionBody } from './useUpdateSection';
import useDocumentHelpers from './utils/documentHelpers';

export const documentListConfig = {
  parts: { id: 'partId' },
  vasCodes: { id: 'vasId' },
  genericSections: { id: 'genericSectionId' },
};

interface UpdateOnlineProps {
  vars: UpdateSectionBody;
  list?: string;
  listId?: string;
}

interface UseUpdateValuesResponse {
  doUpdateSignatureOnline: (vars: UpdateSectionBody) => Promise<void>;
  doUpdateValuesOnline: (props: UpdateOnlineProps) => Promise<void>;
  doUpdateValuesOffline: (vars: UpdateSectionBody) => Report;
  doUpdateListOnline: <ItemType>(
    props: UpdateOnlineProps
  ) => Promise<ItemType[]>;
  doUpdateListOffline: (props: UpdateOnlineProps) => Report;
}

const useUpdateValues = (): UseUpdateValuesResponse => {
  const { axios } = useAxios();
  const { updateSectionAPI, reportValuesListAPI, updateReportSignatureAPI } =
    useAPIUrl();
  const { getDocumentErrors, handleDocumentErrors } = useDocumentHelpers();

  const doUpdateSignatureOnline = async (vars: UpdateSectionBody) => {
    if (vars.reportSignature) {
      const { data } = await axios.post<unknown>(
        updateReportSignatureAPI(),
        vars.reportSignature
      );
      getDocumentErrors(data as AxiosError);
    }
  };

  const doUpdateValuesOnline = async ({ vars }: UpdateOnlineProps) => {
    if (size(vars.reportValues) > 0) {
      const { data } = await axios.post<unknown>(
        updateSectionAPI(),
        map(vars.reportValues, (p) => ({
          ...p,
          ...(vars.reportId
            ? { reportId: toNumber(vars.reportId) }
            : undefined),
        }))
      );
      getDocumentErrors(data as AxiosError);
    }
  };

  const doUpdateValuesOffline = (vars: UpdateSectionBody) => {
    const updatedValues = concat(
      filter(
        vars.report?.reportValues || [],
        (p) =>
          !find(
            vars.reportValues,
            (u) => toString(u.inputId) === toString(p.inputId)
          )
      ),
      vars.reportValues || []
    );
    const addedValues = concat(
      filter(
        vars.report?.addedValues || [],
        (p) =>
          !find(
            vars.reportValues,
            (u) => toString(u.inputId) === toString(p.inputId)
          )
      ),
      vars.reportValues || []
    );
    return {
      reportValues: updatedValues,
      addedValues,
    } as Report;
  };

  async function doUpdateListOnline<ItemType>({
    vars,
    list = '',
    listId = '',
  }: UpdateOnlineProps) {
    let updateData: ItemType[] = [];
    const removedToSend = union(
      filter(get(vars, `${list}Removed`), (p) => toNumber(p) > 0)
    );
    try {
      if (size(removedToSend) > 0) {
        const { data: removeData } = await axios.delete<unknown>(
          reportValuesListAPI(list),
          { data: removedToSend }
        );
        getDocumentErrors(removeData as AxiosError);
      }
    } catch (e) {
      // DOC: delete error ignored
    }
    try {
      const addedToSend = get(vars, `${list}Added`) as ItemType[];
      if (size(addedToSend) > 0) {
        const postData = map(addedToSend, (p) => {
          const objectId = toNumber(get(p, listId));
          const inputJson = { ...p } as Dictionary<unknown>;
          delete inputJson.reportId;
          delete inputJson.sectionId;
          delete inputJson.itemIndex;
          delete inputJson.inputJson;
          delete inputJson[listId];
          return {
            ...p,
            ...(vars.reportId
              ? { reportId: toNumber(vars.reportId) }
              : undefined),
            [listId]: objectId > 0 ? objectId : 0,
            inputJson: JSON.stringify(inputJson),
          };
        });
        ({ data: updateData } = await axios.post<ItemType[]>(
          reportValuesListAPI(list),
          postData
        ));
        getDocumentErrors(updateData as unknown as AxiosError);
      }
    } catch (e) {
      handleDocumentErrors({
        error: e as AxiosError,
        defaultErrorText: `Update ${list} operation failed`,
      });
    }
    return updateData;
  }

  const doUpdateListOffline = ({
    vars,
    list = '',
    listId = '',
  }: UpdateOnlineProps) => {
    const removedToSend = map(
      concat(
        get(vars.report, `${list}Removed`) || [],
        get(vars, `${list}Removed`) || []
      ),
      (i) => toString(i)
    );
    const listToSend = concat(
      filter(
        get(vars.report, `${list}List`) || [],
        (p) =>
          !includes(removedToSend, toString(get(p, listId))) &&
          !find(
            get(vars, `${list}Added`),
            (u) => toString(get(u, listId)) === toString(get(p, listId))
          )
      ),
      get(vars, `${list}Added`) || []
    );
    const addedToSend = concat(
      filter(
        get(vars.report, `${list}Added`) || [],
        (p) =>
          !includes(removedToSend, toString(get(p, listId))) &&
          !find(
            get(vars, `${list}Added`),
            (u) => toString(get(u, listId)) === toString(get(p, listId))
          )
      ),
      get(vars, `${list}Added`) || []
    );
    return {
      [`${list}List`]: listToSend,
      [`${list}Added`]: addedToSend,
      [`${list}Removed`]: filter(removedToSend, (p) => toNumber(p) > 0),
    } as unknown as Report;
  };

  return {
    doUpdateSignatureOnline,
    doUpdateValuesOnline,
    doUpdateValuesOffline,
    doUpdateListOnline,
    doUpdateListOffline,
  };
};

export default useUpdateValues;
