import { useSelector } from 'react-redux';
import type { AxiosError } from 'axios';
import { forEach, get, isEmpty, toNumber, toString } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import type {
  QueryFnProps,
  QueryParamsType,
} from 'common/api/utils/useGetQueryFlags';
import { getUnixTime, startOfToday } from 'date-fns';
import { useAxios } from 'providers/AxiosProvider';
import {
  doGetIsLoading,
  doPromiseAPI,
  useKeyUserId,
  useMiLocOrTeamId,
} from 'api/helpers';
import useGetUserConfig from 'api/user/useGetUserConfig';
import type {
  DateSegmentType,
  GetReportSummaryResponse,
  ReportsSummaryAPIResponse,
  SummaryItemOutput,
} from 'models/Reports';
import type { QueryFlags } from 'models/Search';
import type { RootState } from 'store/reducers';
import useGetLocationCurrency from 'utils/currency';
import { DateFormatEnum, formatDate } from 'utils/date';
import { transformSummaryRows } from 'utils/reports';

export const reportSummaryQueryKey = 'report-summary';

interface UseGetReportSummaryProps {
  miLoc?: string;
  id?: string;
  pgc1?: string;
  territory?: string;
  busPeriod?: number;
  requestType?: DateSegmentType;
  pickTwelve?: boolean;
  locationPick12?: boolean;
  adjustForYoYBusDays?: boolean;
  includeUnbilledSales?: boolean;
  enabled?: boolean;
  sendVirtualTeamId?: boolean;
  includeWebPerfData?: boolean;
  dashboard?: boolean;
  includeDailyTotal?: boolean;
  endOfMonthLive?: boolean;
}

interface UseGetReportSummaryResponse {
  summaryData?: GetReportSummaryResponse;
}

const useGetReportSummary = ({
  miLoc: itemMiLoc,
  id,
  pgc1,
  territory,
  busPeriod = getUnixTime(startOfToday()),
  requestType = 'MTD',
  pickTwelve,
  locationPick12,
  adjustForYoYBusDays,
  includeUnbilledSales,
  enabled = true,
  sendVirtualTeamId,
  includeWebPerfData,
  dashboard,
  includeDailyTotal = false,
  endOfMonthLive = false,
}: UseGetReportSummaryProps): UseGetReportSummaryResponse & QueryFlags => {
  const { axios } = useAxios();
  const { getReportSummaryAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const { createParams, getURLParams } = useMiLocOrTeamId({
    miLoc: itemMiLoc,
    sendVirtualTeamId,
  });

  const { userInfo } = useSelector((state: RootState) => state.user);
  const userId = get(userInfo, 'userid', '');
  const { data: currencyData } = useGetUserConfig({
    configType: 'currency',
    enabled: enabled && !isEmpty(userId),
  });

  const { secondaryCurrencyType } = useGetLocationCurrency();

  const currencyType = currencyData?.currency || secondaryCurrencyType;

  const params: QueryParamsType = {
    ...createParams(),
    busPeriod: formatDate(busPeriod, DateFormatEnum.reportsDateAPI),
    requestType,
  };
  if (id) {
    params.customerNo = id;
  }
  if (territory) {
    params.territory = territory;
  }
  if (pgc1) {
    params.pgc1 = pgc1;
  }
  if (pickTwelve && !(id || pgc1)) {
    // TODO: toString not needed with new typing
    params.pick12 = toString(true);
    params.combinePick12 = toString(true);
  }
  if (locationPick12) {
    params.locationPick12 = toString(true);
    params.combinePick12 = toString(true);
  }
  if (adjustForYoYBusDays) {
    params.adjustForYoYBusDays = toString(true);
  }
  if (includeWebPerfData) {
    params.includeWebPerfData = toString(true);
  }
  if (includeUnbilledSales) {
    params.includeUnbilledSales = toString(includeUnbilledSales);
  }
  if (includeDailyTotal) {
    params.includeDailyTotal = toString(true);
  }
  if (endOfMonthLive) {
    params.endOfMonthLive = toString(true);
  }
  if (currencyType) {
    params.currencyType = currencyType;
  }

  const doGetReportSummary = ({ signal }: QueryFnProps) => {
    return doPromiseAPI<GetReportSummaryResponse>(async () => {
      const sales: SummaryItemOutput[] = [];
      const profit: SummaryItemOutput[] = [];
      const { data } = await axios.get<ReportsSummaryAPIResponse>(
        getReportSummaryAPI(getURLParams(params)),
        { signal }
      );
      forEach(data.sales, (row) =>
        transformSummaryRows(
          sales,
          profit,
          row,
          data.salesTrend,
          true,
          data.currencyType
        )
      );
      forEach(data.profit, (row) =>
        transformSummaryRows(
          sales,
          profit,
          row,
          data.profitTrend,
          false,
          data.currencyType
        )
      );

      return {
        sales,
        profit,
        salesTrend: data.salesTrend,
        profitTrend: data.profitTrend,
        sameBusDay: data.datesWithSameBusDay,
        pick12CustomerCount: data.pick12CustomerCount,
        totalPick12CustCount: data.totalPick12CustCount,
        pick12Pct: data.pick12Pct,
        webPerformance: data.webPerformance,
        currencyType: data.currencyType,
        previous: data.previous,
      };
    });
  };

  const response = useQuery<GetReportSummaryResponse, AxiosError>(
    createQueryKey(reportSummaryQueryKey, { ...params, dashboard }),
    doGetReportSummary,
    {
      enabled: enabled && !isEmpty(userId),
      // TODO: refactor and extract this retry logic to be resuable across the application
      retry: (failureCount, err) =>
        err.response?.status === 403 ? false : !(failureCount < 3),
    }
  );

  const {
    data: summaryData,
    error,
    isFetching,
    dataUpdatedAt,
    refetch,
    status,
  } = response;

  const hasError = status === 'error';
  const isLoading = doGetIsLoading(response);
  let lastUpdatedAt = new Date();
  if (toNumber(dataUpdatedAt) > 0) {
    lastUpdatedAt = new Date(dataUpdatedAt);
  }

  return {
    refetch: async () => {
      await refetch();
    },
    error,
    hasError,
    summaryData,
    isLoading,
    isFetching,
    lastUpdatedAt: getUnixTime(lastUpdatedAt),
  };
};

export default useGetReportSummary;
