import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { includes } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fromUnixTime, isThisMonth } from 'date-fns';
import { namespaces } from 'i18n/i18n.constants';
import {
  SalesPerformanceHomeKey,
  TabSalesPerformanceKey,
  useReportsConfig,
} from 'providers/ReportsProvider';
import useGetSalesDashboard from 'ReportsApp/api/useGetSalesDashboard';
import useGetCamUserDetails from 'ReportsApp/hooks/useGetCamUserDetails';
import {
  goToEndOfMonthReport,
  goToSalesPerformanceReport,
} from 'ReportsApp/navigation/navigationHelpers';
import { useGetSelectedMiLoc } from 'api/helpers';
import { useHasAccessControls } from 'hooks/useAccessControls';
import { useGetSharedBusinessDayLabel } from 'utils/date';
import { removeLocationFromText } from 'utils/helpers';
import { findIcon } from 'utils/icons';
import { GetIncludeDailyTotalsMsg } from 'utils/reports';
import EndOfMonthDashboard from 'assets/EndOfMonthDashboard.svg';
import Text from 'components/Text/Text';
import DashboardCard from './DashboardCard';
import type { DashboardLineProps } from './DashboardCard';
import classes from './DashboardCard.module.scss';

const SalesPerformanceCard = (): JSX.Element => {
  const { t } = useTranslation(namespaces.common);
  const { hasAccessControl } = useHasAccessControls();
  const { accessControl, subTitle, isCamUser } = useGetCamUserDetails();

  const { selectedMiLoc, stateMiLocArray, fromVirtualTeam, team } =
    useGetSelectedMiLoc();

  const { updateRequestType, updateBusPeriod, requestType, busPeriod } =
    useReportsConfig({
      key: SalesPerformanceHomeKey,
    });
  const [showBP, setShowBP] = useState(true);
  const {
    includeDailyTotal,
    includeDailyTotalMsg = '',
    includeDailyTotalInfo = '',
  } = GetIncludeDailyTotalsMsg({
    requestType,
    busPeriod,
  });

  const {
    summaryData,
    error,
    isLoading,
    datesWithSameBusDay: sameBusDay,
  } = useGetSalesDashboard({
    miLoc: fromVirtualTeam || team ? undefined : selectedMiLoc.miLoc,
    busPeriod,
    requestType,
    summaryOnly: true,
    enabled: hasAccessControl(accessControl),
  });

  const sharedBusinessDayLabel = useGetSharedBusinessDayLabel(
    busPeriod,
    sameBusDay
  );

  const currentMonthRequest =
    requestType === 'MTD' && isThisMonth(fromUnixTime(busPeriod));
  const lines = useMemo(() => {
    const currencyType = summaryData?.currency;
    const basisPoints = summaryData?.currentBp ?? 0;
    const result: DashboardLineProps[] = [
      {
        text: t('totalSales'),
        currencyType,
        value: summaryData?.currentSales,
        valueType: 'currency',
        changePercentage: summaryData?.currentSalesChangeToCurrentBusDay,
        changeLabel: 'YOY',
      },
      {
        text: t('gp'),
        currencyType,
        value: summaryData?.currentGp,
        valueType: 'currency',
        changePercentage: summaryData?.currentGpChangeToCurrentBusDay,
        changeLabel: 'YOY',
      },
      {
        text: t('gpPercentage'),
        value: t('reports:gpBasisPoints', {
          positive: basisPoints >= 0 ? '+' : '',
          gpBasisPoints: basisPoints,
        }),
        valueType: 'string',
        changeBp: summaryData?.currentBpChangeToCurrentBusDay,
        changeLabel: 'BPS\nYOY',
        hidden: !showBP,
        onClick: () => setShowBP(false),
      },
      {
        text: t('gpPercentage'),
        value: summaryData?.currentGpPercent,
        valueType: 'percentage',
        changePercentage: summaryData?.currentGpPercentChangeToCurrentBusDay,
        changeLabel: 'YOY',
        hidden: showBP,
        onClick: () => setShowBP(true),
      },
    ];
    if (includes(['MTD', 'YTD'], requestType)) {
      result.push({
        text: t('averageDaily'),
        currencyType,
        value: summaryData?.currentAvgDaily,
        valueType: 'currency',
        changePercentage: summaryData?.currentAvgDailyChangeToCurrentBusDay,
        changeLabel: 'YOY',
      });
    }
    if (currentMonthRequest) {
      result.push(
        {
          text: t('summaryBilling'),
          currencyType,
          value: summaryData?.summaryBilling?.sales,
          valueType: 'currency',
        },
        {
          text: t('summaryBillingGP'),
          currencyType,
          value: summaryData?.summaryBilling?.gp,
          valueType: 'currency',
        }
      );
    }
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestType, summaryData, currentMonthRequest, showBP]);

  const filteredLineItems: DashboardLineProps[] = lines.filter(
    (x) => x !== null
  ) as unknown as DashboardLineProps[];
  let overlayBarMsg = '';
  let overlayInfoHeader = '';
  let overlayInfoContent = '';
  if (includeDailyTotal) {
    overlayBarMsg = includeDailyTotalMsg;
    overlayInfoHeader = includeDailyTotalInfo;
  } else if (requestType === 'DAILY' && sharedBusinessDayLabel) {
    overlayBarMsg = t('sharedBussinesDay');
    overlayInfoHeader = t('sharedBussinesDay');
    overlayInfoContent = sharedBusinessDayLabel;
  }

  return (
    <DashboardCard
      id="salesPerf"
      customTitle={
        <div className={classes.iconWrapper}>
          <FontAwesomeIcon
            className={classes.salesPerfIcon}
            icon={findIcon('chart-pie-simple', 'far')}
          />
          <Text variant="title-action-card" text={t('salesPerformance')} />
        </div>
      }
      subtitle={
        isCamUser ? subTitle : removeLocationFromText(selectedMiLoc.locName)
      }
      lines={filteredLineItems}
      reportKey={SalesPerformanceHomeKey}
      tabReportKey={TabSalesPerformanceKey}
      requestType={requestType}
      busPeriod={busPeriod}
      locations={stateMiLocArray.map((item) => ({
        key: item.miLoc,
        name: removeLocationFromText(item.locName),
      }))}
      isLoading={isLoading}
      overlayBarMsg={overlayBarMsg}
      overlayInfoHeader={overlayInfoHeader}
      overlayInfoContent={overlayInfoContent}
      error={error}
      setRequestType={updateRequestType}
      setBusPeriod={updateBusPeriod}
      primaryButton={{
        href: goToSalesPerformanceReport({}),
        text: t(`${namespaces.reports}:viewFullReport`),
      }}
      secondaryButton={{
        href: goToEndOfMonthReport(),
        text: t(`${namespaces.reports}:viewEOMReport`),
        imageSrc: EndOfMonthDashboard,
      }}
    />
  );
};

export default SalesPerformanceCard;
