import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { isEmpty, map, split, toString, trim, uniq } from 'lodash';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { and } from 'common/utils/logicHelpers';
import type { Recipient } from 'models/Contact';
import type { SearchItemType } from 'models/Search';
import Button from 'components/Button/Button';
import {
  PhoneCallRow,
  PhoneMobileRow,
  PhoneSMSRow,
} from 'components/Contacts/PhoneNumber/PhoneNumber';
import { EmailRow } from 'components/Email/Email';
import SendEmailModal from 'components/Modals/SendEmailModal/SendEmailModal';
import SheetModal from 'components/Modals/SheetModal/SheetModal';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './ContactModal.module.scss';

export interface ContactModalProps {
  name?: string;
  email?: string;
  phoneNumber?: string;
  mobilePhone?: string;
  searchType?: SearchItemType;
  miLoc?: string;
  id?: string;
  titleHref?: string;
}

const ContactModal = ({
  className,
  isOpen,
  setIsOpen: setIsOpenContactModal,
  name,
  email,
  phoneNumber,
  mobilePhone,
  miLoc,
  id,
  searchType = 'customer',
  titleHref,
}: IonicReactProps &
  React.ComponentProps<typeof SheetModal> &
  ContactModalProps): JSX.Element => {
  const [sendEmailModal, setSendEmailModal] = useState(false);
  const { t } = useTranslation();
  const emailList = !isEmpty(email) ? map(uniq(split(email, ';')), trim) : [];
  const recepeints: Recipient[] = map(emailList, (item) => {
    return {
      email: item,
      miLoc,
    };
  });
  const withTitleHref = !isEmpty(titleHref);
  const hasNoContactInformation = and(isEmpty(phoneNumber), isEmpty(email));
  let initialBreakpoint = 0.25;
  if (hasNoContactInformation) {
    initialBreakpoint = 0.35;
  } else if (mobilePhone) {
    initialBreakpoint = 0.4;
  }

  return (
    <>
      <SheetModal
        title={
          !withTitleHref ? t('contact:contactModalTitle', { name }) : undefined
        }
        isOpen={isOpen}
        setIsOpen={setIsOpenContactModal}
        testid="contact-modal"
        withRightCloseButton
        className={className}
        initialBreakpoint={initialBreakpoint}
        contentClass={classes.contactModalWrapper}
        customTitle={
          withTitleHref ? (
            <Button
              testid="contact-modal-title-button"
              href={titleHref}
              onClick={() => {
                setIsOpenContactModal?.(false);
              }}
              variant="link"
              text={name}
              textVariant="mipro-h1-headline"
              className={classes.customTitle}
            />
          ) : undefined
        }
      >
        {!isEmpty(phoneNumber) && (
          <PhoneCallRow
            phoneNumber={toString(phoneNumber)}
            testid="contact-phone"
            onClick={() => {
              setIsOpenContactModal?.(false);
            }}
            className={classes.actionRow}
          />
        )}

        {!isEmpty(mobilePhone) && (
          <PhoneMobileRow
            phoneNumber={toString(mobilePhone)}
            testid="mobile-phone"
            onClick={() => {
              setIsOpenContactModal?.(false);
            }}
            className={classes.actionRow}
          />
        )}

        {!isEmpty(mobilePhone) && (
          <PhoneSMSRow
            phoneNumber={toString(mobilePhone)}
            testid="sms-phone"
            onClick={() => {
              setIsOpenContactModal?.(false);
            }}
            className={classes.actionRow}
          />
        )}

        {!isEmpty(email) && (
          <EmailRow
            emailAddress={toString(email)}
            testid="ocn"
            onClick={() => {
              setIsOpenContactModal?.(false);
              setSendEmailModal(true);
            }}
            className={classNames(classes.emailRow, classes.actionRow)}
          />
        )}

        {hasNoContactInformation && (
          <WarningMessage
            title={t('contact:noContactInformationMessage', { name })}
            testid="nocontact-warning"
          />
        )}
      </SheetModal>
      {/* TUDU : Use SendEmail component */}
      <SendEmailModal
        searchType={searchType}
        id={id}
        miLoc={miLoc}
        isOpen={sendEmailModal}
        defaultRecipients={recepeints}
        setIsOpen={setSendEmailModal}
        title={t('common:sendEmail')}
        testid="send-email-modal"
      />
    </>
  );
};

export default ContactModal;
