import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'components/Modal/Modal';
import Text from 'components/Text/Text';
import classes from './NotificationsSettings.module.scss';

const SnoozeInfoModal = ({
  isOpen,
  setIsOpen,
}: Omit<React.ComponentProps<typeof Modal>, 'title'>): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Modal
      modalClassName={classes.modal}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      testid="notification-settings-snooze-info-modal"
    >
      <Text
        className={classes.title}
        variant="title-info-card"
        text={t('notificationSettings:snooze')}
        testid="notification-settings-snooze-info-modal-title"
      />
      <Text
        className={classes.description}
        variant="content-smaller"
        text={t('notificationSettings:snoozeDescription')}
        testid="notification-settings-snooze-info-modal-description"
      />
      <Text
        variant="label-micro-italic"
        text={t('notificationSettings:note')}
        testid="notification-settings-note"
      />
    </Modal>
  );
};

export default SnoozeInfoModal;
