import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import type { Dictionary } from 'lodash';
import { filter, map, size } from 'lodash';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { choose, or } from 'common/utils/logicHelpers';
import { namespaces } from 'i18n/i18n.constants';
import { searchURL } from 'navigation';
import useFindSearchSuggestions from 'api/search/useFindSearchSuggestions';
import type {
  SearchGlobalOrder,
  SearchItem,
  SearchSuggestionItem,
} from 'models/Search';
import {
  SearchResultsSeeAllEnum,
  SearchResultsSeeMoreEnum,
  SearchResultTabTypeEnum,
  SearchSuggestionTypeEnum,
} from 'models/Search';
import { getAddress } from 'utils/address';
import { concatRoutes } from 'utils/navigations';
import type { SnapshotRoutingState } from 'utils/search';
import {
  getIsCorpAccount,
  getSearchResultData,
  getSearchResultTabLabel,
  handleSearchNavigation,
} from 'utils/search';
import Button from 'components/Button/Button';
import Loader from 'components/Loader/Loader';
import SearchSuggestionCard from 'components/Search/SearchSuggestionCard/SearchSuggestionCard';
import SearchSuggestionOcnCard from 'components/Search/SearchSuggestionCard/SearchSuggestionOcnCard';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './SearchSuggestions.module.scss';

interface SuggestionsListProps {
  searchQuery: string;
  onSearch?: (query: string) => void;
  setSelectedTab?: (v: SearchResultTabTypeEnum) => void;
  list: Array<{
    key: SearchResultTabTypeEnum;
    title: string;
    items: SearchSuggestionItem[];
  }>;
  miLoc: string;
  showTitle: boolean;
  noMoreDataMap?: Dictionary<boolean>;
  handleSeeMore: (st: SearchResultTabTypeEnum) => void;
}

const SuggestionsList = ({
  list,
  searchQuery,
  onSearch,
  setSelectedTab,
  handleSeeMore,
  noMoreDataMap,
  miLoc,
  showTitle,
}: SuggestionsListProps): JSX.Element => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const { t } = useTranslation(namespaces.search);

  return (
    <div className={classes.content}>
      {map(
        list,
        ({ key, title, items }) =>
          size(items) > 0 && (
            <React.Fragment key={key}>
              {showTitle && (
                <Text
                  className={classes.title}
                  variant="title-info-card"
                  text={title}
                  testid="search-section-title"
                />
              )}
              {map(items, (item, idx) => {
                const { searchType: searchSuggestionType } = item;
                const { type: cardType } = item as SearchItem;

                if (cardType === SearchResultTabTypeEnum.ocns) {
                  return (
                    <SearchSuggestionOcnCard
                      key={`${idx}-${item.text}`}
                      item={item as SearchGlobalOrder}
                      testid={`ocn-${item.text}`}
                      textQuery={searchQuery}
                    />
                  );
                }
                const {
                  type,
                  miLoc: iMiLoc,
                  id,
                  key: iKey,
                  text,
                  description,
                  natlAcctNo,
                  address,
                  customerNo,
                  supplierLocationNo,
                  customerPick12,
                } = getSearchResultData(item as SearchItem, idx, t);

                if (searchSuggestionType !== SearchSuggestionTypeEnum.result) {
                  return null;
                }

                return (
                  <SearchSuggestionCard
                    key={iKey}
                    id={key}
                    searchSuggestionType={searchSuggestionType}
                    text={text}
                    customerNo={customerNo}
                    textQuery={searchQuery}
                    description={description}
                    account={natlAcctNo}
                    address={getAddress(address, ',')}
                    onClick={() =>
                      history.push({
                        pathname: concatRoutes(
                          choose(showTitle, url, searchURL()) as string,
                          handleSearchNavigation({
                            type,
                            miLoc: iMiLoc || miLoc,
                            sequenceNo: id,
                            supplierId: or(supplierLocationNo, id, ''),
                            customerId: or(customerNo, id, ''),
                            employeeId: id,
                          })
                        ),
                        state: {
                          isCorpAccount: choose(
                            or(
                              type === 'customer',
                              type === 'corporateAccounts'
                            ),
                            getIsCorpAccount(customerNo, natlAcctNo)
                          ),
                          headerTitle: text,
                        } as SnapshotRoutingState,
                      })
                    }
                    customerPick12={customerPick12}
                    testid={`item-${iKey}`}
                    searchType={type}
                    isMainCard={!showTitle && idx === 0}
                  />
                );
              })}
              {size(items) === 3 && !noMoreDataMap?.[key] && (
                <Button
                  className={classes.seeMoreButton}
                  variant="link"
                  text={t(
                    SearchResultsSeeMoreEnum[
                      key as keyof typeof SearchResultsSeeMoreEnum
                    ]
                  )}
                  onClick={() => {
                    handleSeeMore(key);
                  }}
                  testid={`see-more-${title}-button`}
                />
              )}
              {size(items) === 25 && (
                // TODO:  Get translation for 'Full Results'
                <Button
                  className={classes.seeMoreButton}
                  variant="link"
                  text={t(
                    SearchResultsSeeAllEnum[
                      key as keyof typeof SearchResultsSeeAllEnum
                    ]
                  )}
                  onClick={() => {
                    setSelectedTab?.(key);
                    onSearch?.(searchQuery);
                  }}
                  testid={`full-results-${title}-button`}
                />
              )}
            </React.Fragment>
          )
      )}
    </div>
  );
};

interface SearchSuggestionsProps {
  searchQuery: string;
  onSearch?: (query: string) => void;
  setSelectedTab?: (v: SearchResultTabTypeEnum) => void;
  searchType: SearchResultTabTypeEnum;
  miLoc: string;
  sortField?: string;
  showTitle?: boolean;
  loadingTitle: string;
}

const SearchSuggestions = ({
  className,
  searchQuery,
  onSearch,
  setSelectedTab,
  searchType,
  miLoc,
  sortField,
  showTitle = true,
  loadingTitle,
}: SearchSuggestionsProps & IonicReactProps): JSX.Element => {
  const {
    items,
    error,
    hasError,
    showLoader,
    isEmptyResponse,
    noMoreDataMap,
    seeMoreEmployees,
    seeMoreCustomers,
    seeMoreSuppliers,
    seeMoreCustomerContacts,
    seeMoreMotionLocations,
    seeMoreCorporateAccounts,
    seeMoreOCNs,
    resetSeeMoreLineNumbers,
    ...searchSuggestionsResults
  } = useFindSearchSuggestions({
    query: searchQuery,
    searchType,
    sortField,
  });

  const handleSeeMore = (st: SearchResultTabTypeEnum) => {
    switch (st) {
      case SearchResultTabTypeEnum.customers:
        seeMoreCustomers();
        break;
      case SearchResultTabTypeEnum.suppliers:
        seeMoreSuppliers();
        break;
      case SearchResultTabTypeEnum.employees:
        seeMoreEmployees();
        break;
      case SearchResultTabTypeEnum.customerContacts:
        seeMoreCustomerContacts();
        break;
      case SearchResultTabTypeEnum.motionLocations:
        seeMoreMotionLocations();
        break;
      case SearchResultTabTypeEnum.corporateAccounts:
        seeMoreCorporateAccounts();
        break;
      case SearchResultTabTypeEnum.ocns:
        seeMoreOCNs();
        break;
      default:
        resetSeeMoreLineNumbers();
        break;
    }
  };

  const { t } = useTranslation(namespaces.search);
  const list = useMemo(() => {
    return searchType === SearchResultTabTypeEnum.all
      ? map(
          filter(
            Object.keys(SearchResultTabTypeEnum),
            (key) => key !== SearchResultTabTypeEnum.all
          ),
          (key) => ({
            key: key as SearchResultTabTypeEnum,
            title: t(getSearchResultTabLabel(key)),
            items:
              searchSuggestionsResults[
                key as Exclude<SearchResultTabTypeEnum, 'all'>
              ],
          })
        )
      : [
          {
            key: searchType,
            title: t(getSearchResultTabLabel(searchType)),
            items: searchSuggestionsResults[searchType],
          },
        ];
  }, [searchSuggestionsResults, searchType, t]);
  return (
    <div className={className} data-testid="search-suggestions">
      {!showLoader && (
        <SuggestionsList
          list={list}
          searchQuery={searchQuery}
          onSearch={onSearch}
          setSelectedTab={setSelectedTab}
          handleSeeMore={handleSeeMore}
          noMoreDataMap={noMoreDataMap}
          miLoc={miLoc}
          showTitle={showTitle}
        />
      )}
      {!showLoader && size(filter(list, (li) => size(li.items) > 0)) === 0 && (
        <WarningMessage
          className={classes.contentMessage}
          title={t('noSuggestionMessage')}
        />
      )}
      {hasError && (
        <WarningMessage
          className={classes.contentMessage}
          title={or(error?.message, t('errorSuggest'))}
        />
      )}
      <Loader
        className={classes.loader}
        text={loadingTitle}
        isOpen={showLoader}
      />
    </div>
  );
};

export default SearchSuggestions;
