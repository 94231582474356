import React from 'react';
import type { Dictionary } from 'lodash';
import { toNumber, toString } from 'lodash';
import SalesPlayForm from 'ActivitiesApp/components/SalesPlayForm/SalesPlayForm';
import {
  SalesPlayTypeEnum,
  SalesPlayIconEnum,
  SalesPlayColorEnum,
  SalesPlayFilterEnum,
} from 'ActivitiesApp/models/SalesPlay';
import { formatNumber } from 'common/utils/numberHelper';
import { differenceInDays } from 'date-fns';
import { parseDate } from 'utils/date';
import type {
  ActionCardConfig,
  ActivityTypeConfig,
  GetActivityConfigExtendedProps,
  GetActivityTypeDataProps,
} from 'pages/Activities/ActivityActionCard/ActivityCardConfig';

export const getSalesPlayConfig = ({
  activityVersion,
  activityData,
  defaultFirstLine,
  t,
}: GetActivityConfigExtendedProps): ActionCardConfig | undefined => {
  const { fieldsData, extendedInfo, currency } = activityData;
  const productDescription = toString(
    extendedInfo?.itemDescription || extendedInfo?.productGroupDescription
  );
  const salesPlayAccepted = toString(
    fieldsData?.['Accepted Date'] || activityData.creationTimestampISO
  );
  const projectedValue = formatNumber({
    number: toNumber(fieldsData?.['Projected Value']),
    currencyType: currency,
  });
  const salesOpportunity = formatNumber({
    number: toNumber(fieldsData?.['Sales Opportunity']),
    currencyType: currency,
  });
  const expectedOrder = formatNumber({
    number: toNumber(fieldsData?.['Expected Order Value'] || 0),
    currencyType: currency,
  });
  const potentialSales = formatNumber({
    number: toNumber(fieldsData?.['Potential Sales'] || 0),
    currencyType: currency,
  });
  const potentialSpend = formatNumber({
    number: toNumber(fieldsData?.['Potential Spend'] || 0),
    currencyType: currency,
  });
  const daysDiff = differenceInDays(new Date(), parseDate(salesPlayAccepted));

  const modalBody = {
    hideSaveButton: true,
    refetchActivity: true,
    customContent: (props: Dictionary<unknown>) => (
      <SalesPlayForm
        historyId={activityData.historyId}
        userId={activityData.userId}
        activity={activityData}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    ),
  };

  switch (activityVersion) {
    case `${SalesPlayTypeEnum.overdueSalesPlay}-1`:
      return {
        card: {
          defaultTitle: t('overdueSalesPlay'),
          body: {
            description: t('overdueSalesPlayCardLabel', {
              type: t('overdueSalesPlay'),
              productDescription,
              salesOpportunity,
              projectedValue,
            }),
            lines: [
              ...defaultFirstLine,
              {
                description: t('overdueSalesPlayCardDescription', {
                  expectedOrder,
                }),
                highlight: expectedOrder,
              },
              {
                description: t('salesPlayCardDescription2', {
                  count: daysDiff,
                }),
                highlight: toString(daysDiff),
              },
            ],
          },
          highlight: [{ query: t('overdueSalesPlay') }],
        },
        modal: { title: t('overdueSalesPlay'), ...modalBody },
      };
    case `${SalesPlayTypeEnum.upSellSalesPlay}-1`:
      return {
        card: {
          defaultTitle: t('upSellSalesPlay'),
          body: {
            description: t('salesPlayCardLabel', {
              type: t('upSellSalesPlay'),
              productDescription,
              salesOpportunity,
            }),
            lines: [
              ...defaultFirstLine,
              {
                description: t('upsellSalesPlayCardDescription', {
                  potentialSpend,
                }),
                highlight: potentialSpend,
              },
              {
                description: t('salesPlayCardDescription2', {
                  count: daysDiff,
                }),
                highlight: toString(daysDiff),
              },
            ],
          },
          highlight: [{ query: t('upSellSalesPlay') }],
        },
        modal: { title: t('upSellSalesPlay'), ...modalBody },
      };
    case `${SalesPlayTypeEnum.decliningSalesPlay}-1`:
      return {
        card: {
          defaultTitle: t('decliningSalesPlay'),
          body: {
            description: t('salesPlayCardLabel', {
              type: t('decliningSalesPlay'),
              productDescription,
              salesOpportunity,
            }),
            lines: [
              ...defaultFirstLine,
              {
                description: t('overdueSalesPlayCardDescription', {
                  expectedOrder,
                }),
                highlight: expectedOrder,
              },
              {
                description: t('salesPlayCardDescription2', {
                  count: daysDiff,
                }),
                highlight: toString(daysDiff),
              },
            ],
          },
          highlight: [{ query: t('decliningSalesPlay') }],
        },
        modal: { title: t('decliningSalesPlay'), ...modalBody },
      };
    case `${SalesPlayTypeEnum.crossSellSalesPlay}-1`:
      return {
        card: {
          defaultTitle: t('crossSellSalesPlay'),
          body: {
            description: t('salesPlayCardLabel', {
              type: t('crossSellSalesPlay'),
              productDescription,
              salesOpportunity,
            }),
            lines: [
              ...defaultFirstLine,
              {
                description: t('crosssellSalesPlayCardDescription', {
                  potentialSales,
                }),
                highlight: potentialSales,
              },
              {
                description: t('salesPlayCardDescription2', {
                  count: daysDiff,
                }),
                highlight: toString(daysDiff),
              },
            ],
          },
          highlight: [{ query: t('crossSellSalesPlay') }],
        },
        modal: { title: t('crossSellSalesPlay'), ...modalBody },
      };
    default:
      return undefined;
  }
};

export const getSalesPlayData = ({
  eventTagName,
  t,
}: GetActivityTypeDataProps): ActivityTypeConfig | undefined => {
  switch (eventTagName) {
    case 'OverdueSalesPlayAdded':
      return {
        cardType: SalesPlayTypeEnum.overdueSalesPlay,
        title: t('overdueSalesPlay'),
        icon: SalesPlayIconEnum.overdueSalesPlay,
        color: SalesPlayColorEnum.overdueSalesPlay,
      };
    case 'UpSellSalesPlayAdded':
      return {
        cardType: SalesPlayTypeEnum.upSellSalesPlay,
        title: t('upSellSalesPlay'),
        icon: SalesPlayIconEnum.upSellSalesPlay,
        color: SalesPlayColorEnum.upSellSalesPlay,
      };
    case 'DecliningSalesPlayAdded':
      return {
        cardType: SalesPlayTypeEnum.decliningSalesPlay,
        title: t('decliningSalesPlay'),
        icon: SalesPlayIconEnum.decliningSalesPlay,
        color: SalesPlayColorEnum.decliningSalesPlay,
      };
    case 'CrossSellSalesPlayAdded':
      return {
        cardType: SalesPlayTypeEnum.crossSellSalesPlay,
        title: t('crossSellSalesPlay'),
        icon: SalesPlayIconEnum.crossSellSalesPlay,
        color: SalesPlayColorEnum.crossSellSalesPlay,
      };
    default:
      return undefined;
  }
};

export const getSalesPlayFilterIcon = (
  type: string
): SalesPlayIconEnum | undefined => {
  switch (type) {
    case SalesPlayFilterEnum.salesPlays:
      return SalesPlayIconEnum.salesPlays;
    default:
      return undefined;
  }
};
