import { useSelector } from 'react-redux';
import jobCodesFirstAccessible from 'constants/jobCodesFirstAccessible';
import { get, some } from 'lodash';
import type { UserInfo } from 'models/Signin';
import type { RootState } from 'store/reducers';
import { hasVirtualTeam } from 'store/user/selectors';

interface UseUserInfoResponse {
  userInfo?: UserInfo;
  isOwner: boolean;
  userId: string;
}

interface UseUserInfoProps {
  userId?: string;
}

export const useUserInfo = ({
  userId,
}: UseUserInfoProps): UseUserInfoResponse => {
  const { userInfo } = useSelector((state: RootState) => state.user);
  const userIdApp = get(userInfo, 'userid', '');
  const owner = userId === userIdApp;

  return {
    userInfo,
    isOwner: owner,
    userId: userIdApp,
  };
};

export const isAccountRep = (jobCode?: string) =>
  some(jobCodesFirstAccessible, (jobCodeItem) => jobCodeItem === jobCode);

export const useGetUserInfo = () => {
  const { user } = useSelector((state: RootState) => state);
  const userId = get(user.userInfo, 'userid');

  const hasVT = useSelector(hasVirtualTeam);

  return {
    userId,
    locationTree: user.locationTree,
    hasVirtualTeam: hasVT,
    userState: user,
  };
};
