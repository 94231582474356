import React, { useEffect, useState } from 'react';
import { map, toNumber } from 'lodash';
import useFindCarrier from 'ProductSearchApp/api/fastfind/useFindCarrier';
import type { Carrier } from 'ProductSearchApp/models/Order';
import SelectModal from 'components/SelectModal/SelectModal';

interface CarrierSelectProps {
  selItem?: Carrier;
  label?: string;
  openModal?: boolean;
  setOpenModal?: (b: boolean) => void;
  setCarrierCode: (o?: Carrier) => void;
  triggerInput?: number;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
}

const CarrierSelect = ({
  className,
  selItem,
  label = '',
  openModal = false,
  setOpenModal,
  triggerInput,
  setCarrierCode,
  onClose,
  placeholder,
  required,
  disabled,
}: CarrierSelectProps &
  // should be Omit instead
  Pick<
    React.ComponentProps<typeof SelectModal>,
    'className' | 'onClose'
  >): JSX.Element => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const carrierSelected = {
    key: selItem?.codeValue ?? '',
    title: selItem?.codeDesc ?? '',
    description: selItem?.codeDesc ?? '',
  };

  const { carrier, error, isLoading, isEmptyResponse, fetchNextPage } =
    useFindCarrier({
      query: searchQuery,
    });

  useEffect(() => {
    if (!isOpen && !openModal) {
      setSearchQuery('');
    }
  }, [isOpen, openModal, searchQuery, setSearchQuery]);

  const inputDisplay = selItem?.codeValue
    ? `(${selItem.codeValue}) ${selItem.codeDesc}`
    : '';
  const carrierItem = map(carrier, (item) => ({
    key: item.codeValue,
    title: item.codeDesc,
    description: item.codeValue,
  }));

  useEffect(() => {
    if (toNumber(triggerInput) > 0) {
      setIsOpen(true);
    }
  }, [triggerInput]);

  return (
    <SelectModal
      className={className}
      isOpen={setOpenModal ? openModal : isOpen}
      setIsOpen={setOpenModal || setIsOpen}
      type="carrier"
      subType=""
      selItem={carrierSelected || { title: '', description: '', key: '' }}
      setSelItem={(o) =>
        setCarrierCode(carrier.find((i) => i.codeValue === o.key))
      }
      testid="select--carrier-modal"
      isEmptyResponse={!!isEmptyResponse}
      showLoader={!!isLoading}
      fetchNextPage={fetchNextPage}
      error={error}
      enableInfiniteScroll
      items={carrierItem}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      onClick={() => setIsOpen(true)}
      onClose={onClose}
      label={label}
      value={inputDisplay}
      icon="caret-down"
      placeholder={placeholder}
      required={required}
      disabled={disabled}
    />
  );
};

export default CarrierSelect;
