import React from 'react';
import { useTranslation } from 'react-i18next';
import { choose } from 'common/utils/logicHelpers';
import useGetCostSavingsReport from 'ReportsApp/api/useGetCostSavingsReport';
import type { ViewAsRoleType } from 'models/Reports';
import CostSavingsCard from 'pages/Home/CostSavingsHealthCard/CostSavingsCard';
import classes from './CostSavingsCustomerCard.module.scss';

interface CostSavingsCustomerCardProps {
  custMiLoc: string;
  customerNo: string;
  guaranteed: string;
  isCorpAccount?: boolean;
  natlAcctNo?: string;
}

const CostSavingsCustomerCard = ({
  custMiLoc,
  customerNo,
  guaranteed,
  isCorpAccount,
  natlAcctNo,
}: CostSavingsCustomerCardProps): JSX.Element => {
  const { t } = useTranslation();
  const isGuaranteed = guaranteed === 'Y';
  const filterParams = isCorpAccount
    ? { miLoc: custMiLoc, natlAcct: natlAcctNo }
    : { customerNo, custMiLoc };
  const response = useGetCostSavingsReport({
    ...filterParams,
    viewAsRole: choose(isCorpAccount, 'CORP', 'CUST') as ViewAsRoleType,
    groupBy: 'BRCH',
    enabled: true,
    guaranteed,
  });

  const card = {
    id: isGuaranteed ? `guaranteed` : `goal`,
    title: isGuaranteed
      ? t('common:costSavingsGuaranteed')
      : t('common:costSavingsGoal'),
    data: response,
  };

  return (
    <div className={classes.costSavings}>
      <CostSavingsCard
        id={card.id}
        title={card.title}
        data={card.data}
        useOverage
        isSeparate={false}
      />
    </div>
  );
};

export default CostSavingsCustomerCard;
