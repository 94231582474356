import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { cloneDeep, map, reduce, toString } from 'lodash';
import { IonRow } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import type { FilterSection } from 'models/Filters';
import { FilterSectionEnum } from 'models/Filters';
import { hasFilterApplied } from 'utils/helpers';
import { findIcon } from 'utils/icons';
import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';
import Text from 'components/Text/Text';
import FilterSectionWrapper from './FilterSectionWrapper';
import classes from './FiltersModal.module.scss';

interface FiltersModalProps {
  title?: string;
  filters: FilterSection[];
  onDone?: (filters: FilterSection[]) => void;
}

const FiltersModal = ({
  className,
  title,
  filters: pFilters,
  onDone,
}: FiltersModalProps & IonicReactProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [filters, setFilters] = useState<FilterSection[]>([]);
  // DOC: trigger children change on Modal so that it can be resized
  const [triggerModalResize, setTriggerModalResize] = useState(0);

  useEffect(() => {
    setFilters(cloneDeep(pFilters));
  }, [pFilters, isOpen]);

  const onApply = () => {
    onDone?.call(null, cloneDeep(filters));
    setIsOpen(false);
  };

  const onReset = () => {
    setFilters((prev) =>
      map(prev, ({ type, options, ...prevFilter }) => {
        switch (type) {
          case FilterSectionEnum.checkbox:
            return {
              ...prevFilter,
              type,
              options: map(options, (o) => ({ ...o, checked: false })),
            };
          case FilterSectionEnum.radiogroup:
          case FilterSectionEnum.search:
          case FilterSectionEnum.dateRange:
            return { ...prevFilter, type, options, selectedValue: '' };
          default:
            return { ...prevFilter, type, options };
        }
      })
    );
  };

  const appliedCount = useMemo(
    () => reduce(filters, (sum, f) => sum + (hasFilterApplied(f) ? 1 : 0), 0),
    [filters]
  );
  const hasFiltersApplied = appliedCount > 0;
  const { t } = useTranslation();
  return (
    <>
      <Button
        className={classNames(className, classes.filterButton, {
          [classes.appliedFilters]: hasFiltersApplied,
        })}
        variant={hasFiltersApplied ? 'action' : 'clear'}
        rightIcon={findIcon('filter')}
        onClick={() => setIsOpen(true)}
        testid="filters-button"
      >
        <Text text={t('filter')} />
        {hasFiltersApplied && (
          <Text
            className={classes.appliedLabel}
            text={toString(appliedCount)}
          />
        )}
      </Button>
      <Modal
        className={classes.modal}
        headerClassName={classes.header}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        triggerModalResize={triggerModalResize}
        title={title || t('filterBy')}
        withTitleLine={false}
        forceFullHeight
        footer={
          <IonRow className={classes.buttonsWrapper}>
            <Button
              variant="action"
              text={t('apply')}
              testid="apply-button"
              onClick={onApply}
            />
            <Button
              variant="secondary"
              text={t('reset')}
              testid="reset-button"
              onClick={onReset}
            />
          </IonRow>
        }
        testid="filters-modal"
      >
        {map(filters, ({ key, label, type, selectedValue, options }) => (
          <FilterSectionWrapper
            key={key}
            sectionKey={key}
            label={label}
            type={type}
            selectedValue={selectedValue}
            options={options}
            setFilters={setFilters}
            triggerResize={setTriggerModalResize}
          />
        ))}
      </Modal>
    </>
  );
};

export default FiltersModal;
