import type { AxiosError } from 'axios';
import { isNil } from 'lodash';
import { useIonViewDidEnter } from '@ionic/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { doPromiseAPI, getPlaceholderData, useKeyUserId } from 'api/helpers';
import type { Announcement } from 'models/Announcement';
import type { QueryFlags } from 'models/Search';
import {
  findAnnouncementsObjectKey,
  findAnnouncementsQueryKey,
} from './useFindAnnouncements';

export const getAnnouncementQueryKey = 'announcement';

interface UseGetAnnouncementProps {
  url: string;
  encoding?: string;
}

interface UseGetAnnouncementResponse {
  data?: Announcement;
}

const useGetAnnouncement = ({
  url,
  encoding = 'UTF8',
}: UseGetAnnouncementProps): UseGetAnnouncementResponse & QueryFlags => {
  const { axios } = useAxios();
  const { getAnnouncementAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { createQueryKey } = useKeyUserId();

  const doGetAnnouncement = () => {
    return doPromiseAPI<Announcement>(async (cancelToken) => {
      const { data } = await axios.get<Announcement>(
        getAnnouncementAPI(url, encoding),
        { cancelToken }
      );
      return data;
    });
  };

  const { data, status, error, isFetching, refetch } = useQuery<
    Announcement,
    AxiosError
  >(createQueryKey(getAnnouncementQueryKey, { url }), doGetAnnouncement, {
    placeholderData: () => {
      return getPlaceholderData<Announcement>({
        queryClient,
        queryKey: findAnnouncementsQueryKey,
        objectKey: findAnnouncementsObjectKey,
        findPredicate: { url },
      });
    },
  });

  useIonViewDidEnter(() => {
    void refetch();
  });

  return {
    data,
    error,
    isLoading: status === 'loading' || (isFetching && isNil(data?.content)),
  };
};

export default useGetAnnouncement;
