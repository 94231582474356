/* eslint-disable no-console */
import { includes, isArray, map, reduce, size } from 'lodash';
import type { SQLiteDBConnection } from '@capacitor-community/sqlite';
import {
  transformFromOfflineReport,
  transformFromOnlineReport,
} from 'DocumentsApp/api/utils/documentHelpers';
import type { Report } from 'DocumentsApp/models/Report';
import { useDevice } from 'providers/DeviceProvider';
import { createDocuments } from './utils';

const versionDBTable = `CREATE TABLE IF NOT EXISTS versionDB (
  version TEXT
)`;

const reportTable = `CREATE TABLE IF NOT EXISTS report (
  reportId INTEGER,
  templateId TEXT,
  name TEXT,
  status TEXT,
  startDate TEXT,
  endDate TEXT,
  miLoc TEXT,
  miLocText TEXT,
  shopLoc TEXT,
  orderCtlNo TEXT,
  woCtlNo TEXT,
  customerNo TEXT,
  customerName TEXT,
  orderLineNo TEXT,
  machineId INTEGER,
  siteId INTEGER,
  templateVersion INTEGER,
  lastSyncDate TEXT,
  reportType TEXT,
  custMachineId TEXT,
  machineText TEXT,
  siteText TEXT,
  customerContact TEXT,
  customerContactPhone TEXT,
  creationUserId TEXT,
  creationUserName TEXT,
  creationTmstmp TEXT,
  lastUpdUserId TEXT,
  lastUpdUserName TEXT,
  lastUpdTmstmp TEXT,
  documentJson TEXT,
  needSync BOOL
)`;

const createIndexReportTable = `CREATE UNIQUE INDEX IF NOT EXISTS idx_report
  ON report (reportId)`;

const templateTable = `CREATE TABLE IF NOT EXISTS template (
  templateId INTEGER PRIMARY KEY NOT NULL,
  creationUserId TEXT,
  status TEXT,
  templateName TEXT,
  templateVersion TEXT,
  creationTmstmp TEXT,
  templateType TEXT,
  templateJson TEXT,
  isDownloaded BOOL
)`;

const createIndexTemplateTable = `CREATE UNIQUE INDEX IF NOT EXISTS idx_template
  ON template (templateId)`;

const workOrderTable = `CREATE TABLE IF NOT EXISTS workOrder (
  woCtlNo TEXT PRIMARY KEY NOT NULL,
  woDesc TEXT,
  shopLoc TEXT,
  itemNo TEXT,
  miLoc TEXT,
  orderCtlNo TEXT,
  custNo TEXT,
  customerName TEXT,
  custPo TEXT,
  orderLineNo INTEGER,
  requestOrigin TEXT
)`;

const createIndexWorkOrderTable = `CREATE UNIQUE INDEX IF NOT EXISTS woCtlNo_miLoc_x_workOrder
  ON workOrder (woCtlNo, miLoc)`;

const siteTable = `CREATE TABLE IF NOT EXISTS site (
  siteId TEXT PRIMARY KEY NOT NULL,
  siteDesc TEXT,
  siteMiLoc TEXT,
  siteCustNo TEXT,
  displayText TEXT,
  siteName TEXT,
  custSiteId TEXT,
  customerName TEXT
)`;

const createIndexSiteTable = `CREATE UNIQUE INDEX IF NOT EXISTS idx_site
  ON site (siteId)`;

const machineTable = `CREATE TABLE IF NOT EXISTS machine (
  siteId TEXT,
  siteMiLoc TEXT,
  machineId TEXT PRIMARY KEY NOT NULL,
  machineDesc TEXT,
  custMachineId TEXT,
  displayText TEXT
)`;

const createIndexMachineTable = `CREATE UNIQUE INDEX IF NOT EXISTS idx_machine
  ON machine (machineId)`;

const imageTable = `CREATE TABLE IF NOT EXISTS report_image (
  reportId INTEGER,
  imageId INTEGER,
  image TEXT
)`;

const createIndexImageTable = `CREATE UNIQUE INDEX IF NOT EXISTS idx_report_image
  ON report_image (reportId, imageId)`;

const vasCodesTable = `CREATE TABLE IF NOT EXISTS vas_codes (
  UOM TEXT,
  codeDesc TEXT,
  codeValue TEXT,
  miLoc TEXT
)`;

const createIndexVasCodes = `CREATE UNIQUE INDEX IF NOT EXISTS idx_vas_codes ON vas_codes (codeValue)`;

const workTasksTable = `CREATE TABLE IF NOT EXISTS work_tasks (
  rowStatus TEXT,
  codeDesc TEXT,
  codeValue TEXT,
  miLoc TEXT
)`;

const createIndexWorkTasks = `CREATE UNIQUE INDEX IF NOT EXISTS idx_work_tasks ON work_tasks (codeValue)`;

interface UseDocumentsDBResponse {
  db: SQLiteDBConnection;
  openDB: () => Promise<void>;
  closeDB: () => Promise<void>;
  getLikeStatement: (props: string | string[], vars?: unknown[]) => string;
  getWhereStatement: (whereStatement: string[]) => string;
}

interface VersionDB {
  version: string;
}

const useDocumentsDB = (): UseDocumentsDBResponse => {
  const { documentsDB: db } = useDevice();

  const migrateTo1dot0 = async (versions: string[]): Promise<void> => {
    if (includes(versions, '1.0')) {
      return;
    }
    await db.execute(`DROP TABLE IF EXISTS report`);
    await db.execute(`DROP TABLE IF EXISTS workOrder`);
    await db.query(
      `INSERT INTO versionDB (
      version
      ) VALUES (?)`,
      ['1.0']
    );
  };

  const migrateTo1dot1 = async (versions: string[]): Promise<void> => {
    if (includes(versions, '1.1')) {
      return;
    }
    await db.execute(`DROP TABLE IF EXISTS report`);
    await db.execute(`DROP TABLE IF EXISTS template`);
    await db.query(
      `INSERT INTO versionDB (
      version
      ) VALUES (?)`,
      ['1.1']
    );
  };

  const migrateTo1dot2 = async (versions: string[]): Promise<void> => {
    if (includes(versions, '1.2')) {
      return;
    }
    await db.execute(`DROP TABLE IF EXISTS site`);
    await db.execute(`DROP TABLE IF EXISTS workOrder`);
    await db.query(
      `INSERT INTO versionDB (
      version
      ) VALUES (?)`,
      ['1.2']
    );
  };

  const migrateTo1dot3 = async (versions: string[]): Promise<void> => {
    if (includes(versions, '1.3')) {
      return;
    }
    await db.execute(reportTable);
    const offlineReports = map(
      (await db.query(`SELECT * from report WHERE needSync = 1`))
        .values as Report[],
      (r) => transformFromOfflineReport(r)
    );
    await db.execute(`DROP TABLE IF EXISTS report`);
    await db.execute(reportTable);
    const reportsToRestore = map(offlineReports, (r) =>
      transformFromOnlineReport(r)
    );
    await createDocuments(db, reportsToRestore);
    await db.query(
      `INSERT INTO versionDB (
      version
      ) VALUES (?)`,
      ['1.3']
    );
  };

  const migrateTo1dot4 = async (versions: string[]): Promise<void> => {
    if (includes(versions, '1.4')) {
      return;
    }
    await db.execute(`DROP TABLE IF EXISTS workOrder`);
    await db.query(
      `INSERT INTO versionDB (
      version
      ) VALUES (?)`,
      ['1.4']
    );
  };

  const migrateTo1dot5 = async (versions: string[]): Promise<void> => {
    if (includes(versions, '1.5')) {
      return;
    }
    await db.execute(`DROP TABLE IF EXISTS template`);
    await db.query(
      `INSERT INTO versionDB (
      version
      ) VALUES (?)`,
      ['1.5']
    );
  };

  const createTables = async (): Promise<void> => {
    try {
      await db.execute(versionDBTable);
      const versions = map(
        (await db.query(`SELECT * FROM versionDB`)).values as VersionDB[],
        (v) => v.version
      );
      await migrateTo1dot0(versions);
      await migrateTo1dot1(versions);
      await migrateTo1dot2(versions);
      await migrateTo1dot3(versions);
      await migrateTo1dot4(versions);
      await migrateTo1dot5(versions);
      await db.execute(reportTable);
      await db.execute(createIndexReportTable);
      await db.execute(templateTable);
      await db.execute(createIndexTemplateTable);
      await db.execute(workOrderTable);
      await db.execute(createIndexWorkOrderTable);
      await db.execute(siteTable);
      await db.execute(createIndexSiteTable);
      await db.execute(machineTable);
      await db.execute(createIndexMachineTable);
      await db.execute(imageTable);
      await db.execute(createIndexImageTable);
      await db.execute(vasCodesTable);
      await db.execute(createIndexVasCodes);
      await db.execute(workTasksTable);
      await db.execute(createIndexWorkTasks);
    } catch (error) {
      console.log('Error creating tables', error);
    }
  };

  const openDB = async (): Promise<void> => {
    try {
      await db.open();
      await createTables();
    } catch (error) {
      console.log('Error opening DB connection', error);
    }
  };

  const closeDB = async (): Promise<void> => {
    try {
      await db.close();
    } catch (error) {
      console.log('Error closing DB connection', error);
    }
  };

  const getLikeStatement = (props: string | string[], vars?: unknown[]) => {
    let items = [];
    if (!isArray(props)) {
      items = [props];
    } else {
      items = props;
    }
    return `${size(vars) > 0 ? 'AND ' : ''}(${map(
      items,
      (prop) => `${prop} LIKE ? COLLATE NOCASE\n`
    ).join('OR\n')})`;
  };

  const getWhereStatement = (whereStatement: string[]) =>
    `${size(whereStatement) > 0 ? `WHERE` : ''}
    ${reduce(
      whereStatement,
      (prev, statement) => `${prev}${statement}\n`,
      ''
    )}`;

  return {
    db,
    openDB,
    closeDB,
    getLikeStatement,
    getWhereStatement,
  };
};

export default useDocumentsDB;
