import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import type { AxiosError } from 'axios';
import { size, toString } from 'lodash';
import { useInfiniteQuery } from '@tanstack/react-query';
import { findBulletinsAPI } from 'api';
import { formatISO, startOfDay, subDays } from 'date-fns';
import { useAxios } from 'providers/AxiosProvider';
import { doConcatDataPages, doPromiseAPI, useKeyUserId } from 'api/helpers';
import type { Bulletin } from 'models/Bulletin';
import type { InfiniteQueryFlags } from 'models/Search';
import type { RootState } from 'store/reducers';
import { pageSize } from 'utils/constants';

export const findBulletinsQueryKey = 'bulletins';

interface UseFindBulletinsResponse {
  data?: Bulletin[];
}

const useFindBulletins = (): UseFindBulletinsResponse & InfiniteQueryFlags => {
  const limit = pageSize();
  const { axios } = useAxios();
  const { createQueryKey } = useKeyUserId();
  const { bulletinDismissDate = subDays(startOfDay(new Date()), 300) } =
    useSelector((state: RootState) => state.user);
  const after = formatISO(new Date(bulletinDismissDate));

  const doFindBulletins = ({ pageParam = 1 }) => {
    return doPromiseAPI<Bulletin[]>(async (cancelToken) => {
      const paramsGET = new URLSearchParams();
      paramsGET.append('_fields[]', 'id');
      paramsGET.append('_fields[]', 'title');
      paramsGET.append('_fields[]', 'excerpt');
      paramsGET.append('_fields[]', 'content');
      paramsGET.append('_fields[]', 'image');
      paramsGET.append('_fields[]', 'date');
      paramsGET.append('_fields[]', 'acf');
      paramsGET.append('page', toString(pageParam));
      paramsGET.append('per_page', toString(limit));

      const { data } = await axios.get<Bulletin[]>(
        findBulletinsAPI(toString(paramsGET)),
        {
          cancelToken,
        }
      );
      return data;
    });
  };

  const {
    data,
    error,
    status,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    refetch,
    fetchNextPage,
  } = useInfiniteQuery<Bulletin[], AxiosError>(
    createQueryKey(findBulletinsQueryKey, { after }),
    doFindBulletins,
    {
      getNextPageParam: (lastPage, pages) =>
        size(lastPage) < limit ? false : size(pages) + 1,
    }
  );

  const bulletins = useMemo(() => doConcatDataPages<Bulletin>(data), [data]);

  const hasItems = size(bulletins) > 0;
  const hasError = status === 'error';
  const isEmptyResponse = status === 'success' && !hasNextPage && !hasItems;
  const noMoreData = status === 'success' && !hasNextPage && hasItems;
  const showLoader = status === 'loading' || isFetchingNextPage;
  const enableInfiniteScroll = !(!hasNextPage || isFetchingNextPage);

  return {
    fetchNextPage: async () => {
      await fetchNextPage();
    },
    data: bulletins,
    error,
    hasError,
    isFetching,
    showLoader,
    isEmptyResponse,
    noMoreData,
    enableInfiniteScroll,
    refetch: async () => {
      await refetch();
    },
  };
};

export default useFindBulletins;
