import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  filter,
  find,
  findIndex,
  isEmpty,
  isNil,
  map,
  size,
  toString,
  escapeRegExp,
} from 'lodash';
import { IonRow } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { namespaces } from 'i18n/i18n.constants';
import { useDebounce } from 'use-debounce';
import useFindContacts from 'api/contacts/useFindContacts';
import type { Recipient } from 'models/Contact';
import type { SearchItemType } from 'models/Search';
import { getErrorMessage } from 'utils/helpers';
import { findIcon } from 'utils/icons';
import ActionRow from 'components/ActionRow/ActionRow';
import Button from 'components/Button/Button';
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll';
import Loader from 'components/Loader/Loader';
import Modal from 'components/Modal/Modal';
import Searchbar from 'components/Searchbar/Searchbar';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './SendEmailModal.module.scss';

interface ContactsListProps {
  searchType: SearchItemType;
  id: string;
  miLoc: string;
  showSelectedContacts?: boolean;
  values?: Recipient[];
  onDone?: (v: Recipient[]) => void;
}

const ContactsList = ({
  searchType,
  id,
  miLoc,
  isOpen,
  setIsOpen,
  values,
  title = 'Add Recipients',
  onClose,
  onDone,
  testid,
  showSelectedContacts = true,
}: ContactsListProps &
  React.ComponentProps<typeof Modal> &
  IonicReactProps): JSX.Element => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryValue] = useDebounce(searchQuery, 300);
  const withSearchQuery = !isEmpty(searchQueryValue);
  const [recipients, setRecipients] = useState<Recipient[]>([]);
  // DOC: trigger children change on Modal so that it can be resized
  const [triggerModalResize, setTriggerModalResize] = useState(0);
  const { t } = useTranslation(namespaces.contact);

  useEffect(() => {
    if (isOpen) {
      setRecipients(!isNil(values) ? values : []);
      setSearchQuery('');
    }
  }, [values, isOpen]);

  const {
    contacts,
    error,
    fetchNextPage,
    hasError,
    showLoader,
    isEmptyResponse,
    enableInfiniteScroll,
  } = useFindContacts({
    searchType,
    miLoc,
    id,
    enabled: isOpen,
  });

  const filteredContacts = useMemo(
    () =>
      filter(
        contacts,
        ({ name }) =>
          !withSearchQuery ||
          !isNil(
            new RegExp(escapeRegExp(searchQueryValue), 'i').exec(toString(name))
          )
      ),
    [contacts, searchQueryValue, withSearchQuery]
  );

  useEffect(() => {
    setTriggerModalResize(Date.now());
  }, [filteredContacts, recipients]);

  // DOC: keep using index because Customer Contacts don't have location
  const removeRecipient = (
    index: number,
    sequenceNo?: number,
    itemMiLoc?: string
  ) => {
    setRecipients((prev) => {
      const seqIndex = findIndex(prev, {
        sequenceNo,
        ...(searchType === 'supplier' ? { miLoc: itemMiLoc } : undefined),
      });
      if (seqIndex !== -1) {
        return [...prev.slice(0, seqIndex), ...prev.slice(seqIndex + 1)];
      }
      if (index !== -1) {
        return [...prev.slice(0, index), ...prev.slice(index + 1)];
      }
      return prev;
    });
  };

  return (
    <Modal
      modalClassName={classes.modalWrapper}
      className={classes.modal}
      headerClassName={classes.header}
      isOpen={isOpen}
      triggerModalResize={triggerModalResize}
      withTitleLine={false}
      title={title}
      setIsOpen={setIsOpen}
      onClose={onClose}
      forceFullHeight
      header={
        <div className={classes.searchWrapper}>
          <Searchbar
            className={classes.searchbar}
            value={searchQuery}
            setValue={setSearchQuery}
            testid="search-input"
          />
        </div>
      }
      footer={
        <IonRow className={classes.buttonsWrapper}>
          <Button
            variant="action"
            text={t('common:done')}
            onClick={() => {
              onDone?.(recipients);
              setIsOpen?.(false);
            }}
            testid="send-button"
          />
          <Button
            variant="secondary"
            text={t('common:cancel')}
            onClick={() => {
              onClose?.();
              setIsOpen?.(false);
            }}
            testid="cancel-button"
          />
        </IonRow>
      }
      testid={testid}
    >
      {showSelectedContacts && size(recipients) > 0 && (
        <div className={classes.selectedContacts}>
          <IonRow className={classes.recipientsWrapperInverted}>
            {map(
              recipients,
              ({ sequenceNo, miLoc: itemMiLoc = '', name, email }, index) => (
                <IonRow
                  key={`${toString(sequenceNo)}${itemMiLoc}`}
                  className={classes.recipientButtonInverted}
                >
                  <div>
                    <Text
                      className={classes.contactName}
                      variant="content-heavy"
                      text={toString(name || email)}
                    />
                    {searchType === 'supplier' && (
                      <Text
                        className={classes.contactLocation}
                        variant="content-small"
                        text={itemMiLoc}
                        testid="card-location"
                      />
                    )}
                  </div>
                  <Button
                    icon={findIcon('times-circle', 'fas')}
                    onClick={() =>
                      removeRecipient(index, sequenceNo, itemMiLoc)
                    }
                    testid={`remove-recipient-${toString(sequenceNo)}-button`}
                  />
                </IonRow>
              )
            )}
          </IonRow>
        </div>
      )}
      <div className={classNames(classes.content, classes.recipientsList)}>
        <div className={classes.contactsList}>
          {!hasError &&
            map(
              filteredContacts,
              ({
                sequenceNo,
                miLoc: itemMiLoc = '',
                name,
                email,
                typeDesc,
              }) => {
                const contactAdded = find(recipients, {
                  sequenceNo,
                  ...(searchType === 'supplier'
                    ? { miLoc: itemMiLoc }
                    : undefined),
                });
                const indexAdded = findIndex(recipients, {
                  sequenceNo,
                  ...(searchType === 'supplier'
                    ? { miLoc: itemMiLoc }
                    : undefined),
                });

                return (
                  <ActionRow
                    key={`${toString(sequenceNo)}${itemMiLoc}`}
                    className={classNames(classes.contactCard, {
                      [classes.selected]: contactAdded,
                    })}
                    leftButton={{
                      variant: 'link',
                      icon: contactAdded
                        ? findIcon('check-circle', 'fas')
                        : findIcon('plus-circle'),
                      testid: `contact-${sequenceNo}-add-button`,
                    }}
                    onClick={() => {
                      if (!contactAdded) {
                        setRecipients((prev) => [
                          ...prev,
                          { sequenceNo, miLoc: itemMiLoc, name, email },
                        ]);
                      } else {
                        removeRecipient(indexAdded, sequenceNo, itemMiLoc);
                      }
                    }}
                    testid={`contact-${sequenceNo}`}
                  >
                    <IonRow className={classes.contactRow}>
                      <div>
                        {!isEmpty(name) && (
                          <Text
                            className={classes.contactName}
                            text={toString(name)}
                            textQuery={searchQueryValue}
                          />
                        )}
                        {searchType === 'supplier' && (
                          <Text
                            className={classes.contactLocation}
                            variant="content-small"
                            text={itemMiLoc}
                            testid="card-location"
                          />
                        )}
                      </div>
                      <Text variant="content-small" text={toString(email)} />
                      <Text variant="content-small" text={toString(typeDesc)} />
                    </IonRow>
                  </ActionRow>
                );
              }
            )}
          {isEmptyResponse && (
            <WarningMessage
              className={classes.warningMessage}
              icon={['far', 'info-circle']}
              title={t('noContacts')}
            />
          )}
          {withSearchQuery &&
            size(filteredContacts) === 0 &&
            !isEmptyResponse && (
              <WarningMessage
                className={classes.warningMessage}
                icon={['far', 'info-circle']}
                title={t('common:noResults')}
              />
            )}
          {hasError && (
            <WarningMessage
              className={classes.warningMessage}
              title={t('errorContacts')}
              body={getErrorMessage(error)}
            />
          )}
          <Loader
            className={classes.loader}
            text={t('loadingContacts')}
            isOpen={showLoader}
          />
          <InfiniteScroll
            disabled={!enableInfiniteScroll}
            onLoadMore={fetchNextPage}
            testid="infinite-scroll"
          />
        </div>
      </div>
    </Modal>
  );
};

export default ContactsList;
