import type { AxiosError } from 'axios';
import { toString } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import type { OrderNote } from 'ProductSearchApp/models/Order';
import { useAxios } from 'providers/AxiosProvider';
import { doPromiseAPI, useKeyUserId } from 'api/helpers';
import type { QueryFlags } from 'models/Search';

export const getOrderNoteQuerykey = 'order-note';

interface UsGetOrderNoteProps {
  miLoc: string;
  ocn: string;
  enabled?: boolean;
  micro: string;
  date: string;
  time: string;
}

interface UseGetOrderNoteResponse {
  data?: OrderNote;
}

const useGetOrderNote = ({
  miLoc,
  ocn,
  micro,
  date,
  time,
  enabled = true,
}: UsGetOrderNoteProps): UseGetOrderNoteResponse & QueryFlags => {
  const { axios } = useAxios();
  const { getOrderNoteAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const params = {
    date,
    micro,
    time,
  };

  const doGetOrderNote = () => {
    return doPromiseAPI<OrderNote>(async (cancelToken) => {
      const { data } = await axios.get<OrderNote>(
        getOrderNoteAPI(
          miLoc,
          ocn,
          toString(new URLSearchParams({ ...params }))
        ),
        { cancelToken }
      );
      return data;
    });
  };

  const { data, error, status, isFetching, refetch } = useQuery<
    OrderNote,
    AxiosError
  >(createQueryKey(getOrderNoteQuerykey, params), doGetOrderNote, {
    enabled,
  });

  return {
    data,
    error,
    isLoading: status === 'loading' || isFetching,
    isFetching,
    refetch: async () => {
      await refetch();
    },
  };
};

export default useGetOrderNote;
