import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import type { Dictionary } from 'lodash';
import {
  filter,
  get,
  includes,
  isEmpty,
  isNil,
  isObject,
  last,
  map,
  reduce,
  size,
  tail,
  toNumber,
  toString,
  trim,
} from 'lodash';
import {
  IonCol,
  IonContent,
  IonImg,
  IonPage,
  IonRow,
  IonSkeletonText,
  useIonViewDidEnter,
  useIonViewWillEnter,
} from '@ionic/react';
import Footer from 'common/components/Footer/Footer';
import CustomerName from 'common/components/Header/CustomerName';
import Header from 'common/components/Header/Header';
import { formatKeyValue } from 'common/utils/valueFormatter';
import useGetCostSavingsDetails from 'CostSavingsApp/api/useGetCostSavingsDetails';
import useGetCostTamplate from 'CostSavingsApp/api/useGetCostTemplate';
import type { SubmitCostSavingsBody } from 'CostSavingsApp/api/useSubmitCostSaving';
import useSubmitCostSavings from 'CostSavingsApp/api/useSubmitCostSaving';
import CostSavingsForm from 'CostSavingsApp/components/CostSavingsForm/CostSavingsForm';
import {
  contactSelectTypes,
  getInputType,
  selectTypes,
} from 'CostSavingsApp/components/CostSavingsForm/CostSavingsInput';
import VasCodeLine from 'CostSavingsApp/components/CostSavingsForm/VasCodeLine';
import CostSavingsIndicator from 'CostSavingsApp/components/CostSavingsIndicator/CostSavingsIndicator';
import type { ReviewField } from 'CostSavingsApp/components/CostSavingsReview/CostSavingsReview';
import CostSavingsReview from 'CostSavingsApp/components/CostSavingsReview/CostSavingsReview';
import CostSavingsSignature from 'CostSavingsApp/components/CostSavingsSignature/CostSavingsSignature';
import CustomerSelect from 'CostSavingsApp/components/FastFind/CustomerSelect';
import type {
  CostSavingsEntryForm,
  CostSavingsField,
  CostSavingsURLParams,
} from 'CostSavingsApp/models/CostSavings';
import {
  CostSavingsDataTypeEnum,
  CustomerSearchTabTypeEnum,
} from 'CostSavingsApp/models/CostSavings';
import { fromUnixTime, getUnixTime, isAfter, isToday } from 'date-fns';
import { useFormik } from 'formik';
import { namespaces } from 'i18n/i18n.constants';
import {
  costSavingsDetailsURL,
  costSavingsURL,
  searchCustomerURL,
  searchURL,
} from 'navigation';
import useFindAttachments from 'api/attachments/useFindAttachments';
import useGetAgreementInformation from 'api/customer/useGetAgreementInformation';
import useAccessControls, { AccessControlType } from 'hooks/useAccessControls';
import useGoBack from 'hooks/useGoBack';
import type { MiProFile } from 'models/Attachment';
import type { SelectModalItem } from 'models/Search';
import type { RootState } from 'store/reducers';
import { DateFormatEnum, formatDate, parseDate } from 'utils/date';
import { getErrorMessage } from 'utils/helpers';
import { concatRoutes } from 'utils/navigations';
import { removeLeadingZeros } from 'utils/number';
import { getCustomerShortName, getIsCorpAccount } from 'utils/search';
import Button from 'components/Button/Button';
import SnapshotLink from 'components/Contacts/SnapshotLink/SnapshotLink';
import DateInput from 'components/DateInput/DateInput';
import Loader from 'components/Loader/Loader';
import SheetModal from 'components/Modals/SheetModal/SheetModal';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './CostSavingsEntry.module.scss';

const getDateValue = (date: string) =>
  getUnixTime(parseDate(date.replace('[UTC]', '')));

const reminderIsValid = (date: number) =>
  isAfter(fromUnixTime(date), new Date()) || isToday(fromUnixTime(date));

interface CostSavingsEntryProps {
  mode: 'entry' | 'view' | 'edit';
}

const CostSavingsEntry = ({ mode }: CostSavingsEntryProps): JSX.Element => {
  const { t } = useTranslation(namespaces.costSavings);
  const canCorpCostSavingsEntry = useAccessControls(
    AccessControlType.AddCostSavingsAccessControlsForCorpAccount
  );
  const { userInfo } = useSelector((state: RootState) => state.user);
  const employeeNumber = get(userInfo, 'employeenumber');
  const employeeName = get(userInfo, 'cn');
  const {
    miLoc: routeMiLoc = '',
    id: routeId = '',
    vasCtlNo = '',
  } = useParams<CostSavingsURLParams>();
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const resetView = params.get('reset');
  const { goBack } = useGoBack();

  const [miLoc, setMiLoc] = useState(routeMiLoc);
  const [customerNo, setCustomerNo] = useState(routeId);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [triggerCustomerInput, setTriggerCustomerInput] = useState(0);
  const [triggerVasInput, setTriggerVasInput] = useState(0);
  const [discardModalIsOpen, setDiscardModalIsOpen] = useState(false);
  const [triggerRefetch, setTriggerRefetch] = useState(0);
  const [formInputs, setFormInputs] = useState<CostSavingsField[]>([]);
  const [reminderIsOpen, setReminderIsOpen] = useState(false);
  const [reminderDate, setReminderDate] = useState(0);
  const [reviewAfterEntry, setReviewAfterEntry] = useState(false);
  const [clientSignatureIsOpen, setClientSignatureIsOpen] = useState(false);
  const [askForSignature, setAskForSignature] = useState('');
  const [signatureModalIsOpen, setSignatureModalIsOpen] = useState(false);

  // #region API, flags and text
  const hasCustomerSelected = !isEmpty(miLoc) && !isEmpty(customerNo);
  const viewMode = mode === 'view';
  const entryMode = mode === 'entry';
  const editMode = mode === 'edit' && hasCustomerSelected && !isEmpty(vasCtlNo);
  const displayForm = (entryMode || editMode) && !reviewAfterEntry;

  const {
    customerData,
    agreementInformation,
    error: customerError,
    isLoading: customerLoading,
  } = useGetAgreementInformation({
    miLoc,
    id: customerNo,
  });

  const [selCustomer, setSelCustomer] = useState<SelectModalItem>({
    title: customerData ? customerData?.name || '' : '',
    key: miLoc && customerNo ? `${miLoc}${customerNo}` : '',
  });

  const isCorpAccount = getIsCorpAccount(customerNo, customerData?.natlAcctNo);
  const earMode =
    agreementInformation?.costSavingsCommitment?.useApprovalRouting === 'Y';

  const ccEmail = isCorpAccount ? undefined : customerData?.motionRep?.email;

  const {
    costSavings: costSavingsDetails,
    templateFields,
    instructions: csInstructions,
    refetch: detailsRefetch,
    error: costSavingsError,
    isLoading: detailsLoading,
  } = useGetCostSavingsDetails({
    miLoc,
    vasCtlNo,
    enabled: editMode || viewMode,
  });

  const {
    data: attachmentsData,
    refetch: attachmentsRefetch,
    error: attachmentsError,
    isLoading: attachmentsLoading,
  } = useFindAttachments({
    domain: 'vas',
    miLoc,
    ctlNo: vasCtlNo,
    enabled: editMode,
  });

  const { onSubmitCostSavings, status } = useSubmitCostSavings();

  const isSignatureMode = askForSignature === 'Y';

  let pageTitle = t('costSaving');
  if (entryMode) {
    pageTitle = t('costSavingsEntry');
  }
  if (editMode) {
    pageTitle = t('costSavingsEditDraft');
  }
  if (reviewAfterEntry) {
    pageTitle = t('costSavingsReview');
  }

  let footerActionButtonText = t('review');

  if (reviewAfterEntry) {
    footerActionButtonText = t('common:submit');
    if (!earMode && isEmpty(askForSignature)) {
      footerActionButtonText = t('continue');
    }
    if (!earMode && isSignatureMode) {
      footerActionButtonText = t('common:save');
    }
  }

  let footerSecondaryButtonText = t('saveDraft');
  if (reviewAfterEntry) {
    footerSecondaryButtonText = t('edit');
  }
  if (isSignatureMode) {
    footerSecondaryButtonText = t('common:cancel');
  }
  const editIsLoading = editMode && (detailsLoading || attachmentsLoading);
  const viewIsLoading = viewMode && detailsLoading;
  const ccEmailParams = ccEmail
    ? {
        email: {
          cc: [ccEmail],
        },
      }
    : undefined;

  const setCustomerInfo = (o: SelectModalItem, clear = false) => {
    if (o.key) {
      setMiLoc(o.key.substring(0, 4));
      setCustomerNo(o.key.substring(4));
    } else if (clear) {
      setMiLoc('');
      setCustomerNo('');
    }
    setSelCustomer(o);
  };
  // #endregion

  // #region form initialization
  const {
    values,
    errors,
    dirty,
    setFieldValue,
    setFieldError,
    handleSubmit,
    resetForm,
  } = useFormik<CostSavingsEntryForm & Dictionary<unknown>>({
    initialValues: {
      costSavingsBlitz: 'N',
      supplierAssistance: 'N',
    },
    onSubmit: () => {
      const isDraft = reminderDate > 0;
      const body: SubmitCostSavingsBody = {
        ...reduce(
          formInputs,
          (prev, field) => {
            const fieldName = field.miProColumnName || field.columnName;
            const fieldValue = get(values, field.columnName);
            const inputType = getInputType(
              fieldName,
              field.dataType,
              field.fastFindType
            );
            if (isObject(fieldValue)) {
              return prev;
            }
            if (inputType === 'date') {
              return {
                ...prev,
                [fieldName]: formatDate(
                  toNumber(fieldValue),
                  DateFormatEnum.standardDate
                ),
              };
            }
            return { ...prev, [fieldName]: toString(fieldValue) };
          },
          {}
        ),
        miLoc,
        customerNo: !isCorpAccount ? customerNo : '',
        corpAcctNo: isCorpAccount ? customerNo : '',
        vasCtlNo: values.vasCtlNo,
        vasCode: toString(values.VAS_CODE?.key),
        ...(values.vasCtlNo
          ? { newVasCode: toString(values.VAS_CODE?.key) }
          : undefined),
        employeeId: values.employeeId || employeeNumber,
        vas_creationUserName: values.motionContact || employeeName,
        vas_description: values.VAS_CODE?.title,
        ...(isDraft
          ? {
              reminderDate: formatDate(
                reminderDate,
                DateFormatEnum.standardDate
              ),
            }
          : undefined),
        costSavingsStatus: isDraft ? 'I' : 'P',
        filesToUpload: values.VAS_ATTACHMENTS_ADDED as File[],
        filesToDelete: values.VAS_ATTACHMENTS_REMOVED as string[],
        ...(values.signature
          ? {
              signature: values.signature,
              signatureName: values.signatureName,
              sendEmail: 'S',
              ...ccEmailParams,
            }
          : undefined),
        ...(askForSignature === 'N'
          ? {
              sendEmail: 'A',
              ...ccEmailParams,
            }
          : undefined),
      };
      onSubmitCostSavings(body);
    },
    validateOnChange: false,
    validateOnBlur: true,
  });

  useIonViewWillEnter(() => {
    if (resetView && entryMode) {
      setMiLoc(routeMiLoc);
      setCustomerNo(routeId);
      setSelCustomer({ title: '', key: '' });
      setReviewAfterEntry(false);
      setTriggerVasInput(0);
      resetForm({});
      history.replace(
        concatRoutes(searchURL(), costSavingsURL(routeMiLoc, routeId))
      );
    }
  }, [routeMiLoc, routeId, resetView, entryMode]);

  const vasCode = toString(values.VAS_CODE?.key);
  useIonViewDidEnter(() => {
    if (entryMode && hasCustomerSelected && !vasCode) {
      setTimeout(() => {
        setTriggerVasInput(Date.now());
      }, 600);
    }
    if (entryMode && !hasCustomerSelected && !canCorpCostSavingsEntry) {
      setTimeout(() => {
        setTriggerCustomerInput(Date.now());
      }, 300);
    }
  }, [hasCustomerSelected, entryMode, vasCode]);

  // DOC: open vas modal if no vas and customer has been cleared and selected again
  useEffect(() => {
    if (entryMode && hasCustomerSelected && !vasCode && triggerVasInput === 0) {
      setTimeout(() => {
        setTriggerVasInput(Date.now());
      }, 600);
    }
  }, [entryMode, hasCustomerSelected, triggerVasInput, vasCode]);

  const backFromReview = () => {
    setReviewAfterEntry(false);
    setTriggerVasInput(0);
  };

  const customBackButtonClick = () => {
    if (isSignatureMode) {
      setAskForSignature('');
    } else if (reviewAfterEntry) {
      backFromReview();
    } else if (
      (dirty || (!routeMiLoc && !routeId && miLoc && customerNo)) &&
      !viewMode
    ) {
      setDiscardModalIsOpen(true);
    } else {
      goBack();
    }
  };

  useEffect(() => {
    if (status === 'success') {
      setReminderIsOpen(false);
      setSignatureModalIsOpen(false);
      goBack();
    }
  }, [status, goBack]);
  // #endregion

  // #region set data from details API
  // DOC: set formData from details API
  useEffect(() => {
    if (!isNil(costSavingsDetails)) {
      const {
        vasCode: detailsVasCode,
        vasDesc,
        reminderDate: detailReminderDate,
      } = costSavingsDetails;
      resetForm({
        values: {
          ...(costSavingsDetails as unknown as CostSavingsEntryForm),
          VAS_CODE: {
            key: detailsVasCode,
            description: detailsVasCode,
            title: tail(vasDesc?.split('-'))
              .map((key) => trim(key))
              .join(),
          },
        },
      });
      setReminderDate(getDateValue(toString(detailReminderDate?.time)));
      setTriggerRefetch(Date.now());
    }
  }, [resetForm, costSavingsDetails]);

  // DOC: transform from miProColumnName to columnName, set attachments
  useEffect(() => {
    if (
      !isNil(costSavingsDetails) &&
      !isNil(templateFields) &&
      (!editMode || !isNil(attachmentsData)) &&
      !isNil(values.VAS_TEMPLATE) &&
      triggerRefetch > 0
    ) {
      const {
        dateOfService,
        employeeId,
        motionContact,
        custName,
        custTitle,
        custEmailAddress,
        supplierAssistanceMfrCtlNo,
        supplierAssistanceMfrName,
      } = costSavingsDetails;
      resetForm({
        values: {
          ...values,
          dateOfService: getDateValue(dateOfService?.time),
          employeeId,
          motionContact,
          custContactName_DROPDOWN: {
            key: custName,
            title: custName,
          },
          custContactName: custName,
          custContactEmail: custEmailAddress,
          custContactTitle: custTitle,
          supplierMfrCtlNo_DROPDOWN: {
            key: supplierAssistanceMfrCtlNo,
            title: supplierAssistanceMfrName,
          },
          supplierMfrCtlNo: supplierAssistanceMfrCtlNo,
          ...reduce(
            templateFields,
            (prev, field) => {
              const fieldValue = get(values, toString(field.miProColumnName));
              let fieldTitle = '';
              if (toString(field.miProColumnName) === `originalMfrCtlNo`) {
                fieldTitle = toString(values.originalMfrName);
              }
              if (toString(field.miProColumnName) === `newMfrCtlNo`) {
                fieldTitle = toString(values.newMfrName);
              }
              if (toString(field.miProColumnName) === `originalItemNo`) {
                fieldTitle = fieldTitle.concat(
                  toString(values.originalMfrPartNo),
                  ' ',
                  toString(values.originalMfrName),
                  ' ',
                  toString(values.originalGroupSerial)
                );
              }
              if (toString(field.miProColumnName) === `newItemNo`) {
                fieldTitle = fieldTitle.concat(
                  toString(values.newMfrPartNo),
                  ' ',
                  toString(values.newMfrName),
                  ' ',
                  toString(values.newGroupSerial)
                );
              }
              const inputType = getInputType(
                field.columnName,
                field.dataType,
                field.fastFindType
              );
              const selectType = includes(selectTypes, inputType);
              if (isObject(fieldValue) || !fieldValue) {
                return prev;
              }
              // TODO: handle dates in template fields
              return {
                ...prev,
                [field.columnName]: toString(fieldValue),
                ...(selectType
                  ? {
                      [`${field.columnName}_DROPDOWN`]: {
                        key: toString(fieldValue),
                        title: toString(fieldTitle),
                      },
                    }
                  : undefined),
              };
            },
            {}
          ),
          VAS_ATTACHMENTS_ADDED: [],
          VAS_ATTACHMENTS_REMOVED: [],
          VAS_ATTACHMENTS: map(
            attachmentsData,
            (file) =>
              ({
                ...file,
                fileName: file.USER_FILE,
                name: file.USER_FILE,
                type: last(file.USER_FILE?.split('.')),
              } as MiProFile)
          ),
        },
      });
      setTriggerRefetch(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    editMode,
    costSavingsDetails,
    templateFields,
    attachmentsData,
    values.VAS_TEMPLATE,
    triggerRefetch,
  ]);

  useIonViewWillEnter(() => {
    if (resetView && (editMode || viewMode)) {
      void detailsRefetch?.();
      void attachmentsRefetch?.();
      history.replace(
        concatRoutes(
          searchURL(),
          costSavingsURL(routeMiLoc, routeId),
          costSavingsDetailsURL(mode, vasCtlNo)
        )
      );
    }
  }, [resetView, editMode, viewMode]);
  // #endregion

  const {
    data: templateData,
    isLoading: templateLoading,
    error: templateError,
  } = useGetCostTamplate({
    miLoc,
    customerNo,
    vasCode: vasCode || toString(costSavingsDetails?.vasCode),
  });

  const reviewFields = useMemo(
    () => [
      {
        dataType: CostSavingsDataTypeEnum.text,
        description: t('costSavings:vasInformation'),
        displayName: t('costSavings:vasInformation'),
        columnName: 'template',
        value: `${toString(values.VAS_CODE?.title)} (${toString(
          values.VAS_CODE?.key
        )})`,
        additionalValue: values.VAS_TEMPLATE?.instructions || csInstructions,
        customContent: <VasCodeLine templateData={templateData} />,
      },

      ...map(formInputs, (field) => {
        const fieldValue = toString(get(values, field.columnName));
        const inputType = getInputType(
          field.columnName,
          field.dataType,
          field.fastFindType
        );
        const selectType = includes(selectTypes, inputType);
        const contactSelectType = includes(contactSelectTypes, inputType);
        let value = fieldValue;
        if (inputType === 'date') {
          value = formatDate(toNumber(fieldValue), DateFormatEnum.fullDate);
        }
        if (contactSelectType) {
          value = toString(get(values, `${field.columnName}_DROPDOWN.title`));
        } else if (selectType && fieldValue) {
          if (inputType === 'productFastFind') {
            value = `(${removeLeadingZeros(fieldValue)}) ${toString(
              get(values, `${field.columnName}_DROPDOWN.title`)
            )}`;
          } else {
            value = `(${fieldValue}) ${toString(
              get(values, `${field.columnName}_DROPDOWN.title`)
            )}`;
          }
        }
        return { ...field, value };
      }),
    ],
    [t, values, csInstructions, templateData, formInputs]
  );
  const signatureFields: CostSavingsField[] = [
    {
      dataType: CostSavingsDataTypeEnum.text,
      description: t('pleaseEnterYourName'),
      displayName: t('common:name'),
      required: 'Y',
      columnName: 'signatureName',
    },
    {
      dataType: CostSavingsDataTypeEnum.checkbox,
      description: t('acknowledge'),
      displayName: t('agreement'),
      required: 'Y',
      columnName: 'signatureAgreement',
    },
  ];

  const getFormIsValid = (inputs: CostSavingsField[]) =>
    size(filter(inputs, ({ columnName }) => !!errors[columnName])) === 0 &&
    size(
      filter(
        inputs,
        ({ required, columnName }) =>
          required === 'Y' && isEmpty(toString(values[columnName]))
      )
    ) === 0;

  const error =
    customerError || costSavingsError || attachmentsError || templateError;
  const isSubmitting = status === 'loading' || status === 'success';
  const isValid =
    miLoc &&
    customerNo &&
    vasCode &&
    getFormIsValid(formInputs) &&
    !errors.VAS_ATTACHMENTS;
  const isSignatureValid =
    !isSignatureMode || (getFormIsValid(signatureFields) && values.signature);

  const submitDisabled =
    !isValid ||
    isSubmitting ||
    editIsLoading ||
    viewIsLoading ||
    templateLoading;

  const resetReminderDate = () => {
    let reminderValue = 0;
    if (costSavingsDetails?.reminderDate) {
      reminderValue = getDateValue(
        toString(costSavingsDetails?.reminderDate?.time)
      );
    }
    setReminderDate(reminderValue);
  };

  const reminderError =
    reminderDate > 0 && !reminderIsValid(reminderDate)
      ? t('reminderError')
      : '';

  const askForSignatureAfterReview = (value: string) => {
    setAskForSignature(value);
    setClientSignatureIsOpen(false);
    setReviewAfterEntry(true);
  };

  const onCustomerSelectClose = () => {
    if (!selCustomer.title) {
      setCustomerInfo(selCustomer, true);
    }
  };

  const shortName = getCustomerShortName(customerData?.compressedName);

  return (
    <IonPage className={classes.container} data-testid="cost-savings-page">
      <Header
        testid="cost-savings-header"
        customTitle={<CustomerName customerData={customerData} />}
        hideMenuButton
        backButton={{ onClick: () => customBackButtonClick() }}
      />
      <IonContent
        className={classNames(classes.costSavings, {
          [classes.viewMode]: viewMode || reviewAfterEntry,
        })}
        scrollEvents
      >
        <Header
          pageTitle={{ text: pageTitle, className: classes.title }}
          collapse="condense"
          className={classes.header}
          testid="cost-savings-codensed-header"
          customTitle={
            <>
              {!hasCustomerSelected && (
                <>
                  <CustomerSelect
                    className={classes.lineHeading}
                    label={t('selectCustomerLabel')}
                    placeholder={t('selectCustomer')}
                    iconClassName={classes.magnifier}
                    setCustomerInfo={setCustomerInfo}
                    onClose={onCustomerSelectClose}
                    triggerInput={triggerCustomerInput}
                    showTabOptions={canCorpCostSavingsEntry}
                    selItem={selCustomer}
                    testid="cust"
                  />
                  {canCorpCostSavingsEntry && (
                    <CustomerSelect
                      defaultSelected={
                        CustomerSearchTabTypeEnum.CorporateSearch
                      }
                      className={classes.lineHeading}
                      label={t('selectCorpAcctLabel')}
                      placeholder={t('selectCorpAcct')}
                      setCustomerInfo={setCustomerInfo}
                      onClose={onCustomerSelectClose}
                      iconClassName={classes.magnifier}
                      selItem={selCustomer}
                      testid="corp"
                    />
                  )}
                </>
              )}
              {hasCustomerSelected && !error && (
                <div>
                  <IonRow className={classes.customerRows}>
                    <IonCol className={classes.customerCols}>
                      {customerLoading ? (
                        <IonSkeletonText style={{ height: 20, width: 200 }} />
                      ) : (
                        <SnapshotLink
                          className={classNames({
                            [classes.snapshotLink]: canCorpCostSavingsEntry,
                          })}
                          customerNameClass={classes.customerName}
                          customerNoClass={classes.customerNoClass}
                          eyebrow={`${miLoc}${customerNo}${shortName}`}
                          href={concatRoutes(
                            searchURL(),
                            searchCustomerURL(miLoc, customerNo)
                          )}
                          text={customerData?.name}
                          withIcon={false}
                          testid="customer-snapshot-button"
                          customContentClassName={classes.editButtonWrapper}
                          customContent={
                            entryMode &&
                            !reviewAfterEntry && (
                              <Button
                                variant="link"
                                className={classes.editButton}
                                text={t('common:edit')}
                                testid="edit-customer-button"
                                disabled={isSubmitting || editIsLoading}
                                onClick={() => setIsEditOpen(true)}
                                rightIcon="pencil"
                              />
                            )
                          }
                        />
                      )}
                    </IonCol>
                  </IonRow>
                  {canCorpCostSavingsEntry && (
                    <IonRow className={classes.customerRows}>
                      <IonCol
                        className={classNames(
                          classes.customerCols,
                          classes.corpCol
                        )}
                      >
                        <Text
                          className={classes.customerNoClass}
                          variant="content-default"
                          text={`Corp Acct: ${formatKeyValue({
                            value: customerData?.natlAcctNo,
                          })}`}
                        />
                      </IonCol>
                    </IonRow>
                  )}
                  <CostSavingsIndicator
                    earMode={earMode}
                    className={classes.indicator}
                  />

                  <CustomerSelect
                    showInput={false}
                    className={classes.lineHeading}
                    label={t('selectCustomerLabel')}
                    placeholder={t('selectCustomer')}
                    iconClassName={classes.magnifier}
                    setCustomerInfo={setCustomerInfo}
                    onClose={onCustomerSelectClose}
                    openModal={isEditOpen}
                    setOpenModal={setIsEditOpen}
                    showTabOptions={canCorpCostSavingsEntry}
                    selItem={{
                      key: selCustomer
                        ? selCustomer.key
                        : `${miLoc}${customerNo}`,
                      title: selCustomer
                        ? selCustomer.title
                        : toString(customerData?.name),
                      description: selCustomer
                        ? selCustomer.title
                        : toString(customerData?.name),
                      type: isCorpAccount
                        ? CustomerSearchTabTypeEnum.CorporateSearch
                        : CustomerSearchTabTypeEnum.CustomerSearch,
                    }}
                    testid="cust-selected"
                  />
                </div>
              )}
            </>
          }
        />
        <Loader
          className={classes.loader}
          isOpen={editIsLoading && !viewMode}
        />
        {error && (
          <WarningMessage
            className={classes.warningMessage}
            title={t('genericError')}
            body={getErrorMessage(error)}
          />
        )}
        {!error && (
          <>
            <div
              style={{
                display:
                  hasCustomerSelected &&
                  displayForm &&
                  !editIsLoading &&
                  !isSignatureMode
                    ? 'block'
                    : 'none',
              }}
            >
              <CostSavingsForm
                miLoc={miLoc}
                customerNo={customerNo}
                formData={values}
                formErrors={errors}
                setFieldValue={setFieldValue}
                setFieldError={setFieldError}
                disabled={isSubmitting}
                formInputs={formInputs}
                setFormInputs={setFormInputs}
                triggerVasInput={triggerVasInput}
                triggerRefetch={triggerRefetch}
              />
            </div>
            {(viewMode || reviewAfterEntry) && !isSignatureMode && (
              <CostSavingsReview
                fields={reviewFields as ReviewField[]}
                // DOC: display loader until form is reset
                isLoading={viewIsLoading || !values.custContactName_DROPDOWN}
              />
            )}
            {isSignatureMode && (
              <CostSavingsSignature
                signatureFields={signatureFields}
                formData={values}
                formErrors={errors}
                setFieldValue={setFieldValue}
                setFieldError={setFieldError}
                disabled={isSubmitting}
              />
            )}
          </>
        )}
        <SheetModal
          className={classes.modal}
          isOpen={reminderIsOpen}
          initialBreakpoint={0.33}
          setIsOpen={setReminderIsOpen}
          title={t('setReminder')}
          onClose={resetReminderDate}
          withRightCloseButton
          testid="set-reminder-modal"
        >
          <div className={classes.reminderWrapper}>
            <DateInput
              inputRowClassName={classes.dateInput}
              name="reminder-date"
              label={t('common:date')}
              placeholder={t('enterDate')}
              value={reminderDate}
              setValue={(date) => {
                let reminderValue = date;
                if (!reminderIsValid(reminderValue)) {
                  reminderValue = getUnixTime(new Date());
                }
                setReminderDate(reminderValue);
              }}
              minDate={new Date()}
              error={reminderError}
              disabled={isSubmitting}
              testid="custom-date-picker"
            />
            <IonRow className={classes.reminderFooter}>
              <Button
                variant="secondary"
                text={t('common:cancel')}
                disabled={isSubmitting}
                onClick={() => {
                  resetReminderDate();
                  setReminderIsOpen(false);
                }}
                testid="cancel-reminder-button"
              />
              <Button
                variant="action"
                text={t('common:applySubmit')}
                onClick={() => handleSubmit()}
                disabled={reminderDate === 0 || isSubmitting || !!reminderError}
                testid="apply-button"
              />
            </IonRow>
          </div>
        </SheetModal>
        <SheetModal
          title={t('acceptSubmit')}
          titleTextVariant="mipro-h3-headline"
          className={classes.discardModal}
          titleClassName={classes.discardTitle}
          contentClass={classes.discardContent}
          isOpen={signatureModalIsOpen}
          setIsOpen={setSignatureModalIsOpen}
          initialBreakpoint={0.55}
          breakpoints={[0, 0.3, 0.55, 1]}
          backdropDismiss={false}
          withRightCloseButton
          testid="accept-submit-modal"
        >
          <IonRow className={classes.msgRow}>
            <Text
              className={classes.discardMsg}
              variant="mipro-body-copy"
              text={t('acceptSubmitMsg')}
            />
          </IonRow>
          <IonRow className={classes.msgRow}>
            <IonImg src={values.signature} className={classes.signature} />
          </IonRow>
          <IonRow className={classes.msgRow}>
            <Text variant="mipro-body-copy-bold" text="Name" />
          </IonRow>
          <IonRow className={classes.signatureNameWrapper}>
            <Text
              variant="mipro-body-copy"
              text={toString(values.signatureName)}
            />
          </IonRow>
          <Button
            className={classNames(classes.discardButton, classes.primaryButton)}
            variant="action"
            text={t('acceptSubmit')}
            disabled={isSubmitting}
            onClick={() => handleSubmit()}
            testid="discard-primary-button"
          />
          <Button
            className={classNames(
              classes.discardButton,
              classes.secondaryButton
            )}
            variant="secondary"
            text={t('backToSignature')}
            disabled={isSubmitting}
            onClick={() => setSignatureModalIsOpen(false)}
            testid="discard-secondary-button"
          />
        </SheetModal>
        <SheetModal
          title={t('discardConfirmationTitle')}
          titleTextVariant="mipro-h3-headline"
          className={classes.discardModal}
          titleClassName={classes.discardTitle}
          contentClass={classes.discardContent}
          isOpen={discardModalIsOpen}
          setIsOpen={setDiscardModalIsOpen}
          initialBreakpoint={0.35}
          backdropDismiss={false}
          withRightCloseButton
          testid="discard-changes-modal"
        >
          <IonRow className={classes.msgRow}>
            <Text
              className={classes.discardMsg}
              variant="mipro-body-copy"
              text={t('common:discardMsg')}
            />
          </IonRow>
          <Button
            className={classNames(classes.discardButton, classes.primaryButton)}
            variant="action"
            text={t('goBackToEdit')}
            onClick={() => setDiscardModalIsOpen(false)}
            testid="discard-primary-button"
          />
          <Button
            className={classNames(
              classes.discardButton,
              classes.secondaryButton
            )}
            variant="secondary"
            text={t('common:discard')}
            onClick={() => {
              setDiscardModalIsOpen(false);
              goBack();
            }}
            testid="discard-secondary-button"
          />
        </SheetModal>
        <SheetModal
          title={t('customerSignature')}
          titleTextVariant="mipro-h3-headline"
          isOpen={clientSignatureIsOpen}
          setIsOpen={setClientSignatureIsOpen}
          initialBreakpoint={0.5}
          backdropDismiss={false}
          withRightCloseButton
          className={classes.signatureModal}
          contentClass={classes.signatureModalContent}
          testid="non-ear-mode-changes-modal"
        >
          <IonRow className={classes.rowHeading}>
            <IonCol size="12">
              <Text
                text={t('customerSignatureSubText')}
                variant="content-heavy"
                testid="signature-question-text"
              />
            </IonCol>
            <IonCol size="12">
              <Text
                text={t('customerSignatureQues')}
                variant="content-heavy"
                testid="signature-question-text"
              />
            </IonCol>
          </IonRow>
          <IonRow className={classes.btnWrapper}>
            <IonCol size="12">
              <Button
                variant="action"
                text={t('yes')}
                testid="customer-signature-yes-button"
                onClick={() => {
                  askForSignatureAfterReview('Y');
                }}
                className={classes.fullWidth}
              />
            </IonCol>
            <IonCol size="12">
              <Button
                variant="secondary"
                text={t('no')}
                testid="customer-signature-no-button"
                onClick={() => {
                  askForSignatureAfterReview('N');
                  handleSubmit();
                }}
                className={classes.fullWidth}
              />
            </IonCol>
            <IonCol size="12">
              <Button
                variant="secondary"
                text={t('cancel')}
                testid="customer-signature-cancel-button"
                onClick={() => setClientSignatureIsOpen(false)}
                className={classes.fullWidth}
              />
            </IonCol>
          </IonRow>
        </SheetModal>
      </IonContent>
      {!viewMode && (
        <Footer
          buttons={[
            {
              variant: 'secondary',
              text: footerSecondaryButtonText,
              testid: 'save-draft-button',
              disabled: submitDisabled,
              onClick: () => {
                if (reviewAfterEntry) {
                  customBackButtonClick();
                } else if (entryMode || editMode) {
                  setReminderIsOpen(true);
                }
              },
            },
            {
              variant: 'action',
              text: footerActionButtonText,
              testid: 'review-button',
              disabled: submitDisabled || !isSignatureValid,
              onClick: () => {
                if (isSignatureMode) {
                  setSignatureModalIsOpen(true);
                } else if (reviewAfterEntry) {
                  setReminderDate(0);
                  if (earMode) {
                    handleSubmit();
                  } else if (!earMode && isEmpty(askForSignature)) {
                    setClientSignatureIsOpen(true);
                  } else {
                    handleSubmit();
                  }
                } else if (entryMode || editMode) {
                  setReviewAfterEntry(true);
                }
              },
            },
          ]}
        />
      )}
    </IonPage>
  );
};

export default CostSavingsEntry;
