import React from 'react';
import type { Dictionary } from 'lodash';
import { head, toString, trim } from 'lodash';
import SourcingOverrideForm from 'ActivitiesApp/components/SourcingOverrideForm/SourcingOverrideForm';
import {
  SourcingOverrideTypeEnum,
  SourcingOverrideIconEnum,
  SourcingOverrideColorEnum,
  SourcingOverrideFilterEnum,
} from 'ActivitiesApp/models/SourcingOverride';
import { getActivityDataValue } from 'ActivitiesApp/utils/helpers';
import type { Contact, Recipient } from 'models/Contact';
import generateEmailList from 'utils/generateEmailList';
import type {
  ActionCardConfig,
  ActivityTypeConfig,
  GetActivityConfigExtendedProps,
  GetActivityTypeDataProps,
} from 'pages/Activities/ActivityActionCard/ActivityCardConfig';

export const getSourcingOverrideConfig = ({
  activityVersion,
  activityData,
  defaultFirstLine,
  t,
}: GetActivityConfigExtendedProps): ActionCardConfig | undefined => {
  const { fieldsData } = activityData;
  const orderNumber = getActivityDataValue(
    fieldsData?.['Order Control Number']
  );
  const sourceOverrideRequestor = getActivityDataValue(
    fieldsData?.['Requestor Name'] || fieldsData?.['Requestor Userid']
  );
  const sourceName = getActivityDataValue(fieldsData?.['Supplier Name']);
  const sourceCity = getActivityDataValue(fieldsData?.City);
  const sourceState = getActivityDataValue(fieldsData?.State);

  const sourceOverrideContacts: Recipient[] = generateEmailList({
    name: trim(toString(fieldsData?.['Requestor Name'])),
    rawEmailList: trim(toString(fieldsData?.['Contact Email'])),
    splitChar: ';',
  });
  const emailContact = head(sourceOverrideContacts) as Contact;
  const emailContactName = emailContact?.name || emailContact?.email;

  const modalBody = {
    hideSaveButton: true,
    refetchActivity: true,
    contactEmail: sourceOverrideContacts,
    defaultData: {
      orderNumber,
      emailContactName,
      emailContact,
    },
    customContent: (props: Dictionary<unknown>) => (
      <SourcingOverrideForm
        historyId={activityData.historyId}
        userId={activityData.userId}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    ),
  };

  switch (activityVersion) {
    case `${SourcingOverrideTypeEnum.sourcingOverrideAccepted}-1`:
    case `${SourcingOverrideTypeEnum.sourcingOverrideAccepted}-2`:
      return {
        card: {
          defaultTitle: t('sourcingOverrideAccepted'),
          body: {
            description: t('sourcingOverrideDesc', {
              sourceOverrideRequestor,
              orderNumber,
            }),
            lines: [
              ...defaultFirstLine,
              {
                description: t('sourcingRequestedByDescription', {
                  sourceOverrideRequestor,
                }),
                highlight: t('sourcingRequestedBy'),
              },
              {
                description: t('sourcingLocationDescription', {
                  sourceName,
                  sourceCity,
                  sourceState,
                }),
                highlight: t('sourcingLocation'),
              },
            ],
          },
        },
        modal: { title: t('sourcingOverrideAccepted'), ...modalBody },
      };
    case `${SourcingOverrideTypeEnum.sourcingOverrideRejected}-1`:
    case `${SourcingOverrideTypeEnum.sourcingOverrideRejected}-2`:
      return {
        card: {
          defaultTitle: t('sourcingOverrideRejected'),
          body: {
            description: t('sourcingOverrideDesc', {
              sourceOverrideRequestor,
              orderNumber,
            }),
            lines: [
              ...defaultFirstLine,
              {
                description: t('sourcingRequestedByDescription', {
                  sourceOverrideRequestor,
                }),
                highlight: t('sourcingRequestedBy'),
              },
              {
                description: t('sourcingLocationDescription', {
                  sourceName,
                  sourceCity,
                  sourceState,
                }),
                highlight: t('sourcingLocation'),
              },
            ],
          },
        },
        modal: { title: t('sourcingOverrideRejected'), ...modalBody },
      };
    case `${SourcingOverrideTypeEnum.sourcingOverrideCreated}-1`:
    case `${SourcingOverrideTypeEnum.sourcingOverrideCreated}-2`:
      return {
        card: {
          defaultTitle: t('sourcingOverrideCreated'),
          body: {
            description: t('sourcingOverrideDesc', {
              sourceOverrideRequestor,
              orderNumber,
            }),
            lines: [
              ...defaultFirstLine,
              {
                description: t('sourcingRequestedByDescription', {
                  sourceOverrideRequestor,
                }),
                highlight: t('sourcingRequestedBy'),
              },
              {
                description: t('sourcingLocationDescription', {
                  sourceName,
                  sourceCity,
                  sourceState,
                }),
                highlight: t('sourcingLocation'),
              },
            ],
          },
        },
        modal: { title: t('sourcingOverrideCreated'), ...modalBody },
      };
    default:
      return undefined;
  }
};

export const getSourcingOverrideData = ({
  eventTagName,
  t,
}: GetActivityTypeDataProps): ActivityTypeConfig | undefined => {
  switch (eventTagName) {
    case 'SourcingOverrideCreated':
      return {
        cardType: SourcingOverrideTypeEnum.sourcingOverrideCreated,
        title: t('sourcingOverrideCreated'),
        icon: SourcingOverrideIconEnum.sourcingOverrideCreated,
        color: SourcingOverrideColorEnum.sourcingOverrideCreated,
      };
    case 'SourcingOverrideAccepted':
      return {
        cardType: SourcingOverrideTypeEnum.sourcingOverrideAccepted,
        title: t('SourcingOverrideAccepted'),
        icon: SourcingOverrideIconEnum.sourcingOverrideAccepted,
        color: SourcingOverrideColorEnum.sourcingOverrideAccepted,
      };
    case 'SourcingOverrideRejected':
      return {
        cardType: SourcingOverrideTypeEnum.sourcingOverrideRejected,
        title: t('sourcingOverrideRejected'),
        icon: SourcingOverrideIconEnum.sourcingOverrideRejected,
        color: SourcingOverrideColorEnum.sourcingOverrideRejected,
      };
    default:
      return undefined;
  }
};

export const getSourcingOverrideFilterIcon = (
  type: string
): SourcingOverrideIconEnum | undefined => {
  switch (type) {
    case SourcingOverrideFilterEnum.sourceOverride:
      return SourcingOverrideIconEnum.sourcingOverrideCreated;
    default:
      return undefined;
  }
};
