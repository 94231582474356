/* istanbul ignore file */

export enum FilterSectionEnum {
  checkbox,
  radiogroup,
  search,
  dateRange,
}

export interface FilterOption {
  key: string;
  text: string;
  checked?: boolean;
}

export interface FilterSection {
  key: string;
  label: string;
  type: FilterSectionEnum;
  selectedValue?: string;
  options?: FilterOption[];
  updateFunction?: (key: string, value: unknown) => void;
}
