import React, { useState } from 'react';
import classNames from 'classnames';
import { get, isNil, map, toString } from 'lodash';
import { IonCol, IonRow } from '@ionic/react';
import type { GroupInput } from 'models/DocumentTemplate';
import type {
  ReportRow,
  ReportRowsValues,
  ReportJson,
  ReportValue,
} from 'models/InspectionReport';
import Button from 'components/Button/Button';
import type DocumentImage from 'components/Documents/DocumentImage/DocumentImage';
import DocumentInput from 'components/Documents/DocumentInput/DocumentInput';
import Text from 'components/Text/Text';
import classes from './DocumentSubContainer.module.scss';

export interface DocumentSubContainerProps {
  reportId: number;
  groupId: number;
  fields: GroupInput[];
  inputId: number;
  title: string;
  containerValues?: ReportValue[];
  setHasData: (hasData: boolean) => void;
  onFormUpdate: (formData: Record<number, ReportValue>) => void;
  mainRow: number;
  mainName: string;
  setRecValue: (
    value: ReportJson | string,
    name: string,
    row: number,
    defaultValue?: string,
    isCalc?: boolean
  ) => void;
  custNo: string;
  miLoc: string;
  needSync?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  type?: string;
  listType?: string;
  itemListData?: object;
  noPadding?: boolean;
}

const DocumentSubContainer = ({
  reportId,
  fields,
  inputId,
  groupId,
  title,
  containerValues,
  setHasData,
  onFormUpdate,
  mainRow,
  mainName,
  setRecValue,
  custNo,
  miLoc,
  needSync,
  disabled,
  multiple,
  type,
  listType,
  itemListData,
  noPadding,
  ...imgProps
}: DocumentSubContainerProps &
  React.ComponentProps<typeof DocumentImage>): JSX.Element => {
  const initialValues = () => {
    const data: Record<number, ReportValue> = {};
    const reportValue: ReportValue = {
      reportId,
      groupId,
      inputId,
      inputValue: '',
    };
    if (containerValues) {
      map(
        containerValues.filter(
          (specificContainerValue) => inputId === specificContainerValue.inputId
        ),
        (specificReportValue: ReportValue) => {
          const reportRowsValues: ReportRowsValues = {
            rows: [],
          };
          const specificRow: ReportRow = specificReportValue.inputJson?.rows[
            mainRow
          ] || { row: [] };
          const rowToUpdate: ReportRow = {
            row: [],
          };
          const reportJsonList: ReportJson = specificRow.row.find((rowTmp) => {
            return mainName === rowTmp.name;
          }) || { name: '', rows: [] };
          const reportJsonToUpdate = reportJsonList.rows.filter((tmp) => {
            return tmp.row.find((tmp2) => {
              return tmp2.value || (tmp2.values && tmp2.values.length > 0);
            });
          });

          const rptJson: ReportJson = {
            name: mainName,
            value: '',
            values: [],
            rows: reportJsonToUpdate || { rows: [] },
          };
          rowToUpdate.row.push(rptJson);
          if (rptJson.rows.length > 0) {
            reportRowsValues.rows.push(rowToUpdate);
          }

          reportValue.inputJson = reportRowsValues;
        }
      );
    }
    if (!reportValue.inputJson) {
      reportValue.inputJson = {
        rows: [],
      };
    }
    const row2: ReportRow = {
      row: [],
    };
    map(fields, (emptyGroupInput: GroupInput) => {
      const rptJson: ReportJson = {
        name: emptyGroupInput.name || '',
        value: '',
        values: [],
        rows: [],
      };
      row2.row.push(rptJson);
    });
    // add blank line
    if (reportValue.inputJson.rows.length === 0) {
      reportValue.inputJson.rows.push({ row: [] });
      reportValue.inputJson.rows[0].row.push({
        name: mainName,
        value: '',
        values: [],
        rows: [],
      });
    }
    reportValue.inputJson.rows[0].row[0].rows.push(row2);
    data[0] = reportValue;

    return data;
  };

  const addNewContainer = (
    index: number,
    formData: Record<number, ReportValue>
  ) => {
    const emptyRowToAdd: ReportRow = {
      row: [],
    };
    map(fields, (groupInput: GroupInput) => {
      const rptJson: ReportJson = {
        name: groupInput.name || '',
        value: '',
        values: [],
        rows: [],
      };
      emptyRowToAdd.row.push(rptJson);
    });
    if (formData) {
      const reportJsonToAdd: ReportJson | undefined = formData[
        index
      ]?.inputJson?.rows[0].row.find((rowTmp) => {
        return mainName === rowTmp.name;
      });
      if (reportJsonToAdd) {
        reportJsonToAdd.rows.push(emptyRowToAdd);
      }
    }
    setHasData(true);
    onFormUpdate(formData);
  };

  const [formData] = useState(initialValues);

  const getFormValue = (name: string, row: number) => {
    if (listType) {
      return {
        name,
        value: toString(get(itemListData, `${name}.value`)),
        values: [],
      };
    }
    const rowToUpdate = formData[0].inputJson?.rows[0].row.find(
      (individualRow) => individualRow.name === mainName
    ) || {
      name: '',
      value: '',
      values: [],
      rows: [],
    };
    if (rowToUpdate.rows.length === 0) {
      return rowToUpdate;
    }
    return (
      rowToUpdate.rows[row].row.find(
        (specificRow) => specificRow.name === name
      ) || {
        name: '',
        value: '',
        values: [],
        rows: [],
      }
    );
  };

  const setFormValue = (
    value: string,
    name: string,
    row: number,
    values?: string[],
    defaultValue?: string,
    isCalc?: boolean
  ) => {
    if (listType) {
      setRecValue(value, name, row, defaultValue, isCalc);
      return;
    }
    const rowsToSet = formData[0].inputJson?.rows[0].row.find(
      (specificRow) => specificRow.name === mainName
    ) || {
      name: '',
      value: '',
      values: [],
      rows: [],
    };
    const specificRowToSet = rowsToSet.rows[row].row.find(
      (specificRow) => specificRow.name === name
    ) || {
      name: '',
      value: '',
      values: [],
      rows: [],
    };
    specificRowToSet.value = value;
    if (values) {
      specificRowToSet.values = values;
    }
    setHasData(true);
    setRecValue(rowsToSet, mainName, mainRow);
  };

  const items = listType
    ? [{}]
    : (get(formData, `0.inputJson.rows.0.row.0.rows`) as ReportRowsValues[]);

  return (
    <div>
      {map(items, (rowData, ndx) => (
        <div
          key={ndx}
          className={classes.documentContainer}
          style={noPadding ? { padding: 0 } : undefined}
          data-testid="DocumentSubContainer"
        >
          {title && (
            <IonRow
              className={classNames(classes.documentRow, classes.titleRow)}
              style={ndx === 0 && noPadding ? { padding: 0 } : undefined}
            >
              <IonCol
                size="12"
                className={classNames(
                  classes.containerTitle,
                  classes.documentCol
                )}
              >
                <Text text={`${title} ${ndx + 1}`} variant="content-default" />
              </IonCol>
            </IonRow>
          )}
          <div
            className={classes.inuptsWrap}
            style={
              ndx === 0 && noPadding && !title ? { padding: 0 } : undefined
            }
          >
            <div
              className={classNames({
                [classes.rowWrapper]: type === 'row',
              })}
            >
              {!isNil(fields) &&
                map(fields, (groupInput, rowNdx) => (
                  <DocumentInput
                    key={rowNdx}
                    custNo={custNo}
                    miLoc={miLoc}
                    inputId={inputId}
                    groupId={groupId}
                    disabled={disabled}
                    containerValues={containerValues}
                    setRecValue={setRecValue}
                    reportId={reportId}
                    groupInput={groupInput}
                    onFormUpdate={onFormUpdate}
                    setHasData={setHasData}
                    value={toString(getFormValue(groupInput.name, ndx)?.value)}
                    rowData={ndx}
                    values={getFormValue(groupInput.name, ndx)?.values || []}
                    setValue={(e: string, listValues?: string[]) => {
                      setFormValue(
                        e,
                        groupInput.name,
                        ndx,
                        listValues,
                        groupInput.defaultValue,
                        groupInput.additionalInformation === 'calc'
                      );
                    }}
                    needSync={needSync}
                    listType={listType}
                    itemListData={itemListData}
                    noPadding={!title}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...imgProps}
                  />
                ))}
            </div>
          </div>
        </div>
      ))}
      {multiple && (
        <IonRow className={classes.resetRow}>
          <IonCol size="auto">
            <Button
              className={classes.button}
              variant="action"
              testid="Add-Button"
              disabled={disabled}
              text={title ? `Add ${title}` : 'Add'}
              onClick={() => {
                addNewContainer(0, formData);
              }}
            />
          </IonCol>
        </IonRow>
      )}
    </div>
  );
};

export default DocumentSubContainer;
