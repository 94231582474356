import CorpCustomerSearch from 'SearchApp/pages/CorpCustomerSearch/CorpCustomerSearch';

// #region ui-routes
export const snapshotActionsURL = () => '/actions';
export const searchCorpCustomersURL = () => '/search-corp-customers';
// #endregion ui-routes

export const CorpQuickActionsRouter = [
  {
    path: snapshotActionsURL(),
    routeName: 'Snapshot Actions',
    routes: [
      {
        path: searchCorpCustomersURL(),
        routeName: 'Search Corp Customers',
        component: CorpCustomerSearch,
      },
    ],
  },
];
