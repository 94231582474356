import { isEmpty, toString } from 'lodash';
import i18next from 'i18n/i18n';
import * as yup from 'yup';

// eslint-disable-next-line import/prefer-default-export
export const CheckoutOrderSchema = (isOrder = false) => {
  let validationSchema = yup.object().shape({
    emailCustomer: yup.boolean(),
    contactName: yup.string().required(i18next.t('common:requiredError')),
    contactNumber: yup
      .string()
      .test(
        'isValidPhoneNumber',
        i18next.t('contact:phoneInvalid'),
        (value) => {
          let repValue = value || '';
          if (repValue) {
            repValue = repValue.replace(/[^0-9]/g, '');
          }
          return repValue.length === 10 || isEmpty(repValue);
        }
      ),
    typedRecipients: yup
      .array()
      // eslint-disable-next-line func-names
      .transform(function (value, originalValue) {
        if (this.isType(value) && value !== null) {
          return value;
        }
        return toString(originalValue).split(/[\s;]+/);
      })
      .of(
        yup
          .string()
          .email(({ value }) => `${toString(value)} is not a valid email`)
      )
      .when('emailCustomer', (emailCustomer, schema) => {
        if (emailCustomer?.[0]) {
          return schema.required(i18next.t('email:recipientRequired'));
        }
        return schema;
      }),
  });

  if (isOrder) {
    validationSchema = validationSchema.shape({
      poNumber: yup.string().required(i18next.t('common:requiredError')),
      shipMethodCd: yup.object().shape({
        codeValue: yup.string().required(i18next.t('common:requiredError')),
      }),
      shipChargesCd: yup.object().shape({
        codeValue: yup.string().required(i18next.t('common:requiredError')),
      }),
    });
  }

  return validationSchema;
};
