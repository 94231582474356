import { useMemo } from 'react';
import type { AxiosError } from 'axios';
import { head, map, size, toString } from 'lodash';
import type { QueryFunctionContext } from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import type {
  QueryFlags,
  QueryParamsType,
} from 'common/api/utils/useGetQueryFlags';
import useGetQueryFlags from 'common/api/utils/useGetQueryFlags';
import type { Product } from 'ProductSearchApp/models/Products';
import { useAxios } from 'providers/AxiosProvider';
import { doConcatDataPages, useKeyUserId } from 'api/helpers';
import type { InfiniteQueryFlags } from 'models/Search';
import { pageSize } from 'utils/constants';

export const findProductOpportunitiesQueryKey = 'product-opportunities';

interface UseFindProductOpportunitiesProps {
  miLoc: string;
  customerNo: string;
  productId: string;
  groupSerial: string;
  showOpportunitiesOnly: 'Y' | 'N';
  returnSCAvail: boolean;
  opportunitiesOrderBy?: number;
  limit?: number;
  enabled?: boolean;
}

interface SearchProductOpportunitiesResponse {
  total?: number;
  items?: Product[];
}

interface FindProductOpportunitiesAPIResponse {
  totalRows: number;
  products: Product[];
}

export type UseFindProductOpportunitiesResponse =
  SearchProductOpportunitiesResponse & InfiniteQueryFlags;

const useFindProductOpportunities = ({
  miLoc,
  customerNo,
  productId,
  groupSerial,
  showOpportunitiesOnly = 'Y',
  returnSCAvail = true,
  opportunitiesOrderBy,
  limit = pageSize(),
  enabled = true,
}: UseFindProductOpportunitiesProps): SearchProductOpportunitiesResponse &
  QueryFlags => {
  const { axios } = useAxios();
  const { findProductOpportunitiesAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const params: QueryParamsType = {
    groupSerial,
    returnSCAvail,
    showOpportunitiesOnly,
    limit,
  };
  if (opportunitiesOrderBy) {
    params.opportunitiesOrderBy = opportunitiesOrderBy;
  }

  const doFindProductOpportunities = async ({
    pageParam = 1,
    signal,
  }: QueryFunctionContext) => {
    const { data } = await axios.get<FindProductOpportunitiesAPIResponse>(
      findProductOpportunitiesAPI(
        miLoc,
        customerNo,
        productId,
        toString(new URLSearchParams({ ...params, page: toString(pageParam) }))
      ),
      { signal }
    );
    return {
      total: data.totalRows,
      items: map(data.products, (i) => ({
        ...i,
        mino: i.mino || `${i.manufacturerCtlNo}${i.groupSerial}`,
      })),
    };
  };

  const response = useInfiniteQuery<
    SearchProductOpportunitiesResponse,
    AxiosError
  >(
    createQueryKey(findProductOpportunitiesQueryKey, params),
    doFindProductOpportunities,
    {
      enabled,
      getNextPageParam: (lastPage, pages) =>
        size(lastPage.items) < limit ? false : size(pages) + 1,
    }
  );

  const { data } = response;

  const productOpportunities = useMemo(
    () =>
      doConcatDataPages<Product, SearchProductOpportunitiesResponse>(
        data,
        'items'
      ),
    [data]
  );

  const queryFlags = useGetQueryFlags({
    ...response,
    data: productOpportunities,
    enabled,
  });

  return {
    items: productOpportunities,
    total: head(data?.pages)?.total,
    ...queryFlags,
  };
};

export default useFindProductOpportunities;
