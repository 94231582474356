import {
  sortBy,
  find,
  includes,
  isEmpty,
  some,
  chain,
  toNumber,
  orderBy,
  size,
  toUpper,
} from 'lodash';
import { scaleNumber } from 'common/utils/numberHelper';
import {
  searchLocationDetailURL,
  searchSupplierURL,
  searchURL,
} from 'navigation';
import {
  Carrier,
  MOTION_CARRIER_LABEL,
  UPS_CARRIER_LABEL,
} from 'ProductSearchApp/models/Carrier';
import type {
  OcnLineStatus,
  OcnLineStatusItem,
  OcnLineStatusItemDetail,
} from 'ProductSearchApp/models/Order';
import { qtyScaleProps } from 'ProductSearchApp/pages/EditOrderLine/EditOrderLineSchema';
import type { FileAttachmentProp } from 'models/Attachment';
import { concatRoutes } from 'utils/navigations';

const qtyCriteria = ['RE', 'SH', 'SA', 'DT', 'PO', 'PR', 'AR'];
const carrierCriteria = ['SH', 'SM', 'SX', 'DT', 'SA'];
const notAvailableCriteria = ['SH', 'SA'];

export const getQuantityValue = (
  totalQty: string,
  status?: OcnLineStatusItem
) => {
  const sorted = sortBy(status?.details, ({ tranType }) =>
    qtyCriteria.indexOf(tranType)
  ).filter(({ tranQuantity }) => toNumber(tranQuantity));
  const closed = find(sorted, { tranType: 'RE', tranStatus: 'CL' });
  const item =
    closed ||
    find(
      sorted,
      ({ tranType }) => tranType !== 'RE' && qtyCriteria.includes(tranType)
    );

  return {
    counterQty: scaleNumber({
      number: item?.tranQuantity,
      ...qtyScaleProps,
    }),
    totalQty: scaleNumber({
      number: Number(totalQty),
      ...qtyScaleProps,
    }),
  };
};

export const getSourceValue = ({
  sourceLocType,
  sourceLoc,
  sourceName,
  miLoc,
}: OcnLineStatusItem & Pick<OcnLineStatus, 'miLoc'>) => {
  let label = `${sourceLoc} ${sourceName}`;
  let href = concatRoutes(searchURL(), searchLocationDetailURL(sourceLoc));
  if (sourceLocType === 'SP') {
    label = sourceName;
    href = concatRoutes(searchURL(), searchSupplierURL(miLoc, sourceLoc));
  }

  return {
    label,
    sourceLocType,
    href,
  };
};

export const getPoValue = (details: OcnLineStatusItemDetail[]) => {
  return find(details, ['tranType', 'PO'])?.tranPO;
};

export const getCarrierValue = (details: OcnLineStatusItemDetail[]) => {
  const ordered = sortBy(details, ({ tranType }) =>
    carrierCriteria.indexOf(tranType)
  ).find((detail) => includes(carrierCriteria, detail.tranType));
  return {
    label: ordered?.tranCarrier,
    icon: null, // TODO: Missing determine if is a know carrier and grab the logo. #128706
  };
};

export const getShipDateValue = (details: OcnLineStatusItemDetail[]) => {
  return sortBy(details, ({ tranType }) =>
    carrierCriteria.indexOf(tranType)
  ).find((detail) => includes(carrierCriteria, detail.tranType))?.tranDate;
};

export const getTrackingValues = (
  details: OcnLineStatusItemDetail[],
  trackingNotAvailText = ''
) => {
  const trackingList = chain(details)
    .flatMap(({ tranTrackingNo }) => tranTrackingNo?.split(/\s*,\s*/))
    .filter((trackingNo) => !isEmpty(trackingNo))
    .uniq()
    .value();

  const isTrackingNotAvailable =
    some(
      details,
      ({ tranType, tranTrackingNo }) =>
        includes(notAvailableCriteria, tranType) && !tranTrackingNo
    ) && isEmpty(trackingList);

  if (!isEmpty(trackingList)) {
    return trackingList;
  }

  if (isTrackingNotAvailable) {
    return [trackingNotAvailText];
  }

  return [];
};

export const getPODAttachments = (details: OcnLineStatusItemDetail[]) => {
  return orderBy(
    find(details, (v) => !isEmpty(v.proofOfDeliveryAttachments))
      ?.proofOfDeliveryAttachments || ([] as FileAttachmentProp[]),
    'creationTmstmp',
    'desc'
  );
};

export const isUPSTracking = (tracking = '') => {
  return size(tracking) >= 2 ? tracking.substring(0, 2) === '1Z' : false;
};

interface TrackingProps {
  carrierLabel: string;
}

export const getCarrierURLPrefix = ({
  carrierLabel: carrierDescription,
}: TrackingProps): { prefixUrl: string | null; icon?: string } => {
  // * most will be UPS trackno format
  let carrierDesc = carrierDescription.replace(/\s+/g, '');

  const carrierDesc01 = carrierDesc.substring(0, 1);
  carrierDesc = includes(['#', '@'], carrierDesc01)
    ? carrierDesc.substring(1)
    : carrierDesc;

  if (size(carrierDesc) < 2) {
    return Carrier.NONE;
  }
  carrierDesc = toUpper(carrierDesc);
  const carrierDesc02 = carrierDesc.substring(0, 2);
  switch (carrierDesc02) {
    case 'FE':
    case 'FD':
    case 'FX':
      return Carrier.FEDEX;
    case 'OL':
    case 'OD':
      return Carrier.OLD_DOMINION;
    case 'UP':
    case 'UN':
      return Carrier.UPS;
    default:
  }
  if (size(carrierDesc) < 3) {
    return Carrier.NONE;
  }
  const carrierDesc03 = carrierDesc.substring(0, 3);
  switch (carrierDesc03) {
    case 'USF':
    case 'YRC':
      return Carrier.HOLLAND;
    case 'R&L':
      return Carrier.RL;
    default:
  }

  if (size(carrierDesc) < 5) {
    return Carrier.NONE;
  }

  const carrierDesc05 = carrierDesc.substring(0, 5);

  switch (carrierDesc05) {
    case 'HOLLA':
    case 'REDDA':
      return Carrier.HOLLAND;
    case 'PUROL':
      return Carrier.PUROLATOR;
    case 'CENTR':
      if (carrierDesc === 'CENTRALTRANSPORT') {
        return Carrier.CENTRAL_TRANSPORT;
      }
      if (carrierDesc === 'CENTRALFREIGHTLINES') {
        return Carrier.CENTRAL_FREIGHT;
      }
      return Carrier.NONE;
    default:
  }
  return Carrier.NONE;
};

interface CarrierDisplayProps {
  hasPodValues: boolean;
  icon?: string;
  label: string;
  isUPSCarrier: boolean;
}

export const getCarrierDisplay = ({
  hasPodValues,
  icon,
  label,
  isUPSCarrier,
}: CarrierDisplayProps) => {
  let displayCarrierIcon = icon;
  let displayCarrierName = label;
  if (hasPodValues) {
    displayCarrierIcon = Carrier.MOTION.icon;
    displayCarrierName = MOTION_CARRIER_LABEL;
  } else if (!label && isUPSCarrier) {
    displayCarrierIcon = Carrier.UPS.icon;
    displayCarrierName = UPS_CARRIER_LABEL;
  }
  return { displayCarrierIcon, displayCarrierName };
};
