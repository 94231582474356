import React from 'react';
import classNames from 'classnames';
import { toNumber, toString } from 'lodash';
import { IonRow } from '@ionic/react';
import { findIcon } from 'utils/icons';
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import classes from './ItemsPOUList.module.scss';

const getMaxValue = (max: number, value: number) => {
  const result = value > 0 ? value : 0;
  return toString(!!max && value > max ? max : result);
};

interface QuantityInputProps {
  buttonClassName?: string;
}

const QuantityInput = ({
  className,
  buttonClassName,
  max,
  value,
  setValue,
  disabled,
  triggerInputSelect,
}: QuantityInputProps &
  Omit<React.ComponentProps<typeof Input>, 'testid'>): JSX.Element => (
  <IonRow className={classNames(classes.quantityInput, className)}>
    <div className={classNames(classes.button, buttonClassName)}>
      <Button
        icon={findIcon('minus-circle', 'far')}
        onClick={() => {
          const result = toNumber(value) - 1;
          setValue?.(getMaxValue(toNumber(max), result));
        }}
        disabled={disabled}
        testid="substract-button"
      />
    </div>
    <Input
      wrapperClassName={classes.quantityInputWrapper}
      disabled={disabled}
      type="number"
      inputmode="numeric"
      autocomplete="off"
      min={0}
      max={max}
      value={value}
      onClick={(e) => {
        e.stopPropagation();
      }}
      triggerInputSelect={triggerInputSelect}
      onFocus={async (e) => {
        const inputEl = await e.target.getInputElement();
        inputEl.focus();
        inputEl.select();
      }}
      setValue={setValue}
      testid="quantity-input"
    />
    <div className={classNames(classes.button, buttonClassName)}>
      <Button
        icon={findIcon('plus-circle', 'far')}
        onClick={() => {
          const result = toNumber(value) + 1;
          setValue?.(getMaxValue(toNumber(max), result));
        }}
        disabled={disabled}
        testid="add-button"
      />
    </div>
  </IonRow>
);

export default QuantityInput;
