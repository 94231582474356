import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import type { Dictionary } from '@reduxjs/toolkit';
import { ACTIVITY, HOME, SALES_PLAYS, REPORTS, SEARCH } from 'utils/constants';

interface TabResetContextProps {
  triggerActivityAnimation: () => void;
  activityTabAnimating: boolean;
  triggerResetTabMap?: Dictionary<number>;
  resetTabMap: (k: string, v?: number) => void;
  restoreTab: (k: string) => void;
}

const TabResetContext = createContext<TabResetContextProps>({
  triggerActivityAnimation: () => {},
  activityTabAnimating: false,
  triggerResetTabMap: {
    [HOME]: 0,
    [ACTIVITY]: 0,
    [SEARCH]: 0,
    [REPORTS]: 0,
    [SALES_PLAYS]: 0,
  },
  resetTabMap: () => {},
  restoreTab: () => {},
});

interface TabResetProviderProps {
  children?: React.ReactNode;
}

const TabResetProvider = ({ children }: TabResetProviderProps): JSX.Element => {
  const [activityTabAnimating, setActivityTabAnimating] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (activityTabAnimating) {
      timer = setTimeout(() => setActivityTabAnimating(false), 3000);
    }
    return () => clearTimeout(timer);
  }, [activityTabAnimating]);

  const triggerActivityAnimation = useCallback(
    () => setActivityTabAnimating(true),
    [setActivityTabAnimating]
  );
  const [triggerResetTabMap, setResetTriggerTabMap] = useState<
    Dictionary<number>
  >({});

  const resetTabMap = (k: string, v: number = Date.now()) => {
    setResetTriggerTabMap((prev) => ({ ...prev, [k]: v }));
  };
  const restoreTab = (tabName: string) => {
    resetTabMap(tabName, 0);
  };

  return (
    <TabResetContext.Provider
      value={{
        triggerActivityAnimation,
        activityTabAnimating,
        triggerResetTabMap,
        resetTabMap,
        restoreTab,
      }}
    >
      {children}
    </TabResetContext.Provider>
  );
};
export default TabResetProvider;

export const useTabReset = (): TabResetContextProps => {
  const ctx = useContext(TabResetContext);

  if (!ctx) {
    throw Error(
      'The `useTabReset` hook must be called inside a `TabResetProvider`.'
    );
  }
  return {
    triggerActivityAnimation: ctx.triggerActivityAnimation,
    activityTabAnimating: ctx.activityTabAnimating,
    triggerResetTabMap: ctx.triggerResetTabMap,
    resetTabMap: ctx.resetTabMap,
    restoreTab: ctx.restoreTab,
  };
};
