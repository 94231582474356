import React from 'react';
import { toNumber, head, toString, trim } from 'lodash';
import {
  WebActivityTypeEnum,
  WebActivityIconEnum,
  WebActivityColorEnum,
  WebActivityFilterEnum,
} from 'ActivitiesApp/models/WebActivity';
import { getActivityDataValue } from 'ActivitiesApp/utils/helpers';
import { DataTypeEnum } from 'common/utils/valueFormatter';
import i18next from 'i18next';
import {
  customerWebActivityURL,
  searchCustomerURL,
  searchURL,
} from 'navigation';
import type { Contact, Recipient } from 'models/Contact';
import { DateFormatEnum, formatSnoozeDate, formatDate } from 'utils/date';
import generateEmailList from 'utils/generateEmailList';
import { findIcon } from 'utils/icons';
import { concatRoutes } from 'utils/navigations';
import classes from 'pages/Activities/ActivityActionCard/ActivityActionCard.module.scss';
import type {
  ActionCardConfig,
  ActivityTypeConfig,
  GetActivityConfigExtendedProps,
  GetActivityTypeDataProps,
} from 'pages/Activities/ActivityActionCard/ActivityCardConfig';
import type { ActivityModalData } from 'components/Activities/ActionCardModal/ActionCardModalData';
import ActionCardModalData from 'components/Activities/ActionCardModal/ActionCardModalData';
import Button from 'components/Button/Button';

const webActivityDefinition: ActivityModalData = {
  searchCount: { label: 'snapshot:searchTerms', type: DataTypeEnum.string },
  viewCount: { label: 'snapshot:itemViewed', type: DataTypeEnum.string },
  cartCount: { label: 'snapshot:itemCart', type: DataTypeEnum.string },
  orderCount: { label: 'snapshot:itemBought', type: DataTypeEnum.string },
  impressionCount: { label: 'snapshot:impressions', type: DataTypeEnum.string },
  searchUser: { label: 'common:contact', type: DataTypeEnum.contact },
};

export const getWebActivityConfig = ({
  activityVersion,
  activityData,
  defaultFirstLine,
  onCloseModal,
  i18n,
  t,
}: GetActivityConfigExtendedProps): ActionCardConfig | undefined => {
  const { fieldsData, extendedInfo } = activityData;
  const activityDate = activityData.creationTimestampISO;
  const searchPhrase = getActivityDataValue(fieldsData?.SEARCH_PHRASE);
  const searchDate = formatSnoozeDate(
    toString(fieldsData?.SEARCH_DATE) || activityDate,
    i18next.language
  );
  const searchUser = getActivityDataValue(
    extendedInfo?.custContactName ||
      extendedInfo?.custContactEmail ||
      fieldsData?.['Customer Contact Name'] ||
      fieldsData?.USER_ID
  );

  const webActivityButtons: React.ComponentProps<typeof Button>[] = [
    {
      variant: 'action',
      text: t('goWebActivity'),
      href: concatRoutes(
        searchURL(),
        searchCustomerURL(activityData?.custMiLoc, activityData?.custNo),
        customerWebActivityURL()
      ),
      onClick: onCloseModal,
      testid: `webActivity-button`,
    },
  ];

  const webActivityContacts: Recipient[] = generateEmailList({
    name: trim(toString(extendedInfo?.custContactName)),
    rawEmailList: trim(toString(extendedInfo?.custContactEmail)),
    splitChar: ';',
  });
  const emailContact = head(webActivityContacts) as Contact;
  const emailContactName = emailContact?.name || emailContact?.email;

  const webActivityDataV1 = {
    [searchPhrase]: searchDate,
  };
  const webActivityDataV2 = {
    searchUser,
    searchCount: toNumber(extendedInfo?.searchCount),
    viewCount: toNumber(extendedInfo?.productViews),
    cartCount: toNumber(extendedInfo?.cartAdditions),
    orderCount: toNumber(extendedInfo?.orders),
    impressionCount: toNumber(extendedInfo?.productImpressions),
    emailContact,
    emailContactName,
  };

  switch (activityVersion) {
    case `${WebActivityTypeEnum.webActivity}-1`:
      return {
        card: {
          defaultTitle: t('recentSearchActivityTitle'),
          body: {
            description: t('recentSearchActivityDescription', {
              searchPhrase,
              count: searchPhrase ? 1 : 2,
            }),
            lines: [
              ...defaultFirstLine,
              {
                description: t('recentSearchActivityDescription', {
                  searchPhrase,
                  count: searchPhrase ? 1 : 2,
                }),
              },
            ],
          },
        },
        modal: {
          title: t('recentSearchActivityTitle'),
          buttons: webActivityButtons,
          contactEmail: webActivityContacts,
          defaultData: webActivityDataV1,
          customContent: () => (
            <ActionCardModalData
              permitNotMappedValues
              activityData={webActivityDataV1}
            />
          ),
        },
      };
    case `${WebActivityTypeEnum.webActivity}-2`:
    case `${WebActivityTypeEnum.webActivity}-3`:
      return {
        card: {
          defaultTitle: t('recentActivityTitle'),
          body: {
            description: t('recentActivityDescription', {
              searchUser,
              formatDate: formatDate(
                activityDate,
                DateFormatEnum.ampmTime,
                i18n.language
              ),
            }),
            lines: [
              ...defaultFirstLine,
              {
                description: t('recentActivityDescription', {
                  searchUser,
                  formatDate: formatDate(
                    activityDate,
                    DateFormatEnum.ampmTime,
                    i18n.language
                  ),
                }),
              },
            ],
          },
        },
        modal: {
          title: t('recentActivityTitle'),
          contactEmail: webActivityContacts,
          defaultData: webActivityDataV2,
          customContent: (props) => (
            <div>
              <Button
                className={classes.webActivityButton}
                variant="link"
                text={t('snapshot:webActivity')}
                href={concatRoutes(
                  searchURL(),
                  searchCustomerURL(
                    activityData?.custMiLoc,
                    activityData?.custNo
                  ),
                  customerWebActivityURL()
                )}
                rightIcon={findIcon('chevron-right')}
                testid="webActivity-button"
              />
              <ActionCardModalData
                dataDefinition={webActivityDefinition}
                activityData={webActivityDataV2}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            </div>
          ),
        },
      };
    default:
      return undefined;
  }
};

export const getWebActivityData = ({
  eventTagName,
  t,
}: GetActivityTypeDataProps): ActivityTypeConfig | undefined => {
  switch (eventTagName) {
    case 'CustomerWebActivity':
      return {
        cardType: WebActivityTypeEnum.webActivity,
        title: t('webTitle'),
        icon: WebActivityIconEnum.webActivity,
        color: WebActivityColorEnum.webActivity,
      };
    default:
      return undefined;
  }
};

export const getWebActivityFilterIcon = (
  type: string
): WebActivityIconEnum | undefined => {
  switch (type) {
    case WebActivityFilterEnum.webActivity:
      return WebActivityIconEnum.webActivity;
    default:
      return undefined;
  }
};
