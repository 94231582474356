import type { ReactNode } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { isEmpty, toNumber } from 'lodash';
import { IonRow, IonItem, IonSkeletonText } from '@ionic/react';
import type { QueryFlags } from 'common/api/utils/useGetQueryFlags';
import { choose, ifFunction, ifRender } from 'common/utils/logicHelpers';
import type { GetCostSavingsResponse } from 'ReportsApp/api/useGetCostSavingsReport';
import { getErrorMessage } from 'utils/helpers';
import CurrencyFormat from 'components/CurrencyFormat/CurrencyFormat';
import Text from 'components/Text/Text';
import 'swiper/css';
import 'swiper/css/pagination';
import '@ionic/react/css/ionic-swiper.css';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './CostSavingsHealth.module.scss';

interface CostSavingsCardProps {
  id: string;
  title: string;
  data: GetCostSavingsResponse & QueryFlags;
  useOverage?: boolean;
  isSeparate?: boolean;
}

const CostSavingsCard = ({
  id,
  title,
  data,
  useOverage = false,
  isSeparate = true,
}: CostSavingsCardProps): JSX.Element => {
  const { t } = useTranslation();
  const { totals, error, isLoading, currencyType } = data;

  const overage = choose(!useOverage, totals?.maxOverage, totals?.overage);

  const approvedSales = choose(
    !useOverage,
    totals?.maxApprovedSales,
    totals?.approvedSales
  );

  const pendingSales = totals?.pendingSales;

  const commitCurrentSales = totals?.commitCurrentSales;

  let approvedPercentage;
  let overagePercentage;
  let remainingRow: ReactNode;
  let overAllGoalElem: ReactNode;
  if (toNumber(overage) >= 0) {
    approvedPercentage = '100';
    overagePercentage = '0';
    overAllGoalElem =
      overage !== 0 ? (
        <div className={classes.flex}>
          <Text
            className={classes.pending}
            variant="label-micro-italic"
            text={`${t('common:overGoal')}:`}
          />
          <span>&nbsp;</span>
          <CurrencyFormat
            className={classes.pending}
            variant="label-micro-italic"
            value={toNumber(overage)}
            testid="pending-cs"
            currencyType={currencyType}
          />
        </div>
      ) : undefined;
  } else {
    approvedPercentage =
      (toNumber(approvedSales) * 100) /
      (toNumber(approvedSales) + toNumber(overage) * -1 || 0 || 1);
    overagePercentage =
      (toNumber(overage) * -1 * 100) /
      (toNumber(approvedSales) + toNumber(overage) * -1 || 0 || 1);
    remainingRow = (
      <IonRow className={classNames(classes.csRow, classes.inlineRows)}>
        <div className={classes.flex}>
          <span className={classes.remainingCircle} />
          <Text
            className={classNames(classes.inlineDetails, classes.inlineRowText)}
            variant="mipro-h6-headline"
            text={t('common:remaining')}
          />
        </div>
        {isLoading ? (
          <IonSkeletonText animated style={{ height: 15, width: 130 }} />
        ) : (
          <CurrencyFormat
            className={classes.inlineDetails}
            variant="label-header"
            value={toNumber(overage) * -1}
            currencyType={currencyType}
            testid="remaining-cs"
          />
        )}
      </IonRow>
    );
  }
  ifFunction(!isEmpty(error), () => {
    return (
      <WarningMessage
        title={t('common:badCostSavings')}
        body={getErrorMessage(error)}
      />
    );
  });

  return (
    <div key={id}>
      {isSeparate && id.startsWith('goal') && (
        <IonItem className={classes.separator} />
      )}
      <div>
        <IonRow
          className={classNames(
            classes.csHeader,
            classes.csRow,
            classes.pillsRow
          )}
        >
          <Text
            className={classes.costSavings}
            variant="title-screen-section"
            text={title}
          />
          {ifRender(
            !isLoading,
            <CurrencyFormat
              className={classes.guranteed}
              variant="mipro-h6-headline"
              value={toNumber(commitCurrentSales)}
              testid="commited-cs-sales"
              currencyType={currencyType}
            />
          )}
        </IonRow>
        <IonRow className={classes.csRow}>
          {isLoading ? (
            <IonSkeletonText animated style={{ height: 15, width: 200 }} />
          ) : (
            <div className={classes.overGoalPendingDetails}>
              {overAllGoalElem}
              <div className={classes.flex}>
                <Text
                  className={classes.pending}
                  variant="label-micro-italic"
                  text={`${t('common:pendingApproval')}:`}
                />
                <span>&nbsp;</span>
                <CurrencyFormat
                  className={classes.pending}
                  variant="label-micro-italic"
                  value={toNumber(pendingSales)}
                  testid="pending-cs"
                  currencyType={currencyType}
                />
              </div>
            </div>
          )}
        </IonRow>
        <IonRow
          className={classNames(
            classes.wrapperCap,
            classes.csRow,
            classes.csHeader,
            classes.pillsRow
          )}
        >
          {isLoading ? (
            <IonSkeletonText animated style={{ height: 20 }} />
          ) : (
            <>
              {toNumber(approvedPercentage) > 0 && (
                <div
                  className={classes.approvedCap}
                  style={{
                    width: `${approvedPercentage}%`,
                    maxWidth:
                      toNumber(overagePercentage) > 0
                        ? `calc(100% - 20px)`
                        : undefined,
                  }}
                />
              )}
              {toNumber(overagePercentage) > 0 && (
                <div
                  className={classes.remainingCap}
                  style={{
                    width: `calc(${overagePercentage}% + ${
                      toNumber(approvedPercentage) > 0 ? 25 : 0
                    }px)`,
                  }}
                />
              )}
            </>
          )}
        </IonRow>
        <IonRow className={classNames(classes.csRow, classes.inlineRows)}>
          <div className={classes.flex}>
            <span className={classes.approvedCircle} />
            <Text
              className={classNames(
                classes.inlineDetails,
                classes.inlineRowText
              )}
              variant="mipro-h6-headline"
              text={t('common:approved')}
            />
          </div>
          {isLoading ? (
            <IonSkeletonText animated style={{ height: 15, width: 130 }} />
          ) : (
            <CurrencyFormat
              className={classes.inlineDetails}
              variant="label-header"
              value={toNumber(approvedSales)}
              currencyType={currencyType}
              testid="approved-cs"
            />
          )}
        </IonRow>
        {remainingRow}
      </div>
    </div>
  );
};

export default CostSavingsCard;
