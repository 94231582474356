import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { includes, map, toString } from 'lodash';
import type { AppState } from '@capacitor/app';
import { App } from '@capacitor/app';
import type { PluginListenerHandle } from '@capacitor/core';
import {
  IonContent,
  IonLabel,
  IonPage,
  IonRow,
  useIonViewWillEnter,
} from '@ionic/react';
import Header from 'common/components/Header/Header';
import HelpButton from 'common/components/HelpButton/HelpButton';
import { startOfToday } from 'date-fns';
import { searchURL } from 'navigation';
import { TabEOMReportKey, useReportsConfig } from 'providers/ReportsProvider';
import useGetEomReport from 'ReportsApp/api/useGetEomReport';
import JobWarning from 'ReportsApp/components/JobWarning/JobWarning';
import useGetCamUserDetails from 'ReportsApp/hooks/useGetCamUserDetails';
import { useGetSelectedMiLoc } from 'api/helpers';
import useGetCalendarDays from 'api/salesReports/useGetCalendarDays';
import { useHasAccessControls } from 'hooks/useAccessControls';
import { getBusinessDays, getHolidays } from 'utils/date';
import { getErrorMessage } from 'utils/helpers';
import { concatRoutes } from 'utils/navigations';
import {
  getReportName,
  isSalesJobRunning,
  noCoprporateAccountAssigned,
} from 'utils/reports';
import { handleSearchNavigation } from 'utils/search';
import type { DashboardLineProps } from 'pages/Home/DashboardCards/DashboardCard';
import Button from 'components/Button/Button';
import ContentRow from 'components/ContentRow/ContentRow';
import DateToolbar from 'components/DateToolbar/DateToolbar';
import HelpModal from 'components/HelpModal/HelpModal';
import OverlayInfobar from 'components/Overlays/OverlayInfoBar';
import Refresher from 'components/Refresher/Refresher';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './EndOfMonth.module.scss';

const EndOfMonth = (): JSX.Element => {
  const { t } = useTranslation();
  const [isOpenEomHelpModal, setIsOpenEomHelpModal] = useState<boolean>(false);
  const {
    accessControl,
    isCamUser,
    subTitle: camUserSubtitle,
  } = useGetCamUserDetails();

  const { isBranchUser, selectedMiLoc, fromVirtualTeam, team } =
    useGetSelectedMiLoc();
  const { miLoc } = selectedMiLoc;

  const reportingMiLoc = fromVirtualTeam || team ? undefined : miLoc;
  const { requestType, busPeriod, updateRequestType, updateBusPeriod } =
    useReportsConfig({
      key: TabEOMReportKey,
    });
  let subTitle = isBranchUser
    ? getReportName({
        miLoc,
        locName: selectedMiLoc.locName,
        team,
      })
    : selectedMiLoc.locName;

  if (isCamUser) {
    subTitle = camUserSubtitle;
  }
  const { hasAccessControl } = useHasAccessControls();
  const { eomData, isLoading, error, dataUpdatedAt, refetch } = useGetEomReport(
    {
      miLoc: reportingMiLoc,
      requestType,
      enabled: hasAccessControl(accessControl),
      sendVirtualTeamId: true,
      busPeriod,
    }
  );

  useEffect(() => {
    let reportListener: PluginListenerHandle;
    const doAddEndofMonthReportListener = async () => {
      reportListener = await App.addListener(
        'appStateChange',
        (state: AppState) => {
          if (state.isActive) {
            updateBusPeriod(startOfToday());
          }
        }
      );
    };
    void doAddEndofMonthReportListener();

    return () => {
      void reportListener?.remove();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useIonViewWillEnter(() => {
    updateBusPeriod(startOfToday());
  }, []);

  const lines = useMemo(() => {
    const currencyType = eomData?.currencyType;
    const result: DashboardLineProps[] = [
      {
        text: t('totalSales'),
        value: eomData?.currentSales,
        valueType: 'currency',
        currencyType,
        changePercentage: eomData?.currentSalesChange,
        changeLabel: 'YOY',
        showInfoIcon: requestType !== 'DAILY',
      },
      {
        text: t('gp'),
        value: eomData?.currentGp,
        valueType: 'currency',
        currencyType,
        changePercentage: eomData?.currentGpChange,
        changeLabel: 'YOY',
      },
      {
        text: t('gpPercentage'),
        value: eomData?.currentGpPercent,
        valueType: 'percentage',
        changePercentage: eomData?.currentGpPercentChange,
        changeLabel: 'YOY',
      },
    ];
    if (includes(['MTD', 'YTD'], requestType)) {
      result.push({
        text: t('averageDaily'),
        value: eomData?.currentAvgDaily,
        valueType: 'currency',
        currencyType,
        changePercentage: eomData?.currentAvgDailyChange,
        changeLabel: 'YOY',
      });

      result.push({
        text: t('summaryBilling'),
        value: eomData?.summaryBilling.sales,
        valueType: 'currency',
        currencyType,
      });

      result.push({
        text: t('summaryBillingGP'),
        value: eomData?.summaryBilling.gp,
        valueType: 'currency',
        currencyType,
      });

      result.push({
        text: t('summaryBillingGPPercent'),
        value: eomData?.summaryBilling.gpPercent,
        valueType: 'percentage',
        currencyType,
      });

      result.unshift({
        text: t('totalGpSummaryBilling', {
          period: requestType === 'MTD' ? t('monthly') : t('yearly'),
        }),
        value: eomData?.currentGpPlusSummaryBilling,
        valueType: 'currency',
        currencyType,
        changePercentage: eomData?.currentGpPlusSummaryBillingChange,
        changeLabel: 'YOY',
      });

      result.unshift({
        text: t('totalSalesSummaryBilling', {
          period: requestType === 'MTD' ? t('monthly') : t('yearly'),
        }),
        value: eomData?.currentSalesPlusSummaryBilling,
        valueType: 'currency',
        currencyType,
        changePercentage: eomData?.currentSalesPlusSummaryBillingChange,
        changeLabel: 'YOY',
      });
    }
    return result;
  }, [requestType, eomData, t]);

  let errorContent = (
    <WarningMessage
      testid="generic-error-warning"
      title={t('genericReportErrorTitle')}
      body={getErrorMessage(error)}
      className={classes.warningMessage}
    />
  );

  if (isSalesJobRunning(error)) {
    errorContent = (
      <JobWarning
        testid="eom"
        reportName={t('reports:endOfMonthReport')}
        className={classes.warningMessage}
      />
    );
  }

  if (noCoprporateAccountAssigned(error)) {
    errorContent = (
      <WarningMessage
        testid="noaccount-warning"
        title={t('genericReportErrorTitle')}
        body={t('reports:noAcctsAssigned')}
        className={classes.warningMessage}
      />
    );
  }

  const { data: calendar } = useGetCalendarDays({});
  const businessDays = useMemo(() => getBusinessDays(calendar), [calendar]);
  const holidays = useMemo(() => getHolidays(calendar), [calendar]);

  return (
    <IonPage>
      <Header
        testid="eom-report-header"
        title={t('reports:endOfMonthReport')}
        subTitle={subTitle}
      >
        <DateToolbar
          testid="eom-report"
          busPeriod={busPeriod}
          requestType={requestType}
          updateBusPeriod={(date) => {
            updateBusPeriod(date);
          }}
          updateRequestType={updateRequestType}
          businessDays={businessDays}
          holidays={holidays}
          reportKey={TabEOMReportKey}
          hideCustomDateSelection
          showBussinessDayOnly={false}
          className={classes.datetoolbar}
        />
        <OverlayInfobar
          message={t('reports:eomInfoMsg')}
          className={classes.overlayInfoBar}
          hideInfoIcon
        />
      </Header>
      <IonContent className={classes.eomContent}>
        <Refresher
          slot="fixed"
          onRefresh={async () => {
            updateBusPeriod(startOfToday());
            await refetch?.();
          }}
          hidden
          testid="count-groups-refresher"
          disabled={isLoading}
          lastUpdatedAt={dataUpdatedAt}
        />
        <Header
          testid="eom-report-condensed-header"
          collapse="condense"
          className={classes.headerCondense}
          customTitle={
            <div className={classes.customTitleWrapper}>
              <div className={classes.flex}>
                <Text
                  variant="mipro-h1-headline"
                  text={t('reports:endOfMonthReport')}
                />
                {requestType !== 'DAILY' && (
                  <HelpButton
                    modal={{
                      testid: 'end-of-month',
                      initialBreakpoint: 0.6,
                      title: t('reports:reportHeaderHelpTitle'),
                      children: (
                        <IonRow className={classes.contentRow}>
                          <IonLabel>
                            <Trans
                              i18nKey={t('reports:reportHeaderHelpContent')}
                            />
                          </IonLabel>
                        </IonRow>
                      ),
                    }}
                    button={{ testid: 'eom-help' }}
                  />
                )}
              </div>
              <Button
                textVariant="mipro-h2-headline"
                text={subTitle}
                testid="subtitle"
                variant={isBranchUser ? 'link' : 'clear'}
                href={
                  isBranchUser
                    ? concatRoutes(
                        searchURL(),
                        handleSearchNavigation({
                          type: 'motionLocation',
                          miLoc,
                          sequenceNo: miLoc,
                        })
                      )
                    : undefined
                }
              />
            </div>
          }
        />

        {error ? (
          errorContent
        ) : (
          <div className={classes.content} data-testid="eom-content">
            {map(
              lines,
              (
                {
                  text,
                  value,
                  valueType,
                  changePercentage,
                  changeLabel,
                  currencyType,
                  showInfoIcon,
                },
                index
              ) => (
                <ContentRow
                  className={classes.contentRow}
                  key={index}
                  loading={isLoading}
                  text={text}
                  valueType={valueType}
                  value={toString(value)}
                  currencyType={currencyType}
                  changePercentage={changePercentage}
                  changeLabel={changeLabel}
                  testid={`eom-report-${index}`}
                  showInfoIcon={showInfoIcon}
                  openHelpModal={(e) => {
                    setIsOpenEomHelpModal(e);
                  }}
                />
              )
            )}
          </div>
        )}

        <HelpModal
          title={t('reports:totalSalesHelpTitle')}
          closeHelpModal={setIsOpenEomHelpModal}
          testid="eom-help-modal"
          isOpen={isOpenEomHelpModal}
          initialBreakpoint={0.4}
        >
          <IonRow className={classes.helpContentRow}>
            <IonLabel>
              <Trans i18nKey={t('reports:totalSalesHeaderHelpContent')} />
            </IonLabel>
          </IonRow>
        </HelpModal>
      </IonContent>
    </IonPage>
  );
};

export default EndOfMonth;
