import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { size } from 'lodash';
import { IonRow } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { useUserInfo } from 'common/utils/userInfo';
import { namespaces } from 'i18n/i18n.constants';
import type { MiProFile } from 'models/Attachment';
import { DateFormatEnum, formatDate } from 'utils/date';
import { findIcon } from 'utils/icons';
import ActionRow from 'components/ActionRow/ActionRow';
import Text from 'components/Text/Text';
import classes from './Notebook.module.scss';

interface NotebookProps {
  attachments?: MiProFile[];
  href: string;
  lastUpdatedTimestamp?: string;
  userId?: string;
  userName: string;
  testid: string;
  isUpdating?: boolean;
  disabled?: boolean;
  title: string;
}

const Notebook = ({
  attachments,
  href,
  lastUpdatedTimestamp,
  userId,
  userName,
  testid,
  isUpdating,
  disabled,
  title,
}: NotebookProps & IonicReactProps): JSX.Element => {
  const { t, i18n } = useTranslation(namespaces.notes);
  const { isOwner } = useUserInfo({ userId });
  const owner = isOwner ? t('common:you') : userName;

  return (
    <ActionRow
      className={classes.noteCard}
      disabled={disabled}
      testid="note"
      leftButton={
        size(attachments) === 0
          ? undefined
          : {
              variant: 'link',
              icon: findIcon('paperclip', 'fas'),
              testid: `note--${testid}`,
              className: classes.attachment,
              iconClassName: classes.attachmentIcon,
            }
      }
      href={isUpdating ? undefined : href}
    >
      <div className={classes.noteItem}>
        <IonRow>
          <Text
            className={classes.updateDate}
            variant="label-micro"
            text={formatDate(
              lastUpdatedTimestamp,
              DateFormatEnum.fullDate,
              i18n.language
            )}
            testid="notes--updated--time"
          />
          <Text
            className={classNames(classes.updateDate, classes.spacing)}
            variant="label-micro"
            text={`(${owner})`}
            textQuery={[{ query: owner, className: classes.userId }]}
            testid="notebook--owner"
          />
        </IonRow>
        <div className={classes.divEllipsis}>
          <Text
            className={classes.text}
            text={title}
            testid="notebook--title"
          />
        </div>
      </div>
    </ActionRow>
  );
};

export default Notebook;
