import React, { createContext, useContext, useEffect, useState } from 'react';
import type { AxiosError, AxiosInstance } from 'axios';
import axios from 'axios';
import useAPIUrl from 'api';
import {
  addAuthTokenInterceptor,
  refreshAuthTokenOnErrorInterceptor,
  refreshAuthTokenOnSuccessInterceptor,
  sentryOnSuccessInterceptor,
  sentryOnErrorInterceptor,
} from 'api/interceptors';
import { useVault } from './VaultProvider';

const customAxios = axios.create();

interface AxiosContextProps {
  axios: AxiosInstance;
  triggerInvalidToken: number;
}

const AxiosContext = createContext<AxiosContextProps>({
  axios: customAxios,
  triggerInvalidToken: 0,
});

const AxiosProvider = ({
  children,
}: React.PropsWithChildren<unknown>): JSX.Element => {
  const { loginAPI, pingAPI } = useAPIUrl();
  const { getAuthToken, setAuthToken } = useVault();
  const [isReady, setIsReady] = useState(false);
  const [triggerInvalidToken, setTriggerInvalidToken] = useState(0);

  useEffect(() => {
    const ignoreAuthHeaderAPIs = [loginAPI(), pingAPI()];
    setIsReady(false);
    const customRequestInterceptor = customAxios.interceptors.request.use(
      async (config) =>
        addAuthTokenInterceptor({ config, getAuthToken, ignoreAuthHeaderAPIs })
    );
    const customResponseInterceptor = customAxios.interceptors.response.use(
      async (response) =>
        refreshAuthTokenOnSuccessInterceptor({
          response,
          setAuthToken,
          ignoreAuthHeaderAPIs,
        }),
      async (error: AxiosError) =>
        refreshAuthTokenOnErrorInterceptor({
          error,
          customAxios,
          setAuthToken,
          onInvalidToken: () => setTriggerInvalidToken(Date.now()),
        })
    );
    const customeSentryInterceptor = customAxios.interceptors.response.use(
      sentryOnSuccessInterceptor,
      sentryOnErrorInterceptor
    );
    setIsReady(true);
    return () => {
      customAxios.interceptors.response.eject(customRequestInterceptor);
      customAxios.interceptors.response.eject(customResponseInterceptor);
      customAxios.interceptors.response.eject(customeSentryInterceptor);
    };
  }, [getAuthToken, loginAPI, pingAPI, setAuthToken]);

  return (
    <AxiosContext.Provider value={{ axios: customAxios, triggerInvalidToken }}>
      {!!isReady && children}
    </AxiosContext.Provider>
  );
};

export default AxiosProvider;

export const useAxios = (): AxiosContextProps => {
  const ctx = useContext(AxiosContext);

  if (!ctx) {
    throw Error('The `useAxios` hook must be called inside a `AxiosProvider`.');
  }

  return { axios: ctx.axios, triggerInvalidToken: ctx.triggerInvalidToken };
};
