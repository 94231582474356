import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IonLoading } from '@ionic/react';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import { useToasts } from 'providers/ToastProvider';
import useFindChargeBackProfiles from 'StoreroomsApp/api/useFindChargeBackProfiles';
import useFindChargeBackValues from 'StoreroomsApp/api/useFindChargeBackValues';
import useFindItemsPOU from 'StoreroomsApp/api/useFindItemsPOU';
import useFindStorerooms from 'StoreroomsApp/api/useFindStorerooms';
import { ToastType } from 'models/Toast';
import selectIsBranchLocation from 'store/user/selectors';
import { findIcon } from 'utils/icons';
import Button from 'components/Button/Button';
import OfflineInfo from 'components/OfflineInfo/OfflineInfo';
import classes from './UseSyncHook.module.scss';

interface UseIssueSyncResponse {
  loader: JSX.Element;
  downloadData: () => Promise<void>;
  downloadButton: JSX.Element;
  offlineBanner?: JSX.Element;
}

const useIssueSync = (): UseIssueSyncResponse => {
  const { addToast } = useToasts();
  const { isOnline } = useNetworkStatus();
  const isBranch = useSelector(selectIsBranchLocation);
  const [isDownloading, setIsDownloading] = useState(false);

  const { sync: syncStorerooms, loadingAPI: loadingStorerooms } =
    useFindStorerooms({ enabled: false });
  const { sync: syncItemsPOU, loadingAPI: loadingItemsPOU } = useFindItemsPOU({
    enabled: false,
  });
  const {
    sync: syncChargeBackProfiles,
    loadingAPI: loadingChargeBackProfiles,
  } = useFindChargeBackProfiles({ enabled: false });
  const { sync: syncChargeBackValues, loadingAPI: loadingChargeBackValues } =
    useFindChargeBackValues({ enabled: false });

  const isLoading =
    loadingStorerooms ||
    loadingItemsPOU ||
    loadingChargeBackProfiles ||
    loadingChargeBackValues;

  // TODO fire api requests in parallel
  const downloadData = async () => {
    try {
      setIsDownloading(true);
      await syncStorerooms();
      const totalRows = await syncItemsPOU();
      await syncChargeBackProfiles();
      await syncChargeBackValues();
      addToast({
        type: ToastType.success,
        text: `Downloaded ${totalRows} products.`,
        testid: 'sync-success-toast',
      });
    } catch (e) {
      // DOC: general error
    } finally {
      setIsDownloading(false);
    }
  };

  return {
    loader: (
      <IonLoading
        isOpen={isDownloading || !!isLoading}
        message="Downloading data in progress. This could take a couple minutes."
      />
    ),
    downloadData,
    downloadButton: (
      <Button
        slot="end"
        className={classes.downloadButton}
        variant="action"
        onClick={downloadData}
        icon={findIcon('download')}
        disabled={!isOnline || !isBranch}
        testid="download-button"
      />
    ),
    offlineBanner: isOnline ? undefined : <OfflineInfo />,
  };
};

export default useIssueSync;
