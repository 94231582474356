import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { namespaces, resources } from './i18n.constants';

void i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    defaultNS: namespaces.common,
    fallbackLng: 'en',
    detection: {
      order: ['navigator'],
    },
  });

export default i18n;
