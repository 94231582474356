/* eslint-disable react/forbid-elements */
import React from 'react';
import { Route } from 'react-router-dom';
import { useIonViewDidEnter } from '@ionic/react';
import type { AccessControlType } from 'hooks/useAccessControls';
import useAccessControls from 'hooks/useAccessControls';
import type { FeatureToggleType } from 'hooks/useFeatureToggles';
import useFeatureToggles from 'hooks/useFeatureToggles';
import type { FirebaseEventParams } from 'utils/firebaseAnalytics';
import { logFirebaseEvent } from 'utils/firebaseAnalytics';
import NotAvailable from 'components/NotAvailable/NotAvailable';

interface ComputedMatch {
  params: FirebaseEventParams;
  path: string;
  url: string;
}

type PrivateRouteProps = React.ComponentProps<typeof Route> & {
  track?: boolean;
  accessControl?: AccessControlType;
  featureToggles?: FeatureToggleType | FeatureToggleType[];
  computedMatch?: ComputedMatch; // not sure where to get the type for this
  routeName: string;
};

const PrivateRoute = ({
  track = true,
  ...props
}: PrivateRouteProps): JSX.Element => {
  const { accessControl, computedMatch, routeName, featureToggles } = props;
  const ac = useAccessControls(accessControl);
  const ft = useFeatureToggles(featureToggles);

  const { params: computedParams, path } = (computedMatch ||
    {}) as ComputedMatch;

  useIonViewDidEnter(() => {
    if (track) {
      const params: FirebaseEventParams = {
        ...computedParams,
        screen_name: routeName,
        screen_class: path,
      };
      logFirebaseEvent('screen_view', params);
    }
  }, [computedMatch, track, routeName]);

  if (ac && ft) {
    return React.createElement(Route, props);
  }
  return <Route component={NotAvailable} />;
};

export default PrivateRoute;
