import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { split, toString } from 'lodash';
import { IonDatetime } from '@ionic/react';
import Alert, { AlertVariantEnum } from 'common/components/Alert/Alert';
import { type BaseComponentProps } from 'common/components/utils/renderHelpers';
import {
  addYears,
  formatISO,
  isAfter,
  isBefore,
  set,
  startOfToday,
  subYears,
} from 'date-fns';
import { DateFormatEnum, formatDate } from 'utils/date';
import Button from 'components/Button/Button';
import SheetModal from 'components/Modals/SheetModal/SheetModal';
import classes from './DateTimeWheelModal.module.scss';

interface DateTimeWheelModalProps extends BaseComponentProps {
  dateTime: Date;
  minDate?: Date;
  maxDate?: Date;
  onCancel?: () => void;
  onDone?: (d: Date) => void;
}

const DateTimeWheelModal = ({
  testid = 'datetime-wheel-modal',
  presentation,
  title,
  isOpen,
  onClose,
  onCancel,
  onDone,
  setIsOpen,
  dateTime,
  maxDate = addYears(startOfToday(), 1),
  minDate = subYears(startOfToday(), 2),
}: DateTimeWheelModalProps &
  React.ComponentProps<typeof IonDatetime> &
  React.ComponentProps<typeof SheetModal>) => {
  const { t } = useTranslation();

  const isTimePresentation = presentation === 'time';
  const modalTitle = isTimePresentation
    ? t('calendar:selectCustomTime')
    : title;

  const [wheelDate, setWheelDate] = useState(dateTime);

  useEffect(() => {
    if (isOpen) {
      setWheelDate(dateTime);
    }
  }, [isOpen, dateTime]);

  let alertMsg = '';
  if (isTimePresentation) {
    if (isBefore(wheelDate, minDate) || isAfter(wheelDate, maxDate)) {
      alertMsg = t(`calendar:minimumDate`, {
        minDate: formatDate(minDate, DateFormatEnum.ampmTime),
      });
    }
  }

  const renderWheelModal = useMemo(() => {
    return (
      <IonDatetime
        data-testid="datetime-wheel"
        onIonChange={(e) => {
          const newDate = toString(e.detail.value);
          if (isTimePresentation) {
            const [hours, minutes] = split(newDate, ':').map(Number);
            setWheelDate(set(dateTime, { hours, minutes }));
            return;
          }
          setWheelDate(new Date(newDate));
        }}
        hourCycle="h12"
        className={classes.picker}
        presentation={presentation}
        min={formatISO(minDate)}
        max={formatISO(maxDate)}
        value={
          isTimePresentation
            ? formatDate(dateTime, 'HH:mm')
            : dateTime.toISOString()
        }
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTimePresentation, dateTime, maxDate, minDate, presentation]);

  return (
    <SheetModal
      withRightCloseButton
      isOpen={isOpen}
      onClose={onClose}
      setIsOpen={setIsOpen}
      testid={testid}
      title={modalTitle}
      className={classes.sheetModal}
      contentClass={classes.content}
      initialBreakpoint={0.65}
    >
      {renderWheelModal}
      {alertMsg && (
        <Alert
          variant={AlertVariantEnum.danger}
          className={classes.alert}
          text={alertMsg}
          testid="datetime-wheel-modal-warning"
        />
      )}
      <div className={classes.buttons}>
        <Button
          className={classes.datePickButton}
          variant="secondary"
          text={t('common:cancel')}
          testid={`datetime-wheel-cancel-button-${testid}`}
          onClick={onCancel}
        />
        <Button
          className={classes.datePickButton}
          variant="action"
          text={t('common:apply')}
          testid={`datetime-wheel-apply-button-${testid}`}
          onClick={() => {
            if (!alertMsg) {
              onDone?.(wheelDate);
            }
          }}
        />
      </div>
    </SheetModal>
  );
};

export default DateTimeWheelModal;
