import { useTranslation } from 'react-i18next';
import { lowerCase } from 'lodash';
import type { MutationStatus } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { namespaces } from 'i18n/i18n.constants';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import { findActivitiesQueryKey } from 'api/activities/useFindActivities';
import { findActivityFiltersQueryKey } from 'api/activities/useFindActivityFilters';
import {
  onSuccessMutation,
  doPromiseAPI,
  onErrorUpdate,
  onMutateUpdate,
} from 'api/helpers';
import type { UpdateMutationContext } from 'api/helpers';
import type { ActionCardActivity } from 'models/ActivityModels';
import type { UpdateSalesPlayBody, SalesPlay } from 'models/SalesPlays';
import { ToastType } from 'models/Toast';
import type { UserEventAction } from 'utils/firebaseAnalytics';
import { logFirebaseEvent } from 'utils/firebaseAnalytics';
import { findSalesPlaysQueryKey } from './useFindSalesPlays';
import { getSalesPlayQueryKey } from './useGetSalesPlay';

export interface UseUpdateSalesPlayProps {
  externalSource: string;
  externalId: string;
}

interface UseUpdateSalesPlayResponse {
  status: MutationStatus;
  onUpdateSalesPlay: (body: UpdateSalesPlayBody) => void;
}

interface UpdateSalesPlayMutationContext {
  listContext: UpdateMutationContext<SalesPlay>[];
  itemContext: UpdateMutationContext<SalesPlay>[];
  activityContext: UpdateMutationContext<ActionCardActivity>[];
}

const useUpdateSalesPlay = ({
  externalSource,
  externalId,
}: UseUpdateSalesPlayProps): UseUpdateSalesPlayResponse => {
  const { axios } = useAxios();
  const { updateSalesPlayAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const { t } = useTranslation(namespaces.salesPlays);

  const doUpdateSalesPlayAPI = ({
    status,
    assignedUserId,
    noOppReason,
    eventParams,
    createEvent,
    removeEvent,
    historyId,
    additionalRejectText,
  }: UpdateSalesPlayBody) => {
    let restParams: UpdateSalesPlayBody = createEvent ? { createEvent } : {};
    if (historyId) {
      restParams = { historyId, ...restParams };
    }
    return doPromiseAPI<SalesPlay>(async (cancelToken) => {
      const { data } = await axios.put<SalesPlay>(
        updateSalesPlayAPI(externalSource, externalId),
        {
          status,
          assignedUserId,
          noOppReason,
          additionalRejectText,
          eventParams,
          removeEvent,
          ...restParams,
        },
        { cancelToken }
      );
      return data;
    });
  };

  const { status, mutate } = useMutation(doUpdateSalesPlayAPI, {
    onMutate: async (vars) => {
      const action = lowerCase(vars.status);
      const params: UserEventAction = {
        action,
        event: 'SalesPlay',
      };
      logFirebaseEvent('sales_plays_action', params);

      const itemContext = await onMutateUpdate<SalesPlay>({
        queryClient,
        queryKey: getSalesPlayQueryKey,
        queryKeyParams: { externalSource, externalId },
        updatedItems: [{ externalSource, externalId, ...vars } as SalesPlay],
        findPredicates: [{ externalSource, externalId }],
        isSingleQuery: true,
      });
      const activityContext = vars.historyId
        ? await onMutateUpdate<ActionCardActivity>({
            queryClient,
            queryKey: findActivitiesQueryKey,
            updatedItems: [
              {
                ...vars.eventParams,
                historyId: vars.historyId,
              } as ActionCardActivity,
            ],
            findPredicates: [
              { historyId: vars.historyId, userId: vars.assignedUserId },
            ],
            isInfiniteQuery: true,
          })
        : null;
      return { itemContext, activityContext };
    },
    onSuccess: async (data, vars) => {
      await onSuccessMutation(queryClient, findSalesPlaysQueryKey);
      await onSuccessMutation(queryClient, findActivityFiltersQueryKey);
      await onSuccessMutation(queryClient, findActivitiesQueryKey);
      if (!vars.skipSuccessToast) {
        addToast({
          text: vars.successToastText || t('salesPlays:updateSalesPlayToast'),
          button: vars.undoData
            ? {
                role: 'undo',
                text: t('common:undo'),
                handler: () =>
                  mutate({
                    skipSuccessToast: true,
                    markAsUpdated: false,
                    ...vars.undoData?.(data),
                  }),
              }
            : undefined,
          testid: 'update-sales-play-successful-toast',
        });
      }
    },
    onError: (error, vars, context) => {
      const { listContext, itemContext, activityContext } =
        context as UpdateSalesPlayMutationContext;
      addToast({
        type: ToastType.error,
        text: 'Update SalesPlay operation failed. Please try again later.',
        testid: 'update-sales-play-error-toast',
      });
      onErrorUpdate<SalesPlay>({
        queryClient,
        context: listContext,
        isInfiniteQuery: true,
      });
      onErrorUpdate<SalesPlay>({
        queryClient,
        context: itemContext,
        isSingleQuery: true,
      });
      if (activityContext) {
        onErrorUpdate<ActionCardActivity>({
          queryClient,
          context: activityContext,
          isSingleQuery: true,
        });
      }
    },
  });

  return {
    status,
    onUpdateSalesPlay: (body: UpdateSalesPlayBody) => mutate(body),
  };
};

export default useUpdateSalesPlay;
