import React from 'react';
import classNames from 'classnames';
import { isNil } from 'lodash';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import type { TextVariantType } from 'components/Text/Text';
import Text from 'components/Text/Text';
import classes from './TextList.module.scss';

interface TextListProps {
  title: string;
  titlePrefix?: string;
  subTitle?: string;
  value?: string;
  rowTitleVariant?: TextVariantType;
  iconClass?: string;
  valueVariant?: 'mipro-product-headline';
  testid: string;
}

const TextList = ({
  className,
  title,
  titlePrefix,
  subTitle,
  value,
  rowTitleVariant = 'mipro-product-headline',
  iconClass,
  valueVariant,
  testid,
}: TextListProps & IonicReactProps): JSX.Element => {
  return (
    <div
      key={testid}
      className={classNames(classes.label, classes.rowClassName, className)}
      data-testid={`text-list-item-${testid}`}
    >
      <div className={classes.titleDiv}>
        <div className={classes.titleFirtRow}>
          {titlePrefix && (
            <Text
              className={classNames(classes.iconClass, iconClass)}
              text={titlePrefix}
              variant="list-item-title"
            />
          )}
          <Text
            variant={rowTitleVariant}
            className={classes.title}
            text={title}
          />
        </div>
        {subTitle && (
          <Text
            variant="list-item-secondaryText"
            className={classes.subTitle}
            text={subTitle}
          />
        )}
      </div>
      <Text
        variant={isNil(valueVariant) ? 'content-small' : valueVariant}
        className={classes.value}
        text={value || ''}
      />
    </div>
  );
};

export default TextList;
