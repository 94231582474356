import { forEach, split } from 'lodash';
import * as yup from 'yup';
import type { Recipient } from 'models/Contact';

const validateEmail = (contactEmail: string): boolean => {
  const schema = yup.object().shape({
    email: yup.string().email().required(),
  });

  return schema.isValidSync({
    email: contactEmail,
  });
};

interface GenerateEmailListProps {
  name: string;
  rawEmailList: string;
  phone?: string;
  splitChar: string;
}

const generateEmailList = ({
  name,
  rawEmailList,
  phone,
  splitChar,
}: GenerateEmailListProps): Recipient[] => {
  const emailList = split(rawEmailList, splitChar);
  const result: Recipient[] = [];

  forEach(emailList, (email) => {
    if (validateEmail(email)) {
      result.push({ name, phone, email });
    }
  });

  return result;
};

export default generateEmailList;
