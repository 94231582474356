import React from 'react';
import classNames from 'classnames';
import Pick12SVGIcon from 'assets/Pick12.svg';
import classes from './Pick12Icon.module.scss';

interface Pick12IconProps {
  className?: string;
  testid?: string;
  imgSrc?: string;
}

const Pick12Icon = ({ className, testid = '', imgSrc }: Pick12IconProps) => (
  <img
    src={imgSrc || Pick12SVGIcon}
    alt="Pick12Icon"
    className={classNames(classes.pick12Icon, className)}
    data-testid={`${testid}-pick12-icon`}
  />
);

export default Pick12Icon;
