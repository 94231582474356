import { useSelector } from 'react-redux';
import { includes, map, pickBy } from 'lodash';
import { useGetSelectedMiLoc } from 'api/helpers';
import type { SortOption } from 'models/Sort';
import type { RootState } from 'store/reducers';

const useGetAvailableCSHLocations = () => {
  const AVAILABLE_CSH_EXEC_LOCATIONS = ['AL00', 'MX00', 'CN00'];
  const { locationTree = {} } = useSelector((state: RootState) => state.user);
  const { selectedMiLoc } = useGetSelectedMiLoc();

  const availableCSHExecLocations = pickBy(locationTree, (value, key) =>
    includes(AVAILABLE_CSH_EXEC_LOCATIONS, key)
  );

  const isSingleCSHExecLocation =
    Object.keys(availableCSHExecLocations).length === 1;

  const isExecSelected = selectedMiLoc.miLoc === 'EXEC';

  const filterOptions: SortOption[] = map(
    availableCSHExecLocations,
    ({ id, miLoc, name }) => {
      return {
        key: id,
        name: `${miLoc}: ${name}`,
      };
    }
  );

  return {
    isSingleCSHExecLocation,
    isExecSelected,
    filterOptions,
  };
};

export default useGetAvailableCSHLocations;
