import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { isNil, size, toInteger, toString } from 'lodash';
import { IonContent, IonFooter, IonPage, IonRow } from '@ionic/react';
import useCreateReport from 'DocumentsApp/api/useCreateReport';
import useFindTemplates from 'DocumentsApp/api/useFindTemplates';
import useDocumentSync from 'DocumentsApp/hooks/useDocumentSync';
import { documentDetailsURL, documentsURL } from 'navigation';
import { useMiLocOrTeamId } from 'api/helpers';
import type { ReportImage } from 'models/InspectionReport';
import { concatRoutes } from 'utils/navigations';
import Button from 'components/Button/Button';
import type { CoverPageForm } from 'components/Documents/DocumentCoverPage/DocumentCoverPage';
import DocumentCoverPage from 'components/Documents/DocumentCoverPage/DocumentCoverPage';
import Header from 'components/Header/Header';
import styles from './DocumentDetail.module.scss';

export const isCoverPageValid = (coverPageData: CoverPageForm) => {
  let isValid = true;
  if (
    !(
      toString(coverPageData.data.startDate) &&
      toString(coverPageData.data.shop) &&
      toString(coverPageData.data.branch) &&
      toString(coverPageData.data.documentTitle) &&
      toString(coverPageData.data.status)
    )
  ) {
    isValid = false;
  }
  if (
    coverPageData.data.reportType === 'workorder' ||
    coverPageData.data.reportType === 'fieldservice'
  ) {
    if (
      !(
        toString(coverPageData.data.woNo) &&
        toString(coverPageData.data.orderLineNo)
      )
    ) {
      isValid = false;
    }
  }
  if (coverPageData.data.reportType === 'machine') {
    if (
      !(
        toString(coverPageData.data.machineId) &&
        toString(coverPageData.data.siteId)
      )
    ) {
      isValid = false;
    }
  }
  if (
    coverPageData.data.status === 'CL' &&
    !toString(coverPageData.data.endDate)
  ) {
    isValid = false;
  }
  return isValid;
};

const CreateDocument = (): JSX.Element => {
  const history = useHistory();
  const { reportId, status, onCreateReport } = useCreateReport({});
  const [coverImage, setCoverImage] = useState<ReportImage>();
  const [coverImageForUpload, setCoverImageForUpload] = useState<ReportImage>();
  const [coverImageForRemoval, setCoverImageForRemoval] =
    useState<ReportImage>();
  const { createParams } = useMiLocOrTeamId({ sendTeamId: false });
  const { miLoc } = createParams();
  const { templates } = useFindTemplates({});

  const initCoverPage: CoverPageForm = {
    data: {
      templateId: '',
      reportType: '',
      documentTitle: '',
      startDate: '',
      endDate: '',
      status: 'IP',
      shop: miLoc,
      woNo: '',
      ocn: '',
      branch: '',
      customer: '',
      customerName: '',
      customerNo: '',
      orderLineNo: '',
      machineId: '',
      siteId: '',
      templateVersion: '',
      custMachineId: '',
      machineText: '',
      siteText: '',
      customerContact: '',
      customerContactPhone: '',
      creationUserId: '',
      creationUserName: '',
      creationTmstmp: '',
      lastUpdUserId: '',
      lastUpdUserName: '',
      lastUpdTmstmp: '',
    },
  };

  const [coverPageData, setCoverPageData] = useState(initCoverPage);

  const onCreateClick = () => {
    const coverPage = {
      templateId: toString(coverPageData.data.templateId),
      name: toString(coverPageData.data.documentTitle),
      status: toString(coverPageData.data.status),
      startDate: toString(coverPageData.data.startDate),
      endDate: toString(coverPageData.data.endDate),
      miLoc: toString(coverPageData.data.branch),
      shopLoc: toString(coverPageData.data.shop),
      orderCtlNo: toString(coverPageData.data.ocn),
      woCtlNo: toString(coverPageData.data.woNo),
      customerNo: toString(coverPageData.data.customerNo),
      customerName: toString(coverPageData.data.customerName),
      reportType: toString(coverPageData.data.reportType),
      orderLineNo: toInteger(coverPageData.data.orderLineNo),
      machineId: toInteger(coverPageData.data.machineId),
      siteId: toInteger(coverPageData.data.siteId),
      templateVersion: toInteger(coverPageData.data.templateVersion),
      custMachineId: toString(coverPageData.data.custMachineId),
      machineText: toString(coverPageData.data.machineText),
      siteText: toString(coverPageData.data.siteText),
      customerContact: toString(coverPageData.data.customerContact),
      customerContactPhone: toString(coverPageData.data.customerContactPhone),
      creationUserId: toString(coverPageData.data.creationUserId),
      creationUserName: toString(coverPageData.data.creationUserName),
      creationTmstmp: toString(coverPageData.data.creationTmstmp),
      lastUpdUserId: toString(coverPageData.data.lastUpdTmstmp),
      lastUpdUserName: toString(coverPageData.data.lastUpdUserName),
      lastUpdTmstmp: toString(coverPageData.data.lastUpdTmstmp),
    };
    onCreateReport({
      coverPage,
      updatedImages: coverImageForUpload ? [coverImageForUpload] : [],
    });
  };

  useEffect(() => {
    if (status === 'success' && !isNil(reportId)) {
      history.replace(
        concatRoutes(documentsURL(), documentDetailsURL(toString(reportId)))
      );
    }
  }, [history, reportId, status]);

  const { downloadButton, loader, offlineBanner } = useDocumentSync();

  return (
    <IonPage>
      <Header
        title="Create Document"
        eyebrow="Documents"
        withBackButton
        testid="document"
        toolbarChildren={downloadButton}
      >
        {loader}
        {offlineBanner}
      </Header>
      <IonContent>
        <div>
          <DocumentCoverPage
            startingSaveSection={false}
            isExpanded
            isLoading={false}
            isNewDoc
            setIsExpanded={() => {}}
            formData={coverPageData}
            setFormData={setCoverPageData}
            coverImage={coverImage}
            setCoverImage={setCoverImage}
            coverImageForUpload={coverImageForUpload}
            setCoverImageForUpload={setCoverImageForUpload}
            coverImageForRemoval={coverImageForRemoval}
            setCoverImageForRemoval={setCoverImageForRemoval}
          />
        </div>
      </IonContent>
      {size(templates) > 0 && (
        <IonFooter className={styles.footer}>
          <IonRow>
            <Button
              className={styles.createButton}
              variant="action"
              testid="create-button"
              text="Create Document"
              disabled={
                !isCoverPageValid(coverPageData) || status === 'loading'
              }
              onClick={onCreateClick}
            />
          </IonRow>
        </IonFooter>
      )}
    </IonPage>
  );
};

export default CreateDocument;
