import { useMemo } from 'react';
import type { AxiosError } from 'axios';
import type { Dictionary } from 'lodash';
import { size, isEmpty, toNumber } from 'lodash';
import { useInfiniteQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import type {
  QueryFnProps,
  QueryParamsType,
} from 'common/api/utils/useGetQueryFlags';
import { getUnixTime } from 'date-fns';
import type { StartCustomer } from 'InventoryApp/models/StartProfile';
import { getLocationString } from 'InventoryApp/util/inventoryUtil';
import { useAxios } from 'providers/AxiosProvider';
import {
  doConcatDataPages,
  doGetIsLoading,
  doPromiseAPI,
  useKeyUserId,
  useMiLocOrTeamId,
} from 'api/helpers';
import type { InfiniteQueryFlags } from 'models/Search';
import { pageSize } from 'utils/constants';

export const findStartCustomersQueryKey = 'find-start-customers-list';

interface UseFindStartCustomersProps {
  miLoc?: string;
  enabled?: boolean;
  searchQuery: string;
  limit?: number;
  sendVirtualTeamId?: boolean;
}

interface FindStartCustomersData {
  items: StartCustomer[];
  totals: number;
}

interface UseFindStartCustomersAPIResponse {
  rows: StartCustomer[];
  totalRows: number;
}

interface UseFindStartCustomersResponse {
  findStartCustomersList?: StartCustomer[];
  loc?: string;
}

const useFindStartCustomers = ({
  miLoc,
  enabled,
  searchQuery,
  limit = pageSize(),
  sendVirtualTeamId,
}: UseFindStartCustomersProps): UseFindStartCustomersResponse &
  InfiniteQueryFlags => {
  const { findStartCustomerAPI } = useAPIUrl();
  const { axios } = useAxios();
  const { createQueryKey } = useKeyUserId();
  const { createParams } = useMiLocOrTeamId({
    miLoc,
    sendVirtualTeamId,
  });
  const params: QueryParamsType = {
    ...createParams(),
    limit,
  };

  const { miLoc: paramMiLoc = '', teamId = '' } = params as Dictionary<string>;
  const loc = getLocationString(paramMiLoc || teamId || []);

  if (!isEmpty(searchQuery)) {
    params.search = searchQuery;
  }

  const { getURLParams } = useMiLocOrTeamId(params);

  const doGetStartFindCustomersList = ({
    pageParam = 1,
    signal,
  }: QueryFnProps) => {
    return doPromiseAPI<FindStartCustomersData>(async () => {
      const { data } = await axios.get<UseFindStartCustomersAPIResponse>(
        findStartCustomerAPI(getURLParams({ ...params, page: pageParam })),
        { signal }
      );

      return {
        items: data.rows,
        totals: data.totalRows,
      };
    });
  };

  const response = useInfiniteQuery<FindStartCustomersData, AxiosError>(
    createQueryKey(findStartCustomersQueryKey, { ...params }),
    doGetStartFindCustomersList,
    {
      enabled,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      retry: (failureCount: number, err: AxiosError<any>) =>
        err.response?.status === 403 ? false : !(failureCount < 3),
      getNextPageParam: (lastPage, pages) =>
        size(lastPage.items) < limit ? false : size(pages) + 1,
    }
  );

  const {
    data,
    error,
    status,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
    dataUpdatedAt,
  } = response;

  let lastUpdatedAt = new Date();
  if (toNumber(dataUpdatedAt) > 0) {
    lastUpdatedAt = new Date(dataUpdatedAt);
  }

  const findStartCustomersList = useMemo(
    () =>
      doConcatDataPages<StartCustomer, FindStartCustomersData>(data, 'items'),
    [data]
  );

  const hasItems = size(findStartCustomersList) > 0;
  const hasError = status === 'error';
  const isEmptyResponse = status === 'success' && !hasNextPage && !hasItems;
  const noMoreData = status === 'success' && !hasNextPage && hasItems;
  const showLoader = doGetIsLoading(response) || isFetchingNextPage;
  const enableInfiniteScroll = !(!hasNextPage || isFetchingNextPage);

  return {
    error,
    showLoader,
    refetch: async () => {
      await refetch();
    },
    enableInfiniteScroll,
    noMoreData,
    hasError,
    isEmptyResponse,
    findStartCustomersList,
    fetchNextPage: async () => {
      await fetchNextPage();
    },
    lastUpdatedAt: getUnixTime(lastUpdatedAt),
    loc,
  };
};

export default useFindStartCustomers;
