import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonRow } from '@ionic/react';
import type { SnoozedData } from 'models/SnoozedData';
import { findIcon } from 'utils/icons';
import Text from 'components/Text/Text';
import classes from './SnoozeTitle.module.scss';

export interface SnoozeTitleProps {
  snoozedData?: SnoozedData;
  testid: string;
  className?: string;
}

const SnoozeTitle = ({
  className,
  snoozedData,
  testid,
}: SnoozeTitleProps): JSX.Element | null => {
  if (!snoozedData?.isSnoozed) {
    return null;
  }

  return (
    <IonRow
      className={classNames(classes.container, className)}
      data-testid={`${testid}-snooze-title-row`}
    >
      <FontAwesomeIcon
        className={classes.snoozeIcon}
        icon={findIcon(snoozedData?.isSnoozed ? 'clock' : 'alarm-clock', 'fas')}
        data-testid="snooze-icon"
        transform="shrink-8"
      />
      <Text
        className={classes.snoozeText}
        variant="content-smaller"
        text={snoozedData?.when}
        testid={`${testid}-aging`}
      />
    </IonRow>
  );
};

export default SnoozeTitle;
