/* eslint-disable no-console */
import { head, map, size } from 'lodash';
import type { Template } from 'DocumentsApp/models/Template';
import useDocumentsDB from './initDocumentsDB';

interface UseTemplateDBResponse {
  createTemplates: (templates: Template[]) => Promise<void>;
  findTemplates: () => Promise<Template[]>;
  getTemplateById: (templateId: string) => Promise<Template>;
}

const useTemplateDB = (): UseTemplateDBResponse => {
  const { db, openDB, closeDB } = useDocumentsDB();

  const createTemplates = async (templates: Template[]): Promise<void> => {
    try {
      await openDB();
      const statements = map(templates, (template) => ({
        statement: `INSERT OR REPLACE INTO template (
          templateId,
          creationUserId,
          status,
          templateName,
          templateVersion,
          creationTmstmp,
          templateType,
          isDownloaded,
          templateJson
          ) VALUES (?,?,?,?,?,?,?,?,?)`,
        values: [
          template.templateId,
          template.creationUserId,
          template.status,
          template.templateName,
          template.templateVersion,
          template.creationTmstmp,
          template.templateType,
          template.isDownloaded,
          JSON.stringify({ templateSections: template.templateSections || [] }),
        ],
      }));
      if (size(statements) > 0) {
        await db.executeSet(statements, true);
      }
    } catch (error) {
      console.log('Error saving templates to database', error);
      throw new Error('Error saving templates to database');
    } finally {
      await closeDB();
    }
  };

  const findTemplates = async (): Promise<Template[]> => {
    try {
      await openDB();
      return (
        await db.query(`SELECT * FROM template WHERE isDownloaded = ?`, [true])
      ).values as Template[];
    } catch (error) {
      console.log('Error loading templates', error);
      throw new Error('Error loading templates');
    } finally {
      await closeDB();
    }
  };

  const getTemplateById = async (templateId: string): Promise<Template> => {
    try {
      const vars = [templateId];
      await openDB();
      return head(
        (
          await db.query(
            `SELECT * FROM template
            WHERE templateId = ?`,
            vars
          )
        ).values
      ) as Template;
    } catch (error) {
      console.log('Error loading templates', error);
      throw new Error('Error loading templates');
    } finally {
      await closeDB();
    }
  };

  return {
    createTemplates,
    findTemplates,
    getTemplateById,
  };
};

export default useTemplateDB;
