import { useMemo } from 'react';
import type { AxiosError } from 'axios';
import { isEmpty, size, toNumber, toString } from 'lodash';
import { useInfiniteQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { getUnixTime } from 'date-fns';
import { useAxios } from 'providers/AxiosProvider';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import type { WorkOrderItem } from 'WorkOrders/models/WorkOrderItem';
import { doConcatDataPages, doPromiseAPI, useKeyUserId } from 'api/helpers';
import type { InfiniteQueryFlags } from 'models/Search';
import { pageSize } from 'utils/constants';

export const findWorkOrdersQueryKey = 'work-orders';

interface UseFindWorkOrdersProps {
  workOrderCtlNo: string;
  miLoc: string;
  limit?: number;
  enabled?: boolean;
}

interface UseFindWorkOrdersResponse {
  workOrders: WorkOrderItem[];
}

const useFindWorkOrders = ({
  workOrderCtlNo,
  miLoc,
  limit = pageSize(),
  enabled = true,
}: UseFindWorkOrdersProps): UseFindWorkOrdersResponse & InfiniteQueryFlags => {
  const { axios } = useAxios();
  const { findWorkOrdersAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const { isOnline } = useNetworkStatus();
  const params: Record<string, string> = {
    workOrderCtlNo,
    limit: toString(limit),
    miLoc,
  };

  const doSearchWorkOrders = ({ pageParam = 1 }) => {
    return doPromiseAPI<WorkOrderItem[]>(async (cancelToken) => {
      let data: WorkOrderItem[] = [];
      if (isOnline) {
        const response = await axios.get<WorkOrderItem[]>(
          findWorkOrdersAPI(
            miLoc,
            toString(
              new URLSearchParams({ ...params, page: toString(pageParam) })
            )
          ),
          { cancelToken }
        );
        data = response.data;
      }
      return data;
    });
  };

  const response = useInfiniteQuery<WorkOrderItem[], AxiosError>(
    createQueryKey(findWorkOrdersQueryKey, params),
    doSearchWorkOrders,
    {
      enabled,
      getNextPageParam: (lastPage, pages) =>
        size(lastPage) < limit ? false : size(pages) + 1,
    }
  );

  const {
    data,
    error,
    status,
    hasNextPage,
    isFetchingNextPage,
    refetch,
    fetchNextPage,
    dataUpdatedAt,
  } = response;

  const workOrders = useMemo(
    () => doConcatDataPages<WorkOrderItem>(data),
    [data]
  );

  const hasItems = !isEmpty(workOrders);
  const hasError = status === 'error';
  const isEmptyResponse = status === 'success' && !hasNextPage && !hasItems;
  const noMoreData = status === 'success' && !hasNextPage && hasItems;
  const showLoader = status === 'loading' || isFetchingNextPage;
  const enableInfiniteScroll = !(!hasNextPage || isFetchingNextPage);

  let lastUpdatedAt = new Date();
  if (toNumber(dataUpdatedAt) > 0) {
    lastUpdatedAt = new Date(dataUpdatedAt);
  }

  return {
    refetch: async () => {
      await refetch();
    },
    fetchNextPage: async () => {
      await fetchNextPage();
    },
    workOrders,
    error,
    hasError,
    showLoader,
    isEmptyResponse,
    noMoreData,
    enableInfiniteScroll,
    lastUpdatedAt: getUnixTime(lastUpdatedAt),
  };
};

export default useFindWorkOrders;
