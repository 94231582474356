import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'providers/ToastProvider';
import { ToastType } from 'models/Toast';
import type { RootState } from 'store/reducers';
import { setShowCostDetails } from 'store/user';

interface UseShowCostDetailsResponse {
  toggleCostDetailsOption: (disabled?: boolean) => {
    text: string;
    disabled?: boolean;
    onClick: () => void;
    testid: string;
  };
}

const useToggleCostDetailsDispatcher = (): UseShowCostDetailsResponse => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const { showCostDetails } = useSelector((state: RootState) => state.user);
  const toggleCostDetailsDispatch = () => {
    dispatch(setShowCostDetails(!showCostDetails));
    addToast({
      type: ToastType.info,
      leftIcon: ['fal', 'check'],
      variant: 'mipro-toast',
      header: showCostDetails
        ? t('productSearch:hidingCostTitle')
        : t('productSearch:showingCostTitle'),
      text: showCostDetails
        ? t('productSearch:hideCostMessage')
        : t('productSearch:showCostMessage'),
      testid: 'show-cost-details-toast',
    });
  };

  return {
    toggleCostDetailsOption: (disabled?: boolean) => {
      return {
        text: showCostDetails
          ? t('productSearch:hideCostDetails')
          : t('productSearch:showCostDetails'),
        onClick: () => toggleCostDetailsDispatch(),
        disabled,
        testid: 'gp-details-option',
      };
    },
  };
};

export default useToggleCostDetailsDispatcher;
