import { size } from 'lodash';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { doPromiseAPI, onSuccessMutation } from 'api/helpers';
import type { MiProFile } from 'models/Attachment';
import { findActivityNotesQueryKey } from './useFindActivityNotes';

interface DeleteActivityAttachmentBody {
  deletedImages?: MiProFile[];
}

interface UseDeleteImagesResponse {
  doDeleteActivityAttachments: (
    vars: DeleteActivityAttachmentBody
  ) => Promise<void>;
}
const useDeleteActivityNoteAttachments = (): UseDeleteImagesResponse => {
  const { axios } = useAxios();
  const { deleteActivityNoteAttachmentsAPI } = useAPIUrl();
  const queryClient = useQueryClient();

  const doDeleteActivityAttachments = async (
    vars: DeleteActivityAttachmentBody
  ) => {
    return doPromiseAPI(async (cancelToken) => {
      if (size(vars.deletedImages) > 0) {
        await axios.delete(deleteActivityNoteAttachmentsAPI(), {
          cancelToken,
          data: vars.deletedImages,
        });
      }
    });
  };
  useMutation(doDeleteActivityAttachments, {
    // TODO: optimisticUI updates in add/update note hooks
    onSuccess: async () => {
      await onSuccessMutation(queryClient, findActivityNotesQueryKey);
    },
  });

  return {
    doDeleteActivityAttachments,
  };
};

export default useDeleteActivityNoteAttachments;
