import React, { useSelector } from 'react-redux';
import type { Dictionary } from 'lodash';
import { head, isEmpty, map, size } from 'lodash';
import { IonContent, IonPage } from '@ionic/react';
import useGetStoreroom from 'StoreroomsApp/api/getStoreroom';
import useFindChargeBackProfiles from 'StoreroomsApp/api/useFindChargeBackProfiles';
import useFindIssues from 'StoreroomsApp/api/useFindIssues';
import IssueItem from 'StoreroomsApp/components/IssueItem/IssueItem';
import useIssueSync from 'StoreroomsApp/hooks/useIssueSync';
import type { RootState } from 'store/reducers';
import Header from 'components/Header/Header';
import Loader from 'components/Loader/Loader';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './ReviewIssues.module.scss';

const ReviewIssues = (): JSX.Element => {
  const { storeroom: storeroomId = '' } = useSelector(
    (state: RootState) => state.user
  );
  const { offlineBanner } = useIssueSync();

  const { issues, isLoading: issuesLoading } = useFindIssues({
    storeroomNumber: storeroomId,
  });

  const { storeroom } = useGetStoreroom({ storeroomNumber: storeroomId });

  const { profiles, isLoading: profilesLoading } = useFindChargeBackProfiles({
    customerNumber: storeroom?.customerNumber,
    enabled: !isEmpty(storeroom?.customerNumber),
  });
  const profile = (head(profiles) || {}) as Dictionary<unknown>;

  const isLoading = issuesLoading || profilesLoading;

  return (
    <IonPage data-testid="review-issues">
      <Header withBackButton title="Issues List" testid="review-issues-header">
        {offlineBanner}
      </Header>
      <IonContent className={classes.content} data-testid="storerooms-list">
        <Loader
          className={classes.loader}
          text="Loading issues"
          isOpen={isLoading}
          testid="issues-loader"
        />
        {!isLoading && (
          <>
            {size(issues) === 0 && (
              <WarningMessage
                className={classes.warningMessage}
                icon={['far', 'info-circle']}
                title="No issues pending"
              />
            )}
            <div>
              {map(issues, (issue) => (
                <IssueItem key={issue.id} profile={profile} issue={issue} />
              ))}
            </div>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default ReviewIssues;
