import React, { useEffect, useState } from 'react';
import { map } from 'lodash';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import useFindManufacturers from 'api/inspectionReports/useFindManufacturers';
import type { SelectModalItem } from 'models/Search';
import SelectModal from 'components/SelectModal/SelectModal';

interface ManufacturerSelectProps {
  selItem?: SelectModalItem;
  className?: string;
  label: string;
  inputName?: string;
  required?: boolean;
  iconClassName?: string;
  placeholder?: string;
  disabled?: boolean;
  setManufacturer: (o: SelectModalItem) => void;
  showInput?: boolean;
  openModal?: boolean;
  setOpenModal?: (b: boolean) => void;
  onBlur?: () => void;
  onClose?: () => void;
  inputError?: string;
}

const ManufacturerSelect = ({
  selItem,
  className,
  label,
  inputName,
  required,
  iconClassName,
  placeholder,
  disabled,
  setManufacturer,
  showInput = true,
  openModal = false,
  setOpenModal,
  onBlur,
  onClose,
  inputError,
}: ManufacturerSelectProps & IonicReactProps): JSX.Element => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const {
    error,
    items,
    isEmptyResponse,
    showLoader,
    fetchNextPage,
    enableInfiniteScroll,
  } = useFindManufacturers({
    query: searchQuery,
  });

  const inputDisplay = selItem?.key ? `(${selItem.key}) ${selItem.title}` : '';

  useEffect(() => {
    if (!isOpen && !openModal) {
      setSearchQuery('');
    }
  }, [isOpen, openModal, searchQuery, setSearchQuery]);

  const manufacturerItems = map(items, (item) => ({
    key: item.mfrCtlNo,
    title: item.mfrName,
    description: item.mfrCtlNo,
  }));

  return (
    <SelectModal
      isOpen={setOpenModal ? openModal : isOpen}
      setIsOpen={setOpenModal || setIsOpen}
      type="manufacturer"
      subType=""
      selItem={selItem || { title: '', description: '', key: '' }}
      setSelItem={(o) => {
        setTimeout(() => {
          setManufacturer(o);
        }, 100);
      }}
      testid="select-manufacturer-modal"
      isEmptyResponse={isEmptyResponse}
      showLoader={showLoader && searchQuery.length > 0}
      fetchNextPage={fetchNextPage}
      error={error}
      enableInfiniteScroll={enableInfiniteScroll}
      items={manufacturerItems}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      onClick={() => setIsOpen(true)}
      onBlur={onBlur}
      onClose={onClose}
      inputError={inputError}
      label={label}
      inputName={inputName}
      required={required}
      disabled={disabled}
      value={inputDisplay}
      placeholder={placeholder}
      iconClassName={iconClassName}
      showInput={showInput}
      className={className}
      icon="caret-down"
    />
  );
};

export default ManufacturerSelect;
