import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IonContent,
  IonFooter,
  IonPage,
  IonRow,
  IonToolbar,
} from '@ionic/react';
import useGoBack from 'hooks/useGoBack';
import { findIcon } from 'utils/icons';
import Button from 'components/Button/Button';
import classes from './NotAvailable.module.scss';

const NotAvailable = (): JSX.Element => {
  const { goBack } = useGoBack();

  return (
    <IonPage className={classes.NotAvailable} data-testid="NotAvailable-page">
      <IonContent>
        <div className={classes.title}>Sorry</div>

        <FontAwesomeIcon
          className={classes.icon}
          icon={findIcon('map-signs')}
        />
        <div className={classes.bigCircle} />
        <div className={classes.smallCircle} />

        <div className={classes.text}>
          It looks like this page is no <br /> longer available
        </div>
      </IonContent>
      <IonFooter className={classes.footer}>
        <IonToolbar>
          <IonRow>
            <Button
              variant="action"
              text="Go Back"
              onClick={goBack}
              testid="go-back-button"
            />
          </IonRow>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default NotAvailable;
