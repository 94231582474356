import React, { useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { get, includes, isEmpty, toNumber } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonItemOption, IonItemOptions, IonItemSliding } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import type { Dictionary } from '@reduxjs/toolkit';
import { AlertVariantEnum } from 'common/components/Alert/Alert';
import List from 'common/components/List/List';
import { formatNumber } from 'common/utils/numberHelper';
import ListItem from 'InventoryApp/components/ListItem/ListItem';
import useDeleteOrder from 'ProductSearchApp/api/orders/useDeleteOrder';
import useFindOrders from 'ProductSearchApp/api/orders/useFindOrders';
import useGetOrder from 'ProductSearchApp/api/useGetOrder';
import LostSaleSelect from 'ProductSearchApp/components/FastFind/LostSaleSelect';
import { getStatus } from 'ProductSearchApp/components/OcnBadge/OcnBadge';
import useTransmitDetails from 'ProductSearchApp/hooks/useTransmitLogDetails';
import type { Order } from 'ProductSearchApp/models/Order';
import {
  OrderListTabEnum,
  type OrderListTabOptionProps,
} from 'ProductSearchApp/pages/CustomerOrders/CustomerOrders';
import {
  getOcnType,
  getPoText,
  isClosedOrder,
  isUnfinishedOrder,
} from 'ProductSearchApp/util/ocnHelpers';
import { useToasts } from 'providers/ToastProvider';
import useAccessControls, { AccessControlType } from 'hooks/useAccessControls';
import type { Customer } from 'models/Customer';
import type { RootState } from 'store/reducers';
import { formatCardDate } from 'utils/date';
import { getErrorMessage } from 'utils/helpers';
import { removeLeadingZeros } from 'utils/number';
import {
  goToProductSearch,
  goToReviewCart,
} from 'navigation/navigationHelpers';
import Button from 'components/Button/Button';
import SheetModal from 'components/Modals/SheetModal/SheetModal';
import Text from 'components/Text/Text';
import classes from './OrderList.module.scss';

interface OrderListItemProps {
  item: Order;
  noAccess?: boolean;
  onClick?: () => void;
  onDelete?: () => void;
}

const OrderListItem = ({
  item,
  noAccess,
  onClick,
  onDelete,
}: OrderListItemProps): JSX.Element => {
  const { t } = useTranslation();
  const namespace = 'productSearch:orderList';
  const ocnNameSpace = 'productSearch:ocn';
  const { userInfo } = useSelector((state: RootState) => state.user);
  const loggedInUserId = get(userInfo, 'userid', '');
  const isPriceOverride = toNumber(item.pendingOverrideCount) > 0;

  const { hasTransmitted, ocnListTransmitMessage } = useTransmitDetails({
    ...item.lastDocumentTransmission,
  });
  const isTransmitted =
    hasTransmitted && !isPriceOverride && !item.approvedAfterLastTransmission;
  let alertMessage = '';

  if (isPriceOverride) {
    alertMessage = t(`${ocnNameSpace}:priceOverridePending`);
  }

  if (!isPriceOverride && item.approvedAfterLastTransmission) {
    alertMessage = t(`${ocnNameSpace}:approvedPriceOverrideNotEmailed`);
  }

  if (isTransmitted) {
    alertMessage = ocnListTransmitMessage;
  }

  let title = `${namespace}:OrderListTitlePrefix`;
  if (getOcnType(item) === 'unfinished') {
    title = `${namespace}:OCNListTitlePrefix`;
  } else if (getOcnType(item) === 'quote') {
    title = `${namespace}:QuoteListTitlePrefix`;
  }

  const badge = getStatus[item?.processStatus];

  const { custPONo, custReleaseNo } = item;
  const custPoOrReleaseNo = getPoText(custPONo, custReleaseNo);

  const secondaryTextArray: Dictionary<unknown>[] = [
    {
      text: t(`${namespace}:OCNListSecondary`, {
        username: item.userName,
        count: loggedInUserId === item.enteredById ? 0 : 1,
      }),
    },
  ];

  if (!isEmpty(custPoOrReleaseNo)) {
    secondaryTextArray.unshift({
      children: (
        <>
          {!isEmpty(custPoOrReleaseNo) && (
            <>
              <Text
                variant="list-item-secondaryText"
                text={`${t(`${namespace}:PoNum`)}`}
                textQuery={`${t(`${namespace}:PoNum`)}`}
              />
              &nbsp;
              <Text
                variant="list-item-secondaryText"
                text={custPoOrReleaseNo}
              />
            </>
          )}
        </>
      ),
    });
  }
  return (
    <IonItemSliding
      className={classes.item}
      disabled={noAccess}
      data-testid={`quote-${item.orderCtlNo}`}
    >
      <IonItemOptions>
        <IonItemOption
          className={classes.options}
          onClick={(e) => {
            e.stopPropagation();
            onDelete?.();
          }}
          disabled={noAccess}
          data-testid="delete-button"
        >
          <FontAwesomeIcon icon={['far', 'trash']} />
          <Text variant="mipro-body-copy" text={t('common:delete')} />
        </IonItemOption>
      </IonItemOptions>
      <ListItem
        onClick={onClick}
        className={classNames({
          [classes.disabledItem]: item.isOptimisticallyUpdating,
        })}
        icon={['far', 'chevron-right']}
        titleClassPrefix={classes.prefix}
        title={{
          text: t(title, {
            ocn: removeLeadingZeros(item.orderCtlNo),
          }),
          className: classes.title,
        }}
        subTitle={t(`${namespace}:quoteDescription`, {
          total: formatNumber({
            number: toNumber(item.totalOrderAmt),
            scale: 2,
            currencyType: 'USD',
          }),
          lines: item.linesOrdered,
          date: formatCardDate(item.orderDate, false, false),
        })}
        subTitleClassName={classes.subTitle}
        secondaryTextArray={secondaryTextArray}
        secondaryClassName={classes.secondary}
        testid="quote-item"
        badge={{
          text: isUnfinishedOrder(item.orderTypeCd)
            ? t('productSearch:ocn:unfinished')
            : t(badge?.text),
          className: isUnfinishedOrder(item.orderTypeCd)
            ? classes.unfinishedBadge
            : badge?.className,
        }}
        alert={
          !isUnfinishedOrder(item.orderTypeCd) && !isClosedOrder(item)
            ? {
                text: alertMessage,
                variant: isTransmitted
                  ? AlertVariantEnum.success
                  : AlertVariantEnum.warning,
              }
            : undefined
        }
      />
    </IonItemSliding>
  );
};

interface OrderListProps {
  /* eslint-disable react/require-default-props */
  orderType: OrderListTabOptionProps;
  customerData?: Customer;
  scrollParent?: HTMLElement | null;
  /* eslint-enable react/require-default-props */
}

export interface OrderListRef {
  refetch?: () => Promise<unknown>;
}

const OrderList = React.forwardRef<
  OrderListRef,
  OrderListProps & IonicReactProps
>((props, outerRef): JSX.Element => {
  const { orderType, customerData, scrollParent } = props;
  const { miLoc = '', customerNo = '' } = customerData || {};
  const history = useHistory();
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const namespace = 'productSearch:orderList';
  const [deleteQuoteModalIsOpen, setDeleteQuoteModalIsOpen] = useState(false);
  const [lostSalesModalIsOpen, setLostSalesModalIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Order>();
  const canEditOrders = useAccessControls(
    AccessControlType.EditOrdersAccessControls
  );

  const closedOrderList = orderType.key === OrderListTabEnum.closed;

  let orderTypes = [OrderListTabEnum.quotes, OrderListTabEnum.unfinished];

  if (
    orderType.key === OrderListTabEnum.orders ||
    orderType.key === OrderListTabEnum.closed
  ) {
    orderTypes = [OrderListTabEnum.orders];
  }

  const {
    orders,
    error,
    isEmptyResponse,
    isLoading,
    noMoreData,
    fetchNextPage,
    refetch,
  } = useFindOrders({
    miLoc,
    customerNo,
    orderTypes,
    processStatus: closedOrderList ? 'CL' : '',
  });

  const { onDeleteOrder, onOptimisticDeleteOrder, rollbackDeleteOrder } =
    useDeleteOrder();

  const searchButton = (
    <Button
      className={classes.searchButton}
      variant="action"
      text="Search Products"
      testid="search-products-button"
      data-testid="search-products-button"
      onClick={() => history.replace(goToProductSearch(miLoc, customerNo))}
    />
  );

  const { orderChangedToOP } = useGetOrder({});

  const itemContent = (index: number, item: Order) => {
    // TODO should use canEditOCN(item) when api returns isEditable in list
    const enableItemActions =
      canEditOrders &&
      item?.processStatus === 'OP' &&
      includes(['O', 'Q', 'U'], item?.orderTypeCd);
    return (
      <OrderListItem
        item={item}
        key={item.orderCtlNo}
        noAccess={item.isOptimisticallyUpdating || !enableItemActions}
        onClick={async () => {
          if (item.isOptimisticallyUpdating) {
            return;
          }
          try {
            const orderChange = await orderChangedToOP?.({ ...item, miLoc });
            if (orderChange?.changedToOP) {
              await refetch?.();
            } else {
              history.push(
                goToReviewCart({
                  miLoc,
                  shipToCustNo: customerNo,
                  orderCtlNo: item.orderCtlNo,
                  ocnType: getOcnType(item),
                  ocnMode: 'review',
                })
              );
            }
          } catch (e) {
            // DOC: error handled in util
          }
        }}
        onDelete={() => {
          setSelectedItem(item);
          setDeleteQuoteModalIsOpen(true);
        }}
      />
    );
  };

  useImperativeHandle(outerRef, () => ({ refetch }), [refetch]);

  return (
    <div className={classes.listWrapper}>
      <List
        title={{
          text: orderType.listTitle,
          wrapperProps: { className: classes.titleLabel },
        }}
        className={classes.list}
        stickyOffset={120}
        key={orderType.key}
        data={orders}
        itemContent={itemContent}
        scrollParent={scrollParent}
        isLoading={isLoading}
        isEmptyList={{
          isEmptyList: isEmptyResponse,
          title: orderType.emptyMessage,
          children: !closedOrderList ? searchButton : undefined,
        }}
        isError={{
          isError: !!error,
          title: orderType.errorMessage,
          body: getErrorMessage(error),
          children: !closedOrderList ? searchButton : undefined,
        }}
        isEndOfList={noMoreData}
        endReached={fetchNextPage}
        testid="order-list"
        data-testid="order-list"
      />
      {selectedItem && (
        <SheetModal
          title={orderType.deleteTitle}
          titleTextVariant="mipro-h3-headline"
          className={classes.deleteModal}
          titleClassName={classes.deleteTitle}
          contentClass={classes.deleteContent}
          isOpen={deleteQuoteModalIsOpen}
          setIsOpen={setDeleteQuoteModalIsOpen}
          initialBreakpoint={0.4}
          withRightCloseButton
          testid="delete-order-modal"
        >
          <Button
            className={classNames(classes.deleteButton, classes.primaryButton, {
              [classes.deleteOption]: isUnfinishedOrder(
                selectedItem.orderTypeCd
              ),
            })}
            variant="action"
            text={orderType.deleteButton}
            onClick={async () => {
              const context = await onOptimisticDeleteOrder(
                selectedItem.orderCtlNo
              );
              addToast({
                variant: 'mipro-toast',
                text: t(`${namespace}:deleteSuccessToast`, {
                  ocn: removeLeadingZeros(selectedItem.orderCtlNo),
                }),
                button: {
                  text: t('common:undo'),
                  handler: () => rollbackDeleteOrder(context),
                },
                onClose: () =>
                  onDeleteOrder({
                    miLoc,
                    orderCtlNo: selectedItem.orderCtlNo,
                    orderTypeCd: selectedItem.orderTypeCd,
                    orderSourceCd: selectedItem.orderSourceCd,
                    deleteOption: 'DL',
                    lostSale: '',
                  }),
                testid: 'delete-order-success-toast',
              });
              setDeleteQuoteModalIsOpen(false);
            }}
            testid="delete-order-button"
          />
          {selectedItem.orderTypeCd !== 'U' && (
            <Button
              className={classNames(
                classes.deleteButton,
                classes.primaryButton
              )}
              variant="action"
              text={t(`${namespace}:lostSale`)}
              onClick={() => {
                setLostSalesModalIsOpen(true);
              }}
              testid="lost-sale-button"
            />
          )}
          <Button
            className={classNames(classes.deleteButton)}
            variant="secondary"
            text={t('common:cancel')}
            onClick={() => {
              setSelectedItem(undefined);
              setDeleteQuoteModalIsOpen(false);
            }}
            testid="delete-cancel-button"
          />
        </SheetModal>
      )}
      {selectedItem && (
        <LostSaleSelect
          showInput={false}
          openModal={lostSalesModalIsOpen}
          setOpenModal={setLostSalesModalIsOpen}
          setLostSalesCode={(o) => {
            onDeleteOrder({
              miLoc,
              orderCtlNo: selectedItem.orderCtlNo,
              orderTypeCd: selectedItem.orderTypeCd,
              orderSourceCd: selectedItem.orderSourceCd,
              deleteOption: 'DL',
              lostSale: o.key,
            });
            setDeleteQuoteModalIsOpen(false);
          }}
        />
      )}
    </div>
  );
});

export default OrderList;
