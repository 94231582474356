import React from 'react';
import classNames from 'classnames';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { findIcon } from 'utils/icons';
import type Button from 'components/Button/Button';
import CustomerName from 'components/Contacts/CustomerName/CustomerName';
import Text from 'components/Text/Text';
import classes from './SnapshotLink.module.scss';

interface SnapshotLinkProps {
  eyebrow: string;
  withIcon?: boolean;
  customerPick12?: boolean;
  hasCorpGlobalIcon?: boolean;
  tailRow?: React.ReactNode;
  customerNameClass?: string;
  customerNoClass?: string;
  customContent?: React.ReactNode;
  customContentClassName?: string;
}

const SnapshotLink = ({
  className,
  customerNameClass,
  customerNoClass,
  eyebrow,
  href,
  text,
  withIcon = true,
  customerPick12,
  hasCorpGlobalIcon,
  disabled,
  onClick,
  testid,
  tailRow,
  leftIcon,
  customContent,
  customContentClassName,
}: SnapshotLinkProps &
  React.ComponentProps<typeof Button> &
  IonicReactProps): JSX.Element => (
  <div className={classNames(classes.container, className)}>
    <CustomerName
      customerPick12={customerPick12}
      hasCorpGlobalIcon={hasCorpGlobalIcon}
      customerName={text || ''}
      linkClassName={classes.link}
      textClassName={classNames(customerNameClass, classes.linkText)}
      iconClassName={classes.pick12Icon}
      disabled={disabled}
      leftIcon={leftIcon}
      rightIcon={withIcon ? findIcon('chevron-right') : undefined}
      onClick={onClick}
      testid={testid}
      href={href}
      customContent={customContent}
      customContentClassName={customContentClassName}
    />
    <Text
      className={classNames(customerNoClass, classes.text)}
      text={eyebrow}
    />
    {tailRow}
  </div>
);

export default SnapshotLink;
