import { useMemo } from 'react';
import type { AxiosError } from 'axios';
import { size, toString } from 'lodash';
import type { QueryFunctionContext } from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import type {
  QueryFlags,
  QueryParamsType,
} from 'common/api/utils/useGetQueryFlags';
import useGetQueryFlags from 'common/api/utils/useGetQueryFlags';
import type { ShippingMethod } from 'ProductSearchApp/models/Order';
import { useAxios } from 'providers/AxiosProvider';
import { doConcatDataPages, useKeyUserId } from 'api/helpers';
import { pageSize } from 'utils/constants';

export const findShippingMethodQueryKey = 'shipping-method';

interface UseFindShippingMethodProps {
  limit?: number;
  enabled?: boolean;
  query?: string;
}

interface UseFindShippingMethodResponse {
  shippingMethod: ShippingMethod[];
}

const useFindShippingMethod = ({
  limit = pageSize(),
  enabled = true,
  query,
}: UseFindShippingMethodProps): UseFindShippingMethodResponse & QueryFlags => {
  const { axios } = useAxios();
  const { findShippingMethodAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const params: QueryParamsType = {
    limit,
    fastFind: query,
    sortField: 'codeValue',
    sortDir: 'ASC',
  };

  const doFindShippingMethod = async ({
    pageParam = 1,
    signal,
  }: QueryFunctionContext) => {
    const { data } = await axios.get<ShippingMethod[]>(
      findShippingMethodAPI(
        toString(new URLSearchParams({ ...params, page: toString(pageParam) }))
      ),
      { signal }
    );
    return data;
  };

  const response = useInfiniteQuery<ShippingMethod[], AxiosError>(
    createQueryKey(findShippingMethodQueryKey, params),
    doFindShippingMethod,
    {
      enabled,
      getNextPageParam: (lastPage, pages) =>
        size(lastPage) < limit ? false : size(pages) + 1,
    }
  );

  const { data } = response;

  const shippingMethod = useMemo(
    () => doConcatDataPages<ShippingMethod>(data),
    [data]
  );

  const queryFlags = useGetQueryFlags({
    ...response,
    data: shippingMethod,
    enabled,
  });

  return {
    shippingMethod,
    ...queryFlags,
  };
};

export default useFindShippingMethod;
