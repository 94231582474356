import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { filter } from 'lodash';
import { IonCol } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { reportsDrillDownURL, reportsURL } from 'navigation';
import type { ReportsContextProps, TableFields } from 'models/Reports';
import { DrillDownFieldsEnum, RoleGroupEnum } from 'models/Reports';
import type { SortOption } from 'models/Sort';
import { findIcon } from 'utils/icons';
import { concatRoutes } from 'utils/navigations';
import Button from 'components/Button/Button';
import DropDown from 'components/DropDown/DropDown';
import Text from 'components/Text/Text';
import classes from './DrillDown.module.scss';
import TableDrillDown from './TableDrillDown';

const Pick12ColumnAccessByGroup = [
  RoleGroupEnum[RoleGroupEnum.CUST],
  RoleGroupEnum[RoleGroupEnum.PRD_GRP_01],
  RoleGroupEnum[RoleGroupEnum.PRD_GRP_02],
];

export const Pick12DrillDownFields: TableFields[] = [
  {
    key: DrillDownFieldsEnum.Sales,
    id: 'sales',
  },
  {
    key: DrillDownFieldsEnum['GP %'],
    id: 'gpPercentAmount',
  },
  {
    key: DrillDownFieldsEnum.GP,
    id: 'gpAmount',
  },
  {
    key: DrillDownFieldsEnum['Pick12 Count'],
    id: 'pick12CustomerCount',
    disabledLevels: Pick12ColumnAccessByGroup,
  },
  {
    key: DrillDownFieldsEnum['Total Pick12 Count'],
    id: 'totalPick12CustCount',
    disabledLevels: Pick12ColumnAccessByGroup,
  },
  {
    key: DrillDownFieldsEnum['Pick12 %'],
    id: 'pick12Pct',
    disabledLevels: Pick12ColumnAccessByGroup,
  },
  {
    key: DrillDownFieldsEnum['Avg Sales Acct'],
    id: 'avgSalesPerAcct',
    disabledLevels: Pick12ColumnAccessByGroup,
  },
  {
    key: DrillDownFieldsEnum['Last Year Sales'],
    id: 'previousSales',
    disabledNonToday: true,
  },
  {
    key: DrillDownFieldsEnum['Yoy Growth'],
    id: 'salesChange',
    disabledNonToday: true,
  },
  {
    key: DrillDownFieldsEnum['Effective Date'],
    id: 'effectiveDate',
  },
  {
    key: DrillDownFieldsEnum['Expiration Date'],
    id: 'expirationDate',
  },
];

const Pick12TableDrillDown = ({
  requestType,
  ...props
}: IonicReactProps &
  Omit<
    React.ComponentProps<typeof TableDrillDown>,
    'headerText' | 'fields' | 'customHeader'
  > &
  Pick<ReportsContextProps, 'requestType'>): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();

  const [isOpenHeaderMenu, setIsOpenHeaderMenu] = useState(false);

  const options: SortOption[] = useMemo(() => {
    const result: SortOption[] = [
      {
        key: 'pick-12-leader-board',
        name: t('reports:pick12LeaderBoard'),
        icon: findIcon('trophy'),
      },
      {
        key: 'view-as-pdf',
        name: t('reports:viewAsPDF'),
        icon: findIcon('file-spreadsheet'),
        hidden: true,
      },
      {
        key: 'share-in-email',
        name: t('reports:shareInEmail'),
        icon: findIcon('link'),
        hidden: true,
      },
    ];
    return result;
  }, [t]);

  const toggleMenuHeader = () => {
    setIsOpenHeaderMenu(!isOpenHeaderMenu);
  };

  const helpModalBody = (
    <div className={classes.sheetContent}>
      <Text
        className={classes.sheetText}
        text={t('reports:pick12ModalTextLine1')}
      />
      <ul>
        <li>
          <Text
            className={classes.sheetText}
            text={t('reports:pick12ModalTextLine2')}
          />
        </li>
        <li>
          <Text
            className={classes.sheetText}
            text={t('reports:pick12ModalTextLine3')}
          />
        </li>
        <li>
          <Text
            className={classes.sheetText}
            text={t('reports:pick12ModalTextLine4')}
          />
        </li>
      </ul>
    </div>
  );

  return (
    <TableDrillDown
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      reportHeaderClass={classes.reportHeader}
      headerText={t('reports:pick12Report')}
      fields={Pick12DrillDownFields}
      customHeader={
        <IonCol slot="end" className={classes.pick12Dots}>
          <Button
            variant="icon-action"
            icon={findIcon('ellipsis-h')}
            testid="pick12-dots-button"
            id="pick12-menu-button"
          />
          <DropDown
            trigger="pick12-menu-button"
            dismissOnSelect
            className={classes.pick12Menu}
            alignment="start"
            side="start"
            onClick={toggleMenuHeader}
            iconsLeft
            testid="requesttype-dropdown"
            filterOptions={filter(options, (option) => !option.hidden)}
            selectedItem={requestType}
            onOptionClick={() => {
              history.push(
                concatRoutes(
                  reportsURL(),
                  reportsDrillDownURL('pick12LeaderBoard', '', '', '', '')
                )
              );
            }}
            id="pick12-menu-button"
          />
        </IonCol>
      }
      helpButton={{
        modal: {
          initialBreakpoint: 0.75,
          children: helpModalBody,
          testid: 'pick12-report',
          title: t('reports:pick12Reporting'),
        },
        button: {
          iconClassName: classes.infoIcon,
          testid: 'pick12-report',
        },
      }}
    />
  );
};

export default Pick12TableDrillDown;
