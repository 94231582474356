import React from 'react';
import classNames from 'classnames';
import { IonRow } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import Button from 'components/Button/Button';
import classes from './PriceOverrideLabel.module.scss';

interface PriceOverrideLabelProps {
  text?: string;
}

const PriceOverrideLabel = ({
  text,
  className,
}: PriceOverrideLabelProps & IonicReactProps): JSX.Element => {
  return (
    <IonRow className={classNames(classes.priceOverrideLabel, className)}>
      <Button
        variant="link"
        text={text}
        icon={['fas', 'calculator']}
        testid="price-override-label"
      />
    </IonRow>
  );
};

export default PriceOverrideLabel;
