import React from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import type { IconName } from '@fortawesome/fontawesome-common-types';
import { IonCardContent, IonCard } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import CardHeader from 'components/CardHeader/CardHeader';
import classes from './LinkedCard.module.scss';

interface LinkedCardProps {
  title: string;
  suffix?: string;
  testid: string;
  iconName?: IconName;
}

const LinkedCard = ({
  title,
  suffix,
  children,
  href,
  onClick,
  disabled,
  className,
  testid,
  iconName,
}: React.PropsWithChildren<LinkedCardProps> &
  IonicReactProps &
  React.ComponentProps<typeof IonCard>): JSX.Element => (
  <IonCard
    href={href}
    routerLink={href}
    disabled={disabled}
    onClick={onClick}
    className={classNames(classes.card, className, {
      [classes.disabled]: disabled,
    })}
    data-testid={testid}
  >
    <CardHeader
      title={title}
      className={classes.header}
      titleClassName={classes.title}
      suffixClassName={classes.suffix}
      iconClassName={classes.icon}
      suffix={suffix}
      testid={testid}
      iconName={iconName}
    />
    {!isEmpty(children) && <IonCardContent>{children}</IonCardContent>}
  </IonCard>
);

export default LinkedCard;
