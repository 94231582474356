import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SignatureCanvas from 'react-signature-canvas';
import type ReactSignatureCanvas from 'react-signature-canvas';
import classNames from 'classnames';
import { isEmpty, toString } from 'lodash';
import { IonCol, IonImg, IonRow } from '@ionic/react';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import type { SignatureDBItem } from 'models/InspectionReport';
import { DateFormatEnum, formatDate } from 'utils/date';
import Button from 'components/Button/Button';
import CheckBox from 'components/CheckBox/CheckBox';
import Input from 'components/Input/Input';
import SheetModal from 'components/Modals/SheetModal/SheetModal';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './DocumentSignature.module.scss';

export interface DocumentSignatureProps {
  inputId: number;
  createSignature: (name: string, image: string, inputId: number) => void;
  disabled?: boolean;
  reportSignature?: SignatureDBItem;
}

const DocumentSignature = ({
  inputId,
  createSignature,
  disabled = false,
  reportSignature,
}: DocumentSignatureProps): JSX.Element => {
  const sigCanvas = useRef<ReactSignatureCanvas>(null);
  const [showSignature, setShowSignature] = useState(false);
  const parentRef = useRef<HTMLDivElement>(null);

  // TODO  must something wrong with react signature.  Must delay it some time before showing otherwise can't
  // do signature
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSignature(true);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  const { isOnline } = useNetworkStatus();
  const { t } = useTranslation();
  const inputLabel = (value: string, required: string, needSync: boolean) => (
    <div className={classes.inputTitle}>
      <Text
        text={`${value}${required === 'Y' ? '*' : ''}`}
        variant="content-heavy"
      />
      {needSync && (
        <Text
          className={classes.needSyncBadge}
          variant="label-micro"
          text="Pending"
        />
      )}
    </div>
  );

  const [signatureURL, setSignatureURL] = useState(
    reportSignature?.image || undefined
  );
  const [name, setName] = useState<string>(reportSignature?.name || '');
  const [isOpenSignatureModal, setIsOpenSignatureModal] = useState(false);
  const [hasSignature, sethasSignature] = useState(false);
  const [acknowldegement, setAcknowledgement] = useState(false);

  const handleSignatureEnd = () => {
    if (sigCanvas && sigCanvas.current) {
      setSignatureURL(sigCanvas?.current?.toDataURL());
    }
    sethasSignature(true);
  };
  const saveDisabled =
    isEmpty(signatureURL) || isEmpty(name) || !hasSignature || !acknowldegement;

  const clear = () => {
    if (sigCanvas && sigCanvas.current) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      sigCanvas.current.clear();
    }
    setName('');
    sethasSignature(false);
    setAcknowledgement(false);
  };
  const saveSignature = () => {
    createSignature(name, toString(signatureURL), inputId);
    setIsOpenSignatureModal(false);
  };

  const disabledNoSignature = disabled && isEmpty(signatureURL);
  const disabledWithSignature = disabled && !isEmpty(signatureURL);
  const showSignatureInput = !disabled && showSignature;

  return (
    <div ref={parentRef} className={classes.inputRowWrap}>
      {isOnline && (
        <>
          <IonRow className={classes.sigPadContainer}>
            {disabled && (
              <IonCol size="12" className={classes.documentCol}>
                {disabledNoSignature &&
                  inputLabel('No Signature On File', 'N', false)}
                {disabledWithSignature && (
                  <IonImg
                    className={classes.signatureImage}
                    src={signatureURL}
                  />
                )}
              </IonCol>
            )}
            {showSignatureInput && (
              <>
                <SignatureCanvas
                  penColor="black"
                  canvasProps={{ className: classes.sigCanvas }}
                  ref={sigCanvas}
                  clearOnResize={false}
                  onEnd={handleSignatureEnd}
                />
              </>
            )}
          </IonRow>
          <IonRow
            className={classNames(classes.documentRow, classes.extraPadding)}
          >
            <IonCol size="12" className={classes.documentCol}>
              {inputLabel('Name', 'Y', false)}
            </IonCol>

            <IonCol
              size="12"
              className={classNames(
                classes.containerValue,
                classes.documentCol
              )}
            >
              <Input
                value={name}
                setValue={setName}
                disabled={disabled}
                testid="signature-name"
                name="name"
                parentRef={parentRef}
                disableAutoScroll={false}
              />
            </IonCol>
          </IonRow>
          {disabledWithSignature && (
            <>
              <IonRow
                className={classNames(
                  classes.documentRow,
                  classes.extraPadding
                )}
              >
                <IonCol size="12" className={classes.documentCol}>
                  {inputLabel(
                    `Signed ${formatDate(
                      toString(reportSignature?.creationTimestamp),
                      DateFormatEnum.DocumentCardDateAPI
                    )}`,
                    'N',
                    false
                  )}
                </IonCol>
              </IonRow>
              <IonRow
                className={classNames(
                  classes.documentRow,
                  classes.extraPadding
                )}
              >
                <IonCol size="12" className={classes.documentCol}>
                  <Text
                    className={classes.ackTest}
                    text="I hereby acknowledge the satisfactory completion of the Services outlined in this Scope of Work"
                    variant="content-heavy"
                  />
                </IonCol>
              </IonRow>
            </>
          )}
          {!disabled && (
            <IonRow>
              <IonCol size="12" className={classes.documentCol}>
                <CheckBox
                  ariaLabel="acknowledgement"
                  disabled={disabled}
                  onChange={(checked) => {
                    setAcknowledgement(Boolean(checked));
                  }}
                  name="acknowledgement"
                  checked={acknowldegement}
                  testid="checkbox-test"
                  label={t('documents:documentsAck')}
                  labelTextVariant="content-heavy"
                />
              </IonCol>
            </IonRow>
          )}
          <IonRow className={classes.buttonRow}>
            <IonCol size="auto">
              <Button
                className={classes.saveButton}
                variant="action"
                onClick={clear}
                testid="clearButton"
                text="Clear"
                disabled={disabled}
              />
            </IonCol>
            <IonCol size="auto">
              <Button
                className={classes.saveButton}
                variant="action"
                testid="Save Signature"
                text="Save"
                disabled={disabled || saveDisabled}
                onClick={() => {
                  setIsOpenSignatureModal(true);
                }}
              />
            </IonCol>
          </IonRow>
        </>
      )}
      {!isOnline && (
        <IonRow
          className={classNames(classes.documentRow, classes.extraPadding)}
        >
          <IonCol size="12" className={classes.documentCol}>
            <WarningMessage
              className={classes.warningMessage}
              title="Viewing/Adding Signature must be done online"
            />
          </IonCol>
        </IonRow>
      )}
      <SheetModal
        isOpen={isOpenSignatureModal}
        setIsOpen={setIsOpenSignatureModal}
        title="Saving Signature"
        titleTextVariant="mipro-h3-headline"
        initialBreakpoint={0.75}
        testid="signature-modal"
      >
        <IonRow
          className={classNames(
            classes.signatureContent,
            classes.documentRow,
            classes.extraPadding
          )}
        >
          <IonCol
            size="12"
            className={classNames(classes.containerValue, classes.documentCol)}
          >
            {inputLabel(
              'Saving the signature will complete the document and additional changes cannot be saved!!  Are you Sure?',
              'N',
              false
            )}
          </IonCol>
        </IonRow>
        <IonRow
          className={classNames(classes.documentRow, classes.extraPadding)}
        >
          <IonCol size="12" className={classes.documentCol}>
            <IonImg className={classes.signatureImage} src={signatureURL} />
          </IonCol>
        </IonRow>
        <IonRow
          className={classNames(classes.documentRow, classes.extraPadding)}
        >
          <IonCol size="12" className={classes.documentCol}>
            {inputLabel('Name', 'N', false)}
          </IonCol>
          <IonCol
            size="12"
            className={classNames(
              classes.containerValue,
              classes.documentCol,
              classes.uppercase
            )}
          >
            {inputLabel(name, 'N', false)}
          </IonCol>
        </IonRow>
        <Button
          className={classes.discardButton}
          variant="action"
          text="Save Signature"
          onClick={() => saveSignature()}
          testid="signature-primary-button"
        />
        <Button
          className={classes.discardButton}
          variant="secondary"
          text="Back to Signature"
          onClick={() => setIsOpenSignatureModal(false)}
          testid="signature-secondary-button"
        />
      </SheetModal>
    </div>
  );
};

export default DocumentSignature;
