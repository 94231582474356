import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { toString } from 'lodash';
import { IonImg, IonItem, IonRow } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { formatCardDate } from 'utils/date';
import noImage from 'assets/noImage.png';
import Text from 'components/Text/Text';
import classes from './ProductsCard.module.scss';

interface ProductsCardProps {
  text: string;
  aging?: number;
  description?: string;
  avatar?: string | null;
  testid: string;
  textClassName?: string;
  descriptionClassName?: string;
}

const ProductsCard = ({
  className,
  textClassName,
  descriptionClassName,
  text,
  aging,
  description,
  avatar,
  href,
  testid,
}: ProductsCardProps &
  Pick<React.ComponentProps<typeof IonItem>, 'href'> &
  IonicReactProps): JSX.Element => {
  const [image, setImage] = useState<string>(noImage);

  useEffect(() => {
    if (avatar) {
      setImage(`${toString(process.env.REACT_APP_IMAGES_URL)}${avatar}`);
    }
  }, [avatar]);

  return (
    <IonItem
      className={classNames(className, classes.card)}
      lines="none"
      href={href}
      detail={false}
      routerLink={href}
      data-testid={testid}
    >
      <IonRow className={classes.container}>
        <div className={classes.avatar}>
          <IonImg src={image} onIonError={() => setImage(noImage)} />
        </div>
        <div className={classes.text}>
          <Text
            variant="title-info-card"
            text={text}
            className={textClassName}
            testid={`${testid}-text`}
          />
          {description && (
            <Text
              className={classNames(classes.name, descriptionClassName)}
              variant="label-micro"
              text={description}
              testid={`${testid}-name`}
            />
          )}
        </div>
        {aging && (
          <Text
            className={classes.aging}
            variant="label-micro"
            text={formatCardDate(aging)}
            testid={`${testid}-aging`}
          />
        )}
      </IonRow>
    </IonItem>
  );
};

export default ProductsCard;
