import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { get, map, padStart, toNumber, toString } from 'lodash';
import useRateVisit from 'ActivitiesApp/api/useRateVisit';
import useRemoveAttachment from 'api/attachments/useRemoveAttachment';
import useUploadFileAttachment from 'api/attachments/useUploadFileAttachment';
import useAddNotebook from 'api/notebooks/useAddNotebook';
import useDeleteNotebook from 'api/notebooks/useDeleteNotebook';
import useGetNote from 'api/notebooks/useGetNote';
import useUpdateNotebook from 'api/notebooks/useUpdateNotebook';
import { RatingActivityEnum } from 'models/ActivityModels';
import type { RootState } from 'store/reducers';
import { goToShareCustomerNote } from 'navigation/navigationHelpers';
import AddEditNoteForm from './AddEditNoteForm';

export interface AddNoteURLParams {
  miLoc: string;
  id: string;
  noteType: string;
  noteId: string;
}

const AddEditNote = (): JSX.Element => {
  const { userInfo } = useSelector((state: RootState) => state.user);
  const userId = get(userInfo, 'userid', '');
  const { miLoc, id, noteType, noteId } = useParams<AddNoteURLParams>();

  const { customerData, notebook } = useGetNote({
    miLoc,
    id,
    noteType,
    noteId,
  });

  const {
    data: addData,
    status: addStatus,
    onAddNotebook,
  } = useAddNotebook({
    miLoc,
    id,
    ctlNo: `${miLoc}-${id}`,
  });

  const { status: updateStatus, onUpdateNotebook } = useUpdateNotebook({
    miLoc,
    id,
    noteId,
    ctlNo: `${miLoc}-${id}-${noteId}`,
  });

  const paddedItemLineNo = padStart(toString(notebook?.itemLineNo), 8, '0');

  const { status: deleteStatus, deleteNotebook } = useDeleteNotebook({
    miLoc,
    id,
    ctlNo:
      noteType === 'ALERT'
        ? `${miLoc}-${paddedItemLineNo}-${noteId}`
        : `${miLoc}-${id}-${noteId}`,
    nbType: noteType === 'ALERT' ? 'ALERT' : 'CM',
    historyId: toNumber(notebook?.historyId),
  });

  const { uploadAttachment } = useUploadFileAttachment({
    domain: noteType === 'ALERT' ? 'mprovisi' : 'customer',
    miLoc: noteType === 'ALERT' ? 'EXEC' : miLoc,
    ctlNo: noteType === 'ALERT' ? userId : id,
  });

  const { removeAttachment } = useRemoveAttachment({
    domain: noteType === 'ALERT' ? 'mprovisi' : 'customer',
    miLoc: noteType === 'ALERT' ? 'EXEC' : miLoc,
    ctlNo: noteType === 'ALERT' ? userId : id,
  });

  const { status: rateStatus, onRateVisit } = useRateVisit({
    historyId: toNumber(noteId),
    userId,
    miLoc,
    id,
    noteId,
  });

  const status = noteType === 'ALERT' ? rateStatus : addStatus;

  return (
    <AddEditNoteForm
      noteId={noteId}
      customerData={customerData}
      notebook={{
        title: toString(notebook?.title),
        text: notebook?.text,
        attachments: notebook?.attachments,
        userId: notebook?.updatedUserid,
        userFullName: notebook?.updatedUserName,
        updatedAt: notebook?.updatedTimestamp,
      }}
      updateStatus={noteType === 'ALERT' ? rateStatus : updateStatus}
      deleteStatus={deleteStatus}
      addStatus={status}
      onAddNotebook={(data) => {
        onAddNotebook({
          header: data.title,
          text: toString(data.text),
          filesForUpload: data.filesForUpload,
        });
      }}
      onUpdateNotebook={(data) => {
        const body = {
          header: data.title,
          text: toString(data.text),
        };
        try {
          map(data.filesForUpload, (file) => {
            uploadAttachment({ file, lineNo: noteId, src: file.fileURL });
          });
          map(data.filesForRemoval, (file) => {
            removeAttachment(file);
          });
        } catch (e) {
          // TODO: attachment errors
        }
        if (noteType === 'ALERT') {
          onRateVisit({
            // TODO: hardcoding rating value so API doesn't fail
            rating: RatingActivityEnum.positive,
            notebook: {
              title: data.title,
              text: toString(data.text),
            },
          });
        } else {
          onUpdateNotebook(body);
        }
      }}
      onDeleteNotebook={() => {
        deleteNotebook({
          delete: 'Y',
          header: notebook?.title || '',
          historyId: toNumber(notebook?.historyId),
        });
      }}
      newId={toString(addData?.itemLineNo)}
      buildNoteShareHref={(newId) =>
        goToShareCustomerNote({
          miLoc,
          id,
          noteType,
          noteId: newId || noteId,
          reset: true,
        })
      }
    />
  );
};

export default AddEditNote;
