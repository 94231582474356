import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { searchURL } from 'navigation';
import { findIcon } from 'utils/icons';
import { concatRoutes } from 'utils/navigations';
import { handleSearchNavigation } from 'utils/search';
import Button from 'components/Button/Button';
import Text from 'components/Text/Text';
import classes from './OwnerTitle.module.scss';

export interface OwnerTitleProps {
  className?: string;
  iconClassName?: string;
  nonLoggedInOwnerEmpId?: string;
  testid: string;
  withHref?: boolean;
  ownerName?: string;
  link?: boolean;
}

const OwnerTitle = ({
  className,
  nonLoggedInOwnerEmpId,
  iconClassName,
  ownerName,
  testid,
  withHref = true,
  link = false,
}: OwnerTitleProps): JSX.Element | null => {
  if (nonLoggedInOwnerEmpId) {
    if (link) {
      return (
        <div className={className} aria-hidden="true">
          <Button
            variant="mipro-owner-link"
            iconClassName={iconClassName}
            leftIcon={findIcon('user-alt')}
            href={
              withHref
                ? concatRoutes(
                    searchURL(),
                    handleSearchNavigation({
                      type: 'employee',
                      miLoc: '',
                      employeeId: nonLoggedInOwnerEmpId,
                    })
                  )
                : undefined
            }
            testid={`${testid}-btn`}
            text={ownerName || nonLoggedInOwnerEmpId}
          />
        </div>
      );
    }
    return (
      <div className={classNames(className, classes.title)}>
        <FontAwesomeIcon className={classes.icon} icon={findIcon('user-alt')} />
        <Text
          className={classes.text}
          variant="mipro-h6-headline"
          text={ownerName || nonLoggedInOwnerEmpId}
          testid={`${testid}-text`}
        />
      </div>
    );
  }
  return null;
};

export default OwnerTitle;
