import type { IconProp } from '@fortawesome/fontawesome-svg-core';

export enum IconColor {
  'yellow' = 'yellow',
  'green' = 'green',
  'red' = 'red',
  'white' = 'white',
  'grey' = 'grey',
  'darkgrey' = 'darkgrey',
  'color-upsell' = 'color-upsell',
  'color-largeOrder' = 'color-largeOrder',
  'color-largeQuote' = 'color-largeQuote',
  'color-crossSellSalesPlay' = 'color-crossSellSalesPlay',
  'color-webActivity' = 'color-webActivity',
  'color-customerVisit' = 'color-customerVisit',
  'color-reengagedCustomerAccount' = 'color-reengagedCustomerAccount',
  'color-newCustomerContact' = 'color-newCustomerContact',
  'color-overdueSalesPlay' = 'color-overdueSalesPlay',
  'color-priceOverride' = 'color-priceOverride',
  'color-atRiskCustomerAccount' = 'color-atRiskCustomerAccount',
  'color-lateOrder' = 'color-lateOrder',
  'color-decliningSalesPlay' = 'color-decliningSalesPlay',
  'color-sourcingOverride' = 'color-sourcingOverride',
  'color-priceOverrideApproved' = 'color-priceOverrideApproved',
  'color-sourcingOverrideAccepted' = 'color-sourcingOverrideAccepted',
  'color-sourcingOverrideRejected' = 'color-sourcingOverrideRejected',
  'color-snoozed' = 'color-snoozed',
  'color-snoozed-active' = 'color-snoozed-active',
  'color-delete-active' = 'color-delete-active',
}

export type LegendType = {
  icon: IconProp;
  color?: IconColor;
  className?: string;
  text: string;
};
