import { useMemo } from 'react';
import type { AxiosError } from 'axios';
import { size, toNumber, isEmpty } from 'lodash';
import { useIonViewWillEnter } from '@ionic/react';
import { useInfiniteQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import type {
  QueryFnProps,
  QueryParamsType,
} from 'common/api/utils/useGetQueryFlags';
import { getUnixTime } from 'date-fns';
import type { ShippingCustomer } from 'InventoryApp/models/StartProfile';
import { useAxios } from 'providers/AxiosProvider';
import {
  doConcatDataPages,
  doGetIsLoading,
  doPromiseAPI,
  useKeyUserId,
  useMiLocOrTeamId,
} from 'api/helpers';
import type { InfiniteQueryFlags } from 'models/Search';
import { pageSize } from 'utils/constants';

export const findShippingCustomersQueryKey = 'find-shipping-customers-list';

interface UseFindShippingCustomersProps {
  miLoc: string;
  customerNo: string;
  limit?: number;
}

interface FindShippingCustomersData {
  items: ShippingCustomer[];
  totals: number;
}

interface UseFindShippingCustomersAPIResponse {
  groups: ShippingCustomer[];
  totalRows: number;
}

interface UseFindShippingCustomersResponse {
  shippingCustomers?: ShippingCustomer[];
}

const useFindShippingCustomers = ({
  miLoc,
  limit = pageSize(),
  customerNo,
}: UseFindShippingCustomersProps): UseFindShippingCustomersResponse &
  InfiniteQueryFlags => {
  const { shippingCustomersAPI } = useAPIUrl();
  const { axios } = useAxios();
  const { createQueryKey } = useKeyUserId();
  const { getURLParams } = useMiLocOrTeamId({});
  const enabled = !isEmpty(miLoc) && !isEmpty(customerNo);
  const params: QueryParamsType = {
    limit,
    miLoc,
    customerNo,
  };

  const doGetFindShippingCustomersList = ({
    pageParam = 1,
    signal,
  }: QueryFnProps) => {
    return doPromiseAPI<FindShippingCustomersData>(async () => {
      const { data } = await axios.get<UseFindShippingCustomersAPIResponse>(
        shippingCustomersAPI(getURLParams({ ...params, page: pageParam })),
        { signal }
      );
      return {
        items: data.groups,
        totals: data.totalRows,
      };
    });
  };

  const response = useInfiniteQuery<FindShippingCustomersData, AxiosError>(
    createQueryKey(findShippingCustomersQueryKey, { ...params }),
    doGetFindShippingCustomersList,
    {
      enabled,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      retry: (failureCount: number, err: AxiosError<any>) =>
        err.response?.status === 403 ? false : !(failureCount < 3),
      getNextPageParam: (lastPage, pages) =>
        size(lastPage.items) < limit ? false : size(pages) + 1,
    }
  );

  const {
    data,
    error,
    status,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
    dataUpdatedAt,
  } = response;

  let lastUpdatedAt = new Date();
  if (toNumber(dataUpdatedAt) > 0) {
    lastUpdatedAt = new Date(dataUpdatedAt);
  }

  const shippingCustomers = useMemo(
    () =>
      doConcatDataPages<ShippingCustomer, FindShippingCustomersData>(
        data,
        'items'
      ),
    [data]
  );

  const hasItems = size(shippingCustomers) > 0;
  const hasError = status === 'error';
  const isEmptyResponse = status === 'success' && !hasNextPage && !hasItems;
  const noMoreData = status === 'success' && !hasNextPage && hasItems;
  const showLoader = doGetIsLoading(response) || isFetchingNextPage;
  const enableInfiniteScroll = !(!hasNextPage || isFetchingNextPage);

  useIonViewWillEnter(() => {
    if (enabled) {
      void refetch();
    }
  });

  return {
    error,
    showLoader,
    refetch: async () => {
      await refetch();
    },
    enableInfiniteScroll,
    noMoreData,
    hasError,
    isEmptyResponse,
    shippingCustomers,
    fetchNextPage: async () => {
      await fetchNextPage();
    },
    lastUpdatedAt: getUnixTime(lastUpdatedAt),
  };
};

export default useFindShippingCustomers;
