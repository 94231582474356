import {
  customerCostSavingsURL,
  searchCustomerURL,
  searchURL,
} from 'navigation';
import { concatRoutes } from 'utils/navigations';
import { handleSearchNavigation } from 'utils/search';
import { searchCorpCustomersURL, snapshotActionsURL } from './routerConfig';

export const goToCorpCustomerSearch = ({ miLoc = '', customerNo = '' }) => {
  return concatRoutes(
    concatRoutes(
      searchURL(),
      searchCustomerURL(miLoc, customerNo),
      snapshotActionsURL(),
      searchCorpCustomersURL()
    )
  );
};

export const goToCustomerSnapshot = ({ miLoc = '', customerId = '' }) => {
  return concatRoutes(
    searchURL(),
    handleSearchNavigation({
      type: 'customer',
      miLoc,
      customerId,
    })
  );
};

export const goToCustomerCostSavings = ({ miLoc = '', customerId = '' }) => {
  return concatRoutes(
    searchURL(),
    handleSearchNavigation({
      type: 'customer',
      miLoc,
      customerId,
    }),
    customerCostSavingsURL()
  );
};
