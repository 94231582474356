import SentryCategories from 'constants/sentryCategories';
import * as Sentry from '@sentry/capacitor';
import type { MutationStatus } from '@tanstack/react-query';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import type { UpdateMutationContext } from 'api/helpers';
import {
  doPromiseAPI,
  onErrorUpdate,
  onMutateUpdate,
  onSuccessMutation,
} from 'api/helpers';
import type { Announcement } from 'models/Announcement';
import {
  findAnnouncementsObjectKey,
  findAnnouncementsQueryKey,
} from './useFindAnnouncements';
import { getAnnouncementQueryKey } from './useGetAnnouncement';

interface UseMarkAnnouncementsAsReadResponse {
  status: MutationStatus;
  onMarkAsRead: () => void;
}

interface UseMarkAnnouncementsAsReadProps {
  url: string;
}

const useMarkAnnouncementsAsRead = ({
  url,
}: UseMarkAnnouncementsAsReadProps): UseMarkAnnouncementsAsReadResponse => {
  const { axios } = useAxios();
  const { markAnnouncementAsReadAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const doMarkAsRead = () => {
    return doPromiseAPI(async (cancelToken) => {
      return axios.post(markAnnouncementAsReadAPI(url), {}, { cancelToken });
    });
  };

  const { mutate, status } = useMutation(doMarkAsRead, {
    onMutate: async () => {
      return onMutateUpdate<Announcement>({
        queryClient,
        queryKey: findAnnouncementsQueryKey,
        dataPath: findAnnouncementsObjectKey,
        updatedItems: [{ read: 'Y', READ: 'Y' } as Announcement],
        findPredicates: [{ url }],
        isInfiniteQuery: true,
        markAsUpdated: true,
      });
    },
    onSuccess: () => {
      void onSuccessMutation(queryClient, findAnnouncementsQueryKey);
      void onSuccessMutation(queryClient, getAnnouncementQueryKey);
    },
    onError: (error, vars, context) => {
      Sentry.addBreadcrumb({
        category: SentryCategories.AXIOS,
        message: 'Annnouncements marked as read failed.',
        level: 'error',
        data: { url },
      });
      onErrorUpdate<Announcement>({
        queryClient,
        context: context as UpdateMutationContext<Announcement>[],
        dataPath: findAnnouncementsObjectKey,
        isInfiniteQuery: true,
      });
    },
  });

  return {
    status,
    onMarkAsRead: () => mutate(),
  };
};

export default useMarkAnnouncementsAsRead;
