import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { isEmpty, startCase } from 'lodash';
import type { IconName } from '@fortawesome/fontawesome-svg-core';
import { IonCol, IonRow } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { formatDistanceToNowStrict } from 'date-fns';
import { namespaces } from 'i18n/i18n.constants';
import useAccessControls, { AccessControlType } from 'hooks/useAccessControls';
import { IconColor } from 'models/Legend';
import type { SearchItemType } from 'models/Search';
import { SearchSuggestionTypeEnum } from 'models/Search';
import { getDateLocale, parseDate } from 'utils/date';
import { colorHash, getUserInitials, withStringProp } from 'utils/helpers';
import { findIcon } from 'utils/icons';
import ActionRow from 'components/ActionRow/ActionRow';
import CardAvatar from 'components/CardAvatar/CardAvatar';
import Text from 'components/Text/Text';
import classes from './ContactRow.module.scss';

type SearchSuggestionIconMap = Record<SearchSuggestionTypeEnum, IconName>;

interface ContactRowProps {
  searchSuggestionType: SearchSuggestionTypeEnum;
  text: string;
  description?: string;
  searchType: SearchItemType;
  miLoc: string;
  isMainContact?: string;
  isWebRegistered?: boolean;
  registrationStatus?: 'EXPIRING' | 'NONE' | 'SENT';
  reigsterOrInviteDate?: string;
  onLongPress?: () => void;
}

const ContactRow = ({
  className,
  searchSuggestionType,
  text,
  textQuery,
  description = '',
  onClick,
  href,
  testid,
  searchType,
  miLoc,
  isMainContact,
  isWebRegistered,
  reigsterOrInviteDate,
  registrationStatus,
  onLongPress,
}: ContactRowProps &
  React.ComponentProps<typeof ActionRow> &
  IonicReactProps): JSX.Element => {
  const ac = useAccessControls(AccessControlType.viewCustomers);
  const { t, i18n } = useTranslation(namespaces.contact);
  const showRegistered = searchType === 'customer' && isWebRegistered;
  const showInvitedLabel =
    searchType === 'customer' &&
    (registrationStatus === 'SENT' || registrationStatus === 'EXPIRING');
  const iconsMap: SearchSuggestionIconMap = {
    hint: 'history',
    search: 'search',
    result: 'chevron-right',
  };
  let inviteStatus: IconName | undefined;
  let inviteMsg = '';
  let activateTime = '';
  let inviteIconBackground;
  if (showRegistered) {
    inviteStatus = 'check';
    inviteMsg = t('registered');
    activateTime = reigsterOrInviteDate
      ? t('registeredMessage', {
          formatDate: formatDistanceToNowStrict(
            parseDate(reigsterOrInviteDate),
            {
              addSuffix: true,
              locale: getDateLocale(i18n.language),
            }
          ),
        })
      : '';
    inviteIconBackground = IconColor.green;
  } else if (showInvitedLabel) {
    inviteStatus = 'paper-plane';
    inviteMsg = t('inviteSent');
    activateTime = reigsterOrInviteDate
      ? formatDistanceToNowStrict(parseDate(reigsterOrInviteDate), {
          addSuffix: true,
          locale: getDateLocale(i18n.language),
        })
      : '';
    inviteIconBackground = IconColor.yellow;
  }
  const withDescription =
    searchSuggestionType === SearchSuggestionTypeEnum.result &&
    withStringProp(description);

  const disabled = !ac && searchType === 'customer';

  return (
    <ActionRow
      className={classNames(className, classes.item, {
        [classes.resultItem]: withDescription,
      })}
      icon={findIcon(iconsMap[searchSuggestionType])}
      href={href}
      onClick={onClick}
      testid={testid}
      withArrow={false}
      disabled={disabled}
      onLongPress={onLongPress}
    >
      <IonRow className={classes.container}>
        <div
          className={classes.avatar}
          style={{
            backgroundColor: colorHash.hex(startCase(text)),
          }}
        >
          <Text
            className={classes.avatarTitle}
            text={getUserInitials(text)}
            variant="title-screen-section"
          />
        </div>
        <div className={classes.contentWrapper}>
          <IonRow className={classes.wrapper}>
            <IonCol className={classNames(classes.col, classes.firstCol)}>
              <IonRow className="ion-align-items-baseline">
                {!isEmpty(text) && (
                  <Text
                    className={classes.text}
                    text={text}
                    textQuery={textQuery}
                    testid={`${testid}-text`}
                  />
                )}
                {searchType === 'supplier' && (
                  <Text
                    className={classes.label}
                    text={miLoc}
                    testid={`${testid}-label`}
                  />
                )}
              </IonRow>
            </IonCol>
            {(showRegistered || showInvitedLabel) && (
              <IonCol className={classNames(classes.col, classes.lastCol)}>
                <IonRow className={classes.rightRow}>
                  <CardAvatar
                    icon={inviteStatus}
                    testid="status-icon-registered"
                    color={IconColor.white}
                    backgroundColor={inviteIconBackground}
                    name="check"
                    className={classes.cardAvatarRegistered}
                  />
                  <Text
                    variant="label-micro"
                    className={classes.registered}
                    text={inviteMsg}
                  />
                </IonRow>
              </IonCol>
            )}
          </IonRow>
          <IonRow className={classes.wrapper}>
            <IonCol className={classNames(classes.col, classes.firstCol)}>
              <IonRow className="ion-justify-content-start ion-align-items-baseline">
                {withDescription && (
                  <Text
                    className={classes.text}
                    variant="content-small"
                    text={description}
                    testid={`${testid}-description`}
                  />
                )}
                {isMainContact === 'Y' && (
                  <Text
                    className={classes.mainContact}
                    variant="content-small"
                    text={t('snapshot:mainContact')}
                    testid="contact-main-id"
                  />
                )}
              </IonRow>
            </IonCol>
            {reigsterOrInviteDate && (
              <IonCol
                className={classNames(
                  classes.col,
                  classes.lastCol,
                  classes.lastSignOn
                )}
              >
                <Text
                  className={classes.name}
                  variant="label-micro-italic"
                  text={activateTime}
                  testid="card-title"
                />
              </IonCol>
            )}
          </IonRow>
        </div>
      </IonRow>
    </ActionRow>
  );
};

export default ContactRow;
