import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import type { AxiosError } from 'axios';
import type { Dictionary } from 'lodash';
import { toString, get, isEmpty, isNil } from 'lodash';
import { useIonViewDidEnter } from '@ionic/react';
import { useQuery } from '@tanstack/react-query';
import type { QueryKey } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { differenceInMinutes } from 'date-fns';
import { activitiesURL } from 'navigation';
import { useAxios } from 'providers/AxiosProvider';
import { doPromiseAPI, useKeyUserId } from 'api/helpers';
import useFeatureFlags, { FeatureFlagType } from 'hooks/useFeatureFlags';
import type {
  ActionCardActivity,
  ActivityTypeFilter,
} from 'models/ActivityModels';
import type { QueryFlags } from 'models/Search';
import type { RootState } from 'store/reducers';
import { parseDate } from 'utils/date';

export const findActivityFiltersQueryKey = 'activity-filters';

export const activitiesFilterPredicate = (
  activity: ActionCardActivity,
  key: QueryKey
): boolean => {
  let result = true;
  if (get(key, '1.isDone')) {
    result = result && activity.done === 'Y';
  }
  if (!get(key, '1.isDone')) {
    result = result && activity.done === 'N';
  }
  if (get(key, '1.isFollowUp')) {
    result = result && activity.followUp === 'Y';
  }
  if (!get(key, '1.showSnoozed')) {
    result = result && activity.isSnoozed === 'N';
  }
  if (get(key, '1.showAfter')) {
    result =
      result &&
      differenceInMinutes(
        parseDate(activity.showAfterISO),
        parseDate(activity.creationTimestampISO)
      ) > 0;
  }
  return result;
};

interface UseFindActivityFiltersProps {
  miLoc?: string;
  id?: string;
  includeOthers?: boolean;
}

interface UseFindActivityFiltersResponse {
  data?: ActivityTypeFilter[];
}

const useFindActivityFilters = ({
  miLoc,
  id,
  includeOthers = false,
}: UseFindActivityFiltersProps): UseFindActivityFiltersResponse &
  QueryFlags => {
  const { axios } = useAxios();
  const { findActivityFiltersAPI } = useAPIUrl();
  const { pathname } = useLocation();
  const { createQueryKey } = useKeyUserId();
  const showAllFilters = useFeatureFlags(FeatureFlagType.removeDateFilter);

  const { userInfo } = useSelector((state: RootState) => state.user);
  const userId = get(userInfo, 'userid', '');
  let loggedInUserId = '';
  const params: Dictionary<string> = {};

  if (miLoc) {
    params.miLoc = miLoc;
  }
  if (id) {
    params.customerNo = id;
  }
  if (!includeOthers) {
    loggedInUserId = userId;
  }
  if (loggedInUserId) {
    params.userId = loggedInUserId;
  }

  if (!showAllFilters) {
    params.numDaysToLimitByShowAfter =
      pathname === activitiesURL() ? '14' : '30';
  }

  const doFindActivityFilters = () => {
    return doPromiseAPI<ActivityTypeFilter[]>(async (cancelToken) => {
      const { data } = await axios.get<ActivityTypeFilter[]>(
        findActivityFiltersAPI(
          loggedInUserId,
          toString(new URLSearchParams({ ...params }))
        ),
        { cancelToken }
      );
      return data;
    });
  };

  const { data, error, status, isFetching, refetch } = useQuery<
    ActivityTypeFilter[],
    AxiosError
  >(
    createQueryKey(findActivityFiltersQueryKey, {
      ...params,
    }),
    doFindActivityFilters,
    { enabled: !isEmpty(userId) }
  );

  useIonViewDidEnter(() => {
    void refetch();
  });

  return {
    data,
    error,
    isLoading: status === 'loading' || (isFetching && isNil(data)),
    refetch: async () => {
      await refetch();
    },
  };
};

export default useFindActivityFilters;
