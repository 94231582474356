import type { MutationStatus } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToasts } from 'providers/ToastProvider';
import useReplenishmentDB from 'StoreroomsApp/database/useReplenishmentDB';
import type { ItemReplenishmentDTO } from 'StoreroomsApp/models/Replenishment';
import type { UpdateMutationContext } from 'api/helpers';
import {
  onSuccessMutation,
  doPromiseAPI,
  onErrorUpdate,
  onMutateUpdate,
} from 'api/helpers';
import { ToastType } from 'models/Toast';
import { findReplenishmentItemsQueryKey } from './useFindReplenishmentItems';

interface UpdateReplenishmentQuantityBody {
  itemId: number;
  quantity: number;
}

interface UseUpdateReplenishmentQuantityResponse {
  status: MutationStatus;
  onUpdateReplenishmentQuantity: (
    body: UpdateReplenishmentQuantityBody
  ) => void;
}

const useUpdateReplenishmentQuantity =
  (): UseUpdateReplenishmentQuantityResponse => {
    const queryClient = useQueryClient();
    const { addToast } = useToasts();
    const { updateItemReplenishmentQuantity } = useReplenishmentDB();

    const doUpdateReplenishmentQuantity = ({
      itemId,
      quantity,
    }: UpdateReplenishmentQuantityBody) => {
      return doPromiseAPI(async () => {
        await updateItemReplenishmentQuantity(itemId, quantity);
      });
    };

    const { status, mutate } = useMutation(doUpdateReplenishmentQuantity, {
      networkMode: 'always',
      onMutate: async (vars) =>
        onMutateUpdate<ItemReplenishmentDTO>({
          queryClient,
          queryKey: findReplenishmentItemsQueryKey,
          updatedItems: [
            {
              id: vars.itemId,
              orderQuantity: vars.quantity,
            } as ItemReplenishmentDTO,
          ],
          findPredicates: [{ id: vars.itemId }],
          isArrayQuery: true,
        }),
      onSuccess: async () => {
        await onSuccessMutation(queryClient, findReplenishmentItemsQueryKey);
      },
      onError: (error, vars, context) => {
        addToast({
          type: ToastType.error,
          text: 'There was an error updating quantity for item replenishment',
          testid: 'update-replenishment-quantity-error-toast',
        });
        onErrorUpdate<ItemReplenishmentDTO>({
          queryClient,
          context: context as UpdateMutationContext<ItemReplenishmentDTO>[],
          isArrayQuery: true,
        });
      },
    });

    return {
      status,
      onUpdateReplenishmentQuantity: (body: UpdateReplenishmentQuantityBody) =>
        mutate(body),
    };
  };

export default useUpdateReplenishmentQuantity;
