import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { find, map, size, toString } from 'lodash';
import { IonContent, IonPage } from '@ionic/react';
import { namespaces } from 'i18n/i18n.constants';
import useGetSupplierNotebooks from 'api/supplier/useGetSupplierNotebooks';
import type { SnapshotsURLParams } from 'models/Search';
import type { SupplierNotebook } from 'models/Supplier';
import { SupplierNotebookTypeEnum } from 'models/Supplier';
import { getErrorMessage } from 'utils/helpers';
import { getSnapshotEyebrow } from 'utils/search';
import Header from 'components/Header/Header';
import Loader from 'components/Loader/Loader';
import SegmentTabs from 'components/SegmentTabs/SegmentTabs';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './Notebooks.module.scss';

const Notebooks = (): JSX.Element => {
  const { id } = useParams<SnapshotsURLParams>();
  const [selectedTab, setSelectedTab] = useState<SupplierNotebookTypeEnum>(
    SupplierNotebookTypeEnum.SL
  );
  const { t } = useTranslation(namespaces.supplier);

  const options = [
    { key: SupplierNotebookTypeEnum.SL, text: t('common:supplier') },
    { key: SupplierNotebookTypeEnum.MF, text: t('common:manufacturer') },
    { key: SupplierNotebookTypeEnum.SC, text: t('snapshot:contacts') },
  ];
  const selectedTabName = toString(find(options, { key: selectedTab })?.text);

  const { notebooks, supplierData, error, isLoading } = useGetSupplierNotebooks(
    { nbType: selectedTab, id }
  );

  return (
    <IonPage className={classes.Notebooks} data-testid="Notebooks-page">
      <Header
        withBackButton
        eyebrow={getSnapshotEyebrow({ name: supplierData?.supLocName, id })}
        title={t('snapshot:notebooks')}
        testid="notebook-header"
      >
        <SegmentTabs
          className={classes.notebookTabs}
          options={options}
          value={selectedTab}
          setValue={(v: string) =>
            setSelectedTab(v as SupplierNotebookTypeEnum)
          }
          testid="notebook-tabs"
        />
      </Header>
      <IonContent>
        <Loader
          className={classes.loader}
          text={t('loadingNotebooks', { selectedTabName })}
          isOpen={isLoading}
        />
        {!isLoading && !error && (
          <>
            <div className={classes.textWrapper}>
              {map(notebooks, (notebook: SupplierNotebook) => (
                <Text key={notebook.lastUpdTmstmp} text={notebook.lineText} />
              ))}
            </div>
            {size(notebooks) === 0 && (
              <WarningMessage
                className={classes.warningMessage}
                icon={['far', 'info-circle']}
                title={t('noResultsNotebooks', { selectedTabName })}
              />
            )}
          </>
        )}
        {error && (
          <WarningMessage
            className={classes.warningMessage}
            title={t('errorNotebooks', { selectedTabName })}
            body={getErrorMessage(error)}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default Notebooks;
