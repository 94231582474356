import React, { useState } from 'react';
import type { Column } from 'react-table';
import classNames from 'classnames';
import { map, toString } from 'lodash';
import type { GroupInput } from 'models/DocumentTemplate';
import type { ReportValue } from 'models/InspectionReport';
import DocumentInput from 'components/Documents/DocumentInput/DocumentInput';
import Input from 'components/Input/Input';
import Text from 'components/Text/Text';
import classes from './DocumentTable.module.scss';

interface TableProps {
  columnLayout?: GroupInput[];
  disabled?: boolean;
  reportId: number;
  groupId: number;
  inputId: number;
  needSync?: boolean;
  custNo: string;
  miLoc: string;
  setValue: (v: string, name: string, row: number, isComboBox: boolean) => void;
  onFormUpdate: (formData: Record<number, ReportValue>) => void;
  tableName: string;
}

interface TableCellProps extends TableProps {
  value: unknown;
  row: number;
  ndx: number;
}

const TableCell = ({
  value,
  row,
  ndx,
  columnLayout,
  disabled = false,
  reportId,
  inputId,
  groupId,
  needSync,
  custNo,
  miLoc,
  setValue,
  onFormUpdate,
  tableName,
}: TableCellProps) => {
  const [inputValue, setInputValue] = useState(
    Array.isArray(value) ? '' : toString(value)
  );

  const inputName = `${tableName}-${groupId}-${inputId}-${row}-${ndx}`;

  if (columnLayout && typeof columnLayout[ndx] !== 'undefined') {
    if (columnLayout[ndx].type === 'label') {
      return <Text text={toString(value)} variant="content-default" />;
    }
    return (
      <DocumentInput
        key={ndx}
        setRecValue={() => {}}
        inputId={inputId}
        groupId={groupId}
        disabled={disabled}
        reportId={reportId}
        groupInput={columnLayout[ndx]}
        onFormUpdate={onFormUpdate}
        setHasData={() => {}}
        value={inputValue}
        rowData={ndx}
        values={Array.isArray(value) ? value : []}
        inputName={inputName}
        onBlur={() => {
          setValue(
            inputValue,
            columnLayout[ndx].name,
            row,
            columnLayout[ndx].inputTypeId === 1
          );
        }}
        setValue={(v: string) => {
          setInputValue(v);
          if (columnLayout[ndx].inputTypeId === 1) {
            setValue(
              v,
              columnLayout[ndx].name,
              row,
              columnLayout[ndx].inputTypeId === 1
            );
          }
        }}
        custNo={custNo}
        miLoc={miLoc}
        needSync={needSync}
        createSignature={() => {}}
      />
    );
  }
  // @TODO add error  bad configurations
  return <Input testid="fdg" disabled={disabled} />;
};

interface DocumentTableProps extends TableProps {
  columns?: Column<Record<string, unknown>>[];
  data?: Record<string, unknown>[];
  className?: string;
}

const DocumentTable = ({
  columns,
  data,
  className,
  ...props
}: DocumentTableProps): JSX.Element => {
  return (
    <div data-testid="table" className={classes.documentTable}>
      <table className={classNames(className, classes.mainTable)}>
        <thead>
          <tr>
            {map(columns, ({ Header }) => (
              <th key={toString(Header)}>{toString(Header)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {map(data, (row, rowIndex) => (
            <tr key={rowIndex}>
              {map(columns, ({ Header }, colIndex) => (
                <th key={`${rowIndex}${toString(Header)}`}>
                  <TableCell
                    value={row[toString(Header)]}
                    row={rowIndex}
                    ndx={colIndex}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                  />
                </th>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DocumentTable;
