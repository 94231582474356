/* eslint-disable no-console */
import { map, size } from 'lodash';
import type { ScannerUserProfile } from 'StoreroomsApp/models/ScannerUserProfile';
import useScannerDB from './initScannerDB';

interface UseScannerProfileDBResponse {
  createScanneProfiles: (profiles: ScannerUserProfile[]) => Promise<void>;
  findScannerProfilesByMiLoc: (miLoc: string) => Promise<ScannerUserProfile[]>;
  removeScannerProfiles: (miLoc: string) => Promise<void>;
}

const useScannerProfileDB = (): UseScannerProfileDBResponse => {
  const { db, openDB, closeDB } = useScannerDB();

  const createScanneProfiles = async (
    profiles: ScannerUserProfile[]
  ): Promise<void> => {
    try {
      const statements = map(profiles, (item) => ({
        statement: `INSERT OR REPLACE INTO scanner_user_profile (
          id,
          userID,
          miLocation,
          customerNumber,
          storeroomNumber,
          securityResource1,
          securityResource2,
          securityResource3,
          securityResource4,
          securityResource5
          ) VALUES (?,?,?,?,?,?,?,?,?,?)`,
        values: [
          item.id,
          item.userID,
          item.miLocation,
          item.customerNumber,
          item.storeroomNumber,
          item.securityResource1,
          item.securityResource2,
          item.securityResource3,
          item.securityResource4,
          item.securityResource5,
        ],
      }));
      await openDB();
      if (size(statements) > 0) {
        await db.executeSet(statements);
      }
    } catch (error) {
      console.log('Error saving scanner profiles to database', error);
      throw new Error('Error saving scanner profiles to database');
    } finally {
      await closeDB();
    }
  };

  const findScannerProfilesByMiLoc = async (
    miLoc: string
  ): Promise<ScannerUserProfile[]> => {
    try {
      await openDB();
      return (
        await db.query(
          `SELECT * FROM scanner_user_profile
          WHERE miLocation = ?`,
          [miLoc]
        )
      ).values as ScannerUserProfile[];
    } catch (error) {
      console.log('Error loading scanner profiles', error);
      throw new Error('Error loading scanner profiles');
    } finally {
      await closeDB();
    }
  };

  const removeScannerProfiles = async (miLoc: string): Promise<void> => {
    try {
      await openDB();
      await db.query(
        `DELETE FROM scanner_user_profile
        WHERE miLocation = ?`,
        [miLoc]
      );
    } catch (error) {
      console.log('Error removing scanner profiles', error);
      throw new Error('Error removing scanner profiles');
    } finally {
      await closeDB();
    }
  };

  return {
    createScanneProfiles,
    findScannerProfilesByMiLoc,
    removeScannerProfiles,
  };
};

export default useScannerProfileDB;
