import type React from 'react';
import {
  productSearchDetailURL,
  orderCartURL,
  orderLineURL,
  productSearchURL,
  orderListURL,
  orderCartReviewURL,
  orderNotebookURL,
  emptyCartURL,
  viewPriceHistoryURL,
  transmissionLogURL,
  addZCodedItemURL,
  searchCustomerOCN,
  shippingLineURL,
} from 'navigation';
import AddZCodedItem from 'ProductSearchApp/pages/AddZCodedItem/AddZCodedItem';
import CustomerOrders from 'ProductSearchApp/pages/CustomerOrders/CustomerOrders';
import EditOrderLine from 'ProductSearchApp/pages/EditOrderLine/EditOrderLine';
import LineShippingDetails from 'ProductSearchApp/pages/LineShippingDetails/LineShippingDetails';
import OcnSearch from 'ProductSearchApp/pages/OcnSearch/OcnSearch';
import OrderCart from 'ProductSearchApp/pages/OrderCart/OrderCart';
import OrderNotebook from 'ProductSearchApp/pages/OrderNotebook/OrderNotebook';
import PriceHistory from 'ProductSearchApp/pages/PriceHistory/PriceHistory';
import ProductSearch from 'ProductSearchApp/pages/ProductSearch';
import ProductSearchDetail from 'ProductSearchApp/pages/ProductSearchDetail';
import ReviewOrder from 'ProductSearchApp/pages/ReviewOrder/ReviewOrder';
import TransmissionLog from 'ProductSearchApp/pages/TransmissionLog/TransmissionLog';
import { AccessControlType } from 'hooks/useAccessControls';
import type { SnapshotActionRoute, TabRoutes } from 'models/Navigation';

const ProductSearchRouter: SnapshotActionRoute[] = [
  {
    path: productSearchURL(),
    text: 'Product Search',
    translate: 'productSearch:title',
    action: 'product-search',
    routeName: 'Product Search',
    hideInSnapshot: true,
    routes: [
      { path: '', component: ProductSearch, routeName: 'Product Search' },
      {
        path: productSearchDetailURL(),
        routeName: 'Product Search Detail',
        routes: [
          {
            path: '',
            component: ProductSearchDetail,
            routeName: 'Product Search Detail',
          },
          {
            path: viewPriceHistoryURL(),
            component: PriceHistory,
            routeName: 'View Price History',
          },
        ],
      },
    ],
  },
];

const OcnNotebookRouter: TabRoutes[] = [
  {
    path: orderNotebookURL('add'),
    component: OrderNotebook as React.FC,
    props: { mode: 'add' },
    routeName: 'Add OCN Notebook',
  },
  {
    path: orderNotebookURL('edit'),
    component: OrderNotebook as React.FC,
    props: { mode: 'edit' },
    routeName: 'Edit OCN Notebook',
  },
  {
    path: orderNotebookURL('view'),
    component: OrderNotebook as React.FC,
    props: { mode: 'view' },
    routeName: 'View OCN Notebook',
  },
];

const OcnTransmissionLogRouter: TabRoutes[] = [
  {
    path: transmissionLogURL(),
    component: TransmissionLog,
    routeName: 'OCN Transmission Log',
  },
];

const OcnShippingLineRouter: TabRoutes[] = [
  {
    path: shippingLineURL(),
    component: LineShippingDetails,
    routeName: 'OCN Line Shipping Details',
  },
];

const OcnRouter: SnapshotActionRoute[] = [
  {
    path: orderListURL(),
    text: 'Order List',
    translate: 'productSearch:orderList:quotesTitle',
    action: 'order-list',
    routeName: 'Order List',
    hideInSnapshot: true,
    accessControl: AccessControlType.ViewOrdersAccessControls,
    routes: [{ path: '', component: CustomerOrders, routeName: 'OrderList' }],
  },
  {
    path: addZCodedItemURL(),
    text: 'Add Z-Coded Item',
    translate: 'productSearch:zCodedItem:addZCodedItem',
    action: 'add-z-coded-item',
    routeName: 'Add Z-Coded Item',
    hideInSnapshot: true,
    component: AddZCodedItem,
  },
  {
    path: searchCustomerOCN(),
    text: 'Search Customer OCN',
    translate: 'productSearch:ocn:ocnSearchTitle',
    action: 'ocn-search',
    routeName: 'OCN Search',
    hideInSnapshot: true,
    component: OcnSearch,
    accessControl: AccessControlType.ViewOrdersAccessControls,
  },
  {
    path: orderCartURL(),
    text: 'OCN Cart',
    translate: 'productSearch:ocn:ocnTitle',
    action: 'ocn-cart',
    routeName: 'OCN Cart',
    hideInSnapshot: true,
    accessControl: AccessControlType.ViewOrdersAccessControls,
    routes: [
      {
        path: '',
        component: OrderCart,
        routeName: 'OCN Cart',
        accessControl: AccessControlType.EditOrdersAccessControls,
      },
      {
        path: orderLineURL(),
        routeName: 'OCN Line',
        accessControl: AccessControlType.EditOrdersAccessControls,
        routes: [
          { path: '', component: EditOrderLine, routeName: 'Edit OCN Line' },
          {
            path: orderNotebookURL('add'),
            component: OrderNotebook as React.FC,
            props: { mode: 'add' },
            routeName: 'Add OCN Line Notebook',
          },
          {
            path: orderNotebookURL('edit'),
            component: OrderNotebook as React.FC,
            props: { mode: 'edit' },
            routeName: 'Edit OCN Line Notebook',
          },
          {
            path: orderNotebookURL('view'),
            component: OrderNotebook as React.FC,
            props: { mode: 'view' },
            routeName: 'View OCN Line Notebook',
          },
        ],
      },
      {
        path: orderCartReviewURL('closed', 'review'),
        routeName: 'Review Closed OCN',
        routes: [
          {
            path: '',
            component: ReviewOrder as React.FC,
            props: { ocnType: 'closed', ocnMode: 'review' },
            routeName: 'Review Closed OCN',
          },
          ...OcnShippingLineRouter,
          ...OcnTransmissionLogRouter,
          ...OcnNotebookRouter,
        ],
      },
      {
        path: orderCartReviewURL('unfinished', 'review'),
        routeName: 'Review Unfinished OCN',
        routes: [
          {
            path: '',
            component: ReviewOrder as React.FC,
            props: { ocnType: 'unfinished', ocnMode: 'review' },
            routeName: 'Review Unfinished OCN',
          },
          ...OcnShippingLineRouter,
          ...OcnTransmissionLogRouter,
          ...OcnNotebookRouter,
        ],
      },
      {
        path: orderCartReviewURL('unfinished', 'checkout'),
        routeName: 'Checkout Unfinished OCN',
        accessControl: AccessControlType.EditOrdersAccessControls,
        routes: [
          {
            path: '',
            component: ReviewOrder as React.FC,
            props: { ocnType: 'unfinished', ocnMode: 'checkout' },
            routeName: 'Checkout Unfinished OCN',
          },
          ...OcnNotebookRouter,
        ],
      },
      {
        path: orderCartReviewURL('quote', 'review'),
        routeName: 'Review Quote OCN',
        routes: [
          {
            path: '',
            component: ReviewOrder as React.FC,
            props: { ocnType: 'quote', ocnMode: 'review' },
            routeName: 'Review Quote OCN',
          },
          ...OcnShippingLineRouter,
          ...OcnTransmissionLogRouter,
          ...OcnNotebookRouter,
        ],
      },
      {
        path: orderCartReviewURL('quote', 'checkout'),
        routeName: 'Checkout Quote OCN',
        accessControl: AccessControlType.EditOrdersAccessControls,
        routes: [
          {
            path: '',
            component: ReviewOrder as React.FC,
            props: { ocnType: 'quote', ocnMode: 'checkout' },
            routeName: 'Checkout Quote OCN',
          },
          ...OcnNotebookRouter,
        ],
      },
      {
        path: orderCartReviewURL('order', 'review'),
        routeName: 'Review Order OCN',
        routes: [
          {
            path: '',
            component: ReviewOrder as React.FC,
            props: { ocnType: 'order', ocnMode: 'review' },
            routeName: 'Review Order OCN',
          },
          ...OcnShippingLineRouter,
          ...OcnTransmissionLogRouter,
          ...OcnNotebookRouter,
        ],
      },
      {
        path: orderCartReviewURL('order', 'checkout'),
        routeName: 'Checkout Order OCN',
        accessControl: AccessControlType.EditOrdersAccessControls,
        routes: [
          {
            path: '',
            component: ReviewOrder as React.FC,
            props: { ocnType: 'order', ocnMode: 'checkout' },
            routeName: 'Checkout Order OCN',
          },
          ...OcnNotebookRouter,
        ],
      },
    ],
  },
];

export const ProductSearchAppRouter: SnapshotActionRoute[] = [
  ...ProductSearchRouter,
  ...OcnRouter,
];

export const EmptyCartRouter = [
  {
    path: emptyCartURL(),
    component: OrderCart,
    routeName: 'Empty Cart',
  },
];
