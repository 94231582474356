import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'components/Modal/Modal';
import Text from 'components/Text/Text';
import classes from './NotificationsSettings.module.scss';

const ScheduleInfoModal = ({
  isOpen,
  setIsOpen,
}: Omit<React.ComponentProps<typeof Modal>, 'title'>): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Modal
      modalClassName={classes.modal}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      testid="notification-settings-schedule-info-modal"
    >
      <Text
        className={classes.title}
        variant="title-info-card"
        text={t('notificationSettings:scheduling')}
        testid="notification-settings-modal-scheduling"
      />
      <Text
        className={classes.description}
        variant="content-smaller"
        text={t('notificationSettings:schedulingDescription')}
        testid="notification-settings-modal-scheduling-description"
      />
      <Text
        className={classes.subtitle}
        variant="title-info-card"
        text={t('notificationSettings:always')}
        testid="notification-settings-modal-always"
      />
      <Text
        className={classes.description}
        variant="content-smaller"
        text={t('notificationSettings:alwaysDescription')}
        testid="notification-settings-modal-always-description"
      />
      <Text
        className={classes.subtitle}
        variant="title-info-card"
        text={t('notificationSettings:weekdays')}
        testid="notification-settings-modal-weekdays"
      />
      <Text
        className={classes.description}
        variant="content-smaller"
        text={t('notificationSettings:weekdaysDescription')}
        testid="notification-settings-modal-weekdays-description"
      />
      <Text
        className={classes.note}
        variant="label-micro-italic"
        text={t('notificationSettings:note')}
        testid="notification-settings-note"
      />
    </Modal>
  );
};

export default ScheduleInfoModal;
