import { find, some } from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from 'store/reducers';

export const selectIsBranchLocation = createSelector(
  (state: RootState) => state.user.miLoc,
  (state: RootState) => state.user.locationTree,
  (miLoc, locationTree) => {
    if (locationTree && miLoc) {
      return locationTree[miLoc]?.userRole === 'BRCH';
    }
    return false;
  }
);

export const selectIsTeamLocation = createSelector(
  (state: RootState) => state.user.miLoc,
  (state: RootState) => state.user.locationTree,
  (miLoc, locationTree) => {
    if (locationTree && miLoc) {
      const foundLocation = find(locationTree, { miLoc });
      return (
        foundLocation?.locationType === 'T' ||
        foundLocation?.locationType === 'VT'
      );
    }
    return false;
  }
);

export const selectIsMiLocCorpOrExec = createSelector(
  (state: RootState) => state.user.miLoc,
  (state: RootState) => state.user.locationTree,
  (miLoc, locationTree) => {
    if (locationTree && miLoc) {
      return (
        locationTree[miLoc]?.userRole === 'CORP' ||
        locationTree[miLoc]?.userRole === 'EXEC'
      );
    }
    return false;
  }
);

export const hasVirtualTeam = createSelector(
  (state: RootState) => state.user.locationTree,
  (locationTree) => some(locationTree, (loc) => loc.locationType === 'VT')
);

export default selectIsBranchLocation;
