import React from 'react';
import type ColorVariant from 'constants/colorVariants';
import classNames from 'classnames';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import classes from './ActionCardDecoration.module.scss';

interface ActionCardDecorationProps {
  variant?: ColorVariant;
  icon?: IconProp;
}

const ActionCardDecoration = ({
  variant = 'primary',
  icon = 'star',
  className,
}: ActionCardDecorationProps & IonicReactProps): JSX.Element => {
  return (
    <div
      className={classNames(className, classes.action_card_decoration__wrapper)}
    >
      <div
        className={classNames(classes.action_card_decoration__triangle, {
          [classes.triangle_success]: variant === 'success',
          [classes.triangle_primary]: variant === 'primary',
          [classes.triangle_danger]: variant === 'danger',
          [classes.triangle_secondary]: variant === 'secondary',
        })}
      />
      {icon && (
        <FontAwesomeIcon
          icon={icon}
          className={classes.action_card_decoration__icon}
        />
      )}
    </div>
  );
};

export default ActionCardDecoration;
