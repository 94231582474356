import type { AxiosError } from 'axios';
import { isEmpty, toNumber } from 'lodash';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import useTemplateDB from 'DocumentsApp/database/useTemplate';
import { useAxios } from 'providers/AxiosProvider';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import {
  doGetIsLoading,
  doPromiseAPI,
  getPlaceholderData,
  useKeyUserId,
} from 'api/helpers';
import type { DocumentTemplate } from 'models/DocumentTemplate';
import type { QueryFlags } from 'models/Search';
import { findTemplatesQueryKey } from './useFindTemplates';

export const getTemplateQueryKey = 'template-document';

interface UseGetTemplateProps {
  templateId: string;
}

interface UseGetTemplateResponse {
  data?: DocumentTemplate;
}

const useGetTemplate = ({
  templateId,
}: UseGetTemplateProps): UseGetTemplateResponse & QueryFlags => {
  const { axios } = useAxios();
  const { getTemplateAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { createQueryKey } = useKeyUserId();
  const { getTemplateById, createTemplates } = useTemplateDB();
  const { isOnline } = useNetworkStatus();

  const doGetTemplate = () => {
    return doPromiseAPI<DocumentTemplate>(async () => {
      const offlineTemplate = await getTemplateById(templateId);
      if (isOnline) {
        const { data } = await axios.get<DocumentTemplate>(
          getTemplateAPI(templateId)
        );
        await createTemplates([{ ...offlineTemplate, ...data }]);
        return data;
      }
      return {
        ...offlineTemplate,
        ...JSON.parse(offlineTemplate?.templateJson || '{}'),
      } as DocumentTemplate;
    });
  };

  const response = useQuery<DocumentTemplate, AxiosError>(
    createQueryKey(getTemplateQueryKey, { templateId, isOnline }),
    doGetTemplate,
    {
      enabled: !isEmpty(templateId),
      networkMode: 'always',
      placeholderData: () =>
        getPlaceholderData<DocumentTemplate>({
          queryClient,
          queryKey: findTemplatesQueryKey,
          findPredicate: { templateId: toNumber(templateId) },
        }),
    }
  );

  const { data, error } = response;

  return {
    data,
    error,
    isLoading: doGetIsLoading(response),
  };
};

export default useGetTemplate;
