import React, {
  useEffect,
  useState,
  createContext,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import type { SQLiteDBConnection } from 'react-sqlite-hook';
import { useLocation } from 'react-use';
import SentryCategories from 'constants/sentryCategories';
import { get, includes, isEmpty, isNil, toString } from 'lodash';
import { App } from '@capacitor/app';
import type { AppState, AppInfo } from '@capacitor/app';
import type { PluginListenerHandle } from '@capacitor/core';
import { Capacitor } from '@capacitor/core';
import type { DeviceInfo, GetLanguageCodeResult } from '@capacitor/device';
import { Device } from '@capacitor/device';
import type { DeviceId } from '@capacitor/device/dist/esm/definitions';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { NativeAudio } from '@capacitor-community/native-audio';
import { CapacitorSQLite, SQLiteConnection } from '@capacitor-community/sqlite';
import * as Sentry from '@sentry/capacitor';
import { Deploy } from 'cordova-plugin-ionic';
import type { ISnapshotInfo } from 'cordova-plugin-ionic/www/IonicCordova';
import { territoriesURL } from 'navigation';
import type { RootState } from 'store/reducers';

export const SCANNER_DB_NAME = 'motionscanner';
export const DOCUMENTS_DB_NAME = 'motiondocuments';
export const INVENTORY_DB_NAME = 'motionvmi';

export interface DeviceContextProps {
  deviceId?: DeviceId;
  deviceData?: DeviceInfo;
  deviceLanguage?: GetLanguageCodeResult;
  appData?: AppInfo;
  deployData?: ISnapshotInfo;
  lastVisitedURL: string;
  appVersion: string;
  showDevTools: boolean;
  scannerDB: SQLiteDBConnection;
  documentsDB: SQLiteDBConnection;
  inventoryDB: SQLiteDBConnection;
  setShowDevTools: (b: boolean) => void;
}

const DeviceContext = createContext<DeviceContextProps>({
  deviceId: undefined,
  deviceData: undefined,
  deviceLanguage: undefined,
  appData: undefined,
  deployData: undefined,
  lastVisitedURL: '',
  appVersion: '',
  showDevTools: false,
  scannerDB: {} as SQLiteDBConnection,
  documentsDB: {} as SQLiteDBConnection,
  inventoryDB: {} as SQLiteDBConnection,
  setShowDevTools: () => {},
});

const DeviceProvider = ({
  children,
}: React.PropsWithChildren<unknown>): JSX.Element => {
  const [deviceId, setDeviceId] = useState<DeviceId>();
  const [deviceData, setDeviceData] = useState<DeviceInfo>();
  const [deviceLanguage, setDeviceLanguage] = useState<GetLanguageCodeResult>();
  const [appData, setAppData] = useState<AppInfo>();
  const [deployData, setDeployData] = useState<ISnapshotInfo>();
  const [lastVisitedURL, setLastVisitedURL] = useState<string>('');
  const location = useLocation();
  const { userInfo } = useSelector((state: RootState) => state.user);
  const userId = get(userInfo, 'userid', '');
  const jobCode = get(userInfo, 'jobCode', '');
  const isWeb = Capacitor.getPlatform() === 'web';

  useEffect(() => {
    let path = 'scanning.wav';
    if (Capacitor.getPlatform() === 'ios') {
      path = `sounds/${path}`;
    }
    void NativeAudio.configure({ fade: false, focus: false })?.catch(() => {
      // DOC do nothing with configure error
    });
    void NativeAudio.preload({
      assetId: 'scanning-audio',
      assetPath: path,
      audioChannelNum: 1,
      isUrl: false,
    })?.catch(() => {
      // DOC do nothing with preload error
    });
    return () => {
      void NativeAudio.unload({ assetId: 'scanning-audio' })?.catch(() => {
        // DOC do nothing with unload error
      });
    };
  }, []);

  useEffect(() => {
    let sentryListener: PluginListenerHandle;
    const doAddSentryListener = async () => {
      // add breadcrumbs to sentry when application enters foreground / background
      sentryListener = await App.addListener(
        'appStateChange',
        (state: AppState) => {
          Sentry.addBreadcrumb({
            category: SentryCategories.APP_STATE,
            message: `Application has changed state: ${
              state.isActive ? 'active' : 'inactive'
            }`,
            level: 'debug',
          });
        }
      );
    };
    void doAddSentryListener();

    return () => {
      void sentryListener?.remove();
    };
  }, []);

  const appVersion = useMemo(() => {
    const buildType = toString(process.env.REACT_APP_ENV_BUILD);
    let output = '';
    output += appData?.version ? `v${appData.version}` : '';
    output += !isNil(deployData) ? ` (${deployData.buildId})` : '';
    output = !isEmpty(output) ? `${output}\n` : '';
    output += buildType !== 'prod' ? `${buildType}` : '';

    return output;
  }, [appData?.version, deployData]);

  const [showDevTools, setShowDevTools] = useState<boolean>(false);

  // #region localdb
  const sqliteConnection: SQLiteConnection = useMemo(
    () => new SQLiteConnection(CapacitorSQLite),
    []
  );
  const [scannerDB, setScannerDB] = useState<SQLiteDBConnection>(
    {} as SQLiteDBConnection
  );
  const [documentsDB, setDocumentsDB] = useState<SQLiteDBConnection>(
    {} as SQLiteDBConnection
  );
  const [inventoryDB, setInventoryDB] = useState<SQLiteDBConnection>(
    {} as SQLiteDBConnection
  );

  const initDB = useCallback(
    async (
      dbName: string,
      setDB: (v: SQLiteDBConnection) => void
    ): Promise<void> => {
      try {
        const consistencyResult =
          await sqliteConnection?.checkConnectionsConsistency();
        const connectionResult = await sqliteConnection?.isConnection(
          dbName,
          false
        );
        if (consistencyResult?.result && connectionResult?.result) {
          setDB(await sqliteConnection.retrieveConnection(dbName, false));
        } else {
          setDB(
            await sqliteConnection.createConnection(
              dbName,
              false,
              'no-encryption',
              1,
              false
            )
          );
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.debug('error initializing DB', e);
        setDB(
          await sqliteConnection.createConnection(
            dbName,
            false,
            'no-encryption',
            1,
            false
          )
        );
      }
    },
    [sqliteConnection]
  );

  useEffect(() => {
    void initDB(SCANNER_DB_NAME, setScannerDB);
    void initDB(DOCUMENTS_DB_NAME, setDocumentsDB);
    void initDB(INVENTORY_DB_NAME, setInventoryDB);
  }, [initDB]);
  // #endregion

  useEffect(() => {
    const firebaseInit = async () => {
      if (!isWeb) {
        await FirebaseAnalytics.setCollectionEnabled({ enabled: true });
      }
      if (!isWeb && userId.length) {
        await FirebaseAnalytics.setUserId({
          userId,
        });
      }
      if (!isWeb && jobCode.length) {
        await FirebaseAnalytics.setUserProperty({
          name: 'job_code',
          value: jobCode,
        });
      }
    };
    void firebaseInit();
  }, [jobCode, userId, isWeb]);

  useEffect(() => {
    if (!includes(location?.pathname, territoriesURL(''))) {
      setLastVisitedURL(toString(location?.pathname));
    }
  }, [location?.pathname]);

  useEffect(() => {
    const getDeviceInfo = async () => {
      setDeviceData(await Device.getInfo());
      setDeviceId(await Device.getId());
      setDeviceLanguage(await Device.getLanguageCode());
      if (Capacitor.isNativePlatform()) {
        setAppData(await App.getInfo());
      }
      setDeployData(await Deploy.getCurrentVersion());
    };
    void getDeviceInfo();
  }, []);
  // #endregion

  const value = useMemo(
    () => ({
      deviceId,
      deviceData,
      deviceLanguage,
      appData,
      deployData,
      lastVisitedURL,
      appVersion,
      showDevTools,
      scannerDB,
      documentsDB,
      inventoryDB,
      setShowDevTools,
    }),
    [
      deviceId,
      deviceData,
      deviceLanguage,
      appData,
      deployData,
      lastVisitedURL,
      appVersion,
      showDevTools,
      scannerDB,
      documentsDB,
      inventoryDB,
    ]
  );

  return (
    <DeviceContext.Provider value={value}>{children}</DeviceContext.Provider>
  );
};

export default DeviceProvider;

export const useDevice = (): DeviceContextProps => {
  const ctx = useContext(DeviceContext);

  if (!ctx) {
    throw Error(
      'The `useDevice` hook must be called inside a `DeviceProvider`.'
    );
  }

  return {
    deviceId: ctx.deviceId,
    deviceData: ctx.deviceData,
    deviceLanguage: ctx.deviceLanguage,
    appData: ctx.appData,
    deployData: ctx.deployData,
    lastVisitedURL: ctx.lastVisitedURL,
    appVersion: ctx.appVersion,
    showDevTools: ctx.showDevTools,
    scannerDB: ctx.scannerDB,
    documentsDB: ctx.documentsDB,
    inventoryDB: ctx.inventoryDB,
    setShowDevTools: ctx.setShowDevTools,
  };
};
