import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonCol, IonRow } from '@ionic/react';
import type { CostSavingsTemplate } from 'CostSavingsApp/models/CostSavings';
import classes from 'CostSavingsApp/pages/CostSavingsEntry.module.scss';
import { namespaces } from 'i18n/i18n.constants';
import { findIcon } from 'utils/icons';
import Text from 'components/Text/Text';

interface VasCodeLineProps {
  templateData?: CostSavingsTemplate;
}

const VasCodeLine = ({
  templateData,
}: VasCodeLineProps): JSX.Element | null => {
  const { t } = useTranslation(namespaces.costSavings);

  const showVasCodeLine =
    templateData?.custCostSavingsDesc || templateData?.custVasCode;

  const vasCodeLine = {
    label:
      templateData?.custCostSavingsDesc ||
      (templateData?.custVasCode
        ? `${templateData?.custVasCode}:${templateData?.custVasDesc}`
        : ''),
    //  Have to hardcode this.  There is nothing from the API that checks it if warning.  What ECOS does
    warning: templateData?.custCostSavingsDesc.includes('not valid'),
  };

  return showVasCodeLine ? (
    <div
      className={classNames(classes.vasMessage, {
        [classes.vasWarning]: vasCodeLine.warning,
      })}
    >
      {vasCodeLine.warning ? (
        <FontAwesomeIcon icon={findIcon('exclamation-circle', 'fas')} />
      ) : (
        <IonRow className={classes.customerLabel}>
          <IonCol>
            <Text
              text={t('customerCostSavings')}
              variant="mipro-body-copy-bold"
              testid="cost-savings-custom-title"
            />
          </IonCol>
        </IonRow>
      )}
      <Text
        text={vasCodeLine.label}
        variant="mipro-body-copy"
        testid="cost-savings-custom-code"
      />
    </div>
  ) : null;
};

export default VasCodeLine;
