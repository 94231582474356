import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import type { AxiosError } from 'axios';
import { isNil, size, toString } from 'lodash';
import { useInfiniteQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { doConcatDataPages, doPromiseAPI, useKeyUserId } from 'api/helpers';
import type { Customer, RecentWebSearch } from 'models/Customer';
import type { InfiniteQueryFlags } from 'models/Search';
import type { RootState } from 'store/reducers';
import { pageSize } from 'utils/constants';
import useGetCustomer from './useGetCustomer';

export const findRecentSearchesQueryKey = 'customer-recent-searches';

interface UseGetRecentSearchesProps {
  miLoc: string;
  id: string;
  limit?: number;
  userID?: string;
  startDate?: string;
  endDate?: string;
}

interface UseGetRecentSearchesResponse {
  recentWebSearches?: RecentWebSearch[];
  customerData?: Customer;
}

const useGetRecentSearches = ({
  miLoc: iMiLoc,
  id,
  userID,
  startDate,
  endDate,
  limit = pageSize(),
}: UseGetRecentSearchesProps): UseGetRecentSearchesResponse &
  InfiniteQueryFlags => {
  const { axios } = useAxios();
  const { findRecentSearchesAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const { miLoc: sMiLoc = '' } = useSelector((state: RootState) => state.user);
  const miLoc = iMiLoc || sMiLoc;

  const params: Record<string, string> = {
    limit: toString(limit),
  };

  if (!isNil(startDate)) {
    params.startDate = startDate;
  }
  if (!isNil(endDate)) {
    params.endDate = endDate;
  }
  if (!isNil(userID)) {
    params.userID = userID;
  }

  const { data: customerData } = useGetCustomer({
    searchType: 'customer',
    miLoc,
    id,
  });

  const doGetRecentSearches = ({ pageParam = 1 }) => {
    return doPromiseAPI<RecentWebSearch[]>(async (cancelToken) => {
      const { data } = await axios.get<RecentWebSearch[]>(
        findRecentSearchesAPI(
          miLoc,
          id,
          toString(
            new URLSearchParams({ ...params, page: toString(pageParam) })
          )
        ),
        { cancelToken }
      );
      return data;
    });
  };

  const response = useInfiniteQuery<RecentWebSearch[], AxiosError>(
    createQueryKey(findRecentSearchesQueryKey, {
      searchType: 'customer',
      miLoc,
      id,
      ...params,
    }),
    doGetRecentSearches,
    {
      getNextPageParam: (lastPage, pages) =>
        size(lastPage) < limit ? false : size(pages) + 1,
    }
  );

  const {
    data,
    error,
    status,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = response;

  const recentWebSearches = useMemo(
    () => doConcatDataPages<RecentWebSearch>(data),
    [data]
  );

  const hasItems = size(recentWebSearches) > 0;
  const hasError = status === 'error';
  const isEmptyResponse = status === 'success' && !hasNextPage && !hasItems;
  const noMoreData = status === 'success' && !hasNextPage && hasItems;
  const showLoader = status === 'loading' || isFetchingNextPage;
  const enableInfiniteScroll = !(!hasNextPage || isFetchingNextPage);

  return {
    recentWebSearches,
    customerData,
    error,
    hasError,
    showLoader,
    isEmptyResponse,
    noMoreData,
    enableInfiniteScroll,
    fetchNextPage: async () => {
      await fetchNextPage();
    },
    refetch: async () => {
      await refetch?.call(null);
    },
  };
};

export default useGetRecentSearches;
