import { useSelector } from 'react-redux';
import { head } from 'lodash';
import type { InfiniteData } from '@tanstack/react-query';
import type { BaseAPIHook } from 'common/api/utils/apiHelpers';
import type {
  QueryFlags,
  QueryParamsType,
} from 'common/api/utils/useGetQueryFlags';
import useQuerySetup from 'common/api/utils/useQuerySetup';
import { ifFunction } from 'common/utils/logicHelpers';
import { useAxios } from 'providers/AxiosProvider';
import { useMiLocOrTeamId } from 'api/helpers';
import type { ViewAsRoleType } from 'models/Reports';
import { SortFieldEnum, type SortDirEnum } from 'models/Sort';
import type { RootState } from 'store/reducers';
import { pageSize } from 'utils/constants';
import { getCostSavingsAPI } from './apiRoutes';

export interface CostSavingsReportRow {
  overage: number;
  sales: number;
  commitCurrentSales: number;
  commitPercentage: number;
  projectedSales: number;
  approvedSales: number;
  pendingSales: number;
  guaranteed: string;
  miLoc: string;
  miLocName: string;
  customerName: string;
  customerNo: string;
  teamName: string;
  teamId: string;
  corporateAcctName: string;
  corporateAcctNo: string;
  maxOverage: number;
  maxApprovedSales: number;
}

interface UseCostSavingsProps extends BaseAPIHook {
  miLoc?: string;
  customerId?: string;
  groupBy?: string;
  sortField?: string;
  sortDir?: SortDirEnum;
  guaranteed?: string;
  showBy?: string;
  viewAsRole: ViewAsRoleType;
  natlAcct?: string;
}

export interface GetCostSavingsResponse {
  totals?: CostSavingsReportRow;
  rows: CostSavingsReportRow[];
  currencyType?: string;
}

export const getCostSavingsReportQueryKey = 'cost-savings-report';

const useGetCostSavingsReport = ({
  miLoc,
  customerId,
  groupBy,
  sortField,
  sortDir,
  limit = pageSize(),
  enabled,
  guaranteed,
  viewAsRole,
  natlAcct,
}: UseCostSavingsProps): GetCostSavingsResponse & QueryFlags => {
  const { axios } = useAxios();
  const { createParams, getURLParams } = useMiLocOrTeamId({
    miLoc,
    sendVirtualTeamId: true,
  });
  const { isCamUser } = useSelector((state: RootState) => state.user);

  const groupByParams = groupBy;

  const params: QueryParamsType = {
    ...createParams(),
    customerNo: customerId,
    limit,
    groupBy: groupByParams,
    sortCol: sortField,
    sortDir,
    guaranteed,
    viewAsRole,
    isCamUser,
    natlAcct,
  };

  ifFunction(miLoc === 'EXEC', () => {
    params.miLoc = 'AL00';
  });

  ifFunction(
    params.sortCol === SortFieldEnum.name &&
      (viewAsRole === 'CUST' || viewAsRole === 'BILLTO'),
    () => {
      params.sortCol = SortFieldEnum.customerName;
    }
  );
  ifFunction(
    params.sortCol === SortFieldEnum.name && viewAsRole === 'CORP',
    () => {
      params.sortCol = SortFieldEnum.corporateAcctName;
    }
  );

  const response = useQuerySetup<CostSavingsReportRow, GetCostSavingsResponse>({
    queryKey: getCostSavingsReportQueryKey,
    queryParams: params,
    onAPIRequest: async ({ pageParam = 1, signal }) => {
      const { data } = await axios.get<GetCostSavingsResponse>(
        getCostSavingsAPI(getURLParams({ ...params, page: pageParam })),
        { signal }
      );
      return {
        rows: data.rows,
        totals: data.totals,
        currencyType: data.currencyType,
      };
    },
    dataPath: 'rows',
    totalDataPath: 'totals',
    limit,
    enabled,
    isInfiniteQuery: true,
  });

  const { items, totalItems, data: responseData, ...queryFlags } = response;
  const costSavingsData = responseData as InfiniteData<GetCostSavingsResponse>;
  const summaryPage = head(costSavingsData?.pages);

  return {
    ...queryFlags,
    rows: items,
    totals: summaryPage?.totals,
    currencyType: summaryPage?.currencyType,
  };
};

export default useGetCostSavingsReport;
