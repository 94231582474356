import React, {
  useEffect,
  useState,
  createContext,
  useContext,
  useCallback,
} from 'react';
import SentryCategories from 'constants/sentryCategories';
import { toString } from 'lodash';
import { isPlatform } from '@ionic/react';
import {
  BrowserVault,
  Device as DeviceIdentityVault,
  DeviceSecurityType,
  SupportedBiometricType,
  Vault,
  VaultErrorCodes,
  VaultType,
} from '@ionic-enterprise/identity-vault';
import type {
  IdentityVaultConfig,
  VaultError,
} from '@ionic-enterprise/identity-vault';
import * as Sentry from '@sentry/capacitor';
import Base62Str from 'base62str';
import AuthTokenStorage from 'storage/AuthTokenStorage';
import { getLockAfterBackgroundedConfig } from 'utils/vault';

const ACCESS_TOKEN = 'access-token';

export enum UnlockMode {
  NeverLock = 'NeverLock',
  Biometrics = 'Biometrics',
  CustomPIN = 'CustomPIN',
  BiometricsScreenLock = 'BiometricsScreenLock',
}

export interface BiometricInfo {
  isAvailable: boolean;
  type: string;
  title: string;
  icon: string;
}

const initIdentityVaultConfig: IdentityVaultConfig = {
  key: `${toString(process.env.REACT_APP_ENV_BUILD)}.com.motion.mipro`,
  type: VaultType.SecureStorage,
  deviceSecurityType: DeviceSecurityType.None,
  unlockVaultOnLoad: false,
  androidBiometricsPromptTitle: 'Mi Pro',
  androidBiometricsPromptSubtitle: 'Please Authenticate',
  lockAfterBackgrounded: getLockAfterBackgroundedConfig(),
};

export interface VaultContextProps {
  biometricData: BiometricInfo;
  setBioMetricType: (type?: SupportedBiometricType) => void;
  vault?: Vault | BrowserVault;
  isBiometricEnabled: boolean;
  isSystemPinEnabled: boolean;
  isSetPasscodeMode: boolean;
  showPinModal: boolean;
  supportedBiometricTypes: SupportedBiometricType[];
  isLockedAfterBackgrounded: boolean;
  vaultClearTrigger: boolean;
  setIsSetPasscodeMode: (mode: boolean) => void;
  setAuthToken: (token: string) => Promise<void>;
  getAuthToken: () => Promise<string | null | undefined>;
  removeAuthToken: () => Promise<void>;
  updateUnlockMode: (unlockMode: string, unlock?: boolean) => Promise<void>;
  getUnlockMode: (vault?: Vault | BrowserVault) => UnlockMode;
  canUnlock: () => Promise<boolean>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handlePasscodeRequest: (opts: { data: any; role?: string }) => Promise<void>;
  setShowPinModal: (showModal: boolean) => void;
  lockVault: () => Promise<void | undefined>;
  clearVault: () => Promise<void | undefined>;
}

export const bioMetricInitialState: BiometricInfo = {
  isAvailable: false,
  type: '',
  title: '',
  icon: '',
};

const FINGER: BiometricInfo = {
  isAvailable: true,
  type: 'finger',
  title: 'touchID',
  icon: 'touchId',
};

const FACE: BiometricInfo = {
  isAvailable: true,
  type: 'face',
  title: 'faceID',
  icon: 'faceId',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PasscodeRequestCallback = (opts: any) => Promise<void>;
let handlePasscodeRequest: PasscodeRequestCallback = () => Promise.resolve();

const VaultContext = createContext<VaultContextProps>({
  biometricData: bioMetricInitialState,
  vault: undefined,
  isBiometricEnabled: false,
  isSystemPinEnabled: false,
  isSetPasscodeMode: false,
  showPinModal: false,
  supportedBiometricTypes: [],
  isLockedAfterBackgrounded: false,
  vaultClearTrigger: false,
  setIsSetPasscodeMode: () => {},
  setBioMetricType: () => {},
  setAuthToken: () => Promise.resolve(),
  getAuthToken: () => Promise.resolve(''),
  removeAuthToken: () => Promise.resolve(),
  updateUnlockMode: () => new Promise<void>(() => {}),
  getUnlockMode: () => UnlockMode.NeverLock,
  canUnlock: () => Promise.resolve(false),
  handlePasscodeRequest: () => Promise.resolve(),
  setShowPinModal: () => {},
  lockVault: () => new Promise<void>(() => {}),
  clearVault: () => new Promise<void>(() => {}),
});

export const base62 = Base62Str.createInstance();

const VaultProvider = ({
  children,
}: React.PropsWithChildren<unknown>): JSX.Element => {
  const [biometricData, setBiometricData] = useState<BiometricInfo>(
    bioMetricInitialState
  );

  const [vault, setVault] = useState<Vault | BrowserVault>();
  const [isBiometricEnabled, setIsBiometricEnabled] = useState<boolean>(false);
  const [isSystemPinEnabled, setIsSystemPinEnabled] = useState<boolean>(false);
  const [showPinModal, setShowPinModal] = useState<boolean>(false);

  const [isSetPasscodeMode, setIsSetPasscodeMode] = useState<boolean>(false);
  const [isReady, setIsReady] = useState(false);
  const [supportedBiometricTypes, setSupportedBiometricTypes] = useState<
    SupportedBiometricType[]
  >([]);
  const [isLockedAfterBackgrounded, setIsLockedAfterBackgrounded] =
    useState<boolean>(false);
  const [vaultClearTrigger, setVaultClearTrigger] = useState<boolean>(false);

  const setBioMetricType = (type?: SupportedBiometricType): void => {
    switch (type) {
      // case 'finger':
      case SupportedBiometricType.Fingerprint:
        setBiometricData(FINGER);
        break;
      case SupportedBiometricType.Face:
        setBiometricData(FACE);
        break;
      default:
        setBiometricData(bioMetricInitialState);
    }
  };

  useEffect(() => {
    const vaultInit = isPlatform('hybrid')
      ? new Vault(initIdentityVaultConfig)
      : new BrowserVault(initIdentityVaultConfig);
    setVault(vaultInit);
    setIsReady(true);

    const bioMetricOrPasscodeEnabled = async () => {
      const isBMEnabled = await DeviceIdentityVault.isBiometricsEnabled();
      const isSPEnabled = await DeviceIdentityVault.isSystemPasscodeSet();
      setIsBiometricEnabled(isBMEnabled);
      setIsSystemPinEnabled(isSPEnabled);
      const hardwares = await DeviceIdentityVault.getAvailableHardware();
      setSupportedBiometricTypes(hardwares);
      if (hardwares.length > 0) {
        setBioMetricType(hardwares[0]);
      }
    };
    void bioMetricOrPasscodeEnabled();
  }, []);

  vault?.onPasscodeRequested(async (isPasscodeSetRequest) => {
    return new Promise((resolve) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      handlePasscodeRequest = async (opts: { data: any; role?: string }) => {
        if (opts.role === 'cancel') {
          await vault?.setCustomPasscode('');
        } else {
          await vault?.setCustomPasscode(opts.data as string);
        }
        setIsSetPasscodeMode(false);
        setShowPinModal(false);
        resolve();
      };
      setIsSetPasscodeMode(isPasscodeSetRequest);
      setShowPinModal(true);
    });
  });

  // #region identity vault
  const setAuthToken = async (token: string) => {
    try {
      await vault?.setValue<string>(ACCESS_TOKEN, token);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.debug('Error setting vault token', e);
    }
  };

  const lockVault = async () => vault?.lock();

  const canUnlock = useCallback(async (): Promise<boolean> => {
    return (
      ((await vault?.doesVaultExist()) && (await vault?.isLocked())) ||
      Promise.resolve(false)
    );
  }, [vault]);

  const clearVault = async () => vault?.clear();

  const getUnlockMode = (newVault = vault): UnlockMode => {
    let mode = UnlockMode.NeverLock;
    const type =
      (newVault?.config?.type as VaultType) || VaultType.SecureStorage;
    const deviceSecurityType =
      (newVault?.config?.deviceSecurityType as DeviceSecurityType) ||
      DeviceSecurityType.None;

    if (
      type === VaultType.DeviceSecurity &&
      deviceSecurityType === DeviceSecurityType.Biometrics
    ) {
      mode = UnlockMode.Biometrics;
    } else if (type === VaultType.CustomPasscode) {
      mode = UnlockMode.CustomPIN;
    } else if (
      type === VaultType.DeviceSecurity &&
      deviceSecurityType === DeviceSecurityType.Both
    ) {
      mode = UnlockMode.BiometricsScreenLock;
    }
    return mode;
  };

  const getAuthToken = async () => {
    let token;
    try {
      token = await vault?.getValue<string>(ACCESS_TOKEN);
    } catch (err) {
      // TODO need to be fixed it is not correct what if error type is different
      const error = err as VaultError;
      switch (error.code) {
        case VaultErrorCodes.UserCanceledInteraction:
          Sentry.addBreadcrumb({
            category: SentryCategories.AUTH_STATE,
            message: `Failed unlocking of vault in state due to multiple attempt
                        at concurrent time
                        )}`,
            level: 'error',
          });
          break;
        default:
          Sentry.addBreadcrumb({
            category: SentryCategories.AUTH_STATE,
            message: toString(err),
            level: 'error',
          });
      }
      throw err;
    }
    return token;
  };

  const removeAuthToken = async () => {
    await vault?.removeValue(ACCESS_TOKEN);
    // DOC: migration vaidation
    await AuthTokenStorage.remove();
  };

  const updateUnlockMode = async (
    mode: string,
    unlock = true
  ): Promise<void> => {
    let type: VaultType;
    let deviceSecurityType: DeviceSecurityType;

    switch (mode) {
      case UnlockMode.BiometricsScreenLock:
        type = VaultType.DeviceSecurity;
        deviceSecurityType = DeviceSecurityType.Both;
        break;
      case UnlockMode.Biometrics:
        type = VaultType.DeviceSecurity;
        deviceSecurityType = DeviceSecurityType.Biometrics;
        break;
      case UnlockMode.CustomPIN:
        type = VaultType.CustomPasscode;
        deviceSecurityType = DeviceSecurityType.None;
        break;
      default:
        type = VaultType.SecureStorage;
        deviceSecurityType = DeviceSecurityType.None;
    }
    if (unlock) {
      await vault?.unlock();
    }

    await vault?.updateConfig({
      ...vault?.config,
      key: toString(vault?.config?.key),
      type,
      deviceSecurityType,
    });
  };

  vault?.onError((err) => {
    // eslint-disable-next-line no-console
    console.debug('ERROR from callback', JSON.stringify(err));
    if (err.code === 7) {
      setVaultClearTrigger(true);
    }
  });

  vault?.onUnlock(() => {
    // TODO: This is a hack to ensure the timeout message appears on the login screen
    setTimeout(() => {
      setIsLockedAfterBackgrounded(false);
    }, 10000);
  });

  vault?.onLock((lockEvent) => {
    if (lockEvent.timeout !== false) {
      setIsLockedAfterBackgrounded(true);
    }
  });

  return (
    <VaultContext.Provider
      value={{
        biometricData,
        setBioMetricType,
        vault,
        isBiometricEnabled,
        isSystemPinEnabled,
        isSetPasscodeMode,
        showPinModal,
        supportedBiometricTypes,
        isLockedAfterBackgrounded,
        vaultClearTrigger,
        setAuthToken,
        getAuthToken,
        removeAuthToken,
        updateUnlockMode,
        getUnlockMode,
        canUnlock,
        setIsSetPasscodeMode,
        handlePasscodeRequest,
        setShowPinModal,
        lockVault,
        clearVault,
      }}
    >
      {!!isReady && children}
    </VaultContext.Provider>
  );
};

export default VaultProvider;

export const useVault = (): VaultContextProps => {
  const ctx = useContext(VaultContext);

  if (!ctx) {
    throw Error('The `useVault` hook must be called inside a `VaultProvider`.');
  }

  return {
    biometricData: ctx.biometricData,
    // TODO: vault should be abstracted away by VaultProvider
    vault: ctx.vault,
    isBiometricEnabled: ctx.isBiometricEnabled,
    isSystemPinEnabled: ctx.isSystemPinEnabled,
    isSetPasscodeMode: ctx.isSetPasscodeMode,
    showPinModal: ctx.showPinModal,
    supportedBiometricTypes: ctx.supportedBiometricTypes,
    isLockedAfterBackgrounded: ctx.isLockedAfterBackgrounded,
    vaultClearTrigger: ctx.vaultClearTrigger,
    setIsSetPasscodeMode: ctx.setIsSetPasscodeMode,
    setBioMetricType: ctx.setBioMetricType,
    setAuthToken: ctx.setAuthToken,
    getAuthToken: ctx.getAuthToken,
    removeAuthToken: ctx.removeAuthToken,
    getUnlockMode: ctx.getUnlockMode,
    updateUnlockMode: ctx.updateUnlockMode,
    canUnlock: ctx.canUnlock,
    handlePasscodeRequest: ctx.handlePasscodeRequest,
    setShowPinModal: ctx.setShowPinModal,
    lockVault: ctx.lockVault,
    clearVault: ctx.clearVault,
  };
};
