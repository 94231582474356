import type { AxiosError } from 'axios';
import { isNil } from 'lodash';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { doPromiseAPI, getPlaceholderData, useKeyUserId } from 'api/helpers';
import type { Location } from 'models/Location';
import type { QueryFlags, SearchItem } from 'models/Search';
import { findLocationsQueryKey } from './useFindLocations';

export const getLocationQueryKey = 'search-location';

interface UseGetLocationProps {
  miLoc: string;
  enabled?: boolean;
}

interface UseGetLocationResponse {
  data?: Location;
}

const useGetLocationItem = ({
  miLoc,
  enabled = true,
}: UseGetLocationProps): UseGetLocationResponse & QueryFlags => {
  const { axios } = useAxios();
  const { getLocationAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const queryClient = useQueryClient();

  const doGetLocation = () => {
    return doPromiseAPI<Location>(async (cancelToken) => {
      const { data } = await axios.get<Location>(getLocationAPI(miLoc), {
        cancelToken,
      });
      return data;
    });
  };

  const { data, status, error, isFetching } = useQuery<Location, AxiosError>(
    createQueryKey(getLocationQueryKey, { id: miLoc }),
    doGetLocation,
    {
      enabled: enabled && !!miLoc,
      placeholderData: () => {
        const searchLocation = getPlaceholderData<SearchItem>({
          queryClient,
          queryKey: findLocationsQueryKey,
          objectKey: 'items',
          findPredicate: { id: miLoc },
        });
        if (!isNil(searchLocation)) {
          return {
            ...searchLocation,
            miLoc: searchLocation.id,
          } as unknown as Location;
        }
        return undefined;
      },
    }
  );

  return {
    data,
    error,
    isLoading: status === 'loading' || (isFetching && isNil(data?.miLoc)),
  };
};

export default useGetLocationItem;
