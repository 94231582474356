import { useTranslation } from 'react-i18next';
import type { MutationStatus } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useCountPlanDB from 'InventoryApp/database/useCountPlanDB';
import { useToasts } from 'providers/ToastProvider';
import { doPromiseAPI, onSuccessMutation } from 'api/helpers';
import { ToastType } from 'models/Toast';
import {
  findCountGroupItemsQueryKey,
  findCountGroupTotalItemsQueryKey,
} from './useFindCountGroupItems';

interface UpdateItemProfileBody {
  itemId: string;
  miMaxQty: number;
  miMinQty: number;
  orderQuantity: number;
}

interface UseUpdateItemProfileResponse {
  status: MutationStatus;
  onUpdateItemProfile: (body: UpdateItemProfileBody) => void;
}

const useUpdateItemProfile = (): UseUpdateItemProfileResponse => {
  const { addToast } = useToasts();
  const { updateMinMaxCount } = useCountPlanDB();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const doUpdateItemProfile = (body: UpdateItemProfileBody) => {
    return doPromiseAPI(async () => {
      const { itemId, miMinQty, miMaxQty, orderQuantity } = body;
      await updateMinMaxCount(itemId, miMinQty, miMaxQty, true, orderQuantity);
    });
  };

  const { status, mutate } = useMutation(doUpdateItemProfile, {
    // TODO: add optimistic update?
    onSuccess: () => {
      void onSuccessMutation(queryClient, findCountGroupItemsQueryKey);
      void onSuccessMutation(queryClient, findCountGroupTotalItemsQueryKey);
      addToast({
        type: ToastType.success,
        variant: 'mipro-toast',
        testid: 'update-item-profile-success-toast',
        text: t('inventory:minMaxUpdate'),
      });
    },
    onError: () =>
      addToast({
        type: ToastType.error,
        text: t('inventory:minMaxUpdateError'),
        testid: 'update-item-profile-error-toast',
      }),
  });

  return {
    status,
    onUpdateItemProfile: (body: UpdateItemProfileBody) => mutate(body),
  };
};

export default useUpdateItemProfile;
