import type { SQLiteDBConnection } from 'react-sqlite-hook';
import { map, size } from 'lodash';
import type { CountGroupItem } from 'InventoryApp/models/InventoryPlanGroup';

// eslint-disable-next-line import/prefer-default-export
export const createGroupItems = async (
  db: SQLiteDBConnection,
  items: CountGroupItem[]
) => {
  const statements = map(items, (item) => ({
    statement: `INSERT OR REPLACE INTO count_plan_item (
      uniqueId,
      countPlanId,
      groupId,
      hasCount,
      miLoc,
      actualCost,
      actualCount,
      barcode,
      bin,
      custStockNo,
      description,
      groupSerialNo,
      itemNo,
      lineNo,
      loc1,
      loc2,
      loc3,
      loc4,
      loc5,
      lotNo,
      mfrCtlNo,
      miMaxQty,
      miMinQty,
      mino,
      upcNo,
      uom,
      onOrderQty,
      orderQty,
      pageNo,
      type,
      unprocessedReceiptQty,
      unprocessedUsageQty,
      actualItemWidth,
      actualItemLength,
      rowNbr,
      slabRef,
      mfrName,
      hasEditedMinMax,
      allowEdit,
      hasLocalCount
      ) VALUES (?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?)`,
    values: [
      item.uniqueId,
      item.countPlanId,
      item.groupId,
      item.hasCount,
      item.miLoc,
      item.actualCost,
      item.actualCount,
      item.barcode,
      item.bin,
      item.custStockNo,
      item.description,
      item.groupSerialNo,
      item.itemNo,
      item.lineNo,
      item.loc1,
      item.loc2,
      item.loc3,
      item.loc4,
      item.loc5,
      item.lotNo,
      item.mfrCtlNo,
      item.miMaxQty,
      item.miMinQty,
      item.mino,
      item.upcNo,
      item.uom,
      item.onOrderQty,
      item.orderQty,
      item.pageNo,
      item.type,
      item.unprocessedReceiptQty,
      item.unprocessedUsageQty,
      item.actualItemWidth,
      item.actualItemLength,
      item.rowNbr,
      item.slabRef,
      item.mfrName,
      item.hasEditedMinMax,
      item.allowEdit,
      item.hasLocalCount,
    ],
  }));
  if (size(statements) > 0) {
    await db.executeSet(statements, true);
  }
};
