import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import type { AxiosError } from 'axios';
import { isNil, size, toString } from 'lodash';
import { useInfiniteQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { doConcatDataPages, doPromiseAPI, useKeyUserId } from 'api/helpers';
import type { RecentProductSearch } from 'models/Customer';
import type { InfiniteQueryFlags } from 'models/Search';
import type { WebActivityTabType } from 'models/WebActivity';
import type { RootState } from 'store/reducers';
import { pageSize } from 'utils/constants';

export const findRecentProductsQueryKey = 'customer-recent-products';

interface UseGetRecentProductsProps {
  miLoc: string;
  id: string;
  productType: WebActivityTabType;
  limit?: number;
  userID?: string;
  startDate?: string;
  endDate?: string;
}

interface UseGetRecentProductsResponse {
  recentProductSearches?: RecentProductSearch[];
}

const useGetRecentProducts = ({
  miLoc: iMiLoc,
  id,
  productType,
  userID,
  startDate,
  endDate,
  limit = pageSize(),
}: UseGetRecentProductsProps): UseGetRecentProductsResponse &
  InfiniteQueryFlags => {
  const { axios } = useAxios();
  const { findRecentProductsAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const { miLoc: sMiLoc = '' } = useSelector((state: RootState) => state.user);
  const miLoc = iMiLoc || sMiLoc;

  const params: Record<string, string> = {
    limit: toString(limit),
  };

  if (!isNil(startDate)) {
    params.startDate = startDate;
  }
  if (!isNil(endDate)) {
    params.endDate = endDate;
  }
  if (!isNil(userID)) {
    params.userID = userID;
  }

  const doGetRecentProducts = ({ pageParam = 1 }) => {
    return doPromiseAPI<RecentProductSearch[]>(async (cancelToken) => {
      const { data } = await axios.get<RecentProductSearch[]>(
        findRecentProductsAPI(
          miLoc,
          id,
          toString(
            new URLSearchParams({
              ...params,
              page: toString(pageParam),
              [productType]: '0',
            })
          )
        ),
        { cancelToken }
      );

      return data;
    });
  };

  const response = useInfiniteQuery<RecentProductSearch[], AxiosError>(
    createQueryKey(findRecentProductsQueryKey, {
      miLoc,
      id,
      productType,
      ...params,
    }),
    doGetRecentProducts,
    {
      getNextPageParam: (lastPage, pages) =>
        size(lastPage) < limit ? false : size(pages) + 1,
    }
  );

  const {
    data,
    error,
    status,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = response;

  const recentProductSearches = useMemo(
    () => doConcatDataPages<RecentProductSearch>(data),
    [data]
  );

  const hasItems = size(recentProductSearches) > 0;
  const hasError = status === 'error';
  const isEmptyResponse = status === 'success' && !hasNextPage && !hasItems;
  const noMoreData = status === 'success' && !hasNextPage && hasItems;
  const showLoader = status === 'loading' || isFetchingNextPage;
  const enableInfiniteScroll = !(!hasNextPage || isFetchingNextPage);

  return {
    recentProductSearches,
    error,
    hasError,
    showLoader,
    isEmptyResponse,
    noMoreData,
    enableInfiniteScroll,
    fetchNextPage: async () => {
      await fetchNextPage();
    },
    refetch: async () => {
      await refetch?.call(null);
    },
  };
};

export default useGetRecentProducts;
