import { useTranslation } from 'react-i18next';
import { toString } from 'lodash';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { MutationStatus } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { namespaces } from 'i18n/i18n.constants';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import type { UpdateMutationContext } from 'api/helpers';
import {
  doPromiseAPI,
  onErrorUpdate,
  onMutateUpdate,
  onSuccessMutation,
} from 'api/helpers';
import type { Contact, UpdateContactBody } from 'models/Contact';
import type { SearchItemType } from 'models/Search';
import { ToastType } from 'models/Toast';
import { findContactsQueryKey } from './useFindContacts';

interface RemoveContactMutationProps {
  searchType: SearchItemType;
  miLoc: string;
  id: string;
  sequenceNo: number;
}

interface UseRemoveContactResponse {
  status: MutationStatus;
  removeContact: (body: UpdateContactBody) => void;
}

const useRemoveContact = ({
  searchType,
  miLoc,
  id,
  sequenceNo,
}: RemoveContactMutationProps): UseRemoveContactResponse => {
  const { axios } = useAxios();
  const { contactAPI } = useAPIUrl();
  const { t } = useTranslation(namespaces.contact);
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const doRemoveContact = (body: UpdateContactBody) => {
    return doPromiseAPI(async (cancelToken) =>
      axios.delete(contactAPI(searchType, miLoc, id, toString(sequenceNo)), {
        cancelToken,
      })
    );
  };

  const updateQueryKeyParams = { searchType, id };

  const { mutate, status } = useMutation(doRemoveContact, {
    onMutate: async (vars) => {
      addToast({
        text: t('deleteSuccessToast', {
          name: vars?.name || t('editDefaultNameToast'),
        }),
        // TODO undo operation
        testid: 'delete-contact-toast',
      });
      return onMutateUpdate<Contact>({
        queryClient,
        queryKey: findContactsQueryKey,
        queryKeyParams: updateQueryKeyParams,
        removedFindPredicates: [
          {
            sequenceNo,
            // DOC: supplier contacts have miLoc
            ...(searchType === 'supplier' ? { miLoc } : {}),
          },
        ],
        isInfiniteQuery: true,
      });
    },
    onSuccess: () => {
      setTimeout(() => {
        void onSuccessMutation(
          queryClient,
          findContactsQueryKey,
          updateQueryKeyParams
        );
      }, 1000);
    },
    onError: (error, vars, context) => {
      addToast({
        type: ToastType.error,
        text: t('deleteContactSaveFailure'),
        testid: 'delete-contact-error-toast',
      });
      onErrorUpdate<Contact>({
        queryClient,
        context: context as UpdateMutationContext<Contact>[],
        isInfiniteQuery: true,
      });
    },
  });

  return { status, removeContact: (body: UpdateContactBody) => mutate(body) };
};

export default useRemoveContact;
