import { useMemo } from 'react';
import type { AxiosError } from 'axios';
import type { Dictionary } from 'lodash';
import { isEmpty, map, size, toString } from 'lodash';
import { useInfiniteQuery } from '@tanstack/react-query';
import type { InfiniteQueryObserverResult } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { doConcatDataPages, doPromiseAPI, useKeyUserId } from 'api/helpers';
import type { Location } from 'models/Location';
import type {
  SearchItem,
  SearchResponse,
  InfiniteQueryFlags,
} from 'models/Search';
import { pageSize } from 'utils/constants';

export const findLocationsQueryKey = 'search-locations';

interface UseFindLocationsProps {
  query?: string;
  locTypes: string[];
  sortField?: string;
  sortDir?: string;
  limit?: number;
  enabled?: boolean;
}

export type UseFindLocationsResponse = SearchResponse &
  InfiniteQueryFlags &
  Pick<InfiniteQueryObserverResult<SearchResponse>, 'data'>;

const useFindLocations = ({
  query = '',
  locTypes,
  sortField = 'name',
  sortDir = 'ASC',
  limit = pageSize(),
  enabled = true,
}: UseFindLocationsProps): UseFindLocationsResponse => {
  const { axios } = useAxios();
  const { findLocationsAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const params: Dictionary<string> = {
    query: toString(query),
    locTypes: toString(locTypes),
    sortField,
    sortDir,
    limit: toString(limit),
  };

  const doFindLocations = ({ pageParam = 1 }) => {
    return doPromiseAPI<SearchResponse>(async (cancelToken) => {
      const { data } = await axios.get<Location[]>(
        findLocationsAPI(
          toString(
            new URLSearchParams({ ...params, page: toString(pageParam) })
          )
        ),
        { cancelToken }
      );
      return {
        items: map(
          data,
          ({
            miLoc,
            locationShortName,
            shipToAddressLine1,
            shipToAddressLine2,
            shipToAddressLine3,
            shipToCity,
            shipToState,
            shipToZIP,
          }) => ({
            type: 'motionLocation',
            id: miLoc,
            text: `${miLoc || ''} - ${locationShortName || ''}`,
            locationShortName,
            shipToAddressLine1,
            shipToAddressLine2,
            shipToAddressLine3,
            shipToCity,
            shipToState,
            shipToZIP,
          })
        ),
      };
    });
  };

  const response = useInfiniteQuery<SearchResponse, AxiosError>(
    createQueryKey(findLocationsQueryKey, params),
    doFindLocations,
    {
      enabled: !isEmpty(query) && enabled,
      getNextPageParam: (lastPage, pages) =>
        size(lastPage.items) < limit ? false : size(pages) + 1,
    }
  );

  const {
    data,
    error,
    status,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = response;

  const locations = useMemo(
    () => doConcatDataPages<SearchItem, SearchResponse>(data, 'items'),
    [data]
  );

  const hasItems = !isEmpty(locations);
  const hasError = status === 'error';
  const isEmptyResponse = status === 'success' && !hasNextPage && !hasItems;
  const noMoreData = status === 'success' && !hasNextPage && hasItems;
  const showLoader = status === 'loading' || isFetchingNextPage;
  const enableInfiniteScroll = !(!hasNextPage || isFetchingNextPage);

  return {
    data,
    fetchNextPage: async () => {
      await fetchNextPage();
    },
    items: locations,
    total: !showLoader ? data?.pages[0].total : undefined,
    error,
    hasError,
    showLoader,
    isEmptyResponse,
    noMoreData,
    enableInfiniteScroll,
    refetch: async () => {
      await refetch?.call(null);
    },
  };
};

export default useFindLocations;
