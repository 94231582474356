import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { filter, findIndex, map, size } from 'lodash';
import { inventoryURL } from 'navigation';
import { connectionStatusToastId } from 'utils/constants';
import Toast, { renderToasts } from 'components/Toast/Toast';

export type ToastProps = React.ComponentProps<typeof Toast>;

interface ToastContextProps {
  addToast: (props: ToastProps) => void;
  removeToast: (id: string) => void;
}

const ToastContext = createContext<ToastContextProps>({
  addToast: () => null,
  removeToast: () => null,
});

interface ToastProviderProps {
  children?: React.ReactNode;
}

const ToastProvider = ({ children }: ToastProviderProps): JSX.Element => {
  const [toasts, setToasts] = useState<ToastProps[]>([]);
  const { pathname } = useLocation();

  const removeToast = useCallback(
    (toastId: string) =>
      setToasts((prev) => {
        const index = findIndex(prev, { id: toastId });
        if (index !== -1) {
          return [...prev.slice(0, index), ...prev.slice(index + 1)];
        }
        return prev;
      }),
    []
  );

  const addToast = useCallback(
    (toast: ToastProps) =>
      setToasts((prev) => {
        const toastId = toast.id || `${toast.testid}${Date.now()}`;
        const newToast = {
          ...toast,
          id: toastId,
          onClose: () => {
            toast.onClose?.call(null);
            removeToast(toastId);
          },
        };

        const index = findIndex(prev, { id: toastId });
        if (index !== -1) {
          return [...prev.slice(0, index), ...prev.slice(index + 1), newToast];
        }
        return [...prev, newToast];
      }),
    [removeToast]
  );

  useEffect(() => {
    let counter = 0;
    const interval = setInterval(() => {
      if (counter > 3) {
        clearInterval(interval);
      } else {
        renderToasts();
      }
      counter += 1;
    }, 300);
    return () => {
      clearInterval(interval);
    };
  }, [pathname]);

  const filteredToasts = useMemo(
    () =>
      filter(
        toasts,
        ({ id }) =>
          !pathname.startsWith(inventoryURL()) || id !== connectionStatusToastId
      ),
    [toasts, pathname]
  );

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      {children}
      {map(filteredToasts, ({ id, testid, ...props }, index) => (
        <Toast
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          key={id}
          // DOC: update index of all toast based on current stack size
          index={size(filteredToasts) - index - 1}
          testid={testid}
        />
      ))}
    </ToastContext.Provider>
  );
};

export default ToastProvider;

export const useToasts = (): ToastContextProps => {
  const ctx = useContext(ToastContext);

  if (!ctx) {
    throw Error(
      'The `useToasts` hook must be called inside a `ToastProvider`.'
    );
  }

  return {
    addToast: ctx.addToast,
    removeToast: ctx.removeToast,
  };
};
