import {
  size,
  toNumber,
  map,
  toString,
  reduce,
  find,
  filter,
  set,
} from 'lodash';
import type { MutationStatus } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import useIssueDB from 'StoreroomsApp/database/useIssueDB';
import type {
  Issue,
  ItemIssue,
  ItemIssueDTO,
} from 'StoreroomsApp/models/Issue';
import type { UpdateMutationContext } from 'api/helpers';
import {
  useMiLocOrTeamId,
  onSuccessMutation,
  doPromiseAPI,
  onMutateUpdate,
  onErrorUpdate,
} from 'api/helpers';
import { ToastType } from 'models/Toast';
import useAddIssue from './useAddIssue';
import { findIssuesQueryKey } from './useFindIssues';
import { findItemsPOUQueryKey } from './useFindItemsPOU';
import { findStoreroomsQueryKey } from './useFindStorerooms';

interface UploadItemPOST {
  itemNo: string;
  customerNo: string;
  customerStockNo: string;
  quantity: number;
  binLocation: string;
  chargeBack1?: string;
  chargeBack2?: string;
  chargeBack3?: string;
  chargeBack4?: string;
  chargeBack5?: string;
  errorMessage?: string;
  storeroomNo?: string;
}

interface UploadIssuePOST {
  miLoc: string;
  storeroomNo: string;
  uniqueKey: string;
  items: UploadItemPOST[];
}

interface UploadIssuesBody {
  issues?: Issue[];
  issueId?: string;
  storeroomNumber?: string;
}

interface UseUploadIssuesResponse {
  status: MutationStatus;
  isLoading: boolean;
  onUploadIssues: (body: UploadIssuesBody) => void;
}

const useUploadIssues = (): UseUploadIssuesResponse => {
  const { axios } = useAxios();
  const { issueAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const { createParams } = useMiLocOrTeamId({ sendTeamId: false });
  const { findIssuesByStoreroom, findItemsFromIssue, closeIssue } =
    useIssueDB();
  const params = { ...createParams() };
  const { miLoc } = params;

  const { doAddIssue } = useAddIssue();

  const doUploadIssues = ({
    issues,
    issueId,
    storeroomNumber,
  }: UploadIssuesBody) => {
    return doPromiseAPI(async () => {
      const issuesToUpload = issues || [];
      const failedIssues: Array<Issue & { items: ItemIssueDTO[] }> = [];
      if (issueId && storeroomNumber) {
        const issueFromId = find(
          await findIssuesByStoreroom({ miLoc, storeroomNumber }),
          { id: toNumber(issueId) }
        );
        if (issueFromId) {
          issuesToUpload.push(issueFromId);
        }
      }
      const postData: UploadIssuePOST[] = [];
      await reduce(
        issuesToUpload,
        async (prev, issue: Issue) => {
          await prev;
          if (issue?.id) {
            const items = await findItemsFromIssue(toString(issue.id));
            postData.push({
              miLoc: issue.miLocation,
              storeroomNo: issue.storeroomNumber,
              uniqueKey: toString(issue.id),
              items: map(items, (item) => ({
                itemNo: item.itemNumber,
                customerNo: item.customerNumber,
                customerStockNo: item.customerStockNumber,
                quantity: item.issueQuantity,
                binLocation: item.binLocation,
                chargeBack1: issue.chargeBack1,
                chargeBack2: issue.chargeBack2,
                chargeBack3: issue.chargeBack3,
                chargeBack4: issue.chargeBack4,
                chargeBack5: issue.chargeBack5,
              })),
            });
            failedIssues.push({ ...issue, items });
          }
        },
        Promise.resolve()
      );
      const { data } = await axios.post<UploadIssuePOST[]>(
        issueAPI(),
        postData
      );
      const failedItems: UploadItemPOST[] = [];
      await reduce(
        failedIssues,
        async (prev, issue) => {
          await prev;
          if (issue?.id) {
            await closeIssue(issue.id);
            const itemsWithError = filter(
              find(data, { uniqueKey: toString(issue.id) })?.items,
              (item) => item.errorMessage
            ) as UploadItemPOST[];
            failedItems.push(...itemsWithError);
            const filteredItems: ItemIssueDTO[] = [];
            map(issue.items, (item) => {
              const itemError = find(itemsWithError, {
                itemNo: item.itemNumber,
                customerNo: item.customerNumber,
                customerStockNo: item.customerStockNumber,
                binLocation: item.binLocation,
              });
              if (itemError) {
                filteredItems.push({
                  ...item,
                  error: itemError.errorMessage,
                });
              }
            });
            set(issue, 'items', filteredItems);
          }
        },
        Promise.resolve()
      );
      return { failedItems, failedIssues };
    });
  };

  const { status, mutate, isLoading } = useMutation(doUploadIssues, {
    onMutate: async (vars) =>
      onMutateUpdate<Issue>({
        queryClient,
        queryKey: findIssuesQueryKey,
        removedFindPredicates: map(vars.issues, ({ id }) => ({ id })),
        isArrayQuery: true,
      }),
    onSuccess: async (data, vars) => {
      const errorToast =
        size(data.failedItems) > 0
          ? `Some items have errors that need to be corrected. You can visualize them in Issues List.`
          : '';
      if (vars.issueId && vars.storeroomNumber) {
        addToast({
          text: `The issue has been uploaded. ${errorToast}`,
          testid: 'upload-issue-success-toast',
        });
      } else {
        addToast({
          text: `The issues have been uploaded. ${errorToast}`,
          testid: 'upload-issue-success-toast',
        });
      }
      if (size(data.failedItems) > 0) {
        await reduce(
          data.failedIssues,
          async (prev, issue) => {
            await prev;
            if (size(issue.items) > 0) {
              await doAddIssue({
                storeroomNumber: issue.storeroomNumber,
                chargeBackValues: {
                  chargeBack1: toString(issue.chargeBack1),
                  chargeBack2: toString(issue.chargeBack2),
                  chargeBack3: toString(issue.chargeBack3),
                  chargeBack4: toString(issue.chargeBack4),
                  chargeBack5: toString(issue.chargeBack5),
                },
                items: issue.items as ItemIssue[],
              });
            }
          },
          Promise.resolve()
        );
      }
      void onSuccessMutation(queryClient, findIssuesQueryKey);
      void onSuccessMutation(queryClient, findItemsPOUQueryKey);
      void onSuccessMutation(queryClient, findStoreroomsQueryKey);
    },
    onError: (error, vars, context) => {
      if (vars.issueId && vars.storeroomNumber) {
        addToast({
          type: ToastType.warn,
          text: 'There was an error uploading issue. It will be saved in the local device, please try to upload manually later.',
          testid: 'upload-issue-error-toast',
        });
      } else {
        addToast({
          type: ToastType.error,
          text: 'There was an error uploading issues. Please try again later.',
          testid: 'upload-issue-error-toast',
        });
      }
      onErrorUpdate<Issue>({
        queryClient,
        context: context as UpdateMutationContext<Issue>[],
        isArrayQuery: true,
      });
      // DOC: if there is an error, we still want the upload list to be updated
      void onSuccessMutation(queryClient, findIssuesQueryKey);
      void onSuccessMutation(queryClient, findItemsPOUQueryKey);
      void onSuccessMutation(queryClient, findStoreroomsQueryKey);
    },
  });

  return {
    status,
    isLoading,
    onUploadIssues: (body: UploadIssuesBody) => mutate(body),
  };
};

export default useUploadIssues;
