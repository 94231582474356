import React, { useEffect, useRef, useState } from 'react';
import { toNumber } from 'lodash';
import {
  IonContent,
  IonHeader,
  IonModal,
  IonRow,
  isPlatform,
} from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import type { ItemPOU } from 'StoreroomsApp/models/ItemPOU';
import { findIcon } from 'utils/icons';
import Button from 'components/Button/Button';
import type Modal from 'components/Modal/Modal';
import Text from 'components/Text/Text';
import classes from './ItemsPOUList.module.scss';
import QuantityInput from './QuantityInput';

interface AddItemModalProps {
  itemPOU: ItemPOU;
  searchQuery?: string;
  onAdd?: (v: number) => void;
}

const AddItemModal = ({
  isOpen,
  setIsOpen,
  itemPOU,
  searchQuery,
  onAdd,
  testid,
}: AddItemModalProps &
  IonicReactProps &
  Omit<React.ComponentProps<typeof Modal>, 'title'>): JSX.Element => {
  const {
    customerStockNumber,
    itemDescription,
    binLocation,
    balanceOnHandQuantity,
  } = itemPOU;

  const isMobile = isPlatform('android') || isPlatform('hybrid');
  const initialBreakpoint = isMobile ? 1 : 0.5;
  let maxQuantity: number | undefined = toNumber(balanceOnHandQuantity);
  if (maxQuantity < 0) {
    maxQuantity = undefined;
  }

  const modal = useRef<HTMLIonModalElement>(null);
  const [quantity, setQuantity] = useState(0);
  const [triggerInputSelect, setTriggerInputSelect] = useState(0);

  useEffect(() => {
    if (isOpen) {
      setQuantity(0);
    }
  }, [isOpen]);

  return (
    <IonModal
      className={classes.modal}
      isOpen={isOpen}
      ref={modal}
      initialBreakpoint={initialBreakpoint}
      breakpoints={[0, 0.5, 0.75, 1]}
      onDidDismiss={() => setIsOpen?.(false)}
      onDidPresent={() => setTriggerInputSelect(Date.now())}
      data-testid={testid}
    >
      <IonHeader>
        <IonRow className={classes.headerRow}>
          <div>
            <Button
              icon={findIcon('times')}
              onClick={() => setIsOpen?.(false)}
              testid="close-button"
            />
          </div>
          <Text
            className={classes.title}
            variant="title-screen-section"
            text="Add product"
          />
          <div />
        </IonRow>
      </IonHeader>
      <IonContent className={classes.content}>
        <div className={classes.item}>
          <div className={classes.wrapper}>
            <IonRow>
              <div className={classes.inner}>
                <div className={classes.titleRow}>
                  <Text
                    className={classes.title}
                    text={`${customerStockNumber || '<No CSN>'}`}
                    textQuery={searchQuery}
                    variant="content-heavy"
                    testid="item-pou-text"
                  />
                  <Text text={balanceOnHandQuantity} />
                </div>
                <div>
                  <Text
                    className={classes.description}
                    textQuery={searchQuery}
                    text={itemDescription}
                    variant="content-small"
                  />
                </div>
                <div>
                  <Text textQuery={searchQuery} text={binLocation} />
                </div>
              </div>
            </IonRow>
          </div>
        </div>
        <div className={classes.quantityWrapper}>
          <div>
            <Text text="Quantity" variant="content-heavy" />
          </div>
          {!!maxQuantity && quantity > maxQuantity && (
            <div>
              <Text
                className={classes.errorMaxQuantity}
                text="Quantity issued is greater than Balance on Hand"
              />
            </div>
          )}
          {quantity <= 0 && (
            <div>
              <Text
                className={classes.errorMaxQuantity}
                text="Quantity issued must be greater than 0"
              />
            </div>
          )}
          <QuantityInput
            className={classes.input}
            value={quantity}
            max={maxQuantity}
            triggerInputSelect={triggerInputSelect}
            setValue={(v) => setQuantity(toNumber(v))}
          />
        </div>
        <div className={classes.buttonWrapper}>
          <Button
            className={classes.saveButton}
            variant="action"
            text="Add"
            onClick={() => {
              onAdd?.(quantity);
              setIsOpen?.(false);
            }}
            disabled={
              quantity <= 0 || (!!maxQuantity && quantity > maxQuantity)
            }
            testid="add-button"
          />
        </div>
      </IonContent>
    </IonModal>
  );
};

export default AddItemModal;
