import { useEffect, useState } from 'react';
import type { AxiosError } from 'axios';
import { map, toNumber, toString } from 'lodash';
import * as Sentry from '@sentry/capacitor';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import useTemplateDB from 'DocumentsApp/database/useTemplate';
import type { TemplateDataAPI } from 'DocumentsApp/models/Template';
import { useAxios } from 'providers/AxiosProvider';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import { useToasts } from 'providers/ToastProvider';
import {
  doGetIsLoading,
  doPromiseAPI,
  onSuccessMutation,
  useKeyUserId,
} from 'api/helpers';
import type {
  DocumentTemplate,
  DocumentTemplates,
} from 'models/DocumentTemplate';
import type { QueryFlags } from 'models/Search';
import { ToastType } from 'models/Toast';

export const findTemplatesQueryKey = 'document-templates';

interface UseFindTemplatesProps {
  enabled?: boolean;
}

interface UseFindTemplatesResponse {
  templates?: DocumentTemplates[];
  loadingAPI?: boolean;
  sync: () => Promise<void>;
}

const useFindTemplates = ({
  enabled = true,
}: UseFindTemplatesProps): UseFindTemplatesResponse & QueryFlags => {
  const { axios } = useAxios();
  const { addToast } = useToasts();
  const queryClient = useQueryClient();
  const { getTemplateAPI, findTemplatesAPI, getTemplatesDatabaseAPI } =
    useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const { createTemplates, findTemplates } = useTemplateDB();
  const [loadingAPI, setLoadingAPI] = useState(false);
  const { isOnline } = useNetworkStatus();

  const doFindTemplates = () => {
    return doPromiseAPI<DocumentTemplates[]>(async () => {
      const templates: DocumentTemplates[] = (await findTemplates()) || [];
      if (!isOnline) {
        return templates;
      }
      const { data } = await axios.get<DocumentTemplates[]>(findTemplatesAPI());
      return data;
    });
  };

  const response = useQuery<DocumentTemplates[], AxiosError>(
    createQueryKey(findTemplatesQueryKey, { isOnline }),
    doFindTemplates,
    { enabled, networkMode: 'always' }
  );
  const { data, error, refetch } = response;

  useEffect(() => {
    if (enabled && !isOnline) {
      void refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, isOnline]);

  return {
    templates: data,
    error,
    isLoading: doGetIsLoading(response),
    loadingAPI,
    refetch: async () => {
      await refetch();
    },
    sync: async () => {
      try {
        setLoadingAPI(true);
        const { data: syncData } = await axios.get<TemplateDataAPI>(
          getTemplatesDatabaseAPI()
        );
        const templates = map(syncData.rows, (template) => ({
          templateId: toNumber(template.TEMPLATE_ID),
          creationUserId: template.CREATION_USERID,
          status: template.STATUS,
          templateName: template.TEMPLATE_NAME,
          templateVersion: toNumber(template.TEMPLATE_VER),
          creationTmstmp: template.CREATION_TMSTMP,
          templateType: template.TEMPLATE_TYPE,
          isDownloaded: true,
        }));
        await createTemplates(
          await Promise.all(
            map(templates, async (t) => {
              const { data: templateData } = await axios.get<DocumentTemplate>(
                getTemplateAPI(toString(t.templateId))
              );
              return { ...t, templateSections: templateData.templateSections };
            })
          )
        );
        void onSuccessMutation(queryClient, findTemplatesQueryKey);
      } catch (e) {
        addToast({
          type: ToastType.error,
          text: 'There was an error while downloading document templates',
          testid: 'sync-error-toast',
          duration: 0,
        });
        Sentry.captureException(e);
        throw e;
      } finally {
        setLoadingAPI(false);
      }
    },
  };
};

export default useFindTemplates;
