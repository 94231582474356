import React from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { useDevice } from 'providers/DeviceProvider';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import Text from 'components/Text/Text';
import classes from './AppVersion.module.scss';

interface AppVersionProps {
  setDevToolsCounter?: () => void;
}

const AppVersion = ({
  setDevToolsCounter,
}: AppVersionProps): JSX.Element | null => {
  const { appVersion } = useDevice();
  const { t } = useTranslation();
  const { buildInfoBranch } = useNetworkStatus();
  return !isEmpty(appVersion) ? (
    <div
      className={classes.appVersionCont}
      onClick={setDevToolsCounter}
      role="button"
      onKeyUp={() => {}}
      tabIndex={0}
    >
      <Text
        className={classes.appVersion}
        variant="label-micro"
        text={`${t('common:uiVersion')} ${appVersion}`}
        testid="app-version"
        textQuery={t('common:uiVersion')}
      />

      {buildInfoBranch && (
        <Text
          className={classes.appVersion}
          variant="label-micro"
          text={`${t('common:apiVersion')} ${buildInfoBranch}`}
          testid="app-version"
          textQuery={t('common:apiVersion')}
        />
      )}
    </div>
  ) : null;
};

export default AppVersion;
