import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import classnames from 'classnames';
import {
  concat,
  get,
  isNil,
  map,
  size,
  toLower,
  toNumber,
  toString,
} from 'lodash';
import type { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonFooter,
  IonImg,
  IonPage,
  IonRow,
  IonToolbar,
} from '@ionic/react';
import { namespaces } from 'i18n/i18n.constants';
import {
  salesPlaysURL,
  salesPlayProductURL,
  searchCustomerURL,
  searchURL,
} from 'navigation';
import { useTabReset } from 'providers/TabResetProvider';
import useGetProductDetail from 'api/customer/useGetProductDetailV2';
import useFindDataCodes from 'api/data/useFindDataCodes';
import useGetSalesPlay from 'api/salesPlays/useGetSalesPlay';
import useUpdateSalesPlay from 'api/salesPlays/useUpdateSalesPlay';
import useGoBack from 'hooks/useGoBack';
import type {
  SalesPlay,
  SalesPlayStatusButtons,
  SalesPlayTypesKeys,
} from 'models/SalesPlays';
import { SalesPlaysStatus } from 'models/SalesPlays';
import type { RootState } from 'store/reducers';
import { getAddress } from 'utils/address';
import { findIcon } from 'utils/icons';
import { concatRoutes } from 'utils/navigations';
import {
  getDescription,
  getFormattedValue,
  getSalesPlayRejectionReasons,
  iconAndColorFromType,
} from 'utils/salesPlays';
import { getCustomerShortName } from 'utils/search';
import noImage from 'assets/noImage.png';
import Button from 'components/Button/Button';
import CustomerName from 'components/Contacts/CustomerName/CustomerName';
import Header from 'components/Header/Header';
import IndustryCodes from 'components/IndustryCodes/IndustryCodes';
import Loader from 'components/Loader/Loader';
import RejectionModal from 'components/Modals/RejectionModal/RejectionModal';
import SalesPlayDetailHeadline from 'components/SalesPlayDetailHeadline/SalesPlayDetailHeadline';
import Text from 'components/Text/Text';
import classes from './SalesPlayDetail.module.scss';

interface SalesPlayStatusTextProps {
  salesPlay?: SalesPlay;
}
const SalesPlayStatusText = ({
  salesPlay,
}: SalesPlayStatusTextProps): JSX.Element | null => {
  const { t } = useTranslation(namespaces.salesPlays);

  if (isNil(salesPlay)) {
    return null;
  }

  const { active, status, noOppReason } = salesPlay;

  if (
    (active !== 'Y' && status !== SalesPlaysStatus.ARCHIVED) ||
    status === SalesPlaysStatus.ARCHIVED
  ) {
    const text =
      noOppReason === 'NOTACTIV' ? 'archiveNotActive' : 'archiveExpired';
    return (
      <IonRow
        className={classnames(classes.notActiveRow, classes.rowSeparator)}
      >
        <FontAwesomeIcon
          icon={findIcon('exclamation-circle', 'fas')}
          className={classnames(classes.notActive)}
        />
        <Text
          className={classnames(classes.notActiveText)}
          variant="content-small-heavy"
          text={t(text)}
          testid="sales-play-status-text"
        />
      </IonRow>
    );
  }
  return null;
};

const SalesPlayDetail = (): JSX.Element => {
  const history = useHistory();
  const { goBack } = useGoBack();
  const { triggerActivityAnimation } = useTabReset();
  const { userInfo } = useSelector((state: RootState) => state.user);
  const userId = get(userInfo, 'userid', '');
  const { t } = useTranslation(namespaces.salesPlays);

  const { externalSource, externalId } =
    useParams<Pick<SalesPlay, 'externalSource' | 'externalId'>>();
  const [iconName, setIconName] = useState<IconName>();
  const [iconColor, setIconColor] = useState<string | undefined>();
  const [img, setImage] = useState<string>(noImage);
  const [openRejectionModal, setOpenRejectionModal] = useState(false);

  const { data: salesPlay, isLoading: isLoadingSalesPlay } = useGetSalesPlay({
    externalSource,
    externalId,
  });
  const miLoc = toString(salesPlay?.miLoc);
  const custNo = toString(salesPlay?.customerNo);
  const customerName = toString(salesPlay?.customerName);
  const itemNo = toString(salesPlay?.itemNo);
  const mfgPartNo = toString(salesPlay?.mfgPartNo);
  const manufacturerName = toString(salesPlay?.manufacturerName);
  const itemDescription = toString(salesPlay?.itemDescription);
  const type = toString(salesPlay?.type);
  const readOnly = toString(salesPlay?.readOnly);

  const newSalesPlayActions = [
    {
      primary: true,
      text: 'common:acceptPlay',
      newStatus: SalesPlaysStatus.ACCEPTED,
      testId: 'accept-button',
      icon: findIcon('pennant', 'fas'),
    },
    {
      primary: false,
      text: 'common:decline',
      newStatus: SalesPlaysStatus.REJECTED,
      testId: 'reject-button',
      icon: findIcon('trash-alt', 'fas'),
    },
  ];
  const buttonConfig: SalesPlayStatusButtons = {
    status: SalesPlaysStatus.NEW,
    active: 'Y',
    buttons: newSalesPlayActions,
  };

  const variant = (primary: boolean) => (primary ? 'action' : 'secondary');

  const showFooter =
    salesPlay?.status === SalesPlaysStatus.NEW ||
    salesPlay?.status === SalesPlaysStatus.ACCEPTED ||
    salesPlay?.status === '';

  const { productData, customerData } = useGetProductDetail({
    miLoc,
    id: custNo,
    productId: itemNo,
  });

  const shortName = getCustomerShortName(customerData?.compressedName);

  const addressFull = getAddress(customerData?.address);

  const { data: dataCodes = [] } = useFindDataCodes({
    codeType: 'OPPRESN',
  });

  const rejectionCodes = useMemo(
    () => getSalesPlayRejectionReasons(dataCodes, type as SalesPlayTypesKeys),
    [dataCodes, type]
  );

  const { onUpdateSalesPlay } = useUpdateSalesPlay({
    externalSource,
    externalId,
  });

  const handleButtonClick = (
    status: SalesPlaysStatus,
    noOppReason = '',
    additionalRejectText = ''
  ) => {
    const prevStatus = salesPlay?.status;
    const createEventParam =
      status === SalesPlaysStatus.ACCEPTED
        ? {
            eventParams: {
              followUp: 'Y',
            },
            createEvent: true,
          }
        : {};
    goBack();
    let successToastText = '';
    if (status === SalesPlaysStatus.ACCEPTED) {
      successToastText = t('acceptedSuccessToast');
    } else {
      successToastText = t('rejectedSuccessToast');
    }
    onUpdateSalesPlay({
      status,
      assignedUserId: userId,
      noOppReason,
      additionalRejectText,
      ...createEventParam,
      successToastText,
      undoData: (data) => ({
        status: prevStatus,
        // DOC: userId because event was created for it
        assignedUserId: userId,
        noOppReason: '',
        additionalRejectText: '',
        removeEvent: !!data?.historyId,
        createEvent: false,
        historyId: data?.historyId,
      }),
    });
  };

  useEffect(() => {
    if (!isNil(salesPlay)) {
      const legendType = iconAndColorFromType(salesPlay.type);
      setIconName(legendType.icon);
      setIconColor(legendType.color);
    }
  }, [salesPlay, t]);

  const image = productData?.imageURL;

  useEffect(() => {
    if (image) {
      setImage(
        `${toString(process.env.REACT_APP_IMAGES_URL)}${toString(image)}`
      );
    }
  }, [image]);

  return (
    <IonPage
      data-testid="sales-plays-detail-page"
      className={classes.salesPlaysDetailPage}
    >
      <Header withBackButton testid="sales-plays-header" />

      <IonContent className={classes.content}>
        <SalesPlayDetailHeadline
          title={t('salesPlays:salesPlayDetailTitle')}
          testid="sales-play-detail-title"
          iconColor={iconColor}
          iconName={iconName}
          headerType={toString(type)}
        />
        {isLoadingSalesPlay && (
          <Loader
            testid="loader"
            text={t('common:loading')}
            className={classes.loader}
            isOpen={isLoadingSalesPlay}
          />
        )}
        {!isLoadingSalesPlay && (
          <>
            <SalesPlayStatusText salesPlay={salesPlay} />
            <div className={classes.section}>
              <CustomerName
                customerPick12={salesPlay?.customerPick12}
                customerName={customerName}
                textClassName={classes.companyName}
                iconClassName={classes.pick12Icon}
                onClick={() => {
                  history.push(
                    concatRoutes(searchURL(), searchCustomerURL(miLoc, custNo))
                  );
                }}
              />
              <IonRow className={classes.lastRow}>
                <Text
                  text={`${miLoc}${custNo}${shortName}`}
                  variant="mipro-h6-headline"
                  testid="customerlocNo"
                />
              </IonRow>
              <IonRow>
                <Text
                  text={addressFull}
                  testid="customerAddress"
                  className={classes.addressText}
                />
              </IonRow>
            </div>
            {salesPlay?.jsonSummary && (
              <div className={classes.section}>
                <Text
                  text={t('common:details')}
                  variant="title-action-card"
                  className={classnames(
                    classes.blockHeading,
                    classes.rowSeparator
                  )}
                  testid="sales-play-data-heading"
                />
                <div className={classes.salesPlay}>
                  {map(
                    salesPlay?.jsonSummary,
                    ({ _format, label, value, description }, key) => (
                      <IonRow key={`label-${key}`}>
                        <IonCol size="7" key={`col-1-${key}`}>
                          <Text
                            text={t(concat('salesPlays:', label))}
                            variant="content-small"
                            testid={`label-${key}`}
                          />
                        </IonCol>
                        <IonCol size="5" key={`col-2-${key}`}>
                          {getFormattedValue(
                            _format,
                            description || value,
                            toNumber(value) * 100 >= 0
                              ? classes.positiveText
                              : classes.negativeText,
                            salesPlay.currencyType
                          )}
                        </IonCol>
                      </IonRow>
                    )
                  )}
                </div>
              </div>
            )}
            <div className={classnames(classes.section, classes.blockHeading)}>
              <IonRow className={classes.rowSeparator}>
                {type !== 'UpSell' ? (
                  <Text
                    text={getDescription(t, salesPlay)}
                    variant="content-small"
                    testid={`${toLower(type)}-content`}
                  />
                ) : (
                  <div className={classes.cardContentTop}>
                    <Text
                      text={getDescription(t, salesPlay)}
                      variant="content-small"
                      testid={`${toLower(type)}-content`}
                    />
                  </div>
                )}
              </IonRow>
            </div>
            {!isNil(productData) && (
              <IonCard className={classes.card}>
                <IonCardContent className={classes.cardContent}>
                  <IonRow>
                    <Button
                      text={toString(mfgPartNo)}
                      variant="link"
                      rightIcon="chevron-right"
                      className={classes.productName}
                      testid="part-number"
                      onClick={() => {
                        history.push(
                          concatRoutes(
                            salesPlaysURL(),
                            salesPlayProductURL(miLoc, custNo, itemNo)
                          )
                        );
                      }}
                    />
                  </IonRow>
                  <IonRow>
                    <Text
                      text={toString(manufacturerName)}
                      variant="content-small-heavy"
                      testid="manufacturer-name"
                    />
                  </IonRow>
                  <IonRow className={classes.rowSeparator}>
                    <Text
                      text={itemDescription || 'Recommended Product'}
                      variant="label-header-micro"
                      testid="item-description-link"
                    />
                  </IonRow>
                  <IonRow
                    className={classnames(classes.imgRow, classes.rowSeparator)}
                  >
                    <Button
                      testid="item-thumbnail-link"
                      onClick={() => {
                        history.push(
                          concatRoutes(
                            salesPlaysURL(),
                            salesPlayProductURL(miLoc, custNo, itemNo)
                          )
                        );
                      }}
                    >
                      <IonImg
                        src={img}
                        className={classes.img}
                        onIonError={() => {
                          setImage(noImage);
                        }}
                      />
                    </Button>
                  </IonRow>
                </IonCardContent>
              </IonCard>
            )}
            <IndustryCodes
              className={classes.industryCodes}
              data={customerData}
            />
          </>
        )}
      </IonContent>
      {showFooter && (
        <IonFooter className={classes.footer}>
          <IonToolbar>
            <IonRow>
              {map(
                buttonConfig.buttons,
                (
                  { primary, text, newStatus, testId, noOppReason, icon },
                  idx
                ) => (
                  <Button
                    key={idx}
                    className={classnames(classes.acceptButton, {
                      [classes.declineButton]: !primary,
                    })}
                    variant={variant(primary)}
                    text={t(text)}
                    onClick={() => {
                      if (
                        SalesPlaysStatus.REJECTED === newStatus &&
                        size(rejectionCodes) > 0
                      ) {
                        setOpenRejectionModal(true);
                      } else {
                        triggerActivityAnimation();
                        handleButtonClick(newStatus, noOppReason);
                      }
                    }}
                    testid={testId}
                    rightIcon={icon}
                    disabled={readOnly === 'true'}
                  />
                )
              )}
            </IonRow>

            <RejectionModal
              reasons={rejectionCodes}
              onDone={(
                rejectionReason?: string,
                additionalRejectText?: string
              ) =>
                handleButtonClick(
                  SalesPlaysStatus.REJECTED,
                  rejectionReason,
                  additionalRejectText
                )
              }
              isOpen={openRejectionModal}
              setIsOpen={setOpenRejectionModal}
              onClose={() => setOpenRejectionModal(false)}
              eyebrow={toString(customerName)}
              title={t('rejectSalesPlay', {
                type: toString(type),
              })}
              testid="rejection-modal"
            />
          </IonToolbar>
        </IonFooter>
      )}
    </IonPage>
  );
};

export default SalesPlayDetail;
