import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { isNil, toString } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonRow } from '@ionic/react';
import type {
  BaseComponentProps,
  OptionalComponentProps,
} from 'common/components/utils/renderHelpers';
import { searchCustomerURL, searchSupplierURL, searchURL } from 'navigation';
import type { Customer } from 'models/Customer';
import type { Supplier } from 'models/Supplier';
import { concatRoutes } from 'utils/navigations';
import Badge from 'components/Badge/Badge';
import Button from 'components/Button/Button';
import Pick12Icon from 'components/Pick12Icon/Pick12Icon';
import Text from 'components/Text/Text';
import classes from './Header.module.scss';

interface CustomerNameProps extends BaseComponentProps {
  customerData?: Customer | Supplier;
  type?: string;
  showFavorite?: boolean;
  hidePick12?: boolean;
  customButton?: OptionalComponentProps<React.ComponentProps<typeof Button>>;
  showAccountOnHold?: boolean;
  isCorpAccount?: boolean;
  name?: string;
}

const CustomerName = ({
  className,
  customerData,
  showFavorite = false,
  hidePick12 = false,
  customButton,
  type = 'customer',
  showAccountOnHold = false,
  isCorpAccount = false,
  name: pName,
}: CustomerNameProps): JSX.Element => {
  let customerOrSupplierData = customerData;
  customerOrSupplierData ||= {} as Customer;
  const {
    miLoc = '',
    customerNo = '',
    compressedName,
    customerPick12,
    isAccountOnHold,
    isAccountOnLocalHold,
    corpAcctInfo,
  } = customerOrSupplierData as Customer;
  let { name = pName, isBookmarked } = customerOrSupplierData as Customer;
  let href = concatRoutes(searchURL(), searchCustomerURL(miLoc, customerNo));
  let miLocId = `${miLoc}${customerNo}${
    compressedName ? ` - ${compressedName}` : ''
  }`;

  if (type !== 'customer') {
    const data = customerOrSupplierData as Supplier;
    name = toString(data.supLocName);
    isBookmarked = data.bookmarked === 'Y';
    miLocId = data.supLocNo;
    href = concatRoutes(searchURL(), searchSupplierURL(miLoc, miLocId));
  }

  const { t } = useTranslation();

  return (
    <div className={className}>
      <IonRow className={classes.customerRow}>
        {showFavorite && isBookmarked && (
          <FontAwesomeIcon
            icon={['fas', 'star']}
            className={classes.favorite}
          />
        )}
        {!hidePick12 && customerPick12 && (
          <Pick12Icon className={classes.pick12Icon} />
        )}
        {(isCorpAccount || !isNil(corpAcctInfo)) && type === 'customer' && (
          <FontAwesomeIcon
            icon={['fas', 'globe']}
            className={classes.corpIcon}
          />
        )}
        <Button
          variant="link"
          href={href}
          testid="snaphot-link"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...customButton}
          className={classNames(classes.customerLink, customButton?.className)}
        >
          <Text
            className={classes.customerName}
            text={name}
            variant="mipro-h2-headline"
          />

          {showAccountOnHold && (isAccountOnHold || isAccountOnLocalHold) && (
            <Badge
              type="error"
              icon="exclamation"
              text={t('snapshot:onHold')}
              testid="on-hold"
              className={classes.badge}
            />
          )}
        </Button>
      </IonRow>
      <IonRow>
        <Text
          className={classes.customerEyebrow}
          variant="mipro-h6-headline"
          text={miLocId}
        />
      </IonRow>
    </div>
  );
};

export default CustomerName;
