import { useEffect } from 'react';

export const scrollToFieldError = (
  parentTestId = '',
  options: ScrollIntoViewOptions = { behavior: 'smooth', block: 'start' }
) => {
  const el = document.querySelector<HTMLElement>(
    `div.ion-page:not(.ion-page-hidden):not([data-testid="main-tab-bar"]) ${
      parentTestId ? `[data-testid="${parentTestId}"]` : ''
    } [data-mipro-error="true"]`
  );
  el?.scrollIntoView(options);
  if (el?.dataset?.miproFocusable === 'true') {
    el.querySelector('input')?.focus();
  }
};

// eslint-disable-next-line import/prefer-default-export
export const useScrollIntoFieldError = (
  parentTestId = '',
  isSubmitting = false,
  options: ScrollIntoViewOptions = {
    behavior: 'smooth',
    block: 'start',
  }
) => {
  useEffect(() => {
    setTimeout(() => {
      if (isSubmitting) {
        scrollToFieldError(parentTestId, options);
      }
    });

    // TODO we should clear timeout, but this is not working... should be interval like onKeypadScroll
    // return () => {
    //   clearTimeout(timeout);
    // };
  }, [parentTestId, isSubmitting, options]);
};
