import type { SQLiteDBConnection } from 'react-sqlite-hook';
import { map, size } from 'lodash';
import type { Report } from 'DocumentsApp/models/Report';

// eslint-disable-next-line import/prefer-default-export
export const createDocuments = async (
  db: SQLiteDBConnection,
  reports: Report[]
) => {
  const statements = map(reports, (report) => ({
    statement: `INSERT OR REPLACE INTO report (
      reportId,
      templateId,
      name,
      status,
      startDate,
      endDate,
      miLoc,
      miLocText,
      shopLoc,
      orderCtlNo,
      woCtlNo,
      customerNo,
      customerName,
      orderLineNo,
      machineId,
      siteId,
      templateVersion,
      lastSyncDate,
      reportType,
      custMachineId,
      machineText,
      siteText,
      customerContact,
      customerContactPhone,
      creationUserId,
      creationUserName,
      creationTmstmp,
      lastUpdUserId,
      lastUpdUserName,
      lastUpdTmstmp,
      documentJson,
      needSync
      ) VALUES (?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?)`,
    values: [
      report.reportId,
      report.templateId,
      report.name,
      report.status,
      report.startDate,
      report.endDate,
      report.miLoc,
      report.miLocText,
      report.shopLoc,
      report.orderCtlNo,
      report.woCtlNo,
      report.customerNo,
      report.customerName,
      report.orderLineNo,
      report.machineId,
      report.siteId,
      report.templateVersion,
      report.lastSyncDate,
      report.reportType,
      report.custMachineId,
      report.machineText,
      report.siteText,
      report.customerContact,
      report.customerContactPhone,
      report.creationUserId,
      report.creationUserName,
      report.creationTmstmp,
      report.lastUpdUserId,
      report.lastUpdUserName,
      report.lastUpdTmstmp,
      JSON.stringify({
        reportValues: report.reportValues || [],
        addedValues: report.addedValues || [],
        partsList: report.partsList || [],
        partsAdded: report.partsAdded || [],
        partsRemoved: report.partsRemoved || [],
        vasCodesList: report.vasCodesList || [],
        vasCodesAdded: report.vasCodesAdded || [],
        vasCodesRemoved: report.vasCodesRemoved || [],
        genericSectionsList: report.genericSectionsList || [],
        genericSectionsAdded: report.genericSectionsAdded || [],
        genericSectionsRemoved: report.genericSectionsRemoved || [],
        reportImages: map(report.reportImages, (img) => ({
          ...img,
          image: undefined,
        })),
        addedImages: report.addedImages || [],
        removedImages: report.removedImages || [],
      }),
      report.needSync,
    ],
  }));
  if (size(statements) > 0) {
    await db.executeSet(statements, true);
  }
};
