import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import type { Dictionary } from 'lodash';
import {
  filter,
  find,
  get,
  includes,
  isEmpty,
  last,
  map,
  reduce,
  size,
  toNumber,
  toString,
  values,
} from 'lodash';
import { IonCol, IonRow } from '@ionic/react';
import { documentListConfig } from 'DocumentsApp/api/useUpdateValues';
import {
  GENERIC_TYPE,
  type GroupInput,
  type TemplateImage,
} from 'models/DocumentTemplate';
import type {
  ReportRow,
  ReportRowsValues,
  ReportJson,
  ReportValue,
} from 'models/InspectionReport';
import { findIcon } from 'utils/icons';
import Button from 'components/Button/Button';
import DocumentHeader from 'components/Documents/DocumentHeader/DocumentHeader';
import type DocumentImage from 'components/Documents/DocumentImage/DocumentImage';
import DocumentInput from 'components/Documents/DocumentInput/DocumentInput';
import Loader from 'components/Loader/Loader';
import ConfirmDialog from 'components/Modals/ConfirmDialog/ConfirmDialog';
import Text from 'components/Text/Text';
import TitleLine from 'components/TitleLine/TitleLine';
import classes from './DocumentRecursiveContainer.module.scss';

export const hasListData = (listData: unknown) =>
  reduce(
    values(listData),
    (prev, attribute) => {
      if (get(attribute, 'hidden')) {
        return prev;
      }
      const attrValue = toString(get(attribute, 'value'));
      return (
        prev ||
        (!isEmpty(attrValue) &&
          attrValue !== toString(get(attribute, 'defaultValue')))
      );
    },
    false
  );

interface ExpandableRowProp {
  isExpanded?: boolean;
  isLoading?: boolean;
  setIsExpanded?: () => void;
  onInit?: () => void;
  children: React.ReactNode;
}

const ExpandableRow = ({
  isExpanded,
  isLoading,
  setIsExpanded,
  onInit,
  children,
}: ExpandableRowProp): JSX.Element => {
  useEffect(() => {
    onInit?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IonRow
      className={classNames(classes.genericName, {
        [classes.expanded]: isExpanded,
        [classes.genericLoading]: isLoading,
      })}
      role="button"
      tabIndex={0}
      onClick={setIsExpanded}
      onKeyPress={() => {}}
    >
      {children}
    </IonRow>
  );
};

export interface DocumentRecursiveContainerProps {
  reportId: number;
  groupId: number;
  fields: GroupInput[];
  inputId: number;
  title: string;
  type: string;
  containerValues?: ReportValue[];
  needSyncValues?: number[];
  setHasData: (hasData: boolean) => void;
  onFormUpdate: (formData: Record<number, ReportValue>) => void;
  custNo: string;
  miLoc: string;
  disabled?: boolean;
  multiple?: boolean;
  listType?: string;
  templateImages?: TemplateImage[];
  reportListData?: Dictionary<object>;
  needSyncListData?: Dictionary<number[]>;
  updateListValue?: (
    itemIndex: number,
    attributeName: string,
    value: string,
    defaultValue?: string,
    isCalc?: boolean
  ) => void;
  addNewToList?: () => void;
  deleteFromList?: (itemIndex: number) => void;
  isLoading?: boolean;
  disabledView?: boolean;
  isExpanded?: boolean;
  onExpand?: (index?: string) => void;
  subSectionToFlip?: string;
  subSectionExpanded?: Dictionary<boolean>;
  setSubSectionExpanded?: React.Dispatch<
    React.SetStateAction<Dictionary<boolean>>
  >;
  subSectionIsEdit?: Dictionary<boolean>;
  setSubSectionIsEdit?: React.Dispatch<
    React.SetStateAction<Dictionary<boolean>>
  >;
}

const DocumentRecursiveContainer = ({
  reportId,
  fields,
  inputId,
  groupId,
  multiple = false,
  listType = '',
  title,
  type,
  containerValues,
  needSyncValues,
  setHasData,
  onFormUpdate,
  custNo,
  miLoc,
  disabled,
  templateImages = [],
  reportListData,
  needSyncListData,
  updateListValue,
  addNewToList,
  deleteFromList,
  isLoading: parentLoading,
  disabledView,
  isExpanded: parentExpanded,
  onExpand,
  subSectionToFlip,
  subSectionExpanded,
  setSubSectionExpanded,
  subSectionIsEdit,
  setSubSectionIsEdit,
  ...imgProps
}: DocumentRecursiveContainerProps &
  React.ComponentProps<typeof DocumentImage>): JSX.Element => {
  const [triggerInputSelect, setTriggerInputSelect] = useState<
    Dictionary<number>
  >({});
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteId, setDeleteId] = useState<number>();

  const initialValues = () => {
    const data: Record<number, ReportValue> = {};
    const reportValue: ReportValue = {
      reportId,
      groupId,
      inputId,
      inputValue: '',
    };
    if (containerValues) {
      map(
        containerValues.filter(
          (specificContainerValue) => inputId === specificContainerValue.inputId
        ),
        (specificReportValue: ReportValue) => {
          const reportRowsValues: ReportRowsValues = {
            rows: [],
          };
          map(specificReportValue.inputJson?.rows, (reportRow: ReportRow) => {
            let foundData = false;
            const specificReportRow: ReportRow = {
              row: [],
            };
            map(fields, (groupInput: GroupInput) => {
              const rptJson: ReportJson = {
                name: groupInput.name || '',
                value: '',
                values: [],
                rows: [],
              };
              const valueWithTemplateObject: ReportJson | undefined =
                reportRow.row.find((rowTmp) => {
                  return groupInput.name === rowTmp.name;
                });
              if (valueWithTemplateObject) {
                rptJson.values = valueWithTemplateObject.values;
                rptJson.value = valueWithTemplateObject.value;
                rptJson.rows = valueWithTemplateObject.rows;
                if (
                  valueWithTemplateObject.value &&
                  valueWithTemplateObject.value.length > 0
                ) {
                  foundData = true;
                }
                if (
                  valueWithTemplateObject.values &&
                  valueWithTemplateObject.values.length > 0
                ) {
                  foundData = true;
                }
                if (
                  valueWithTemplateObject.rows &&
                  valueWithTemplateObject.rows.length > 0
                ) {
                  foundData = true;
                }
              }
              specificReportRow.row.push(rptJson);
            });
            if (foundData) {
              reportRowsValues.rows.push(specificReportRow);
            }
          });
          reportValue.inputJson = reportRowsValues;
        }
      );
    }
    if (!reportValue.inputJson) {
      reportValue.inputJson = {
        rows: [],
      };
    }
    const rowsToAdd: ReportRow = {
      row: [],
    };
    if (multiple || reportValue.inputJson.rows.length === 0) {
      map(fields, (value: GroupInput) => {
        const rptJson: ReportJson = {
          name: value.name || '',
          value: '',
          values: [],
          rows: [],
        };
        rowsToAdd.row.push(rptJson);
      });
      reportValue.inputJson.rows.push(rowsToAdd);
    }
    data[0] = reportValue;
    return data;
  };

  const addNewContainer = (
    index: number,
    formData: Record<number, ReportValue>
  ) => {
    const row2: ReportRow = {
      row: [],
    };
    map(fields, (groupInput: GroupInput) => {
      const rptJson: ReportJson = {
        name: groupInput.name || '',
        value: '',
        values: [],
        rows: [],
      };
      row2.row.push(rptJson);
    });
    if (formData) {
      formData[index]?.inputJson?.rows.push(row2);
    }
    onFormUpdate(formData);
  };

  const [formData] = useState(initialValues);

  const getFormValue = (name: string, row: number, listData?: unknown) => {
    if (listType) {
      return {
        name,
        value: toString(get(listData, `${name}.value`)),
        values: [],
      };
    }
    return (
      formData[0].inputJson?.rows[row].row.find(
        (individualRow) => individualRow.name === name
      ) || {
        name: '',
        value: '',
        values: [],
        rows: [],
      }
    );
  };

  const setFormValueFromSubContainer = (
    value: ReportJson | string,
    name: string,
    row: number,
    listData?: unknown,
    defaultValue?: string,
    isCalc?: boolean
  ) => {
    if (listType) {
      updateListValue?.(
        toNumber(get(listData, 'itemIndex.value')),
        name,
        toString(value),
        toString(defaultValue),
        isCalc
      );
      setHasData(hasListData(listData));
      return;
    }
    const rowToUpdate = formData[0].inputJson?.rows[row].row.find(
      (specificRow) => specificRow.name === name
    ) || {
      name,
      value: '',
      values: [],
      rows: [],
    };
    rowToUpdate.rows = get(value, 'rows') as ReportRow[];
    setHasData(true);
    onFormUpdate(formData);
  };

  const setFormValue = (
    value: string,
    name: string,
    row: number,
    formValues?: string[],
    listData?: unknown
  ) => {
    if (listType) {
      updateListValue?.(
        toNumber(get(listData, 'itemIndex.value')),
        name,
        value
      );
      setHasData(hasListData(listData));
      return;
    }
    const rowToUpdate = formData[0].inputJson?.rows[row].row.find(
      (specificRow) => specificRow.name === name
    ) || {
      name: '',
      value: '',
      values: [],
      rows: [],
    };
    rowToUpdate.value = value;
    if (formValues) {
      rowToUpdate.values = formValues;
    }
    setHasData(true);
    onFormUpdate(formData);
  };

  const getImValues = (listData?: unknown) => {
    return filter(
      imgProps.imageValues,
      (i) =>
        toString(get(listData, 'itemId.value')) === `${toNumber(i.objectId)}`
    );
  };

  const getImagesForUpload = (listData?: unknown) => {
    return filter(
      imgProps.imagesForUpload,
      (i) =>
        toString(get(listData, 'itemId.value')) === `${toNumber(i.objectId)}`
    );
  };

  const items = listType
    ? values(reportListData?.[listType])
    : (get(formData, `0.inputJson.rows`) as ReportRowsValues[]);

  let disableAddList = listType ? !hasListData(last(items)) : false;
  if (listType && size(items) === 0) {
    disableAddList = false;
  }

  return (
    <div>
      {map(items, (_rowData: ReportRowsValues, ndx: number) => {
        const itemId = toString(get(_rowData, 'itemId.value'));
        const sectionExpanded =
          parentExpanded &&
          (subSectionExpanded?.[itemId] || subSectionToFlip === itemId);
        const isLoading = parentLoading && sectionExpanded;
        return (
          <div
            className={classNames(classes.documentContainer, {
              [classes.genericContainer]: type === GENERIC_TYPE,
              [classes.genericLoading]: type === GENERIC_TYPE && isLoading,
            })}
            data-testid="DocumentContainer"
            key={`${ndx}-${itemId}`}
          >
            {title && type !== GENERIC_TYPE && (
              <IonRow
                className={classNames(classes.documentRow, classes.titleRow)}
              >
                <IonCol
                  className={classNames(
                    classes.containerTitle,
                    classes.documentCol
                  )}
                >
                  <IonRow>
                    <div style={{ flex: 1 }}>
                      {multiple && (
                        <Text
                          text={`${title} ${ndx + 1}`}
                          variant="content-default"
                        />
                      )}
                      {!multiple && (
                        <Text text={title} variant="content-default" />
                      )}
                      {includes(
                        needSyncListData?.[listType] as number[],
                        toNumber(
                          get(
                            _rowData,
                            `${toString(
                              get(documentListConfig, `${listType}.id`)
                            )}.value`
                          )
                        )
                      ) && (
                        <Text
                          className={classes.needSyncBadge}
                          variant="label-micro"
                          text="Pending"
                        />
                      )}
                    </div>
                    {listType && (
                      <Button
                        icon={findIcon('times')}
                        onClick={() => {
                          deleteFromList?.(
                            toNumber(get(_rowData, 'itemIndex.value'))
                          );
                        }}
                        testid="remove-item-button"
                      />
                    )}
                  </IonRow>
                </IonCol>
              </IonRow>
            )}
            <div className={classes.inuptsWrap}>
              <div>
                {map(fields, (groupInput, rowNdx) => {
                  const sectionName = getFormValue(
                    groupInput.name,
                    ndx,
                    _rowData
                  )?.value;
                  const doExpand = (v: boolean) => {
                    if (!disabledView) {
                      setSubSectionExpanded?.((prev) => ({
                        ...reduce(
                          Object.keys(prev),
                          (result, key) => ({ ...result, [key]: false }),
                          {}
                        ),
                        [itemId]: v,
                      }));
                      onExpand?.(v ? itemId : undefined);
                    }
                  };
                  const fieldInput = (
                    <DocumentInput
                      key={rowNdx}
                      setRecValue={(value, name, row, defaultValue, isCalc) => {
                        setFormValueFromSubContainer(
                          value,
                          name,
                          row,
                          _rowData,
                          defaultValue,
                          isCalc
                        );
                      }}
                      inputId={inputId}
                      groupId={groupId}
                      disabled={disabled}
                      containerValues={containerValues}
                      reportId={reportId}
                      inputName={groupInput.name}
                      groupInput={groupInput}
                      onFormUpdate={onFormUpdate}
                      setHasData={setHasData}
                      value={toString(
                        getFormValue(groupInput.name, ndx, _rowData)?.value
                      )}
                      rowData={ndx}
                      values={
                        getFormValue(groupInput.name, ndx, _rowData)?.values ||
                        []
                      }
                      setValue={(e: string, listValues?: string[]) => {
                        setFormValue(
                          e,
                          groupInput.name,
                          ndx,
                          listValues,
                          _rowData
                        );
                      }}
                      custNo={custNo}
                      miLoc={miLoc}
                      needSync={includes(needSyncValues, groupInput.inputId)}
                      templateImage={
                        groupInput.type === 'image'
                          ? find(
                              templateImages,
                              (item) =>
                                item.imageName === groupInput.defaultValue
                            )
                          : undefined
                      }
                      listType={listType}
                      itemListData={_rowData as object}
                      noPadding={!title}
                      onFocus={() => {
                        if (!sectionName && !sectionExpanded) {
                          doExpand(true);
                        }
                      }}
                      onBlur={() => {
                        setTimeout(
                          () =>
                            setSubSectionIsEdit?.((prev) => ({
                              ...prev,
                              [itemId]: false,
                            })),
                          300
                        );
                      }}
                      triggerInputSelect={triggerInputSelect[itemId]}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...imgProps}
                      imageValues={getImValues(_rowData)}
                      imagesForUpload={getImagesForUpload(_rowData)}
                    />
                  );
                  const showInput = !sectionName || subSectionIsEdit?.[itemId];
                  if (type === GENERIC_TYPE && rowNdx === 0) {
                    return (
                      <ExpandableRow
                        key={rowNdx}
                        isExpanded={sectionExpanded}
                        isLoading={isLoading}
                        onInit={() => {
                          if (!sectionName) {
                            setSubSectionIsEdit?.((prev) => ({
                              ...prev,
                              [itemId]: true,
                            }));
                            doExpand(true);
                          }
                        }}
                        setIsExpanded={() => doExpand(!sectionExpanded)}
                      >
                        <IonCol>
                          {showInput ? (
                            <>
                              {fieldInput}
                              <TitleLine />
                            </>
                          ) : (
                            <div className={classes.genericTitle}>
                              <DocumentHeader titleText={sectionName} />
                            </div>
                          )}
                        </IonCol>
                        <IonCol
                          className={classes.genericColumn}
                          size="auto"
                          onClick={(e) => e.stopPropagation()}
                        >
                          {sectionExpanded && isLoading && (
                            <span className={classes.sectionLoaderWrap}>
                              <Loader isOpen={isLoading} />
                            </span>
                          )}
                          {!isLoading && listType && (
                            <>
                              {sectionExpanded && !showInput && (
                                <Button
                                  className={classes.editSection}
                                  icon={findIcon('pencil', 'far')}
                                  variant="link"
                                  disabled={disabled}
                                  onClick={() => {
                                    setSubSectionIsEdit?.((prev) => ({
                                      ...prev,
                                      [itemId]: true,
                                    }));
                                    if (!sectionExpanded) {
                                      doExpand(true);
                                    }
                                    setTimeout(
                                      () =>
                                        setTriggerInputSelect((prev) => ({
                                          ...prev,
                                          [itemId]: Date.now(),
                                        })),
                                      0
                                    );
                                  }}
                                  testid="edit-item-button"
                                />
                              )}
                              <Button
                                className={classes.removeSection}
                                icon={findIcon('times')}
                                disabled={disabled}
                                variant="link"
                                onClick={() => {
                                  const deleteItemId = toNumber(
                                    get(_rowData, 'itemIndex.value')
                                  );
                                  if (!sectionName) {
                                    deleteFromList?.(deleteItemId);
                                    return;
                                  }
                                  setDeleteId(deleteItemId);
                                  setConfirmDelete(true);
                                }}
                                testid="remove-item-button"
                              />
                            </>
                          )}
                        </IonCol>
                      </ExpandableRow>
                    );
                  }
                  return (
                    (sectionExpanded || type !== GENERIC_TYPE) && fieldInput
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
      <ConfirmDialog
        isOpen={confirmDelete}
        setIsOpen={setConfirmDelete}
        title="Remove Section"
        text="Are you sure you want to remove this section?"
        primaryText="Yes"
        secondaryText="No"
        onPrimaryClick={() => {
          deleteFromList?.(toNumber(deleteId));
        }}
        testid="delete-section-confirm-modal"
      />
      {multiple && (
        <IonRow
          className={classNames(classes.resetRow, {
            [classes.genericAddSection]: type === GENERIC_TYPE,
          })}
        >
          <IonCol size="auto">
            <Button
              className={classes.button}
              variant="action"
              testid="Add-Button"
              disabled={disabled || disableAddList}
              text={title ? `Add ${title}` : 'Add'}
              onClick={() => {
                if (listType) {
                  addNewToList?.();
                  return;
                }
                addNewContainer(0, formData);
              }}
            />
          </IonCol>
        </IonRow>
      )}
    </div>
  );
};

export default DocumentRecursiveContainer;
