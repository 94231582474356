import type { AxiosError } from 'axios';
import { isNil } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { doPromiseAPI, useKeyUserId } from 'api/helpers';
import type { AccountsReceivable, Customer } from 'models/Customer';
import type { QueryFlags } from 'models/Search';
import useGetCustomer from './useGetCustomer';

const getAccountsReceivableQueryKey = 'customer-accounts-receivable';

interface UseGetAccountsReceivableProps {
  miLoc: string;
  id: string;
}

interface UseGetAccountsReceivableResponse {
  accountsReceivable?: AccountsReceivable;
  customerData?: Customer;
}

const useGetAccountsReceivable = ({
  miLoc,
  id,
}: UseGetAccountsReceivableProps): UseGetAccountsReceivableResponse &
  QueryFlags => {
  const { axios } = useAxios();
  const { getAccountsReceivableAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();

  const { data: customerData } = useGetCustomer({
    searchType: 'customer',
    miLoc,
    id,
  });

  const doGetAccountsReceivable = () => {
    return doPromiseAPI<AccountsReceivable>(async (cancelToken) => {
      const { data } = await axios.get<AccountsReceivable>(
        getAccountsReceivableAPI(miLoc, id),
        { cancelToken }
      );
      return data;
    });
  };

  const {
    data: accountsReceivable,
    error,
    status,
    isFetching,
  } = useQuery<AccountsReceivable, AxiosError>(
    createQueryKey(getAccountsReceivableQueryKey, { miLoc, id }),
    doGetAccountsReceivable
  );

  return {
    error,
    accountsReceivable,
    customerData,
    isLoading:
      status === 'loading' || (isFetching && isNil(accountsReceivable)),
  };
};

export default useGetAccountsReceivable;
