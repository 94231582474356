import { useState } from 'react';
import { filter, find, head, map, size, toNumber, toString } from 'lodash';
import type { MutationStatus } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToasts } from 'providers/ToastProvider';
import useItemPOUDB from 'StoreroomsApp/database/useItemPOUDB';
import useReplenishmentDB from 'StoreroomsApp/database/useReplenishmentDB';
import type { ItemPOU } from 'StoreroomsApp/models/ItemPOU';
import type {
  ItemReplenishment,
  ItemReplenishmentDTO,
} from 'StoreroomsApp/models/Replenishment';
import type { UpdateMutationContext } from 'api/helpers';
import {
  onSuccessMutation,
  doPromiseAPI,
  onMutateUpdate,
  onErrorUpdate,
  useMiLocOrTeamId,
  useKeyUserId,
} from 'api/helpers';
import { ToastType } from 'models/Toast';
import useFindBarcodeItems from './useFindBarcodeItems';
import { findItemsPOUQueryKey } from './useFindItemsPOU';
import useFindReplenishmentItems, {
  findReplenishmentItemsQueryKey,
} from './useFindReplenishmentItems';

interface AddItemsReplenishmentBody {
  barcode?: string;
  items?: ItemReplenishment[];
  storeroomNumber?: string;
  hideSuccessToast?: boolean;
}

interface UseAddReplenishmentResponse {
  status: MutationStatus;
  isLoading?: boolean;
  onAddItemsReplenishment: (body: AddItemsReplenishmentBody) => void;
}

const useAddReplenishment = (): UseAddReplenishmentResponse => {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const { addItemsToReplenishment, getOpenReplenishment } =
    useReplenishmentDB();
  const { findItemsPOUByStoreroom } = useItemPOUDB();
  const { userId } = useKeyUserId();
  const { createParams } = useMiLocOrTeamId({ sendTeamId: false });
  const [isLoading, setIsLoading] = useState(false);
  const { miLoc } = createParams();

  const { replenishmentItems } = useFindReplenishmentItems({});
  const { findBarcodeItems } = useFindBarcodeItems();

  const getBarcodeItems = async (
    barcode: string,
    showToast = true,
    doOnlineSearch = true
  ) => {
    const barcodeItems = await findBarcodeItems({
      barcode,
      showToast,
      doOnlineSearch,
    });
    return map(
      size(barcodeItems) > 0 ? [head(barcodeItems) as ItemPOU] : [],
      (item) => ({
        ...item,
        balanceOnHandQuantity: item.fixedOrderQuantity,
        orderQuantity: toNumber(item.fixedOrderQuantity),
        itemId: item.combinedId,
        itemName: `${item.barcodeValue} ${item.itemDescription}`,
      })
    );
  };

  const getStoreroomItems = async (storeroomNumber: string) => {
    const storeroomItems = await findItemsPOUByStoreroom({
      miLoc,
      storeroomNumber,
    });
    return map(storeroomItems, (item) => ({
      ...item,
      balanceOnHandQuantity: item.fixedOrderQuantity,
      orderQuantity: toNumber(item.fixedOrderQuantity),
      itemId: item.combinedId,
      itemName: `${item.barcodeValue} ${item.itemDescription}`,
    }));
  };

  const filterItemsAlreadyInReplenishment = (
    items: ItemReplenishment[],
    showToast = true
  ) =>
    filter(items, ({ itemName = '', itemId }) => {
      const itemFound = find(
        replenishmentItems,
        (rItem) => rItem.itemId === itemId
      );
      if (itemFound) {
        if (showToast) {
          addToast({
            text: `The item "${itemName}" is already in the replenishment.`,
            type: ToastType.warn,
            testid: `feedback-warning-toast-${itemId}`,
          });
        }
        return false;
      }
      return true;
    });

  const doAddItemsReplenishment = ({
    storeroomNumber,
    barcode,
    items,
  }: AddItemsReplenishmentBody) => {
    return doPromiseAPI<ItemReplenishment[]>(async () => {
      setIsLoading(true);
      let newItems = items || [];
      if (storeroomNumber) {
        newItems = await getStoreroomItems(storeroomNumber);
      }
      if (barcode) {
        newItems = await getBarcodeItems(barcode);
      }
      newItems = filterItemsAlreadyInReplenishment(newItems);
      const openReplenishment = await getOpenReplenishment({ miLoc, userId });
      await addItemsToReplenishment(
        map(newItems, (i) => ({
          ...i,
          replenishmentId: toNumber(openReplenishment.id),
        }))
      );
      return newItems;
    });
  };

  interface AddItemsReplenishmentContext {
    replenishmentContext: UpdateMutationContext<ItemReplenishmentDTO>[];
    itemsPOUContext: UpdateMutationContext<ItemPOU>[];
  }

  const { status, mutate } = useMutation(doAddItemsReplenishment, {
    networkMode: 'always',
    onMutate: async (vars) => {
      let replenishmentContext: UpdateMutationContext<ItemReplenishmentDTO>[] =
        [];
      if (vars.storeroomNumber) {
        const storeroomItems = await getStoreroomItems(vars.storeroomNumber);
        replenishmentContext = await onMutateUpdate<ItemReplenishmentDTO>({
          queryClient,
          queryKey: findReplenishmentItemsQueryKey,
          newItems: map(
            filterItemsAlreadyInReplenishment(storeroomItems, false),
            (item) => ({ ...item } as ItemReplenishmentDTO)
          ),
          isArrayQuery: true,
        });
      }
      if (vars.barcode) {
        const barcodeItems = await getBarcodeItems(vars.barcode, false, false);
        replenishmentContext = await onMutateUpdate<ItemReplenishmentDTO>({
          queryClient,
          queryKey: findReplenishmentItemsQueryKey,
          newItems: map(
            filterItemsAlreadyInReplenishment(barcodeItems, false),
            (item) => ({ ...item } as ItemReplenishmentDTO)
          ),
          isArrayQuery: true,
        });
      }
      const itemsPOUContext = await onMutateUpdate<ItemPOU>({
        queryClient,
        queryKey: findItemsPOUQueryKey,
        updatedItems: map(
          vars.items,
          ({ itemId }) =>
            ({ combinedId: itemId, alreadyAddedToReplenishment: 1 } as ItemPOU)
        ),
        findPredicates: map(vars.items, ({ itemId }) => ({
          combinedId: itemId,
        })),
        isInfiniteQuery: true,
      });
      return { replenishmentContext, itemsPOUContext };
    },
    onSuccess: async (data, vars) => {
      await onSuccessMutation(queryClient, findReplenishmentItemsQueryKey);
      void onSuccessMutation(queryClient, findItemsPOUQueryKey);
      if (!vars.hideSuccessToast) {
        map(data, ({ itemName, itemId }) => {
          addToast({
            text: `The item "${toString(itemName)}" has been added.`,
            testid: `add-item-toast-${toString(itemId)}`,
          });
        });
      }
      if (vars.storeroomNumber) {
        addToast({
          text: `All items from storeroom (${size(data)}) have been added.`,
          testid: `add-all-items-toast`,
        });
      }
      setIsLoading(false);
    },
    onError: (vars, error, context) => {
      const { replenishmentContext, itemsPOUContext } =
        context as AddItemsReplenishmentContext;
      addToast({
        type: ToastType.error,
        text: 'There was an error adding item to replenishment',
        testid: 'add-replenishment-item-error-toast',
      });
      onErrorUpdate<ItemReplenishmentDTO>({
        queryClient,
        context: replenishmentContext,
        isArrayQuery: true,
      });
      onErrorUpdate<ItemPOU>({
        queryClient,
        context: itemsPOUContext,
        isInfiniteQuery: true,
      });
      setIsLoading(false);
    },
  });

  return {
    status,
    isLoading,
    onAddItemsReplenishment: (body: AddItemsReplenishmentBody) => mutate(body),
  };
};

export default useAddReplenishment;
