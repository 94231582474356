import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { toString } from 'lodash';
import type {
  IonSegmentCustomEvent,
  SegmentChangeEventDetail,
} from '@ionic/core';
import { IonSegment, IonSegmentButton } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import type { BaseComponentProps } from 'common/components/utils/renderHelpers';
import { choose } from 'common/utils/logicHelpers';
import { namespaces } from 'i18n/i18n.constants';
import type { DateSegmentType } from 'models/Reports';
import { DateFormatEnum, formatDate } from 'utils/date';
import Button from 'components/Button/Button';
import classes from './DateToolbarButtons.module.scss';

interface DateToolbarButtonsProps extends BaseComponentProps {
  hideCustomDateSelection?: boolean;
  prevButtonDisabled?: boolean;
  nextButtonDisabled?: boolean;
  onPrev?: (isDaily: boolean) => void;
  onDateClick?: () => void;
  onNext?: (isDaily: boolean) => void;
  date: Date;
  requestType: DateSegmentType;
  showSegmentedButtons?: boolean;
  onSegmentChange?: (
    e: IonSegmentCustomEvent<SegmentChangeEventDetail>
  ) => void | undefined;
}

const DateToolbarButtons = ({
  requestType,
  testid,
  className,
  hideCustomDateSelection,
  prevButtonDisabled,
  nextButtonDisabled,
  onPrev,
  onDateClick,
  onNext,
  date,
  onSegmentChange,
  showSegmentedButtons = false,
}: DateToolbarButtonsProps & IonicReactProps): JSX.Element => {
  const isDaily = requestType === 'DAILY';
  const isQuarterly = requestType === 'QTD';
  const [dateFormat, setDateFormat] = useState<string>(
    isDaily ? DateFormatEnum.fullDate : DateFormatEnum.monthPicker
  );
  const { t, i18n } = useTranslation(namespaces.calendar);

  return (
    <div
      className={classNames(classes.dateToolbar, className)}
      data-testid={`DateToolbar-${toString(testid)}`}
    >
      <div
        className={classNames(classes.dateNav, {
          [classes.eomNav]: hideCustomDateSelection,
          [classes.fullFlex]: !showSegmentedButtons,
        })}
        data-testid={`DateNav-${toString(testid)}`}
      >
        {!hideCustomDateSelection && (
          <Button
            icon="caret-left"
            className={classNames(classes.dateArrows, {
              [classes.disabled]: prevButtonDisabled,
            })}
            disabled={prevButtonDisabled}
            testid={`reports-date-prev-button-${toString(testid)}`}
            onClick={() => onPrev?.(isDaily)}
          />
        )}
        <Button
          variant="clear"
          className={classes.dateText}
          testid={`reports-date-button-${toString(testid)}`}
          onClick={onDateClick}
          text={formatDate(
            date,
            choose(isQuarterly, DateFormatEnum.quarterMonth, dateFormat),
            i18n.language
          )}
        />
        {!hideCustomDateSelection && (
          <Button
            icon="caret-right"
            className={classNames(classes.dateArrows, {
              [classes.disabled]: nextButtonDisabled,
            })}
            disabled={nextButtonDisabled}
            testid={`reports-date-next-button-${toString(testid)}`}
            onClick={() => onNext?.(isDaily)}
          />
        )}
      </div>
      {showSegmentedButtons && (
        <>
          <div className={classes.dateSegmentDivider} />
          <IonSegment
            value={requestType}
            className={classes.dateSegments}
            data-testid={`DateSegments-${toString(testid)}`}
            onIonChange={(e) => {
              setDateFormat(
                e.detail.value === 'DAILY'
                  ? DateFormatEnum.fullDate
                  : DateFormatEnum.monthPicker
              );
              onSegmentChange?.(e);
            }}
          >
            <IonSegmentButton
              data-testid={`DateSegments-Daily-${toString(testid)}`}
              value="DAILY"
              className={classNames(classes.dateSegment)}
            >
              {toString(t('reports:daily'))}
            </IonSegmentButton>
            <IonSegmentButton
              data-testid={`DateSegments-MTD-${toString(testid)}`}
              value="MTD"
              className={classes.dateSegment}
            >
              MTD
            </IonSegmentButton>
            <IonSegmentButton
              data-testid={`DateSegments-QTD-${toString(testid)}`}
              value="QTD"
              className={classes.dateSegment}
            >
              QTD
            </IonSegmentButton>
            <IonSegmentButton
              data-testid={`DateSegments-YTD-${toString(testid)}`}
              value="YTD"
              className={classes.dateSegment}
            >
              YTD
            </IonSegmentButton>
          </IonSegment>
        </>
      )}
    </div>
  );
};

export default DateToolbarButtons;
