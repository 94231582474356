import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classnames from 'classnames';
import { IonContent, IonPage, IonRow } from '@ionic/react';
import useRecentlyViewed from 'hooks/useRecentlyViewed';
import { selectIsBranchLocation } from 'store/user/selectors';
import Header from 'components/Header/Header';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './WorkOrdersList.module.scss';

interface WorkOrderDetailURLParams {
  miLoc: string;
  woCtlNo: string;
}

const WorkOrdersDetails = (): JSX.Element => {
  const { miLoc, woCtlNo } = useParams<WorkOrderDetailURLParams>();
  const { t } = useTranslation();
  const isBranch = useSelector(selectIsBranchLocation);

  useRecentlyViewed({
    type: 'workOrders',
    miLoc,
    id: woCtlNo,
    text: `${miLoc} ${woCtlNo}`,
  });

  return (
    <IonPage className={classnames(classes.page, classes.workOrderList)}>
      <Header
        withBackButton
        title={t('workOrders:singleHeader')}
        testid="work-order-header"
      />
      <IonContent className={classes.content}>
        {!isBranch ? (
          <WarningMessage
            className={classes.warningMessage}
            icon={['far', 'info-circle']}
            title={t('workOrders:wrongAccessLvl')}
          />
        ) : (
          <>
            <IonRow>{woCtlNo}</IonRow>
            <IonRow>{miLoc}</IonRow>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default WorkOrdersDetails;
