import { useEffect, useMemo, useState } from 'react';
import type { AxiosError } from 'axios';
import { size, toString } from 'lodash';
import * as Sentry from '@sentry/capacitor';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import type { QueryParamsType } from 'common/api/utils/useGetQueryFlags';
import useWorkOrderDB from 'DocumentsApp/database/useWorkOrderDB';
import type { FastFindAPIResponse } from 'DocumentsApp/models/FastFind';
import { useAxios } from 'providers/AxiosProvider';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import { useToasts } from 'providers/ToastProvider';
import {
  doConcatDataPages,
  doGetIsLoading,
  doPromiseAPI,
  getAPIHeadersV2,
  getOfflineNextPage,
  onDownloadData,
  onSuccessMutation,
  useKeyUserId,
  useMiLocOrTeamId,
} from 'api/helpers';
import type { WorkOrderFastFind } from 'models/InspectionReport';
import type { InfiniteQueryFlags } from 'models/Search';
import { ToastType } from 'models/Toast';
import { pageSize } from 'utils/constants';

export const findWorkOrdersQueryKey = 'documents-work-orders';

interface UseFindWorkOrdersProps {
  templateId?: string;
  query?: string;
  limit?: number;
  enabled?: boolean;
}

interface UseFindWorkOrdersResponse {
  workOrders: WorkOrderFastFind[];
  loadingAPI?: boolean;
  sync: () => Promise<void>;
}

const useFindWorkOrders = ({
  templateId,
  query,
  enabled = true,
  limit = pageSize(),
}: UseFindWorkOrdersProps): UseFindWorkOrdersResponse & InfiniteQueryFlags => {
  const { axios } = useAxios();
  const { addToast } = useToasts();
  const { getWorkOrdersDatabaseAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { createQueryKey } = useKeyUserId();
  const { createParams, getURLParams } = useMiLocOrTeamId({
    sendTeamId: false,
  });
  const { createWorkOrders, findWorkOrders, removeWorkOrders } =
    useWorkOrderDB();
  const [loadingAPI, setLoadingAPI] = useState(false);
  const { isOnline } = useNetworkStatus();
  const params: QueryParamsType = {
    ...createParams(),
    templateId,
    fastFind: query,
    showUsedWO: false,
    limit,
    isOnline,
  };
  const miLoc = toString(params.miLoc);

  const doFindWorkOrderNums = ({ pageParam = 1 }) => {
    return doPromiseAPI<WorkOrderFastFind[]>(async () => {
      if (isOnline) {
        const {
          data: { rows: workOrdersData },
        } = await axios.get<FastFindAPIResponse<WorkOrderFastFind>>(
          getWorkOrdersDatabaseAPI(
            getURLParams({
              ...params,
              query: params.fastFind,
              page: pageParam,
            })
          ),
          {
            data: null,
            headers: { ...getAPIHeadersV2() },
          }
        );
        await createWorkOrders(workOrdersData);
        return workOrdersData;
      }
      const workOrders: WorkOrderFastFind[] =
        (await findWorkOrders(params)) || [];
      return workOrders;
    });
  };

  const response = useInfiniteQuery<WorkOrderFastFind[], AxiosError>(
    createQueryKey(findWorkOrdersQueryKey, params),
    doFindWorkOrderNums,
    {
      enabled,
      networkMode: 'always',
      getNextPageParam: getOfflineNextPage(!!isOnline),
    }
  );

  const {
    data,
    error,
    status,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = response;

  const workOrders = useMemo(
    () => doConcatDataPages<WorkOrderFastFind>(data),
    [data]
  );

  const hasItems = size(workOrders) > 0;
  const isEmptyResponse = status === 'success' && !hasNextPage && !hasItems;
  const noMoreData = status === 'success' && !hasNextPage && hasItems;
  const showLoader = doGetIsLoading(response) || isFetchingNextPage;
  const enableInfiniteScroll = !(!hasNextPage || isFetchingNextPage);

  useEffect(() => {
    if (enabled && !isOnline) {
      void refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, isOnline]);

  return {
    fetchNextPage: async () => {
      await fetchNextPage();
    },
    refetch: async () => {
      await refetch();
    },
    sync: async () => {
      try {
        setLoadingAPI(true);
        const { syncData } = await onDownloadData<WorkOrderFastFind>({
          customAxios: axios,
          getAPIUrl: getWorkOrdersDatabaseAPI,
          params: {
            miLoc,
            // TODO: should return used work orders for existing documents
            showUsedWO: toString(true),
          },
          headers: { ...getAPIHeadersV2() },
          method: 'get',
        });
        await removeWorkOrders(miLoc);
        await createWorkOrders(syncData);
        void onSuccessMutation(queryClient, findWorkOrdersQueryKey);
      } catch (e) {
        addToast({
          type: ToastType.error,
          text: 'There was an error while downloading work orders',
          testid: 'sync-error-toast',
          duration: 0,
        });
        Sentry.captureException(e);
        throw e;
      } finally {
        setLoadingAPI(false);
      }
    },
    workOrders,
    error,
    showLoader,
    loadingAPI,
    isEmptyResponse,
    noMoreData,
    enableInfiniteScroll,
  };
};

export default useFindWorkOrders;
