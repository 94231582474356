import { useTranslation } from 'react-i18next';
import { debounce, head, toNumber } from 'lodash';
import type { PriceOverrideInfo } from 'ActivitiesApp/models/PriceOverride';
import { emptyNumber, scaleNumber } from 'common/utils/numberHelper';
import type { FormikErrors } from 'formik';
import { namespaces } from 'i18n/i18n.constants';
import * as yup from 'yup';
import { RatingActivityEnum } from 'models/ActivityModels';
import type { DataCode } from 'models/DataCode';
import type { IPriceOverrideForm } from './PriceOverrideForm';

interface UsePriceOverrideFormValidationsProps {
  overridePrice: string;
  overrideGP: string;
  finalGP: string;
  marginGP: string;
  contractGP: string;
  calculateOverrideGP: (price: number) => string;
  calculateOverridePrice: (gp: number) => string;
  calculateFinalGP: (price: number) => string;
  values: IPriceOverrideForm;
  overrideReasons?: DataCode[];
  extendedInfo: PriceOverrideInfo;
  setFieldError: (f: string, value?: string) => void;
  setFieldValue: (
    field: string,
    value: unknown,
    shouldValidate?: boolean
  ) => Promise<void> | Promise<FormikErrors<IPriceOverrideForm>>;
}

interface UsePriceOverrideFormValidationsResponse {
  // TODO should use yup.test for all of these validations
  validateOverridePrice: (
    value: number,
    validateGP?: boolean
  ) => Promise<void> | undefined;
  validateOverrideGP: (
    value: number,
    validatePrice?: boolean
  ) => Promise<void> | undefined;
  validateMarginGP: (marginGPValue?: number) => Promise<void>;
  validateContractGP: (contractGPValue?: number) => Promise<void>;
  resetFormErrors: (rating?: RatingActivityEnum) => void;
  resetMarginGPError: (enabled: boolean) => void;
  resetContractGPError: (enabled: boolean) => void;
}

const usePriceOverrideFormValidations = ({
  overridePrice,
  overrideGP,
  finalGP,
  marginGP,
  contractGP,
  calculateOverrideGP,
  calculateOverridePrice,
  calculateFinalGP,
  values,
  overrideReasons,
  extendedInfo,
  setFieldValue,
  setFieldError,
}: UsePriceOverrideFormValidationsProps): UsePriceOverrideFormValidationsResponse => {
  const { t } = useTranslation(namespaces.activities);
  const {
    overrideReason,
    incidentalQualify,
    floorPrice,
    opQtyOrdered,
    eCOSMaxExtendedPrice,
  } = extendedInfo;

  const overrideGPRules = async (
    overrideGPValue?: number,
    validatePrice = true,
    setGPValue = true
  ) => {
    try {
      const schema = yup.number().required(t('overrideGPPercentRequired'));
      await schema.validate(overrideGPValue);
    } catch (error) {
      setFieldError('overrideGP', (error as Error)?.message);
      throw new Error();
    }
    if ((overrideGPValue || 0) >= 100) {
      setFieldError('overrideGP', t('invalidGPPercent'));
      throw new Error();
    }
    if (
      incidentalQualify === 'Y' &&
      (overrideGPValue || 0) < (toNumber(floorPrice) || 0)
    ) {
      setFieldError('overrideGP', t('specialBuyError'));
      throw new Error();
    }
    if (setGPValue) {
      void setFieldValue(
        'overrideGP',
        scaleNumber({ number: overrideGPValue, scale: 5 })
      );
    }
    setFieldError('overrideGP', undefined);
    if (validatePrice) {
      const overridePriceValue = calculateOverridePrice(
        toNumber(overrideGPValue)
      );
      const finalGPValue = calculateFinalGP(toNumber(overridePriceValue));
      void setFieldValue('overridePrice', overridePriceValue);
      void setFieldValue('finalGP', finalGPValue);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      await overridePriceRules(toNumber(overridePriceValue) || 0, false);
    }
  };

  const overridePriceRules = async (
    overridePriceValue?: number,
    validateGP = true,
    setPriceValue = true
  ) => {
    try {
      await yup
        .number()
        .moreThan(0, t('overridePriceRequired'))
        .required(t('overridePriceRequired'))
        .validate(overridePriceValue);
    } catch (error) {
      setFieldError('overridePrice', (error as Error)?.message);
      throw new Error();
    }
    if (
      (toNumber(opQtyOrdered) || 0) * (overridePriceValue || 0) >
      (toNumber(eCOSMaxExtendedPrice) || 0)
    ) {
      setFieldError(
        'overridePrice',
        t('extendedPriceError', { eCOSMaxExtendedPrice })
      );
      throw new Error();
    }
    if (setPriceValue) {
      void setFieldValue(
        'overridePrice',
        scaleNumber({ number: overridePriceValue, scale: 3 })
      );
    }
    setFieldError('overridePrice', undefined);
    if (validateGP) {
      const overrideGPValue = calculateOverrideGP(toNumber(overridePriceValue));
      const finalGPValue = calculateFinalGP(overridePriceValue || 0);
      void setFieldValue('overrideGP', overrideGPValue);
      void setFieldValue('finalGP', finalGPValue);
      await overrideGPRules(toNumber(overrideGPValue) || 0, false);
    }
  };

  const validateOverridePrice = debounce(
    // eslint-disable-next-line @typescript-eslint/no-inferrable-types
    async (value: number, setPriceValue: boolean = false) => {
      try {
        await overridePriceRules(toNumber(value) || 0, true, setPriceValue);
      } catch (error) {
        void setFieldValue('overrideGP', emptyNumber);
        void setFieldValue('finalGP', emptyNumber);
        const message = (error as Error)?.message;
        if (message) {
          setFieldError('overridePrice', message);
        }
      }
    },
    300
  );

  const validateOverrideGP = debounce(
    // eslint-disable-next-line @typescript-eslint/no-inferrable-types
    async (value: number, setGPValue: boolean = false) => {
      try {
        await overrideGPRules(toNumber(value) || 0, true, setGPValue);
      } catch (error) {
        void setFieldValue('overridePrice', emptyNumber);
        const message = (error as Error)?.message;
        if (message) {
          setFieldError('overrideGP', message);
        }
      }
    },
    300
  );

  const validateMarginGP = async (
    marginGPValue = toNumber(values.marginGP) || 0
  ) => {
    try {
      await yup
        .number()
        .moreThan(0, t('marginGPPositive'))
        .required(t('marginGPRequired'))
        .validate(marginGPValue);
      void setFieldValue(
        'marginGP',
        scaleNumber({ number: marginGPValue, scale: 5 })
      );
      setFieldError('marginGP', undefined);
    } catch (error) {
      const message = (error as Error)?.message;
      if (message) {
        setFieldError('marginGP', message);
      }
    }
  };

  const validateContractGP = async (
    contractGPValue = toNumber(values.contractGP) || 0
  ) => {
    try {
      await yup
        .number()
        .moreThan(0, t('contractGPPositive'))
        .required(t('contractGPRequired'))
        .validate(contractGPValue);
      void setFieldValue(
        'contractGP',
        scaleNumber({
          number: contractGPValue,
          scale: 5,
        })
      );
      setFieldError('contractGP', undefined);
    } catch (error) {
      const message = (error as Error)?.message;
      if (message) {
        setFieldError('contractGP', message);
      }
    }
  };

  const resetFormErrors = (rating?: RatingActivityEnum) => {
    void setFieldValue(
      'overrideReason',
      overrideReason || head(overrideReasons)?.id
    );
    if (rating === RatingActivityEnum.positive) {
      void setFieldValue('overridePrice', overridePrice);
      void setFieldValue('overrideGP', overrideGP);
      void setFieldValue('finalGP', finalGP);
      void setFieldValue('marginGP', marginGP);
      void setFieldValue('contractGP', contractGP);
      void setFieldValue('createOverrideException', false);
      void setFieldValue('createManufacturerMargin', false);
      void setFieldValue('updateUploadOverride', false);
      void setFieldValue('updateManufacturerMargin', false);
    } else {
      setFieldError('overridePrice', undefined);
      setFieldError('overrideGP', undefined);
      setFieldError('finalGP', undefined);
      setFieldError('marginGP', undefined);
      setFieldError('contractGP', undefined);
    }
  };

  const resetMarginGPError = (enabled: boolean) => {
    if (enabled) {
      void setFieldValue('marginGP', marginGP);
      void validateMarginGP(toNumber(marginGP));
    } else {
      setFieldError('marginGP', undefined);
    }
  };

  const resetContractGPError = (enabled: boolean) => {
    if (enabled) {
      void setFieldValue('contractGP', contractGP);
      void validateContractGP(toNumber(contractGP));
    } else {
      setFieldError('contractGP', undefined);
    }
  };

  return {
    validateOverridePrice,
    validateOverrideGP,
    validateMarginGP,
    validateContractGP,
    resetFormErrors,
    resetMarginGPError,
    resetContractGPError,
  };
};

export default usePriceOverrideFormValidations;
