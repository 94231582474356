import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { isNil, toString } from 'lodash';
import { IonContent, IonPage } from '@ionic/react';
import useGetAnnouncement from 'api/announcements/useGetAnnouncement';
import useMarkAnnouncementAsRead from 'api/announcements/useMarkAnnouncementAsRead';
import type { AnnouncementURLParams } from 'models/Announcement';
import { sanitizeTitle } from 'utils/announcements';
import { getErrorMessage } from 'utils/helpers';
import FilePreview from 'components/FilePreview/FilePreview';
import Header from 'components/Header/Header';
import Loader from 'components/Loader/Loader';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './Announcements.module.scss';

const AnnouncementDetail = (): JSX.Element => {
  const { url } = useParams<AnnouncementURLParams>();
  const { data, isLoading, error } = useGetAnnouncement({ url });
  const { onMarkAsRead } = useMarkAnnouncementAsRead({ url });

  const { t } = useTranslation();

  useEffect(() => {
    if (
      !isLoading &&
      !isNil(data) &&
      (data.read === 'N' || data.READ === 'N')
    ) {
      onMarkAsRead();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading]);

  return (
    <IonPage className={classes.announcement}>
      <Header
        title={sanitizeTitle(data?.typeName || t('common:loading'))}
        eyebrow={t('common:announcements')}
        withBackButton
        testid="announcement"
      />
      <IonContent className={classes.content}>
        <div className={classes.title}>
          <strong>{sanitizeTitle(data?.title || t('common:loading'))}</strong>
        </div>
        <hr className={classes.line} />
        <Loader
          className={classes.loader}
          text={t('announcements:loadingAnnouncement')}
          isOpen={isLoading}
        />
        {!isLoading && !error && (
          <FilePreview
            fileContent={toString(data?.content)}
            loaderClassname={classes.loader}
          />
        )}
        {error && (
          <WarningMessage
            className={classes.warningMessage}
            title={t('announcements:errorLoadingAnnouncement')}
            body={getErrorMessage(error)}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default AnnouncementDetail;
