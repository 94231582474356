import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { forEach, isNil } from 'lodash';
import { IonRow, IonSkeletonText } from '@ionic/react';
import { namespaces } from 'i18n/i18n.constants';
import type { DateSegmentType, SummaryItemOutput } from 'models/Reports';
import ChangeFormat from 'components/ChangeFormat/ChangeFormat';
import CurrencyFormat from 'components/CurrencyFormat/CurrencyFormat';
import Text from 'components/Text/Text';
import classes from './ReportHeader.module.scss';

interface ReportChangeTextProps {
  value: number;
  displayChange?: boolean;
  testid: string;
}

export const ReportChangeText = ({
  value,
  displayChange,
  testid,
}: ReportChangeTextProps): JSX.Element =>
  displayChange ? (
    <ChangeFormat className={classes.change} value={value} testid={testid} />
  ) : (
    <Text className={classes.noChange} text="-" />
  );

interface ReportHeaderProps {
  isLoading?: boolean;
  requestType: DateSegmentType;
  headerData: SummaryItemOutput[];
  displayChange?: boolean;
  testid: string;
  currencyType?: string;
}

const ReportHeader = ({
  isLoading,
  requestType,
  headerData,
  displayChange = true,
  testid,
  currencyType,
}: ReportHeaderProps): JSX.Element => {
  let leftHeaderAmount = 0;
  let leftHeaderChange = 0;
  let rightHeaderAmount = 0;
  let rightHeaderChange = 0;
  forEach(headerData, (item: SummaryItemOutput) => {
    switch (item.Name) {
      case 'Sales':
        leftHeaderAmount = item.amount;
        leftHeaderChange = item.change;
        break;
      case 'GP':
        rightHeaderAmount = item.amount;
        rightHeaderChange = item.change;
        break;
      default:
        break;
    }
  });
  const { t } = useTranslation(namespaces.reports);

  return (
    <IonRow className={classes.headerContainer}>
      <div className={classes.headerSection}>
        <IonRow className={classes.headerWrapper}>
          <Text
            className={classes.headerText}
            text={t('salesWithType', { requestType })}
            variant="label-header"
          />
        </IonRow>
        {isLoading && (
          <IonRow className={classes.skeletonRow}>
            <IonSkeletonText className={classes.valueSkeleton} animated />
            <IonSkeletonText className={classes.changeSkeleton} animated />
          </IonRow>
        )}
        {!isLoading && (
          <IonRow className={classes.headerWrapper}>
            <CurrencyFormat
              className={classes.headerText}
              value={leftHeaderAmount}
              variant="title-screen-section"
              testid={`${testid}-bar-chart-left`}
              currencyType={currencyType}
            />
            {!isNil(leftHeaderChange) && (
              <ReportChangeText
                displayChange={displayChange}
                value={leftHeaderChange}
                testid={`${testid}-bar-chart-left-change`}
              />
            )}
          </IonRow>
        )}
      </div>
      <div className={classes.headerSection}>
        <IonRow className={classNames(classes.headerWrapper, classes.rightRow)}>
          <Text
            className={classes.headerText}
            text={t('gpWithType', { requestType })}
            variant="label-header"
          />
        </IonRow>
        {isLoading && (
          <IonRow className={classNames(classes.skeletonRow, classes.rightRow)}>
            <IonSkeletonText className={classes.valueSkeleton} animated />
            <IonSkeletonText className={classes.changeSkeleton} animated />
          </IonRow>
        )}
        {!isLoading && (
          <IonRow
            className={classNames(classes.headerWrapper, classes.rightRow)}
          >
            <CurrencyFormat
              className={classes.headerText}
              value={rightHeaderAmount}
              variant="title-screen-section"
              testid={`${testid}-bar-chart-right`}
              currencyType={currencyType}
            />
            {!isNil(rightHeaderChange) && (
              <ReportChangeText
                displayChange={displayChange}
                value={rightHeaderChange}
                testid={`${testid}-bar-chart-right-change`}
              />
            )}
          </IonRow>
        )}
      </div>
    </IonRow>
  );
};

export default ReportHeader;
