import { useTranslation } from 'react-i18next';
import { omit } from 'lodash';
import type { MutationStatus } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import { findActivitiesQueryKey } from 'api/activities/useFindActivities';
import { findActivityFiltersQueryKey } from 'api/activities/useFindActivityFilters';
import type { UpdateMutationContext } from 'api/helpers';
import {
  onSuccessMutation,
  doPromiseAPI,
  onMutateUpdate,
  onErrorUpdate,
} from 'api/helpers';
import type { ActionCardActivity } from 'models/ActivityModels';
import { ToastType } from 'models/Toast';
import { logFirebaseEvent } from 'utils/firebaseAnalytics';

interface UseUpdateSourcingOverrideProps {
  miLoc: string;
  ocn: string;
  lineNo: string;
  userId?: string;
}

interface UpdateSourcingOverrideBody {
  historyId: number;
  userId: string;
  action: string;
  purReqCtlNo: string;
  statusCd: string;
  reasonCd: string;
}

interface UpdateSourcingOverrideAPIResponse {
  success: boolean;
  errorMsg: string;
}

interface UseUpdateSourcingOverrideResponse {
  data?: UpdateSourcingOverrideAPIResponse;
  status: MutationStatus;
  onUpdateSourcingOverride: (body: UpdateSourcingOverrideBody) => void;
}

const useUpdateSourcingOverride = ({
  miLoc,
  ocn,
  lineNo,
}: UseUpdateSourcingOverrideProps): UseUpdateSourcingOverrideResponse => {
  const { axios } = useAxios();
  const { updateSourcingOverrideAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const { t } = useTranslation();

  const doUpdateActivity = (body: UpdateSourcingOverrideBody) => {
    return doPromiseAPI<UpdateSourcingOverrideAPIResponse>(async () => {
      const requestBody = omit(body, ['userId', 'action']);
      const { data } = await axios.put<UpdateSourcingOverrideAPIResponse>(
        updateSourcingOverrideAPI(miLoc, ocn, lineNo),
        requestBody
      );
      return data;
    });
  };

  const { status, data, mutate } = useMutation(doUpdateActivity, {
    onMutate: async (vars) => {
      logFirebaseEvent('sourcing_override_action', {
        userId: vars.userId,
        miLoc,
        action:
          // eslint-disable-next-line no-nested-ternary
          vars.action === 'A'
            ? 'accepted'
            : vars.action === 'R'
            ? 'rejected'
            : '',
        event: 'SourcingOverride',
      });

      return onMutateUpdate<ActionCardActivity>({
        queryClient,
        queryKey: findActivitiesQueryKey,
        updatedItems: [{ done: 'Y' } as ActionCardActivity],
        findPredicates: [{ historyId: vars.historyId, userId: vars.userId }],
        isInfiniteQuery: true,
      });
    },
    onSuccess: () => {
      setTimeout(() => {
        void onSuccessMutation(queryClient, findActivityFiltersQueryKey);
        void onSuccessMutation(queryClient, findActivitiesQueryKey);
      }, 1000);
    },
    onError: (error, vars, context) => {
      addToast({
        type: ToastType.error,
        text: t('activities:sourcingOverride:updateErrorToast'),
        testid: 'update-sourcing-override-error-toast',
      });
      onErrorUpdate<ActionCardActivity>({
        queryClient,
        context: context as UpdateMutationContext<ActionCardActivity>[],
        isInfiniteQuery: true,
      });
    },
  });

  return {
    data,
    status,
    onUpdateSourcingOverride: (body: UpdateSourcingOverrideBody) =>
      mutate(body),
  };
};

export default useUpdateSourcingOverride;
