import React from 'react';
import classNames from 'classnames';
import type { IonModal } from '@ionic/react';
import { IonRow } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import type { ButtonVariantType } from 'components/Button/Button';
import Button from 'components/Button/Button';
import SheetModal from 'components/Modals/SheetModal/SheetModal';
import Text from 'components/Text/Text';
import classes from './DiscardModal.module.scss';

interface DiscardModalProps {
  title: string;
  isOpen: boolean;
  backdropDismiss?: boolean;
  withRightCloseButton?: boolean;
  setIsOpen?: (b: boolean) => void;
  testid: string;
  discardButtonTitle: string;
  discardMsg?: string;
  goBackButtonTitle: string;
  onDiscardClick?: () => void;
  onGoBackClick?: () => void;
  secondayButtonVariant?: ButtonVariantType;
}

const DiscardModal = ({
  title,
  isOpen,
  setIsOpen,
  backdropDismiss = true,
  withRightCloseButton = false,
  initialBreakpoint = 0.5,
  discardButtonTitle,
  discardMsg = '',
  goBackButtonTitle,
  onDiscardClick,
  onGoBackClick,
  testid,
  secondayButtonVariant,
}: DiscardModalProps &
  IonicReactProps &
  React.ComponentProps<typeof IonModal>): JSX.Element => (
  <SheetModal
    title={title}
    titleTextVariant="mipro-h3-headline"
    className={classes.discardModal}
    titleClassName={classes.discardTitle}
    contentClass={classes.discardContent}
    isOpen={isOpen}
    setIsOpen={setIsOpen}
    initialBreakpoint={initialBreakpoint}
    backdropDismiss={backdropDismiss}
    withRightCloseButton={withRightCloseButton}
    testid={testid}
  >
    <IonRow className={classes.msgRow}>
      <Text
        className={classes.discardMsg}
        variant="mipro-body-copy"
        text={discardMsg}
      />
    </IonRow>
    <Button
      className={classNames(classes.discardButton, classes.primaryButton)}
      variant="action"
      text={goBackButtonTitle}
      onClick={() => {
        setIsOpen?.(false);
        onGoBackClick?.();
      }}
      testid="primary-button"
    />
    <Button
      className={classNames(classes.discardButton, classes.secondaryButton)}
      variant={secondayButtonVariant || 'secondary'}
      text={discardButtonTitle}
      onClick={() => {
        setIsOpen?.(false);
        onDiscardClick?.();
      }}
      testid="secondary-button"
    />
  </SheetModal>
);

export default DiscardModal;
