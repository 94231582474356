import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';
import { filter, isEmpty, map, toNumber } from 'lodash';
import { IonContent } from '@ionic/react';
import useAddReplenishment from 'StoreroomsApp/api/useAddItemsReplenishment';
import useFindItemsPOU from 'StoreroomsApp/api/useFindItemsPOU';
import useFindOpenReplenishment from 'StoreroomsApp/api/useFindOpenReplenishment';
import type {
  IssueSearchParams,
  ItemIssueDTO,
} from 'StoreroomsApp/models/Issue';
import type { RootState } from 'store/reducers';
import { getErrorMessage } from 'utils/helpers';
import Loader from 'components/Loader/Loader';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import ItemCard from './ItemCard';
import classes from './ItemsPOUList.module.scss';

interface ItemsPOUListProps {
  searchQuery: string;
  selectedItems?: unknown[];
  isPendingIssue?: boolean;
  onResetSearch?: () => void;
  onAddItem?: (v: unknown) => void;
}

const ItemsPOUList = ({
  searchQuery,
  selectedItems,
  isPendingIssue,
  onResetSearch,
  onAddItem,
}: ItemsPOUListProps): JSX.Element => {
  const { storeroom: urlStoreroom } = useParams<IssueSearchParams>();
  const { storeroom: stateStoreroom = '' } = useSelector(
    (state: RootState) => state.user
  );
  const storeroomId = urlStoreroom || stateStoreroom;

  const { replenishment } = useFindOpenReplenishment();
  const {
    itemsPOU: apiItems,
    error,
    showLoader,
    isEmptyResponse,
    enableInfiniteScroll,
    fetchNextPage,
  } = useFindItemsPOU({
    replenishmentId: replenishment?.id,
    storeroom: storeroomId,
    query: searchQuery,
    isPendingIssue,
  });
  const { onAddItemsReplenishment } = useAddReplenishment();
  const itemsPOU = useMemo(
    () =>
      isPendingIssue
        ? map(apiItems, (item) => ({
            ...item,
            alreadyAddedToIssue: filter(
              selectedItems,
              (i) => (i as ItemIssueDTO).itemId === item.combinedId
            ).length,
          }))
        : apiItems,
    [apiItems, isPendingIssue, selectedItems]
  );

  return (
    <IonContent className={classes.content} data-testid="items-pou-list">
      {!error && !isEmpty(itemsPOU) && (
        <Virtuoso
          className="ion-content-scroll-host"
          data={itemsPOU}
          increaseViewportBy={{ top: 500, bottom: 500 }}
          endReached={enableInfiniteScroll ? fetchNextPage : undefined}
          itemContent={(index, itemPOU) => (
            <ItemCard
              key={itemPOU.combinedId}
              searchQuery={searchQuery}
              itemPOU={itemPOU}
              loading={itemPOU.isOptimisticallyUpdating}
              isPendingIssue={isPendingIssue}
              onAddItem={(v?: number) => {
                if (isPendingIssue) {
                  onAddItem?.({
                    issueQuantity: v || 0,
                    itemId: itemPOU.combinedId,
                    itemName: `${itemPOU.barcodeValue} ${itemPOU.itemDescription}`,
                  });
                  onResetSearch?.();
                } else {
                  onAddItemsReplenishment({
                    items: [
                      {
                        balanceOnHandQuantity: itemPOU.fixedOrderQuantity,
                        orderQuantity: toNumber(itemPOU.fixedOrderQuantity),
                        replenishmentId: toNumber(replenishment?.id),
                        itemId: itemPOU.combinedId,
                        itemName: `${itemPOU.barcodeValue} ${itemPOU.itemDescription}`,
                      },
                    ],
                  });
                }
              }}
            />
          )}
        />
      )}
      {!showLoader && isEmptyResponse && (
        <WarningMessage
          className={classes.warningMessage}
          icon={['far', 'info-circle']}
          title="No results for your search"
          body="Are you expecting data? Go online and download to sync with the server."
        />
      )}
      {error && (
        <WarningMessage
          className={classes.warningMessage}
          title="Error loading items POU"
          body={getErrorMessage(error)}
        />
      )}
      <Loader
        className={classes.loader}
        text="Loading items POU"
        isOpen={showLoader}
        testid="loading-items POU"
      />
    </IonContent>
  );
};

export default ItemsPOUList;
