import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useDebounce as useFnDebounce } from 'react-use';
import type { Dictionary } from 'lodash';
import { reduce, times, head, isEmpty, map, size } from 'lodash';
import { IonLoading, IonPage, IonRow, IonToolbar } from '@ionic/react';
import type { ScanListenerEvent } from 'capacitor-datawedge';
import { DataWedge } from 'capacitor-datawedge';
import { useFormik } from 'formik';
import { useToasts } from 'providers/ToastProvider';
import useAddIssue from 'StoreroomsApp/api/useAddIssue';
import IssueList from 'StoreroomsApp/components/IssueList/IssueList';
import AddItemModal from 'StoreroomsApp/components/ItemsPOUList/AddItemModal';
import ItemsPOUList from 'StoreroomsApp/components/ItemsPOUList/ItemsPOUList';
import useIssueSync from 'StoreroomsApp/hooks/useIssueSync';
import type { ItemIssue, ItemIssueDTO } from 'StoreroomsApp/models/Issue';
import { IssueTypeEnum } from 'StoreroomsApp/models/Issue';
import type { ItemPOU } from 'StoreroomsApp/models/ItemPOU';
import { useDebounce } from 'use-debounce';
import useGoBack from 'hooks/useGoBack';
import { ToastType } from 'models/Toast';
import type { RootState } from 'store/reducers';
import selectIsBranchLocation from 'store/user/selectors';
import Header from 'components/Header/Header';
import ConfirmDialog from 'components/Modals/ConfirmDialog/ConfirmDialog';
import Searchbar from 'components/Searchbar/Searchbar';
import classes from './IssueItems.module.scss';

export const getIssueChargeBackValues = (issue?: Dictionary<unknown>) =>
  reduce(
    times(5),
    (prev, index) => ({
      ...prev,
      [`chargeBack${index + 1}`]: issue?.[`chargeBack${index + 1}`],
    }),
    {}
  );

const IssueItems = (): JSX.Element => {
  const { pathname } = useLocation();
  const { url } = useRouteMatch();
  const { addToast } = useToasts();
  const {
    miLoc,
    storeroom = '',
    storeroomName,
  } = useSelector((state: RootState) => state.user);

  const isBranch = useSelector(selectIsBranchLocation);
  const { goBack } = useGoBack();
  const [scannedLabel, setScannedLabel] = useState<string>('');
  const [scanInProgress, setScanInProgress] = useState<boolean>(false);
  const [scannedItem, setScannedItem] = useState<ItemPOU>();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryString] = useDebounce(searchQuery, 300);
  const [isOpen, setIsOpen] = useState(false);
  const [discardConfirmDialogIsOpen, setDiscardConfirmDialogIsOpen] =
    useState(false);
  const headerRef = React.useRef<HTMLDivElement>(null);

  const { values, setFieldValue, resetForm } = useFormik<Dictionary<unknown>>({
    initialValues: {
      recordType: IssueTypeEnum.issue,
      issueItems: [] as ItemIssue[],
      ...getIssueChargeBackValues(),
    },
    onSubmit: () => {},
  });

  const doSearch = (query: string) => {
    if (!isEmpty(query)) {
      return;
    }
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };

  const onSearch = (query: string) => {
    setSearchQuery(query);
    setTimeout(() => {
      void doSearch(query);
    }, 300);
  };

  const { loader, offlineBanner } = useIssueSync();

  const { getBarcodeItem } = useAddIssue();

  const onScanItem = async (barcode: string) => {
    if (!scanInProgress) {
      setScanInProgress(true);
      try {
        const barcodeItems = await getBarcodeItem(
          barcode,
          values.issueItems as ItemIssueDTO[],
          storeroom
        );
        setScannedItem(head(barcodeItems));
        if (size(barcodeItems) > 0) {
          setIsOpen(true);
        }
        setScannedLabel('');
      } catch (e) {
        addToast({
          type: ToastType.error,
          text: 'Cant find an item with the scanned barcode',
          testid: 'item-scan-error-toast',
        });
      } finally {
        setScanInProgress(false);
      }
    }
  };

  useEffect(() => {
    DataWedge.addListener('scan', (e: ScanListenerEvent) => {
      const barcode = e.data;
      setScannedLabel(barcode);
    }).catch(() => null);
  }, []);

  useFnDebounce(
    () => {
      if (isBranch && scannedLabel && pathname.startsWith(url)) {
        void onScanItem(scannedLabel);
      }
    },
    500,
    [scannedLabel]
  );

  const onDiscardConfirmDialogYes = () => {
    setDiscardConfirmDialogIsOpen(false);
    goBack();
  };

  const onDiscardConfirmDialogNo = () => {
    setDiscardConfirmDialogIsOpen(false);
  };

  const onAddIssueItem = (v: ItemIssue[]) => {
    void setFieldValue('issueItems', [
      ...(values.issueItems as ItemIssue[]),
      ...v,
    ]);
  };

  return (
    <IonPage className={classes.replenishment} data-testid="issue-items">
      <Header
        eyebrow={miLoc}
        title={storeroom ? storeroomName || storeroom : 'Loading...'}
        withBackButton
        hideHomeMenu
        customBackButtonClick={() => {
          if (!isEmpty(values.issueItems)) {
            setDiscardConfirmDialogIsOpen(true);
            return;
          }
          goBack();
        }}
        testid="issue-items-header"
      >
        <IonToolbar className={classes.toolbar}>
          <IonRow className={classes.searchRow}>
            <Searchbar
              className={classes.searchBar}
              inputClassName={classes.searchInput}
              value={searchQuery}
              placeholder="Search CSN..."
              setValue={(v) => setSearchQuery(v)}
              onSearch={() => onSearch(searchQuery)}
              onClear={() => setSearchQuery('')}
              variant="dark"
              testid="search-input"
            />
            <div ref={headerRef} />
          </IonRow>
          {!!scannedItem && (
            <AddItemModal
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              itemPOU={scannedItem}
              onAdd={(v) =>
                onAddIssueItem(
                  map([scannedItem], (item) => ({
                    issueQuantity: v,
                    itemId: item.combinedId,
                    itemName: `${item.barcodeValue} ${item.itemDescription}`,
                  })) as ItemIssue[]
                )
              }
              testid="add-product-modal"
            />
          )}
        </IonToolbar>
        {offlineBanner}
      </Header>
      <IonLoading isOpen={scanInProgress} message="Scan in progress..." />
      {loader}
      {!isEmpty(searchQueryString) ? (
        <ItemsPOUList
          searchQuery={searchQueryString}
          selectedItems={values.issueItems as ItemIssue[]}
          isPendingIssue
          onResetSearch={() => setSearchQuery('')}
          onAddItem={(v) => onAddIssueItem([v] as ItemIssue[])}
        />
      ) : (
        <IssueList
          formValues={values}
          setFieldValue={(f: string, v: unknown) => {
            void setFieldValue(f, v);
          }}
          resetForm={() =>
            resetForm({
              recordType: IssueTypeEnum.issue,
              issueItems: [] as ItemIssue[],
              ...getIssueChargeBackValues(),
            } as Dictionary<unknown>)
          }
          headerRef={headerRef}
        />
      )}
      <ConfirmDialog
        isOpen={discardConfirmDialogIsOpen}
        setIsOpen={setDiscardConfirmDialogIsOpen}
        title="Discard changes?"
        text="Changes you made will not be saved."
        primaryText="Go Back to Issue"
        secondaryText="Discard Changes"
        onPrimaryClick={onDiscardConfirmDialogNo}
        onSecondaryClick={onDiscardConfirmDialogYes}
        testid="discard-changes-modal"
      />
    </IonPage>
  );
};

export default IssueItems;
