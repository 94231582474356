export enum WebActivityTypeEnum {
  webActivity = 'CustomerWebActivity',
}

export enum WebActivityIconEnum {
  webActivity = 'browser',
}

export enum WebActivityColorEnum {
  webActivity = 'color-webActivity',
}

export enum WebActivityFilterEnum {
  webActivity = 'CustomerWebActivity',
}

export interface WebActivityInfo {
  custContactName: string;
  custContactEmail?: string;
  searchCount: number;
  productImpressions: number;
}
