import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { find, first, isEmpty, toNumber, toString } from 'lodash';
import type { PluginListenerHandle } from '@capacitor/core';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { IonContent, IonPage } from '@ionic/react';
import Footer from 'common/components/Footer/Footer';
import CustomerName from 'common/components/Header/CustomerName';
import Header from 'common/components/Header/Header';
import { scaleNumber } from 'common/utils/numberHelper';
import { Formik } from 'formik';
import useGetOrder from 'ProductSearchApp/api/useGetOrder';
import useUpdateToCart from 'ProductSearchApp/api/useUpdateToCart';
import NotesTab from 'ProductSearchApp/components/NotesTab/NotesTab';
import PricingTab from 'ProductSearchApp/components/PricingTab/PricingTab';
import ProductHeader from 'ProductSearchApp/components/ProductHeader/ProductHeader';
import type { ProductDetailURLParams } from 'ProductSearchApp/models/Products';
import {
  getItemShopSourcing,
  getProductFromOcnLine,
  isUnfinishedOrder,
  isZcodedItem,
  sumOfSourcedQuantity,
  getOrderLineItemPayload,
  useRepriceToast,
  hasPriceOverride,
} from 'ProductSearchApp/util/ocnHelpers';
import useGetCustomer from 'api/customer/useGetCustomer';
import useGoBack from 'hooks/useGoBack';
import useShowCostDetailsDispatcher from 'hooks/useToggleCostDetailsDispatcher';
import { getErrorMessage } from 'utils/helpers';
import { goToProductHistory } from 'navigation/navigationHelpers';
import Loader from 'components/Loader/Loader';
import DiscardModal from 'components/Modals/DiscardModal/DiscardModal';
import SegmentTabs from 'components/SegmentTabs/SegmentTabs';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import DetailsForm from './DetailsForm';
import classes from './EditOrderLine.module.scss';
import type { EditOrderLineForm } from './EditOrderLineSchema';
import { EditOrderLineSchema, qtyScaleProps } from './EditOrderLineSchema';

export enum EditOrderLineTabEnum {
  details = 'details',
  pricing = 'pricing',
  notes = 'notes',
}

enum SubmitTypeEnum {
  submit = 'submit',
  reset = 'reset',
  reprice = 'reprice',
}

const EditOrderLine = (): JSX.Element => {
  const { t } = useTranslation();
  const location = useLocation();
  const { goBack } = useGoBack();
  const { miLoc, id, ocn, lineNo } = useParams<ProductDetailURLParams>();
  const { toggleCostDetailsOption } = useShowCostDetailsDispatcher();
  const params = new URLSearchParams(location.search);
  const tabQueryParam = params.get('tab') as EditOrderLineTabEnum;
  const [tabValue, setTabValue] = useState<EditOrderLineTabEnum>(
    EditOrderLineTabEnum.details
  );
  const [discardModalIsOpen, setDiscardModalIsOpen] = useState(false);
  const [submitType, setSubmitType] = useState<SubmitTypeEnum>();
  const [triggerRecalcReset, setTriggerRecalcReset] = useState(0);

  useEffect(() => {
    if (tabQueryParam) {
      setTabValue(EditOrderLineTabEnum[tabQueryParam]);
    }
  }, [tabQueryParam]);

  const {
    data: customerData,
    isLoading: customerLoading,
    error: customerError,
  } = useGetCustomer({ searchType: 'customer', miLoc, id });

  const {
    order,
    isLoading: orderLoading,
    error: orderError,
  } = useGetOrder({ miLoc, orderCtlNo: ocn });

  const { onUpdateToCart, status } = useUpdateToCart({});

  const tabs = [
    {
      key: EditOrderLineTabEnum.details,
      text: t('productSearch:ocn:ocnTab'),
    },
    {
      key: EditOrderLineTabEnum.pricing,
      text: t('productSearch:ocn:pricingTab'),
    },
    {
      key: EditOrderLineTabEnum.notes,
      text: t('productSearch:ocn:notesTab'),
    },
  ];

  const isSubmitting = status === 'loading';
  const isLoading = customerLoading || orderLoading;
  const error = customerError || orderError;
  const disabledSave = isLoading || !!error;
  const lineItem = find(order?.items, { orderLineNo: lineNo });
  const overrideMode = hasPriceOverride(lineItem);
  const overridePrice = scaleNumber({
    number: overrideMode
      ? lineItem?.priceOverridePrice
      : lineItem?.unitSellPrice,
  });
  const overrideGP = scaleNumber({
    number: overrideMode
      ? lineItem?.priceOverrideGPPerc
      : lineItem?.grossProfitPct,
  });
  const { showRepriceToast } = useRepriceToast(overridePrice, overrideGP);
  const customerCost = scaleNumber({ number: lineItem?.customerCost });
  const quantity = scaleNumber({
    number: lineItem?.opQtyOrdered,
    ...qtyScaleProps,
  });
  const itemSource = first(getItemShopSourcing(lineItem));

  const isUnfinished = isUnfinishedOrder(order?.orderTypeCd);

  const sourcingPayload = (values: EditOrderLineForm) => {
    const isDifferentShop = itemSource?.requestTo !== values.repairShop?.key;

    const sourceList = [];
    if (
      isUnfinished &&
      itemSource?.requestTo &&
      (scaleNumber({ number: values.quantity, ...qtyScaleProps }) !==
        values.originalQuantity ||
        isDifferentShop)
    ) {
      sourceList.push({
        purchaseQty: isDifferentShop ? '0' : values.quantity,
        sourceLoc: itemSource?.requestTo,
        reqCtlNo: itemSource?.ctlNo,
        purchaseQtyOrig: itemSource?.qtyRequested,
      });
      if (isDifferentShop) {
        sourceList.push({
          purchaseQty: values.quantity,
          sourceLoc: values.repairShop?.key,
          purchaseQtyOrig: '0',
        });
      }
    }
    return sourceList;
  };

  const requestPayload = (values: EditOrderLineForm) => ({
    ...getOrderLineItemPayload(lineItem),
    custComment: values.custComment,
    unitCost:
      values.customerCost !== values.originalCustomerCost
        ? values.customerCost
        : lineItem?.unitCost,
    quantity: toNumber(values.quantity),
    promsdDelivDt: values.promsdDelivDt,
    salesUOM: values.uom?.key,
    custStockNo: values.csn,
  });

  useEffect(() => {
    if (status === 'success') {
      switch (submitType) {
        case SubmitTypeEnum.submit:
          goBack();
          break;
        case SubmitTypeEnum.reprice:
          showRepriceToast();
          setTriggerRecalcReset(Date.now());
          break;
        case SubmitTypeEnum.reset:
        default:
          // TODO this is needed to reset the form, should be a ref.reset call instead
          setTriggerRecalcReset(Date.now());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goBack, submitType, status]);

  const [keyboardDisplay, setKeyboardDisplay] = useState(false);

  useEffect(() => {
    let keyboardShowListener: PluginListenerHandle;
    let keyboardHideListener: PluginListenerHandle;
    const doKeyboadListener = async () => {
      if (Capacitor.isPluginAvailable('Keyboard')) {
        keyboardShowListener = await Keyboard.addListener(
          'keyboardWillShow',
          () => setKeyboardDisplay(true)
        );
        keyboardHideListener = await Keyboard.addListener(
          'keyboardDidHide',
          () => setKeyboardDisplay(false)
        );
      }
    };
    void doKeyboadListener();

    return () => {
      if (Capacitor.isPluginAvailable('Keyboard')) {
        void keyboardShowListener?.remove();
        void keyboardHideListener?.remove();
      }
    };
  }, []);

  return (
    <Formik<EditOrderLineForm>
      initialValues={{
        isUnfinished,
        isRepair: isUnfinished && !!itemSource,
        repairShop: {
          key: toString(itemSource?.requestTo),
          title: toString(itemSource?.requestToName),
        },
        uom: {
          key: toString(lineItem?.salesUOM),
          title: toString(lineItem?.salesUOM),
        },
        isZCoded: isZcodedItem(lineItem?.itemNo),
        autoReprice: lineItem?.paType === 'Z' && order?.autoRepriceZPI === 'Y',
        csn: lineItem?.custStockNo,
        quantity,
        originalQuantity: quantity,
        promsdDelivDt: lineItem?.promsdDelivDt,
        custComment: lineItem?.custComment,
        customerCost,
        originalCustomerCost: customerCost,
        overridePrice,
        originalPrice: overridePrice,
        overrideGP,
        originalGP: overrideGP,
        overrideMode,
        overrideReason: '',
        overrideReasonText: '',
        sendConfirmation: '',
        addToAgreement: '',
        agreementGP: '',
        notebookText: '',
        userOverride: 'none',
        triggerRecalcReset,
        isSourcing: !isEmpty(lineItem?.itemSourcing),
        sourcedQty: sumOfSourcedQuantity(lineItem),
        governmentPricing: lineItem?.governmentPricing,
        ...(overrideMode
          ? {
              overrideReason: lineItem?.priceOverrideReason,
              overrideReasonText: lineItem?.priceOverrideReasonDesc,
              sendConfirmation: lineItem?.priceOverrideSendOrderConfirmation,
              addToAgreement: lineItem?.addToAgreement,
              agreementGP: scaleNumber({ number: lineItem?.recommendedGP }),
              notebookText: lineItem?.priceOverrideNotebookText,
            }
          : undefined),
      }}
      enableReinitialize
      validationSchema={EditOrderLineSchema}
      onSubmit={(values) => {
        setSubmitType(SubmitTypeEnum.submit);
        const autoOverride =
          toNumber(values.overridePrice) > toNumber(values.originalPrice);
        onUpdateToCart({
          itemList: [requestPayload(values)],
          sourceList: sourcingPayload(values),
          overrideCost: values.customerCost !== values.originalCustomerCost,
          overrideMode:
            values.userOverride !== 'none' &&
            values.originalPrice !== values.overridePrice
              ? {
                  orderLineNo: toString(lineItem?.orderLineNo),
                  overridePrice: toString(values.overridePrice),
                  overrideReason: toString(values.overrideReason),
                  sendConfirmation: autoOverride
                    ? 'N'
                    : toString(values.sendConfirmation),
                  autoOverride: autoOverride ? 'Y' : 'N',
                  agreementGP: autoOverride ? '' : toString(values.agreementGP),
                  addToAgreement: autoOverride
                    ? 'N'
                    : toString(values.addToAgreement),
                  updateNotebook: true,
                  notebookText: toString(values.notebookText),
                  notebookLastUpdated: toString(
                    lineItem?.priceOverrideNotebookLastModified
                  ),
                }
              : undefined,
        });
      }}
    >
      {({ values, errors, dirty, isValid, handleSubmit }) => (
        <IonPage className={classes.page} data-testid="order-line-page">
          <Header
            customTitle={<CustomerName customerData={customerData} />}
            backButton={{
              onClick: () => {
                if (dirty) {
                  setDiscardModalIsOpen(true);
                } else {
                  goBack();
                }
              },
            }}
            headerActions={{
              title: t('productSearch:ocn:manageOcn'),
              initialBreakpoint: 0.4,
              disabled: false,
              options: [
                ...(!isZcodedItem(lineItem?.itemNo)
                  ? [
                      {
                        text: t('productSearch:viewPriceHistory'),
                        href: goToProductHistory({
                          miLoc,
                          customerNo: id,
                          itemNo: lineItem?.itemNo,
                        }),
                        disabled: isSubmitting || isLoading,
                        testid: 'view-price-history',
                      },
                    ]
                  : []),
                toggleCostDetailsOption(false),
              ],
            }}
            hideMenuButton
            testid="ocn-line-header"
          />
          <IonContent className={classes.content}>
            <div
              className={classNames({
                [classes.container]: !keyboardDisplay,
              })}
            >
              <ProductHeader
                withLink
                lineNumber={lineItem?.orderLineNo}
                disabled={isSubmitting}
                productData={getProductFromOcnLine(lineItem)}
                testid="product-detail-header"
              />
              <SegmentTabs
                className={classes.tabs}
                variant="miproWhite"
                options={tabs}
                value={tabValue}
                setValue={(v) => setTabValue(v as EditOrderLineTabEnum)}
                textVariant="title-action-card"
                testid="segment-tabs"
              />
              {/* eslint-disable-next-line no-nested-ternary */}
              {isLoading ? (
                <Loader
                  className={classes.loader}
                  text={t('product:loadingProduct')}
                  isOpen={isLoading}
                />
              ) : error ? (
                <WarningMessage
                  className={classes.message}
                  title={t('product:loadingError')}
                  body={getErrorMessage(error)}
                />
              ) : (
                <>
                  {tabValue === EditOrderLineTabEnum.details && (
                    <DetailsForm
                      isLoading={isSubmitting}
                      miLoc={miLoc}
                      order={order}
                      lineItem={lineItem}
                      onAutoReprice={() => {
                        setSubmitType(SubmitTypeEnum.reprice);
                        onUpdateToCart({ itemList: [requestPayload(values)] });
                      }}
                    />
                  )}
                  {tabValue === EditOrderLineTabEnum.pricing && (
                    <PricingTab
                      isLoading={isSubmitting}
                      lineItem={lineItem}
                      order={order}
                      onReset={() => {
                        setSubmitType(SubmitTypeEnum.reset);
                        onUpdateToCart({
                          itemList: [
                            {
                              ...requestPayload(values),
                              reprice: values.isZCoded ? 'P' : 'R',
                            },
                          ],
                          sourceList: sourcingPayload(values),
                        });
                      }}
                      onAutoReprice={() => {
                        setSubmitType(SubmitTypeEnum.reprice);
                        onUpdateToCart({
                          itemList: [requestPayload(values)],
                          sourceList: sourcingPayload(values),
                        });
                      }}
                    />
                  )}
                  {tabValue === EditOrderLineTabEnum.notes && (
                    <NotesTab disabled={isSubmitting} />
                  )}
                </>
              )}
            </div>
            <DiscardModal
              title={t('productSearch:ocn:editOcnLineDiscardModalTitle')}
              className={classes.discardModal}
              isOpen={discardModalIsOpen}
              setIsOpen={setDiscardModalIsOpen}
              initialBreakpoint={0.4}
              backdropDismiss={false}
              withRightCloseButton
              testid="discard-changes-modal"
              discardMsg={t('common:discardMsg')}
              goBackButtonTitle={t('common:goBack')}
              discardButtonTitle={t('common:discard')}
              onDiscardClick={() => goBack()}
            />
          </IonContent>
          <Footer
            buttons={[
              {
                variant: 'mipro-action',
                text: t('common:save'),
                onClick: () => {
                  if (!isValid) {
                    if (
                      !isEmpty(errors.quantity) ||
                      !isEmpty(errors.repairShop)
                    ) {
                      setTabValue(EditOrderLineTabEnum.details);
                    } else {
                      setTabValue(EditOrderLineTabEnum.pricing);
                    }
                  }
                  if (submitType === SubmitTypeEnum.reprice) {
                    return;
                  }
                  handleSubmit();
                },
                disabled: disabledSave || isSubmitting,
                testid: 'save-button',
              },
            ]}
          />
        </IonPage>
      )}
    </Formik>
  );
};

export default EditOrderLine;
