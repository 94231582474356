/* eslint-disable no-console */
import { get, head, isNil, map, size, toNumber, toString, trim } from 'lodash';
import type { capSQLiteSet } from '@capacitor-community/sqlite';
import type {
  CountGroup,
  CountGroupItem,
  CountPlan,
  CountPlanOptions,
  ProfileDefaultSort,
} from 'InventoryApp/models/InventoryPlanGroup';
import { useGetSelectedMiLoc, useMiLocOrTeamId } from 'api/helpers';
import useInventoryDB from './initInventoryDB';
import { createGroupItems } from './utils';

export interface UpdateCountGroupProps {
  groupUniqueId: string;
  billCustomerNo?: string;
  billCustomerName?: string;
  lines?: string;
  linesUpdated?: string;
  isDeleted?: boolean;
  downloadedItems?: number;
  sortField?: string;
  sortDir?: string;
  miLoc?: string;
  customerNo?: string;
}

export interface RemoveCountGroupProps {
  countPlanId: string;
  groupId: string;
}

interface UseCountPlanDBResponse {
  createCountPlan: (countPlan: CountPlan) => Promise<string>;
  createCountGroups: (countGroups: CountGroup[]) => Promise<void>;
  createCountPlanOptions: (
    countPlanOptions: CountPlanOptions
  ) => Promise<string>;
  createProfileDefaultSort: (
    defaultSort: ProfileDefaultSort
  ) => Promise<string>;
  addItemsToCountPlan: (items: CountGroupItem[]) => Promise<void>;
  findCountPlanOptionsById: (countPlanId: string) => Promise<CountPlanOptions>;
  findCountPlanById: (countPlanId: string) => Promise<CountPlan>;
  findCountGroupByMiLoc: (includeDeleted?: boolean) => Promise<CountGroup[]>;
  findCountGroupById: (
    countPlanId: string,
    groupId: string,
    groupUniqueId: string
  ) => Promise<CountGroup>;
  findProfileDefaultSort: (
    miLoc: string,
    customerNo: string
  ) => Promise<ProfileDefaultSort>;
  findItemsByGroup: (
    countPlanId: string,
    groupId: string,
    searchQuery?: string,
    isScanSearch?: boolean,
    hasCount?: boolean,
    sortField?: string,
    sortDir?: string
  ) => Promise<CountGroupItem[]>;
  findTotalItemsByGroup: (
    countPlanId: string,
    groupId: string,
    hasCount?: boolean
  ) => Promise<number>;
  updateCountGroups: (props: UpdateCountGroupProps[]) => Promise<void>;
  updateItemCount: (
    itemId: string,
    actualCount: string,
    orderQuantity: number,
    hasCount: boolean,
    width?: number,
    length?: number,
    hasLocalCount?: boolean
  ) => Promise<void>;
  updateMinMaxCount: (
    itemId: string,
    miMinQty: number,
    miMaxQty: number,
    hasEditedMinMax: boolean,
    orderQuantity: number
  ) => Promise<void>;
  addZeroCounts: (groupId: string) => Promise<number>;
  removeCountGroups: (props: RemoveCountGroupProps[]) => Promise<void>;
  removeCountGroupAllItems: (
    countPlanId: string,
    groupId: string
  ) => Promise<void>;
  removeCountGroupItem: (
    countPlanId: string,
    groupId: string,
    uniqueId: string
  ) => Promise<void>;
}

const useCountPlanDB = (): UseCountPlanDBResponse => {
  const { db, openDB, closeDB, getLikeStatement } = useInventoryDB();
  const { selectedMiLoc, fromVirtualTeam, team } = useGetSelectedMiLoc();
  const { createParams } = useMiLocOrTeamId({
    miLoc: fromVirtualTeam || team ? undefined : selectedMiLoc.miLoc,
    sendVirtualTeamId: true,
  });

  const createCountPlan = async (countPlan: CountPlan): Promise<string> => {
    try {
      await openDB();
      const response = await db.run(
        `INSERT OR REPLACE INTO count_plan (
        countPlanId,
        countType,
        miLoc,
        groupedLines,
        manualLines,
        manualPages,
        totalLines,
        totalPages,
        totalRows,
        unGroupedLines,
        updatedLines,
        updatedManualLines,
        updatedManualPages,
        updatedPages
        ) VALUES (?,?,?,?,?,?,?,?,?,?,?,?,?,?)`,
        [
          countPlan.countPlanId,
          countPlan.countType,
          countPlan.miLoc,
          countPlan.groupedLines,
          countPlan.manualLines,
          countPlan.manualPages,
          countPlan.totalLines,
          countPlan.totalPages,
          countPlan.totalRows,
          countPlan.unGroupedLines,
          countPlan.updatedLines,
          countPlan.updatedManualLines,
          countPlan.updatedManualPages,
          countPlan.updatedPages,
        ]
      );
      return toString(response.changes?.lastId);
    } catch (error) {
      console.log('Error creating count plan', error);
      throw new Error('Error creating count plan');
    } finally {
      await closeDB();
    }
  };

  const createCountGroups = async (
    countGroups: CountGroup[]
  ): Promise<void> => {
    try {
      await openDB();
      const statements = map(countGroups, (countGroup) => ({
        statement: `INSERT OR REPLACE INTO count_group (
          uniqueId,
          amtPctDiff,
          assignedEmpId,
          assignedEmpId2,
          assignedUserId,
          assignedUserId2,
          completedBy,
          confirmInput,
          consign,
          countCompleted,
          countDownloaded,
          countFinalized,
          countPlanId,
          countPrinted,
          countType,
          countTypeDescription,
          createdBy,
          custAcct,
          customerName,
          customerNo,
          billCustomerNo,
          billCustomerName,
          dateCompleted,
          dateCreated,
          dateDeleted,
          dateDownloaded,
          dateFinalized,
          datePrinted,
          defDeptNo,
          defHdg1,
          defHdg2,
          defHdg3,
          defHdg4,
          defHdg5,
          defaultAcctNo,
          defaultCustomerNo,
          deletedBy,
          description,
          disableOnOrderFl,
          downloadedBy,
          finalizedBy,
          groupId,
          groupType,
          issues,
          itemClassCd,
          labelAtOrd,
          langCd,
          lines,
          linesUpdated,
          locHdg1,
          locHdg2,
          locHdg3,
          locHdg4,
          locHdg5,
          manualLines,
          manualLinesUpdated,
          manualPages,
          manualPagesEntered,
          miLoc,
          model,
          name,
          oneLocFlag,
          optionMaint,
          orderItemLimit,
          orders,
          pages,
          pagesEntered,
          partIdentCD,
          passThru,
          planEndDate,
          planName,
          planStartDate,
          printedBy,
          qtyPctDiff,
          receipts,
          replen,
          seqOpiOrdersBy,
          splitOrdBySrc,
          sortSequenceName,
          localSortField,
          localSortDir,
          startScanCd,
          startedBy,
          status,
          storeroomCustomerNo,
          storeroomName,
          storeroomNo,
          systemLines,
          systemLinesUpdated,
          systemPages,
          systemPagesEntered,
          teamMember1,
          teamMember2,
          timeCompleted,
          timeCreated,
          timeDeleted,
          timeDownloaded,
          timeFinalized,
          timePrinted,
          isDeleted,
          downloadedItems
          ) VALUES (?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?)`,
        values: [
          countGroup.uniqueId,
          countGroup.amtPctDiff,
          countGroup.assignedEmpId,
          countGroup.assignedEmpId2,
          countGroup.assignedUserId,
          countGroup.assignedUserId2,
          countGroup.completedBy,
          countGroup.confirmInput,
          countGroup.consign,
          countGroup.countCompleted,
          countGroup.countDownloaded,
          countGroup.countFinalized,
          countGroup.countPlanId,
          countGroup.countPrinted,
          countGroup.countType,
          countGroup.countTypeDescription,
          countGroup.createdBy,
          countGroup.custAcct,
          countGroup.customerName,
          countGroup.customerNo,
          countGroup.billCustomerNo,
          countGroup.billCustomerName,
          countGroup.dateCompleted,
          countGroup.dateCreated,
          countGroup.dateDeleted,
          countGroup.dateDownloaded,
          countGroup.dateFinalized,
          countGroup.datePrinted,
          countGroup.defDeptNo,
          countGroup.defHdg1,
          countGroup.defHdg2,
          countGroup.defHdg3,
          countGroup.defHdg4,
          countGroup.defHdg5,
          countGroup.defaultAcctNo,
          countGroup.defaultCustomerNo,
          countGroup.deletedBy,
          countGroup.description,
          countGroup.disableOnOrderFl,
          countGroup.downloadedBy,
          countGroup.finalizedBy,
          countGroup.groupId,
          countGroup.groupType,
          countGroup.issues,
          countGroup.itemClassCd,
          countGroup.labelAtOrd,
          countGroup.langCd,
          countGroup.lines,
          countGroup.linesUpdated,
          countGroup.locHdg1,
          countGroup.locHdg2,
          countGroup.locHdg3,
          countGroup.locHdg4,
          countGroup.locHdg5,
          countGroup.manualLines,
          countGroup.manualLinesUpdated,
          countGroup.manualPages,
          countGroup.manualPagesEntered,
          countGroup.miLoc,
          countGroup.model,
          countGroup.name,
          countGroup.oneLocFlag,
          countGroup.optionMaint,
          countGroup.orderItemLimit,
          countGroup.orders,
          countGroup.pages,
          countGroup.pagesEntered,
          countGroup.partIdentCD,
          countGroup.passThru,
          countGroup.planEndDate,
          countGroup.planName,
          countGroup.planStartDate,
          countGroup.printedBy,
          countGroup.qtyPctDiff,
          countGroup.receipts,
          countGroup.replen,
          countGroup.seqOpiOrdersBy,
          countGroup.splitOrdBySrc,
          countGroup.sortSequenceName,
          countGroup.localSortField,
          countGroup.localSortDir,
          countGroup.startScanCd,
          countGroup.startedBy,
          countGroup.status,
          countGroup.storeroomCustomerNo,
          countGroup.storeroomName,
          countGroup.storeroomNo,
          countGroup.systemLines,
          countGroup.systemLinesUpdated,
          countGroup.systemPages,
          countGroup.systemPagesEntered,
          countGroup.teamMember1,
          countGroup.teamMember2,
          countGroup.timeCompleted,
          countGroup.timeCreated,
          countGroup.timeDeleted,
          countGroup.timeDownloaded,
          countGroup.timeFinalized,
          countGroup.timePrinted,
          countGroup.isDeleted,
          countGroup.downloadedItems,
        ],
      }));
      if (size(statements) > 0) {
        await db.executeSet(statements, true);
      }
    } catch (error) {
      console.log('Error creating count plan', error);
      throw new Error('Error creating count plan');
    } finally {
      await closeDB();
    }
  };

  const createCountPlanOptions = async (
    countPlanOptions: CountPlanOptions
  ): Promise<string> => {
    try {
      await openDB();
      const response = await db.run(
        `INSERT OR REPLACE INTO count_plan_option (
        countPlanId,
        canGroupItems,
        consign,
        forceCountAllItems,
        hasEmail,
        hasManualSheets,
        createOrder,
        reviewVariance,
        source
        ) VALUES (?,?,?,?,?,?,?,?,?)`,
        [
          countPlanOptions.countPlanId,
          countPlanOptions.canGroupItems,
          countPlanOptions.consign,
          countPlanOptions.forceCountAllItems,
          countPlanOptions.hasEmail,
          countPlanOptions.hasManualSheets,
          countPlanOptions.createOrder,
          countPlanOptions.reviewVariance,
          countPlanOptions.source,
        ]
      );
      return toString(response.changes?.lastId);
    } catch (error) {
      console.log('Error creating count plan', error);
      throw new Error('Error creating count plan');
    } finally {
      await closeDB();
    }
  };

  const createProfileDefaultSort = async (
    defaultSort: ProfileDefaultSort
  ): Promise<string> => {
    try {
      await openDB();
      const response = await db.run(
        `INSERT OR REPLACE INTO profile_default_sort (
        uniqueId,
        miLoc,
        customerNo,
        sortField,
        sortDir
        ) VALUES (?,?,?,?,?)`,
        [
          defaultSort.uniqueId,
          defaultSort.miLoc,
          defaultSort.customerNo,
          defaultSort.sortField,
          defaultSort.sortDir,
        ]
      );
      return toString(response.changes?.lastId);
    } catch (error) {
      console.log('Error creating profile default sort', error);
      throw new Error('Error creating profile default sort');
    } finally {
      await closeDB();
    }
  };

  const addItemsToCountPlan = async (
    items: CountGroupItem[]
  ): Promise<void> => {
    try {
      await openDB();
      await createGroupItems(db, items);
    } catch (error) {
      console.log('Error adding items to count plan', error);
      throw new Error('Error adding items to count plan');
    } finally {
      await closeDB();
    }
  };

  const findCountPlanById = async (countPlanId: string): Promise<CountPlan> => {
    try {
      await openDB();
      return head(
        (
          await db.query(
            `SELECT *
            FROM count_plan
            WHERE countPlanId = ?`,
            [countPlanId]
          )
        ).values
      ) as CountPlan;
    } catch (error) {
      console.log('Error loading count plan', error);
      throw new Error('Error loading count plan');
    } finally {
      await closeDB();
    }
  };

  const findCountPlanOptionsById = async (
    countPlanId: string
  ): Promise<CountPlanOptions> => {
    try {
      await openDB();
      return head(
        (
          await db.query(
            `SELECT *
            FROM count_plan_option
            WHERE countPlanId = ?`,
            [countPlanId]
          )
        ).values
      ) as CountPlanOptions;
    } catch (error) {
      console.log('Error loading count plan option', error);
      throw new Error('Error loading count plan option');
    } finally {
      await closeDB();
    }
  };

  const findCountGroupByMiLoc = async (
    includeDeleted = true
  ): Promise<CountGroup[]> => {
    const vars = [];
    const { miLoc } = createParams();
    // DOC: on a team location, get all downloaded groups
    if (miLoc) {
      vars.push(miLoc);
    }
    let whereStatement = '';
    if (miLoc || !includeDeleted) {
      whereStatement += 'WHERE\n';
      if (miLoc) {
        whereStatement += 'miLoc = ?\n';
      }
      if (miLoc && !includeDeleted) {
        whereStatement += 'AND\n';
      }
      if (!includeDeleted) {
        whereStatement += 'isDeleted = false\n';
      }
    }
    try {
      await openDB();
      return (
        await db.query(
          `SELECT *
          FROM count_group
          ${whereStatement}`,
          vars
        )
      ).values as CountGroup[];
    } catch (error) {
      console.log('Error loading count groups', error);
      throw new Error('Error loading count groups');
    } finally {
      await closeDB();
    }
  };

  const findCountGroupById = async (
    countPlanId: string,
    groupId: string,
    groupUniqueId: string
  ): Promise<CountGroup> => {
    try {
      await openDB();
      return head(
        (
          await db.query(
            `SELECT *
            FROM count_group
            WHERE countPlanId = ?
            AND groupId = ?
            AND uniqueId = ?`,
            [countPlanId, groupId, groupUniqueId]
          )
        ).values
      ) as CountGroup;
    } catch (error) {
      console.log('Error loading count group', error);
      throw new Error('Error loading count group');
    } finally {
      await closeDB();
    }
  };

  const findProfileDefaultSort = async (
    miLoc: string,
    customerNo: string
  ): Promise<ProfileDefaultSort> => {
    try {
      await openDB();
      return head(
        (
          await db.query(
            `SELECT *
            FROM profile_default_sort
            WHERE miLoc = ?
            AND customerNo = ?`,
            [miLoc, customerNo]
          )
        ).values
      ) as ProfileDefaultSort;
    } catch (error) {
      console.log('Error loading profile default sort', error);
      throw new Error('Error loading profile default sort');
    } finally {
      await closeDB();
    }
  };

  const findItemsByGroup = async (
    countPlanId: string,
    groupId: string,
    searchQuery = '',
    isScanSearch = false,
    hasCount?: boolean,
    sortField?: string,
    sortDir?: string
  ): Promise<CountGroupItem[]> => {
    try {
      let whereStatement = '';
      const vars: unknown[] = [countPlanId, groupId];
      if (!isNil(hasCount)) {
        vars.push(hasCount);
      }
      const query = trim(searchQuery);
      if (query) {
        whereStatement = `AND ${getLikeStatement(
          [
            'upcNo',
            'barcode',
            'custStockNo',
            'itemNo',
            ...(isScanSearch
              ? [
                  `(COALESCE(custStockNo,'') ||
                    COALESCE(loc1,'') ||
                    COALESCE(loc2,'') ||
                    COALESCE(loc3,'') ||
                    COALESCE(loc4,'') ||
                    COALESCE(loc5,''))`,
                ]
              : ['description', 'bin', 'mfrCtlNo', 'mino']),
          ],
          [],
          isScanSearch
        )}`;
        const queryLike = isScanSearch ? trim(query) : `%${query}%`;
        vars.push(queryLike, queryLike, queryLike, queryLike);
        if (isScanSearch) {
          vars.push(queryLike);
        } else {
          vars.push(queryLike, queryLike, queryLike, queryLike);
        }
      }
      await openDB();
      return (
        await db.query(
          `SELECT *
          FROM count_plan_item
          WHERE countPlanId = ?
          AND groupId = ?
          ${!isNil(hasCount) ? 'AND hasCount = ?' : ''}
          ${whereStatement}
          ORDER BY
          ${sortField || 'pageNo, lineNo'}
          ${sortDir || 'ASC'}`,
          vars
        )
      ).values as CountGroupItem[];
    } catch (error) {
      console.log('Error loading count group items', error);
      throw new Error('Error loading count group items');
    } finally {
      await closeDB();
    }
  };

  const findTotalItemsByGroup = async (
    countPlanId: string,
    groupId: string,
    hasCount?: boolean
  ): Promise<number> => {
    try {
      const vars: unknown[] = [countPlanId, groupId];
      if (!isNil(hasCount)) {
        vars.push(hasCount);
      }
      await openDB();
      return toNumber(
        get(
          await db.query(
            `SELECT COUNT(uniqueId) as total
            FROM count_plan_item
            WHERE countPlanId = ?
            AND groupId = ?
            ${!isNil(hasCount) ? 'AND hasCount = ?' : ''}`,
            vars
          ),
          'values.0.total'
        )
      );
    } catch (error) {
      console.log('Error loading count group items', error);
      throw new Error('Error loading count group items');
    } finally {
      await closeDB();
    }
  };

  const updateCountGroups = async (
    items: UpdateCountGroupProps[]
  ): Promise<void> => {
    try {
      await openDB();
      const statements = map(
        items,
        ({
          groupUniqueId,
          billCustomerNo,
          billCustomerName,
          lines,
          linesUpdated,
          isDeleted,
          downloadedItems,
          sortField,
          sortDir,
        }) => {
          const vars = [];
          if (billCustomerNo) {
            vars.push(billCustomerNo);
          }
          if (billCustomerName) {
            vars.push(billCustomerName);
          }
          if (lines) {
            vars.push(lines);
          }
          if (linesUpdated) {
            vars.push(linesUpdated);
          }
          if (!isNil(isDeleted)) {
            vars.push(isDeleted);
          }
          if (downloadedItems) {
            vars.push(downloadedItems);
          }
          if (sortField) {
            vars.push(sortField);
          }
          if (sortDir) {
            vars.push(sortDir);
          }
          return {
            statement: `UPDATE count_group
              SET uniqueId = ?
              ${billCustomerNo ? ',\nbillCustomerNo = ?' : ''}
              ${billCustomerName ? ',\nbillCustomerName = ?' : ''}
              ${lines ? ',\nlines = ?' : ''}
              ${linesUpdated ? ',\nlinesUpdated = ?' : ''}
              ${!isNil(isDeleted) ? ',\nisDeleted = ?' : ''}
              ${downloadedItems ? ',\ndownloadedItems = ?' : ''}
              ${sortField ? ',\nlocalSortField = ?' : ''}
              ${sortDir ? ',\nlocalSortDir = ?' : ''}
              WHERE uniqueId = ?`,
            values: [groupUniqueId, ...vars, groupUniqueId],
          };
        }
      );
      if (size(statements) > 0) {
        await db.executeSet(statements, true);
      }
    } catch (error) {
      console.log('Error updating count group', error);
      throw new Error('Error updating count group');
    } finally {
      await closeDB();
    }
  };

  const updateItemCount = async (
    itemId: string,
    actualCount: string,
    orderQuantity: number,
    hasCount: boolean,
    width?: number,
    length?: number,
    hasLocalCount?: boolean
  ): Promise<void> => {
    try {
      await openDB();
      await db.query(
        `UPDATE count_plan_item
        SET actualCount = ?,
        orderQty = ?,
        hasCount = ?,
        actualItemWidth = ?,
        actualItemLength = ?,
        hasLocalCount = ?
        WHERE uniqueId = ?`,
        [
          actualCount,
          orderQuantity,
          hasCount,
          width || null,
          length || null,
          hasLocalCount,
          itemId,
        ]
      );
    } catch (error) {
      console.log('Error updating count group item count', error);
      throw new Error('Error updating count group item count');
    } finally {
      await closeDB();
    }
  };

  const updateMinMaxCount = async (
    itemId: string,
    miMinQty: number,
    miMaxQty: number,
    hasEditedMinMax: boolean,
    orderQuantity: number
  ): Promise<void> => {
    try {
      await openDB();
      await db.query(
        `UPDATE count_plan_item
        SET miMinQty = ?,
        miMaxQty = ?,
        hasEditedMinMax = ?,
        orderQty = ?
        WHERE uniqueId = ?`,
        [miMinQty, miMaxQty, hasEditedMinMax, orderQuantity, itemId]
      );
    } catch (error) {
      console.log('Error updating min/max Quantity', error);
      throw new Error('Error updating min/max Quantity');
    } finally {
      await closeDB();
    }
  };

  const addZeroCounts = async (groupId: string): Promise<number> => {
    try {
      await openDB();
      const response = await db.executeSet([
        {
          statement: `UPDATE count_plan_item
          SET actualCount = ?,
          hasCount = ?,
          hasLocalCount = ?
          WHERE groupId = ?
          AND hasCount = ?`,
          values: [0, true, true, groupId, false],
        },
      ]);
      return toNumber(response.changes?.changes);
    } catch (error) {
      console.log('Error updating count group item count', error);
      throw new Error('Error updating count group item count');
    } finally {
      await closeDB();
    }
  };

  const removeCountGroups = async (
    items: RemoveCountGroupProps[]
  ): Promise<void> => {
    try {
      await openDB();
      const statements: capSQLiteSet[] = [];
      map(items, ({ countPlanId, groupId }) => {
        statements.push({
          statement: `DELETE FROM count_plan_item
            WHERE countPlanId = ?
            AND groupId = ?`,
          values: [countPlanId, groupId],
        });
        statements.push({
          statement: `DELETE FROM count_group
            WHERE countPlanId = ?
            AND uniqueId = ?`,
          values: [countPlanId, groupId],
        });
        statements.push({
          statement: `DELETE FROM count_plan_option
            WHERE countPlanId = ?`,
          values: [countPlanId],
        });
        statements.push({
          statement: `DELETE FROM count_plan
            WHERE countPlanId = ?`,
          values: [countPlanId],
        });
      });
      if (size(statements) > 0) {
        await db.executeSet(statements, true);
      }
    } catch (error) {
      console.log('Error removing count plan', error);
      throw new Error('Error removing count plan');
    } finally {
      await closeDB();
    }
  };

  const removeCountGroupAllItems = async (
    countPlanId: string,
    groupId: string
  ): Promise<void> => {
    try {
      await openDB();
      await db.query(
        `DELETE FROM count_plan_item
        WHERE countPlanId = ?
        AND groupId = ?`,
        [countPlanId, groupId]
      );
    } catch (error) {
      console.log('Error removing count plan group items', error);
      throw new Error('Error removing count plan group items');
    } finally {
      await closeDB();
    }
  };

  const removeCountGroupItem = async (
    countPlanId: string,
    groupId: string,
    uniqueId: string
  ): Promise<void> => {
    try {
      await openDB();
      await db.query(
        `DELETE FROM count_plan_item
        WHERE countPlanId = ?
        AND groupId = ? and uniqueId = ?`,
        [countPlanId, groupId, uniqueId]
      );
    } catch (error) {
      console.log('Error removing count plan group item', error);
      throw new Error('Error removing count plan group item');
    } finally {
      await closeDB();
    }
  };

  return {
    createCountPlan,
    createCountGroups,
    createCountPlanOptions,
    createProfileDefaultSort,
    addItemsToCountPlan,
    findCountPlanById,
    findCountPlanOptionsById,
    findCountGroupByMiLoc,
    findCountGroupById,
    findProfileDefaultSort,
    findItemsByGroup,
    findTotalItemsByGroup,
    updateCountGroups,
    updateItemCount,
    updateMinMaxCount,
    addZeroCounts,
    removeCountGroups,
    removeCountGroupItem,
    removeCountGroupAllItems,
  };
};

export default useCountPlanDB;
