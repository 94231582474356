import { useSelector } from 'react-redux';
import type { AxiosError } from 'axios';
import type { Dictionary } from 'lodash';
import { toString, get, isEmpty } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { doPromiseAPI, useKeyUserId } from 'api/helpers';
import type { SignInResponse } from 'models/Signin';
import type { RootState } from 'store/reducers';

const getAccessControlQueryKey = 'access-control';

type AccessControl = Pick<SignInResponse, 'accessControl'>;

interface UseGetAccessControlResponse {
  data?: Dictionary<unknown>;
}

const useGetAccessControl = (
  miLocItem?: string
): UseGetAccessControlResponse => {
  const { axios } = useAxios();
  const { accessControlAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const {
    userInfo,
    miLoc = '',
    locationTree,
  } = useSelector((state: RootState) => state.user);
  const employeeId = get(userInfo, 'employeenumber', '');
  const location = locationTree?.[miLocItem || miLoc];
  let miLocParam = miLocItem || miLoc;
  if (miLocParam === 'VT') {
    miLocParam = 'virtualteam';
  }

  const doGetAccessControl = () => {
    return doPromiseAPI<AccessControl>(async () => {
      const { data } = await axios.get<AccessControl>(
        accessControlAPI(miLocParam)
      );
      return data;
    });
  };

  const { data } = useQuery<AccessControl, AxiosError>(
    createQueryKey(getAccessControlQueryKey, { employeeId, miLoc: miLocParam }),
    doGetAccessControl,
    {
      enabled:
        !isEmpty(employeeId) &&
        ['T', 'VT', 'virtualteam'].includes(toString(location?.locationType)),
    }
  );

  return { data };
};

export default useGetAccessControl;
