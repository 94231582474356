import { useMemo } from 'react';
import type { AxiosError } from 'axios';
import type { Dictionary } from 'lodash';
import { size, toString } from 'lodash';
import { useInfiniteQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import type { CostSavingsCode } from 'CostSavingsApp/models/CostSavings';
import { useAxios } from 'providers/AxiosProvider';
import {
  doConcatDataPages,
  doGetIsLoading,
  doPromiseAPI,
  useKeyUserId,
  useMiLocOrTeamId,
} from 'api/helpers';
import type { InfiniteQueryFlags } from 'models/Search';
import { pageSize } from 'utils/constants';

export const findCostCodesQueryKey = 'cost-codes';

interface UseFindCostCodesProps {
  query?: string;
  limit?: number;
  enabled?: boolean;
}

interface UseFindCostCodesResponse {
  vasCodes: CostSavingsCode[];
}

const useFindCostCodes = ({
  query,
  enabled,
  limit = pageSize(),
}: UseFindCostCodesProps): UseFindCostCodesResponse & InfiniteQueryFlags => {
  const { axios } = useAxios();
  const { findCostCodesAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const { createParams } = useMiLocOrTeamId({ sendTeamId: false });
  const params: Dictionary<string> = {
    ...createParams(),
    fastFind: toString(query),
    limit: toString(limit),
  };

  const doFindVasCodes = ({ pageParam = 1 }) => {
    return doPromiseAPI<CostSavingsCode[]>(async (cancelToken) => {
      // TODO: this should not be needed, something is triggering a call with page = false
      if (pageParam) {
        const { data } = await axios.get<CostSavingsCode[]>(
          findCostCodesAPI(
            toString(
              new URLSearchParams({ ...params, page: toString(pageParam) })
            )
          ),
          { cancelToken }
        );
        return data;
      }
      return [];
    });
  };

  const response = useInfiniteQuery<CostSavingsCode[], AxiosError>(
    createQueryKey(findCostCodesQueryKey, params),
    doFindVasCodes,
    {
      enabled,
      getNextPageParam: (lastPage, pages) =>
        size(lastPage) < limit ? false : size(pages) + 1,
    }
  );

  const {
    data,
    error,
    status,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = response;

  const vasCodes = useMemo(
    () => doConcatDataPages<CostSavingsCode>(data),
    [data]
  );

  const hasItems = size(vasCodes) > 0;
  const isEmptyResponse = status === 'success' && !hasNextPage && !hasItems;
  const noMoreData = status === 'success' && !hasNextPage && hasItems;
  const showLoader = doGetIsLoading(response) || isFetchingNextPage;
  const enableInfiniteScroll = !(!hasNextPage || isFetchingNextPage);

  return {
    fetchNextPage: async () => {
      await fetchNextPage();
    },
    refetch: async () => {
      await refetch();
    },
    vasCodes,
    error,
    showLoader,
    isEmptyResponse,
    noMoreData,
    enableInfiniteScroll,
  };
};

export default useFindCostCodes;
