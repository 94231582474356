import React, { useMemo, useState } from 'react';
import {
  escapeRegExp,
  filter,
  isEmpty,
  isNil,
  join,
  map,
  size,
  toString,
} from 'lodash';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { useDebounce } from 'use-debounce';
import type Input from 'components/Input/Input';
import FastFind from './FastFind';

interface GenericItem {
  id: string;
  text: string;
}

interface GenericFastFindProps {
  options?: GenericItem[];
  modalTitle?: string;
  values?: string[];
  setValues?: (v: string[]) => void;
  multiple?: boolean;
}

const GenericFastFind = ({
  multiple,
  values,
  options: pOptions,
  label,
  value,
  required,
  disabled,
  setValue,
  setValues,
  modalTitle,
  testid,
}: GenericFastFindProps &
  React.ComponentProps<typeof Input> &
  IonicReactProps): JSX.Element => {
  const [searchQuery, setSearchQuery] = useState(toString(value));
  const [searchQueryValue] = useDebounce(searchQuery, 300);

  const options = useMemo(
    () =>
      filter(
        pOptions,
        (v) =>
          isEmpty(searchQueryValue) ||
          !isNil(
            new RegExp(escapeRegExp(searchQueryValue), 'i').exec(
              toString(v.text)
            )
          )
      ),
    [pOptions, searchQueryValue]
  );

  return (
    <FastFind<GenericItem>
      items={options}
      getId={(i) => i.id}
      getSelectedText={(i) => i.text}
      getTitle={(i) => i.text}
      getMultipleSelectedText={(i) =>
        join(
          map(i, (v) => v.text),
          ', '
        )
      }
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      showLoader={false}
      isEmptyResponse={size(options) === 0}
      label={label}
      modalTitle={modalTitle}
      value={value}
      values={values}
      required={required}
      disabled={disabled}
      setValue={setValue}
      setValues={setValues}
      multiple={multiple}
      testid={testid}
    />
  );
};

export default GenericFastFind;
