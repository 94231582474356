import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { defaultExpectedDate } from 'constants/platformSpecificConstants';
import { debounce, filter, find, findIndex, size, toString } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { IonItemSlidingCustomEvent } from '@ionic/core';
import {
  IonContent,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonPage,
  IonRow,
} from '@ionic/react';
import { useQueryClient } from '@tanstack/react-query';
import Alert, { AlertVariantEnum } from 'common/components/Alert/Alert';
import Header from 'common/components/Header/Header';
import { useIonNavContext } from 'common/navigation/navigationHelpers';
import { FormikProvider, useFormik } from 'formik';
import { namespaces } from 'i18n/i18n.constants';
import { findOcnLineStatusQueryKey } from 'ProductSearchApp/api/orders/useFindOcnLineStatus';
import useGetOrder from 'ProductSearchApp/api/useGetOrder';
import useUpdateToCart from 'ProductSearchApp/api/useUpdateToCart';
import OcnLineShippingSections from 'ProductSearchApp/components/OcnLineShippingSections/OcnLineShippingSections';
import ProductHeader from 'ProductSearchApp/components/ProductHeader/ProductHeader';
import type { ProductDetailURLParams } from 'ProductSearchApp/models/Products';
import {
  canEditOCN,
  cancelWoStatus,
  getOcnType,
  getProductFromOcnLine,
  isCancelledItem,
  getOrderLineItemPayload,
} from 'ProductSearchApp/util/ocnHelpers';
import useGetCustomer from 'api/customer/useGetCustomer';
import { DateFormatEnum, formatDate, parseDate } from 'utils/date';
import { getErrorMessage } from 'utils/helpers';
import { removeLeadingZeros } from 'utils/number';
import {
  goToLineShippingDetail,
  goToReviewCart,
} from 'navigation/navigationHelpers';
import Button from 'components/Button/Button';
import DatePickerModal from 'components/DatePickerModal/DatePickerModal';
import Loader from 'components/Loader/Loader';
import Refresher from 'components/Refresher/Refresher';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './LineShippingDetails.module.scss';

interface LineExpectedDateProps {
  expectedDate: string;
}

const LineShippingDetails = (): JSX.Element => {
  const { navigate, goBack } = useIonNavContext();
  const { miLoc, ocn, lineNo, id } = useParams<ProductDetailURLParams>();
  const { t } = useTranslation(namespaces.product);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const queryClient = useQueryClient();

  const {
    order,
    error: orderError,
    isLoading: isOrderLoading,
  } = useGetOrder({ miLoc, orderCtlNo: ocn });

  const {
    data: customerData,
    error: customerError,
    isLoading: customerLoading,
  } = useGetCustomer({ searchType: 'customer', miLoc, id });

  const lineItem = find(order?.items, { orderLineNo: toString(lineNo) });

  const { status, onUpdateToCart } = useUpdateToCart({
    miLoc,
    orderCtlNo: ocn,
    shipToCustNo: order?.shipToCustNo,
    billToCustNo: order?.billToCustNo,
  });

  const isCancelledWo = cancelWoStatus(lineItem?.itemSourcing);
  const disabledEditable =
    !lineItem?.isEditable || isCancelledWo || !canEditOCN(order);
  const { promsdDelivDt } = lineItem || {};

  const formik = useFormik<LineExpectedDateProps>({
    initialValues: {
      expectedDate: toString(promsdDelivDt),
    },
    enableReinitialize: true,
    onSubmit: ({ expectedDate }) => {
      if (expectedDate !== lineItem?.promsdDelivDt) {
        const payloadItems = {
          ...getOrderLineItemPayload(lineItem),
          promsdDelivDt: expectedDate,
          itemInfoChange: 'Y',
        };

        onUpdateToCart({
          itemList: [payloadItems],
        });
      }
    },
    validateOnChange: true,
  });
  const { values, errors } = formik;
  const expectedDate = values?.expectedDate || promsdDelivDt;
  const expectedDateShown =
    expectedDate === defaultExpectedDate ? '' : expectedDate;

  let pickerDate: Parameters<typeof parseDate>[0] = expectedDateShown;
  pickerDate ||= new Date();
  const datePickerDate = parseDate(pickerDate);

  useEffect(() => {
    if (status === 'error') {
      void formik?.resetForm({
        values: { expectedDate: toString(lineItem?.promsdDelivDt) },
      });
    }
  }, [expectedDateShown, formik, lineItem?.promsdDelivDt, status]);

  const isLoadingHeader = customerLoading || isOrderLoading;
  const pageTitle = t(`productSearch:ocn:${getOcnType(order)}`);
  const error = customerError || orderError;

  const items = filter(
    order?.items,
    (item) =>
      !cancelWoStatus(item.itemSourcing) &&
      !isCancelledItem(item.itemStatusDetailDesc)
  );

  const navigateItem = (next = true) => {
    const index = findIndex(items, {
      orderLineNo: lineNo,
    });
    if (
      (next && index === size(items) - 1) ||
      (!next && index === 0) ||
      index === -1
    ) {
      goBack(
        goToReviewCart({
          miLoc,
          shipToCustNo: id,
          orderCtlNo: ocn,
          ocnType: getOcnType(order),
          ocnMode: 'review',
        })
      );
    } else {
      const navItem = items[index + 1 * (next ? 1 : -1)];
      navigate(
        goToLineShippingDetail({
          miLoc,
          orderCtlNo: ocn,
          shipToCustNo: id,
          lineNo: navItem.orderLineNo,
          ocnMode: 'review',
          ocnType: getOcnType(order),
          productId: navItem.itemNo,
        }),
        next ? 'forward' : 'back',
        'replace'
      );
    }
  };

  const prevClick = debounce(() => {
    setTimeout(() => navigateItem(false), 0);
  }, 300);

  const nextClick = debounce(() => {
    setTimeout(() => navigateItem(true), 0);
  }, 300);

  const onDrag = async (e: IonItemSlidingCustomEvent<unknown>) => {
    const pixelRatio = await e.target.getOpenAmount();
    if (pixelRatio < -125) {
      prevClick();
      await e.target.close();
    } else if (pixelRatio > 125) {
      nextClick();
      await e.target.close();
    }
  };

  const lineShippingDetailContent = (
    <IonItemSliding className={classes.itemSlider} onIonDrag={onDrag}>
      <IonItemOptions side="start">
        <IonItemOption className={classes.itemOption} expandable>
          <FontAwesomeIcon
            className={classes.prevOption}
            icon={['far', 'chevron-left']}
            data-testid="prev-slide-option"
          />
        </IonItemOption>
      </IonItemOptions>
      <IonItem className={classes.item} lines="none">
        <div className={classes.container}>
          <div className={classes.shippingHeader}>
            <ProductHeader
              lineNumber={toString(lineItem?.orderLineNo)}
              productData={getProductFromOcnLine(lineItem)}
              testid="product-detail-header"
              badge={{
                status: lineItem?.itemStatusDetailDesc,
                isCancelledWo,
              }}
            />
            <IonRow className={classes.expectedDateRow}>
              <Text
                text={t('productSearch:lineShipping:expectedDate', {
                  expectedDate:
                    expectedDateShown === ''
                      ? t(`productSearch:review:notSet`)
                      : formatDate(
                          expectedDateShown,
                          DateFormatEnum.dayShortMonth
                        ),
                })}
                variant="mipro-h3-headline"
              />
              <FormikProvider value={formik}>
                {!disabledEditable && (
                  <>
                    <Button
                      variant="link"
                      textVariant="mipro-body-copy-bold"
                      className={classes.changeDate}
                      icon={['far', 'calendar-days']}
                      iconClassName={classes.calendarIcon}
                      text={t('productSearch:lineShipping:changeDate')}
                      testid="expected-date-date-picker"
                      onClick={() => {
                        if (disabledEditable) {
                          return;
                        }
                        setIsCalendarOpen(true);
                      }}
                    />
                    {errors.expectedDate && (
                      <Alert
                        testid="expectedDate-error"
                        text={{
                          text: errors.expectedDate,
                          testid: 'content-small',
                        }}
                        variant={AlertVariantEnum.danger}
                        className={classes.error}
                      />
                    )}
                  </>
                )}
              </FormikProvider>
            </IonRow>
          </div>
          <OcnLineShippingSections
            miLoc={miLoc}
            order={order}
            orderLineNo={lineNo}
            customer={customerData}
          />
        </div>
        {!disabledEditable && (
          <DatePickerModal
            isOpen={isCalendarOpen}
            setIsOpen={setIsCalendarOpen}
            onDone={async (date) => {
              await formik.setFieldValue(
                'expectedDate',
                formatDate(date, DateFormatEnum.standardDate)
              );
              formik.handleSubmit();
            }}
            minDate={new Date()}
            date={datePickerDate}
            title={t('productSearch:checkout:expectedDt')}
            testid="expected-date-calendar"
          />
        )}
      </IonItem>
      <IonItemOptions side="end">
        <IonItemOption className={classes.itemOption} expandable>
          <FontAwesomeIcon
            className={classes.nextOption}
            icon={['far', 'chevron-right']}
            data-testid="next-slide-option"
          />
        </IonItemOption>
      </IonItemOptions>
    </IonItemSliding>
  );

  const pageContent = error ? (
    <WarningMessage
      className={classes.warningMessage}
      title={t(`productSearch:ocn:errorOcn`, {
        ocn: removeLeadingZeros(ocn),
      })}
      body={getErrorMessage(error)}
      testid="error-holder"
    />
  ) : (
    lineShippingDetailContent
  );

  return (
    <IonPage
      className={classes.productDetail}
      data-testid="ProductDetailNew-page"
    >
      <Header
        testid="line-shipping-details"
        hideMenuButton
        title={
          !isLoadingHeader ? `${pageTitle}: ${removeLeadingZeros(ocn)}` : ''
        }
        subTitle={!isLoadingHeader ? customerData?.name : ''}
      />
      <IonContent>
        <Refresher
          hidden
          slot="fixed"
          onRefresh={async () => {
            await Promise.all([
              queryClient.invalidateQueries([findOcnLineStatusQueryKey]),
            ]);
          }}
          disabled={isLoadingHeader}
          testid="line-shipping-details-refresher"
        />
        {isLoadingHeader ? (
          <Loader
            className={classes.loader}
            text={t('common:loading')}
            isOpen={isLoadingHeader}
          />
        ) : (
          pageContent
        )}
      </IonContent>
    </IonPage>
  );
};

export default LineShippingDetails;
