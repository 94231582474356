import { useMemo } from 'react';
import type { AxiosError } from 'axios';
import { size } from 'lodash';
import { useInfiniteQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import type {
  QueryFlags,
  QueryFnProps,
  QueryParamsType,
} from 'common/api/utils/useGetQueryFlags';
import useGetQueryFlags from 'common/api/utils/useGetQueryFlags';
import { useAxios } from 'providers/AxiosProvider';
import { doConcatDataPages, useKeyUserId, useMiLocOrTeamId } from 'api/helpers';
import { pageSize } from 'utils/constants';

export interface UomType {
  codeDesc: string;
  codeValue: string;
}

export const findUomTypesQueryKey = 'uom-types';

interface UseFindUomTypesProps {
  query?: string;
  limit?: number;
  enabled?: boolean;
}

interface UseFindUomTypesResponse {
  uomTypes: UomType[];
}

const useFindUomTypes = ({
  query,
  limit = pageSize(),
  enabled = true,
}: UseFindUomTypesProps): UseFindUomTypesResponse & QueryFlags => {
  const { axios } = useAxios();
  const { findUOMTypesAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const { getURLParams } = useMiLocOrTeamId({});

  const params: QueryParamsType = {
    limit,
    fastFind: query,
    sortField: 'codeValue',
    sortDir: 'ASC',
  };

  const doFindUOMTypes = async ({ pageParam = 1, signal }: QueryFnProps) => {
    const { data } = await axios.get<UomType[]>(
      findUOMTypesAPI(getURLParams({ ...params, page: pageParam })),
      { signal }
    );
    return data;
  };

  const response = useInfiniteQuery<UomType[], AxiosError>(
    createQueryKey(findUomTypesQueryKey, params),
    doFindUOMTypes,
    {
      enabled,
      getNextPageParam: (lastPage, pages) =>
        size(lastPage) < limit ? false : size(pages) + 1,
    }
  );

  const { data } = response;

  const uomTypes = useMemo(() => doConcatDataPages<UomType>(data), [data]);

  const queryFlags = useGetQueryFlags({ ...response, data: uomTypes, enabled });

  return { uomTypes, ...queryFlags };
};

export default useFindUomTypes;
