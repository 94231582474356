import { toNumber, toString } from 'lodash';
import { isMultiple } from 'common/utils/numberHelper';
import i18next from 'i18n/i18n';

interface UseGetMtqOrMoqMessageResponse {
  minOrderQtyMessage?: string;
}

export interface MinimumOrderQty {
  minimumOrderQty?: string;
  orderIncrement?: string;
  brDefaultFlag?: string;
  transferMinQty?: string;
  dcDefaultFlag?: string;
  quantity?: number;
}

const useGetMtqOrMoqMessage = ({
  minimumOrderQty,
  orderIncrement,
  brDefaultFlag,
  transferMinQty,
  dcDefaultFlag,
  quantity,
}: MinimumOrderQty): UseGetMtqOrMoqMessageResponse => {
  let minOrderQtyMessage;
  const mtq = toNumber(transferMinQty);
  const moq = toNumber(minimumOrderQty);
  if (mtq !== 1 && !isMultiple({ a: quantity, b: transferMinQty })) {
    minOrderQtyMessage = i18next.t(
      'productSearch:ocn:minimumOrderQtyWarningMessage',
      {
        transferOrMinQty: mtq,
        transferOrIncrementQty: mtq,
      }
    );
  }

  if (
    mtq === 1 &&
    (moq !== 1 || toNumber(orderIncrement) !== 1) &&
    (brDefaultFlag === 'Y' || dcDefaultFlag === 'Y') &&
    (toNumber(quantity) < moq || toNumber(quantity) > moq) &&
    !isMultiple({ a: quantity, b: orderIncrement })
  ) {
    minOrderQtyMessage = i18next.t(
      'productSearch:ocn:minimumOrderQtyWarningMessage',
      {
        transferOrMinQty: moq,
        transferOrIncrementQty: toString(orderIncrement),
      }
    );
  }

  return { minOrderQtyMessage };
};

export default useGetMtqOrMoqMessage;
