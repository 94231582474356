import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  addDays,
  addHours,
  addMinutes,
  getUnixTime,
  isFriday,
  isThursday,
  isWeekend,
  startOfDay,
  startOfHour,
  startOfWeek,
} from 'date-fns';
import { namespaces } from 'i18n/i18n.constants';
import type { SnoozedData } from 'models/SnoozedData';
import { DateFormatEnum, formatDate } from 'utils/date';
import { findIcon } from 'utils/icons';
import ActionRow from 'components/ActionRow/ActionRow';
import DatePickerModal from 'components/DatePickerModal/DatePickerModal';
import type Modal from 'components/Modal/Modal';
import SheetModal from 'components/Modals/SheetModal/SheetModal';
import Text from 'components/Text/Text';
import classes from './SnoozeModal.module.scss';

interface OptionsType {
  text: string;
  value: number;
}

interface SnoozeModalProps {
  onSnooze?: (v: number) => void;
  onUnSnooze?: () => void;
  snoozedData?: SnoozedData;
}

const SnoozeModal = ({
  isOpen,
  setIsOpen,
  onSnooze,
  onUnSnooze,
  onClose,
  snoozedData,
  testid,
}: SnoozeModalProps &
  Omit<React.ComponentProps<typeof Modal>, 'title'>): JSX.Element => {
  const { t, i18n } = useTranslation(namespaces.activities);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [customDate, setCustomDate] = useState<Date>();

  const options: OptionsType[] = useMemo(() => {
    const result: OptionsType[] = [];
    const today = new Date();
    if (isOpen) {
      result.push({
        text: t('laterToday'),
        value: getUnixTime(startOfHour(addHours(today, 3))),
      });
      if (!isFriday(today) && !isWeekend(today)) {
        result.push({
          text: t('tomorrow'),
          value: getUnixTime(
            startOfHour(addHours(startOfDay(addDays(today, 1)), 7))
          ),
        });
      }
      if (!isThursday(today) && !isFriday(today) && !isWeekend(today)) {
        result.push({
          text: t('laterThisWeek'),
          value: getUnixTime(
            startOfHour(addHours(startOfDay(addDays(today, 2)), 7))
          ),
        });
      }
      result.push({
        text: t('nextWeek'),
        value: getUnixTime(
          startOfHour(
            addHours(startOfWeek(addDays(today, 7), { weekStartsOn: 1 }), 7)
          )
        ),
      });
    }
    return result;
  }, [isOpen, t]);

  return (
    <>
      <SheetModal
        className={classes.modal}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        withRightCloseButton
        title={snoozedData?.isSnoozed ? t('editSnooze') : t('snoozeUntil')}
        titleClassName={classes.title}
        onWillPresent={() => setCustomDate(addMinutes(new Date(), 15))}
        onClose={onClose}
        testid={testid}
      >
        <div>
          {map(options, ({ text, value }, index) => (
            <ActionRow
              className={classes.detail}
              key={index}
              leftButton={{
                variant: 'link',
                text,
                className: classes.label,
                testid: 'snooze-option-left-button',
              }}
              onClick={() => {
                onSnooze?.(value);
                setIsOpen?.(false);
              }}
              testid="snooze-option"
            >
              <Text
                className={classes.label}
                text={formatDate(
                  value,
                  DateFormatEnum.snoozeTime,
                  i18n.language
                )}
              />
            </ActionRow>
          ))}
          <ActionRow
            className={classes.detail}
            leftButton={{
              variant: 'link',
              testid: `${testid}-custom-left-button`,
              icon: 'calendar-days',
            }}
            onClick={() => {
              setShowDatePicker(true);
            }}
            testid={`${testid}-custom-button`}
          >
            <Text
              className={classes.text}
              testid={`${testid}-custom-left-button`}
              text={t('snoozeCustomLabel')}
            />
          </ActionRow>
          {snoozedData?.isSnoozed && (
            <ActionRow
              className={classes.detail}
              leftButton={{
                variant: 'link',
                text: t('unsnoozeAlert'),
                testid: 'unsnooze-left-button',
              }}
              onClick={() => {
                onUnSnooze?.();
                setIsOpen?.(false);
              }}
              testid="unsnooze-button"
            >
              <FontAwesomeIcon
                className={classes.icon}
                icon={findIcon('alarm-exclamation')}
              />
            </ActionRow>
          )}
        </div>
      </SheetModal>
      <DatePickerModal
        showTimeSelect
        testid="snooze-modal"
        minDate={customDate}
        isOpen={showDatePicker}
        setIsOpen={setShowDatePicker}
        date={customDate}
        onDone={(d) => {
          onSnooze?.(getUnixTime(d));
          setIsOpen?.(false);
        }}
      />
    </>
  );
};

export default SnoozeModal;
