import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { AxiosError } from 'axios';
import type { Dictionary } from 'lodash';
import { size, toString, toNumber, head, find, map, join } from 'lodash';
import { useIonViewWillEnter } from '@ionic/react';
import { useInfiniteQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { getUnixTime } from 'date-fns';
import type {
  RemoveCountGroupProps,
  UpdateCountGroupProps,
} from 'InventoryApp/database/useCountPlanDB';
import useCountPlanDB from 'InventoryApp/database/useCountPlanDB';
import type { CountGroup } from 'InventoryApp/models/InventoryPlanGroup';
import { getLocationString } from 'InventoryApp/util/inventoryUtil';
import { useAxios } from 'providers/AxiosProvider';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import { useToasts } from 'providers/ToastProvider';
import {
  doConcatDataPages,
  doGetIsLoading,
  doPromiseAPI,
  onDownloadData,
  useKeyUserId,
  useMiLocOrTeamId,
} from 'api/helpers';
import type { InfiniteQueryFlags } from 'models/Search';

export const findCountGroupsQueryKey = 'count-groups';

interface UseFindCountGroupsProps {
  offlineOnly?: boolean;
  query?: string;
  sendVirtualTeamId?: boolean;
  countType?: string[];
  countStatus?: string;
  enabled?: boolean;
}

interface UseGetCountGroupAPIResponse {
  groups: CountGroup[];
  totalRows: number;
}

interface UseFindCountGroupsResponse {
  total?: number;
  groups?: CountGroup[];
  loc?: string;
}

const useFindCountGroups = ({
  offlineOnly,
  query = '',
  sendVirtualTeamId = true,
  countType,
  countStatus,
  enabled = true,
}: UseFindCountGroupsProps): InfiniteQueryFlags &
  UseFindCountGroupsResponse => {
  const { axios } = useAxios();
  const { isOnline } = useNetworkStatus();
  const { createQueryKey } = useKeyUserId();
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const {
    findCountGroupByMiLoc,
    createCountGroups,
    updateCountGroups,
    removeCountGroups,
  } = useCountPlanDB();
  const { findCountGroupsAPI } = useAPIUrl();
  const { createParams } = useMiLocOrTeamId({ sendVirtualTeamId });
  const params: Dictionary<string | string[]> = {
    ...createParams(),
    countStatus: toString(countStatus),
    offlineOnly: toString(offlineOnly),
    query,
  };

  const { miLoc = '', teamId = '' } = params;
  const loc = getLocationString(miLoc || teamId || []);

  if (countType) {
    params.countType = countType;
  }

  const doFindGroups = async () => {
    return doPromiseAPI<UseFindCountGroupsResponse>(async () => {
      const deletedGroupList: CountGroup[] = [];
      const offlineGroups = await findCountGroupByMiLoc();

      if (isOnline && !offlineOnly) {
        const { syncData: onlineGroups } = await onDownloadData<CountGroup>({
          customAxios: axios,
          method: 'get',
          getData: (r) => (r as UseGetCountGroupAPIResponse).groups,
          getAPIUrl: findCountGroupsAPI,
          params,
        });

        // TODO: optimize this logic, update instead of create and only if there are differences
        const createGroups: CountGroup[] = [];
        map(onlineGroups, (onlineGroup) => {
          const offlineGroup = find(offlineGroups, {
            uniqueId: onlineGroup.uniqueId,
          });
          const newGroup = {
            ...offlineGroup,
            ...onlineGroup,
            lines: offlineGroup?.lines || onlineGroup.lines,
            linesUpdated:
              offlineGroup?.linesUpdated || onlineGroup.linesUpdated,
            localSortField: toString(offlineGroup?.localSortField),
            localSortDir: toString(offlineGroup?.localSortDir),
            isDeleted: false,
          };
          createGroups.push(newGroup);
        });
        await createCountGroups(createGroups);

        const updateGroups: UpdateCountGroupProps[] = [];
        const removeGroups: RemoveCountGroupProps[] = [];
        map(offlineGroups, (offlineGroup) => {
          const onlineGroup = find(onlineGroups, {
            uniqueId: offlineGroup.uniqueId,
          });
          if (!onlineGroup) {
            updateGroups.push({
              groupUniqueId: offlineGroup.uniqueId,
              // TODO: some of these may not be needed
              billCustomerNo: offlineGroup.billCustomerNo,
              billCustomerName: offlineGroup.billCustomerName,
              lines: offlineGroup.lines,
              linesUpdated: offlineGroup.linesUpdated,
              isDeleted: true,
            });
            if (toNumber(offlineGroup.linesUpdated) === 0) {
              removeGroups.push({
                countPlanId: offlineGroup.countPlanId,
                groupId: offlineGroup.uniqueId,
              });
              if (toNumber(offlineGroup.downloadedItems) > 0) {
                deletedGroupList.push(offlineGroup);
              }
            }
          }
        });
        await updateCountGroups(updateGroups);
        await removeCountGroups(removeGroups);

        if (size(deletedGroupList) > 0) {
          const groupNames = join(
            map([{}, ...deletedGroupList], 'name'),
            '\n- '
          );
          addToast({
            id: 'delete-groups-toast',
            duration: 0,
            text: t('inventory:deleteOfflineGroupMsg', { groupNames }),
            testid: 'delete-groups-toast',
          });
        }
        const syncGroups = (await findCountGroupByMiLoc()) || [];

        return {
          items: map(syncGroups, (g) => ({ ...g, isDeleted: !!g.isDeleted })),
          total: size(syncGroups),
        };
      }

      return {
        items: map(offlineGroups, (g) => ({ ...g, isDeleted: !!g.isDeleted })),
        total: size(offlineGroups),
      };
    });
  };

  const response = useInfiniteQuery<UseFindCountGroupsResponse, AxiosError>(
    createQueryKey(findCountGroupsQueryKey, { ...params, isOnline }),
    doFindGroups,
    { networkMode: 'always', enabled }
  );

  const {
    data,
    status,
    error,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    refetch,
    fetchNextPage,
    dataUpdatedAt,
  } = response;

  let lastUpdatedAt = new Date();
  if (toNumber(dataUpdatedAt) > 0) {
    lastUpdatedAt = new Date(dataUpdatedAt);
  }

  const groups = useMemo(
    () =>
      doConcatDataPages<CountGroup, UseFindCountGroupsResponse>(data, 'items'),
    [data]
  );
  const hasItems = size(groups) > 0;
  const isEmptyResponse = status === 'success' && !hasNextPage && !hasItems;
  const noMoreData = status === 'success' && !hasNextPage && hasItems;
  const showLoader = doGetIsLoading(response) || isFetchingNextPage;
  const enableInfiniteScroll = !(!hasNextPage || isFetchingNextPage);

  useIonViewWillEnter(() => {
    if (enabled) {
      void refetch();
    }
  });

  return {
    fetchNextPage: async () => {
      await fetchNextPage();
    },
    refetch: async () => {
      await refetch();
    },
    groups,
    total: toNumber(head(data?.pages)?.total) || 0,
    error,
    showLoader,
    isFetching,
    isEmptyResponse,
    noMoreData,
    enableInfiniteScroll,
    lastUpdatedAt: getUnixTime(lastUpdatedAt),
    loc,
  };
};

export default useFindCountGroups;
