import React from 'react';
import { useTranslation } from 'react-i18next';
import { toNumber, toString } from 'lodash';
import { IonCol, IonContent, IonList, IonRow } from '@ionic/react';
import { formatNumber } from 'common/utils/numberHelper';
import { isThisYear } from 'date-fns';
import type {
  LeaderboardDetailItem,
  LeaderboardItem,
} from 'models/LeaderBoard';
import Pick12Truck from 'assets/Pick12Truck.png';
import SheetModal from 'components/Modals/SheetModal/SheetModal';
import classes from './Pick12Contest.module.scss';
import Pick12ContestItemList from './Pick12ContestItems';

interface Pick12ContestRules extends LeaderboardItem, LeaderboardDetailItem {}

interface Pick12ContestProps {
  isOpen: boolean;
  setIsOpen: (b: boolean) => void;
  pick12ContestRules?: Pick12ContestRules;
}

const Pick12Contest = ({
  isOpen,
  setIsOpen,
  pick12ContestRules,
}: Pick12ContestProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <SheetModal
      testid="pick12-contest"
      initialBreakpoint={1}
      isOpen={isOpen}
      className={classes.pick12Contest}
      title={t('pick12LeaderBoard:pick12TruckContestTitle')}
      withRightCloseButton
      setIsOpen={setIsOpen}
    >
      <IonContent data-testid="pick12-contest-content">
        <IonRow />
        <IonRow className={classes.imgWrapper}>
          <img alt="img" src={Pick12Truck} />
        </IonRow>
        <IonRow>
          <IonCol size="12">
            {pick12ContestRules?.pick12User && (
              <IonList data-testid="pick12-user-contest-rules">
                <Pick12ContestItemList
                  icon={
                    pick12ContestRules?.eligibility?.rank ? 'check' : 'times'
                  }
                  title={t('pick12LeaderBoard:currentlyRankedTitle', {
                    rank: pick12ContestRules?.rank,
                    yearVerb: isThisYear(pick12ContestRules?.year)
                      ? 'are currently'
                      : 'were',
                  })}
                  description={t('pick12LeaderBoard:currentlyRanked', {
                    rank: pick12ContestRules?.eligibilityRules?.minRank,
                  })}
                  testid="pick12-contest-item-one"
                />
                <Pick12ContestItemList
                  icon={
                    pick12ContestRules?.eligibility?.sales ? 'check' : 'times'
                  }
                  title={t('pick12LeaderBoard:totalSaleRuleTitle', {
                    sales: formatNumber({
                      number: pick12ContestRules?.sales,
                      abbreviated: true,
                      currencyType: pick12ContestRules?.currency,
                    }),
                    yearVerb: isThisYear(pick12ContestRules?.year)
                      ? 'is'
                      : 'was',
                  })}
                  description={t('pick12LeaderBoard:totalSalesRule', {
                    sales: formatNumber({
                      number: toNumber(
                        pick12ContestRules?.eligibilityRules?.minSales
                      ),
                      abbreviated: true,
                      currencyType: pick12ContestRules?.currency,
                    }),
                  })}
                  testid="pick12-contest-item-two"
                />
                <Pick12ContestItemList
                  icon={
                    pick12ContestRules?.eligibility?.gpPercent
                      ? 'check'
                      : 'times'
                  }
                  title={t('pick12LeaderBoard:marginAverageRuleTitle', {
                    marginAverage: `${pick12ContestRules?.gpPercent}%`,
                    yearVerb: isThisYear(pick12ContestRules?.year)
                      ? 'is'
                      : 'was',
                  })}
                  description={t('pick12LeaderBoard:marginAverageRule', {
                    marginAverage: `${pick12ContestRules?.eligibilityRules?.minGpPercent}%`,
                  })}
                  testid="pick12-contest-item-three"
                />
              </IonList>
            )}
            {!pick12ContestRules?.pick12User && (
              <ul
                className={classes.genericRules}
                data-testid="non-pick12-user-contest-rules"
              >
                <li>
                  <span>
                    {toString(
                      t('pick12LeaderBoard:currentlyRanked', {
                        rank: pick12ContestRules?.eligibilityRules?.minRank,
                      })
                    )}
                  </span>
                </li>
                <li>
                  <span>
                    {toString(
                      t('pick12LeaderBoard:totalSalesRule', {
                        sales: formatNumber({
                          number: toNumber(
                            pick12ContestRules?.eligibilityRules?.minSales
                          ),
                          abbreviated: true,
                          currencyType: pick12ContestRules?.currency,
                        }),
                      })
                    )}
                  </span>
                </li>
                <li>
                  <span>
                    {toString(
                      t('pick12LeaderBoard:marginAverageRule', {
                        marginAverage: `${toString(
                          pick12ContestRules?.eligibilityRules?.minGpPercent
                        )}%`,
                      })
                    )}
                  </span>
                </li>
              </ul>
            )}
          </IonCol>
        </IonRow>
      </IonContent>
    </SheetModal>
  );
};

export default Pick12Contest;
