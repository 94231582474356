import { useMemo } from 'react';
import type { AxiosError } from 'axios';
import { size, toString, head, map } from 'lodash';
import type { QueryFunctionContext } from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import type {
  QueryFlags,
  QueryParamsType,
} from 'common/api/utils/useGetQueryFlags';
import useGetQueryFlags from 'common/api/utils/useGetQueryFlags';
import type { Product } from 'ProductSearchApp/models/Products';
import { useAxios } from 'providers/AxiosProvider';
import { doConcatDataPages, useKeyUserId } from 'api/helpers';
import { pageSize } from 'utils/constants';

export const findProductsQueryKey = 'search-products';

interface UseFindProductsProps {
  query?: string;
  miLoc?: string;
  customerNo?: string;
  searchMode?: string;
  inStockOnly?: boolean;
  limitToBranch?: boolean;
  limitToCustomer?: boolean;
  returnSCAvail?: boolean;
  branchItemBalance?: boolean;
  mfrCtlNo?: string;
  limit?: number;
  enabled?: boolean;
}

interface UseFindProductsResponse {
  products: Product[];
  totalRows?: number;
}

const useFindProducts = ({
  query,
  miLoc,
  customerNo,
  searchMode = 'smart',
  inStockOnly = false,
  limitToBranch = false,
  limitToCustomer = false,
  returnSCAvail = false,
  branchItemBalance = false,
  mfrCtlNo,
  limit = pageSize(),
  enabled = true,
}: UseFindProductsProps): UseFindProductsResponse & QueryFlags => {
  const { axios } = useAxios();
  const { findProductsAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const params: QueryParamsType = {
    searchFor: query,
    miLoc,
    customerNo,
    searchMode,
    inStockOnly,
    limitToBranch,
    limitToCustomer,
    returnSCAvail,
    branchItemBalance,
    limit,
  };
  if (mfrCtlNo) {
    params.mfrCtlNo = mfrCtlNo;
  }

  const doFindProducts = async ({
    pageParam = 1,
    signal,
  }: QueryFunctionContext) => {
    const { data } = await axios.get<UseFindProductsResponse>(
      findProductsAPI(
        toString(new URLSearchParams({ ...params, page: toString(pageParam) }))
      ),
      { signal }
    );
    return {
      ...data,
      products: map(data.products, (i) => ({
        ...i,
        mino: i.mino || `${i.manufacturerCtlNo}${i.groupSerial}`,
      })),
    };
  };

  const response = useInfiniteQuery<UseFindProductsResponse, AxiosError>(
    createQueryKey(findProductsQueryKey, params),
    doFindProducts,
    {
      enabled,
      getNextPageParam: (lastPage, pages) =>
        size(lastPage.products) < limit ? false : size(pages) + 1,
    }
  );

  const { data } = response;

  const products = useMemo(
    () => doConcatDataPages<Product, UseFindProductsResponse>(data, 'products'),
    [data]
  );

  const queryFlags = useGetQueryFlags({ ...response, data: products, enabled });

  return {
    products,
    totalRows: head(data?.pages)?.totalRows,
    ...queryFlags,
  };
};

export default useFindProducts;
