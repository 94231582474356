import React from 'react';
import classNames from 'classnames';
import { isEmpty, isString } from 'lodash';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { findIcon } from 'utils/icons';
import ActionSheet from 'components/ActionSheet/ActionSheet';
import Button from 'components/Button/Button';
import Text from 'components/Text/Text';
import TitleLine from 'components/TitleLine/TitleLine';
import classes from './Modal.module.scss';

interface ModalProps {
  modalClassName?: string;
  wrapperClassName?: string;
  headerClassName?: string;
  withCloseButton?: boolean;
  closeButtonClick?: () => void;
  eyebrow?: React.ReactNode;
  title?: React.ReactNode;
  withTitleLine?: boolean;
  withTruncatedTitle?: boolean;
  forceFullHeight?: boolean;
}

const Modal = ({
  className,
  modalClassName,
  wrapperClassName,
  headerClassName,
  isOpen,
  setIsOpen,
  triggerModalResize,
  withCloseButton = true,
  eyebrow = '',
  title = '',
  withTitleLine = true,
  withTruncatedTitle = false,
  forceFullHeight = false,
  children,
  backdropDismiss,
  keyboardClose,
  canDismiss = true,
  onDidPresent,
  header,
  footer,
  onClose,
  closeButtonClick,
  testid,
}: ModalProps &
  React.ComponentProps<typeof ActionSheet> &
  IonicReactProps): JSX.Element => (
  <ActionSheet
    className={classNames(className, classes.modal)}
    modalClassName={modalClassName}
    wrapperClassName={wrapperClassName}
    isOpen={isOpen}
    setIsOpen={setIsOpen}
    triggerModalResize={triggerModalResize}
    onClose={onClose}
    keyboardClose={keyboardClose}
    backdropDismiss={backdropDismiss}
    canDismiss={canDismiss}
    onDidPresent={onDidPresent}
    forceFullHeight={forceFullHeight}
    header={
      <div className={classNames(classes.headerWrapper, headerClassName)}>
        {withCloseButton && (
          <Button
            className={classes.closeButton}
            icon={findIcon('times')}
            onClick={() => {
              closeButtonClick?.();
              if (canDismiss) {
                onClose?.();
                setIsOpen?.(false);
              }
            }}
            testid={`${testid}-close-button`}
          />
        )}
        {!isEmpty(eyebrow) &&
          (isString(eyebrow) ? (
            <Text
              className={classes.header}
              text={eyebrow}
              testid="modal-header"
            />
          ) : (
            eyebrow
          ))}
        {!isEmpty(title) && (
          <>
            {isString(title) ? (
              <Text
                className={classNames(classes.title, {
                  [classes.withTitleLine]: withTitleLine,
                  [classes.withTruncatedTitle]: withTruncatedTitle,
                })}
                variant={
                  withTitleLine
                    ? 'underlined-title-section'
                    : 'title-screen-section'
                }
                text={title}
                testid="modal-title"
              />
            ) : (
              title
            )}
            {withTitleLine && <TitleLine className={classes.titleLine} />}
          </>
        )}
        {header}
      </div>
    }
    footer={footer}
    testid={testid}
  >
    {children}
  </ActionSheet>
);

export default Modal;
