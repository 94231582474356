import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import {
  capitalize,
  filter,
  get,
  isEmpty,
  isNil,
  size,
  toString,
} from 'lodash';
import { IonPage, IonContent } from '@ionic/react';
import Footer from 'common/components/Footer/Footer';
import Input from 'common/components/Forms/Input/Input';
import CustomerName from 'common/components/Header/CustomerName';
import Header from 'common/components/Header/Header';
import { useFormik } from 'formik';
import { namespaces } from 'i18n/i18n.constants';
import * as yup from 'yup';
import useGoBack from 'hooks/useGoBack';
import type { MiProFile } from 'models/Attachment';
import type { Customer } from 'models/Customer';
import type { RootState } from 'store/reducers';
import { DateFormatEnum, formatDate } from 'utils/date';
import { findIcon } from 'utils/icons';
import OwnerTitle from 'components/Activities/OwnerTitle/OwnerTitle';
import Attachments from 'components/Attachments/Attachments';
import Button from 'components/Button/Button';
import ConfirmDialog from 'components/Modals/ConfirmDialog/ConfirmDialog';
import SheetModal from 'components/Modals/SheetModal/SheetModal';
import Text from 'components/Text/Text';
import Toggle from 'components/Toggle/Toggle';
import classes from './AddEditNoteForm.module.scss';

interface NoteFormProps {
  title: string;
  text?: string;
  publicFlag?: string;
  filesForUpload?: MiProFile[];
  filesForRemoval?: MiProFile[];
  userId?: string;
  employeeId?: string;
  userFullName?: string;
  updatedAt?: string;
  attachments?: MiProFile[];
}

interface NoteFormikProps {
  noteTitle: string;
  noteText: string;
  publicFlag: boolean;
}

interface AddEditNoteFormProps {
  noteId: string;
  newId?: string;
  customerData?: Customer;
  notebook?: NoteFormProps;
  updateStatus?: string;
  deleteStatus?: string;
  addStatus?: string;
  onAddNotebook?: (body: NoteFormProps) => void;
  onUpdateNotebook?: (body: NoteFormProps) => void;
  onDeleteNotebook?: () => void;
  withToggle?: boolean;
  buildNoteShareHref?: (id?: string) => string;
}

const AddEditNoteForm = ({
  noteId,
  newId,
  customerData,
  notebook,
  updateStatus,
  deleteStatus,
  addStatus,
  onAddNotebook,
  onUpdateNotebook,
  onDeleteNotebook,
  withToggle,
  buildNoteShareHref,
}: AddEditNoteFormProps): JSX.Element => {
  const history = useHistory();
  const { userInfo } = useSelector((state: RootState) => state.user);
  const { t, i18n } = useTranslation(namespaces.notes);
  const userId = get(userInfo, 'userid', '');
  const [files, setFiles] = useState<MiProFile[]>([]);
  const [filesForUpload, setFilesForUpload] = useState<MiProFile[]>([]);
  const [filesForRemoval, setFilesForRemoval] = useState<MiProFile[]>([]);
  const [discardConfirmDialogIsOpen, setDiscardConfirmDialogIsOpen] =
    useState(false);
  const [deleteConfirmDialogIsOpen, setDeleteConfirmDialogIsOpen] =
    useState(false);
  const [toggleModalIsOpen, setToggleModalIsOpen] = useState(false);
  const isAdding = noteId === 'add';

  const { goBack } = useGoBack();

  const editDeleteStatus =
    deleteStatus === 'success' ? deleteStatus : updateStatus;
  const status = isAdding ? addStatus : editDeleteStatus;

  const onDiscardConfirmDialogYes = () => {
    setDiscardConfirmDialogIsOpen(false);
    goBack();
  };
  const onDiscardConfirmDialogNo = () => {
    setDiscardConfirmDialogIsOpen(false);
  };
  const onDeleteConfirmDialogNo = () => {
    setDeleteConfirmDialogIsOpen(false);
  };

  const {
    values,
    errors,
    setFieldValue,
    submitForm,
    resetForm,
    validateField,
    dirty,
    isValid,
  } = useFormik<NoteFormikProps>({
    initialValues: { noteTitle: '', noteText: '', publicFlag: true },
    onSubmit: ({ noteTitle, noteText, publicFlag }) => {
      const body = {
        title: noteTitle,
        text: noteText,
        publicFlag: publicFlag ? 'Y' : 'N',
        filesForUpload,
        filesForRemoval,
      };
      if (!isAdding) {
        onUpdateNotebook?.(body);
      } else {
        onAddNotebook?.(body);
      }
    },
    validationSchema: yup.object().shape({
      noteTitle: yup.string().required(t('titleRequired')),
      noteText: yup.string(),
    }),
    validateOnBlur: true,
    validateOnChange: false,
  });

  const ownerUserId = notebook?.userId;
  const updatedDate = notebook?.updatedAt;
  const canDelete = ownerUserId === userId;
  const isSubmitting = status === 'loading';
  const isViewOnly = !isAdding && !canDelete;
  const ownerUserName = notebook?.userFullName;

  const [saveFromOption, setSaveFromOption] = useState<(h?: string) => void>();

  useEffect(() => {
    if (status === 'success') {
      if (saveFromOption) {
        saveFromOption?.(newId);
      } else {
        goBack();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    setFiles(notebook?.attachments || []);
  }, [notebook?.attachments]);

  useEffect(() => {
    if (!isNil(notebook) && !isAdding) {
      void resetForm({
        values: {
          noteTitle: notebook.title,
          noteText: toString(notebook.text),
          publicFlag: notebook.publicFlag === 'Y',
        },
      });
    }
  }, [notebook, isAdding, resetForm]);

  const onDelete = () => {
    onDeleteNotebook?.();
  };

  const isDirty = dirty || size(filesForUpload) || size(filesForRemoval);

  const customBackButtonClick = () => {
    if (isDirty) {
      setDiscardConfirmDialogIsOpen(true);
    } else {
      goBack();
    }
  };

  const headerActions = {
    title: t('manageNotes'),
    initialBreakpoint: 0.3,
    options: [
      {
        key: 'send email',
        text: isDirty ? t('common:sendEmailSave') : t('common:sendEmail'),
        testid: 'send-email-btn',
        onClick: () => {
          if (isDirty) {
            setSaveFromOption(() => (newNoteId: string) => {
              setSaveFromOption(undefined);
              const href = toString(buildNoteShareHref?.(newNoteId));
              setTimeout(() => {
                history.replace(href);
                // TODO: hardcoded to give attachments time for upload,
                // this just improves UX, attachments will always be up-to-date due to refetch logic
              }, 1000);
            });
            void submitForm();
          } else {
            history.push(toString(buildNoteShareHref?.()));
          }
        },
      },
    ] as React.ComponentProps<typeof Button>[],
  };

  if (canDelete) {
    headerActions.options.push({
      key: 'delete',
      text: capitalize(t('common:delete')),
      icon: findIcon('trash-alt'),
      className: classes.delete,
      onClick: () => setDeleteConfirmDialogIsOpen(true),
      testid: 'delete-action',
    });
  }

  return (
    <IonPage>
      <Header
        testid="add-notebook"
        backButton={{ onClick: () => customBackButtonClick() }}
        customTitle={<CustomerName customerData={customerData} />}
      />
      <IonContent className={classes.addNote}>
        <Header
          testid="add-notebook-condense"
          backButton={{ onClick: () => customBackButtonClick() }}
          customTitle={
            <div className={classes.customTitleWrap}>
              <CustomerName customerData={customerData} />
              {ownerUserId !== userId && (
                <div className={classes.customerEmpName}>
                  <OwnerTitle
                    nonLoggedInOwnerEmpId={
                      notebook?.employeeId || toString(ownerUserId)
                    }
                    withHref={!!notebook?.employeeId}
                    ownerName={ownerUserName}
                    link
                    testid="non-logged-in-employee"
                  />
                </div>
              )}
            </div>
          }
          headerActions={
            !isAdding || (isAdding && isDirty) ? headerActions : undefined
          }
          collapse="condense"
          pageTitle={t('notes')}
        />
        <form>
          <Input
            textarea
            label={t('noteTitle')}
            placeholder={t('common:title')}
            testid="note-title"
            value={values.noteTitle}
            name="noteTitle"
            setValue={(e) => {
              void setFieldValue('noteTitle', e);
              setTimeout(() => {
                void validateField('noteTitle');
              }, 100);
            }}
            disabled={isSubmitting}
            error={errors.noteTitle}
            readonly={isViewOnly}
            required
            rows={2}
            className={classes.textarea}
          />

          <Input
            textarea
            className={classes.textarea}
            placeholder={t('feedback:textAreaPlaceholder')}
            name="noteText"
            disabled={isSubmitting}
            value={values.noteText}
            setValue={(e) => setFieldValue('noteText', e)}
            label={t('common:note')}
            testid="note-text"
            rows={6}
            readonly={isViewOnly}
          />

          {!isAdding && (
            <Text
              className={classes.updateDate}
              variant="label-micro"
              text={t('updateDateText', {
                updateDate: formatDate(
                  updatedDate,
                  DateFormatEnum.fullDate,
                  i18n.language
                ),
              })}
            />
          )}
          {withToggle && (
            <Toggle
              className={classes.publicToggle}
              wrapperClassName={classes.toggleWrapper}
              checked={values.publicFlag}
              lines="none"
              disabled={isViewOnly || isSubmitting}
              onClick={() => setFieldValue('publicFlag', !values.publicFlag)}
              testid="note-public-flag"
            >
              <Button
                testid="toggle-button"
                textVariant="mipro-body-copy"
                text={t('allowPublicView')}
                onClick={() => setToggleModalIsOpen(true)}
                rightIcon={findIcon('info-circle')}
              />
            </Toggle>
          )}
          <div
            className={classnames(classes.attachmentWrapper, {
              [classes.isViewOnly]: isViewOnly,
            })}
          >
            <Text
              className={classes.attachmentsTitle}
              text={
                isEmpty(files) && isViewOnly
                  ? t('email:noAttachments')
                  : t('email:attachments')
              }
              variant="mipro-h3-headline"
            />
            <Attachments
              className={classnames({ [classes.smallSize]: isViewOnly })}
              domain="customer"
              name="customer"
              files={files}
              onAdd={(addFiles) => {
                setFilesForUpload([...(filesForUpload || []), ...addFiles]);
              }}
              onRemove={(file) => {
                const filesFiltered = filter([...filesForUpload], (i) => {
                  // fileName or userFile or USER_FILE is used as the unique identifier for files
                  if ('fileName' in file) {
                    return 'fileName' in file
                      ? i.fileName !== file.fileName
                      : true;
                  }
                  return true;
                });

                if (file.userFile || file.USER_FILE) {
                  setFilesForRemoval([...(filesForRemoval || []), file]);
                }
                setFilesForUpload(filesFiltered);
              }}
              disabled={isSubmitting}
              editMode={!isViewOnly}
              enableSmallPreview
              testid="attachments"
            />
          </div>
        </form>
      </IonContent>
      {!isViewOnly && (
        <Footer
          buttons={[
            {
              variant: 'action',
              testid: 'save--notebook--button',
              text: t('common:save'),
              onClick: () => submitForm(),
              disabled: !isValid || isSubmitting || !isDirty,
            },
          ]}
        />
      )}
      <ConfirmDialog
        isOpen={discardConfirmDialogIsOpen}
        setIsOpen={setDiscardConfirmDialogIsOpen}
        title={t('cancelNotesTitle')}
        text={t('cancelNotesMessage')}
        primaryText={t('cancelNotesNo')}
        secondaryText={t('cancelNotesYes')}
        onPrimaryClick={onDiscardConfirmDialogNo}
        onSecondaryClick={onDiscardConfirmDialogYes}
        testid="confirmation-modal"
      />
      <ConfirmDialog
        isOpen={deleteConfirmDialogIsOpen}
        setIsOpen={setDeleteConfirmDialogIsOpen}
        title={t('images:deleteNotesTitle')}
        text={t('images:deleteNotesMessage')}
        primaryText={t('images:deleteNotesNo')}
        secondaryText={t('images:deleteNotesYes')}
        onPrimaryClick={onDeleteConfirmDialogNo}
        onSecondaryClick={onDelete}
        testid="confirmation-modal"
      />
      <SheetModal
        title={t('allowPublicView')}
        titleTextVariant="mipro-h3-headline"
        titleClassName={classes.publicViewTitle}
        isOpen={toggleModalIsOpen}
        setIsOpen={setToggleModalIsOpen}
        initialBreakpoint={0.3}
        withCloseButton={false}
        testid="allow-public-view-modal"
      >
        <div className={classes.publicViewContent}>
          <Text text={t('allowPublicViewModal')} />
        </div>
      </SheetModal>
    </IonPage>
  );
};

export default AddEditNoteForm;
