import { useMemo } from 'react';
import type { AxiosError } from 'axios';
import type { Dictionary } from 'lodash';
import { isEmpty, map, size, toString } from 'lodash';
import { useInfiniteQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import useGetCustomer from 'api/customer/useGetCustomer';
import useFindDataCodes from 'api/data/useFindDataCodes';
import {
  doConcatDataPages,
  doPromiseAPI,
  useKeyUserId,
  useMiLocOrTeamId,
} from 'api/helpers';
import useGetSupplier from 'api/supplier/useGetSupplier';
import type { Contact } from 'models/Contact';
import type { Customer } from 'models/Customer';
import type { InfiniteQueryFlags, SearchItemType } from 'models/Search';
import { SortContactFieldEnum, SortDirEnum } from 'models/Sort';
import type { Supplier } from 'models/Supplier';
import { pageSize } from 'utils/constants';

export const findContactsQueryKey = 'contacts';

interface UseFindContactsProps {
  searchType: SearchItemType;
  miLoc: string;
  id: string;
  sortField?: SortContactFieldEnum;
  sortDir?: SortDirEnum;
  enabled?: boolean;
  limit?: number;
  query?: string;
}

interface UseFindContactsResponse {
  data?: Customer | Supplier;
  // DOC: return location used in request
  miLoc?: string;
  contacts?: Contact[];
}

const useFindContacts = ({
  query = '',
  searchType,
  miLoc: itemMiLoc,
  id,
  sortField = SortContactFieldEnum.name,
  sortDir = SortDirEnum.ASCENDING,
  enabled = true,
  limit = pageSize(),
}: UseFindContactsProps): InfiniteQueryFlags & UseFindContactsResponse => {
  const { axios } = useAxios();
  const { findContactsAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const { createParams } = useMiLocOrTeamId({
    miLoc: itemMiLoc,
    sendTeamId: false,
  });
  const params: Dictionary<string> = {
    ...createParams(),
    searchQuery: query,
    sortField,
    sortDir,
    limit: toString(limit),
  };
  const { miLoc } = params;

  const { data: customerData } = useGetCustomer({ searchType, miLoc, id });
  const { data: supplierData } = useGetSupplier({ searchType, id });
  useFindDataCodes({
    codeType: searchType === 'customer' ? 'CUCONTYP' : 'CONTYPE',
    enabled,
  });

  const doFindContacts = ({ pageParam = 1 }) => {
    return doPromiseAPI<Contact[]>(async (cancelToken) => {
      const { data } = await axios.get<Contact[]>(
        findContactsAPI(
          searchType,
          miLoc,
          id,
          toString(
            new URLSearchParams({ ...params, page: toString(pageParam) })
          )
        ),
        { cancelToken }
      );
      return map(data, ({ phone, phone1, ...item }) => ({
        ...item,
        phone: searchType === 'customer' ? phone : phone1,
      }));
    });
  };

  const response = useInfiniteQuery<Contact[], AxiosError>(
    createQueryKey(findContactsQueryKey, { searchType, miLoc, id, ...params }),
    doFindContacts,
    {
      enabled: enabled && !isEmpty(miLoc) && !isEmpty(id),
      getNextPageParam: (lastPage, pages) =>
        size(lastPage) < limit ? false : size(pages) + 1,
    }
  );

  const {
    data,
    error,
    status,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = response;

  const contacts = useMemo(() => doConcatDataPages<Contact>(data), [data]);

  const hasItems = size(contacts) > 0;
  const hasError = status === 'error';
  const isEmptyResponse = status === 'success' && !hasNextPage && !hasItems;
  const noMoreData = status === 'success' && !hasNextPage && hasItems;
  const showLoader = status === 'loading' || isFetchingNextPage;
  const enableInfiniteScroll = !(!hasNextPage || isFetchingNextPage);

  return {
    fetchNextPage: async () => {
      await fetchNextPage();
    },
    data: searchType === 'customer' ? customerData : supplierData,
    miLoc,
    contacts,
    error,
    hasError,
    showLoader,
    isEmptyResponse,
    noMoreData,
    enableInfiniteScroll,
    refetch: async () => {
      await refetch();
    },
  };
};

export default useFindContacts;
