import CostSavings from 'ReportsApp/pages/CostSavings/CostSavings';
import EndOfMonth from 'ReportsApp/pages/EndOfMonth/EndOfMonth';
import OpenQuotes from 'ReportsApp/pages/OpenQuotes/OpenQuotes';
import SalesPerformance from 'ReportsApp/pages/SalesPerformance/SalesPerformance';
import type { TabRoutes } from 'models/Navigation';

// #region ui-routes
interface BaseDrilldownReportURLProps {
  orgType?: string;
  miLoc?: string;
  rowId?: string;
  pgc1?: string;
}

const baseDrilldownReportURL = ({
  url = '',
  orgType = ':orgType?',
  miLoc = ':miLoc?',
  rowId = ':rowId?',
  pgc1 = ':pgc1?',
}) => {
  const orgTypeParam = orgType ? `/${orgType}` : '';
  const miLocParam = miLoc ? `/${miLoc}` : '';
  const rowIdParam = rowId ? `/${rowId}` : '';
  const pgc1Param = pgc1 ? `/${pgc1}` : '';
  return `/${url}${orgTypeParam}${miLocParam}${rowIdParam}${pgc1Param}`;
};

export const salesPerformanceReportURL = (
  props: BaseDrilldownReportURLProps
) => {
  return baseDrilldownReportURL({ url: 'sales-performance', ...props });
};

export const costSavingsReportURL = (props: BaseDrilldownReportURLProps) => {
  return baseDrilldownReportURL({ url: 'cost-savings', ...props });
};

export const openQuotesReportsURL = () => '/open-quotes';

export const endOfMonthReportsURL = () => '/end-of-month';
// #endregion ui-routes

export const ReportsAppRouter: TabRoutes[] = [
  {
    path: salesPerformanceReportURL({}),
    component: SalesPerformance,
    routeName: 'Sales Performance Report',
  },
  {
    path: openQuotesReportsURL(),
    component: OpenQuotes,
    routeName: 'Open Quotes Report',
  },
  {
    path: endOfMonthReportsURL(),
    component: EndOfMonth,
    routeName: 'End Of Month Live',
  },
  {
    path: costSavingsReportURL({}),
    component: CostSavings,
    routeName: 'Cost Savings Report',
  },
];
