import { size, map, toNumber, filter, find } from 'lodash';
import type { MutationStatus } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import useReplenishmentDB from 'StoreroomsApp/database/useReplenishmentDB';
import type { ItemPOU } from 'StoreroomsApp/models/ItemPOU';
import type {
  ItemReplenishmentDTO,
  UploadReplenishmentAPIResponse,
} from 'StoreroomsApp/models/Replenishment';
import type { UpdateMutationContext } from 'api/helpers';
import {
  onSuccessMutation,
  doPromiseAPI,
  onErrorUpdate,
  onMutateUpdate,
  useKeyUserId,
  useMiLocOrTeamId,
} from 'api/helpers';
import { ToastType } from 'models/Toast';
import { findItemsPOUQueryKey } from './useFindItemsPOU';
import { findOpenReplenishmentQueryKey } from './useFindOpenReplenishment';
import { findReplenishmentItemsQueryKey } from './useFindReplenishmentItems';

interface UploadReplenishmentBody {
  items: ItemReplenishmentDTO[];
}

interface UploadReplenishmentResponse {
  sendItems: ItemReplenishmentDTO[];
  failedItems: UploadReplenishmentAPIResponse[];
}

interface UseUploadReplenishmentResponse {
  data?: UploadReplenishmentResponse;
  status: MutationStatus;
  isLoading: boolean;
  onUploadReplenishment: (body: UploadReplenishmentBody) => void;
}

const useUploadReplenishment = (): UseUploadReplenishmentResponse => {
  const { axios } = useAxios();
  const { replenishmentAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const { userId } = useKeyUserId();
  const { createParams } = useMiLocOrTeamId({ sendTeamId: false });
  const { getOpenReplenishment, closeReplenishment } = useReplenishmentDB();
  const { miLoc } = createParams();

  const doUploadReplenishment = ({ items }: UploadReplenishmentBody) => {
    return doPromiseAPI<UploadReplenishmentResponse>(async () => {
      const failedItems: UploadReplenishmentAPIResponse[] = [];
      const itemBody = map(items, (item) => ({
        miLoc: item.miLocation,
        storeroomNo: item.storeroomNumber,
        itemNo: item.itemNumber,
        bohQty: toNumber(item.balanceOnHandQuantity),
        ordQty: toNumber(item.orderQuantity),
        type: 'F',
      }));
      const {
        data: { rows, success },
      } = await axios.post<{
        rows: UploadReplenishmentAPIResponse[];
        success: boolean;
      }>(replenishmentAPI(), itemBody);
      if (!success) {
        throw new Error('Error with upload');
      }
      failedItems.push(...filter(rows, { success: false }));
      const openReplenishment = await getOpenReplenishment({ miLoc, userId });
      await closeReplenishment(toNumber(openReplenishment.id));
      return {
        sendItems: items,
        failedItems: filter(failedItems, (i) =>
          find(items, {
            itemId: i.combinedStoreroomItemId,
          })
        ) as UploadReplenishmentAPIResponse[],
      };
    });
  };

  interface UploadReplenishmentContext {
    replenishmentContext: UpdateMutationContext<ItemReplenishmentDTO>[];
    itemsPOUContext: UpdateMutationContext<ItemPOU>[];
  }

  const { data, status, mutate, isLoading } = useMutation(
    doUploadReplenishment,
    {
      onMutate: async (vars) => {
        const replenishmentContext = await onMutateUpdate<ItemReplenishmentDTO>(
          {
            queryClient,
            queryKey: findReplenishmentItemsQueryKey,
            removedFindPredicates: map(vars.items, ({ id }) => ({ id })),
            isArrayQuery: true,
          }
        );
        const itemsPOUContext = await onMutateUpdate<ItemPOU>({
          queryClient,
          queryKey: findItemsPOUQueryKey,
          updatedItems: map(
            vars.items,
            ({ itemId }) =>
              ({
                combinedId: itemId,
                alreadyAddedToReplenishment: 0,
              } as ItemPOU)
          ),
          findPredicates: map(vars.items, ({ itemId }) => ({
            combinedId: itemId,
          })),
          isInfiniteQuery: true,
        });
        return { replenishmentContext, itemsPOUContext };
      },
      onSuccess: async ({ sendItems, failedItems }) => {
        addToast({
          text:
            size(sendItems) === size(failedItems)
              ? 'Items have errors that need to be corrected.'
              : `The replenishment items have been uploaded.${
                  size(failedItems) > 0
                    ? ' Some items have errors that need to be corrected.'
                    : ''
                }`,
          testid: 'upload-replenishment-success-toast',
        });
        await onSuccessMutation(queryClient, findOpenReplenishmentQueryKey);
        await onSuccessMutation(queryClient, findReplenishmentItemsQueryKey);
        void onSuccessMutation(queryClient, findItemsPOUQueryKey);
      },
      onError: (error, vars, context) => {
        const { replenishmentContext, itemsPOUContext } =
          context as UploadReplenishmentContext;
        addToast({
          type: ToastType.error,
          text: 'There was an error uploading replenishment. Please try again later.',
          testid: 'upload-replenishment-error-toast',
        });
        onErrorUpdate<ItemReplenishmentDTO>({
          queryClient,
          context: replenishmentContext,
          isArrayQuery: true,
        });
        onErrorUpdate<ItemPOU>({
          queryClient,
          context: itemsPOUContext,
          isInfiniteQuery: true,
        });
      },
    }
  );

  return {
    data,
    status,
    isLoading,
    onUploadReplenishment: (body: UploadReplenishmentBody) => mutate(body),
  };
};

export default useUploadReplenishment;
