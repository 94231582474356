import {
  documentsURL,
  homeURL,
  replenishmentViewURL,
  issueViewURL,
  workOrdersURL,
} from 'navigation';

interface AppDefaultUrlProps {
  isMiProApp: boolean;
  workOrderFeatureFlag: boolean;
  workOrdersAccessControl: boolean;
  documentsAccessControl: boolean;
  replenishmentsAccessControl: boolean;
  issueProcessingAccessControl?: boolean;
}

export default ({
  isMiProApp,
  workOrderFeatureFlag,
  workOrdersAccessControl,
  documentsAccessControl,
  replenishmentsAccessControl,
}: AppDefaultUrlProps): string => {
  let url;
  if (isMiProApp) {
    url = homeURL();
  } else if (workOrderFeatureFlag && workOrdersAccessControl) {
    url = workOrdersURL();
  } else if (documentsAccessControl) {
    url = documentsURL();
  } else if (replenishmentsAccessControl) {
    url = replenishmentViewURL();
  } else {
    url = issueViewURL();
  }
  return url;
};
