import React from 'react';
import classNames from 'classnames';
import { isEmpty, isObject, toString } from 'lodash';
import type { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonRow } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import type { OptionalRenderProp } from 'common/components/utils/renderHelpers';
import Text from 'components/Text/Text';
import classes from './Alert.module.scss';

export enum AlertVariantEnum {
  warning = 'warning',
  danger = 'danger',
  success = 'success',
  info = 'info',
  loading = 'loading',
}

interface AlertProps {
  text?: OptionalRenderProp<React.ComponentProps<typeof Text>>;
  variant?: AlertVariantEnum;
  testid?: string;
  children?: React.ReactNode;
  hideIcon?: boolean;
}

const Alert = ({
  variant = AlertVariantEnum.warning,
  className,
  testid,
  children,
  hideIcon = false,
  ...props
}: AlertProps & IonicReactProps): JSX.Element => {
  const textProps = isObject(props.text) ? props.text : undefined;
  const text = isObject(props.text) ? props.text.text : props.text;

  const icon = {
    [AlertVariantEnum.warning]: 'circle-exclamation',
    [AlertVariantEnum.danger]: 'circle-xmark',
    [AlertVariantEnum.success]: 'circle-check',
    [AlertVariantEnum.info]: 'circle-info',
    [AlertVariantEnum.loading]: 'loader',
  };
  return (
    <IonRow
      className={classNames(
        classes.validationWrapper,
        classes[variant],
        className
      )}
      data-testid={testid}
    >
      {!hideIcon && (
        <FontAwesomeIcon
          icon={['fas', icon[variant] as IconName]}
          data-testid="icon"
        />
      )}
      {!isEmpty(text) && (
        <Text
          variant="label-micro"
          text={toString(text)}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...textProps}
          testid="text"
        />
      )}
      {children}
    </IonRow>
  );
};

export default Alert;
