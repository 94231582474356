import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty, last, toString } from 'lodash';
import type { Order } from 'ProductSearchApp/models/Order';
import { getPoText } from 'ProductSearchApp/util/ocnHelpers';
import useGetAttachment from 'api/attachments/useGetAttachment';
import useGetDownloadAttachment from 'api/attachments/useGetDownloadAttachment';
import type { FileAttachmentProp, MiProFile } from 'models/Attachment';
import type { Customer } from 'models/Customer';
import { DateFormatEnum, formatDate } from 'utils/date';
import { getAttachmentProps } from 'utils/filesUpload';
import Button from 'components/Button/Button';
import SendEmailModal from 'components/Modals/SendEmailModal/SendEmailModal';
import classes from './PODAttachment.module.scss';

interface PODAttachmentProp {
  pod: FileAttachmentProp;
  order?: Order;
  customer?: Customer;
}
const PODAttachment = ({
  pod,
  order,
  customer,
}: PODAttachmentProp): JSX.Element => {
  const { i18n, t } = useTranslation();
  let domain = toString(pod?.ENTITY);
  domain ||= toString(pod?.entity);
  const { startDownload } = useGetDownloadAttachment({
    domain: toString(domain),
  });
  const namespace = 'productSearch:shippingDetails';
  const [sendEmailModal, setSendEmailModal] = useState(false);
  const fileName = toString(pod.userFile);

  useGetAttachment({
    ...getAttachmentProps({ file: pod as MiProFile }),
    enabled: sendEmailModal,
  });
  const custPoNo = order?.custPoNo;
  const custReleaseNo = order?.custReleaseNo;
  const podEmailSubject = t(`${namespace}:podSendEmailSubject`, {
    custPoNo,
    shipperNo: pod.ctlNo,
  });

  const podEmailBody = t(`${namespace}:podSendEmailBody`, {
    shipperNo: pod.ctlNo,
    purchaseOrder: getPoText(custPoNo, custReleaseNo),
  });

  const attachment = {
    CTL_NO: pod.ctlNo,
    DESCRIPTION: pod.description,
    ENTITY: pod.entity,
    fileName,
    type: last(pod.userFile?.split('.')),
    FILE_NAME: fileName,
    LINE_NO: toString(pod.lineNo),
    MI_LOC: pod.miLoc,
    SEQ_NO: toString(pod.seqNo),
    USER_FILE: fileName,
  };

  const cc: string[] = [];

  if (!isEmpty(customer?.motionRep?.email)) {
    cc.push(toString(customer?.motionRep?.email));
  }

  return (
    <div className={classes.podWrapper}>
      <Button
        variant="link"
        text={t(`${namespace}:podFileName`, {
          creationTmstmp: formatDate(
            toString(pod.creationTmstmp),
            DateFormatEnum.fullDate,
            i18n.language
          ),
          name: pod.userFile,
        })}
        testid="pod-button"
        onClick={() => startDownload(pod as MiProFile)}
        textVariant="mipro-body-copy"
        className={classes.podFile}
      />

      <Button
        variant="icon-action"
        testid="send-email"
        icon={['far', 'envelope']}
        className={classes.sendEmailBtn}
        onClick={() => {
          setSendEmailModal(true);
        }}
      />

      <SendEmailModal
        searchType="customer"
        miLoc={order?.miLoc}
        isOpen={sendEmailModal}
        setIsOpen={setSendEmailModal}
        title={t('common:sendEmail')}
        testid="send-email-modal"
        id={order?.shipToCustNo}
        subject={podEmailSubject}
        body={podEmailBody}
        defaultFiles={[attachment] as MiProFile[]}
        defaultRecipients={[]}
        cc={cc}
      />
    </div>
  );
};

export default PODAttachment;
