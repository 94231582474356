import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import type { AxiosError } from 'axios';
import { get, map, size, toString } from 'lodash';
import { useIonViewDidEnter } from '@ionic/react';
import { useInfiniteQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import {
  doConcatDataPages,
  doPromiseAPI,
  useKeyUserId,
  useMiLocOrTeamId,
} from 'api/helpers';
import type { FindFavoriteCustomersAPIResponse } from 'models/Favorites';
import type {
  SearchItem,
  SearchResponse,
  InfiniteQueryFlags,
} from 'models/Search';
import type { RootState } from 'store/reducers';
import { pageSize } from 'utils/constants';

export const findFavoriteCustomersQueryKey = 'favorite-customers';

interface UseFindFavoriteCustomersProps {
  enabled?: boolean;
  limit?: number;
}

const useFindFavoriteCustomers = ({
  enabled,
  limit = pageSize(),
}: UseFindFavoriteCustomersProps): SearchResponse & InfiniteQueryFlags => {
  const { axios } = useAxios();
  const { findFavoriteCustomersAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const { userInfo } = useSelector((state: RootState) => state.user);
  const userId = get(userInfo, 'userid', '');
  const params = { limit: toString(limit) };
  const { createParams } = useMiLocOrTeamId({ sendTeamId: false });
  const doFindFavoriteCustomers = ({ pageParam = 1 }) => {
    return doPromiseAPI<SearchResponse>(async (cancelToken) => {
      const { data } = await axios.get<FindFavoriteCustomersAPIResponse>(
        findFavoriteCustomersAPI(
          userId,
          toString(
            new URLSearchParams({
              ...createParams(),
              ...params,
              page: toString(pageParam),
            })
          )
        ),
        { cancelToken }
      );
      return {
        items: map(
          data,
          ({ custNo, miLoc: iMiLoc, name, customerPick12, natlAcctNo }) => ({
            type: 'customer',
            miLoc: iMiLoc,
            id: custNo,
            text: name,
            customerPick12,
            natlAcctNo,
          })
        ),
      };
    });
  };

  const response = useInfiniteQuery<SearchResponse, AxiosError>(
    createQueryKey(findFavoriteCustomersQueryKey),
    doFindFavoriteCustomers,
    {
      enabled,
      getNextPageParam: (lastPage, pages) =>
        size(lastPage.items) < limit ? false : size(pages) + 1,
    }
  );

  const {
    data,
    error,
    status,
    hasNextPage,
    isFetchingNextPage,
    refetch,
    fetchNextPage,
  } = response;

  useIonViewDidEnter(() => {
    void refetch();
  });

  const favorites = useMemo(
    () => doConcatDataPages<SearchItem, SearchResponse>(data, 'items'),
    [data]
  );

  const hasItems = size(favorites) > 0;
  const hasError = status === 'error';
  const isEmptyResponse = status === 'success' && !hasNextPage && !hasItems;
  const noMoreData = status === 'success' && !hasNextPage && hasItems;
  const showLoader = status === 'loading' || isFetchingNextPage;
  const enableInfiniteScroll = !(!hasNextPage || isFetchingNextPage);

  return {
    fetchNextPage: async () => {
      await fetchNextPage();
    },
    items: favorites,
    error,
    hasError,
    showLoader,
    isEmptyResponse,
    noMoreData,
    enableInfiniteScroll,
    refetch: async () => {
      await refetch?.call(null);
    },
  };
};

export default useFindFavoriteCustomers;
