import React from 'react';
import { useTranslation } from 'react-i18next';
import { toString } from 'lodash';
import type { BaseComponentProps } from 'common/components/utils/renderHelpers';
import WarningMessage, {
  WarningMessageVarianEnum,
} from 'components/WarningMessage/WarningMessage';

interface JobWarningProps extends BaseComponentProps {
  reportName: string;
}

const JobWarning = ({ testid, className, reportName }: JobWarningProps) => {
  const { t } = useTranslation();
  return (
    <WarningMessage
      className={className}
      testid={`${toString(testid)}-job-warning`}
      variant={WarningMessageVarianEnum.warning}
      title={t('reports:runningJobTitle', { reportName })}
      body={t('reports:runningJobMsg')}
    />
  );
};

export default JobWarning;
