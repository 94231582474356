import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { includes, isEmpty, toNumber } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonRow } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import ItemDetailLines from 'common/components/List/ItemDetailLines';
import { formatNumber } from 'common/utils/numberHelper';
import { emptyString } from 'common/utils/valueFormatter';
import { namespaces } from 'i18n/i18n.constants';
import { OcnBadge } from 'ProductSearchApp/components/OcnBadge/OcnBadge';
import { getPoText, isUnfinishedOrder } from 'ProductSearchApp/util/ocnHelpers';
import type { SearchGlobalOrder } from 'models/Search';
import { formatCardDate } from 'utils/date';
import { goToReviewCart } from 'navigation/navigationHelpers';
import ActionRow from 'components/ActionRow/ActionRow';
import Text from 'components/Text/Text';
import classes from './SearchSuggestionCard.module.scss';

interface SearchSuggestionCardProps {
  item: SearchGlobalOrder;
  textQuery: string;
  hideCustomerName?: boolean;
}

const SearchSuggestionOcnCard = ({
  item,
  testid,
  textQuery,
  hideCustomerName,
}: SearchSuggestionCardProps &
  React.ComponentProps<typeof ActionRow> &
  IonicReactProps): JSX.Element => {
  const { t } = useTranslation(namespaces.search);
  const {
    customerName = emptyString,
    custPONo = emptyString,
    custReleaseNo = emptyString,
    creationTmstmp = emptyString,
    miLoc = emptyString,
    shipToCustNo = emptyString,
    orderCtlNo = emptyString,
    totalOrderAmt = emptyString,
    linesOrdered = '0',
    userName = emptyString,
    orderTypeCd = emptyString,
    processStatus = emptyString,
  } = item;
  const isUnfinished = isUnfinishedOrder(orderTypeCd);
  const isQuote = orderTypeCd === 'Q';
  const isOrder = orderTypeCd === 'O';

  return (
    <ActionRow
      className={classes.item}
      href={goToReviewCart({
        miLoc,
        shipToCustNo,
        orderCtlNo: item.orderCtlNo,
        ocnType:
          // eslint-disable-next-line no-nested-ternary
          isUnfinished ? 'unfinished' : isOrder ? 'order' : 'quote',
        ocnMode: 'review',
      })}
      testid={testid}
      disabled={false}
      withArrow={false}
    >
      <IonRow className={classes.container}>
        <div className={classes.wrapper}>
          <div>
            {!hideCustomerName && (
              <div className={classes.noWrapRow}>
                <Text
                  className={classNames(classes.clampLine, classes.gap, {
                    [classes.statusWidthFixer]: includes(
                      ['HD', 'AR', 'PA', 'PS'],
                      processStatus
                    ),
                  })}
                  text={`${customerName} | ${miLoc}${shipToCustNo}`}
                  variant="list-item-overlay"
                  textQuery={[
                    { query: textQuery, className: classes.highlighter },
                  ]}
                  testid={`${orderCtlNo}-text`}
                />
                <OcnBadge statusId={processStatus} />
              </div>
            )}
            <div className={classes.maxWidthContainer}>
              <IonRow
                className={classNames({
                  [classes.noWrapRow]: hideCustomerName,
                })}
              >
                <Text
                  className={classNames(classes.description, classes.gap)}
                  variant="list-item-title"
                  text={t('search:ocnCardTitle', {
                    // eslint-disable-next-line no-nested-ternary
                    ocnType: isQuote
                      ? t('search:quote')
                      : isOrder
                      ? t('search:order')
                      : t('search:unfinished'),
                    orderCtlNo,
                  })}
                  testid={`${testid}-description`}
                  textQuery={[
                    { query: textQuery, className: classes.highlighter },
                  ]}
                />
                {hideCustomerName && (
                  <OcnBadge
                    className={classes.badge}
                    statusId={processStatus}
                  />
                )}
              </IonRow>
              <ItemDetailLines
                className={classes.gap}
                itemClass={classes.clampLine}
                lines={[
                  {
                    hidden: !isEmpty(custPONo) || !isEmpty(custReleaseNo),
                    label: {
                      variant: 'list-item-subtitle',
                      text: t('search:poTitle'),
                      className: classes.poTitle,
                      testid: `${testid}-po`,
                    },
                    value: {
                      variant: 'list-item-subtitle',
                      text: getPoText(custPONo, custReleaseNo),
                      textQuery: [
                        { query: textQuery, className: classes.highlighter },
                      ],
                      testid: `${testid}-po`,
                      className: classes.poLine,
                    },
                  },
                ]}
              />
              <IonRow>
                <Text
                  className={classNames(
                    classes.text,
                    classes.grayRow,
                    classes.gap
                  )}
                  variant="list-item-secondaryText"
                  text={t('amountWithLinesText', {
                    totalOrderAmt: formatNumber({
                      number: toNumber(totalOrderAmt),
                      scale: 2,
                      currencyType: 'USD',
                    }),
                    count: toNumber(linesOrdered),
                  })}
                  testid={`${testid}-amount`}
                  textQuery={[
                    { query: textQuery, className: classes.highlighter },
                  ]}
                />
              </IonRow>
              <IonRow>
                <Text
                  className={classNames(
                    classes.text,
                    classes.grayRow,
                    classes.gap
                  )}
                  variant="list-item-secondaryText"
                  text={t('search:creationDetails', {
                    userName,
                    creationTmstmp: formatCardDate(creationTmstmp),
                  })}
                  testid={`${testid}-creator`}
                  textQuery={[
                    { query: textQuery, className: classes.highlighter },
                  ]}
                />
              </IonRow>
            </div>
            <div className={classes.iconWrapper}>
              <FontAwesomeIcon
                icon={['far', 'chevron-right']}
                className={classes.arrow}
              />
            </div>
          </div>
        </div>
      </IonRow>
    </ActionRow>
  );
};

export default SearchSuggestionOcnCard;
