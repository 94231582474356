import { useMemo } from 'react';
import type { AxiosError } from 'axios';
import type { Dictionary } from 'lodash';
import { isEmpty, size, toString } from 'lodash';
import { useInfiniteQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { doConcatDataPages, doPromiseAPI, useKeyUserId } from 'api/helpers';
import type { LeaderboardItem } from 'models/LeaderBoard';
import type { InfiniteQueryFlags } from 'models/Search';
import { pageSize } from 'utils/constants';

export const findLeaderboardQueryKey = 'leaderboard';

interface UseFindLeaderboardProps {
  year: number;
  limit?: number;
  enabled?: boolean;
  eligible?: 'all' | 'Y' | 'N';
}

interface LeaderboardResponse {
  items: LeaderboardItem[];
  total?: number;
}

const useFindLeaderboard = ({
  year,
  eligible = 'all',
  limit = pageSize(),
  enabled = true,
}: UseFindLeaderboardProps): LeaderboardResponse & InfiniteQueryFlags => {
  const { axios } = useAxios();
  const { getLeaderboardAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const params: Dictionary<string> = {
    limit: toString(limit),
    eligible,
  };

  const doGetLeaderboard = ({ pageParam = 1 }) => {
    return doPromiseAPI<LeaderboardResponse>(async (cancelToken) => {
      const { data } = await axios.get<LeaderboardItem[]>(
        getLeaderboardAPI(
          year,
          toString(
            new URLSearchParams({ ...params, page: toString(pageParam) })
          )
        ),
        { cancelToken }
      );
      return { items: data };
    });
  };

  const response = useInfiniteQuery<LeaderboardResponse, AxiosError>(
    createQueryKey(findLeaderboardQueryKey, { ...params, year }),
    doGetLeaderboard,
    {
      enabled: enabled && !!toString(year),
      getNextPageParam: (lastPage, pages) =>
        size(lastPage.items) < limit ? false : size(pages) + 1,
    }
  );

  const {
    data,
    error,
    status,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = response;

  const leaderboardItems = useMemo(
    () =>
      doConcatDataPages<LeaderboardItem, LeaderboardResponse>(data, 'items'),
    [data]
  );

  const hasItems = !isEmpty(leaderboardItems);
  const hasError = status === 'error';
  const isEmptyResponse = status === 'success' && !hasNextPage && !hasItems;
  const noMoreData = status === 'success' && !hasNextPage && hasItems;
  const showLoader = status === 'loading' || isFetchingNextPage;
  const enableInfiniteScroll = !(!hasNextPage || isFetchingNextPage);

  return {
    fetchNextPage: async () => {
      await fetchNextPage();
    },
    items: leaderboardItems,
    total: !showLoader ? data?.pages[0].total : undefined,
    error,
    hasError,
    showLoader,
    isEmptyResponse,
    noMoreData,
    enableInfiniteScroll,
    refetch: async () => {
      await refetch?.call(null);
    },
  };
};

export default useFindLeaderboard;
