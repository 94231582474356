import React, { useCallback, useState, createContext, useContext } from 'react';
import type { Dictionary } from 'lodash';
import { isNil, toNumber } from 'lodash';
import { getUnixTime, startOfToday } from 'date-fns';
import type { DateSegmentType, ReportsContextProps } from 'models/Reports';
import { SortDirEnum, SortFieldEnum } from 'models/Sort';

interface IReportsContextProps {
  busPeriod?: Dictionary<number>;
  defaultRequestType?: Dictionary<DateSegmentType>;
  requestType?: Dictionary<DateSegmentType>;
  sortField?: Dictionary<string>;
  sortDir?: Dictionary<string>;
  updateRequestType: (key: string, dateSegment: DateSegmentType) => void;
  updateBusPeriod: (key: string, date: number) => void;
  updateSortField: (key: string, field: string) => void;
  updateSortDir: (key: string, dir: string) => void;
  defaultSortField?: Dictionary<string>;
  defaultSortType?: Dictionary<string>;
  defaultSortDir?: Dictionary<string>;
}

export const TabHomeKey = 'home-tab';
export const TabSalesPerformanceKey = 'sales-tab';
export const TabPick12ReportKey = 'pick12-report-tab';
export const TabWebSalesReportKey = 'web-report-tab';
export const SalesPerformanceHomeKey = 'sales-home-key';
export const Pick12HomeKey = 'pick12-home-key';
export const WebSalesHomeKey = 'web-home-key';
export const ProductGroupKey = 'product-group-key';
export const TabEOMReportKey = 'eom-group-key';
export const TabCostSavingsReportKey = 'cost-savings-key';
export const TabUnbilledReportKey = 'unbilled-key';
export const DatePickerKey = 'date-picker-key';

const ReportsContext = createContext<IReportsContextProps>({
  updateRequestType: () => null,
  updateBusPeriod: () => null,
  updateSortField: () => null,
  updateSortDir: () => null,
});

const ReportsProvider = ({
  children,
}: React.PropsWithChildren<unknown>): JSX.Element => {
  const [busPeriod, setBusPeriod] = useState<Dictionary<number>>();
  const [sortField, setSortFieldKey] = useState<Dictionary<string>>();
  const [sortDir, setSortDirKey] = useState<Dictionary<string>>();
  const [requestType, setRequestType] = useState<Dictionary<DateSegmentType>>();
  const defaultRequestType: Dictionary<DateSegmentType> = {
    [TabHomeKey]: 'DAILY',
    [Pick12HomeKey]: 'YTD',
    [SalesPerformanceHomeKey]: 'DAILY',
    [WebSalesHomeKey]: 'MTD',
    [ProductGroupKey]: 'YTD',
    [TabEOMReportKey]: 'MTD',
    [DatePickerKey]: 'DAILY',
  };

  const defaultSortField: Dictionary<string> = {
    [TabCostSavingsReportKey]: SortFieldEnum.overage,
    [TabSalesPerformanceKey]: SortFieldEnum.miLoc,
    [TabUnbilledReportKey]: SortFieldEnum.totalPossibleAmt,
  };

  const defaultSortDir: Dictionary<string> = {
    [TabCostSavingsReportKey]: SortDirEnum.ASCENDING,
    [TabSalesPerformanceKey]: SortDirEnum.ASCENDING,
    [TabUnbilledReportKey]: SortDirEnum.DESCENDING,
  };

  const updateRequestType = (key: string, newDateSegment: DateSegmentType) => {
    if (requestType?.[key] !== newDateSegment) {
      setRequestType((prev) => ({ ...prev, [key]: newDateSegment }));
    }
  };

  const updateBusPeriod = (key: string, newBusPeriod: number) => {
    if (busPeriod?.[key] !== newBusPeriod) {
      setBusPeriod((prev) => ({ ...prev, [key]: newBusPeriod }));
    }
  };

  const updateSortField = (key: string, field: string) => {
    if (sortField?.[key] !== field) {
      setSortFieldKey((prev) => ({ ...prev, [key]: field }));
    }
  };

  const updateSortDir = (key: string, dir: string) => {
    if (sortDir?.[key] !== dir) {
      setSortDirKey((prev) => ({ ...prev, [key]: dir }));
    }
  };

  return (
    <ReportsContext.Provider
      value={{
        busPeriod,
        defaultRequestType,
        requestType,
        sortField,
        sortDir,
        updateRequestType,
        updateBusPeriod,
        updateSortField,
        updateSortDir,
        defaultSortField,
        defaultSortDir,
      }}
    >
      {children}
    </ReportsContext.Provider>
  );
};
export default ReportsProvider;

interface UseReportsConfigProps {
  key: string;
}

export const useReportsConfig = ({
  key,
}: UseReportsConfigProps): ReportsContextProps => {
  const ctx = useContext(ReportsContext);

  if (!ctx) {
    throw Error(
      'The `useReportsConfig` hook must be called inside a `ReportsProvider`.'
    );
  }

  const defaultRequestType = ctx.defaultRequestType?.[key] || 'MTD';

  return {
    busPeriod: !isNil(ctx.busPeriod?.[key])
      ? toNumber(ctx.busPeriod?.[key])
      : getUnixTime(startOfToday()),
    requestType:
      ctx.requestType?.[key] || ctx.defaultRequestType?.[key] || 'MTD',
    updateRequestType: useCallback(
      (newDateSegment: DateSegmentType = defaultRequestType) =>
        ctx.updateRequestType(key, newDateSegment),
      [ctx, defaultRequestType, key]
    ),
    updateBusPeriod: useCallback(
      (newBusPeriod: Date) =>
        ctx.updateBusPeriod(key, getUnixTime(newBusPeriod)),
      [ctx, key]
    ),
    sortField:
      ctx.sortField?.[key] ||
      ctx.defaultSortField?.[key] ||
      SortFieldEnum.sales,
    updateSortField: useCallback(
      (option: string) => ctx.updateSortField(key, option),
      [ctx, key]
    ),
    sortDir:
      ctx.sortDir?.[key] || ctx.defaultSortDir?.[key] || SortDirEnum.DESCENDING,
    updateSortDir: useCallback(
      (option: string) => ctx.updateSortDir(key, option),
      [ctx, key]
    ),
  };
};
