import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import classnames from 'classnames';
import { includes, isEmpty, isNil, toNumber, toString } from 'lodash';
import { formatNumber } from 'common/utils/numberHelper';
import { namespaces } from 'i18n/i18n.constants';
import type { SecondaryTextProps } from 'InventoryApp/components/ListItem/ListItem';
import ListItem from 'InventoryApp/components/ListItem/ListItem';
import ProductPrice from 'ProductSearchApp/components/ProductHeader/ProductPrice';
import type {
  Product,
  ProductDetailURLParams,
} from 'ProductSearchApp/models/Products';
import {
  supplierConnectCapableTitle,
  sssGreenArray,
} from 'ProductSearchApp/util/productSearchUtil';
import { removeLeadingZeros } from 'utils/number';
import { goToProductDetail } from 'navigation/navigationHelpers';
import MIIcon from 'assets/MIIcon.svg';
import type { BadgeType } from 'components/Badge/Badge';
import Text from 'components/Text/Text';
import classes from './ProductListItem.module.scss';

interface ProductListItemProp {
  hideCost?: boolean;
  isSSSItem?: boolean;
  productItem: Product;
  miLoc: string;
  customerId: string;
  showGpPercent?: boolean;
  onClick?: () => void;
  testid: string;
}

const ProductListItem = ({
  hideCost = false,
  isSSSItem = false,
  productItem,
  miLoc,
  customerId,
  onClick,
  showGpPercent = false,
  testid,
}: ProductListItemProp): JSX.Element => {
  const location = useLocation();
  const { productId, depth } = useParams<ProductDetailURLParams>();
  const params = new URLSearchParams(location.search);
  const fromCart = params.get('fromCart');
  const { t } = useTranslation(namespaces.product);

  const DBDefaultDate = '01/01/1900';

  const {
    imageURL,
    corpAvail,
    itemNumber,
    costSource,
    manufacturerCtlNo,
    groupSerial,
    miLocAvail,
    allDCAvailable,
    srvDCAvail,
    corpInterchangeAvailable,
    unitSellPrice,
    priceSourceDesc,
    itemUOMDesc,
    gpPct,
    mfgPartNumber,
    supConAvail,
    manufacturerName,
    supplierAvailablity,
    lastInvoiceDate = '',
    lastQuoteDate = '',
    customerStockNumber = '',
    hasOpportunities = '',
  } = productItem;

  const isValidInvoiceDate =
    !isEmpty(lastInvoiceDate) && DBDefaultDate !== lastInvoiceDate;
  const isValidLastQuoteDate =
    !isEmpty(lastQuoteDate) && DBDefaultDate !== lastQuoteDate;

  const sssGreen = includes(sssGreenArray, costSource);
  const showCostDetails = !isNil(gpPct) && !isSSSItem && showGpPercent;

  const tempListSecondary: SecondaryTextProps[] = [
    {
      children: (
        <>
          <Text
            variant="list-item-secondaryText"
            text={`${t('item')} ${removeLeadingZeros(itemNumber)} `}
            textQuery={t('item')}
            className={classes.secondaryText}
          />
          <Text
            variant="list-item-secondaryText"
            text={`| ${t('mino')} ${manufacturerCtlNo}${groupSerial}`}
            textQuery={`| ${t('mino')}`}
            className={classes.secondaryText}
          />
          {!isEmpty(customerStockNumber) && (
            <Text
              variant="list-item-secondaryText"
              text={`| ${t('csn')} ${customerStockNumber}`}
              textQuery={`| ${t('csn')}`}
              className={classes.secondaryText}
            />
          )}
        </>
      ),
    },
  ];
  if (isValidInvoiceDate || isValidLastQuoteDate) {
    tempListSecondary.push({
      children: (
        <>
          {!isEmpty(lastInvoiceDate) && DBDefaultDate !== lastInvoiceDate && (
            <Text
              variant="list-item-secondaryText"
              text={t('prefixDate', {
                prefix: t('lastInvoice'),
                date: lastInvoiceDate,
              })}
              textQuery={t('lastInvoice')}
              className={classes.secondaryText}
            />
          )}
          {!isEmpty(lastQuoteDate) && DBDefaultDate !== lastQuoteDate && (
            <Text
              variant="list-item-secondaryText"
              text={t('prefixDate', {
                prefix: t('lastQuote'),
                date: toString(lastQuoteDate),
              })}
              textQuery={t('lastQuote')}
              className={classes.secondaryText}
            />
          )}
        </>
      ),
    });
  }
  const listSecondary = tempListSecondary.concat([
    {
      children: (
        <div className={classes.availDiv}>
          <Text
            variant="list-item-secondaryText"
            text={`${t('branch')} (${miLocAvail}),`}
            className={classes.secondaryText}
          />
          <Text
            variant="list-item-secondaryText"
            text={`${t('allDCs')} (${allDCAvailable}),`}
            className={classes.secondaryText}
          />
          <Text
            variant="list-item-secondaryText"
            text={`${t('fcdc')} (${srvDCAvail}),`}
            className={classes.secondaryText}
          />
          <Text
            variant="list-item-secondaryText"
            text={`${t('allLoc')} (${corpAvail})${!isSSSItem ? ',' : ''}`}
            className={classes.secondaryText}
          />
          {!isSSSItem && (
            <>
              <Text
                variant="list-item-secondaryText"
                text={`${t('allIc')} (${corpInterchangeAvailable}),`}
                className={classes.secondaryText}
              />
              <Text
                variant="list-item-secondaryText"
                text={`${t('suppliers')} (${supplierAvailablity})`}
                className={classes.secondaryText}
              />
            </>
          )}
        </div>
      ),
    },
    {
      children: (
        <>
          {!hideCost && (
            <>
              <Text
                variant="list-item-secondaryText"
                text={`$${unitSellPrice}`}
                textQuery={`${unitSellPrice} `}
                className={classes.secondaryText}
              />
              <Text
                variant="list-item-secondaryText"
                text={`${toString(priceSourceDesc)}/ ${toString(itemUOMDesc)}`}
                className={classnames(classes.secondaryText, classes.lowerCase)}
              />
            </>
          )}
          {showCostDetails && (
            <Text
              variant="list-item-secondaryText"
              text={t('gpPercent', {
                gpPct: toString(gpPct).substring(0, 5),
              })}
              className={classes.secondaryText}
            />
          )}
          {sssGreen && !isSSSItem && (
            <Text
              variant="list-item-secondaryText"
              text="SSS"
              className={classnames(
                classes.secondaryText,
                classes.secondaryTextGreen
              )}
            />
          )}
          {hasOpportunities === 'Y' && !isSSSItem && (
            <Text
              variant="list-item-secondaryText"
              text="SSS"
              className={classnames(
                classes.secondaryText,
                classes.secondaryTextBlue
              )}
            />
          )}
        </>
      ),
    },
  ]);

  let stockMessage = t('noStockSearch');
  let stockColor: BadgeType = 'error';
  const supplierAavail = toNumber(supplierAvailablity);
  if (corpAvail > 0) {
    const count = formatNumber({ number: corpAvail, abbreviated: true });
    stockMessage = t('inStock', { value: count });
    stockColor = 'green';
  } else if (supplierAavail > 0) {
    stockMessage = t('supplierStock', {
      value: formatNumber({
        number: supplierAavail,
        abbreviated: true,
      }),
    });
    stockColor = 'warning';
  }
  const stockImage = isEmpty(imageURL)
    ? MIIcon
    : `${toString(process.env.REACT_APP_IMAGES_URL)}${toString(imageURL)}`;

  return (
    <ListItem
      className={classnames({
        [classes.currentProduct]: itemNumber === productId,
      })}
      onClick={onClick}
      testid={testid}
      title={mfgPartNumber}
      titlePrefix={
        supConAvail === 'Y' ? supplierConnectCapableTitle : undefined
      }
      titleClassPrefix={classes.titlePrefix}
      overlay={manufacturerName}
      withoutHrefArrow={false}
      withArrow={false}
      disabled={false}
      href={
        itemNumber !== productId
          ? goToProductDetail({
              miLoc,
              customerNo: customerId,
              itemNo: itemNumber,
              depth: toString((toNumber(depth) || 0) + 1),
              fromCart: !!fromCart,
            })
          : undefined
      }
      secondaryTextArray={listSecondary}
      badge={{
        text: stockMessage,
        type: stockColor,
        icon:
          corpAvail > 0 || toNumber(supplierAvailablity) > 0
            ? 'check'
            : 'times',
      }}
      leftImage={stockImage}
      footer={
        isSSSItem && (
          <ProductPrice
            productData={productItem}
            testid={toString(productItem?.itemNumber)}
          />
        )
      }
    />
  );
};

export default ProductListItem;
