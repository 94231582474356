import { type TFunction } from 'react-i18next';
import {
  capitalize,
  get,
  isEmpty,
  isNil,
  toString,
  filter,
  take,
  endsWith,
} from 'lodash';
import {
  searchContactDetailURL,
  searchCustomerURL,
  searchDetailURL,
  searchLocationDetailURL,
  searchSupplierURL,
} from 'navigation';
import type { Address } from 'models/Address';
import type { Customer } from 'models/Customer';
import type {
  SearchCustomerContactItem,
  SearchCustomerItem,
  SearchEmployeeItem,
  SearchItem,
  SearchItemType,
  SearchMotionLocationItem,
  SearchSupplierContactItem,
} from 'models/Search';
import { SearchResultTablabelEnum } from 'models/Search';
import type { Supplier } from 'models/Supplier';
import { CORP_ACCOUNT_SUFFIX } from 'utils/constants';
import { withStringProp } from './helpers';

interface SearchNavigationProps {
  type: SearchItemType;
  miLoc: string;
  sequenceNo?: string;
  supplierId?: string;
  customerId?: string;
  employeeId?: string;
  locationId?: string;
  ocnId?: string;
  index?: number;
}

export const getSearchItemKey = ({
  type,
  miLoc,
  sequenceNo = '',
  customerId = '',
  supplierId = '',
  employeeId = '',
  locationId = '',
  ocnId = '',
  index,
}: SearchNavigationProps): string => {
  switch (type) {
    case 'customer':
      return `${type}-${miLoc}${customerId}`;
    case 'supplier':
      return `${type}-${supplierId}`;
    case 'customerContact':
      return `${type}-${miLoc}${customerId}-${sequenceNo || toString(index)}`;
    case 'supplierContact':
      return `${type}-${miLoc}${supplierId}-${sequenceNo || toString(index)}`;
    case 'employee':
      return `${type}-${employeeId}`;
    case 'motionLocation':
      return `${type}-${locationId}`;
    case 'ocns':
      return `${type}-${ocnId}`;
    default:
      return '';
  }
};

export const handleSearchNavigation = ({
  type,
  miLoc,
  sequenceNo,
  customerId,
  supplierId,
  employeeId,
}: SearchNavigationProps): string => {
  let url = '';
  switch (type) {
    case 'customer':
      url = searchCustomerURL(miLoc, customerId);
      break;
    case 'supplier':
      url = searchSupplierURL(miLoc, supplierId);
      break;
    case 'customerContact':
      url = searchContactDetailURL('customer', miLoc, customerId, sequenceNo);
      break;
    case 'supplierContact':
      url = searchContactDetailURL('supplier', miLoc, supplierId, sequenceNo);
      break;
    case 'motionLocation':
      url = searchLocationDetailURL(sequenceNo);
      break;
    default:
      url = searchDetailURL(type, employeeId);
  }
  return url;
};

export const getSnapshotProp = (
  searchType: string,
  customerKey: string,
  supplierKey: string,
  data?: Customer | Supplier
): string =>
  toString(
    searchType === 'customer' ? get(data, customerKey) : get(data, supplierKey)
  );

interface GetSnapshotEyebrowProps {
  name?: string;
  miLoc?: string;
  id: string;
  prefix?: string;
}

export const getSnapshotEyebrow = ({
  name = '',
  miLoc = '',
  id,
  prefix = '',
}: GetSnapshotEyebrowProps): string => {
  return name ? `${prefix}${miLoc}${id} - ${name}` : 'Loading';
};

interface GetSearchResultDataResponse {
  type: SearchItemType;
  miLoc: string;
  id: string;
  key: string;
  text: string;
  description: string;
  natlAcctNo?: string;
  address?: Address;
  compressedName?: string;
  customerNo?: string;
  supplierLocationNo?: string;
  customerPick12?: boolean;
  isCorpAccount: boolean;
}

export const getCustomerShortName = (compressedName?: string): string =>
  isNil(compressedName) || isEmpty(compressedName)
    ? ''
    : ` - ${compressedName}`;

export const getIsCorpAccount = (
  customerNo?: string,
  natlAcctNo?: string
): boolean => {
  return (
    take(customerNo, 6).join('') === natlAcctNo &&
    endsWith(customerNo, CORP_ACCOUNT_SUFFIX)
  );
};
export const getSearchResultData = (
  item: SearchItem,
  index: number,
  t: TFunction
): GetSearchResultDataResponse => {
  const {
    type,
    id,
    text,
    natlAcctNo,
    address,
    compressedName,
    customerPick12,
  } = item;
  const { miLoc } = item as SearchCustomerItem;
  const { jobPosition } = item as SearchEmployeeItem;
  const { supplierName, supplierLocationNo } =
    item as SearchSupplierContactItem;
  const { customerName, customerNo } = item as SearchCustomerContactItem;
  const {
    shipToAddressLine1,
    shipToAddressLine2,
    shipToAddressLine3,
    shipToCity,
    shipToState,
    shipToZIP,
  } = item as SearchMotionLocationItem;
  const key = getSearchItemKey({
    type,
    miLoc,
    sequenceNo: id,
    supplierId: supplierLocationNo || id || '',
    customerId: customerNo || id || '',
    employeeId: id,
    locationId: id,
    ocnId: id,
    index,
  });

  const customerString = t('common:customer');
  const supplierString = t('common:supplier');

  const shortName = getCustomerShortName(compressedName);
  let description;
  switch (type) {
    case 'customer':
      description = `${customerString} ${miLoc}${id}${shortName}`;
      break;
    case 'supplier':
      description = `${supplierString} ${id}`;
      break;
    case 'employee':
      description = `${jobPosition} ${miLoc}`;
      break;
    case 'customerContact':
      description = customerName;
      break;
    case 'supplierContact':
      description = supplierName;
      break;
    case 'motionLocation':
      description = filter([
        shipToAddressLine1,
        shipToAddressLine2,
        shipToAddressLine3,
        shipToCity,
        shipToState,
        shipToZIP,
      ]).join(', ');
      break;
    default:
      description = '';
  }
  return {
    type,
    miLoc,
    id,
    key,
    text,
    description,
    natlAcctNo,
    isCorpAccount:
      getIsCorpAccount(id, natlAcctNo) || withStringProp(natlAcctNo),
    address,
    customerNo,
    supplierLocationNo,
    customerPick12,
  };
};

export const getSearchResultTabLabel = (key: string): string => {
  return key in SearchResultTablabelEnum
    ? SearchResultTablabelEnum[key as keyof typeof SearchResultTablabelEnum]
    : capitalize(key);
};

export interface SnapshotRoutingState {
  isCorpAccount?: boolean;
  headerTitle?: string;
}
