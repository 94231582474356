import type { BaseAPIHook } from 'common/api/utils/apiHelpers';
import type {
  QueryFlags,
  QueryParamsType,
} from 'common/api/utils/useGetQueryFlags';
import useQuerySetup from 'common/api/utils/useQuerySetup';
import { and } from 'common/utils/logicHelpers';
import { useAxios } from 'providers/AxiosProvider';
import { useMiLocOrTeamId } from 'api/helpers';
import type { Customer } from 'models/Customer';
import { pageSize } from 'utils/constants';
import { findCustomersAPI } from './apiRoutes';

export const findCorpCustomersQueryKey = 'find-corp-customers';

interface UseFindCorpCustomersProps extends BaseAPIHook {
  miLoc?: string;
  nationalAcctNo?: string;
  query?: string;
  sortCol?: string;
  sortDir?: string;
}

interface UseFindCorpCustomersAPIResponse {
  records: Customer[];
  totalRecords: number;
}

interface UseFindCorpCustomersResponse extends QueryFlags {
  items: Customer[];
  totalItems?: number;
}

const useFindCorpCustomers = ({
  miLoc = '',
  nationalAcctNo = '',
  query = '',
  sortCol = 'name',
  sortDir = 'ASC',
  limit = pageSize(),
  enabled: propsEnabled = true,
}: UseFindCorpCustomersProps): UseFindCorpCustomersResponse => {
  const { axios } = useAxios();
  const { getURLParams } = useMiLocOrTeamId({});
  const enabled = and(propsEnabled, !!miLoc, !!nationalAcctNo);
  const params: QueryParamsType = {
    miLoc,
    nationalAcctNo: `${miLoc}${nationalAcctNo}`,
    query,
    sortCol,
    sortDir,
    includeShipTo: true,
    limit,
  };

  const response = useQuerySetup<Customer, UseFindCorpCustomersResponse>({
    queryKey: findCorpCustomersQueryKey,
    queryParams: params,
    onAPIRequest: async ({ pageParam = 1, signal }) => {
      const { data } = await axios.get<UseFindCorpCustomersAPIResponse>(
        findCustomersAPI(getURLParams({ ...params, page: pageParam })),
        { signal }
      );
      return { items: data.records, totalItems: data.totalRecords };
    },
    dataPath: 'items',
    totalDataPath: 'totalItems',
    limit,
    enabled,
    isInfiniteQuery: true,
  });

  const { items, totalItems, ...queryFlags } = response;

  return { ...queryFlags, items, totalItems };
};

export default useFindCorpCustomers;
