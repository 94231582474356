import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { map, toString } from 'lodash';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import useFindProducts from 'CostSavingsApp/api/useFindProducts';
import type { SelectModalItem } from 'models/Search';
import { removeLeadingZeros } from 'utils/number';
import Button from 'components/Button/Button';
import SelectModal from 'components/SelectModal/SelectModal';
import Toggle from 'components/Toggle/Toggle';
import classes from './FastFind.module.scss';

interface ProductSelectProps {
  miLoc: string;
  selItem?: SelectModalItem;
  className?: string;
  label: string;
  required?: boolean;
  iconClassName?: string;
  placeholder: string;
  disabled?: boolean;
  setProduct: (o: SelectModalItem) => void;
  showInput?: boolean;
  openModal?: boolean;
  setOpenModal?: (b: boolean) => void;
  onBlur?: () => void;
  inputError?: string;
}

const ProductSelect = ({
  miLoc,
  selItem,
  className,
  label,
  required,
  iconClassName,
  placeholder,
  disabled,
  setProduct,
  showInput = true,
  openModal = false,
  setOpenModal,
  onBlur,
  inputError,
}: ProductSelectProps & IonicReactProps): JSX.Element => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState('');
  const [inStockOnly, setInStockOnly] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  const {
    error,
    products,
    isEmptyResponse,
    showLoader,
    fetchNextPage,
    enableInfiniteScroll,
  } = useFindProducts({
    miLoc,
    query: searchQuery,
    inStockOnly,
  });

  const inputDisplay = selItem?.key
    ? `(${removeLeadingZeros(selItem?.key)}) ${selItem.title}`
    : '';

  useEffect(() => {
    if (!isOpen && !openModal) {
      setSearchQuery('');
    }
  }, [isOpen, openModal, searchQuery, setSearchQuery]);

  const productsItems = map(products, (item) => ({
    item,
    key: item.itemNumber,
    title: t('costSavings:productItemNo', {
      itemNo: removeLeadingZeros(item.itemNumber),
    }),
    description: `${item.partNumber} ${item.manufacturerName} ${item.groupSerial}`,
  }));

  return (
    <SelectModal
      isOpen={setOpenModal ? openModal : isOpen}
      setIsOpen={setOpenModal || setIsOpen}
      type="product"
      subType=""
      selItem={selItem || { title: '', description: '', key: '' }}
      setSelItem={(o) => {
        setTimeout(() => {
          setProduct({ ...o, title: toString(o.description) });
        }, 100);
      }}
      testid="select-product-modal"
      isEmptyResponse={isEmptyResponse}
      showLoader={showLoader && searchQuery.length > 0}
      fetchNextPage={fetchNextPage}
      error={error}
      enableInfiniteScroll={enableInfiniteScroll}
      items={productsItems}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      onClick={() => setIsOpen(true)}
      onBlur={onBlur}
      inputError={inputError}
      label={label}
      customContent={
        <div>
          <Toggle
            className={classes.stockToggle}
            wrapperClassName={classes.toggleWrapper}
            checked={inStockOnly}
            lines="none"
            onClick={() => setInStockOnly(!inStockOnly)}
            testid="in-stock-flag"
          >
            <Button
              testid="toggle-button"
              textVariant="mipro-body-copy"
              text={t('costSavings:inStockOnly')}
            />
          </Toggle>
        </div>
      }
      required={required}
      value={inputDisplay}
      placeholder={placeholder}
      disabled={disabled}
      iconClassName={iconClassName}
      showInput={showInput}
      className={className}
      icon="caret-down"
    />
  );
};

export default ProductSelect;
