/* eslint-disable no-console */
import { includes, isArray, map, reduce, size } from 'lodash';
import type { SQLiteDBConnection } from '@capacitor-community/sqlite';
import type { CountGroupItem } from 'InventoryApp/models/InventoryPlanGroup';
import { useDevice } from 'providers/DeviceProvider';
import { createGroupItems } from './utils';

const versionDBTable = `CREATE TABLE IF NOT EXISTS versionDB (
  version TEXT
)`;

const countPlanTable = `CREATE TABLE IF NOT EXISTS count_plan (
  countPlanId TEXT PRIMARY KEY,
  countType TEXT,
  miLoc TEXT,
  groupedLines TEXT,
  manualLines TEXT,
  manualPages TEXT,
  totalLines TEXT,
  totalPages TEXT,
  totalRows TEXT,
  unGroupedLines TEXT,
  updatedLines TEXT,
  updatedManualLines TEXT,
  updatedManualPages TEXT,
  updatedPages TEXT
)`;

const countPlanIndex = `CREATE UNIQUE INDEX IF NOT EXISTS idx_count_plan
  ON count_plan (countPlanId)`;

const countGroupTable = `CREATE TABLE IF NOT EXISTS count_group (
  uniqueId TEXT PRIMARY KEY,
  amtPctDiff TEXT,
  assignedEmpId TEXT,
  assignedEmpId2 TEXT,
  assignedUserId TEXT,
  assignedUserId2 TEXT,
  completedBy TEXT,
  confirmInput TEXT,
  consign TEXT,
  countCompleted TEXT,
  countDownloaded TEXT,
  countFinalized TEXT,
  countPlanId TEXT,
  countPrinted TEXT,
  countType TEXT,
  countTypeDescription TEXT,
  createdBy TEXT,
  custAcct TEXT,
  customerName TEXT,
  customerNo TEXT,
  billCustomerNo TEXT,
  billCustomerName TEXT,
  dateCompleted TEXT,
  dateCreated TEXT,
  dateDeleted TEXT,
  dateDownloaded TEXT,
  dateFinalized TEXT,
  datePrinted TEXT,
  defDeptNo TEXT,
  defHdg1 TEXT,
  defHdg2 TEXT,
  defHdg3 TEXT,
  defHdg4 TEXT,
  defHdg5 TEXT,
  defaultAcctNo TEXT,
  defaultCustomerNo TEXT,
  deletedBy TEXT,
  description TEXT,
  disableOnOrderFl TEXT,
  downloadedBy TEXT,
  finalizedBy TEXT,
  groupId TEXT,
  groupType TEXT,
  issues TEXT,
  itemClassCd TEXT,
  labelAtOrd TEXT,
  langCd TEXT,
  lines TEXT,
  linesUpdated TEXT,
  locHdg1 TEXT,
  locHdg2 TEXT,
  locHdg3 TEXT,
  locHdg4 TEXT,
  locHdg5 TEXT,
  manualLines TEXT,
  manualLinesUpdated TEXT,
  manualPages TEXT,
  manualPagesEntered TEXT,
  miLoc TEXT,
  model TEXT,
  name TEXT,
  oneLocFlag TEXT,
  optionMaint TEXT,
  orderItemLimit TEXT,
  orders TEXT,
  pages TEXT,
  pagesEntered TEXT,
  partIdentCD TEXT,
  passThru TEXT,
  planEndDate TEXT,
  planName TEXT,
  planStartDate TEXT,
  printedBy TEXT,
  qtyPctDiff TEXT,
  receipts TEXT,
  replen TEXT,
  seqOpiOrdersBy TEXT,
  sortSequenceName TEXT,
  localSortField TEXT,
  localSortDir TEXT,
  splitOrdBySrc TEXT,
  startScanCd TEXT,
  startedBy TEXT,
  status TEXT,
  storeroomCustomerNo TEXT,
  storeroomName TEXT,
  storeroomNo TEXT,
  systemLines TEXT,
  systemLinesUpdated TEXT,
  systemPages TEXT,
  systemPagesEntered TEXT,
  teamMember1 TEXT,
  teamMember2 TEXT,
  timeCompleted TEXT,
  timeCreated TEXT,
  timeDeleted TEXT,
  timeDownloaded TEXT,
  timeFinalized TEXT,
  timePrinted TEXT,
  isDeleted BOOL,
  downloadedItems TEXT,
  countedItems TEXT
)`;

const countGroupIndex = `CREATE UNIQUE INDEX IF NOT EXISTS idx_count_group
  ON count_group (uniqueId)`;

const countPlanItemTable = `CREATE TABLE IF NOT EXISTS count_plan_item (
  uniqueId TEXT PRIMARY KEY,
  countPlanId TEXT,
  groupId TEXT,
  hasCount BOOL,
  miLoc TEXT,
  actualCost INTEGER,
  actualCount TEXT,
  barcode TEXT,
  bin TEXT,
  custStockNo TEXT,
  description TEXT,
  groupSerialNo TEXT,
  itemNo TEXT,
  lineNo INTEGER,
  loc1 TEXT,
  loc2 TEXT,
  loc3 TEXT,
  loc4 TEXT,
  loc5 TEXT,
  lotNo INTEGER,
  mfrCtlNo TEXT,
  miMaxQty INTEGER,
  miMinQty INTEGER,
  mino TEXT,
  upcNo TEXT,
  uom TEXT,
  onOrderQty TEXT,
  orderQty INTEGER,
  pageNo INTEGER,
  type TEXT,
  unprocessedReceiptQty INTEGER,
  unprocessedUsageQty INTEGER,
  actualItemWidth REAL,
  actualItemLength REAL,
  rowNbr TEXT,
  slabRef TEXT,
  mfrName TEXT,
  hasEditedMinMax BOOL,
  allowEdit BOOL,
  hasLocalCount BOOL
)`;

const countPlanItemIndex = `CREATE UNIQUE INDEX IF NOT EXISTS idx_count_plan_item
  ON count_plan_item (uniqueId)`;

const countPlanOptionTable = `CREATE TABLE IF NOT EXISTS count_plan_option (
  countPlanId TEXT PRIMARY KEY,
  canGroupItems TEXT,
  consign TEXT,
  forceCountAllItems TEXT,
  hasEmail TEXT,
  hasManualSheets TEXT,
  createOrder TEXT,
  reviewVariance TEXT,
  source TEXT
)`;

const countPlanOptionIndex = `CREATE UNIQUE INDEX IF NOT EXISTS idx_count_plan_option
  ON count_plan_option (countPlanId)`;

const profileDefaultSortTable = `CREATE TABLE IF NOT EXISTS profile_default_sort (
  uniqueId TEXT PRIMARY KEY,
  miLoc TEXT,
  customerNo TEXT,
  sortField TEXT,
  sortDir TEXT
)`;

const profileDefaultSortIndex = `CREATE UNIQUE INDEX IF NOT EXISTS idx_profile_default_sort
  ON profile_default_sort (uniqueId)`;

interface UseInventoryDBResponse {
  db: SQLiteDBConnection;
  openDB: () => Promise<void>;
  closeDB: () => Promise<void>;
  getLikeStatement: (
    props: string | string[],
    vars?: unknown[],
    exactMatch?: boolean
  ) => string;
  getWhereStatement: (whereStatement: string[]) => string;
}

interface VersionDB {
  version: string;
}

const useInventoryDB = (): UseInventoryDBResponse => {
  const { inventoryDB: db } = useDevice();

  // #region migrations
  const migrateTo1dot0 = async (versions: string[]): Promise<void> => {
    if (includes(versions, '1.0')) {
      return;
    }
    await db.execute(`DROP TABLE IF EXISTS count_plan_item`);
    await db.query(
      `INSERT INTO versionDB (
      version
      ) VALUES (?)`,
      ['1.0']
    );
  };

  const migrateTo1dot1 = async (versions: string[]): Promise<void> => {
    if (includes(versions, '1.1')) {
      return;
    }
    await db.execute(`DROP TABLE IF EXISTS count_group`);
    await db.query(
      `INSERT INTO versionDB (
      version
      ) VALUES (?)`,
      ['1.1']
    );
  };

  const migrateTo1dot2 = async (versions: string[]): Promise<void> => {
    if (includes(versions, '1.2')) {
      return;
    }
    await db.execute(`DROP TABLE IF EXISTS count_plan_item`);
    await db.query(
      `INSERT INTO versionDB (
      version
      ) VALUES (?)`,
      ['1.2']
    );
  };

  const migrateTo1dot3 = async (versions: string[]): Promise<void> => {
    if (includes(versions, '1.3')) {
      return;
    }
    await db.execute(`DROP TABLE IF EXISTS count_plan_item`);
    await db.query(
      `INSERT INTO versionDB (
      version
      ) VALUES (?)`,
      ['1.3']
    );
  };

  const migrateOfflineItems = async (): Promise<void> => {
    await db.execute(countPlanItemTable);
    const offlineItems = (await db.query(`SELECT * from count_plan_item`))
      .values as CountGroupItem[];
    // DOC: migrate downloaded items
    await db.execute(`DROP TABLE IF EXISTS count_plan_item`);
    await db.execute(countPlanItemTable);
    await createGroupItems(db, offlineItems);
  };

  const migrateTo1dot4 = async (versions: string[]): Promise<void> => {
    if (includes(versions, '1.4')) {
      return;
    }
    await migrateOfflineItems();

    await db.query(
      `INSERT INTO versionDB (
      version
      ) VALUES (?)`,
      ['1.4']
    );
  };

  const migrateTo1dot5 = async (versions: string[]): Promise<void> => {
    if (includes(versions, '1.5')) {
      return;
    }
    await migrateOfflineItems();

    await db.query(
      `INSERT INTO versionDB (
      version
      ) VALUES (?)`,
      ['1.5']
    );
  };

  const migrateTo1dot6 = async (versions: string[]): Promise<void> => {
    if (includes(versions, '1.6')) {
      return;
    }
    await db.execute(`DROP TABLE IF EXISTS count_plan_item`);

    await db.query(
      `INSERT INTO versionDB (
      version
      ) VALUES (?)`,
      ['1.6']
    );
  };

  const migrateTo1dot7 = async (versions: string[]): Promise<void> => {
    if (includes(versions, '1.7')) {
      return;
    }
    await migrateOfflineItems();

    await db.query(
      `INSERT INTO versionDB (
      version
      ) VALUES (?)`,
      ['1.7']
    );
  };

  const migrateTo1dot8 = async (versions: string[]): Promise<void> => {
    if (includes(versions, '1.8')) {
      return;
    }
    await db.execute(`DROP TABLE IF EXISTS count_group`);

    await db.query(
      `INSERT INTO versionDB (
      version
      ) VALUES (?)`,
      ['1.8']
    );
  };

  const migrateTo1dot9 = async (versions: string[]): Promise<void> => {
    if (includes(versions, '1.9')) {
      return;
    }
    await db.execute(`DROP TABLE IF EXISTS count_group`);

    await db.query(
      `INSERT INTO versionDB (
      version
      ) VALUES (?)`,
      ['1.9']
    );
  };

  const migrateTo1dot10 = async (versions: string[]): Promise<void> => {
    if (includes(versions, '1.10')) {
      return;
    }
    await migrateOfflineItems();

    await db.query(
      `INSERT INTO versionDB (
      version
      ) VALUES (?)`,
      ['1.10']
    );
  };

  const migrateTo1dot11 = async (versions: string[]): Promise<void> => {
    if (includes(versions, '1.11')) {
      return;
    }
    await migrateOfflineItems();

    await db.query(
      `INSERT INTO versionDB (
      version
      ) VALUES (?)`,
      ['1.11']
    );
  };

  // #endregion

  const createTables = async (): Promise<void> => {
    try {
      await db.execute(versionDBTable);
      const versions = map(
        (await db.query(`SELECT * FROM versionDB`)).values as VersionDB[],
        (v) => v.version
      );
      await migrateTo1dot0(versions);
      await migrateTo1dot1(versions);
      await migrateTo1dot2(versions);
      await migrateTo1dot3(versions);
      await migrateTo1dot4(versions);
      await migrateTo1dot5(versions);
      await migrateTo1dot6(versions);
      await migrateTo1dot7(versions);
      await migrateTo1dot8(versions);
      await migrateTo1dot9(versions);
      await migrateTo1dot10(versions);
      await migrateTo1dot11(versions);
      await db.execute(countPlanTable);
      await db.execute(countPlanIndex);
      await db.execute(countGroupTable);
      await db.execute(countGroupIndex);
      await db.execute(countPlanItemTable);
      await db.execute(countPlanItemIndex);
      await db.execute(countPlanOptionTable);
      await db.execute(countPlanOptionIndex);
      await db.execute(profileDefaultSortTable);
      await db.execute(profileDefaultSortIndex);
    } catch (error) {
      console.log('Error creating tables', error);
    }
  };

  const openDB = async (): Promise<void> => {
    try {
      await db.open();
      await createTables();
    } catch (error) {
      console.log('Error opening DB connection', error);
    }
  };

  const closeDB = async (): Promise<void> => {
    try {
      await db.close();
    } catch (error) {
      console.log('Error closing DB connection', error);
    }
  };

  const getLikeStatement = (
    props: string | string[],
    vars?: unknown[],
    exactMatch = false
  ) => {
    let items = [];
    if (!isArray(props)) {
      items = [props];
    } else {
      items = props;
    }
    return `${size(vars) > 0 ? 'AND ' : ''}(${map(items, (prop) =>
      exactMatch ? `${prop} = ?\n` : `${prop} LIKE ? COLLATE NOCASE\n`
    ).join('OR\n')})`;
  };

  const getWhereStatement = (whereStatement: string[]) =>
    `${size(whereStatement) > 0 ? `WHERE` : ''}
    ${reduce(
      whereStatement,
      (prev, statement) => `${prev}${statement}\n`,
      ''
    )}`;

  return {
    db,
    openDB,
    closeDB,
    getLikeStatement,
    getWhereStatement,
  };
};

export default useInventoryDB;
