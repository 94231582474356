import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { map } from 'lodash';
import { IonContent, IonPage } from '@ionic/react';
import CustomerName from 'common/components/Header/CustomerName';
import Header from 'common/components/Header/Header';
import { useIonContentRef } from 'common/components/utils/renderHelpers';
import type { OrderListRef } from 'ProductSearchApp/components/OrderList/OrderList';
import OrderList from 'ProductSearchApp/components/OrderList/OrderList';
import useGetCustomer from 'api/customer/useGetCustomer';
import useMiComConnection from 'api/external/useMiComConnection';
import type { SnapshotsURLParams } from 'models/Search';
import { getErrorMessage } from 'utils/helpers';
import { goToOCNSearch } from 'navigation/navigationHelpers';
import Loader from 'components/Loader/Loader';
import Refresher from 'components/Refresher/Refresher';
import type { SegmentTabOptionProps } from 'components/SegmentTabs/SegmentTabs';
import SegmentTabs from 'components/SegmentTabs/SegmentTabs';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './CustomerOrders.module.scss';

export enum OrderListTabEnum {
  closed = 'closed',
  quotes = 'quotes',
  orders = 'orders',
  unfinished = 'unfinished',
}

export interface OrderListTabOptionProps extends SegmentTabOptionProps {
  listTitle: string;
  emptyMessage: string;
  errorMessage: string;
  deleteTitle: string;
  deleteButton: string;
}

export type OrderListTabType = keyof typeof OrderListTabEnum;

const CustomerOrders = (): JSX.Element => {
  const { t } = useTranslation();
  const namespace = 'productSearch:orderList';
  const [tabValue, setTabValue] = useState<OrderListTabType>(
    OrderListTabEnum.quotes
  );
  const orderListRef = useRef<OrderListRef>(null);

  const tabs = [
    {
      key: OrderListTabEnum.quotes,
      text: t(`${namespace}:quoteTab`),
      listTitle: t(`${namespace}:quoteHeaderText`),
      emptyMessage: t(`${namespace}:quoteHeaderEmptyList`),
      errorMessage: t(`${namespace}:quoteHeaderError`),
      deleteTitle: t(`${namespace}:quoteDeleteTitle`),
      deleteButton: t(`${namespace}:quoteDeleteButton`),
    },
    {
      key: OrderListTabEnum.orders,
      text: t(`${namespace}:orderTab`),
      listTitle: t(`${namespace}:orderHeaderText`),
      emptyMessage: t(`${namespace}:orderHeaderEmptyList`),
      errorMessage: t(`${namespace}:orderHeaderError`),
      deleteTitle: t(`${namespace}:orderDeleteTitle`),
      deleteButton: t(`${namespace}:orderDeleteButton`),
    },
    {
      key: OrderListTabEnum.closed,
      text: t(`${namespace}:closedTab`),
      listTitle: t(`${namespace}:closedHeaderText`),
      emptyMessage: t(`${namespace}:closedHeaderEmptyList`),
      errorMessage: t(`${namespace}:closedHeaderError`),
      deleteTitle: t(`${namespace}:closedDeleteTitle`),
      deleteButton: t(`${namespace}:closedDeleteButton`),
    },
  ];
  const { miLoc, id } = useParams<SnapshotsURLParams>();
  const { nodeRef, node } = useIonContentRef();

  const {
    data: customerData,
    error: customerError,
    isLoading: customerIsLoading,
  } = useGetCustomer({ searchType: 'customer', miLoc, id });

  const { openMiComQuotesLink } = useMiComConnection({ miLoc, id });

  const headerActions = {
    title: t(`${namespace}:headerActionsTitle`),
    initialBreakpoint: 0.3,
    options: [
      {
        text: t(`${namespace}:viewMotionQuotes`),
        onClick: openMiComQuotesLink,
        testid: 'view-motion-quotes-button',
      },
    ],
  };

  return (
    <IonPage className={classes.page} data-testid="quotes-page">
      <Header
        customTitle={<CustomerName customerData={customerData} />}
        headerActions={headerActions}
        testid="order-list-page-header"
        customEndButtons={[
          {
            className: classNames(classes.searchButton, classes.condensed),
            testid: 'ocn-search-button',
            variant: 'icon-action',
            icon: 'search',
            href: goToOCNSearch(miLoc, id),
          },
        ]}
      />
      <IonContent ref={nodeRef} className={classes.content}>
        <Refresher
          slot="fixed"
          onRefresh={orderListRef.current?.refetch}
          disabled={customerIsLoading}
          hidden
          testid="order-list-refresher"
        />
        {customerIsLoading && (
          <Loader
            isOpen={customerIsLoading}
            className={classes.loader}
            text={t('common:loading')}
            testid="customer-loader"
          />
        )}
        {customerError && (
          <WarningMessage
            className={classes.message}
            title={t(`${namespace}:customerErrorTitle`)}
            body={getErrorMessage(customerError)}
            testid="customer-error"
          />
        )}
        {!customerIsLoading && !customerError && (
          <>
            <Header
              className={classes.header}
              collapse="condense"
              pageTitle={t(`${namespace}:quotesTitle`)}
              customTitle={<CustomerName customerData={customerData} />}
              headerActions={headerActions}
              customEndButtons={[
                {
                  className: classes.searchButton,
                  testid: 'ocn-search-button',
                  variant: 'icon-action',
                  icon: 'search',
                  href: goToOCNSearch(miLoc, id),
                },
              ]}
              testid="order-list-header"
            />
            <SegmentTabs
              className={classes.tabs}
              variant="miproWhite"
              options={tabs}
              value={tabValue}
              setValue={(v) => setTabValue(v as OrderListTabType)}
              textVariant="title-action-card"
              testid="order-list-tabs"
            />
            {map(tabs, (orderType) => (
              <React.Fragment key={orderType.key}>
                {tabValue === orderType.key && (
                  <OrderList
                    ref={orderListRef}
                    orderType={orderType}
                    customerData={customerData}
                    scrollParent={node}
                  />
                )}
              </React.Fragment>
            ))}
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default CustomerOrders;
