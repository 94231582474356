import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { toNumber, toString } from 'lodash';
import { IonItem, IonLabel, IonList, IonSkeletonText } from '@ionic/react';
import { formatNumber } from 'common/utils/numberHelper';
import type {
  LeaderboardDetailItem,
  LeaderboardItem,
} from 'models/LeaderBoard';
import AvatarCard from 'components/Avatar/AvatarCard';
import LeaderboardShape from 'components/LeaderboardShape/LeaderboardShape';
import Text from 'components/Text/Text';
import classes from './Pick12LeaderBoardDetail.module.scss';

interface LeaderBoardDetailCardProps {
  isLoading: boolean;
  rank: number;
  closeLeaderBoardCard?: () => void;
  leaderboardItem?: LeaderboardItem & LeaderboardDetailItem;
}

const LeaderBoardDetailCard = ({
  isLoading,
  closeLeaderBoardCard,
  leaderboardItem,
  rank,
}: LeaderBoardDetailCardProps): JSX.Element => {
  const { t } = useTranslation();
  const name = `${toString(leaderboardItem?.firstName)} ${toString(
    leaderboardItem?.lastName
  )}`;
  return (
    <LeaderboardShape
      className="leadeboardDetail"
      rank={rank || 0}
      isLoading={isLoading}
      showCloseButton
      closeLeaderBoardCard={closeLeaderBoardCard}
      testid="leaderboard-detail-card-one"
    >
      {!isLoading ? (
        <AvatarCard
          displayName={name}
          className={classes.circle}
          backgroundColor={classes.avatarFallback}
        />
      ) : (
        <div className={classes.circle} />
      )}

      <div className={classNames(classes.nameWrapper, classes.firstName)}>
        {isLoading ? (
          <IonSkeletonText animated data-testid="first-name-loading" />
        ) : (
          <Text
            variant="title-card-red"
            text={leaderboardItem?.firstName || ''}
            className={classes.name}
            testid="leaderboard-first-name"
          />
        )}
      </div>
      <div className={classNames(classes.nameWrapper, classes.secondName)}>
        {isLoading ? (
          <IonSkeletonText animated data-testid="second-name-loading" />
        ) : (
          <Text
            variant="title-card-red"
            text={leaderboardItem?.lastName || ''}
            className={classes.name}
            testid="leaderboard-second-name"
          />
        )}
      </div>
      <div className={classes.loc}>
        {isLoading ? (
          <IonSkeletonText animated data-testid="miloc-loading" />
        ) : (
          <Text
            variant="title-info-card"
            text={toString(leaderboardItem?.miLoc)}
            className={classes.miLocText}
            testid="miloc"
          />
        )}
      </div>
      <IonList className={classes.detailCard}>
        <IonItem lines="none">
          <IonLabel slot="start">
            {toString(t('pick12LeaderBoard:totalSales'))}
          </IonLabel>
          {isLoading ? (
            <IonSkeletonText animated data-testid="total-sales-loading" />
          ) : (
            <IonLabel slot="end">
              {formatNumber({
                number: toNumber(leaderboardItem?.sales),
                abbreviated: true,
                currencyType: leaderboardItem?.currency,
              })}
            </IonLabel>
          )}
        </IonItem>
        <IonItem lines="none">
          <IonLabel slot="start">
            {toString(t('pick12LeaderBoard:totalGp'))}
          </IonLabel>
          {isLoading ? (
            <IonSkeletonText animated data-testid="total-gp-loading" />
          ) : (
            <IonLabel slot="end" data-testid="total-gp">
              {formatNumber({
                number: toNumber(leaderboardItem?.gp),
                abbreviated: true,
                currencyType: leaderboardItem?.currency,
              })}
            </IonLabel>
          )}
        </IonItem>
        <IonItem lines="none">
          <IonLabel slot="start" data-testid="gp-percent-text">
            {toString(t('pick12LeaderBoard:gpPercent'))}
          </IonLabel>
          {isLoading ? (
            <IonSkeletonText animated />
          ) : (
            <IonLabel slot="end">{`${
              leaderboardItem?.gpPercent
                ? toNumber(leaderboardItem?.gpPercent)
                : 0
            }%`}</IonLabel>
          )}
        </IonItem>
        <IonItem lines="none">
          <IonLabel slot="start" data-testid="active-pcik12-customers-text">
            {toString(t('pick12LeaderBoard:activePick12Customers'))}
          </IonLabel>
          {isLoading ? (
            <IonSkeletonText animated data-testid="active-pick12-loading" />
          ) : (
            <IonLabel slot="end">
              {`${toString(leaderboardItem?.pick12Active)}/${toString(
                leaderboardItem?.pick12Total
              )}`}
            </IonLabel>
          )}
        </IonItem>
      </IonList>
    </LeaderboardShape>
  );
};

export default LeaderBoardDetailCard;
