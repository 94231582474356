import type { AxiosError } from 'axios';
import { isEmpty, toString } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { doPromiseAPI, useKeyUserId } from 'api/helpers';
import type {
  LeaderboardItem,
  LeaderboardDetailItem,
} from 'models/LeaderBoard';
import type { QueryFlags } from 'models/Search';

export const findLeaderboardDetailsQueryKey = 'leaderboardDetails';

interface UseFindLeaderboardProps {
  year: number;
  userId: string;
  enabled?: boolean;
}

interface LeaderBoardDetailItem
  extends LeaderboardItem,
    LeaderboardDetailItem {}

export interface LeaderboardDetailResponse {
  data?: LeaderBoardDetailItem;
}

const useFindLeaderboardDetails = ({
  year,
  userId,
  enabled = true,
}: UseFindLeaderboardProps): LeaderboardDetailResponse & QueryFlags => {
  const { axios } = useAxios();
  const { getLeaderBoardDetails } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();

  const doGetLeaderboardDetail = () => {
    return doPromiseAPI<LeaderBoardDetailItem>(async (cancelToken) => {
      const { data } = await axios.get<LeaderBoardDetailItem>(
        getLeaderBoardDetails(year, userId),
        { cancelToken }
      );
      return data;
    });
  };

  const response = useQuery<LeaderBoardDetailItem, AxiosError>(
    createQueryKey(findLeaderboardDetailsQueryKey, { year, userId }),
    doGetLeaderboardDetail,
    {
      enabled: enabled && !!toString(year) && !isEmpty(userId),
    }
  );

  const { data, error, status, isLoading } = response;

  const hasError = status === 'error';

  return {
    data,
    error,
    hasError,
    isLoading,
  };
};

export default useFindLeaderboardDetails;
