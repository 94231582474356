export enum PriceOverrideTypeEnum {
  priceOverride = 'OrderPriceOverrideRequest',
  priceOverrideApproved = 'PriceOverrideApproved',
  priceOverrideRejected = 'PriceOverrideRejected',
}

export enum PriceOverrideIconEnum {
  priceOverride = 'calculator',
  priceOverrideRejected = 'times',
  priceOverrideApproved = 'check',
}

export enum PriceOverrideColorEnum {
  priceOverride = 'color-priceOverride',
  priceOverrideApproved = 'color-priceOverrideApproved',
  priceOverrideRejected = 'color-priceOverrideRejected',
}

export enum PriceOverrideFilterEnum {
  priceOverride = 'priceOverride',
}

export interface PriceOverrideInfo {
  addToAgreement: string;
  agreementLastUpdated: string;
  approveRejectTmstmp: string;
  approveRejectUsername: string;
  cost: string;
  costSource: string;
  eCOSMaxExtendedPrice: string;
  overridePrice: string;
  finalCost: string;
  floorPrice: string;
  groupSerial: string;
  incidentalQualify: string;
  mfgPartNo: string;
  mfrCtlNo: string;
  mfrName: string;
  mino: string;
  opQtyOrdered: string;
  orderTypeCd: string;
  overrideReason: string;
  overrideReasonDesc: string;
  overrideStatus: string;
  pgcMfrGPPct: string;
  pgcMfrLastUpdated: string;
  priceIncreasePts: string;
  priceOverrideAgreement: string;
  priceSource: string;
  prodGroupNo: string;
  rebateFlag: string;
  recommendedGP: string;
  requestorEmail: string;
  requestorName: string;
  requestorPhone: string;
  requestorUserid: string;
  uomDescription: string;
  salesUomQty: string;
  notebooks: Array<{ text: string }>;
  notAbleToEmailReason?: string;
  lastDocumentTransmission?: {
    sendToList: string[];
    transmissionTmstmp: string;
  };
}
