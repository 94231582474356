import { includes, isArray, join } from 'lodash';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import type { CountGroup } from 'InventoryApp/models/InventoryPlanGroup';
import { findIcon } from 'utils/icons';

export const startVMICountType = ['S', '2'];

export const piBeltingCountType = ['6'];

const inventoryEvaluation = ['I'];
const piConsignment = ['1'];
const cycleCount = ['3'];
const cycleCountConsignment = ['4'];
const cycleCountEvaluation = ['5'];

export const isStartVMI = (listGroup?: CountGroup) =>
  includes(startVMICountType, listGroup?.countType);

export const isPiBelting = (listGroup?: CountGroup) =>
  includes(piBeltingCountType, listGroup?.countType);

export const inventoryCountType = ['I', '1', '3', '4', '5', '6'];

export const getLocationString = (loc: string | string[]) =>
  isArray(loc) ? join(loc, ', ') : loc;

export interface InventoryPlanIcon {
  icon: IconProp;
  color: string;
}
export const getInventoryPlanIcon = (countType: string): InventoryPlanIcon => {
  switch (true) {
    case includes(piBeltingCountType, countType):
      return {
        icon: findIcon('conveyor-belt-boxes', 'fas'),
        color: `var(--mipro-color-primary)`,
      };
    case includes(inventoryEvaluation, countType):
      return {
        icon: findIcon('warehouse-full', 'fas'),
        color: `var(--mipro-color-primary)`,
      };
    case includes(piConsignment, countType):
      return {
        icon: findIcon('warehouse-full', 'fas'),
        color: `var(--mipro-color-light-green)`,
      };
    case includes(cycleCount, countType):
      return {
        icon: findIcon('scanner-keyboard', 'fas'),
        color: `var(--mipro-color-primary)`,
      };
    case includes(cycleCountConsignment, countType):
      return {
        icon: findIcon('scanner-keyboard', 'fas'),
        color: `var(--mipro-color-light-green)`,
      };
    case includes(cycleCountEvaluation, countType):
      return {
        icon: findIcon('scanner-keyboard', 'fas'),
        color: `var(--mipro-color-light-yellow)`,
      };
    case includes(startVMICountType, countType):
    default:
      return {
        icon: findIcon('shelves', 'fas'),
        color: `var(--mipro-color-primary)`,
      };
  }
};
