import React from 'react';
import classNames from 'classnames';
import { isNil, map } from 'lodash';
import Text from 'components/Text/Text';
import classes from './RowTable.module.scss';

interface TableData {
  title: string;
  value?: string | false;
}

interface RowTableProps {
  sectionTitle?: string;
  tableData: TableData[];
  sectionTitleClass?: string;
  sectionWrapperClass: string;
  rowClassName?: string;
  testid: string;
  titleVariant?: 'mipro-h3-headline';
  valueVaraint?: 'mipro-product-headline';
}

const RowTable = ({
  sectionTitle = '',
  tableData,
  sectionTitleClass,
  sectionWrapperClass,
  rowClassName,
  children,
  testid,
  titleVariant,
  valueVaraint,
}: React.PropsWithChildren<RowTableProps>): JSX.Element => (
  <div data-testid={testid}>
    {sectionTitle && (
      <Text
        variant={isNil(titleVariant) ? 'title-info-card' : titleVariant}
        className={classNames(classes.sectionTitle, sectionTitleClass)}
        text={sectionTitle}
      />
    )}
    <div className={classNames(classes.data, sectionWrapperClass)}>
      {map(tableData, (item, index) => (
        <div
          key={`${item.title}${index}`}
          className={classNames(classes.label, rowClassName)}
          data-testid={`${testid}-item-${index}`}
        >
          <Text
            variant="label-header"
            className={classes.title}
            text={item.title}
          />
          <Text
            variant={isNil(valueVaraint) ? 'content-small' : valueVaraint}
            className={classes.value}
            text={item.value ? item.value : 'N/A'}
          />
        </div>
      ))}
      {children}
    </div>
  </div>
);

export default RowTable;
