import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { toNumber, trim } from 'lodash';
import { IonPage, IonContent } from '@ionic/react';
import CustomerName from 'common/components/Header/CustomerName';
import Header from 'common/components/Header/Header';
import { formatNumber } from 'common/utils/numberHelper';
import { namespaces } from 'i18n/i18n.constants';
import useGetAccountsReceivable from 'api/customer/useGetAccountsReceivable';
import type { SnapshotsURLParams } from 'models/Search';
import { getErrorMessage } from 'utils/helpers';
import type { AccountsReceivableChartData } from 'components/Charts/AccountsReceivable/AccountsReceivableChart';
import AccountsReceivableChart from 'components/Charts/AccountsReceivable/AccountsReceivableChart';
import Loader from 'components/Loader/Loader';
import RowTable from 'components/RowTable/RowTable';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './AccountsReceivable.module.scss';

const AccountsReceivable = (): JSX.Element => {
  const { miLoc, id } = useParams<SnapshotsURLParams>();
  const { t } = useTranslation(namespaces.ar);
  const { accountsReceivable, customerData, error, isLoading } =
    useGetAccountsReceivable({ miLoc, id });

  const agingInfoData: AccountsReceivableChartData[] = [
    {
      value: toNumber(accountsReceivable?.ACCOUNT_STATUS_CURRENT),
      id: 'current',
      label: trim(t('current', { currency: '' })),
      color: '#1A9850',
    },
    {
      value: toNumber(accountsReceivable?.ACCOUNT_STATUS_1_30),
      id: '1-30',
      label: '1-30',
      color: '#ffd21a',
    },
    {
      value: toNumber(accountsReceivable?.ACCOUNT_STATUS_31_60),
      id: '31-60',
      label: '31-60',
      color: '#f46d43',
    },
    {
      value: toNumber(accountsReceivable?.ACCOUNT_STATUS_61_90),
      id: '61-90',
      label: '61-90',
      color: '#f54f21',
    },
    {
      value: toNumber(accountsReceivable?.ACCOUNT_STATUS_91_120),
      id: '91-120',
      label: '91-120',
      color: '#d73027',
    },
    {
      value: toNumber(accountsReceivable?.ACCOUNT_STATUS_OVER_120),
      id: 'over-120',
      label: t('over120'),
      color: '#a50026',
    },
  ].reverse();

  const accountBalanceData = [
    {
      title: t('creditLimit'),
      value: formatNumber({
        number: toNumber(accountsReceivable?.ACCOUNT_STATUS_CR_LIMIT),
        currencyType: accountsReceivable?.currencyType,
      }),
    },
    {
      title: t('balance'),
      value: formatNumber({
        number: toNumber(accountsReceivable?.ACCOUNT_STATUS_ACCOUNT_BALANCE),
        currencyType: accountsReceivable?.currencyType,
      }),
    },
    {
      title: t('serviceCharge'),
      value: formatNumber({
        number: toNumber(accountsReceivable?.ACCOUNT_STATUS_SRVCE_CHRG_OWED),
        currencyType: accountsReceivable?.currencyType,
      }),
    },
    {
      title: t('totalDue'),
      value: formatNumber({
        number: toNumber(accountsReceivable?.ACCOUNT_STATUS_TOTAL_DUE),
        currencyType: accountsReceivable?.currencyType,
      }),
    },
    {
      title: t('unallocatedCash'),
      value: formatNumber({
        number: toNumber(accountsReceivable?.ACCOUNT_STATUS_UNALC_CASH),
        currencyType: accountsReceivable?.currencyType,
      }),
    },
    {
      title: trim(t('beyondTerms', { currency: '' })),
      value: formatNumber({
        number: toNumber(accountsReceivable?.ACCOUNT_STATUS_BEYOND_TERMS),
        currencyType: accountsReceivable?.currencyType,
      }),
    },
    {
      title: t('terms'),
      value: accountsReceivable?.ACCOUNT_STATUS_TERMS,
    },
  ];

  return (
    <IonPage>
      <Header
        customTitle={<CustomerName customerData={customerData} />}
        testid="header"
      />
      <IonContent className={classes.content}>
        <Loader
          className={classes.loader}
          text={t('loadingAR')}
          isOpen={isLoading}
          testid="loader"
        />
        {!isLoading && !error && (
          <>
            <div>
              <Text
                variant="title-info-card"
                className={classNames(
                  classes.sectionTitle,
                  classes.sectionWrapper
                )}
                text={t('agingInfo')}
              />
              <AccountsReceivableChart
                data={agingInfoData}
                testid="accounts-receivable-chart"
                currencyType={accountsReceivable?.currencyType}
              />
            </div>

            <RowTable
              sectionTitle={t('accountBalance')}
              tableData={accountBalanceData}
              sectionTitleClass={classes.sectionTitle}
              sectionWrapperClass={classes.sectionWrapper}
              testid="account-balance"
            />
          </>
        )}
        {error && (
          <WarningMessage
            className={classes.warningMessage}
            title={t('errorLoadingARBalance')}
            body={getErrorMessage(error)}
            testid="error"
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default AccountsReceivable;
