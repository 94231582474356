import React from 'react';
import type { TemplateGroup } from 'models/DocumentTemplate';
import Text from 'components/Text/Text';
import classes from './DocumentSubHeader.module.scss';

export interface DocumentSubHeaderProps {
  group: TemplateGroup;
}

const DocumentSubHeader = ({ group }: DocumentSubHeaderProps): JSX.Element => (
  <div className={classes.DocumentSubHeader} data-testid="DocumentSubHeader">
    <Text text={group.title || ''} variant="title-action-card" />
  </div>
);

export default DocumentSubHeader;
