import { useState } from 'react';
import type { ActionOperationStatus } from 'models/ActivityModels';

interface UseActionOperationsResponse<OperationType> {
  operations: OperationType[];
  operationStatus: ActionOperationStatus;
  addOperation: (o: OperationType) => void;
  completeOperations: () => void;
  cancelOperations: () => void;
  doOperations: () => void;
  undoOperations: () => void;
}

function useActionOperations<
  OperationType
>(): UseActionOperationsResponse<OperationType> {
  const [operationStatus, setOperationStatus] =
    useState<ActionOperationStatus>('none');
  const [operations, setOperations] = useState<OperationType[]>([]);

  const addOperation = (o: OperationType) => setOperations([...operations, o]);

  const completeOperations = () => {
    setOperations([]);
    setOperationStatus('completed');
  };

  const cancelOperations = () => {
    setOperations([]);
    setOperationStatus('none');
  };

  const doOperations = () => setOperationStatus('pending');

  const undoOperations = () => setOperationStatus('none');

  return {
    operations,
    operationStatus,
    addOperation,
    completeOperations,
    cancelOperations,
    doOperations,
    undoOperations,
  };
}

export default useActionOperations;
