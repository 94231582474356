import { useMemo } from 'react';
import type { AxiosError } from 'axios';
import { head, size } from 'lodash';
import { useInfiniteQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import type {
  QueryFlags,
  QueryFnProps,
  QueryParamsType,
} from 'common/api/utils/useGetQueryFlags';
import useGetQueryFlags from 'common/api/utils/useGetQueryFlags';
import type { OrderNote } from 'ProductSearchApp/models/Order';
import { useAxios } from 'providers/AxiosProvider';
import { doConcatDataPages, useKeyUserId, useMiLocOrTeamId } from 'api/helpers';
import { pageSize } from 'utils/constants';

export const findOrderNotesQueryKey = 'order-notes';

export interface UseFindOrderNotesProps {
  limit?: number;
  enabled?: boolean;
  miLoc: string;
  ocn: string;
  lineNo?: string;
}

interface UseFindOrderNotesResponse {
  rows: OrderNote[];
  totalRows?: number;
}

const useFindOrderNotes = ({
  limit = pageSize(),
  enabled = true,
  miLoc,
  ocn,
  lineNo,
}: UseFindOrderNotesProps): UseFindOrderNotesResponse & QueryFlags => {
  const { axios } = useAxios();
  const { getOrderNotesAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const { getURLParams } = useMiLocOrTeamId({});

  const params: QueryParamsType = {
    miLoc,
    ocn,
    orderLineNo: lineNo,
    limit,
  };

  const doFindOrderNotes = async ({ pageParam = 1, signal }: QueryFnProps) => {
    const { data } = await axios.get<UseFindOrderNotesResponse>(
      getOrderNotesAPI(
        miLoc,
        ocn,
        getURLParams({ ...params, page: pageParam })
      ),
      { signal }
    );
    return data;
  };

  const response = useInfiniteQuery<UseFindOrderNotesResponse, AxiosError>(
    createQueryKey(findOrderNotesQueryKey, params),
    doFindOrderNotes,
    {
      enabled,
      getNextPageParam: (lastPage, pages) =>
        size(lastPage.rows) < limit ? false : size(pages) + 1,
    }
  );

  const { data } = response;

  const rows = useMemo(
    () => doConcatDataPages<OrderNote, UseFindOrderNotesResponse>(data, 'rows'),
    [data]
  );

  const queryFlags = useGetQueryFlags({
    ...response,
    data: rows,
    enabled,
  });

  return {
    rows,
    totalRows: head(data?.pages)?.totalRows,
    ...queryFlags,
  };
};

export default useFindOrderNotes;
