/* istanbul ignore file */

import type { Address } from './Address';
import type { Contact } from './Contact';

export interface Supplier {
  // #region api fields
  supLocNo: string;
  supLocName?: string;
  bookmarked?: string;
  contact?: Contact;
  address?: Address;
  // not used
  acctNo?: string;
  canUsVen?: string;
  corpMiCost?: number;
  costSource?: string;
  currencyType?: string;
  langFlag?: string;
  mexUsVen?: string;
  mfrContactNotebook?: string;
  mfrCtlNo?: string;
  mfrHasNotebook?: string;
  mfrName?: string;
  mfrSupplierConnect?: string;
  poDollarMin?: number;
  purchaseAllowed?: string;
  reqCtlNo?: string;
  requestForQuote?: string;
  requestQuantity?: number;
  requestStatus?: string;
  reservedPoCtlNo?: string;
  supConCapable?: string;
  supLocStatus?: string;
  supplierContactNotebook?: string;
  supplierHasNotebook?: string;
  unitCost?: number;
  validToPurchase?: string;
  // #endregion
}

export type DCApprovedVendor = {
  descLong: string;
  miloc: string;
  name: string;
};

export interface InventoryAnalysis {
  RU_NM_GOAL_DIFF: string;
  DOLLAR_TURNS: string;
  NM_INV_QTY: string;
  EX_GOAL_VALUE: string;
  ITEM_COUNT: string;
  SHORT_NAME: string;
  COGS_12_MO: string;
  BK_INV_VALUE: string;
  EX_GOAL_DIFF: string;
  USE_12_MO_QTY: string;
  NM_GOAL_DIFF: string;
  P_RU_EX_INV_VALUE: string;
  NUMBER_STOCK_OUTS: string;
  INV_TYPE: string;
  EX_INV_QTY: string;
  NM_GOAL_VALUE: string;
  Z_CODE_AMOUNT: string;
  TURN_12_MO_AVG_INV: string;
  INVENTORY_VALUE: string;
  RU_EX_INV_PCT: string;
  USE_24_MO_AMT: string;
  RU_NM_ITEM_COUNT: string;
  RU_NM_INV_QTY: string;
  NM_PCT_MET: string;
  FU_EX_VALUE: string;
  BK_VOI_VALUE: string;
  P_RU_NM_INV_VALUE: string;
  BK_INV_NET: string;
  RU_EX_GOAL_VALUE: string;
  INTERNAL_TRANSFERS: string;
  TURNS: string;
  INV_12_MO_VALUE: string;
  ADJUSTED_BK: string;
  Z_CODE_ADMIN_FEE: string;
  CORP: string;
  RU_EX_ITEM_COUNT: string;
  RU_NM_GOAL_VALUE: string;
  NM_ITEM_COUNT: string;
  RU_EX_INV_QTY: string;
  EX_PCT_MET: string;
  EX_ITEM_COUNT: string;
  EX_MS: string;
  USE_24_MO_QTY: string;
  FILL_RATE: string;
  EX_INV_PCT: string;
  RU_NM_INV_PCT: string;
  DIVISION: string;
  RU_NM_PCT_MET: string;
  GROUP: string;
  MAX_MONTHS_HISTORY: string;
  FU_EX_QTY: string;
  RU_EX_GOAL_DIFF: string;
  RU_EX_PCT_MET: string;
  RU_EX_INV_VALUE: string;
  MI_LOC: string;
  OPEN_ACTIVITY_CNT: string;
  NM_ADMIN_FEE: string;
  SUMMARY_BILLING: string;
  EX_ADMIN_FEE: string;
  P_NM_INV_VALUE: string;
  INVENTORY_QTY: string;
  EX_INV_VALUE: string;
  P_EX_INV_VALUE: string;
  NM_INV_VALUE: string;
  RU_NM_INV_VALUE: string;
  USE_12_MO_AMT: string;
  ACCURACY_RATE: string;
  NM_INV_PCT: string;
  DOL_TURN_12_MO_AVG: string;
}

export type VendorIncreaseLetter = {
  // #region api fields
  shortDescription: string;
  creationTmstmp: string;
  effectiveDate: string;
  increasePercentDesc: string;
  // not used
  activeFlag?: string;
  countryCode?: string;
  letterHeader?: string;
  letterType?: string;
  manufacturerControlNumber?: string;
  notes?: string;
  priceUpdateFlag?: string;
  prodGroupNumber?: string;
  // #endregion
};

export enum SupplierNotebookTypeEnum {
  SL = 'SL',
  MF = 'MF',
  SC = 'SC',
}

export interface SupplierNotebook {
  lineText: string;
  itemLineNo: string;
  lastUpdTmstmp: string;
}
