import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { map, toString } from 'lodash';
import List from 'common/components/List/List';
import Notebook from 'common/components/Notebook/Notebook';
import { useNodeRef } from 'common/components/utils/renderHelpers';
import useFindOrderNotes from 'ProductSearchApp/api/orders/useFindOrderNotes';
import type { ProductDetailURLParams } from 'ProductSearchApp/models/Products';
import useFindAttachments from 'api/attachments/useFindAttachments';
import useRemoveAttachment from 'api/attachments/useRemoveAttachment';
import useUpdateAttachmentDetails from 'api/attachments/useUpdateAttachmentDetails';
import useUploadFileAttachment from 'api/attachments/useUploadFileAttachment';
import { AttachmentSize } from 'models/Attachment';
import { getErrorMessage } from 'utils/helpers';
import { goToNotebook } from 'navigation/navigationHelpers';
import FileAttachments from 'components/Attachments/FileAttachments';
import Button from 'components/Button/Button';
import Spinner from 'components/Spinner/Spinner';
import classes from './NotesTab.module.scss';

interface NotesTabProps {
  disabled?: boolean;
}

const NotesTab = ({ disabled }: NotesTabProps): JSX.Element => {
  const { t } = useTranslation();

  const { miLoc, id, lineNo, ocn, productId } =
    useParams<ProductDetailURLParams>();

  const { data: attachmentsData, isLoading: attachmentsLoading } =
    useFindAttachments({
      domain: 'order',
      miLoc,
      ctlNo: ocn,
      lineNo,
    });

  const { updateAttachmentDetails } = useUpdateAttachmentDetails({
    domain: 'order',
    miLoc,
    ctlNo: ocn,
    lineNo,
  });

  const { uploadAttachment } = useUploadFileAttachment({
    domain: 'order',
    miLoc,
    ctlNo: ocn,
  });

  const { removeAttachment } = useRemoveAttachment({
    domain: 'order',
    miLoc,
    ctlNo: ocn,
  });

  const {
    rows: orderNotes,
    isLoading: isLoadingNotes,
    isEmptyResponse,
    fetchNextPage,
    noMoreData,
    error,
  } = useFindOrderNotes({
    miLoc,
    ocn,
    lineNo,
  });

  const { node, nodeRef } = useNodeRef();

  return (
    <div className={classes.content}>
      <Spinner
        testid="ocn-line-notes-loader"
        text={t('common:updating')}
        showSpinner={disabled}
      />
      <div ref={nodeRef} className={classes.wrapper}>
        <div className={classes.cardWrapper}>
          <FileAttachments
            domain="order"
            name="order"
            files={attachmentsData}
            size={AttachmentSize.LARGE}
            enableSmallPreview
            editMode
            disabled={attachmentsLoading || disabled}
            testid="order--lines--attachments"
            onAdd={(addFiles) => {
              map(addFiles, (file) => {
                uploadAttachment({
                  file,
                  lineNo,
                  src: file.fileURL,
                  miOnly: file.miOnly,
                  description: file.description,
                });
              });
            }}
            onUpdate={updateAttachmentDetails}
            onRemove={(file) => {
              removeAttachment(file);
            }}
            label={t('email:attachments')}
          />
        </div>
        <div className={classes.cardWrapper}>
          <Button
            testid="add--notes"
            leftIcon={['far', 'note-sticky']}
            variant="link"
            text={t('notes:addNote')}
            disabled={disabled}
            className={classes.addNoteBtn}
            href={goToNotebook({
              miLoc,
              shipToCustNo: id,
              orderCtlNo: ocn,
              lineNo,
              productId,
              headerNotes: false,
            })}
          />
          <List
            isLoading={{
              isLoading: isLoadingNotes,
              text: t('notes:loadingActivityNotes'),
              testid: 'order--notes--list--loading',
            }}
            testid="order--notes--list"
            endReached={fetchNextPage}
            isEndOfList={noMoreData}
            isEmptyList={{
              isEmptyList: isEmptyResponse,
              title: t('notes:noActivityNotes'),
              className: classes.emptyMessage,
              testid: 'order--notes--empty--list',
            }}
            isError={{
              isError: !!error,
              title: getErrorMessage(error, t('notes:noActivityNotes')),
              testid: 'order--notes--list--error',
            }}
            data={orderNotes}
            itemContent={(itemIndex, item) => (
              <Notebook
                title={toString(item?.notebookFirstLine)}
                userName={toString(item?.creationName)}
                lastUpdatedTimestamp={item.lastUpdTmstmp}
                testid={`order--notes--${itemIndex}`}
                key={`order--notes--${itemIndex}`}
                userId={item.creationUserid}
                disabled={disabled}
                href={goToNotebook({
                  miLoc,
                  shipToCustNo: id,
                  orderCtlNo: ocn,
                  lineNo,
                  productId,
                  micro: item.micro,
                  date: item.date,
                  time: item.time,
                  headerNotes: false,
                  mode: 'edit',
                })}
              />
            )}
            scrollParent={node}
          />
        </div>
      </div>
    </div>
  );
};

export default NotesTab;
