import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import classnames from 'classnames';
import { filter, kebabCase, map, size, toString } from 'lodash';
import { IonPage, IonContent, IonRow } from '@ionic/react';
import { workOrderDetailsURL } from 'navigation';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import { useDebounce } from 'use-debounce';
import useFindWorkOrders from 'WorkOrders/api/useFindWorkOrders';
import type { WorkOrderItem } from 'WorkOrders/models/WorkOrderItem';
import { SearchSuggestionTypeEnum } from 'models/Search';
import type { RootState } from 'store/reducers';
import { clearRecentlyViewed } from 'store/user';
import { selectIsBranchLocation } from 'store/user/selectors';
import { getErrorMessage } from 'utils/helpers';
import { concatRoutes } from 'utils/navigations';
import Button from 'components/Button/Button';
import Header from 'components/Header/Header';
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll';
import Loader from 'components/Loader/Loader';
import ConfirmDialog from 'components/Modals/ConfirmDialog/ConfirmDialog';
import OfflineInfo from 'components/OfflineInfo/OfflineInfo';
import Refresher from 'components/Refresher/Refresher';
import RecentlyViewedCard from 'components/Search/RecentlyViewedCard/RecentlyViewedCard';
import SearchSuggestionCard from 'components/Search/SearchSuggestionCard/SearchSuggestionCard';
import Searchbar from 'components/Searchbar/Searchbar';
import Text from 'components/Text/Text';
import TitleLine from 'components/TitleLine/TitleLine';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './WorkOrdersList.module.scss';

const WorkOrdersList = (): JSX.Element => {
  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  const { t } = useTranslation();
  const [clearIsOpen, setClearIsOpen] = useState(false);
  const { isOnline } = useNetworkStatus();

  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryValue] = useDebounce(searchQuery, 300);

  const { miLoc, recentlyViewed } = useSelector(
    (state: RootState) => state.user
  );
  const isBranch = useSelector(selectIsBranchLocation);

  const {
    workOrders,
    error,
    refetch,
    fetchNextPage,
    hasError,
    showLoader,
    isEmptyResponse,
    enableInfiniteScroll,
    lastUpdatedAt,
  } = useFindWorkOrders({
    workOrderCtlNo: searchQueryValue,
    miLoc: toString(miLoc),
    enabled: isOnline && isBranch,
  });

  const filteredRecentlyViewed = !showLoader
    ? filter(recentlyViewed, ({ type }) => {
        return type === 'workOrders';
      })
    : [];

  const withRecentlyViewed = size(filteredRecentlyViewed) > 0;

  return (
    <IonPage className={classnames(classes.page, classes.workOrderList)}>
      <Header
        title={t('search:search')}
        testid="work-orders-header"
        showLocationText
      >
        {isBranch && (
          <IonRow className={classes.workOrdersSearchRow}>
            <Searchbar
              className={classes.searchbar}
              placeholder={t('workOrders:searchWorkOrders')}
              value={searchQuery}
              setValue={setSearchQuery}
              testid="search-input"
            />
          </IonRow>
        )}
        {!isOnline && <OfflineInfo message={t('workOrders:offlineMsg')} />}
      </Header>
      <IonContent className={classes.content}>
        {!isBranch && (
          <WarningMessage
            className={classes.warningMessage}
            icon={['far', 'info-circle']}
            title={t('workOrders:wrongAccessLvl')}
          />
        )}
        {isBranch && (
          <>
            {withRecentlyViewed && !searchQueryValue && (
              <>
                <IonRow
                  className={classes.titleRow}
                  data-testid="recently-viewed"
                >
                  <Text
                    className={classes.title}
                    variant="underlined-title-section"
                    text={t('search:recentlyViewed')}
                  />
                  <Button
                    className={classes.clearButton}
                    variant="link"
                    text={t('search:clearRecent')}
                    onClick={() => setClearIsOpen(true)}
                    testid="clear-recently-viewed-button"
                  />
                  <ConfirmDialog
                    isOpen={clearIsOpen}
                    setIsOpen={setClearIsOpen}
                    title={t('search:clearRecentTitle')}
                    text={t('search:clearRecentMessage')}
                    primaryText={t('search:clearRecentNo')}
                    secondaryText={t('search:clearRecentYes')}
                    onSecondaryClick={() => {
                      queueMicrotask(() => {
                        dispatch(clearRecentlyViewed());
                      });
                    }}
                    testid="clear-recently-viewed-modal"
                  />
                </IonRow>
                <TitleLine />
                <div
                  className={classes.recentlyViewedWrapper}
                  data-testid="recently-viewed-wrapper"
                >
                  {map(filteredRecentlyViewed, (item) => {
                    const { type, id, text } = item;

                    return (
                      <RecentlyViewedCard
                        searchType={type}
                        className={classes.recentlyViewedCard}
                        key={id}
                        text={text}
                        description={text}
                        testid={`recently-viewed-${id}`}
                      />
                    );
                  })}
                </div>
              </>
            )}
            {!showLoader && (
              <Refresher
                slot="fixed"
                className={classes.refresher}
                lastUpdatedAt={lastUpdatedAt}
                hidden
                onRefresh={refetch}
                testid="documents-list-refresher"
              />
            )}
            <Text
              className={classnames(classes.title, classes.subHeading)}
              variant="title-info-card"
              text={t('common:workOrders')}
              testid="search-section-title"
            />
            {!hasError &&
              map(workOrders, (workOrderItem: WorkOrderItem) => {
                const {
                  customerName,
                  mfrPartNo,
                  workOrderCtlNo,
                  workOrderDescr,
                } = workOrderItem;
                const workOrderDesc = workOrderDescr
                  ? `${workOrderCtlNo} - ${workOrderDescr}`
                  : workOrderCtlNo;

                return (
                  <SearchSuggestionCard
                    className={classes.suggestionCard}
                    key={workOrderCtlNo}
                    searchSuggestionType={SearchSuggestionTypeEnum.result}
                    text={workOrderDesc}
                    textQuery={searchQuery}
                    description={
                      customerName
                        ? `${t('common:customer')} ${customerName}`
                        : ''
                    }
                    address={mfrPartNo}
                    testid={`work-order-${kebabCase(workOrderCtlNo)}`}
                    searchType="workOrders"
                    href={concatRoutes(
                      url,
                      workOrderDetailsURL(miLoc, workOrderCtlNo)
                    )}
                  />
                );
              })}
            {isEmptyResponse && (
              <WarningMessage
                className={classes.warningMessage}
                icon={['far', 'info-circle']}
                title={t('workOrders:notAvailable')}
              />
            )}
            {hasError && (
              <WarningMessage
                className={classes.warningMessage}
                title={t('workOrders:errorLoading')}
                body={getErrorMessage(error)}
              />
            )}
            <Loader
              className={classes.loaderWrap}
              text={t('workOrders:loading')}
              isOpen={showLoader}
            />
            <InfiniteScroll
              disabled={!enableInfiniteScroll}
              onLoadMore={fetchNextPage}
              testid="infinite-scroll"
            />
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default WorkOrdersList;
