import React from 'react';
import { IonRow, IonToolbar } from '@ionic/react';
import {
  NetworkStatusEnum,
  useNetworkStatus,
} from 'providers/NetworkStatusProvider';
import Text from 'components/Text/Text';
import classes from './OfflineInfo.module.scss';

interface OfflineInfoProps {
  message?: string;
}

const OfflineInfo = ({ message }: OfflineInfoProps): JSX.Element => {
  const { networkStatus } = useNetworkStatus();

  return (
    <IonToolbar className={classes.offlineBanner}>
      <IonRow className={classes.wrapper}>
        <Text
          variant="label-micro"
          text={
            message ||
            `${
              networkStatus === NetworkStatusEnum.ServerUnreachable
                ? 'Unable to reach Mi Pro server'
                : 'You are in offline mode'
            }, all the information displayed is from the local database`
          }
          testid="offline-mode-text"
        />
      </IonRow>
    </IonToolbar>
  );
};

export default OfflineInfo;
