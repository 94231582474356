import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import type { MutationStatus } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import useAPIUrl from 'api';
import type { Order } from 'ProductSearchApp/models/Order';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import { ToastType } from 'models/Toast';
import type { RootState } from 'store/reducers';
import useUpdateToCart from './useUpdateToCart';

interface UpdateCancelWoBody {
  orderLineNo: string;
  cancelCode: string;
  notebookText?: string;
  shopMiLoc: string;
  workOrderCtlNo: string;
}

interface UseUpdateCancelWoReponse {
  status: MutationStatus;
  data?: Order;
  onUpdateCancelWo: (body: UpdateCancelWoBody) => void;
}

const useUpdateCancelWo = (): UseUpdateCancelWoReponse => {
  const { axios } = useAxios();
  const { cancelWorkOrderAPI } = useAPIUrl();
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const { currentCartCustomer } = useSelector((state: RootState) => state.user);
  const { onOptismitcUpdateItem, onRollbackItem, onOrderSuccessUpdate } =
    useUpdateToCart({});

  const doUpdateCancelWo = async (body: UpdateCancelWoBody) => {
    const { ...restBody } = body;
    const updatePayload = {
      miLoc: currentCartCustomer?.miLoc,
      customerNo: currentCartCustomer?.shipToCustNo,
      orderCtlNo: currentCartCustomer?.orderCtlNo,
    };
    const updateBody = { ...restBody, ...updatePayload };
    const { data } = await axios.post<Order>(cancelWorkOrderAPI(), updateBody);

    return data;
  };

  const response = useMutation(doUpdateCancelWo, {
    onMutate: async (vars) => {
      await onOptismitcUpdateItem?.(vars.orderLineNo);
    },
    onSuccess: (data) => {
      onOrderSuccessUpdate?.(data);
    },
    onError: async (error, vars) => {
      addToast({
        type: ToastType.error,
        text: t('productSearch:ocn:cancelWoToast'),
        testid: 'cancel-wo-error-toast',
      });
      await onRollbackItem?.(vars.orderLineNo);
    },
  });

  const { status, mutate, data } = response;

  return {
    status,
    data,
    onUpdateCancelWo: (body: UpdateCancelWoBody) => mutate(body),
  };
};

export default useUpdateCancelWo;
