import SentryCategories from 'constants/sentryCategories';
import axios from 'axios';
import type {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
  AxiosInstance,
  RawAxiosRequestConfig,
  AxiosRequestHeaders,
} from 'axios';
import { includes, toString } from 'lodash';
import * as Sentry from '@sentry/capacitor';
import { isAPIUrl, isBulletinsAPIUrl } from 'api';
import i18next from 'i18next';
import {
  CLOUDFLARE_CLIENT_ID,
  CLOUDFLARE_CLIENT_SECRET,
  isCloudflareConfigured,
} from 'utils/cloudflare';
import { addAxiosContextToSentry } from 'utils/sentry';

interface AuthTokenInterceptorProps {
  config: AxiosRequestConfig;
  getAuthToken: () => Promise<string | null | undefined>;
  ignoreAuthHeaderAPIs: string[];
}

export const addAuthTokenInterceptor = async ({
  config,
  getAuthToken,
  ignoreAuthHeaderAPIs,
}: AuthTokenInterceptorProps) => {
  const { headers = {}, url } = config;

  if (isAPIUrl(url)) {
    if (!includes(ignoreAuthHeaderAPIs, url)) {
      const token = await getAuthToken();
      // set accept-language header for mipro and model api requests
      headers['Accept-Language'] = i18next.language;
      // add motion token to authorization header if available
      if (token) {
        headers.Authorization = `Bearer: ${token}`;
      }
    }
    // add cloudflare access headers for mipro api requests
    if (isCloudflareConfigured && isAPIUrl(url)) {
      headers['CF-Access-Client-Id'] = CLOUDFLARE_CLIENT_ID;
      headers['CF-Access-Client-Secret'] = CLOUDFLARE_CLIENT_SECRET;
    }
  }
  if (isBulletinsAPIUrl(url)) {
    // add JWT to authorization header if available
    if (process.env.REACT_APP_BULLETINS_SECRET) {
      headers.Authorization = `Bearer ${process.env.REACT_APP_BULLETINS_SECRET}`;
    }
  }

  return { ...config, headers: headers as AxiosRequestHeaders };
};

interface RefreshAuthTokenOnSuccessInterceptorProps {
  response: AxiosResponse;
  setAuthToken: (value: string) => Promise<void>;
  ignoreAuthHeaderAPIs: string[];
}

export const refreshAuthTokenOnSuccessInterceptor = async ({
  response,
  setAuthToken,
  ignoreAuthHeaderAPIs,
}: RefreshAuthTokenOnSuccessInterceptorProps): Promise<AxiosResponse> => {
  const token = toString(response.headers['motion-token']);
  const { url = '' } = response.config || {};
  if (token && !includes(ignoreAuthHeaderAPIs, url)) {
    await setAuthToken(token);
  }
  return response;
};

interface RefreshAuthTokenOnErrorInterceptorProps {
  error: AxiosError;
  customAxios: AxiosInstance;
  setAuthToken: (value: string) => Promise<void>;
  onInvalidToken?: () => void;
}

export const refreshAuthTokenOnErrorInterceptor = async ({
  error,
  customAxios,
  onInvalidToken,
  setAuthToken,
}: RefreshAuthTokenOnErrorInterceptorProps): Promise<AxiosResponse> => {
  const token = toString(error.response?.headers?.['motion-token']);
  if (token) {
    await setAuthToken(token);
    const { headers = {} } = error.config as RawAxiosRequestConfig;
    headers.Authorization = `Bearer: ${token}`;
    return customAxios.request({ ...error.config, headers });
  }
  const { url = '' } = error.config || {};
  if (error.response?.status === 401 && url !== '/api/auth/login') {
    onInvalidToken?.();
  }
  return Promise.reject(error);
};

export const sentryOnSuccessInterceptor = async (
  response: AxiosResponse
): Promise<AxiosResponse> => {
  if (
    response.status === 200 &&
    response.headers['x-backside-transport'] === 'FAIL FAIL'
  ) {
    Sentry.withScope((scope) => {
      addAxiosContextToSentry(scope, response.config, response);
      Sentry.captureMessage(
        `API Error Response - Inconsistent Status Code`,
        'error'
      );
      throw new Error('API Error Response - Inconsistent Status Code', {
        cause: response.data as Error,
      });
    });
  }
  return Promise.resolve(response);
};

export const sentryOnErrorInterceptor = async (
  error: AxiosError
): Promise<AxiosResponse> => {
  Sentry.withScope((scope) => {
    const { config: request = {}, response } = error;
    addAxiosContextToSentry(scope, request, response);

    if (error instanceof axios.Cancel) {
      Sentry.addBreadcrumb({
        category: SentryCategories.AXIOS,
        message: error.message,
        level: 'info',
      });
    } else if (axios.isAxiosError(error)) {
      Sentry.captureMessage(
        `API Error Response - ${String(response?.status)} (${String(
          response?.statusText
        )})`,
        'warning'
      );
    } else {
      // fallback for things other than Axios errors / cancellations
      Sentry.captureException(error);
    }
  });
  return Promise.reject(error);
};

axios.interceptors.response.use(
  sentryOnSuccessInterceptor,
  sentryOnErrorInterceptor
);
