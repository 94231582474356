import React, { useEffect, useMemo, useState } from 'react';
import { filter, map, size } from 'lodash';
import type {
  BaseComponentProps,
  OptionalComponentProps,
} from 'common/components/utils/renderHelpers';
import useFindGroups4Digits from 'ProductSearchApp/api/fastfind/useFindGroups4Digits';
import { useDebounce } from 'use-debounce';
import type { SelectModalItem } from 'models/Search';
import SelectModal from 'components/SelectModal/SelectModal';

interface Group4DigitsSelectProps extends BaseComponentProps {
  prodGroup?: string;
  setGroup4Digits: (o: SelectModalItem) => void;
}

const Group4DigitsSelect = ({
  prodGroup,
  setGroup4Digits,
  selItem,
  testid = 'group-4digits-select',
  ...props
}: Group4DigitsSelectProps &
  OptionalComponentProps<
    React.ComponentProps<typeof SelectModal>
  >): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryValue] = useDebounce(searchQuery, 300);

  const { groups, error, isLoading } = useFindGroups4Digits({
    prodGroup,
  });

  const inputDisplay = selItem?.key ? `(${selItem.key}) ${selItem.title}` : '';

  useEffect(() => {
    if (!isOpen) {
      setSearchQuery('');
    }
  }, [isOpen, searchQuery, setSearchQuery]);

  const items = useMemo(() => {
    return map(
      filter(
        groups,
        (item) =>
          new RegExp(searchQueryValue, 'i').test(item.text) ||
          new RegExp(searchQueryValue, 'i').test(item.code)
      ),
      (item) => ({
        key: item.code,
        title: item.text,
        description: item.code,
      })
    );
  }, [groups, searchQueryValue]);

  return (
    <SelectModal
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      selItem={selItem || { title: '', description: '', key: '' }}
      setSelItem={(o) => setGroup4Digits(o)}
      items={items}
      error={error}
      isEmptyResponse={size(items) === 0}
      showLoader={!!isLoading}
      enableInfiniteScroll
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      onClick={() => setIsOpen(true)}
      value={inputDisplay}
      icon="caret-down"
      testid={testid}
    />
  );
};

export default Group4DigitsSelect;
