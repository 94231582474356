import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { includes } from 'lodash';
import type { CrmForm } from 'ActivitiesApp/models/CrmActivity';
import { FormikInput } from 'common/components/Forms/Input/Input';
import type { OptionItem } from 'common/components/ListModal/ListModal';
import ListModal from 'common/components/ListModal/ListModal';
import { useScrollIntoFieldError } from 'common/utils/formHelpers';
import { choose } from 'common/utils/logicHelpers';
import { Form, useFormikContext } from 'formik';
import { namespaces } from 'i18n/i18n.constants';
import classes from './CrmOpportunityForm.module.scss';

interface CrmOpportunityFormProps {
  reasons: OptionItem[];
  isSameUser: boolean;
  isSubmitting?: boolean;
}

const CrmOpportunityForm = ({
  reasons,
  isSameUser,
  isSubmitting,
}: CrmOpportunityFormProps) => {
  const testIdPrefix = 'crm-opportunity-form';
  const [isReasonModalOpen, setIsReasonModalOpen] = useState(false);
  const { t } = useTranslation(namespaces.activities);

  const { values, setValues } = useFormikContext<CrmForm>();
  const { action, currency, reason } = values;

  const showReasonField = includes(['WON', 'LOST'], action);
  const isWon = action === 'WON';

  const openReasonModal = () => {
    setIsReasonModalOpen(true);
  };

  useScrollIntoFieldError(undefined, isSubmitting);

  return (
    <>
      <Form>
        {showReasonField && (
          <FormikInput
            label={choose(isWon, t('wonReason'), t('lostReason'))}
            name="reasonDesc"
            testid={`${testIdPrefix}-reason-value`}
            onFocus={openReasonModal}
            rightButton={{
              icon: ['fas', 'caret-down'],
              onClick: openReasonModal,
            }}
            className={classes.field}
            required={showReasonField}
          />
        )}
        <FormikInput
          label={t('activities:actualValue')}
          name="actualValue"
          testid={`${testIdPrefix}-actual-value`}
          className={classes.field}
          currencyMask
          currencyType={currency}
          readonly={!isSameUser}
        />
      </Form>
      <ListModal
        testid={`${testIdPrefix}-list-modal`}
        title={choose(isWon, t('wonReason'), t('lostReason'))}
        isOpen={isReasonModalOpen}
        onClick={(value, label) => {
          setTimeout(() => {
            setValues({
              ...values,
              reason: value,
              reasonDesc: label,
            });
          }, 100);
        }}
        setIsOpen={setIsReasonModalOpen}
        options={reasons}
        selectedOption={reason}
      />
    </>
  );
};

export default CrmOpportunityForm;
