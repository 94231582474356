import React from 'react';
import classNames from 'classnames';
import { includes, isNil, map, toString } from 'lodash';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import Alert, { AlertVariantEnum } from 'common/components/Alert/Alert';
import { ecosClassOrange, ecosClassRed } from 'ProductSearchApp/models/Order';
import Text from 'components/Text/Text';
import classes from './ErrorList.module.scss';

interface ErrorListProps {
  errorList?: string[];
  title?: string;
}

const ErrorList = ({
  errorList,
  className,
  title,
}: ErrorListProps & IonicReactProps) => {
  return (
    <>
      {!isNil(title) && <Text variant="mipro-h3-headline" text={title} />}
      {map(errorList, (errorItem, errorIndex) => {
        const danger = includes(errorItem, ecosClassRed);
        const warning = includes(errorItem, ecosClassOrange);
        let variant = AlertVariantEnum.warning;
        if (danger) {
          variant = AlertVariantEnum.danger;
        }
        if (warning) {
          variant = AlertVariantEnum.warning;
        }
        return (
          <Alert
            variant={variant}
            key={errorIndex}
            className={classNames(className, classes.row, {
              [classes.firstErrRow]: errorIndex === 0,
            })}
            testid="error-list-row"
          >
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: toString(errorItem),
              }}
              className={classes.label}
            />
          </Alert>
        );
      })}
    </>
  );
};

export default ErrorList;
