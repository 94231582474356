import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { map, toString } from 'lodash';
import { IonPage, IonContent, IonRow } from '@ionic/react';
import { bulletinDetailURL } from 'navigation';
import useFindBulletins from 'api/bulletins/useFindBulletins';
import { sanitizeExcerpt, sanitizeTitle } from 'utils/announcements';
import { getErrorMessage } from 'utils/helpers';
import { concatRoutes } from 'utils/navigations';
import ActionRow from 'components/ActionRow/ActionRow';
import DateFormat from 'components/DateFormat/DateFormat';
import Header from 'components/Header/Header';
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll';
import Loader from 'components/Loader/Loader';
import Refresher from 'components/Refresher/Refresher';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './Bulletins.module.scss';

const BulletinList = (): JSX.Element => {
  const { url } = useRouteMatch();
  const {
    data: bulletins,
    error,
    fetchNextPage,
    hasError,
    showLoader,
    isEmptyResponse,
    enableInfiniteScroll,
    refetch: bulletinsRefetch,
  } = useFindBulletins();

  const { t } = useTranslation();

  return (
    <IonPage className={classes.bulletinList} data-testid="bulletin-list">
      <Header title={t('common:bulletins')} withBackButton testid="bulletins" />
      <IonContent>
        <Refresher
          slot="fixed"
          hidden
          onRefresh={bulletinsRefetch}
          testid="bulletins-refresher"
          disabled={showLoader}
        />
        {map(
          bulletins,
          (
            {
              id,
              title: { rendered: title },
              excerpt: { rendered: excerpt },
              date,
            },
            index
          ) => (
            <ActionRow
              className={classes.item}
              key={`bulletins-${index}`}
              href={concatRoutes(url, bulletinDetailURL(toString(id)))}
              testid={`bulletins-${index}`}
            >
              <IonRow className={classes.wrapper}>
                <Text
                  variant="title-info-card"
                  text={sanitizeTitle(title)}
                  testid="card-title"
                  className={classes.title}
                />
                <Text text={sanitizeExcerpt(excerpt)} testid="card-excerpt" />
                <DateFormat
                  variant="content-small"
                  date={new Date(date)}
                  testid="card-date"
                />
              </IonRow>
            </ActionRow>
          )
        )}
        {isEmptyResponse && (
          <WarningMessage
            className={classes.warningMessage}
            icon={['far', 'info-circle']}
            title={t('announcements:noAnnouncements')}
          />
        )}
        {hasError && (
          <WarningMessage
            className={classes.warningMessage}
            title={t('announcements:errorLoadingAnnouncements')}
            body={getErrorMessage(error)}
          />
        )}
        <Loader
          className={classes.loader}
          text={t('announcements:loadingAnnouncements')}
          isOpen={showLoader}
        />
        <InfiniteScroll
          disabled={!enableInfiniteScroll}
          onLoadMore={fetchNextPage}
          testid="infinite-scroll"
        />
      </IonContent>
    </IonPage>
  );
};

export default BulletinList;
