import React from 'react';
import type { TFunction } from 'react-i18next';
import {
  filter,
  head,
  size,
  startsWith,
  toLower,
  toNumber,
  toString,
} from 'lodash';
import type { DataCode } from 'models/DataCode';
import type {
  SalesPlaysTypesLegend,
  SalesPlayFormat,
  SalesPlayTypesKeys,
  SalesPlaysJsonSummary,
  SalesPlayDescription,
  SalesPlay,
} from 'models/SalesPlays';
import { SalesPlayTypesEnum } from 'models/SalesPlays';
import CurrencyFormat from 'components/CurrencyFormat/CurrencyFormat';
import DateFormat from 'components/DateFormat/DateFormat';
import type { FilterOption } from 'components/Filter/Filter';
import NumberFormat from 'components/NumberFormat/NumberFormat';
import Text from 'components/Text/Text';
import { parseDate } from './date';
import type { FormatNumberScale } from './number';

export const iconAndColorFromType = (
  type: keyof typeof SalesPlayTypesEnum | undefined
): SalesPlaysTypesLegend => {
  switch (type) {
    case SalesPlayTypesEnum.Overdue:
      return {
        icon: 'alarm-exclamation',
        color: '#E5BA2F',
        text: 'overdueMessage',
      };
    case SalesPlayTypesEnum.UpSell:
      return {
        icon: 'lightbulb-dollar',
        color: '#009633',
        text: 'upsellMessage',
      };
    case SalesPlayTypesEnum.Declining:
      return {
        icon: 'chart-line-down',
        color: '#F80E0E',
        text: 'decliningMessage',
      };
    case SalesPlayTypesEnum.CrossSell:
      return {
        icon: 'random',
        color: '#7308AF',
        text: 'crossSellMessage',
      };
    default:
      return {
        icon: 'ban',
      };
  }
};

export const convertSalesTypeForDisplay = (
  type: keyof typeof SalesPlayTypesEnum | string | undefined
): string => {
  switch (type) {
    case SalesPlayTypesEnum.CrossSell:
      return 'Cross-Sell';
    default:
      return toString(type);
  }
};

export const translateFilter =
  (t: TFunction) =>
  (filterItem: FilterOption): FilterOption => {
    const tFilter = filterItem;
    tFilter.name = t(filterItem.name);
    return tFilter;
  };

export const getFormattedPercentageScale = (
  value: string
): FormatNumberScale => {
  // Remove leading - if the number is negative
  const abs = Math.abs(toNumber(value));
  // Subtract 4 to account for leading 0, decimal point,
  // and multiplication by 100 to get percentage
  let scale: number;
  // wrap in try catch block in case SparxIQ ever sends a percentage without a leading 0
  try {
    scale = toString(abs).length > 4 ? toString(abs).length - 4 : 2;
  } catch {
    scale = 2;
  }
  return scale as FormatNumberScale;
};

export const getFormattedValue = (
  format: keyof typeof SalesPlayFormat,
  value: string,
  className: string,
  currencyType?: string
): JSX.Element => {
  switch (format) {
    case 'Date':
      return (
        <DateFormat
          date={parseDate(value)}
          variant="content-default"
          testid="sales-plays-data-date-value"
        />
      );
    case 'Decimal':
      return (
        <NumberFormat
          value={toNumber(value)}
          variant="content-default"
          testid="sales-plays-data-number-value"
        />
      );
    case 'Currency':
      return (
        <CurrencyFormat
          value={toNumber(value)}
          variant="content-default"
          testid="sales-plays-data-currency-value"
          className={className}
          currencyType={currencyType}
        />
      );
    case 'Percent':
      return (
        <>
          <NumberFormat
            value={toNumber(value) * 100}
            scale={getFormattedPercentageScale(value)}
            suffix="%"
            variant="content-default"
            testid="sales-plays-data-percent-value"
            className={className}
          />
        </>
      );
    case 'Sku':
    case 'Boolean':
    case 'Text':
    default:
      return (
        <Text
          text={value}
          variant="content-default"
          testid="sales-plays-data-text-value"
        />
      );
  }
};

export const findValueByKey = (
  jsonSummary: SalesPlaysJsonSummary[] | undefined,
  key: string
): string =>
  toString(jsonSummary?.find((s) => startsWith(s.key, key, 0))?.value);

export const findManufacturerNameByLabel = (
  jsonSummary: SalesPlaysJsonSummary[] | undefined,
  label: string
): string =>
  toString(jsonSummary?.find((s) => s.label === label)?.manufacturerName);

export const findDescriptionByLabel = (
  jsonSummary: SalesPlaysJsonSummary[] | undefined,
  label: string
): string => toString(jsonSummary?.find((s) => s.label === label)?.description);

export const findMfgPartNoByLabel = (
  jsonSummary: SalesPlaysJsonSummary[] | undefined,
  label: string
): string => toString(jsonSummary?.find((s) => s.label === label)?.mfgPartNo);

export const getSalesPlayRejectionReasons = (
  codes: DataCode[],
  type: SalesPlayTypesKeys
): DataCode[] => {
  if (codes && type) {
    return filter(
      codes,
      ({ applicableOppTypes = [] }) =>
        size(applicableOppTypes) > 0 &&
        toLower(head(applicableOppTypes)?.stage) === 'rejected' &&
        head(applicableOppTypes)?.oppTypes.includes(type)
    ) as DataCode[];
  }
  return [];
};

const descriptionLabelByType = {
  CrossSell: 'salesPlays:crossSellDescription',
  UpSell: 'salesPlays:upSellDescription',
  Declining: 'salesPlays:decliningDescription',
  Overdue: 'salesPlays:overdueDescription',
};

export const getDescription = (
  t: TFunction,
  salesPlay?: SalesPlayDescription | SalesPlay
) => {
  const {
    type = 'Blank',
    materialType,
    manufacturerName,
    productGroupDescription,
    materialNo,
    mfgPartNo,
    jsonSummary,
  } = salesPlay || {};
  if (type !== 'Blank') {
    return t(descriptionLabelByType[type], {
      crossSellManufacturerName:
        materialType === 'Sku'
          ? findManufacturerNameByLabel(jsonSummary, 'Based on Item')
          : findDescriptionByLabel(jsonSummary, 'Based on Category'),
      crossSellPgcCode:
        materialType === 'PGC4'
          ? ` (${findValueByKey(jsonSummary, 'CrossSell_RefPGC')})`
          : '',
      crossSellMfgPartNo: findMfgPartNoByLabel(jsonSummary, 'Based on Item'),
      manufacturerName: manufacturerName || productGroupDescription,
      pgcCode: materialType === 'PGC4' ? ` (${materialNo || ''})` : '',
      mfgPartNo,
      orderFrequency: findValueByKey(jsonSummary, 'Overdue_OrderFreq'),
      overdueDays: findValueByKey(jsonSummary, 'Overdue_DaysOverdue'),
    });
  }
  return '';
};
