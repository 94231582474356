import { toNumber } from 'lodash';
import type { MutationStatus } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToasts } from 'providers/ToastProvider';
import useReplenishmentDB from 'StoreroomsApp/database/useReplenishmentDB';
import type { ItemPOU } from 'StoreroomsApp/models/ItemPOU';
import type { ItemReplenishmentDTO } from 'StoreroomsApp/models/Replenishment';
import type { UpdateMutationContext } from 'api/helpers';
import {
  onSuccessMutation,
  doPromiseAPI,
  onErrorUpdate,
  onMutateUpdate,
} from 'api/helpers';
import { ToastType } from 'models/Toast';
import { findItemsPOUQueryKey } from './useFindItemsPOU';
import { findReplenishmentItemsQueryKey } from './useFindReplenishmentItems';

interface RemoveItemReplenishmentBody {
  item: ItemReplenishmentDTO;
}

interface UseRemoveItemReplenishmentResponse {
  status: MutationStatus;
  onRemoveItemReplenishment: (body: RemoveItemReplenishmentBody) => void;
}

const useRemoveItemReplenishment = (): UseRemoveItemReplenishmentResponse => {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const { removeItemReplenishment } = useReplenishmentDB();

  const doRemoveItemReplenishmentQuantity = ({
    item,
  }: RemoveItemReplenishmentBody) => {
    return doPromiseAPI(async () => {
      await removeItemReplenishment(toNumber(item.id));
    });
  };

  interface RemoveItemReplenishmentMutationContext {
    replenishmentContext: UpdateMutationContext<ItemReplenishmentDTO>[];
    itemPOUContext: UpdateMutationContext<ItemPOU>[];
  }

  const { status, mutate } = useMutation(doRemoveItemReplenishmentQuantity, {
    networkMode: 'always',
    onMutate: async (vars) => {
      const replenishmentContext = await onMutateUpdate<ItemReplenishmentDTO>({
        queryClient,
        queryKey: findReplenishmentItemsQueryKey,
        removedFindPredicates: [{ id: toNumber(vars.item.id) }],
        isArrayQuery: true,
      });
      const itemPOUContext = await onMutateUpdate<ItemPOU>({
        queryClient,
        queryKey: findItemsPOUQueryKey,
        updatedItems: [
          {
            combinedId: vars.item.itemId,
            alreadyAddedToReplenishment: 0,
          } as ItemPOU,
        ],
        findPredicates: [{ combinedId: vars.item.itemId }],
        isInfiniteQuery: true,
      });
      return { replenishmentContext, itemPOUContext };
    },
    onSuccess: async (data, vars) => {
      addToast({
        text: `The item "${`${vars.item.barcodeValue} ${vars.item.itemDescription}`}" has been removed.`,
        testid: `remove-item-toast-${vars.item.itemId}`,
      });
      await onSuccessMutation(queryClient, findReplenishmentItemsQueryKey);
      void onSuccessMutation(queryClient, findItemsPOUQueryKey);
    },
    onError: (error, vars, context) => {
      const { replenishmentContext, itemPOUContext } =
        context as RemoveItemReplenishmentMutationContext;
      addToast({
        type: ToastType.error,
        text: 'There was an error removing item from replenishment',
        testid: 'rremove-item-replenishment-error-toast',
      });
      onErrorUpdate<ItemReplenishmentDTO>({
        queryClient,
        context: replenishmentContext,
        isArrayQuery: true,
      });
      onErrorUpdate<ItemPOU>({
        queryClient,
        context: itemPOUContext,
        isInfiniteQuery: true,
      });
    },
  });

  return {
    status,
    onRemoveItemReplenishment: (body: RemoveItemReplenishmentBody) =>
      mutate(body),
  };
};

export default useRemoveItemReplenishment;
