import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { find, isNil, toString } from 'lodash';
import { IonSegment, IonSegmentButton } from '@ionic/react';
import type { BaseComponentProps } from 'common/components/utils/renderHelpers';
import { and, choose, ifFunction, or } from 'common/utils/logicHelpers';
import {
  startOfToday,
  addDays,
  subDays,
  addMonths,
  subMonths,
  subYears,
  isWeekend,
  isSunday,
  isFirstDayOfMonth,
  isThisMonth,
  isSameDay,
  getUnixTime,
  fromUnixTime,
  addQuarters,
  subQuarters,
  isBefore,
  isAfter,
  endOfQuarter,
  isThisQuarter,
  endOfMonth,
  startOfDay,
  endOfDay,
  max,
  min,
} from 'date-fns';
import { useReportsConfig } from 'providers/ReportsProvider';
import type { DateSegmentType, ReportsContextProps } from 'models/Reports';
import { DateFormatEnum, formatDate, parseDate } from 'utils/date';
import { findIcon } from 'utils/icons';
import Button from 'components/Button/Button';
import DatePickerModal from 'components/DatePickerModal/DatePickerModal';
import Text from 'components/Text/Text';
import classes from './DateToolbar.module.scss';

interface DateToolbarProps extends BaseComponentProps {
  holidays?: Date[];
  businessDays?: Date[];
  reportKey?: string;
  minDate?: Date;
  maxDate?: Date;
  showBussinessDayOnly?: boolean;
  updateBusPeriod: (date: Date, waitFor?: boolean) => void;
  hideCustomDateSelection?: boolean;
}

const DateToolbar = ({
  className,
  requestType,
  busPeriod,
  showBussinessDayOnly = true,
  updateBusPeriod,
  updateRequestType,
  holidays,
  businessDays,
  reportKey,
  minDate: propsMinDate = subYears(startOfToday(), 5),
  maxDate: propsMaxDate = startOfToday(),
  testid = '',
  hideCustomDateSelection = false,
}: DateToolbarProps &
  Omit<
    ReportsContextProps,
    'sortOption' | 'updateSortOption' | 'updateBusPeriod'
  >): JSX.Element => {
  const { t, i18n } = useTranslation();
  const isDaily = requestType === 'DAILY';
  const isQuarterly = requestType === 'QTD';
  const dateFormat = choose(
    isDaily,
    DateFormatEnum.fullDate,
    choose(isQuarterly, DateFormatEnum.quarterMonth, DateFormatEnum.monthPicker)
  );
  const [isOpen, setIsOpen] = useState(false);

  const isBusinessDay = useCallback(
    (d: Date) => !isNil(find(businessDays, (day) => isSameDay(day, d))),
    [businessDays]
  );

  const calendarKey = useMemo(() => toString(Date.now()), []);
  const {
    requestType: calendarRequestType,
    updateRequestType: calendarUpdateRequestType,
    updateBusPeriod: calendarUpdateBusPeriod,
  } = useReportsConfig({ key: calendarKey });

  const setSelectedDate = (newDate: Date, isPrevNext?: boolean) => {
    let selectedDate = newDate;
    /**
     * Validate if app is open during weekends.
     * Add validation when on MTD navigation
     * and first day of the month starts on a weekend.
     */
    if (showBussinessDayOnly) {
      if (
        isFirstDayOfMonth(selectedDate) &&
        isWeekend(selectedDate) &&
        !isBusinessDay(selectedDate)
      ) {
        selectedDate = addDays(selectedDate, isSunday(selectedDate) ? 1 : 2);
      }
      if (isWeekend(selectedDate) && !isBusinessDay(selectedDate)) {
        selectedDate = subDays(selectedDate, isSunday(selectedDate) ? 2 : 1);
      }
      updateBusPeriod(selectedDate, isPrevNext);
    }
  };

  useEffect(() => {
    // DOC: this sets the correct day if today is a weekend
    setSelectedDate(parseDate(busPeriod));
    calendarUpdateBusPeriod(parseDate(busPeriod));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [busPeriod]);

  useEffect(() => {
    calendarUpdateRequestType(requestType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestType]);

  const setPrevNextDate = (direction: string, d: number = busPeriod) => {
    const isNext = direction === 'next';
    const selectedDate = parseDate(d);
    let newDate = choose(
      isNext,
      endOfMonth(addMonths(selectedDate, 1)),
      endOfMonth(subMonths(selectedDate, 1))
    ) as Date;
    ifFunction(isThisMonth(newDate), () => {
      newDate = startOfToday();
    });
    ifFunction(isQuarterly, () => {
      newDate = choose(
        isNext,
        endOfQuarter(addQuarters(selectedDate, 1)),
        endOfQuarter(subQuarters(selectedDate, 1))
      ) as Date;
      ifFunction(isThisQuarter(newDate), () => {
        newDate = startOfToday();
      });
    });
    if (isDaily) {
      newDate = choose(
        isNext,
        addDays(selectedDate, 1),
        subDays(selectedDate, 1)
      ) as Date;
      if (and(isWeekend(newDate), !isBusinessDay(newDate))) {
        setPrevNextDate(direction, getUnixTime(newDate));
        return;
      }
    }
    setSelectedDate(newDate, true);
  };

  const useEndOfDay = calendarRequestType === 'DAILY';
  let minDate = endOfMonth(propsMinDate);
  let maxDate = endOfMonth(propsMaxDate);

  if (useEndOfDay) {
    minDate = max([propsMinDate, startOfDay(propsMinDate)]);
    maxDate = min([propsMaxDate, endOfDay(propsMaxDate)]);
  }

  const currentDate = useEndOfDay
    ? endOfDay(parseDate(busPeriod))
    : endOfMonth(parseDate(busPeriod));

  const isLeftArrowDisabled = or(
    isBefore(currentDate, minDate),
    isSameDay(currentDate, minDate)
  );
  const isRightArrowDisabled = or(
    isAfter(currentDate, maxDate),
    isSameDay(currentDate, maxDate)
  );

  const dateLabel = formatDate(busPeriod, dateFormat, i18n.language);

  return (
    <div
      className={classNames(classes.DateToolbar, className)}
      data-testid={`DateToolbar-${testid}`}
    >
      <div
        className={classNames(classes.DateNav, {
          [classes.eomNav]: hideCustomDateSelection,
        })}
        data-testid={`DateNav-${testid}`}
      >
        {!hideCustomDateSelection && (
          <Button
            icon={findIcon('caret-left', 'fas')}
            className={classNames(classes.DateArrows, {
              [classes.disabled]: isLeftArrowDisabled,
            })}
            disabled={isLeftArrowDisabled}
            testid={`reports-date-before-button-${testid}`}
            onClick={() => setPrevNextDate('prev')}
          />
        )}
        <Button
          variant="clear"
          className={classes.DateText}
          testid={`reports-date-button-${testid}`}
          onClick={() => {
            calendarUpdateRequestType(requestType);
            calendarUpdateBusPeriod(fromUnixTime(busPeriod));
            setIsOpen(true);
          }}
        >
          <Text
            text={dateLabel}
            testid="datetoolbar-datetext"
            className={classes.Text}
          />
        </Button>
        {!hideCustomDateSelection && (
          <Button
            icon={findIcon('caret-right', 'fas')}
            className={classNames(classes.DateArrows, {
              [classes.disabled]: isRightArrowDisabled,
            })}
            disabled={isRightArrowDisabled}
            testid={`reports-date-after-button-${testid}`}
            onClick={() => setPrevNextDate('next')}
          />
        )}
      </div>
      <div className={classes.DateSegmentDivider} />
      <IonSegment
        value={requestType}
        className={classes.DateSegments}
        data-testid={`DateSegments-${testid}`}
        onIonChange={(e) => {
          if (!isNil(e.detail.value)) {
            updateRequestType(e.detail.value as DateSegmentType);
            setSelectedDate(new Date(), false);
          }
        }}
      >
        <IonSegmentButton
          data-testid={`DateSegments-Daily-${testid}`}
          value="DAILY"
          className={classNames(classes.DateSegment)}
        >
          {toString(t('reports:daily'))}
        </IonSegmentButton>
        <IonSegmentButton
          data-testid={`DateSegments-MTD-${testid}`}
          value="MTD"
          className={classes.DateSegment}
        >
          MTD
        </IonSegmentButton>
        <IonSegmentButton
          data-testid={`DateSegments-QTD-${testid}`}
          value="QTD"
          className={classes.DateSegment}
        >
          QTD
        </IonSegmentButton>
        <IonSegmentButton
          data-testid={`DateSegments-YTD-${testid}`}
          value="YTD"
          className={classes.DateSegment}
        >
          YTD
        </IonSegmentButton>
      </IonSegment>
      <DatePickerModal
        isOpen={isOpen && !hideCustomDateSelection}
        setIsOpen={setIsOpen}
        onDone={(d) => setSelectedDate(d, false)}
        date={parseDate(busPeriod)}
        maxDate={maxDate}
        minDate={minDate}
        filterDate={(d) =>
          !useEndOfDay ? true : or(!isWeekend(d), isBusinessDay(d))
        }
        holidays={holidays}
        businessDays={businessDays}
        calendarKey={calendarKey}
        showSegmentedButtons
        testid={testid}
        reportKey={reportKey}
      />
    </div>
  );
};

export default DateToolbar;
