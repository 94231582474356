import type { MutationStatus } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { doPromiseAPI } from 'api/helpers';

interface CheckForDuplicatesPOBody {
  miLoc: string;
  orderCtlNo: string;
  customerNo: string;
  custPoNo?: string;
  custRelNo?: string;
}

interface UseCheckForDuplicatedPOAPIResponse {
  isDuplicated: boolean;
  rowCount: number;
}

interface UseCheckForDuplicatedPOResponse {
  status: MutationStatus;
  data?: UseCheckForDuplicatedPOAPIResponse;
  onCheckDuplicatesPO: (body: CheckForDuplicatesPOBody) => void;
}

const useCheckForDuplicatesPO = (): UseCheckForDuplicatedPOResponse => {
  const { axios } = useAxios();
  const { checkForDuplicatesPOAPI } = useAPIUrl();

  const doCheckForDuplicatesPO = (body: CheckForDuplicatesPOBody) => {
    return doPromiseAPI<UseCheckForDuplicatedPOAPIResponse>(
      async (cancelToken) => {
        const { data } = await axios.post<UseCheckForDuplicatedPOAPIResponse>(
          checkForDuplicatesPOAPI(),
          { ...body },
          { cancelToken }
        );

        return data;
      }
    );
  };

  const { status, mutate, data } = useMutation(doCheckForDuplicatesPO);

  return {
    status,
    data,
    onCheckDuplicatesPO: (body: CheckForDuplicatesPOBody) => mutate(body),
  };
};

export default useCheckForDuplicatesPO;
