import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { DateFormatEnum, formatDate } from 'utils/date';
import Text from 'components/Text/Text';
import classes from './DateFormat.module.scss';

interface DateFormatProps {
  date?: Date;
  format?: string;
  prefix?: string;
  suffix?: string;
  display?: 'block' | 'inline';
  testid: string;
}
const DateFormat = ({
  date = new Date(),
  format = DateFormatEnum.fullDate,
  prefix = '',
  suffix = '',
  display = 'block',
  variant = 'content-default',
  className,
  testid,
}: DateFormatProps &
  IonicReactProps &
  Omit<React.ComponentProps<typeof Text>, 'text'>): JSX.Element => {
  const { i18n } = useTranslation();
  return (
    <Text
      className={classNames(className, classes[display])}
      variant={variant}
      text={`${prefix}${formatDate(date, format, i18n.language)}${suffix}`}
      testid={`date-format-${testid}`}
    />
  );
};

export default DateFormat;
