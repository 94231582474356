import React from 'react';
import { isNil, map } from 'lodash';
import { IonItem, IonLabel, IonList, IonSkeletonText } from '@ionic/react';
import CostSavingsInput from 'CostSavingsApp/components/CostSavingsForm/CostSavingsInput';
import type { CostSavingsField } from 'CostSavingsApp/models/CostSavings';
import Text from 'components/Text/Text';
import classes from './CostSavingsReview.module.scss';

export type ReviewField = CostSavingsField & {
  value: string;
  additionalValue?: string;
  customContent?: React.ReactNode;
};

interface CostSavingsReviewProps {
  fields: ReviewField[];
  isLoading?: boolean;
}

const CostSavingsReview = ({
  fields,
  isLoading,
}: CostSavingsReviewProps): JSX.Element => (
  <div className={classes.reviewWrapper}>
    <IonList>
      {map(
        fields,
        ({ additionalValue, customContent, show, ...field }, index) => {
          if (show === 'N') {
            return null;
          }
          return (
            <IonItem lines="none" key={index} className={classes.item}>
              <IonLabel className={classes.label}>
                {!isLoading ? (
                  <h3>
                    <Text
                      variant="mipro-body-copy-bold"
                      text={field.displayName}
                    />
                  </h3>
                ) : (
                  <IonSkeletonText />
                )}
                {!isLoading ? (
                  <div className={classes.valueTextWrapper}>
                    <CostSavingsInput
                      viewMode
                      textVariant={
                        !isNil(additionalValue)
                          ? 'mipro-body-copy-bold'
                          : 'mipro-body-copy'
                      }
                      formData={{ [field.columnName]: field.value || '--' }}
                      field={field}
                      testid={`${field.columnName}-text`}
                    />
                    {!!additionalValue && (
                      <Text
                        variant="mipro-body-copy"
                        text={`: ${additionalValue}`}
                        className={classes.valueText}
                      />
                    )}
                    {customContent}
                  </div>
                ) : (
                  <IonSkeletonText />
                )}
              </IonLabel>
            </IonItem>
          );
        }
      )}
    </IonList>
  </div>
);

export default CostSavingsReview;
