import React from 'react';
import Text from 'components/Text/Text';
import TitleLine from 'components/TitleLine/TitleLine';
import classes from './DocumentHeader.module.scss';

export interface DocumentHeaderProps {
  titleText: string;
}

const DocumentHeader = ({ titleText }: DocumentHeaderProps): JSX.Element => (
  <div className={classes.DocumentHeader} data-testid="DocumentHeader">
    <Text text={titleText} variant="underlined-title-section" />
    <TitleLine />
  </div>
);

export default DocumentHeader;
