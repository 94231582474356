import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { defaultExpectedDate } from 'constants/platformSpecificConstants';
import Input, { FormikInput } from 'common/components/Forms/Input/Input';
import { useFormikContext } from 'formik';
import useCheckForDuplicatesPO from 'ProductSearchApp/api/checkout/useCheckForDuplicatesPO';
import { OcnTypeEnum } from 'ProductSearchApp/models/Order';
import type { CheckoutOrderForm } from 'ProductSearchApp/models/Order';
import type { OrderURLParams } from 'ProductSearchApp/models/Products';
import { DateFormatEnum, formatLastUpdatedDate } from 'utils/date';
import Text from 'components/Text/Text';
import classes from './CheckoutForm.module.scss';

interface OrderInfoProps {
  reOrderReminder: string;
  disabled?: boolean;
  ocnType: OcnTypeEnum;
}

const OrderInfo = ({
  reOrderReminder,
  disabled,
  ocnType,
}: OrderInfoProps): JSX.Element => {
  const { t } = useTranslation();
  const namespace = 'productSearch:checkout';
  const reviewNamespace = 'productSearch:review';
  const { miLoc, id, ocn } = useParams<OrderURLParams>();
  const isOrder = ocnType === OcnTypeEnum.order;

  const { onCheckDuplicatesPO, data } = useCheckForDuplicatesPO();

  const [poNumberUpdated, setPoNumberUpdated] = useState(false);
  const [poNumberChanged, setPoNumberChanged] = useState(false);
  const [releaseNoUpdated, setReleaseNoUpdated] = useState(false);
  const [releaseNoChanged, setReleaseNoChanged] = useState(false);

  const {
    values: { dueDate, poNumber, custReleaseNo },
    touched,
  } = useFormikContext<CheckoutOrderForm>();

  const expectedDate =
    dueDate === defaultExpectedDate ? t(`${reviewNamespace}:notSet`) : dueDate;
  const poWarning = data?.isDuplicated && poNumber;

  const checkDuplicates = () => {
    if (poNumber) {
      onCheckDuplicatesPO({
        miLoc,
        customerNo: id,
        orderCtlNo: ocn,
        custPoNo: poNumber,
        custRelNo: custReleaseNo,
      });
    }
  };

  useEffect(() => {
    if (!touched.poNumber && !touched.custReleaseNo) {
      checkDuplicates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [touched.custReleaseNo, touched.poNumber]);

  useEffect(() => {
    if (
      (poNumberUpdated && poNumberChanged) ||
      (releaseNoUpdated && releaseNoChanged)
    ) {
      checkDuplicates();
      setPoNumberUpdated(false);
      setPoNumberChanged(false);
      setReleaseNoUpdated(false);
      setReleaseNoChanged(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    custReleaseNo,
    poNumber,
    poNumberUpdated,
    poNumberChanged,
    releaseNoUpdated,
    releaseNoChanged,
  ]);

  return (
    <div className={classes.cardWrapper}>
      <Text variant="mipro-h3-headline" text={t(`${namespace}:orderInfo`)} />
      <Input
        className={classes.input}
        value={formatLastUpdatedDate(
          expectedDate,
          DateFormatEnum.dayShortMonth
        )}
        name="expectedDate"
        label={t(`${namespace}:expectedDt`)}
        placeholder={t(`${namespace}:expectedDt`)}
        readonly
        rightButton={{
          testid: 'calendar-icon',
          icon: ['far', 'calendar-days'],
        }}
        testid="expected-date-input"
        info={t(`${namespace}:expectedDtWarningMessage`)}
      />
      <FormikInput
        className={classes.input}
        label={t(`${namespace}:poNumber`)}
        placeholder={t(`${namespace}:poNumberPlaceholder`)}
        name="poNumber"
        setValue={() => setPoNumberChanged(true)}
        onFocus={() => setPoNumberUpdated(false)}
        onBlur={() => setPoNumberUpdated(true)}
        toUpperCase
        required={isOrder}
        disabled={reOrderReminder === 'Y' || disabled}
        maxlength={22}
        warning={poWarning ? t(`${namespace}:duplicatesText`) : ''}
        testid="po-number-input"
      />
      <FormikInput
        className={classes.input}
        label={t(`${namespace}:releaseNumber`)}
        placeholder={t(`${namespace}:releaseNumberPlaceholder`)}
        name="custReleaseNo"
        setValue={() => setReleaseNoChanged(true)}
        onFocus={() => setReleaseNoUpdated(false)}
        onBlur={() => setReleaseNoUpdated(true)}
        toUpperCase
        disabled={disabled}
        maxlength={25}
        testid="release-number-input"
      />
    </div>
  );
};

export default OrderInfo;
