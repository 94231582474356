import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { isEmpty, toString } from 'lodash';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import type { BaseComponentProps } from 'common/components/utils/renderHelpers';
import type { BadgeType } from 'components/Badge/Badge';
import Badge from 'components/Badge/Badge';
import classes from './OcnBadge.module.scss';

enum OcnBadgeEnum {
  'AR' = 'AR',
  'CA' = 'CA',
  'CL' = 'CL',
  'HD' = 'HD',
  'PA' = 'PA',
  'PL' = 'PL',
  'PS' = 'PS',
  'UM' = 'UM',
}

interface OcnBadgeProps {
  statusId: OcnBadgeEnum | string;
}

export const getStatus: Record<
  string,
  { text: string; variant: BadgeType; className?: string }
> = {
  [OcnBadgeEnum.AR]: {
    text: 'productSearch:orderList:authorizationBadge',
    variant: 'error',
  },
  [OcnBadgeEnum.CA]: {
    text: 'productSearch:orderList:cancelledBadge',
    variant: 'info',
    className: classes.badgeSecondary,
  },
  [OcnBadgeEnum.CL]: {
    text: 'productSearch:orderList:closedBadge',
    variant: 'info',
    className: classes.badgeSecondary,
  },
  [OcnBadgeEnum.HD]: {
    text: 'productSearch:orderList:creditBadge',
    variant: 'error',
  },
  [OcnBadgeEnum.PA]: {
    text: 'productSearch:orderList:pendingBadge',
    variant: 'warning',
    className: classes.badgeWarning,
  },
  [OcnBadgeEnum.PL]: {
    text: 'productSearch:orderList:plannedBadge',
    variant: 'warning',
    className: classes.badgeWarning,
  },
  [OcnBadgeEnum.PS]: {
    text: 'productSearch:orderList:partiallyBadge',
    variant: 'info',
  },
  [OcnBadgeEnum.UM]: {
    text: 'productSearch:orderList:unprocessedBadge',
    variant: 'info',
  },
};

export const OcnBadge = ({
  statusId,
  className,
}: OcnBadgeProps & IonicReactProps) => {
  const { t } = useTranslation();
  const status = getStatus[statusId];

  if (isEmpty(status)) {
    return <></>;
  }

  return (
    <Badge
      className={classNames(className, status.className)}
      type={status.variant}
      text={t(status.text)}
      testid={`ocn-badge-${statusId}`}
    />
  );
};

interface OcnLineItemBadgeProps {
  status?: string;
  isCancelledWo?: boolean;
}

interface GetLineItemStatusReponse
  extends OcnLineItemBadgeProps,
    BaseComponentProps {
  type: BadgeType;
}

const getLineItemStatus = (status?: string): GetLineItemStatusReponse => {
  let className = '';
  let type: BadgeType = 'info';

  switch (status) {
    case 'Allocated':
    case 'In Transit':
    case 'Sourced':
      className = classes.badgeWarning;
      break;
    case 'Delay Pick':
      type = 'error';
      break;
    case 'Open':
      className = classes.clear;
      break;
    case 'Active Pick':
    case 'SSS Pending':
      type = 'green';
      break;
    case 'Cancelled':
      className = classes.badgeSecondary;
      break;
    default:
  }

  return { className, type, status };
};

export const OcnLineItemBadge = ({
  status,
  isCancelledWo,
}: OcnLineItemBadgeProps) => {
  const { t } = useTranslation();
  let lineStatus = toString(status);
  const { type, className: badgeClassName } = getLineItemStatus(lineStatus);
  if (isCancelledWo) {
    lineStatus = t('productSearch:ocn:cancelWoStatusBadge');
  }

  return (
    <Badge
      text={toString(lineStatus)}
      type={type}
      className={classNames(badgeClassName, {
        [classes.cancelledWo]: isCancelledWo,
      })}
      testid="ocn-line-item-badge"
    />
  );
};
