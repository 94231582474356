import { useMemo } from 'react';
import type { AxiosError } from 'axios';
import { head, size } from 'lodash';
import { useInfiniteQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import type {
  QueryFlags,
  QueryFnProps,
  QueryParamsType,
} from 'common/api/utils/useGetQueryFlags';
import useGetQueryFlags from 'common/api/utils/useGetQueryFlags';
import type { ProductSourcesModel } from 'ProductSearchApp/models/Products';
import { useAxios } from 'providers/AxiosProvider';
import { doConcatDataPages, useKeyUserId, useMiLocOrTeamId } from 'api/helpers';
import type { InfiniteQueryFlags } from 'models/Search';
import { pageSize } from 'utils/constants';

export const findCustomerProductSources = 'search-customer-product-sources';

interface UseFindProductSourcesProps {
  miLoc: string;
  id: string;
  productId: string;
  allLocation: boolean;
  primarySource: boolean;
  groupSerial: string;
  mfrCtlNo: string;
  limit?: number;
  enabled?: boolean;
}

export interface SearchProductSourcesResponse {
  total?: number;
  items?: ProductSourcesModel[];
}

export type UseFindProuctSourcesResponse = SearchProductSourcesResponse &
  InfiniteQueryFlags;

interface FindCustomerProductSourcesAPIResponse {
  totalRows: number;
  sources: ProductSourcesModel[];
}

const useFindProductSources = ({
  miLoc,
  id,
  productId,
  allLocation,
  primarySource,
  groupSerial,
  mfrCtlNo,
  limit = pageSize(),
  enabled = true,
}: UseFindProductSourcesProps): SearchProductSourcesResponse & QueryFlags => {
  const { axios } = useAxios();
  const { findProductSourcesAPI } = useAPIUrl();
  const { getURLParams } = useMiLocOrTeamId({});
  const { createQueryKey } = useKeyUserId();

  const params: QueryParamsType = {
    allLocation,
    primarySource,
    groupSerial,
    mfrCtlNo,
    limit,
  };

  const doFindProductSources = async ({
    pageParam = 1,
    signal,
  }: QueryFnProps) => {
    const { data } = await axios.get<FindCustomerProductSourcesAPIResponse>(
      findProductSourcesAPI(
        miLoc,
        id,
        productId,
        getURLParams({ ...params, page: pageParam })
      ),
      { signal }
    );
    return { total: data.totalRows, items: data.sources };
  };

  const response = useInfiniteQuery<SearchProductSourcesResponse, AxiosError>(
    createQueryKey(findCustomerProductSources, params),
    doFindProductSources,
    {
      enabled,
      getNextPageParam: (lastPage, pages) =>
        size(lastPage.items) < limit ? false : size(pages) + 1,
    }
  );

  const { data } = response;

  const productSources = useMemo(
    () =>
      doConcatDataPages<ProductSourcesModel, SearchProductSourcesResponse>(
        data,
        'items'
      ),
    [data]
  );

  const queryFlags = useGetQueryFlags({
    ...response,
    data: productSources,
    enabled,
  });

  return {
    items: productSources,
    total: head(data?.pages)?.total,
    ...queryFlags,
  };
};

export default useFindProductSources;
