import { useMemo } from 'react';
import type { AxiosError } from 'axios';
import { size } from 'lodash';
import { useInfiniteQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import type {
  QueryFlags,
  QueryFnProps,
  QueryParamsType,
} from 'common/api/utils/useGetQueryFlags';
import useGetQueryFlags from 'common/api/utils/useGetQueryFlags';
import { useAxios } from 'providers/AxiosProvider';
import { doConcatDataPages, useKeyUserId, useMiLocOrTeamId } from 'api/helpers';
import type { Location } from 'models/Location';
import { pageSize } from 'utils/constants';

interface FindRepaitShopsAPIResponse {
  locations: Location[];
  totalRows: number;
}

export const findRepairShopsQueryKey = 'repair-shops';

interface UseFindRepairShopsProps {
  query?: string;
  miLoc?: string;
  // TODO create reusable interface with props
  enabled?: boolean;
  limit?: number;
}

interface UseFindRepairShopsResponse {
  locations: Location[];
}

const useFindRepairShops = ({
  query,
  miLoc = '',
  enabled: propsEnabled = true,
  limit = pageSize(),
}: UseFindRepairShopsProps): UseFindRepairShopsResponse & QueryFlags => {
  const { axios } = useAxios();
  const { findRepairShopsAPI } = useAPIUrl();
  // TODO put together common utils
  const { createQueryKey } = useKeyUserId();
  const { getURLParams } = useMiLocOrTeamId({});
  const params: QueryParamsType = { query, miLoc, limit };
  const enabled = propsEnabled && !!miLoc;

  const doFindRepairShops = async ({ pageParam = 1, signal }: QueryFnProps) => {
    const { data } = await axios.get<FindRepaitShopsAPIResponse>(
      findRepairShopsAPI(miLoc, getURLParams({ ...params, page: pageParam })),
      { signal }
    );
    return data;
  };

  const response = useInfiniteQuery<FindRepaitShopsAPIResponse, AxiosError>(
    createQueryKey(findRepairShopsQueryKey, params),
    doFindRepairShops,
    {
      enabled,
      getNextPageParam: (lastPage, pages) =>
        size(lastPage.locations) < limit ? false : size(pages) + 1,
    }
  );

  const locations = useMemo(
    () =>
      doConcatDataPages<Location, FindRepaitShopsAPIResponse>(
        response.data,
        'locations'
      ),
    [response.data]
  );

  const queryFlags = useGetQueryFlags({
    ...response,
    data: locations,
    enabled,
  });

  return { locations, ...queryFlags };
};

export default useFindRepairShops;
