import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { toString, map } from 'lodash';
import { IonList, IonLabel, IonItem, IonRow, IonCol } from '@ionic/react';
import { formatNumber } from 'common/utils/numberHelper';
import type { Pick12CustomerProps } from 'models/LeaderBoard';
import LeaderboardShape from 'components/LeaderboardShape/LeaderboardShape';
import Text from 'components/Text/Text';
import classes from './Pick12LeaderBoardDetail.module.scss';

interface CustomerListProps {
  pick12Customers: Pick12CustomerProps[];
  closeLeaderBoardCard?: () => void;
  currency?: string;
}

const CustomersList = ({
  pick12Customers,
  closeLeaderBoardCard,
  currency,
}: CustomerListProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <LeaderboardShape
      className="customerLists"
      closeLeaderBoardCard={closeLeaderBoardCard}
      showCloseButton
      testid="customer-lists"
    >
      <IonRow>
        <IonCol size="12">
          <Text
            text={t('pick12LeaderBoard:pick12Customers')}
            variant="title-card-red"
            className={classes.text}
            testid="pick12-customers"
          />
        </IonCol>
      </IonRow>
      <IonList className={classes.customerList}>
        <IonItem slot="header" lines="none">
          <IonLabel slot="start">{toString(t('customer'))}</IonLabel>
          <IonLabel slot="end">
            {toString(t('pick12LeaderBoard:totalSalesYtd'))}
          </IonLabel>
        </IonItem>
        {map(pick12Customers, (customer, customerIndex) => (
          <IonItem
            lines="none"
            key={`${customer?.name}${customerIndex}`}
            className={classNames({
              [classes.inActive]: !customer.active,
            })}
            data-testid="single-customer"
          >
            <IonLabel slot="start">{customer.name}</IonLabel>
            <IonLabel
              slot="end"
              data-testid={`customer-sales-${customerIndex}`}
            >
              {formatNumber({
                number: customer.sales,
                abbreviated: true,
                currencyType: currency,
              })}
            </IonLabel>
          </IonItem>
        ))}
      </IonList>
    </LeaderboardShape>
  );
};

export default CustomersList;
