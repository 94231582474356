import React, { useState } from 'react';
import { isEmpty, toString } from 'lodash';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import FastFind from 'DocumentsApp/components/FastFind/FastFind';
import useFindChargeBackValues from 'StoreroomsApp/api/useFindChargeBackValues';
import type { ChargeBackValue } from 'StoreroomsApp/models/ChargeBackProfile';
import { useDebounce } from 'use-debounce';
import type Input from 'components/Input/Input';

interface ChargeBackFastFindProps {
  customerNumber?: string;
  descriptionId?: string;
}

const ChargeBackFastFind = ({
  className,
  label,
  value,
  error,
  required,
  setValue,
  disabled,
  customerNumber,
  descriptionId,
  testid,
}: ChargeBackFastFindProps &
  React.ComponentProps<typeof Input> &
  IonicReactProps): JSX.Element => {
  const [searchQuery, setSearchQuery] = useState(toString(value));
  const [searchQueryValue] = useDebounce(searchQuery, 300);

  const {
    chargeBackValues,
    showLoader,
    isEmptyResponse,
    enableInfiniteScroll,
    fetchNextPage,
  } = useFindChargeBackValues({
    enabled: !isEmpty(customerNumber) && !isEmpty(descriptionId),
    query: searchQueryValue,
    customerNumber,
    descriptionId,
  });

  return (
    <FastFind<ChargeBackValue>
      className={className}
      items={chargeBackValues || []}
      getId={(i) => i.valueId}
      getTitle={(i) => `${i.valueId} ${i.valueDescription}`}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      showLoader={showLoader}
      isEmptyResponse={isEmptyResponse}
      enableInfiniteScroll={enableInfiniteScroll}
      fetchNextPage={fetchNextPage}
      label={label}
      value={value}
      inputError={error}
      disabled={disabled}
      required={required}
      setValue={setValue}
      testid={testid}
    />
  );
};

export default ChargeBackFastFind;
