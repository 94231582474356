import { useTranslation } from 'react-i18next';
import { toNumber } from 'lodash';
import type { MutationStatus } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { namespaces } from 'i18n/i18n.constants';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import type { UpdateMutationContext } from 'api/helpers';
import {
  doPromiseAPI,
  onErrorUpdate,
  onMutateUpdate,
  onSuccessMutation,
} from 'api/helpers';
import type { Note, UpdateNotebookFileBody } from 'models/Notebook';
import { ToastType } from 'models/Toast';
import { findNotesQueryKey } from './useFindNotes';

interface UseUpdateNotebookProps {
  miLoc: string;
  id: string;
  ctlNo: string;
  noteId: string;
  nbType?: string;
}

interface UseUpdateNotebookResponse {
  status: MutationStatus;
  onUpdateNotebook: (body: UpdateNotebookFileBody) => void;
}

const useUpdateNotebook = ({
  miLoc,
  id,
  ctlNo,
  noteId,
  nbType = 'CM',
}: UseUpdateNotebookProps): UseUpdateNotebookResponse => {
  const { axios } = useAxios();
  const { notebooksAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const { t } = useTranslation(namespaces.notes);

  const doAddNotebook = (body: UpdateNotebookFileBody) => {
    return doPromiseAPI(async (cancelToken) => {
      return axios.put(notebooksAPI(nbType, ctlNo), body, { cancelToken });
    });
  };

  const { status, mutate } = useMutation(doAddNotebook, {
    onMutate: async (vars) =>
      onMutateUpdate<Note>({
        queryClient,
        queryKey: findNotesQueryKey,
        queryKeyParams: { miLoc, id },
        updatedItems: [
          {
            miLoc,
            customerNo: id,
            title: vars.header,
            text: vars.text,
            updatedTimestamp: new Date().toISOString(),
          },
        ],
        findPredicates: [
          {
            miLoc,
            customerNo: id,
            itemLineNo: toNumber(noteId),
          },
        ],
        isInfiniteQuery: true,
      }),
    onSuccess: (data, vars) => {
      addToast({
        text: t('editToast', { title: vars?.header || t('notebook') }),
        // TODO undo operation
        testid: 'edit-notebook-toast',
      });
      void onSuccessMutation(queryClient, findNotesQueryKey, {
        miLoc,
        id,
      });
    },
    onError: (data, vars, context) => {
      addToast({
        type: ToastType.error,
        text: t('editFailureToast'),
        testid: 'edit-notebook-error-toast',
      });
      onErrorUpdate<Note>({
        queryClient,
        context: context as UpdateMutationContext<Note>[],
        isInfiniteQuery: true,
      });
    },
  });

  return {
    status,
    onUpdateNotebook: (body: UpdateNotebookFileBody) => mutate(body),
  };
};

export default useUpdateNotebook;
