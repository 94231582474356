import React, { useEffect, useState } from 'react';
import type { Dictionary } from 'lodash';
import { findIndex, toNumber, toString } from 'lodash';
import {
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonRow,
} from '@ionic/react';
import { useToasts } from 'providers/ToastProvider';
import QuantityInput from 'StoreroomsApp/components/ItemsPOUList/QuantityInput';
import type { ItemIssue, ItemIssueDTO } from 'StoreroomsApp/models/Issue';
import { useDebounce } from 'use-debounce';
import { findIcon } from 'utils/icons';
import ActionRow from 'components/ActionRow/ActionRow';
import Button from 'components/Button/Button';
import Text from 'components/Text/Text';
import classes from './ItemCard.module.scss';

interface ItemCardProps {
  item: ItemIssueDTO;
  disabled?: boolean;
  formValues?: Dictionary<unknown>;
  setFieldValue?: (field: string, value: unknown) => void;
}

const ItemCard = ({
  item,
  disabled,
  formValues,
  setFieldValue,
}: ItemCardProps): JSX.Element => {
  const { addToast } = useToasts();
  const {
    customerStockNumber,
    itemDescription,
    binLocation,
    balanceOnHandQuantity,
  } = item;
  const itemQuantity = toNumber(item.issueQuantity);
  const [quantity, setQuantity] = useState(itemQuantity);
  const [updateQuantity] = useDebounce(quantity, 1000);

  useEffect(() => {
    setQuantity(itemQuantity);
  }, [itemQuantity]);

  useEffect(() => {
    if (updateQuantity !== itemQuantity) {
      const prev = [...((formValues?.issueItems || []) as ItemIssue[])];
      const index = findIndex(prev, { itemId: item.combinedId });
      setFieldValue?.(`issueItems.${index}`, {
        ...prev[index],
        issueQuantity: updateQuantity,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.id, itemQuantity, updateQuantity]);

  let maxQuantity: number | undefined = toNumber(balanceOnHandQuantity);
  if (maxQuantity < 0) {
    maxQuantity = undefined;
  }

  return (
    <IonItemSliding>
      <ActionRow
        disabled={false}
        className={classes.item}
        onClick={() => {
          if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
          }
        }}
        testid={`item-${toString(item.id)}`}
      >
        <IonRow className={classes.itemRow}>
          <div className={classes.dataRow}>
            <div className={classes.wrapper}>
              <IonRow>
                <div className={classes.inner}>
                  <div className={classes.titleRow}>
                    <Text
                      className={classes.title}
                      text={customerStockNumber || '<No CSN>'}
                      variant="content-heavy"
                      testid="item-pou-text"
                    />
                    <Text text={balanceOnHandQuantity} />
                  </div>
                  <div>
                    <Text
                      className={classes.description}
                      text={itemDescription}
                      variant="content-small"
                    />
                  </div>
                  <div>
                    <Text text={binLocation} />
                  </div>
                </div>
              </IonRow>
              {!!maxQuantity && quantity > maxQuantity && (
                <div>
                  <Text
                    className={classes.itemError}
                    text="Quantity issued is greater than Balance on Hand"
                  />
                </div>
              )}
              {quantity <= 0 && (
                <div>
                  <Text
                    className={classes.itemError}
                    text="Quantity issued must be greater than 0"
                  />
                </div>
              )}
            </div>
          </div>
          <QuantityInput
            className={classes.input}
            buttonClassName={classes.buttonInput}
            disabled={disabled}
            max={maxQuantity}
            value={quantity}
            setValue={(v) => setQuantity(toNumber(v))}
          />
        </IonRow>
      </ActionRow>
      <IonItemOptions side="end">
        <IonItemOption
          onClick={() => {
            const prev = [...((formValues?.issueItems || []) as ItemIssue[])];
            const index = findIndex(prev, { itemId: item.combinedId });
            setFieldValue?.('issueItems', [
              ...prev.slice(0, index),
              ...prev.slice(index + 1),
            ]);
            addToast({
              text: `The item "${`${item.barcodeValue} ${item.itemDescription}`}" has been removed.`,
              testid: `remove-item-toast-${item.itemId}`,
              button: {
                role: 'undo',
                text: 'Undo',
                handler: () => setFieldValue?.('issueItems', prev),
              },
            });
          }}
          color="danger"
        >
          <Button
            className={classes.deleteButton}
            icon={findIcon('trash')}
            text="Delete"
            testid="delete-button"
          />
        </IonItemOption>
      </IonItemOptions>
    </IonItemSliding>
  );
};

export default ItemCard;
