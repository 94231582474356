import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import {
  filter,
  findIndex,
  forEach,
  head,
  isEmpty,
  last,
  map,
  set,
  size,
  toNumber,
} from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IonContent,
  IonMenuButton,
  IonMenuToggle,
  IonPage,
  IonicSlides,
  IonRow,
} from '@ionic/react';
import { useQueryClient } from '@tanstack/react-query';
import { getCostSavingsReportQueryKey } from 'ReportsApp/api/useGetCostSavingsReport';
import { getDigitalSalesReportQueryKey } from 'ReportsApp/api/useGetDigitalSalesReport';
import { getSalesDashboardQueryKey } from 'ReportsApp/api/useGetSalesDashboard';
import { getSalesReportQueryKey } from 'ReportsApp/api/useGetSalesReport';
import useGetCamUserDetails from 'ReportsApp/hooks/useGetCamUserDetails';
import type { Swiper as SwiperClass } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { findAnnouncementsQueryKey } from 'api/announcements/useFindAnnouncements';
import useAccessControls, {
  AccessControlType,
  useHasAccessControls,
} from 'hooks/useAccessControls';
import useGetAppLogo from 'hooks/useGetAppLogo';
import { findIcon } from 'utils/icons';
import Header from 'components/Header/Header';
import headerClasses from 'components/Header/Header.module.scss';
import Refresher from 'components/Refresher/Refresher';
import SegmentTabs from 'components/SegmentTabs/SegmentTabs';
import Text from 'components/Text/Text';
import 'swiper/css';
import 'swiper/css/pagination';
import '@ionic/react/css/ionic-swiper.css';
import CostSavingsHealthCard from './CostSavingsHealthCard/CostSavingsHealthCard';
import Pick12Card from './DashboardCards/Pick12Card';
import SalesPerformanceCard from './DashboardCards/SalesPerformanceCard';
import WebPerformanceCard from './DashboardCards/WebPerformanceCard';
import HomeAnnouncements from './HomeAnnouncements';
import classes from './NewHome.module.scss';

const NewHome = (): JSX.Element => {
  const { t } = useTranslation();
  const { redLogo } = useGetAppLogo();
  const [swiperRef, setSwiperRef] = useState<SwiperClass>();
  const tabsRowRef = useRef<HTMLDivElement>(null);
  const segmentTabsRef = useRef<HTMLIonSegmentElement>(null);
  const [selectedTab, setSelectedTab] = useState('sales');

  const costSavingsAC = useAccessControls(
    AccessControlType.CostSavingsAccessControls
  );

  const { hasAccessControl } = useHasAccessControls();
  const queryClient = useQueryClient();

  const { accessControl: reportAccessControl } = useGetCamUserDetails();

  const tabs = filter(
    [
      {
        key: 'sales',
        text: t('common:sales'),
        accessControl: reportAccessControl,
        component: SalesPerformanceCard,
      },
      {
        key: 'pick12',
        text: t('reports:pick12'),
        component: Pick12Card,
        accessControl: AccessControlType.viewReports,
      },
      {
        key: 'web',
        text: t('activities:webTitle'),
        component: WebPerformanceCard,
        accessControl: reportAccessControl,
      },
    ],
    ({ accessControl }) => hasAccessControl(accessControl)
  );

  const onSwipe = (sw: SwiperClass) => {
    if (tabsRowRef.current) {
      const lowerRange = 0.35;
      const firstSnap = toNumber(head(sw.snapGrid));
      // calibrate to 0
      const snapGrid = map(sw.snapGrid, (snap) => Math.abs(snap - firstSnap));
      const lastSnap = toNumber(last(snapGrid));
      let translate = -1 * sw.translate - firstSnap;
      let snapIndex = 0;
      if (translate < 0) {
        translate = 0;
      }
      if (translate > lastSnap) {
        translate = lastSnap;
      }
      forEach(snapGrid, (snap, index) => {
        if (translate > snap) {
          snapIndex = index;
        }
      });
      const progress =
        100 *
        ((translate - snapGrid[snapIndex]) /
          (snapGrid[snapIndex + 1] - snapGrid[snapIndex]));
      tabsRowRef.current.style.transform = `translateX(${
        100 * snapIndex + progress
      }%)`;
      if (segmentTabsRef.current) {
        const range = 1 - lowerRange;
        const prevTab =
          segmentTabsRef.current.childNodes[snapIndex]?.firstChild?.firstChild;
        const nextTab =
          segmentTabsRef.current.childNodes[snapIndex + 1]?.firstChild
            ?.firstChild;
        set(
          prevTab as ChildNode,
          'style.opacity',
          lowerRange + ((100 - progress) * range) / 100
        );
        set(
          nextTab as ChildNode,
          'style.opacity',
          lowerRange + (progress * range) / 100
        );
      }
    }
  };

  return (
    <IonPage className={classes.homePage} data-testid="home-page">
      <div className={classes.background} />
      <Header
        theme="dark"
        className={classes.homeHeader}
        showLocationText
        toolbar={
          <>
            <div className={headerClasses.logo}>
              <img data-testid="mipro-logo" src={redLogo} alt="" />
            </div>
            <IonMenuToggle
              slot="end"
              className={headerClasses.openMenuToggle}
              menu="home-menu"
              data-testid="home-header-avatar-toggle"
            >
              <IonMenuButton
                className={headerClasses.menuButton}
                autoHide={false}
              />
            </IonMenuToggle>
          </>
        }
        testid="home"
      />
      <IonContent className={classes.content}>
        <div>
          <IonRow className={classes.titleRow}>
            <Text
              className={classes.title}
              variant="title-screen-section"
              text={t('reportSummaries')}
            />
            <Refresher
              slot="fixed"
              variant="new-home"
              className={classes.refreshHomeBanner}
              onRefresh={async () => {
                await Promise.all([
                  queryClient.invalidateQueries([findAnnouncementsQueryKey]),
                  queryClient.invalidateQueries(['features']),
                  queryClient.invalidateQueries([getCostSavingsReportQueryKey]),
                  queryClient.invalidateQueries([getSalesDashboardQueryKey]),
                  queryClient.invalidateQueries([getSalesReportQueryKey]),
                  queryClient.invalidateQueries([
                    getDigitalSalesReportQueryKey,
                  ]),
                ]);
              }}
              testid="home-refresher"
            />
          </IonRow>
          {!isEmpty(tabs) ? (
            <>
              <SegmentTabs
                ref={segmentTabsRef}
                className={classes.tabs}
                selectedClassName={classes.selectedTab}
                value={selectedTab}
                options={tabs}
                setValue={(key) => {
                  setSelectedTab(key);
                  swiperRef?.slideTo(findIndex(tabs, { key }));
                }}
                testid="tabs"
              />
              <div className={classes.tabsRow}>
                <div
                  ref={tabsRowRef}
                  className={classes.selectedRow}
                  style={{ width: `calc(100% / ${size(tabs) || 1})` }}
                />
              </div>
              <Swiper
                className={classes.slides}
                onSwiper={setSwiperRef}
                slidesPerView="auto"
                centeredSlides
                onActiveIndexChange={(sw) =>
                  setSelectedTab(tabs[sw.activeIndex].key)
                }
                modules={[IonicSlides]}
                onTransitionStart={onSwipe}
                onTransitionEnd={(sw) => {
                  onSwipe(sw);
                  if (segmentTabsRef.current) {
                    forEach(segmentTabsRef.current.childNodes, (node) => {
                      set(
                        node?.firstChild?.firstChild as ChildNode,
                        'style.opacity',
                        ''
                      );
                    });
                  }
                }}
                onSliderMove={onSwipe}
              >
                {map(tabs, ({ key, component: CustomComponent }) => (
                  <SwiperSlide key={key}>
                    <CustomComponent />
                  </SwiperSlide>
                ))}
              </Swiper>
            </>
          ) : (
            <div className={classes.forbiddenSummariesWarning}>
              <IonRow className={classes.iconRow}>
                <FontAwesomeIcon
                  className={classes.icon}
                  icon={findIcon('exclamation-triangle', 'fas')}
                />
              </IonRow>
              <IonRow
                className={classnames(
                  classes.warningRow,
                  classes.warningHeaderRow
                )}
              >
                <Text
                  className={classnames(
                    classes.warningHeaderText,
                    classes.warningText
                  )}
                  variant="title-info-card"
                  text={t('forbiddenSummariesHeader')}
                />
              </IonRow>
              <IonRow
                className={classnames(
                  classes.warningRow,
                  classes.warningMsgRow
                )}
              >
                <Text
                  className={classnames(
                    classes.warningMsgText,
                    classes.warningText
                  )}
                  variant="content-default"
                  text={t('forbiddenSummariesDesc')}
                />
              </IonRow>
            </div>
          )}
        </div>
        <div className={classes.bottomSectionWrapper}>
          {costSavingsAC && <CostSavingsHealthCard />}
          <HomeAnnouncements />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default NewHome;
