import React from 'react';
import classNames from 'classnames';
import { kebabCase, map, toString } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonContent, IonItem, IonList, IonPopover } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import type { SortOption } from 'models/Sort';
import Text from 'components/Text/Text';
import classes from './DropDown.module.scss';

interface DropDownProps {
  className?: string;
  testid: string;
  id?: string;
  selectedItem?: string;
  filterOptions?: SortOption[];
  onOptionClick?: (option: SortOption) => void;
  alignment: string;
  iconsLeft?: boolean;
}

const DropDown = ({
  className,
  testid,
  id,
  selectedItem,
  onClick,
  onOptionClick,
  filterOptions,
  alignment,
  side = 'bottom',
  iconsLeft = false,
}: DropDownProps &
  React.ComponentProps<typeof IonPopover> &
  IonicReactProps): JSX.Element => {
  return (
    <IonPopover
      trigger={id}
      dismissOnSelect
      className={classNames(className, classes.dropdown)}
      alignment={alignment}
      side={side}
      onClick={onClick}
    >
      <IonContent className={classes.dropdownContent} scrollY={false}>
        <IonList>
          {map(filterOptions, ({ key, name, icon, hidden }, index) => (
            <IonItem
              hidden={hidden || false}
              key={key}
              button
              detail={false}
              onClick={() => {
                if (onOptionClick && filterOptions) {
                  onOptionClick?.(filterOptions[index]);
                }
              }}
              data-testid={`${testid}-${kebabCase(toString(name)) || key}`}
            >
              {iconsLeft && icon && (
                <FontAwesomeIcon
                  className={classNames(classes.icon, classes.iconLeft)}
                  icon={icon}
                  size="xs"
                />
              )}
              <Text
                className={classNames(classes.menuText, {
                  [classes.selected]: selectedItem === key,
                })}
                text={toString(name)}
              />
              {!iconsLeft && icon && (
                <FontAwesomeIcon
                  className={classes.icon}
                  icon={icon}
                  size="xs"
                />
              )}
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </IonPopover>
  );
};

export default DropDown;
