import React from 'react';
import classNames from 'classnames';
import type { IonLoading } from '@ionic/react';
import { IonBackdrop, IonRow, IonSpinner } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import Text from 'components/Text/Text';
import classes from './Spinner.module.scss';

interface SpinnerProps {
  text?: string;
  testid: string;
  showSpinner?: boolean;
  showBackdrop?: boolean;
}

const Spinner = ({
  className,
  showBackdrop = true,
  text = '',
  testid,
  showSpinner = false,
}: SpinnerProps &
  React.ComponentProps<typeof IonLoading> &
  IonicReactProps): JSX.Element | null => {
  return showSpinner ? (
    <div
      className={classNames(classes.container, className)}
      data-testid={testid}
    >
      {showBackdrop && <IonBackdrop visible />}
      <IonRow className={classes.spinner}>
        <IonSpinner
          class={classNames('ion-justify-content-center', classes.spinnerClass)}
          name="lines-sharp-small"
        />
        <Text
          className={classes.updateLoaderText}
          text={text}
          variant="mipro-h4-headline"
          testid="spinner-text"
        />
      </IonRow>
    </div>
  ) : null;
};

export default Spinner;
