import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonImg } from '@ionic/react';
import useGetAttachment from 'api/attachments/useGetAttachment';
import type { FileAttachmentProp, MiProFile } from 'models/Attachment';
import { getAttachmentProps, iconFinder } from 'utils/filesUpload';
import classes from './Attachments.module.scss';

interface ImageBlobProps {
  domain?: string;
  file: FileAttachmentProp;
  width?: number;
  height?: number;
  isImage?: boolean;
  prefetch?: boolean;
  fileType?: string;
  onImageClick?: (image: FileAttachmentProp) => void;
}
const AttachmentThumbnail = ({
  domain,
  file,
  width = 208,
  height = 208,
  onImageClick,
  isImage = true,
  prefetch = false,
  fileType = '',
}: ImageBlobProps): JSX.Element => {
  const imageProps = isImage ? { width, height } : {};

  const { data } = useGetAttachment({
    ...getAttachmentProps({ file: file as MiProFile, domain }),
    ...imageProps,
    enabled: isImage || prefetch,
  });

  return isImage ? (
    <IonImg
      src={file.forUpload ? file.src : data}
      onClick={() => onImageClick?.(file)}
    />
  ) : (
    <FontAwesomeIcon
      className={classes.fileImg}
      icon={iconFinder(fileType)}
      onClick={() => onImageClick?.(file)}
    />
  );
};

export default AttachmentThumbnail;
