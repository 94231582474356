export const date2020Jan01 = (): number => 1577836800;
export const date2020Jan03 = (): number => 1578009600;
export const date2020Jan04 = (): number => 1578096000;
export const date2020Jan05 = (): number => 1578182400;
export const date2020Jan06 = (): number => 1578268800;
export const date2021Jun15 = (): number => 1623708000;
export const date2023Apr13Sunday = (): number => 1682868000;

export const pageSize = (): number => 25;
export const downloadPageSize = (): number => 5000;
export const maxNumericValue = 999999999.99;

// mipro tabs
export const HOME = 'HOME';
export const ACTIVITY = 'ACTIVITY';
export const SEARCH = 'SEARCH';
export const REPORTS = 'REPORTS';
export const SALES_PLAYS = 'SALES_PLAYS';
// miproservices tabs
export const WORKORDERS = 'WORK ORDERS';
export const DOCUMENTS = 'DOCUMENTS';
export const REPLENISHMENTS = 'STOREROOM: REPLENISHMENTS';
export const ISSUE_PROCESSING = 'STOREROOM: ISSUE PROCESSING';

export const APP_NAME = 'Mi Pro';
export const CORP_ACCOUNT_SUFFIX = '00';
export const connectionStatusToastId = 'connection-status-toast';
