import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { UnbilledFieldsEnum } from 'models/Reports';
import { selectIsTeamLocation } from 'store/user/selectors';
import ChangeLocation from 'components/ChangeLocation/ChangeLocation';
import classes from './DrillDown.module.scss';
import TableDrillDown from './TableDrillDown';

export const UnbilledDrillDownFields = [
  {
    key: UnbilledFieldsEnum.totalPossibleAmt,
    id: 'totalPossibleAmt',
  },
  {
    key: UnbilledFieldsEnum.unbilledDirectsCnt,
    id: 'unbilledDirectsCnt',
  },
  {
    key: UnbilledFieldsEnum.unbilledDirectsLineCnt,
    id: 'unbilledDirectsLineCnt',
  },
  {
    key: UnbilledFieldsEnum.unbilledDirectsAmt,
    id: 'unbilledDirectsAmt',
  },
  {
    key: UnbilledFieldsEnum.unreleasedSummaryBillCnt,
    id: 'unreleasedSummaryBillCnt',
  },
  {
    key: UnbilledFieldsEnum.unreleasedSummaryBillAmt,
    id: 'unreleasedSummaryBillAmt',
  },
  {
    key: UnbilledFieldsEnum.unapprovedVendorDirectsCnt,
    id: 'unapprovedVendorDirectsCnt',
  },
  {
    key: UnbilledFieldsEnum.unapprovedVendorDirectsLineCnt,
    id: 'unapprovedVendorDirectsLineCnt',
  },

  {
    key: UnbilledFieldsEnum.unapprovedVendorDirectsAmt,
    id: 'unapprovedVendorDirectsAmt',
  },
  {
    key: UnbilledFieldsEnum.otherOrdersReadyToShipCnt,
    id: 'otherOrdersReadyToShipCnt',
  },
  {
    key: UnbilledFieldsEnum.otherOrdersReadyToShipAmt,
    id: 'otherOrdersReadyToShipAmt',
  },
];

const UnbilledDrillDown = (
  props: IonicReactProps &
    Omit<
      React.ComponentProps<typeof TableDrillDown>,
      'headerText' | 'fields' | 'customHeader'
    >
): JSX.Element => {
  const { t } = useTranslation();
  const isTeam = useSelector(selectIsTeamLocation);

  if (isTeam) {
    return (
      <ChangeLocation
        onlineMsg={t('reports:unbilledReportError')}
        testid="unbilled"
      />
    );
  }

  return (
    <TableDrillDown
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      thClassName={classes.unbilledTH}
      tdClassName={classes.unbilledTD}
      fields={UnbilledDrillDownFields}
      headerText={t('reports:unbilled')}
    />
  );
};

export default UnbilledDrillDown;
