import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import type { Dictionary } from 'lodash';
import {
  isNil,
  map,
  values,
  toString,
  keys,
  filter,
  toInteger,
  size,
  toNumber,
  isEmpty,
  set,
  forEach,
  get,
  reduce,
  includes,
  concat,
  split,
  orderBy,
} from 'lodash';
import { IonContent, IonLoading, IonPage } from '@ionic/react';
import useCreateReport from 'DocumentsApp/api/useCreateReport';
import useFindReports from 'DocumentsApp/api/useFindReports';
import useGetReport from 'DocumentsApp/api/useGetReport';
import useGetTemplate from 'DocumentsApp/api/useGetTemplate';
import type { UpdateSectionBody } from 'DocumentsApp/api/useUpdateSection';
import useUpdateSection from 'DocumentsApp/api/useUpdateSection';
import { documentListConfig } from 'DocumentsApp/api/useUpdateValues';
import { documentDetailsURL, documentsURL } from 'navigation';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import { useToasts } from 'providers/ToastProvider';
import { useDebounce } from 'use-debounce';
import useAccessControls, { AccessControlType } from 'hooks/useAccessControls';
import useGoBack from 'hooks/useGoBack';
import {
  GENERIC_TYPE,
  type GroupInput,
  type TemplateGroup,
  type TemplateImage,
  type TemplateSection,
} from 'models/DocumentTemplate';
import type {
  DocumentDetailsURLParams,
  InspectionReport,
  ReportImage,
  ReportValue,
  SignatureDBItem,
} from 'models/InspectionReport';
import { ToastType } from 'models/Toast';
import { downloadFile } from 'utils/helpers';
import { findIcon } from 'utils/icons';
import { concatRoutes } from 'utils/navigations';
import type { CoverPageForm } from 'components/Documents/DocumentCoverPage/DocumentCoverPage';
import DocumentCoverPage from 'components/Documents/DocumentCoverPage/DocumentCoverPage';
import { hasListData } from 'components/Documents/DocumentRecursiveContainer/DocumentRecursiveContainer';
import DocumentSection from 'components/Documents/DocumentSection/DocumentSection';
import Header from 'components/Header/Header';
import Loader from 'components/Loader/Loader';
import ConfirmDialog from 'components/Modals/ConfirmDialog/ConfirmDialog';
import OptionsListModal from 'components/Modals/OptionsListModal/OptionsListModal';
import OfflineInfo from 'components/OfflineInfo/OfflineInfo';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import { isCoverPageValid } from './CreateDocument';
import styles from './DocumentDetail.module.scss';

const enableAutosave = true;

const DocumentDetail = (): JSX.Element => {
  const history = useHistory();
  const { isOnline } = useNetworkStatus();
  const { reportId } = useParams<DocumentDetailsURLParams>();
  const { goBack } = useGoBack();
  const { addToast } = useToasts();

  const reportDataResponse = useGetReport({ reportId });
  const reportData = reportDataResponse.data;

  const templateId = toString(reportData?.templateId);
  const templateResponse = useGetTemplate({ templateId });

  const { status: updateCoverPageStatus, onCreateReport } = useCreateReport({
    isUpdate: true,
  });
  const { status: updateSectionStatus, onUpdateSection } = useUpdateSection({
    reportId,
  });
  const { onCreateReport: onCreateWithAutoSave } = useCreateReport({
    isUpdate: true,
  });
  const { onUpdateSection: onUpdateWithAutoSave } = useUpdateSection({
    reportId,
  });
  const {
    loadingAPI: isUploadLoading,
    uploadReport,
    discardOffline,
  } = useFindReports({ enabled: false });
  const [stateReportData, setStateReportData] = useState(reportData);
  const [coverImage, setCoverImage] = useState<ReportImage>();
  const [coverImageForUpload, setCoverImageForUpload] = useState<ReportImage>();
  const [coverImageForRemoval, setCoverImageForRemoval] =
    useState<ReportImage>();
  const [savedImages, setSavedImages] = useState<ReportImage[]>([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [reportName, setReportName] = useState('DOCUMENT DETAILS');
  const [disabledByStatus, setDisabledByStatus] = useState(
    reportData?.coverPage.status === 'CL'
  );
  const [tempTriggerAutoSave, setTriggerAutoSave] = useState(0);
  const [tempCoverPageAutoSave, setCoverPageAutoSave] = useState(0);
  const [triggerAutoSave] = useDebounce(tempTriggerAutoSave, 500);
  const [coverPageAutoSave] = useDebounce(tempCoverPageAutoSave, 500);

  useEffect(() => {
    if (reportData && !dataLoaded) {
      setStateReportData(reportData);
      setDataLoaded(true);
    }
  }, [dataLoaded, reportData]);

  useEffect(() => {
    setStateReportData(reportData);
    setReportName(stateReportData?.coverPage.name as string);
    setDisabledByStatus(reportData?.coverPage.status === 'CL');
  }, [reportData, stateReportData?.coverPage.name]);

  useEffect(() => {
    setCoverImage(undefined);
    if (reportData?.reportImages) {
      map(reportData.reportImages, (img: ReportImage) => {
        if (img.primaryImage === 'Y') {
          setCoverImage(img);
        }
      });
    }
  }, [reportData?.reportImages]);

  const sections: TemplateSection[] = values(
    templateResponse?.data?.templateSections
      ? templateResponse?.data?.templateSections
      : null
  );

  const templateImages: TemplateImage[] = values(
    templateResponse?.data?.templateImages
      ? templateResponse?.data?.templateImages
      : null
  );

  // #region init form
  const initCoverPage: CoverPageForm = {
    data: {
      // reportType: templateId,
      reportType: '',
      documentTitle: '',
      startDate: '',
      endDate: '',
      status: '',
      shop: '',
      woNo: '',
      ocn: '',
      branch: '',
      customer: '',
      customerNo: '',
      customerName: '',
      orderLineNo: '',
      machineId: '',
      siteId: '',
      templateVersion: '',
      custMachineId: '',
      machineText: '',
      siteText: '',
      customerContact: '',
      customerContactPhone: '',
      creationUserId: '',
      creationUserName: '',
      creationTmstmp: '',
      lastUpdUserId: '',
      lastUpdUserName: '',
      lastUpdTmstmp: '',
    },
  };

  const [coverPageData, setCoverPageData] = useState(initCoverPage);
  const [coverPageDirty, setCoverPageDirty] = useState(false);
  const [requiredInputs, setRequiredInputs] =
    useState<Record<number, GroupInput>>();

  useEffect(() => {
    const requiredData: Record<number, GroupInput> = {};
    map(
      templateResponse?.data?.templateSections,
      (section: TemplateSection) => {
        map(section.templateGroups, (group: TemplateGroup) => {
          map(group.templateGroupInputs, (groupInput: GroupInput) => {
            if (groupInput.required === 'Y') {
              requiredData[groupInput.inputId] = groupInput;
            }
          });
        });
      }
    );
    setRequiredInputs(requiredData);
  }, [templateResponse?.data?.templateSections, reportId]);

  useEffect(() => {
    const reportValues: CoverPageForm = {
      data: {
        reportType: toString(stateReportData?.coverPage.reportType),
        documentTitle: toString(stateReportData?.coverPage.name),
        startDate: toString(stateReportData?.coverPage.startDate),
        endDate: toString(stateReportData?.coverPage.endDate),
        status: toString(stateReportData?.coverPage.status),
        shop: toString(stateReportData?.coverPage.shopLoc),
        woNo: toString(stateReportData?.coverPage.woCtlNo),
        ocn: toString(stateReportData?.coverPage.orderCtlNo),
        branch: toString(stateReportData?.coverPage.miLoc),
        customer: toString(
          stateReportData?.coverPage.customerName ||
            stateReportData?.coverPage.customerNo
        ),
        customerNo: toString(stateReportData?.coverPage.customerNo),
        customerName: toString(stateReportData?.coverPage.customerName),
        orderLineNo: toString(stateReportData?.coverPage.orderLineNo),
        machineId: toString(stateReportData?.coverPage.machineId),
        siteId: toString(stateReportData?.coverPage.siteId),
        templateVersion: toString(stateReportData?.coverPage.templateVersion),
        templateId: toString(stateReportData?.coverPage.templateId),
        custMachineId: toString(stateReportData?.coverPage.custMachineId),
        machineText: toString(stateReportData?.coverPage.machineText),
        siteText: toString(stateReportData?.coverPage.siteText),
        customerContact: toString(stateReportData?.coverPage.customerContact),
        customerContactPhone: toString(
          stateReportData?.coverPage.customerContactPhone
        ),
        creationUserId: toString(stateReportData?.coverPage.creationUserId),
        creationUserName: toString(stateReportData?.coverPage.creationUserName),
        creationTmstmp: toString(stateReportData?.coverPage.creationTmstmp),
        lastUpdUserId: toString(stateReportData?.coverPage.lastUpdUserId),
        lastUpdUserName: toString(stateReportData?.coverPage.lastUpdUserName),
        lastUpdTmstmp: toString(stateReportData?.coverPage.lastUpdTmstmp),
        hasValidSignature:
          stateReportData?.coverPage.hasValidSignature || false,
      },
    };
    setCoverPageData(reportValues);
  }, [stateReportData?.coverPage]);

  const isFormValid = (newData: CoverPageForm) => {
    let isValidForm = true;
    if (newData.data.status === 'CL') {
      map(values(requiredInputs), (requiredInput: GroupInput) => {
        map(stateReportData?.reportValues, (reportValue: ReportValue) => {
          if (
            requiredInput.required === 'Y' &&
            requiredInput.inputId === reportValue.inputId &&
            reportValue.inputValue.length === 0
          ) {
            isValidForm = false;
          }
        });
      });
    }
    return isValidForm;
  };

  const checkSectionData = (
    typeToTest: 'CN' | 'IM' | 'RC' | 'GN',
    section: TemplateSection
  ) => {
    let hasData = false;
    if (typeToTest === 'CN' || includes(['RC', GENERIC_TYPE], typeToTest)) {
      const listType = toString(
        get(
          section,
          `templateGroups.1.templateGroupInputs.0.additionalInformation`
        )
      );
      if (listType) {
        hasData =
          size(get(stateReportData, `${listType}List`) as unknown[]) > 0;
      } else {
        const reportValues = stateReportData?.reportValues as ReportValue[];
        map(reportValues, (value: ReportValue) => {
          map(section.templateGroups, (group: TemplateGroup) => {
            if (
              value.groupId === group.groupId &&
              value.inputValue.length > 0
            ) {
              hasData = true;
            }
          });
        });
      }
    } else if (typeToTest === 'IM') {
      const reportImages = stateReportData?.reportImages as ReportImage[];
      map(reportImages, (image: ReportImage) => {
        map(section.templateGroups, (group: TemplateGroup) => {
          if (image.groupId === group.groupId) {
            hasData = true;
          }
        });
      });
    }
    return hasData;
  };

  const populateAccordionIndexes = () => {
    const bools: boolean[] = [];
    map(sections, () => {
      bools.push(false);
    });
    return bools;
  };

  const populateSectionDataStates = () => {
    const innerDataState: boolean[] = [];
    map(sections, (section: TemplateSection) => {
      innerDataState.push(checkSectionData('CN', section));
      innerDataState.push(checkSectionData('RC', section));
      innerDataState.push(checkSectionData(GENERIC_TYPE, section));
    });
    return innerDataState;
  };

  const populateSectionImageStates = () => {
    const innerImageState: boolean[] = [];
    map(sections, (section: TemplateSection) => {
      innerImageState.push(checkSectionData('IM', section));
    });
    return innerImageState;
  };

  const [isCoverPageExpanded, setIsCoverPageExpanded] = useState(false);
  const [startingSaveSection, setStartingSaveSection] = useState(false);
  const [sectionDataStates, setSectionDataStates] = useState<boolean[]>(
    populateSectionDataStates()
  );
  const [sectionImageStates, setSectionImageStates] = useState<boolean[]>(
    populateSectionImageStates()
  );
  const [accordionIndexes, setAccordionIndexes] = useState<boolean[]>(
    populateAccordionIndexes()
  );
  const [sectionLoadingStates, setSectionLoadingStates] = useState<boolean[]>(
    map(sections, () => false)
  );

  const onRefreshSections: () => number = () => {
    const newContainsData: boolean[] = [];
    const newContainsImages: boolean[] = [];
    // map causes odd issues on update here
    sections.forEach((section) => {
      newContainsData.push(checkSectionData('CN', section));
      newContainsData.push(checkSectionData('RC', section));
      newContainsData.push(checkSectionData(GENERIC_TYPE, section));
      newContainsImages.push(checkSectionData('IM', section));
    });
    setSectionDataStates(newContainsData);
    setSectionImageStates(newContainsImages);
    return newContainsData.length;
  };

  const onSetContainsImages = (
    indexToUpdate: number,
    containsImages: boolean
  ) => {
    const newBools: boolean[] = [];
    map(sections, (section: TemplateSection, index) => {
      if (index === indexToUpdate) {
        newBools.push(containsImages);
      } else {
        newBools.push(sectionImageStates[index]);
      }
    });
    setSectionImageStates(newBools);
  };

  const onSetContainsData = (indexToUpdate: number, containsData: boolean) => {
    const newBools: boolean[] = [];
    map(sections, (section, index) => {
      if (index === indexToUpdate) {
        newBools.push(containsData);
      } else {
        newBools.push(sectionDataStates[index]);
      }
    });
    setSectionDataStates(newBools);
  };

  const checkDataLength = () => {
    let bool = false;
    if (sectionDataStates.length > 0) {
      bool = true;
    } else {
      onRefreshSections();
      bool = true;
    }
    return bool;
  };

  useEffect(() => {
    onRefreshSections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateReportData?.reportImages]);
  // #endregion

  // #region save images
  const [imagesForUpload, setImagesForUpload] = useState<
    Record<number, ReportImage[]>
  >({});
  const [imagesForRemoval, setImagesForRemoval] = useState<
    Record<number, ReportImage[]>
  >({});
  const [flattenedImagesForUpload, setFlattenedImagesForUpload] = useState<
    ReportImage[]
  >([]);
  const [flattenedImagesForRemoval, setFlattenedImagesForRemoval] = useState<
    ReportImage[]
  >([]);

  const contentRef = useRef<HTMLIonContentElement | null>(null);

  const filterSavedImages = (images: Record<number, ReportImage[]>) => {
    if (size(savedImages) > 0) {
      let flattenedImgsForRemoval: ReportImage[] = [];
      const changedImageGroupsForRemoval = keys(images);
      map(changedImageGroupsForRemoval, (groupId: number) => {
        flattenedImgsForRemoval = flattenedImgsForRemoval.concat(
          images[groupId]
        );
      });
      const newSavedImages = filter(savedImages, (curImage: ReportImage) => {
        if (flattenedImgsForRemoval.length > 0) {
          let isInSavedImages = false;
          map(flattenedImgsForRemoval, (imgForRemoval: ReportImage) => {
            if (
              curImage.imageId === imgForRemoval.imageId &&
              curImage.imageId !== 0
            ) {
              isInSavedImages = true;
            }
          });
          if (isInSavedImages) {
            return false;
          }
        }
        return true;
      });
      setSavedImages(newSavedImages);
    }
  };

  const saveImages = () => {
    const updatedImages: UpdateSectionBody = {};
    const changedImageGroupsForUpload = keys(imagesForUpload);
    let newFlattenedImagesForUpload: ReportImage[] = [];
    map(changedImageGroupsForUpload, (groupId: number) => {
      newFlattenedImagesForUpload = newFlattenedImagesForUpload.concat(
        imagesForUpload[groupId]
      );
    });
    if (size(newFlattenedImagesForUpload) > 0) {
      updatedImages.updatedImages = newFlattenedImagesForUpload;
      setFlattenedImagesForUpload(newFlattenedImagesForUpload);
    }
    const changedImageGroupsForRemoval = keys(imagesForRemoval);
    let newFlattenedImagesForRemoval: ReportImage[] = [];
    map(changedImageGroupsForRemoval, (groupId: number) => {
      newFlattenedImagesForRemoval = newFlattenedImagesForRemoval.concat(
        imagesForRemoval[groupId]
      );
    });
    if (size(newFlattenedImagesForRemoval) > 0) {
      updatedImages.removedImages = map(
        newFlattenedImagesForRemoval,
        (img: ReportImage) => img.imageId
      );
      setFlattenedImagesForRemoval(newFlattenedImagesForRemoval);
    }
    return updatedImages;
  };
  // #endregion

  // #region save document lists
  const initDocumentList: Dictionary<() => object> = useMemo(
    () => ({
      parts: () => {
        const itemId = -1 * Date.now();
        return {
          // TODO generalize this
          partId: {
            attributeName: 'partId',
            displayName: 'Part Id',
            value: itemId,
            hidden: true,
          },
          itemId: {
            attributeName: 'itemId',
            value: itemId,
            hidden: true,
          },
          itemIndex: {
            attributeName: 'itemIndex',
            displayName: 'Part Index',
            value: 1,
            hidden: true,
          },
          reportId: {
            attributeName: 'reportId',
            displayName: 'Report Id',
            value: reportId,
            hidden: true,
          },
          sectionId: {
            attributeName: 'sectionId',
            displayName: 'Section Id',
            value: 0,
            hidden: true,
          },
          quantity: {
            attributeName: 'quantity',
            displayName: 'Quantity',
            value: 1,
            hidden: false,
          },
          description: {
            attributeName: 'description',
            displayName: 'Description',
            value: '',
            hidden: false,
            type: 'textArea',
          },
          mfrCtlNo: {
            attributeName: 'mfrCtlNo',
            displayName: 'Manufacturer Control Number',
            value: '',
            hidden: true,
          },
          groupSerial: {
            attributeName: 'groupSerial',
            displayName: 'Group Serial Number',
            value: '',
            hidden: true,
          },
          itemNo: {
            attributeName: 'itemNo',
            displayName: 'Item Number',
            value: '',
            hidden: true,
          },
          custStockNo: {
            attributeName: 'custStockNo',
            displayName: 'Customer Stuck Number',
            value: '',
            hidden: true,
          },
          mfgPartNo: {
            attributeName: 'mfgPartNo',
            displayName: 'Manufacturer Part Number',
            value: '',
            hidden: true,
          },
        };
      },
      vasCodes: () => {
        const itemId = -1 * Date.now();
        return {
          // TODO generalize this
          vasId: {
            attributeName: 'vasId',
            displayName: 'VAS Id',
            value: itemId,
            hidden: true,
          },
          itemId: {
            attributeName: 'itemId',
            value: itemId,
            hidden: true,
          },
          itemIndex: {
            attributeName: 'itemIndex',
            displayName: 'VAS Index',
            value: 1,
            hidden: true,
          },
          reportId: {
            attributeName: 'reportId',
            displayName: 'Report Id',
            value: reportId,
            hidden: true,
          },
          sectionId: {
            attributeName: 'sectionId',
            displayName: 'Section Id',
            value: 0,
            hidden: true,
          },
        };
      },
      genericSections: () => {
        const itemId = -1 * Date.now();
        return {
          // TODO generalize this
          genericSectionId: {
            attributeName: 'genericSectionId',
            displayName: 'Generic Section Id',
            value: itemId,
            hidden: true,
          },
          itemId: {
            attributeName: 'itemId',
            value: itemId,
            hidden: true,
          },
          itemIndex: {
            attributeName: 'itemIndex',
            displayName: 'Generic Section Index',
            value: 1,
            hidden: true,
          },
          reportId: {
            attributeName: 'reportId',
            displayName: 'Report Id',
            value: reportId,
            hidden: true,
          },
          sectionId: {
            attributeName: 'sectionId',
            displayName: 'Section Id',
            value: 0,
            hidden: true,
          },
        };
      },
    }),
    [reportId]
  );
  const initIdsToDelete = useMemo(
    () => ({ parts: [], vasCodes: [], genericSections: [] }),
    []
  );
  const [documentListIdsToDelete, setDocumentListIdsToDelete] =
    useState<Dictionary<number[]>>(initIdsToDelete);
  const [documentListData, setDocumentListData] = useState<Dictionary<object>>({
    parts: { 1: initDocumentList.parts() },
    vasCodes: { 1: initDocumentList.vasCodes() },
    genericSections: { 1: initDocumentList.genericSections() },
  });
  const [dirtyDocumentListData, setDirtyDocumentListData] =
    useState<Dictionary<object>>(initIdsToDelete);
  const [highestListDataIndex, setHighestListDataIndex] = useState<
    Dictionary<number>
  >({
    parts: 1,
    vasCodes: 1,
    genericSections: 1,
  });

  useEffect(() => {
    const doInitDocumentList = (key: string) => {
      const data = get(reportData, `${key}List`) as object[];
      if (reportId && size(data) > 0) {
        const newForm: Dictionary<unknown> = {};
        map(orderBy([...data, {}], 'itemIndex', 'desc'), (dbItem, index) => {
          const newItem = initDocumentList[key]?.();
          forEach(Object.keys(dbItem), (prop) => {
            set(newItem, `${prop}.value`, get(dbItem, prop));
            set(newItem, `${prop}.attributeName`, prop);
          });
          const itemIndex = index + 1;
          set(newItem, `itemIndex.value`, itemIndex);
          set(
            newItem,
            `itemId.value`,
            get(
              newItem,
              `${toString(get(documentListConfig, `${key}.id`))}.value`
            )
          );
          newForm[itemIndex] = newItem;
        });
        setDocumentListData((prev) => ({ ...prev, [key]: newForm }));
      }
    };
    doInitDocumentList('parts');
    doInitDocumentList('vasCodes');
    doInitDocumentList('genericSections');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reportData?.vasCodesList,
    reportData?.partsList,
    reportData?.genericSectionsList,
    reportId,
  ]);

  const addNewToDocumentList = (key: string) => () => {
    const newItem = { ...initDocumentList[key]?.() };
    let itemIndex = keys(documentListData[key]).length + 1;
    const listDataIndex = highestListDataIndex[key];
    if (itemIndex >= listDataIndex + 1) {
      setHighestListDataIndex((prev) => ({ ...prev, [key]: itemIndex }));
    } else {
      setHighestListDataIndex((prev) => ({
        ...prev,
        [key]: listDataIndex + 1,
      }));
      itemIndex = listDataIndex + 1;
    }
    set(newItem, `itemIndex.value`, itemIndex);
    setDocumentListData((prev) => ({
      ...prev,
      [key]: set({ ...documentListData[key] }, itemIndex, newItem),
    }));
  };

  const updateDocumentListValue =
    (key: string) =>
    (
      itemIndex: number,
      attributeName: string,
      value: string,
      defaultValue?: string,
      isCalc?: boolean
    ) => {
      const newListData = { ...documentListData[key] };
      const itemToUpdate = { ...get(newListData, itemIndex) } as object;
      set(itemToUpdate, `${attributeName}.value`, value);
      set(itemToUpdate, `${attributeName}.attributeName`, attributeName);
      set(itemToUpdate, `${attributeName}.defaultValue`, defaultValue);
      set(newListData, itemIndex, itemToUpdate);
      setDocumentListData((prev) => ({ ...prev, [key]: newListData }));
      if (!isCalc) {
        // track all updates in separate piece of state to save on close
        const newDirtyData = { ...dirtyDocumentListData[key] };
        set(newDirtyData, itemIndex, itemToUpdate);
        setDirtyDocumentListData((prev) => ({ ...prev, [key]: newDirtyData }));
      }
      setTriggerAutoSave(Date.now());
    };

  const deleteFromDocumentList =
    (key: string, listId: string) => (itemIndex: number) => {
      // if our itemIndex to delete is also in dirtyDocumentListData, remove it
      if (get(dirtyDocumentListData, `${key}.${itemIndex}`)) {
        const newDirtyData = {};
        map(keys(dirtyDocumentListData[key]), (dirtyDataIndex) => {
          const index = toString(dirtyDataIndex);
          if (index !== `${itemIndex}`) {
            set(
              newDirtyData,
              index,
              get(dirtyDocumentListData, `${key}.${index}`)
            );
          }
        });
        setDirtyDocumentListData((prev) => ({ ...prev, [key]: newDirtyData }));
      }
      const newListData = {};
      map(values(documentListData[key]), (item) => {
        const index = toString(get(item, `itemIndex.value`));
        const itemId = toNumber(get(item, `${listId}.value`));
        if (`${index}` !== `${itemIndex}`) {
          set(newListData, index, item);
        } else if (index === `${itemIndex}`) {
          setDocumentListIdsToDelete((prev) => ({
            ...prev,
            [key]: [...prev[key], itemId],
          }));
        }
      });
      setDocumentListData((prev) => ({ ...prev, [key]: newListData }));
      setTriggerAutoSave(Date.now());
    };

  const saveDocumentList = (key: string) => {
    const updatedDocumentList: UpdateSectionBody = {};
    const changedIndexes = keys(dirtyDocumentListData[key]);
    if (size(changedIndexes) > 0) {
      if (dirtyDocumentListData[key]) {
        const dataToUpdate = filter(
          map(values(dirtyDocumentListData[key]), (item) =>
            reduce(
              values(item),
              (prev, attribute) => {
                if (!hasListData(item)) {
                  return prev;
                }
                return {
                  ...prev,
                  [get(attribute, 'attributeName')]: toString(
                    get(attribute, 'value')
                  ),
                };
              },
              {}
            )
          ),
          (d) => !isEmpty(d)
        );
        if (size(dataToUpdate) > 0) {
          set(
            updatedDocumentList,
            `${key}Added`,
            filter(dataToUpdate, (data) => !isEmpty(data))
          );
        }
      }
    }
    if (size(documentListIdsToDelete[key])) {
      set(updatedDocumentList, `${key}Removed`, documentListIdsToDelete[key]);
    }
    return updatedDocumentList;
  };
  // #endregion

  // #region save signature
  const initSignature = (): SignatureDBItem => ({
    name: '',
    image: '',
    inputId: 0,
    reportId: 0,
  });

  const [reportSignature, setReportSignature] = useState<SignatureDBItem>(
    initSignature()
  );
  const [dirtyReportSignature, setDirtyReportSignture] =
    useState<SignatureDBItem>();

  useEffect(() => {
    const data = reportData?.reportSignature;
    if (data) {
      setReportSignature(data);
    } else {
      setReportSignature(initSignature());
    }
  }, [reportData]);

  const createSignature = (name: string, image: string, inputId: number) => {
    setDirtyReportSignture({
      name,
      image,
      inputId,
      reportId: toInteger(reportId),
    });
  };
  // #endregion

  // #region save section
  const [coverPageLoadingState, setCoverPageLoadingState] = useState(false);
  const [coverPageToFlip, setCoverPageToFlip] = useState(false);
  const [sectionIndexToFlip, setSectionIndexToFlip] = useState<number>();
  const [subSectionIndexToFlip, setSubSectionIndexToFlip] = useState<string>();
  const [shouldGoBack, setShouldGoBack] = useState(false);

  const coverPageIsValid = (showToast = true) => {
    if (!isCoverPageValid(coverPageData)) {
      if (
        showToast &&
        coverPageData.data.status === 'CL' &&
        !((coverPageData.data.endDate as string).length > 0)
      ) {
        addToast({
          type: ToastType.error,
          text: 'Report cannot be marked Complete without Completion Date of Inspection',
          testid: 'save-report-error-toast',
        });
      }
      return false;
    }
    return true;
  };

  const prepareCoverPageData = () => {
    const coverPage = {
      reportId,
      templateId,
      name: toString(coverPageData.data.documentTitle),
      status: toString(coverPageData.data.status),
      startDate: toString(
        coverPageData.data.startDate
          ? new Date(toString(coverPageData.data.startDate)).toISOString()
          : ''
      ),
      endDate: toString(
        coverPageData.data.endDate
          ? new Date(toString(coverPageData.data.endDate)).toISOString()
          : ''
      ),
      miLoc: toString(coverPageData.data.branch),
      shopLoc: toString(coverPageData.data.shop),
      orderCtlNo: toString(coverPageData.data.ocn),
      woCtlNo: toString(coverPageData.data.woNo),
      customerNo: toString(coverPageData.data.customerNo),
      customerName: toString(coverPageData.data.customerName),
      reportType: toString(coverPageData.data.reportType),
      orderLineNo: toInteger(coverPageData.data.orderLineNo),
      machineId: toInteger(coverPageData.data.machineId),
      siteId: toInteger(coverPageData.data.siteId),
      templateVersion: toInteger(coverPageData.data.templateVersion),
      custMachineId: toString(coverPageData.data.custMachineId),
      machineText: toString(coverPageData.data.machineText),
      siteText: toString(coverPageData.data.siteText),
      customerContact: toString(coverPageData.data.customerContact),
      customerContactPhone: toString(coverPageData.data.customerContactPhone),
      creationUserId: toString(coverPageData.data.creationUserId),
      creationUserName: toString(coverPageData.data.creationUserName),
      creationTmstmp: toString(coverPageData.data.creationTmstmp),
      lastUpdUserId: toString(coverPageData.data.lastUpdTmstmp),
      lastUpdUserName: toString(coverPageData.data.lastUpdUserName),
      lastUpdTmstmp: toString(coverPageData.data.lastUpdTmstmp),
      hasValidSignature: Boolean(coverPageData.data.hasValidSignature),
    };

    // if coverImageForRemoval - fire removal
    // if coverImageForUpdate - fire update
    // listen for update, set coverImage = returned updated image
    // (or set it equal to coverImageForUpload and splice the id in)
    // *clear coverImageForUpload
    const updatedImages: UpdateSectionBody = {};
    if (coverImageForRemoval) {
      updatedImages.removedImages = [coverImageForRemoval.imageId];
    }
    if (coverImageForUpload) {
      updatedImages.updatedImages = [coverImageForUpload];
    }
    return { coverPage, ...updatedImages };
  };

  const saveCoverPageData = () => {
    setCoverPageLoadingState(true);
    const updateCoverPageData = prepareCoverPageData();
    // This is where the backend gets called to save the coverpage data
    onCreateReport(updateCoverPageData);
    setReportName(coverPageData.data.documentTitle as string);
  };

  const [curSectionData, setCurSectionData] =
    useState<Record<number, ReportValue>>();

  // updateSectionData needs to be in the parent so data can be stored outside of "this section" scope
  const onUpdateSectionData = (newData: Record<number, ReportValue>) => {
    // if we have data in the section already
    // merge the new data with the old data by inputId
    if (!isNil(curSectionData)) {
      const mergedSectionData: Record<number, ReportValue> = {
        ...curSectionData,
      };

      map(values(newData), (newValue: ReportValue) => {
        mergedSectionData[newValue.inputId] = newValue;
      });
      setCurSectionData(mergedSectionData);
    } else {
      setCurSectionData(newData);
    }
    setTriggerAutoSave(Date.now());
  };

  const saveSection = () => {
    const updatedValues: UpdateSectionBody = {};
    const dataToSave: Record<number, ReportValue> = curSectionData || {};
    const reportValues = values(dataToSave);
    let mergedValues: ReportValue[] = [];
    // this block will do an update of report data, and merge state
    // and will then do the same for images
    // the 2nd if is for if they only edit images
    if (size(reportValues) > 0) {
      updatedValues.reportValues = reportValues;
      mergedValues = [...(stateReportData?.reportValues || [])];
      map(reportValues, (newValue) => {
        let needsPush = true;
        map(mergedValues, (oldValue, index) => {
          if (newValue.inputId === oldValue.inputId) {
            mergedValues.splice(index, 1, newValue);
            needsPush = false;
          }
        });
        if (needsPush) {
          mergedValues.push(newValue);
        }
      });
      let filteredImages: ReportImage[] = [];
      if (stateReportData?.reportImages) {
        filteredImages = [...stateReportData?.reportImages];
        // filteredImages will eventually get passed to stateDataResponse
        // we need to 1st remove edited images,
        // then remove deleted images so that if they re-open a section
        // before a refresh, it will have accurate info
        filteredImages = filter(filteredImages, (curImage: ReportImage) => {
          let isInImagesForUpload = false;
          map(flattenedImagesForUpload, (uploadImage) => {
            if (curImage.imageId === uploadImage.imageId) {
              isInImagesForUpload = true;
            }
          });
          if (isInImagesForUpload) {
            return false;
          }
          return true;
        });
        if (flattenedImagesForRemoval.length > 0) {
          filteredImages = filter(filteredImages, (curImage: ReportImage) => {
            let isDeletedImage = false;
            map(flattenedImagesForRemoval, (removalImage) => {
              if (curImage.imageId === removalImage.imageId) {
                isDeletedImage = true;
              }
            });
            if (isDeletedImage) {
              return false;
            }
            return true;
          });
        }
      }
      const mergedInspectionReport: InspectionReport = {
        coverPage: reportData?.coverPage || {},
        templateId: reportData?.templateId || 0,
        reportId: reportData?.reportId || 0,
        reportValues: mergedValues,
        reportImages: filteredImages,
      };
      setStateReportData(mergedInspectionReport);
    }
    if (size(flattenedImagesForUpload) > 0) {
      let filteredImages: ReportImage[] = [];
      if (stateReportData?.reportImages) {
        filteredImages = [...stateReportData?.reportImages];
        // if an image from the db is edited, it will be
        // in imagesForUpload, and will need to be removed
        // from stateReportData on section save
        filteredImages = filter(filteredImages, (curImage: ReportImage) => {
          let isInImagesForUpload = false;
          map(flattenedImagesForUpload, (uploadImage) => {
            if (curImage.imageId === uploadImage.imageId) {
              isInImagesForUpload = true;
            }
          });
          if (isInImagesForUpload) {
            return false;
          }
          return true;
        });
        if (size(flattenedImagesForRemoval) > 0) {
          filteredImages = filter(filteredImages, (curImage: ReportImage) => {
            let isDeletedImage = false;
            map(flattenedImagesForRemoval, (removalImage) => {
              if (curImage.imageId === removalImage.imageId) {
                isDeletedImage = true;
              }
            });
            if (isDeletedImage) {
              return false;
            }
            return true;
          });
        }
      }
      const mergedInspectionReport: InspectionReport = {
        coverPage: reportData?.coverPage || {},
        templateId: reportData?.templateId || 0,
        reportId: reportData?.reportId || 0,
        reportValues:
          mergedValues.length > 0 ? mergedValues : reportData?.reportValues,
        reportImages: filteredImages,
      };
      setStateReportData(mergedInspectionReport);
    }
    if (size(flattenedImagesForRemoval) > 0) {
      let filteredImages: ReportImage[] = [];
      if (stateReportData?.reportImages) {
        filteredImages = [...stateReportData?.reportImages];
        filteredImages = filter(filteredImages, (curImage: ReportImage) => {
          let isDeletedImage = false;
          map(flattenedImagesForRemoval, (removalImage) => {
            if (curImage.imageId === removalImage.imageId) {
              isDeletedImage = true;
            }
          });
          if (isDeletedImage) {
            return false;
          }
          return true;
        });
        const mergedInspectionReport: InspectionReport = {
          coverPage: reportData?.coverPage || {},
          templateId: reportData?.templateId || 0,
          reportId: reportData?.reportId || 0,
          reportValues:
            mergedValues.length > 0 ? mergedValues : reportData?.reportValues,
          reportImages: filteredImages,
        };
        setStateReportData(mergedInspectionReport);
      }
    }
    return updatedValues;
  };

  const [saveSectionBody, setSaveSectionBody] = useState<UpdateSectionBody>();
  const [triggerSectionSave, setTriggerSectionSave] = useState(0);

  const onSectionSave = () => {
    // potentially check each piece of state
    // and only set newLoadingStates[i] = true if there is something to save
    // clear sectionLoadingStates in useEffect for all loading statuses
    const newLoadingStates = [...sectionLoadingStates];
    map(accordionIndexes, (isExpanded: boolean, i: number) => {
      if (isExpanded) {
        newLoadingStates[i] = true;
      }
    });
    setSectionLoadingStates(newLoadingStates);
    const updatedValues = saveSection();
    const updatedPartsList = saveDocumentList('parts');
    const updatedVasCodesList = saveDocumentList('vasCodes');
    const updatedGenericSectionsList = saveDocumentList('genericSections');
    const updatedImages = saveImages();
    if (
      updatedValues.reportValues ||
      updatedPartsList.partsAdded ||
      updatedPartsList.partsRemoved ||
      updatedVasCodesList.vasCodesAdded ||
      updatedVasCodesList.vasCodesRemoved ||
      updatedGenericSectionsList.genericSectionsAdded ||
      updatedGenericSectionsList.genericSectionsRemoved ||
      updatedImages.updatedImages ||
      updatedImages.removedImages ||
      dirtyReportSignature
    ) {
      setSaveSectionBody({
        ...updatedValues,
        ...updatedPartsList,
        ...updatedVasCodesList,
        ...updatedGenericSectionsList,
        ...updatedImages,
        reportSignature: dirtyReportSignature,
      });
      setTriggerSectionSave(Date.now());
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (saveSectionBody && triggerSectionSave > 0) {
      onUpdateSection(saveSectionBody);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerSectionSave]);

  const onToggleCoverPage = (newVal: boolean) => {
    if (startingSaveSection) {
      return;
    }
    setStartingSaveSection(true);
    // if we are openning the cover page, save the section data
    if (newVal) {
      const savingSection = onSectionSave();
      if (savingSection) {
        setCoverPageToFlip(newVal);
        return;
      }
      setAccordionIndexes(map(accordionIndexes, () => false));
      setStartingSaveSection(false);
      // if we are closing the cover page, save it's data
    } else if (!newVal && isCoverPageExpanded && coverPageDirty) {
      if (coverPageIsValid()) {
        saveCoverPageData();
      } else {
        setStartingSaveSection(false);
        return;
      }
    } else {
      setStartingSaveSection(false);
    }
    // DOC: allways expand, but do not collapse until after loader
    if (!coverPageDirty) {
      setIsCoverPageExpanded(newVal);
    }
  };

  const scrollToViewById = (sectionId: string) => {
    if (contentRef.current) {
      void contentRef.current.scrollToTop();
      const section = document.getElementById(sectionId);
      if (section) {
        const { top } = section.getBoundingClientRect();
        void contentRef.current.scrollToPoint(0, top - 100, 1000);
      }
    }
  };

  const accordionHeaderFlip = (indexToFlip: number) => {
    setSectionIndexToFlip(undefined);

    // flip index, scroll to section if newly openned section
    // is below currently openned section
    const newBools: boolean[] = [];
    let sectionScrollIndex = -1;
    // map causes odd issues on update here
    sections.forEach((section, index) => {
      if (index === indexToFlip) {
        const newBool = !accordionIndexes[index];
        newBools.push(newBool);

        // if the newBool is a section that has just been opened (meaning it's value is true)
        if (newBool) {
          // if there is a section above it
          if (index - 1 >= 0) {
            // check all potential sections above to see if they were open previously
            // if one of them was, set the scroll index to the currently open section
            // so that it can be scrolled to below
            map(accordionIndexes, (oldValue, oldIndex) => {
              if (oldIndex < index && oldValue === true) {
                sectionScrollIndex = index;
              }
            });
          }
        }
      } else {
        newBools.push(false);
      }
    });
    setAccordionIndexes(newBools);

    if (sectionScrollIndex !== -1 && sectionScrollIndex !== 0) {
      scrollToViewById(`section-${sectionScrollIndex}`);
    }
  };

  const onAccordionHeaderClick = (indexToFlip: number) => {
    // save data
    if (startingSaveSection) {
      return;
    }
    setStartingSaveSection(true);
    const savingSection = onSectionSave();
    if (savingSection) {
      setSectionIndexToFlip(indexToFlip);
      return;
    }
    if (isCoverPageExpanded && coverPageDirty) {
      if (coverPageIsValid()) {
        saveCoverPageData();
        setSectionIndexToFlip(indexToFlip);
      } else {
        setStartingSaveSection(false);
      }
      return;
    }
    if (isCoverPageExpanded && !coverPageDirty) {
      setIsCoverPageExpanded(false);
    }
    accordionHeaderFlip(indexToFlip);
    setStartingSaveSection(false);
  };

  useEffect(() => {
    let isCoverPageLoading = false;
    if (updateCoverPageStatus === 'loading') {
      isCoverPageLoading = true;
    }
    if (updateCoverPageStatus === 'success') {
      setCoverPageDirty(false);
      setIsCoverPageExpanded(false);
      setCoverImageForRemoval(undefined);
      setCoverImageForUpload(undefined);
      setStartingSaveSection(false);
      if (!isNil(sectionIndexToFlip)) {
        accordionHeaderFlip(sectionIndexToFlip);
      }
      if (shouldGoBack) {
        void uploadReport({ reportId });
        goBack();
      }
    }
    setCoverPageLoadingState(isCoverPageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCoverPageStatus]);

  useEffect(() => {
    if (updateSectionStatus !== 'loading') {
      setSectionLoadingStates(map(accordionIndexes, () => false));
    }
  }, [updateSectionStatus, accordionIndexes]);

  useEffect(() => {
    if (updateSectionStatus === 'success') {
      setCurSectionData({});
      setImagesForUpload({});
      setImagesForRemoval({});
      setDirtyDocumentListData(initIdsToDelete);
      setDocumentListIdsToDelete(initIdsToDelete);
      setDirtyReportSignture(undefined);
      setAccordionIndexes(map(accordionIndexes, () => false));
      if (coverPageToFlip) {
        setIsCoverPageExpanded(true);
      }
      if (!isNil(sectionIndexToFlip)) {
        accordionHeaderFlip(sectionIndexToFlip);
      }
      if (shouldGoBack) {
        void uploadReport({ reportId });
        goBack();
      }
    }
    if (updateSectionStatus === 'error') {
      setStartingSaveSection(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSectionStatus]);

  useEffect(() => {
    if (
      isEmpty(curSectionData) &&
      isEmpty(imagesForUpload) &&
      isEmpty(documentListIdsToDelete.parts) &&
      isEmpty(documentListIdsToDelete.vasCodes) &&
      isEmpty(documentListIdsToDelete.genericSections) &&
      isEmpty(imagesForRemoval) &&
      isEmpty(dirtyDocumentListData.parts) &&
      isEmpty(dirtyDocumentListData.vasCodes) &&
      isEmpty(dirtyDocumentListData.genericSections) &&
      isEmpty(imagesForRemoval) &&
      isEmpty(dirtyReportSignature)
    ) {
      setStartingSaveSection(false);
    }
  }, [
    curSectionData,
    dirtyDocumentListData.parts,
    dirtyDocumentListData.vasCodes,
    dirtyDocumentListData.genericSections,
    dirtyReportSignature,
    documentListIdsToDelete.parts,
    documentListIdsToDelete.vasCodes,
    documentListIdsToDelete.genericSections,
    imagesForRemoval,
    imagesForUpload,
    updateSectionStatus,
  ]);

  useEffect(() => {
    if (dirtyReportSignature) {
      onSectionSave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dirtyReportSignature]);
  // #endregion

  // #region headerActions
  const documentEditPermissions = useAccessControls(
    AccessControlType.editDocuments
  );

  const [headerActionsIsOpen, setHeaderActionsIsOpen] = useState(false);
  const [confirmDiscardIsOpen, setConfirmDiscardIsOpen] = useState(false);
  const headerActions = [
    {
      key: 'discard',
      name: 'Discard Offline Changes',
      icon: findIcon('trash'),
      onClick: () => {
        setHeaderActionsIsOpen(false);
        setConfirmDiscardIsOpen(true);
      },
    },
    {
      key: 'export',
      name: 'Export Offline Document',
      icon: findIcon('download'),
      onClick: async () => {
        await downloadFile(
          `${Date.now()}-mipro-document-${reportId}.json`,
          'application/json',
          JSON.stringify({
            ...reportData,
            addedImages: map(reportData?.addedImages, (img) => ({
              ...img,
              image: undefined,
            })),
            reportImages: map(reportData?.reportImages, (img) => ({
              ...img,
              image: undefined,
            })),
          }),
          addToast
        );
      },
    },
  ];

  if (documentEditPermissions) {
    headerActions.unshift({
      key: 'upload',
      name: 'Upload',
      icon: findIcon('upload'),
      onClick: async () => {
        setHeaderActionsIsOpen(false);
        const newReportId = await uploadReport({ reportId });
        if (toNumber(reportId) < 0 && toNumber(newReportId) > 0) {
          history.replace(
            concatRoutes(
              documentsURL(),
              documentDetailsURL(toString(newReportId))
            )
          );
        }
      },
    });
  }
  // #endregion

  const onCovePageAutoSave = () => {
    const updateCoverPageData = prepareCoverPageData();
    if (isCoverPageExpanded && coverPageDirty && coverPageIsValid(false)) {
      onCreateWithAutoSave({
        ...updateCoverPageData,
        autoSave: true,
      });
    }
  };

  useEffect(() => {
    if (enableAutosave) {
      onCovePageAutoSave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coverPageAutoSave]);

  const onAutoSave = () => {
    const updatedValues = saveSection();
    const updatedPartsList = saveDocumentList('parts');
    const updatedVasCodesList = saveDocumentList('vasCodes');
    const updatedGenericSectionsList = saveDocumentList('genericSections');
    const updatedImages = saveImages();
    if (
      updatedValues.reportValues ||
      updatedPartsList.partsAdded ||
      updatedPartsList.partsRemoved ||
      updatedVasCodesList.vasCodesAdded ||
      updatedVasCodesList.vasCodesRemoved ||
      updatedGenericSectionsList.genericSectionsAdded ||
      updatedGenericSectionsList.genericSectionsRemoved ||
      updatedImages.updatedImages ||
      updatedImages.removedImages ||
      dirtyReportSignature
    ) {
      onUpdateWithAutoSave({
        ...updatedValues,
        ...updatedPartsList,
        ...updatedVasCodesList,
        ...updatedGenericSectionsList,
        ...updatedImages,
        reportSignature: dirtyReportSignature,
        autoSave: true,
      });
    }
  };

  useEffect(() => {
    if (enableAutosave) {
      onAutoSave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerAutoSave]);

  const [subSectionExpanded, setSubSectionExpanded] = useState<
    Dictionary<boolean>
  >({});
  const [subSectionIsEdit, setSubSectionIsEdit] = useState<Dictionary<boolean>>(
    {}
  );
  const getSubSectionToFlip = (sectionId: string) => {
    const [secId, subSection] = split(subSectionIndexToFlip, ':');
    return secId === sectionId ? subSection : '';
  };

  return (
    <IonPage>
      <Header
        title={reportName || 'Loading'}
        eyebrow="Documents"
        withBackButton
        testid="document-detail"
        hideHomeMenu
        showOptionsMenu={isOnline && reportData?.needSync}
        setIsOpen={setHeaderActionsIsOpen}
        customBackButtonClick={async () => {
          const savingSection = onSectionSave();
          if (savingSection) {
            setShouldGoBack(true);
            return;
          }
          if (coverPageDirty) {
            if (coverPageIsValid()) {
              saveCoverPageData();
              setShouldGoBack(true);
            }
            return;
          }
          if (reportData?.needSync) {
            await uploadReport({ reportId });
          }
          goBack();
        }}
      >
        {!isOnline && <OfflineInfo />}
        <IonLoading
          isOpen={!!isUploadLoading}
          message="Sync data in progress. This could take a couple minutes."
        />
      </Header>
      <IonContent ref={contentRef} scrollEvents>
        {!isNil(sections) &&
          // TODO optimize to only reload screen on initial load
          !(reportDataResponse.isLoading || templateResponse.isLoading) && (
            <>
              <DocumentCoverPage
                startingSaveSection={startingSaveSection}
                isLoading={coverPageLoadingState}
                isExpanded={isCoverPageExpanded}
                setIsExpanded={onToggleCoverPage}
                formData={coverPageData}
                disabled={disabledByStatus || !documentEditPermissions}
                setFormData={(newData: CoverPageForm) => {
                  setDisabledByStatus(newData.data.status === 'CL');
                  if (newData.data.status === 'CL') {
                    if (isFormValid(newData)) {
                      setCoverPageData(newData);
                      setCoverPageDirty(true);
                    } else {
                      map(values(requiredInputs), (groupInput: GroupInput) => {
                        addToast({
                          type: ToastType.error,
                          text: `input ${groupInput.label} is missing`,
                          testid: 'input-report-error-toast',
                        });
                      });
                    }
                  } else {
                    setCoverPageData(newData);
                    setCoverPageDirty(true);
                  }
                  setCoverPageAutoSave(Date.now());
                }}
                reportId={stateReportData?.reportId}
                coverImage={coverImage}
                setCoverImage={setCoverImage}
                coverImageForUpload={coverImageForUpload}
                setCoverImageForUpload={(img: ReportImage) => {
                  setCoverImageForUpload(img);
                  setCoverPageDirty(true);
                  setCoverPageAutoSave(Date.now());
                }}
                coverImageForRemoval={coverImageForRemoval}
                setCoverImageForRemoval={(img: ReportImage) => {
                  setCoverImageForRemoval(img);
                  setCoverPageDirty(true);
                  setCoverPageAutoSave(Date.now());
                }}
              />
              {size(sections) === 0 && (
                <WarningMessage
                  className={styles.warningMessage}
                  icon={['far', 'info-circle']}
                  title="No Document Template"
                  body="Are you expecting data? Go online and download to sync with the server."
                />
              )}
              {!(reportDataResponse.isLoading && !reportData?.reportValues) && (
                <div>
                  {map(sections, (section, sectionIndex) => (
                    <div
                      id={`section-${sectionIndex}`}
                      key={`section-${sectionIndex}`}
                    >
                      {checkDataLength() && (
                        <DocumentSection
                          startingSaveSection={startingSaveSection}
                          section={section}
                          templateImages={templateImages}
                          isLoading={sectionLoadingStates[sectionIndex]}
                          isExpanded={
                            (!startingSaveSection &&
                              !!getSubSectionToFlip(
                                toString(section.sectionId)
                              )) ||
                            accordionIndexes[sectionIndex]
                          }
                          subSectionToFlip={getSubSectionToFlip(
                            toString(section.sectionId)
                          )}
                          subSectionExpanded={subSectionExpanded}
                          setSubSectionExpanded={setSubSectionExpanded}
                          subSectionIsEdit={subSectionIsEdit}
                          setSubSectionIsEdit={setSubSectionIsEdit}
                          disabled={
                            disabledByStatus ||
                            sectionLoadingStates[sectionIndex] ||
                            !documentEditPermissions
                          }
                          reportId={+reportId}
                          custNo={
                            (coverPageData.data.customerNo as string) || ''
                          }
                          miLoc={(coverPageData.data.branch as string) || ''}
                          toggleExpansion={(subSectionIndex) => {
                            onAccordionHeaderClick(sectionIndex);
                            setSubSectionIndexToFlip(
                              subSectionIndex
                                ? `${section.sectionId}:${subSectionIndex}`
                                : ''
                            );
                          }}
                          containsData={sectionDataStates[sectionIndex]}
                          setContainsData={(containsData: boolean) => {
                            onSetContainsData(sectionIndex, containsData);
                          }}
                          containsImages={sectionImageStates[sectionIndex]}
                          setContainsImages={(containsImages: boolean) => {
                            onSetContainsImages(sectionIndex, containsImages);
                          }}
                          updateSectionData={onUpdateSectionData}
                          reportValues={reportData?.reportValues}
                          needSyncValues={map(
                            reportData?.addedValues,
                            (p) => p.inputId
                          )}
                          reportImages={concat(
                            filter(
                              reportData?.reportImages,
                              (img) => toNumber(img.imageId) > 0
                            ),
                            reportData?.addedImages || []
                          )}
                          needSyncImages={map(
                            reportData?.addedImages,
                            (p) => p.imageId
                          )}
                          imagesForUpload={imagesForUpload}
                          setImagesForUpload={(images) => {
                            setImagesForUpload(images);
                            setTriggerAutoSave(Date.now());
                          }}
                          imagesForRemoval={imagesForRemoval}
                          setImagesForRemoval={(images) => {
                            filterSavedImages(images);
                            setImagesForRemoval(images);
                            setTriggerAutoSave(Date.now());
                          }}
                          reportListData={documentListData}
                          needSyncListData={{
                            parts: map(reportData?.partsAdded, (i) => i.partId),
                            vasCodes: map(reportData?.vasCodesAdded, (i) =>
                              toNumber(get(i, documentListConfig.vasCodes.id))
                            ),
                            genericSections: map(
                              reportData?.genericSectionsAdded,
                              (i) =>
                                toNumber(
                                  get(i, documentListConfig.genericSections.id)
                                )
                            ),
                          }}
                          updateListValue={updateDocumentListValue}
                          addNewToList={addNewToDocumentList}
                          deleteFromList={deleteFromDocumentList}
                          createSignature={createSignature}
                          reportSignature={reportSignature}
                        />
                      )}
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        <Loader
          className={styles.documentsLoader}
          text="Loading document details"
          isOpen={reportDataResponse.isLoading || templateResponse.isLoading}
        />
        <div>
          <OptionsListModal
            isOpen={headerActionsIsOpen}
            setIsOpen={setHeaderActionsIsOpen}
            title="Actions"
            filterOptions={headerActions}
            testid="header-actions-modal"
          />
          <ConfirmDialog
            isOpen={confirmDiscardIsOpen}
            setIsOpen={setConfirmDiscardIsOpen}
            title="Discard Offline Changes"
            text="Offline changes will be lost forever."
            primaryText="Discard"
            secondaryText="Cancel"
            onPrimaryClick={async () => {
              await discardOffline(reportId);
              if (toNumber(reportId) < 0) {
                history.replace(concatRoutes(documentsURL()));
              }
            }}
            testid="discard-modal"
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default DocumentDetail;
