import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';
import {
  capitalize,
  filter,
  isEmpty,
  map,
  some,
  toNumber,
  values,
} from 'lodash';
import { IonCol, IonContent, IonGrid, IonPage, IonRow } from '@ionic/react';
import type { Dictionary } from '@reduxjs/toolkit';
import Footer from 'common/components/Footer/Footer';
import CustomerName from 'common/components/Header/CustomerName';
import Header from 'common/components/Header/Header';
import { searchURL } from 'navigation';
import useDeleteOrderItem from 'ProductSearchApp/api/orders/useDeleteOrderItem';
import useGetOrder from 'ProductSearchApp/api/useGetOrder';
import useUpdateToCart from 'ProductSearchApp/api/useUpdateToCart';
import CartLineItem from 'ProductSearchApp/components/CartLineItem/CartLineItem';
import type { CartOrderItem } from 'ProductSearchApp/models/Order';
import type { OrderURLParams } from 'ProductSearchApp/models/Products';
import { OrderListTabEnum } from 'ProductSearchApp/pages/CustomerOrders/CustomerOrders';
import { canEditOCN } from 'ProductSearchApp/util/ocnHelpers';
import { useToasts } from 'providers/ToastProvider';
import useFindAttachments from 'api/attachments/useFindAttachments';
import useGetCustomer from 'api/customer/useGetCustomer';
import useFirstRef from 'hooks/useFirstRef';
import useShowCostDetailsDispatcher from 'hooks/useToggleCostDetailsDispatcher';
import type { RootState } from 'store/reducers';
import type { CurrentCartCustomer } from 'store/user';
import { clearCurrentCartCustomer } from 'store/user';
import { DateFormatEnum, formatDate } from 'utils/date';
import { getErrorMessage } from 'utils/helpers';
import { concatRoutes } from 'utils/navigations';
import { removeLeadingZeros } from 'utils/number';
import { handleSearchNavigation } from 'utils/search';
import {
  goToAddZCodedItem,
  goToProductSearch,
  goToReviewCart,
} from 'navigation/navigationHelpers';
import Button from 'components/Button/Button';
import CurrencyFormat from 'components/CurrencyFormat/CurrencyFormat';
import DatePickerModal from 'components/DatePickerModal/DatePickerModal';
import Loader from 'components/Loader/Loader';
import SheetModal from 'components/Modals/SheetModal/SheetModal';
import Spinner from 'components/Spinner/Spinner';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './OrderCart.module.scss';

export interface UpdatedLineItemProp {
  orderLineNo?: string;
  quantity?: string;
  originalQuantity?: string;
  expectedDate?: string;
  isSourcing?: boolean;
  isUnfinished?: boolean;
  sourcedQty?: number;
  cancelwo?: string;
  notebookText?: string;
}

export interface QuantitySetFieldProps {
  quantity: string;
  lineItemIndex: number;
  orderLineNo: string;
}

const OrderCart = (): JSX.Element => {
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const { toggleCostDetailsOption } = useShowCostDetailsDispatcher();
  const history = useHistory();
  const { addToast } = useToasts();
  const {
    miLoc,
    id: shipToCustNo,
    ocn: orderCtlNo,
  } = useParams<OrderURLParams>();
  const { currentCartCustomer = {} } = useSelector(
    (state: RootState) => state.user
  );
  const [isOpenClearCart, setIsOpenClearCart] = useState(false);
  const [removeOrder, setRemoveOrder] = useState<CurrentCartCustomer>();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [datePickerDate, setDatePickerDate] = useState<Date>(new Date());
  const [selectedOrderLineNo, setSelectedOrderLineNo] = useState<string>();
  const [triggerSubmit, setTriggerSubmit] = useState<number>();
  const [itemDate, setItemDate] = useState<Date>();
  const [focusedOrderLineNo, setFocusOrderLineNo] = useState<string>();
  const [disabled, setDisabled] = useState(false);
  const [formikError, setFormikError] = useState<Dictionary<boolean>>({});

  const updateFormikError = (orderLineNo: string, formikErr: boolean): void => {
    setFormikError((prev) => ({ ...prev, [orderLineNo]: formikErr }));
  };

  const {
    data: customerData,
    isLoading: customerIsLoading,
    error: customerError,
  } = useGetCustomer({ searchType: 'customer', miLoc, id: shipToCustNo });

  const {
    order,
    isLoading: orderIsLoading,
    error: orderError,
  } = useGetOrder({ miLoc, orderCtlNo, refetchOnEnter: true });

  const { data: attachmentsData, isLoading: attachmentsLoading } =
    useFindAttachments({
      domain: 'order',
      miLoc,
      ctlNo: orderCtlNo,
      lineNo: '0',
      enabled: !isEmpty(shipToCustNo),
    });

  const isLoading = orderIsLoading || customerIsLoading;
  const error = orderError || customerError;
  const namespace = 'productSearch:ocn';
  const zCodedNameSpace = 'productSearch:zCodedItem';

  const { t } = useTranslation();

  const { status: updateStatus, onUpdateToCart } = useUpdateToCart({});
  const firstRef = useFirstRef();

  useEffect(() => {
    if (isEmpty(currentCartCustomer) && removeOrder?.miLoc) {
      history.replace(
        concatRoutes(
          searchURL(),
          handleSearchNavigation({
            type: 'customer',
            miLoc,
            customerId: shipToCustNo,
          })
        )
      );
      setRemoveOrder(undefined);
    }
  }, [history, currentCartCustomer, miLoc, shipToCustNo, removeOrder?.miLoc]);

  const headerActions = {
    title: t(`${namespace}:manageOcn`),
    initialBreakpoint: 0.5,
    disabled,
    options: !isEmpty(orderCtlNo)
      ? [
          {
            text: t(`${namespace}:addCatalogItem`),
            href: goToProductSearch(miLoc, shipToCustNo, true),
            testid: 'add-item-button',
          },
          {
            text: t(`${zCodedNameSpace}:addZCodedItem`),
            href: goToAddZCodedItem({
              miLoc,
              shipToCustNo,
              orderCtlNo,
              reset: true,
            }),
            testid: 'add-zcoded-button',
          },
          toggleCostDetailsOption(false),
          {
            text: t(`${namespace}:clearOcn`),
            onClick: () => setIsOpenClearCart(true),
            disabled: isEmpty(shipToCustNo),
            testid: 'clear-item-button',
            className: classNames({
              [classes.clearText]: !isEmpty(shipToCustNo),
            }),
          },
        ]
      : [],
  };
  const disableButtons =
    disabled ||
    isLoading ||
    !canEditOCN(order) ||
    !!error ||
    some(values(formikError), (errorItem) => errorItem === true);
  const disableQuoteButton = order?.orderTypeCd === 'O' && order?.isSourced;
  let ocnType = OrderListTabEnum.unfinished;
  if (order?.orderTypeCd === 'O') {
    ocnType =
      order.processStatus === 'CL'
        ? OrderListTabEnum.closed
        : OrderListTabEnum.orders;
  } else if (order?.orderTypeCd === 'Q') {
    ocnType = OrderListTabEnum.quotes;
  }

  const {
    status: deleteStatus,
    onDeleteOrderItem,
    onOptimisticDeleteItem,
    rollbackDeleteItem,
  } = useDeleteOrderItem();

  useEffect(() => {
    if (deleteStatus !== 'loading') {
      setDisabled?.(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus]);

  const onDeleteItem = async (lineItem: CartOrderItem, lostSale: string) => {
    setDisabled?.(true);
    await onOptimisticDeleteItem(lineItem.orderLineNo);
    addToast({
      variant: 'mipro-toast',
      text: t(`${namespace}:deleteItemToast`, {
        orderCtlNo: removeLeadingZeros(order?.orderCtlNo),
        orderLineNo: lineItem.orderLineNo,
      }),
      button: {
        text: t('common:undo'),
        handler: async () => {
          setDisabled?.(false);
          await rollbackDeleteItem(lineItem.orderLineNo);
        },
      },
      onClose: () => {
        setRemoveOrder(currentCartCustomer);
        onDeleteOrderItem({
          deleteOption: lineItem.deleteOption,
          orderLineNo: lineItem.orderLineNo,
          lostSale,
        });
      },
      testid: 'delete-item-success-toast',
    });
  };

  let title = t(`${namespace}:ocnNo`, {
    orderCtlNo: removeLeadingZeros(orderCtlNo),
  });
  if (order?.orderTypeCd === 'O') {
    title = t(`${namespace}:ocnNoOrder`, {
      orderCtlNo: removeLeadingZeros(orderCtlNo),
    });
  }
  if (order?.orderTypeCd === 'Q') {
    title = t(`${namespace}:ocnNoQuote`, {
      orderCtlNo: removeLeadingZeros(orderCtlNo),
    });
  }

  return (
    <IonPage className={classes.cartPage} data-testid="order-cart-page">
      <Header
        customTitle={<CustomerName customerData={customerData} />}
        hideMenuButton
        headerActions={headerActions}
        testid="order-cart-header"
      />
      <IonContent>
        {/* eslint-disable-next-line no-nested-ternary */}
        {(isLoading && firstRef) || attachmentsLoading ? (
          <Loader
            className={classes.loader}
            isOpen={isLoading || attachmentsLoading}
            testid="cart-loader"
            text={
              isLoading
                ? t(`${namespace}:orderLoading`)
                : t(`${namespace}:attachmentLoading`)
            }
          />
        ) : // eslint-disable-next-line no-nested-ternary
        isEmpty(currentCartCustomer) ? (
          <WarningMessage
            className={classes.warningMessage}
            title={t(`${namespace}:emptyOcnTitle`)}
            icon={['fas', 'cart-shopping']}
            body={t(`${namespace}:emptyOcnMsg`)}
            testid="empty-customer-no"
          >
            <Button
              className={classes.searchCustBtn}
              variant="action"
              text={t('common:searchCustomer')}
              testid="search-customer"
              href={searchURL()}
            />
          </WarningMessage>
        ) : error ? (
          <WarningMessage
            className={classes.warningMessage}
            title={t(`${namespace}:errorOcn`, {
              ocn: removeLeadingZeros(orderCtlNo),
            })}
            body={getErrorMessage(error)}
            testid="error-holder"
          />
        ) : (
          <>
            <Header
              className={classes.contentHeader}
              collapse="condense"
              customTitle={
                <>
                  <IonRow className={classes.headerRow}>
                    <Text
                      text={title}
                      variant="mipro-h1-headline"
                      testid="shopping-cart-cust-title"
                    />
                    <Button
                      className={classes.addItemButton}
                      key="add-item"
                      text={t(`${namespace}:addItem`)}
                      variant="link"
                      disabled={disabled}
                      textVariant="mipro-body-copy-bold"
                      href={goToProductSearch(miLoc, shipToCustNo, true)}
                      testid="add-item"
                    />
                  </IonRow>
                  <CustomerName
                    customerData={customerData}
                    customButton={{ disabled }}
                  />
                </>
              }
              testid="quote-cart-header"
            />
            <Spinner
              className={classes.removingSpinner}
              testid="remove-line-item-loader"
              text={t('common:updating')}
              showSpinner={disabled}
            />
            <IonGrid className={classes.summaryGrid}>
              <IonRow className={classes.summaryTitle}>
                <Text
                  text={t(`${namespace}:orderSummary`)}
                  variant="title-screen-section"
                  testid="shopping-cart-cust-ocn"
                />
              </IonRow>
              <IonRow className={classes.totalRow}>
                <IonCol className={classes.estimatedCol}>
                  <Text
                    text={t(`${namespace}:estimatedTotal`)}
                    variant="list-item-subtitle"
                  />
                </IonCol>
                <IonRow>
                  <IonCol className={classes.amtCol}>
                    <IonRow>
                      <CurrencyFormat
                        className={classes.totalPrice}
                        value={toNumber(order?.totalOrderAmt)}
                        variant="mipro-h1-headline"
                        scale={2}
                        currencyType="USD"
                        testid="total-order-amt"
                      />
                    </IonRow>
                    <IonRow>
                      <Text
                        className={classes.amtOverline}
                        text={t(`${namespace}:estimatedHelpText`)}
                        variant="list-item-secondaryText"
                      />
                    </IonRow>
                  </IonCol>
                </IonRow>
              </IonRow>
            </IonGrid>
            <div>
              {map(order?.items, (item) => (
                <CartLineItem
                  key={item.orderLineNo}
                  testid={item.orderLineNo}
                  order={order}
                  lineItem={item}
                  attachments={filter(
                    attachmentsData,
                    (i) => i.LINE_NO === item.orderLineNo
                  )}
                  disabled={disabled}
                  baseUrl={url}
                  focusedOrderLineNo={focusedOrderLineNo}
                  setFocusOrderLineNo={setFocusOrderLineNo}
                  selectedOrderLineNo={selectedOrderLineNo}
                  setSelectedOrderLineNo={setSelectedOrderLineNo}
                  triggerSubmit={triggerSubmit}
                  itemDate={
                    selectedOrderLineNo === item.orderLineNo
                      ? formatDate(itemDate, DateFormatEnum.standardDate)
                      : item.promsdDelivDt
                  }
                  setDatePickerDate={setDatePickerDate}
                  setIsCalendarOpen={setIsCalendarOpen}
                  onDeleteItem={onDeleteItem}
                  updating={order?.linesUpdating?.includes(item.orderLineNo)}
                  onUpdateToCart={onUpdateToCart}
                  updateStatus={updateStatus}
                  updateFormikError={updateFormikError}
                />
              ))}
            </div>
          </>
        )}
        <SheetModal
          title={t(`${namespace}:clearOcnTitle`)}
          isOpen={isOpenClearCart}
          withRightCloseButton
          initialBreakpoint={0.5}
          testid="clear--cart--modal"
          className={classes.clearOcn}
          setIsOpen={setIsOpenClearCart}
        >
          <div className={classes.text}>
            <Text
              text={t(`${namespace}:clearOcnText`, {
                ocn: removeLeadingZeros(orderCtlNo),
                ocnType: capitalize(ocnType),
              })}
              variant="mipro-body-copy"
            />
          </div>
          <div className={classes.btnWrapper}>
            <Button
              testid="clear--cart--button"
              variant="action"
              text={t(`${namespace}:clearOcn`)}
              className={classes.btn}
              onClick={() => {
                dispatch(clearCurrentCartCustomer());
                setIsOpenClearCart(false);
                history.replace(
                  concatRoutes(
                    searchURL(),
                    handleSearchNavigation({
                      type: 'customer',
                      miLoc,
                      customerId: shipToCustNo,
                    })
                  )
                );
              }}
            />
          </div>
          <div className={classes.btnWrapper}>
            <Button
              testid="clear--cart--cancel-button"
              variant="secondary"
              text={t('common:cancel')}
              onClick={() => setIsOpenClearCart(false)}
              className={classes.btn}
            />
          </div>
        </SheetModal>
        <DatePickerModal
          isOpen={isCalendarOpen}
          setIsOpen={setIsCalendarOpen}
          title={t('productSearch:checkout:expectedDt')}
          onDone={(date) => {
            setItemDate(date);
            setTriggerSubmit(Date.now());
          }}
          minDate={new Date()}
          date={datePickerDate}
          testid="expected-date-calendar"
        />
      </IonContent>
      {!isEmpty(currentCartCustomer) && (
        <Footer
          buttons={[
            {
              text: t(`${namespace}:order`),
              testid: 'order-btn',
              variant: 'secondary',
              disabled: disableButtons,
              href: goToReviewCart({
                miLoc,
                shipToCustNo,
                orderCtlNo,
                ocnType: 'order',
                ocnMode: 'checkout',
                reset: true,
              }),
            },
            {
              text: t(`${namespace}:quote`),
              testid: 'quote-btn',
              variant: 'mipro-action',
              disabled: disableButtons || disableQuoteButton,
              href: goToReviewCart({
                miLoc,
                shipToCustNo,
                orderCtlNo,
                ocnType: 'quote',
                ocnMode: 'checkout',
                reset: true,
              }),
            },
          ]}
        />
      )}
    </IonPage>
  );
};

export default OrderCart;
