import useAPIUrl from 'api';

const useGetApiUrl = ({
  isPick12Report,
  isLocationPick12Report,
}: {
  isPick12Report: boolean;
  isLocationPick12Report: boolean;
}): ((paramsString: string) => string) => {
  const {
    getNewSalesReportAPI,
    getPick12ReportAPI,
    getLocationPick12ReportAPI,
  } = useAPIUrl();

  let apiUrl = getNewSalesReportAPI;
  if (isPick12Report) {
    apiUrl = getPick12ReportAPI;
  } else if (isLocationPick12Report) {
    apiUrl = getLocationPick12ReportAPI;
  }
  return apiUrl;
};

export default useGetApiUrl;
