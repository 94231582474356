import { useMemo } from 'react';
import type { AxiosError } from 'axios';
import { head, isEmpty, size, toString } from 'lodash';
import { useInfiniteQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import type {
  QueryFlags,
  QueryFnProps,
  QueryParamsType,
} from 'common/api/utils/useGetQueryFlags';
import useGetQueryFlags from 'common/api/utils/useGetQueryFlags';
import { useAxios } from 'providers/AxiosProvider';
import { doConcatDataPages, useKeyUserId, useMiLocOrTeamId } from 'api/helpers';
import { pageSize } from 'utils/constants';

export const findPriceHistoryQueryKey = 'find-price-history';

interface UseGetPriceHistoryProps {
  miLoc: string;
  itemNo: string;
  customerNo: string;
  enabled?: boolean;
  limit?: number;
}

export interface PriceHistoryModel {
  shipperCtlNo: string;
  orderCtlNo: string;
  creationTmstmp: string;
  customerCost: string;
  unitSellPrice: string;
  gpPct: string;
  priceInformation: string;
  orderLoc: string;
  shipToCustNo: string;
  custPoNo: string;
  custReleaseNo: string;
  orderDate: string;
  orderLineNo: string;
  mfrCtlNo: string;
  groupSerial: string;
  mfgPartNo: string;
  shipperLineNo: string;
  opQtyOrdered: string;
}

interface FindPricingHistoryAPIResponse {
  history: PriceHistoryModel[];
  totalRows: number;
}

interface PricingHistoryResponse {
  items?: PriceHistoryModel[];
  total?: number;
}

const useFindPriceHistory = ({
  miLoc,
  customerNo,
  itemNo,
  enabled: propsEnabled = true,
  limit = pageSize(),
}: UseGetPriceHistoryProps): PricingHistoryResponse & QueryFlags => {
  const { axios } = useAxios();
  const { findPriceHistoryAPI } = useAPIUrl();
  const { getURLParams } = useMiLocOrTeamId({});
  const { createQueryKey } = useKeyUserId();
  const enabled =
    !isEmpty(miLoc) && !isEmpty(itemNo) && !isEmpty(customerNo) && propsEnabled;

  const params: QueryParamsType = {
    miLoc,
    customerNo,
    itemNo,
    limit,
  };

  const doFindPriceHistory = async ({
    pageParam = 1,
    signal,
  }: QueryFnProps) => {
    const { data } = await axios.get<FindPricingHistoryAPIResponse>(
      findPriceHistoryAPI(
        toString(getURLParams({ ...params, page: pageParam }))
      ),
      { signal }
    );
    return { total: data.totalRows, items: data.history };
  };

  const response = useInfiniteQuery<PricingHistoryResponse, AxiosError>(
    createQueryKey(findPriceHistoryQueryKey, params),
    doFindPriceHistory,
    {
      enabled,
      getNextPageParam: (lastPage, pages) =>
        size(lastPage.items) < limit ? false : size(pages) + 1,
    }
  );

  const { data } = response;

  const priceHistory = useMemo(
    () =>
      doConcatDataPages<PriceHistoryModel, PricingHistoryResponse>(
        data,
        'items'
      ),
    [data]
  );

  const queryFlags = useGetQueryFlags({
    ...response,
    data: priceHistory,
    enabled,
  });

  return {
    items: priceHistory,
    total: head(data?.pages)?.total,
    ...queryFlags,
  };
};

export default useFindPriceHistory;
