import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { IonPage, IonRow, IonToolbar } from '@ionic/react';
import useGetStoreroom from 'StoreroomsApp/api/getStoreroom';
import ItemsPOUList from 'StoreroomsApp/components/ItemsPOUList/ItemsPOUList';
import useReplenishmentSync from 'StoreroomsApp/hooks/useReplenishmentSync';
import type { StoreroomSearchParams } from 'StoreroomsApp/models/Storeroom';
import { useDebounce } from 'use-debounce';
import type { RootState } from 'store/reducers';
import Header from 'components/Header/Header';
import Searchbar from 'components/Searchbar/Searchbar';
import classes from './StoreroomSearch.module.scss';

const StoreroomSearch = (): JSX.Element => {
  const { storeroom: storeroomId } = useParams<StoreroomSearchParams>();
  const { miLoc } = useSelector((state: RootState) => state.user);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryValue] = useDebounce(searchQuery, 300);

  const doSearch = (query: string) => {
    if (!isEmpty(query)) {
      return;
    }
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };

  const onSearch = (query: string) => {
    setSearchQuery(query);
    setTimeout(() => {
      void doSearch(query);
    }, 300);
  };

  const { loader, downloadButton, offlineBanner } = useReplenishmentSync();
  const { storeroom } = useGetStoreroom({ storeroomNumber: storeroomId });

  return (
    <IonPage className={classes.replenishment} data-testid="storeroom-search">
      <Header
        eyebrow={miLoc}
        title={
          storeroom
            ? `(${storeroom.storeroomNumber}) ${storeroom.storeroomName}`
            : 'Loading...'
        }
        withBackButton
        testid="storeroom-search-header"
        toolbarChildren={downloadButton}
      >
        <IonToolbar className={classes.toolbar}>
          <IonRow className={classes.searchRow}>
            <Searchbar
              className={classes.searchBar}
              inputClassName={classes.searchInput}
              value={searchQuery}
              placeholder="Search CSN..."
              setValue={setSearchQuery}
              onSearch={() => onSearch(searchQuery)}
              onClear={() => setSearchQuery('')}
              variant="light"
              testid="search-input"
            />
          </IonRow>
        </IonToolbar>
        {offlineBanner}
      </Header>
      {loader}
      <ItemsPOUList searchQuery={searchQueryValue} />
    </IonPage>
  );
};

export default StoreroomSearch;
