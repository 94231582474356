import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { filter, map } from 'lodash';
import type { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { IonRow, IonToolbar } from '@ionic/react';
import { namespaces } from 'i18n/i18n.constants';
import useAccessControls, { AccessControlType } from 'hooks/useAccessControls';
import {
  SearchResultTabIconEnum,
  SearchResultTabIconPrefixEnum,
  SearchResultTabTypeEnum,
} from 'models/Search';
import { selectIsMiLocCorpOrExec } from 'store/user/selectors';
import { getSearchResultTabLabel } from 'utils/search';
import MIIconGray from 'assets/MIIconGray.svg';
import type { FilterOption } from 'components/Filter/Filter';
import Filter from 'components/Filter/Filter';
import Header from 'components/Header/Header';
import Searchbar from 'components/Searchbar/Searchbar';
import classes from './SearchHeader.module.scss';

interface SearchHeaderProps {
  selectedTab: SearchResultTabTypeEnum;
  setSelectedTab?: (v: SearchResultTabTypeEnum) => void;
  searchQuery: string;
  setSearchQuery?: (v: string) => void;
  onSearchFocus?: () => void;
  onSearchBlur?: () => void;
  onSearch?: () => void;
  onClearSearch?: () => void;
}

const SearchHeader = ({
  selectedTab,
  setSelectedTab,
  searchQuery,
  setSearchQuery,
  onSearchFocus,
  onSearchBlur,
  onSearch,
  onClearSearch,
}: SearchHeaderProps): JSX.Element => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const selectedIconName = SearchResultTabIconEnum[selectedTab] as IconName;
  const { t } = useTranslation(namespaces.search);

  const setFilterData = (option?: FilterOption) => {
    if (!option || selectedTab === option.key) {
      setSelectedTab?.(SearchResultTabTypeEnum.all);
    } else {
      setSelectedTab?.(option?.key as SearchResultTabTypeEnum);
    }
  };

  const selectedFilters = useMemo(
    () =>
      selectedTab === SearchResultTabTypeEnum.all
        ? []
        : [
            {
              key: selectedTab,
              name: t(getSearchResultTabLabel(selectedTab)),
              icon: selectedIconName,
            } as FilterOption,
          ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedTab, selectedIconName]
  );

  const isCorpOrExec = useSelector(selectIsMiLocCorpOrExec);
  const canSearchOrders = useAccessControls(
    AccessControlType.ViewOrdersAccessControls
  );

  const options = map(
    filter(
      Object.keys(SearchResultTabTypeEnum),
      (key) =>
        key !== SearchResultTabTypeEnum.all &&
        (key !== SearchResultTabTypeEnum.corporateAccounts || isCorpOrExec) &&
        (key !== SearchResultTabTypeEnum.ocns || canSearchOrders)
    ),
    (key) => {
      const iconName = SearchResultTabIconEnum[
        key as SearchResultTabTypeEnum
      ] as IconName;
      const iconPrefix = SearchResultTabIconPrefixEnum[
        key as SearchResultTabTypeEnum
      ] as IconPrefix;
      return {
        key,
        name: t(getSearchResultTabLabel(key)),
        icon: iconName,
        iconPrefix,
        customContent:
          key === SearchResultTabTypeEnum.employees ? (
            <img
              slot="start"
              className={classes.employeesIconFilter}
              data-testid="employees-filter-icon"
              src={MIIconGray}
              alt=""
            />
          ) : null,
      };
    }
  ) as FilterOption[];

  const getCorrectPlaceHolder = (key: string): string =>
    t('searchPlaceholder', {
      areas:
        key === SearchResultTabTypeEnum.all
          ? map(options, ({ name }) => t(name)).join(', ')
          : t(getSearchResultTabLabel(key)),
    });

  return (
    <Header testid="search" title={t('common:search')} showLocationText>
      <IonToolbar className={classes.toolbar}>
        <IonRow className={classes.searchRow}>
          <Searchbar
            className={classes.searchBar}
            inputClassName={classes.searchInput}
            value={searchQuery}
            placeholder={getCorrectPlaceHolder(selectedTab)}
            setValue={setSearchQuery}
            onSearch={onSearch}
            onClear={onClearSearch}
            onFocus={onSearchFocus}
            onBlur={onSearchBlur}
            variant="dark"
            testid="search-input"
          />
        </IonRow>
        <IonRow className={classes.filterRow}>
          <Filter
            selectedItems={selectedFilters}
            setFilterData={[setFilterData]}
            filterOptions={[
              {
                title: t('searchFilter'),
                options,
              },
            ]}
            isOpen={isFilterOpen}
            setIsOpen={setIsFilterOpen}
            testid="search-filter"
            toggleOnSelection
          />
        </IonRow>
      </IonToolbar>
    </Header>
  );
};

export default SearchHeader;
