import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import type { UpdateMutationContext } from 'api/helpers';
import {
  doPromiseAPI,
  onErrorUpdate,
  onMutateUpdate,
  onSuccessMutation,
} from 'api/helpers';
import useAPIUrl from 'api/index';
import type { FileAttachmentProp } from 'models/Attachment';
import { ToastType } from 'models/Toast';
import { findAttachmentsQueryKey } from './useFindAttachments';

interface UseUploadAttachmentProps {
  domain: string;
  miLoc?: string;
  ctlNo?: string;
  lineNo?: string;
}

export interface UpdateAttachmentBody {
  fileName: string;
  seqNo: string;
  miOnly: string;
  description: string;
}

interface UseUpdateAttachmentResponse {
  updateAttachmentDetails: (body: UpdateAttachmentBody) => void;
}

const useUpdateAttachmentDetails = ({
  domain,
  miLoc = '',
  ctlNo = '',
  lineNo = '',
}: UseUploadAttachmentProps): UseUpdateAttachmentResponse => {
  const { axios } = useAxios();
  const { t } = useTranslation();
  const { updateAttachmentAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  const doUpdateAttachment = ({
    fileName,
    seqNo,
    miOnly,
    description,
  }: UpdateAttachmentBody) => {
    return doPromiseAPI(async (cancelToken) => {
      await axios.put(
        updateAttachmentAPI(domain, miLoc, ctlNo, lineNo, seqNo, fileName),
        { miOnly, description },
        { cancelToken }
      );
    });
  };

  const { mutate } = useMutation(doUpdateAttachment, {
    onMutate: async (vars) =>
      onMutateUpdate<FileAttachmentProp>({
        queryClient,
        queryKey: findAttachmentsQueryKey,
        queryKeyParams: { domain, miLoc, ctlNo },
        isArrayQuery: true,
        findPredicates: [
          { USER_FILE: vars.fileName, SEQ_NO: vars.seqNo, LINE_NO: lineNo },
        ],
        updatedItems: [{ MI_ONLY: vars.miOnly, DESCRIPTION: vars.description }],
      }),
    onSuccess: () => {
      void onSuccessMutation(queryClient, findAttachmentsQueryKey, {
        domain,
        miLoc,
        ctlNo,
      });
    },
    onError: (_error, vars, context) => {
      onErrorUpdate<FileAttachmentProp>({
        queryClient,
        context: context as UpdateMutationContext<FileAttachmentProp>[],
        isArrayQuery: true,
      });
      addToast({
        type: ToastType.error,
        text: t('common:updateAttachmentError'),
        testid: 'update-attachment-error-toast',
      });
    },
  });

  return {
    updateAttachmentDetails: (body) => mutate(body),
  };
};

export default useUpdateAttachmentDetails;
