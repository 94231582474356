import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isEmpty, map, toString } from 'lodash';
import { IonPage, IonContent, IonItem, IonRow, IonGrid } from '@ionic/react';
import useGetUserConfig from 'api/user/useGetUserConfig';
import useUpdateUserConfig from 'api/user/useUpdateUserConfig';
import useChangeAppMode from 'hooks/useChangeAppMode';
import type { RootState } from 'store/reducers';
import FlagCA from 'assets/flag_ca.svg';
import FlagMX from 'assets/flag_mx.svg';
import FlagUS from 'assets/flag_us.svg';
import Header from 'components/Header/Header';
import Loader from 'components/Loader/Loader';
import RadioButton from 'components/RadioButton/RadioButton';
import Text from 'components/Text/Text';
import classes from './CurrencySelection.module.scss';

const CurrencySelection = (): JSX.Element => {
  const { t } = useTranslation();
  const { onUpdateUserConfig } = useUpdateUserConfig({
    configType: 'currency',
  });
  const { currencyType, currencyTypeOptions } = useSelector(
    (state: RootState) => state.user
  );
  const { isMiProApp } = useChangeAppMode();
  const { isLoading, data } = useGetUserConfig({
    configType: 'currency',
    enabled: isMiProApp,
  });
  const selectedCurrency = isEmpty(data) ? currencyType : data?.currency;

  const onCurrencyChange = (value: string) => {
    onUpdateUserConfig({
      currency: value,
    });
  };

  const currencyTypeRadioOptions = map(currencyTypeOptions, (currency) => {
    let flagImg = FlagUS;
    if (currency === 'MXN') {
      flagImg = FlagMX;
    } else if (currency === 'CAD') {
      flagImg = FlagCA;
    }
    return {
      value: currency,
      displayValue: `${currency}$`,
      ariaLabel: `${currency}-currency`,
      customContent: (
        <img src={flagImg} alt="FlagUS" className={classes.flag} />
      ),
    };
  });

  return (
    <IonPage className={classes.currencySettings} data-testid="settings">
      <Header withBackButton testid="currency-selection" />
      <IonContent className={classes.content}>
        <IonItem className={classes.ionPageItem}>
          <IonGrid className={classes.ionGrid}>
            <IonRow className="ion-align-items-center">
              <Text
                className={classes.title}
                variant="title-screen-section"
                text={t('settings:selectCurrency')}
                testid="select-currency-title"
              />
            </IonRow>
            <IonRow className="ion-align-items-center">
              <Text
                className={classes.subTitle}
                variant="label-micro-unread"
                text={t('settings:setCurrency')}
                testid="select-currency-subtitle"
              />
              <Text
                className={classes.infoMsg}
                variant="content-smaller"
                text={t('settings:setCurrencyInfo')}
                testid="currency-info"
              />
            </IonRow>
          </IonGrid>
        </IonItem>

        <IonItem lines="none" className={classes.radioGroupItem}>
          <RadioButton
            options={currencyTypeRadioOptions}
            value={selectedCurrency}
            testid="choose-currency"
            onChange={(val) => onCurrencyChange(toString(val))}
            labelTextVariant="mipro-body-copy"
            isVerticalAlign
          />
        </IonItem>

        <Loader type="fullscreen" isOpen={isLoading} />
      </IonContent>
    </IonPage>
  );
};

export default CurrencySelection;
