import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IonLoading } from '@ionic/react';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import { useToasts } from 'providers/ToastProvider';
import useFindItemsPOU from 'StoreroomsApp/api/useFindItemsPOU';
import useFindScannerProfiles from 'StoreroomsApp/api/useFindScannerProfiles';
import useFindStorerooms from 'StoreroomsApp/api/useFindStorerooms';
import useAccessControls, { AccessControlType } from 'hooks/useAccessControls';
import { ToastType } from 'models/Toast';
import selectIsBranchLocation from 'store/user/selectors';
import { findIcon } from 'utils/icons';
import Button from 'components/Button/Button';
import OfflineInfo from 'components/OfflineInfo/OfflineInfo';
import classes from './UseSyncHook.module.scss';

interface UseReplenishmentSyncResponse {
  loader: JSX.Element;
  downloadButton?: JSX.Element;
  offlineBanner?: JSX.Element;
}

const useReplenishmentSync = (): UseReplenishmentSyncResponse => {
  const { addToast } = useToasts();
  const { isOnline } = useNetworkStatus();
  const isBranch = useSelector(selectIsBranchLocation);
  const ac = useAccessControls(AccessControlType.downloadItemReplenishment);
  const [isDownloading, setIsDownloading] = useState(false);

  const { sync: syncStorerooms, loadingAPI: loadingStorerooms } =
    useFindStorerooms({ enabled: false });
  const { sync: syncItemsPOU, loadingAPI: loadingItemsPOU } = useFindItemsPOU({
    enabled: false,
  });
  const { sync: syncProfiles, loadingAPI: loadingProfiles } =
    useFindScannerProfiles({ enabled: false });

  const isLoading = loadingStorerooms || loadingItemsPOU || loadingProfiles;

  const downloadData = async () => {
    try {
      setIsDownloading(true);
      await syncStorerooms();
      const totalRows = await syncItemsPOU();
      await syncProfiles();
      addToast({
        type: ToastType.success,
        text: `Downloaded ${totalRows} products.`,
        testid: 'sync-success-toast',
      });
    } catch (e) {
      // DOC: general error
    } finally {
      setIsDownloading(false);
    }
  };

  return {
    loader: (
      <IonLoading
        isOpen={isDownloading || !!isLoading}
        message="Downloading data in progress. This could take a couple minutes."
      />
    ),
    downloadButton: (
      <Button
        slot="end"
        className={classes.downloadButton}
        onClick={downloadData}
        icon={findIcon('download')}
        disabled={!ac || !isOnline || !isBranch}
        testid="download-button"
      />
    ),
    offlineBanner: isOnline ? undefined : <OfflineInfo />,
  };
};

export default useReplenishmentSync;
