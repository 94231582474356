import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { debounce, includes, toNumber, toString } from 'lodash';
import { IonRow } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { FormikInput } from 'common/components/Forms/Input/Input';
import { emptyNumber, scaleNumber } from 'common/utils/numberHelper';
import { useFormikContext } from 'formik';
import PriceOverrideLabel from 'ProductSearchApp/components/CartLineItem/PriceOverrideLabel';
import PriceOverrideReasonSelect from 'ProductSearchApp/components/FastFind/PriceOverrideReasonSelect';
import type { CartOrderItem, Order } from 'ProductSearchApp/models/Order';
import type { EditOrderLineForm } from 'ProductSearchApp/pages/EditOrderLine/EditOrderLineSchema';
import useAccessControls, { AccessControlType } from 'hooks/useAccessControls';
import type { RootState } from 'store/reducers';
import { maxNumericValue } from 'utils/constants';
import Button from 'components/Button/Button';
import CheckBox from 'components/CheckBox/CheckBox';
import CurrencyFormat from 'components/CurrencyFormat/CurrencyFormat';
import HelpModal from 'components/HelpModal/HelpModal';
import DiscardModal from 'components/Modals/DiscardModal/DiscardModal';
import Spinner from 'components/Spinner/Spinner';
import Text from 'components/Text/Text';
import classes from './PricingTab.module.scss';

interface PricingTabProps {
  order?: Order;
  lineItem?: CartOrderItem;
  isLoading?: boolean;
  onReset?: () => void;
  onAutoReprice?: () => void;
}

const PricingTab = ({
  className,
  order,
  lineItem,
  isLoading,
  onReset,
  onAutoReprice,
}: PricingTabProps & IonicReactProps): JSX.Element => {
  const { showCostDetails } = useSelector((state: RootState) => state.user);
  const { t } = useTranslation();
  const [editCost, setEditCost] = useState(false);
  const [recalculateIsOpen, setRecalculateIsOpen] = useState(false);
  const [isSendConfirmationModal, setIsSendConfirmationModal] = useState(false);

  const canResetOcnLinePrice = useAccessControls(
    AccessControlType.ResetOcnLinePrice
  );
  const disableRecalcLine1 =
    !lineItem?.isEditable &&
    (lineItem?.itemStatus !== 'PL' ||
      !canResetOcnLinePrice ||
      toNumber(lineItem?.opQtyOrdered) === 0);
  const disableRecalcLine2 =
    order?.fcFlag === 'Y' &&
    (toNumber(lineItem?.scheduleSeqNo) > 0 ||
      (lineItem?.hasTransmittedLine && lineItem.hasScheduledLines));
  const disableRecalculate =
    disableRecalcLine1 ||
    lineItem?.freightAdded === 'Y' ||
    lineItem?.itemStatus === 'PS' ||
    lineItem?.deleteFlag === 'I' ||
    disableRecalcLine2;

  const disableOverrideLine1 =
    toNumber(lineItem?.paCalcPrice) === 0 &&
    lineItem?.priceCompFlag !== 'Y' &&
    toNumber(lineItem?.unitCost) !== 0;
  const disableOverride =
    lineItem?.priceOverrideRequestUserid !== '' ||
    !!lineItem?.priceLocked ||
    disableOverrideLine1 ||
    disableRecalculate;

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    validateField,
  } = useFormikContext<EditOrderLineForm>();

  const {
    customerCost,
    originalCustomerCost,
    overridePrice,
    originalPrice,
    overrideGP,
    originalGP,
    overrideMode,
    userOverride,
    overrideReason,
    overrideReasonText,
    governmentPricing,
    sendConfirmation,
    addToAgreement,
    autoReprice,
  } = values;

  const productSourceI = lineItem?.priceSourceDesc === '(I)';
  const modifiedCost = customerCost !== originalCustomerCost;
  const showCostInput = editCost || modifiedCost;
  const disablePriceAndGp =
    isLoading || disableOverride || overrideMode || modifiedCost;
  const disableCost =
    isLoading ||
    !lineItem?.editUnitCost ||
    overrideMode ||
    (!productSourceI && overridePrice !== originalPrice) ||
    (productSourceI && overrideGP !== originalGP);

  const setOverrideMode = (newOverridePrice?: string) => {
    if (newOverridePrice !== originalPrice) {
      let newUserOverride = 'auto';
      if (
        newOverridePrice === emptyNumber ||
        (governmentPricing &&
          toNumber(newOverridePrice) > toNumber(originalPrice))
      ) {
        newUserOverride = 'error';
      }
      if (toNumber(newOverridePrice) < toNumber(originalPrice)) {
        newUserOverride = 'full';
      }
      setFieldValue('userOverride', newUserOverride);
    } else {
      setFieldValue('userOverride', 'none');
    }
  };

  const updateFromPrice = debounce((price?: string) => {
    const newPrice = toNumber(price);
    const newGP = ((newPrice - toNumber(customerCost)) / newPrice) * 100;
    const newOverridePrice = scaleNumber({ number: newPrice });
    const newOverrideGP = scaleNumber({ number: newGP });
    setOverrideMode(newOverridePrice);
    // TODO should look for a workaround instead of timeout
    setFieldTouched('overrideGP', true);
    setTimeout(() => setFieldValue('overrideGP', newOverrideGP), 0);
  }, 300);

  const updateFromGP = debounce((gp?: string) => {
    const newGP = toNumber(gp);
    const newPrice = toNumber(customerCost) / (1 - newGP / 100);
    const newOverridePrice = scaleNumber({ number: newPrice });
    setOverrideMode(newOverridePrice);
    setFieldTouched('overridePrice', true);
    setTimeout(() => setFieldValue('overridePrice', newOverridePrice), 0);
  }, 300);

  const updateFromCost = debounce((cost?: string) => {
    const price = toNumber(overridePrice);
    const gp = toNumber(overrideGP);
    const newCost = toNumber(cost);
    if (productSourceI && userOverride === 'none') {
      const newPrice = toNumber(newCost) / (1 - gp / 100);
      const newOverridePrice = scaleNumber({ number: newPrice });
      setFieldTouched('overridePrice', true);
      setTimeout(() => setFieldValue('overridePrice', newOverridePrice), 0);
    } else {
      const newGP = ((price - toNumber(newCost)) / price) * 100;
      const newOverrideGP = scaleNumber({ number: newGP });
      setFieldTouched('overrideGP', true);
      setTimeout(() => setFieldValue('overrideGP', newOverrideGP), 0);
    }
  }, 300);

  const hiddenOverride = includes(['none', 'error'], userOverride);

  return (
    <div className={classNames(classes.content, className)}>
      <Spinner
        testid="ocn-line-update-loader"
        text={t('common:updating')}
        showSpinner={isLoading}
      />
      <div className={classes.wrapper}>
        <div className={classes.formSection}>
          <Text variant="mipro-h1-headline" text="Pricing" />
          {(overrideMode || !hiddenOverride) && (
            <PriceOverrideLabel
              className={classes.priceOverrideLabel}
              text={t(
                `productSearch:ocn:${
                  // eslint-disable-next-line no-nested-ternary
                  overrideMode
                    ? 'priceOverridePending'
                    : userOverride !== 'auto'
                    ? 'priceOverrideLabel'
                    : 'autoPriceOverrideLabel'
                }`
              )}
            />
          )}
          <FormikInput
            className={classNames(classes.input, {
              [classes.priceInput]: !hiddenOverride || overrideMode,
            })}
            label={t(`productSearch:ocn:priceOverrideInput`)}
            name="overridePrice"
            setValue={updateFromPrice}
            currencyMask
            disabled={disablePriceAndGp}
            testid="price-override-input"
          />
          {(showCostDetails || overrideMode || userOverride !== 'none') && (
            <>
              <FormikInput
                className={classes.input}
                label={t(`productSearch:ocn:priceOverrideGpInput`)}
                name="overrideGP"
                setValue={updateFromGP}
                numberMask={{ min: -1 * maxNumericValue, max: 100 }}
                disabled={disablePriceAndGp}
                testid="gp-input"
              />
              {!showCostInput && (
                <>
                  <IonRow>
                    <Text
                      className={classes.input}
                      variant="content-heavy"
                      text={t(`productSearch:ocn:costInput`)}
                      testid="cost-input-row-text"
                    />
                  </IonRow>
                  <IonRow className={classes.editCostRow}>
                    <CurrencyFormat
                      value={toNumber(customerCost)}
                      variant="mipro-body-copy"
                      scale={2}
                      currencyType="USD"
                      testid="cost-input-value"
                    />
                    <Button
                      className={classes.editCostButton}
                      iconClassName={classes.editIcon}
                      variant="link"
                      textVariant="mipro-body-copy"
                      text={t('common:edit')}
                      onClick={() => setEditCost(true)}
                      rightIcon={['fas', 'pencil']}
                      disabled={disableCost}
                      testid="edit-cost-button"
                    />
                  </IonRow>
                </>
              )}
              {showCostInput && (
                <FormikInput
                  className={classes.input}
                  label={t(`productSearch:ocn:costInput`)}
                  name="customerCost"
                  currencyMask
                  setValue={updateFromCost}
                  disabled={disableCost}
                  onBlur={() => {
                    const newCost = scaleNumber({ number: customerCost });
                    if (autoReprice && newCost !== originalCustomerCost) {
                      onAutoReprice?.();
                    }
                  }}
                  customLabel={
                    <Button
                      className={classes.cancelButton}
                      textVariant="mipro-body-copy"
                      variant="link"
                      text={t('common:cancel')}
                      onClick={() => {
                        setEditCost(false);
                        setTimeout(() => {
                          setFieldValue(
                            'customerCost',
                            scaleNumber({ number: lineItem?.customerCost })
                          );
                          updateFromCost(lineItem?.customerCost);
                        }, 0);
                      }}
                      testid="cancel-cost-button"
                    />
                  }
                  testid="cost-input"
                />
              )}
            </>
          )}
          {(overrideMode || userOverride === 'full') && (
            <>
              <PriceOverrideReasonSelect
                className={classes.input}
                label={t(`productSearch:ocn:priceOverrideReasonSelect`)}
                modalTitle={t(
                  `productSearch:ocn:overrideReasonSelectModalTitle`
                )}
                inputName="overrideReason"
                setOverrideReason={(v) => {
                  setFieldValue('overrideReason', v.key);
                  setFieldValue('overrideReasonText', v.title);
                  setTimeout(() => validateField('overrideReason'));
                }}
                disabled={isLoading || overrideMode}
                inputError={
                  touched.overrideReason ? errors.overrideReason : undefined
                }
                required
                onClose={() => setFieldTouched('overrideReason', true)}
                selItem={{
                  key: toString(overrideReason),
                  title: toString(overrideReasonText),
                }}
              />
              <div className={classes.checkboxWrapper}>
                <CheckBox
                  label={t(
                    `productSearch:ocn:priceOverrideSendConfirmationCheckbox`
                  )}
                  checked={sendConfirmation === 'Y'}
                  onChange={(v) =>
                    setFieldValue('sendConfirmation', v ? 'Y' : '')
                  }
                  disabled={isLoading || overrideMode}
                  testid="send-confirmation-checkbox"
                />
                <Button
                  icon={['fas', 'circle-info']}
                  testid="priceoverride-confirmation"
                  onClick={() => setIsSendConfirmationModal(true)}
                  iconClassName={classes.warningIcon}
                  className={classes.btnWrapper}
                />
              </div>
              <CheckBox
                className={classes.checkbox}
                label={t(
                  `productSearch:ocn:priceOverrideAddToAgreementCheckbox`
                )}
                checked={addToAgreement === 'Y'}
                onChange={(v) => setFieldValue('addToAgreement', v ? 'Y' : '')}
                disabled={isLoading || overrideMode}
                testid="notify-checkbox"
              />
              {addToAgreement === 'Y' && (
                <FormikInput
                  className={classes.input}
                  label={t(`productSearch:ocn:priceOverrideAgreementGpInput`)}
                  name="agreementGP"
                  disabled={isLoading || overrideMode}
                  numberMask={{ max: 100 }}
                  testid="recommended-gp-input"
                />
              )}
              <FormikInput
                className={classes.input}
                label={t(`productSearch:ocn:priceOverrideNoteInput`)}
                name="notebookText"
                required
                textarea
                toUpperCase
                disabled={isLoading || overrideMode}
                testid="note-input"
              />
            </>
          )}
          <Button
            className={classes.recalculateButton}
            variant="secondary"
            text={t('productSearch:ocn:recalculateOcnLinePriceButton')}
            onClick={() => setRecalculateIsOpen(true)}
            disabled={isLoading || disableRecalculate}
            testid="recalculate-button"
          />
          <DiscardModal
            title={t('productSearch:ocn:recalculateOcnLinePriceModalTitle')}
            className={classes.discardModal}
            isOpen={recalculateIsOpen}
            setIsOpen={setRecalculateIsOpen}
            initialBreakpoint={0.4}
            backdropDismiss={false}
            withRightCloseButton
            testid="recalculate-price-modal"
            discardMsg={t(
              'productSearch:ocn:recalculateOcnLinePriceModalMessage'
            )}
            goBackButtonTitle={t('common:goBack')}
            discardButtonTitle={t(
              'productSearch:ocn:recalculateOcnLinePriceModalButton'
            )}
            onDiscardClick={onReset}
          />

          <HelpModal
            isOpen={isSendConfirmationModal}
            closeHelpModal={setIsSendConfirmationModal}
            testid="priceoverride-confirmation-send"
            title={t('productSearch:ocn:priceOverrideSendConfirmationCheckbox')}
            initialBreakpoint={0.4}
          >
            <IonRow>
              <Text
                variant="mipro-body-copy"
                text={t(
                  'productSearch:ocn:priceOverrideSendConfirmationMessage'
                )}
              />
            </IonRow>
          </HelpModal>
        </div>
      </div>
    </div>
  );
};

export default PricingTab;
