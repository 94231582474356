import React from 'react';
import { useTranslation } from 'react-i18next';
import type { Column, Row } from 'react-table';
import classNames from 'classnames';
import type { Dictionary } from 'lodash';
import { find, get, isEmpty, map, toString } from 'lodash';
import type { ContactModalProps } from 'common/components/ContactModal/ContactModal';
import { emptyString } from 'common/utils/valueFormatter';
import { searchCustomerURL, searchURL } from 'navigation';
import type { OpenQuoteReportItem } from 'ReportsApp/api/useGetOpenQuotesReports';
import {
  AccessControlType,
  useHasAccessControls,
} from 'hooks/useAccessControls';
import { concatRoutes } from 'utils/navigations';
import { removeLeadingZeros } from 'utils/number';
import { goToReviewCart } from 'navigation/navigationHelpers';
import Button from 'components/Button/Button';
import Text from 'components/Text/Text';
import classes from './OpenQuotes.module.scss';

interface OpenQuotesReportField {
  key: string;
  id: string;
}

interface UseOpenQuotesTableDataProps {
  openQuoteData: OpenQuoteReportItem[];
  openContactModal?: React.Dispatch<ContactModalProps>;
}

const renderOpenQuotesCell = (
  field: OpenQuotesReportField,
  data: OpenQuoteReportItem,
  hasOcnAccess?: boolean,
  openContactModal?: React.Dispatch<ContactModalProps>
) => {
  const value = toString(get(data, field.id));
  const {
    custContactEmail,
    custContactName,
    custContactPhone,
    miLoc,
    shipToCustNo,
    orderCtlNo,
  } = data;
  const hasCustomerContact = !isEmpty(custContactName);
  const ocn = removeLeadingZeros(value);
  switch (field.id) {
    case 'orderCtlNo':
      return hasOcnAccess ? (
        <Button
          testid="ocn"
          text={ocn}
          variant="link"
          href={goToReviewCart({
            miLoc,
            orderCtlNo,
            shipToCustNo,
            ocnType: 'quote',
            ocnMode: 'review',
          })}
          className={classes.reportBtn}
        />
      ) : (
        <Text variant="mipro-body-copy" text={ocn} className={classes.column} />
      );
    case 'customerName':
      return (
        <Button
          testid="customer-name"
          variant="mipro-owner-link"
          text={value}
          className={classes.customerBtn}
          href={concatRoutes(
            searchURL(),
            searchCustomerURL(miLoc, shipToCustNo)
          )}
        />
      );
    case 'custContactName':
      return (
        <Button
          testid="cust-contact-name"
          text={hasCustomerContact ? value : emptyString}
          variant={hasCustomerContact ? 'link' : 'clear'}
          onClick={() => {
            if (hasCustomerContact) {
              openContactModal?.({
                name: custContactName,
                email: custContactEmail,
                phoneNumber: custContactPhone,
              });
            }
          }}
          className={classes.reportBtn}
        />
      );
    default:
      return (
        <Text
          text={value}
          variant="mipro-body-copy"
          className={classNames(classes.column, {
            [classes.poColumn]: field.id === 'custPoNo',
          })}
        />
      );
  }
};

// eslint-disable-next-line import/prefer-default-export
export const useGetOpenQuotesTableData = ({
  openQuoteData,
  openContactModal,
}: UseOpenQuotesTableDataProps) => {
  const { t } = useTranslation();
  const namespace = 'reports:openQuote';
  const { hasAccessControl } = useHasAccessControls();
  const hasOcnAccess = hasAccessControl(
    AccessControlType.ViewOrdersAccessControls
  );

  const openQuotesReportFields: OpenQuotesReportField[] = [
    {
      key: t(`${namespace}:customer`),
      id: 'customerName',
    },
    {
      key: t(`${namespace}:amount`),
      id: 'totalOrderAmt',
    },
    {
      key: t(`${namespace}:ocns`),
      id: 'orderCtlNo',
    },
    {
      key: t(`${namespace}:date`),
      id: 'creationTmstmp',
    },
    {
      key: t(`${namespace}:custPoOrReleaseNo`),
      id: 'custPoNo',
    },
    {
      key: t(`${namespace}:customerContact`),
      id: 'custContactName',
    },
    {
      key: t(`${namespace}:createdBy`),
      id: 'creationUserName',
    },
    {
      key: t(`${namespace}:lines`),
      id: 'lineCount',
    },
    {
      key: t(`${namespace}:expectedDate`),
      id: 'dueDate',
    },
    {
      key: t(`${namespace}:miloc`),
      id: 'miLoc',
    },
  ];

  const tableColumns: Column<Dictionary<unknown>>[] = [
    ...map(openQuotesReportFields, (field) => ({
      Header: field.key,
      id: field.id,
      sortType: 'basic',
      accessor: field.id,
      Cell: ({ row }: Dictionary<unknown>) => {
        const { values } = row as Row;
        const { orderCtlNo } = values as OpenQuoteReportItem;
        const rowItem = find(openQuoteData, {
          orderCtlNo,
        }) as OpenQuoteReportItem;
        return renderOpenQuotesCell(
          field,
          rowItem,
          hasOcnAccess,
          openContactModal
        );
      },
    })),
  ];

  return { tableColumns };
};
