import type { SearchItemType } from 'models/Search';

const showInvite = (
  searchType: SearchItemType,
  isWebRegistered: boolean,
  registrationStatus: 'EXPIRING' | 'NONE' | 'SENT' | undefined
): boolean =>
  searchType === 'customer' &&
  !isWebRegistered &&
  registrationStatus === 'NONE';

export default showInvite;
