import React, { useState } from 'react';
import type { Dictionary } from 'lodash';
import { toString } from 'lodash';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import useFindWorkOrders from 'DocumentsApp/api/useFindWorkOrders';
import { useDebounce } from 'use-debounce';
import type { WorkOrderFastFind } from 'models/InspectionReport';
import type Input from 'components/Input/Input';
import FastFind from './FastFind';

interface WorkOrdersFastFindProps {
  templateId?: string;
  workOrder?: WorkOrderFastFind;
  setWorkOrder?: (i: WorkOrderFastFind) => void;
}

const WorkOrdersFastFind = ({
  label,
  value,
  required,
  disabled,
  setValue,
  templateId,
  workOrder,
  setWorkOrder,
  testid,
}: WorkOrdersFastFindProps &
  React.ComponentProps<typeof Input> &
  IonicReactProps): JSX.Element => {
  const [searchQuery, setSearchQuery] = useState(toString(value));
  const [searchQueryValue] = useDebounce(searchQuery, 300);

  const {
    workOrders,
    showLoader,
    isEmptyResponse,
    enableInfiniteScroll,
    fetchNextPage,
  } = useFindWorkOrders({ query: searchQueryValue, templateId });

  return (
    <FastFind<WorkOrderFastFind>
      items={workOrders}
      getId={(i) => i.woCtlNo}
      getTitle={(i) => `${i.woCtlNo}${i.woDesc ? ` - ${i.woDesc}` : ''}`}
      getDescription={(i) => i.custPo}
      getLine={(i) =>
        i.custNo ? `${i.miLoc}${i.custNo} ${i.customerName}` : ''
      }
      getDetails={(i) => {
        const workOrderDetails: Dictionary<string> = {};
        if (i.miLoc) {
          workOrderDetails[
            i.requestOrigin === 'W' ? 'workOderShop' : 'workOderMiLoc'
          ] = i.miLoc;
        }
        if (i.orderCtlNo) {
          workOrderDetails[
            i.requestOrigin === 'W' ? 'workOrderNo' : 'workOrderOCN'
          ] = i.orderCtlNo;
        }
        workOrderDetails.workOrderLineNo = i.orderLineNo;
        return workOrderDetails;
      }}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      showLoader={showLoader}
      isEmptyResponse={isEmptyResponse}
      enableInfiniteScroll={enableInfiniteScroll}
      fetchNextPage={fetchNextPage}
      label={label}
      value={value}
      required={required}
      disabled={disabled}
      setValue={setValue}
      selectedItem={workOrder}
      setSelectedItem={setWorkOrder}
      testid={testid}
    />
  );
};

export default WorkOrdersFastFind;
