import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { IonPage, IonRow, IonToolbar } from '@ionic/react';
import ReplenishmentsList from 'StoreroomsApp/components/ReplenishmentsList/ReplenishmentsList';
import StoreroomsList from 'StoreroomsApp/components/StoreroomsList/StoreroomsList';
import useReplenishmentSync from 'StoreroomsApp/hooks/useReplenishmentSync';
import { useDebounce } from 'use-debounce';
import Header from 'components/Header/Header';
import Searchbar from 'components/Searchbar/Searchbar';
import SegmentTabs from 'components/SegmentTabs/SegmentTabs';
import classes from './Replenishment.module.scss';

enum TabTypeEnum {
  UploadList = 'UploadList',
  StoreroomSearch = 'StoreroomSearch',
}

const ReplenishmentView = (): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState<TabTypeEnum>(
    TabTypeEnum.UploadList
  );
  const [searchQuery, setSearchQuery] = useState({
    [TabTypeEnum.UploadList]: '',
    [TabTypeEnum.StoreroomSearch]: '',
  });
  const [searchUploadList] = useDebounce(
    searchQuery[TabTypeEnum.UploadList],
    300
  );
  const [searchStoreroomSearch] = useDebounce(
    searchQuery[TabTypeEnum.StoreroomSearch],
    300
  );

  const doSearch = (query: string) => {
    if (!isEmpty(query)) {
      return;
    }
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };

  const onSearch = (query: string) => {
    setSearchQuery((prev) => ({ ...prev, [selectedTab]: query }));
    doSearch(query);
  };

  const { loader, downloadButton, offlineBanner } = useReplenishmentSync();

  return (
    <IonPage className={classes.replenishment} data-testid="replenishments">
      <Header
        title="Replenishments"
        testid="replenishments-header"
        showLocationText
        toolbarChildren={downloadButton}
      >
        <SegmentTabs
          className={classes.searchTabs}
          options={[
            { key: TabTypeEnum.UploadList, text: 'Upload List' },
            { key: TabTypeEnum.StoreroomSearch, text: 'Search' },
          ]}
          value={selectedTab}
          setValue={(v: string) => setSelectedTab(v as TabTypeEnum)}
          testid="replenishments-tabs"
        />
        <IonToolbar className={classes.toolbar}>
          <IonRow className={classes.searchRow}>
            <Searchbar
              className={classes.searchBar}
              inputClassName={classes.searchInput}
              value={searchQuery[selectedTab]}
              placeholder={
                selectedTab === TabTypeEnum.StoreroomSearch
                  ? 'Storerooms...'
                  : 'Search CSN...'
              }
              setValue={(v) =>
                setSearchQuery((prev) => ({ ...prev, [selectedTab]: v }))
              }
              onSearch={() => onSearch(searchQuery[selectedTab])}
              onClear={() =>
                setSearchQuery((prev) => ({ ...prev, [selectedTab]: '' }))
              }
              variant="dark"
              testid="search-input"
            />
          </IonRow>
        </IonToolbar>
        {offlineBanner}
      </Header>
      {loader}
      {selectedTab === TabTypeEnum.StoreroomSearch ? (
        <StoreroomsList searchQuery={searchStoreroomSearch} />
      ) : (
        <ReplenishmentsList searchQuery={searchUploadList} />
      )}
    </IonPage>
  );
};

export default ReplenishmentView;
