import type { AxiosError } from 'axios';
import { isEmpty, type Dictionary } from 'lodash';
import { useIonViewWillEnter } from '@ionic/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import useCountPlanDB from 'InventoryApp/database/useCountPlanDB';
import type {
  CountGroup,
  CountPlan,
  CountPlanOptions,
} from 'InventoryApp/models/InventoryPlanGroup';
import {
  doGetIsLoading,
  doPromiseAPI,
  getPlaceholderData,
  useKeyUserId,
} from 'api/helpers';
import type { QueryFlags } from 'models/Search';
import { findCountGroupsQueryKey } from './useFindCountGroups';

export const getCountGroupQueryKey = 'count-group';

interface UseGetCountGroupProps {
  countPlanId: string;
  groupId: string;
  groupUniqueId: string;
}

interface UseGetCountGroupResponse {
  countPlan?: CountPlan;
  countGroup?: CountGroup;
  countPlanOptions?: CountPlanOptions;
}

const useGetCountGroup = ({
  countPlanId,
  groupId,
  groupUniqueId,
}: UseGetCountGroupProps): QueryFlags & UseGetCountGroupResponse => {
  const queryClient = useQueryClient();
  const { createQueryKey } = useKeyUserId();
  const { findCountPlanById, findCountGroupById, findCountPlanOptionsById } =
    useCountPlanDB();
  const enabled = !isEmpty(countPlanId) && !isEmpty(groupId);
  const params: Dictionary<string> = {
    countPlanId,
    groupId,
    groupUniqueId,
  };

  const doGetCountGroup = () => {
    return doPromiseAPI<UseGetCountGroupResponse>(async () => {
      const countPlan = await findCountPlanById(countPlanId);
      const countGroup = await findCountGroupById(
        countPlanId,
        groupId,
        groupUniqueId
      );
      const countPlanOptions = await findCountPlanOptionsById(countPlanId);
      return { countPlan, countGroup, countPlanOptions };
    });
  };

  const response = useQuery<UseGetCountGroupResponse, AxiosError>(
    createQueryKey(getCountGroupQueryKey, params),
    doGetCountGroup,
    {
      networkMode: 'always',
      enabled,
      placeholderData: () => ({
        countGroup: getPlaceholderData<CountGroup>({
          queryClient,
          queryKey: findCountGroupsQueryKey,
          objectKey: 'items',
          findPredicate: { uniqueId: groupUniqueId },
        }),
      }),
    }
  );

  const { data, error, refetch } = response;

  useIonViewWillEnter(() => {
    if (enabled) {
      void refetch();
    }
  });

  return {
    countPlan: data?.countPlan,
    countGroup: data?.countGroup,
    countPlanOptions: data?.countPlanOptions,
    error,
    isLoading: doGetIsLoading(response),
    refetch: async () => {
      await refetch();
    },
  };
};

export default useGetCountGroup;
