import { useMemo } from 'react';
import type { AxiosError } from 'axios';
import type { Dictionary } from 'lodash';
import { isEmpty, map, size, toString } from 'lodash';
import { useInfiniteQuery } from '@tanstack/react-query';
import type { InfiniteQueryObserverResult } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { doConcatDataPages, doPromiseAPI, useKeyUserId } from 'api/helpers';
import type { Employee } from 'models/Employee';
import type {
  SearchItem,
  SearchResponse,
  InfiniteQueryFlags,
} from 'models/Search';
import { pageSize } from 'utils/constants';

export const findEmployeesQueryKey = 'search-employees';

interface UseFindEmployeesProps {
  query?: string;
  sortField?: string;
  sortDir?: string;
  limit?: number;
  enabled?: boolean;
}

export type UseFindEmployeesResponse = SearchResponse &
  InfiniteQueryFlags &
  Pick<InfiniteQueryObserverResult<SearchResponse>, 'data'>;

const useFindEmployees = ({
  query = '',
  sortField = 'searchScore',
  sortDir = 'ASC',
  limit = pageSize(),
  enabled = true,
}: UseFindEmployeesProps): UseFindEmployeesResponse => {
  const { axios } = useAxios();
  const { findEmployeesAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const params: Dictionary<string> = {
    query: toString(query),
    sortField,
    sortDir,
    limit: toString(limit),
  };

  const doFindEmployees = ({ pageParam = 1 }) => {
    return doPromiseAPI<SearchResponse>(async (cancelToken) => {
      const { data } = await axios.get<Employee[]>(
        findEmployeesAPI(
          toString(
            new URLSearchParams({ ...params, page: toString(pageParam) })
          )
        ),
        { cancelToken }
      );
      return {
        items: map(
          data,
          ({
            email,
            firstName,
            homePhone,
            id,
            jobCode,
            jobPosition,
            lastName,
            miLoc,
            mobilePhone,
            name,
            name_empDisplayName,
            postalCode,
            state,
            title,
            workPhone,
          }) => ({
            type: 'employee',
            email,
            homePhone,
            id,
            jobCode,
            jobPosition,
            text: name_empDisplayName || `${firstName} ${lastName}`,
            miLoc,
            name,
            postalCode,
            state,
            title,
            mobilePhone,
            workPhone,
          })
        ),
      };
    });
  };

  const response = useInfiniteQuery<SearchResponse, AxiosError>(
    createQueryKey(findEmployeesQueryKey, params),
    doFindEmployees,
    {
      enabled: !isEmpty(query) && enabled,
      getNextPageParam: (lastPage, pages) =>
        size(lastPage.items) < limit ? false : size(pages) + 1,
    }
  );

  const {
    data,
    error,
    status,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = response;

  const employees = useMemo(
    () => doConcatDataPages<SearchItem, SearchResponse>(data, 'items'),
    [data]
  );

  const hasItems = size(employees) > 0;
  const hasError = status === 'error';
  const isEmptyResponse = status === 'success' && !hasNextPage && !hasItems;
  const noMoreData = status === 'success' && !hasNextPage && hasItems;
  const showLoader = status === 'loading' || isFetchingNextPage;
  const enableInfiniteScroll = !(!hasNextPage || isFetchingNextPage);

  return {
    data,
    fetchNextPage: async () => {
      await fetchNextPage();
    },
    items: employees,
    total: !showLoader ? data?.pages[0].total : undefined,
    error,
    hasError,
    showLoader,
    isEmptyResponse,
    noMoreData,
    enableInfiniteScroll,
    refetch: async () => {
      await refetch?.call(null);
    },
  };
};

export default useFindEmployees;
