import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import type { AxiosError } from 'axios';
import { get, head, isEmpty, map, size, toNumber, toString } from 'lodash';
import { useInfiniteQuery } from '@tanstack/react-query';
import type { QueryFunctionContext } from '@tanstack/react-query';
import useAPIUrl from 'api';
import type { QueryParamsType } from 'common/api/utils/useGetQueryFlags';
import { and, choose, or } from 'common/utils/logicHelpers';
import { getUnixTime } from 'date-fns';
import { useAxios } from 'providers/AxiosProvider';
import type { BaseReportProps, BaseReportRow } from 'ReportsApp/models';
import { doConcatDataPages, useKeyUserId, useMiLocOrTeamId } from 'api/helpers';
import useGetUserConfig from 'api/user/useGetUserConfig';
import type {
  DrillDownItem,
  ReportDrillDownItem,
  SummaryItemOutput,
  WebPerformanceOutput,
} from 'models/Reports';
import type { InfiniteQueryFlags } from 'models/Search';
import { SortDirEnum } from 'models/Sort';
import type { RootState } from 'store/reducers';
import { pageSize } from 'utils/constants';
import useGetLocationCurrency from 'utils/currency';
import { DateFormatEnum, formatDate } from 'utils/date';
import { transformDrillDownRows } from 'utils/reports';
import { mapGroupBy, mapSortField } from './useGetSalesDashboard';

export const getDigitalSalesReportQueryKey = 'digital-sales-report';

interface GetDigitalSalesResponse {
  webPerformance?: WebPerformanceOutput;
  currencyType?: string;
}

interface GetReportDrillDownData {
  totals?: SummaryItemOutput[];
  items: ReportDrillDownItem[];
  summaryData?: GetDigitalSalesResponse;
}

export interface DigitalSalesRow extends BaseReportRow {
  webVsNonWebGPBasisPointsDiff: number;
  webSalesPercentOfTotalSales: number;
  webRegisteredPercentOfTotalCustomers: number;
}

interface DigitalSalesReportData {
  rows?: DigitalSalesRow[];
  summary?: DigitalSalesRow;
}

interface UseGetDigitalSalesReportResponse {
  totalRows?: number;
  totalsData?: SummaryItemOutput[];
  drilldownData: ReportDrillDownItem[];
  summaryData?: GetDigitalSalesResponse;
}

const useGetDigitalSalesReport = ({
  miLoc: propsMiLoc,
  busPeriod,
  requestType = 'MTD',
  groupBy,
  nationalAcctNo,
  sortField = 'name',
  sortDir = SortDirEnum.ASCENDING,
  summaryOnly,
  limit = pageSize(),
  enabled = true,
  sendVirtualTeamId = true,
  territory,
}: BaseReportProps): UseGetDigitalSalesReportResponse & InfiniteQueryFlags => {
  const { axios } = useAxios();
  const { getNewDigitalSalesReportAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const { createParams, getURLParams } = useMiLocOrTeamId({
    miLoc: propsMiLoc,
    sendVirtualTeamId,
  });

  const { userInfo, isCamUser } = useSelector((state: RootState) => state.user);
  const userId = get(userInfo, 'userid', '');
  const { data: currencyData } = useGetUserConfig({
    configType: 'currency',
    enabled: enabled && !isEmpty(userId),
  });
  const { secondaryCurrencyType } = useGetLocationCurrency();
  const currencyType = or(currencyData?.currency, secondaryCurrencyType);
  const propsGroupBy = toString(groupBy);
  const paramsGroupBy = or(mapGroupBy[propsGroupBy], propsGroupBy);

  const params: QueryParamsType = {
    ...createParams(),
    requestDate: formatDate(busPeriod, DateFormatEnum.reportsDateAPI),
    requestType,
    usdRequested: currencyType === 'USD',
    limit,
    nationalAcctNo,
    showCAMView: isCamUser,
    territory,
    ...(summaryOnly
      ? {}
      : {
          groupBy: paramsGroupBy,
          sortField: mapSortField(sortField, paramsGroupBy),
          sortDir,
        }),
  };

  const doGetDigitalSalesReport = async ({
    pageParam = 1,
    signal,
  }: QueryFunctionContext) => {
    const { data: digitalSalesData } = await axios.get<DigitalSalesReportData>(
      getNewDigitalSalesReportAPI(
        getURLParams({
          ...params,
          // api doesn't support page param
          start: limit * (pageParam - 1),
        })
      ),
      { signal }
    );
    const digitalSalesSummary = digitalSalesData.summary;

    const dataCurrencyType = or(digitalSalesSummary?.currency, currencyType);

    return {
      summaryData: {
        webPerformance: {
          webVsNonWebGPBasisPointsDiff:
            digitalSalesSummary?.webVsNonWebGPBasisPointsDiff,
          webPercentOfTotalSales:
            digitalSalesSummary?.webSalesPercentOfTotalSales,
          sales: [
            {
              Name: 'Sales',
              amount: digitalSalesSummary?.currentSales,
              change: digitalSalesSummary?.currentSalesChangeToCurrentBusDay,
            },
            {
              Name: 'Avg Daily',
              amount: digitalSalesSummary?.currentAvgDaily,
              change: digitalSalesSummary?.currentAvgDailyChangeToCurrentBusDay,
            },
          ],
          profit: [
            {
              Name: 'GP%',
              amount: digitalSalesSummary?.currentGpPercent,
              change:
                digitalSalesSummary?.currentGpPercentChangeToCurrentBusDay,
            },
          ],
        },
        currencyType: dataCurrencyType,
      } as GetDigitalSalesResponse,
      totals: transformDrillDownRows({
        ...digitalSalesSummary,
        currencyType: dataCurrencyType,
        sales: digitalSalesSummary?.currentSales,
        salesChange: digitalSalesSummary?.currentSalesChangeToCurrentBusDay,
        transactions: digitalSalesSummary?.currentOrders,
        transactionsChange:
          digitalSalesSummary?.currentOrdersChangeToCurrentBusDay,
        avgDaily: digitalSalesSummary?.currentAvgDaily,
        avgDailyChange:
          digitalSalesSummary?.currentAvgDailyChangeToCurrentBusDay,
        gpAmount: digitalSalesSummary?.currentGp,
        gpChange: digitalSalesSummary?.currentGpChangeToCurrentBusDay,
        gpPercentAmount: digitalSalesSummary?.currentGpPercent,
        gpPercentChange:
          digitalSalesSummary?.currentGpPercentChangeToCurrentBusDay,
        webPercentOfTotalSales:
          digitalSalesSummary?.webSalesPercentOfTotalSales,
        activeWebRegistered:
          digitalSalesSummary?.webRegisteredPercentOfTotalCustomers,
      } as unknown as DrillDownItem),
      items: map(digitalSalesData.rows, (row) => {
        let rowId = row.miLoc;
        let rowName = row.miLocName;
        switch (paramsGroupBy) {
          case 'REP':
            rowId = row.repNo;
            rowName = row.repName;
            break;
          case 'CUSTOMER':
            rowId = row.customerNo;
            rowName = row.customerName;
            break;
          case 'NATLACCT':
            rowId = row.nationalAcctNo;
            rowName = `${row.miLoc}: ${row.customerName}`;
            break;
          case 'BRANCH':
          case 'DIVISION':
          case 'GROUP':
            if (isCamUser) {
              rowId = row.nationalAcctNo;
              rowName = `${row.miLoc}: ${row.miLocName}`;
            }
            break;
          case 'TEAM':
            rowId = row.teamId;
            rowName = row.teamName;
            break;
          default:
        }

        return {
          miLoc: row.miLoc,
          id: rowId,
          Name: rowName,
          disabledDrilldown: row.disabled,
          items: transformDrillDownRows({
            ...row,
            currencyType: or(row.currency, dataCurrencyType),
            sales: row.currentSales,
            salesChange: row.currentSalesChangeToCurrentBusDay,
            transactions: row.currentOrders,
            transactionsChange: row.currentOrdersChangeToCurrentBusDay,
            avgDaily: row.currentAvgDaily,
            avgDailyChange: row.currentAvgDailyChangeToCurrentBusDay,
            gpAmount: row.currentGp,
            gpChange: row.currentGpChangeToCurrentBusDay,
            gpPercentAmount: row.currentGpPercent,
            gpPercentChange: row.currentGpPercentChangeToCurrentBusDay,
            webPercentOfTotalSales: row.webSalesPercentOfTotalSales,
            activeWebRegistered: row?.webRegisteredPercentOfTotalCustomers,
          } as unknown as DrillDownItem),
        };
      }),
    };
  };

  const response = useInfiniteQuery<GetReportDrillDownData, AxiosError>(
    createQueryKey(getDigitalSalesReportQueryKey, params),
    doGetDigitalSalesReport,
    {
      enabled: enabled && !isEmpty(userId),
      // TUDU: refactor and extract this retry logic to be resuable across the application
      retry: (failureCount, err) =>
        err.response?.status === 403 ? false : failureCount >= 3,
      getNextPageParam: (lastPage, pages) =>
        choose(size(lastPage.items) < limit, false, size(pages) + 1),
    }
  );

  const {
    data,
    error,
    status,
    hasNextPage,
    isFetchingNextPage,
    dataUpdatedAt,
    refetch,
    fetchNextPage,
    fetchStatus,
  } = response;

  const drilldownData = useMemo(
    () =>
      doConcatDataPages<ReportDrillDownItem, GetReportDrillDownData>(
        data,
        'items'
      ),
    [data]
  );

  // TUDU use useGetQueryFlags instead
  const hasItems = size(drilldownData) > 0;
  const hasError = status === 'error';
  const isEmptyResponse = and(status === 'success', !hasNextPage, !hasItems);
  const noMoreData = and(status === 'success', !hasNextPage, hasItems);
  const showLoader = fetchStatus === 'fetching';
  const enableInfiniteScroll = !or(!hasNextPage, isFetchingNextPage);
  let lastUpdatedAt = new Date();
  if (toNumber(dataUpdatedAt) > 0) {
    lastUpdatedAt = new Date(dataUpdatedAt);
  }

  return {
    drilldownData,
    summaryData: head(data?.pages)?.summaryData,
    refetch: async () => {
      await refetch();
    },
    fetchNextPage: async () => {
      await fetchNextPage();
    },
    error: error as AxiosError,
    hasError,
    showLoader,
    isEmptyResponse,
    noMoreData,
    enableInfiniteScroll,
    lastUpdatedAt: getUnixTime(lastUpdatedAt),
    totalsData: head(data?.pages)?.totals,
  };
};

export default useGetDigitalSalesReport;
