import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import type { MutationStatus } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import type { Order } from 'ProductSearchApp/models/Order';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import type { UpdateMutationContext } from 'api/helpers';
import { onErrorUpdate, onMutateUpdate, onSuccessMutation } from 'api/helpers';
import { ToastType } from 'models/Toast';
import type { RootState } from 'store/reducers';
import { clearCurrentCartCustomer } from 'store/user';
import { findOrdersQueryKey } from './useFindOrders';

interface DeleteOrderBody {
  miLoc: string;
  orderCtlNo: string;
  deleteOption: string;
  orderTypeCd: string;
  orderSourceCd: string;
  lostSale: string;
}

interface UseDeleteOrderResponse {
  status: MutationStatus;
  onDeleteOrder: (body: DeleteOrderBody) => void;
  onOptimisticDeleteOrder: (
    orderCtlNo: string
  ) => Promise<UpdateMutationContext<Order>[]>;
  rollbackDeleteOrder: (context?: UpdateMutationContext<Order>[]) => void;
}

const useDeleteOrder = (): UseDeleteOrderResponse => {
  const { axios } = useAxios();
  const { deleteOrderAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const namespace = 'productSearch:orderList';
  const { currentCartCustomer } = useSelector((state: RootState) => state.user);

  const doDeleteOrder = async (body: DeleteOrderBody) => {
    await axios.post(deleteOrderAPI(), { orderInfo: body });
  };

  const onOptimisticDeleteOrder = async (orderCtlNo: string) =>
    onMutateUpdate<Order>({
      queryClient,
      queryKey: findOrdersQueryKey,
      updatedItems: [{ orderCtlNo }],
      dataPath: 'orders',
      findPredicates: [{ orderCtlNo }],
      isInfiniteQuery: true,
    });

  const rollbackDeleteOrder = (context?: UpdateMutationContext<Order>[]) =>
    onErrorUpdate<Order>({
      queryClient,
      dataPath: 'orders',
      context,
      isInfiniteQuery: true,
    });

  const { mutate, status } = useMutation(doDeleteOrder, {
    onMutate: ({ orderCtlNo }) => onOptimisticDeleteOrder(orderCtlNo),
    onSuccess: (data, { miLoc, orderCtlNo }) => {
      void onSuccessMutation(queryClient, findOrdersQueryKey);
      if (
        miLoc === currentCartCustomer?.miLoc &&
        orderCtlNo === currentCartCustomer?.orderCtlNo
      ) {
        dispatch(clearCurrentCartCustomer());
      }
    },
    onError: (error, vars, context) => {
      addToast({
        type: ToastType.error,
        text: t(`${namespace}:deleteErrorToast`),
        testid: 'delete-quote-error-toast',
      });
      rollbackDeleteOrder(context);
    },
  });

  return {
    status,
    onDeleteOrder: (body: DeleteOrderBody) => mutate(body),
    onOptimisticDeleteOrder,
    rollbackDeleteOrder,
  };
};

export default useDeleteOrder;
