import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  map,
  toString,
  set,
  some,
  intersectionWith,
  isEmpty,
  size as lodashSize,
} from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonCol, IonRow, IonItem, isPlatform } from '@ionic/react';
import { namespaces } from 'i18n/i18n.constants';
import { useToasts } from 'providers/ToastProvider';
import type { UpdateAttachmentBody } from 'api/attachments/useUpdateAttachmentDetails';
import {
  AttachmentSize,
  type FileAttachmentProp,
  type MiProFile,
} from 'models/Attachment';
import { ToastType } from 'models/Toast';
import {
  attachmentAcceptedFiles,
  updateFileSizeFlag,
  getFileName,
  getFileExtension,
  isImageFile,
} from 'utils/filesUpload';
import { compressImages, withStringProp } from 'utils/helpers';
import { findIcon } from 'utils/icons';
import ActionRow from 'components/ActionRow/ActionRow';
import Button from 'components/Button/Button';
import SheetModal from 'components/Modals/SheetModal/SheetModal';
import Text from 'components/Text/Text';
import AttachmentThumbnail from './AttachmentThumbnail';
import classes from './FileAttachments.module.scss';
import FileFormModal from './FileFormModal/FileFormModal';
import FileRemoveModal from './FileRemoveModal/FileRemoveModal';

interface FileAttachmentsProps {
  domain?: string;
  size?: string;
  label?: string;
  className?: string;
  name: string;
  disabled?: boolean;
  editMode?: boolean;
  enableSmallPreview?: boolean;
  files?: MiProFile[];
  setFieldError?: (n: string, v: string) => void;
  onAdd?: (file: MiProFile[]) => void;
  onRemove?: (file: MiProFile) => void;
  onUpdate?: (body: UpdateAttachmentBody) => void;
  testid: string;
}

const FileAttachments = ({
  domain,
  size,
  name,
  label,
  className,
  disabled,
  enableSmallPreview,
  files,
  setFieldError,
  onAdd,
  onRemove,
  onUpdate,
  testid,
  editMode = true,
}: FileAttachmentsProps): JSX.Element => {
  const withLabel = withStringProp(label);
  const { t } = useTranslation(namespaces.common);
  const { addToast } = useToasts();

  const [filesToUpload, setFilesToUpload] = useState<Array<MiProFile>>([]);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [fileForPreview, setFileForPreview] = useState<MiProFile>();
  const [removeModalIsOpen, setRemoveModalIsOpen] = useState(false);
  const [fileForRemoval, setFileForRemoval] = useState<{
    fileToRemove: MiProFile | undefined;
    index: number;
  }>({
    fileToRemove: undefined,
    index: -1,
  });
  const [sameFileName, setSameFileName] = useState<MiProFile[]>();
  const [isOpenOptionsModal, setIsOpenOptionsModal] = useState<boolean>(false);

  const attachmentKey = useMemo(() => toString(Date.now()), []);

  const isSmallSize = size === AttachmentSize.SMALL;

  useEffect(() => {
    setFilesToUpload(files || []);
  }, [files]);

  const removeFile = (index: number, file?: MiProFile) => {
    if (file) {
      onRemove?.(file);
      setFilesToUpload([
        ...filesToUpload.slice(0, index),
        ...filesToUpload.slice(index + 1),
      ]);
    }
  };

  const photoLibFileInput = useRef<HTMLInputElement>(null);
  const takePhotoFileInput = useRef<HTMLInputElement>(null);
  const fileInput = useRef<HTMLInputElement>(null);

  const onFilesClick = (e: React.BaseSyntheticEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    e.target.value = null;
  };

  const onFilesChange = async (e: React.BaseSyntheticEvent) => {
    setIsOpenOptionsModal(false);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const fileListObj = e.target.files as FileList;
    let fileArray: MiProFile[] = [];
    await Promise.all(
      map(fileListObj, async (fileItem) => {
        // file is compressed only if it is image type
        let updatedFile = fileItem;
        let fileURL = '';
        if (fileItem.type.startsWith('image/')) {
          updatedFile = await compressImages(fileItem);
          fileURL = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = (fileResult) => {
              resolve(toString(fileResult.target?.result));
            };
            reader.readAsDataURL(updatedFile);
          });
        }
        set(updatedFile, 'fileName', `${updatedFile.name}`);
        set(updatedFile, 'fileURL', fileURL);
        fileArray.push(updatedFile);
      })
    );
    const sameFileNameArray = intersectionWith(
      filesToUpload,
      fileArray,
      (a, b) => {
        const uploadedFileName = getFileName(a);
        const uploadToBeFileName = getFileName(b);
        return uploadedFileName === uploadToBeFileName;
      }
    );
    if (isEmpty(sameFileNameArray)) {
      if (lodashSize(fileArray) === 1) {
        const file = fileArray[0];
        setFileForPreview(file);
        setIsImageModalOpen(true);
      } else if (lodashSize(fileArray) > 1) {
        fileArray = fileArray.map((item) => {
          set(item, 'miOnly', 'Y');
          return item;
        });

        onAdd?.([...fileArray]);
        setFilesToUpload([...filesToUpload, ...fileArray]);
      }
    }
    setSameFileName(sameFileNameArray);
  };

  const onAddClick = ({
    miOnly,
    description,
  }: {
    miOnly: string;
    description: string;
  }) => {
    if (fileForPreview) {
      const file = fileForPreview;
      set(file, 'miOnly', miOnly);
      set(file, 'description', description);
      onAdd?.([file]);
      setFilesToUpload([...filesToUpload, file]);
      setIsOpenOptionsModal?.(false);
    }
  };

  useEffect(() => {
    if (!isEmpty(sameFileName)) {
      sameFileName?.forEach((file) => {
        const fileName = getFileName(file);
        addToast({
          type: ToastType.error,
          icon: ['fas', 'circle-xmark'],
          variant: 'mipro-toast',
          header: 'Attachment Failed',
          text: t('sameFileNameError', {
            fileName,
          }),
          testid: `same-file-error-${fileName}`,
        });
        return file;
      });
    }
  }, [addToast, name, sameFileName, t]);

  const fileSizeExceeded = some(
    map(filesToUpload, (file) => updateFileSizeFlag([file]))
  );
  const attachmentsTooLargeError = t('attachmentsTooLarge', {
    count: filesToUpload.length,
  });

  const onImageClick = (img: FileAttachmentProp | MiProFile) => {
    if (isSmallSize && !enableSmallPreview) {
      return;
    }
    setFileForPreview(img as MiProFile);
    setIsImageModalOpen(true);
  };

  const onFileRemoveConfirmDialogYes = () => {
    setRemoveModalIsOpen(false);
    removeFile(fileForRemoval.index, fileForRemoval?.fileToRemove);
  };

  useEffect(() => {
    setFieldError?.(name, fileSizeExceeded ? attachmentsTooLargeError : '');
  }, [setFieldError, name, fileSizeExceeded, attachmentsTooLargeError]);

  return (
    <IonItem
      className={classNames(className, classes.attachmentCont)}
      lines="none"
    >
      <IonCol>
        {withLabel && (
          <IonRow className={classes.labelRow}>
            <Text
              className={classNames(classes.label, {
                [classes.errWarning]: fileSizeExceeded,
              })}
              variant="title-card-red"
              text={toString(label)}
              testid={`${testid}-label`}
            />
          </IonRow>
        )}
        {fileSizeExceeded && (
          <IonRow className={classes.sizeExceedError}>
            <Text
              text={attachmentsTooLargeError}
              className={classNames(classes.label)}
            />
          </IonRow>
        )}
        <div
          className={classNames(classes.attachmentGrid, {
            [classes.withLabel]: withLabel,
            [classes.viewSmall]: !editMode && isSmallSize,
          })}
        >
          {editMode && (
            <IonCol className={classes.fileItemCol}>
              <IonCol
                className={classNames(
                  classes.attachItemCol,
                  classes.addColumn,
                  {
                    [classes.disabledColumn]: disabled,
                    [classes.small]: isSmallSize,
                    [classes.medium]: size === AttachmentSize.MEDIUM,
                  }
                )}
              >
                <Button
                  className={classNames(classes.addImageButton, {
                    [classes.disabledAdd]: disabled,
                  })}
                  variant="secondary"
                  icon={findIcon('plus', 'fas')}
                  onClick={() => {
                    if (isPlatform('ios')) {
                      fileInput.current?.click();
                    } else {
                      setIsOpenOptionsModal(true);
                    }
                  }}
                  testid="add-image-button"
                  disabled={disabled}
                  id={`auto-trigger-${attachmentKey}`}
                />
                <input
                  key="photo-lib"
                  type="file"
                  name={name}
                  onClick={(e) => onFilesClick(e)}
                  onChange={(e) => onFilesChange(e)}
                  style={{ display: 'none' }}
                  ref={photoLibFileInput}
                  accept="image/*"
                  disabled={disabled}
                />
                <input
                  key="take-photo"
                  type="file"
                  name={name}
                  onClick={(e) => onFilesClick(e)}
                  onChange={(e) => onFilesChange(e)}
                  multiple
                  style={{ display: 'none' }}
                  ref={takePhotoFileInput}
                  disabled={disabled}
                  accept="image/*"
                  capture
                />
                <input
                  key="file-input"
                  accept={attachmentAcceptedFiles}
                  type="file"
                  name={name}
                  onClick={(e) => onFilesClick(e)}
                  onChange={(e) => onFilesChange(e)}
                  multiple
                  style={{ display: 'none' }}
                  ref={fileInput}
                  disabled={disabled}
                />
                <SheetModal
                  className={classes.optionsModal}
                  headerClassName={classes.optionHeader}
                  title={t('common:addAttachment')}
                  isOpen={isOpenOptionsModal}
                  setIsOpen={setIsOpenOptionsModal}
                  initialBreakpoint={0.5}
                  testid="add-attachment-modal"
                  withRightCloseButton
                >
                  <div className={classes.searchByContent}>
                    <ActionRow
                      testid="takePhoto"
                      key="takePhoto"
                      disabled={false}
                      onClick={() => takePhotoFileInput.current?.click()}
                    >
                      <FontAwesomeIcon
                        icon={['far', 'camera']}
                        className={classes.icon}
                      />
                      <Text
                        className={classes.optionText}
                        variant="mipro-h4-headline"
                        text={t('takePhoto')}
                      />
                    </ActionRow>
                    <ActionRow
                      testid="chooseGallery"
                      key="chooseGallery"
                      disabled={false}
                      onClick={() => photoLibFileInput.current?.click()}
                    >
                      <FontAwesomeIcon
                        icon={['far', 'gallery-thumbnails']}
                        className={classes.icon}
                      />
                      <Text
                        className={classes.optionText}
                        variant="mipro-h4-headline"
                        text={t('chooseGallery')}
                      />
                    </ActionRow>
                    <ActionRow
                      testid="uploadFiles"
                      key="uploadFiles"
                      disabled={false}
                      onClick={() => fileInput.current?.click()}
                    >
                      <FontAwesomeIcon
                        icon={['far', 'file-plus']}
                        className={classes.icon}
                      />
                      <Text
                        className={classes.optionText}
                        variant="mipro-h4-headline"
                        text={t('uploadFiles')}
                      />
                    </ActionRow>
                  </div>
                </SheetModal>
              </IonCol>
            </IonCol>
          )}
          {map(filesToUpload, (file, index) => {
            const fileName = getFileName(file);
            const fileExtenstion = getFileExtension({ file });
            let fileType = file.type;
            fileType ||= fileExtenstion;
            let fileDesc = file.DESCRIPTION;
            fileDesc ||= file.description;
            fileDesc ||= fileName;
            const isImage = isImageFile(fileType);
            return (
              <IonCol
                key={`${fileName}-${index}`}
                className={classNames(classes.fileItemCol, {
                  [classes.smallFileCol]: isSmallSize,
                })}
              >
                <IonRow
                  className={classes.fileRow}
                  onClick={!isImage ? () => onImageClick(file) : undefined}
                >
                  <IonCol
                    key={`${file.name}-${index}`}
                    className={classNames(classes.attachItemCol, {
                      [classes.imageCol]: isImage,
                      [classes.fileCol]: !isImage,
                      [classes.imageError]: updateFileSizeFlag([file]),
                      [classes.small]: isSmallSize,
                      [classes.medium]: size === AttachmentSize.MEDIUM,
                      [classes.viewOnly]: !editMode,
                    })}
                  >
                    {isImage ? (
                      <AttachmentThumbnail
                        domain={domain}
                        width={500}
                        height={500}
                        file={
                          {
                            ...file,
                            ...(file.fileURL
                              ? { src: file.fileURL }
                              : undefined),
                          } as FileAttachmentProp
                        }
                        onImageClick={onImageClick}
                      />
                    ) : (
                      <div className={classes.fileDiv}>
                        <FontAwesomeIcon
                          className={classes.fileIcon}
                          icon={['far', 'file-check']}
                          onClick={() => onImageClick?.(file)}
                        />
                        <Text
                          className={classes.fileExtension}
                          text={`.${fileExtenstion}`}
                        />
                      </div>
                    )}
                    {editMode && (
                      <Button
                        key={`${fileName}${index}`}
                        className={classes.removeButton}
                        icon={findIcon('close', 'fas')}
                        iconClassName={classes.removeIcon}
                        onClick={(e) => {
                          e.stopPropagation();
                          setRemoveModalIsOpen(true);
                          setFileForRemoval({ fileToRemove: file, index });
                        }}
                        testid="remove-img-button"
                        disabled={disabled}
                      />
                    )}
                  </IonCol>
                </IonRow>
                {isSmallSize ? (
                  (file.miOnly === 'Y' || file.MI_ONLY === 'Y') && (
                    <IonRow className={classes.smallBadge}>
                      <FontAwesomeIcon icon={['fas', 'eye']} />
                    </IonRow>
                  )
                ) : (
                  <>
                    {(file.miOnly === 'Y' || file.MI_ONLY === 'Y') && (
                      <IonRow className={classes.internalBadge}>
                        <FontAwesomeIcon
                          className={classes.internalBadgeIcon}
                          icon={['fas', 'eye']}
                        />
                        <Text
                          variant="content-smaller"
                          className={classes.internalOnly}
                          text="Internal Only"
                        />
                      </IonRow>
                    )}
                    <IonRow className={classes.fileDescRow}>
                      <Text className={classes.fileDesc} text={fileDesc} />
                    </IonRow>
                  </>
                )}
              </IonCol>
            );
          })}
        </div>
      </IonCol>
      {fileForPreview && (
        <FileFormModal
          domain={toString(domain)}
          isOpen={isImageModalOpen}
          setIsOpen={(b: boolean) => setIsImageModalOpen(b)}
          editMode={editMode}
          file={fileForPreview}
          testid="file-modal"
          onAddClick={onAddClick}
          onUpdate={onUpdate}
        />
      )}
      {fileForRemoval?.fileToRemove && (
        <FileRemoveModal
          domain={toString(domain)}
          isOpen={removeModalIsOpen}
          setIsOpen={(b: boolean) => setRemoveModalIsOpen(b)}
          file={fileForRemoval?.fileToRemove}
          testid="file-remove-modal"
          onRemoveClick={onFileRemoveConfirmDialogYes}
        />
      )}
    </IonItem>
  );
};

export default FileAttachments;
