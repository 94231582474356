enum SentryCategories {
  AUTH_STATE = 'authState',
  APP_STATE = 'appState',
  AXIOS = 'axios',
  CAMERA = 'camera',
  PUSH_NOTIFICATIONS = 'pushNotifications',
  BADGE_COUNT = 'badgeCount',
  STORAGE = 'storage',
  ACTIVITIES = 'activities',
  VAULT = 'vault',
}

export default SentryCategories;
