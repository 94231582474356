import useAccessControls, { AccessControlType } from 'hooks/useAccessControls';
import useFeatureToggles, { FeatureToggleType } from 'hooks/useFeatureToggles';

type UseInventoryPermissionsResult = {
  hasVmiPermission: boolean;
  hasInventoryPermission: boolean;
};

const useInventoryPermissions = (): UseInventoryPermissionsResult => {
  const hasVmiFeatureToggle = useFeatureToggles(
    FeatureToggleType.viewVmiFeatureToggle
  );
  const hasInventoryFeatureToggle = useFeatureToggles(
    FeatureToggleType.viewInventoryFeatureToggle
  );
  const hasInventoryAccessControl = useAccessControls(
    AccessControlType.InventoryAccessControl
  );
  const hasCreateGroupAccessControl = useAccessControls(
    AccessControlType.InventoryCreateGroupControls
  );
  const hasCreateCountAccessControl = useAccessControls(
    AccessControlType.InventoryCreateCountControls
  );
  const hasVmiPermission =
    hasVmiFeatureToggle &&
    hasInventoryAccessControl &&
    hasCreateGroupAccessControl &&
    hasCreateCountAccessControl;
  const hasInventoryPermission =
    hasInventoryFeatureToggle &&
    hasInventoryAccessControl &&
    hasCreateGroupAccessControl;

  return { hasVmiPermission, hasInventoryPermission };
};

export default useInventoryPermissions;
