import React, { useEffect, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import classNames from 'classnames';
import type { Dictionary } from 'lodash';
import {
  isNil,
  concat,
  head,
  size,
  filter,
  includes,
  isEmpty,
  toString,
} from 'lodash';
import type { InfiniteQueryFlags } from 'models/Search';
import { getErrorMessage } from 'utils/helpers';
import { findIcon } from 'utils/icons';
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import Loader from 'components/Loader/Loader';
import Modal from 'components/Modal/Modal';
import Searchbar from 'components/Searchbar/Searchbar';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './FastFind.module.scss';
import FastFindItem from './FastFindItem';

interface FastFindProps<ItemType> {
  selectedItem?: ItemType;
  setSelectedItem?: (item: ItemType) => void;
  items: ItemType[];
  getId?: (i: ItemType) => string;
  getTitle?: (i: ItemType) => string;
  getSelectedText?: (i: ItemType) => string;
  getMultipleSelectedText?: (i: ItemType[]) => string;
  getDescription?: (i: ItemType) => string;
  getLine?: (i: ItemType) => string;
  getAdditionalInfo?: (i: ItemType) => string;
  getDetails?: (i: ItemType) => Dictionary<string>;
  multiple?: boolean;
  values?: string[];
  setValues?: (v: string[]) => void;
  modalTitle?: string;
  searchQuery?: string;
  setSearchQuery?: (v: string) => void;
  inputError?: string;
}

function FastFind<ItemType>({
  className,
  items,
  searchQuery,
  setSearchQuery,
  error,
  showLoader,
  isEmptyResponse,
  enableInfiniteScroll,
  fetchNextPage,
  label,
  modalTitle = label,
  value,
  disabled,
  required,
  getId,
  getTitle,
  getSelectedText,
  getMultipleSelectedText,
  getDescription,
  getLine,
  getAdditionalInfo,
  getDetails,
  setValue,
  multiple,
  values,
  setValues,
  selectedItem,
  setSelectedItem,
  inputError,
  testid,
}: FastFindProps<ItemType> &
  Omit<React.ComponentProps<typeof Input>, 'error'> &
  InfiniteQueryFlags): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const [stateValues, setStateValues] = useState<string[]>([]);

  useEffect(() => {
    setStateValues(values || []);
  }, [values, isOpen]);

  const singleSelectedItem = !isNil(selectedItem)
    ? [selectedItem]
    : filter<ItemType>(
        items,
        (item) => toString(getId?.(item)) === toString(value)
      );
  const selectedItems = multiple
    ? filter<ItemType>(items, (item) =>
        includes(values, toString(getId?.(item)))
      )
    : singleSelectedItem;

  useEffect(() => {
    setSearchQuery?.('');
  }, [isOpen, setSearchQuery]);

  let inputValue = '';
  if (size(selectedItems) > 0) {
    inputValue = toString(
      multiple
        ? getMultipleSelectedText?.(selectedItems)
        : (getSelectedText || getTitle)?.(head(selectedItems) as ItemType)
    );
  }

  return (
    <>
      <Input
        className={classNames(classes.fastFindInput, className)}
        value={toString(inputValue || value)}
        label={label}
        required={required}
        disabled={disabled}
        error={inputError}
        testid={testid}
        onFocus={() => setIsOpen(true)}
        // TODO: validations for field
        rightButton={{
          icon: findIcon('caret-down', 'fas'),
          onClick: () => setIsOpen(true),
          testid: 'fast-find-dropdown',
        }}
      />
      <Modal
        className={classes.modal}
        headerClassName={classes.header}
        isOpen={isOpen}
        withTitleLine={false}
        title={modalTitle}
        setIsOpen={setIsOpen}
        forceFullHeight
        header={
          <div className={classes.searchWrapper}>
            <Searchbar
              className={classes.searchbar}
              value={searchQuery}
              setValue={setSearchQuery}
              testid="search-input"
            />
            {multiple && (
              <Button
                className={classNames(classes.clearButton, {
                  [classes.disabled]: size(stateValues) === 0,
                })}
                variant="secondary"
                text="Clear"
                onClick={() => setStateValues?.([])}
                disabled={size(stateValues) === 0}
                testid="clear-button"
              />
            )}
          </div>
        }
        footer={
          multiple && (
            <div className={classes.footer}>
              <Button
                variant="action"
                text="Save"
                onClick={() => {
                  setValues?.(stateValues);
                  setIsOpen?.(false);
                }}
                testid="apply-button"
              />
            </div>
          )
        }
        testid={testid}
      >
        <div className={classes.list}>
          {!error && !isEmpty(items) && (
            <Virtuoso
              className="ion-content-scroll-host"
              data={items}
              increaseViewportBy={{ top: 500, bottom: 500 }}
              endReached={enableInfiniteScroll ? fetchNextPage : undefined}
              itemContent={(index, item) => (
                <FastFindItem<ItemType>
                  key={`${toString(getId?.(item))}-${index}`}
                  searchQuery={toString(searchQuery)}
                  item={item}
                  getId={getId}
                  getTitle={getTitle}
                  getDescription={getDescription}
                  getLine={getLine}
                  getDetails={getDetails}
                  getAdditionalInfo={getAdditionalInfo}
                  multiple={multiple}
                  selected={
                    multiple
                      ? includes(stateValues, toString(getId?.(item)))
                      : toString(getId?.(item)) === toString(value)
                  }
                  onSelectItem={() => {
                    if (multiple) {
                      setStateValues?.((prev) =>
                        concat(prev, toString(getId?.(item)))
                      );
                    } else {
                      setIsOpen(false);
                      setValue?.(toString(getId?.(item)));
                      setSelectedItem?.(item);
                    }
                  }}
                  onDeselectItem={() => {
                    if (multiple) {
                      setStateValues?.((prev) =>
                        filter(
                          prev,
                          (v) => toString(v) !== toString(getId?.(item))
                        )
                      );
                    }
                  }}
                />
              )}
            />
          )}
          {!showLoader && isEmptyResponse && (
            <WarningMessage
              className={classes.warningMessage}
              icon={['far', 'info-circle']}
              title="No results for your search"
              body="Are you expecting data? Go online and download to sync with the server."
            />
          )}
          {error && (
            <WarningMessage
              className={classes.warningMessage}
              title="Error loading results"
              body={getErrorMessage(error)}
            />
          )}
          <Loader
            className={classes.loader}
            text="Loading results..."
            isOpen={showLoader}
          />
        </div>
      </Modal>
    </>
  );
}

export default FastFind;
