import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { tabsURL } from 'navigation';
import { UnlockMode, useVault } from 'providers/VaultProvider';
import useSignin from 'api/user/useSignin';
import UsernameStorage from 'storage/UsernameStorage';
import Button from 'components/Button/Button';
import ConfirmDialog from 'components/Modals/ConfirmDialog/ConfirmDialog';
import PasswordInput from 'components/PasswordInput/PasswordInput';
import Text from 'components/Text/Text';
import classes from './VaultChangerSettingsModal.module.scss';

interface VaultChangerSettingsModalProps {
  biometricSwitcher: boolean;
  customPinSwitcher: boolean;
  lockTitle: string;
  requestType: 'switch-vault' | 'change-pin' | '';
  setRequestType: (requestType: 'switch-vault' | 'change-pin' | '') => void;
  triggerResize: (d: number) => void;
  homeMenu?: boolean;
}

// TODO for the issue with modal not opening again on settings --> change pin, switch vault options (it's hard to replicate)

const VaultChangerSettingsModal = ({
  biometricSwitcher,
  customPinSwitcher,
  lockTitle,
  requestType,
  setRequestType,
  triggerResize,
  homeMenu = false,
}: VaultChangerSettingsModalProps): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();

  const [passwordDialogIsOpen, setPasswordDialogIsOpen] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [username, setUsername] = useState<string>('');
  const [changePin, setChangePin] = useState<boolean>(false);

  const { signin } = useSignin();

  const { isSystemPinEnabled, setAuthToken, updateUnlockMode } = useVault();

  useEffect(() => {
    if (requestType === 'change-pin') {
      setChangePin(true);
      setPasswordDialogIsOpen(false);
      setPassword('');
      setPasswordError('');
    } else if (requestType === 'switch-vault') {
      setPasswordDialogIsOpen(true);
      setChangePin(false);
      setPassword('');
      setPasswordError('');
    } else {
      setChangePin(false);
      setPasswordDialogIsOpen(false);
    }
  }, [requestType]);

  const changePinOrVaultType = async () => {
    setPasswordError('');
    setIsSubmitting(true);
    try {
      const { motionToken } = await signin({
        username,
        password,
      });
      setPasswordDialogIsOpen(false);
      setChangePin(false);
      if (requestType === 'change-pin') {
        await updateUnlockMode(UnlockMode.NeverLock);
        await updateUnlockMode(UnlockMode.CustomPIN);
        setRequestType('');
      } else if (biometricSwitcher) {
        setRequestType('');
        if (isSystemPinEnabled) {
          await updateUnlockMode(UnlockMode.BiometricsScreenLock);
        } else {
          await updateUnlockMode(UnlockMode.Biometrics);
        }
      } else if (customPinSwitcher) {
        setChangePin(false);
        setPasswordDialogIsOpen(false);
        setRequestType('');
        await updateUnlockMode(UnlockMode.CustomPIN);
      }

      if (motionToken) {
        await setAuthToken(motionToken);
        if (homeMenu) {
          history.replace(tabsURL());
        }
      }
      setIsSubmitting(false);
    } catch (e) {
      setPasswordError((e as Error).message);
      setIsSubmitting(false);
    }
    return true;
  };

  useEffect(() => {
    const getUserName = async () => {
      const storedUserName = await UsernameStorage.get();
      setUsername(storedUserName || '');
    };
    void getUserName();
  }, []);

  return (
    <ConfirmDialog
      isOpen={passwordDialogIsOpen || changePin}
      setIsOpen={
        requestType === 'change-pin' ? setChangePin : setPasswordDialogIsOpen
      }
      triggerResize={triggerResize}
      title={
        requestType === 'change-pin'
          ? t('settings:changePin')
          : t('login:biometricOnOff', {
              turn: t('turnOn'),
              title: lockTitle,
            })
      }
      testid="password-modal"
      onClose={() => {
        setPasswordDialogIsOpen(false);
        setChangePin(false);
        setRequestType('');
      }}
    >
      <Text
        className={classes.usernameText}
        variant="content-heavy"
        text={t('Username')}
      />
      <Text text={username} testid="confirm-dialog-username" />
      <PasswordInput
        className={classes.password}
        value={password}
        setValue={setPassword}
        error={passwordError}
        disabled={isSubmitting}
        label={t('password')}
        testid="password-input"
      />
      <Button
        className={classes.primaryButton}
        variant="action"
        text={
          changePin
            ? t('settings:changePin')
            : t('login:enableBio', {
                title: lockTitle,
              })
        }
        onClick={changePinOrVaultType}
        testid="set-password-primary-button"
      />
    </ConfirmDialog>
  );
};

export default VaultChangerSettingsModal;
