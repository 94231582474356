import React, { useState } from 'react';
import classNames from 'classnames';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { findIcon } from 'utils/icons';
import Input from 'components/Input/Input';
import classes from './PasswordInput.module.scss';

enum PasswordTypeEnum {
  password,
  text,
}

type PasswordInputType = keyof typeof PasswordTypeEnum;

const PasswordInput = ({
  className,
  label,
  name = 'password',
  disabled,
  placeholder,
  value,
  error,
  setValue,
  onBlur,
  testid,
}: React.ComponentProps<typeof Input> & IonicReactProps): JSX.Element => {
  const [passwordType, setPasswordType] =
    useState<PasswordInputType>('password');
  const isTypeText = passwordType === 'text';

  const onShowPassword = () => {
    setPasswordType(isTypeText ? 'password' : 'text');
  };

  return (
    <Input
      className={classNames(className, classes.password, {
        [classes.smallIcon]: isTypeText,
      })}
      label={label}
      name={name}
      placeholder={placeholder}
      type={passwordType}
      value={value}
      error={error}
      setValue={setValue}
      rightButton={{
        icon: findIcon(isTypeText ? 'eye' : 'eye-slash'),
        onClick: onShowPassword,
        testid: `${testid}-show-password-button`,
      }}
      onBlur={onBlur}
      disabled={disabled}
      testid={testid}
    />
  );
};

export default PasswordInput;
