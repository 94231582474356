import * as globalAxios from 'axios';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import type {
  SignInResponse,
  SignInHeaders,
  SignInDataResponse,
} from 'models/Signin';

export enum ModelLoginEnum {
  modelAR = 'modelAR',
  modelBM = 'modelBM',
  modelCAM = 'modelCAM',
}

interface SignInProps {
  username: string;
  password?: string;
  useToken?: boolean;
  modelAR?: boolean;
  modelBM?: boolean;
  modelCAM?: boolean;
}

interface UseSigninResponse {
  signin: (body: SignInProps) => Promise<SignInDataResponse>;
}

const useSignin = (): UseSigninResponse => {
  const { axios } = useAxios();
  const { loginAPI, loginTokenAPI } = useAPIUrl();

  const signin = async ({
    username,
    password,
    useToken,
    modelAR,
    modelBM,
    modelCAM,
  }: SignInProps): Promise<SignInDataResponse> => {
    try {
      const { data, headers } = await axios.post<SignInResponse>(
        useToken ? loginTokenAPI() : loginAPI(),
        {
          username,
          password,
          // TODO: temporary fix to test miproservices server
          ...(process.env.REACT_APP_IS_SERVICES === 'true'
            ? undefined
            : { useVirtualTeam: 'true', modelAR, modelBM, modelCAM }),
        }
      );
      const { 'motion-token': motionToken } =
        headers as unknown as SignInHeaders;

      if (!motionToken) {
        throw new Error('Motion token is missing');
      }

      return { data, motionToken };
    } catch (e) {
      // TODO: improve the handling of known error types to improve messaging to users
      if (globalAxios.default.isAxiosError(e)) {
        throw new Error(e.message);
      }
      throw e;
    }
  };

  return { signin };
};

export default useSignin;
