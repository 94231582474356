import { get, map, toString } from 'lodash';
import type { MutationStatus } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { useDevice } from 'providers/DeviceProvider';
import { useToasts } from 'providers/ToastProvider';
import { doPromiseAPI } from 'api/helpers';
import { ToastType } from 'models/Toast';

interface UseSendFeedbackBody {
  userName: string;
  userEmail: string;
  userComment: string;
  filesForUpload: File[];
}

interface UseSendFeedbackResponse {
  status: MutationStatus;
  onSendFeedback: (body: UseSendFeedbackBody) => void;
}

const useSendFeedback = (): UseSendFeedbackResponse => {
  const { axios } = useAxios();
  const { feedbackAPI } = useAPIUrl();
  const { addToast } = useToasts();
  const { deviceData, appVersion } = useDevice();
  const doSendFeedback = ({
    userName,
    userEmail,
    userComment,
    filesForUpload,
  }: UseSendFeedbackBody) => {
    return doPromiseAPI(async (cancelToken) => {
      const formData = new FormData();
      formData.append(
        'data',
        JSON.stringify({
          userName,
          userEmail,
          userComment,
          deviceInfo: JSON.stringify(deviceData) ?? '',
          appVersion: appVersion?.replace(/\n/g, ' '),
        })
      );
      await Promise.all(
        map(filesForUpload, async (file) => {
          const fileName = toString(get(file, 'fileName'));
          if (file.type.startsWith('image/')) {
            const fileData: ArrayBuffer = await new Promise((resolve) => {
              const reader = new FileReader();
              reader.onload = (fileResult) => {
                resolve(fileResult.target?.result as ArrayBuffer);
              };
              reader.readAsArrayBuffer(file);
            });
            const fileBlob = new Blob([new Uint8Array(fileData)], {
              type: file.type,
            });
            formData.append(fileName, fileBlob, fileName);
          } else {
            formData.append(fileName, file);
          }
        })
      );
      await axios.post(feedbackAPI(), formData, {
        cancelToken,
      });
    });
  };

  const { mutate, status } = useMutation(doSendFeedback, {
    onSuccess: () =>
      addToast({
        text: 'Your feedback has been sent.',
        // TODO undo operation
        testid: 'feedback-success-toast',
      }),
    onError: () =>
      addToast({
        type: ToastType.error,
        text: 'Send feedback operation failed. Please try again later.',
        testid: 'feedback-error-toast',
      }),
  });

  return {
    status,
    onSendFeedback: (body: UseSendFeedbackBody) => mutate(body),
  };
};

export default useSendFeedback;
