import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { toString } from 'lodash';
import List from 'common/components/List/List';
import Notebook from 'common/components/Notebook/Notebook';
import { useNodeRef } from 'common/components/utils/renderHelpers';
import useFindOrderNotes from 'ProductSearchApp/api/orders/useFindOrderNotes';
import classes from 'ProductSearchApp/components/CheckoutForm/CheckoutForm.module.scss';
import type { OcnTypeEnum } from 'ProductSearchApp/models/Order';
import { getErrorMessage } from 'utils/helpers';
import { goToNotebook } from 'navigation/navigationHelpers';
import Button from 'components/Button/Button';
import Text from 'components/Text/Text';

interface OrderNotesProps {
  viewMode: boolean;
  miLoc: string;
  orderCtlNo: string;
  customerNo: string;
  ocnType: OcnTypeEnum;
  ocnMode: 'review' | 'checkout';
}

const OrderNotes = ({
  viewMode,
  miLoc,
  orderCtlNo,
  customerNo,
  ocnType,
  ocnMode,
}: OrderNotesProps): JSX.Element => {
  const { t } = useTranslation();
  const { node, nodeRef } = useNodeRef();

  const {
    rows: orderNotes,
    isLoading: isLoadingNotes,
    isEmptyResponse,
    fetchNextPage,
    noMoreData,
    error: orderNotesError,
  } = useFindOrderNotes({ miLoc, ocn: orderCtlNo, lineNo: '0' });
  return (
    <div
      className={classNames(classes.cardWrapper, {
        [classes.noMargin]: viewMode,
      })}
      ref={nodeRef}
    >
      <div className={classes.flex}>
        <Text variant="mipro-h3-headline" text={t('activities:notebook')} />
        {!viewMode && (
          <Button
            testid="add--notes"
            leftIcon={['far', 'note-sticky']}
            variant="link"
            text={t('notes:addNote')}
            className={classes.addNoteBtn}
            href={goToNotebook({
              miLoc,
              shipToCustNo: customerNo,
              orderCtlNo,
              ocnType,
              ocnMode,
              headerNotes: true,
            })}
          />
        )}
      </div>
      <List
        isLoading={{
          isLoading: isLoadingNotes,
          text: t('notes:loadingActivityNotes'),
          testid: 'order--notes--list--loading',
        }}
        testid="order--notes--list"
        endReached={fetchNextPage}
        isEndOfList={noMoreData}
        isEmptyList={{
          isEmptyList: isEmptyResponse,
          title: t('notes:noActivityNotes'),
          className: classes.emptyMessage,
          testid: 'order--notes--empty--list',
        }}
        isError={{
          isError: !!orderNotesError,
          title: getErrorMessage(orderNotesError, t('notes:noActivityNotes')),
          testid: 'order--notes--list--error',
        }}
        data={orderNotes}
        itemContent={(itemIndex, item) => (
          <Notebook
            title={toString(item?.notebookFirstLine)}
            userName={toString(item?.lastUpdName)}
            lastUpdatedTimestamp={item.lastUpdTmstmp}
            testid={`order--notes--${itemIndex}`}
            key={`order--notes--${itemIndex}`}
            userId={item.lastUpdUserid}
            href={goToNotebook({
              miLoc,
              shipToCustNo: customerNo,
              orderCtlNo,
              ocnType,
              ocnMode,
              headerNotes: true,
              micro: item.micro,
              date: item.date,
              time: item.time,
              mode: viewMode ? 'view' : 'edit',
            })}
          />
        )}
        scrollParent={node}
      />
    </div>
  );
};

export default OrderNotes;
