import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toNumber, toString } from 'lodash';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { namespaces } from 'i18n/i18n.constants';
import i18next from 'i18next';
import useInviteContact from 'api/contacts/useInviteContact';
import useRemoveContact from 'api/contacts/useRemoveContact';
import type { Contact } from 'models/Contact';
import type { SearchItemType } from 'models/Search';
import showInvite from 'utils/contact';
import { DateFormatEnum, formatDate } from 'utils/date';
import { findIcon } from 'utils/icons';
import ContactModalV2 from 'components/Contacts/ContactModalV2/ContactModalV2';
import type Modal from 'components/Modal/Modal';
import ConfirmDialog from 'components/Modals/ConfirmDialog/ConfirmDialog';
import OptionsListModal from 'components/Modals/OptionsListModal/OptionsListModal';
import Text from 'components/Text/Text';
import classes from './ContactActionsModal.module.scss';

export interface ContactActionsModalProps {
  searchType: SearchItemType;
  headerLink?: string;
  contact?: Contact;
  activityData?: Record<string, unknown>;
  miLoc: string;
  id: string;
  inviteMiCom?: boolean;
}

const ContactActionsModal = ({
  searchType,
  isOpen,
  setIsOpen,
  contact,
  testid,
  miLoc,
  id,
  inviteMiCom,
}: ContactActionsModalProps &
  React.ComponentProps<typeof Modal> &
  IonicReactProps): JSX.Element => {
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isOpenRemoveModal, setIsOpenRemoveModal] = useState(false);
  const { t } = useTranslation(namespaces.contact);
  const type = contact?.type;
  const customerPhone = contact?.phone;
  const registrationStatus = contact?.registrationStatus;
  const webRegistered = contact?.webRegistered;
  const email = contact?.email;
  const isMainContact = contact?.isMainContact;
  const registrationCreationDate = contact?.registrationCreationDate;
  const sequenceNo = contact?.sequenceNo;
  const name = contact?.name;
  const isWebRegistered = contact?.webRegistered || contact?.isWebRegistered;

  const { removeContact } = useRemoveContact({
    searchType,
    miLoc,
    id,
    sequenceNo: toNumber(sequenceNo),
  });
  const onRemoveContact = () => removeContact({ name });

  const showInviteButton = showInvite(
    'customer',
    !!webRegistered,
    registrationStatus
  );
  const { inviteContact } = useInviteContact({
    searchType: 'customer',
    miLoc,
    id,
    sequenceNo,
  });
  const showResendButton =
    registrationStatus === 'SENT' || registrationStatus === 'EXPIRING' || false;

  let inviteIcon;
  let inviteMsg = '';
  let childElement: React.ReactNode | undefined;
  if (showInviteButton) {
    inviteIcon = findIcon('envelope-open-text');
    inviteMsg = t('inviteMotion');
    if (!inviteMiCom) {
      childElement = (
        <Text
          className={classes.contactInfo}
          variant="content-small"
          text={t('invitePunchoutMessage')}
          testid="contact-id"
        />
      );
    }
  } else if (showResendButton) {
    inviteIcon = findIcon('sync');
    inviteMsg = t('resendMotion');
    childElement = (
      <Text
        variant="label-micro"
        className={classes.contactInfo}
        text={t('inviteSentMessage', {
          formatDate: formatDate(
            registrationCreationDate,
            DateFormatEnum.fullDate,
            i18next.language
          ),
        })}
      />
    );
  }
  const [contactModalData, setContactModalData] =
    useState<
      Omit<
        React.ComponentProps<typeof ContactModalV2>,
        'isOpen' | 'setIsOpen' | 'searchType' | 'testid'
      >
    >();

  const handleEditContact = () => {
    setIsOpenEditModal(true);
    setIsOpen?.call(null, false);
    setContactModalData({
      miLoc,
      inviteMiCom,
      data: {
        sequenceNo: toNumber(sequenceNo),
        name,
        type,
        phone: customerPhone,
        email,
        isMainContact: isMainContact === 'Y',
      },
      showInvite: showInvite(searchType, !!isWebRegistered, registrationStatus),
      title: t('editContact'),
    });
  };

  const handleDeleteContact = () => {
    setIsOpenRemoveModal(true);
    setIsOpen?.call(null, false);
  };

  const handleInvite = () => {
    inviteContact({ name, resend: showResendButton });
    setIsOpen?.call(null, false);
  };

  return (
    <>
      <OptionsListModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={t('contactActions')}
        filterOptions={[
          {
            key: 'edit',
            name: t('editContact'),
            icon: findIcon('pen'),
            onClick: handleEditContact,
          },
          {
            key: 'delete',
            name: t('deleteContact'),
            icon: findIcon('trash'),
            onClick: handleDeleteContact,
          },
          ...(inviteMsg
            ? [
                {
                  key: 'invite',
                  name: '',
                  icon: inviteIcon,
                  onClick: handleInvite,
                  customContent: (
                    <div className={classes.inviteWrapper}>
                      <Text
                        className={classes.inviteText}
                        variant="content-heavy"
                        text={inviteMsg}
                      />
                      {childElement}
                    </div>
                  ),
                },
              ]
            : []),
        ]}
        testid={testid}
      />
      <ContactModalV2
        searchType={searchType}
        isOpen={isOpenEditModal}
        setIsOpen={setIsOpenEditModal}
        testid="contact-actions-edit-modal"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...contactModalData}
      />
      <ConfirmDialog
        isOpen={isOpenRemoveModal}
        setIsOpen={setIsOpenRemoveModal}
        title={t('deleteContact')}
        text={t('deleteContactTitle', { name: toString(name) })}
        primaryText={t('deleteContactNo')}
        secondaryText={t('deleteContactYes')}
        onSecondaryClick={onRemoveContact}
        testid="contact-actions-remove-modal"
      />
    </>
  );
};

export default ContactActionsModal;
