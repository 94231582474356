import { useTranslation } from 'react-i18next';
import type { AxiosError } from 'axios';
import {
  differenceBy,
  filter,
  find,
  forEach,
  includes,
  size,
  toString,
} from 'lodash';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { MutationStatus } from '@tanstack/react-query';
import useAPIUrl from 'api';
import useCountPlanDB from 'InventoryApp/database/useCountPlanDB';
import type {
  CountGroup,
  CountGroupItem,
} from 'InventoryApp/models/InventoryPlanGroup';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import {
  doPromiseAPI,
  onDownloadData,
  onErrorUpdate,
  onMutateUpdate,
} from 'api/helpers';
import { ToastType } from 'models/Toast';
import {
  findCountGroupItemsQueryKey,
  findCountGroupTotalItemsQueryKey,
} from './useFindCountGroupItems';
import { findCountGroupsQueryKey } from './useFindCountGroups';

interface DownloadGroupItemsBody {
  miLoc: string;
  countPlanId: string;
  groupId: string;
  groupUniqueId: string;
  storeroomNo: string;
  ignoreToast?: boolean;
  forceDeleteManual?: boolean;
}

interface UseDownloadGroupItemsResponse {
  status: MutationStatus;
  error?: AxiosError | null;
  onDownloadGroupItems: (body: DownloadGroupItemsBody) => void;
}

const useDownloadGroupItems = (): UseDownloadGroupItemsResponse => {
  const { axios } = useAxios();
  const queryClient = useQueryClient();
  const { countGroupItemsAPI } = useAPIUrl();
  const {
    findItemsByGroup,
    addItemsToCountPlan,
    removeCountGroupAllItems,
    updateCountGroups,
  } = useCountPlanDB();
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const doDownloadGroupItems = (body: DownloadGroupItemsBody) => {
    const { ignoreToast, forceDeleteManual, ...restBody } = body;
    return doPromiseAPI(async () => {
      const { syncData } = await onDownloadData<CountGroupItem>({
        customAxios: axios,
        method: 'get',
        getAPIUrl: countGroupItemsAPI,
        params: { ...restBody, planId: body.countPlanId },
      });
      const offlineItems: CountGroupItem[] = await findItemsByGroup(
        body.countPlanId,
        body.groupUniqueId
      );
      const syncItems: CountGroupItem[] = [];
      forEach(syncData, (item) => {
        const offlineItem = find(offlineItems, { uniqueId: item.uniqueId });
        syncItems.push({
          countPlanId: body.countPlanId,
          groupId: body.groupUniqueId,
          miLoc: body.miLoc,
          ...offlineItem,
          ...item,
          // TODO refactor actalCount to behave same as itemWidth/Length
          actualCount: offlineItem?.hasCount
            ? offlineItem?.actualCount
            : item.actualCount || '0',
          orderQty:
            (offlineItem?.hasCount ? offlineItem?.orderQty : item.orderQty) ||
            0,
          hasCount: offlineItem?.hasCount || item.updRec === 'Y' || false,
          actualItemWidth: offlineItem?.hasCount
            ? offlineItem?.actualItemWidth
            : item.actualItemWidth || 0,
          actualItemLength: offlineItem?.hasCount
            ? offlineItem?.actualItemLength
            : item.actualItemLength || 0,
          miMinQty: offlineItem?.hasEditedMinMax
            ? offlineItem.miMinQty
            : item.miMinQty,
          miMaxQty: offlineItem?.hasEditedMinMax
            ? offlineItem.miMaxQty
            : item.miMaxQty,
          hasLocalCount: forceDeleteManual
            ? false
            : !!offlineItem?.hasLocalCount,
        });
      });

      await removeCountGroupAllItems(body.countPlanId, body.groupUniqueId);
      const extraOfflineItems = differenceBy(
        offlineItems,
        syncData,
        'uniqueId'
      );

      const itemsDeletedList = extraOfflineItems.filter(
        (item) =>
          !(item.type === 'M' && includes([null, undefined, ''], item.itemNo))
      );
      let itemsToInsert = syncItems;

      if (!forceDeleteManual) {
        const manualUnsyncedItems = extraOfflineItems.filter(
          (item) =>
            item.type === 'M' && includes([null, undefined, ''], item.itemNo)
        );
        itemsToInsert = [...syncItems, ...manualUnsyncedItems];
      }

      const countedItems = filter(itemsToInsert, (item) => !!item.hasCount);
      const lines = toString(size(itemsToInsert));
      const linesUpdated = toString(size(countedItems));

      await addItemsToCountPlan(itemsToInsert);
      await updateCountGroups([
        {
          groupUniqueId: body.groupUniqueId,
          lines,
          linesUpdated,
          downloadedItems: size(syncItems),
        },
      ]);

      if (!ignoreToast) {
        const itemsAddedList = differenceBy(syncData, offlineItems, 'uniqueId');

        if (size(itemsAddedList)) {
          addToast({
            text: t('inventory:itemsUpdatedtoGroupMsg', {
              noOfItems: size(itemsAddedList),
            }),
            testid: 'items-added-local-group-toast',
          });
        }
        if (size(itemsDeletedList)) {
          addToast({
            text: t('inventory:itemsDeletedFromGroupMsg', {
              noOfItems: size(itemsDeletedList),
            }),
            testid: 'items-deleted-local-group-toast',
          });
        }
      }

      return { lines, linesUpdated };
    });
  };

  const { status, error, mutate } = useMutation(doDownloadGroupItems, {
    onMutate: (vars) =>
      onMutateUpdate<CountGroup>({
        queryClient,
        queryKey: findCountGroupsQueryKey,
        updatedItems: [
          {
            uniqueId: vars.groupUniqueId,
            downloadedItems: 1,
          } as CountGroup,
        ],
        dataPath: 'items',
        markAsUpdated: false,
        findPredicates: [{ uniqueId: vars.groupUniqueId }],
        isInfiniteQuery: true,
      }),
    onSuccess: ({ lines, linesUpdated }, vars) => {
      const queryParams = {
        countPlanId: vars.countPlanId,
        groupId: vars.groupUniqueId,
      };
      void queryClient.removeQueries({
        queryKey: [findCountGroupItemsQueryKey, queryParams],
      });
      void queryClient.removeQueries({
        queryKey: [findCountGroupTotalItemsQueryKey, queryParams],
      });
      void onMutateUpdate<CountGroup>({
        queryClient,
        queryKey: findCountGroupsQueryKey,
        updatedItems: [
          {
            uniqueId: vars.groupUniqueId,
            lines,
            linesUpdated,
          } as CountGroup,
        ],
        dataPath: 'items',
        markAsUpdated: false,
        findPredicates: [{ uniqueId: vars.groupUniqueId }],
        isInfiniteQuery: true,
      });
    },
    onError: (e, vars, context) => {
      addToast({
        type: ToastType.error,
        text: t('inventory:downloadingDataError'),
        testid: 'download-items-error-toast',
      });
      onErrorUpdate<CountGroup>({
        queryClient,
        context,
        dataPath: 'items',
        isInfiniteQuery: true,
      });
    },
  });

  return {
    status,
    error: error as AxiosError,
    onDownloadGroupItems: (body: DownloadGroupItemsBody) => mutate(body),
  };
};

export default useDownloadGroupItems;
