/* istanbul ignore file */

import type { ReportJson } from './InspectionReport';

export interface DocumentTemplate {
  name: string;
  status: 'A' | 'I';
  templateId: number;
  templateName: string;
  templateType: string;
  templateVersion: number;
  templateSections: TemplateSection[];
  templateImages?: TemplateImage[];
  isDownloaded?: boolean;
}

export interface TemplateImage {
  imageName: string;
  src: string;
  lastUpdTmstmp: string;
}

export interface TemplateSection {
  displaySequence: number;
  sectionId: number;
  templateGroups: TemplateGroup[];
}

export const GENERIC_TYPE = 'GN';

export interface TemplateGroup {
  displaySequence: string;
  groupId: number;
  sectionId: number;
  templateId: number;
  type: 'H1' | 'H2' | 'P' | 'CN' | 'IM' | 'PL' | 'RC' | 'GN';
  title?: string;
  layoutAttributes?: string;
  templateGroupInputs?: GroupInput[];
}

export interface GroupInput {
  inputId: number;
  status: 'A' | 'I';
  inputTypeId:
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15 // signature
    | 16 // fastfind
    | 17; // images
  displaySequence: number;
  label: string;
  required: 'Y' | 'N';
  inputOptions?: InputOption[];
  rcObject?: RecursiveObject;
  name: string;
  reportSpecificValues?: ReportJson;
  additionalInformation?: string;
  inputMask?: string;
  multiple?: boolean;
  fields?: GroupInput[];
  tableLayout?: TableLayout;
  type?: string;
  values?: string[];
  defaultValue?: string;
}

export interface TableLayout {
  headers: string[];
  rowNumber: number;
  columns: GroupInput[];
}

export interface RecursiveObject {
  displaySequence: number;
  multiple: boolean;
  title: string;
  type: string;
  fields?: GroupInput[];
}

export interface InputOption {
  optionId: number;
  displaySequence: number;
  displayValue: string;
  value: string;
}

// export interface CreateDocumentURLParams {
//   templateId: string;
//   name: string;
// }

export interface DocumentTemplates {
  templateId: number;
  templateName: string;
  templateType: string;
  templateVersion: number;
  status: string;
}
