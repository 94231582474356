/* eslint-disable no-console */
import { map, size } from 'lodash';
import type { FastFindQueryParams } from 'DocumentsApp/models/FastFind';
import type { WorkTask } from 'DocumentsApp/models/WorkTask';
import useDocumentsDB from './initDocumentsDB';

interface UseWorkTasksDBResponse {
  createWorkTasks: (workTasks: WorkTask[]) => Promise<void>;
  findWorkTasks: (props: FastFindQueryParams) => Promise<WorkTask[]>;
  removeWorkTasks: (miLoc: string) => Promise<void>;
}

const useWorkTasksDB = (): UseWorkTasksDBResponse => {
  const { db, openDB, closeDB, getLikeStatement, getWhereStatement } =
    useDocumentsDB();

  const createWorkTasks = async (workTasks: WorkTask[]): Promise<void> => {
    try {
      await openDB();
      const statements = map(workTasks, (workTask) => ({
        statement: `INSERT OR REPLACE INTO work_tasks (
          rowStatus,
          codeDesc,
          codeValue,
          miLoc
          ) VALUES (?,?,?,?)`,
        values: [
          workTask.rowStatus,
          workTask.codeDesc,
          workTask.codeValue,
          workTask.miLoc,
        ],
      }));
      if (size(statements) > 0) {
        await db.executeSet(statements, true);
      }
    } catch (error) {
      console.log('Error saving work tasks to database', error);
      throw new Error('Error saving work tasks to database');
    } finally {
      await closeDB();
    }
  };

  const findWorkTasks = async ({
    fastFind,
    miLoc,
  }: FastFindQueryParams): Promise<WorkTask[]> => {
    try {
      const whereStatement = [];
      const vars = [];
      if (fastFind) {
        whereStatement.push(
          getLikeStatement(['rowStatus', 'codeDesc', 'codeValue'])
        );
        const likeFastFind = `%${fastFind}%`;
        vars.push(likeFastFind, likeFastFind, likeFastFind);
      }
      if (miLoc) {
        whereStatement.push(getLikeStatement('miLoc', vars));
        vars.push(`%${miLoc}%`);
      }
      await openDB();
      return (
        await db.query(
          `SELECT * FROM work_tasks
          ${getWhereStatement(whereStatement)}`,
          vars
        )
      ).values as WorkTask[];
    } catch (error) {
      console.log('Error loading work tasks', error);
      throw new Error('Error loading work tasks');
    } finally {
      await closeDB();
    }
  };

  const removeWorkTasks = async (miLoc: string): Promise<void> => {
    try {
      await openDB();
      await db.query(
        `DELETE FROM work_tasks
        WHERE miLoc = ?`,
        [miLoc]
      );
    } catch (error) {
      console.log('Error removing work tasks', error);
      throw new Error('Error removing work tasks');
    } finally {
      await closeDB();
    }
  };

  return {
    createWorkTasks,
    findWorkTasks,
    removeWorkTasks,
  };
};

export default useWorkTasksDB;
