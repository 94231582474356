import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import type { MutationStatus } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import type { Order } from 'ProductSearchApp/models/Order';
import type { SubmitOCNBody } from 'ProductSearchApp/models/Products';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import { onSuccessMutation, useKeyUserId } from 'api/helpers';
import { ToastType } from 'models/Toast';
import type { RootState } from 'store/reducers';
import { clearCurrentCartCustomer } from 'store/user';
import { removeLeadingZeros } from 'utils/number';
import { findOrdersQueryKey } from './orders/useFindOrders';
import { findTransmissionLogsQueryKey } from './orders/useFindTransmissionLogs';
import { getOrderQueryKey } from './useGetOrder';

interface UseSubmitOCNResponse {
  status: MutationStatus;
  onSubmitOCN: (body: SubmitOCNBody) => void;
}

const useSubmitOCN = (isOrder: boolean): UseSubmitOCNResponse => {
  const { axios } = useAxios();
  const { saveAsQuoteAPI, saveAsOrderAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const { createQueryKey } = useKeyUserId();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { currentCartCustomer } = useSelector((state: RootState) => state.user);
  const apiUrl = isOrder ? saveAsOrderAPI() : saveAsQuoteAPI();

  const doSubmitOCN = async (body: SubmitOCNBody) => {
    const { data } = await axios.post<Order>(apiUrl, body);
    return data;
  };

  const { status, mutate } = useMutation(doSubmitOCN, {
    onSuccess: (data, { miLoc, orderCtlNo }) => {
      void onSuccessMutation(queryClient, findOrdersQueryKey);
      void onSuccessMutation(queryClient, findTransmissionLogsQueryKey, {
        miLoc,
        orderCtlNo,
      });
      // TODO create utils to update query data
      queryClient.setQueryData<Order>(
        createQueryKey(getOrderQueryKey, {
          miLoc: currentCartCustomer?.miLoc,
          orderCtlNo: currentCartCustomer?.orderCtlNo,
        }),
        data
      );
      if (
        miLoc === currentCartCustomer?.miLoc &&
        orderCtlNo === currentCartCustomer?.orderCtlNo
      ) {
        dispatch(clearCurrentCartCustomer());
      }
      let successToastMsg = t('productSearch:review:saveAsQuoteSuccessToast', {
        ocn: removeLeadingZeros(orderCtlNo),
      });
      if (isOrder) {
        successToastMsg = t('productSearch:review:saveAsOrderSuccessToast', {
          ocn: removeLeadingZeros(orderCtlNo),
        });
      }
      addToast({
        variant: 'mipro-toast',
        text: successToastMsg,
        testid: 'submit-ocn-success-toast',
      });
    },
    onError: () => {
      let failureToastMsg = t('productSearch:review:saveAsQuoteFailedToast');
      if (isOrder) {
        failureToastMsg = t('productSearch:review:saveAsOrderFailedToast');
      }
      addToast({
        type: ToastType.error,
        text: failureToastMsg,
        testid: 'submit-ocn-failure-toast',
      });
    },
  });

  return {
    status,
    onSubmitOCN: (body: SubmitOCNBody) => mutate(body),
  };
};

export default useSubmitOCN;
