import type { AxiosError } from 'axios';
import { isNil, toString } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { startOfToday } from 'date-fns';
import { useAxios } from 'providers/AxiosProvider';
import { doPromiseAPI, useKeyUserId } from 'api/helpers';
import type { CalendarDay } from 'models/Reports';
import type { QueryFlags } from 'models/Search';
import {
  DateFormatEnum,
  formatDate,
  getReportsCalendarEndDate,
  getReportsCalendarStartDate,
} from 'utils/date';

const initStartDate = getReportsCalendarStartDate(startOfToday());
const initEndDate = getReportsCalendarEndDate(startOfToday());

export const getCalendarDaysQueryKey = 'calendar';

interface UseGetCalendarDaysProps {
  startDate?: Date | number | string;
  endDate?: Date | number | string;
}

interface UseGetCalendarDaysResponse {
  data?: CalendarDay[];
}

const useGetCalendarDays = ({
  startDate = initStartDate,
  endDate = initEndDate,
}: UseGetCalendarDaysProps): UseGetCalendarDaysResponse & QueryFlags => {
  const { axios } = useAxios();
  const { getCalendarAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const params: Record<string, string> = {
    dateFrom: formatDate(startDate, DateFormatEnum.activitiesDateRangeAPI),
    dateTo: formatDate(endDate, DateFormatEnum.activitiesDateRangeAPI),
  };

  const doGetCalendarDays = () => {
    return doPromiseAPI<CalendarDay[]>(async (cancelToken) => {
      const { data } = await axios.get<CalendarDay[]>(
        getCalendarAPI(toString(new URLSearchParams(params))),
        { cancelToken }
      );
      return data;
    });
  };

  const { data, error, status, isFetching } = useQuery<
    CalendarDay[],
    AxiosError
  >(createQueryKey(getCalendarDaysQueryKey, params), doGetCalendarDays);

  return {
    data,
    error,
    isLoading: status === 'loading' || (isFetching && isNil(data)),
  };
};

export default useGetCalendarDays;
