import React, { useMemo } from 'react';
import classNames from 'classnames';
import {
  forEach,
  head,
  isArray,
  isNil,
  join,
  map,
  size,
  toInteger,
  toString,
  escapeRegExp,
  isEmpty,
  filter,
} from 'lodash';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import classes from './Text.module.scss';

enum TextVariantTypeEnum {
  'underlined-title-section',
  'title-screen-section',
  'title-action-card',
  'title-info-card',
  'title-info-card-read',
  'title-info-card-activity',
  'content-default',
  'content-heavy',
  'content-small',
  'content-smaller',
  'content-small-heavy',
  'content-small-unread',
  'label-micro-unread',
  'label-micro',
  'label-micro-italic',
  'label-header',
  'label-header-micro',
  'title-card-red',
  'announcement-subtitle',
  'mipro-h1-headline',
  'mipro-h2-headline',
  'mipro-h3-headline',
  'mipro-h6-headline',
  'mipro-body-copy',
  'mipro-body-copy-bold',
  'mipro-report-info',
  'mipro-product-headline',
  'mipro-h4-headline',
  'list-item-overlay',
  'list-item-title',
  'list-item-subtitle',
  'list-item-secondaryText',
}

export type TextVariantType = keyof typeof TextVariantTypeEnum;

export interface TextQueryProps {
  query: string;
  className?: string;
}

export type TextQueryType = string | (TextQueryProps | string)[];

interface TextProps {
  variant?: TextVariantType;
  text?: string;
  textQuery?: TextQueryType;
  testid?: string;
}

interface TokenType {
  value: string;
  isBolded?: boolean;
  className?: string;
}

const Text = ({
  className,
  variant = 'content-default',
  text = '',
  textQuery = '',
  testid,
}: TextProps & IonicReactProps): JSX.Element => {
  const textQueries = useMemo(() => {
    let queries: TextQueryProps[] = [];
    if (!isArray(textQuery)) {
      if (!isEmpty(textQuery)) {
        textQuery.split(' ').forEach((query) => {
          if (!isEmpty(query)) {
            queries.push({ query });
          }
        });
      }
    } else {
      queries = map(
        filter(textQuery, (query) => {
          if (typeof query === 'string') {
            return !isEmpty(query);
          }
          return !isEmpty(query.query);
        }),
        (query) => {
          if (typeof query === 'string') {
            return { query };
          }
          return query;
        }
      );
    }
    if (size(queries) === 0) {
      return [];
    }
    return queries;
  }, [textQuery]);

  const tokens = useMemo(() => {
    const rTokens: TokenType[] = [];
    if (size(textQueries) > 0) {
      const textRegExp = new RegExp(
        join(
          map(
            textQueries,
            ({ query }, index) => `(?<g${index}>${escapeRegExp(query)})`
          ),
          '|'
        ),
        'gi'
      );
      let match = textRegExp.exec(text);
      let lastIndex = 0;
      rTokens.push({ value: text });

      while (match !== null) {
        let matchQuery: undefined | TextQueryProps;
        forEach(match.groups, (v, key) => {
          if (!isNil(v)) {
            matchQuery = textQueries[toInteger(key.replace('g', ''))];
          }
        });
        rTokens.pop();
        rTokens.push({ value: text.substring(lastIndex, match.index) });
        rTokens.push({
          value: toString(head(match)),
          isBolded: true,
          className: matchQuery?.className,
        });
        lastIndex = textRegExp.lastIndex;
        rTokens.push({ value: text.substring(textRegExp.lastIndex) });
        match = textRegExp.exec(text);
      }
    }
    return rTokens;
  }, [text, textQueries]);

  return (
    <span
      className={classNames(className, classes[variant])}
      data-testid={testid}
      id={testid}
    >
      {size(textQueries) > 0
        ? map(tokens, ({ value, isBolded, className: bClassName }, index) => (
            <React.Fragment key={index}>
              {isBolded ? <b className={bClassName}>{value}</b> : value}
            </React.Fragment>
          ))
        : text}
    </span>
  );
};

export default Text;
