import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import type { Dictionary } from 'lodash';
import { filter, get, map, reduce, size, toNumber, toString } from 'lodash';
import { IonLoading, IonRow } from '@ionic/react';
import { useQueryClient } from '@tanstack/react-query';
import { DataTypeEnum } from 'common/utils/valueFormatter';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import { useToasts } from 'providers/ToastProvider';
import { findIssuesQueryKey } from 'StoreroomsApp/api/useFindIssues';
import useUploadIssues from 'StoreroomsApp/api/useUploadIssues';
import useIssueDB from 'StoreroomsApp/database/useIssueDB';
import type { ItemIssueDTO, Issue } from 'StoreroomsApp/models/Issue';
import { onSuccessMutation } from 'api/helpers';
import type { RootState } from 'store/reducers';
import { formatCardDate } from 'utils/date';
import { downloadFile } from 'utils/helpers';
import { findIcon } from 'utils/icons';
import ActionCardModalData from 'components/Activities/ActionCardModal/ActionCardModalData';
import Button from 'components/Button/Button';
import SheetModal from 'components/Modals/SheetModal/SheetModal';
import Text from 'components/Text/Text';
import classes from './IssueItem.module.scss';

interface IssueItemProps {
  profile: Dictionary<unknown>;
  issue: Issue;
}

const IssueItem = ({ profile, issue }: IssueItemProps): JSX.Element => {
  const { isOnline } = useNetworkStatus();
  const { addToast } = useToasts();
  const queryClient = useQueryClient();
  const { findItemsFromIssue, closeIssue } = useIssueDB();
  const { storeroom = '' } = useSelector((state: RootState) => state.user);
  const [uploadPressed, setUploadPressed] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const [items, setItems] = useState<ItemIssueDTO[]>([]);

  const dataDefinition = useMemo(
    () =>
      reduce(
        Object.keys(profile),
        (prev, key) => {
          if (key.startsWith('chargeBackDescription')) {
            const index = key.replace('chargeBackDescription', '');
            const label = toString(profile[`chargeBackDescription${index}`]);
            if (label) {
              return {
                ...prev,
                [label]: { label, type: DataTypeEnum.string },
              };
            }
          }
          return prev;
        },
        {}
      ),
    [profile]
  );

  useEffect(() => {
    const doFindIssues = async () => {
      setItems(await findItemsFromIssue(toString(issue.id)));
    };
    void doFindIssues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [issue.id]);

  const {
    onUploadIssues,
    isLoading: uploadLoading,
    status: uploadStatus,
  } = useUploadIssues();

  const uploadIsLoading = uploadLoading || uploadPressed;
  const hasError = size(filter(items, (item) => item.error)) > 0;

  useEffect(() => {
    if (uploadStatus !== 'loading') {
      setUploadPressed(false);
    }
  }, [uploadStatus]);

  return (
    <div className={classes.issueRow}>
      <IonLoading
        isOpen={uploadIsLoading}
        message="Uploading issue in progress. This could take a couple minutes."
      />
      <Button
        variant="clear"
        onClick={() => setExpanded((prev) => !prev)}
        testid="expand-button"
      >
        <div className={classes.issueHeader}>
          <Text
            text={`Issue created on ${formatCardDate(
              issue.creationTimestamp,
              false,
              false
            )} (${toNumber(issue.pendingItems)} items)`}
          />
          {hasError && (
            <Text className={classes.itemError} text="Upload Error" />
          )}
          <ActionCardModalData
            className={classes.additionalData}
            activityData={reduce(
              Object.keys(profile),
              (prev, key) => {
                if (key.startsWith('chargeBackDescription')) {
                  const index = key.replace('chargeBackDescription', '');
                  const label = toString(
                    profile[`chargeBackDescription${index}`]
                  );
                  if (label) {
                    return {
                      ...prev,
                      [label]: toString(get(issue, `chargeBack${index}`)),
                    };
                  }
                }
                return prev;
              },
              {}
            )}
            dataDefinition={dataDefinition}
            labelClassName={classes.moreInfoLabel}
          />
        </div>
      </Button>
      {expanded && (
        <>
          <IonRow className={classes.actions}>
            <Button
              testid="upload-issue-button"
              variant="icon-action"
              text="Upload"
              onClick={() => {
                onUploadIssues({
                  issueId: toString(issue.id),
                  storeroomNumber: storeroom,
                });
              }}
              icon={findIcon('upload')}
              disabled={!isOnline || uploadIsLoading}
            />
            <Button
              className={classes.downloadButton}
              testid="download-issue-button"
              variant="icon-action"
              text="Download"
              onClick={async () => {
                await downloadFile(
                  `${Date.now()}-mipro-issue-${toString(issue.id)}.json`,
                  'application/json',
                  JSON.stringify({ issue, profile, items }),
                  addToast,
                  'Issue has been saved to device.'
                );
              }}
              icon={findIcon('download')}
            />
            <Button
              className={classes.downloadButton}
              testid="download-issue-button"
              variant="icon-action"
              text="Remove"
              onClick={() => setDeleteIsOpen(true)}
              icon={findIcon('times')}
            />
            <SheetModal
              title="Remove Issue"
              titleTextVariant="mipro-h3-headline"
              className={classes.discardModal}
              titleClassName={classes.discardTitle}
              contentClass={classes.discardContent}
              isOpen={deleteIsOpen}
              setIsOpen={setDeleteIsOpen}
              initialBreakpoint={0.5}
              backdropDismiss={false}
              withRightCloseButton
              testid="discard-changes-modal"
            >
              <Text
                className={classes.discardMsg}
                variant="mipro-body-copy"
                text="Are you sure you want to remove the issue? All offline data will be lost."
              />
              <Button
                variant="action"
                text="Yes"
                onClick={async () => {
                  setDeleteIsOpen(false);
                  await closeIssue(toNumber(issue.id));
                  void onSuccessMutation(queryClient, findIssuesQueryKey);
                  addToast({
                    text: 'Issue has been deleted.',
                    testid: 'remove-success-toast',
                  });
                }}
                testid="discard-primary-button"
              />
              <Button
                variant="secondary"
                text="No"
                onClick={() => setDeleteIsOpen(false)}
                testid="discard-secondary-button"
              />
            </SheetModal>
          </IonRow>
          <div>
            {map(items, (item, index) => (
              <IonRow
                className={classes.itemRow}
                key={`${item.combinedId}-${index}`}
              >
                <IonRow className={classes.itemHeader}>
                  <Text text={item.customerStockNumber || '<No CSN>'} />
                  <Text text={item.balanceOnHandQuantity} />
                  <Text text={toString(item.issueQuantity)} />
                </IonRow>
                <Text text={item.itemDescription} />
                <Text text={item.binLocation} />
                {item.error && (
                  <Text className={classes.itemError} text={item.error} />
                )}
              </IonRow>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default IssueItem;
