import { get, toString } from 'lodash';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import type { UpdateMutationContext } from 'api/helpers';
import {
  doPromiseAPI,
  onErrorUpdate,
  onMutateUpdate,
  onSuccessMutation,
} from 'api/helpers';
import useAPIUrl from 'api/index';
import { findNotesQueryKey } from 'api/notebooks/useFindNotes';
import type { FileAttachmentProp } from 'models/Attachment';
import { ToastType } from 'models/Toast';
import { findAttachmentsQueryKey } from './useFindAttachments';

interface UseUploadAttachmentProps {
  domain: string;
  miLoc?: string;
  ctlNo?: string;
}

interface UploadAttachmentBody {
  file: File;
  miLoc?: string;
  ctlNo?: string;
  lineNo: string;
  src?: string;
  miOnly?: string;
  description?: string;
}

interface UseUploadAttachmentResponse {
  uploadAttachment: (body: UploadAttachmentBody) => void;
}

const useUploadFileAttachment = ({
  domain,
  miLoc = '',
  ctlNo = '',
}: UseUploadAttachmentProps): UseUploadAttachmentResponse => {
  const { axios } = useAxios();
  const { uploadAttachmentAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  const doUploadAttachment = ({
    file,
    miLoc: itemMiLoc,
    ctlNo: itemCtlNo,
    lineNo,
    miOnly,
    description,
  }: UploadAttachmentBody) => {
    return doPromiseAPI(async (cancelToken) => {
      const formData = new FormData();
      const fileName = toString(get(file, 'fileName'));
      if (file.type.startsWith('image/')) {
        const fileData: ArrayBuffer = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = (fileResult) => {
            resolve(fileResult.target?.result as ArrayBuffer);
          };
          reader.readAsArrayBuffer(file);
        });
        const fileBlob = new Blob([new Uint8Array(fileData)], {
          type: file.type,
        });
        formData.append(fileName, fileBlob, fileName);
      } else {
        formData.append(fileName, file);
      }
      formData.append('data', JSON.stringify({ miOnly, description }));

      let miLocation = itemMiLoc;
      miLocation ||= miLoc;

      let ctlNumber = itemCtlNo;
      ctlNumber ||= ctlNo;

      await axios.post(
        uploadAttachmentAPI(domain, miLocation, ctlNumber, lineNo),
        formData,
        { cancelToken }
      );
    });
  };

  const { mutate } = useMutation(doUploadAttachment, {
    onMutate: async (vars) => {
      let miLocation = vars.miLoc;
      miLocation ||= miLoc;
      let ctlNumber = vars.ctlNo;
      ctlNumber ||= ctlNo;
      return onMutateUpdate<FileAttachmentProp>({
        queryClient,
        queryKey: findAttachmentsQueryKey,
        queryKeyParams: { domain, miLoc, ctlNo },
        newItems: [
          {
            ENTITY: domain,
            MI_LOC: miLocation,
            CTL_NO: ctlNumber,
            LINE_NO: vars.lineNo,
            SEQ_NO: '',
            FILE_NAME: toString(get(vars.file, 'fileName')),
            src: vars.src,
            forUpload: true,
            MI_ONLY: vars.miOnly,
            description: vars.description,
          },
        ],
        isArrayQuery: true,
      });
    },
    onSuccess: async () => {
      void onSuccessMutation(queryClient, findAttachmentsQueryKey, {
        domain,
        miLoc,
        ctlNo,
      });
      if (domain === 'customer') {
        // DOC: refresh again after attachments upload
        // TODO: API should batch this operation
        await onSuccessMutation(queryClient, findNotesQueryKey, {
          miLoc,
          id: ctlNo,
        });
      }
    },
    onError: (error, vars, context) => {
      onErrorUpdate<FileAttachmentProp>({
        queryClient,
        context: context as UpdateMutationContext<FileAttachmentProp>[],
        isArrayQuery: true,
      });
      addToast({
        type: ToastType.error,
        text: 'Upload attachment operation failed.',
        testid: 'upload-notebook-attachment-error-toast',
      });
    },
  });

  return {
    uploadAttachment: (body) => mutate(body),
  };
};

export default useUploadFileAttachment;
