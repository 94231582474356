import { useMemo } from 'react';
import type { AxiosError } from 'axios';
import type { Dictionary } from 'lodash';
import { isEmpty, map, size, toString } from 'lodash';
import { useInfiniteQuery } from '@tanstack/react-query';
import type { InfiniteQueryObserverResult } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import {
  doConcatDataPages,
  doPromiseAPI,
  useKeyUserId,
  useMiLocOrTeamId,
} from 'api/helpers';
import type {
  FindSuppliersAPIResponse,
  SearchItem,
  SearchResponse,
  InfiniteQueryFlags,
} from 'models/Search';
import { pageSize } from 'utils/constants';

export const findSuppliersQueryKey = 'search-suppliers';

interface UseFindSuppliersProps {
  query?: string;
  sortCol?: string;
  sortDir?: string;
  limit?: number;
  enabled?: boolean;
}

export type UseFindSuppliersResponse = SearchResponse &
  InfiniteQueryFlags &
  Pick<InfiniteQueryObserverResult<SearchResponse>, 'data'>;

const useFindSuppliers = ({
  query = '',
  sortCol = 'supLocName',
  sortDir = 'ASC',
  limit = pageSize(),
  enabled = true,
}: UseFindSuppliersProps): UseFindSuppliersResponse => {
  const { axios } = useAxios();
  const { findSuppliersAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const { createParams } = useMiLocOrTeamId({ sendTeamId: false });
  const params: Dictionary<string> = {
    ...createParams(),
    query: toString(query),
    sortCol,
    sortDir,
    limit: toString(limit),
  };

  const doFindSuppliers = ({ pageParam = 1 }) => {
    return doPromiseAPI<SearchResponse>(async (cancelToken) => {
      const { data } = await axios.get<FindSuppliersAPIResponse>(
        findSuppliersAPI(
          toString(
            new URLSearchParams({ ...params, page: toString(pageParam) })
          )
        ),
        { cancelToken }
      );
      return {
        total: data.totalRecords,
        items: map(data.records, ({ supLocNo, supLocName = '', address }) => ({
          type: 'supplier',
          id: supLocNo,
          text: supLocName,
          address,
        })),
      };
    });
  };

  const response = useInfiniteQuery<SearchResponse, AxiosError>(
    createQueryKey(findSuppliersQueryKey, params),
    doFindSuppliers,
    {
      enabled: !isEmpty(query) && enabled,
      getNextPageParam: (lastPage, pages) =>
        size(lastPage.items) < limit ? false : size(pages) + 1,
    }
  );

  const {
    data,
    error,
    status,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = response;

  const suppliers = useMemo(
    () => doConcatDataPages<SearchItem, SearchResponse>(data, 'items'),
    [data]
  );

  const hasItems = size(suppliers) > 0;
  const hasError = status === 'error';
  const isEmptyResponse = status === 'success' && !hasNextPage && !hasItems;
  const noMoreData = status === 'success' && !hasNextPage && hasItems;
  const showLoader = status === 'loading' || isFetchingNextPage;
  const enableInfiniteScroll = !(!hasNextPage || isFetchingNextPage);

  return {
    data,
    fetchNextPage: async () => {
      await fetchNextPage();
    },
    items: suppliers,
    total: !showLoader ? data?.pages[0].total : undefined,
    error,
    hasError,
    showLoader,
    isEmptyResponse,
    noMoreData,
    enableInfiniteScroll,
    refetch: async () => {
      await refetch?.call(null);
    },
  };
};

export default useFindSuppliers;
