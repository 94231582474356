import { useTranslation } from 'react-i18next';
import type { AxiosError } from 'axios';
import type { MutationStatus } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import useAPIUrl from 'api';
import useCountPlanDB from 'InventoryApp/database/useCountPlanDB';
import type { CountPlanOptions } from 'InventoryApp/models/InventoryPlanGroup';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import { doPromiseAPI } from 'api/helpers';
import { ToastType } from 'models/Toast';

interface UseDownloadCountPlanOptionsRequest {
  countPlanId: string;
}
interface UseDownloadCountPlanOptionsResponse {
  status: MutationStatus;
  error?: AxiosError | null;
  onDownloadCountPlanOptions: (
    body: UseDownloadCountPlanOptionsRequest
  ) => void;
}

const useDownloadCountPlanOptions = (): UseDownloadCountPlanOptionsResponse => {
  const { axios } = useAxios();
  const { getCountPlanOptionsAPI } = useAPIUrl();
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const { createCountPlanOptions } = useCountPlanDB();

  const doDownloadCountPlanOptions = (
    body: UseDownloadCountPlanOptionsRequest
  ) => {
    return doPromiseAPI<CountPlanOptions>(async (cancelToken) => {
      const { data } = await axios.get<CountPlanOptions>(
        getCountPlanOptionsAPI(body.countPlanId),
        { cancelToken }
      );
      await createCountPlanOptions({ ...data, countPlanId: body.countPlanId });
      return data;
    });
  };

  const { status, error, mutate } = useMutation(doDownloadCountPlanOptions, {
    onError: () => {
      addToast({
        type: ToastType.error,
        text: t('inventory:downloadingDataError'),
        testid: 'download-items-error-toast',
      });
    },
  });

  return {
    status,
    error: error as AxiosError,
    onDownloadCountPlanOptions: (body: UseDownloadCountPlanOptionsRequest) =>
      mutate(body),
  };
};

export default useDownloadCountPlanOptions;
