import React from 'react';
import classNames from 'classnames';
import { IonRow } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { RatingActivityEnum } from 'models/ActivityModels';
import { findIcon } from 'utils/icons';
import Button from 'components/Button/Button';
import classes from './RatingButtons.module.scss';

interface RatingButtonsProps {
  rating?: RatingActivityEnum;
  positiveText?: string;
  negativeText?: string;
  disabled?: boolean;
  setRating?: (r: RatingActivityEnum) => void;
}

const RatingButtons = ({
  className,
  rating,
  positiveText,
  negativeText,
  disabled,
  setRating,
}: RatingButtonsProps & IonicReactProps): JSX.Element => (
  <div className={classNames(classes.wrapper, className)}>
    <IonRow className={classes.ratingButtons}>
      <Button
        className={classNames(classes.positiveButton, {
          [classes.active]: rating === RatingActivityEnum.positive,
        })}
        variant="secondary"
        icon={findIcon('check')}
        text={positiveText}
        onClick={() => setRating?.(RatingActivityEnum.positive)}
        disabled={disabled}
        testid="positive-button"
      />
      <Button
        className={classNames(classes.negativeButton, {
          [classes.active]: rating === RatingActivityEnum.negative,
        })}
        variant="secondary"
        icon={findIcon('times')}
        text={negativeText}
        onClick={() => setRating?.(RatingActivityEnum.negative)}
        disabled={disabled}
        testid="negative-button"
      />
    </IonRow>
  </div>
);

export default RatingButtons;
