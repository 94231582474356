import React, { useEffect, useRef } from 'react';
import { getLongPressDuration } from 'constants/platformSpecificConstants';
import classNames from 'classnames';
import { toString, uniqueId } from 'lodash';
import { Clipboard } from '@capacitor/clipboard';
import { useIonPopover } from '@ionic/react';
import type { LongPressEvent } from 'use-long-press';
import { LongPressDetectEvents, useLongPress } from 'use-long-press';
import useHapticFeedback from 'hooks/useHapticFeedback';
import classes from './LongPressCopyGesture.module.scss';

const copyToClipboard = async (value: string) => {
  await Clipboard.write({
    string: value,
  });
};

interface CopyPopoverProps {
  onHide: () => void;
}

export const CopyPopover: React.FC<CopyPopoverProps> = ({
  onHide,
}: CopyPopoverProps): JSX.Element => {
  useEffect(() => {
    const timer = setTimeout(() => onHide(), 2000);
    return () => clearTimeout(timer);
  });
  return <div className={classes.popover}>Copied</div>;
};

type LongPressCopyGestureProps = {
  copyableValue?: string;
  children?: React.ReactNode;
  styles?: [string];
  longPressDuration?: number;
};

const LongPressCopyGesture = ({
  copyableValue,
  children,
  styles,
  longPressDuration = getLongPressDuration(),
}: LongPressCopyGestureProps): JSX.Element => {
  const { hapticsImpactLight } = useHapticFeedback();
  const popoverID = `${toString(uniqueId())}-${toString(
    copyableValue
  )}-popover-trigger`;
  const popoverRef = useRef(null);

  const [present, dismiss] = useIonPopover(CopyPopover, {
    onHide: () => dismiss(),
    reference: 'trigger',
    trigger: popoverID,
    triggerAction: 'context-menu',
    arrow: false,
    alignment: 'center',
    side: 'top',
    dismissOnSelect: true,
    backdropDismiss: true,
    animated: true,
  });

  const presentPopover = (e: LongPressEvent | undefined) => {
    void copyToClipboard(toString(copyableValue));
    void hapticsImpactLight();
    present({
      event: e?.nativeEvent,
    });
  };

  const bind = useLongPress((e) => presentPopover(e), {
    captureEvent: true,
    threshold: longPressDuration,
    cancelOnMovement: true,
    detect: LongPressDetectEvents.BOTH,
  });

  return (
    <div
      id={popoverID}
      ref={popoverRef}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...bind}
      className={classNames(styles)}
    >
      {children}
    </div>
  );
};

export default LongPressCopyGesture;
