import { useTranslation } from 'react-i18next';
import type { AxiosError } from 'axios';
import { filter, find, head, map, size, toString } from 'lodash';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { MutationStatus } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { isEqual, max } from 'date-fns';
import useCountPlanDB from 'InventoryApp/database/useCountPlanDB';
import type {
  CountGroup,
  CreateCountPlanAPIResponse,
} from 'InventoryApp/models/InventoryPlanGroup';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import { doPromiseAPI, onSuccessMutation } from 'api/helpers';
import { ToastType } from 'models/Toast';
import { DateFormatEnum, formatDate } from 'utils/date';
import { findCountGroupsQueryKey } from './useFindCountGroups';
import { findShippingCustomersQueryKey } from './useFindShippingCustomers';

interface SubmitInventoryPlanBody {
  planName: string;
  countType: string;
  miLoc: string;
  customerNo: string;
  billCustomerNo: string;
  billCustomerName: string;
}

interface CheckInProgressBody {
  miLoc: string;
  customerNo: string;
}

interface UseSubmitInventoryPlanResponse {
  status: MutationStatus;
  countPlanId?: string;
  group?: CountGroup;
  error?: AxiosError | null;
  onCheckInProgress: (
    body: CheckInProgressBody
  ) => Promise<CountGroup | undefined>;
  onSubmitInventoryPlan: (body: SubmitInventoryPlanBody) => void;
}

interface OpenGroupCountInfo {
  creationTmstmp: string;
  creationUserName: string;
}

interface InventoryExistError {
  error?: string;
  openCountGroup?: OpenGroupCountInfo;
}

interface InvetoryResponseError {
  response: {
    data: InventoryExistError;
  };
}

const useSubmitInventoryPlan = (): UseSubmitInventoryPlanResponse => {
  const { axios } = useAxios();
  const { submitInventoryPlanAndGroupsAPI } = useAPIUrl();
  const {
    createCountPlan,
    createCountGroups,
    addItemsToCountPlan,
    createCountPlanOptions,
    findProfileDefaultSort,
    findCountGroupByMiLoc,
  } = useCountPlanDB();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const onCheckInProgress = async ({
    miLoc,
    customerNo,
  }: CheckInProgressBody) => {
    const offlineGroups = await findCountGroupByMiLoc(false);
    const mostRecentDate = max(
      map(
        offlineGroups,
        ({ dateCreated, timeCreated }) =>
          new Date(`${dateCreated} ${timeCreated}`)
      )
    );
    return find(
      filter(offlineGroups, { miLoc, customerNo }),
      ({ dateCreated, timeCreated }) =>
        isEqual(new Date(`${dateCreated} ${timeCreated}`), mostRecentDate)
    );
  };

  const doSubmitInventoryPlanAndGroup = (body: SubmitInventoryPlanBody) => {
    return doPromiseAPI(async (cancelToken) => {
      const {
        planName,
        countType,
        miLoc,
        customerNo,
        billCustomerNo,
        billCustomerName,
      } = body;
      const groupPlanName = `${planName.substring(0, 20)}_${formatDate(
        new Date(),
        DateFormatEnum.PlanGroupAndInventoryAPI
      )}`;
      const { data } = await axios.post<CreateCountPlanAPIResponse>(
        submitInventoryPlanAndGroupsAPI(
          toString(
            new URLSearchParams({
              planName: groupPlanName,
              countType,
              miLoc,
              customerNo,
            })
          )
        ),
        {},
        { cancelToken }
      );
      await createCountPlan({ ...data, ...data.countGroups });
      await createCountPlanOptions({
        ...data.planOption,
        countPlanId: data.countPlanId,
      });
      const profileDefaultSort = await findProfileDefaultSort(
        miLoc,
        customerNo
      );

      const countGroup = head(data.countGroups.groups) as CountGroup;
      await createCountGroups([
        {
          ...countGroup,
          lines: toString(size(countGroup.items)),
          billCustomerNo,
          billCustomerName,
          downloadedItems: 1,
          dateDownloaded: formatDate(new Date(), DateFormatEnum.fullDateV2),
          localSortField: profileDefaultSort?.sortField,
          localSortDir: profileDefaultSort?.sortDir,
          isDeleted: false,
        },
      ]);
      await addItemsToCountPlan(
        map(countGroup.items, (item) => ({
          ...item,
          countPlanId: countGroup.countPlanId,
          groupId: countGroup.uniqueId,
          hasCount: false,
          actualCount: '',
          miLoc: countGroup.miLoc,
          hasEditedMinMax: false,
        }))
      );
      return data;
    });
  };

  const { status, data, error, mutate } = useMutation(
    doSubmitInventoryPlanAndGroup,
    {
      // TODO optimistic update for shipping customers and count groups
      onSuccess: (inventoryData, vars) => {
        const { miLoc, billCustomerNo } = vars;
        void onSuccessMutation(queryClient, findShippingCustomersQueryKey, {
          miLoc,
          customerNo: billCustomerNo,
        });
        void onSuccessMutation(queryClient, findCountGroupsQueryKey);
      },
      onError: (inventoryError) => {
        const err = inventoryError as InvetoryResponseError;
        if (err?.response?.data?.openCountGroup) {
          const {
            openCountGroup: { creationTmstmp, creationUserName },
          } = err?.response?.data;
          addToast({
            type: ToastType.error,
            text: t('inventory:errorDuplicatedInventoryPlan', {
              creationTmstmp,
              creationUserName,
            }),
            testid: 'submit-inventory-plan-error-toast',
          });
        } else {
          addToast({
            type: ToastType.error,
            text: t('inventory:errorCreateInventoryPlan'),
            testid: 'submit-inventory-plan-error-toast',
          });
        }
      },
    }
  );

  return {
    status,
    countPlanId: toString(data?.countPlanId),
    group: head(data?.countGroups.groups),
    error: error as AxiosError,
    onCheckInProgress,
    onSubmitInventoryPlan: (body: SubmitInventoryPlanBody) => mutate(body),
  };
};

export default useSubmitInventoryPlan;
