import React, { useState } from 'react';
import classNames from 'classnames';
import type { Dictionary } from 'lodash';
import { isNil, toString } from 'lodash';
import { IonRow } from '@ionic/react';
import { DataTypeEnum } from 'common/utils/valueFormatter';
import { findIcon } from 'utils/icons';
import ActionRow from 'components/ActionRow/ActionRow';
import type { ActivityModalData } from 'components/Activities/ActionCardModal/ActionCardModalData';
import ActionCardModalData from 'components/Activities/ActionCardModal/ActionCardModalData';
import Button from 'components/Button/Button';
import Text from 'components/Text/Text';
import classes from './FastFind.module.scss';

const workOrderDefinition: ActivityModalData = {
  workOderShop: { label: 'Workshop', type: DataTypeEnum.string },
  workOrderNo: { label: 'Work Order', type: DataTypeEnum.string },
  workOderMiLoc: { label: 'Branch', type: DataTypeEnum.string },
  workOrderOCN: { label: 'OCN', type: DataTypeEnum.string },
  workOrderLineNo: { label: 'Line', type: DataTypeEnum.string },
};

interface FastFindItemProps<ItemType> {
  searchQuery: string;
  item: ItemType;
  selected?: boolean;
  multiple?: boolean;
  getId?: (i: ItemType) => string;
  getTitle?: (i: ItemType) => string;
  getDescription?: (i: ItemType) => string;
  getLine?: (i: ItemType) => string;
  getAdditionalInfo?: (i: ItemType) => string;
  getDetails?: (i: ItemType) => Dictionary<string>;
  onSelectItem?: () => void;
  onDeselectItem?: () => void;
}

function FastFindItem<ItemType>({
  searchQuery,
  item,
  selected,
  multiple,
  getId,
  getTitle,
  getDescription,
  getLine,
  getAdditionalInfo,
  getDetails,
  onSelectItem,
  onDeselectItem,
}: FastFindItemProps<ItemType>): JSX.Element {
  const [expanded, setExpanded] = useState(false);

  return (
    <ActionRow
      className={classNames(classes.item, {
        [classes.selectedItem]: selected,
      })}
      onClick={() => setExpanded((prev) => !prev)}
      testid={`item-${toString(getId?.(item))}`}
    >
      <div className={classes.wrapper}>
        <IonRow>
          <Button
            className={classes.addButton}
            variant="link"
            onClick={(e) => {
              e.stopPropagation();
              if (multiple && selected) {
                onDeselectItem?.();
                return;
              }
              if (selected) {
                return;
              }
              onSelectItem?.();
            }}
            text={selected ? '' : 'Select'}
            icon={selected ? findIcon('check') : undefined}
            testid="add-item-pou-button"
          />
          <div>
            <Text
              className={classes.title}
              text={toString(getTitle?.(item))}
              textQuery={searchQuery}
              variant="content-heavy"
              testid="item-pou-text"
            />
            <div className={classes.descriptionRow}>
              <Text
                className={classes.description}
                textQuery={searchQuery}
                text={toString(getDescription?.(item))}
                variant="content-small"
              />
              <Text
                className={classes.additionalInfo}
                textQuery={searchQuery}
                text={toString(getAdditionalInfo?.(item))}
                variant="content-small"
              />
            </div>
            <div>
              <Text
                className={classes.description}
                textQuery={searchQuery}
                text={toString(getLine?.(item))}
                variant="content-small"
              />
            </div>
          </div>
        </IonRow>
        {expanded && !isNil(getDetails) && (
          <div>
            <ActionCardModalData
              className={classes.details}
              rowClassName={classes.detailRow}
              dataDefinition={workOrderDefinition}
              activityData={getDetails?.(item)}
            />
          </div>
        )}
        {!isNil(getDetails) && (
          <Button
            className={classes.detailsButton}
            variant="link"
            text={expanded ? 'Hide Details' : 'Show Details'}
            testid="details-button"
          />
        )}
      </div>
    </ActionRow>
  );
}

export default FastFindItem;
