import React from 'react';
import type { AxiosError } from 'axios';
import {
  isNil,
  isString,
  get,
  toString,
  isEmpty,
  split,
  chain,
  size,
  filter,
  toNumber,
  has,
} from 'lodash';
import { File } from '@awesome-cordova-plugins/file';
import { PreviewAnyFile } from '@awesome-cordova-plugins/preview-any-file';
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style as StatusBarStyle } from '@capacitor/status-bar';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isPlatform } from '@ionic/react';
import Compress from 'browser-image-compression';
import ColorHash from 'color-hash';
import type { ToastProps } from 'providers/ToastProvider';
import { renderToStaticMarkup } from 'react-dom/server';
import type { ImageUpload } from 'models/Attachment';
import type { FilterSection } from 'models/Filters';
import { FilterSectionEnum } from 'models/Filters';
import type { ReportImage } from 'models/InspectionReport';
import type { DataResponse } from 'models/Reports';
import { ToastType } from 'models/Toast';
import { invalidBusinessDay } from 'utils/reports';

export const colorHash = new ColorHash({ saturation: 0.6, lightness: 0.5 });

const optionsCompress = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};

export const withStringProp = (prop?: string): boolean =>
  !isNil(prop) && prop !== '';

export const isNumericString = (value = ''): boolean => /\d/.test(value);

export const stringValue = (
  data: unknown,
  key: string,
  placeholder: string
): string => {
  return toString(get(data, key) || placeholder);
};

export const getUserInitials = (name?: string): string => {
  if (isEmpty(name)) {
    return '';
  }
  const nameArr = split(name, ' ');
  const fn = chain(nameArr).head().split('').head().value();
  const ln =
    size(nameArr) > 1 ? chain(nameArr).last().split('').head().value() : '';
  return `${fn}${ln}`;
};

/**
 * Create icon as inline SVG data.
 */
export const createSVGIcon = (icon?: IconProp | string): string | undefined => {
  if (isNil(icon)) {
    return undefined;
  }

  return isString(icon)
    ? icon
    : `data:image/svg+xml;utf8,${renderToStaticMarkup(
        <FontAwesomeIcon icon={icon} />
      )}`;
};

export const setStatusBarTheme = async (
  theme: StatusBarStyle
): Promise<StatusBarStyle> => {
  if (Capacitor.isNativePlatform()) {
    const { style } = await StatusBar.getInfo();
    // Set device Style and Background color
    await StatusBar.setStyle({
      style: theme,
    });
    // Apply background color only to Android devices
    if (isPlatform('android')) {
      await StatusBar.setBackgroundColor({
        color: theme === StatusBarStyle.Light ? '#FFFFFF' : '#333333',
      });
    }
    // returns previous style
    return style;
  }
  return theme;
};

export const hasFilterApplied = ({
  type,
  selectedValue,
  options,
}: Pick<FilterSection, 'type' | 'selectedValue' | 'options'>): boolean => {
  const dateRange = split(selectedValue, '#');
  switch (type) {
    case FilterSectionEnum.checkbox:
      return size(filter(options, { checked: true })) > 0;
    case FilterSectionEnum.radiogroup:
    case FilterSectionEnum.search:
      return !isEmpty(selectedValue);
    case FilterSectionEnum.dateRange:
      return toNumber(dateRange[0]) > 0 || toNumber(dateRange[1]) > 0;
    default:
      return false;
  }
};

export const getErrorMessage = (
  error?: AxiosError | null,
  defaultMsg?: string
): string => {
  return toString(error?.message || defaultMsg);
};

export const convertBlobToBase64 = (blob: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result as string;
      resolve(base64data);
    };
    reader.onerror = () => reject(reader.error);
  });
};

export const compressImages = (img: File): Promise<File> => {
  return Compress(img, optionsCompress);
};

export function removeLocationFromText(item?: string) {
  return toString(item?.split(' - ').pop());
}

export const transformImage = (
  image: ReportImage | ImageUpload,
  miLoc?: string,
  data?: string,
  groupId?: number,
  isPrimary?: boolean,
  reportId?: number
) => {
  if (has(image, 'webPath') && 'webPath' in image) {
    return {
      caption: image.caption || '',
      internalOnly: image.internalOnly,
      displaySequence: 0,
      imagePath: image.fileName,
      groupId,
      imageId: image?.imageId || 0,
      inputId: image?.inputId || 0,
      primaryImage: isPrimary ? 'Y' : 'N',
      image: image.webPath,
      miLoc,
      reportId,
    } as unknown as ReportImage;
  }

  if (has(image, 'image') && 'image' in image) {
    return {
      caption: image.caption,
      internalOnly: image.internalOnly,
      fileName: image.imagePath || '',
      webPath: data || image.image,
      imageId: image?.imageId || 0,
      format: image.imagePath?.split('.').pop(),
      forUpload: true,
    } as ImageUpload;
  }

  return image;
};

export const setAsyncTimeout = (cb: () => void, timeout = 0) =>
  new Promise<void>((resolve) => {
    setTimeout(() => {
      cb();
      resolve();
    }, timeout);
  });

export const downloadFile = async (
  fileName: string,
  mimeType: string,
  content: BlobPart,
  addToast: (p: ToastProps) => void,
  successText = ''
) => {
  try {
    const isDesktop = isPlatform('mobileweb') || isPlatform('desktop');
    const baseDirectory = isPlatform('android')
      ? File.externalDataDirectory
      : File.documentsDirectory;

    const fileBlob = new Blob([content], {
      type: mimeType,
    });
    const downloadNode = document.createElement('a');
    downloadNode.setAttribute('href', URL.createObjectURL(fileBlob));
    downloadNode.setAttribute('download', fileName);
    document.body.appendChild(downloadNode);
    downloadNode.click();
    // delete the internal blob reference, to let the browser clear memory from it
    window.URL.revokeObjectURL(downloadNode.href);
    downloadNode.remove();
    await File.writeExistingFile(baseDirectory, fileName, fileBlob);
    if (successText) {
      addToast({
        type: ToastType.success,
        text: successText,
        testid: 'save-file-success-toast',
      });
    } else if (!isDesktop) {
      PreviewAnyFile.previewPath(encodeURI(`${baseDirectory}${fileName}`), {
        mimeType,
      }).subscribe();
    }
  } catch (e) {
    // TODO make an util to handle these errors
    if (e instanceof Error) {
      addToast({
        type: ToastType.error,
        text: 'Download file operation failed. Please try again later.',
        testid: 'save-file-error-toast',
      });
    } else {
      addToast({
        type: ToastType.error,
        text: 'Failed to save file. Please ensure Mi Pro has been granted file system access.',
        testid: 'download-file-error-toast',
      });
    }
  }
};

export const getInvalidBusDay = (error?: AxiosError | null): boolean => {
  let invalid = false;
  const data = (error?.response?.data as DataResponse) || undefined;

  if (!isEmpty(data?.messages)) {
    invalid = data.messages[0]?.message === invalidBusinessDay;
  }
  return invalid;
};
