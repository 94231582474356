import { useMemo } from 'react';
import type { AxiosError } from 'axios';
import { size, toString } from 'lodash';
import type { QueryFunctionContext } from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import type {
  QueryFlags,
  QueryParamsType,
} from 'common/api/utils/useGetQueryFlags';
import useGetQueryFlags from 'common/api/utils/useGetQueryFlags';
import { useAxios } from 'providers/AxiosProvider';
import { doConcatDataPages, useKeyUserId } from 'api/helpers';
import { pageSize } from 'utils/constants';

export const findLostSalesQueryKey = 'lost-sales';

interface LostSales {
  codeDesc: string;
  codeValue: string;
  statusFlag: string;
}

interface UseFindLostSalesProps {
  limit?: number;
  enabled?: boolean;
  query?: string;
}

interface UseFindLostSalesResponse {
  lostSales: LostSales[];
}

const useFindLostSales = ({
  limit = pageSize(),
  enabled = true,
  query,
}: UseFindLostSalesProps): UseFindLostSalesResponse & QueryFlags => {
  const { axios } = useAxios();
  const { findLostSalesAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const params: QueryParamsType = {
    limit,
    fastFind: query,
    sortField: 'codeDesc',
    sortDir: 'ASC',
  };

  const doFindLostSales = async ({
    pageParam = 1,
    signal,
  }: QueryFunctionContext) => {
    const { data } = await axios.get<LostSales[]>(
      findLostSalesAPI(
        toString(new URLSearchParams({ ...params, page: toString(pageParam) }))
      ),
      { signal }
    );
    return data;
  };

  const response = useInfiniteQuery<LostSales[], AxiosError>(
    createQueryKey(findLostSalesQueryKey, params),
    doFindLostSales,
    {
      enabled,
      getNextPageParam: (lastPage, pages) =>
        size(lastPage) < limit ? false : size(pages) + 1,
    }
  );

  const { data } = response;

  const lostSales = useMemo(() => doConcatDataPages<LostSales>(data), [data]);

  const queryFlags = useGetQueryFlags({
    ...response,
    data: lostSales,
    enabled,
  });

  return {
    lostSales,
    ...queryFlags,
  };
};

export default useFindLostSales;
