import type {
  RatingActivityEnum,
  UpdateActivityBody,
} from 'models/ActivityModels';
import type { MiProFile } from 'models/Attachment';

export enum CustomerVisitTypeEnum {
  customerVisit = 'CustomerVisit',
  customerVisitedByNonRep = 'CustomerVisitedByNonRep',
}

export enum CustomerVisitIconEnum {
  customerVisit = 'user-check',
}

export enum CustomerVisitColorEnum {
  customerVisit = 'color-customerVisit',
}

export enum CustomerVisitFilterEnum {
  customerVisit = 'customerVisit',
}

export interface VisitContact {
  contactSeqNo: number;
  contactName?: string;
}

export interface RateVisitContact {
  customerNo: string;
  miLoc: string;
  sequenceNo: number;
  name?: string;
  delete?: boolean;
}

interface RateVisitChild {
  historyId: number;
  userId: string;
  done?: 'Y' | 'N';
  delete?: boolean;
}

export interface RateVisitNote {
  title: string;
  text: string;
}

export interface RateVisitBody {
  rating?: RatingActivityEnum;
  children?: RateVisitChild[];
  contacts?: RateVisitContact[];
  notebook?: RateVisitNote;
  visitDate?: Date;
  visitDateWithZimeZoneCreated?: Date;
}

export enum VisitTypes {
  Visit = 'V',
  Call = 'C',
}

export type CustomerVisitBody = UpdateActivityBody &
  RateVisitBody & {
    filesForUpload?: MiProFile[];
    visitType?: VisitTypes | string;
  };

export const enum MyVisitFilterKeys {
  today = 'today',
  last_2weeks = 'last_2weeks',
  last_month = 'last_month',
  this_month = 'this_month',
  all = 'all',
  custom_date = 'custom_date',
}
