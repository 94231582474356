import { useMemo, useState } from 'react';
import type { AxiosError } from 'axios';
import type { Dictionary } from 'lodash';
import { find, isEmpty, map, toString } from 'lodash';
import * as Sentry from '@sentry/capacitor';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import { useToasts } from 'providers/ToastProvider';
import useStoreroomDB from 'StoreroomsApp/database/useStoreroomDB';
import type { Storeroom, StoreroomAPI } from 'StoreroomsApp/models/Storeroom';
import {
  doConcatDataPages,
  doGetIsLoading,
  doPromiseAPI,
  getAPIHeaders,
  getOfflineNextPage,
  onDownloadData,
  onSuccessMutation,
  useKeyUserId,
  useMiLocOrTeamId,
} from 'api/helpers';
import type { InfiniteQueryFlags } from 'models/Search';
import { ToastType } from 'models/Toast';
import { pageSize } from 'utils/constants';

export const findStoreroomsQueryKey = 'storerooms';

interface UseFindStoreroomsProps {
  enabled?: boolean;
  query?: string;
  isPendingIssue?: boolean;
  limit?: number;
}

interface UseFindStoreroomsResponse {
  storerooms?: Storeroom[];
  loadingAPI?: boolean;
  sync: () => Promise<void>;
}

const useFindStorerooms = ({
  enabled,
  query = '',
  isPendingIssue,
  limit = pageSize(),
}: UseFindStoreroomsProps): InfiniteQueryFlags & UseFindStoreroomsResponse => {
  const { axios } = useAxios();
  const { addToast } = useToasts();
  const queryClient = useQueryClient();
  const { storeroomsAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const { createParams } = useMiLocOrTeamId({ sendTeamId: false });
  const { createStorerooms, findStoreroomsByMiLoc, removeStorerooms } =
    useStoreroomDB();
  const [loadingAPI, setLoadingAPI] = useState(false);
  const { isOnline } = useNetworkStatus();
  const params: Dictionary<string> = {
    ...createParams(),
    query,
    limit: toString(limit),
  };
  const { miLoc } = params;

  const doFindStorerooms = async ({ pageParam = 1 }) => {
    return doPromiseAPI<Storeroom[]>(async () => {
      let storerooms: Storeroom[] = [];
      const offlineStorerooms =
        (await findStoreroomsByMiLoc({
          miLoc,
          query,
          isPendingIssue,
        })) || [];
      if (isOnline) {
        const { data: storeroomsData } = await axios.post<StoreroomAPI[]>(
          storeroomsAPI(
            toString(
              new URLSearchParams({
                ...params,
                page: toString(pageParam),
              })
            )
          )
        );
        // TODO: remove code duplication with sync
        storerooms = map(storeroomsData, (s) => ({
          combinedId: s.combinedStoreroomId,
          miLocation: s.miLoc,
          storeroomNumber: s.storeroomNo,
          customerNumber: s.customerNo,
          storeroomName: s.storeroomName,
          creationUserID: s.creationPgm,
          creationProgram: s.creationTimestamp,
          creationTimestamp: s.creationUserid,
          lastUpdateUserID: s.lastUpdatedPgm,
          lastUpdateProgram: s.lastUpdatedTimestamp,
          lastUpdateTimestamp: s.lastUpdatedUserid,
        }));
        await createStorerooms(storerooms);
        return map(storerooms, (storeroom) => ({
          ...find(offlineStorerooms, {
            miLocation: storeroom.miLocation,
            storeroomNumber: storeroom.storeroomNumber,
          }),
          ...storeroom,
        }));
      }
      return offlineStorerooms;
    });
  };

  const response = useInfiniteQuery<Storeroom[], AxiosError>(
    createQueryKey(findStoreroomsQueryKey, {
      ...params,
      isOnline,
      isPendingIssue: toString(isPendingIssue),
    }),
    doFindStorerooms,
    {
      enabled,
      networkMode: 'always',
      getNextPageParam: getOfflineNextPage(!!isOnline),
      // TODO: is this still needed?
      onError: () => {
        setLoadingAPI(false);
      },
    }
  );

  const {
    data,
    error,
    status,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = response;

  const storerooms = useMemo(() => doConcatDataPages<Storeroom>(data), [data]);

  const hasItems = !isEmpty(storerooms);
  const isEmptyResponse = status === 'success' && !hasNextPage && !hasItems;
  const noMoreData = status === 'success' && !hasNextPage && hasItems;
  const showLoader = doGetIsLoading(response) || isFetchingNextPage;
  const enableInfiniteScroll = !(!hasNextPage || isFetchingNextPage);

  return {
    storerooms,
    error,
    showLoader,
    loadingAPI,
    isEmptyResponse,
    noMoreData,
    enableInfiniteScroll,
    fetchNextPage: async () => {
      await fetchNextPage();
    },
    refetch: async () => {
      await refetch();
    },
    sync: async () => {
      try {
        setLoadingAPI(true);
        const { syncData } = await onDownloadData<StoreroomAPI>({
          customAxios: axios,
          getAPIUrl: storeroomsAPI,
          getData: (r) => r as StoreroomAPI[],
          params: { miLoc },
          headers: { ...getAPIHeaders() },
        });
        const syncedStorerooms: Storeroom[] = map(syncData, (s) => ({
          combinedId: s.combinedStoreroomId,
          miLocation: s.miLoc,
          storeroomNumber: s.storeroomNo,
          customerNumber: s.customerNo,
          storeroomName: s.storeroomName,
          creationUserID: s.creationPgm,
          creationProgram: s.creationTimestamp,
          creationTimestamp: s.creationUserid,
          lastUpdateUserID: s.lastUpdatedPgm,
          lastUpdateProgram: s.lastUpdatedTimestamp,
          lastUpdateTimestamp: s.lastUpdatedUserid,
        }));
        await removeStorerooms(miLoc);
        await createStorerooms(syncedStorerooms);
        void onSuccessMutation(queryClient, findStoreroomsQueryKey);
      } catch (e) {
        addToast({
          type: ToastType.error,
          text: 'There was an error while downloading storerooms',
          testid: 'sync-error-toast',
          duration: 0,
        });
        Sentry.captureException(e);
        throw e;
      } finally {
        setLoadingAPI(false);
      }
    },
  };
};

export default useFindStorerooms;
