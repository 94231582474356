import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { defaultExpectedDate } from 'constants/platformSpecificConstants';
import { includes, toNumber, values } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import type { InsetListData } from 'common/components/InsetList/InsetList';
import InsetList from 'common/components/InsetList/InsetList';
import List from 'common/components/List/List';
import { useNodeRef } from 'common/components/utils/renderHelpers';
import { formatNumber, scaleNumber } from 'common/utils/numberHelper';
import ListItem from 'InventoryApp/components/ListItem/ListItem';
import { OcnLineItemBadge } from 'ProductSearchApp/components/OcnBadge/OcnBadge';
import { OcnTypeEnum, type Order } from 'ProductSearchApp/models/Order';
import type { ProductDetailURLParams } from 'ProductSearchApp/models/Products';
import {
  cancelWoStatus,
  getOcnType,
  hasPriceOverride,
  isCancelledItem,
  isZcodedItem,
} from 'ProductSearchApp/util/ocnHelpers';
import type { RootState } from 'store/reducers';
import { DateFormatEnum, formatDate } from 'utils/date';
import {
  goToLineShippingDetail,
  goToProductDetail,
} from 'navigation/navigationHelpers';
import Badge from 'components/Badge/Badge';
import Button from 'components/Button/Button';
import CurrencyFormat from 'components/CurrencyFormat/CurrencyFormat';
import NumberFormat from 'components/NumberFormat/NumberFormat';
import Text from 'components/Text/Text';
import classes from './OcnLinesReview.module.scss';

interface OcnLinesReviewProps {
  order?: Order;
}

const OcnLinesReview = ({ order }: OcnLinesReviewProps): JSX.Element => {
  const namespace = 'productSearch:review';
  const { t } = useTranslation();
  const { showCostDetails } = useSelector((state: RootState) => state.user);
  const { miLoc, id: customerNo } = useParams<ProductDetailURLParams>();
  const { nodeRef, node } = useNodeRef();
  const ocnType = getOcnType(order);

  const ocnSummary: InsetListData[] = [
    {
      label: t(`${namespace}:subTotal`),
      value: {
        variant: 'list-item-subtitle',
        testid: 'subtotal-price',
        text: formatNumber({
          number: toNumber(order?.mdseOrderedPrice),
          scale: 2,
          currencyType: 'USD',
        }),
      },
    },
    {
      label: {
        variant: 'mipro-h6-headline',
        text: t(`${namespace}:freightTotal`),
      },
      value: {
        variant: 'mipro-h6-headline',
        testid: 'freight-total',
        text: formatNumber({
          number: toNumber(order?.totalFrt),
          scale: 2,
          currencyType: 'USD',
        }),
      },
    },
    {
      label: {
        variant: 'mipro-h6-headline',
        text: t(`${namespace}:salesTaxTotal`),
      },
      value: {
        variant: 'mipro-h6-headline',
        testid: 'sales-tax-total',
        text: formatNumber({
          number: toNumber(order?.salesTaxTotal),
          scale: 2,
          currencyType: 'USD',
        }),
      },
    },
    {
      label: {
        variant: 'mipro-h6-headline',
        text: t(`${namespace}:otherVasTotal`),
      },
      value: {
        variant: 'mipro-h6-headline',
        testid: 'sales-vas-total',
        text: formatNumber({
          number: toNumber(order?.totalVas),
          scale: 2,
          currencyType: 'USD',
        }),
      },
    },
  ];

  const totalsRow: InsetListData[] = [
    {
      label: {
        variant: 'mipro-body-copy-bold',
        text: t('common:total'),
        className: classes.total,
      },
      value: {
        variant: 'mipro-h1-headline',
        testid: 'total-price',
        text: formatNumber({
          number: toNumber(order?.totalOrderAmt),
          scale: 2,
          currencyType: 'USD',
        }),
        className: classes.totalAmt,
      },
    },
  ];

  const costDetails: InsetListData[] = [
    {
      label: {
        variant: 'mipro-h6-headline',
        text: t(`${namespace}:grossProfit`),
        className: classes.grossProfit,
      },
      value: {
        variant: 'mipro-h6-headline',
        testid: 'gross-profit-amt',
        text: formatNumber({
          number: toNumber(order?.grossProfitDollars),
          scale: 2,
          currencyType: 'USD',
        }),
        className: classes.grossProfitAmt,
      },
    },
    {
      label: {
        variant: 'mipro-h6-headline',
        text: t('common:gpPercentage'),
      },
      customValue: (
        <NumberFormat
          value={toNumber(order?.grossProfitPct)}
          scale={2}
          suffix="%"
          variant="mipro-h6-headline"
          testid="gp-percentage"
        />
      ),
    },
  ];

  return (
    <div ref={nodeRef} className={classes.list}>
      <List
        testid="ocn-lines-list"
        data={values(order?.items)}
        title={{ text: t(`${namespace}:ocnLines`), className: classes.header }}
        itemContent={(index, item) => {
          let href: string | undefined = goToProductDetail({
            miLoc,
            customerNo,
            itemNo: item.itemNo,
          });

          if (isZcodedItem(item.itemNo)) {
            href = undefined;
          }

          const isCancelledWo = cancelWoStatus(item?.itemSourcing);
          const hideOrderStatus =
            isCancelledWo ||
            isCancelledItem(item.itemStatusDetailDesc) ||
            includes([OcnTypeEnum.unfinished, OcnTypeEnum.quote], ocnType);
          const overrideMode = hasPriceOverride(item);

          return (
            <ListItem
              key={index}
              leftText={item.orderLineNo}
              title={{
                text: item.mfgPartNo,
                children: isZcodedItem(item.itemNo) ? (
                  <Badge
                    className={classes.badge}
                    text="Z"
                    type="info"
                    testid="zcoded-badge"
                    circle
                  />
                ) : undefined,
                variant: !isZcodedItem(item.itemNo) ? 'link' : 'clear',
                href,
                className: classes.title,
                testid: `ocn-item-title-${index}`,
              }}
              testid={`ocn-item-${index}`}
              withArrow={false}
              overlay={item.mfrName}
              customBadge={
                <OcnLineItemBadge
                  status={item.itemStatusDetailDesc}
                  isCancelledWo={isCancelledWo}
                />
              }
              href={
                hideOrderStatus
                  ? undefined
                  : goToLineShippingDetail({
                      miLoc,
                      orderCtlNo: order?.orderCtlNo,
                      shipToCustNo: order?.shipToCustNo,
                      lineNo: item.orderLineNo,
                      ocnMode: 'review',
                      ocnType,
                      productId: item.itemNo,
                    })
              }
              secondaryTextArray={[
                {
                  children: (
                    <div className={classes.expectedDate}>
                      <Text
                        text={t(`${namespace}:expectedDate`)}
                        variant="mipro-body-copy"
                      />
                      <Text
                        text={
                          item.promsdDelivDt === defaultExpectedDate
                            ? t(`${namespace}:notSet`)
                            : formatDate(
                                item.promsdDelivDt,
                                DateFormatEnum.dayShortMonth
                              )
                        }
                        variant="mipro-body-copy-bold"
                      />
                    </div>
                  ),
                },
                {
                  children: (
                    <IonGrid className={classes.grid}>
                      <IonRow>
                        <IonCol size="3">
                          <Text
                            variant="mipro-body-copy"
                            text={t(`${namespace}:qty`, {
                              quantity: scaleNumber({
                                number: item.opQtyOrdered,
                                scale: 1,
                                padFractionalZeros: false,
                              }),
                            })}
                          />
                        </IonCol>
                        <IonCol size="5" className={classes.unitPrice}>
                          {overrideMode && (
                            <FontAwesomeIcon icon={['far', 'calculator']} />
                          )}
                          <CurrencyFormat
                            value={toNumber(item.unitSellPrice)}
                            variant="mipro-body-copy"
                            scale={2}
                            currencyType="USD"
                            testid={`${item.orderLineNo}-unit-sell-price`}
                          />
                          <Text
                            variant="list-item-secondaryText"
                            text={`${item.priceSourceDesc}/ ${item.salesUOM}`}
                          />
                        </IonCol>
                        <IonCol size="4">
                          <CurrencyFormat
                            value={toNumber(item?.extPrice)}
                            variant="mipro-body-copy-bold"
                            scale={2}
                            currencyType="USD"
                            testid={`${item.orderLineNo}-subtotal-price`}
                            className={classes.subTotal}
                          />
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  ),
                },
                {
                  children: hideOrderStatus ? undefined : (
                    <Button
                      text={t(`${namespace}:orderStatus`)}
                      testid="order-satus"
                      variant="link"
                      rightIcon="chevron-right"
                      textVariant="mipro-body-copy-bold"
                      className={classes.orderStatus}
                    />
                  ),
                },
              ]}
            />
          );
        }}
        scrollParent={node}
      />
      <div className={classes.totalSectionGrid}>
        <IonRow className={classes.subTotalHeading}>
          <Text
            variant="mipro-h3-headline"
            text={t(`${namespace}:ocnSummary`)}
          />
        </IonRow>

        <InsetList
          data={ocnSummary}
          testid="ocn-summary"
          className={classes.ocnSummary}
        />
        <div className={classes.separatorRow} />
        <InsetList
          data={totalsRow}
          testid="totals-row"
          className={classes.ocnSummary}
        />

        {showCostDetails && (
          <InsetList
            data={costDetails}
            testid="cost-details"
            className={classes.costDetails}
          />
        )}
      </div>
    </div>
  );
};

export default OcnLinesReview;
