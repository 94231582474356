import React from 'react';
import classNames from 'classnames';
import { IonSkeletonText } from '@ionic/react';
import { findIcon } from 'utils/icons';
import { ordinalNumber } from 'utils/number';
import Button from 'components/Button/Button';
import classes from './LeaderboardShape.module.scss';

interface LeaderboardShapeInterface {
  className: string;
  rank?: number;
  isLoading?: boolean;
  showCloseButton?: boolean;
  closeLeaderBoardCard?: () => void;
  children: React.ReactNode;
  testid?: string;
}

const LeaderboardShape = ({
  className,
  rank,
  isLoading,
  showCloseButton,
  closeLeaderBoardCard,
  children,
  testid,
}: LeaderboardShapeInterface): JSX.Element => {
  return (
    <div className={classes[className]} data-testid={testid}>
      <div className={classes.rank}>
        {isLoading ? <IonSkeletonText animated /> : rank && ordinalNumber(rank)}
      </div>
      <div className={classes.cardBorder}>
        <div className={classes.cardContent}>{children}</div>
      </div>
      {showCloseButton && (
        <Button
          testid="pick12-leaderboard-card-close"
          className={classNames(classes.closeButton, classes.rankCard)}
          icon={findIcon('times')}
          iconClassName={classes.closeIcon}
          onClick={closeLeaderBoardCard}
        />
      )}
    </div>
  );
};

export default LeaderboardShape;
