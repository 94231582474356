import { useMemo } from 'react';
import type { AxiosError } from 'axios';
import type { Dictionary } from 'lodash';
import { isEmpty, map, size, toString } from 'lodash';
import { useInfiniteQuery } from '@tanstack/react-query';
import type { InfiniteQueryObserverResult } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import {
  doConcatDataPages,
  doPromiseAPI,
  useKeyUserId,
  useMiLocOrTeamId,
} from 'api/helpers';
import type { CustomerContact } from 'models/CustomerContact';
import type {
  SearchItem,
  SearchResponse,
  InfiniteQueryFlags,
} from 'models/Search';
import { pageSize } from 'utils/constants';

export const findCustomerContactsQueryKey = 'search-customer-contacts';

interface UseFindCustomerContactsProps {
  query?: string;
  sortField?: string;
  sortDir?: string;
  limit?: number;
  enabled?: boolean;
}

export type UseFindCustomerContactsResponse = SearchResponse &
  InfiniteQueryFlags &
  Pick<InfiniteQueryObserverResult<SearchResponse>, 'data'>;

const useFindCustomerContacts = ({
  query = '',
  sortField = 'name',
  sortDir = 'ASC',
  limit = pageSize(),
  enabled = true,
}: UseFindCustomerContactsProps): UseFindCustomerContactsResponse => {
  const { axios } = useAxios();
  const { findCustomerContactsAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const { createParams } = useMiLocOrTeamId({
    sendTeamId: false,
    teamSearch: true,
  });
  const params: Dictionary<string> = {
    query: toString(query),
    sortField,
    sortDir,
    ...createParams(),
    limit: toString(limit),
  };

  const doFindCustomerContacts = ({ pageParam = 1 }) => {
    return doPromiseAPI<SearchResponse>(async (cancelToken) => {
      const { data } = await axios.get<CustomerContact[]>(
        findCustomerContactsAPI(
          toString(
            new URLSearchParams({ ...params, page: toString(pageParam) })
          )
        ),
        { cancelToken }
      );
      return {
        items: map(
          data,
          ({
            customerName,
            customerNo,
            email,
            isMainContact,
            isWebRegistered,
            miLoc: iMiLoc,
            name,
            phone,
            registrationCreationDate,
            registrationExpiryDate,
            registrationStatus,
            sequenceNo,
            type: customerType,
            typeDesc,
          }) => ({
            type: 'customerContact',
            customerName,
            customerNo,
            email,
            isMainContact,
            isWebRegistered,
            miLoc: iMiLoc,
            id: toString(sequenceNo),
            text: name || '',
            phone,
            registrationCreationDate,
            registrationExpiryDate,
            registrationStatus,
            sequenceNo,
            customerType,
            typeDesc,
          })
        ),
      };
    });
  };

  const response = useInfiniteQuery<SearchResponse, AxiosError>(
    createQueryKey(findCustomerContactsQueryKey, params),
    doFindCustomerContacts,
    {
      enabled: !isEmpty(query) && enabled,
      getNextPageParam: (lastPage, pages) =>
        size(lastPage.items) < limit ? false : size(pages) + 1,
    }
  );

  const {
    data,
    error,
    status,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = response;

  const customerContacts = useMemo(
    () => doConcatDataPages<SearchItem, SearchResponse>(data, 'items'),
    [data]
  );

  const hasItems = size(customerContacts) > 0;
  const hasError = status === 'error';
  const isEmptyResponse = status === 'success' && !hasNextPage && !hasItems;
  const noMoreData = status === 'success' && !hasNextPage && hasItems;
  const showLoader = status === 'loading' || isFetchingNextPage;
  const enableInfiniteScroll = !(!hasNextPage || isFetchingNextPage);

  return {
    data,
    fetchNextPage: async () => {
      await fetchNextPage();
    },
    items: customerContacts,
    total: !showLoader ? data?.pages[0].total : undefined,
    error,
    hasError,
    showLoader,
    isEmptyResponse,
    noMoreData,
    enableInfiniteScroll,
    refetch: async () => {
      await refetch?.call(null);
    },
  };
};

export default useFindCustomerContacts;
