import { concat, isNil, take } from 'lodash';
import type { InfiniteData } from '@tanstack/react-query';
import type { UseFindGlobalOrdersResponse } from 'ProductSearchApp/api/orders/useFindGlobalOrders';
import type { UseFindCustomersResponse } from 'api/customer/useFindCustomers';
import type { UseFindCustomerContactsResponse } from 'api/customerContacts/useFindCustomerContacts';
import type { UseFindEmployeesResponse } from 'api/employee/useFindEmployees';
import type { UseFindLocationsResponse } from 'api/location/useFindLocations';
import type { UseFindSuppliersResponse } from 'api/supplier/useFindSuppliers';
import type {
  SearchItem,
  InfiniteQueryFlags,
  SearchResponse,
} from 'models/Search';
import { pageSize } from 'utils/constants';

interface ConcatSearchResultsProps {
  searchType: string;
  customersResponse?: InfiniteData<SearchResponse>;
  suppliersResponse?: InfiniteData<SearchResponse>;
  employeesResponse?: InfiniteData<SearchResponse>;
  customerContactsResponse?: InfiniteData<SearchResponse>;
  motionLocationsResponse?: InfiniteData<SearchResponse>;
  corporateAccountsResponse?: InfiniteData<SearchResponse>;
  OCNsResponse?: InfiniteData<SearchResponse>;
  linesToShow?: number;
}

export const concatSearchResults = ({
  searchType,
  customersResponse,
  suppliersResponse,
  employeesResponse,
  customerContactsResponse,
  motionLocationsResponse,
  corporateAccountsResponse,
  OCNsResponse,
  linesToShow = pageSize(),
}: ConcatSearchResultsProps): SearchItem[] => {
  let items: SearchItem[] = [];
  let page = 0;
  while (
    !isNil(customersResponse?.pages[page]) ||
    !isNil(suppliersResponse?.pages[page]) ||
    !isNil(employeesResponse?.pages[page]) ||
    !isNil(customerContactsResponse?.pages[page]) ||
    !isNil(motionLocationsResponse?.pages[page]) ||
    !isNil(corporateAccountsResponse?.pages[page]) ||
    !isNil(OCNsResponse?.pages[page])
  ) {
    if (['all', 'customers'].includes(searchType)) {
      items = concat(
        items,
        take(customersResponse?.pages[page]?.items, linesToShow)
      );
    }
    if (['all', 'suppliers'].includes(searchType)) {
      items = concat(
        items,
        take(suppliersResponse?.pages[page]?.items, linesToShow)
      );
    }
    if (['all', 'employees'].includes(searchType)) {
      items = concat(
        items,
        take(employeesResponse?.pages[page]?.items, linesToShow)
      );
    }
    if (['all', 'customerContacts'].includes(searchType)) {
      items = concat(
        items,
        take(customerContactsResponse?.pages[page]?.items, linesToShow)
      );
    }
    if (['all', 'motionLocations'].includes(searchType)) {
      items = concat(
        items,
        take(motionLocationsResponse?.pages[page]?.items, linesToShow)
      );
    }
    if (['all', 'corporateAccounts'].includes(searchType)) {
      items = concat(
        items,
        take(corporateAccountsResponse?.pages[page]?.items, linesToShow)
      );
    }
    if (['all', 'ocns'].includes(searchType)) {
      items = concat(
        items,
        take(OCNsResponse?.pages[page]?.items, linesToShow)
      );
    }
    page += 1;
  }
  return items;
};

interface GetSearchResponseFlagProps {
  searchType: string;
  customersResponse: UseFindCustomersResponse;
  suppliersResponse: UseFindSuppliersResponse;
  employeesResponse: UseFindEmployeesResponse;
  customerContactsResponse: UseFindCustomerContactsResponse;
  motionLocationsResponse: UseFindLocationsResponse;
  corporateAccountsResponse: UseFindCustomersResponse;
  OCNsResponse: UseFindGlobalOrdersResponse;
  key: keyof InfiniteQueryFlags;
  isOR: boolean;
  seachOcn?: boolean;
}

export const getSearchResponseFlag = ({
  searchType,
  customersResponse,
  suppliersResponse,
  employeesResponse,
  customerContactsResponse,
  motionLocationsResponse,
  corporateAccountsResponse,
  OCNsResponse,
  key,
  isOR = true,
}: GetSearchResponseFlagProps): boolean => {
  let value = isOR
    ? customersResponse[key] ||
      suppliersResponse[key] ||
      employeesResponse[key] ||
      customerContactsResponse[key] ||
      motionLocationsResponse[key] ||
      corporateAccountsResponse[key] ||
      OCNsResponse[key]
    : customersResponse[key] &&
      suppliersResponse[key] &&
      employeesResponse[key] &&
      customerContactsResponse[key] &&
      motionLocationsResponse[key] &&
      corporateAccountsResponse[key] &&
      OCNsResponse[key];
  if (searchType === 'customers') {
    value = customersResponse[key];
  }
  if (searchType === 'suppliers') {
    value = suppliersResponse[key];
  }
  if (searchType === 'employees') {
    value = employeesResponse[key];
  }
  if (searchType === 'customerContacts') {
    value = customerContactsResponse[key];
  }
  if (searchType === 'motionLocations') {
    value = motionLocationsResponse[key];
  }
  if (searchType === 'corporateAccounts') {
    value = corporateAccountsResponse[key];
  }
  if (searchType === 'ocns') {
    value = OCNsResponse[key];
  }

  return !!value;
};
