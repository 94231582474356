export interface GroupByProps {
  name: string;
  key: string;
  suffix?: string;
}
export const groupByRoleOptions = (isTableView?: boolean): GroupByProps[] => [
  { key: 'EXEC', name: 'reports:executive' },
  {
    key: 'CORP',
    name: isTableView ? 'reports:corpsPick12' : 'reports:corporations',
  },
  { key: 'GRP', name: 'reports:groups' },
  { key: 'DIV', name: 'reports:divisions' },
  { key: 'BRCH', name: 'reports:branches' },
  {
    key: 'REP',
    name: isTableView ? 'reports:acctRepsPick12' : 'reports:acctReps',
  },
];

export const groupByBranch = (isTableView?: boolean): GroupByProps[] => [
  {
    key: 'REP',
    name: isTableView ? 'reports:acctRepsPick12' : 'reports:acctReps',
  },
  { key: 'CUST', name: 'common:customers' },
];

export const groupByCostSavings: GroupByProps[] = [
  ...groupByRoleOptions(),
  { key: 'CUST', name: 'common:customers' },
];

export const groupByUnbillled = (userRole: string): GroupByProps[] =>
  // eslint-disable-next-line no-nested-ternary
  userRole === 'EXEC'
    ? [{ key: 'CORP', name: 'reports:corpsPick12' }]
    : userRole === 'CORP'
    ? [{ key: 'GRP', name: 'reports:groups' }]
    : [
        { key: 'DIV', name: 'reports:divisions' },
        { key: 'BRCH', name: 'reports:branches' },
      ];

export const groupByVirtualTeam: GroupByProps[] = [
  { key: 'TEAM', name: 'common:teams' },
  { key: 'CUST', name: 'common:customers' },
];

export const groupByCamExec: GroupByProps[] = [
  { key: 'NATLACCT', name: 'common:corpaccounts' },
];

export const groupByCamCorp: GroupByProps[] = [
  { key: 'GRP', name: 'reports:groups' },
  { key: 'DIV', name: 'reports:divisions' },
  { key: 'BRCH', name: 'reports:branches' },
  { key: 'CUST', name: 'common:customers' },
];

export const groupByCam: GroupByProps[] = [
  { key: 'CUST', name: 'common:customers' },
];

export const groupByTeamAndRep: GroupByProps[] = [
  { key: 'CUST', name: 'common:customers' },
];

export const groupByPersonalPick12: GroupByProps[] = [
  { key: 'CUST', name: 'common:customers' },
];

export const groupByCustomer: GroupByProps[] = [
  { key: 'PRD_GRP_01', name: 'reports:pgcLevel', suffix: '1' },
  { key: 'PRD_GRP_02', name: 'reports:pgcLevel', suffix: '2' },
];

export const groupByProductGroup01 = [
  { key: 'PRD_GRP_02', name: 'reports:pgcLevel', suffix: '2' },
];

export const groupByWithNameColumn = [
  'TEAM',
  'REP',
  'NATLACCT',
  'CUST',
  'PRD_GRP_01',
  'PRD_GRP_02',
];
