import React from 'react';
import { chunk, map } from 'lodash';
import { IonContent, IonModal, IonicSlides } from '@ionic/react';
import { Virtual, EffectCards } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import useFindLeaderboardDetails from 'api/leaderboard/useFindLeaderboardDetails';
import CustomersList from './CustomersLists';
import LeaderBoardDetailCard from './LeaderBoardDetailCard';
import classes from './Pick12LeaderBoardDetail.module.scss';
import 'swiper/css/effect-cards';

interface Pick12LeaderBoardDetailModalProps {
  closeLeaderBoardCard?: () => void;
  year: number;
  rank: number;
  userId: string;
  isOpen: boolean;
}

const Pick12LeaderBoardDetailsModal = ({
  year,
  userId,
  rank,
  closeLeaderBoardCard,
  isOpen,
}: Pick12LeaderBoardDetailModalProps): JSX.Element => {
  const { data: leaderBoardDetail, isLoading } = useFindLeaderboardDetails({
    year,
    userId,
    enabled: isOpen,
  });

  const pick12CustomersList = chunk(leaderBoardDetail?.pick12Customers, 10);
  return (
    <IonModal
      isOpen={isOpen}
      className={classes.pick12LeaderBoardModal}
      backdropDismiss
      onDidDismiss={closeLeaderBoardCard}
      data-testid="pick12-leaderboard-detail"
    >
      <IonContent data-testid="pick12-leaderboard-detail-content">
        <Swiper
          slidesPerView={1}
          spaceBetween={0}
          resizeObserver
          className={classes.slideOverride}
          modules={[IonicSlides, Virtual, EffectCards]}
          effect="cards"
          cardsEffect={{
            slideShadows: false,
            perSlideRotate: 1,
            perSlideOffset: 7,
          }}
        >
          <SwiperSlide
            className={classes.swiperPd}
            data-testid="pick12-leaderboard-detail-slide"
          >
            <LeaderBoardDetailCard
              isLoading={isLoading}
              closeLeaderBoardCard={closeLeaderBoardCard}
              leaderboardItem={leaderBoardDetail}
              rank={rank}
            />
          </SwiperSlide>
          {leaderBoardDetail?.userHasBranchAccess &&
            map(pick12CustomersList, (pick12Customers, index) => (
              <SwiperSlide
                key={index}
                className={classes.swiperPd}
                data-testid="pick12-leaderboard-detail-slide"
              >
                <CustomersList
                  pick12Customers={pick12Customers}
                  closeLeaderBoardCard={closeLeaderBoardCard}
                  currency={leaderBoardDetail?.currency}
                />
              </SwiperSlide>
            ))}
        </Swiper>
      </IonContent>
    </IonModal>
  );
};

export default Pick12LeaderBoardDetailsModal;
