import { defaultExpectedDate } from 'constants/platformSpecificConstants';
import { toNumber, head, toString, trim, toLower } from 'lodash';
import {
  OrderQuoteTypeEnum,
  OrderQuoteIconEnum,
  OrderQuoteColorEnum,
  OrderQuoteFilterEnum,
} from 'ActivitiesApp/models/OrderQuote';
import {
  getActivityDataValue,
  noActivityData,
} from 'ActivitiesApp/utils/helpers';
import { formatNumber } from 'common/utils/numberHelper';
import { differenceInDays } from 'date-fns';
import type { Contact, Recipient } from 'models/Contact';
import { DateFormatEnum, formatDate, parseDate } from 'utils/date';
import generateEmailList from 'utils/generateEmailList';
import { removeLeadingZeros } from 'utils/number';
import type {
  ActionCardConfig,
  ActivityTypeConfig,
  GetActivityConfigExtendedProps,
  GetActivityTypeDataProps,
} from 'pages/Activities/ActivityActionCard/ActivityCardConfig';

export const getOrderQuoteConfig = ({
  activityVersion,
  activityData,
  cardType,
  defaultFirstLine,
  t,
}: GetActivityConfigExtendedProps): ActionCardConfig | undefined => {
  const { fieldsData, extendedInfo, currency, ocn, totalOrderAmount } =
    activityData;
  const orderType = cardType === OrderQuoteTypeEnum.order ? '' : ' Large';
  const quoteType = cardType === OrderQuoteTypeEnum.quote ? '' : ' Large';
  const orderNumber = getActivityDataValue(
    fieldsData?.['Order Control Number'] ||
      fieldsData?.['Order Ctl No'] ||
      fieldsData?.ORDER_CTL_NO ||
      ocn
  );
  let formattedOCN = noActivityData;
  if (orderNumber !== noActivityData) {
    formattedOCN = removeLeadingZeros(orderNumber);
  }

  const orderStatus = getActivityDataValue(
    extendedInfo?.statusCdDesc ||
      extendedInfo?.statusCdDescr ||
      extendedInfo?.statusCd
  );
  const shipNumber = getActivityDataValue(extendedInfo?.scn, t('noSCN'));
  const shipStatus = getActivityDataValue(extendedInfo?.shipStatus);
  const activityDate = activityData.creationTimestampISO;
  let orderDate = getActivityDataValue(extendedInfo?.orderDate);
  orderDate = orderDate === defaultExpectedDate ? noActivityData : orderDate;
  let expectedDate = getActivityDataValue(fieldsData?.['Exp Delivery Date.']);
  expectedDate =
    expectedDate === defaultExpectedDate ? noActivityData : expectedDate;
  const custPONumber = getActivityDataValue(
    extendedInfo?.custPONo || fieldsData?.['Customer PO No.']
  );
  const orderLines = getActivityDataValue(
    fieldsData?.['Lines Ordered'] ||
      fieldsData?.LINES ||
      fieldsData?.LINES_ORDERED ||
      extendedInfo?.lines
  );
  let totalOrderAmt: string | number = getActivityDataValue(
    fieldsData?.['Total Order Amount'] ||
      fieldsData?.TOTAL_ORDER_AMT ||
      fieldsData?.TOTAL_AMT ||
      extendedInfo?.total ||
      totalOrderAmount
  );
  let formattedTotalAmount = noActivityData;
  if (totalOrderAmt !== noActivityData) {
    totalOrderAmt = toNumber(totalOrderAmt);
    formattedTotalAmount = formatNumber({
      number: toNumber(totalOrderAmt),
      scale: 2,
      currencyType: currency,
    });
  }
  const lateLineCount = toNumber(fieldsData?.['Late Line Count'] || 0);
  const promisedDateDifference =
    fieldsData?.['Earliest Promised Date'] &&
    fieldsData?.['Earliest Promised Date'] !== defaultExpectedDate
      ? differenceInDays(
          new Date(),
          parseDate(toString(fieldsData?.['Earliest Promised Date']))
        )
      : 0;
  const ocnContacts: Recipient[] = generateEmailList({
    name: trim(toString(extendedInfo?.ocnContactName)),
    rawEmailList: trim(toString(extendedInfo?.ocnContactEmail)),
    phone: toString(extendedInfo?.ocnContactPhone),
    splitChar: ';',
  });
  const ocnContact = head(ocnContacts) as Contact;
  const ocnContactName = getActivityDataValue(
    ocnContact?.name || ocnContact?.email || ocnContact?.phone
  );
  const emailContact = ocnContact;
  const emailContactName = emailContact?.name || emailContact?.email;

  switch (activityVersion) {
    case `${OrderQuoteTypeEnum.order}-1`:
    case `${OrderQuoteTypeEnum.largeOrder}-1`:
      return {
        card: {
          defaultTitle: t('orderActivityTitle', { orderType }),
          body: {
            description: t('orderActivityDescription', {
              orderType: toLower(orderType),
              orderNumber: formattedOCN,
              formattedTotalAmount,
            }),
            lines: [
              ...defaultFirstLine,
              {
                description: t('orderActivityDescription1', {
                  orderType: toLower(orderType),
                  orderNumber: formattedOCN,
                  formattedTotalAmount,
                }),
              },
              {
                description: t('orderActivityDescription2', {
                  orderType: toLower(orderType),
                  orderNumber: formattedOCN,
                  formattedTotalAmount,
                }),
              },
            ],
          },
        },
        modal: {
          title: t('orderActivityModalTitle', { orderType }),
          description: t('orderActivityModalDescription', {
            orderType: toLower(orderType),
            formattedTotalAmount,
          }),
          contactEmail: ocnContacts,
          defaultData: {
            orderNumber,
            lines: orderLines,
            custPONumber,
            total: totalOrderAmt,
            ocnContactName,
            orderDate,
            expectedDate,
            orderStatus,
            emailContact,
            emailContactName,
          },
        },
      };
    case `${OrderQuoteTypeEnum.lateOrder}-1`:
    case `${OrderQuoteTypeEnum.lateOrder}-2`:
      return {
        card: {
          defaultTitle: t('lateOrderTitle'),
          body: {
            description: t('lateOrderDescription', {
              orderNumber: formattedOCN,
              formattedTotalAmount,
            }),
            lines: [
              ...defaultFirstLine,
              {
                description: t('lateOrderListDescription', {
                  orderNumber: formattedOCN,
                  count: lateLineCount,
                  days: promisedDateDifference,
                  formattedTotalAmount,
                }),
                highlight: [
                  { query: t('lineItem', { count: lateLineCount }) },
                  {
                    query: t('daysLate_one', { count: promisedDateDifference }),
                  },
                  {
                    query: t('daysLate_other', {
                      count: promisedDateDifference,
                    }),
                  },
                  { query: formattedTotalAmount },
                ],
              },
            ],
          },
        },
        modal: {
          title: t('lateOrderTitle'),
          description: t('lateOrderDescription', {
            orderNumber,
            formattedTotalAmount,
          }),
          contactEmail: ocnContacts,
          defaultData: {
            orderNumber,
            lines: orderLines,
            custPONumber,
            total: totalOrderAmt,
            ocnContactName,
            orderDate,
            orderStatus,
            emailContact,
            emailContactName,
          },
        },
      };
    case `${OrderQuoteTypeEnum.orderShipped}-1`:
    case `${OrderQuoteTypeEnum.orderShipped}-2`:
      return {
        card: {
          defaultTitle: t('orderShippedTitle'),
          body: {
            description: t('orderShippedDescription', { shipNumber }),
            lines: [
              {
                ...defaultFirstLine[0],
                description: t('orderShippedDescription', { shipNumber }),
              },
            ],
          },
        },
        modal: {
          title: t('orderShippedTitle'),
          description: t('orderShippedDescription', { shipNumber }),
          contactEmail: ocnContacts,
          buttons: [
            {
              variant: 'action',
              text: t('trackShipment'),
              testid: `track-button`,
              disabled: true,
            },
            {
              variant: 'secondary',
              text: t('viewOrder'),
              testid: `view-button`,
              disabled: true,
            },
          ],
          defaultData: {
            orderNumber,
            custPONumber,
            lines: orderLines,
            total: totalOrderAmt,
            orderDate,
            shipStatus,
            // TODO: improve return this values
            emailContact,
            emailContactName,
          },
        },
      };
    case `${OrderQuoteTypeEnum.largeQuote}-1`:
    case `${OrderQuoteTypeEnum.quote}-1`:
      return {
        card: {
          defaultTitle: t('quoteActivityTitle', { quoteType }),
          body: {
            description: t('quoteActivityDescription', {
              quoteType: toLower(quoteType),
              orderNumber: formattedOCN,
              formattedTotalAmount,
            }),
            lines: [
              ...defaultFirstLine,
              {
                description: t('quoteActivityDescription1', {
                  orderType: toLower(orderType),
                  orderNumber: formattedOCN,
                  formattedTotalAmount,
                }),
              },
              {
                description: t('quoteActivityDescription2', {
                  orderType: toLower(orderType),
                  orderNumber: formattedOCN,
                  formattedTotalAmount,
                }),
              },
            ],
          },
        },
        modal: {
          title: t('quoteActivityModalTitle', { quoteType }),
          description: t('quoteActivityModalDescription', {
            quoteType: toLower(quoteType),
            formattedTotalAmount,
          }),
          contactEmail: ocnContacts,
          defaultData: {
            orderNumber,
            lines: orderLines,
            custPONumber,
            total: totalOrderAmt,
            ocnContactName,
            created: formatDate(activityDate, DateFormatEnum.standardDate),
            emailContact,
            emailContactName,
          },
        },
      };
    default:
      return undefined;
  }
};

export const getOrderQuoteData = ({
  eventTagName,
  t,
}: GetActivityTypeDataProps): ActivityTypeConfig | undefined => {
  switch (eventTagName) {
    case 'OrderCreated':
      return {
        cardType: OrderQuoteTypeEnum.order,
        title: trim(t('orderActivityModalTitle', { orderType: '' })),
        icon: OrderQuoteIconEnum.largeOrder,
        color: OrderQuoteColorEnum.largeOrder,
      };
    case 'LargeOrderCreated':
      return {
        cardType: OrderQuoteTypeEnum.largeOrder,
        title: t('orderActivityModalTitle', { orderType: t('largeTitle') }),
        icon: OrderQuoteIconEnum.largeOrder,
        color: OrderQuoteColorEnum.largeOrder,
      };
    case 'OrderShipped':
      return {
        cardType: OrderQuoteTypeEnum.orderShipped,
        title: t('orderShippedTitle'),
        icon: OrderQuoteIconEnum.largeOrder,
        color: OrderQuoteColorEnum.largeOrder,
      };
    case 'LateOrder':
      return {
        cardType: OrderQuoteTypeEnum.lateOrder,
        title: t('lateOrderTitle'),
        icon: OrderQuoteIconEnum.lateOrder,
        color: OrderQuoteColorEnum.lateOrder,
      };
    case 'QuoteCreated':
      return {
        cardType: OrderQuoteTypeEnum.quote,
        title: trim(t('customizeQuoteActivityTitle', { quoteType: '' })),
        icon: OrderQuoteIconEnum.largeQuote,
        color: OrderQuoteColorEnum.largeQuote,
      };
    case 'LargeQuoteCreated':
      return {
        cardType: OrderQuoteTypeEnum.largeQuote,
        title: trim(
          t('customizeQuoteActivityTitle', { quoteType: t('largeTitle') })
        ),
        icon: OrderQuoteIconEnum.largeQuote,
        color: OrderQuoteColorEnum.largeQuote,
      };
    default:
      return undefined;
  }
};

export const getOrderQuoteFilterIcon = (
  type: string
): OrderQuoteIconEnum | undefined => {
  switch (type) {
    case OrderQuoteFilterEnum.largeOrder:
      return OrderQuoteIconEnum.largeOrder;
    case OrderQuoteFilterEnum.lateOrder:
      return OrderQuoteIconEnum.lateOrder;
    case OrderQuoteFilterEnum.largeQuote:
      return OrderQuoteIconEnum.largeQuote;
    default:
      return undefined;
  }
};
