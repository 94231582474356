import type { Dictionary } from 'lodash';
import { includes, last, toString } from 'lodash';
import type { TimelineItem } from 'ActivitiesApp/components/Timeline/Timeline';
import {
  CrmActivityTypeEnum,
  type GeneralTaskInfo,
  type SecondaryStatus,
} from 'ActivitiesApp/models/CrmActivity';
import {
  CustomerVisitTypeEnum,
  MyVisitFilterKeys,
} from 'ActivitiesApp/models/CustomerVisit';
import { and, choose, or } from 'common/utils/logicHelpers';
import { endOfMonth, startOfMonth, subDays, subMonths } from 'date-fns';
import type { TFunction } from 'i18next';
import type { ActionCardActivity } from 'models/ActivityModels';
import type { FilterOption } from 'components/Filter/Filter';

export const noActivityData = 'N/A';

export const getActivityDataValue = (
  value?: unknown | string,
  placeholder?: string
): string => toString(value) || placeholder || noActivityData;

export const isVisit = (type: unknown = '') => type !== 'C';

export const getVisitAttachmentsIds = (activity?: ActionCardActivity) => {
  return activity?.eventTagName ===
    CustomerVisitTypeEnum.customerVisitedByNonRep
    ? {
        filteredHistoryId: toString(
          activity?.fields?.find(
            (item) => item.name === 'Linked Customer Visit History ID'
          )?.value
        ),
        attachmentUserId: toString(
          activity?.fields?.find(
            (item) => item.name === 'Visitor Employee User ID'
          )?.value
        ),
      }
    : {
        filteredHistoryId: toString(activity?.historyId),
        attachmentUserId: activity?.userId,
      };
};

export const BLANK_DUE_DATE = '1900-01-01 00:00:00.0';

export const highPriorityTask = 2;

export const getCrmTimeLineStatusTitle = (
  status: SecondaryStatus,
  t: TFunction
): string => {
  const titles: Dictionary<string> = {
    OPEN: t('assigned'),
    ACCEPT: t('accepted'),
    COMPL: t('completed'),
    CANC: t('cancelled'),
    DELETE: t('deleted'),
    REJECT: t('rejected'),
    WON: t('common:won'),
    LOST: t('common:lost'),
  };
  return titles[status];
};

export const getCrmRatingLabels = (
  t: TFunction,
  status: string,
  isCrmOpportunity = false
) => {
  const ratingActionTexts: Partial<Record<string, string[]>> = {
    OPEN: [t('common:accept'), t('common:reject')],
    ACCEPT: choose(
      isCrmOpportunity,
      [t('common:won'), t('common:lost')],
      [t('markCompleted'), t('cancelTask')]
    ),
  };
  return ratingActionTexts[status];
};

export const getCrmTimelineStatusLabel = (
  status: SecondaryStatus,
  t: TFunction
): string => {
  const labels: Dictionary<string> = {
    OPEN: `${t('common:createdBy')}:`,
    ACCEPT: t('acceptedBy'),
    COMPL: t('completedBy'),
    CANC: t('cancelledBy'),
    DELETE: t('deletedBy'),
    REJECT: t('rejectedBy'),
    WON: t('submittedBy'),
    LOST: t('submittedBy'),
  };
  return labels[status];
};

export const getCrmTimelineStatusVariant = (
  status: SecondaryStatus
): TimelineItem['variant'] => {
  const variants: Dictionary<TimelineItem['variant']> = {
    OPEN: 'success',
    ACCEPT: 'success',
    COMPL: 'success',
    CANC: 'alert',
    DELETE: 'alert',
    REJECT: 'alert',
    WON: 'success',
    LOST: 'alert',
    SCHED: 'alert',
    STALLED: 'alert',
  };
  return variants[status];
};

export const shouldShowDueDate = (secondaryStatus?: SecondaryStatus) => {
  return includes(['OPEN', 'ACCEPT'], secondaryStatus);
};

export const isCrmTask = (activity?: ActionCardActivity) =>
  or(
    activity?.eventTagName === CrmActivityTypeEnum.crmTask,
    activity?.eventTagName === CrmActivityTypeEnum.crmOpportunity
  );

export const crmHasOpenStatus = (activity?: ActionCardActivity) =>
  and(
    isCrmTask(activity),
    last((activity?.extendedInfo as unknown as GeneralTaskInfo)?.statusHistory)
      ?.secondaryStatus === 'OPEN'
  );

export const crmHasCancelStatus = (activity?: ActionCardActivity) =>
  last((activity?.extendedInfo as unknown as GeneralTaskInfo)?.statusHistory)
    ?.secondaryStatus === 'CANC';

export const getDateFilterParams = (
  selectedFilter?: FilterOption,
  customDate?: Date
): {
  startDate?: Date;
  endDate?: Date;
} => {
  let startDate;
  let endDate;
  const today = new Date();
  switch (selectedFilter?.key) {
    case MyVisitFilterKeys.today:
      startDate = today;
      endDate = today;
      break;
    case MyVisitFilterKeys.last_2weeks:
      startDate = subDays(today, 14);
      endDate = today;
      break;
    case MyVisitFilterKeys.this_month:
      startDate = startOfMonth(today);
      endDate = endOfMonth(today);
      break;
    case MyVisitFilterKeys.last_month:
      startDate = startOfMonth(subMonths(today, 1));
      endDate = endOfMonth(subMonths(today, 1));
      break;
    case MyVisitFilterKeys.custom_date:
      startDate = customDate;
      endDate = customDate;
      break;
    default:
      startDate = undefined;
      endDate = undefined;
  }
  return {
    startDate,
    endDate,
  };
};
export const isActivityOwner = (userId: string, activity: ActionCardActivity) =>
  userId === activity.userId;

export const crmDisableComments = (activity?: ActionCardActivity) =>
  and(
    isCrmTask(activity),
    last((activity?.extendedInfo as unknown as GeneralTaskInfo)?.statusHistory)
      ?.secondaryStatus === 'DELETE'
  );
