import React from 'react';
import { IonContent, IonPage, IonRow } from '@ionic/react';
import useGetAppLogo from 'hooks/useGetAppLogo';
import classes from './Blank.module.scss';

const Blank = (): JSX.Element => {
  const { logo } = useGetAppLogo();
  return (
    <IonPage className={classes.blankPage} data-testid="blank-page">
      <IonContent>
        <IonRow className={classes.wrapper}>
          <img
            id="mipro-logo"
            data-testid="mipro-logo"
            src={logo}
            alt="Mi Pro"
          />
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default Blank;
