import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useFindGlobalOrders from 'ProductSearchApp/api/orders/useFindGlobalOrders';
import useFindCustomers from 'api/customer/useFindCustomers';
import useFindCustomerContacts from 'api/customerContacts/useFindCustomerContacts';
import useFindEmployees from 'api/employee/useFindEmployees';
import useFindLocations from 'api/location/useFindLocations';
import useFindSuppliers from 'api/supplier/useFindSuppliers';
import useAccessControls, { AccessControlType } from 'hooks/useAccessControls';
import { SearchResultTabTypeEnum } from 'models/Search';
import type { SearchResponse, InfiniteQueryFlags } from 'models/Search';
import { selectIsMiLocCorpOrExec } from 'store/user/selectors';
import { pageSize } from 'utils/constants';
import { getSearchResponseFlag, concatSearchResults } from './utils';

interface FindSearchResultsProps {
  searchType: SearchResultTabTypeEnum;
  query?: string;
  limit?: number;
  sortField?: string;
}

interface UseFindSearchResultsResponse {
  fetchNextPage: () => Promise<void>;
}

const useFindSearchResults = ({
  searchType,
  query,
  limit = pageSize(),
  sortField,
}: FindSearchResultsProps): SearchResponse &
  InfiniteQueryFlags &
  UseFindSearchResultsResponse => {
  const findCustomersResponse = useFindCustomers({
    query,
    limit,
    enabled:
      searchType === SearchResultTabTypeEnum.all ||
      searchType === SearchResultTabTypeEnum.customers,
  });
  const findSuppliersResponse = useFindSuppliers({
    query,
    limit,
    enabled:
      searchType === SearchResultTabTypeEnum.all ||
      searchType === SearchResultTabTypeEnum.suppliers,
  });
  const findEmployeesResponse = useFindEmployees({
    query,
    limit,
    sortField,
    enabled:
      searchType === SearchResultTabTypeEnum.all ||
      searchType === SearchResultTabTypeEnum.employees,
  });
  const findCustomerContactsResponse = useFindCustomerContacts({
    query,
    limit,
    enabled:
      searchType === SearchResultTabTypeEnum.all ||
      searchType === SearchResultTabTypeEnum.customerContacts,
  });
  const findMotionLocationsResponse = useFindLocations({
    locTypes: ['S'],
    query,
    limit,
    enabled:
      searchType === SearchResultTabTypeEnum.all ||
      searchType === SearchResultTabTypeEnum.motionLocations,
  });
  const canSearchOrders = useAccessControls(
    AccessControlType.ViewOrdersAccessControls
  );
  const findOCNsResponse = useFindGlobalOrders({
    query,
    limit,
    enabled:
      canSearchOrders &&
      (searchType === SearchResultTabTypeEnum.all ||
        searchType === SearchResultTabTypeEnum.ocns),
  });

  const isCorpOrExec = useSelector(selectIsMiLocCorpOrExec);
  const findCorporateAccountsResponse = useFindCustomers({
    query,
    limit,
    enabled:
      isCorpOrExec &&
      (searchType === SearchResultTabTypeEnum.all ||
        searchType === SearchResultTabTypeEnum.corporateAccounts),
    nationalAccountOnly: true,
  });

  const {
    refetch: refetchCustomers,
    fetchNextPage: fetchNextPageCustomers,
    data: customersResponse,
    error: errorCustomers,
    enableInfiniteScroll: enableInfiniteScrollCustomers,
  } = findCustomersResponse;
  const {
    refetch: refetchSuppliers,
    fetchNextPage: fetchNextPageSuppliers,
    data: suppliersResponse,
    error: errorSuppliers,
    enableInfiniteScroll: enableInfiniteScrollSuppliers,
  } = findSuppliersResponse;

  const {
    refetch: refetchEmployees,
    fetchNextPage: fetchNextPageEmployees,
    data: employeesResponse,
    error: errorEmployees,
    enableInfiniteScroll: enableInfiniteScrollEmployees,
  } = findEmployeesResponse;

  const {
    refetch: refetchCustomerContacts,
    fetchNextPage: fetchNextPageCustomerContacts,
    data: customerContactsResponse,
    error: errorCustomerContacts,
    enableInfiniteScroll: enableInfiniteScrollCustomerContacts,
  } = findCustomerContactsResponse;

  const {
    refetch: refetchMotionLocations,
    fetchNextPage: fetchNextPageMotionLocations,
    data: motionLocationsResponse,
    error: errorMotionLocations,
    enableInfiniteScroll: enableInfiniteScrollMotionLocations,
  } = findMotionLocationsResponse;

  const {
    refetch: refetchCorporateAccounts,
    fetchNextPage: fetchNextPageCorporateAccounts,
    data: corporateAccountsResponse,
    error: errorCorporateAccounts,
    enableInfiniteScroll: enableInfiniteScrollCorporateAccounts,
  } = findCorporateAccountsResponse;

  const {
    refetch: refetchOCNs,
    fetchNextPage: fetchNextPageOCNs,
    data: OCNsResponse,
    error: errorOCNs,
    enableInfiniteScroll: enableInfiniteScrollOCNs,
  } = findOCNsResponse;

  const searchResults = useMemo(
    () =>
      concatSearchResults({
        searchType,
        customersResponse,
        suppliersResponse,
        employeesResponse,
        customerContactsResponse,
        motionLocationsResponse,
        corporateAccountsResponse,
        OCNsResponse,
      }),
    [
      searchType,
      customersResponse,
      suppliersResponse,
      employeesResponse,
      customerContactsResponse,
      motionLocationsResponse,
      corporateAccountsResponse,
      OCNsResponse,
    ]
  );

  const getFlagValue = (key: keyof InfiniteQueryFlags, isOR = true) =>
    getSearchResponseFlag({
      searchType,
      customersResponse: findCustomersResponse,
      suppliersResponse: findSuppliersResponse,
      employeesResponse: findEmployeesResponse,
      customerContactsResponse: findCustomerContactsResponse,
      motionLocationsResponse: findMotionLocationsResponse,
      corporateAccountsResponse: findCorporateAccountsResponse,
      OCNsResponse: findOCNsResponse,
      key,
      isOR,
    });

  let error = errorCustomers || errorSuppliers;
  if (searchType === 'customers') {
    error = errorCustomers;
  }
  if (searchType === 'suppliers') {
    error = errorSuppliers;
  }
  if (searchType === 'employees') {
    error = errorEmployees;
  }
  if (searchType === 'customerContacts') {
    error = errorCustomerContacts;
  }
  if (searchType === 'motionLocations') {
    error = errorMotionLocations;
  }
  if (searchType === 'corporateAccounts') {
    error = errorCorporateAccounts;
  }
  if (searchType === 'ocns') {
    error = errorOCNs;
  }

  return {
    items: searchResults,
    fetchNextPage: async () => {
      const promises = [];
      if (enableInfiniteScrollCustomers) {
        promises.push(fetchNextPageCustomers?.call(null));
      }
      if (enableInfiniteScrollSuppliers) {
        promises.push(fetchNextPageSuppliers?.call(null));
      }
      if (enableInfiniteScrollEmployees) {
        promises.push(fetchNextPageEmployees?.call(null));
      }
      if (enableInfiniteScrollCustomerContacts) {
        promises.push(fetchNextPageCustomerContacts?.call(null));
      }
      if (enableInfiniteScrollMotionLocations) {
        promises.push(fetchNextPageMotionLocations?.call(null));
      }
      if (enableInfiniteScrollCorporateAccounts) {
        promises.push(fetchNextPageCorporateAccounts?.call(null));
      }
      if (enableInfiniteScrollOCNs) {
        promises.push(fetchNextPageOCNs?.call(null));
      }
      await Promise.all(promises);
    },
    error,
    hasError: getFlagValue('hasError'),
    showLoader: getFlagValue('showLoader'),
    isEmptyResponse: getFlagValue('isEmptyResponse', false),
    noMoreData: getFlagValue('noMoreData', false),
    enableInfiniteScroll: getFlagValue('enableInfiniteScroll'),
    refetch: async () => {
      await Promise.all([
        refetchCustomers?.call(null),
        refetchSuppliers?.call(null),
        refetchEmployees?.call(null),
        refetchCustomerContacts?.call(null),
        refetchMotionLocations?.call(null),
        refetchCorporateAccounts?.call(null),
        refetchOCNs?.call(null),
      ]);
    },
  };
};

export default useFindSearchResults;
