/* eslint-disable no-console */
import { trim } from 'lodash';
import type { ItemPOU } from 'StoreroomsApp/models/ItemPOU';
import useScannerDB from './initScannerDB';
import { createItems } from './utils';

interface FindItemsPOUByStoreroomProps {
  miLoc: string;
  storeroomNumber: string;
  replenishmentId?: string;
  issueId?: string;
  query?: string;
  barcode?: string;
}

interface UseItemPOUDBResponse {
  createItemsPOU: (items: ItemPOU[]) => Promise<void>;
  findItemsPOUByStoreroom: (
    props: FindItemsPOUByStoreroomProps
  ) => Promise<ItemPOU[]>;
  removeItemsPOU: (miLoc: string) => Promise<void>;
}

const useItemPOUDB = (): UseItemPOUDBResponse => {
  const { db, openDB, closeDB } = useScannerDB();

  const createItemsPOU = async (items: ItemPOU[]): Promise<void> => {
    try {
      await openDB();
      await createItems(db, items);
    } catch (error) {
      console.log('Error saving items POU to database', error);
      throw new Error('Error saving items POU to database');
    } finally {
      await closeDB();
    }
  };

  const findItemsPOUByStoreroom = async ({
    miLoc,
    replenishmentId,
    issueId,
    ...props
  }: FindItemsPOUByStoreroomProps): Promise<ItemPOU[]> => {
    try {
      const vars = [];
      if (replenishmentId) {
        vars.push(replenishmentId);
      }
      if (issueId) {
        vars.push(issueId);
      }
      vars.push(miLoc);
      const query = trim(props.query);
      const queryLike = `%${query}%`;
      if (query) {
        vars.push(queryLike, queryLike);
      }
      const storeroomNumber = trim(props.storeroomNumber);
      if (storeroomNumber) {
        vars.push(`%${storeroomNumber}%`);
      }
      const barcode = trim(props.barcode);
      if (barcode) {
        vars.push(barcode);
      }
      await openDB();
      return (
        await db.query(
          `SELECT itemspou.*
          ${
            replenishmentId
              ? `,
            (SELECT COUNT(*) 
            FROM item_replenishment 
            WHERE itemspou.combinedId = item_replenishment.itemId
            AND item_replenishment.replenishmentId = ?) as alreadyAddedToReplenishment`
              : ''
          }
          ${
            issueId
              ? `,
            (SELECT COUNT(*) 
            FROM item_issue 
            WHERE itemspou.combinedId = item_issue.itemId
            AND item_issue.issueId = ?) as alreadyAddedToIssue`
              : ''
          }
          FROM itemspou
          WHERE miLocation like ?
          ${
            query
              ? `AND (customerStockNumber LIKE ? OR itemDescription LIKE ?)`
              : ''
          }
          ${storeroomNumber ? `AND storeroomNumber LIKE ?` : ''}
          ${barcode ? `AND barcodeValue = ?` : ''}
          ORDER BY customerStockNumber ASC`,
          vars
        )
      ).values as ItemPOU[];
    } catch (error) {
      console.log('Error loading items POU', error);
      throw new Error('Error loading items POU');
    } finally {
      await closeDB();
    }
  };

  const removeItemsPOU = async (miLoc: string): Promise<void> => {
    try {
      await openDB();
      await db.query(
        `DELETE FROM itemspou
        WHERE miLocation = ?`,
        [miLoc]
      );
    } catch (error) {
      console.log('Error removing items POU', error);
      throw new Error('Error removing items POU');
    } finally {
      await closeDB();
    }
  };

  return {
    createItemsPOU,
    findItemsPOUByStoreroom,
    removeItemsPOU,
  };
};

export default useItemPOUDB;
