import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { find, toNumber, toString } from 'lodash';
import { or } from 'common/utils/logicHelpers';
import { namespaces } from 'i18n/i18n.constants';
import { reportsDrillDownURL, reportsURL } from 'navigation';
import {
  useReportsConfig,
  Pick12HomeKey,
  TabPick12ReportKey,
} from 'providers/ReportsProvider';
import useGetSalesReport from 'ReportsApp/api/useGetSalesReport';
import type { RootState } from 'store/reducers';
import { concatRoutes } from 'utils/navigations';
import { GetIncludeDailyTotalsMsg } from 'utils/reports';
import Pick12Icon from 'components/Pick12Icon/Pick12Icon';
import Text from 'components/Text/Text';
import DashboardCard from './DashboardCard';
import type { DashboardLineProps } from './DashboardCard';
import classes from './DashboardCard.module.scss';

const Pick12Card = (): JSX.Element => {
  const { t } = useTranslation(namespaces.reports);

  const { updateRequestType, updateBusPeriod, requestType, busPeriod } =
    useReportsConfig({ key: Pick12HomeKey });
  const { miLoc: stateMiLoc = '', locationTree } = useSelector(
    (state: RootState) => state.user
  );

  const pick12Url = concatRoutes(
    reportsURL(),
    reportsDrillDownURL('pick12', '', '', '', '')
  );

  const loctionPick12Url = concatRoutes(
    reportsURL(),
    reportsDrillDownURL('locationPick12', '', '', '', '')
  );

  const { includeDailyTotalMsg = '', includeDailyTotalInfo = '' } =
    GetIncludeDailyTotalsMsg({
      requestType,
      busPeriod,
    });

  const stateLocation = locationTree?.[stateMiLoc];
  const fromVirtualTeam = stateMiLoc === 'VT';
  const team = stateLocation?.locationType === 'T';
  const pick12 = or(fromVirtualTeam, team);
  const miLoc = pick12 ? undefined : stateLocation?.miLoc;

  const {
    summaryData: newHomePick12Data,
    showLoader: newPick12Loading,
    error: newSummaryError,
  } = useGetSalesReport({
    miLoc,
    busPeriod,
    requestType,
    summaryOnly: true,
    isPick12Report: pick12,
    isLocationPick12Report: !pick12,
  });

  const lines = useMemo(() => {
    const currencyType = newHomePick12Data?.currencyType;
    const result: DashboardLineProps[] = [
      {
        text: t('groupTotalSales'),
        value: or(
          find(newHomePick12Data?.sales, {
            Name: 'Sales',
          })?.amount,
          0
        ),
        valueType: 'currency',
        currencyType,
        changePercentage: or(
          find(newHomePick12Data?.sales, {
            Name: 'Sales',
          })?.change,
          0
        ),
        changeLabel: 'YOY',
      },
      {
        text: t('totalGP'),
        value: or(
          find(
            newHomePick12Data?.profit,

            {
              Name: 'GP',
            }
          )?.amount,
          0
        ),
        valueType: 'currency',
        currencyType,
        changePercentage: or(
          find(newHomePick12Data?.profit, {
            Name: 'GP',
          })?.change,
          0
        ),
        changeLabel: 'YOY',
      },
      {
        text: t('activePick12'),
        value: `${or(
          toNumber(newHomePick12Data?.pick12CustomerCount),
          0
        )} of ${or(toNumber(newHomePick12Data?.totalPick12CustCount), 0)}`,
        valueType: 'string',
      },
      {
        text: t('activePick12'),
        value: or(toNumber(newHomePick12Data?.pick12Pct), 0),
        valueType: 'percentage',
      },
    ];

    return result;
  }, [newHomePick12Data, t]);

  return (
    <DashboardCard
      id="pick12"
      customTitle={
        <div className={classes.iconWrapper}>
          <Pick12Icon testid="dashboard-card" />
          <Text variant="title-action-card" text={t('pick12')} testid="title" />
        </div>
      }
      subtitle={pick12 ? t('myPick12') : toString(stateLocation?.name)}
      lines={lines}
      primaryButton={{
        href: pick12 ? pick12Url : loctionPick12Url,
        text: t(`${namespaces.reports}:viewFullReport`),
      }}
      secondaryButton={{
        href: concatRoutes(
          reportsURL(),
          reportsDrillDownURL('pick12LeaderBoard', '', '', '', '')
        ),
        text: t(`${namespaces.reports}:viewLeaderBoard`),
        icon: 'trophy-alt',
      }}
      overlayBarMsg={includeDailyTotalMsg}
      overlayInfoHeader={includeDailyTotalInfo}
      reportKey={Pick12HomeKey}
      tabReportKey={TabPick12ReportKey}
      requestType={requestType}
      busPeriod={busPeriod}
      isLoading={newPick12Loading}
      error={newSummaryError}
      setRequestType={updateRequestType}
      setBusPeriod={updateBusPeriod}
    />
  );
};

export default Pick12Card;
