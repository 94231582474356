import type React from 'react';
import { useEffect, useState } from 'react';
import { useIMask } from 'react-imask';
import { isObject, toString } from 'lodash';
import type { IonInput } from '@ionic/react';
import { or } from 'common/utils/logicHelpers';
import { currencyMap, emptyNumber } from 'common/utils/numberHelper';
import type { FactoryOpts } from 'imask';
import { maxNumericValue } from 'utils/constants';
import type { InputMaskOptions } from './Input';

interface UseInputMaskProps extends InputMaskOptions {
  value?: string;
  setValue?: (v: string) => void;
  inputmode?: React.ComponentProps<typeof IonInput>['inputmode'];
  inputRef: HTMLIonInputElement;
}

const useInputMask = (props: UseInputMaskProps) => {
  const {
    mask,
    numberMask,
    currencyMask,
    value,
    setValue,
    inputRef,
    currencyType = 'USD',
  } = props;
  let { maskOptions, inputmode } = props;
  const numberMasked = isObject(numberMask) ? true : numberMask;
  const numberMaskedProps = isObject(numberMask) ? numberMask : undefined;
  const currencyMasked = isObject(currencyMask) ? true : currencyMask;
  const currencyMaskedProps = isObject(currencyMask) ? currencyMask : undefined;
  let numberMaskedBlock = {};

  if (numberMasked || currencyMasked) {
    inputmode ||= 'decimal';
    numberMaskedBlock = {
      mask: Number,
      min: 0,
      max: maxNumericValue,
      scale: 2,
      radix: '.',
      // TODO should follow user settings/location
      // mapToRadix: [','],
      padFractionalZeros: true,
      thousandsSeparator: ',',
      ...numberMaskedProps,
      ...currencyMaskedProps,
    };
    maskOptions = {
      mask: [
        { mask: emptyNumber },
        {
          mask: `${currencyMasked ? or(currencyMap[currencyType], '$') : ''}g1`,
          blocks: {
            g1: numberMaskedBlock,
          },
        },
      ],
    };
  }

  let isMasked = !!mask;
  isMasked ||= !!maskOptions;
  const imaskOptions = { mask, ...maskOptions } as FactoryOpts;
  const [initUnmasked, setInitUnmasked] = useState(false);
  const [updUnmasked, setUpdUnmasked] = useState(false);

  const {
    ref: maskedInputRef,
    value: maskedValue,
    unmaskedValue,
    setUnmaskedValue,
  } = useIMask<HTMLIonInputElement>(imaskOptions, {
    onAccept: (v, maskRef) => {
      if (initUnmasked && !updUnmasked && isMasked) {
        setValue?.(maskRef.unmaskedValue);
        inputRef.value = maskRef.unmaskedValue;
      }
      setUpdUnmasked(false);
    },
  });

  useEffect(() => {
    if (isMasked && toString(value) !== toString(unmaskedValue)) {
      setUnmaskedValue(toString(value));
      setUpdUnmasked(true);
    }
    setInitUnmasked(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return {
    inputmode,
    isMasked,
    maskedInputRef,
    maskedValue,
    unmaskedValue,
    imaskOptions,
    numberMaskedBlock,
  };
};

export default useInputMask;
