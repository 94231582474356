import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { defaultExpectedDate } from 'constants/platformSpecificConstants';
import classNames from 'classnames';
import { filter, map, toString, isEmpty, uniq, split, trim } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonGrid, IonRow } from '@ionic/react';
import type { InsetListData } from 'common/components/InsetList/InsetList';
import InsetList from 'common/components/InsetList/InsetList';
import { emptyString } from 'common/utils/valueFormatter';
import { useFormikContext } from 'formik';
import useTransmitDetails from 'ProductSearchApp/hooks/useTransmitLogDetails';
import type { CheckoutOrderForm, Order } from 'ProductSearchApp/models/Order';
import { mergeEmailRecipients } from 'ProductSearchApp/util/ocnHelpers';
import type { Recipient } from 'models/Contact';
import { DateFormatEnum, formatLastUpdatedDate } from 'utils/date';
import { PhoneCallRow } from 'components/Contacts/PhoneNumber/PhoneNumber';
import { EmailRow } from 'components/Email/Email';
import SendEmailModal from 'components/Modals/SendEmailModal/SendEmailModal';
import Text from 'components/Text/Text';
import classes from './OcnLinesReview.module.scss';

interface OcnHeaderProps {
  summaryMode?: boolean;
  ocnType?: string;
  order?: Order;
}

const OcnHeader = ({
  summaryMode,
  ocnType,
  order,
}: OcnHeaderProps): JSX.Element => {
  const namespace = 'productSearch:review';
  const checkoutNameSpace = 'productSearch:checkout';
  const isOrder = ocnType === 'order';
  const { t } = useTranslation();
  const [sendEmailModal, setSendEmailModal] = useState(false);
  const { values } = useFormikContext<CheckoutOrderForm>();
  const { hasTransmitted, transmitMessage } = useTransmitDetails({
    ...order?.lastOrderTransmissionLog,
  });

  const customerContact =
    mergeEmailRecipients(values.typedRecipients) || values.contactEmail;

  const ocnEmailList = !isEmpty(customerContact)
    ? map(uniq(split(customerContact, ';')), trim)
    : [];

  const recepeints: Recipient[] = map(ocnEmailList, (item) => {
    return {
      email: item,
      miLoc: order?.miLoc,
    };
  });

  const ocnHeaderLines: InsetListData[] = [
    {
      label: t(`${namespace}:customerContact`),
      value: values.contactName,
    },
    {
      label: t(`${namespace}:customerPhone`),
      customValue: !isEmpty(values.contactNumber) ? (
        <PhoneCallRow
          phoneNumber={toString(values.contactNumber)}
          testid="ocn-contact-number"
          className={classes.customRow}
        />
      ) : undefined,
    },
    {
      label: t(`${namespace}:customerEmail`),
      customValue: !isEmpty(customerContact) ? (
        <EmailRow
          emailAddress={toString(customerContact)}
          testid="ocn-contact"
          className={classes.customRow}
          onClick={() => {
            setSendEmailModal(true);
          }}
        />
      ) : undefined,
    },
    {
      label: t(`${checkoutNameSpace}:expectedDt`),
      value:
        values.dueDate === defaultExpectedDate
          ? t(`${namespace}:notSet`)
          : formatLastUpdatedDate(values.dueDate, DateFormatEnum.dayShortMonth),
    },
    {
      label: t(`${checkoutNameSpace}:poNumber`),
      value: values.poNumber,
    },
    {
      label: t(`${checkoutNameSpace}:releaseNumber`),
      value: values.custReleaseNo,
    },
    {
      label: t(`${checkoutNameSpace}:shippingCharges`),
      value: values.shipChargesCd?.codeValue
        ? `(${values.shipChargesCd?.codeValue}) ${values.shipChargesCd?.codeDesc}`
        : '',
    },
    {
      label: t(`${checkoutNameSpace}:carrier`),
      value: values.carrierCd?.codeValue
        ? `(${values.carrierCd?.codeValue}) ${values.carrierCd?.codeDesc}`
        : '',
    },
    {
      label: t(`${checkoutNameSpace}:shippingMethod`),
      value: values.shipMethodCd?.codeValue
        ? `(${values.shipMethodCd?.codeValue}) ${values.shipMethodCd?.codeDesc}`
        : '',
    },
    {
      label: t(`${checkoutNameSpace}:carrierAccount`),
      value: values.carrierAccount,
    },
    {
      label: t(`${checkoutNameSpace}:orderSource`),
      value: values.orderSourceDesc,
    },
  ];

  const ocnHeaderCheckLines = [
    {
      label: t(`${namespace}:sendCustomer`, {
        emailType:
          values.emailCustomer && values.emailAckType !== 'CO'
            ? t(`${checkoutNameSpace}:changeOrder`)
            : t(`${checkoutNameSpace}:confirmation`),
      }),
      checkValue: values.emailCustomer,
      hidden: !isOrder || (summaryMode && !values.emailCustomer),
    },
    {
      label: t(`${namespace}:pendingApproval`),
      checkValue: values.priceOverridePending,
      hidden: summaryMode && !values.priceOverridePending,
    },
    {
      label: t(`${namespace}:emailQuote`),
      checkValue: values.emailCustomer,
      hidden: summaryMode || isOrder,
    },
    {
      label: t(`${namespace}:sendOrderToUnprocessedInECOS`),
      checkValue: values.sendUnprocessed,
      hidden: summaryMode,
    },
    {
      label: t(`${namespace}:showInMotion`),
      checkValue: values.excludeFromWeb !== 'Y',
      hidden: summaryMode && !(values.excludeFromWeb !== 'Y'),
    },
    {
      label: transmitMessage,
      checkValue: true,
      hidden:
        values.priceOverridePending ||
        order?.approvedAfterLastTransmission ||
        !hasTransmitted,
    },
    {
      label: t(`${checkoutNameSpace}:includeAttachments`),
      checkValue: values.includeAttachments,
      hidden: summaryMode,
    },
  ];

  const ocnHeaderListList = map(ocnHeaderLines, (item) => {
    return {
      ...item,
      value: { variant: 'mipro-h6-headline', text: item.value || emptyString },
      label: { variant: 'mipro-h6-headline', text: item.label },
    } as InsetListData;
  });

  return (
    <>
      <IonGrid className={classes.ocnGrid} data-testid="ocn-header-grid">
        <IonRow className={classes.ocnLineHeader}>
          <Text
            className={classes.ocnLines}
            variant="title-screen-section"
            testid="ocn-header-title"
            text={t(`${namespace}:ocnHeader`)}
          />
        </IonRow>
      </IonGrid>
      <div className={classes.ocnLineHeaderWrapper}>
        <InsetList
          data={ocnHeaderListList}
          testid="ocn-header-lines"
          className={classes.table}
        />
      </div>

      <IonGrid className={classes.ocnLineGrid}>
        {map(
          filter(ocnHeaderCheckLines, (item) => !item.hidden),
          (item, index) => {
            const icon = item.checkValue ? 'check' : 'times';
            return (
              <IonRow
                key={index}
                className={classNames(
                  classes.ocnLineHeader,
                  classes.ocnLineItem
                )}
              >
                <FontAwesomeIcon
                  icon={['far', icon]}
                  className={classNames({
                    [classes.times]: !item.checkValue,
                    [classes.check]: item.checkValue,
                  })}
                />
                <Text
                  className={classNames(classes.checkLabel)}
                  variant="mipro-h6-headline"
                  text={item.label}
                />
              </IonRow>
            );
          }
        )}
      </IonGrid>
      <SendEmailModal
        searchType="customer"
        id={order?.shipToCustNo}
        miLoc={order?.miLoc}
        isOpen={sendEmailModal}
        defaultRecipients={recepeints}
        setIsOpen={setSendEmailModal}
        title={t('common:sendEmail')}
        testid="send-email-modal"
      />
    </>
  );
};

export default OcnHeader;
