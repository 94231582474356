import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AxiosError } from 'axios';
import { isEmpty, size, toString, get } from 'lodash';
import { useIonViewWillEnter } from '@ionic/react';
import type { QueryFunctionContext } from '@tanstack/react-query';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import type { QueryFlags } from 'common/api/utils/useGetQueryFlags';
import useGetQueryFlags from 'common/api/utils/useGetQueryFlags';
import { differenceInSeconds } from 'date-fns';
import { t } from 'i18next';
import type { Order } from 'ProductSearchApp/models/Order';
import { canEditOCN } from 'ProductSearchApp/util/ocnHelpers';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import {
  doGetIsLoading,
  onErrorUpdate,
  onMutateUpdate,
  useKeyUserId,
} from 'api/helpers';
import { ToastType } from 'models/Toast';
import type { RootState } from 'store/reducers';
import { clearCurrentCartCustomer } from 'store/user';
import { findOrdersQueryKey } from './orders/useFindOrders';

export const getOrderQueryKey = 'get-order';

interface UseGetOrderProps {
  miLoc?: string;
  orderCtlNo?: string;
  enabled?: boolean;
  refetchOnEnter?: boolean;
}

interface UseGetOrderResponse {
  status?: 'success' | 'error' | 'loading';
  order?: Order;
  numberOfCartItems: number;
  getOrder?: (
    miLoc: string,
    orderCtlNo: string,
    updateCaching?: boolean
  ) => Promise<Order | undefined>;
  orderChangedToOP?: (
    order?: Order,
    updateCaching?: boolean
  ) => Promise<{ changedToOP: boolean; stayedUM: boolean }>;
}

const useGetOrder = ({
  miLoc,
  orderCtlNo,
  enabled: propsEnabled = true,
  refetchOnEnter,
}: UseGetOrderProps): UseGetOrderResponse & QueryFlags<Order> => {
  const { axios } = useAxios();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { getOrderAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const { addToast } = useToasts();
  const { userInfo, currentCartCustomer } = useSelector(
    (state: RootState) => state.user
  );
  const userId = get(userInfo, 'userid', '');
  const enabled =
    propsEnabled && !isEmpty(miLoc) && !isEmpty(orderCtlNo) && !isEmpty(userId);

  const getOrder = async (
    orderMiLoc: string,
    orderOCN: string,
    updateCaching = true
  ): Promise<Order | undefined> => {
    if (!orderMiLoc || !orderOCN) {
      return undefined;
    }
    let context;
    let data;
    try {
      context = await onMutateUpdate<Order>({
        queryClient,
        queryKey: findOrdersQueryKey,
        updatedItems: [{ orderCtlNo: orderOCN }],
        dataPath: 'orders',
        findPredicates: [{ orderCtlNo: orderOCN }],
        isInfiniteQuery: true,
      });
      ({ data } = await axios.get<Order>(getOrderAPI(orderMiLoc, orderOCN)));
      return data;
    } catch (e) {
      throw new Error();
    } finally {
      onErrorUpdate<Order>({
        queryClient,
        dataPath: 'orders',
        context,
        isInfiniteQuery: true,
      });
      // TODO create utils to update query data
      if (updateCaching) {
        queryClient.setQueryData<Order>(
          createQueryKey(getOrderQueryKey, {
            miLoc: orderMiLoc,
            orderCtlNo: orderOCN,
          }),
          data
        );
      }
    }
  };

  const orderChangedToOP = async (order?: Order, updateCaching = true) => {
    let stayedUM = false;
    try {
      const newData = await getOrder(
        toString(order?.miLoc),
        toString(order?.orderCtlNo),
        updateCaching
      );
      stayedUM =
        order?.processStatus === 'UM' && newData?.processStatus === 'UM';
      if (order?.processStatus === 'UM' && newData?.processStatus === 'OP') {
        addToast({
          type: ToastType.error,
          text: t('productSearch:checkout:lockedOrderError'),
          testid: 'locked-order-error-toast',
        });
        return { changedToOP: true, stayedUM };
      }
      return { changedToOP: false, stayedUM };
    } catch (e) {
      addToast({
        type: ToastType.error,
        text: t('productSearch:checkout:getOrderError'),
        testid: 'get-order-error-toast',
      });
      throw new Error();
    }
  };

  const doGetOrder = async ({ signal }: QueryFunctionContext) => {
    const { data } = await axios.get<Order>(
      getOrderAPI(toString(miLoc), toString(orderCtlNo)),
      { signal }
    );
    return data;
  };

  const response = useQuery<Order, AxiosError>(
    createQueryKey(getOrderQueryKey, { miLoc, orderCtlNo }),
    doGetOrder,
    { enabled, retry: 1 }
  );
  const { data, error, dataUpdatedAt } = response;

  useEffect(() => {
    if (
      miLoc === currentCartCustomer?.miLoc &&
      orderCtlNo === currentCartCustomer?.orderCtlNo &&
      data?.orderCtlNo &&
      !canEditOCN(data)
    ) {
      dispatch(clearCurrentCartCustomer());
    }
  }, [
    dispatch,
    currentCartCustomer?.miLoc,
    currentCartCustomer?.orderCtlNo,
    data,
    miLoc,
    orderCtlNo,
  ]);

  useEffect(() => {
    if (
      miLoc === currentCartCustomer?.miLoc &&
      orderCtlNo === currentCartCustomer?.orderCtlNo &&
      !!error
    ) {
      dispatch(clearCurrentCartCustomer());
    }
  }, [
    dispatch,
    currentCartCustomer?.miLoc,
    currentCartCustomer?.orderCtlNo,
    error,
    miLoc,
    orderCtlNo,
  ]);

  const queryFlags = useGetQueryFlags({ ...response, enabled });

  useIonViewWillEnter(() => {
    if (
      enabled &&
      refetchOnEnter &&
      differenceInSeconds(Date.now(), dataUpdatedAt) > 10
    ) {
      void queryFlags.refetch?.();
    }
  }, [refetchOnEnter, enabled, dataUpdatedAt]);

  return {
    order: data,
    numberOfCartItems: size(data?.items),
    ...queryFlags,
    isLoading: doGetIsLoading(response),
    getOrder,
    orderChangedToOP,
  };
};

export default useGetOrder;
