/* eslint-disable no-console */
import type { Dictionary } from 'lodash';
import { map, reduce, size, slice, toString } from 'lodash';
import type {
  Issue,
  ItemIssue,
  ItemIssueDTO,
} from 'StoreroomsApp/models/Issue';
import useScannerDB from './initScannerDB';
import { createIssueItems } from './utils';

interface FindIssuesByStoreroomProps {
  miLoc: string;
  storeroomNumber: string;
  issueId?: string;
}

interface UseIssueDBResponse {
  createIssue: (issue: Issue) => Promise<string>;
  addItemsToIssue: (items: ItemIssue[]) => Promise<void>;
  findIssuesByStoreroom: (
    props: FindIssuesByStoreroomProps
  ) => Promise<Issue[]>;
  findItemsFromIssue: (issueId: string) => Promise<ItemIssueDTO[]>;
  findItemsFromItemId: (ids: string[]) => Promise<ItemIssueDTO[]>;
  updateIssueType: (issueId: string, issueType: string) => Promise<void>;
  updateIssueChargeBack: (
    issueId: string,
    chargeBackValues: Dictionary<string>
  ) => Promise<void>;
  updateIssueQuantity: (itemId: number, quantity: number) => Promise<void>;
  closeIssue: (issueId: number) => Promise<void>;
  removeIssue: (issueId: number) => Promise<void>;
}

const useIssueDB = (): UseIssueDBResponse => {
  const { db, openDB, closeDB } = useScannerDB();

  const createIssue = async (issue: Issue): Promise<string> => {
    try {
      await openDB();
      const response = await db.run(
        `INSERT OR IGNORE INTO issue (
        recordType,
        userId,
        userName,
        miLocation,
        storeroomNumber,
        creationTimestamp,
        takeOnStatus
        ) VALUES (?,?,?,?,?,?,?)`,
        [
          issue.recordType,
          issue.userId,
          issue.userName,
          issue.miLocation,
          issue.storeroomNumber,
          Date.now(),
          issue.takeOnStatus,
        ]
      );
      return toString(response.changes?.lastId);
    } catch (error) {
      console.log('Error creating issue', error);
      throw new Error('Error creating issue');
    } finally {
      await closeDB();
    }
  };

  const addItemsToIssue = async (items: ItemIssue[]): Promise<void> => {
    try {
      await openDB();
      await createIssueItems(db, items);
    } catch (error) {
      console.log('Error adding items to issue', error);
      throw new Error('Error adding items to issue');
    } finally {
      await closeDB();
    }
  };

  const findIssuesByStoreroom = async ({
    miLoc,
    storeroomNumber,
    issueId,
  }: FindIssuesByStoreroomProps): Promise<Issue[]> => {
    try {
      const vars = [miLoc, storeroomNumber];
      if (issueId) {
        vars.push(issueId);
      }
      await openDB();
      return (
        await db.query(
          `SELECT issue.*, (
            SELECT COUNT(*)
            FROM item_issue
            WHERE item_issue.issueId = issue.id
          ) as pendingItems
          FROM  issue
          WHERE issue.miLocation = ?
          AND issue.storeroomNumber = ?
          AND issue.syncTimestamp ISNULL
          ${issueId ? `AND issue.id = ?` : ''}`,
          vars
        )
      ).values as Issue[];
    } catch (error) {
      console.log('Error loading issue', error);
      throw new Error('Error loading issue');
    } finally {
      await closeDB();
    }
  };

  const findItemsFromIssue = async (
    issueId: string
  ): Promise<ItemIssueDTO[]> => {
    try {
      const vars = [issueId];
      await openDB();
      return (
        await db.query(
          `SELECT itemspou.*, item_issue.*
          FROM item_issue
          INNER JOIN issue ON issue.id = item_issue.issueId
          INNER JOIN itemspou ON itemspou.combinedId = item_issue.itemId
          WHERE item_issue.issueId = ?`,
          vars
        )
      ).values as ItemIssueDTO[];
    } catch (error) {
      console.log('Error loading items from issue', error);
      throw new Error('Error loading items from issue');
    } finally {
      await closeDB();
    }
  };

  const findItemsFromItemId = async (
    ids: string[]
  ): Promise<ItemIssueDTO[]> => {
    const response: ItemIssueDTO[] = [];
    try {
      if (size(ids) === 0) {
        return [];
      }
      await openDB();
      // TODO create util function for batch wrapper
      const batchSize = 500;
      const batches: string[][] = [];
      for (let i = 0; i < size(ids); i += batchSize) {
        batches.push(slice(ids, i, i + batchSize));
      }
      await reduce(
        batches,
        async (prev, batch) => {
          await prev;
          response.push(
            ...((
              await db.query(
                `SELECT *
              FROM itemspou
              WHERE ${map(batch, () => `combinedId = ?\n`).join('OR\n')}`,
                batch
              )
            ).values as ItemIssueDTO[])
          );
        },
        Promise.resolve()
      );
      return response;
    } catch (error) {
      console.log('Error loading items from issue', error);
      throw new Error('Error loading items from issue');
    } finally {
      await closeDB();
    }
  };

  const updateIssueType = async (
    issueId: string,
    issueType: string
  ): Promise<void> => {
    try {
      await openDB();
      await db.query(
        `UPDATE issue
        SET recordType = ?
        WHERE id = ?`,
        [issueType, issueId]
      );
    } catch (error) {
      console.log('Error updating issue type', error);
      throw new Error('Error updating issue type');
    } finally {
      await closeDB();
    }
  };

  const updateIssueChargeBack = async (
    issueId: string,
    chargeBackValues: Dictionary<string>
  ): Promise<void> => {
    try {
      await openDB();
      await db.query(
        `UPDATE issue
        SET
        ${map(Object.keys(chargeBackValues), (key) => `${key} = ?`).join(',\n')}
        WHERE id = ?`,
        [
          ...map(
            Object.keys(chargeBackValues),
            (key) => chargeBackValues[key] || ''
          ),
          issueId,
        ]
      );
    } catch (error) {
      console.log('Error updating issue charge back', error);
      throw new Error('Error updating issue charge back');
    } finally {
      await closeDB();
    }
  };

  const updateIssueQuantity = async (
    itemId: number,
    quantity: number
  ): Promise<void> => {
    try {
      await openDB();
      await db.query(
        `UPDATE item_issue
        SET issueQuantity = ?
        WHERE id = ?`,
        [quantity, itemId]
      );
    } catch (error) {
      console.log('Error updating item issue quantity', error);
      throw new Error('Error updating item issue quantity');
    } finally {
      await closeDB();
    }
  };

  const closeIssue = async (issueId: number): Promise<void> => {
    try {
      await openDB();
      await db.query(
        `DELETE FROM item_issue
        WHERE issueId = ?`,
        [issueId]
      );
      await db.query(
        `DELETE FROM issue
        WHERE id = ?`,
        [issueId]
      );
    } catch (error) {
      console.log('Error closing issue', error);
      throw new Error('Error closing issue');
    } finally {
      await closeDB();
    }
  };

  const removeIssue = async (issueId: number): Promise<void> => {
    try {
      await openDB();
      await db.query(
        `DELETE FROM item_issue
        WHERE issueId = ?`,
        [issueId]
      );
      await db.query(
        `DELETE FROM issue
        WHERE id = ?`,
        [issueId]
      );
    } catch (error) {
      console.log('Error removing issue', error);
      throw new Error('Error removing issue');
    } finally {
      await closeDB();
    }
  };

  return {
    createIssue,
    addItemsToIssue,
    findIssuesByStoreroom,
    findItemsFromIssue,
    findItemsFromItemId,
    updateIssueType,
    updateIssueChargeBack,
    updateIssueQuantity,
    closeIssue,
    removeIssue,
  };
};

export default useIssueDB;
