import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { get, size } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonPage, IonContent, IonItem, IonRow, IonGrid } from '@ionic/react';
import {
  currencyURL,
  homeURL,
  manageFavoritesURL,
  notificationSettingsURL,
  settingsURL,
} from 'navigation';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import { useVault } from 'providers/VaultProvider';
import useGetUserConfig from 'api/user/useGetUserConfig';
import useChangeAppMode from 'hooks/useChangeAppMode';
import useFeatureFlags, { FeatureFlagType } from 'hooks/useFeatureFlags';
import type { RootState } from 'store/reducers';
import { findIcon } from 'utils/icons';
import { concatRoutes } from 'utils/navigations';
import vaultChangerSettings from 'utils/vaultChangerSettings';
import FlagCA from 'assets/flag_ca.svg';
import FlagMX from 'assets/flag_mx.svg';
import FlagUS from 'assets/flag_us.svg';
import ActionRow from 'components/ActionRow/ActionRow';
import Avatar from 'components/Avatar/Avatar';
import Button from 'components/Button/Button';
import Header from 'components/Header/Header';
import Loader from 'components/Loader/Loader';
import VaultChangerSettingsModal from 'components/Modals/VaultChangerSettingsModal/VaultChangerSettingsModal';
import Text from 'components/Text/Text';
import classes from './Settings.module.scss';

const Settings = (): JSX.Element => {
  const { userInfo, currencyType, currencyTypeOptions } = useSelector(
    (state: RootState) => state.user
  );
  const { isOnline } = useNetworkStatus();
  const { isMiProApp } = useChangeAppMode();
  const { isLoading, data } = useGetUserConfig({
    configType: 'currency',
    enabled: isMiProApp,
  });

  const selectedCurrency = data?.currency || currencyType;

  const loggedInUsername = get(userInfo, 'cn', 'User Name');

  const [requestType, setRequestType] = useState<
    'switch-vault' | 'change-pin' | ''
  >('');

  const { t } = useTranslation();
  const changePinFeatureFlag = useFeatureFlags(FeatureFlagType.changePin);

  // DOC: trigger children change on Screen so that it can be resized
  const [, setTriggerResize] = useState(0);

  const { isBiometricEnabled, supportedBiometricTypes, getUnlockMode } =
    useVault();

  const unlockMode = getUnlockMode();
  const {
    isSwitcherEnable,
    biometricSwitcher,
    customPinSwitcher,
    lockTitle,
    switchTitle,
  } = vaultChangerSettings({
    unlockMode,
    isBiometricEnabled,
    supportedBiometricTypes,
    t,
  });

  const switchVaultLogin = () => {
    setRequestType('switch-vault');
  };

  const changePinAction = () => {
    setRequestType('change-pin');
  };

  let flagImg = FlagUS;
  if (selectedCurrency === 'MXN') {
    flagImg = FlagMX;
  } else if (selectedCurrency === 'CAD') {
    flagImg = FlagCA;
  }

  // TODO for the issue with modal not opening again on settings --> change pin, switch vault options (it's hard to replicate)

  return (
    <IonPage className={classes.settings} data-testid="settings">
      <Header title={t('common:settings')} withBackButton testid="settings" />
      <IonContent className={classes.content}>
        <IonItem className={classes.ionPageItem}>
          <IonGrid className={classes.ionGrid}>
            <IonRow
              className={classNames(
                'ion-align-items-center',
                classes.sectionRow
              )}
            >
              <FontAwesomeIcon
                className={classes.profileIcon}
                icon={findIcon('user-alt')}
              />
              <Text
                className={classes.settingsTitles}
                variant="title-action-card"
                text={t('settings:profileSettings')}
                testid="profile-settings"
              />
            </IonRow>
            <ActionRow
              className={classNames(classes.primary, classes.lastItemRow)}
              text={loggedInUsername}
              disabled={false}
              textVariant="mipro-body-copy"
              testid="user-name"
            >
              <Avatar className={classes.avatar} />
            </ActionRow>
          </IonGrid>
        </IonItem>
        <IonItem className={classes.sectionSeparator} />

        {isSwitcherEnable && isOnline && (
          <>
            <IonItem className={classes.ionPageItem}>
              <IonGrid className={classes.ionGrid}>
                <IonRow
                  className={classNames(
                    'ion-align-items-center',
                    classes.sectionRow
                  )}
                >
                  <FontAwesomeIcon
                    className={classes.profileIcon}
                    icon={findIcon('address-card')}
                  />
                  <Text
                    className={classes.settingsTitles}
                    variant="title-action-card"
                    text={t('settings:accountSettings')}
                    testid="account-settings"
                  />
                </IonRow>

                <ActionRow
                  className={classNames(classes.primary, {
                    [classes.lastItemRow]: customPinSwitcher,
                  })}
                  textVariant="mipro-body-copy"
                  text={switchTitle}
                  onClick={switchVaultLogin}
                  testid="vault-switcher"
                />

                {!customPinSwitcher && changePinFeatureFlag && (
                  <ActionRow
                    className={classNames(classes.primary, classes.lastItemRow)}
                    text={t('settings:changePin')}
                    textVariant="mipro-body-copy"
                    onClick={changePinAction}
                    testid="change-pin"
                  />
                )}
              </IonGrid>
            </IonItem>
            <IonItem className={classes.sectionSeparator} />
          </>
        )}
        {isMiProApp && (
          <>
            <IonItem className={classes.ionPageItem}>
              <IonGrid className={classes.ionGrid}>
                <IonRow
                  className={classNames(
                    'ion-align-items-center',
                    classes.sectionRow
                  )}
                >
                  <FontAwesomeIcon
                    className={classes.profileIcon}
                    icon={findIcon('gear')}
                  />
                  <Text
                    className={classes.settingsTitles}
                    variant="title-action-card"
                    text={t('settings:appSettings')}
                    testid="app-settings"
                  />
                </IonRow>
                <ActionRow
                  className={classes.primary}
                  text={t('settings:notificationPreferences')}
                  textVariant="mipro-body-copy"
                  href={concatRoutes(
                    homeURL(),
                    settingsURL(),
                    notificationSettingsURL()
                  )}
                  testid="notification-action"
                />
                <ActionRow
                  className={classes.primary}
                  text={t('settings:editFavorites')}
                  textVariant="mipro-body-copy"
                  href={concatRoutes(
                    homeURL(),
                    settingsURL(),
                    manageFavoritesURL()
                  )}
                  testid="edit-favorites"
                />
                <ActionRow
                  className={classNames(classes.primary, classes.lastItemRow)}
                  text={
                    size(currencyTypeOptions) > 1
                      ? t('settings:selectCurrency')
                      : t('settings:currency')
                  }
                  textVariant="mipro-body-copy"
                  href={
                    size(currencyTypeOptions) > 1
                      ? concatRoutes(homeURL(), settingsURL(), currencyURL())
                      : undefined
                  }
                  // withArrow={size(currencyTypeOptions) > 1}
                  // disabled={size(currencyTypeOptions) < 2}
                  testid="select-currency"
                >
                  {!isLoading ? (
                    <>
                      <img
                        className={classes.flag}
                        slot="end"
                        src={flagImg}
                        alt="Flag"
                      />

                      <Button
                        slot="end"
                        variant="clear"
                        testid="flag-name"
                        className={classNames(classes.currencyBtn, {
                          [classes.singleCurrency]:
                            !currencyTypeOptions ||
                            currencyTypeOptions?.length <= 1,
                        })}
                        text={`${selectedCurrency || 'USD'}$`}
                      />
                    </>
                  ) : null}
                </ActionRow>
              </IonGrid>
            </IonItem>
            <IonItem className={classes.sectionSeparator} />
          </>
        )}

        <Loader type="fullscreen" isOpen={isLoading && isMiProApp} />
      </IonContent>
      <VaultChangerSettingsModal
        biometricSwitcher={biometricSwitcher}
        lockTitle={lockTitle}
        customPinSwitcher={customPinSwitcher}
        requestType={requestType}
        setRequestType={setRequestType}
        triggerResize={setTriggerResize}
      />
    </IonPage>
  );
};

export default Settings;
