import type { AxiosError } from 'axios';
import { isNil, toNumber } from 'lodash';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import useGetCustomer from 'api/customer/useGetCustomer';
import { findCustomerContactsQueryKey } from 'api/customerContacts/useFindCustomerContacts';
import {
  doPromiseAPI,
  getPlaceholderData,
  useKeyUserId,
  useMiLocOrTeamId,
} from 'api/helpers';
import useGetSupplier from 'api/supplier/useGetSupplier';
import { findSupplierContactsQueryKey } from 'api/supplierContacts/useFindSupplierContacts';
import type { Customer } from 'models/Customer';
import type {
  QueryFlags,
  SearchCustomerContactItem,
  SearchItem,
  SearchItemType,
  SearchSupplierContactItem,
} from 'models/Search';
import type { Supplier } from 'models/Supplier';

export const getContactQueryKey = 'contact';

interface UseFindContactProps {
  query?: string;
  searchType: SearchItemType;
  miLoc: string;
  id: string;
  seqNo: string;
}

interface UseFindContactResponse {
  data?: SearchItem;
  searchTypeInfo?: Customer | Supplier | undefined;
}

const useGetContact = ({
  searchType,
  miLoc,
  id,
  seqNo,
}: UseFindContactProps): QueryFlags & UseFindContactResponse => {
  const { axios } = useAxios();
  const { contactAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { createQueryKey } = useKeyUserId();
  const { createParams } = useMiLocOrTeamId({
    miLoc,
    sendTeamId: false,
  });
  const params = { ...createParams() };
  const { miLoc: itemMiLoc } = params;

  const { data: customerData } = useGetCustomer({
    searchType,
    miLoc: itemMiLoc,
    id,
  });
  const { data: supplierData } = useGetSupplier({ searchType, id });

  const doGetContact = () => {
    return doPromiseAPI<SearchItem>(async (cancelToken) => {
      const { data } = await axios.get<SearchItem>(
        contactAPI(searchType, itemMiLoc, id, seqNo),
        { cancelToken }
      );
      return data;
    });
  };

  const {
    data: contactData,
    error,
    status,
    isFetching,
    refetch,
  } = useQuery<SearchItem, AxiosError>(
    createQueryKey(getContactQueryKey, {
      searchType,
      miLoc: itemMiLoc,
      id,
      sequenceNo: toNumber(seqNo),
    }),
    doGetContact,
    {
      placeholderData: () => {
        // TODO: fix these query selectors
        const contactPlaceholder = getPlaceholderData<SearchItem>({
          queryClient,
          queryKey:
            searchType === 'customer'
              ? findCustomerContactsQueryKey
              : findSupplierContactsQueryKey,
          objectKey: 'items',
          findPredicate: {
            miLoc: itemMiLoc,
            sequenceNo: toNumber(seqNo),
            ...(searchType === 'customer'
              ? { customerNo: id }
              : { supplierLocationNo: id }),
          },
        });
        if (contactPlaceholder) {
          return { ...contactPlaceholder, name: contactPlaceholder.text };
        }
        return undefined;
      },
    }
  );

  const {
    name = '',
    customerNo,
    type,
    ...custRest
  } = (contactData as SearchCustomerContactItem) || {};
  const { supplierLocationNo, ...suppRest } =
    (contactData as SearchSupplierContactItem) || {};
  const data =
    searchType === 'customer'
      ? {
          ...custRest,
          text: name,
          customerNo: id,
          type: searchType,
          miLoc: itemMiLoc,
          customerType: type,
          customerPick12: customerData?.customerPick12,
        }
      : {
          ...suppRest,
          text: name,
          suppplierLocationNo: id,
          type: searchType,
          miLoc: itemMiLoc,
          supplierType: type,
        };

  return {
    data,
    searchTypeInfo: searchType === 'customer' ? customerData : supplierData,
    error,
    isLoading: status === 'loading' || (isFetching && isNil(contactData)),
    isFetching,
    refetch: async () => {
      await refetch();
    },
  };
};

export default useGetContact;
