import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { filter, isEmpty, map, size, toString } from 'lodash';
import useFindOcnLineStatus from 'ProductSearchApp/api/orders/useFindOcnLineStatus';
import type { Order } from 'ProductSearchApp/models/Order';
import type { Customer } from 'models/Customer';
import Loader from 'components/Loader/Loader';
import WarningMessage, {
  WarningMessageVarianEnum,
} from 'components/WarningMessage/WarningMessage';
import OcnLineShippingDetails from './OcnLineShippingDetails';
import classes from './OcnLineShippingSections.module.scss';

interface OcnLineShippingSectionsProp {
  miLoc: string;
  order?: Order;
  orderLineNo: string;
  customer?: Customer;
}

const OcnLineShippingSections = ({
  miLoc,
  order,
  orderLineNo,
  customer,
}: OcnLineShippingSectionsProp): JSX.Element => {
  const { t } = useTranslation();

  const { data, isLoading } = useFindOcnLineStatus({
    miLoc,
    orderCtlNo: toString(order?.orderCtlNo),
    orderLineNo,
  });

  const orderStatuses = useMemo(() => {
    let customerCounter = 0;
    let branchCounter = 0;
    const filtered = filter(data?.statuses, ['showResults', true]).map(
      (status) => {
        const isDirectShip = !!status.directShip;
        const type = isDirectShip ? 'customer' : 'branch';
        if (isDirectShip) {
          customerCounter += 1;
        } else {
          branchCounter += 1;
        }
        return {
          ...status,
          type,
          counter: isDirectShip ? customerCounter : branchCounter,
        };
      }
    );
    return {
      data: filtered,
      branchShipmentTotalCount: size(filter(filtered, ['directShip', false])),
      customerShipmentTotalCount: size(filter(filtered, ['directShip', true])),
    };
  }, [data?.statuses]);

  const showWarningMessage = !isEmpty(data?.warningCode) && !data?.showResults;

  return (
    <div>
      <Loader
        testid="loader"
        text={t('common:loading')}
        isOpen={isLoading && !data}
        className={classes.loader}
      />
      {!isLoading && (
        <>
          {isEmpty(orderStatuses.data) && !showWarningMessage && (
            <WarningMessage
              title={t('productSearch:shippingDetails:noStatusInfoTitle')}
              body={t('productSearch:shippingDetails:noStatusInfoMessage')}
              icon={['fas', 'info-circle']}
              className={classes.infoBar}
              testid="empty-shipping-message"
            />
          )}
          {showWarningMessage && (
            <WarningMessage
              title={t(
                'productSearch:shippingDetails:undeterminedShippingStatusTitle'
              )}
              variant={WarningMessageVarianEnum.warning}
              className={classes.infoBar}
            >
              <span className={classes.message}>
                <Trans
                  i18nKey={t(
                    'productSearch:shippingDetails:undeterminedShippingStatusMessage',
                    { warningText: data?.warningDescription }
                  )}
                />
              </span>
            </WarningMessage>
          )}
        </>
      )}
      {!showWarningMessage &&
        map(orderStatuses.data, (status, idx) => (
          <OcnLineShippingDetails
            key={`ocn-line-shipment-${idx}`}
            testid={`ocn-line-shipment-${idx}`}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...status}
            type={status.type}
            counter={status.counter}
            totalCount={
              status.type === 'branch'
                ? orderStatuses.branchShipmentTotalCount
                : orderStatuses.customerShipmentTotalCount
            }
            totalQtd={data?.lineStatus.tranQuantity}
            miLoc={toString(data?.miLoc)}
            order={order}
            customer={customer}
          />
        ))}
    </div>
  );
};

export default OcnLineShippingSections;
