import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { IonContent, IonPage } from '@ionic/react';
import { namespaces } from 'i18n/i18n.constants';
import useGetCustomer from 'api/customer/useGetCustomer';
import useFindSalesPlays from 'api/salesPlays/useFindSalesPlays';
import type { SnapshotsURLParams } from 'models/Search';
import { getErrorMessage } from 'utils/helpers';
import { getSnapshotEyebrow } from 'utils/search';
import Header from 'components/Header/Header';
import Refresher from 'components/Refresher/Refresher';
import SalesPlaysCard from 'components/SalesPlaysCard/SalesPlaysCard';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './SalesPlays.module.scss';

const CustomerSalesPlays = (): JSX.Element => {
  const { miLoc, id } = useParams<SnapshotsURLParams>();
  const { t } = useTranslation(namespaces.salesPlays);
  const [atTop, setAtTop] = useState<boolean>(true);

  const { data: customerData } = useGetCustomer({
    searchType: 'customer',
    miLoc,
    id,
  });

  const {
    salesPlays,
    hasError,
    error,
    fetchNextPage,
    showLoader,
    isEmptyResponse,
    enableInfiniteScroll,
    refetch: findSalesPlaysRefetch,
  } = useFindSalesPlays({
    filterStatus: 'NEW',
    filterType: '',
    custNo: customerData?.customerNo,
    miLoc: customerData?.miLoc,
    limit: 25,
  });
  const history = useHistory();
  const { url } = useRouteMatch();

  return (
    <IonPage data-testid="sales-plays-customer-page">
      <Header
        withBackButton
        eyebrow={getSnapshotEyebrow({ name: customerData?.name, miLoc, id })}
        title={t('common:salesPlays')}
        testid="sales-plays"
      />
      <IonContent>
        <Refresher
          slot="fixed"
          hidden
          onRefresh={findSalesPlaysRefetch}
          testid="sales-plays-refresher"
          disabled={showLoader || !atTop}
        />
        {!hasError && !isEmpty(salesPlays) && (
          <Virtuoso
            atTopStateChange={(atp) => setAtTop(atp)}
            className="ion-content-scroll-host"
            data={salesPlays}
            increaseViewportBy={{
              top: 500,
              bottom: 500,
            }}
            endReached={enableInfiniteScroll ? fetchNextPage : undefined}
            itemContent={(index, salesPlay) => (
              <SalesPlaysCard
                className={classNames({
                  [classes.updatingCard]: salesPlay.isOptimisticallyUpdating,
                })}
                key={`${salesPlay.externalId}-${index}`}
                salesPlay={salesPlay}
                testid="sales-plays-card"
                url={url}
                history={history}
              />
            )}
          />
        )}
        {isEmptyResponse && (
          <WarningMessage
            className={classes.warningMessage}
            icon={['far', 'info-circle']}
            title={t('noSalesPlays')}
          />
        )}
        {hasError && (
          <WarningMessage
            className={classes.warningMessage}
            title={t('errorLoadingSalesPlays')}
            body={getErrorMessage(error)}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default CustomerSalesPlays;
