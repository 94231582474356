import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePrevious } from 'react-use';
import classNames from 'classnames';
import { findIndex, isNil, size, toInteger } from 'lodash';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { namespaces } from 'i18n/i18n.constants';
import useFindFavoriteCustomers from 'api/customer/useFindFavoriteCustomers';
import useFindFavoriteSuppliers from 'api/supplier/useFindFavoriteSuppliers';
import type { ActionOperationStatus } from 'models/ActivityModels';
import type { FavoriteOperation } from 'models/Favorites';
import { SearchResultTabTypeEnum } from 'models/Search';
import type { SearchItem, FavoriteItemType } from 'models/Search';
import { getErrorMessage } from 'utils/helpers';
import FavoritesSection from 'pages/Favorites/FavoritesSection/FavoritesSection';
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll';
import Loader from 'components/Loader/Loader';
import Refresher from 'components/Refresher/Refresher';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './FavoritesList.module.scss';

enum FavoritesListTypeEnum {
  fullscreen,
  inline,
}

type FavoritesListType = keyof typeof FavoritesListTypeEnum;

type SetFavoritesMapType = Record<
  FavoriteItemType,
  React.Dispatch<React.SetStateAction<SearchItem[]>>
>;

interface FavoritesListProps {
  type?: FavoritesListType;
  setHasFavorites?: (b: boolean) => void;
  searchType?: SearchResultTabTypeEnum;
  isEditable?: boolean;
  operationStatus?: ActionOperationStatus;
  searchQuery?: string;
  addOperation?: (o: FavoriteOperation) => void;
  testid: string;
  favMiLoc: string;
}

const FavoritesList = ({
  className,
  type = 'inline',
  searchType = SearchResultTabTypeEnum.all,
  setHasFavorites,
  isEditable,
  operationStatus,
  searchQuery,
  addOperation,
  testid,
  favMiLoc,
}: FavoritesListProps & IonicReactProps): JSX.Element => {
  const favoriteCustomersResponse = useFindFavoriteCustomers({
    enabled: searchType !== 'suppliers',
  });
  const customersLoaded =
    searchType === 'suppliers' ||
    favoriteCustomersResponse.isEmptyResponse ||
    favoriteCustomersResponse.noMoreData;
  const favoriteSuppliersResponse = useFindFavoriteSuppliers({
    enabled: searchType !== 'customers' ? customersLoaded : false,
  });

  const { items: favoriteCustomers, refetch: favCustRefetch } =
    favoriteCustomersResponse;
  const { items: favoriteSuppliers, refetch: favSuppRefetch } =
    favoriteSuppliersResponse;

  const [cloneFavoriteCustomers, setCloneFavoriteCustomers] = useState<
    SearchItem[]
  >([]);
  const [cloneFavoriteSuppliers, setCloneFavoriteSuppliers] = useState<
    SearchItem[]
  >([]);
  const prevOperationStatus = usePrevious(operationStatus);

  // #region display flags
  const withFavoriteCustomers =
    searchType !== 'suppliers' && size(favoriteCustomers) > 0;
  const withFavoriteSuppliers =
    searchType !== 'customers' &&
    (!customersLoaded || (customersLoaded && size(favoriteSuppliers) > 0));
  const withFavorites = withFavoriteCustomers || withFavoriteSuppliers;

  const showClones =
    isEditable || (!isEditable && operationStatus === 'pending');
  const withCloneFavoriteCustomers = size(cloneFavoriteCustomers) > 0;
  const withCloneFavoriteSuppliers =
    !customersLoaded || (customersLoaded && size(cloneFavoriteSuppliers) > 0);
  const withCloneFavorites =
    withCloneFavoriteCustomers || withCloneFavoriteSuppliers;

  const hasFavorites =
    (!showClones && withFavorites) || (showClones && withCloneFavorites);
  // #endregion

  // #region favorite operations
  const setFavoritesMap: SetFavoritesMapType = {
    customer: setCloneFavoriteCustomers,
    supplier: setCloneFavoriteSuppliers,
  };

  const onRemove =
    (key: FavoriteItemType) =>
    ({ miLoc, id }: FavoriteOperation) => {
      setFavoritesMap[key]((prev) => {
        let index;
        if (key === 'customer') {
          index = findIndex(prev, { miLoc, id });
        } else {
          index = findIndex(prev, { id });
        }
        if (index !== -1) {
          return [...prev.slice(0, index), ...prev.slice(index + 1)];
        }
        return prev;
      });
      addOperation?.call(null, { type: key, miLoc, id });
    };

  const onReorder =
    (key: FavoriteItemType) =>
    ({ miLoc, id, from, to }: FavoriteOperation) => {
      setFavoritesMap[key]((prev) => {
        const iFrom = toInteger(from);
        const iTo = toInteger(to);
        const item = prev[iFrom];
        if (!isNil(item)) {
          const next = [...prev.slice(0, iFrom), ...prev.slice(iFrom + 1)];
          return [...next.slice(0, iTo), item, ...next.slice(iTo)];
        }
        return prev;
      });
      addOperation?.call(null, { type: key, miLoc, id, from, to });
    };

  const setClones = useCallback(() => {
    setCloneFavoriteCustomers(favoriteCustomers);
    setCloneFavoriteSuppliers(favoriteSuppliers);
  }, [favoriteCustomers, favoriteSuppliers]);

  useEffect(() => {
    setClones();
  }, [setClones]);

  useEffect(() => {
    if (
      !isNil(prevOperationStatus) &&
      prevOperationStatus !== operationStatus
    ) {
      if (operationStatus === 'none') {
        setClones();
      }
    }
  }, [setClones, operationStatus, prevOperationStatus]);
  // #endregion

  const error =
    favoriteCustomersResponse.error || favoriteSuppliersResponse.error;
  const hasError =
    favoriteCustomersResponse.hasError || favoriteSuppliersResponse.hasError;
  const isLoading =
    favoriteCustomersResponse.showLoader ||
    favoriteSuppliersResponse.showLoader;
  const isEmptyResponse =
    (favoriteCustomersResponse.isEmptyResponse &&
      favoriteSuppliersResponse.isEmptyResponse) ||
    !hasFavorites;

  useEffect(() => {
    setHasFavorites?.call(null, !isEmptyResponse);
  }, [setHasFavorites, isEmptyResponse]);

  const { t } = useTranslation(namespaces.search);
  return (
    <div
      className={classNames(className, classes.content)}
      data-testid={testid}
    >
      <Refresher
        slot="fixed"
        hidden
        onRefresh={async () => {
          await Promise.all([
            favCustRefetch?.call(null),
            favSuppRefetch?.call(null),
          ]);
        }}
        testid="favourites-list-refresher"
        disabled={isLoading || isEditable}
      />

      {hasFavorites && (
        <>
          {searchType !== 'suppliers' && (
            <FavoritesSection
              className={classes.section}
              isEditable={isEditable}
              title={
                (!showClones && withFavoriteCustomers) ||
                (showClones && withCloneFavoriteCustomers) ||
                type === 'inline'
                  ? t('snapshot:customers')
                  : t('favorites:noCustomerFav')
              }
              searchQuery={searchQuery}
              items={showClones ? cloneFavoriteCustomers : favoriteCustomers}
              emptyMessage={
                !favoriteCustomersResponse.showLoader && type === 'fullscreen'
                  ? t('favorites:starMessage', { type: t('common:customer') })
                  : undefined
              }
              onRemove={onRemove('customer')}
              onReorder={onReorder('customer')}
              testid="favorite-customers"
            />
          )}
          {searchType !== 'customers' && (
            <FavoritesSection
              className={classes.section}
              isEditable={isEditable}
              title={
                (!showClones && withFavoriteSuppliers) ||
                (showClones && withCloneFavoriteSuppliers) ||
                type === 'inline'
                  ? t('snapshot:suppliers')
                  : t('favorites:noSupplierFav')
              }
              searchQuery={searchQuery}
              items={showClones ? cloneFavoriteSuppliers : favoriteSuppliers}
              emptyMessage={
                customersLoaded &&
                !favoriteSuppliersResponse.showLoader &&
                type === 'fullscreen'
                  ? t('favorites:starMessage', { type: t('common:supplier') })
                  : undefined
              }
              onRemove={onRemove('supplier')}
              onReorder={onReorder('supplier')}
              testid="favorite-suppliers"
              favMiLoc={favMiLoc}
            />
          )}
        </>
      )}
      {isEmptyResponse && !isLoading && !hasError && (
        <WarningMessage
          className={classNames(classes.warningMessage, {
            [classes.fullscreenMessage]: type === 'fullscreen',
          })}
          icon={['far', 'star']}
          title={t('favorites:noFavoriteTitle')}
          body={t('favorites:starMessage', { type: t('favorites:custOrSupp') })}
        />
      )}
      {hasError && (
        <WarningMessage
          className={classes.warningMessage}
          title={t('favorites:errorFavorite')}
          body={getErrorMessage(error)}
        />
      )}
      {searchType !== 'suppliers' && (
        <Loader
          className={classes.loader}
          text={t('favorites:favoriteLoadMessage', { type: t('customers') })}
          isOpen={favoriteCustomersResponse.showLoader}
          testid="loading-favorite-customers"
        />
      )}
      {searchType !== 'customers' && (
        <Loader
          className={classes.loader}
          text={t('favorites:favoriteLoadMessage', {
            type: t('snapshot:suppliers'),
          })}
          isOpen={favoriteSuppliersResponse.showLoader}
          testid="loading-favorite-suppliers"
        />
      )}
      <InfiniteScroll
        disabled={
          searchType === 'suppliers' ||
          !favoriteCustomersResponse.enableInfiniteScroll
        }
        onLoadMore={favoriteCustomersResponse.fetchNextPage}
        testid="infinite-scroll-customers"
      />
      <InfiniteScroll
        disabled={
          searchType === 'customers' ||
          !customersLoaded ||
          !favoriteSuppliersResponse.enableInfiniteScroll
        }
        onLoadMore={favoriteSuppliersResponse.fetchNextPage}
        testid="infinite-scroll-suppliers"
      />
    </div>
  );
};

export default FavoritesList;
