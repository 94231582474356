export enum CustomerAccountTypeEnum {
  reengagedCustomerAccount = 'ReengagedCustomerAccount',
  newCustomerContact = 'NewCustomerContact',
  atRiskCustomerAccount = 'AtRiskCustomerAccount',
}

export enum CustomerAccountIconEnum {
  reengagedCustomerAccount = 'person-carry',
  newCustomerContact = 'user-plus',
  atRiskCustomerAccount = 'traffic-cone',
}

export enum CustomerAccountColorEnum {
  reengagedCustomerAccount = 'color-reengagedCustomerAccount',
  newCustomerContact = 'color-newCustomerContact',
  atRiskCustomerAccount = 'color-atRiskCustomerAccount',
}

export enum CustomerAccountFilterEnum {
  reengagedCustomerAccount = 'ReengagedCustomerAccount',
  newCustomerContact = 'NewCustomerContact',
  atRiskCustomerAccount = 'AtRiskCustomerAccount',
}
