import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty, size } from 'lodash';
import { IonContent, IonPage } from '@ionic/react';
import Header from 'common/components/Header/Header';
import List from 'common/components/List/List';
import { useIonContentRef } from 'common/components/utils/renderHelpers';
import useFindGlobalOrders from 'ProductSearchApp/api/orders/useFindGlobalOrders';
import { useDebounce } from 'use-debounce';
import useGetCustomer from 'api/customer/useGetCustomer';
import { concatSearchResults } from 'api/search/utils';
import { SearchResultTabTypeEnum } from 'models/Search';
import type { SearchGlobalOrder, SnapshotsURLParams } from 'models/Search';
import type { RootState } from 'store/reducers';
import { setCustomerOcnSearch } from 'store/user';
import Refresher from 'components/Refresher/Refresher';
import SearchSuggestionOcnCard from 'components/Search/SearchSuggestionCard/SearchSuggestionOcnCard';
import Searchbar from 'components/Searchbar/Searchbar';
import Text from 'components/Text/Text';
import classes from './OcnSearch.module.scss';

const OcnSearch = (): JSX.Element => {
  const { customerOcnSearch } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();

  const { miLoc, id } = useParams<SnapshotsURLParams>();
  const { t } = useTranslation();

  const [triggerAutoFocus, setTriggerAutoFocus] = useState(0);
  const [resetForm, setResetForm] = useState<null | boolean>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryValue] = useDebounce(searchQuery, 300);

  const { node, nodeRef } = useIonContentRef();

  const { data: customerData } = useGetCustomer({
    searchType: 'customer',
    miLoc,
    id,
  });

  const {
    data,
    total,
    showLoader,
    isEmptyResponse,
    noMoreData,
    hasError,
    enableInfiniteScroll,
    refetch,
    fetchNextPage,
  } = useFindGlobalOrders({
    query: searchQueryValue,
    enabled: !isEmpty(searchQueryValue),
    customerNo: customerData?.customerNo,
    miLoc,
  });

  const results = useMemo(
    () =>
      concatSearchResults({
        OCNsResponse: data,
        searchType: SearchResultTabTypeEnum.ocns,
      }),
    [data]
  );

  const isCustomerCached =
    customerOcnSearch?.customerNo === customerData?.customerNo &&
    customerOcnSearch?.miLoc === miLoc;
  const namespace = 'productSearch:ocn';

  useEffect(() => {
    if (searchQueryValue.length === 0 && results.length === 0) {
      setTimeout(() => {
        setTriggerAutoFocus(Date.now());
      }, 150);
    }
  }, [results.length, searchQueryValue.length]);

  useEffect(() => {
    if (
      customerOcnSearch?.searchQuery &&
      isCustomerCached &&
      resetForm === null
    ) {
      setSearchQuery(customerOcnSearch.searchQuery);
    } else {
      dispatch(
        setCustomerOcnSearch({
          customerOcnSearch: {
            searchQuery: searchQueryValue,
            customerNo: customerData?.customerNo,
            miLoc,
          },
        })
      );
    }
    setResetForm(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQueryValue, customerData?.customerNo, resetForm]);

  return (
    <IonPage data-testid="ocn-search-page">
      <Header
        title={t(`${namespace}:ocnSearchTitle`)}
        subTitle={customerData?.name}
        testid="ocn-search-header"
        className={classes.header}
        hideMenuButton={false}
        hideLocationSelector={false}
      >
        <Searchbar
          className={classes.input}
          value={searchQuery}
          placeholder={t(`${namespace}:ocnSearchPlaceholder`)}
          setValue={setSearchQuery}
          testid="search-input"
          onClear={() => {
            setResetForm(true);
          }}
          triggerInputSelect={triggerAutoFocus}
        />
      </Header>
      <IonContent className={classes.content} ref={nodeRef}>
        <Refresher
          hidden
          slot="fixed"
          onRefresh={refetch}
          testid="ocn-search-refresher"
          disabled={showLoader || !searchQueryValue}
        />
        <List
          title={total ? t('inventory:searchResult', { count: total }) : ''}
          testid="ocn-search-results"
          data={results}
          itemContent={(idx, item) => (
            <SearchSuggestionOcnCard
              hideCustomerName
              testid={`ocn-search-results-${idx}`}
              item={item as unknown as SearchGlobalOrder}
              textQuery=""
            />
          )}
          isLoading={!isEmpty(searchQueryValue) && showLoader}
          isEmptyList={{
            isEmptyList: isEmptyResponse || isEmpty(searchQueryValue),
            title: isEmpty(searchQueryValue)
              ? t('common:startSearch')
              : t(`${namespace}:ocnSearchEmptyStateTitle`, {
                  name: customerData?.name,
                  search: searchQueryValue,
                }),
            children: isEmptyResponse && !isEmpty(searchQueryValue) && (
              <div className={classes.emptyListWrapper}>
                <Text
                  className={classes.emptyListText}
                  text={t(`${namespace}:ocnSearchEmptyStateLine1`)}
                />
                <Text
                  className={classes.emptyListText}
                  text={t(`${namespace}:ocnSearchEmptyStateLine2`)}
                />
                <Text
                  className={classes.emptyListText}
                  text={t(`${namespace}:ocnSearchEmptyStateLine3`)}
                />
                <Text
                  className={classes.emptyListText}
                  text={t(`${namespace}:ocnSearchEmptyStateLine4`)}
                />
              </div>
            ),
          }}
          isError={hasError}
          isEndOfList={noMoreData && size(data?.pages) > 1}
          scrollParent={node}
          endReached={enableInfiniteScroll ? fetchNextPage : undefined}
        />
      </IonContent>
    </IonPage>
  );
};

export default OcnSearch;
