import { useMemo, useState } from 'react';
import type { AxiosError } from 'axios';
import type { Dictionary } from 'lodash';
import { map, find, toString, size } from 'lodash';
import * as Sentry from '@sentry/capacitor';
import type { QueryFunctionContext } from '@tanstack/react-query';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import { useToasts } from 'providers/ToastProvider';
import useItemPOUDB from 'StoreroomsApp/database/useItemPOUDB';
import type { ItemPOU, ItemPOUAPI } from 'StoreroomsApp/models/ItemPOU';
import {
  doConcatDataPages,
  doGetIsLoading,
  doPromiseAPI,
  onDownloadData,
  getAPIHeadersV2,
  onSuccessMutation,
  useKeyUserId,
  useMiLocOrTeamId,
  getOfflineNextPage,
} from 'api/helpers';
import type { InfiniteQueryFlags } from 'models/Search';
import { ToastType } from 'models/Toast';
import { pageSize } from 'utils/constants';

export const findItemsPOUQueryKey = 'items-pou';

interface UseFindItemsPOUProps {
  enabled?: boolean;
  replenishmentId?: number;
  issueId?: string;
  storeroom?: string;
  query?: string;
  barcode?: string;
  isPendingIssue?: boolean;
  limit?: number;
}

interface UseFindItemsPOUResponse {
  itemsPOU?: ItemPOU[];
  loadingAPI?: boolean;
  sync: () => Promise<number>;
}

const useFindItemsPOU = ({
  enabled,
  replenishmentId,
  issueId,
  storeroom = '',
  query = '',
  barcode = '',
  isPendingIssue,
  limit = pageSize(),
}: UseFindItemsPOUProps): InfiniteQueryFlags & UseFindItemsPOUResponse => {
  const { axios } = useAxios();
  const { addToast } = useToasts();
  const queryClient = useQueryClient();
  const { itemsAPISearch } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const { createParams } = useMiLocOrTeamId({ sendTeamId: false });
  const { createItemsPOU, findItemsPOUByStoreroom, removeItemsPOU } =
    useItemPOUDB();
  const [loadingAPI, setLoadingAPI] = useState(false);
  const { isOnline } = useNetworkStatus();
  const params: Dictionary<string> = {
    ...createParams(),
    isPendingIssue: toString(isPendingIssue),
    storeroom,
    query,
    barcode,
    sortCol: 'customerStockNumber',
    sortDir: 'ASC',
    limit: toString(limit),
  };
  if (!isPendingIssue && replenishmentId) {
    params.replenishmentId = toString(replenishmentId);
  }
  if (isPendingIssue && issueId) {
    params.issueId = toString(issueId);
  }
  const { miLoc } = params;

  const doFindItemsPOU = ({ pageParam = 1, signal }: QueryFunctionContext) => {
    return doPromiseAPI<ItemPOU[]>(async () => {
      let itemsPOU: ItemPOU[] = [];
      const offlineItems =
        (await findItemsPOUByStoreroom({
          miLoc,
          query,
          replenishmentId: params.replenishmentId,
          issueId: params.issueId,
          storeroomNumber: params.storeroom,
          barcode,
        })) || [];
      if (isOnline) {
        const {
          data: { rows: itemsData },
        } = await axios.post<{ rows: ItemPOUAPI[] }>(
          itemsAPISearch(
            toString(
              new URLSearchParams({
                ...params,
                storeRoomNumber: params.storeroom,
                page: toString(pageParam),
              })
            )
          ),
          {},
          { headers: { ...getAPIHeadersV2() }, signal }
        );
        itemsPOU = map(itemsData, (i) => ({
          ...i,
          combinedId: i.combinedStoreroomItemId,
        }));
        await createItemsPOU(itemsPOU);
        return map(itemsPOU, (item) => ({
          ...find(offlineItems, {
            miLocation: item.miLocation,
            storeroomNumber: item.storeroomNumber,
            itemNumber: item.itemNumber,
            barcodeValue: item.barcodeValue,
          }),
          ...item,
        }));
      }
      return offlineItems;
    });
  };

  const response = useInfiniteQuery<ItemPOU[], AxiosError>(
    createQueryKey(findItemsPOUQueryKey, { ...params, isOnline }),
    doFindItemsPOU,
    {
      enabled,
      networkMode: 'always',
      onError: () => {
        setLoadingAPI(false);
      },
      getNextPageParam: getOfflineNextPage(!!isOnline),
    }
  );

  const {
    data,
    status,
    error,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    refetch,
    fetchNextPage,
  } = response;

  const itemsPOU = useMemo(() => doConcatDataPages<ItemPOU>(data), [data]);
  const hasItems = size(itemsPOU) > 0;
  const isEmptyResponse = status === 'success' && !hasNextPage && !hasItems;
  const noMoreData = status === 'success' && !hasNextPage && hasItems;
  const showLoader = doGetIsLoading(response) || isFetchingNextPage;
  const enableInfiniteScroll = !(!hasNextPage || isFetchingNextPage);

  return {
    fetchNextPage: async () => {
      await fetchNextPage();
    },
    refetch: async () => {
      await refetch();
    },
    sync: async () => {
      let totalRows = 0;
      try {
        setLoadingAPI(true);
        const { syncData, totalRows: totalItems } =
          await onDownloadData<ItemPOUAPI>({
            customAxios: axios,
            getAPIUrl: itemsAPISearch,
            params: { miLoc },
            headers: { ...getAPIHeadersV2() },
          });
        await removeItemsPOU(miLoc);
        await createItemsPOU(
          map(syncData, (i) => ({
            ...i,
            combinedId: i.combinedStoreroomItemId,
          }))
        );
        void onSuccessMutation(queryClient, findItemsPOUQueryKey);
        totalRows = totalItems;
      } catch (e) {
        addToast({
          type: ToastType.error,
          text: 'There was an error while downloading items',
          testid: 'sync-error-toast',
          duration: 0,
        });
        Sentry.captureException(e);
        throw e;
      } finally {
        setLoadingAPI(false);
      }
      return totalRows;
    },
    itemsPOU,
    error,
    loadingAPI,
    showLoader,
    isFetching,
    isEmptyResponse,
    noMoreData,
    enableInfiniteScroll,
  };
};

export default useFindItemsPOU;
