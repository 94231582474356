import React, { useState } from 'react';
import type { TFunction } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { isEmpty, isNil, kebabCase, orderBy } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IonAccordionGroup,
  IonAccordion,
  IonItem,
  IonLabel,
  IonList,
} from '@ionic/react';
import { formatNumber } from 'common/utils/numberHelper';
import type { ProductGroup, ProductGroupItem } from 'models/ProductGroup';
import type { ReportDrillDownItem, SummaryItemOutput } from 'models/Reports';
import { findIcon } from 'utils/icons';
import ChangeFormat from 'components/ChangeFormat/ChangeFormat';
import classes from './SalesProductGroupDetails.module.scss';

interface SalesProductGroupProps {
  pg1DrilldownData: ReportDrillDownItem[];
  pg2DrilldownData: ReportDrillDownItem[];
  productGroups: ProductGroup[] | undefined;
  onPGC1Click: (pgc2Id: string) => string;
  pg1SalesSummary: SummaryItemOutput | undefined;
  t: TFunction;
  currencyType?: string;
}

interface SortConfigProps {
  sortField: 'productGroup' | 'sales' | 'change';
  sortDir: 'asc' | 'desc';
}

interface PG1DrillDownProps {
  pg1Name: string;
  pg1Id: string;
  pg1Amount: number | undefined;
  pg1Change: number | undefined;
  pg2List:
    | {
        pg2Name: string;
        pg2Id: string;
        pg2Amount: number | undefined;
        pg2Change: number | undefined;
      }[]
    | [];
}

const SalesProductGroupDetails = ({
  pg1DrilldownData,
  pg2DrilldownData,
  productGroups = [],
  onPGC1Click,
  pg1SalesSummary,
  t,
  currencyType,
}: SalesProductGroupProps): JSX.Element => {
  const [sortConfig, setSortConfig] = useState<SortConfigProps>({
    sortField: 'sales',
    sortDir: 'desc',
  });

  const productGroupObject: Record<string, ProductGroupItem[]> = {};
  productGroups.forEach((item) => {
    productGroupObject[item.code] = item.children;
  });
  let pg1Drilldown: PG1DrillDownProps[] = [];
  if (pg2DrilldownData) {
    pg1Drilldown = pg1DrilldownData.map((productGroup1Item) => {
      const { Name: pg1Name, id: pg1Id } = productGroup1Item;
      const salesData = productGroup1Item.items.find(
        (item) => item.Name === 'Sales'
      );
      const { amount: pg1Amount, change: pg1Change } = salesData || {};
      const pg2List = pg2DrilldownData
        .filter(
          (pg2Item) =>
            (pg1Id !== '0' &&
              !isEmpty(productGroupObject[pg1Id]) &&
              productGroupObject[pg1Id]
                .map((i) => i.code)
                .includes(pg2Item.id)) ||
            pg1Name === pg2Item.Name
        )
        .map(({ id, Name, items }) => {
          const pg2SalesData = items.find((i) => i.Name === 'Sales');
          const { amount: pg2Amount, change: pg2Change } = pg2SalesData || {};
          return { pg2Name: Name, pg2Id: id, pg2Amount, pg2Change };
        });
      return { pg1Name, pg1Amount, pg1Id, pg1Change, pg2List };
    });
  }

  const handleSortByPG = () => {
    const { sortField, sortDir } = sortConfig;
    if (sortField === 'productGroup') {
      setSortConfig({ sortField, sortDir: sortDir === 'asc' ? 'desc' : 'asc' });
    } else {
      setSortConfig({ sortField: 'productGroup', sortDir });
    }
  };

  const handleSortBySales = () => {
    const { sortField, sortDir } = sortConfig;
    if (sortField === 'sales') {
      setSortConfig({ sortField, sortDir: sortDir === 'asc' ? 'desc' : 'asc' });
    } else {
      setSortConfig({ sortField: 'sales', sortDir });
    }
  };

  const handleSortByChange = () => {
    const { sortField, sortDir } = sortConfig;
    if (sortField === 'change') {
      setSortConfig({ sortField, sortDir: sortDir === 'asc' ? 'desc' : 'asc' });
    } else {
      setSortConfig({ sortField: 'change', sortDir });
    }
  };

  const { sortDir, sortField } = sortConfig;
  pg1Drilldown = orderBy(
    pg1Drilldown,
    [
      (o) => {
        if (sortField === 'productGroup') {
          return o.pg1Name;
        }
        if (sortField === 'sales') {
          return o.pg1Amount;
        }
        return o.pg1Change;
      },
    ],
    [sortDir]
  );

  return (
    <>
      <IonItem
        slot="header"
        key="sales-group-header"
        className={classes.tableHeaderWrapper}
        color="#f5f5f5"
      >
        <IonLabel onClick={handleSortByPG} className={classes.tableHeader}>
          <>
            {sortField === 'productGroup' && (
              <FontAwesomeIcon
                className={classes.sortIcon}
                icon={
                  sortDir === 'asc'
                    ? findIcon('long-arrow-up', 'far')
                    : findIcon('long-arrow-down', 'far')
                }
              />
            )}
            {t('activities:productGroup')}
          </>
        </IonLabel>
        <IonLabel onClick={handleSortBySales} className={classes.tableHeader}>
          <>
            {sortField === 'sales' && (
              <FontAwesomeIcon
                className={classes.sortIcon}
                icon={
                  sortDir === 'asc'
                    ? findIcon('long-arrow-up', 'far')
                    : findIcon('long-arrow-down', 'far')
                }
              />
            )}
            {t('common:sales')}
          </>
        </IonLabel>
        <IonLabel onClick={handleSortByChange} className={classes.tableHeader}>
          <>
            {sortField === 'change' && (
              <FontAwesomeIcon
                className={classes.sortIcon}
                icon={
                  sortDir === 'asc'
                    ? findIcon('long-arrow-up', 'far')
                    : findIcon('long-arrow-down', 'far')
                }
              />
            )}
            {t('common:%Change')}
          </>
        </IonLabel>
      </IonItem>

      <IonAccordionGroup>
        {pg1Drilldown.map((productGroup1Item) => {
          const { pg1Name, pg1Id, pg1Amount, pg1Change, pg2List } =
            productGroup1Item;
          return (
            <IonAccordion
              value={pg1Id}
              toggleIconSlot="start"
              aria-hidden="false"
              key={pg1Id}
              className={classes.accordionCont}
            >
              <IonItem
                slot="header"
                key={`${pg1Id}-details`}
                className={classes.pgc1Cont}
              >
                <IonLabel
                  className={classNames(
                    classes.title,
                    classes.accordionLabel,
                    classes.accordionItem
                  )}
                >
                  <Link to={onPGC1Click(pg1Id)}>{pg1Name}</Link>
                </IonLabel>
                {!isNil(pg1Amount) && (
                  <IonLabel
                    className={classNames(
                      classes.amount,
                      classes.accordionLabel,
                      classes.accordionItem
                    )}
                  >
                    {formatNumber({ number: pg1Amount, currencyType })}
                  </IonLabel>
                )}
                {!isNil(pg1Change) && (
                  <div
                    className={classNames(
                      classes.change,
                      classes.accordionItem
                    )}
                  >
                    <ChangeFormat
                      className={classes.salesChange}
                      value={pg1Change}
                      testid={`reports-cards-change-${kebabCase(pg1Name)}}`}
                    />
                  </div>
                )}
              </IonItem>

              <IonList
                slot="content"
                key={`${pg1Id}-pgc2-details`}
                className={classes.pgc2Container}
              >
                {pg2List &&
                  orderBy(
                    pg2List,
                    [
                      (o) => {
                        if (sortField === 'productGroup') {
                          return o.pg2Name;
                        }
                        if (sortField === 'sales') {
                          return o.pg2Amount;
                        }
                        return o.pg2Change;
                      },
                    ],
                    [sortDir]
                  ).map((pgc2Item) => {
                    const { pg2Id, pg2Name, pg2Change, pg2Amount } = pgc2Item;
                    return (
                      <IonItem key={pg2Id} className={classes.pgc2Item}>
                        <IonLabel
                          className={classNames(
                            classes.title,
                            classes.accordionLabel,
                            classes.accordionItem
                          )}
                        >
                          {pg2Name}
                        </IonLabel>
                        {!isNil(pg2Amount) && (
                          <IonLabel
                            className={classNames(
                              classes.amount,
                              classes.accordionLabel,
                              classes.accordionItem
                            )}
                          >
                            {formatNumber({
                              number: pg2Amount,
                              currencyType,
                            })}
                          </IonLabel>
                        )}
                        {!isNil(pg2Change) && (
                          <div
                            className={classNames(
                              classes.change,
                              classes.accordionItem
                            )}
                          >
                            <ChangeFormat
                              className={classes.salesChange}
                              value={pg2Change}
                              testid={`reports-cards-change-${kebabCase(
                                pg2Name
                              )}}`}
                            />
                          </div>
                        )}
                      </IonItem>
                    );
                  })}
              </IonList>
            </IonAccordion>
          );
        })}
      </IonAccordionGroup>
      {pg1SalesSummary && (
        <IonItem
          key="total-summary"
          className={classNames(classes.pgc2Item, classes.tableFooter)}
        >
          <IonLabel
            className={classNames(
              classes.title,
              classes.accordionLabel,
              classes.accordionItem
            )}
          >
            <>{t('common:total')}</>
          </IonLabel>
          <IonLabel
            className={classNames(
              classes.amount,
              classes.accordionLabel,
              classes.accordionItem
            )}
          >
            {formatNumber({ number: pg1SalesSummary.amount, currencyType })}
          </IonLabel>
          <div className={classNames(classes.change, classes.accordionItem)}>
            <ChangeFormat
              className={classes.salesChange}
              value={pg1SalesSummary.change}
              testid="reports-cards-change-total-summary"
            />
          </div>
        </IonItem>
      )}
    </>
  );
};

export default SalesProductGroupDetails;
