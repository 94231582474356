import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import type { AxiosError } from 'axios';
import { get, isEmpty, size, toString, isNil } from 'lodash';
import { useInfiniteQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { doConcatDataPages, doPromiseAPI, useKeyUserId } from 'api/helpers';
import type { SalesPlay } from 'models/SalesPlays';
import { SalesPlaysStatus } from 'models/SalesPlays';
import type { InfiniteQueryFlags, QueryFlags } from 'models/Search';
import type { RootState } from 'store/reducers';
import { pageSize } from 'utils/constants';

export const findSalesPlaysQueryKey = 'salesPlays';

interface UseFindSalesPlaysProps {
  id?: string;
  filterStatus?: string;
  filterType?: string;
  custNo?: string;
  miLoc?: string;
  enabled?: boolean;
  limit?: number;
  topSalesPlays?: boolean;
}

interface UseFindSalesPlaysResponse {
  salesPlays: SalesPlay[];
}

const useFindSalesPlays = ({
  filterStatus,
  filterType,
  custNo,
  miLoc,
  enabled = true,
  limit = pageSize(),
  topSalesPlays,
}: UseFindSalesPlaysProps): InfiniteQueryFlags &
  UseFindSalesPlaysResponse &
  QueryFlags => {
  const { axios } = useAxios();
  const { findSalesPlaysAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const { userInfo } = useSelector((state: RootState) => state.user);
  const userId = get(userInfo, 'userid', '');
  const params: Record<string, string> = topSalesPlays
    ? {
        userId,
        topOpportunities: toString(topSalesPlays),
      }
    : {};

  const doFindSalesPlays = ({ pageParam = 1 }) => {
    return doPromiseAPI<SalesPlay[]>(async (cancelToken) => {
      const paramsGET = new URLSearchParams({
        ...params,
        limit: toString(limit),
      });

      if (!isNil(filterStatus) && !isEmpty(filterStatus)) {
        paramsGET.append('status', filterStatus);
      }
      if (!isNil(filterType) && !isEmpty(filterType)) {
        paramsGET.append('type', filterType);
      }
      if (!isNil(custNo)) {
        paramsGET.append('custNo', custNo);
      }
      if (!isNil(miLoc)) {
        paramsGET.append('custMiLoc', miLoc);
      }
      if (filterStatus === SalesPlaysStatus.NEW) {
        paramsGET.append('sortField', 'projectedValue');
      }
      if (
        filterStatus === SalesPlaysStatus.ACCEPTED ||
        filterStatus === SalesPlaysStatus.ARCHIVED
      ) {
        paramsGET.append('sortField', 'creationTimestamp');
      }
      paramsGET.append('sortDir', 'DESCENDING');
      paramsGET.append('page', toString(pageParam));

      const { data } = await axios.get<SalesPlay[]>(
        findSalesPlaysAPI(toString(paramsGET)),
        { cancelToken }
      );
      return data;
    });
  };

  const response = useInfiniteQuery<SalesPlay[], AxiosError>(
    createQueryKey(findSalesPlaysQueryKey, {
      ...params,
      filterStatus,
      filterType,
      custNo,
      miLoc,
    }),
    doFindSalesPlays,
    {
      enabled:
        enabled &&
        !isEmpty(userId) &&
        !isNil(filterStatus) &&
        !isNil(filterType),
      getNextPageParam: (lastPage, pages) =>
        size(lastPage) < pageSize() ? false : size(pages) + 1,
    }
  );

  const {
    data,
    error,
    status,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    refetch,
    fetchNextPage,
  } = response;

  const isLoading = response.status === 'loading';

  const salesPlays = useMemo(() => doConcatDataPages<SalesPlay>(data), [data]);

  const hasItems = size(salesPlays) > 0;
  const hasError = status === 'error';
  const isEmptyResponse = status === 'success' && !hasNextPage && !hasItems;
  const noMoreData = status === 'success' && !hasNextPage && hasItems;
  const showLoader = status === 'loading' || isFetchingNextPage;
  const enableInfiniteScroll = !(!hasNextPage || isFetchingNextPage);

  return {
    fetchNextPage: async () => {
      await fetchNextPage();
    },
    refetch: async () => {
      await refetch();
    },
    salesPlays,
    error,
    hasError,
    isFetching,
    showLoader,
    isEmptyResponse,
    noMoreData,
    enableInfiniteScroll,
    isLoading,
  };
};

export default useFindSalesPlays;
