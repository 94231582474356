import { useSelector } from 'react-redux';
import type { AxiosError } from 'axios';
import { get, toNumber, toString } from 'lodash';
import type { MutationStatus } from '@tanstack/react-query';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import useFindReports, {
  findDocumentsQueryKey,
  getDocumentQueryKey,
  reportsToSyncQueryKey,
} from 'DocumentsApp/api/useFindReports';
import useReportDB from 'DocumentsApp/database/useReportDB';
import type { Report } from 'DocumentsApp/models/Report';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import { useToasts } from 'providers/ToastProvider';
import {
  doPromiseAPI,
  onErrorUpdate,
  onMutateUpdate,
  onSuccessMutation,
} from 'api/helpers';
import type {
  GenericSectionDbItem,
  InspectionReport,
  PartsListDbItem,
  ReportValue,
  SignatureDBItem,
  VasCodesDbItem,
} from 'models/InspectionReport';
import { ToastType } from 'models/Toast';
import type { RootState } from 'store/reducers';
import { DateFormatEnum, formatDate } from 'utils/date';
import type { UpdateImagesBody } from './useUpdateImages';
import useUpdateImages from './useUpdateImages';
import useUpdateValues, { documentListConfig } from './useUpdateValues';
import useDocumentHelpers, {
  transformFromOfflineReport,
  transformFromOnlineReport,
} from './utils/documentHelpers';

interface UseUpdateSectionProps {
  reportId?: string;
}

export interface UpdateSectionBody extends UpdateImagesBody {
  reportValues?: ReportValue[];
  partsAdded?: PartsListDbItem[];
  partsRemoved?: number[];
  vasCodesAdded?: VasCodesDbItem[];
  vasCodesRemoved?: number[];
  genericSectionsAdded?: GenericSectionDbItem[];
  genericSectionsRemoved?: number[];
  reportSignature?: SignatureDBItem;
  autoSave?: boolean;
  forceOffline?: boolean;
}

interface UseUpdateSectionResponse {
  status: MutationStatus;
  onUpdateSection: (body: UpdateSectionBody) => void;
}

const useUpdateSection = ({
  reportId,
}: UseUpdateSectionProps): UseUpdateSectionResponse => {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const { isOnline } = useNetworkStatus();
  const { getReportById, createReports } = useReportDB();
  const { handleDocumentErrors } = useDocumentHelpers();
  const { userInfo } = useSelector((state: RootState) => state.user);
  const currentUserId = get(userInfo, 'userid', '');
  const currentUserName = get(userInfo, 'cn', '');

  const { doUpdateImagesOnline, doUpdateImagesOffline } = useUpdateImages();
  const {
    doUpdateSignatureOnline,
    doUpdateValuesOnline,
    doUpdateValuesOffline,
    doUpdateListOnline,
    doUpdateListOffline,
  } = useUpdateValues();

  const { uploadReport } = useFindReports({ enabled: false });

  const doUpdateSection = (vars: UpdateSectionBody) => {
    return doPromiseAPI(async () => {
      const report = transformFromOfflineReport(
        (await getReportById(toString(reportId))) || {}
      );
      // TODO optimize update when saving section and needs sync
      if (
        !vars.forceOffline &&
        isOnline &&
        !report.needSync &&
        !vars.autoSave
      ) {
        await doUpdateValuesOnline({ vars });
        await doUpdateListOnline({
          vars,
          list: 'parts',
          listId: documentListConfig.parts.id,
        });
        await doUpdateListOnline({
          vars,
          list: 'vasCodes',
          listId: documentListConfig.vasCodes.id,
        });
        await doUpdateListOnline({
          vars,
          list: 'genericSections',
          listId: documentListConfig.genericSections.id,
        });
        await doUpdateImagesOnline({ ...vars, report, reportId });
        await doUpdateSignatureOnline({ ...vars });
        return;
      }
      if (!vars.forceOffline && isOnline && report.needSync && !vars.autoSave) {
        await uploadReport({
          reportId,
          shouldRefetch: false,
          shouldThrowError: true,
        });
        return;
      }
      const offlineVars = { ...vars, report };
      const updatedValues = doUpdateValuesOffline(offlineVars);
      const updatedParts = doUpdateListOffline({
        vars: offlineVars,
        list: 'parts',
        listId: documentListConfig.parts.id,
      });
      const updatedVasCodes = doUpdateListOffline({
        vars: offlineVars,
        list: 'vasCodes',
        listId: documentListConfig.vasCodes.id,
      });
      const updatedGenericSections = doUpdateListOffline({
        vars: offlineVars,
        list: 'genericSections',
        listId: documentListConfig.genericSections.id,
      });
      const updatedImages = doUpdateImagesOffline({
        ...vars,
        report,
        reportId,
      });
      const reportDB: Report = {
        ...transformFromOnlineReport(report),
        ...updatedValues,
        ...updatedParts,
        ...updatedVasCodes,
        ...updatedGenericSections,
        ...updatedImages,
        lastUpdUserId: currentUserId,
        lastUpdUserName: currentUserName,
        lastUpdTmstmp: formatDate(new Date(), DateFormatEnum.ISO),
        lastSyncDate: formatDate(new Date(), DateFormatEnum.ISO),
        needSync: true,
      };
      await createReports([reportDB]);
    });
  };

  const { mutate, status } = useMutation(doUpdateSection, {
    networkMode: 'always',
    onMutate: () =>
      onMutateUpdate<InspectionReport>({
        queryClient,
        queryKey: findDocumentsQueryKey,
        updatedItems: [
          {
            reportId: toNumber(reportId),
          } as InspectionReport,
        ],
        findPredicates: [{ reportId: toNumber(reportId) }],
        isInfiniteQuery: true,
      }),
    onSuccess: (data, vars) => {
      if (!vars.autoSave) {
        void onSuccessMutation(queryClient, reportsToSyncQueryKey);
        void onSuccessMutation(queryClient, findDocumentsQueryKey);
        void onSuccessMutation(queryClient, getDocumentQueryKey);
        if (vars.forceOffline) {
          addToast({
            text: `Changes will be saved in your device.`,
            testid: 'save-offline-toast',
          });
          return;
        }
        addToast({
          type: ToastType.default,
          text: 'Save section operation successful.',
          testid: 'edit-report-success-toast',
        });
      }
    },
    onError: (e, vars, context) => {
      handleDocumentErrors({
        error: e as AxiosError,
        defaultErrorText: 'Save section operation failed',
      });
      if (!vars.forceOffline) {
        mutate({ ...vars, forceOffline: true });
      }
      onErrorUpdate<InspectionReport>({
        queryClient,
        context,
        isInfiniteQuery: true,
      });
    },
  });

  return {
    status,
    onUpdateSection: (body) => mutate(body),
  };
};

export default useUpdateSection;
