import { useMemo } from 'react';
import type { AxiosError } from 'axios';
import { size, toString } from 'lodash';
import type { QueryFunctionContext } from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import type {
  QueryFlags,
  QueryParamsType,
} from 'common/api/utils/useGetQueryFlags';
import useGetQueryFlags from 'common/api/utils/useGetQueryFlags';
import type { ShippingCharges } from 'ProductSearchApp/models/Order';
import { useAxios } from 'providers/AxiosProvider';
import { doConcatDataPages, useKeyUserId } from 'api/helpers';
import { pageSize } from 'utils/constants';

export const findShippingChargesQueryKey = 'shipping-charges';

interface UseFindShippingChargesProps {
  limit?: number;
  enabled?: boolean;
  query?: string;
}

interface UseFindShippingChargesResponse {
  shippingCharges: ShippingCharges[];
}

const useFindShippingCharges = ({
  limit = pageSize(),
  enabled = true,
  query,
}: UseFindShippingChargesProps): UseFindShippingChargesResponse &
  QueryFlags => {
  const { axios } = useAxios();
  const { findShippingChargesAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const params: QueryParamsType = {
    limit,
    fastFind: query,
    sortField: 'codeValue',
    sortDir: 'ASC',
  };

  const doFindShippingCharges = async ({
    pageParam = 1,
    signal,
  }: QueryFunctionContext) => {
    const { data } = await axios.get<ShippingCharges[]>(
      findShippingChargesAPI(
        toString(new URLSearchParams({ ...params, page: toString(pageParam) }))
      ),
      { signal }
    );
    return data;
  };

  const response = useInfiniteQuery<ShippingCharges[], AxiosError>(
    createQueryKey(findShippingChargesQueryKey, params),
    doFindShippingCharges,
    {
      enabled,
      getNextPageParam: (lastPage, pages) =>
        size(lastPage) < limit ? false : size(pages) + 1,
    }
  );

  const { data } = response;

  const shippingCharges = useMemo(
    () => doConcatDataPages<ShippingCharges>(data),
    [data]
  );

  const queryFlags = useGetQueryFlags({
    ...response,
    data: shippingCharges,
    enabled,
  });

  return {
    shippingCharges,
    ...queryFlags,
  };
};

export default useFindShippingCharges;
