import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { isEmpty, isObject, toString } from 'lodash';
import { IonCol, IonRow, IonImg } from '@ionic/react';
import ItemDetailLines from 'common/components/List/ItemDetailLines';
import type {
  BaseComponentProps,
  OptionalRenderProp,
} from 'common/components/utils/renderHelpers';
import { OcnLineItemBadge } from 'ProductSearchApp/components/OcnBadge/OcnBadge';
import type {
  Product,
  ProductDetailURLParams,
} from 'ProductSearchApp/models/Products';
import { isZcodedItem } from 'ProductSearchApp/util/ocnHelpers';
import { removeLeadingZeros } from 'utils/number';
import { goToProductDetail } from 'navigation/navigationHelpers';
import MIIcon from 'assets/MIIcon.svg';
import Badge from 'components/Badge/Badge';
import Button from 'components/Button/Button';
import Text from 'components/Text/Text';
import classes from './ProductHeader.module.scss';
import ProductPrice from './ProductPrice';

interface ProductHeaderProps extends BaseComponentProps {
  productData?: Product;
  withLink?: boolean;
  disabled?: boolean;
  hidePrice?: boolean;
  hideImage?: boolean;
  lineNumber?: string;
  badge?: OptionalRenderProp<React.ComponentProps<typeof OcnLineItemBadge>>;
}

const ProductHeader = ({
  className,
  withLink = false,
  disabled,
  hidePrice = false,
  hideImage = false,
  lineNumber,
  productData,
  testid,
  ...props
}: ProductHeaderProps): JSX.Element => {
  const [img, setImage] = useState<string>(MIIcon);
  const { miLoc, id: customerNo } = useParams<ProductDetailURLParams>();
  const isZCoded = isZcodedItem(productData?.itemNumber);
  const { t } = useTranslation();
  const badgeProps = isObject(props.badge) ? props.badge : undefined;
  const badgeText = isObject(props.badge) ? props.badge.status : props.badge;

  useEffect(() => {
    if (productData?.imageURL) {
      setImage(
        `${toString(process.env.REACT_APP_IMAGES_URL)}${toString(
          productData?.imageURL
        )}`
      );
    }
  }, [productData?.imageURL]);

  return (
    <div
      className={classNames(classes.productHeader, className)}
      data-testid={testid}
    >
      <IonRow class="ion-no-padding">
        {(!hideImage || lineNumber) && (
          <IonCol
            class="ion-no-padding"
            className={classNames(classes.imgCol, {
              [classes.lineNumberCol]: !isEmpty(lineNumber),
            })}
          >
            {lineNumber && (
              <Text
                className={classes.lineNumber}
                variant="mipro-h3-headline"
                text={lineNumber}
              />
            )}
            {!hideImage && !isZCoded && (
              <IonImg
                src={img}
                className={classes.img}
                onIonError={() => setImage(MIIcon)}
                data-testid="product-image"
              />
            )}
          </IonCol>
        )}
        <IonCol class="ion-no-padding">
          <IonRow className={classes.row}>
            <Text
              variant="content-small"
              text={toString(productData?.manufacturerName)}
              testid="product-manufacturerName"
              className={classes.mfrName}
            />

            {!isEmpty(badgeText) && (
              <OcnLineItemBadge
                status={toString(badgeText)}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...badgeProps}
              />
            )}
          </IonRow>
          <IonRow>
            {!isZCoded && withLink ? (
              <Button
                className={classes.mfgPartNumber}
                text={toString(productData?.mfgPartNumber)}
                variant="link"
                textVariant="mipro-h3-headline"
                disabled={disabled}
                href={goToProductDetail({
                  miLoc,
                  customerNo,
                  itemNo: productData?.itemNumber,
                })}
                testid="product-link"
              />
            ) : (
              <>
                {isZCoded && (
                  <Badge
                    className={classes.badge}
                    text="Z"
                    type="info"
                    testid="zcoded-badge"
                    circle
                  />
                )}
                <Text
                  className={classes.mfgPartNumberLabel}
                  variant="mipro-h3-headline"
                  text={toString(productData?.mfgPartNumber)}
                  testid="product-manufacturerPartNo"
                />
              </>
            )}
          </IonRow>
          <ItemDetailLines
            className={classes.itemWrapper}
            lines={[
              {
                hidden: !isZCoded,
                label: t('productSearch:ocn:item'),
                value: removeLeadingZeros(productData?.itemNumber),
              },
              {
                label: t('productSearch:ocn:mino'),
                value: toString(productData?.mino),
              },
              {
                hidden: !isEmpty(productData?.customerStockNumber),
                label: t('product:csn'),
                value: toString(productData?.customerStockNumber),
              },
            ]}
          />
          {!hidePrice && (
            <ProductPrice
              testid={toString(productData?.itemNumber)}
              productData={productData}
            />
          )}
        </IonCol>
      </IonRow>
    </div>
  );
};

export default ProductHeader;
