import { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { map, padEnd, split, toString } from 'lodash';
import { and, choose, ifFunction, or } from 'common/utils/logicHelpers';
import type { CostSavingsReportRow } from 'ReportsApp/api/useGetCostSavingsReport';
import { useGetTableColumns } from 'ReportsApp/hooks/useGetTableColumns';
import type { BaseReportURLParams, ReportField } from 'ReportsApp/models';
import { goToCostSavingsReport } from 'ReportsApp/navigation/navigationHelpers';
import {
  goToCustomerCostSavings,
  goToCustomerSnapshot,
} from 'SearchApp/navigation/navigationHelpers';
import type { SnapshotRoutingState } from 'utils/search';
import type { FilterOption } from 'components/Filter/Filter';

interface UseGetCostSavingsTableDataProps {
  drilldownData: CostSavingsReportRow[];
  summaryData: CostSavingsReportRow;
  selectedGroupBy: FilterOption;
  reportFields: ReportField[];
  currencyType?: string;
  showBy?: string;
}

// eslint-disable-next-line import/prefer-default-export
export const useGetCostSavingsTableData = ({
  drilldownData,
  summaryData,
  selectedGroupBy,
  reportFields,
  currencyType,
  showBy,
}: UseGetCostSavingsTableDataProps) => {
  const { orgType = '', rowId: routeRowId = '' } =
    useParams<BaseReportURLParams>();
  const [rowId] = split(routeRowId, '-');
  const groupByDataKey = selectedGroupBy.key;
  const namespace = 'ReportApp-CostSavingsReport';
  const shouldLinkToCorpAcc = and(showBy === 'CORP', groupByDataKey === 'CUST');
  const history = useHistory();

  const getTableData = (row: CostSavingsReportRow, index: number) => {
    let rowName = toString(
      choose(row.miLocName, `${row.miLoc}: ${row.miLocName}`, row.miLoc)
    );
    let href: string | undefined = goToCostSavingsReport({
      orgType: groupByDataKey,
      miLoc: row.miLoc,
      rowId: routeRowId,
    });
    let customerHref = goToCustomerSnapshot({
      miLoc: row.miLoc,
      customerId: row.customerNo,
    });

    ifFunction(shouldLinkToCorpAcc, () => {
      customerHref = goToCustomerSnapshot({
        miLoc: row.corporateAcctNo.slice(0, 4),
        customerId: padEnd(row.corporateAcctNo.slice(4), 8, '0'),
      });
    });

    ifFunction(and(showBy === 'CUST', groupByDataKey === 'CUST'), () => {
      customerHref = goToCustomerCostSavings({
        miLoc: row.miLoc,
        customerId: row.customerNo,
      });
    });

    switch (groupByDataKey) {
      case 'TEAM':
        rowName = row.teamName;
        href = goToCostSavingsReport({
          orgType: groupByDataKey,
          miLoc: row.teamId,
        });
        break;
      case 'CUST':
        rowName = toString(
          choose(showBy === 'CORP', row.corporateAcctName, row.customerName)
        );
        href = choose(
          or(showBy === 'BILLTO', shouldLinkToCorpAcc),
          undefined,
          customerHref
        );
        break;
      default:
    }

    const onClick = () => {
      if (shouldLinkToCorpAcc) {
        history.push({
          pathname: customerHref,
          state: {
            isCorpAccount: true,
            headerTitle: rowName,
          } as SnapshotRoutingState,
        });
      }
    };

    return {
      data: {
        ...row,
        title: rowName,
        href,
        hideArrow: groupByDataKey === 'CUST',
        testid: `cost-savings-${rowName}-${index}`,
        onClick,
      },
    };
  };

  const tableData = useMemo(
    () => map(drilldownData, getTableData),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [drilldownData, groupByDataKey, routeRowId, rowId, orgType]
  );

  const { tableColumns, totalsRow } = useGetTableColumns({
    groupByDataKey,
    reportFields,
    selectedGroupBy,
    namespace,
    summaryData,
    currencyType,
  });

  return { tableData, tableColumns, totalsRow };
};
