import React from 'react';
import { useTranslation } from 'react-i18next';
import type { AxiosError } from 'axios';
import type { BaseComponentProps } from 'common/components/utils/renderHelpers';
import { getErrorMessage, getInvalidBusDay } from 'utils/helpers';
import { isSalesJobRunning, noCoprporateAccountAssigned } from 'utils/reports';
import WarningMessage, {
  WarningMessageVarianEnum,
} from 'components/WarningMessage/WarningMessage';

interface ReportErrorProps extends BaseComponentProps {
  error?: AxiosError | null;
  reportName?: string;
  requestType?: string;
}

const ReportError = ({
  className,
  error,
  reportName,
  requestType,
  testid,
}: ReportErrorProps): JSX.Element => {
  const { t } = useTranslation();
  let errorTitle = t('reports:errorReports');
  let errorBody = getErrorMessage(error);
  let errorVariant = WarningMessageVarianEnum.default;

  if (isSalesJobRunning(error)) {
    errorTitle = t('reports:runningJobTitle', { reportName });
    errorBody = t('reports:runningJobMsg');
    errorVariant = WarningMessageVarianEnum.warning;
  }

  if (getInvalidBusDay(error)) {
    errorTitle = t('reports:emptyReportHeader');
    errorBody = t('reports:invalidBusDayMsg', {
      requestType: requestType === 'MTD' ? t('common:month') : t('common:year'),
    });
  }

  if (noCoprporateAccountAssigned(error)) {
    errorBody = t('reports:noAcctsAssigned');
  }

  return (
    <WarningMessage
      className={className}
      title={errorTitle}
      body={errorBody}
      variant={errorVariant}
      testid={testid}
    />
  );
};

export default ReportError;
