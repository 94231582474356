import { useTranslation } from 'react-i18next';
import type { AxiosError } from 'axios';
import { filter, includes, map, toString } from 'lodash';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { MutationStatus } from '@tanstack/react-query';
import useAPIUrl from 'api';
import useCountPlanDB from 'InventoryApp/database/useCountPlanDB';
import type {
  CountGroup,
  CountGroupItem,
  UpdateCountGroupItemsResponse,
} from 'InventoryApp/models/InventoryPlanGroup';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import { doPromiseAPI, onMutateUpdate, onSuccessMutation } from 'api/helpers';
import { ToastType } from 'models/Toast';
import useDownloadGroupItems from './useDownloadGroupItems';
import { findCountGroupsQueryKey } from './useFindCountGroups';

interface SubmitInventoryOrderBody {
  miLoc: string;
  countPlanId: string;
  groupId: number;
  groupUniqueId: string;
  storeroomNo: string;
  amtPctDiff: string;
  qtyPctDiff: string;
  createOrder: boolean;
  countType: string;
  unassignGroup: boolean;
  keepLocal: boolean;
  completeGroup: boolean;
}

interface UseSubmitInventoryOrderResponse {
  status: MutationStatus;
  error?: AxiosError | null;
  onSubmitInventoryOrder: (body: SubmitInventoryOrderBody) => void;
}

const useSubmitInventoryOrder = (): UseSubmitInventoryOrderResponse => {
  const { axios } = useAxios();
  const { submitInventoryOrderAPI } = useAPIUrl();
  const { removeCountGroups, findItemsByGroup } = useCountPlanDB();

  const { onDownloadGroupItems } = useDownloadGroupItems();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const doSubmitInventoryPlanAndGroup = (body: SubmitInventoryOrderBody) => {
    return doPromiseAPI(async (cancelToken) => {
      const { countPlanId, groupUniqueId, keepLocal, ...rest } = body;
      let items = await findItemsByGroup(
        countPlanId,
        groupUniqueId,
        '',
        false,
        true
      );
      items = map(items, (item) => ({
        ...item,
        hasCount: !!item.hasCount,
        hasEditedMinMax: !!item.hasEditedMinMax,
        allowEdit: !!item.allowEdit,
        hasLocalCount: !!item.hasLocalCount,
      }));
      items = map(
        filter(
          items,
          (item: CountGroupItem) =>
            (item.hasCount && item.hasLocalCount) || item.hasEditedMinMax
        ),
        (item: CountGroupItem) => {
          return {
            ...item,
            actualCount: item.hasCount ? item.actualCount || 0 : '0',
            orderQty: item.hasCount ? item.orderQty || 0 : 0,
          };
        }
      ) as unknown as CountGroupItem[];
      const { data } = await axios.post<UpdateCountGroupItemsResponse>(
        submitInventoryOrderAPI(),
        { countPlanId, groupUniqueId, items, ...rest },
        { cancelToken }
      );
      if (!keepLocal) {
        await removeCountGroups([{ countPlanId, groupId: groupUniqueId }]);
      } else if (keepLocal) {
        onDownloadGroupItems({
          miLoc: body.miLoc,
          countPlanId,
          groupId: toString(body.groupId),
          groupUniqueId,
          storeroomNo: body.storeroomNo,
          ignoreToast: true,
          forceDeleteManual: true,
        });
      }
      return data;
    });
  };

  const { status, error, mutate } = useMutation(doSubmitInventoryPlanAndGroup, {
    onMutate: ({ groupUniqueId }) =>
      onMutateUpdate<CountGroup>({
        queryClient,
        queryKey: findCountGroupsQueryKey,
        updatedItems: [
          {
            uniqueId: groupUniqueId,
          } as CountGroup,
        ],
        dataPath: 'items',
        findPredicates: [{ uniqueId: groupUniqueId }],
        isInfiniteQuery: true,
      }),
    onSuccess: (data, vars) => {
      void onSuccessMutation(queryClient, findCountGroupsQueryKey);
      if (includes(data.message, 'Items saved but could Not complete Group')) {
        addToast({
          type: ToastType.warn,
          variant: 'mipro-toast',
          header: t('inventory:couldNotCompleteGroupToast'),
          testid: 'submit-order-not-completed-toast',
        });
      } else {
        addToast({
          type: ToastType.success,
          variant: 'mipro-toast',
          header: vars.createOrder
            ? t('inventory:submitOrderMsg')
            : t('inventory:submitCountGroupMsg'),
          testid: 'submit-order-success-toast',
        });
      }
    },
    onError: () => {
      // TODO if we need to add msg of specific error as in useSubmitInventoryPlan
      addToast({
        type: ToastType.error,
        text: t('inventory:errorSubmitOrder'),
        testid: 'submit-order-error-toast',
      });
    },
  });

  return {
    status,
    error: error as AxiosError,
    onSubmitInventoryOrder: (body: SubmitInventoryOrderBody) => mutate(body),
  };
};

export default useSubmitInventoryOrder;
